<template>
  <svg
    version="1.1"
    :class="currClassState"
    id="portfolio_x5F_svg_x5F_1_xA0_Image_00000049932047193446491390000012671380171327439512_"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1003 1089.1"
    style="enable-background: new 0 0 1003 1089.1"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M393.5,449.6c0.3,3.6-0.4,6.4-4.1,8.3c-16.3,8.5-32.5,17.4-48.7,26c-21.3,11.3-42.7,22.4-64,33.7
			c-2.5,1.3-4.7,3.1-7.1,4.6c0.6,7.2,0.6,14.4,0,21.6c-1.8,2.6-4.6,3.8-7.2,5c-11.6,5.8-23.1,11.6-34.5,17.7c-5,2.7-10,2.2-14.7-1.4
			c-1.9-1.5-3-3.2-3-5.7c0-1.2-0.1-2.5-0.4-3.7c-0.3-1.3-1-2.1-2.4-1.3c-6.4,3.6-13,6.7-19.4,10.2c-0.5,0.3-0.9,0.8-1.2,1.4
			c-1.4,2-3,3.7-5.5,4.1c-2.6-1.6-4.9-0.6-7.3,0.7c-16.5,8.7-33.3,17.1-49.6,26.2c-8,4.5-14.6,2.6-21.5-1.6c1.7-1.2,1.7-1.2,4.5,0
			c-0.8-1.7-1.5-3.3-1.8-5.2c-0.4-12-0.2-23.9-0.1-35.9c0.2-55,0-110,0.1-165c-0.2-2.5,0.6-4.7,1.6-6.9c0.4-1.1,1.1-2.1,1.8-3.1
			c2.3-3.4,5.8-5.5,9.3-7.3c21.3-10.7,42.3-22.2,63.4-33.4c26.3-13.9,52.6-27.7,78.8-41.8c9.2-5,18.6-9.6,27.7-14.7
			c12.9-6.4,25.4-13.6,38.1-20.5c4.2-2.2,8.5-4.1,12.5-6.6c14.4-7.8,29-15.5,43.2-23.7c2.6-1.5,5.2-2.2,8.2-1.5
			c0.5-0.1,0.9-0.3,1.4-0.3c2,2.3,2.6,5.1,2.5,8c-0.6,32.9-0.3,65.8-0.2,98.6c0,0.4-0.2,0.7-0.2,1.1c-0.8,2.1-0.3,4.2-0.3,6.3
			c0,17.9,0.1,35.9-0.1,53.8c0,4.6,0.1,9.2-0.1,13.7c0.2,8.5,0.2,17,0,25.5C392.8,441,392.9,445.4,393.5,449.6z"
        />
        <path
          class="st1"
          d="M268.6,544.6c0.1-6.2,0.1-12.4,0.2-18.5c0-1.3-0.4-2.7,0.7-3.9c8.4,4.3,16.8,8.6,25.2,12.8c2,1,3.9,2,6.2,2.3
			c2.1,0,3.7,0.8,4.8,2.6c0.6,2.8,2.4,4.5,4.8,5.8s5.2,2.4,5.2,6c-1,0.8-2.2,1.1-3.5,1c-2.4-1.2-4.5,0-6.6,0.9
			c-2.2,0.8-4.2,2-6.2,3.1c-17.2,8.9-34.6,17.5-51.5,27c-8.8,4.9-17.1,5.3-25.7,0.2c-1.1-0.6-2.2-1.1-3.5-1.2c-0.4,0-0.7-0.2-1-0.4
			c-2.1-3.1,0.4-4.6,2.4-5.8c5.1-3.1,10.4-5.9,15.7-8.7c8.2-4.2,16.3-8.5,24.4-13C264.2,552.4,266.3,548.4,268.6,544.6z"
        />
        <path
          class="st2"
          d="M116.2,365.9c12.2-6.5,24.4-12.9,36.5-19.4c31.5-16.8,63-33.6,94.5-50.3c15.3-8.1,30.6-16,46-24
			c0.7-0.4,1,0,1.2,0.7c-1,2.2-3.1,3-5.1,4c-2.5,1.3-4.9,2.8-7.4,4c-16.4,8.6-32.8,17.3-49.2,25.9c-22,11.6-43.8,23.6-65.9,35.1
			c-10.6,5.5-21.1,11.2-31.7,16.7c-4.5,2.3-9,4.7-13.5,7.1c-1.4,0.8-2.8,1.7-4.5,1.4C116.4,366.9,116,366.6,116.2,365.9z"
        />
        <path
          class="st3"
          d="M292.6,569.4c-12.6,7.6-25.9,13.8-38.7,20.9c-3.2,1.8-7,2.6-10.5,3.8c-0.9,0.4-1.8,0.7-2.5-0.4
			c1.2-2.4,3.8-2.9,5.9-4.1c2.6-1.5,5.3-3,7.6-5.1c7.2-4.7,15-8.2,22.6-12.1c0.8-0.4,1.6-0.7,2.3-1.2c8.5-6.2,18.4-9.7,27.6-14.5
			c2.3-1.2,4.6-2.4,6.9-3.7c1.1-0.6,2.5-1.4,3.4-0.2c1.1,1.5-0.7,2-1.5,2.8c-0.4,0.3-0.8,0.6-1.2,0.9l0.1,0.3
			c-1.8,1.3-3.2,3.2-5.3,4.2C303.7,563.9,298,566.4,292.6,569.4z"
        />
        <path
          class="st4"
          d="M181.1,570.2c1-2,4-2.2,4.6-4.6c1.3-0.9,2.4,0,3.4,0.6c8.2,4.8,17,8.6,25.2,13.6c1,0.6,1.9,1.2,2.4,2.3
			c1,5.8,5.8,7.4,10.3,9.3c1.1,0.7,2.3,1.1,3.5,1.5c2.6,1.5,6,0,8.5,2.1c-3.2,1.9-6.5,1.1-9.7,0.3c-2.3-0.6-4.2-2-6.5-2.6
			c-7.5-1.9-13.2-7.2-20-10.5C195.4,578.5,188.3,574.2,181.1,570.2z"
        />
        <path
          class="st5"
          d="M314.4,556.6c0.8-1,1.7-2.1,2.5-3.1c-0.4-0.7-0.9-0.6-1.3-0.4c-7.4,4.7-15.6,7.6-23.2,11.8
			c-12.5,6.9-25.2,13.4-37.8,20.1c-1.8-0.2-2.3-1.2-0.8-2.2c2.2-1.5,4.8-2.5,7.2-3.4c-0.3-0.4-1.4,0.5-2-0.3c-0.3-1.7,1.2-2,2.2-2.6
			c13.1-7.1,26.1-14.2,39.6-20.6c2.5-0.8,4.7-2.1,7.1-3.2c1.3-0.6,2.5-1.7,4.1-0.8c1.3-0.2,2.5-0.4,3.8-0.6c1.5,0,1.3-3.5,3.6-1.7
			C319.7,553.6,318.5,555.3,314.4,556.6z"
        />
        <path
          class="st6"
          d="M116.2,365.9c0.3,0.3,0.6,0.5,0.8,0.8c-0.2,2-2.1,2.2-3.4,3c-3.6,2-7.5,3.4-10.4,6.5
			c-1.6,1.8-2.4,3.9-3.1,6.1c-0.6,3.8-0.7,7.7-0.7,11.5c0,30.5,0,61,0,91.5c0,2.2,0.1,4.5-0.3,6.7c-1.2,4.5-0.3,9-0.6,13.5
			c-0.1,1.5,0.5,3.3-1.6,4c0-24-0.1-48.1-0.1-72.2c0-16.5-0.1-32.9,0-49.4c0.1-8.1,3.6-14.4,11-18.1
			C110.5,368.5,113.3,367.2,116.2,365.9z"
        />
        <path
          class="st7"
          d="M96.8,509.5c1.4-4.1,0.3-8.4,0.6-12.5c0.1-1.6-0.6-3.3,0.7-4.7c1.3,1.2,1.2,2.9,1.2,4.5c0,9.7,0.1,19.4,0,29
			c0.1,21.1-0.9,42.2,1,63.3c-0.1,0.9-0.1,2-1.5,2c-1.9-3.1-2.1-6.6-2.1-10.1c0-15.5,0-31.1,0-46.6
			C96.7,526.1,96.8,517.8,96.8,509.5z"
        />
        <path
          class="st8"
          d="M391.4,229.7c-0.2,0.2-0.5,0.4-0.7,0.7c-6.1-4.3-11.6-1.7-17.3,1.3c-9.7,5.1-19.2,10.5-28.9,15.7
			c-1,0.5-2.1,1-3.3,0.7c-0.6-0.2-1-0.6-0.7-1.3c12.3-6.6,24.5-13.4,36.8-19.9C383.3,223.8,387.6,225,391.4,229.7z"
        />
        <path
          class="st9"
          d="M340.5,246.8c0.3,0.3,0.5,0.6,0.8,0.8c0.1,1.6-1.3,1.9-2.4,2.5c-12.7,7.1-25.5,13.8-38.3,20.7
			c-2,1.1-3.9,2.9-6.5,2.2c-0.3-0.3-0.6-0.5-0.9-0.8c3.6-2,7.1-4,10.7-5.9C316.1,259.8,328.3,253.3,340.5,246.8z"
        />
        <path
          class="st10"
          d="M392.8,411.5c-0.8-2.8-0.8-5.9-0.5-8.5c1-9.8,0.4-19.6,0.5-29.4c0.1-11,0.4-22.1-0.2-33.1
			c-0.1-1.1,0.1-2.2,1-3.1c0,24.2,0,48.4,0,72.6C393.1,410.4,393.4,411.1,392.8,411.5z"
        />
        <path
          class="st11"
          d="M319.3,549.8c-1-0.4-1.1,0.2-1.4,1c-0.3,1-1,1.8-2.1,0.7c-1.6-2.4-3.5-4.3-6.3-5.4c-2.5-1-4.3-2.9-4.2-5.9
			c4.9,0.2,8.7,2.7,12.3,5.9C318.1,547.3,319.4,548.2,319.3,549.8z"
        />
        <path
          class="st12"
          d="M317.5,546.1c-4.2-1.7-7.9-4.6-12.3-5.9c-1-1.7-3.3-1.4-4.4-2.8c-0.2-4,1.6-1.7,2.9-0.9
			c2.8,2.5,6.2,3.7,9.6,4.7c2,0.4,3,2.3,4.9,3.1C320.9,545.4,317.3,545.2,317.5,546.1z"
        />
        <path
          class="st13"
          d="M392.8,411.5c0.2-0.5,0.4-1,0.6-1.5c0,13.2,0,26.4,0,39.6c-2-2.4-0.7-5.3-0.8-7.9
			C392.4,431.7,392.7,421.6,392.8,411.5z"
        />
        <path
          class="st14"
          d="M292.6,569.4c2.6-2.7,6.2-3.9,9.3-5.8c2.2-1.3,4.6-2.3,7-3.4c0.9,1.6-0.8,1.7-1.4,2.4
			C302.9,565.5,297.9,567.8,292.6,569.4z"
        />
        <path
          class="st15"
          d="M98.8,591.1c0.7-0.3,0.8-0.9,0.8-1.5c1.5-0.5,2.1,0.9,3.1,1.5c1.2,0.8,2.3,1.5,3.7,0.6
			c0.7,1.5,1.4,3,2.2,4.6c-2.3,0.3-3.8-2.1-6-0.9C100.9,594.5,99.5,593.1,98.8,591.1z"
        />
        <path
          class="st16"
          d="M222.7,592.7c2.4-0.5,4.3,1,6.2,1.9c3.3,1.5,6.5,1.5,9.8,0.2c0.4-1.1,0.9-1.8,2.2-1.5
			c0.6,0.9,1.5,0.7,2.4,0.8C236.2,598,229.3,597.3,222.7,592.7z"
        />
        <path
          class="st17"
          d="M313.4,541.1c-2.5,0.7-8-1.9-9.6-4.7C307.1,537.7,310.4,539.1,313.4,541.1z"
        />
        <path
          class="st18"
          d="M307.6,562.6c0.5-0.8,0.9-1.6,1.4-2.4c1.8-1.2,3.3-2.9,5.5-3.4C312.9,559.7,310.9,561.9,307.6,562.6z"
        />
        <path
          class="st19"
          d="M120.7,580c-0.9-3.3-0.8-6.6-0.8-10c0-21.9,0-43.9,0-65.8c0-6.7,0-13.4,0.7-20.1c2,0.2,1.7,1.9,1.8,3.1
			c0.5,8.3,0.4,16.7-0.2,25c-0.8,10.5-0.5,20.9,0.8,31.3c0.6,4.9,1.1,9.9,1,14.9c0,1.7-0.4,3.5,0.4,5.1c0.7,0.6,1.3,1.6,2.5,1
			c15.8-8.1,31.8-16,47.3-24.8c3.4-2,7.6-2.6,9.9-6.2c-0.3-2.1-0.9-4.3,0.5-6.3c0.2-1.7-0.1-3.3,0.6-4.9c0.8-1.6,2.5-2.4,3.6-3.7
			c1.6-2,1.3-3-1.1-3.7c-4.6-1.2-5.4-2.3-5.1-7c0.1-1.4,0.5-2.7,0.6-4c0.3-2.7,1.5-4.1,4.4-3.7c1.5,0.2,2.9,0,4.4-0.6
			c3.6-1.4,5.4-0.8,7.4,2.5c0.7,1.2,1.3,2.5,1.4,3.8c0.2,3.3,1.1,5.9,4.4,7.5c2.5,1.2,2.3,4.2,2.9,6.5c0.3,0.4,0.8,0.5,1.3,0.5
			c1.9,0,3.3-1.4,4.9-2c8.1-3.8,15.8-8.4,23.6-12.6c14.8-7.5,29.1-15.7,43.6-23.5c2.5-1.4,5.1-2.5,7.6-4c1.5-0.9,3.1-1.8,4.8-0.4
			c1.2,1,2.3,0.2,3.4-0.4c5.5-2.7,10.4-6.3,16.1-8.7c11.5-6.2,23.1-12,34.5-18.4c5.9-3.2,12.4-5.3,17.6-9.8c0.8-0.9,1.6-1.8,2.9-1.9
			c2.4-0.3,1.9-1.9,1.4-3.3c-0.9-2.7-1-5.4,0.2-8c0.4-0.8,0.9-1.7,1.8-1.6s1.3,1,1.7,1.9c0.6,1.7,0.3,3.5,1,5.1
			c-0.1-6.9,0.5-13.8-0.5-20.8c-1.2-8.3-2.2-16.8,1-25c0.6-1.7,0.6-3.4,0.5-5.2c-0.2-12.7-0.2-25.4-0.2-38.1s0.1-25.4,0.3-38.1
			c-0.2-11-0.1-21.9-0.1-32.9c0-1.4,0-2.8,0.9-4c0.4-0.4,0.8-0.5,1.3-0.1c1.1,1.4,0.8,3.1,0.8,4.7c0,53.6,0,107.3,0,161
			c0,3-0.5,5.9-0.6,8.9c0.3,2.7-1.5,4.1-3.5,5.3c-3.6,2.3-7.3,4.4-11,6.2c-16.9,8.3-33.2,17.6-49.9,26.2
			c-21.5,10.8-42.7,22.1-64,33.2c-11.7,6.1-23.5,12-35.2,18.2c-29.3,15.5-58.7,30.8-88.1,46.2C124.3,579.4,122.8,580.9,120.7,580z"
        />
        <path
          class="st20"
          d="M123.7,384.6c7.6-5.3,16.2-8.8,24.2-13.2c14.8-8.1,29.8-15.8,44.8-23.6c13.2-6.9,26.4-13.7,39.5-20.8
			c20.4-10.9,40.7-22.1,61-33c10.9-5.9,22-11.4,33-17.2c5.1-2.6,10.1-5.4,15.1-8.1c2.5,1.2,2.5,2.7,0.2,4c-1.7,1-3.5,2-5.4,2.5
			c-3.8,1-7.3,2.4-8.7,6.2c2-1.2,4.1-3.4,6.6-0.2c-2.4,3-6.2,3.8-9.2,5.9c-1.3,0.9-2.9,1.4-4.2,2.3c-14.4,7.9-28.9,15.6-43.4,23.4
			c-27.6,14.9-55.5,29.1-83.1,44.1c-10.4,5.6-20.9,11-31.4,16.5c-10,5.3-19.8,11-30.1,15.8c-1.4,0.7-2.8,1.7-4.5,1.5
			c-1.6-1.6-4.1-3-1.7-5.7C125.6,385.4,124.3,387,123.7,384.6z"
        />
        <path
          class="st21"
          d="M333.8,281.2c-2.9-1.7-4.9-0.2-6.8,1.8c-0.5,0.5-1,1.5-1.7,0.8c-0.7-0.6,0.2-1.2,0.4-1.8
			c2.6-6.9,9.7-7,14.9-9.7c2.8-1.4,1.8-2.1,0.8-3.5c9.2-4.9,18.4-9.9,27.5-14.8c2.4-1.3,4.5-3.1,7.4-3.2c0.5,0.5,0.9,1.1,1,1.8
			c0.3,4.2,0.1,8.4,0.1,12.6c0,1.2-0.1,2.5-1,3.5c0,12.5,0,24.9,0,37.4c-2.3,3.8-1.1,8-0.9,12c0.1,2.8-0.3,5.4-0.4,8.1
			c0,1.1-0.2,2.5-1.6,2.5c-1.5,0-1.5-1.4-1.7-2.5c-0.2-1.2-0.2-2.5-0.1-3.7c0.1-2.9-0.4-5.7-1.3-8.5c-2.2,0-1.8,1.7-1.9,3.1
			c0,6.3,0.1,12.7,0,19c0,1.6,0.5,3.6-1.6,4.5c-1.6-1.4-1.1-3.2-1.1-4.9c0-13.8,0-27.7,0-41.5c0-1,0-2,0-3c0-2-0.9-2.5-2.5-1.4
			c-9.7,6.4-20.3,11.1-30.2,17c-2,1.2-4.1,2.4-6.5,2.9c-4.6-2.6-9-2.8-13.1,1c-0.4,0.4-1.2,0.6-1.8,0.5c-3.2-0.7-5.8,0.7-8.5,2
			c-1.2,0.6-2.7,1.8-3.9-0.1c0.2-2.2,1.9-2.8,3.6-3.6c1.5-0.7,3.8-0.4,4-2.9c-0.2-1.5-0.9-3-0.7-4.6c0.1-1.7,1.6-2.3,2.5-3.4
			c1.9,0.5,2.1,1.9,1.7,3.5c-0.2,1-0.8,1.8-0.4,2.9c1.8,1.7,2.5-0.9,3.8-0.9c2.9-1.4,5.4-3.4,8.3-4.7c0.9-0.3,1.8-0.7,2.6-1.1
			c0.5-0.3,1.1-0.5,1.6-0.8c0.8-0.4,1.5-1,2.3-1.5c0.5-0.3,1.1-0.6,1.6-0.9c1.9-0.9,3.8-1.9,5.6-2.8c0.3-0.1,0.6-0.3,0.9-0.4
			c1.7-0.6,3-2.1,4.6-2.8c2.5-1,4.7-2.8,7.3-3.6c1.7-0.6,3.6-0.9,4.8-2.4c2.1-1.8,4.8-2.6,7.1-4c1.2-0.9,1-2,0.4-3.1
			c-1-1.4,0.6-3.5-1.3-4.7c-2.7,1.8-5.6,3.1-8.5,4.4c-0.7,0.3-0.6,1.4-1.4,1.7c-1.2,0.2-2.4,0.5-3.6,0.7c-1,0.2-2.1,0.2-2-1.3
			c6-3.9,12.6-6.8,18.8-10.4c1.8-1,3.1-1.1,2.9,1.6c-0.7,2.6-0.2,5.2-0.3,7.8c-0.5-3.4,1.5-6.9-0.6-10.3c-7.1,3.3-13.8,7.3-20.6,11
			c-0.7,0.4-1,1.1-1.5,1.6c-2,1.8-4.7,2.2-7.1,3.2C335,281.3,334.4,281.7,333.8,281.2z"
        />
        <path
          class="st22"
          d="M102.8,375.6c2.8-3.5,7.1-4.8,10.8-6.9c1.2-0.7,2.3-1.4,3.5-2c10.9-5.6,21.9-11.1,32.7-16.9
			c43.9-23.2,87.8-46.5,131.7-69.7c1.7,0.6,3-0.8,4.5-0.8c1.1,0,2.2,0,3,1c1,1.2-0.1,2-0.6,2.9c-14,7.5-28.1,15-42.1,22.4
			c-42.6,22.6-85.3,45.2-127.9,67.7c-3.3,1.8-6.4,3.7-8.9,6.7c-2.4,0.9-4.2-0.5-6.1-1.5C102.1,377.7,101.9,376.6,102.8,375.6z"
        />
        <path
          class="st23"
          d="M98.2,526c0-11.2-0.1-22.5-0.1-33.7c0-31.3,0-62.6,0.1-93.9c0-5.9-1-12,1.4-17.7c0.4-0.1,0.8-0.6,0.8-0.6
			c2.2,1.8,5.1,0.9,7.5,2.1c-0.4,2-1.8,3.8-1.6,6c-0.5,1.3-0.3,2.7-0.2,4.1c0.3,15.1-0.1,30.1-0.2,45.2c-0.1,12.4,0.2,24.9,0,37.3
			c-0.3,16.7,0.2,33.4,0.2,50c0,11.5,0,22.9-1.3,34.3c-0.1,0.6-0.1,1.2-0.2,1.8c-0.3,1.2-0.5,2.6-2.1,2.7c-1.7,0.1-2.6-1.1-2.8-2.5
			c-0.5-3.8-1.2-7.6-0.8-11.5C99.4,541.9,98.7,533.9,98.2,526z"
        />
        <path
          class="st24"
          d="M216.4,582.8c-3.4-3.1-7.4-5.1-11.4-7.2c-6.4-3.3-12.9-6.7-19.3-10c0.6-1.5,2.1-2.1,3.4-2.8
			c6-3.1,12.1-6.2,18.1-9.5c2.3-1.2,3.4-1.1,3.3,1.8c0,0.8,0.6,1.7,0.5,2.5c-0.7,4,1.2,6.4,4.4,8.1c3.3,1.8,7,2.3,10.4,0.6
			c7.3-3.5,14.3-7.5,21.5-11.2c7-3.6,14.1-7.1,21.2-10.6c-1.1,7.5-7,10.4-12.7,13.4c-11.7,6.3-23.6,12.6-35.3,18.9
			c-1.9,1-4.3,2.1-2.8,5.1C217.9,583.3,217.2,583.3,216.4,582.8z"
        />
        <path
          class="st25"
          d="M123.7,384.6c1.8,2.1,2.9-1.4,4.8-0.4c-4.1,1.9-1.2,4-0.4,6.2c-0.1,0.8-0.7,1.2-1.4,1.4
			c-1.4,0.1-2.5,0.9-2.3,2.3c0.2,2.2-0.7,4.3-0.4,6.3c0,0.6,0.8,0,0.3,0.2c-0.2,0.1-0.2,0-0.3-0.3c-0.1-2.8-0.1-2.8,1.7-2.5
			c0.4,0.8,0.4,1.6,0.4,2.5c0,53,0,105.9,0,158.9c0,1.9,0.6,4-1.2,5.6c-1.5-0.2-2.1-1.2-2.1-2.6c0.4-15.1-3.6-30-2.1-45.1
			c0.7-7.3,0.5-14.6,0.8-21.9c0.1-3.7,0.7-7.6-0.9-11.2c0-31.1,0-62.2-0.1-93.3C120.6,388.1,120.9,385.8,123.7,384.6z"
        />
        <path
          class="st26"
          d="M120.7,580c9.8-5,19.7-10.1,29.5-15.1c32.5-16.8,64.6-34.3,97.2-50.9c19.9-10.1,39.5-20.6,59.2-30.9
			c2-1,4-1.9,6.1-2.8c1.3-0.3,2.8-1,2.3,1.5c-55.3,28.6-110.5,57.4-165.6,86.6c-8.2,4.4-16.5,8.7-24.8,13
			C123.1,582.2,120.9,583.9,120.7,580z"
        />
        <path
          class="st27"
          d="M377.8,249.9c0.4-2,1.3-1.4,2.2-0.4c1.2,1.2,0.8,2.8,0.8,4.3c0,35.5-0.1,71,0.1,106.5
			c0,6.5,0.6,13.1,0.3,19.5c-0.4,9.8-0.2,19.6-0.4,29.4c-0.2,10.2,0,20.4-0.1,30.6c0,1.3,0.5,2.8-0.8,3.9c-1.1-0.8-2.1-1.7-2.3-3.1
			c0.3-10.6-0.1-21.1,0.1-31.7c0.1-6.5-0.2-12.9-0.3-19.4c-0.3-24.4,0.4-48.8,0.3-73.2c0-11.6-0.5-23.1,0-34.7
			c0.3-8.7,0-17.4-0.6-26.1C377,253.6,377.4,251.7,377.8,249.9z"
        />
        <path
          class="st28"
          d="M98.2,526c0.1,0.1,0.2,0.2,0.2,0.3c0.3,8,2.2,16,0.7,24c-0.8,4.1,1.5,7.8,1.5,11.7c0,0.7,0.8,1.3,1.6,1.2
			c1-0.1,1.1-0.8,1.1-1.6c0.2-5.9,1.2-11.8,1.6-17.7c0.9-14.8,0.8-29.6,0.5-44.4c-0.2-8.1,0.5-16.3-0.6-24.4
			c-0.3-2.6,0.9-5.1,0.8-7.8c-0.3-5.1,0.2-10.1-0.7-15.2c-0.6-3.4,0.2-6.9,0.5-10.4c0.4-4.5-0.6-9.2-0.4-13.7
			c0.5-12.8,0.4-25.6,0.6-38.4c0-0.4,0.4-0.9,0.6-1.3c0,43,0.1,86,0.1,129c0,24.8,0.1,49.6,0.1,74.4c-0.2,1.1,0.3,2.8-2,1.5
			c-1.8-1-3.3-2.5-4.9-3.7c-1-2.6-1.3-5.3-1.3-8C98.2,563.1,98.3,544.5,98.2,526z"
        />
        <path
          class="st29"
          d="M288.2,283.1c0.8-2.4,0.4-3.6-2.6-2.9c-1.3,0.3-2.9,1.4-4.3-0.2c4.2-2.3,8.5-4.7,12.7-7
			c15.9-8.1,31.6-16.5,47.2-25.4c1-0.4,1.9-0.8,2.9-1.2c1.4,3.2-1.2,3.8-3.1,4.5c-3.8,1.5-3.9,1.4-2,4.8
			c-12.5,6.8-25,13.6-37.6,20.4C297,278.5,292.9,281.3,288.2,283.1z"
        />
        <path
          class="st30"
          d="M339,255.8c-0.4-1.3-3.2,0.2-2.5-2.3c0.5-1.8,1.2-3.1,3.4-3.2c2.3-0.1,4.1-1.1,4.3-3.8
			c11.2-6,22.5-12,33.7-18.1c4.8-2.6,9-1.9,12.8,2c-0.1,0.3-0.3,0.6-0.4,0.9c-4.2-2-7.4,0.5-10.7,2.4
			C366.2,241.5,352.7,248.8,339,255.8z"
        />
        <path
          class="st31"
          d="M377.8,249.9c0.3,3.5,0.7,7,0.7,10.5c0,10.4,0,20.9-0.5,31.3c-0.7,13.2,1.2,26.4,0.5,39.5
			c-1,19.4-0.8,38.8-0.5,58.2c0.1,6.6,0.8,13.1,0.5,19.7c-0.5,11,0.3,22.1,0.2,33.1c0.5,1.4-0.1,2.1-1.5,2.2
			c-1.9-1.4-0.6-3.4-0.6-4.8c1-35.9,0.1-71.8,0.4-107.6c0.1-21.2,0-42.4,0-63.6c-0.5-4.3-0.1-8.6-0.2-12.9c0-1.2,0.3-2.5-0.4-3.6
			c0-0.5-0.1-1-0.1-1.5C376.8,250.4,377.3,250.2,377.8,249.9z"
        />
        <path
          class="st32"
          d="M377.3,444.6c0.7-0.6,1.3-1.3,1.5-2.2c2.1-1.1,0.9,0.9,1.3,1.4c-1.4,3.4-3.8,5.8-7,7.5
			c-12.4,6.6-24.8,13.2-37.3,19.8c-6.9,3.6-13.6,7.7-20.8,10.8c-0.1-1.5-1.2-1.4-2.3-1.5c9.6-5.1,19.2-10.2,28.8-15.4
			c10.6-5.9,21.7-10.9,32.2-17.1C375.1,446.9,376.5,446.1,377.3,444.6z"
        />
        <path
          class="st33"
          d="M107.9,382.3c-2.9,0.6-5.7,0.1-8.2-1.5c0.5-2,1.3-3.9,3.1-5.2c-0.6,2.1,1.2,2.3,2.4,2.8
			c1.3,0.6,2.7,1,4.1,1.5C108.8,380.7,108.4,381.5,107.9,382.3z"
        />
        <path
          class="st34"
          d="M253,520.1c-0.9,2.5-3.5,3.8-4.2,6.4c-1.3-2.6-0.5-3.9,4.3-7.2c0.2,0.2,0.3,0.4,0.2,0.5
			C253.2,520,253.1,520.1,253,520.1z"
        />
        <path
          class="st35"
          d="M253,520.1c0-0.3,0-0.5,0-0.8c3.2-2.4,3.2-2.4,6.2-1.5C256.9,518.7,255,519.4,253,520.1z"
        />
        <path
          class="st36"
          d="M216.4,582.8c0.6,0,1.2-0.2,1.5-0.8c0.3,0,0.6,0,0.9,0c2.7,0.9,5.2,2.4,7.7,3.7c7.5,3.9,14.4,1.8,21.1-2
			c3.5-2,7.1-4,11.2-4.7c0.8,0.1,1.7,0.1,2.7,0.2c-1.9,2.1-4.2,3-6.6,3.8c-1.1,0.4-2.3,1-0.4,2c-3.5,4.4-8.7,6-13.5,8.2
			c-0.8,0.5-1.5,1-2.2,1.5c-3.1-0.2-6.3,0.7-9-1.5c-0.1,0.5,0-0.7,0.3-0.3c0.1,0.2,0.4-0.3,0.2-0.3c-1.2-0.2-2.4,0-3.5-0.8
			c-3.1,0.1-5-2.4-7.7-3.2C216.2,587.7,216.7,585,216.4,582.8z"
        />
        <path
          class="st37"
          d="M258.8,579c-4.3,2.1-8.7,4-12.8,6.5c-10.1,6-18.8,2.4-27.2-3.5c1.2-0.8,2.1,0.1,3,0.7
			c9.4,5.9,18.3,4.4,27.7-0.8c15.7-8.8,31.8-16.9,47.8-25.2c0.6-0.3,1.4-0.6,2-0.8c0.9-0.3,1.7-0.5,1.7,0.9
			C286.8,564.1,272.6,571.1,258.8,579z"
        />
        <path
          class="st38"
          d="M300.9,556.7c-0.3-0.7-1-0.8-1.7-0.9c3-1.9,6.2-3.4,9.7-4.5c1.1-0.4,2.4-1,3.1,0.8
			C308.1,553.2,304.9,556,300.9,556.7z"
        />
        <path
          class="st16"
          d="M226.9,591.8c1.4,0,2.7-0.2,4,0.5c0.4,0.3,1,0.5,0.1,0.9c-0.3,0.1-0.7,0.1-1.1,0.1
			C228.5,593.4,227.4,593.2,226.9,591.8z"
        />
        <path
          class="st39"
          d="M185.7,528.2c-1.2,1.4-2.7,2.6,0.1,4.1c1,0.6-0.3,1-0.9,1.2c-0.7,1.1-1.8,1.7-2.9,2.2
			c-16.3,8.4-32.8,16.5-48.8,25.5c-1.2,0.7-2.4,1.4-3.7,1.7c-1.8,0.4-3.2,0-3.6-2.1c-0.1-9.1-0.1-18.1,0-27.2c1.7-2.8,2.7-6,5.4-8.1
			c1.9,0.6,0.8,3.3,2.6,4c0.8,0.7,0.5,1.6,0.5,2.5c0.1,2,1.1,2.7,2.9,1.9c0.9-0.4,1.9-1,2.6,0.1c0.9,1.3-0.1,2-0.9,2.8
			c-0.8,0.6-1.7,1-2.5,1.7c-0.6,0.8-1.8,1.6,0.3,2c0.8,0,1.5,0.3,2.2-0.1c0.3-0.2,0.5-0.3,0.8-0.5c3.3-3.3,4.4-3.3,6-0.3
			c0,0.3-0.1,0.6-0.3,1c-1,1.1,0.1,2.4-0.2,3.6c-0.3,0.4-0.8,0.6-1.2,0.8c-1.3,0.6-2.5,1.4-3.9,0.4c-1.3,0.5-0.6,1.7-1,2.5
			c-0.1,0.3-0.5,0.6-0.2,1c0.2-0.3,0.5-0.5,0.8-0.8c1.6-1.9,4.5-0.8,6-2.9c1.4-1.2,0.9-2.8,0.6-4.2c-0.6-2.9-0.3-5.7,0.9-8.4
			c1.3-1.5,2.8-1.4,4.4-0.5c0.5,0.4,1,1.2,1.5,0c0.4-0.8,1.2-1.4,1.4-2.2c0.5-2.7,2.3-2.1,4-1.7c0.3-0.7,0.4-1.3-0.7-1
			c-2.3-0.5-3.7,1.7-5.8,1.7c-4.4-0.8-5.1-2.4-2.9-6.2c0.7-0.8,1.6-1.3,2.6-1.7c0.8-0.2,1.6-0.4,2.1-1c1.3-1.8,4.8-1.7,4.2-5
			c-0.4-1-1.5-1.8-1.2-3.1c1.3-2.4,4.1-2.9,5.9-4.6c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.4,1.8-0.8,2.7-1.3c0.5-0.2,0.9-0.4,1.4-0.5
			c1.1-0.4,2.2-1,3.5-0.8c1.6,0.3,2.7,1.1,3.2,2.7c0.1,0.5,0.2,1.1,0.6,1.3c5.3,2.9,2,7.2,1.8,11c-0.1,1-0.7,1.8-1.9,1.9
			c-0.4,0.4-0.6-1.4-0.9-0.2c-0.2,1-0.1,2.1,0.1,3.2c0.1,0.7,0.4,0.7,0.6,0c0.1-0.2,0.1-0.5,0.1-0.7c0.4-1.7,1.7-2,3.2-2.1
			c1.4,0.8,2.5,0,3.6-0.7c1.4,0.1,2.4,0.8,3.2,1.9C185.5,524.2,184.2,526.4,185.7,528.2z"
        />
        <path
          class="st40"
          d="M366.8,340.4c1.2-1.6,0.6-3.4,0.6-5c0.1-7.8,0-15.6,0-23.4c1.6-0.4,1.2,1.1,1.9,1.5c0.9-0.6-0.2-2,0.9-2.5
			c3.3,5.2,2.1,11.2,2.6,17c0.4,0,0.7,0,1.1,0c0.2-3.1,0.9-6.2,0.5-9.2c-0.4-3.5-0.1-6.9-0.3-10.4c-0.1-1.5,0.7-2.3,2.2-2.5
			c0,10.2,0,20.4,0,30.7c0,15.9,0,31.8,0,47.7c0,1.7,0.3,3.5-1.5,4.7c-0.5,0.3-0.7,1.3-0.7,2c0,6.4-1.1,13,0.2,19
			c1.7,7.3,0.6,14.5,1.2,21.7c0.1,0.9,0,1.8-0.1,2.6c-0.1,0.9-0.6,1.7-1.7,1.7c-1.2,0-1.1-1.1-1.2-1.9c-0.3-2.5,0.7-5.2-1.2-8.2
			c-1.7,3.7-2.5,7-0.3,10.2c1.2,1.8,0.2,2.9-0.7,3.9c-1.3,1.5-2.1-0.1-3-0.9c-2-2.1-1.8-4.8-1.8-7.4c0-16.4,0-32.9,0-49.3
			c0-1.4,0.1-2.7-0.1-4.1c-1.2-1.8-2.4-0.1-3.5,0c-1.4,0.2-3,0.6-3.6-1c-0.6-1.8,1.3-2.1,2.4-2.8c3.2-2,5.3-7,4.5-10.7
			c-0.2-1.2-1.1-1.1-1.9-1.3c-1.9-0.4-3.3-1.2-2.5-3.5c0.8-1.3,2.1-2,3.5-2.5c2-0.8,2.4-2,1.4-3.9c-0.2-2.8-0.1-5.7-0.1-8.5
			C365.7,342.8,365.5,341.4,366.8,340.4z"
        />
        <path
          class="st41"
          d="M174.3,520.7c1.5-0.4,1.8-1.6,1.6-2.9c-0.2-1.5-0.7-2.8,1-3.8c0.8-0.5,0.8-2.1,0.3-2.3
			c-3.7-1.2-3.2-6-6.5-7.4c-0.1-0.1-0.2-0.3-0.2-0.4c0.1-0.3,0.2-0.6,0.5-0.8c0.7-0.5,1.4-0.7,2.2-0.7c0.9,0.1,1.5-0.5,2.1-1
			c2.8-2,5.4-4.1,8.6-5.3c0.6-0.2,1.2-0.6,0.3-1.3c-0.6-0.4-1.8-0.7-1.5-1.3c1.2-2.2-1.1-2.7-1.8-4c0.6-1.4,1.7-2.1,2.9-2.7
			c0.9-0.1,1.5-0.6,1.5-1.4c-0.3-4.8,3.4-8,4.7-12.2c0.2-0.5,0.4-1,0.8-1.4c3.6-2.3,7.5-4,11.3-5.7c1.1-0.5,2.1-0.6,3.3-0.2
			c2.2,1,2.9,2.9,2.9,5.2c0.1,16-0.1,31.9,0.1,47.9c0,1-0.1,2-1.1,2.6c-1.2-0.9-0.6-2.3-0.8-3.5c-0.2-2-0.9-4.1-2.8-4.4
			c-3.6-0.6-3.7-3.2-3.9-5.7c-0.2-2.2-0.6-4.2-1.9-5.9c-1.5-1.9-3-3.6-5.7-1.4c-1.6,1.3-3.8,0.9-5.8,0.7c-1.4-0.2-2.3-0.1-2.3,1.4
			c-0.1,2.7-1.2,5.3-0.8,8.1c0.3,2.2,1.4,3.6,3.6,3.2c1.9-0.4,3.3,0.3,4.1,1.7c0.9,1.6-0.9,2.7-1.7,3.8c-1,1.2-2.5,1.9-3.8,2.9
			c-0.6-0.7-1.1-0.4-1.6,0c-1.2,0-1.8-1-2.7-1.5c-1.8-2.8-2.2,0.5-3.3,0.7c-0.8,0.9-2.7,0.3-2.8,2.1
			C173.1,523,172.9,522.5,174.3,520.7z"
        />
        <path
          class="st42"
          d="M126.7,532.7c0,9.7,0,19.3,0,29c0.7,1.4,0.8,2.7,0,4.1c-1.4,1.2-1.5,0.1-1.7-0.9c0-24.9,0.1-49.9,0.1-74.8
			c0.1-30.2,0.1-60.4,0.1-90.5c2,0.4,1.5,2,1.6,3.2c0.2,1.7,0,3.5,0.6,5.1c0.5,6.1,0.5,12.1-0.2,18.2c-0.1,0.4-0.2,0.7-0.2,1.1
			c-0.1,25.1-0.2,50.3,0.1,75.5c0.4,1.8,0.2,3.6,0.6,5.4c0.3,6.8,0.1,13.6,0.2,20.4C127.8,530,127.4,531.4,126.7,532.7z"
        />
        <path
          class="st43"
          d="M313.5,469.7c-5.6,3.1-11.2,6.2-16.9,9.3c-1.2,0.7-2.8,2.7-3.7-0.6c-0.4-1.5-1.7-0.2-2.4,0.2
			c-14.2,7.7-28.4,15.5-42.6,23.2c-3.1,1.7-6.4,3.3-9.6,4.9c-2,0.6-1.9-0.4-1.5-1.8c1.1-1.6,2.9-2.3,4.6-3.1
			c15.5-7.6,30.3-16.7,45.8-24.2c2.8-1.1,4.1-3.2,4.2-6.2c0.9-3.6-0.7-7.5,1.1-11.1c2.9,0.4,1.6,2.7,2,4.3c-0.1,2.4-0.2,4.9-0.1,7.3
			c0.1,2.5,1.3,3.9,4,3.3c4-1.4,7.3-3.9,11-5.8c0.6-0.4,1.3-0.7,2-0.9C312.3,468.5,313.4,468.2,313.5,469.7z"
        />
        <path
          class="st44"
          d="M126.7,565.8c0-1.4,0-2.7,0-4.1c1.7,2.1,3.3,0.2,4.6-0.4c5.4-2.8,10.7-5.9,16.1-8.7
			c12.5-6.5,24.8-13.2,37.5-19.1c-0.1,2.9-2.7,3.4-4.5,4.3C162.5,547.2,144.6,556.5,126.7,565.8z"
        />
        <path
          class="st45"
          d="M204.3,466c-4.4,2.1-8.7,4.1-13.1,6.2c-1.5,0.9-2.8,3-4.9,0.9c0.8-2.5,3.4-2.6,5.2-3.9
			c6.1-2.6,11.9-5.6,17.4-9.2c0.9-0.3,1.7-0.1,2.1,0.8c0.6,1.7,0.4,3.5,0.4,5.2c0,15.8,0,31.6,0,47.5c0,1.9-0.2,3.9,2.3,4.5
			c0.5,0.2,0.6,0.6,0.4,1.1c-1.5,1.2-3,2.5-5.1,2.3c-1.1-1.7-0.8-3.6-0.8-5.4c0-15.3,0-30.6,0-45.9
			C208.2,467.4,207.9,465.2,204.3,466z"
        />
        <path
          class="st46"
          d="M204.3,466c1.2-1.1,2.6-1.8,4.7-2.5c0,19.5,0,38.7,0,57.9c-0.5,0-1,0-1.6,0c0-17.1-0.1-34.2,0-51.3
			C207.4,467.8,205.8,467.1,204.3,466z"
        />
        <path
          class="st47"
          d="M313.5,469.7c-0.6-0.8-1.5-0.5-2.4-0.7c0-1.6,1.4-2.1,2.4-2.8c8.5-4.6,16.9-9.3,25.5-13.7
			c2.2-1.1,4.4-2.2,6.8-2.8c0.9,0.1,2.1-0.1,2.1,1.4c-5.9,4.3-12.8,7-19.1,10.5C323.8,464.4,318.6,467,313.5,469.7z"
        />
        <path
          class="st48"
          d="M365.9,376.4c1.7,0.2,1,1.4,1,2.3c0,17.4-0.1,34.9-0.1,52.3c0,2.7,0.4,5.5,0.7,8.2c-0.5,0.7-1.1,1.4-1.6,2.1
			c-0.8,0.2-1.1-0.4-1.3-1c-0.4-1.2-0.3-2.5-0.4-3.8c0-0.5,0-0.9,0-1.4c0.2-13.2-0.3-26.5,0.2-39.7c1.1-5.1,0.4-10.3,0.5-15.5
			C364.8,378.6,364.2,377.2,365.9,376.4z"
        />
        <path
          class="st49"
          d="M214.1,519.1c0-0.2,0-0.5,0-0.7c0.3-2.1,2.4-2.3,3.8-3.1c2-1.2,4.1-2.4,5.6-4.2c3.8-2.6,8.1-4.1,12-6.4
			c0.5-0.3,1.2-0.2,1.8,0.1c-0.3,0.9-0.4,1.7,0.9,1.7C230.2,510.9,222.5,515.7,214.1,519.1z"
        />
        <path
          class="st50"
          d="M365.1,440.6c0.3,0.2,0.5,0.5,0.8,0.7c-1.3,2.8-4.3,3.1-6.6,4.3c-3.7,1.9-7.1,4.5-11.3,5.5
			c-0.5-0.9-1.4-0.6-2.2-0.7c-0.2-1,0.5-1.3,1.1-1.6c5.3-2.8,11-4.7,16-7.9C363.5,440.5,364.3,440.3,365.1,440.6z"
        />
        <path
          class="st51"
          d="M184,522.2c0.3-0.9-0.1-2,0.8-2.7c1.5,0.5,0.5,1.9,0.9,2.7c0,2,0,4,0,5.9C182.2,527,185,524.1,184,522.2z"
        />
        <path
          class="st40"
          d="M376.4,252.1c1.6,5.4,0.4,11,0.6,16.4c-0.2,0-0.4,0-0.6,0C376.4,263.1,376.4,257.6,376.4,252.1z"
        />
        <path
          class="st52"
          d="M126.5,407.8c-1.7-2.5,0.4-5.7-1.3-8.2c-0.1-0.7-0.2-1.5-0.2-2.2c-1.1-2.5-1-4.6,1.7-6
			c0.5-0.3,1-0.6,1.5-0.9c12.5-5.6,24.2-12.6,36.4-18.9c26.3-13.8,52.6-27.7,78.8-41.6c25.7-13.6,51.3-27.4,77-41
			c0.3-0.4,0.6-0.4,0.9,0c0.1,1.5-1.1,2.1-2.2,2.7c-13.2,7.1-26.2,14.5-39.4,21.6c-0.6,0.3-1.3,0.6-1.9,0.9
			c-1.5,0.8-2.9,1.9-4.5,2.6c-9.7,5.3-19.6,10.1-29.4,15.3c-11.8,6.2-23.4,12.8-35.4,18.6c-3.6,1.8-6.9,3.9-10.5,5.7
			c-22.1,11.9-44.4,23.6-66.6,35.2c-2.8,1.5-3.8,3.4-3.8,6.5C127.5,401.2,128.6,404.7,126.5,407.8z"
        />
        <path
          class="st53"
          d="M321.3,288.9c-0.3,0-0.7,0-1-0.1c4.5-2.5,9-5,13.5-7.6c0.5-0.3,1-0.5,1.5-0.8c1.6,1.6-0.1,2.1-1,2.6
			c-3.7,2.2-7.4,4.4-11.4,6.1C322.3,289.3,321.8,289.2,321.3,288.9z"
        />
        <path
          class="st54"
          d="M197.6,355.5c18.2-9.6,36.4-19.3,54.6-28.9c7-3.7,14-7.2,21-10.8c2.1,0.4,3.2-1.2,4.5-2.3
			c0.5-0.3,1-0.6,1.4-0.8c4.1-0.5,6.9-3.7,10.5-5.4c2.7-1.2,5.4-2.5,7.9-4.1c0.9-0.6,1.5-0.7,2.1,0.3c0.1,1.4-0.2,2.8,0.4,4.1
			c0.7,1.8-0.7,3.4-0.6,5.2c2.3,1,4-0.7,5.6-1.7c1.1-0.7,2.4-1.4,3.4-1.1c3.1,1,5.7-0.1,7.7-2.2c3.3-3.4,6-1.2,8.8,0.5
			c0.3,0.2,0.6,0.5,0.9,0.7c-0.9,2.5-3.3,3.3-5.3,4.2c-5.4,2.5-10.5,5.5-15.8,8.2c-4.2,2.1-8.7,4.1-12.5,7.1
			c-1.8,0.6-1.3,2.2-1.4,3.4c-0.2,1.9,0.3,3.9-0.6,5.8c-2.2,1.1-2.5-0.8-2.8-2c-1.1-3.6,0.4-10.3,4-13.2c1.1-0.9,2-1.8,3-2.8
			c1.6-1.5,1.8-3.1,0.4-4.9c-1.3-1.8-2.8-2.7-5-1.4c-2.2,1.3-4.8,1-7,2.2c-2.3,1.3-4.1,2.8-4.1,5.7c0,1.6-0.5,3.2-1.6,4.5
			c-0.2,0.3-0.5,0.5-0.8,0.7c-1.4,1-2.2-1-3.5-0.7c0.9,1.8-0.3,2.7-1.5,3.6c-0.9,0.4-1.8,0.5-2.7,0.2c-0.3-0.1-0.6-0.4-0.8-0.7
			c-0.2-2.3-0.9-3.4-3.3-1.7c-1.1,0.8-2.2,1.6-3.6,1.6c-2,0-1.6,1.4-1.5,2.6c-1.1,1.7-2.8,2.5-4.7,3c-0.9,0.2-1.7,0.6-1.6,1.7
			c0,0.5-0.1,1-0.2,1.4c-0.6,1-1.7,0.9-2.6,1.3c-2.8,1.2-4.4,1.1-3.1-2.6c0.4-1-0.6-0.9-1.1-0.8c-0.9,0.3,0.3,0.9-0.1,1.3
			c-1.1,2.4-3.3,3-5.6,3.5c-2.1,1.8-4.1,3.6-7,4.3c-1.7,0.4-2,2-1.9,3.6c-1.6,2.9-4.6,2.6-7.1,3.6c-1.4-0.1-1.5-1-1.1-2.1
			c0.4-1,1.6-1.6,1.6-2.8c-1.4,0.2-1.4,0.2-3.6,6.3c-1.8,1.7-5.2,2-4.4,5.7c0,0.9,0.2,1.7,0.2,2.6c0,0.4-0.2,0.7-0.4,1
			c-1.8,1.6-4.1,1.3-6.3,1.5c-2-0.9-1.1-4.9-4.5-4.2c-2.4,0.3-4.5,1.3-6.1,3.1c-1.6,0.9-3.2,2.1-5,0.4
			C194.8,360.5,195.9,357.9,197.6,355.5z"
        />
        <path
          class="st55"
          d="M291.8,327.7c11.3-6.2,22.6-12.4,33.9-18.6c12.4-6.3,24.7-12.9,36.6-20c1.2-0.7,2.5-1.9,3.7-1.3
			c1.5,0.7,0.6,2.5,0.6,3.7c0.1,16.3,0.1,32.6,0.2,48.9c0,4.5,0,9-0.1,13.5c-0.6,0.4-1.3,0.4-1.8-0.1c-1.1-1.8-0.8-3.9-0.8-5.8
			c0-17,0-34,0-51.1c0-0.9,0-1.7,0-2.6c0-2.1-0.9-2.3-2.5-1.4c-1,0.6-2,1-2.9,1.7c-19.3,9.9-38.3,20.5-57.4,30.8
			c-2.5,1.4-5.7,1.5-7,4.6c1,7.7-0.7,15.3-0.5,23c0.3,10.3,0.3,20.6,0.1,30.9c0,1.8,0.3,3.8-1.2,5.3c-2.4-0.9-1.7-3-1.7-4.7
			c-0.1-10.9,0-21.8-0.1-32.7c-0.1-6.5,0-13,0-19.4C290.8,330.6,290.7,329,291.8,327.7z"
        />
        <path
          class="st56"
          d="M322.4,288.7c4.4-2.5,8.7-4.9,13.1-7.4c0.1,0,0.2-0.2,0.1-0.3c-0.1-0.2-0.2-0.4-0.4-0.6
			c1.9-1.8,4.7-1.1,6.7-2.6c0.6-0.6,1.2-1.5,2.3-0.9c2,1.5,3.9-1.9,5.9-0.2c1.6,1.4,0,3,0.4,4.2c-0.1,0.7,0.7,0.3,0.2,0.4
			c-0.1,0-0.1-0.1-0.1-0.2c0-2,2-4.1-0.4-5.9c3.3-1.7,6.5-3.4,9.8-5.1c1.1,1.7,0.9,3.3-0.2,4.8c-1.8,2-3.8,3.6-5.2,4.6
			c1.1-0.9,3.2-2.2,4.7-4.2c1-0.5,1.6,0.2,2.3,0.8c1.4,1.3,1,2.5-0.3,3.6c-0.2-0.3-0.4-0.6-0.5-1c-2,1.9-5.5,1.9-6.9,4.8
			c-1.1,2.1-3.2,2-5.2,2.3c-0.4-0.3-0.5-0.8-0.8-1.2c-0.7-1.3,0.9-4-1.9-3.9c-3.2,0.2-6.2,4-5.6,6.5c0.2,0.8,0.7,1.6,0.9,2.4
			c-1.6,0.8-2.5,2.8-4.6,2.8c-1.1-1.8,0.2-4.4-1.9-5.9c-0.7,0-1.4-0.4-2,0.2c0.3,0.2,0.7,0.3,1,0.6c0.5,2.4-0.6,3.5-3,3.4
			c-0.3,0-0.6,0-1-0.1c-0.7-0.2-0.9-1.3-1.8-1.3c-1.5,0.3-3,0.5-4.4,0.1C323.2,289.4,322.7,289.2,322.4,288.7z"
        />
        <path
          class="st57"
          d="M324.1,288.8c1.5,0,3,0,4.5,0c0.4,2.5,1.6,5-0.1,7.4c-0.4,1-1.1,1.5-2.2,1.5c-2.4-1.2-0.2-3.1-0.9-4.6
			c-0.5,1.6-0.3,3.5-0.7,5.3c-0.7,0.5-1.5,1-2.2,1.6c-0.9-0.9-0.9-2-0.9-3.2c-0.9,0.7-1.2,1.8-1.7,2.6c-0.5,0.8-1,1.9-2.2,1.5
			c-0.9-0.3-1.1-1.2-1.2-2.1c-0.2-1.7-0.2-3.4,0.2-5.1C318.4,290.9,321.4,290.1,324.1,288.8z"
        />
        <path
          class="st58"
          d="M341.3,289.7c-3-2.5-3-5.4,0-7.3c0.2-0.1,0.4-0.2,0.7-0.3c2.1-0.9,3.7-4,6-2.5c1.9,1.2,0.4,4.1,0.7,6.3
			C346.3,287.2,344,288.8,341.3,289.7z"
        />
        <path
          class="st59"
          d="M299.4,312.8c-1.7-1.8,0.8-3.5,0-5.3c-0.8-1.7,0.3-2.4,1.5-3c1-0.5,2.1-0.8,1.9-2.2c0.3-3.2,2.6-3.8,5.2-4
			c0.1,0.1,0.3,0.2,0.4,0.3c0.6,1.6-1.6,1.6-1.6,2.9c-1.4,1.9,0,3.5,0.7,5.2c0.2,3.2-2.8,3-4.4,3.5
			C301.5,310.8,300.4,311.5,299.4,312.8z"
        />
        <path
          class="st60"
          d="M317.3,293.3c0,2.3,0,4.6,0,7.9c2.2-2.4,2.3-5.2,5.3-5.9c0,1.6,0,3.2,0,4.7c-3,1.5-6,3.1-9,4.6
			c-3.3-2-3.3-3.8,0-5.8c0.2-0.1,0-1.1,0.2-1.7C314.8,295.7,314.7,293.3,317.3,293.3z"
        />
        <path
          class="st61"
          d="M344.3,276.9c-0.8,0.3-1.5,0.6-2.3,0.9c1-2.3,3.4-2.6,5.3-3.6c5.9-3.3,11.9-6.4,17.9-9.5
			c2.1,0.9,1.2,2.5,0.7,3.8c-0.1,0.4-0.8,0-0.7-0.6c0.1-1.9-0.4-2-2-1.1C356.9,270.2,350.5,273.5,344.3,276.9z"
        />
        <path
          class="st62"
          d="M365.1,267.8c1.4-1.1,0.9-2.1,0-3.2c0.6-0.5,1.4-0.5,1.4,0.3c0.1,3.7,0.1,7.5-0.1,11.2c0,1-0.7,1.8-2.2,1.9
			C365,274.6,363.6,271.1,365.1,267.8z"
        />
        <path
          class="st57"
          d="M314.3,297c0,1.2,0,2.4,0,3.7c-1.2,0.2-1-1.3-2.1-1.5c-0.2,2-0.6,4,1.3,5.4c-0.9,0.7-1.7,1.4-2.6,2
			c-2.1,1.4-1.8-0.3-1.9-1.5c0.8-2,1.8-4,0.6-6.2c-0.4-0.7,0.1-1.4,0.6-2C311.7,296.5,313,296.5,314.3,297z"
        />
        <path
          class="st63"
          d="M330,290.3c0.2,0,0.5,0,0.7,0c0.5,2.8,1.9-0.1,2.7,0.4c1,0.6,1.9-1.4,2.9-0.1c0.2,0.7,0.1,1.4-0.4,1.9
			c-1.7,1.5-3.8,2.4-5.9,3C328,293.8,329.1,292,330,290.3z"
        />
        <path
          class="st64"
          d="M324.8,298.5c-1.6-2.8-0.2-5.2,0.7-7.7c1.3,2.3,0.4,4.7,0.8,7C326,298.4,325.4,298.4,324.8,298.5z"
        />
        <path
          class="st65"
          d="M353.9,283.5c-2.3-1.1,0.1-1.7,0.4-1.9c2.5-1.1,4.8-2.3,6.4-4.6c1.1,1,0.6,1.9,0.5,2.7
			C358.7,280.7,356.2,281.8,353.9,283.5z"
        />
        <path
          class="st60"
          d="M310.5,297c-0.1,1.1-0.5,2.2,0,3.2c1.1,2.1,0.4,3.7-1.5,4.9c-0.5-0.8-1.3-1.8-0.4-2.6c1.7-1.6,0.8-2.7-0.3-4
			l-0.2-0.1C308.3,296.9,309,296.3,310.5,297z"
        />
        <path
          class="st64"
          d="M330,290.3c0.6,1.8-1.3,3.6,0.1,5.3c-0.5,0.2-1.1,0.5-1.6,0.7c0.1-2.5-0.9-5,0.1-7.4
			C331.1,287.3,328.9,290.4,330,290.3z"
        />
        <path
          class="st66"
          d="M350.2,275.2c1,0.9,2,1.8,3.2,2.9c-2.5,0.7-2.5,2.8-3.3,4.8c0-2,0-4,0-6.1
			C350.2,276.2,349.2,275.7,350.2,275.2z"
        />
        <path
          class="st65"
          d="M361.5,276.1c-0.9,0.3-1.5-0.3-2.2-0.7l-0.1-0.2c0.5-1.7,0.9-3.3,0.8-5.1c0.7-0.3,1.4-0.7,1.4,0.6
			C361.4,272.5,361.5,274.3,361.5,276.1z"
        />
        <path
          class="st67"
          d="M307.5,306.7c-3.3-0.1-0.3-3.4-2.2-4.5c-0.5-0.3,0.9-0.5,1.5-0.6C307.1,303.2,307.3,305,307.5,306.7z"
        />
        <path
          class="st68"
          d="M336,292.5c0-0.8-0.1-1.5-0.1-2.3c-1-1.2-0.7-2.6-0.6-4c0-0.2,0.1-0.3,0.1-0.5c0.5-0.4,1.3-0.8,1.3,0
			c0,2.2,1.1,4.5,0.1,6.7C336.5,292.5,336.2,292.5,336,292.5z"
        />
        <path
          class="st20"
          d="M126.7,391.3c-2.1,1.6-1.3,4-1.7,6c-1.3,1.2,0.1,3.1-1.1,4.5c-0.7-2.6-0.1-5.2-0.5-7.8
			C123.1,392.6,123.8,390.1,126.7,391.3z"
        />
        <path
          class="st69"
          d="M126.7,532.7c0-8.5,0-16.9,0-25.3c1.2-1.8,3.2-2.8,5.1-3.6c9.5-4.2,18.2-9.9,27.6-14.3
			c8.9-3.9,17-9.2,25.6-13.7c1.9-1,3.6-2.7,6-2.2c-1.6,2.5-2,5.6-4.3,7.9c-1,1,0.2,3.4-0.4,5.2c-0.6,1.6-1.2,1.2-2.1,0.5
			c-0.8-0.3-0.3-1.9-1.7-1.5c-1.2,0.4-2.4,0.8-3.5,1.2c-0.3,0.2-1.1,0.5-0.9,0.8c0.4,0.5,1-0.1,1.5-0.1c1.1,0,1.5,0.8,1.5,1.8
			c1.5,0.9,4.9,0.7,2.2,4c-0.2,0.3,0.9,0.3,1.4,0.4c0.9,0.8,0.4,2.1-0.2,2.1c-2.4,0.1-4.5,4.1-7.2,0.5c-0.7-0.9-1.5,0.2-2.3,0.5
			c-4,1.5-7.4,4-11.3,5.6c-0.8,0.5-1.5,1-1.6,2c-0.2,0.9,0,1.9-0.4,2.7c-0.8,0.8-1.5,0.4-2.1-0.3c-0.5-0.9-1.2-1-2.2-0.9
			c-1.3,0.1-2.4-0.1-2.9-1.5c0-1.1,0.8-2.1,0.1-3c0.1-0.7-0.6-0.4-0.3-0.2c0.5,0.4,0.2,1.1-0.1,1.6c-2.8,0-5.6-2-8.4,0
			c-1.3,0.9-3.4,0.9-2.3,3.6c0.6,1.6-1.3,2-2.7,2c-1.3-1.4-1.9-0.4-2.5,0.8c-0.3,0.2-0.6,0.4-0.9,0.4c-1-0.2-1.5-0.8-1.7-1.7
			c0-0.3,0.2-0.9,0.1-1c-0.6-0.3-0.8,0.5-1.3,0.5c-2.6,0.6-3.1,2.6-1.7,6.2c0.6,1.4,0.6,3.5,3.1,2.2c0.5,0.1,0.9,0.4,1.2,0.8
			c0,1.8-1.5,2.5-2.7,3.4c-0.3,0.2-0.5,0.4-0.8,0.5c-1.9,1.2-0.2,3.8-2,5.1c-2.3,0.4-1.2,2.7-2.2,3.9
			C128.7,530.9,127.9,532,126.7,532.7z"
        />
        <path
          class="st70"
          d="M151.9,532.7c-1.5-0.4-3-1.2-4.5,0c-0.7,0.6-0.7,2.1-1.4,2c-1.1-0.1-1.3-0.3-0.5,0.8
			c1.1,1.5,1.7,3.2-0.1,4.7c-0.3-2.8-1.9-2.6-3.5-1.2c-0.7,0.6-0.9,1.7-2,1.8c-0.9-1.2-0.5-2.8-1.2-4c-0.1-0.2-0.1-0.3-0.1-0.5
			c0.3-0.7,1.9-0.8,1.2-2c-0.4-0.8-0.9-0.3-1.4,0.1c-1.1,0.9-2.2,1.6-3.6,0.5c-1.3-1.1-1.5-2.4-0.8-4c0.2-0.5,0.1-1-0.4-1.3
			c-1.2-3.3-2.8-6.7,0.7-9.7l0,0c2-1,1.6,0.8,2,1.7c0-1.3-1-3.2,1.2-3.6c2.2-0.4,1.6,2,2.4,3.1c0.1,0.3,0.2,0.7,0.2,1
			c0,0.8-0.1,1.5-1.1,1.9c1.6-0.4,1-1.8,1.4-2.7c1.2-1.7,2.3-1.7,3.4,0.1c0.9-0.2-1.5,3.1,1.4,1.3c0.9-0.6,3.1-0.3,4.7,0.2
			c-2,3.2-1.6,4.2,2.4,5.3c0.3,0.3,0.5,0.7,0.6,1C153,530.6,153.1,531.8,151.9,532.7z"
        />
        <path
          class="st71"
          d="M155.1,504.1c0.9,1.5,2,1.4,3.3,0.7c2-1,1.8,0.5,1.9,1.7c-0.6,1.1,0.4,3.2-2.3,2.9c-0.4-0.1-1.2,1.4-0.7,2.5
			c-0.1,1.2,1.5,1.5,1.5,2.6c-0.6,1.9-1.4,3.5-3.5,4.2c-0.8,0.3-1.7,0.7-1.2,1.8c-0.4,1.2-1.4,0.9-2.3,0.8c-1-0.7-1.3-1.8-1.7-2.9
			c-1.3-2.2-2.8,0.7-4.2-0.1c-0.4-0.1-0.7-0.2-1-0.4c-0.5-0.6-0.9-1.1-0.4-1.9c1.6-0.8,3.2-1.5,4.6-2.8c-1.7,1.8-4.5,1.9-6.1,4
			c-0.4,0.1-0.7,0.1-1.1-0.1c-1.6-1.2-1.8-3-2.1-4.8c0-0.3,0-0.7,0-1c0.2-1.1,0.2-2.3,0.8-3.3c0.9-0.6,4.3,0.1,1.3-2.6
			c-0.4-0.3-0.4-1.3,0.7-2c4.3-2.9,7.7-3.1,11.5-0.6C155.5,502.7,155.5,503.3,155.1,504.1z"
        />
        <path
          class="st72"
          d="M145.4,540.2c1.1-0.9,0.8-2.1,0.4-3.1c-0.9-2.4-0.7-3,1.6-4.4c-1,3.7-0.4,7.4,0.3,11.1
			c0.3,1.5,0.2,2.5-1.7,1.6c-1.1,0.1-3,1.3-2-1.4c0.4,0,0.8-0.1,1.3-0.1C146.2,542.7,144.7,541.4,145.4,540.2L145.4,540.2z"
        />
        <path
          class="st73"
          d="M150.6,518.5c1,0.7,1.4,1.7,1.3,2.9c-0.7,0.5-1.4,1.1-2.1,1.6c-1.9,1.7-4.9-1-6.4,1.9
			c-0.7-1.1-0.5-1.9-0.4-2.8c1.6-0.8,1.8-2.4,2.3-3.9c0.5-0.2,1-0.2,1.5,0c1,0.9,1.8,3,3.3,0.5C150.3,518.6,150.4,518.5,150.6,518.5
			z"
        />
        <path
          class="st74"
          d="M152.1,529c0-0.2,0-0.5,0.1-0.7c1.9-0.7,3.3-3,5.8-1.6c1.6,0.4,0.8,2.1,1.5,3c0,0.1-0.1,0.2-0.1,0.3
			c-3.7-2.7-4.2,0.9-5.7,2.8C152.3,531.9,153,530.1,152.1,529z"
        />
        <path
          class="st75"
          d="M167.8,505.7c-0.5,0-1,0-1.5,0c-2.7,0.5-2.3-1.2-2.2-2.9c0.6-1.5,2.2-1.9,3.4-2.5c0.9-0.5,2.2-1.6,3.3-0.5
			s0.8,2.5-0.1,3.6l0,0C169.5,502.5,168.6,503.1,167.8,505.7z"
        />
        <path
          class="st70"
          d="M144.1,544c-0.3,1.8,1.6,0.7,2,1.4c-0.8,3.3-4.2,1.4-5.8,3c-1.1-1.7-1.7-3.3,0.5-4.6
			C141.7,547,143,543.6,144.1,544z"
        />
        <path
          class="st76"
          d="M174.3,520.7c-1.1,1.4-0.9,2.4,0.9,2.9c0.3,1.7,0.3,1.7-2.2,2.7c-0.5-2,0-4,0.2-5.9c0.1-0.8-1.1-2,0.4-2.6
			c0.3-0.1,0.8,0,1,0.2C175.7,519,174.2,519.7,174.3,520.7z"
        />
        <path
          class="st77"
          d="M157.3,511.9c-0.9-2.5-0.9-2.5,1.2-2.8c1.4-0.2,0.7-2,1.8-2.5c0.5,0.7,1,0.9,1.4-0.1
			c0.8-0.3,1.3,0.1,1.6,0.8C161.9,509.5,158.9,509.9,157.3,511.9z"
        />
        <path
          class="st78"
          d="M178,521.5c0.1-0.2,0.2-0.4,0.4-0.6c0.8-1-2.3-2.6,0.6-3.2c2.8-0.5,1,2.4,2.3,3c-0.1,1.3-0.9,2-2.1,2.2
			C178.1,523.1,178.2,522.2,178,521.5z"
        />
        <path
          class="st79"
          d="M140.8,543.8c-1.3,1.4-0.6,3-0.5,4.6c-1,1.1-1.6,2.7-3.9,2.9c1.2-2.4,2.6-4.3,2.4-6.9
			C138.6,543.5,139.7,542.8,140.8,543.8z"
        />
        <path
          class="st80"
          d="M154.2,520.6c-1.5,0-1.8-1.5-0.9-1.7c2.8-0.5,3.8-2.8,5.5-4.4c0.9,2,0.1,3.1-1.6,4.1
			C156.2,519.2,154.8,519.4,154.2,520.6z"
        />
        <path
          class="st81"
          d="M163.2,526.1c-1.2-2.1-0.7-3.3,1-3.5c1-0.1,1,1,0.8,1.7c-0.4,1.4,0.5,4-1.4,4.1
			C161.4,528.6,163.6,526.5,163.2,526.1z"
        />
        <path
          class="st82"
          d="M157,534.1c-0.1,2.7,1.2,5.7-1.7,8.1C154.8,539.1,155.4,536.5,157,534.1z"
        />
        <path
          class="st83"
          d="M134.1,520c-2.7,3.1-0.9,6.5-0.7,9.7c-1.6-0.5-1.6-0.5-1.6-3.8c0-1.3,0.1-2.7,0-4
			C131.8,520.3,132.6,519.8,134.1,520z"
        />
        <path
          class="st70"
          d="M136.3,537.9c0.7-0.5,1.4-1,2.2-1.5l0.1,0.1c1.1,1.4,0.6,3.1,0.7,4.6c-1,1.9-1.7,0-2.5-0.2
			C135.5,540.1,134.7,539.2,136.3,537.9z"
        />
        <path
          class="st84"
          d="M179.2,526.6c0.9,0.5,1.2,1.2,1.2,2.1c0,1.5-1.2,1.9-2.3,1.9c-1,0-0.3-0.9-0.1-1.3
			C178.4,528.4,177.5,527,179.2,526.6z"
        />
        <path
          class="st75"
          d="M163.3,507.3c-0.5-0.3-1.1-0.5-1.6-0.8c-1.1-0.7-0.2-1.4,0.1-2c0.4-0.9,1.2-1.6,1.9-1c1.2,1,1.1,2.5,0.3,3.9
			C163.7,507.3,163.5,507.3,163.3,507.3z"
        />
        <path
          class="st77"
          d="M164,507.3c-0.7-1,1.8-4.4-2.2-2.9c-1.7-2.2,1.1-1.6,1.5-2.4c0.6,0,1.1,0.3,1.5,0.8c0.2,1.1-0.4,2.8,1.6,3
			C165.7,506.6,164.9,507,164,507.3z"
        />
        <path
          class="st70"
          d="M145.4,540.2c0.4,1.2,2.4,2.5-0.1,3.7C144,542.6,142.9,541.4,145.4,540.2z"
        />
        <path
          class="st85"
          d="M139.4,541.1c-1.8-1.3-0.5-3.1-0.7-4.6c2.8,0.8,1.1,2.8,1.2,4.3C139.6,540.8,139.5,540.9,139.4,541.1z"
        />
        <path
          class="st86"
          d="M167.8,505.7c0.1-0.9-0.2-1.7,0.5-2.6c1.3-1.5,1.8-0.9,2.5,0.4c0,0.2-0.1,0.5-0.1,0.7
			C169.7,504.7,168.8,505.2,167.8,505.7z"
        />
        <path
          class="st87"
          d="M152.1,529c1.3,0.9,1.4,2.4,1.6,3.8c-0.6,1-1.2,1.7-1.8-0.1C152,531.5,152,530.2,152.1,529z"
        />
        <path
          class="st88"
          d="M163,537.6c-0.7-1.8-0.4-2.9,0.7-3.8c0.1-0.1,0.6,0,0.6,0.2C165,535.4,164.1,536.2,163,537.6z"
        />
        <path
          class="st89"
          d="M136.3,537.9c-0.9,1.2,0.2,2,0.5,2.9c-0.7,0.2-1.5,1.5-1.7,0.9C134.7,540.4,133.9,538.5,136.3,537.9z"
        />
        <path
          class="st90"
          d="M174.7,531.5c-0.2,1.3-1.1,1.9-2.4,2.3C172,531.7,172.6,530.9,174.7,531.5z"
        />
        <path
          class="st91"
          d="M159.5,529.6c-1.1-0.7-0.7-2.1-1.5-3c0.1-0.7,0.2-1.6,1.2-1.5c0.9,0.1,0.6,0.9,0.6,1.5
			C159.7,527.7,159.6,528.7,159.5,529.6z"
        />
        <path
          class="st92"
          d="M361.4,359.1c-0.7,2.3,0.4,3,2.4,2.5c1.5-0.4,2.9,0.1,2.6,1.6c-0.6,2.9-0.2,5.9-0.9,8.6
			c-0.7,3-4.6,3.2-6.9,5.2c0.9,1.3,2.2,0.6,3.3,0.7c-0.9,2.9-3.5,3.4-5.7,4.4c-1.9-0.1-3.7-0.1-5.2,1.2c-2.6,2.1-5.5,0.2-8.3,0.5
			c-1.6,0.2-2.6,0.5-3.4,1.6c-1.8,2.5-1.8,2.6-3.3-0.6c-0.4-1.8-0.2-3.7-0.4-6.3c-0.4,2.7-0.2,4.6-0.2,6.6c-0.1,2.5,2.5,4.9,0.6,7.5
			c-5,3.6-10.8,6-16.2,9c-1.8,1-3.6,2.2-5.9,2.2c-0.6,0.1-1.3,0.3-1.9,0.7c-1.8,0.9-3.3,2.3-4.7,3.6c-4.2,1.9-7.7,5.1-12.4,6
			c-2.5,0-3.5-1.3-3.5-3.7c0-2,0-3.9,0-5.9c0-1.1,0.1-2.2,0-3.3c-0.6-4.4,1.2-7.1,5.3-8.5c4.4-1.4,8.3-3.9,12.3-6.1
			c5.9-2.8,11.3-6.3,17.2-9c2-0.9,2.6-2.2,2.7-4.4c0.3-5.1-0.9-10.1-0.8-15.2c0-2.3,0.3-4.5,1.3-6.5c0.7-2.3,1.3-4.6,1.7-6.9
			c0.4-0.7,1-1.1,1.6-1.5c7-3.5,13.8-7.3,20.7-11c0.7-0.4,1.4-0.5,2.2-0.4c1.8,0.5,1.8-0.7,1.8-1.9c0-3.4,0-6.7,0-10.1
			c0-1.5-0.2-3,1-4.2c2.1,0.4,1.7,2.2,1.7,3.5c0.1,3.8-0.2,7.7,0.1,11.5c0.4,4.8-1.8,7.2-5.9,9.1c-5.1,2.4-10.2,4.9-15,8.1
			c-1.7,2-0.9,4.3-0.9,6.5c0.1,4.8,1.5,9.5,0.2,14.6c5.8-2.4,11.1-4.9,16.3-7.8C357,360,358.8,357.9,361.4,359.1z"
        />
        <path
          class="st93"
          d="M356.2,381.6c2-1.2,3.9-2.5,5.9-3.8c1.1-1,2.4-1.6,3.9-1.4c0,4.9,0,9.8-0.1,14.6c0,1.3,0.4,2.7-0.5,4
			c-1.6-1.3-1-3.2-1.1-4.8c-0.1-1.7,0-3.5,0-5.2c0-3.9,0-3.9-3.6-2.3c-0.6,0.3-1.3,0.6-1.9,0.9c-4,2.2-8,4.2-11.7,6.7
			c-0.6,0.4-1.2,0.7-1.8,1c-3.4,1.5-6.5,3.4-9.8,5.1c-6.5,3.4-13,6.7-19.3,10.3c-3.3,0.6-3.5,0.3-2.5-3.4c3.5-0.3,6.3-2.5,9.3-3.9
			c4.2-2.1,8.3-4.3,12.4-6.5C342,388.7,348.8,384.5,356.2,381.6z"
        />
        <path
          class="st94"
          d="M361.4,359.1c-6.7,2.6-12.7,6.6-19.2,9.6c-1.4,0.7-3,2.4-4.2,1.6c-1.4-0.9,0-2.9,0-4.5
			c-0.1-4.8-0.4-9.6-0.4-14.5c0-1.4-0.7-3,0.7-4.2c0.7,0.4,1.1,1,1.4,1.7c0.4,4.5,1.2,8.9,1.1,13.4c-0.1,4,0.1,4.1,3.5,2.3
			c5.8-3,11.3-6.2,17.2-9c1.2-0.5,2.6-0.9,3.6-1.8c0.5,0,1.1,0,1.6,0c0.6,0.7,1.9,1.2,0.2,2.1C365.1,357.1,363.3,358.1,361.4,359.1z
			"
        />
        <path
          class="st21"
          d="M368.4,327.7c-0.1-3.8-1-7.5,1.1-11.8c0,4.4,0,8.1,0,11.9C369.1,327.7,368.7,327.7,368.4,327.7z"
        />
        <path
          class="st95"
          d="M190.9,473.6c-7.9,4.4-15.9,8.7-23.8,13.1c-2.4,1.3-4.7,2.8-7.4,3.4c-1.9,0.3-4.1,2-5.6-0.7
			c1-3.2,4.4-3.2,6.6-4.7c7.4-3.2,14-7.7,21.3-11.1c1.4-0.6,2.7-2,4.4-0.7c1.8,1.1,3.1-0.7,4.7-0.7
			C191.1,472.7,191,473.1,190.9,473.6z"
        />
        <path
          class="st96"
          d="M164.8,502.8c-0.6,0-1.2-0.1-1.5-0.8c4.8-2.6,9.6-5.1,14.4-7.7c1.2,0.7,0.8,3.7,1.8,2.9
			c1.7-1.5,5-0.2,5.4-3.4c2.2,0.6,1.8,2.1,0.4,2.6c-3.7,1.3-6.6,4-10,5.7c-0.3,0.2-0.8,0.4-1,0.2c-0.9-1.3-1.2-0.8-1.3,0.4
			c-0.7,0.3-1.5,0.5-2.2,0.8c-0.1-1.2,0.7-2.8-0.6-3.4c-1-0.5-2.3,0.3-3.3,1.2C166.3,502,165.5,502.3,164.8,502.8z"
        />
        <path
          class="st39"
          d="M179.7,502.9c1.3,0.1,1.6,0.8,1.5,1.5c-0.2,1.6-1.1,2.7-2.8,2.7c-0.8,0-1.5-0.6-1.3-1.4
			C177.4,504.2,178.7,503.5,179.7,502.9z"
        />
        <path
          class="st97"
          d="M182.7,484.9c4.2-1.6,0.3,2,1.6,2.3c-1,0.7-2,1.4-3,2.2c-0.5-0.5-1-1-1.5-1.5c-0.1-0.2-0.1-0.3-0.2-0.5
			C180.2,486,181.7,485.8,182.7,484.9z"
        />
        <path
          class="st39"
          d="M172.9,502.8c-0.7-1.2-0.7-2.5-0.7-3.8c2.7-0.4,1,3,2.9,3.2C174.7,503.3,173.9,503.3,172.9,502.8z"
        />
        <path
          class="st98"
          d="M178.8,515.8c0.1-0.1,0.4-0.7,0.8-0.8c0.6-0.2,0.9,0.4,0.8,0.9c-0.1,0.4-0.5,0.9-0.9,1
			C179.1,517,178.6,516.8,178.8,515.8z"
        />
        <path
          class="st99"
          d="M197.6,355.5c-0.6,2.6-0.9,5.4-3,7.4c-0.3,0.6-0.8,1-1.5,1c-2.3-0.3-3.8,0.4-3.6,3c-1.9,1.5-4.4,2-6,3.9
			c-1.8,0.8-3.8,0.8-5.4,1.8c-2.3,1.6-4.9,2.5-7.1,4.4c-3.9,2.6-8.4,4.1-12.3,6.6c-1.5,1-0.6,2.4-0.6,3.7c-0.1,1.1,0,2.5-1.4,2.6
			c-1.3,0.1-2-0.8-1.9-2.1c0-0.4,0.2-0.9,0-1.3c-0.8,0.6-0.5,1.4-0.5,2.1c0.1,0.7,0.1,1.4-0.1,2c-1.9,2.3-5,2.9-7.3,4.6
			c-0.8,0.6-2.2,0.5-2.1,2c0.4,0.4,0.9,0.8,0.8,1.5c-0.4,1.1-1.7,1.8-1.5,3.2c0,1.1,0.2,2.2,0.5,3.3c0.2,0.7,0.4,1.3,0.6,2
			c0.2,0.9,0.2,1.8-0.1,2.7c-0.2,0.5-0.6,0.9-0.9,1.3c-1.1,0.9-2.1,3.3-3.8,1.6c-1.3-1.3-3.8-2.8-2.1-5.5c0.5-0.4,1.6-0.5,1.1-1.5
			c-0.8-1.5-0.5-3,0-5c-1.3,2.2-0.3,4-0.5,5.7c-0.9,0.7-1.4,1.4-1.3,2.8c0.1,1.6,2.6,4-1.2,4.7c1.4,4.3-4.2,6.1-3.5,10.2
			c-4.6,1.8-3.8,5.9-3.6,9.4c0.1,3,0.5,6,4.4,6.7c1.4,0.2,2.3,1.4,3,2.6c0.2,2.1-0.9,3.1-2.8,3.5c-1.8-1.2-3.4-2.2-5.3,0.1
			c-1.1,1.3-2.3,0.7-2.2-1.2c0.2-6.5-0.4-13,0.3-19.5c0-6,0-12,0-18c0-3.7,0.3-7.5,0-11.2c-0.2-2.9,1.1-4.3,3.4-5.5
			c14.6-7.6,29.1-15.2,43.6-22.8C181.6,364,189.6,359.7,197.6,355.5z"
        />
        <path
          class="st100"
          d="M154.3,489.3c1.7,1.4,3.7,0.6,5.5,0.8c-10.9,6.1-21.9,11.7-33.1,17.2c-1.1-1.2-0.2-2.5-0.1-3.8
			c0.1-0.2,0.1-0.3,0.2-0.5c2.4-1.9,5.5-2.1,8.2-3.2c5.8-2.4,10.3-6.8,15.8-9.7C151.8,489.4,152.9,488.7,154.3,489.3z"
        />
        <path
          class="st100"
          d="M126.6,425.7c0,7,0,14,0.1,20.9c1.3,1.4,1.2,3.2,1.2,5c-0.1,15.6,0.1,31.2,0,46.7c0,1.8-0.1,3.5-1.1,5
			l-0.2,0.2c-1.1-1.2-0.7-2.6-0.7-3.9c0-23.5,0-46.9,0-70.4C125.9,428.1,125.5,426.7,126.6,425.7z"
        />
        <path
          class="st101"
          d="M237.2,504.9c-4.5,2.3-8.9,4.6-13.4,6.9c-3.2,2.2-6.6,3.9-10.1,5.4c-2.4,1.1-3,0.5-3-2
			c0.1-10.3,0.1-20.6,0-30.9c1.9-2.1,4.4-3.6,6.4-5.6c0.1-0.1,0.2-0.1,0.3-0.1c0.6-0.2,1.5-0.5,1.8-0.2c1.8,2.3,4.8,0.9,6.8,2.4
			c1,0.7,1.3,1.8,1.4,3c0.6-0.2,1-1,1.4-0.8c3.1,1.8,7.4-1,10,2.4c0.6,0.8,0.3-0.1,0.3-0.1c0.3-1.1,1.3-1.5,2.2-1.9
			c1-0.3,1.9-1,3-1.1c1.9,0.6,1.9,2.4,2.4,3.9c1.2-2,1.9-4.9,5.1-2.2c0.8,0.7,1.1,0.1,1.4-0.4c0.8-1.1,1.7-1.9,3.1-1.1
			c1.3,0.7,2,1.9,1.7,3.4c-0.1,0.6-0.3,1.2-0.2,1.6c0-1.4,0.4-2.8,0.2-4.3c-0.2-2.1,0.2-3.9,2.7-4.6c1.3-0.4,2.5-1.6,3-2.7
			c2.1-4.8,6.5-7.3,10.3-10.4c0.7-0.6,1.2-1.2,1.2-2.2c0-1.8,1.3-3.3,2.6-3.8c2.9-1.1,3.9-3.6,5.4-5.9c0.6-0.9,0.8-2.3,2.3-2.1
			c1.4,0.2,2.2,1.2,2.7,2.4c0.5,1.3,1.1,2.5,2.2,3.4c0.7,5.2,0.3,10.4,0.2,15.6c0,2.3-1.5,3.9-3.2,5.2
			C270.7,487,254,496,237.2,504.9z"
        />
        <path
          class="st102"
          d="M313.5,403.2c1.4,0.5-2,4.6,2.2,2.8c0.7,0.8,1.8,0.3,2.6,0.9c1.8,2.8-1.9,3.5-2.1,5.6
			c0.1,1.5-1.2,1.8-2,2.6c-1.4,2.1-3.3,2.5-5.6,1.7c-2.7,0.6-4.2,3.3-6.6,4.3c-1.3,0.3-2.8-0.1-3.8,1.4c1.1,0,1.9-0.8,2.8-0.8
			c2,0.1,3.5-1.5,5.4-1.9c2.2,1.4,2.2,1.4-0.8,3.6c-1.6,1.2-3.3,2.1-5.4,2c-0.5,0.8-1.7,1-2,2.1c0.3-0.4,0.8,1.5,0.9,0
			c0.1-0.8,0.5-1.4,1-1.9c2.1,0.3,3.7-0.8,5.3-1.9c3.4-2.8,7.7-4.1,11.1-7c1.6-2.4,4.8-3,6-5.8c2.7-0.8,5.2-1.9,7.7-3.2
			c1.1-0.6,2.2-1.1,3.5-0.5c0.3,0.2,0.5,0.5,0.8,0.8c0.9,3.6-1.7,4.4-4.1,5.3c-4.2,1.6-7.9,4.2-12,5.9c-0.3,0.5,0.6,0.7,0.2,0.4
			c-0.1,0,0-0.1,0.2-0.3c1-0.8,1.9-2.1,3.5-1.2c1,1.8,0.6,3.5-0.1,5.3c-1.2,1.1-2.9,1.2-4.3,2.1c-0.7,0.4-1.7,0.6-2-0.1
			c-1.7-4-3.8-1.5-5.8-0.5c-0.6,0.3-1.5,0.4-1.9,1.1c-0.4,0.3,0.6,0.5,0.2,0.3c-0.1-0.1,0-0.2,0.1-0.3c0.7-0.8,1.7-0.9,2.5-1.5
			c1.1-0.7,2.6-1.9,3.5-0.3c1,1.7-0.9,2.1-1.9,2.8c1.3-0.7,2.5-1.2,4-0.4c0.7,0.7,0.3,1.2-0.2,1.8c-0.9-0.2-1.5,0.2-1.8,1.1
			c-0.2,0.2-0.5,0.4-0.8,0.6c-3.4,1.4-7.5,1-9.8,3.7c2.3-1.1,4.9-3.4,8.3-2.6c0.3,0.2,0.5,0.5,0.6,0.9c0.2,0.9,0.1,1.8-0.7,2.5
			c-2.4,0.6-4.5,1.8-6.8,2.6c-2,0.3-3.3,2.2-5.4,2.6c1.1,0.5,2.5,0.9,2,2.5c-0.4,1.3-1.6,0.9-2.6,0.6c-1.1-0.2-2.5,0.2-2,1.3
			c1.2,3.1-1.2,6.4,1,9.5c1.3-0.3,3-2,4.9-0.7c1,1.9-0.7,2-1.7,2.6c-2.9,1.6-4.8,3.7-4.4,7.3c-0.1,0.4-0.3,0.7-0.5,1
			c-0.9,0.8-1.6,2.2-3.3,1.2c-0.4-1.8,0.3-3.6-0.6-5.4c-1.6-1.3-1.1-3.2-1.1-4.9c0-11.1,0-22.3,0-33.4c-0.4-3.5,1.1-5.5,4.4-7.1
			c5.3-2.5,10.6-5.2,15.6-8.3c0.2-0.8,0.5-1.6,0.5-2.5C312.6,403.7,313,403.5,313.5,403.2z"
        />
        <path
          class="st103"
          d="M293.4,465.4c1-0.5,2-1,3-1.5c2.9,3.8,6.1,4.3,10.5,2c6.8-3.5,13.4-7.5,20.3-10.7c1.6-0.8,3.1-1.5,4.9-0.5
			c0.6,2-1.2,2.1-2.2,2.7c-5.6,3-11,6.2-16.3,9.6c-0.8,0.7-1.6,1.4-2.5,2.1c-0.4,0.3-0.9,0.5-1.3,0.8c-3.9,1.6-7.7,3.7-11.2,6
			c-2.5,1.3-5,0.1-5.2-2.7C293.2,470.5,293.4,467.9,293.4,465.4z"
        />
        <path
          class="st104"
          d="M287.4,478.1c1.9-2.2,2.4-4.8,2.2-7.6c-0.2-4.7-0.1-9.4-0.1-14.1c-0.2-0.3-0.4-0.7-0.4-1.2
			c0.3-2.3,0-4.6,0.5-6.9c0.7-3,0.1-6.1,0.4-9.2c0.1-1.1-0.6-3.2,2-2.8c0,11.9,0.4,23.7-0.2,35.6
			C292.4,475.5,290.4,477.2,287.4,478.1z"
        />
        <path
          class="st105"
          d="M292,472c-0.1-11.9-0.2-23.9-0.2-35.8c-0.7-0.7-0.8-1.6-0.9-2.5c0.2-4.3-0.6-8.6,0.5-12.8
			c0.3-0.5,0.7-0.6,1.1-0.4c0.1,13.2,0.1,26.3,0.2,39.5C292,464,293.5,468.1,292,472z"
        />
        <path
          class="st47"
          d="M298.6,475.8c3-3.3,7-5,11.2-6C306.3,472.1,302.8,474.6,298.6,475.8z"
        />
        <path
          class="st106"
          d="M186.5,472.9c-6.2,1.6-11.2,5.7-16.9,8.4c-2.9,1.4-5.4,3.5-8.6,4.1c-0.6-2.5,1-4.4,2-6.4
			c1.4-4.3,0.6-8.7,0.9-12.3c-0.3-1.3-1.1-0.7-0.6-0.8c0.1,0,0.2,0,0.2,0.1c0.6,1.7,0.8,3.3-0.5,4.8c-2.7-2.1-3.2-5-1.7-9.6
			c5.3-1.1,9.6-4.5,14.3-6.9c3.9-2,7.6-4.8,12.1-5.5c2.2,2-0.4,4.3,0.4,6.4c-1.4,1.3-2.2,2.4-0.9,4.5c1.1,1.8-0.4,4.3,0.6,6.4
			c1.5,2.6,4.3-0.2,6,1.4c0.4,1.8-1.8,1.4-2.2,2.6C190,471,188.2,471.9,186.5,472.9z"
        />
        <path
          class="st107"
          d="M210.3,460.2c-0.5,0.2-1,0.4-1.5,0.6c-0.4-0.3-0.6-0.7-0.8-1.1c-0.6-4.6-0.4-9.1-0.1-13.7
			c0.2-0.7,0.4-1.3,1.1-1.7c2.2,3.1,0.2,6.6,1.1,9.8c-1.2-13.8-0.8-27.7-0.9-41.6c0-2-0.2-4,0.6-5.9c0.2-0.4,0.6-0.6,1.1-0.6
			c2.2,3.2,1.2,6.5,0.6,9.5c1.2-2.2,3.4-2.4,5.9-1.6c1.5,2.8,0.6,9.9-1.4,11.8c-0.2,0.2-1.4,0.2-0.6,0.7c3.6,2.4,0.2,5.8,1.4,8.5
			c2.3,5.4,1.9,6.7-2.9,10.7c-0.5,0.4,0.6,0.5,0.3,1c-1.6,1.9-2.2,4-2.1,6.5c0,2.3,0.6,4.7-0.7,6.9
			C211.1,460.4,210.7,460.4,210.3,460.2z"
        />
        <path
          class="st108"
          d="M210.3,460.2c0.2,0,0.5,0,0.8,0c0.6,0.5,0.9,1.2,1,1.9c0.3,5.6,0.2,11.1,0.2,16.7c0,1.6,0,3.2-1.1,4.6
			c0,11.2,0,22.4,0,33.6c4.7-0.8,8.3-4,12.7-5.2c-2.3,3.6-6.6,4.3-9.7,6.6c-3.2,1.4-3.7-0.3-3.7-3.1
			C210.4,496.9,210.4,478.5,210.3,460.2z"
        />
        <path
          class="st109"
          d="M208.8,460c0,0.2,0.1,0.5,0.1,0.7c-5.2,4-11.1,6.8-17.1,9.2c0.2-1.3,1.6-1.4,2.2-2.4c1-2.1,3.1-2.7,4.9-3.6
			c2.4-1.1,4.6-2.4,6.8-3.7C206.7,459.9,207.7,459.3,208.8,460z"
        />
        <path
          class="st110"
          d="M313.6,466.9c3-3.2,6.7-5.3,10.4-7.3c2.8-1.5,5.6-3,8.2-4.7c0.1-2,1.9-2.3,3.2-2.9c2-1,3.9-2.5,6.1-3.1
			c1.8-0.1,3.4-0.5,4.3-2.2c2.8-2.7,6.2-4,9.9-4.6c2.8,0.2,4.8-1.4,6.7-3.1c0.8-0.7,1.1-2,2.4-2c0,1.2,0,2.4,0.1,3.6
			c-6,4.1-13,6.3-19.3,9.8c-9.5,4.4-18.6,9.4-27.7,14.5C316.6,465.7,315,466.2,313.6,466.9z"
        />
        <path
          class="st110"
          d="M360,382.3c1.5-1,3.2-2.9,4.7-2.1c1.3,0.7,0.4,3.2,0.4,4.9c0.1,3.3,0.1,6.7,0.1,10
			c0,12.2-0.1,24.5-0.1,36.7c0,1.5-0.1,3-0.2,4.4c-1.2,0.7-1.6-0.2-2.1-1.1c-0.5-1.8-0.4-3.7-0.4-5.5c0.2-13-0.8-26,0-39
			C362.7,387.4,362.2,384.6,360,382.3z"
        />
        <path
          class="st103"
          d="M363.7,435.4c0.3,0.6,0.8,0.7,1.4,0.8c0,0.3,0,0.5,0,0.8c-1.9,3.5-4.5,6-8.8,5.9
			C358.2,439.9,360.3,437.1,363.7,435.4z"
        />
        <path
          class="st21"
          d="M310.5,297c-1.2-0.1-1.8,0.5-2.3,1.5c-1.5,1.2-5,0.2-4.4,3.8c-1.2,1.5-2.4,2.9-4.5,1.4
			c-3.5,0.5-6.3,2.5-9.4,4c-2.3,1.1-4.4,2.8-6.6,4.1c-1.3,0.8-2.6,2.1-4.3,0.8c12.5-6.9,25-13.9,37.5-20.8c1.6-0.9,3.4-1.5,4.7-3
			c0.4,0,0.7-0.1,1.1-0.2c0.6,0,1.1,0.1,1.7,0.1c-2.3,1.5-4.5,3-6.8,4.5c-2,0.5-1.1,3.2-3,3.7C313.1,297.8,311.8,297.8,310.5,297z"
        />
        <path
          class="st21"
          d="M277.7,313.5c-0.5,2-2,2.8-4.5,2.3C274.7,314.9,276,313.8,277.7,313.5z"
        />
        <path
          class="st111"
          d="M153.5,390.4c0-0.7,0-1.3,0-2c0.4-1.1,1.1-1.5,2.2-0.9c-0.4,0.9-0.3,1.9,0.8,1.7c1.3-0.2,0.4-1.6,0.7-2.4
			c1-1.1-0.5-3.6,2.2-3.8c2.5-1.9,5.7-2.5,8.1-4.4c0.9-0.8,1.9-0.5,2.5,0.5c0.2,1.1-0.6,1.2-1.4,1.4c0.7,0,1.9,0.1,1.9-0.6
			c0.1-1.2,3.5-0.5,1.5-3.1c-0.6-0.7,2.2-2.8,4.4-2.4c1.5,0.9,1.2,2.4,1.4,3.8c-0.4,2.4-2.5,3-4.2,3.9c-1.6,0.8-3,1.7-4.6,2.5
			c-0.8,0.5-1.8,0.6-2.9,1.2c0.5-0.2,0.8-0.3,1-0.5c0.6-0.4,1.4-0.3,2-0.9c7-4.2,14.3-8,21.8-11.5c-1.7,0.7-3.2,2-5,2.5
			c-1.1,0.1-2.4,0.4-2.7-1.1c-0.2-1.1,0.9-1.6,1.9-1.9c1.8-0.5,3-2,4.9-2.3c-1.9,0.3-3.2,1.9-5.1,2.2c-1.3-0.1-2.5-0.2-1.6-2
			c0.9-3,3.8-2.8,5.9-3.8c1.2-1,1.8-2.8,3.8-2.9c0.5-0.2,1-0.5,1.5-0.8c1.7,1.5,3-0.1,4.4-0.6c2-1.2,3.8-3,6.5-2.3
			c-0.1-0.4,0-0.7,0.3-1c1.6-1.6,2.2,3.4,4,0.2c0.6,1.8-1.4,3-0.3,4.4c1.4,0.8,1.3,2,1,3.3c0,3.9-1.4,7.8,0.2,11.7
			c0,0.4-0.1,0.7-0.3,1c-1.1,1.8-1.2,3.8-0.9,5.8c0.2,1.6,0.4,3.3-0.3,4.8c-3.7,1.7-2.1-2-3.1-3.1c-2.6,0-3.9,4.8-7.3,2.1
			c-2.5-4.7-2.6-4.7-7.8-0.5c1.5,0.3,3.3,0.1,3.6,1.9c0.2,1.4-1.4,1.9-2.2,2.7c-0.8,0.9-2.3,1-2.8,2.3c-1.3,1.9-3,3-5.4,2.7
			c-1.8-1.1-1.9-3.1-2.6-4.8c0.8,1.9-0.9,3.4-0.9,5.1c-1.2,1.4-2.6,1.2-3.9,0.2c-1-0.8-2.1-1.3-3.4-0.5c-7.6,2.7-14.4,7.2-22,10
			c-2.2,0.8-4.4,2.4-7,1.5c0-1,0-1.9,0-2.9c1.4-1.9,4.1-1.9,5.4-3.8c0.2-0.2,0.5-0.4,0.8-0.5c1.4-0.4,2.8-0.7,4.1-1.4
			c0.7-0.3,1.5-0.5,2.2-1c1.2-0.6,2.4-1.4,3.6-2.1c0.6-0.3,0.6-0.6-0.1-0.8c-0.8-0.3-1.5-0.8-1.5-1.8c1-1.9,3-2.3,4.4-3.2
			c-1.9,0.8-3.8,2.1-5.8,3.3c-1.2,0.8-2.5,1.8-4.1,1c-0.1-1.6-0.9-1.6-2-0.8c-2.2,0.6-3.5,3.5-6.4,2.5c-0.5-0.2-1.1-0.5-1.6-0.7
			c2.4-3.4,6.2-4.7,9.7-6.3C154.7,391,153.8,390.8,153.5,390.4z"
        />
        <path
          class="st21"
          d="M276.3,325.4c0.5-1.1,1.1-2.2,1.3-3.4c0.9-5.3,3.5-7.9,8.8-8.5c0.8-0.1,1.7-0.2,2.5-0.5
			c3.7-1.7,7.4,0.1,8.1,4.1c0.2,1.4-0.6,2-1.4,2.9c-2.1,2.4-4.7,4.2-6.4,7.1c-1.8,3-1.9,8.4,0.5,11.2c0.9,0.8,0.8,1.8,0.6,2.9
			c-2.1,4.2-0.6,8.8-1.8,13.1c-0.6,2.2-0.5,4.7-1,7c-0.8,3.4-2.8,3.8-5.3,1.5c-2.6-2.4-5.5-4.4-9.5-3.6c-0.9,0.2-1.9,0.1-2.5-0.5
			c-1.3-1.5-3-1.4-4.7-1.6c-1.6-0.2-4-0.1-3.6-3c0.4-0.6,1.1-1,1.7-1.3s1.2-0.5,1.8-0.8c0.9-0.6,2-0.9,3-1.4c0.5-0.3,1.1-0.6,1.5-1
			c0.8-1.4,2.1-2.9-0.7-3.6c-0.9-1.3-1.3-2.6,0.1-3.9c0.8-0.7,0.5-1.5,0.4-2.4c0-0.9,0.7-1.7,0.4-2.5c-0.8-2.2,0-3.2,2.1-3.8
			c2.4-0.7,3-2.7,3-4.9c0-0.4,0.1-0.7,0.3-1C275.9,326.7,275.7,325.9,276.3,325.4z"
        />
        <path
          class="st112"
          d="M270.2,350.3c-0.5,0.2-0.9,0.4-1.4,0.6c-0.8-0.5-0.5-1.2-0.5-1.5c0.9,2.2-2.3,1.5-2.7,3
			c-0.3,0.6-0.9,0.7-1.5,0.7c-0.5-0.5-0.8-1.1-0.5-1.9c-0.4,0.6-0.2,1.4-0.7,1.9c-3.8,2.4-7.1,5.4-11.5,6.8
			c-0.7,0.2-1.4,0.6-2.1,0.3c-0.8-2.5-2.2-1.2-3.5-0.5c-1.3,0.6-2,1.7-2.6,2.9c-0.2,0.3-0.4,0.5-0.7,0.7c-0.5,0.2-1,0.2-1.5,0
			c-2.1-1.1-2.9,1.5-4.6,1.5c-2.4,0.9-4.9,2.2-6.7-1.1c-0.5-1-1.8-0.5-2.7-0.3c-1.7,0.5-3.4,0.7-5.1,0c-0.6-0.5-1.3-0.9-1.8-0.2
			c-0.4,0.6,0.6,0.5,0.9,0.9c0.4,0.6,0.8,1.2,0.9,1.9c-1.4,2.6-4.5,2-5.4,1c-2.1-2.1-3.6-1.4-5.4-0.2c-0.4,0.3-1,0.1-1.4-0.3
			c0.6-1,0.6-2,0-3c2.3-0.5,4.5-1,6.8-1.5c0.4-0.4,0.7-0.7,1.1-1.1c-0.4,0.1-0.8,0.3-1.3,0.4c-0.8-0.6-0.6-1.5-0.6-2.3
			c0.6-2,1.6-3.7,4-3.8c-0.2-1,3.3,0,1.2-2.1c-1-2.2,1.5-4.1,0.6-6.4c-0.3-0.9,1.4-1.1,2.4-1c0.9,0.1,2.2-0.5,2.6,0.7
			c0.4,0.9-0.8,1.4-1.2,2.1c-0.6,1-2.7,1.4-1.4,3.2c2.5,0.3,2,2.3,2.2,3.9c-0.4,1.2-1.2,1.7-2.3,1.7c1.1,0.1,1.9-0.4,2.6-1.2
			c0.3-0.2,0.5-0.4,0.8-0.6c2-0.9,3.4-3.2,6-2.8c1.6,0.4,3.3,0.5,4,2.4c0.4,1.3-0.4,2-1.4,2.6c0.7-0.4,1.3-0.7,1.8-1.2
			c0.8-0.5,1.7-0.9,2.4-1.6c0.9-0.9,2.2-1.4,3.2-2.2c1.6-1.2,3.8-1.7,5.1-3.4c3-2-1.5-2.1-0.9-3.5c-0.1-0.8,0.2-1.4,0.5-1.6
			c-0.7,0.1-0.6,0.9-0.6,1.7c0.6,2,1.2,4.1-2.2,2.4c-1.3-0.6-3.4,0.5-4.1-1.6c0.2-1.8,2.1-1.6,2.9-2.4c-3.7,1.3-6.8,4.5-11.2,4.6
			c-0.8,0-1.4-0.3-2-0.9c-0.8-2.7,1.3-4.3,3-4.5c2.6-0.4,4.3-1.1,4.8-3.9c0.1-0.5,0.8-0.2,1.2,0.2c4.1,1.4,5.1,0.9,6-2.9
			c-1.7-3.6,2.3-1.1,2.4-2.9c1.6,2.3-1.2,3.3-0.7,5.1c1.7-0.5,3.4-1,5-1.5c2.4,1.6,4.6,3.5,5.4,6c0.3,0.9,0.6-0.1,0.3,0.2
			c-0.1,0.1-0.2,0-0.3-0.2c-0.7-3.3-4-4.7-5.3-7.5c-1.4-2,0.9-1.6,1.4-2.3c1.2,0.9,2.4,1.7,4.1,1.2c1-0.3,2.2-0.1,2.2,1.2
			c0,1.4,0.5,1.3,1.5,1c0.6-0.2,1.2-0.1,1.8,0.2c0.6,0.4,5.9,2.1,6.5,2.1c0.1,0,0.2,0.1,0.3,0.2c0,0.9,2.3,1.7,0.2,2.5
			c-2,0.8-0.6,2.3-0.9,3.4C271.4,346.9,271,348.6,270.2,350.3z"
        />
        <path
          class="st113"
          d="M289.6,341.1c0-1,0-2,0-3c0-2.4,0-4.9,0-7.3c0-1.6,0.2-3,2.2-3.2c0,7.9,0,15.9,0,23.8
			c-0.6,9.6-0.1,19.1-0.3,28.7c-1.2,1.2-1.6,3.1-3.2,4c-2.6,1.4-5.5-0.7-5.8-4.3c0-0.5,0.8-1.5-0.2-1.5c-2.1,0-2.7-0.9-2.7-2.9
			c0-0.4-1.4-0.2-2.1-0.2c-5-4.1-10.8-4.2-16.8-3.2c-1.9,0.3-3.8,1.2-5.6-0.5c0.5-3.9,0.8-4.1,4.1-3.3c0.1,0,0.2,0,0.2-0.2
			c-0.2-1.1,0.1-1.9,0.9-2.6c2-1.4,4.6-1.1,6.6-2.5c0.3-0.2,0.9-0.4,1-0.2c1.3,3.7,5,0.4,6.9,2.4c0.3,0.3,0.9,0.1,1.4,0
			c3.5-0.8,3.5-0.8,4.2,3.4c2.1-1.2,3.9-1.2,4.9,1.5c0.9,2.2,2.1,1,2.9-0.2c0.7-1.1,0.6-2.4,0.5-3.6c-0.1-7,0.1-13.9,0.2-20.9
			C288.9,344,288.6,342.5,289.6,341.1z"
        />
        <path
          class="st114"
          d="M270.2,339.6c-1,0.4-2.1,0.8-3,1.3c-1.9,1-3.6,2-3.5-1.6c0-1.4-0.7-2-2.2-1.2c-0.8,0.4-1.8,0.5-2.7,0.8
			c-0.6-1.4,1.7-1.9,0.8-3c-1-1.2-1.9,0.4-2.9,0.6c-2.3,0.5-2-1.7-2.8-2.7c2.2,0.2,3.4-1.6,5.2-2.3c2.8-0.6,1.9,2.1,3,3.4
			c1.4-0.5,2.7-1.6,3.5-2.8c0.9-1.4,2.9,1,3.4-1.3c0.1-0.3-0.1-0.3,0.2-0.2c2.9,1.1,4.8-1.2,7.1-2c0.2,3.3-1.1,5.3-4.4,5.9
			c-1.1,0.2-1.7,0.7-0.9,1.7C272.2,337.7,270.7,338.5,270.2,339.6z"
        />
        <path
          class="st115"
          d="M276.1,328.5c-1.1,0.4-1.4,1.4-1.8,2.2c-0.7,1.5-3.6,1.6-4.7,0.2c-0.1,0.3-2,1.1-2.5,1
			c-0.8-0.2-1.1,0.5-1.4,1c-1.2,1.6-2.9,2.3-4.6,3.3c-1.1-1.6,0.2-4.1-2.2-4.9c-1.3-1.3-1.9-3.4,0.4-3.2c2.7,0.2,4-2,6.2-2.4
			c0.9-0.1,1.5-1.5,2.6-0.7c0.9,0.7-0.2,1.6-0.1,2.4c0,0.6,0,1.2-0.1,1.8c-0.6,1.1-1.8,1.1-3.1,1.5c1.3-0.3,2.5-0.3,3-1.5
			c1,0,2,0,2.9,0c1.8-0.6,3-2.9,5.3-1.5C276.2,328,276.1,328.2,276.1,328.5z"
        />
        <path
          class="st116"
          d="M225.3,355.8c-0.1-1.6-0.4-3-1.5-4.2c1.9-2,5.3-1,6.8-3.7c0.7,0,1.5,0,2.2,0c0.5,0.7,1.4,0.7,2.1,1.2
			c0.6,0.5,0.8,1.1,0.7,1.8c-2.8,1.9-6.4,2.6-8.7,5.3l0,0C226.2,356.5,225.7,356.7,225.3,355.8z"
        />
        <path
          class="st117"
          d="M220.8,353.1c0.9-0.4,2.2-1.4,2.2,0.7c0,0.8,0.6,1.7-1.5,1.7s-4.6,1.3-5.9,3.5
			C214.9,355,217.8,354,220.8,353.1z"
        />
        <path
          class="st118"
          d="M252.3,336c1,0,1.7,0.5,2,1.4c0.2,0.5,0.2,1,0.3,1.5c4.2-0.8,2.2,3.4,3.8,4.6c0.6,0.5,1.9,0.6,0.6,1.7
			c-1.2,0.9-1.5-0.2-1.8-1c-0.9-2.8-3.4-4.3-4.9-6.6C252.3,337,252.3,336.5,252.3,336z"
        />
        <path
          class="st119"
          d="M245.5,336.8c0.7,1.4,1,2.8-0.6,3.8c-1.9,1.2-3.9,0.5-5.8,0.4c0.1-0.4,0.3-0.9,0.4-1.3
			C241.9,339.5,243.7,338.1,245.5,336.8z"
        />
        <path
          class="st120"
          d="M276.2,327.7c-2-0.4-3.2,2.1-5.3,1.5c0.5-0.7,1.3-1.3,1.4-2.1c0.4-2.2,1.2-2.3,2.7-0.8
			c0.1,0.1,0.9-0.5,1.3-0.9C276.2,326.2,276.2,326.9,276.2,327.7z"
        />
        <path
          class="st121"
          d="M299.4,303.8c1.9,0.6,2.9-1.3,4.5-1.4c-0.1,1.2,0.3,2.4-1.6,2.5c-1.4,0.1-2.9,0.7-2.8,2.6
			C298.3,306.2,298.3,305,299.4,303.8z"
        />
        <path
          class="st122"
          d="M205.9,359c0.1,1.6,0.1,1.4-1.1,1.4c-2.2-0.2-3.6,1.9-5.8,1.9C200.7,359.8,203.2,359.2,205.9,359z"
        />
        <path
          class="st123"
          d="M216.2,361.4c0.9-1.3,2.1-1.9,4.2-2.4c-1.4,1.8-2.4,2.9-4,3.1C216.4,361.8,216.3,361.6,216.2,361.4z"
        />
        <path
          class="st124"
          d="M292.6,389c0.1-15.5,0.1-30.9,0.2-46.4c0-4.8,0.3-9.5,0.5-14.2c1.2,0,2.3,0.2,3.5,0.4
			c0.3,0.1,0.6,0.3,0.8,0.6c1.1,1.9,1.9,3.9,0.9,6.2c0.5,1.6,0,3.4,0.7,5c0.2,0.5,0.4,1,0.4,1.5c-0.3,0.8-0.9,1-1.7,1.1
			c-4.2,0.1-4.8,1.7-2.8,6.8c1.4-0.4,0.9-1.9,1.6-2.6c0.2-0.3,0.5-0.4,0.9-0.5c0.9,0.2,1.4,0.9,2.1,1.4c0.4,0.4,0.6,0.8,0.6,1.3
			c-0.4,1-1.7,1.6-1.5,2.9c0,1,0.5,1.8,0.9,2.6c0.1,0.3,0.2,0.6,0.2,1c-0.5,1.5-0.8,3-2.3,4c-2.3,0.1-2.2-1.9-3-3.4
			c-0.5,2.8-0.1,5.6-0.3,8.3c-0.1,1.7,0.1,2.6,2.1,1.9c0.7-0.3,1.4,0.2,2,0.7c1.1,1.7-0.6,2-1.3,2.7c2.6-1.7,5.6-2.9,8.3-4.7
			c6.6-2.9,12.5-7.1,19-10.2c4-1.9,4-1.8,4.2,2.5c0.1,5.1,0.7,10.1,0.7,15.2c0,2.5-0.8,3.9-3.1,4.9c-6,2.7-11.6,6.2-17.5,9.2
			c-4.1,2.4-8.3,4.5-12.7,6.4c-1.2,0.5-2.5,1.8-3.6,0.5C291.2,392.5,291.3,390.7,292.6,389z"
        />
        <path
          class="st125"
          d="M365.1,353.9c0.3,2.4-1.8,1.6-2.9,2.2c-2.7,0.5-2.3-1.5-2.6-3c0-3.5,0-6.9,0-10.3c-0.1-1.8-0.1-3.6,0-5.5
			c0.5-7.7,0.1-15.4-0.6-23c-0.4-4.7-0.1-9.5-0.1-14.2c0-1.6,0.2-3.3-0.9-4.7c-0.1-0.2-0.2-0.4-0.3-0.6c1.4-3,4.9-3,7.4-5.5
			C365.1,311.3,365.1,332.6,365.1,353.9z"
        />
        <path
          class="st126"
          d="M300.9,324.8c7-4.6,14.5-8.1,21.9-12.1c10.5-5.7,21-11.3,31.6-16.9c1.1-0.6,2-1.5,3.5-1l0,0
			c0.3,0.3,0.4,0.6,0.2,1c-0.7,1.4-2,2.2-3.3,2.9c-16.5,8.2-32,18.5-48.7,26.4c-1.5,0.7-3,1.9-4.9,1.7
			C299.8,326.2,299.6,325.6,300.9,324.8z"
        />
        <path
          class="st127"
          d="M292.6,389c0.1,0.6,0.2,1.2,0.2,1.8c-0.4,3.7,1.2,3.3,3.5,1.9c0.8-0.4,1.6-0.6,1.5,0.7
			c-6.7,1.5-4.8,6.9-5.2,11.2c-0.7,2-0.1,4.2-0.4,6.3c-0.1,0.9-0.3,1.9-1.5,0.8c-0.2-0.4-0.5-0.9-0.5-1.4
			c-0.4-8.7-0.2-17.5-0.1-26.2c0-1,0.4-1.9,1-2.8c0.1-8.7,0.1-17.4,0.2-26c0-1.3-0.3-2.7,0.5-4c0,11.2,0.1,22.5,0.2,33.7
			C291.9,386.5,291.4,387.9,292.6,389z"
        />
        <path
          class="st128"
          d="M300.9,324.8c-0.2,0.5-0.8,1.1,0.2,1.5c-0.7,2.4-3.4,1.7-4.7,3.2c-1.1-0.1-2.2-0.1-2.9-1
			C295.6,326.7,298.1,325.4,300.9,324.8z"
        />
        <path
          class="st129"
          d="M359.3,275.4c-1,3.4-4.2,4.1-7,5.3c2.3-1.8,4.6-3.6,6.9-5.5L359.3,275.4z"
        />
        <path
          class="st130"
          d="M333.9,291.1c-1-0.5-3.1,3.1-3.1-0.9c2.1,0.1,2.4-1.6,3-3c0.2,0,0.4,0,0.6,0
			C335.5,288.7,335.8,290.1,333.9,291.1z"
        />
        <path
          class="st131"
          d="M333.8,287.3c-0.8,0.2-1.6,0.4-2.4,0.6c0.1-2.8,2.2-2.2,3.9-2.1l0,0C335.8,287.3,334.2,286.7,333.8,287.3
			C333.7,287.3,333.8,287.3,333.8,287.3z"
        />
        <path
          class="st132"
          d="M345.1,281.5c-0.7,2.1-2.1,3.2-4.7,2.6C342.2,283.1,343.7,282.3,345.1,281.5z"
        />
        <path
          class="st133"
          d="M346.5,284.5c-1,2.6-3.1,2.4-4.6,3.2C343,286.3,344.3,285.3,346.5,284.5z"
        />
        <path
          class="st56"
          d="M333.7,287.3c0.5-0.5,1-1,1.5-1.5c1.4,1.3,0.5,3,0.6,4.5c-0.9-0.2-0.1,3.4-2,0.9
			C334.7,289.8,335,288.5,333.7,287.3z"
        />
        <path
          class="st134"
          d="M140.8,511.1c0,0.2,0,0.3,0,0.5c-1.3,1.5-3.9,1.8-4.1,4.3c-0.1,0.1-0.3,0.2-0.4,0.3
			c-1.2,0.1-1.3,2.5-2.8,1.8c-1.6-0.8-0.8-2.6-1-4c-0.5-1.7,0.6-2.5,1.9-3c1.2-0.4,2.3-0.9,3.4-1.5l0,0
			C139.8,508.2,140.3,509.8,140.8,511.1z"
        />
        <path
          class="st83"
          d="M137.9,509.5c-0.4,1-0.8,1.8-2.2,1.9s-2.7,1-3.1,2.5c-1.8-1.5-1.8-7,0-7.8c0.7-0.3,1.1,1.3,2.1,1
			c0.6,0,1.1,0.2,1.5,0.7C136.8,508.4,137.3,509,137.9,509.5z"
        />
        <path
          class="st83"
          d="M139.3,521.5c-0.1,0-0.2-0.1-0.4-0.1c-2-0.1,0.5-3.3-1.6-2.7c-2,0.6-0.8,2.7-0.7,4.2c0,0.3-0.2,0.6-0.4,1.1
			c-1.5-1.1,0.4-3.8-2.2-4.1c1-1,2-2,3-3c1.4-2,2.3-1.2,3,0.5C140.1,518.9,141.6,520.6,139.3,521.5z"
        />
        <path
          class="st135"
          d="M166.3,494.8c-1.5,1.6-3.3,2.3-5.1,3.1c-0.6,0.3-0.9-0.6-0.9-1.3c0.1-2.7,3-1.9,4.2-3.3
			C165.1,493.8,165.6,494.2,166.3,494.8z"
        />
        <path
          class="st134"
          d="M139.4,517.7c-0.3-1.8-1.3-1.4-2.3-0.7c-0.3-0.3-0.5-0.5-0.8-0.8l0.1-0.1c0.1-0.2,0.3-0.4,0.4-0.6
			c0.7-1.2,1.3-3.3,3-2.4c1.5,0.8,2,2.8,0.6,4.5C140.1,517.9,139.8,518,139.4,517.7z"
        />
        <path
          class="st83"
          d="M140.8,511.1c-0.8-0.9-1.5-2-3-1.6c0.9-1.2,0.6-2.9,1.8-3.9c0.8,0.6,0.1,2,1.2,2.4
			C141.6,509.1,140.8,510.1,140.8,511.1z"
        />
        <path
          class="st97"
          d="M179.8,487.9c-0.8,0.6-1.3,1.7-2.7,1.9c-0.3-1.8-0.9-3.5,1.9-3.5c0.7,0.2,0.5,0.9,0.7,1.4L179.8,487.9z"
        />
        <path
          class="st136"
          d="M155.1,504.1c-0.3-0.5-0.5-0.9-0.8-1.4c0.7-1.5-1.6-2.8,0.1-5C154.4,500.4,157.7,501.7,155.1,504.1z"
        />
        <path
          class="st135"
          d="M158.6,496.7c0.3,0.5,0.7,0.9,0.9,1.4c0.4,1.1-0.4,1.6-1.4,1.7c-0.5,0-1.1,0.1-1.2-0.5
			C156.7,498,158.5,498,158.6,496.7z"
        />
        <path
          class="st137"
          d="M179.6,487.6c-0.6-0.3-0.7-0.8-0.7-1.4c1.2-0.6,2.3-1.4,3.8-1.4C182.1,486.3,180.6,486.7,179.6,487.6z"
        />
        <path
          class="st138"
          d="M136.2,507.9c-0.6,0.1-1.2,0-1.5-0.7c0.1-1.7,1.5-1.6,2.5-2.2C137.6,506.5,136.5,507,136.2,507.9z"
        />
        <path
          class="st71"
          d="M139.4,517.7c0.2,0,0.4,0,0.6,0c0.2-0.3,0.5-0.3,0.9-0.2c1.4,1.2,2.9-0.5,4.4,0.2l0.1,0.1
			c0.2,0.1,0.3,0.3,0.2,0.4c-0.1,0.2-0.1,0.3-0.2,0.3c1.1,2.5,0.5,3.8-2.4,3.7c-0.1-0.6-0.2-1.2-1-1.3c-1-0.1-1.1,0.7-1.3,1.4
			c-0.5,0.4-0.9,0.4-1.4,0c0-0.2-0.1-0.5-0.1-0.8C140.7,520.3,138.8,519,139.4,517.7z"
        />
        <path
          class="st83"
          d="M139.4,522.3c0.5,0,0.9,0,1.4,0c0.6,2.5-1.9,2.2-3,3.5C137,523.7,139.7,523.7,139.4,522.3z"
        />
        <path
          class="st83"
          d="M140.9,517.9c-0.3,0-0.6-0.1-0.9-0.1c0.1-0.5,0.2-1,0.4-1.4c0.5-1.1,0.1-2.1-0.7-2.6
			c-1.1-0.6-1.1,0.9-1.8,1.3c-0.5,0.3-1,0.7-1.5,1.1c-0.9-3.8,1.8-4.1,4.4-4.5c0,2,0.8,3.7,1.7,5.4
			C142.4,518.2,141.8,518.3,140.9,517.9z"
        />
        <path
          class="st139"
          d="M143.2,516.9c1.3-3.1,5.2-2.8,6.9-5.3c0-0.1,0.7,0.1,0.9,0.4c0.4,0.6,0.1,1.3-0.5,1.6
			c-1.9,1-3.9,1.9-5.9,2.8C144.2,516.9,143.9,517.3,143.2,516.9z"
        />
        <path
          class="st140"
          d="M146.7,510.1c1.6-2.4,3.9-2.9,6.9-3.6C151.8,509.7,148.9,509.1,146.7,510.1z"
        />
        <path
          class="st70"
          d="M143.2,516.9c0.5-0.2,0.9-0.4,1.4-0.5c0.2,0.4,0.5,0.9,0.7,1.3c-0.9,0.5-1.9,0.9-2.8,1.4
			c-1.6,0.9-1.3-0.5-1.6-1.2c0.5-0.3,1.1-0.6,1.6-0.8C142.7,517,142.9,517,143.2,516.9z"
        />
        <path
          class="st139"
          d="M152.1,513.7c-0.2-4.1,2.7-2.4,3.4-3.9C155.8,512.4,153.3,511.6,152.1,513.7z"
        />
        <path
          class="st141"
          d="M145.5,518.5c-0.1-0.2-0.1-0.5-0.1-0.7c1.3,0.1,2.4-0.4,3.5-1c2.6-1.4,1.9,0.4,1.7,1.7h-0.1
			c-1.2,0.5-2.3,0.8-3.5,0.1C146.5,518.5,146,518.5,145.5,518.5z"
        />
        <path
          class="st71"
          d="M147,518.5c1.2-0.5,2.3-1.1,3.5-0.1c0.1,1.7-0.9,2.6-2.4,3.7C147.4,521,147,519.9,147,518.5z"
        />
        <path
          class="st128"
          d="M298.7,368.1c-1.9-1-3.5-0.4-5.5,1.7c0.7-5.5-0.8-10.3,1.4-15.6c1.5,2.5,0.5,5.2,3.2,5.7
			c0.4,0.2,0.7,0.6,1,1c1.5,0.7,2.6-0.5,3.8-1c8.9-3.8,16.7-9.4,25.2-13.7c1.1-0.5,2.1-2,3.6-1c1,2.3-0.9,4.3-0.8,6.5
			c-7.2,4.6-14.9,8.5-22.5,12.7c-1.2,0.7-2.4,1.3-3.5,2C302.7,366.8,301.2,369.2,298.7,368.1z"
        />
        <path
          class="st142"
          d="M304.6,365.8c8.5-5,17-10.2,26.2-14.1c-2.2,4-1.1,8.2-1.1,12.4c0,1.5,0,3,0.3,4.4
			c1.5,6.6-1.4,10.2-7.5,12.5c-4.6,1.7-8.9,4.4-13.2,6.7c-0.8,0.3-1-0.1-0.9-0.9c5.5-3.3,11.1-6.6,17-9.2c2.6-1.1,3.4-2.7,3.2-5.4
			c-0.2-5.7-0.1-11.4-0.1-17.7c-8.4,3.6-15.2,9-23.2,12.1C304.6,366.9,304.4,366.5,304.6,365.8z"
        />
        <path
          class="st143"
          d="M331.5,345.1c-10.1,5.2-19.6,11.8-30.1,16.3c-1,0.4-2.4,2.7-3.5,0c0.6-1.4,1.7-2.1,3-2.6
			c10.3-3.9,19.2-10.5,28.9-15.5c8.2-4.2,16.3-8.5,24.2-13.1c1-0.6,2-1.2,3.1-0.4c0.5,1.5-0.1,2.2-1.6,2.5
			C347.5,336.6,339.5,340.9,331.5,345.1z"
        />
        <path
          class="st144"
          d="M357.8,295.7c0-0.3-0.1-0.6-0.1-1c2.4-0.2,2.4,1.5,2.4,3.2c0,6.2-0.1,12.4,0,18.6
			c0.1,6.9,0.4,13.9,0.6,20.8c-4.7,3.8-10.5,5.9-15.7,8.9c-1.8,1-3.5,2.8-6,2.4c-0.5-0.3-0.7-0.8-0.7-1.3c5.1-3.3,10.5-6.1,16-8.7
			c3.3-1.5,5.4-3.4,4.9-7.6c-0.6-5.1,0.4-10.4-0.6-15.6c-1.4-1.1-1.1-2.6-1.1-4.1c0-4.2-0.1-8.4,0-12.5
			C357.4,297.8,357.3,296.7,357.8,295.7z"
        />
        <path
          class="st145"
          d="M356.2,381.6c-6.7,4.2-13.5,8.3-20.9,11.2c2.3-2.8-1.4-5-0.7-7.6c0.8,0,1.5,0.6,2.4,0.1
			c0.3,0.5,0.6,1,0.9,1.5c0.9-2.4,1.3-5.8,5.2-3.1c2.5-2.6,6.5,1.5,9-2C352.8,380.5,354.7,381.1,356.2,381.6z"
        />
        <path
          class="st126"
          d="M358.5,297.7c0,5.9,0,11.9,0.1,17.8c0,6,0,12.1,0,18.1c-1.5,0.3-2.1-0.7-3-1.2c0.8-0.5,1.3-1.2,1.3-2.2
			c-0.1-0.8-2-0.5-1.3-1.8c2.4-4.8,1-9.8,0.4-14.7c-0.1-0.3,0-0.7,0-1c0.5-0.9,0.5-1.9,0.6-2.9c0.7-3.7-1.9-7.2-0.2-10.9
			c0.2-0.4,0.2-1,0.6-1.4C357.5,297.3,358,297.3,358.5,297.7z"
        />
        <path
          class="st146"
          d="M292.6,420.5c-0.2,0-0.4,0-0.6,0c-2-4.1-1.4-5.2,3.7-6.7c3.9-2,7.5-4.7,11.7-6.2c1.8-0.4,3.1-3.1,5.5-1.4
			c0.1,1.1-0.8,1.4-1.6,1.8c-5.3,2.7-10.6,5.5-16,8C293,417,292.3,418.3,292.6,420.5z"
        />
        <path
          class="st147"
          d="M295.6,413.8c-2.2,1.7-4.9,3.1-3.7,6.7c-0.1,4.5-0.1,9-0.2,13.5c-1.9-0.4-1.6-2-1.6-3.2
			c-0.2-3.1-0.2-6.1-0.1-9.2c0.2-2.6-0.3-4.1-3.4-3.2c-0.8,0.2-1.7-0.1-2.4-0.7c-1.3-1.8-1.1-3.7-0.5-5.6c0.2-0.4,0.5-0.6,0.8-0.7
			c0.2,0,0.5,0.1,0.6,0.3c2.1,4.2,2.5,4.2,5.9,0.7c1.8-2.2-0.4-5.2,1.4-7.5c0,1.7,0.3,3.4,0.1,5.1
			C292.4,412.2,293.1,413.6,295.6,413.8z"
        />
        <path
          class="st148"
          d="M348,377.1c-1.5,2.6-5,1.7-6.9,3.9c-0.4,0.5-1.1,0.2-1.4-0.6c-0.3-1.1-1.4-2.5-0.5-3.5
			c0.9-0.9,2.3-1.5,3.5-1.7c1.8-0.3,0.8,2,1.8,2.5c0.8-1.2-1-3.5,1.8-3.8C348.8,374.2,348.8,375.4,348,377.1z"
        />
        <path
          class="st149"
          d="M337,385.3c-0.2,0.7,0.1,2-1,2c-1.3,0.1-0.6-1.6-1.4-2.1c0-2.2-0.1-4.4-0.1-6.6c0-0.8-0.2-1.9,1-2
			c1.6-0.1,1.1,1.3,1.2,2.1C336.8,380.9,336.9,383.1,337,385.3z"
        />
        <path
          class="st150"
          d="M310.6,396.6c-1.4,0.7-2.8,3.2-4.1,1.5c-1.5-2,0.2-3.8,2.3-4.7c0.5-0.2,1.1-0.4,1.7-0.6
			C311.8,394,312.2,395.3,310.6,396.6z"
        />
        <path
          class="st151"
          d="M308.4,386.8c0.3,0.3,0.6,0.6,0.9,0.9c-3.6,2.3-7.3,4.5-11.4,5.8c-0.5-0.2-1-0.5-1.5-0.7
			C300.3,390.8,304.4,388.8,308.4,386.8z"
        />
        <path
          class="st152"
          d="M358.6,366.5c0.5,1.1,0.7,1.7,1,2.5c0.3-0.8,0.3-1.7,0.7-2c0.8-0.6,1.8-1.9,2.8-0.7c0.6,0.7,0.1,1.8-1,2.3
			c-0.4,0.2-0.6,0.8-1.1,1c-1.1,0.5-2.3,2.3-3.5,1.1S357.6,368.2,358.6,366.5z"
        />
        <path
          class="st153"
          d="M350.2,372.6c0.3,0.5,0.7,1,1.3,1.8c-0.3-2.5,0.2-4.4,3.1-4.2c-2.2,1.3,0,5.8-4.4,5.4
			C349.3,374.6,349.5,373.6,350.2,372.6z"
        />
        <path
          class="st154"
          d="M310.6,396.6c0.9-1.3-0.3-2.6,0-3.8c1.4-1,2.7-0.5,4,0.1C313.7,394.7,312.9,396.4,310.6,396.6z"
        />
        <path
          class="st155"
          d="M302.9,396.4c-1.6,2.3-0.4,5-3.2,5.7C298.6,399.5,299.6,397.9,302.9,396.4z"
        />
        <path
          class="st156"
          d="M350.2,372.6c0,1,0,2,0,3c-0.8,0.4-1,1.7-2.2,1.6c0-1.3-0.1-2.5-1.5-3.1
			C347.2,372.4,348.8,372.6,350.2,372.6z"
        />
        <path
          class="st157"
          d="M321,390.3c0.2,1.7-1,1.8-1.8,2.2c-1,0.5-1.2-0.3-1.3-1.1c-0.1-1.2,0.1-2.3,1.6-2.5
			C320.8,388.7,320.4,390.1,321,390.3z"
        />
        <path
          class="st93"
          d="M312.8,406.2c-2-0.4-3.5,1.6-5.5,1.4c1-2,3-2.6,4.7-3.7C313.1,404.4,312.9,405.4,312.8,406.2z"
        />
        <path
          class="st158"
          d="M328.1,388.2c-0.4-1.8-0.6-3.3,0.2-4.8C329.2,384.9,330,386.4,328.1,388.2z"
        />
        <path
          class="st159"
          d="M316.5,394.4c-1.1-1.7-0.6-2.8-0.1-3.9C317.5,391.4,317.5,392.5,316.5,394.4z"
        />
        <path
          class="st160"
          d="M363.7,435.4c-2.2,2.8-4.5,5.5-7.5,7.5c-3.7,0.6-7,1.8-9.7,4.5c-1.4,1-3.1,1.3-4.5,2.3
			c-3.3,1.6-6.7,3.1-9.7,5.2c-4.4-0.4-7.5,2.8-11.2,4.4c-5.3,2.4-10.1,6.1-15.4,8.6c-2.1,1-5,0.6-7.4,0.1c-1.6-0.3-1.9-2.4-1.9-4.1
			c0-0.2,0-0.4,0-0.6c2.5-2.5,6.5-2.3,9-5c0.4-0.4,0.8-0.7,1.3-0.9c0.9-0.3,1.9-0.5,2.8-0.8s1.7-0.7,2.5-1.3
			c0.5-0.3,1.1-0.6,1.7-0.9c0.7-0.3,1.5-0.6,2.1-1c1.9-0.9,2.6-3.7,5.3-3.2c3.2-1.7,6.1-4,9.4-5.6c1.6-0.1,2.3-1.7,3.5-2.3
			c4.6-2.3,9.4-4.3,14-6.6c0.1-0.6,1.5-1.3-0.2-1.6c-3.6,2.5-7.9,3.4-12,4.7c-1.3-0.2-1.8-1.1-1.9-2.3c-0.3-1.3,0.9-1.9,1.3-3
			c-1.1,1.1-2,2.3-3.6,2.4c-2.1-0.5-1.7-1.8-0.9-3.1c0.6-1.7,2.7-1.7,3.4-3.2c0.4-1.4,1.4-2.2,2.7-2.8c2.6-1.2,5.5-2.1,6.7-5.3
			c0.2-0.6,0.8,0.2,1.4-0.3c-2-0.3-3.2,2.2-5.1,1.1c-1.8-1.7-1.8-3.1,0.8-4c1.3-0.4,2.5-0.8,4.2-1.2c-1.7-0.5-3,0.2-4.3,0.5
			c-4.9-0.4-6.9-4.1-4.9-9c0.6-1,1.5-1.6,2.6-1.8c0.5,0-0.3-0.7-0.1-0.3c-1.5,0.1-3,0.4-4.3,1.3c-0.9,0.3-1.9,0.4-2.8,0.8
			c-2.7,1.4-5.2,3.3-8.4,3.1c-1.4,2.5-3.7,3.3-6.4,3.7c-0.9,0.1-1.8,0-2.5-0.7c0.5-1,2.3-0.6,2.3-2.2c0.3-1.5,1.7-1.7,2.6-2.4
			c1.4-1.2,1.4-2.2-0.4-2.9c-0.8-0.2-2.2,1.2-2.3-0.9c6.1-4.1,12.8-7.1,19.4-10.4c3.6-1.1,7.3-2.1,9.9-5.1c0.4-0.3,0.9-0.6,1.3-0.9
			c4.5-1.4,8.6-3.4,12.1-6.7c0.5-0.3,1.1-0.5,1.6-0.8c2.9,0.4,3.8,2.1,3.7,4.9c-0.2,5.5-0.9,11.2-0.5,16.6
			C363.9,414.4,363.3,424.9,363.7,435.4z"
        />
        <path
          class="st161"
          d="M358.4,383.1c-1,3.2-7.3,6.7-12.1,6.7C350.2,387.2,354.1,384.8,358.4,383.1z"
        />
        <path
          class="st161"
          d="M345,390.6c0.5,0.9,0.4,1.8-0.6,2.2c-3,1.1-5.7,3.6-9.3,2.9C338.3,393.7,341.5,391.8,345,390.6z"
        />
        <path
          class="st162"
          d="M339,348.6c7.1-4,14.1-8.2,21.7-11.3c0,1.8,0,3.6,0,5.4c-0.5,0.5-1,1.1-1.8,1.1c-1,0.2-1.8,0.7-2.7,1.1
			c-3.9,1.6-6.5,3.9-5.2,8c-0.1-5.1,1-6.6,6.3-8.5c1.4-0.5,2.5-0.6,2.6,1.2c0.4,2.4-0.7,5,0.7,7.3c0.3,1.2-0.4,2.9,1.6,3.1
			c-7.3,3.9-14.5,7.9-22.1,12C339.7,361.4,339.3,355,339,348.6z"
        />
        <path
          class="st163"
          d="M163.3,479.5c-0.8,2-1.5,3.9-2.2,5.9c-2.2,1.3-4.5,2.6-6.8,3.9c-1.1,0.5-2.1,1-3.2,1.5
			c-1.4,0.7-2.9,1.3-4.4,2.1c-3.4,1.7-4.9,0.8-5.6-3.3c0.3-0.8,1-1.2,1.8-1.1c4.3,0.7,8-1.2,11.6-3c4.9-2.4,6.9-8.1,4.6-13
			c-1.4-2.9-1.6-5.7-0.7-8.7c0.6-1.9,0.4-3.7-0.6-5.4c-1.9-3-2.9-6-1.5-9.5c0.2-0.5-0.2-0.9-0.6-1.2c-1.9-1.9-4-1.3-6.1-0.3
			c-1.5,0.7-2.7,2.3-4.7,2c-0.9-1.2-1.4-2.8-2.4-4c-0.1-0.8-0.4-1.3-1.4-0.8c-1.3,0.8-2.8,1.4-4.3,1.8c-2.3-2.4,0.7-2.7,1.5-3.9
			c0.6-0.6,1.2-1.3,0.2-2.2c0,0.7,0.8,1.3,0.1,2c-0.7,0.4-1.3,1.2-2.2,0.8c-1.8-0.3-2.5-3-5.1-2.1c-1,0.3-1.6-1.6-2.1-2.8
			c-1.5-3.5-1.1-7.1-0.8-10.6c0.2-2.3,1.9-3.6,4.3-3.9c0.8,0.5,1.5,0.3,2.3-0.1c1.4-1,3.1-1.6,3.7-3.5c0.5-1.1,1-2.3,2.6-1.5
			c2.3,1.2,3.9-0.7,5.7-1.4c1.5-0.6,2.8-1.5,4.2-2.2c0.3-0.2,0.6-0.3,1-0.4c3.3-0.9,5.8-3.2,9.1-4.3c1.6-0.4,3.1-1.5,4.9-1.3
			c2.8,1.2,3,3.5,2.4,6c-3.1,2.9-4.8,6.1-2.7,10.4c2.1-0.3,2.4-2.4,3.7-3.5c0.4,0,0.7,0.1,0.9,0.4c0.4,0.6,0.5,1.4,0.5,2.1
			c-0.3,3.3-1.2,6.2-4.7,7.6c-0.9,0.4-1.1,1.2-1.5,2c-0.6,1.2-2.1,2-1.3,3.7c0.4,6.1,0.1,12.2,0.2,18.3c0,0.8,0,1.5,0.1,2.3
			c0,0.4,0,0.7,0,1.1c-0.2,0.7-0.5,1.3-1.3,1.6c-0.9,3.4-1.4,6.7,0.8,9.8C164.5,473.6,164.4,476.5,163.3,479.5z"
        />
        <path
          class="st164"
          d="M175.8,500.4c-0.5-0.8-1.3-1.5-1.5-2.3c-0.1-0.4,1.1-1.2,1.8-1.4c1-0.3,1.2,0.6,1.2,1.3
			C177.4,498.9,176.9,499.8,175.8,500.4z"
        />
        <path
          class="st165"
          d="M141.6,489.3c1.3,4.2,2,4.5,5.7,2.8c1.2-0.6,2.4-1.4,3.8-1.3c-7.6,5.3-15,10.8-24.4,12.6
			c0-18.9-0.1-37.8-0.1-56.7c0.8,0,1.2-0.5,1.7-1.1c2.5-3,3-2.9,5.1,0.4c0.2,2-2.1,1.1-2.8,2.6c2-1.6,4.1-0.4,6-0.9
			c2.2-0.8,3.5-3.6,6.4-2.6c0.9,1.2,3.1,1.8,1.6,3.9c1.8,2.9-1.3,3.6-2.3,5.3c-0.1,0.3-0.3,0.6-0.6,0.8c-1.3,1.2-3.5,0.9-4.4,2.7
			c-0.8,0.7-1.6,0.5-2.1-0.3c-2.1-2-3.8-1.5-4.8,1.4c-1.2,2.2-1.5,4.4,0.3,6.5c1.7,2,0.9,4.5,1.1,6.8c0.2,0.7,0.4,1.5,0.3,2.2
			c-0.5,3.1-0.3,6.1,1.7,8.7c-0.4,0.7-1.6,0.5-1.7,1.5c-0.4,0.4,0.4,0.5,0,0.2c-0.1,0,0.2-0.4,0.3-0.6c0.3-0.3,0.8-0.5,1.2-0.6
			c0.9-1.6,0.1-3.7,1.6-5c1.6-1.8,3.6-2.8,5.9-3.3c1.6-0.8,1.7-2.2,1.9-3.7c0.1-1,0.2-1.9,0.9-2.6c0.6-0.2,1.2-0.2,1.7,0.1
			c1.5,3.1-0.9,5.8-0.9,8.7c-0.5,1.7-2.4,1.7-3.5,2.6c-0.9,0.4-1.7,0.9-2.4,1.2c-0.6,0.3-0.2,0.4-0.1,0.4c0.8-1,2.2-1,3.1-1.8
			c1-0.2,1.7-1.2,2.9-0.7c0.8,1.8-0.1,3.3-0.6,4.9c-1.6,1.3-4,1.1-5.1,2.7c0.7-0.1,1.4-1.6,2.8-1
			C143.2,487.1,142.3,488.2,141.6,489.3z"
        />
        <path
          class="st166"
          d="M134.9,424.3c-1.3,1.3-1.7,0-2.3-0.8c-0.2-3.5,0.3-6.6,4.3-7.6c-0.4-2-2.5-0.8-3-2.4c1.1-1.1,2.2,0.7,3.4,0
			c-0.1-1.7-0.1-3.5-0.4-5.3c-0.2-1.4,0.6-1.7,1.6-1.9c0.5,0.5,0.6,1,0.2,1.5c0.2,1.4,1.6,4.4,2.1,4.7c2,1.2,1.3-2.3,2.9-1.7
			c4-0.6,7.5-2.7,11.3-4c1.2-0.4,2.4-1.4,3.8-0.5c0.8,1.2,0.6,2.2-0.7,2.7c-2.6,0.9-4.7,2.8-7.4,3.5c-3,0.2-5.2,2.1-7.1,3.1
			c0.4-0.6,1.3-0.5,1.9,0.6c0.5,0.9,0.2,1.9-0.7,2.2c-1.2,0.4-2.4,1.3-3.9,0.8c-1.6-0.7-1.3,0.7-1.5,1.5c-0.1,1.1-1.2-2-1.3,0.4
			C138,422.4,135.6,422.8,134.9,424.3z"
        />
        <path
          class="st167"
          d="M151.2,395.1c1.1-0.1,2.6-1,2.3,1.3c-0.4,1.6-1.6,2.6-2.4,4c-0.8,0.4-1.7,0.4-2.6,0.9
			c0.3,0,0.5-0.2,0.7-0.2c0.7-0.3,1.5-0.2,2.1-0.8c2.3-2.3,4.3-5,8.1-4.4c0.6,0.4,1.2,0.9,1.9,1.1c0.6,0.2,1.7-0.5,1.7,0.6
			c0.1,1.5-1.2,1.2-2.2,1.2c-1.6,0.3-2.9,0.9-3.6,2.5c-0.6,0.7-1.4,0.6-2.2,0.6c-1.8-0.2-3,1.1-4.6,1.5l0,0
			c-2.1,1.2-3.9,3.1-6.6,2.4c-0.8-1.2-0.9-2.5-0.8-3.9C145.4,399,149.3,398.2,151.2,395.1z"
        />
        <path
          class="st168"
          d="M183.4,370.4c0.1,1.6,1.3,1.5,2.3,1.5c0.3,0.4,0.2,0.7-0.1,1c-0.4,0.6-1.9,0.5-1.6,1.5
			c0.3,0.9,1.4,0.2,2.2,0.4c-1.4,2.1-6.6,3.9-8.6,3.1c-1.5-0.6,0-2.2-0.9-3c-1.6-1.5,1.5-1.7,0.9-3
			C179.3,370.6,181.4,370.3,183.4,370.4z"
        />
        <path
          class="st169"
          d="M158.8,406.2c-4.8,1.3-9.4,3.2-13.9,5.4c-1.2,0.6-1.5,0.4-1.2-0.9c0.3-0.3,0.6-0.5,0.9-0.8
			c9-2.8,17.3-7.2,25.7-11.4c1.1-0.5,2.2-1.2,3.5-0.6c0.1,0.2,0.2,0.4,0.2,0.6s0,0.3-0.1,0.3c-0.9,0.8-2,1.2-3,1.8
			c-0.3,0.1-0.6,0.3-0.9,0.4c-2.1,0.4-3.7,1.6-5.3,3c-0.2,0.2-0.5,0.4-0.8,0.6c-1.5,0.5-2.9,1.4-4.6,1.7
			C159.1,406.2,158.9,406.2,158.8,406.2z"
        />
        <path
          class="st170"
          d="M177.6,371.9c0.5,0,1.1-0.1,1.4,0.1c0.6,0.5-0.1,0.9-0.4,1.1c-0.9,0.4-1.9,0.5-1.8,1.8
			c-2-0.6-2.6,1.5-5.2,1.8c1.9,0.8,3.8,0.2,3.8,1.5c-0.1,1-1.3,2.2-2.7,0.9c-1,2.9-4.1,1.4-5.9,3.4c0.4-2.5,2.5-2.1,3.3-3.3
			c-0.9-1.2-0.1-2,0.7-2.8C172.4,374.1,175.4,373.7,177.6,371.9z"
        />
        <path
          class="st171"
          d="M137,448.2c-2.1,1.2-5-0.5-7,1.8c-0.3,0.3-0.8-0.8-0.4-1.6c0.8-1.7,2.7-1.5,3.9-2.5c1.5-0.5,2.4-1.5,3-3
			c0.7-0.4,1.3-1,2.2-0.8l0,0c0.1,1.5-3.2,1.7-1.5,3.8C137.7,446.8,137.7,447.5,137,448.2z"
        />
        <path
          class="st172"
          d="M150.5,386.1c1.7,2.8,1.8,2.9-1.9,4.5c-1.9,0.9-3.4,2.3-5.7,4c0.2-2.4-0.5-3.9,2.6-4.2
			C147.3,390.2,150.2,389.4,150.5,386.1z"
        />
        <path
          class="st173"
          d="M170.7,376.5c-0.2,0.9-0.4,1.9-0.7,2.8c-0.9-0.2-1.5-0.8-2.6,0c-2.5,1.7-5.2,3-7.9,4.5
			c-0.4-0.5-1.1-0.2-1.5-0.7C162,380.4,166.3,378.5,170.7,376.5z"
        />
        <path
          class="st174"
          d="M153.5,390.4c0.5,0.1,1.2,0,1.4,0.3c0.5,0.9-0.6,1-0.9,1.1c-3.8,1.4-6.9,4-10.3,6.1c-1-1.4-1-1.4,0.4-2.2
			C147.2,393.9,150.3,392.1,153.5,390.4z"
        />
        <path
          class="st175"
          d="M138.6,407.7c-0.1-0.5-0.1-1-0.2-1.5c-1.9-2.4-0.7-4.4,0.6-6.4c1.8,1.8-1,4.6,1.7,6.4
			C141.5,406.8,139.6,407.4,138.6,407.7z"
        />
        <path
          class="st176"
          d="M151.2,395.1c-1.6,3.6-4.9,5.1-8.1,6.6c-0.5-1.9,1.1-2.3,2.1-3.1C147.6,398,149.2,396.1,151.2,395.1z"
        />
        <path
          class="st54"
          d="M193.2,363.6c-1.4,0.8-1.4,3.4-3.8,2.9c-0.6-0.8-2-1.5-0.6-2.6C190.2,362.8,191.7,362.6,193.2,363.6z"
        />
        <path
          class="st177"
          d="M143.9,405.6c2.5-0.1,4.1-2.4,6.6-2.4c-1.1,2.7-3.8,2.9-6,3.8C144,406.7,143.9,406.2,143.9,405.6z"
        />
        <path
          class="st178"
          d="M155.8,387.5c-0.7,0.3-1.5,0.6-2.2,0.9c-1.7-2,1.7-2.4,0.6-4.4C156.2,385,155.7,386.3,155.8,387.5z"
        />
        <path
          class="st179"
          d="M133.4,403.5c0-1.5,0-3,0-4.5c0.2,0,0.5,0,0.8,0c0,1.5,0,3,0,4.5C133.9,403.5,133.7,403.5,133.4,403.5z"
        />
        <path
          class="st180"
          d="M158,383c0.6,0,1.2,0.1,1.5,0.7c-0.7,0.3-1.8,0.3-1.1,1.6c0.6,1.2-0.1,1.4-1.1,1.4
			C157.4,385.5,155.4,383.8,158,383z"
        />
        <path
          class="st181"
          d="M139.4,391.9c-0.1,1.5,0.8,3.2-0.7,4.8C138.5,394.9,138.3,393.4,139.4,391.9z"
        />
        <path
          class="st182"
          d="M289.7,455.6c0,0.2,0,0.5-0.1,0.8c-0.5-0.1-0.8,1.7-1.4,0.2c-0.4-0.9-0.5-1.9-1-2.8c-0.4-0.8-1-1.6-1.9-1.6
			c-1,0-0.8,1.1-1,1.8c-0.4,2-3.5,2.6-2.5,5.4c0.2,0.6-0.9,0.2-1.5,0.2c-2.7,0.1-4,1.6-3.8,4.2c0,0.5,0.4,1.2,0,1.3
			c-3.1,1.1-4.7,3.9-7.2,5.6c-1.7,1.1-3.2,1.8-3.6,4.1c-0.4,2.5-3.2,4.6-4.4,4.8c-2.6,0.4-2.1,1.6-2.3,3.1c-0.2,2.2-0.5,4.4-0.9,6.8
			c-1.5-0.8-0.6-1.8-0.6-2.6c0-1.7,0-3.5-1.9-4.1c-2.1-0.6-1.6,1.7-2.4,2.7c-0.3,0.4-0.6,0.7-0.9,1.1c-1.5-0.7-0.5-3.5-2.7-3.1
			c-1.5,0.3-1.9,1.7-2.1,3.2c-0.1,0.9-0.7,1.8-1.8,1.4c-1-0.4-0.5-1-0.3-1.8c0.5-1.6-0.6-2.8-1.6-3.9c-1.2-0.7-1.6-1.5-0.3-2.5
			c7.1,1.8,10.9-0.1,15-7.4c4-1.3,5.9-3.8,5.5-8.4c-0.3-3,2.6-6.1,5.6-7c0.9-0.2,1.6-0.6,2.3-1.2c3.9-1.3,4.9-5.3,7-8.1
			c1-1.4,2.5-2.7,3.1-4.4c0.5-1.1-0.4-2.7,1.3-3.3c4,1,4.9,4.8,6,7.7C290.2,450.2,290.6,453.1,289.7,455.6z"
        />
        <path
          class="st183"
          d="M211.2,483.3c0-7.2,0-14.5,0-21.7c1.2-1.6,2.2-1.7,2.6,0.5c0.8,4.4,0.7,4.4,5.3,4.3c3.9,0,1.7,3,2.5,4.9
			c3.8-2,5,2.3,7.7,3.3c1.3,0.5,1.9,1.1,3.2,0.3c1.1-0.6,2.4-0.8,3.5,0.1c1.2,1.5,0,3.1,0.2,4.6c0.1-1.3,0-2.6,1.5-3.2
			c1.9,1.2,2.3,3.7,4.2,4.9c0.9,0.6,0.6,1.8-0.1,2.6c-1.4,0.6-2.5,1.4-2.4,3.5c-1.4-2.3-2.3-4.9-5.1-2.3c-0.7,0.6-1.1,0.7-1.7-0.2
			c-1.6-2.5-3.4-1.9-5.2-0.1c-1.7-5-2.5-5.3-6.6-2.6c-0.9-1,0.7-3.3-1.5-3.4c-1.7-0.1-3.2,0.8-3.8,2.5
			C214.7,483.7,213.3,484.2,211.2,483.3z"
        />
        <path
          class="st184"
          d="M241.8,484c0.6-2.3-1.7-3.1-2.7-4.3c-0.9-1.1-0.9-2-1.1-3.1c0.1-0.1,0.2-0.3,0.3-0.5c0.9-0.8,1.8-1.1,3-0.9
			c1.9,0.7,2.9,2.1,3.2,4c0,0.4-0.1,0.7-0.4,1c-1.2,0.7-0.4,1.5-0.1,2.3C243.6,483.5,242.9,484.1,241.8,484z"
        />
        <path
          class="st185"
          d="M216.3,503.6c1-0.3,2-1.8,3,0c0.9,1,0.6,2,0,3c-1,0.7-2,1.5-3,2.2C215.2,507,215.5,505.3,216.3,503.6z"
        />
        <path
          class="st186"
          d="M219.4,506.5c0-1,0-2,0-3c1.2-0.6,1.2-2.8,3.5-2.1C220.9,502.7,221.6,505.5,219.4,506.5z"
        />
        <path
          class="st187"
          d="M216.3,503.6c0,1.7,0,3.4,0,5.1c-0.6,0.2-1.6,0.9-1.5-0.4C215,506.7,214,504.6,216.3,503.6z"
        />
        <path
          class="st188"
          d="M336,408.5c-1.6,4.4,0.2,7.8,4.5,8.2c0.2,1-0.4,1.3-1.1,1.4c-1.4,0.3-1.5,1.2-1.4,2.3
			c-0.2,0.5-0.5,0.9-1.1,0.9c-1.7-1.1-3-0.1-4.4,0.6s-2.9,1.4-4.4,1.9c-0.8,0.3-1.6,0.5-2.3,1c-2.6,1.5-5.3,3-8.1,4.2
			c-0.6,0.3-1.5,1.2-1.8-0.4c0.3-0.5,0.5-1,0.8-1.5c1-2.3,3.7-2.4,5.3-4c-0.8-1.6,0.3-3.3,0-5c2.3-2.4,5.2-3.9,8.2-5.4
			c1.7-1.1,4.4-1.4,3.8-4.5C334.5,407.9,335.3,407.8,336,408.5z"
        />
        <path
          class="st189"
          d="M303.1,453.4c-2.6,0-4.7,1.3-6.9,2.8c1.1-1.7,0.7-3.2,0.1-4.9c-0.8-2.5,0.8-5-0.4-7.6
			c-0.6-1.5,1.7-1.7,3-1.7c0.7,0,1.7,1.9,2.2,0.1c0.4-1.2-1.3-1.2-2.1-1.6c-0.4-0.2-0.7-0.6-1.1-1.2c3.1,1,4.2-3.4,7.3-2.3
			c1.2,0.2,2.7-0.8,3.3,1c-0.3,2.6-3.1,2.9-4.2,4.7c-0.7,0.9-1.6,1.4-3.2,1.9c2.7-0.3,3.6,1.2,4.4,2.8c-1.6,1.2,0.1,2.2,0.2,3.3
			c-0.3,1.2-1.4,1.7-2.2,2.5C303.4,453.2,303.3,453.3,303.1,453.4z"
        />
        <path
          class="st189"
          d="M309.8,457.1c-0.9,0.6-1.9,0.9-3,0.7c-1.3-3.9-3-1.2-3.9-0.9c1.2-1.3,2.6-1.3,3.1,1.6
			c-2.6,2.9-6.2,3.5-9.7,4.6c-1-4.4,1.7-6.6,5-8.4c0.6-0.4,1.9,0,1.8-1.4l0,0c0.7-0.6,1.5-0.8,2.4-1c1.3-0.4,2.8-1.8,4-0.1
			C310.6,453.8,311.2,455.4,309.8,457.1z"
        />
        <path
          class="st190"
          d="M313.6,414.5c1,0,2,0,3,0c1,0.8,0.5,1.9,0.6,2.8c-3.4,3.3-7.8,4.8-11.9,6.9c-0.2-0.1-0.2-0.3-0.2-0.4
			s0.1-0.2,0.2-0.2c1.8-2.4,1.8-2.4,0.8-3c-1.6-0.9-0.7-1.6,0-2.6c0.6-1,2.5-0.1,2.2-1.9c0.4-0.6,1.3-1,1.5-0.7
			C311.8,418,312.5,415.2,313.6,414.5z"
        />
        <path
          class="st191"
          d="M321.9,423.3c-0.9,2.4-3.5,2.6-5.3,4c-1.7,0-3.5-0.2-4.8,1.3c-0.4,0.4-1,1-1.5,0.3
			c-0.5-0.6-0.2-1.2,0.5-1.5c1.3-0.6,3.1-0.5,3.3-2.4c0-0.1-0.6-0.6-0.8-0.5c-1.6,0.8-3.6,1-4.7,2.7c-0.4,0.7-0.3,1.8-1.9,1.5
			c0.3-1.1,0.5-3.2,1-3.3c2.7-0.4,4.4-2.8,7-3.2c0.2,0,0.6,0.1,0.7,0.2C316.5,429.3,319.9,421.3,321.9,423.3z"
        />
        <path
          class="st192"
          d="M315.8,428.7c3.5-1,6.5-2.9,9.7-4.7c0.7-0.3,1.4-0.3,1.8,0.5c-0.4,2.2-2.9,2.1-4.1,3.5
			c-0.3,0.3-0.7,0.7-1,1c-0.7,0.7-0.4,1.8-0.9,2.6c-1.5,0.8-2.4,3-4.7,1.8c-0.2-0.3-0.3-0.6-0.3-1c0.6-1.3,0.6-1.4-0.3-1.4
			c-1.4,0.1-2.5,0.1-1.8-1.8C314.3,428.1,315,428.3,315.8,428.7z"
        />
        <path
          class="st193"
          d="M330,413c-2.5,2.1-5.2,3.9-8.2,5.4c-2.2,0.3-2.9,2.6-4.7,4.4C317.6,418.9,326.3,412.9,330,413z"
        />
        <path
          class="st192"
          d="M312,431.8c-2.3-0.6-3.9,1.2-5.9,1.9c-1.8,0.6-3.6,1.5-5.5,1.9c3.6-1,4.7-6.3,9.7-4.8
			c1.4,0.4,1.9-1.5,3.3-1.4C314.3,431,313.7,431.8,312,431.8z"
        />
        <path
          class="st194"
          d="M308.4,416c1.6,0.4,1,0.9,0,1.4c-1.4,0.6-3,1.1-2.2,3.2c-1.5,1.3-3.1,2.6-5.3,2.2c-0.6-1,0.2-1.6,0.8-2.2
			C303.9,419,305.6,416.8,308.4,416z"
        />
        <path
          class="st195"
          d="M312.6,409.7c-1.4,4.4-5.7,4.5-9,6C306.1,413.1,309.8,412.1,312.6,409.7z"
        />
        <path
          class="st196"
          d="M312,431.8c1-0.5,1.6-1.2,1.5-2.3c0.2,0,0.5,0,0.7,0c0.1,1,0.5,1.6,1.7,1.1c1-0.5,2-1.4,3.5-0.5
			c-1,1.1-2,2.2-2.9,3.2c-1.8,1.6-3.4,2.1-4.5-0.7C312.1,432.2,312,432,312,431.8z"
        />
        <path
          class="st197"
          d="M308.3,437.7c-0.8-1-2.5,1.4-3-0.8c1.8-1.2,3.6-2.6,6-2.3c1.6,1.1,1,2.2,0.1,3.3c-0.3,0.2-0.7,0.4-1,0.4
			C309.6,438.5,308.9,438.3,308.3,437.7z"
        />
        <path
          class="st198"
          d="M302.5,415.6c-1.6,3.2-4.8,3.1-6.8,4.9C295.3,417.8,295.3,417.8,302.5,415.6z"
        />
        <path
          class="st199"
          d="M336,408.5c-0.8,0-1.5,0-2.3,0c0-0.3,0-0.5-0.1-0.8c0.2-1.8,1.3-2.6,3-2.2c0.6,0.1,1,0.7,1.4-0.1
			c0.3-0.6,0.5-1.7,1.4-0.6c0.5,0.6,0.4,1.5-0.4,1.9C338.1,407.4,337.1,407.9,336,408.5z"
        />
        <path
          class="st192"
          d="M311.3,437.6c0.1-1,0.7-2,0-3c0.9-0.5,0.6-1.4,0.7-2.2c1.2,2.2,3,0.5,4.5,0.7l0.1,0.1
			c-0.7,2-1.5,3.9-3.3,5.1C312.5,438.4,311.8,438.3,311.3,437.6z"
        />
        <path
          class="st200"
          d="M317.2,417.3c-1-0.8-0.5-1.9-0.6-2.8c2.2-0.5,4.1-1.6,5.9-2.9c0.7,1.1,0.2,1.6-0.7,2.3
			C320.2,415,318.3,415.7,317.2,417.3z"
        />
        <path
          class="st201"
          d="M318.1,407c0.9,0.2,1.6-1.3,2.6-0.3c-1.3,0.8,0.9,2.9-1.3,3.5c-1.2,0.4-2.3,1.3-3.5,2
			c-1.5-0.2-1.5-0.7-0.3-1.4C317.2,410.1,318.5,409.1,318.1,407z"
        />
        <path
          class="st202"
          d="M301.6,420.6c-0.2,0.7-0.5,1.4-0.8,2.2c-1.5-0.2-2.1,1.8-3.7,1.4C297.9,420.5,297.9,420.5,301.6,420.6z"
        />
        <path
          class="st203"
          d="M300.1,425.7c0,1-0.1,1.9-0.1,2.9c0,1.1-0.8,1.6-1.7,1.5c-1.6-0.2,0-1-0.2-1.5c-0.5-1.3-1.8,0.9-2.3-0.8
			C297.3,427.3,298.3,425.9,300.1,425.7L300.1,425.7z"
        />
        <path
          class="st204"
          d="M305.4,423.5c0,0.2,0,0.4,0,0.7c-1.5,1.5-2.8,3.4-5.2,1.5l0,0C301.9,425,303.6,424.3,305.4,423.5z"
        />
        <path
          class="st205"
          d="M296.8,433c1.8-0.9,3.4-1.7,5.2-2.5C300.7,433.1,300.4,433.2,296.8,433z"
        />
        <path
          class="st206"
          d="M289.7,455.6c0.4-5.1-1.9-9.3-4.3-13.5c-0.5-0.9-1.3-1.5-2.4-1.5c-2.8-0.8-3.5-2.3-1.6-4.7
			c1.1-1.3,1.2-2.9,1.5-4.4c0.5-2.5,1.8-4,4.5-3.8c4.3,0.4,3.2,4.2,3.3,6.4c0.2,4.9,0.5,9.9-0.3,14.8
			C290,451.1,291.1,453.5,289.7,455.6z"
        />
        <path
          class="st207"
          d="M290.4,448.9c-0.3-5.9,0.4-11.8-0.6-17.7c-0.4-2.2-1.3-2.6-3.1-2.5c-1.2-1.1-3.7-0.6-3.8-3.1
			c0-2.6,0.4-5.2,1.2-7.7c0.1-0.1,0.3-0.3,0.5-0.4c0.5,0,1-0.1,1.5,0c1.8,0.5,3.7-2.2,5-0.3c0.8,1.2,0.1,3.4,0.2,5.1
			c0.3,3.9-0.7,7.9,0.6,11.7c0,0.8,0,1.5,0,2.3c-1.3,2.7-0.3,5.6-0.6,8.4C291,446.1,291.7,447.7,290.4,448.9z"
        />
        <path
          class="st208"
          d="M205.9,460.9c-3.5,3.1-8.1,4.3-12,6.7c-1.4-0.1-2.6,0.3-3.9,0.6c-1.6,0.4-3,0.1-2.8-2.1
			c0.4-3.7-0.8-7.5,0.7-11.2c-1.1-2,0.1-4,0-6c2.5-3.8,6.7-4.6,10.5-6.2c4.4-1.5,4.8,1.5,6.4,4.5
			C207.3,451.9,206.6,456.3,205.9,460.9z"
        />
        <path
          class="st209"
          d="M198.3,443.6c-3.6,1.5-7.1,3.3-10.3,5.4c-8,3.4-15.2,8.3-23,12.2c-0.9,0.5-2,2.2-2.6-0.3
			c0.3-0.5,0.6-1.1,0.9-1.6c11.9-5.9,23.2-12.9,35.1-18.9c1.7-0.9,4.1-3.3,5.3-0.7c1,2.2-2.4,2.4-4,3.3
			C199.2,443.2,198.7,443.4,198.3,443.6z"
        />
        <path
          class="st210"
          d="M163.3,479.5c0-3-0.1-6-0.1-8.9c0-2,0-4.1,0-6.2c1.9,4.9,1.9,9.8,1.1,14.8
			C164.3,479.4,163.6,479.4,163.3,479.5z"
        />
        <path
          class="st211"
          d="M187.9,455c-0.4,3.7,0.9,7.5-0.7,11.2c-1.4-1.3-0.6-3-0.7-4.5c-0.1-1.2,0.6-2.1-1.2-3.4
			C183.8,457.1,186.6,455.8,187.9,455z"
        />
        <path
          class="st212"
          d="M176.3,470.7c1.7-1.1,3.5-2.2,5.8-3.8C180.8,470,178.5,470.4,176.3,470.7z"
        />
        <path
          class="st213"
          d="M173.2,470.7c-0.4-1.2-0.2-2.4,0.5-3.5C175.4,469.5,175.4,469.5,173.2,470.7z"
        />
        <path
          class="st214"
          d="M198.3,443.6c1.2-1.9,4.1-1.7,5.1-3.9c-1.2-1.1-2,0-2.7,0.4c-10.7,6-21.8,11.3-32.2,17.8
			c-1.6,1-3.3,1.8-5.2,1.4c0-0.2,0-0.4,0-0.5c0.1-0.3,0.3-0.6,0.6-0.8c1.5-0.9,1.6-2.5,1.8-4c1.1-5.9-1.1-11.8-0.4-17.7
			c0-0.4,0.1-0.8,0.2-1.2c0.2-0.6,0.5-1.1,1.1-1.4c5.9-2.7,11.4-6.3,17.5-8.6c1,0.1,1.9,0.3,2.9,0.3c2.1,0,2.1,1.2,1.6,2.7
			c-0.4,0.6-0.6,1.3-0.9,1.4c0.9-0.9,2.1-2.1,4-1.8c0.6,0.2,0.9,0.5,1,1.1c-0.2,1.8-1.9,2-3,2.8c-1.3,0.6-1.8,2-3.1,2.9
			c1-0.1,1.7-0.5,2.5-0.1c0.3,0.2,0.5,0.5,0.5,0.9c-0.3,1.1-1.1,1.6-2,2.1c-1.2,0.7-2.2,1.6-1.4,3c0.8,1.5,2.2,0.6,3.3,0.2
			c1.6-0.7,3.2-1.3,4.9-1.9c1.6-1.1,4.2-0.7,4.5-3.4c0.1-1.5,0-3.1,0.2-4.6c0-0.3,0.1-0.6,0.2-1c0.5-1.3-1.1-2.3-0.6-3.6
			c0.3-1.4,0.2-2.8,0.2-4.1c0-1.2,0-2.4,0.1-3.6c-1.3-1.8-2.4-0.1-3.3,0.3c-9.2,4.5-18.1,9.4-27.3,14c-1.3,0.7-2.6,2-4.4,1.3
			c0-1,0-2.2,1.1-2.6c3.6-1.1,4.5-4.1,5-7.3c0.7-0.7,1-1.6,2.3-2.5c-0.2,0.1-0.8,0-0.9,0.1c-0.3,1-0.9,0.8-1.6,0.3
			c-0.2,0-0.3,0-0.5,0c-0.8-0.4-0.9-0.2-1.1,0.7c-0.3,2.2-1.2,3.5-3.5,1.6c-0.5-3.4-0.5-6.8,2.9-9c0-2.3,0.5-4.8-2.2-6
			c-0.1-1.9,1.9-1.7,2.6-2.8c2.6-1.3,3.6-3.4,2.7-6.2c0.5-1.5,1.9-1.3,2.9-1.7c1.9,3.6,4.4,1.7,6.7,0.6c1.2-0.7,2.2-1.9,3.8-1.1
			c2-0.5,3.8-1.2,5.2-2.9c3.2-2.2,5.9-5,9.7-6.1c2.8,1.2,3.7-1.7,5.5-2.7c0.7-0.4,1.2-1.3,2.2-0.9c1.2,0.5,1,1.5,0.6,2.5
			c-0.4,1.1-0.4,2.3,1.2,2.5c1.3,1.1,1.1,2.7,1.1,4.1c0.1,4.9,0.1,9.9-0.4,14.8c-0.1,0.4-0.4,0.8-0.8,1.1c-1,0.6-2,0.9-1.4,2.8
			c1.8,4.8,1.5,10,1.3,15c-0.2,5.9-0.6,11.8,0.1,17.7c0,4.7,0,9.4,0,14c-1,0.3-1.9,0.6-2.9,0.8c-0.3-2.3-0.8-4.5-0.1-6.9
			c0.6-2-0.3-4.1-1.7-5.8c-0.5-0.6-1.5-1.3-1.4-1.6C204.4,440.8,199.8,444.5,198.3,443.6z"
        />
        <path
          class="st215"
          d="M216.3,435.4c-0.8-1.2-1.1-2.6-0.6-3.9c1.2-3.3,0.6-5.1-3.4-4.3c0-0.6,0-1,0.1-1c4.4-0.6,4.6-3.9,4.8-7.4
			c0-1.5,0.4-2.9,0.6-4.4c-0.5-0.6-0.6-1.3-0.3-2c3.6-2,3.6-5.5,3.5-9c1.1-1.7,2.1-3.4,1.5-5.6c0.7-2.7,2-1,3.2-0.7
			c-0.1-1.4-1.3-3.4,1.6-3.9c1.2-0.2,0.7-1.4,0.8-2.1c0.1-1.2,0.5-2.5,1.8-2.5c2.6-0.1,3.9-3.2,6.6-2.8c0.5,0.1,0.9-0.4,1-0.9
			c1.1-5.6,6.4-5.5,10.1-7.6c2.1-1.2,3.6-1.7,4.8,0.9c0.4,0.9,0.2,1.8-0.3,2.6c-0.2,0.3-0.5,0.6-0.7,0.8c-4.8,4.4-11,6.9-15.3,11.8
			c-7.2,8.3-12.9,17.4-15.4,28.2c-1.7,4.2-2.3,8.5-2.9,13C217.4,435.1,217.1,435.6,216.3,435.4z"
        />
        <path
          class="st184"
          d="M235.8,475.1c-1.1,0.2-2.2,0.4-3.2,0.7c-1.3,0.4-2.4,2.4-3.6-0.5c-0.5-1.2-3.6-0.6-4.5-3.3
			c-0.4-1.4-2.3,0.9-3.5,1.4c-1.1-1-0.9-2.3-0.6-3.2c1.4-3.6-0.7-2.9-2.8-2.4c-3.1,0.7-5.4-1.5-4.7-4.6c0.6-2.2,0-2.2-1.6-1.4
			c0-0.5-0.1-1-0.1-1.5c0-2.7,0.3-5.5,0-8.2c-0.3-2.8,0.6-4.8,3-6c2.7-1.6,3.6-1.3,3.9,1.7c0.2,1.3,0.4,2.7-1,3.6
			c-0.3,0.2-0.6,0.4-0.9,0.6c-0.7,0.6-1.1,1.5-1.9,2.2c1.8-0.5,3.1-2.1,5.1-1.5c0.4,0.4,0.5,0.9,0.4,1.5c-0.7,1.5-1.5,2.5,0.7,3.8
			c1.8,1.1,2.8,3.4,3.2,5.7c0,0.2,0.3,1.5,0.7,0.9c1.6-2.9,2.7-0.5,4,0.2c0.8,1.5-0.5,2.5-0.9,3.7c-0.4,0.2,0.7,0.4,0.3,0.2
			c1.9-1.8,3.5-0.8,5.1,0.4c0.7,0.9,1.1,1.9,2.6,1.6c0.9-0.2,2-0.7,2.7,0.4c0.6,1.2-0.3,2.1-0.9,3c-0.3,0.4-0.6,0.8-1,1.1
			C236.1,475,235.9,475.1,235.8,475.1z"
        />
        <path
          class="st216"
          d="M217.9,412.2c0,0.7-0.1,1.5-0.1,2.2c-2.4,0.2-5.1-0.7-6.7,3.5c0-4.8,0-8.2,0-11.7c-0.2-0.3-0.3-0.7-0.2-1
			c1.3-6,6.8-6.8,11.2-8.9c0.9,0.2,0.5,1.3,1.1,1.8c1.1,2.2,1,4-1.5,5.2c-1.4,1.2-2.3,2.7-2.3,4.7c0,1.1-0.2,2.4-1.3,2.8
			C216.5,411.4,218.9,411.8,217.9,412.2z"
        />
        <path
          class="st217"
          d="M208.3,410.1c0.2,0,0.4,0,0.6-0.1c0.7-8.5,0.6-17,0-25.4c0.6-1.7-1.4-4,1.5-5c0.8,0.7,1,1.7,1,2.7
			c0.1,6.8,0.1,13.6,0,20.5c0,1.2-0.4,2.5-0.9,3.6c0,17.2,0,34.4,0,51.6c-1.5-4.5,0-9.1-1.4-13.6c-1.3-1.2-0.8-2.8-0.8-4.2
			c-0.1-7.7,0.4-15.4,0-23c-0.1-1.3,0.4-2.6-1-3.7C204.9,411.4,206.6,410.7,208.3,410.1z"
        />
        <path
          class="st109"
          d="M208.3,410.1c-0.7,0.8-3.4,1.5-0.2,2.6c1.7,0.6,0.9,2.1,0.9,3.2c0.1,9.5,0,18.9,0,28.4
			c-0.1,0.6-0.1,1.2-0.2,1.7c-1-0.3-1.4-0.9-1.4-1.9c0.3-5.6,0.3-11.2,0.3-16.7c0-4.7,1.3-9.9-1.9-14.5
			C204.1,410.6,206.2,409.9,208.3,410.1z"
        />
        <path
          class="st218"
          d="M219.4,454.1c-0.1-0.5-0.1-0.9-0.1-1.4c-0.9-0.7-2.9,0.7-3-1.6c1.9-1.6,1-3.7,0.4-5.4
			c-0.3-1-1.7,0.3-2.6,0.2c-0.8-0.2-1.6-0.4-0.5-1.2c3.7-2.8,4.3-4.9,2.7-9.3c0.3-0.2,0.5-0.5,0.8-0.7c2.1,0.5,1.5,2.2,1.7,3.5
			c-0.6,4.7,1.5,8.8,2.9,13c0.3,1.5,1.6,2.9,0.6,4.6C220.6,456.7,219.8,455.8,219.4,454.1z"
        />
        <path
          class="st219"
          d="M210.4,406.2c0-8.9-0.1-17.8-0.1-26.7c0-0.1,0-0.3,0-0.4c3.2,2,1.4,5.2,1.9,7.8c-0.3,6.2,1.1,12.5-1.1,18.5
			c0,0.2,0,0.5-0.1,0.8C210.9,406.2,210.7,406.2,210.4,406.2z"
        />
        <path
          class="st103"
          d="M342,449.6c0.9-1.9,2.5-2.4,4.5-2.3C345.6,449.4,344,449.9,342,449.6z"
        />
        <path
          class="st54"
          d="M211.1,386.8c-0.4-2.5,0.7-5.2-0.8-7.7c-2.6-4-0.2-8.4-0.7-12.5c1.3,0,2-0.9,2.8-1.7
			c1.4-1.6,2.5-1.2,3.6,0.3c1.7,2.5,3.6,1.9,5.6,0.4c0.1-0.9-0.6-1.9,0.2-2.8c2.1-0.1,4.2,0.3,6.1-0.9c0.4-0.2,1.3-0.4,1.3-0.4
			c1.5,3.5,4.5,3,7.4,2.8c-0.2,0.6-0.2,1.3-0.4,1.9c-0.6,1.5-3.4,1.7-2.4,4.1c-0.9,1.2-0.1,2.6-0.7,3.9c-1.8,1.5-3.4,3.1-4.1,5.4
			c-0.5,1.5-1.4,3.1-2.8,3.8c-2.2,1.2-3.6,2.5-3.5,5.3c0.1,1.9-1.8,2.8-3.5,3.3s-3.2,0.2-4.2-1.4
			C213.8,389.3,211.9,388.6,211.1,386.8z"
        />
        <path
          class="st220"
          d="M199.2,389.1c-2.7,2.8-6.5,4.1-9.7,6.1c-0.3-2.6,3.2-1.6,3.6-3.7c0.1-0.4,1.4-0.2,0.9-1.1
			c-0.4-0.6-1.1-1-1.9-0.8c-0.6,0.1-0.2,1.8-1.3,0.9c-0.7-0.6-0.8-2-0.3-2.4c1.9-1.1,2.7-3.8,5.5-3.6
			C198.7,384.8,199,386.9,199.2,389.1z"
        />
        <path
          class="st221"
          d="M177.6,377.9c3.4,0.3,5.7-2.3,8.6-3.1c2.3-1.2,4.6-2.5,7.7-4.2c-1.8,3.2-4.1,4.1-6.1,5
			c-5.7,2.5-11.2,5.3-16.4,8.6c-0.6,0.4-1.4,0.2-2.1,0.3l0.1,0.1c1-2.7,3.9-2.5,5.8-4C176,379.8,177.4,379.5,177.6,377.9z"
        />
        <path
          class="st222"
          d="M175.4,384.7c-0.7,4,0.4,5.2,4.1,2.9c-2.1,2.8-4.7,4-7.2,5.5c-0.5,0.3-1.2,0.6-1.4-0.4
			c-0.1-0.5,0.1-1.1,0.6-1.1c3.6,0,2.6-2.4,2.4-4.5c-1.4-0.5-1.8,1.9-3.2,1.1C171.5,386.8,171.5,386.8,175.4,384.7z"
        />
        <path
          class="st223"
          d="M192.5,380.1c0.5-1.9,3.5-0.6,3.8-3.2c0.1-0.7,1.2-0.6,0.6-2.1c-0.4-0.4-1.2-0.1-1.5-0.8
			c1-0.5,2.1-0.9,3.1-1.4c0.4,0.4,0.7,0.9,0.7,1.5c-0.8,1.1-1.1,2.1,0.9,1.9c1.2-0.1,1.9-1.6,4.4-1c-4.1,2.1-7,4.4-10.6,5.8
			C193.4,380.5,192.9,380.5,192.5,380.1z"
        />
        <path
          class="st224"
          d="M180.5,399.6c-2.1,1.2-4.1,2.4-6.4,3.8c-0.2-1.8,1.3-3.4-0.3-4.8c0-0.2,0-0.4,0-0.7
			c0.5-2.3,1.8-1.6,2.7-0.5c1.1,1.4,2.4,1.9,4,1.4c0.2,0.1,0.3,0.3,0.2,0.5C180.6,399.5,180.6,399.6,180.5,399.6z"
        />
        <path
          class="st225"
          d="M151.3,400.3c-0.9-2.3,1.4-2.6,2.2-3.9c4.5-1,8.1-3.9,13.1-6.2c-2.1,3.4-5.5,3.4-7.1,5.7
			C156.1,396,154.2,399.1,151.3,400.3L151.3,400.3z"
        />
        <path
          class="st219"
          d="M208.9,384.5c1.3,1.4,0.7,3.1,0.7,4.6c0.1,6.1,0.1,12.1,0.1,18.2c0,0.9,0.4,2-0.8,2.6c0-6.5,0-13-0.1-19.4
			C208.8,388.5,208.9,386.5,208.9,384.5z"
        />
        <path
          class="st226"
          d="M180.5,399.6c0-0.2,0-0.5-0.1-0.8c0.4-1-1.1-2.4,0.5-3.3c0.4-0.2,0.8-0.6,0.2-1.1c-0.4-0.3-2.2,0.5-1-1.1
			c0.4-0.5,0.7-1.1,1.4-0.9c0.8,0.2,1.1,1,1.1,1.7c-0.1,1.6,1.6,2.4,1.6,4C183,398.5,181.8,399,180.5,399.6z"
        />
        <path
          class="st227"
          d="M162.7,387.8c-1.6,3-3.7,5.2-7.3,5.4C157.7,391.2,160.3,389.6,162.7,387.8z"
        />
        <path
          class="st228"
          d="M185.7,372.9c0-0.3,0.1-0.7,0.1-1c1.7-1.6,4-2.2,6.3-3C190.5,371.1,187.9,371.7,185.7,372.9z"
        />
        <path
          class="st229"
          d="M199.2,374.1c-0.6-0.3-0.8-0.8-0.7-1.5c2.1-0.4,3.4-2.3,5.7-3.1C203,372.1,200.7,372.7,199.2,374.1z"
        />
        <path
          class="st230"
          d="M157.3,401.2c-0.3-0.1-0.6-0.3-1-0.4c1.4-1,2.2-3.4,4.6-2.1C160.1,400.1,158.4,400.2,157.3,401.2z"
        />
        <path
          class="st231"
          d="M195.4,374c0.6,0.1,1.3,0,1.5,0.8c-1.6,0.8-3.2,1.5-5.5,2.6C192.4,375,194.4,375.1,195.4,374z"
        />
        <path
          class="st232"
          d="M178.5,383.3c1.8-0.9,3.6-1.8,5.4-2.7C182.8,382.7,180.9,383.3,178.5,383.3z"
        />
        <path
          class="st230"
          d="M150.6,403.3c1.7,0,2-4.2,4.6-1.5C153.8,403,152.3,403.6,150.6,403.3z"
        />
        <path
          class="st233"
          d="M200,364.6c-0.4,2.3-2.1,2.9-4.1,3.5C196.7,366.1,198.9,366,200,364.6z"
        />
        <path
          class="st234"
          d="M169.2,384.5c-1.3,1.3-2.8,2.1-5.7,2.2C165.9,385.4,167.3,384.2,169.2,384.5
			C169.3,384.6,169.2,384.5,169.2,384.5z"
        />
        <path
          class="st235"
          d="M192.5,380.1c0.5,0,1.1,0,1.3,0.6c-1.5,0.8-3.1,1.6-5.1,2.8C189.6,381.1,191.6,381.3,192.5,380.1z"
        />
        <path
          class="st236"
          d="M289.6,341.1c0,8.8,0,17.7,0,26.5c0,0.9,0.1,1.8-0.1,2.6c-0.5,1.7-2.1,2.1-3.5,2.5c-2,0.6-0.8-1.6-1.6-2.2
			c-0.6-0.5-0.8-1.4-1.3-2.2c-1.4,0.6-2.1,2.8-3.8,1.8c-2-1.4,1-3.5-0.5-5.2c-1.9,0.4-4.1,5-6,0c-1,0.3-1.3,1.2-1.9,1.8
			c-1.3,1.2-2.3,1-2.4-0.9c-0.1-1.8,0-2.8-2.3-1.1c-1.4,1.1-3.8,0.9-5.7,1.3c-2.3,0.1-4.4-1.1-6.6-1.2c-1-0.8-1-3.2-3.2-2.3
			c-1.8,0.8-2.7,2-2.3,4.1c0.2,1.1,0.1,2.4-1.3,2.7c-1.7,0.4-2.2-0.9-2.5-2.1c-0.4-1.6-2.1-2.5-2.1-4.3c0-0.2,0-0.4,0-0.6
			c1.1-0.6,2.6-0.1,3.5-1.3c1.1-0.5,2.1-1.2,3.3-1.1c3.6-2.1,8.1-2.6,10.8-6.3c0.5-0.8,1.6-0.4,2.5-0.4c0.4,0.1,0.5,0.3,0.3,0.6
			c-0.5,2.8,1.9,2.1,3.1,2.2c1.8,0.2,4-1.1,4.7,1.9c0,0.2,0.8,0.5,0.9,0.4c3.4-2.5,5.8,1.2,8.8,1.3c1.5,0.1,1.7,1.3,2.3,2.1
			c0.7,0.8,1.1,2.2,2.5,1.7c1-0.4,1.4-1.5,1.4-2.4c0.2-5.3,1.9-10.5,1.6-15.9C288.1,343.6,287.9,342,289.6,341.1z"
        />
        <path
          class="st237"
          d="M270.2,350.3c-0.2-1.6-0.5-3.1-0.8-4.7c0.8,0.3,2.2-0.9,2.2-0.2C271.8,347.1,272.2,349.1,270.2,350.3z"
        />
        <path
          class="st238"
          d="M262.9,353.8c-0.1-0.2-0.2-0.5-0.3-0.6c0.2-1.5-0.5-3.3,1.7-4.3c0,1.5,0,2.9,0,4.3
			C263.8,353.3,263.3,353.6,262.9,353.8z"
        />
        <path
          class="st238"
          d="M265.7,352.4c-0.6-2.1,5.1-2.2,1.3-5.6c2.1,1.1,1.5,2.8,1.8,4.1C267.8,351.4,266.8,351.9,265.7,352.4z"
        />
        <path
          class="st239"
          d="M242.6,362.8c1.1,1.4,3.2,2,3,4.4c-0.1,0.8,0.2,1.6,1.2,1.4c1.2-0.2,1.2-1.4,0.8-2c-2.3-3.6,1.4-4.5,3-5.3
			c2.5-1.2,3.7,0.4,3.2,3.1c0,0.2,0.1,0.4,0.1,0.5c0.1,2.3,0.4,4.7-1,6.8c-0.2,0.3-0.5,0.5-0.8,0.7c-5.8-0.3-10.4,2.6-14.6,6
			c-2.1,1.7-4.3,3.5-4.9,6.5c-0.4,2-2.6,2.5-3.8,3.9c-2,2.7-5.4,4.2-6.4,7.8c-2.6,1.4-4.8,3.4-8,4.1c-1.7,0.4-1.4,3.7-3.2,4.9
			c0-6.2,0-12.5,0-18.7c1.7,1.3,3.8,1.9,4.9,4.2c0.6,1.4,2.4,0.7,3.6,0.1c1.4-0.7,2.9-1.6,2.3-3.3c-0.9-2.8,0.1-3.7,2.7-4.3
			c2.1-0.5,3.3-2.9,3.7-4.8c0.6-2.7,2.3-3.7,4.3-4.7c0.9-0.9,2.1-1.6,3.3-2.5c-0.9-0.4-3.6,1.9-2.4-1.4c-3.8-3.8,1.5-2.7,2.2-4.1
			c1.3-1.9,3.4-2.4,5.3-3.2C241.6,362.9,242.1,362.8,242.6,362.8z"
        />
        <path
          class="st240"
          d="M236.6,355.4c-0.5-2-2.9-1-3.8-2.3c1.4-1.7,4.1-1.7,5.1-3.9c0.9-0.6,1.7-1.3,1.4-2.6c0.4-0.7,1-0.8,1.6-0.3
			c0.8,0.2,1.6,1.6,2.1,0.9c2-2.4,2.6-1.3,2.6,1.6c1.7-1.4,0.9-2.3,0.5-3.2c0.5-0.4,1-0.4,1.5,0c0.1,0.2,0.2,0.4,0.3,0.6
			c0.6,1,2.4-1.2,2.6,1c0.1,1.5-1.3,1.5-2.1,2.1c-1.7,1.8-4.4,2.2-6.1,4c-1.3,0.1-2.8-0.1-2.2,2c-0.8,0.5-1.5,1-2.3,1.5
			C237.4,356.6,237,356,236.6,355.4z"
        />
        <path
          class="st123"
          d="M241,346.4c-0.5,0.2-1.1,0.4-1.6,0.7c-1.3,1.7-3.2,2.1-5.2,2.3c-1.1,0.1-1.6-0.4-1.5-1.5
			c4.7-1.1,8.8-3.4,13.8-6C245.3,344.9,242.2,344.6,241,346.4z"
        />
        <path
          class="st123"
          d="M238,349.4c0.9,1.5-0.4,1.7-1.3,2.2c-1.2,0.7-2.9,0.4-3.9,1.5c-2.5,0-3.4,3.1-6,3c1.9-3.1,4.8-4.6,8.2-5.4
			c0.7-0.6,1.3-1.4,2-2C237.7,348.4,238.2,348.4,238,349.4z"
        />
        <path
          class="st241"
          d="M249.3,359.9c-0.9,0.7-1.8,1.5-3.1,1.5c-0.8-0.6-0.9-1.4-0.8-2.3c0.7-0.4,1.3-1.1,2.1-1.3
			c0.7-0.2,1.4-1.3,2.2-0.5C250.6,358.2,249.7,359,249.3,359.9z"
        />
        <path
          class="st242"
          d="M241.1,362.9c-1.1,2.2-3.5,2.3-5.3,3.2c-0.1-0.7-0.8-1.7,0.8-1.7C238.6,361.6,239.1,361.4,241.1,362.9z"
        />
        <path
          class="st243"
          d="M245.5,359.1c0.9,0.5,0.8,1.4,0.8,2.3c-1,1.5-2.3,1.6-3.8,0.9C243,360.7,243.6,359.3,245.5,359.1z"
        />
        <path
          class="st244"
          d="M261.8,339.5c-0.3,1.9,1.4,3.3-0.3,4.3c-0.7,0.5-0.8-0.5-1.2-0.9C258.9,341.2,260.3,340.7,261.8,339.5z"
        />
        <path
          class="st245"
          d="M221.7,362.9c0.2,1,0.7,1.9-0.2,2.8c-0.4-0.4-1-0.8-1.2-1.3c-0.3-0.9-2.3,1.7-1.5-0.7
			C219,362.9,220.1,360.8,221.7,362.9z"
        />
        <path
          class="st246"
          d="M242.6,353.1c1.4-2.2,3.9-2.7,6-3.7c-0.9,2.4-3.5,2.4-5.2,3.8C243.1,353.4,242.9,353.4,242.6,353.1z"
        />
        <path
          class="st123"
          d="M225.3,355.8c0.5,0.1,1,0.2,1.6,0.3c-1,2.3-3.9,1.1-5.3,3.6C221.7,356.5,224.4,357.2,225.3,355.8z"
        />
        <path
          class="st247"
          d="M242.6,353.1c0.3,0,0.5,0,0.8,0c-1,0.7-2.1,1.5-3.1,2.2c-2.4-0.9,1.1-2.2-0.2-3.2
			C241.3,351.4,241.4,353.8,242.6,353.1z"
        />
        <path
          class="st248"
          d="M236.6,355.4c0.7,0.2,1.2,0.7,1.4,1.4c-1.4,0.6-2.7,1.3-4.2,2C233.3,356,236.8,357.5,236.6,355.4z"
        />
        <path
          class="st249"
          d="M229.4,360.2c0.6-1.1,1-2.4,2.5-2.1c0.2,0,0.6,0.6,0.6,0.8C232,360.5,230.4,359.7,229.4,360.2z"
        />
        <path
          class="st250"
          d="M247.8,345.6c-0.5,0-1,0-1.5,0c0.1-1.8,1.6-2.5,2.9-3.1c0.4-0.2,1.1,1.1,0.2,1.5
			C248.6,344.4,247.9,344.7,247.8,345.6z"
        />
        <path
          class="st251"
          d="M291.2,412.2c0.3,4.5-3.6,2.9-5.8,4.3c-0.1-1.9-0.2-3.5-0.3-5c-1.6-3.3-0.4-6.8-0.6-10.2
			c-0.1-1.7,2-1.9,3-2.7c-2.9,0.9-2.9,0.9-2.6-1.7c0-0.1,0.4-0.4,0.2-0.5c-3.7-2.5-1.6-7-3.5-10.1c-0.9-0.9-1-2.1-1.4-3.2
			c-0.4-1.5,1.1-3.5-1.6-4.4c-0.8-0.3-0.7-2.5-0.8-3.9c1.1-1.3,2.3,0.4,3.3-0.2c1.2,1.3-1.4,1.8-0.4,3c1.3,0.6,2.8-1.3,4.3,0.2
			c-1,1.5-4.2,3.7,0.6,4.4c0.1,0,0,1.3,0,2.4c2.2-1.1,4.6-1.4,4.2-4.9c0.7,0.9,1,1.4,1.4,1.9C291.1,391.8,291.1,402,291.2,412.2z"
        />
        <path
          class="st252"
          d="M277.7,374.8c1.3,2.6,4.1,4.7,3,8.2c-2.7-1.7-6.5-1.3-8.5-4.4c-0.2-0.4-0.1-0.8-0.1-1.2
			c-2.8,2.8-4.9,1.1-6.9-1c-4.3-0.1-8.2,0.5-11.2,4c-2-0.5-1.2-2.7-2.4-3.7c-0.4-1.7-0.6-3.4,0.7-4.9l0,0c0.4-0.4,0.9-0.6,1.5-0.7
			c0.6,0,1.1,0.2,1.5,0.7c2.1,0.2,4.2-0.3,6.2-0.8s3.8-1.1,6-0.1c1,0.5,3.1-0.2,4.9,0.2c1.5,0.3,2.7,2.7,4.7,0.9
			c0.1-0.1,0.2,0.9-0.2,1.4C276.1,374.5,276.9,374.7,277.7,374.8z"
        />
        <path
          class="st239"
          d="M255.2,371.8c-0.5,0-1,0-1.5,0c-0.7-2.5-0.5-5,0-7.5l0,0c2.5-0.4,4.9-0.8,6.7,1.5c-0.7,1.1-0.8,2.3-0.9,3.6
			C255.9,366.1,256.4,370.4,255.2,371.8z"
        />
        <path
          class="st112"
          d="M269.4,336.4c-0.1,2.4-2.1,2.6-3.4,3.3c-1.4,0.7-1-1-1.3-1.6c-0.5-1-0.4-1.1,1.2-0.8
			c1.1,0.2,0.7-1.3,1.6-1.4c0.5,0,1-0.8,1.7-0.2C269.3,335.9,269.4,336.3,269.4,336.4z"
        />
        <path
          class="st253"
          d="M267.9,329.2c-0.8,2.2-3,1.9-5,3.2C263.9,329.4,266.6,330.5,267.9,329.2L267.9,329.2z"
        />
        <path
          class="st112"
          d="M238,349.4c-1.2,0-1.5,1.9-3,1.4c0-0.6-0.2-1.1-0.8-1.4c1.6-0.9,3.3-2,5.2-2.3c0.6,0.2,1.4,0.1,1.3,1
			C240.4,349.5,239.1,349.3,238,349.4z"
        />
        <path
          class="st254"
          d="M356.2,312.8c2,3.6,2,12.2,0,15.8c-0.2,0.3,0.4,1,0.6,1.6c-4,1.3-7.4,3.8-11,5.8
			c-13.3,7.1-26.5,14.5-39.9,21.7c-2.6,1.4-5.4,2.6-8,3.9c0-0.5,0-1.1-0.1-1.6c-0.4-1.8,1.5-2.9,1.5-4.5c9.3-7,20.5-10.1,30.5-15.8
			c8.2-3.4,15.7-8.2,23.5-12.4c-7.8,4.1-15.1,8.9-23.3,12.2c-7.6,4.8-16.4,7.2-24.2,11.8c-2.2,1.3-4.2,3-6.5,4.2
			c-1-0.4-1.5-1.1-1.5-2.3c2.4-3.7,6.4-5.4,10.1-7.3c1-0.5,2.1-0.9,3.1-1.3c0.7-0.2,1.4-0.4,2-0.7c4.8-1.3,8.8-4.6,13.5-6.2
			c2.5-0.7,4.7-2.2,7.1-3.2c-2.2,0.9-4.2,2.2-6.5,3c-5.1,1.5-9.2,5.2-14.4,6.4c-1,0.4-1.8,0.2-2.4-0.8c-0.1-1.5,1.1-1.7,2.1-2.1
			c6.9-3,13.8-6.3,20-10.6c3.8-2.6,7.7-4.8,11.8-6.9c-11.5,6.6-22.5,13.9-34.7,19c-0.8,0.4-1.6,0.7-2.4,1.1c-2,1-4,1.8-5.8,3.1
			c-1,0.6-2,1.6-3.3,0.7h-0.1c-0.6-0.6-0.6-1.4-0.3-2.1c3.3-3.9,8-5.2,12.4-7.2c8-3.8,16.1-7.5,23.2-13c2.7-2.1,5.7-3.6,8.9-4.6
			c4.9-1.5,9.3-3.5,12.9-7.1C355.2,312.7,355.7,312.5,356.2,312.8z"
        />
        <path
          class="st128"
          d="M357,297.9c-0.9,4.2,1.6,8.4,0,12.6c-1.5,1.3-3.4,1.7-5,2.7c-1.6,0.7-3.1,1.5-4.6,2.4
			c-1.9,0.9-3.8,2-5.9,2.3c-1.3-0.2-2.5-0.4-3.8,0.1c-4.8,2-8.6,5.8-13.4,7.9c-2.3,1.2-4.5,2.4-6.8,3.6c-2,1.2-5,1.1-5.8,4
			c0.4,0.9,1,1.8,0.7,2.9c-3.9,2.6-8.3,4.4-12.8,5.7c-0.3-0.3-0.7-0.6-1-1c-0.2-2.7,0.1-4.7,3-6.3c1.9-1,2.8-3.8,5.1-4.9
			c-2.3,1.1-4.1,2.6-5.3,4.9c-0.5,1.1-2.2,1.5-3.5,0.4c1.5-2.2-1.3-3.8-0.8-5.9c1.3-2.4,4.1-2,6.1-3.1c7.8-4.3,15.9-8.1,23.6-12.7
			c9-5.4,18.4-10.4,27.7-15.2C355.3,297.9,356,297.3,357,297.9z"
        />
        <path
          class="st255"
          d="M357,297.9c-11.8,5.9-23.2,12.4-34.6,19c-8.1,4.7-16.5,8.9-25.2,12.5c-0.3,0-0.5,0.1-0.8,0.2
			c0.7-2.3,3.2-2,4.7-3.2c14.1-6.2,27-14.4,40.5-21.8c5.4-3,10.9-5.9,16.3-8.8c0.2,0.7,0.4,1.3,0.7,2
			C358,297.7,357.5,297.8,357,297.9z"
        />
        <path
          class="st256"
          d="M299.6,342c3.9-2.5,8.7-3.4,12.5-6.1c2.5-1.9,5.4-3.1,7.9-4.9c3.2-2.2,7.4-2.5,10.1-5.5
			c0.2-0.3,0.5-0.5,0.8-0.7c2.8-2.5,6.3-3.8,9.6-5.4c5.2-1.7,10.3-3.4,14.2-7.6c1.1-1.2,2.1-1.1,1.5,1l0,0c-2.5,3.2-5.7,5.4-9.5,6.7
			c-5.7,2-10.9,4.7-15.9,8.3c-6,4.3-13,7.3-19.7,10.4c-4.5,2-8.9,4.1-13.1,6.6c-0.7-0.7-0.7-1.4-0.1-2.1
			C298.5,342.5,299,342.2,299.6,342z"
        />
        <path
          class="st257"
          d="M299.4,385.4c7-4.6,14.9-7.5,22-11.8c1.4-0.8,2.8-1.6,4.4-1.9c-8.5,5.6-17.3,10.7-26.5,15.2
			C298.9,386.3,298.9,385.9,299.4,385.4z"
        />
        <path
          class="st128"
          d="M297.9,342.7c0,0.7,0,1.4,0.1,2.1c0,0.8-0.1,1.6-0.1,2.4c-0.1,0.1-0.2,0.2-0.2,0.3
			c-1.2,1.2,0.8,4.8-2.7,4.1c-2.9-0.6-1.3-3.3-1.4-5C293.2,342.1,293.4,342.1,297.9,342.7z"
        />
        <path
          class="st258"
          d="M309.1,376.3c-0.6,0.2-1.2,0.4-1.8,0.6c-1.8-2-1.8-3.9,0.1-5.8c1.5-0.7,3-1.5,4-2.9
			c0.9-1.3,2.1-1.4,3.5-0.9c0.6,1.1-0.2,1.9-0.9,2.4c-2.3,1.5-1.2,2.8,0.1,4.2c0.6,3.2-1.5,1.7-2.8,1.7c-0.4-0.2,0,0.3-0.4-0.1
			C309.7,374.2,309.1,374.4,309.1,376.3z"
        />
        <path
          class="st259"
          d="M297.9,335.2c1.1-0.1,3,0.4,3-0.7c0-4.3,4.8-3.5,6.2-6.1c0.4-0.7,1.3-1,1.8-0.2c0.4,0.7,0.2,1.6-0.7,1.8
			c-4,0.8-4.5,5.7-8.2,6.9c-1.8,0.6,0.7,3.3-1.3,4.3C297.2,339.2,297.9,337.2,297.9,335.2z"
        />
        <path
          class="st260"
          d="M314.2,373.9c-2.6,1.6-0.8-2.1-2.6-2.1c-1.4,0,0-0.6,0.2-0.9c0.8-1.4,2.6-1.9,3.1-3.6
			c2.2-2,3.4-1.7,3.1,1.5c-0.2,1.6,1,3.4-0.9,4.7c-1.5-1.6,0.7-3.6-1.1-6.1C316.4,370.5,313.9,371.6,314.2,373.9z"
        />
        <path
          class="st261"
          d="M311.4,345c-4.7,2.5-9.5,4.7-13.5,8.1c-0.6-2.1,0.8-2.9,2.3-3.7c2-3.7,6-4.1,9.3-5.8
			C310.3,343.7,311.5,343.4,311.4,345z"
        />
        <path
          class="st262"
          d="M324,371.1c-1.2-2-0.2-4-1-5.2c-1.3-1.9-2.4,1.4-4.9,0c2.5-0.7,4.3-3.2,6-1.9
			C325.9,365.4,325.1,368.2,324,371.1z"
        />
        <path
          class="st263"
          d="M304.6,365.8c0.3,0.3,0.5,0.5,0.8,0.8c-3,2.2-6.5,3.4-10.3,5.5c0.6-2.4,3-2.4,3.6-4
			C300.7,367.4,302.6,366.6,304.6,365.8z"
        />
        <path
          class="st128"
          d="M309.2,344.2c-3,1.8-6,3.5-9,5.2c-0.3-0.3-0.5-0.6-0.8-0.8c1.9-3.5,5.5-4.5,9-5.6
			C308.9,343.2,309.3,343.5,309.2,344.2z"
        />
        <path
          class="st264"
          d="M300.2,380.9c1.1-1.3,0.8-2.8,0.6-4.2c-0.3-1.7,0.5-2.8,2-3.6c-2.3,2.4,3.9,7.1-2.6,8.3
			c-0.3-0.1-0.4-0.2-0.3-0.3C300,380.9,300.1,380.9,300.2,380.9z"
        />
        <path
          class="st265"
          d="M308.3,343.4c-3.2,1.3-6.3,2.8-8.9,5.2c-1.1,0.1-1.5-0.4-1.5-1.4c3.2-1.2,5.6-3.7,8.9-4.6
			C307.6,342.3,308.4,341.9,308.3,343.4z"
        />
        <path
          class="st266"
          d="M307.5,371.2c-0.9,1.9-0.5,3.9-0.1,5.8c-0.6,0.5-1,1.7-2,1.2c-0.9-0.5-0.1-1.3,0.1-1.9
			C305.9,374.5,304.1,371.7,307.5,371.2z"
        />
        <path
          class="st267"
          d="M300.2,380.9c0,0.2,0,0.4,0,0.5c-0.4,0.3-0.9,0.5-1.1,0.9c-0.6,1-1.4,1.9-2.7,1.3c-1.3-0.6-0.7-1.9-0.7-3.2
			C297.1,383.1,298.7,379.9,300.2,380.9z"
        />
        <path
          class="st268"
          d="M309.1,376.3c-0.6-1.2-1.1-2.5-0.4-4.3c1,1.3,1.8,2.4,2.7,3.6C310.9,376.8,310.1,376.9,309.1,376.3z"
        />
        <path
          class="st269"
          d="M299.4,385.4c0,0.5,0,1-0.1,1.5c-0.9,0.8-1.6,1.9-3.6,2.2C297,387.3,297.9,386,299.4,385.4z"
        />
        <path
          class="st270"
          d="M359.1,343.2c0.4-0.6,0.9-0.7,1.6-0.5c0,3.4,0,6.9,0,10.3c-0.6,0.4-1.4,1-1.4-0.4c0-2.4,0.1-4.7,0.2-7.1
			C359.8,344.7,359.3,344,359.1,343.2z"
        />
        <path
          class="st254"
          d="M356.2,312.7c-0.1-0.6-0.6-1.5-1-0.8c-3.5,5.4-9.2,6.5-14.7,8.2c-0.5-0.4-1-0.7-1.4-1.1
			c-0.6-0.6-1.9-0.5-1.6-1.9c1.3-0.4,2.6-0.3,4,0.1c2.3,0.2,4.1-1.1,6-2c1.2-1.1,3.1-1.3,4.2-2.8c1.6-1.1,3.2-2.2,5.3-2
			C357.3,311.5,358.1,312.5,356.2,312.7z"
        />
        <path
          class="st271"
          d="M284.5,411.5c0,2,0,4,0,6h-0.1c-1.9,3.3-1.5,7.3-3.5,10.6c-1.1,1.7-2.1,3.5-3.2,5.2
			c-0.4,0.5-0.8,1.1-1.2,1.6c-0.5,0.5-1.1,0.8-1.8,0.9c-0.4,0-0.7,0-1,0c-0.8-0.3-1.7-0.4-2.1-1.4c4.9-3.9,7.5-9.2,9.4-15.1
			c0.6-1.8-0.1-1.8-1.1-2.4c-1.3-0.8-3-0.4-4.2-1.3c-0.4-0.7-0.1-1.4,0.5-1.3c1.7,0.3,0.3-1.3,1-1.5c1-0.7,2.3-0.6,3.5-1
			c1.9,0.1,2.1-2,3.5-2.5C285.1,409.8,284.9,410.6,284.5,411.5z"
        />
        <path
          class="st272"
          d="M284.5,411.5c-0.1-0.8,0.1-1.7-0.7-2.3c-1.8-1.9-1-4.2-1.1-6.4c0-0.4,0-0.9,0-1.3
			c-0.8-3.9-0.6-7.8-1.2-11.7c-0.2-1.3-0.3-2.7,0.8-3.8c2,1.8,1,4.4,1.6,6.5c0.5,1.8,0.6,3.6,3.1,3.4c-0.3,1.4-2.3,0.9-2,2.6
			c1.9,0.3,3.1-1.8,5-1.7c0,0.2,0,0.6,0,0.6c-6.8,2.9-3.7,9.1-4.7,14C284.8,411.5,284.6,411.5,284.5,411.5z"
        />
        <path
          class="st273"
          d="M309.8,457.1c-0.1-1-0.2-2-0.4-2.9c-0.3-1.9-1.1-2.7-2.9-1.1c-1,1-2.3,0.5-3.5,0.3c0.1-1.5,0.9-2.2,2.3-2.3
			c2.8-1.9,6.1-2.8,8.6-5.1c6.2-2.5,11.4-6.7,17.3-9.8c1-0.3,2-1.1,3.1-0.2c0.1,1,0.5,1.9,1.5,2.3c0.9,0.9,0.4,1.5-0.5,1.9
			s-1.5,1.2-2,2c-1.1,0.7-1.9,1.8-2.5,2.9c-3.2,2-6.5,4-9.7,6.1c-0.8-0.2-2.1-1.3-2.1-1c0,2.3-4.1,1.2-3.2,4
			c-0.5,0.8-1.3,0.7-2.1,0.8c-0.8-0.4-1.5-0.7-1.7,0.6C311.7,456.6,311,457.1,309.8,457.1z"
        />
        <path
          class="st274"
          d="M327,424.8c-0.5-0.2-1-0.5-1.5-0.8c0.6-0.8,1.4-0.6,2.2-0.7c0.6,0.1,1.2,0.1,1.7,0.7
			c-0.6,1.2,0.2,1.2,1,1.2c1.8,0.3,3.1-0.6,4.2-1.9c0.8-1.4,2.4-1.4,3.7-1.9c0.6,0,1.1,0.2,1.5,0.5c1.3-0.4,2.6-0.6,3.7-1.4
			c0.6-0.4,1.6-1.2,2.2,0.1c0.4,0.8,0.4,1.8-0.2,2.5c-1.3,1.5-1.1-0.1-1.3-0.8c-0.2-0.4-0.5-0.5-0.5,0.1c-0.1,4.1-4.1,3.1-6.1,4.8
			c-1,0.9-3.2,0.7-2.3,3c-0.5,0.4-1,0.3-1.5,0.3c-3.5-0.2-6.3,1.1-8.8,3.5c-0.3,0.2-0.6,0.2-1,0.1c-2.5-1.1-1.3-3.8-2.3-5.6
			c0.2-0.2,0.5-0.4,0.7-0.6C323.5,426.3,326.5,427.4,327,424.8z"
        />
        <path
          class="st273"
          d="M331.4,433.1c-0.5,0.9-1.7,1.9,0.2,2.5c0.1,0.4,0,0.7-0.3,0.9c-6,3-11.5,7.2-17.8,9.7c-0.4,0-0.7-0.1-1-0.4
			c-1.5-3.6-1.4-4,2-5.6c2.5-1.2,5.1-2.3,7.3-4.2c0.3-0.2,0.6-0.4,1-0.5c1.6-0.3,3-1.2,4.4-1.9C328.6,432.9,329.9,432,331.4,433.1z"
        />
        <path
          class="st275"
          d="M333,442.2c-0.9-2.6,2.4-2.2,2.8-3.8c3.8-2.3,8.4-2.5,11.9-5.3c1.2,0.8,0.6,2.1,0.9,3.1
			c-4.8,2.7-9.7,5.1-15,6.7C333.4,442.7,333.2,442.5,333,442.2z"
        />
        <path
          class="st276"
          d="M358.5,417.9c-0.7,0.8,1.4,3.1-1.5,3c-2.5-0.1-1.4-2-1.5-3.3c-0.1-0.5-0.1-1-0.1-1.5c0-1.4,0.2-3.1,2-2.9
			c1.8,0.1,0.9,1.9,1.1,3C358.5,416.7,358.5,417.2,358.5,417.9z"
        />
        <path
          class="st277"
          d="M339.8,422c-0.5,0-1.1,0-1.6,0c-0.5-0.4-0.2-1.1-0.6-1.5c-1.3-3.1,2-2.5,3-3.7c1-1.3,3.1,0,4.1-1.7
			c0,0,0.7,0.2,0.8,0.4c0.5,0.7,0.4,1.5-0.4,1.9c-1.3,0.7-2.6,1.6-4,1.7C338.5,419.4,338.8,420.4,339.8,422z"
        />
        <path
          class="st273"
          d="M306.1,458.6c-1.4-1.5-1.4-1.6-2.4-0.1c-0.6,0.9-1.5,0.5-2.2,0.6c-0.3,0-0.9-0.5-1-0.9
			c-0.3-0.9,0.4-1.4,1.1-1.6c1-0.4,2.1-0.8,3.2-1s2.2-1.9,3.3-0.8c1.2,1.2-1.4,1.8-1.1,3.1C306.6,458.1,306.3,458.4,306.1,458.6z"
        />
        <path
          class="st276"
          d="M358.5,408.3c-0.1,0.8,0,1.9-0.4,2.8c-0.2,0.5-1.2,0.7-1.9,1c-0.2,0-0.7-0.5-0.7-0.6c0.9-1.9,0-3.8,0.3-5.7
			c0.1-0.6,0.3-0.9,0.9-0.9s1.3,0,1.5,0.8C358.5,406.4,358.4,407.3,358.5,408.3z"
        />
        <path
          class="st278"
          d="M331.4,433.1c-3.3,0.1-5.4,3.5-8.9,3.1c-0.2-0.2-0.2-0.5-0.2-0.8c0.5-0.6,1-1.1,1.8-1.3
			c0.2,0,0.4,0,0.6-0.1c2.7-2,5.4-4,9-3.8c0.5,0,1,0,1.4,0C334.4,431.8,331.9,431.1,331.4,433.1z"
        />
        <path
          class="st279"
          d="M315.9,454.2c-1.7-0.1-1.7-1.3-1.3-2.4c0.4-1.2,1.2-1.5,2.2-0.5c0.5,0.4,0.9,0.2,1.1-0.4
			c1.4-3.8,2.3-1.3,3.2,0.2C318.7,451,317.8,453.5,315.9,454.2z"
        />
        <path
          class="st280"
          d="M356.8,422.3c1.7-0.2,1.6,0.7,1.7,1.6c0.1,1.5-0.8,1.8-2.1,1.9c-1.3,0-1.3-0.7-1.3-1.6
			C355.1,422.7,355.7,422,356.8,422.3z"
        />
        <path
          class="st281"
          d="M331.5,436.3c0-0.2,0.1-0.5,0.1-0.7c1.8-0.7,2.2-3.4,4.7-3.4c0.6,1.9-1.8,2.3-2,3.9c-1,0.3-1.9,0.5-2.9,0.8
			c-0.2-0.1-0.3-0.3-0.2-0.4C331.4,436.3,331.4,436.3,331.5,436.3z"
        />
        <path
          class="st282"
          d="M358.4,394.2c0,2.1,0,3-1.4,3.1s-1.1-1-1.3-1.7c-0.3-1.4,0.9-1.7,1.8-1.9
			C358.6,393.5,358.4,394.5,358.4,394.2z"
        />
        <path
          class="st283"
          d="M333,442.2c0.2,0.2,0.5,0.5,0.7,0.7c0,2-1.3,2.3-2.9,2.2C329.9,442.9,330.9,442.1,333,442.2z"
        />
        <path
          class="st282"
          d="M357.1,401c-0.8,0-1.5-0.2-1.5-1.2s0.5-1.6,1.5-1.7c0.8-0.1,1.2,0.3,1.2,1.1
			C358.4,400.1,358.4,401,357.1,401z"
        />
        <path
          class="st284"
          d="M348.7,436.2c-2.2-0.5-0.1-2.2-0.9-3.1c0.3-0.8,0.9-1.9,1.5-0.5C349.7,433.6,350,435.2,348.7,436.2z"
        />
        <path
          class="st280"
          d="M358.5,428.1c-0.2,0.2-0.2,1.4-0.6,0.7c-0.8-1.5-2.6,0.3-3.1-1.2c-0.2-0.7,0.6-1.2,1.2-1
			C356.9,427,358.5,426.2,358.5,428.1z"
        />
        <path
          class="st285"
          d="M312.1,455.5c0.5-1.2-2.3-1.8-0.8-2.6c1.5-0.9,1.7,1.2,2.4,2C313.3,455.5,312.7,455.6,312.1,455.5z"
        />
        <path
          class="st286"
          d="M345.7,359.1c-1.7,2.4-3.3,1.9-3.5-0.7c-0.3-2.8-1.1-6.1,2.5-7.7c0.8-0.3,1.6-0.8,2.3,0.1
			C348.1,352.1,347.3,357.6,345.7,359.1c-1.5-1.5,0.4-3.5-1-5.5C344.4,355.8,344.7,357.5,345.7,359.1z"
        />
        <path
          class="st287"
          d="M359.1,343.2c0.8,0.7,1.2,1.4,0.3,2.3c-3.1-1.2-7.9,2.1-8,5.4c0,2.2,1.2,4.5-0.5,6.8
			C348.2,349.6,350.4,345.9,359.1,343.2z"
        />
        <path
          class="st288"
          d="M354.3,355.2c0.2-2.3,0.4-4.4,0.6-6.7C356.7,351,356.9,353.1,354.3,355.2z"
        />
        <path
          class="st289"
          d="M143.7,484.2c-0.6-1.7,0.1-3.1,0.9-4.5c0.1-0.8,0.5-1.3,1.2-1.7c-0.6-0.1-1.2,0.6-1.9,0.2
			c-0.6-3.2,1.8-6,1.4-9.2c-0.4-1.1,0.8-1.4,0.9-2.2c-0.4,0.3,0.2-0.2-0.1-0.1c-2.4,1.2-5.1,3.7-6-1.4c0.1-3.9,1.6-7.5,2.4-11.3
			c3.9-3.3,7.8-6.4,13.2-6.8c0.7-0.2,1.3-1,2.1-0.3c1,0.8-0.2,1.2-0.5,1.7c-1.4,2.6-1.3,5.3,0.4,7.7c1.8,2.6,2.6,5.1,1.3,8.3
			c-0.8,1.9-0.8,4.1,0.3,6.1c3.6,6.8,1.1,13.5-6.1,16.3c-3.7,1.4-7.4,3.5-11.7,2.1c0-1,0.7-2,0-2.9
			C141.2,484.5,143.1,484.9,143.7,484.2z"
        />
        <path
          class="st290"
          d="M164,433.9c10.6-5.5,21.1-10.9,31.6-16.4c1.1-0.6,2.2-1.3,3.3-1.6c1.8-0.5,1.3,0.7,0.9,1.7
			c-5.3,2.7-9.5,7.5-15.8,8.2c-5.9,3-11.9,6.1-17.8,9.1c-1.1,0.9-2.1,2.1-3.8,1.5C161.9,434.7,163.3,434.5,164,433.9z"
        />
        <path
          class="st291"
          d="M162.5,436.2c1.2-0.6,2.3-1.5,3.8-1.5c0,0.2,0,0.5-0.1,0.7c-0.9,4.4-0.1,8.8,0,13.2c0.1,2.1-0.2,4.2,0,6.3
			c0.2,1.8-0.3,3.2-2.4,3.6c-0.6-5.9-0.1-11.7-0.3-17.5c0,4.1,0.1,8.4-0.1,12.6c0,1.1,0.9,2.8-1,3.5
			C162.6,450.2,162.5,443.2,162.5,436.2z"
        />
        <path
          class="st292"
          d="M155.8,447.4c-3.8,2-7.6,4-11.5,5.9c-0.8,0.4-1.2,0.8-1.2,1.6c-0.9,0.4-1.1-0.3-1.5-0.8c0-1,0-1.6,1.4-1.8
			c1.5-0.2,1.4-2,1.5-3.4c2.6-0.7,4.3-3.2,7.1-3.4C153.3,445.5,155.9,443.6,155.8,447.4z"
        />
        <path
          class="st293"
          d="M150.6,411.5c2.4-1.2,4.7-2.6,7.2-3.4c1.4-0.4,0.9-1.1,1-1.9l0,0c2.9-1.1,2.7,1.8,3.6,3
			c0.9,1.2-1,0.9-1.3,1.5c-2.9,1.6-5.6,3.6-8.9,4.5c-0.5-1-1.3-1.8-1.8-2.8C150.2,412,150.3,411.7,150.6,411.5z"
        />
        <path
          class="st294"
          d="M150.6,411.5c0,0.3,0,0.6,0.1,0.9c-0.7,1.2,1.1,1.7,0.7,2.8c-2.5,2.2-5.6,3.3-8.5,4.7c-1,0.5-1.9,0.7-2-0.9
			c0.1,0,0.3-0.1,0.3-0.2c0.9-1.4,4.4-0.1,3.6-2.4c-0.6-2-2.1,2.2-3.8,0.3C144.1,414.8,146.2,411.2,150.6,411.5z"
        />
        <path
          class="st166"
          d="M161,410.8c0.8-2.3,0-3.8-2.3-4.5c1.5-1.3,3.2-2.2,5.2-2.3c0.4,0.9,1.6,1.7,3.1,2c0.6,0.1,1.1,0.5,1.4,1.1
			c-0.4,1.5-2.2,1.1-3,2.2C164.1,409.9,162.8,410.9,161,410.8z"
        />
        <path
          class="st295"
          d="M167.7,415.2c-2.3,2.8-2.9,6-2.1,9.5c0.4,0.6,0.3,1.2,0,1.8c-0.4,0.3-1.1,0.8-1.2,0.3
			c-0.8-2.9-0.6-5.9-0.3-8.8C164.3,416.4,166.3,415.9,167.7,415.2z"
        />
        <path
          class="st296"
          d="M137,448.2c0-0.8,0.1-1.5,0.1-2.3c2.1-1.3,4.1-2.6,6.2-3.9c0.8,1.3-0.6,2-0.4,2.9
			C140.6,445.4,139,447.2,137,448.2z"
        />
        <path
          class="st297"
          d="M162.6,457.2c1.5-3.6,0.3-7.3,0.6-11c0.1-1.8,0-3.6,0.7-5.5c0,5.9,0,11.9,0,17.8c-0.2,0-0.4,0.1-0.7,0.2
			C162.7,458.3,162.6,457.8,162.6,457.2z"
        />
        <path
          class="st298"
          d="M154.3,433.2c-1.2,1.5-3.4,1.2-4.6,2.7c-0.5,0.6-1.5,0.5-1.9-0.3s0-1.5,0.9-1.6
			C150.5,433.7,152,429.9,154.3,433.2L154.3,433.2z"
        />
        <path
          class="st299"
          d="M141.7,439.9c-0.8-0.1-1.3-0.5-1.1-1.2c0.8-2.1,3.4-1.4,4.6-2.9c0.4-0.4,0.9,0.5,0.9,1.2
			C145.8,439.9,142.8,438.5,141.7,439.9z"
        />
        <path
          class="st300"
          d="M165.6,426.6c0-0.6,0-1.2,0-1.8c1-0.5,0.8-0.6,1.2-2.3c0.2-0.8,1.4-1.6,2.3-1.8c0.9-0.2,0.3,0.9,0.3,1.4
			C169.4,424.5,167.4,425.4,165.6,426.6z"
        />
        <path
          class="st301"
          d="M134.9,424.3c0.5-2.2,3.8-3.1,2.6-6.5c1.6,0.8,0.8,2.4,1.9,2.7C138.7,422.7,136.5,423.2,134.9,424.3z"
        />
        <path
          class="st171"
          d="M138.6,442.1c-0.1-0.2,0-0.5-0.2-0.7c-0.5-0.7-2.7-0.2-1.5-2c0.5-0.7,1.4-0.9,2.2-0.1
			C140.4,440.6,140.1,441.5,138.6,442.1C138.6,442.2,138.6,442.1,138.6,442.1z"
        />
        <path
          class="st166"
          d="M151.3,415.2c-0.8-0.8-4.4-0.9-0.7-2.8c1.3,0.5,2.2,1.2,1.5,2.8C151.9,415.2,151.6,415.2,151.3,415.2z"
        />
        <path
          class="st302"
          d="M154.3,433.2c0.2-2,0.9-3.6,4.3-3.7C156.4,430.7,156.4,433,154.3,433.2L154.3,433.2z"
        />
        <path
          class="st303"
          d="M170,422c1.4,0.2,1.3-1.2,2.1-2c0.1,1.9,0.6,3.7-2,4C170.1,423.3,170,422.6,170,422z"
        />
        <path
          class="st304"
          d="M141.6,454.1c0.5,0.3,1,0.5,1.5,0.8c-0.8,3.5-1.6,7-2.4,10.6c-0.6,1.3-1.4,2.6-0.8,4.1
			c1.4,0.5,2.5-1.2,3.9-0.4c0,1,0,2,0,3c-1.4,0.9-1,3.2-3,3.7c-1.7,1-3.5,2-5.2,3c-1.9-0.3-1.4-1.5-0.8-2.5c0.6-1,1.7-1.8,1.9-3.1
			c-3,2.5-3,2.5-5.6-1.1c-0.6-0.1-1.6-0.5-1-1c1.9-2,0.7-3.9-0.4-5.3c-1.9-2.5-1.7-4.7-0.1-7.1c1.7-1.6,3.2-4,6-1.5
			c0.6,0.8,1.1,0.7,1.6-0.1c0.6-0.3,1.2-0.3,1.6,0.4c0.2,0.3,0.3,0.7-0.1,0.9c0.6,0,0.3-0.5,0.3-0.8c-0.1-1.9,1.4-2,2.6-2.6
			C141.6,454.7,141.6,454.4,141.6,454.1z"
        />
        <path
          class="st305"
          d="M131.1,472.1c0.6-0.2,1.1-0.5,1.6,0.2c1.4,2,2.8,1.7,4.1-1c0.7,2.1,1.1,4-1.3,5.5c-0.9,0.5-0.7,1.5,0.1,2.1
			c-1.2,1.5-0.6,3.6-1.5,5.2c-0.3-0.3-0.8-0.4-0.8-0.9C128.1,480.4,132.2,475.7,131.1,472.1z"
        />
        <path
          class="st306"
          d="M143.8,469.1c-0.3,0.2-0.6,0.4-1,0.5c-1.4,0.2-2.7,2.8-3.8,1.1c-1.1-1.7-0.7-4.1,1.7-5.3
			c1.2,2.8,3.5,2.7,5.9-0.3c1.4,2-0.1,2.8-1.3,3.9C144.8,469.1,144.3,469.1,143.8,469.1z"
        />
        <path
          class="st307"
          d="M141.3,450.3c-3.5,1.3-7,2.5-10.5,3.8c2.8-3.2,6.6-3.5,10.2-4.7C141,449.7,141.2,450,141.3,450.3z"
        />
        <path
          class="st308"
          d="M129.5,497.1c2.1-2.8,6.1-2.7,8.4-5.2c0.2,0.4,0.5,0.8,0.8,1.1c-2.8,1.7-5.6,3.4-8.4,5
			C129.9,497.8,129.7,497.4,129.5,497.1z"
        />
        <path
          class="st309"
          d="M143.7,484.2c-0.7,0.7-1.4,1.4-2.1,2.1c-1.8,0.9-3.7,1.8-5.5,2.7C137.9,486.2,140.4,484.5,143.7,484.2z"
        />
        <path
          class="st310"
          d="M135.6,457.1c-2.5-1.7-3.9,1.4-6,1.5c-0.5-4.3,3.6-2.5,5.3-3.9C135.5,454.3,135.5,456.2,135.6,457.1z"
        />
        <path
          class="st311"
          d="M143.9,478.2c1.2-0.6,2.4-1.6,4.1-1.5c-0.8,1.7-2.4,1.9-3.4,3c-0.9,0.2-1.9,0.4-2.9,0.6
			c-0.4,0.4-0.8,0.3-1.1-0.2C141.7,479.5,142.8,478.9,143.9,478.2z"
        />
        <path
          class="st312"
          d="M130.5,489.6c1.2-0.6,2.5-1.1,4.4-2c-1.1,2.6-2.9,2.4-4.1,3.1C130.8,490.3,130.7,490,130.5,489.6z"
        />
        <path
          class="st313"
          d="M141.6,454.9c-1.3,0.9-2.4,2-2.3,3.9c-1-0.5-0.9-1.9-2.1-1.8C138.3,455.6,139.7,454.8,141.6,454.9z"
        />
        <path
          class="st314"
          d="M140.9,475.8c1.8-0.6,0-4,3-3.7C143.2,473.6,143.8,476.1,140.9,475.8z"
        />
        <path
          class="st312"
          d="M133.3,483.2c0.3,0.3,0.6,0.6,0.8,0.9c-1.9-0.2-1.7,2.4-3.6,2.6C131,485,130.7,483,133.3,483.2z"
        />
        <path
          class="st315"
          d="M140.7,480.1c0.4,0.1,0.7,0.1,1.1,0.2c-1.7,1-3.3,2-5,3C137.3,481,139.2,481,140.7,480.1z"
        />
        <path
          class="st316"
          d="M151.3,400.2c-1.4,1.2-2.7,2.2-5.1,2.2C147.8,400.5,149.4,400.1,151.3,400.2
			C151.3,400.3,151.3,400.2,151.3,400.2z"
        />
        <path
          class="st317"
          d="M168.5,407c-1,0.1-2-1.2-3.1-0.2c-0.8,0.7-1.5,1.2-2.3-0.2c-1.3-2.1,1.1-1,1.2-1.6c0.1-0.3-0.2-0.7-0.3-1
			l0,0c1.3,0.3,2.1-3.1,3.8-0.5c0.1,0.2,0.1-0.3,0.1-0.4c0-1.5,1.2-2,2.1-2.8c0.3,0,0.6,0,0.8,0C173.4,403.6,173.1,404.4,168.5,407z
			"
        />
        <path
          class="st318"
          d="M169.9,400.3c-0.8,1.7-2.1,3.3-1.5,5.8c-0.4-4-2.9-1.8-4.5-2.1C165.3,401.6,167.4,400.7,169.9,400.3z"
        />
        <path
          class="st319"
          d="M281.4,437c-2.1,2.3,1.5,2.3,1.5,3.7c-0.3,1,1.3,2,0,3c-2,0.1-4.9-0.9-3.5,3.1c0.5,1.3-0.8,1.5-1.8,1.9
			c-4.6,1.8-5.4,3-5.2,7.7c0,1.3-0.9,1.7-1.9,2c-3.5,0.9-5.5,3.3-5.6,6.9c-0.1,6-0.3,6.3-5.9,7.6c-1.6-2.4,0.3-4.4,0.8-6.5
			c0.3-0.6,0.8-1.2,1.2-1.8c0.9-2.8,0.4-6.2,2.9-8.5c-2.1-2.1,1.3-3.6,0.6-5.8c-0.7-2.3,0.6-3.9,3.4-3c1.7-0.1,3.1-1.2,4.8-1.5
			c1.7-0.3,2.6-1.9,3.3-3.6C277,439.8,277.8,436.8,281.4,437z"
        />
        <path
          class="st320"
          d="M260.6,466.2c-0.5,2.2-1.1,4.5-1.6,6.7c-1.1,4.3-6.7,9-10.8,9.5c-2,0.3-2.6-1.7-4.1-2.1
			c0-0.2,0-0.5-0.1-0.7c2.1-2.1,5.5-2.3,7-5.1c0.1-0.8,0.7-1.4,1.4-1.6c3.4-1.2,5.6-3.5,6.6-6.9
			C259.6,465.6,260.2,465.7,260.6,466.2z"
        />
        <path
          class="st206"
          d="M272.4,456.4c-2.1-3.4-0.9-6.2,3.6-8.2c0.9-0.4,1.7-0.9,2.8-0.3c0.3-1.9-2.2-3.5-0.5-4.9
			c1.5-1.2,3.2-0.1,4.6,0.8c-1.6,2-2.1,4.8-4.4,6.4c-0.6,0.4-1.5,1.2-1.4,1.6C278.5,456.2,274,454.8,272.4,456.4z"
        />
        <path
          class="st184"
          d="M238,476.6c-1.4,0.8-1.1,2.6-2.3,4.3c0-2.4,0-4.1,0-5.8l0.1-0.1c0.2-0.2,0.4-0.4,0.5-0.5
			c1.2-1,2.8-2.5,4-0.6C241.4,475.6,238.6,475.5,238,476.6L238,476.6z"
        />
        <path
          class="st321"
          d="M251.3,475c0.6,0.2,1.8,1.1,1,1.1c-3.2,0.3-4.9,3.9-8.3,3.5c-0.7-1.4-1.1-3.1-2.9-3.8
			c-1.2-3-1.6-6.1-1.9-9.3c0-1,0-2,0.5-3c0.7-1.3,2.3-1.2,3.3-2c6.9-1.1,11.9-5.4,16.4-10.4c-4.4,4.3-11.3,4.5-15.5,9.2
			c-0.9,1-2.1,1.2-3.2,0c-0.6-0.9-0.6-2-1.2-2.9c-0.8-1.4-1.5-2.8-1.7-4.4c-0.2-1.2-1.2-2.1-1-3.4c-0.1-5-1.2-9.6-5.4-13
			c-0.4-0.4-0.7-0.9-0.9-1.5c-0.4-1.7-0.2-3.4-0.3-5c-0.1-5.8-0.3-11.6,1.4-17.2c3.3-9.2,7.7-17.7,16.5-23c2.4-1.4,2.5-3.7,2.4-6.2
			c0-1.1-0.2-2.2,1-2.9l0,0c1.3-0.7,2.2-0.5,2.8,0.9c0.2,1.2-0.4,2.5,0.1,3.7c0.2,1.6,1.3,1.9,2.7,1.9c1.6,0,3.2,0,4.8,0.1
			c4.7-0.3,6.1,4.3,9.1,6.4c0.5,0.6,0.9,1.2,1.1,1.9c1.8,3.6,1.6,7.5,1.3,11.4c-0.2,3,0.1,5.3,3.5,5.9c2.5,2.9-2.1,0.5-1.5,2
			c-3.1,1.6-4.5,4.3-5.4,7.5c-0.8,2.8-2.2,5.5-3.1,8.3c-0.8,2.5-0.5,3.9,2.6,3.2c0.7-0.2,1.5-0.1,2.1,0.5c0.8,0.1,1.8-0.1,2.4,0.8
			c-1.8,2.1-4.4,2.8-6.8,3.9c-1.5,0.7-2.1,2-2.4,3.6c-1,7.5-3.2,14.9-2.9,22.5c-0.4,0.3-0.9,0.5-1.3,0.8c-0.6,0-1.1,0-1.7,0
			c-1.2,2.6-3.4,4.2-5.9,5.5c-2.2,1.1-4.6,1.9-6.7,3.7C248.2,475.9,249.7,473.6,251.3,475z"
        />
        <path
          class="st322"
          d="M240.3,466.1c0.3,3.2,1.2,6.4,0.9,9.7c-1,0.3-2.1,0.5-3.1,0.8c-0.2-1.5,1.6-1.2,2-2.5
			c-1.7-0.6-2.8,1.2-4.2,1c-0.1-1.6,1.7-2.3,2.1-3.9c-1.6-1.1-5.6,3.5-5.1-2.1c0-0.2,0-0.3,0-0.5
			C233.6,464.9,234.9,464.6,240.3,466.1z"
        />
        <path
          class="st192"
          d="M329.1,424.1c-0.7,0-1.2-0.1-1.5-0.8c2.3-1.1,4.7-2.1,6.9-3.4c1.9-1.2,2-0.2,2.2,1.2
			c-0.2,1.1-3,0.9-1.5,2.9c-1.5,1.3-2.9,3-5.2,1.7C330.8,424.6,330.9,423.9,329.1,424.1z"
        />
        <path
          class="st160"
          d="M335.2,424c-0.8,0-1.6-0.2-1.4-1.2c0.3-1.4,1.7-1.5,2.8-1.7c0.3-0.2,0.5-0.5,0.8-0.7
			c0.6,0.3,0.8,0.8,0.6,1.5C337.4,422.9,335.3,422.1,335.2,424z"
        />
        <path
          class="st102"
          d="M311.3,437.6c0.8,0,1.5,0,2.2,0.1c1.5,0.6,3.4-2.1,4.7,0.5c-2.1,2.4-5.5,2.5-7.8,4.6
			c-0.4,0.4-1.3,0.3-0.9,1.2c0.6,0.6,0.7,1.4,0.7,2.1c0.2,1.3,0.1,2.5-1.3,3.1c-1.9,0.8-2.6-0.8-3.6-1.8c-1.7-1-3-3.9-6.1-0.4
			c0.7-3.5,3.6-3.1,4.6-4.8c2.3-1.5,4.7-2.8,6.8-4.5C310.9,437.7,311.1,437.7,311.3,437.6z"
        />
        <path
          class="st323"
          d="M305.3,447.4c0.6-0.6,1.2-0.2,1.4,0.4c0.5,1.3,1.3,1.4,2.3,0.8c1.2-0.7,0.5-1.7,0.1-2.5
			c1-1,2.1-0.3,3.2-0.2c0.2,0,0.3,0.1,0.5,0.1s0.5,0,0.8,0c0.5,0.1,0.7,0.4,0.8,0.8c-2.7,2.1-5.4,4.1-9,4.4c0.1-1.7-1.9-1.2-2.5-2.4
			C303.3,447.7,305,448.6,305.3,447.4z"
        />
        <path
          class="st324"
          d="M310.7,437.7c-1.2,3.1-4.3,3.4-6.8,4.5c0.9-2.1,3.8-2.2,4.5-4.5C309.1,437.7,309.9,437.7,310.7,437.7z"
        />
        <path
          class="st196"
          d="M318,438.3c-1.2-1.8-3.2,1.3-4.5-0.6c0-2.2,1.9-3,3-4.5c1.9,0.6,2.7-1.9,4.5-1.5c2.3,0.5,0.9,2.6,1.6,3.8
			c0,0.2-0.1,0.5-0.1,0.7l0,0C321,436.7,320.1,438.9,318,438.3z"
        />
        <path
          class="st325"
          d="M322.6,435.5c-2.9-0.3,0.5-3.2-1.6-3.8c0.4-1-1.7-2.5,0.6-3.1c2.4,1.1,0.2,4.3,2.4,5.5
			C324,434.9,323.5,435.4,322.6,435.5z"
        />
        <path
          class="st326"
          d="M327,424.8c0.6,2,0.6,1.9-1.1,2.1c-1.2,0.2-2.3,0.7-3.5,1C323,425.5,325.8,426.4,327,424.8z"
        />
        <path
          class="st327"
          d="M281.4,437c-2.2,1.7-4.7,3.1-4.7,6.7c0,2-2.8,3.5-3.9,3.4c-2.2-0.1-3,0.5-3.9,2.2c-0.3,0.5-0.8-0.5-1-1
			c-1.2-2.1-1.5-4.2,0.5-6c1.2-0.1,1.8-0.6,1.8-2c0-1.8,0.8-3,2.9-2.6c1.2,0,1.5-1.7,2.3-1.5c2.8,0.6,0.8-0.8,0.7-1.4
			c0.1-0.6,0.1-1.2,0.7-1.5c3.5,0,3.7-3.1,5-5.2c0.4-0.9,0.1-2.4,1.8-2.1c-0.2,2.1,3.1,0.8,3,3c-3.2,0-2.8,2.7-2.9,4.5
			C283.6,435.1,283.2,436.3,281.4,437z"
        />
        <path
          class="st328"
          d="M283.7,425.8c-1.2,0.8-0.5,2-0.8,3c-1.3,0.9-2.5,1.5-3-0.8c1.2-2.2,1.7-4.6,2.3-7c0.4-1.3,0.1-3.1,2.2-3.5
			C284.9,420.3,283.7,423,283.7,425.8z"
        />
        <path
          class="st329"
          d="M199.2,426.5c-0.5,1.1,3,1.3,0.7,2.9c-1.4-0.5-2.2,0.7-3.7,1.4c1.6-0.6,2.4-1.8,3.7-1.4c0,2,0,4.1,0,6.1
			c-0.3,0.2-0.5,0.4-0.9,0.5c-0.5,0.1-1,0.1-1.5,0c-1.9-0.2-3.4,1.2-5.1,1.2c-1.6-0.2-1.6-2-2.9-2.6l-0.1-0.2
			c-0.2-0.8,0.2-1.3,0.7-1.8c1.1-1.7,2.2-3.5,4.3-4.3c0.9-0.4-0.5-0.3-0.4-0.7c-0.2-0.3-0.4-0.6-0.4-0.9c-0.1-1.1,0.6-1.9,1.5-2.4
			c1.1-0.8,2.3-1.9,3.7-1.1C200,423.9,199.1,425.4,199.2,426.5z"
        />
        <path
          class="st330"
          d="M184.1,425.6c0.1-0.2,0.3-0.5,0.4-0.6c3.5-0.4,6-2.8,8.7-4.5c2.1-1.4,4.3-2.3,6.6-3c0,1.5,0,2.9,0,4.4
			c-1.4,1.4-3.6,1.3-5.1,2.8c-0.8,0.8-2.1,1.2-0.9,2.6l0,0c0.6,1.6,0.1,2.1-1.5,1.5c-0.2-0.2-0.4-0.5-0.7-0.8
			c-0.5,0.4-0.2-2.7-1.3-0.3c-0.4,0.8-1.6,1.1-2.5,0.1c0.1-1,1-2.6-1.4-1.9C185.8,426.1,184.9,425.8,184.1,425.6z"
        />
        <path
          class="st331"
          d="M189.5,434.7c2.1-0.7,1.5,2,2.9,2.2c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.4,0.6,0.6
			c0.4,0.5,0.8,0.9,0.9,1.5c-2.3,0.9-4.5,2-6.8,2.8c-0.9,0.3-2.5,0.6-2.8-0.9c-0.2-1.4-1.2-3.2,1-4.2
			C187.4,436.4,188.8,436,189.5,434.7z"
        />
        <path
          class="st332"
          d="M177.5,443c-0.1,1.8-3,2.8-2,4.1c1.1,1.6,3-1.1,5.1-0.5c-2.5,2-5.2,3.1-7.7,4.4c-1.6-0.4-1.6-0.4,0-2.9
			c-1.6,0.4-2.8,1.6-4.3,0.7c1.6-4,5.4-4.8,8.9-6V443z"
        />
        <path
          class="st333"
          d="M168.5,448.9c1-0.8,2.6-0.2,3.4-1.7c0.5-1,1.6-0.7,2.2-0.1c0.8,0.8,0.1,1.4-0.6,2.1
			c-0.4,0.4-2.7,0.6-0.6,1.9c-1.1,1.4-2.9,2.7-4.3,2C167.1,452.3,169,450.3,168.5,448.9z"
        />
        <path
          class="st334"
          d="M166.3,454.9c-1.5-1.3-0.9-3-0.7-4.5c0.4-5-1.1-10,0.7-15C166,442,167.7,448.4,166.3,454.9z"
        />
        <path
          class="st335"
          d="M176,416.7c-1.5-0.3-2.4,1-3.8,2c0.3-2.8-0.9-5.5,2.3-7c0.6-0.3,1.2-1.2,1.8-0.1c0.5,0.9,0,1.3-0.9,1.6
			c-1.1,0.3-1.4,1.2-1.6,3.1C173.7,417.2,177.6,412.5,176,416.7L176,416.7z"
        />
        <path
          class="st336"
          d="M188.8,412.2c0,0.5,0.2,1,0.1,1.4c-0.1,0.3-0.6,0.5-0.9,0c-1.2-1.9,0.3-9.8,2.1-10.8
			c0.6-0.4,1.1-0.4,1.4,0.4c-0.9,1.6-1.2,3.3-1.6,5.1C189.4,409.5,190.3,411.2,188.8,412.2z"
        />
        <path
          class="st337"
          d="M190.2,433.2c-0.3,0.5-0.5,0.9-0.8,1.3c-1.6-0.5-3.1,2.3-4.1,0.9c-1.7-2.3,2.6-1.6,2.4-3.6
			c0-0.6,1.1-0.8,1.8-0.8C191.3,431.3,190.1,432.4,190.2,433.2z"
        />
        <path
          class="st338"
          d="M174.4,438.6c1.6-2.7,4.8-2.9,6.6-5.2c0.3-0.4,0.9-0.3,1.3,0.1c0.5,0.6,0.2,1.3-0.4,1.5
			C179.4,436.4,176.9,437.5,174.4,438.6z"
        />
        <path
          class="st339"
          d="M190.2,433.2c-1.3-0.4,0.1-1.6-0.7-2.2c0.5-1.3,2.3-1.1,2.9-2.3c0.9-0.1,1.6-0.5,1.5-1.5
			c0.9,1.1,1.9-0.6,2.9-0.1c-0.1,0.4-0.2,0.7-0.3,0.8C193.7,428.8,192.3,431.5,190.2,433.2z"
        />
        <path
          class="st340"
          d="M196.8,399.1c-0.6,1.8-2.5,3.2-0.3,5.1c0.7,0.6-0.5,1.2-1.1,1.5s-1.4,0.1-1.4-0.6
			C194.1,402.8,192.9,399.9,196.8,399.1z"
        />
        <path
          class="st341"
          d="M193.9,427.2c-2.6-0.5-1.2-1.7-0.3-2.3c1.9-1.4,4-2.5,6.3-3c-0.1,1.6,0.4,3.2-0.8,4.6c-0.2-1-0.4-2-0.8-3.3
			C196.6,424.4,194.4,424.7,193.9,427.2z"
        />
        <path
          class="st332"
          d="M177.4,442.9c0.2-1.6,1.6-1.7,2.6-2.4c0.6-0.4,1.5-0.9,1.8,0.2c0.3,0.9,0.4,2.1-0.3,2.9
			c-1.1,1.2-0.9-0.5-1.3-0.7c-2.2-1-0.4,2.1-2,1.9c-0.4-0.5,0.3-1.5-0.6-1.8C177.5,443,177.4,442.9,177.4,442.9z"
        />
        <path
          class="st342"
          d="M187.9,427.8c1.9,0.6,2.5-1,3.3-2.2c0.8,0.7,0.5,1.5,0.5,2.3c-1.8,0.5-3.2,1.7-4.7,2.7
			c-0.5,0.4-1.1,1.4-1.8,0.4c-0.5-0.7-0.1-1.4,0.8-1.7C186.9,429.2,187.5,428.6,187.9,427.8z"
        />
        <path
          class="st343"
          d="M171.5,439.2c1,0.3,2.1,0.6,3.4,1c-1.7,1-3.1,1.9-4.6,2.8c-0.5,0.3-1.1,0.9-1.6,0.1s0.1-1.2,0.7-1.8
			C170.1,440.6,172.3,441.3,171.5,439.2L171.5,439.2z"
        />
        <path
          class="st344"
          d="M189.4,408.5c0.1-2-0.2-4.2,2.2-5.3c0.5,1.8,1.1,3.5,0.6,5.4c-0.3,0.1-0.6,0.2-0.9,0.1
			C190.7,408.6,190,408.8,189.4,408.5z"
        />
        <path
          class="st345"
          d="M189.4,408.5c0.8-0.4,1.7-2.8,2.4,0.1c-0.3,1.4-0.3,3-1.9,3.7c-0.4,0-0.7,0-1.1,0
			C189.6,411.1,187.8,409.5,189.4,408.5z"
        />
        <path
          class="st303"
          d="M176,416.7c1.3,2.6,0.9,3.3-3.3,5.7C174,420.2,175,418.4,176,416.7L176,416.7z"
        />
        <path
          class="st346"
          d="M181.3,411c-0.6-2.4,1-3,2.4-3.7c0.5-0.3,1-0.1,1.1,0.5s0.3,1.8-0.7,1.7C182.7,409.2,182.6,410.8,181.3,411
			z"
        />
        <path
          class="st347"
          d="M194.8,439.3c-1-0.1-1.6-0.5-1.6-1.6c2.1-0.5,3.9-1.5,6-2.2c0.2,0,0.5,0,0.7,0.1
			C199.7,438.8,196.5,438.1,194.8,439.3z"
        />
        <path
          class="st348"
          d="M182,430.6c-2,1-3.6,1.9-5.6,2.9C178.2,430.4,178.2,430.4,182,430.6z"
        />
        <path
          class="st349"
          d="M171.5,439.2c-0.5,0.1-1,0.2-1.4,0.4c-0.7,0.4-1.4,1.5-2,0c-0.4-1.2,0.8-1.4,1.6-1.6
			C170.7,437.6,172.1,437,171.5,439.2L171.5,439.2z"
        />
        <path
          class="st350"
          d="M203,400.8c-1.7,1.6-2.8,2.6-4.5,4.2C198.7,401.8,200.5,401.5,203,400.8z"
        />
        <path
          class="st351"
          d="M184.5,413.8c-1,1.7-1.1,3.2-2.7,3.5c-0.1,0-0.5-0.5-0.6-0.7C181.1,414.9,182.4,414.4,184.5,413.8z"
        />
        <path
          class="st352"
          d="M189.9,412.3c0.9-1.1,0.4-2.9,1.9-3.7c0.1,0,0.3,0,0.4,0C192.5,410.5,192.6,412.3,189.9,412.3z"
        />
        <path
          class="st353"
          d="M252.3,371.8c0,1.8,0,3.6,0.1,5.4c0.3,0.5,0.4,0.9-0.1,1.3c-1.4-1.3-4.4-1.2-6.6,0.4
			c-1.6,1.1-3.1,2.5-5.2,2.7c-1.9,0.2-1.5,1.6-1.6,2.8c-0.2,1.9,0,4.3-3.1,2.3c-0.2-0.2-0.6-0.1-1-0.1c-0.2,3.7-3.5,2-5.2,3.1
			c-0.4,0.2-0.3,1.1-0.5,1.7c-2-0.6-1.2-1.8-0.8-3c0-0.1,0-0.3,0.1-0.4c3.1-3,3.1-3,3.3-7.3c0.8,0.5,1.7-0.3,2.4-0.8
			c2.5-2,5.2-3.6,7.6-5.6C244.9,371.5,248.6,371.8,252.3,371.8z"
        />
        <path
          class="st29"
          d="M252.2,378.5c0.1-0.4,0.1-0.9,0.1-1.3c1.3,0.5-0.1,3.1,2.2,2.8c2.4-1.8,5.4-2.1,8.2-2.9
			c1.1-0.3,2.5-0.2,3-1.6c1.6,3,3.7,3.3,6.8,1c0.7,0.7-0.3,1.3-0.1,2c2.3,3.1,5.4,5.3,7.7,8.4c1.8,2.3,1.8,3.6-0.8,5.2
			c-1.6,1-3.4,1.5-4.9,2.6c-1,0.7-2,1.5-3.4,1.1c-0.1-0.7-0.2-1.3-0.9-1.6c0-2.2,2-2.2,3.3-3c2.6-1.5,2.9-2.9,0.9-5
			c-1.8-1.9-3.4-4.5-6.8-3c-1.2,0.5-1.5,0.1-2.7-2c-0.3-0.6-0.7-0.2-0.9-0.1c-2.2,0.6-4.4,0.6-6.6,1.3c-1.1,0.4-2.3,1-3.5,0
			c-0.1-1.5-1.2-1.6-2.3-1.6C251.7,380,251.9,379.3,252.2,378.5z"
        />
        <path
          class="st354"
          d="M251.5,380.8c-0.5,0.8-0.3,1.6-0.1,2.5c-0.5,1.1-1.6,1.5-2.6,2.1c-1.1,0.7-2.2,1.4-3.2,2.2
			c-9.8,6.6-16.3,15.8-20.2,26.8c-0.6,1.6-1.7,3-2.4,4.6c-0.7,1.1-1,2.4-2.2,3.1c-0.6-0.1-1.1-0.3-0.7-1.1c1.5-8.8,6.4-16,11.2-23.3
			c3.9-6,9.6-10,15.4-13.8C248.3,383,249.9,381.9,251.5,380.8z"
        />
        <path
          class="st355"
          d="M228.3,388.3c0.1,1-0.3,2.2,0.8,3c-0.5,1.1,1.6,3.9-2.2,3c-0.9-0.2-1.3,1.8-0.1,2.6c1.3,0.9,0.3,1.6-0.1,2
			c-0.9,0.9-1-0.2-1.4-0.7c-0.6-0.6-1.4-2.3-2.2-0.1c-0.6-0.3-0.7-0.9-0.7-1.5c-0.3-2.5,0.4-4.5,3-5.7
			C226.5,390.3,227.3,389.1,228.3,388.3z"
        />
        <path
          class="st356"
          d="M220,421.1c0.3,0.3,0.5,0.6,0.8,0.9c0.6,1.4,1.4,2.8,3.3,2.6c0.5,0.3,0.8,0.7,0.6,1.3
			c-3.2,1.6-5.2,3.9-4.4,7.8c0,0.4-0.1,0.7-0.1,1c-0.7,1.7,0.3,3.5-0.6,5.2c-1.6,0.8-1.1-1-1.7-1.4c-0.3-1.3,0.4-2.7-0.7-3.8
			C216.9,429.9,217.9,425.4,220,421.1z"
        />
        <path
          class="st357"
          d="M217.9,412.2c-0.5-1.2-2.2-0.2-2.9-1.9c3.5,0.9,3.7-1.5,3.5-3.6c-0.3-2.5,0.7-3.5,3.1-3.5
			c1.2,2.5-0.1,4.9-0.4,7.4C220.9,412.5,219.2,412.2,217.9,412.2z"
        />
        <path
          class="st358"
          d="M219.4,454.1c0.7,1.1,1.6,1.8,2.9,1.6c1.6,2,4.1,1,6.1,1.9c2.6,2.2,1.2,4.5,0.2,6.8
			c-0.1,0.1-0.2,0.3-0.4,0.4c-2.9-1.9-3.6,0.9-4.9,2.7c1.2-2.2-1.9-3.6-0.5-5.5c-3.2,1.1-0.5-5-4.4-3.6
			C218,458.4,217.1,455.1,219.4,454.1z"
        />
        <path
          class="st322"
          d="M232.8,469.1c-1.9-0.5-3.8-1.6-5.6,0.1c-1-2.1,1.3-3,1.1-4.6v-0.1c1-1.5,2.3-2.6,3.6-3.7
			c0.8-0.7,1.8-0.9,2.8-0.3c1.1,0.7,0.7,1.7,0.4,2.6C234.2,465.1,233.4,467,232.8,469.1L232.8,469.1z"
        />
        <path
          class="st358"
          d="M216.3,451.1c0.5,1.4,2.5,0.2,3,1.6c-2.2,0.8-4.1,2.8-7.8,1.6C214.4,454.2,214.1,451.3,216.3,451.1z"
        />
        <path
          class="st359"
          d="M228.3,457.8c-2.1-0.5-5.4,2.4-5.9-2.2c-0.4-1.3-0.9-2.5-1.4-3.8c0.6-0.7-0.2-1.8,0.8-2.4
			c1.2,0.2,1.4,1.3,1.9,2.1c1.9,3.3,3,3.6,6.6,2c1.6-0.7,2.9-1.8,4.3-2.8c0.8-0.6,1.5-1.8,2.8-0.9c0,1.2,1.8,1.6,1.3,3
			C235.6,455.5,232.5,457.8,228.3,457.8z"
        />
        <path
          class="st360"
          d="M221.6,449.6c-0.1,0.8,0.1,1.6-0.6,2.2c-1.5-4.3-3.8-8.5-3.1-13.4c0.4,0.5,0.9,1,1.3,1.5
			c0.5,1.5,0.5,1.3,2,0.5c3.4-1.6,6-4.9,10.1-5.1c0.3,0.3,0.6,0.6,0.8,0.9c0.3,1.4-0.8,1.9-1.6,2.6c-1.1,0.8-2.1,1.6-3.4,2.2
			C223.5,442.8,221,445.2,221.6,449.6z"
        />
        <path
          class="st361"
          d="M233.6,370.1c0.3,0.4,0,1.7,0.9,1c1.1-0.9,1.9-1.6,2.8,0c1.4,2.5-1.8,0.1-1.8,1.7c0,1-1.5,1.5-2.7,1.2
			C233.2,372.8,230.5,370.8,233.6,370.1z"
        />
        <path
          class="st353"
          d="M253.7,364.4c0,2.5,0,5,0,7.5c-0.5,0-1,0-1.5,0C253.5,369.5,252.2,366.6,253.7,364.4z"
        />
        <path
          class="st362"
          d="M274,394.3c1.1-1.9,3.3-2,4.9-3c1.7-1,2.6-1.7,0.6-3.6c-2.5-2.4-4-5.9-7.2-7.8c-0.6-0.4-0.5-1.1,0.1-1.5
			c1.6,0.4,2.8,3,4.9,1.1c0.8,1.5,2.1,2.4,3.4,3.5c0.5,1,1,2,1.5,3c0.5,5.2,1,10.5,1.5,15.7c-2.4,1.5-4.4,3.6-7.1,4.7
			c-0.9,0.4-2.1,0.7-2.7-0.4c-0.6-1.2,0.4-1.9,1.4-2.2c1.8-0.6,2.4-2.4,4.2-3.5c-1.8,0-3.8,2.5-4.6-0.4
			C274.2,398.2,272.7,396.4,274,394.3z"
        />
        <path
          class="st363"
          d="M265.7,375.5c0.2,1.5-0.4,2.2-1.9,2.4c-3.1,0.6-6.2,1.4-9.3,2c0.3-1.6,2.7-2,2.4-4.1
			c-0.1-0.9,0.8-0.4,1.4-0.3C260.8,376.1,263.2,375.7,265.7,375.5z"
        />
        <path
          class="st364"
          d="M309,341.9c2.8-2.5,6.6-3.1,9.8-4.6c8.6-4.1,15.9-10.4,24.7-14.2c0.8-0.3,1.4-1.1,2.6-0.3
			c-1.2,1.5-3.1,1.8-4.7,2.6c-8.6,5-16.7,10.9-25.9,14.9c-1.7,0.7-3.7,0.8-4.9,2.4C309.6,343.2,309,343.1,309,341.9z"
        />
        <path
          class="st365"
          d="M330,338.9c8.7-4.2,17.1-9.1,25.5-14.1c-0.4,2.8-11.1,9.1-25.4,15.1C329.7,339.6,329.7,339.3,330,338.9z"
        />
        <path
          class="st366"
          d="M330,338.9c0,0.3,0.1,0.7,0.1,1c-7.5,4.8-16.1,7.3-23.8,11.6c-2.3,1.3-4,3.8-7,3.8l0,0
			c3.6-3.8,8.1-6.4,13-8.2C318.5,345,324,341.3,330,338.9z"
        />
        <path
          class="st367"
          d="M327,338.1c-4.3,0.8-7.3,4.3-11.5,5.3c-0.9,0.2-1.7,0.8-2.8,0.7c-0.2-0.2-0.3-0.3-0.2-0.5s0.1-0.3,0.2-0.3
			c4.8-2.2,9.5-4.4,14.2-6.6C327.5,337.2,327.5,337.7,327,338.1z"
        />
        <path
          class="st368"
          d="M353,322.5c-4.2,2.9-9.4,4.2-12.5,9.2C340.3,328,341.5,327.1,353,322.5z"
        />
        <path
          class="st369"
          d="M309.3,352.2c-1.5,3.2-4.7,3.5-8.2,5.6C303.5,354.1,306.6,353.4,309.3,352.2z"
        />
        <path
          class="st370"
          d="M327,338.1c0-0.5,0-0.9,0-1.4c3-0.5,5-3.5,9.3-3.2C332.7,335.4,329.9,336.7,327,338.1z"
        />
        <path
          class="st371"
          d="M350,320.9c1.7-1.4,3.6-2.3,6.3-3C354.5,320.8,351.8,320,350,320.9z"
        />
        <path
          class="st372"
          d="M312.8,343.4c0,0.3,0,0.5,0,0.8c-0.5,0.3-0.9,0.5-1.4,0.8c-0.5-1-1.4-0.8-2.3-0.8c-0.3-0.3-0.6-0.5-0.9-0.8
			c-0.3-0.7-0.9-0.7-1.5-0.8c0.6-0.7,1.4-0.6,2.2-0.7c0.3,0.7,0.9,0.8,1.6,0.8C311.1,343.6,312,343.4,312.8,343.4z"
        />
        <path
          class="st373"
          d="M337.5,317.2c0.7,0.5,1.5,1,2.2,1.5c-0.8,2-2.8,2.4-4.4,3c-3.6,1.4-6.6,4.2-10.8,4.1
			c-0.1-0.1-0.3-0.2-0.4-0.4C328.4,322.4,332.6,319.2,337.5,317.2z"
        />
        <path
          class="st374"
          d="M320.3,331.6c-2.5,1.9-4.8,4.1-8.2,4.4c-0.5-0.8-1-1.6-1.6-2.5c0-1,0.7-1,1.4-1.1c3,0,4.7-3.5,7.8-2.9
			c0.4,0.1,0.6,0.3,0.8,0.6C320.8,330.6,320.7,331.1,320.3,331.6z"
        />
        <path
          class="st375"
          d="M320.3,330c-0.3,0-0.6,0-0.9,0c-0.5-0.7-1.2-0.3-1.8-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c1.8-2,4.1-3.2,6.7-3.8
			l0,0c2,1.4,3.3-0.5,4.9-1.1c1.5-0.6,1.5-0.6,1.8,1.1l0,0C328,328.5,323.9,328.7,320.3,330z"
        />
        <path
          class="st259"
          d="M301.3,337.5c2.4-1.4,4.7-2.9,7-4.3C307.2,336.4,304.9,338,301.3,337.5z"
        />
        <path
          class="st376"
          d="M349.9,311c1.5-2.1,4.8-2.4,5.3-5.9C356.7,308.8,355.2,310.3,349.9,311z"
        />
        <path
          class="st377"
          d="M312.1,332.9c-0.5,0.2-1,0.4-1.5,0.5c0.4-2.2,3.7-4.3,6.8-4.3l0,0C316.3,331.4,314.1,332,312.1,332.9z"
        />
        <path
          class="st378"
          d="M310.9,327.7c0.5-2,1.7-2.7,2.6-3.7c0.4,0.6,1.5,0.9,1.2,1.7C314.1,327.3,312.5,327,310.9,327.7z"
        />
        <path
          class="st379"
          d="M348.6,311.6c-1.6,1.5-3.3,2.2-5.3,2.2C345.1,313.1,345.8,310.6,348.6,311.6z"
        />
        <path
          class="st380"
          d="M347.9,315.6c-2.1,0.8-3.9,2.8-6.5,1.7c1.9-0.8,3.8-1.6,5.7-2.4C347.9,314.5,348,315,347.9,315.6z"
        />
        <path
          class="st381"
          d="M347.9,315.6c-0.3-0.2-0.5-0.5-0.8-0.7c1.3-1.2,3-1.7,4.6-2.4C351.3,314.6,349,314.4,347.9,315.6z"
        />
        <path
          class="st128"
          d="M330.8,325.5c-1.1-2.5-1.9,0.1-2.9,0.1c-1.3,0-2.8,3-3.9-0.2c4.1-0.6,7.3-3.2,11.1-4.5
			c1.6-0.5,3.1-1.5,4.6-2.3c0.6,0.3,0.8,0.9,0.8,1.5C337.2,322,334,323.7,330.8,325.5z"
        />
        <path
          class="st128"
          d="M320.3,330c1.3-1.2,1.8-2.9,4.6-2.1c1.6,0.5,3.9-1.5,5.9-2.5c0,2.1-1.9,2.3-3.2,3c-2.4,1.2-4.8,2.1-7.3,3.2
			C320.3,331,320.3,330.5,320.3,330z"
        />
        <path
          class="st382"
          d="M271.6,434.6c-2,0.3-4.5,2.1-6,0.4c-1.4-1.5,0.1-4,0.9-5.8c1.6-3.9,2.7-7.9,4.6-11.6
			c1.1-2.2,2.4-2.4,4.2-2.5c2.1,0.3,4.2-0.1,6.2,1c1,0.5,0.6,1.2,0.5,1.6C280,424.2,278.9,431.3,271.6,434.6z"
        />
        <path
          class="st383"
          d="M274,394.3c-0.1,1.9,1.7,3.1,1.5,5.1c-0.2,1.5,1.5,1.5,2,0.9c0.9-1.3,2.4-0.7,3.5-1.4
			c-1.5,2.9-3.4,5.4-6.6,6.6c1.8,0.4,3.3-0.2,4.5-1.5c1.3-1.4,2.9-2.3,4.9-2.4c0,0.3,0,0.6,0,0.9c-1.2,2.2-3.1,3.4-5.2,4.5
			c-1.3,0.6-3.1,1.3-2.9,3c0.2,1.9,2.2,1,3.4,1.3c0.7,0.2,1.5,0.2,1.9,1c-1.1,1-2.6,0.4-3.8,0.7c-1.6-0.2-3.1,0-4.8,0.5
			c0.1-6.1,1.2-12-1.1-17.7C272,395.3,273,394.8,274,394.3z"
        />
        <path
          class="st362"
          d="M280.7,412.3c-1.4,0-2.7,0-4-0.1c-1-0.1-2.4,0.3-2.6-1.3c-0.2-1.3,0-2.4,1.3-3.2c2.8-1.7,5.5-3.5,8.3-5.2
			c0,2.2,0.1,4.5,0.1,6.7C284,411.5,283.1,412.6,280.7,412.3z"
        />
        <path
          class="st382"
          d="M273.2,438.5c-3.4-1-2,1.6-2,2.9c0,0.8,0.4,1.8-0.8,2c-0.9,0.2-0.8-0.7-1-1.2c-1.5-0.8-2.1-2.1-2.1-3.8
			c2.2-1,4.4-2,6.7-3.1c0.2,0,0.5,0,0.7,0C274.6,436.7,274.8,438,273.2,438.5z"
        />
        <path
          class="st384"
          d="M279.9,428c0.8,1,1.8,1.1,3,0.8c-0.5,1.2-2,1.9-2.2,3.3c-0.5,3.2-2.1,2.3-3.8,1.1
			C277.9,431.4,278.9,429.7,279.9,428z"
        />
        <path
          class="st384"
          d="M273.2,438.5c1.1-0.8,0.8-2.2,1.5-3.1c0.3-0.6,0.9-0.6,1.5-0.8c1.6,0.2,1.9,1.5,1.4,2.4
			c-0.8,1.5-0.6-0.8-1.4-0.6c-1.1,0.3-1.2,0.9-1.3,1.8C274.7,439.6,273.9,439.2,273.2,438.5z"
        />
        <path
          class="st385"
          d="M314.4,446.7c-0.3-0.3-0.5-0.5-0.8-0.8c5.5-3.2,11-6.4,16.5-9.6c0.4-0.2,1,0,1.5,0c0,0.2,0,0.4,0,0.6
			C326,440.5,320.5,444.2,314.4,446.7z"
        />
        <path
          class="st325"
          d="M333.8,430.2c-3.2,0.7-5.7,3.2-9,3.8c0.6-1.9,5.9-5,8.4-4.9C333.4,429,333.5,429.8,333.8,430.2z"
        />
        <path
          class="st386"
          d="M329.1,424.1c0.3-0.2,0.5-0.5,0.8-0.7c0.8-0.4,1.9-1.4,2.5-0.3s-0.7,1.6-1.5,2c-0.3,0.2-0.6,0.4-1,0.5
			C328.6,425.8,329,424.9,329.1,424.1z"
        />
        <path
          class="st387"
          d="M318,438.3c1.8-0.2,2.5-2.7,4.6-2.2c-1.4,2.8-4.5,3.5-7,4.4c-2.9,1.1-3.8,2.5-2.8,5.3l0,0
			c-1.8,0.9-1.9-2.5-3.8-1.5c-0.6,0-1.4,0.4-1.7-0.3c-0.3-0.9,0.6-1.3,1.2-1.6C311.7,441.1,314.9,439.7,318,438.3z"
        />
        <path
          class="st388"
          d="M345.7,359.1c-2.9-1.2-1.9-3.8-2-5.9c0-0.7,0.7-1,1.3-1c0.8-0.1,0.9,0.7,1.1,1.2
			C346.9,355.3,345.7,357.2,345.7,359.1L345.7,359.1z"
        />
        <path
          class="st389"
          d="M134.2,459.9c-1.9,1.4-2,3.6-4,2.9c-0.6-0.2-0.7-1.1-0.4-1.6C130.5,459.4,132,460.3,134.2,459.9z"
        />
        <path
          class="st390"
          d="M267.3,438.4c0.7,1.2,1.4,2.5,2.1,3.8c-2.5,1.5-1.1,4-1.5,6c-1.5-0.9-3.1-0.6-2.8,1.2
			c0.3,2.1,1.4,3.9-1.2,5.1c1.5,3.6-3,6.4-1.4,10.1c0.1,0.1-0.4,0.5-0.7,0.8c-1.6-6.4,0.4-12.5,1.2-18.7c0.3-2.1,0.8-4.1,1.2-6.2
			C264.6,438.7,265.7,438.2,267.3,438.4z"
        />
        <path
          class="st391"
          d="M251.3,475c-2.4,0.1-4.3,2.8-7.4,1c4.5-4.2,11-5.2,15-9.8c0.6,2.9-0.5,4.7-3.2,6
			C254.2,472.9,252.1,473,251.3,475z"
        />
        <path
          class="st392"
          d="M236.7,435.4c-1.3-2-2.6-4-2.4-6.6c-0.4-0.8-0.6-1.7,0.1-2.2c0.8-0.6,1.5,0.1,2.1,0.7
			c1,1.9,1.5,1.4,1.6-0.4c0.1-2.7,0.1-5.5,0.1-8.2c0-1-0.3-2,1.5-3.4c0,3.5,0.1,6.5,0,9.4c-0.1,2.3,0.5,3.3,3.1,3.2
			c5-0.1,5.4,0.7,5.9,5.9c0.5,5.6-0.3,11.3,0.5,16.9c0.5,3.3-3.1,3.7-5,4.6c-2.1,1-2.2-1.7-2.5-3.1c-1.1-5-2.3-9.9-4.3-14.6
			c-0.7-2.6,1.4-2.2,2.6-2.2c2.4-0.1,2.4,1.6,2.3,2.8c0.1-1.4,0.4-3.2-2.6-3.7c-0.9-0.1-0.8-0.6-1.2,0.4
			C238.2,435.7,237.5,436.1,236.7,435.4z"
        />
        <path
          class="st393"
          d="M221.6,449.6c-1.7-3.8-0.7-6.2,3.1-8.1c2.7-1.4,5.4-2.9,7.4-5.3c2.5-0.1,2.6,2.1,3.2,3.6
			c1.3,3.2,3.6,6.3,2.1,10.1c-2.4,1.5-4.4,3.6-7,4.7c-4.5,1.9-5.6,1.6-7.8-3C222.2,450.9,221.9,450.3,221.6,449.6z"
        />
        <path
          class="st394"
          d="M248.6,384.6c0.9-0.5,1.8-0.9,2.8-1.3c0.8,3.7-0.4,6.2-3.8,8.2c-7.8,4.7-11.6,12.5-14.7,20.7
			c-0.8,0.6-1,1.7-2.2,1.8c-0.7-0.1-1.2-0.4-1.8-0.6c-1.7-0.6-2.1,0.6-2.5,1.8c-0.5,1.4-0.6,2.9-0.8,4.3c-1.3,1.3-2.3,0.6-3.3-0.5
			c3.6-9.6,8.2-18.7,15.4-26.2c2.3-2.5,5.1-4.2,7.9-6.1c0.6,1.3,0.7,1.4,0,1.9c-2.6,2.1-4.5,4.7-6.3,7.5c2.2-3.3,4.7-6.2,7.8-8.6
			C248,386.7,248.3,385.7,248.6,384.6z"
        />
        <path
          class="st395"
          d="M262.5,435.3c-0.5,2.9-0.7,5.9-1.4,8.8c-0.8,3.4-3.2,5-6.9,5.6c1.8-4.9,0.3-9.6,0.8-14.3
			c0.4-3.4,0.9-6.9,0.9-10.4c0-2.2,1.6-2.9,3-3.9c1.2-0.9,2.5-1.6,3.7-2.4c1-0.5,1.8-0.2,2.5,0.6c0.8,1.7,0.1,3.1-1.4,3.6
			c-2,0.8-2,2.2-2.1,3.8c-0.3,1.8-0.1,3.6-0.8,5.3c-0.8,2.2-1.3,4.4-1.2,6.7c0-2,0.2-4.1,1-6c0.3-0.6,0.3-1.5,1.3-1.4
			C262.9,432.7,262,434.1,262.5,435.3z"
        />
        <path
          class="st396"
          d="M253.8,382.4c3.1-0.8,6.2-1.6,9.3-2.2c1.5-0.3,3.1-0.5,4.6,0.4c-0.4,1.8-1.9,0.6-3,1.7
			c1.6-0.4,2.9,1.3,4-0.5c0.7-1.1,1.4-0.6,2.1,0c0.5,0.4,1,0.7,1.5,1.1c6.5,5.5,6.4,6.5-1.1,10.3c-0.4,0.2-0.6,0.7-1,1.1
			c-3-1.7-4-5.9-8.1-6c0-3.2-2.5-2.5-4.4-2.9c-1.4-0.3-2.6,0.5-4,0.2C252.5,384.4,253.1,383.4,253.8,382.4z"
        />
        <path
          class="st397"
          d="M264.9,419.8c-0.8-0.3-1.5-0.7-2.3-1c0-0.5,0.1-0.9,0.1-1.4c1.9-3.1,1.8-6.3,0.8-9.6
			c0.7-2.5,0.8-2.6,1.4-0.9c0.2,0.6-0.2,1.5,1.3,2.2c0.2-3.8,1-7.4-0.5-10.9s-4.5-3.8-7.8-2c-3.6,1.8-7.2,3.4-10.7,5.4
			c-1,0.6-1.6-0.2-1.7-1.3c0-0.2,0-0.3,0.1-0.5c1.7-4,5.7-4.9,9.1-6.6c0,0-0.1-0.1,0,0c-3.1,1.1-5.7,3-8.6,4.4
			c-0.2,0-0.3-0.1-0.5-0.2c-0.3-2.1,1-3.5,2.5-4.1c4.1-1.9,8.2-3.7,13.1-3.3c-0.4,1.2-1.7,0.3-2.4,1.2c1.7,0.9,2.5,3.5,5.4,1.3
			c1.2-1,1.9,1,2.3,2.1c3,5.2,1.5,11,2.4,16.5c-0.2,3.2-0.8,6.4-2.6,9.2C265.7,420.1,265.3,420,264.9,419.8z"
        />
        <path
          class="st398"
          d="M228.3,457.8c3.5-1.7,6.9-3.3,10.4-5c0.1,1.6,0.9,2.9,1.6,4.3c1.2,2-0.8,4-0.2,6c0.1,1,0.1,2,0.2,3
			c-1.5-0.3-2.8,0.7-4.5,0c-1.9-0.8-1.5,2.3-2.9,3c-0.3-2.2,0.4-4.2,1.1-6.2c0.2-0.6,1.4-1.1,0.3-1.8c-0.8-0.5-1.6-0.2-2.1,0.8
			c-0.8,1.5-2,2.4-3.7,2.6C227.8,462.3,230.3,460,228.3,457.8z"
        />
        <path
          class="st399"
          d="M241,460.1c0.7,0.2,1.5,0.8,2.1,0c4.2-6.1,12.6-5.6,17.2-12c0.8,3.2-0.1,5.2-1.7,6.3
			c-3.6,2.5-7.5,4.8-11.4,7c-1.1,0.6-2.5,1.3-3.8,0.2C241.9,462.2,240.5,462.5,241,460.1z"
        />
        <path
          class="st400"
          d="M231.3,435.4c-3.9,2.4-7.7,4.7-11.8,7.2c-0.1-1-0.2-1.8-0.3-2.6c0.4-1.7-1.3-3.5,0.2-5.2
			c3.6-2.4,7.6-3.9,11.8-4.5C231.2,432,231.3,433.7,231.3,435.4z"
        />
        <path
          class="st401"
          d="M231.2,430.2c-3.8,1.9-7.6,3.6-11.8,4.5c0-0.3,0-0.6,0-0.9c0.9-1.7,2.6-1.9,4.2-2.5
			c1.9-0.7,4.4-0.7,5.3-3.1c-0.3-0.9-1.1-0.9-1.8-1.1c-2.2-1.1-1.1-2,0-2.9c-1.1-0.2-1.2,2.1-2.7,1.4c-0.2-0.2-0.5-0.5-0.7-0.7
			c-1.1-2.4-0.9-4,1.8-5.3c1.7-0.9,3.6-2.3,3.3-4.9c-0.1-0.7,0.8-1.2,1.6-1c0.8-0.4,1.1-1.6,2.3-1.5
			C231.1,418.1,231.2,424.2,231.2,430.2z"
        />
        <path
          class="st402"
          d="M261.4,406.5c-4.6,2.3-4.1,7.2-5.8,11c-1.2-0.9-1.1-2.1-1.1-3.4c0-0.9,0.1-1.9-1.2-1.8
			c-1.2,0.1-1.7,0.9-1.6,2.1c0.1,1.1,1,2.2,0.1,3.3c-0.3,0-0.5,0-0.5-0.1c-1-1,0.1-3.9-1.8-3.5c-1.7,0.4-2,3.1-2,5
			c0,0.6,1.1,1.3-0.2,1.5c-0.9,0.2-0.8-0.7-0.9-1.3c-0.8-4.4-0.2-5.7,4.2-7.4C254.4,410.8,256.9,407.2,261.4,406.5z"
        />
        <path
          class="st403"
          d="M236.5,427.2c-1.4-0.4-2.4-0.4-2.2,1.5c-1.7-6.3-0.7-12.4,1.5-18.3c0.5-1.4-0.1-3.7,2.3-4.2
			c0.4,0.3,0.4,0.6,0.1,0.9c-1.3,3.1-0.9,6.8-2.9,9.6c-1.4,2.1,2,0.5,1.5,2.5C236.1,421.7,236.5,424.5,236.5,427.2z"
        />
        <path
          class="st404"
          d="M252.3,467.6c-2.4,1.3-4.8,2.5-7.2,3.8c-1.1,0.6-1.7,0.4-1.7-0.8c0.1-1.5-0.7-3.2,0.6-4.6
			c1.9-0.4,3.8-1,5.6-1.4C252.2,463.9,252.8,464.6,252.3,467.6z"
        />
        <path
          class="st405"
          d="M253.6,385.5c1.6-2.8,4-0.8,5.9-0.8c1.7,0,3.4,0.6,5,0.9c0.1,2.3-3.4-0.2-2.5,2.7c-2,0-4-0.1-5.9,0.2
			C253.5,388.7,253,387.6,253.6,385.5z"
        />
        <path
          class="st406"
          d="M268,410.8c-1.2-5.4,0-11-1.6-16.4c3.9,4.9,4.4,10.5,3.1,16.4c-0.7-0.2,0-1.2-0.8-1.4
			c0.5,0.5,0.3,0.9-0.1,1.3C268.5,410.9,268.2,410.9,268,410.8z"
        />
        <path
          class="st407"
          d="M252.3,467.6c-0.1-1.8,0.1-3.7-2.9-2c-1.5,0.9-3.6,0.7-5.4,0.4c4.1-2.3,8.2-4.5,12.7-5.9
			c1.8,0.8,0.4,1.5-0.2,2.1c-1.3,1.4-3.1,2.4-2.8,4.6C253.4,467.4,252.9,467.6,252.3,467.6z"
        />
        <path
          class="st408"
          d="M253.7,466.8c-1.6-1.9-0.1-3,1-4.4c0.6-0.8,2.7-0.5,2-2.3c0.1-1.1,0.5-2.1,1.8-1.9c1.2,0.2,1.2,1.5,1.2,2.4
			c0.1,1.9-1.4,2.8-2.6,3.9C256.1,465.5,254.9,466.1,253.7,466.8z"
        />
        <path
          class="st409"
          d="M236.7,435.4c2.8-0.6,0.2-3.7,2-4.7c1.9,0.3-0.9,5.6,3.5,3.2c0.1,0,0.6,0.6,0.6,0.8c-0.7,2.5,0.8,5,0.3,7.9
			c-1.7-1-1.2-2.4-1.3-3.6c-0.1-1,0.3-2.5-1.3-2.6c-1.2-0.1-2.9-0.6-3.2,1.4C236.4,437.1,236.6,436.2,236.7,435.4z"
        />
        <path
          class="st410"
          d="M264.9,419.8c0.3,0,0.6,0,0.8,0c1,0.8,0.3,2,0.7,2.9c-0.6,2-1.1,4.1-2.3,5.9c-1.1-3.1-1.3,1.6-2.2,0.3
			c-0.8-0.6-0.7-1.5-0.8-2.4c-0.9-2-0.6-3.6,1.8-4C264.7,422.2,264.7,421,264.9,419.8z"
        />
        <path
          class="st411"
          d="M266.4,422.7c-1.1-0.8-0.6-1.9-0.7-2.9c0.6-3,2-5.9,2.2-9c0.4-0.5,0.8-1.1,1.2-1.6c0.1,0.6,0.2,1.1,0.4,1.7
			C269.7,415.1,268.1,418.9,266.4,422.7z"
        />
        <path
          class="st412"
          d="M238.1,407.1c0-0.3-0.1-0.6-0.1-0.9c0.8-4.4,4.3-6.5,7.5-8.9l0,0c0.9,1,0.7,2,0,3l0,0
			C241.9,401.3,240.5,404.7,238.1,407.1z"
        />
        <path
          class="st410"
          d="M263.5,407.8c1.9,3.4,2.6,6.7-0.8,9.6C263,414.2,263.8,411.1,263.5,407.8z"
        />
        <path
          class="st413"
          d="M241,460.1c0.1,1.6,1.2,1.4,2.3,1.5c-0.3,2.2-2,1.3-3.2,1.5c-0.9-1.4-2.8-2.9,0.1-4.2
			c0.2-0.1,0.1-1.1,0.1-1.7C241.4,457.9,240.9,459.1,241,460.1z"
        />
        <path
          class="st414"
          d="M260.9,416.3c-1-2.7,0.5-4.8,0.7-7C262,411.6,262.5,413.9,260.9,416.3z"
        />
        <path
          class="st395"
          d="M262,428.7c1.3-1.2,0.4-2.7,0.6-4.5c2.1,1.1,1.5,2.9,1.5,4.3c-0.3,0.5-0.5,1.1-0.8,1.6
			c-0.7,0.3-1.2-0.1-1.6-0.6C261.7,429.2,261.8,429,262,428.7z"
        />
        <path
          class="st382"
          d="M262,429.5c0.5,0.2,0.9,0.5,1.4,0.7c0.2,1.8-0.6,3.4-0.9,5.1c-0.9-1.1-0.3-2.5-0.6-3.7
			C261.3,430.9,261.3,430.1,262,429.5z"
        />
        <path
          class="st273"
          d="M309.1,444.4c0.8-1.1,1.5-2.3,2.3-0.1c0.3,0.7,0.7,1.2,1.4,1.5c-1.2,1.6-2.5-0.1-3.7,0.1
			C309.1,445.5,309.9,444.9,309.1,444.4z"
        />
        <path
          class="st415"
          d="M199.9,429.4c-1.6,0.9-3.1,1.8-4.7,2.7C196.4,430.4,197.1,428,199.9,429.4L199.9,429.4z"
        />
        <path
          class="st416"
          d="M199.1,435.4c-1.8,1.3-3.1,3.7-6,2.2l0,0c0.9-2,2.8-1.8,4.4-2.3C198.1,435.4,198.6,435.4,199.1,435.4z"
        />
        <path
          class="st347"
          d="M197.6,435.4c-1.5,0.8-2.9,1.5-4.4,2.3c-0.3-0.3-0.6-0.5-0.9-0.8C194.1,436.3,195.4,434.4,197.6,435.4z"
        />
        <path
          class="st417"
          d="M230.5,413.7c-0.4,0.4-1.3,1.1-1.2,1.2c1.5,2.7-0.8,3.5-2.3,4.7c-1.4,1.1-4.4,1.1-2.4,4.2
			c0.1,0.1-0.5,0.8-0.8,1.2c-3.7,1.8-3.5-0.4-3-2.9c0.3-1.1,0.4-2.3,1.4-3c1-0.3,2,1.4,3,0c-0.2-1.4,0.8-2.5,0.8-3.9
			c0.1-1.5,1.1-2.5,2.8-2.2C229.5,413.1,230,413.4,230.5,413.7z"
        />
        <path
          class="st418"
          d="M248.6,384.6c0.5,0.8,2.2,1.7,0.5,2.5c-4.7,2.2-7.1,6.6-10.2,10.4c-0.4,0.5-0.9,1.2-2.5,0.6
			c3.1-2.9,4.4-6.8,7.8-9.1c0.7-0.5,2.4-0.7,1.4-2.4C246.5,386,247.6,385.3,248.6,384.6z"
        />
        <path
          class="st419"
          d="M229.8,427.9c1.4,1.8-0.4,2.1-1.3,2.5c-3,1.2-6.1,2.3-9.1,3.5c-0.5-4.1-0.1-7.7,5.1-8.2
			c1.3-1.5,2.7-2.8,5-2.6c-0.4,1.6-2.2,1.1-2.8,2.3c-0.4,1,0.3,1,0.9,1c-0.1,3.4-3.7,2.1-4.7,3.6
			C225.1,429.9,226.9,427.1,229.8,427.9z"
        />
        <path
          class="st128"
          d="M312.1,332.9c1.7-1.3,3.5-2.5,5.2-3.8c0.7,0.4,2.1-1.5,2.1,0.8c-2.2,0.1-3.4,1.9-5.2,2.8
			C313.5,333,313,334.1,312.1,332.9z"
        />
        <path
          class="st383"
          d="M282.5,410.4c-1.4,0.2-2.8,0.4-4.2,0.6C279.3,409.4,280.7,408.8,282.5,410.4z"
        />
        <path
          class="st420"
          d="M244.7,451.9c0-5.5,0-11,0-16.4c0.2,0,0.4,0,0.6,0c0,5.5,0,11,0,16.4C245.1,451.9,244.9,451.9,244.7,451.9z
			"
        />
        <path
          class="st356"
          d="M228.9,412.9c-1.1,0.9-3.2,1.1-1.9,3.6c0.6,1.1-1.5,1.3-1.8,2.5c-1.2-2.4,0.1-4.4,1.2-6.3
			C226.8,412,228.4,410.5,228.9,412.9z"
        />
        <path
          class="st421"
          d="M236.5,400.5c-1,1.4-1.7,2.6-2.7,3.6c-0.8,0.9-0.6,3.3-2.4,2.2c-1-0.6,0.6-2.1,1.3-2.9
			C233.5,402.4,233.8,400.5,236.5,400.5z"
        />
        <path
          class="st422"
          d="M230.1,407.8c1.3-0.1,1.9,0.4,1.9,1.4c0,0.6-0.4,1.6-0.9,1c-0.8-1.1-2.4,0-2.6-1.3
			C228.3,407.7,229.5,407.9,230.1,407.8z"
        />
        <path
          class="st423"
          d="M261.2,433.1c-1.4,2.1,0.1,4.9-1.9,7.1c-0.8-3-0.4-5.5,1.1-7.8C260.9,432.4,260.9,432.9,261.2,433.1z"
        />
        <path
          class="st424"
          d="M261.2,433.1c-0.2-0.2-0.5-0.5-0.8-0.8c0.4-2-0.7-4.1,0.8-6c0.9,0.5,0.7,1.5,0.8,2.3c0,0.2,0,0.5,0,0.8
			c0,0.7-0.1,1.4-0.1,2.1C261.6,432.1,261.4,432.6,261.2,433.1z"
        />
        <path
          class="st425"
          d="M245.5,400.3c0-1,0-2,0-3c3.4-2.8,7.4-4.4,11.7-5.4C253.3,394.8,248.5,396.3,245.5,400.3z"
        />
        <path
          class="st426"
          d="M229.8,427.9c-3.1,0.5-5.5,3-8.9,3.3c1.4-3.3,5.7-1.7,6.8-4.8C228.7,426.5,229.8,426.5,229.8,427.9z"
        />
      </g>
      <g>
        <path
          class="st427"
          d="M702.2,627.1c0.3,0.8,0.8,1.3,1.5,1.8c10.1,7.3,21,13.3,31.7,19.7c20.5,12.3,41,24.8,61.6,37.2
			c20.7,12.5,41.4,24.8,62,37.3c0.7,0.4,1.4,0.8,2,1.3c7.9,5.3,10.5,12,8.4,21.5c-0.4,5.1-4.2,7.7-7.8,10.4
			c-12,9.1-25.3,16.1-38.2,23.8c-52.7,31.8-105.2,63.8-157.8,95.7c-38.9,23.6-77.8,47.2-116.8,70.8c-2.2,1.3-4.3,2.8-7,2.8
			c-4.5-1.8-7.9,1.1-10.9,3.4c-6.5,4.8-13.8,7.2-21.5,8.8c-4.8,1-9.6,0.2-14.2-1.1c-3.3-0.9-5-3.5-5.2-6.8s-1.7-5.2-4.7-6.5
			c-3.2-1.4-5-4.1-4.5-7.7c0.4-3.3-1-5.5-3.2-7.6c-9.3-8.6-19.8-15.6-30.4-22.4c-20.7-13.3-41.5-26.3-62.1-39.8
			c-28.9-18.8-58.4-36.7-87.7-54.9c-5.7-3.6-11.3-7.2-16.7-11.3c-1.6-1.3-3.5-2.3-3.2-4.9c5.1-2.9,10.8-4.6,16.4-6.2
			c3-0.9,5.8,1.1,8.5,2.7c20.8,12.3,40.9,25.5,59.8,40.6c4.2,3.4,9,6.1,13.5,9.2c4.6,3.1,5.3,4.5,3.8,10c-0.9,3.2,1,4.3,3.1,5.6
			c13.4,8.1,26.6,16.5,39.8,24.9c19.3,12.2,39.1,23.7,58.2,36.1c15.8,10.3,32.8,10,50,2.9c0.4-0.2,0.9-0.4,1.3-0.6
			c8.5-5.5,15.8-12.7,24.3-18.3c2.1-1.4,3.2-3.9,4-6.4c1.4-4.3,4-7.6,7.8-9.9c3.7-2.3,7-5,10-8.2c1.8-1.9,3.8-3.4,6.4-4.1
			c7.8,0.2,13.1-5.3,19.3-8.7c3.1-1.7,6-3.7,8.9-5.6c1.9-1.2,3-3,1.6-5.3c-1.2-2-2.8-3.6-5.5-2.6c-1.1,0.4-2.1,0.8-3.2,0.8
			c-2.5,0.1-3.7-1.2-3.2-3.5c0.6-2.8,1.9-5.4,2.8-8.1c0.7-2.2,3-2.2,4.9-1.6c2.8,0.8,5.2,2.5,7.4,4.2c6.9,5.5,9.4,5.7,16.8,1.2
			c12-7.2,24.2-14.3,36-22c1.4-0.9,3.2-1.5,3.4-3.6c-1.3-1.1-2.1,0.1-2.9,0.7c-12.5,7.8-24.9,15.6-37.4,23.3
			c-3.7,2.3-7.5,3.7-11.7,1.2c-1.8-1.1-3.5-2.5-3.1-5c0.3-2.3,2.3-2.8,4.3-3.2c7.5-1.5,12.8-7,19.3-10.4c4.3-2.2,8.2-4.9,12-7.9
			c4.2-3.3,6.9-7.3,6.3-13.1c-0.4-4.4,1.5-5.6,5.5-3.7c4.7,2.3,9,5.4,13.6,8.1c4.9,2.9,9.3,2.8,14.3-0.3
			c10.3-6.5,20.8-12.5,31.2-18.8c2.8-1.7,6.6-3.1,5.9-7.6c-5-4.4-11.1-7.3-16.3-11.5c-1.6-1.3-3.3-2.6-4.3-4.4
			c-0.9-1.6-2.1-3.4,0.1-5c2.2-0.8,4,0.2,5.9,1.2c5.4,3.1,10.7,6.3,15.8,10c7.2,5.2,11.9,5.2,19.2,0.4c5.2-3.4,10.6-6.5,16-9.7
			c1.5-0.9,3-1.7,4.5-2.8c1.3-1,3.1-1.8,2.7-3.6c-0.3-1.8-2.5-0.7-3.7-1.5c-6.7-11.7-20.1-14.8-29.5-23.1c-4.7-4.1-9.6-7.9-14.2-12
			c-2.9-2.6-4.8-5.7-2.2-9.6c3.7-3.6,8.7-5.2,12.6-8.6c2-1.7,3.3-3.4-0.5-4.5c-3.7-0.6-6.5-2.4-8.7-5.4c-2.3-3.2-5.5-5.2-9.1-6.8
			c-5.7-2.5-7.1-5.7-5.1-11.5c1.6-4.5,1.5-9.1,1.2-13.7c-0.2-3.8-1-7.5,0.4-11.3c0.8-2.2-1.1-3.9-2.5-5.4
			c-3.6-4.1-7.7-7.6-12.2-10.7c-2.2-1.5-4.6-3-4.5-6.3C697.7,626.8,699.2,625.2,702.2,627.1z"
        />
        <path
          class="st428"
          d="M870.5,767.9c1.9,1.7,3.8,3.4,5.7,5.2c2.2,2.1,4.1,4.5,5.3,7.3c2.3,5,2.1,9.9-0.8,14.7
			c-1.4,2.3-3.3,4.3-5.3,6.2c-2.4,2.1-5.1,3.9-7.8,5.6c-27.9,17.8-56.4,34.7-84.8,51.5c-15.4,9.1-30.8,18.2-46.3,27.2
			c-6.4,3.7-12.1,8.3-18.3,12.3c-2.5,1.7-5.1,3.3-8,4.2c-1.3,0.4-2.6,1.2-3.8,1.9c-13.8,8.3-27.7,16.7-41.5,25
			c-1.5,0.9-2.9,1.9-4.4,3c-2.5,1.8-5,3.4-8,4.1c-1.7,0.4-3.2,1.4-4.7,2.4c-2,1.4-3.9,2.8-5.9,4.2c-1.2,0.8-2.3,1.7-3.6,2.3
			c-11,5-21.1,11.4-31.3,17.7c-22,13.4-44.1,26.6-66.5,39.2c-5.7,3.2-11.5,6.2-17.3,9.4c-11.2,6.1-22.8,6.7-34.8,3
			c-4-1.2-7.8-2.9-11.5-4.8c-5.1-2.7-10.2-5.3-15.1-8.3c-21.9-13.2-44.1-25.9-66.2-38.8c-50.1-29.2-99.6-59.5-149.5-89.1
			c-33.8-20-67.9-39.6-101.5-60c-8.3-5.1-16.6-10.2-24.9-15.3c-2.3-1.4-4.5-3-6.3-5.1c-4.9-5.7-5.7-13.5-1.9-20.1
			c1.8-3,4.4-5,7.5-6.5c1.8-0.8,3.1,0.2,4.4,1.2c11,8.8,23.7,15,35.8,22.2c27.1,16.2,53.8,33.3,81.2,49.1
			c43.2,24.9,85.6,51.1,128.3,76.7c34.7,20.8,69.8,40.8,104,62.2c6.2,3.9,12.5,7.6,19.9,9.3c8.4,1.9,16.1,0.2,23.7-3.3
			c2-0.9,3.8-2.4,6.1-2.8c6.5-2.5,11.7-7.1,17.5-10.6c87.9-52.4,175.4-105.5,263.3-157.9c17.1-10.2,34.2-20.6,51.4-30.7
			c5.5-3.2,10.5-6.8,13.8-12.2C868.9,768.5,869.7,768.1,870.5,767.9z"
        />
        <path
          class="st429"
          d="M278.1,798.7c7.7,6,15.8,11.3,24.2,16.4c6.3,3.8,12.5,7.7,18.7,11.6c9.8,6.1,19.5,12.1,29.2,18.2
			c6.4,4,12.6,8.1,19,12c14.2,8.6,27.8,18.1,41.7,27.2c8.7,5.6,17.7,10.8,26.4,16.5c9.7,6.2,19.5,12.1,28.6,19.2
			c3,2.4,6.2,4.5,9.2,6.9c2.2,1.8,4.3,3.8,6.2,5.9c1.7,1.8,2.3,4.1,1.8,6.7c-0.7,3.8-0.1,4.8,3.3,6.2c0.9,0.4,1.8,0.7,2.6,1.1
			c1.9,0.9,2.9,2.4,2.9,4.6c0,1.1,0,2.2,0.1,3.3c0.3,2.3,1.6,3.9,3.9,4.3c5.5,1,11,1.8,16.5,0c4.2-1.4,8.7-2,12.6-4.3
			c1.5-0.9,3.1-1.7,4.5-2.8c2.8-2.2,6-3.6,9.4-4.6c1.3-0.4,2.1,0.2,2.8,1.2c0.1,1.7-1,2.7-2.2,3.4c-6.4,3.5-12.2,8-18.5,11.5
			c-16.7,7.5-33,7.4-49-2.2c-7.4-4.5-14.9-8.7-22.3-13.2c-45.2-27.9-90.8-55.3-136.6-82.4c-46.3-27.4-92.3-55.4-138.5-82.9
			c-11.2-6.7-22.5-13.3-33.8-20c-6.6-3.9-12.8-8.4-18.9-13.1c-3-2.4-4.7-5.1-5.1-8.7c-1.5-8.1,1.7-14.3,8.1-19.2
			c1.5-1.2,3.2-2.2,4.8-3.2c6.2-3.7,12.4-7.3,18.5-11c16.8-10.2,33.5-20.6,50.2-30.8c7.1-4.3,14.2-8.6,21.3-12.9
			c4.6-2.8,9.1-5.8,13.7-8.8c1.9-1.2,3.8-2.4,5.9-3.1c2.7-0.2,3.6,1.9,4.5,3.7c6.9,14,17.2,25.2,27.4,36.7c0.5,0.6,1.1,1.1,1.6,1.8
			c3.9,4.7,3.6,8.4-1.5,11.6c-9.1,5.8-16.9,13-23.6,21.4c-7.3,9.3-11.9,19.5-11.2,31.8c0.5,8.2,3.6,14.8,10.9,19
			c2.1,1.2,4.3,2.2,6.2,3.7c5.7,5.5,13,8.5,19.5,12.7C275.5,795,278,795.8,278.1,798.7z"
        />
        <path
          class="st430"
          d="M284.6,581.8c0-0.3,0-0.6,0.2-0.9c2.2-2.5,4.3-5.2,7.2-6.9c0.8-0.3,1.7-0.1,2.3-0.6
			c8.8-7.4,20.7-6.8,30.4-12.2c4.9-2.7,11-3.1,16.8-2c7,1.3,14-0.8,21-0.9c1.1,0,2.2-0.6,3-1.4c3.5-3.8,8.1-3.8,12.8-4
			c10.6-0.6,20.8,2.6,31.1,3.8c8.3,2.5,15.5,7.5,23.1,11.5c13.4,7.2,26.8,14.2,39.9,21.7c3.6,2,7.5,3.8,8.6,8.4
			c0.2,2.3,0.4,4.6,1,6.8c3.3,16.5,6.1,33.1,10,49.5c0.4,1.7,0.5,3.4,0.7,5.2c0,1.4-0.2,2.8-0.1,4.2c0.2,3.7-1.8,5.5-5,6.4
			c-4.4,1.2-8.6,2.8-13.3,2.6c-1.8,0.4-3.6,0-4.9-1.2c-2.6-2.3-5.8-2.6-9-2.3c-10.3,1-21.1-0.3-30.9,2.3c-15.1,4-30.6,0-45.4,4.3
			c-2.9,0.8-5.7,0-8.1-1.9c-5.5-2.8-11.4-2-17.1-1.8c-5.2,0.1-10.3,0.9-15.5,0.9c-0.5,0.1-1,0.2-1.5,0.2c-0.8,0-1.5-0.1-2.2-0.3
			c-8-3.5-15.5-7.9-22.7-13c-1.9-1.4-4-2.7-6.2-3.5c-5.5-3-9.2-7.3-10.7-13.4c-4.2-16.8-9.1-33.4-12.7-50.4
			C286.3,589.2,285.6,585.5,284.6,581.8z"
        />
        <path
          class="st431"
          d="M704.5,377.5c6,7.6,10,16.3,13.2,25.4c5.4,15.5,10,31.3,14.4,47.1c3.1,11.1,6.1,22.1,9.1,33.2
			c1,3.6,2.3,6.9,3.8,10.3c5,10.8,6.3,22,2.4,33.5c-10.4,13.3-23.5,22.5-39.9,27c-7.8,2.2-15.8,3.6-24,3c-1.9-0.1-3.8-0.1-5.4-1.5
			c-1.4-1.8-3.6-2-5.6-2.3c-5.8-0.7-10-4.2-12-9.2c-7.8-20-17.7-39.4-22-60.7c-4.1-20.7-3.2-41.2,2.1-61.6
			c3.6-14,10.3-26.3,19.6-37.3c8.4-10,19-15.1,32-15.3c3.7-0.1,6,2.1,7.9,4.7C701.4,375.5,702.7,376.7,704.5,377.5z"
        />
        <path
          class="st432"
          d="M311.8,655.7c5.9,3.4,11.9,6.8,17.8,10.2c3.5,2,6.9,4.1,10.7,5.4c0.5,0.1,0.8,0.5,1.1,0.7l0.1,0.1
			c3.7,0.6,6.9,2.1,10,4.2c8,5.3,16.9,9.1,24.4,15.1c1,0.8,2.4,0.9,3.6,1.3c4,2.8,9.1,4.1,12.1,8.4c5.5,7.5,11.4,14.5,18.9,20
			c5.9,4.2,11,9.2,15.6,14.8c4,4.8,8.6,9,13.6,12.7c11.9,8.8,22.1,19.4,32.4,29.8c2.5,2.5,3.9,5.5,3.2,9.1c-0.5,2.7,0.6,4.2,2.7,5.6
			c4.8,3.2,9.1,7,13.5,10.7c1.4,1.2,3.4,1.9,3.8,4.1c-2.9,4.2-4.5,4.7-9.9,2.8c-1.3-0.7-2.5-1.5-3.8-2.2c-1.8-2.6-4.7-3.8-7.2-5.3
			c-2.2-1.3-4.5-1.1-6.8,0.2c-12.4,7-21.1,23-12.3,38.4c1.6,2.8,2.8,5.8,2.5,9.1c-0.3,1.5-1,2.8-2.4,3.7c-1.1,0.4-2.2,0.9-3.4,1.2
			c-3.9,0.6-7.1-0.9-10.1-3.2c-15.1-11.8-29.3-24.5-43.4-37.4c-14.2-13-28.2-26.2-43-38.6c-2-1.6-3.9-3.3-5.4-5.4
			c-1.3-3.4-4.3-5.4-6.7-7.8c-3-1.6-4.1-5.8-8.3-5.7c-3.9-1.3-3.3-5.8-5.8-8.2c-0.8-1.3-1.6-2.5-2.5-3.8c-4.1-3-7.4-6.7-10.2-10.8
			c-0.6-0.5-1.1-1.2-2-1.3c-4,1.7-7.4,1.3-9.7-2.9c-0.5-0.8-1.2-1.6-2.3-1.2c-1,0.4-1.2,1.5-1.2,2.4c0,6.8-1.6,13.6-1,20.4
			c0.1,1.6,0.1,3.4,2,4c1.8,0.5,3-0.8,4-1.9c0.5-0.6,0.9-1.3,1.5-1.9c3.3-3.5,5.1-3,6.6,1.8c0.2,2-0.2,3.9-1.4,5.4
			c-2.1,2.6-1.3,4.9,0.5,7.7c1.5-2.3,1.6-5,3.7-6.4c1.4-1,2.9-1.5,4.5-1c3.4,0.8,4.4,3.2,4.1,6.4c-0.8,2.6-2.1,4.8-4.9,5.7
			c-1.4,0.4-1.1,1.7-1.6,2.5c-1.1,1.6-2.8,2.3-4.5,2.5c-4.6,0.5-8.8,2.3-13.2,3.5c-9.1,0.5-17.8,3.5-26.8,4.5
			c-7.4,0.8-14.9,1.8-21.8-2.4c-1.8-1-3.6-2.1-5.5-3c-8.2-3.9-11.8-10.8-12.8-19.4c-0.8-6.5,0.1-12.7,2.4-18.8
			c3.7-9.6,9.5-17.7,16.9-24.6c5.2-4.8,10.8-8.9,16.4-13.2c1.1-0.9,2.3-1.8,3.3-2.7c0.9-0.8,0.9-1.7,0.2-2.6c-1-1.3-2-2.5-3-3.7
			c-5.2-6-10.5-12-15.6-18.1c-5.9-7.2-11-14.9-14.9-23.3c-0.3-0.6-0.7-1.1-1-1.6c-2.7-6.3-3.3-12.8-2.9-19.5
			c0.2-4.8,1.6-9.3,3.4-13.8c0.3-0.7,0.9-1.2,1.4-1.9c4.6-1.1,3.9,2.5,3.6,4.6c-0.4,3.2,0.5,5.5,2.3,8c9.3,12.4,18,25.3,29.7,35.7
			c17.5,15.4,33.9,32,51.9,46.8c13.9,11.5,27.2,23.7,40.8,35.6c20.2,17.6,41.2,34.4,62.2,51.1c2.6,2.1,5,4.4,7.9,6.3
			c2.4,1.6,4.7,1.6,7.2,0.2c7.1-4.2,8.6-11.1,3.5-17.5c-4.6-5.9-10.9-9.7-16.6-14.4c-17.7-14.5-34.9-29.5-50.7-46
			c-10.1-10.6-21.9-19-32.9-28.6c-13.4-11.7-25.4-24.8-37.9-37.3C310,658.3,310.2,657.7,311.8,655.7z"
        />
        <path
          class="st433"
          d="M469,281.3c0.2-3.1-0.5-6-1.5-8.9c-1.8-5-2.3-10.3-1.7-15.6c0.8-6.2,1.6-12.3,2.7-18.5
			c0.8-5,0.3-9.9-1.4-14.6c-1.1-3.3-2.4-6.6-3.4-9.9c-0.9-2.9-1.7-5.8-1.1-8.9c0.6-4.6-0.9-9.1-0.3-13.7c0.5-3.5,0.3-7.3,3.5-9.9
			c2.2-1.1,3.9,0,5.8,1.1c15.1,8.9,31.4,14.9,48.8,17.1c8.9,1.1,18.1,0.7,27.2-0.1c5.3-0.5,11.3-2.4,15.1,3.8c1.9,4.5,3,9.2,3.5,14
			c-1,4,0.5,7.8,2.3,11c4.3,7.6,5.5,15.9,6.2,24.4c0.2,2.1,0.5,4.1,1,6.1c4,16.3-0.8,30.4-10.9,43.1c-11.4,14.4-27.7,17.9-44.6,19.6
			c-3.6,0.4-7.2,1.2-10.9,1c-0.5,0-1,0-1.5,0c-1,0-1.9-0.1-2.9-0.4c-6.4-1.9-11.4-6.1-16.9-9.6c-2.2-2-4.1-4.4-6.6-6
			c-3.3-4.7-5.6-10-8.7-14.9C471.3,288.1,469.5,284.9,469,281.3z"
        />
        <path
          class="st434"
          d="M311.8,655.7c-0.3,0.4-0.5,0.9-0.1,1.3c0.8,1,1.7,1.9,2.6,2.8c8.1,8.1,16.4,16.2,24.4,24.4
			c9.2,9.6,19.4,18,30.1,25.9c1.6,1.2,3,2.4,4.4,3.7c3.2,3.2,6.4,6.3,9.5,9.5c8.5,9,17.6,17.2,26.7,25.5c6.1,5.6,12.4,11,18.8,16.3
			c6.6,5.5,13.6,10.6,20.1,16.2c3.1,2.6,5.3,5.9,7.3,9.4c1.2,2.1,1.4,4.2,0.1,6.3c-2.3,3.6-4.3,7.4-8.2,9.8
			c-2.8,1.7-5.5,1.5-8.1-0.2c-1.6-1-3.1-2.2-4.6-3.5c-6.1-5.4-12.6-10.2-19-15.3c-5.6-4.5-11.1-9-16.7-13.6
			c-5.2-4.2-10.4-8.5-15.5-12.8c-5-4.2-10-8.5-14.9-12.8c-6-5.3-11.8-10.7-17.9-15.9c-4.7-4-9.4-8.2-14.1-12.2
			c-5.5-4.6-10.7-9.5-16.4-13.9c-4-3.1-7.5-6.9-11.4-10.2c-6.8-5.7-12.7-12.2-19.1-18.1c-5.2-4.7-10.8-9-15.8-14
			c-7.2-7.1-13.5-14.9-19.6-22.9c-3.3-4.3-6.4-8.8-9.8-13c-1.8-2.2-2.2-4.5-1.5-7.1c0.2-0.9,0.4-1.9,0.4-2.8c0-1.3-0.8-2-2-2.3
			c1.6-4.6,4.7-8.3,8-11.8c3.5-3.8,7.6-6.8,11.8-9.7c5.7-3.9,11.7-7.2,17.9-10.2c1.9-0.9,3.7-1.9,5.5-2.8c1.4,0.2,2.4,0.9,2.9,2.2
			c0.3,0.7,0.4,1.5,0.6,2.3c2,12.4,5.7,24.5,8.8,36.7c1.5,6,3.1,12,4.5,18.1c1.4,5.8,4.1,10.6,9.5,13.7
			C311.2,654.9,311.5,655.3,311.8,655.7z"
        />
        <path
          class="st435"
          d="M117.1,740.5c1.3,2.6,3.3,4.6,5.5,6.5c9.8,8.1,20.6,14.5,31.6,20.6c5.6,3.1,11,6.5,16.5,9.8
			c9.8,6,19.6,12,29.5,17.9c17.7,10.6,35.4,21.1,53.1,31.7c18.8,11.3,37.5,22.6,56.2,33.9c14,8.4,28,16.6,42.1,24.9
			c12.6,7.5,25.3,14.9,37.9,22.5c11.5,6.9,22.8,14.2,34.3,21.1c15.4,9.2,30.4,18.8,46,27.7c4.2,2.4,8.3,4.8,12.8,6.6
			c10.1,4,20.3,3.6,30.5,0.4c2.6-0.8,5-2,7.8-2.2c3.2,1.4,3.3,4.6,3.6,7.2c0.5,4.4,2,9.1-1.6,13c-6,4.3-12.8,6.5-20,7.5
			c-4.3,0.6-8.6-0.4-12.8-1.4c-4.1-1-7.9-2.7-11.4-4.8c-10.5-6.4-21-12.8-31.6-19.1c-8.4-5-16.8-9.8-25.2-14.8
			c-9.5-5.6-19-11.2-28.5-16.8c-17.9-10.8-35.8-21.7-53.9-32.2c-11-6.4-21.9-13.2-32.8-19.8c-17.7-10.8-35.5-21.5-53.5-31.9
			c-20-11.7-39.9-23.5-59.6-35.7c-15.5-9.6-31.1-18.9-46.8-28.3c-4.9-3-9.9-5.8-15-8.6c-4.1-2.3-7.5-5.4-11.1-8.4
			c-0.6-0.5-1.2-1-1.9-1.5c-1.9-4.8-2.4-9.7-2.1-14.8C117.1,747.8,117,744.1,117.1,740.5z"
        />
        <path
          class="st436"
          d="M522.8,982c0.5-6.9-0.5-13.6-2-20.2c3.3-3.2,7.3-5.5,11.2-7.9c3.2-2,6.6-3.8,9.9-5.6
			c11.1-6.7,22.2-13.3,33.2-20c14.6-8.8,29.3-17.7,43.9-26.5c12.3-7.5,24.5-15,36.8-22.5c14.3-8.7,28.7-17.4,43.1-26.1
			c10.9-6.6,21.8-13.3,32.7-20c14.3-8.7,28.7-17.4,43.1-26.1c14.6-8.8,29.3-17.7,43.9-26.5c10.1-6.1,20.2-12.3,30.4-18.3
			c7.7-4.5,14.7-9.7,20.4-16.5c1.5,3.6,1.9,7.4,1.1,11.2c-0.8,3.7-0.8,7.2-0.1,10.9c-0.2,2.7-1.6,4.7-3.3,6.7
			c-3.8,4.3-8.7,7-13.5,9.8c-19.5,11.6-38.9,23.2-58.4,34.9c-16.9,10.1-33.7,20.1-50.6,30.2c-18.3,11-36.7,22.1-55,33.1
			c-13,7.8-25.9,15.8-39,23.3c-12.7,7.3-25.3,14.9-37.9,22.5c-9,5.5-17.8,11.3-26.8,16.8c-7.9,4.8-15.9,9.5-23.9,14.2
			c-11.1,6.6-22.6,12.7-33.2,20C527,980.8,525.2,982.2,522.8,982z"
        />
        <path
          class="st437"
          d="M564.3,217.1c-1-4.4-2.5-8.6-2.9-13.1c-1.2-6-2.5-7.1-7.7-6.6c-22.9,2.3-45.1-0.1-66.5-8.9
			c-4.6-1.9-9-4.1-13.4-6.2c-2.3-1.1-4.7-2.2-7.2-0.6c-1.9,3.6-1.9,7.6-2.2,11.6c-0.2,2.8-0.1,5.7-0.1,8.6c0,1.4-0.4,2.5-1.7,3.1
			c-2.7-5.9-3.8-12.2-5-18.4c-0.5-2.5-0.7-5.1-1.1-7.6c-1.1-7-3.4-13.6-7.3-19.6c-3.2-5-5.8-10.3-7.1-16.1
			c-2.3-10-1.3-19.7,3.4-28.9c1.3-2.7,3.2-4.7,6.3-5.3c1.6,2.8,0.3,5.3-1,7.6c-6.4,11.4-5.1,18.3,1.3,32.1
			c-1.2-4.9-2.4-9.8-2.6-14.8c-0.2-5.1,0.5-10.1,2.5-14.9c0.4-0.9,0.8-1.8,1.4-2.5c1-1.3,2.3-2.6,4-1.9c1.8,0.8,1.4,2.6,0.9,4.2
			c-9.8,21.5,9.4,45.2,27.5,52.5c-0.4-2.1-2.2-2.7-3.4-3.9c-5-4.5-9.6-9.3-14.3-14.1c-1-1-1.8-2.2-2.2-3.6c-0.3-1.2-0.6-2.6,0.6-3.5
			s2.4-0.2,3.5,0.5c1.2,0.8,2.1,2,3,3c6.5,6.8,13.2,13.4,21.7,17.9c0.6,0.3,1.2,0.7,1.7,1.1c0.4,0.3,0.7,0.6,1,0.9
			c11.1,7.9,23,10.1,35.9,4.8c5-2.1,5.2-3.5,1.2-7.3c-2.1-2-4.9-2.9-7.2-4.5c-0.4-0.2-0.8-0.4-1.2-0.5c-0.7-0.2-1.3-0.4-2-0.6
			c-11.6-6.5-24-11.2-35.6-17.6c-6.5-3.6-13.4-7-17.7-13.5c-0.5-3.8-3.9-6.2-4.8-9.7c-0.7-2.4-1.9-4.8-1.5-7.4c0.2-1.7,1.1-3,3-3.2
			c1.7-0.1,2.6,0.9,3.2,2.3c0,0.1,0.1,0.2,0.2,0.4c1.5,12.4,10.4,18.7,20.1,24.3c14.1,9.1,29.9,15.1,44.7,22.8
			c0.7,0.3,1.3,0.6,1.8,1.1c0.4,0.3,0.7,0.6,1,1c3.2,1.8,6.1,4,9.2,5.9c3,1.8,5.6,1.4,8.1-1.2c3.9-4.1,5.5-8.8,5-14.3
			c-0.6-6.6,0.8-8.7,7.3-9.8c4.8-0.8,8.1-3.1,10.1-7.6c1.8-4.2,4-8.3,6.5-12.2c3.2-5.1,4.8-10.6,3.8-16.7c1.9-2.5,3.5-0.5,4.7,0.6
			c4.8,4.2,8.2,9.4,9.2,15.7c0.7,4,1.1,7.6,5.7,8.8c1.2,0.3,1.7,1.4,0.8,2.6c-3.9,1.4-5.9,4.4-7.5,8c-2,4.4-4.8,8.3-6.7,12.7
			c-1,2.3-0.9,4,1.5,5.1c4.2,2,7.8,4.9,11.1,8.3c2.4,2.5,4.5,1.9,6.2-0.9c0.4-0.7,1.2-1.3,1.2-2c0.8-7.9,4.7-15.6,1.2-23.8
			c-0.6-1.3-1-2.7-0.1-4.1c3.4-1.2,5,1.5,6.5,3.5c4.9,6.4,7.7,13.8,9.1,21.7c1.9,10.3,1,20.4-0.9,30.6c0.1,2.6-0.9,5-1.6,7.4
			c-2.6,8.3-5.4,16.5-8.2,24.7c-1.5,2.2-3.7,2.3-6,1.8c-11.7-2.5-16.9,4-19.8,12.5c-1,2.9-1.5,5.9-2.4,8.8c-1,3.4-2.8,6.4-5.8,8.5
			c-2.5,1.8-5.2,2.6-8.1,1.4c-3-1.3-4-3.9-3.9-7.1c0.2-8.4-0.8-16.6-5.1-24C567.8,223.5,565.3,220.7,564.3,217.1z"
        />
        <path
          class="st438"
          d="M409.6,558.8c-1.4-0.4-2.8-0.7-4.1-1.2c-2-0.7-4-1-6.1-0.3c-1.7,0.6-3.1,0.2-4.6-0.6
			c-2.9-1.4-6-1.9-9.3-1.8c-4,0.1-8,0.1-12,0c-1.9,0-3.5,0.5-4.7,1.9c-2.7,3.3-6.2,4.2-10.3,3.9c-2.1-0.2-4.1,0.1-6.2,0.5
			c-3.8,0.7-7.6,0.8-11.4,0c-2.4-0.5-4.7-0.6-7.1,0.1c-0.6,0.2-1.3,0.2-1.9,0.2c-3.8,0-7.2,1.3-10.3,3.3c-1.9,1.2-4,2-6.2,2.5
			c-3.9,1-7.7,2-11.6,3c-2.3,0.6-4.5,1.5-6.1,3.5c-1.8,2.2-3.9,2.5-6.4,1.2c-2.9,1.6-4.8,0-6.5-2.1c-1.9-2.5-2.3-5.5-3-8.4
			c-1.3-5.2-2.7-10.5-3.9-15.7c-4.7-20-9.3-40-13.9-60c-0.3-1.2-0.6-2.5-0.8-3.7c-0.5-2.8,0.3-5,3-6.4c4-0.2,7.5,1.2,10.9,3.1
			c38.4,21.9,77.2,43.3,115.7,65.2c4.2,2.4,8.9,3.7,13,6.2C407.9,554.6,410.4,555.6,409.6,558.8z"
        />
        <path
          class="st439"
          d="M678.4,554.8c14.5,1.6,28.2-1.7,41.3-7.6c6.4-2.9,12.1-7.1,17.2-12c2.4-2.3,4.7-4.8,7-7.2
			c1-1,2-1.6,3.4-1.1c1.7,2,2.3,4.6,2.7,7.1c1.2,8.2,2.4,16.4,3.4,24.7c1.7,14.2-2.5,26.5-12,37.1c-2.3,2.6-4.9,5-7.6,7.3
			c-8.4,7.4-17.5,14-26.9,20c-1.8,1.1-3.6,2.1-4.8,3.9c-2.1,0-3.7,0.8-4.9,2.6c-0.4,1.8-0.7,3.6-2.7,4.5c-2.8-0.2-5.1,0.9-7.5,2.3
			c-17.4,10.7-34.4,22-52.2,32c-1.3,0.7-2.1,1.9-3.1,2.9c-0.8,0.5-1.3,1.3-1.9,2c-11,9.1-21.9,18.5-35.8,23.1
			c-4.4,1.5-8.9,2.4-13.5,3c-3.3,0.4-6.1,1.7-7.7,4.9c-2.8,2.9-5.7,5.7-8.3,8.8c-1.4,1.7-3.1,3.2-5.7,2.4c-3.1-2.6-2.3-5.6-0.6-8.5
			c2.1-3.6,4.8-6.7,8-9.3c1.3-1.1,2.6-2.3,3.6-3.8c4.8-3.7,10.3-5.6,16-7.2c9.4-0.5,17-5.4,23.9-11.1c21-17.4,43.9-32.2,66.1-47.9
			c13.5-9.6,27-19.3,38.9-30.9c6-5.8,9.3-13,11-20.9c1.7-7.8-2.6-12.2-10.5-10.6c-12.1,2.4-23.8,0.3-35.6-1.9
			c-0.8-0.2-1.5-0.6-2.1-1.1C674.8,557.5,674.8,556.6,678.4,554.8z"
        />
        <path
          class="st440"
          d="M400,545.2c-0.2-1.3,0.1-2.5,0.5-3.7c3.9-11,6.5-22.2,8.4-33.7c0.7-4.1,1.7-8.1,2.7-12.1
			c1.2-4.6,2.3-9.3,2.6-14c0.1-1.5,0.4-2.9,1.9-3.6c2.4,0.4,3.4-1.3,4.4-2.9c2.7-4.4,6.8-6.3,11.8-5.4c5.3,0.9,10.7,0.6,16.1,1.3
			c2.1,0.3,4,0.8,5.4,2.6c0.6,1.6,0.5,3.2,0.3,4.8c-3.3,11.4-7,22.7-6.6,34.9c0.2,6.4,1.1,12.6,2.7,18.8c0.5,2.1,1.3,4.3,1.2,6.5
			c0,1.1,0,2.2,0,3.3c-0.5,3.4,1.1,6.4,1.4,9.7c0.2,1.7,0.2,3.5-0.5,5.1c-1.3,3-3.2,5.7-4.7,8.6c-1.2,2.4-3.2,3.8-6,3.8
			c-4.9-0.3-8.4-3.6-12.4-5.8c-8.3-4.7-16.5-9.6-25.1-13.7C402.1,548.5,400.1,547.7,400,545.2z"
        />
        <path
          class="st441"
          d="M564.3,217.1c7.8,6.6,11.3,15.2,12.3,25.1c0.3,3,0.1,6.1,0.1,9.1c0,5.2,2.9,6.9,7.5,4.5
			c2.5-1.3,4.3-3.3,5.3-5.9c0.6-1.5,1-3,1.4-4.6c0.6-2.1,1-4.4,1.7-6.5c0.6-2,1.3-4,2.3-5.7c5.1-9,11.7-10.6,19.6-8.8
			c2,0.5,4,1.3,6,0.4c2.6,8.1,4.1,16.4,2,24.9c-1.2,4.7-3,9-6.1,12.9c-5.1,6.4-11.8,9.7-19.8,10.1c-2.2,0.1-3.6,0.8-4.7,2.6
			c-2.3,0.2-3.2,2.1-3.8,3.9c-8.5,26.6-30,36.9-54.3,43.8c-6,1.7-12.2,2.1-18.4,2.5c-0.5-0.1-1-0.1-1.4-0.2c-0.7,0-1.5-0.1-2.2-0.3
			c-1.9-0.9-4.6-0.8-4.4-4c4.2,0.3,8.3-0.7,12.4-1.1c6.7-0.7,13.2-2,19.8-3.5s12.2-4.6,16.9-9.4c1.5-1.5,2.8-3,4.4-4.4
			c6.3-5.3,9.5-12.4,11.5-20c1.2-4.5,2-9,2.9-13.6c0.4-2,0-3.8-0.7-5.6c-1.3-3.5-1.9-7-2.2-10.7c-0.6-6-1-12.1-3.4-17.8
			c-0.5-1.2-0.8-2.5-1.5-3.5C564.6,226.9,563.7,222.1,564.3,217.1z"
        />
        <path
          class="st442"
          d="M421.9,453c-1-3.1-3.1-5.9-3-9.4c0.5-15,0.9-30,3.4-44.9c1.2-7,1.7-14.2,2.2-21.3c0.1-2-0.1-4.2,1.6-5.8
			c2,0.3,3.7,1.2,5.9,1.1c3.5-0.2,5,2.7,6.5,5.5c0.7,1.3,0.8,3.8,2.6,3.5c2.1-0.2,1.9-2.7,2.1-4.3c1.8-9.9,3.3-19.8,2.8-29.9
			c-0.1-2.1-0.3-4.2,0.4-6.2c0.5-1.5,1.2-3.7,3.6-1.5c0.6,11.1,1.1,22.3-0.3,33.4c-0.7,5.2-0.5,10.5-0.6,15.7c-0.1,13-2.9,26-0.7,39
			c0.4,2.2,0.6,4.5,0,6.7c-1.3,5.2-0.6,10.5-0.7,15.7c0.1,1.1,0.2,2.1,0.6,3.1c0.1,0.5,0.2,1,0.3,1.5c0,2.4,1.1,4.5,2,6.6
			c0.8,1.8,1.8,3.7,0.4,5.7c-1.5,1.2-3.2,1.2-5,0.7c-7.2-1.7-13.9-4.6-19.9-8.7C423.7,457.8,422.3,455.7,421.9,453z"
        />
        <path
          class="st443"
          d="M678.4,554.8c-1.3,0.6-1.8,1.6-1.2,3c0.6,1.3,1.1,2.5,1.1,4c0.3,1.6-0.4,2.7-1.6,3.6
			c-0.2,0.3-0.5,0.5-0.8,0.7s-0.6,0.4-0.9,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.2-0.5,0.4-0.8,0.6l-0.4,0.3
			c-0.2,0.2-0.5,0.4-0.7,0.6c0,0-0.4,0.3-0.3,0.3c-0.2,0.2-0.4,0.5-0.7,0.7l-0.3,0.3c-0.2,0.3-0.4,0.5-0.7,0.7
			c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0.3-0.5,0.5-0.7,0.8c-0.1,0.1-0.3,0.2-0.4,0.4c-0.3,0.3-0.6,0.5-0.9,0.7c-0.2,0.1-0.3,0.2-0.5,0.3
			c-0.4,0.2-0.8,0.3-1.2,0.4c-0.4,0.1-0.8,0.1-1.3,0c-0.4-0.1-0.8-0.2-1.1-0.4c-0.2-0.2-0.6-0.5-0.7-0.5c-0.5,0.5,0.2,1,0.1,1.6
			c-1.8,5.8-4.2,7.6-9.3,7.1c-1.7-1.5-3.4-1.6-5.7-0.8c-4.5,1.5-7.4-0.8-7.8-5.5c-0.3-3.2,0.5-6.3,0.7-9.5
			c0.2-5.7,0.5-11.5-0.5-17.2c-0.9-5.3-0.1-10.5,2-15.5c1.5-3.6,1.8-7.3,0.1-10.7c-5.4-10.3-5.7-21.8-8-32.9
			c-1.8-9.1-2-18.4-2.9-27.6c-0.7-7.4,0.8-14.6,1.9-21.9c0.2-1.3,0.5-2.5,0.3-3.8c1.6-16.8,7.5-32,17.4-45.7
			c5.3-7.3,12.1-13.1,18.5-19.2c0.5-0.6,1-1,1.7-1.3c4.5-0.7,8.8-2.4,13.3-3.2c2.4-0.4,3-1.5,1-3.4c-1.6-1.5-3.3-3-4.6-4.8
			s-2.9-3.6-0.5-5.8c2.9,1.2,5.1,3.1,7,5.5c1.5,1.9,3.1,3.6,5.5,4.5c1.2,0.5,2.2,1.3,2.8,2.5c1.5,3.4,4,6.1,6.2,9
			c0.7,1,1.6,2,1.8,3.3c-1.8,0.9-3.4,0.7-4.6-1.1c-0.1-0.1-0.2-0.3-0.3-0.4c-2.8-5-7.2-5.8-12.3-4.8c-2.3,0.5-4.7,0.8-7,1.4
			c-7.4,1.9-13.1,6.5-18,12.1c-5.2,5.9-9.6,12.3-13.1,19.4c-4.6,9.4-7.4,19.4-9.2,29.6c-1.6,9.1-2.2,18.3-2,27.6
			c0.1,7.2,1.2,14.3,2.7,21.3c2.2,11.2,6.2,21.8,10.5,32.3c3.7,9,7.3,18,10.8,27.1c1.1,2.8,2.8,5,5,6.9c1.5,1.3,3.2,2.1,5.2,2.3
			c2.8,0.3,5.7,0.7,8.5,1.2c0.3,0,0.6,0.3,0.7,0.6c0.2,0.5-0.2,0.8-0.6,1C679.9,554.2,679.2,554.5,678.4,554.8z"
        />
        <path
          class="st444"
          d="M514.2,323.7c4.6-0.6,9.2-1.2,13.7-1.9c10.8-1.7,21-5.2,30.4-10.9c3.1-1.9,6.2-3.9,9.3-5.9
			c7-4.6,12.1-10.8,15.2-18.6c1-2.7,2.3-5.3,3.2-8c0.7-1.9,2-3.3,3.4-4.5c1.1-1,1.7-0.7,2.7,1.2c-0.4,3.5-0.8,6.9-0.4,10.4
			c0.5,3.9,0.7,7.9,3,11.3c1.5,3.1,4.9,4.7,6.2,8c0.1,0.5,0.2,0.9,0.3,1.4c0.3,1,0.7,1.9,0.8,2.9c0.8,4,1.8,8,0.2,12.1
			c-0.6,1.5-1,3-2.6,3.7c-2.7,0.2-3.8-2-5.2-3.7c-3.9-5-7.1-10.5-9.5-17c-4.4,7.4-9,14-14.5,20c-9.6,10.3-20.5,18.4-34.9,21
			c-4.5,0.8-8.7-1.4-13.2-0.9c-4.6-4.4-7.2-10-9.1-15.9C512.6,326.8,512.5,325,514.2,323.7z"
        />
        <path
          class="st445"
          d="M599.8,305.5c-0.8-1-1.5-2-2.4-2.8c-1.6-1.6-2.6-3.5-2.7-5.8c7.2,4.6,14.5,9.3,21.8,13.8
			c3,1.8,6.1,3.5,9.2,5.1c5.8,3.1,11.5,6.1,17.3,9.2c1.4,0.8,2.7,1.7,4,2.6c6.7,4.8,13.4,9.7,20.1,14.5c4.8,3.4,9.5,6.9,14.2,10.3
			c-0.2,1.7,0.5,3.1,1.7,4.2c1.7,1.7,3.3,3.4,5,5c0.9,0.9,1.8,1.8,2.6,2.7c1.4,1.7,1,3-1.1,3.3c-4.4,0.6-8.8,1.2-12.8,3.2
			c-1.5,0.7-2.8-0.1-4.2-0.3c-4.5-0.9-6.1-5.4-9.1-8.1c-9.1-8-19.8-13.3-30.1-19.4c-4.8-2.8-9.6-5.7-14.4-8.5
			c-4.8-2.7-8.4-6.1-5.8-11.4c-0.8,5.9-0.8,5.9-4.1,7.3c-2.4-2.7-3.1-6.6-6.3-8.7C603,316.3,603.3,310.6,599.8,305.5z"
        />
        <path
          class="st446"
          d="M400,545.2c0.6,0.4,1.2,1,2,1.3c7.4,2.6,13.9,6.9,20.7,10.6c6.3,3.4,12.4,7,18.6,10.5
			c6.7-0.3,11.7,3.7,17.1,6.6c6.8,3.6,13.4,7.4,20.1,11.2c4,2.3,7.6,5,6.2,10.6c-0.4,1-1,1.7-1.8,2.3c-0.6,0.4-1.3,0.6-2,0.6
			c-0.5-0.1-0.9-0.3-1.2-0.6c-1.8-2.8-4.5-4.4-7.3-6c-5.8-3.3-11.7-6.5-17.5-9.7c-10.2-5.5-20.5-11-30.7-16.4
			c-3.2-1.7-6.4-3.6-9.6-5.2c-1.5-0.8-3.2-1.4-4.8-2c-0.8-1.4-2.1-2.4-3.5-3.1c-2.4-1.2-4.8-2.7-7.3-3.6c-5.5-2-10.2-5.2-15.2-8
			c-6.4-3.6-12.8-7.1-19.3-10.7c-6.3-3.5-12.6-7-18.8-10.5c-7.1-4-14.2-8-21.3-12c-6.4-3.6-12.8-7.3-19.2-10.9
			c-6-3.4-12-6.7-18-10.1c-2-1.1-3.8-2.3-5.8-3.4c-4-2.1-7.6-4.7-12.1-5.7c-1.1-0.2-2-1-2.7-1.9c3.6-3.6,7.8-3.9,12.3-1.9
			c0.6,0.3,1.1,0.6,1.7,0.9c22.7,12.7,45.3,25.5,68,38.2c3.9,2.2,7.5,4.8,11.5,6.8c10.5,5.2,20.3,11.4,30.5,17
			C393.6,541.6,396.8,543.4,400,545.2z"
        />
        <path
          class="st447"
          d="M490.1,138.6c-2.8-1.6-5.7-2.9-8.2-4.8c-7-4.9-12.1-11.2-12.8-20.2c-0.2-2-0.9-2.8-2-2.6
			c-1,0.3-1.3,1-1,2.8c0.7,3.9,2.3,7.6,4.2,11.1c0.9,1.6,1.7,3.1,1.7,4.9c-2.1,1.3-3.7,0.1-5.1-1.1c-3.7-3.1-7.4-6.3-10.3-10.3
			c0.1-1,1.6-2.2,0.1-2.9c-0.8-0.4-1.6,0.9-2,1.8c-2.4,5.1-3.6,10.5-3.3,16.2c0.2,5.3,1.4,10.4,3.2,15.4c0.3,0.8,0.5,1.5,0.7,2.3
			s0.1,1.6-0.6,2.2c-0.7,0.5-1.5,0.2-2.2-0.2c-2-1.4-3.2-3.6-4.1-5.8c-1.5-3.7-2.5-7.6-3.4-11.4c0-0.2,0-0.3-0.1-0.5
			c-1.9-5.7-0.2-11,2-16.2c0.5-1.2,1.1-2.3,1.7-3.4c0.9-1.5,1.8-2.9,1.8-4.8c0-0.7,0.7-1.4,1.1-2.1c1-0.9,2-1.9,3.1-2.8
			c2-1.5,4.1-1.5,6.1-0.2c1.5,0.9,2.8,2,4.2,3c1.3,0.7,2.5,0.6,3.8,0.1c4.8-0.3,9.5-1.1,14.4-0.9c3.2,1.7,5.8-0.4,8.5-1.6
			c7.3-3.2,13.9-7.8,21.5-10.4c2.4-0.8,4.8-1.8,7.2,0.1c1.6,2.1,1.5,4.6,1.1,6.9c-1.3,7.1-3.7,13.9-7.3,20.1
			c-3.3,5.8-8,9.9-15.2,9.9C495.1,133,493.4,137.1,490.1,138.6z"
        />
        <path
          class="st448"
          d="M448.9,339.1c-1,1.2-0.9,2.7-0.9,4.1c0,3-0.1,6,0,9.1c0.2,6.2-0.5,12.4-1.7,18.5c-0.6,3-0.8,6-1.3,9
			c-0.3,1.9-0.8,3.8-2.1,5.2c-1.1,1.2-2.5,1.2-3.7-0.1c-1.2-1.4-1.8-3-2.3-4.7c-0.6-2-1.7-3.8-3-5.4c-0.8-1-2-1.1-3-0.5
			c-0.5,0.3-1,0.8-1.5,1.2c-0.5,0.4-1,0.8-1.7,1.3c-1.2-1.7-0.5-3.5-0.8-5.1c-1.7-1.1-1.4-2.6-1.2-4.2c1.3-14.9,4.2-29.5,8.8-43.7
			c2.5-7.8,6.7-14.7,12-21c1.8-2,3.5-4.1,4.5-6.6c4.8-6.5,11.3-11,18.1-14.9c1.3,0.4,2.1,1.4,2.6,2.6c1.1,2.3,2.1,4.6,2.3,7.2
			c0.4,0.4,0.4,0.8,0.2,1.3c-12.4,9.6-19,22.5-22.3,37.6C451.1,333,452,336.7,448.9,339.1z"
        />
        <path
          class="st449"
          d="M520.1,96.4c-4.6,0.2-8.6,2.5-12.6,4.3c-5,2.2-9.7,4.8-14.6,7.2c-1.4,0.7-2.9,1.4-4.4,1.9
			c-2.9,1-2.9,1-5-1.8c7.1-2.6,13.8-6,20.2-9.8c2.8-1.6,5.6-3,8.7-4c8.5-2.9,17-5.8,26-6.1c16.1-0.5,31.5,2,45.7,10.1
			c4.3,2.5,8.3,5.3,11.7,9c4.1,4.4,6.8,9.6,7.8,15.6c0.1,0.5,0.2,0.9,0.2,1.4c0.2,2.7,1.5,4.2,4.2,5.2c12.9,4.6,20,14.2,23.1,27.2
			c2.7,11.5,2.8,23.1,0.1,34.6c-0.1,0.4-0.4,0.9-0.6,1.3c-0.1-2.2-0.1-4.4,0.5-6.6c0.3-1.4,0.3-2.8,0.4-4.3c0.1-1.6,0.3-3.2,0.5-4.7
			c0.1-0.8,0.4-1.6,0.3-2.4c-0.7-5.2-1.1-10.4-2.2-15.5c-1.4-6.9-4.4-13.3-8.9-18.8c-0.9-1.1-1.9-2.1-3.2-2.8
			c-0.6-0.3-1.2-0.4-1.8,0.1c-4,0.6-6.8-0.6-7.8-4.8c0.2-0.6,0-0.9-0.6-1.1c-3.6-0.9-5.2-3.4-5.6-6.9c0-0.2-0.1-0.3-0.1-0.5
			c-0.3-5.7-3.5-10.2-6.7-14.6c-1.5-2-3.3-3.9-6.1-4c-2.9,1.4-4.7-1.3-7-1.9c-1.8-0.5-3.2-2-5.2-2.3c-14.2-6.6-29-8.8-44.5-6.2
			c-2,0.3-4,1-6,1.4C524.3,97.4,522,98.2,520.1,96.4z"
        />
        <path
          class="st450"
          d="M450.9,296.2c0.2,1.9-0.4,3.4-1.5,4.9c-1.5,1.8-3,3.7-4.4,5.6c-4,5.4-6.9,11.5-9.2,17.8
			c-3.8,10.4-6.4,21.1-7.7,32c-0.6,5-1.6,10.1-1.2,15.2c-0.2,0.4-0.6,0.8-0.6,1.3c-0.5,4.3-0.9,8.5-1.3,12.8
			c-0.5,5.2-1.1,10.4-1.5,15.6c-0.4,4.9-1.6,9.7-1.4,14.7c0.1,1.3,0,2.6-0.3,3.8c-0.6,3-0.7,6,0,9c0.9,4-0.3,7.9-1.4,11.7
			c-0.3,1.1-0.9,2-1.4,3c-3.4-6.9-3.9-14.2-2.1-21.5c3.4-14.2,4.7-28.7,6.3-43.1c1.9-17.6,4.4-35.1,9.1-52.3
			C435.5,314.5,442.1,304.8,450.9,296.2z"
        />
        <path
          class="st451"
          d="M421.9,453c0.5,0.4,1.1,0.7,1.5,1.1c6.8,7.2,15.8,9.9,24.9,12.4c0.8,0.2,1.6,0.1,2.4,0.1
			c4,2.1,4.2,3,1.9,6.8c-0.8-0.2-1.6-0.6-2.3-0.6c-4,0.4-7.9-0.8-11.9-0.5c-1.2,0.1-2.5-0.3-3.8-0.4c-1.3-0.2-2.5-0.3-3.8-0.5
			c-4.7-0.6-7.5,1.8-9.4,5.8c-0.5,1-0.8,2.1-1.4,3c-0.5,0.8-1.3,1.5-2.4,1.3c-0.8-0.2-1.2-0.9-1.4-1.7c-0.1-0.6-0.2-1.3-0.3-1.9
			c0.3-4,1-7.9,2-11.7C419.4,461.9,420.7,457.4,421.9,453z"
        />
        <path
          class="st452"
          d="M282,564.7c0.3,0.5,0.8,1,0.9,1.6c1,4.8,4.3,7.3,8.6,8.9c-1.1,3.2-3,5.4-6.7,5.7c-0.8-3.7-1.5-7.4-2.3-11.1
			C282.2,568.1,281.8,566.4,282,564.7z"
        />
        <path
          class="st453"
          d="M626.4,983.5c0.3,3.4,0.4,6.9,0,10.3C624.9,990.3,624.9,986.9,626.4,983.5z"
        />
        <path
          class="st454"
          d="M782,464.8c0.8-0.6,0.8-1.6,0.8-2.5c0-1.2,0.1-2.4,1.2-3.3c0,1.9-0.1,3.8-0.1,5.6c0,0.6,0,1.4-0.8,1.4
			C782.4,466.1,782,465.5,782,464.8z"
        />
        <path
          class="st455"
          d="M469.1,108.9c-1.3,1.1-2.6,1.1-3.8-0.1C466.6,108.5,467.9,108.5,469.1,108.9z"
        />
        <path
          class="st456"
          d="M733,784.4c1.4,2.5,0.6,4.7-1.2,6.5c-1.4,1.3-3,2.4-4.6,3.4c-11.2,6.8-22.5,13.5-33.7,20.3
			c-3.8,2.3-7.7,2.9-11.8,0.7c-2.1-1.1-4.2-2.3-6.3-3.5c-3.6-2.1-7.1-4.3-10.7-6.5c-0.7-0.4-1.3-1-2.2-0.8c-0.7,0.9-0.1,1.8,0.1,2.7
			c1,5-0.5,9.2-4.1,12.6c-3,2.9-6.4,5.3-9.9,7.5c-6.1,3.8-12.5,7.2-18.3,11.3c-1.9,1.3-4,2-6.3,2.2c-1,0.1-1.9,0.1-2.8,0.3
			c-1,0.2-1.4,1.5-0.7,2.3c3.5,3.8,6.6,4.3,11,1.5c12.5-7.8,24.9-15.6,37.4-23.4c1.5-0.9,2.8-2.1,4.3-3c1.8-1,3.5,0,3.8,2.1
			c0.2,1.6-0.4,2.9-1.4,3.9c-0.8,0.8-1.7,1.4-2.7,2c-9.8,6-19.4,12.3-29.5,17.9c-3.3,1.9-6.6,4-9.8,6c-4.7,3-9.2,3.2-13.8-0.2
			c-1.4-1-2.8-2.2-4.2-3.2c-2.3-1.8-4.8-3.2-7.6-3.9c-0.6-0.2-1.5,0.2-1.3,1c0.6,2.6-0.6,4.7-2,6.6c-0.4,0.5-0.6,1.1-0.9,1.7
			c-0.2,0.5-0.2,1,0.2,1.3c0.2,0.2,0.6,0.2,0.9,0.2c1-0.4,2-0.9,3.1-1.4c3.4-1.5,4.6-1.2,7.1,1.6c0.3,0.4,0.6,0.7,0.9,1.1
			c2.5,3.5,2.2,6.2-1.5,8.5c-7.1,4.6-14.4,9-21.5,13.4c-2.5,1.5-5.2,1.2-7.9,1.1c-0.1-0.1-0.3-0.3-0.4-0.2c-3.3,0.6-7.2-3.7-8.8-1.9
			c-5.3,5.9-13.8,9-16.7,16.8c-4.8,12.8-15.6,19.9-25.4,27.9c-8.5,6.9-19,8.8-29.8,8.3c-4.9-0.3-8.7-3-11.5-7
			c-1.1-1.5-2.1-3.4-0.2-5.1c2.9-1.2,5.8-0.3,8.3,0.8c7.6,3,15.1,2.9,22.8,0.6c11.3-3,17.5-11.8,23.3-20.9
			c4.1-6.3,6.8-13.4,10.9-19.7c2.8-4.5,5.7-9,10.5-11.6c2.9-1.5,1.4-4.8,3-6.7c2.6-4.5,5.7-8.6,7.7-13.5c2.6-5.1,3.8-10.5,3.3-16.3
			c-0.2-0.4-0.3-0.8-0.4-1.3c0-1.3-0.6-2.6-1-3.8c-1.1-3.8-2.6-7.5-5.6-10.2c-1-0.9-1.7-1.9-1.6-3.3c0.1-0.5,0.2-0.9,0.5-1.3
			c6.3-4.5,13.9-6.2,20.7-9.8c25.1-13,50.4-25.7,71.6-44.8c15.4-13.9,24.8-31,26.1-52.1c0.2-2.4,0.4-4.8,2.3-6.6
			c0.8-0.7,1.8-1,2.8-0.7c10.2,10.5,9.3,22.8,5.5,36.1c4.3-0.7,6.8-3.3,9.7-5c1.5-0.9,2.9-2,4.3-3c4.4-3.2,7.1-3.2,9-0.2
			c2,3.3,0.5,6.9-3.9,9.2c-1.6,0.8-3.1,1.7-4.3,2.9c-7,4-13.3,9.3-20.8,12.7c-5,2.2-5.3,3.2-4,8.6c1,4.1-1.4,6.3-4.4,8.2
			c-8.9,5.6-17.9,10.9-25,15.4c8.5-6,19.9-11.2,29.7-19c2.6-2.1,5.6-2.4,8.5-0.5c3,2.1,3.6,5.1,2.6,8.5c-0.8,2.6-2.4,4.9-3.8,7.2
			c-5.5,8.6-10.8,17.3-17.2,25.4c-1,1.3-2.9,3-1.1,5c1.5,1.7,3.4,2.2,5.7,1.6c2.4-0.7,4.3-2,6.3-3.3c10.3-7,20.8-13.7,31.6-20
			C728.1,784.1,730.3,782.8,733,784.4z"
        />
        <path
          class="st457"
          d="M492.1,917.8c1,2.6,2.9,4.5,5,6.3s4.5,3.2,7.3,3.2c10.1,0.2,19.6-1.7,28.1-7.7c0.4-0.3,0.8-0.5,1.2-0.8
			c5.2-4.5,10.3-9,15.5-13.4c1.8-1.5,3-3.2,4-5.2c1.8-3.5,3.3-7.3,5.6-10.5c2.6-3.6,5.5-7,9.6-9.1c2.4-1.2,4-3.1,5-5.6
			c0.2-0.6,0.4-1.2,0.7-1.8c0.5-1,1.7-1.4,2.4-0.7c2.7,2.3,6.1,3.4,8.7,5.9c-1.3,0.2-2.4,0.9-3.2,1.9c-4.2,4.6-9,8.6-14.4,11.8
			c-2.1,1.3-3.6,3.2-4.3,5.6c-2.1,8-7.7,12.6-14.8,15.8c-1.3,0.6-2.6,1-3.5,2.3c-5.1,7.1-12.4,10.8-20.6,13.1
			c-7.5,2.1-15.1,2.7-22.8,2.2c-1,0-1.9-0.3-2.8-0.5c-9.4-1.9-17.9-5.9-25.6-11.5c-7.1-5.2-14.7-9.6-22.2-14.2
			c-10.7-6.6-21.5-13.1-32.3-19.8c-12.8-7.9-25.5-15.8-38.3-23.7c-0.7-0.4-1.4-0.8-2-1.3c-1.5-1.3-1.8-3-1.2-4.8
			c0.2-0.6,0.6-1.1,0.9-1.7c1.6-3.2,1.3-4.3-1.8-6.2c-11.1-6.7-21.4-14.6-31.5-22.6c-6.1-4.9-12.7-9.1-19.3-13.3
			c-7.9-5.1-16-9.9-24.1-14.8c-0.7-0.4-1.4-0.8-2-1.2c-3-1.9-5.9-1.8-9,0.1c-3.7,2.3-7.9,3.5-12.3,3.2c-5.2-3.1-10.3-6.1-15.5-9.2
			c-3.4-2-6.6-4.3-9.5-7c10.7,2.1,21.3,0.8,31.8-1.6c5.6-1.2,11.2-2.7,17-2.5c7.2-2.1,13.4,0.4,19.2,4.5
			c13.9,9.6,28.3,18.6,42.9,27.1c4.4,2.6,8,3.3,11.9-0.7c1.6-1.7,3.8-2.9,6.2-3.6c1.2-0.3,2.6-0.6,3.5,0.6c0.8,1,0.4,2.3-0.2,3.1
			c-2.3,2.9-2.8,7.3-7.3,8.2c1.2,2.8,3.7,3.5,5.6,4.8c15.9,10.1,31.9,20,48.1,29.6c6.6,3.9,12.8,6.2,20.1,2.4
			c0.8-0.7,1.7-1.3,2.9-1.1c1.7,2.2,0,3.7-1.3,4.9c-3.1,2.6-2.1,4.5,0.7,6.3c2.8,1.8,5.5,3.8,8.1,5.9c3.4,2.6,5.2,5.8,4.4,10.3
			c-1,5.1,1.3,9.7,2.9,14.4c0.4,1.3,1.7,1.8,2.9,2.2c1.2,0.6,2.1,1.5,2.7,2.5c3.6,5.9,8.4,10.6,14.3,14.2
			C490.8,914.9,492.1,915.9,492.1,917.8z"
        />
        <path
          class="st458"
          d="M716.7,734.4c2.1-1.2,4.2-2.4,6.2-3.7c2.2-1.4,3-3.9,2.2-6.3c-0.6-1.7-2.3-2.4-3.8-1.3
			c-1.2,0.8-2.2,1.9-3.3,2.7c-4.3,3-8.6,6-13.3,8.6c-0.4,0.2-0.8,0.5-1.3,0.6c-1.6,0.6-3-0.5-2.8-2.2c0.1-0.6,0.3-1.3,0.5-1.8
			c2-5,2.4-10.3,2.8-15.5c0.4-4.4-0.8-8.9-3.2-12.8c-0.7-1.2-1.5-2.4-2.4-3.6c-0.7-1-0.9-2-0.9-3.2c0.1-3.9,3.6-4.9,5.8-7
			c2-1.9,4.7-3,6.7-6.3c-3.5,0.6-5.4,2.6-7.7,3.8c-4.8,2.7-7.3,1.4-7.2-4.1c0.1-6.4-1-12.6-2.5-18.8c-0.7-5.3-2-10.6-1.7-16
			c1.1-3.4,0.5-7,1.5-10.3c0.5-1.5,0.6-3.1,1.8-4.3c0.1-2,1.7-2.7,3.2-3.4c0.9,1.8,2.6,2.8,4.2,3.8c4.3,2.8,8.2,6,11.8,9.7
			c1.9,2,3.4,4.2,5.2,6.3c0.9,1,0.8,2.1-0.1,3c-1.9,2-1.9,4.2-1.4,6.7c1.1,4.6,0.6,9.2,0.5,13.8c0,2.1-1.1,4-1.8,5.9
			c-1.2,3-0.3,5.3,2.5,6.8c0.6,0.3,1.2,0.5,1.8,0.7c4.3,1.2,7.8,4,10.2,7.5c2,2.9,4.5,4.4,7.7,5c0.4,0.8,0.3,1.5-0.1,2.3
			c-3.3,6.3-16.1,9.5-21.9,5.6c-3.3-2.2-6.2-5-10.1-6.4c4.8,4.3,9.5,8.8,15.9,10.9c1.8,0.6,3.8,0.9,4.6,3.1c-1.5,2.5-1,4.5,1.1,6.4
			c2.7,2.3,5.2,4.7,7.9,6.9c5.7,4.6,11.3,9.3,17.6,13.1c4.6,2.8,9.1,5.9,13.8,8.6c2.2,1.3,3.8,2.8,3.7,5.6c0,1,0.3,1.9,1,2.6
			c0.6,2.6-1.3,3.9-3,4.9c-7.2,4.5-14.1,9.3-21.3,13.8c-3.6,2.2-7.2,2.3-10.8,0c-5.5-3.6-11.9-6.2-16-11.8c-1.8-5.7,0.2-10.9,2.4-16
			c1.3-3.2,3.1-6.2,4.6-9.4c0.6-1.2,1.8-2.6,0.6-3.9c-1.2-1.4-2.7-0.1-3.9,0.4c-2.1,0.8-4.1,1.8-6.4,0.8
			C716.9,736,716.4,735.4,716.7,734.4z"
        />
        <path
          class="st459"
          d="M716.7,734.4c1.1,1,2.3,1,3.5,0.5c1.5-0.6,2.8-1.4,4.3-2c1-0.5,2-0.9,3.1-1.2c1.7-0.4,3.1,0.8,3.3,2.5
			c0.2,1.5-0.3,2.8-0.9,4.1c-1.6,3.1-3.1,6.2-4.7,9.3c-2.6,5.2-4.2,10.6-3.9,16.4c-2.3,1.5-4.3,0-6.2-1c-4.3-2.4-8.4-5.1-12.2-8.2
			c-1.3-1.1-2.5-2.2-4.9-2.3c1.2,3.3,4.1,3.7,6.2,5.2c2.7,2,6.5,2.9,6.5,7.3c-0.2,2.8,1.8,4.3,3.5,5.8c2.4,2.1,5.1,3.8,7.8,5.6
			c3.2,2.2,6.4,4.2,9.5,6.4c0.5,0.3,0.8,1,1.2,1.5c-2.1,0.2-3.9,1.4-5.7,2.5c-11.1,6.6-22,13.5-32.7,20.9c-1.8,1.3-3.7,2.4-5.9,3.1
			c-3.7,1.1-6.6-0.2-9-2.9c-1.1-1.2-1.6-2.6-0.1-4.3c0.9-1.1,1.7-2.3,2.6-3.4c4.9-6.3,9.4-12.9,13.5-19.8c1.5-2.5,3.2-4.8,4.7-7.2
			c0.9-1.5,1.8-3,2.4-4.7c0.9-2.4,0.3-4.2-1.7-6c-1.7-1.5-3.5-1.4-5.3-0.4c-1,0.5-2,1-3,1.6c-7.2,4.5-14.3,9-21.6,13.3
			c-4.1,2.4-7.9,5.4-12.3,7.3c-0.7,0.3-1.5,0.4-2.3,0.6c-0.1,0-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.4,0-0.4c0.4-0.4,0.9-0.9,1.4-1.2
			c6.2-3.9,12.4-7.8,18.7-11.7c3.5-2.2,7-4.4,10.6-6.6c0.7-0.4,1.4-0.8,2-1.3c3.2-2.5,3.6-4.6,1.4-8.2c-0.4-0.7-0.9-1.3-1.3-2
			c-0.9-1.5-0.7-2.1,1-3c0.3-0.2,0.6-0.3,0.8-0.4c6.6-2.9,12.5-7,18.5-11C711.6,737.1,714.1,735.6,716.7,734.4z"
        />
        <path
          class="st456"
          d="M711,765.1c-3.3-3.1-6.9-5.6-11.1-7.4c-1.5-0.6-2.7-1.7-3.6-3.1c-1-1.5-0.8-3,0.3-4.4c0.8-1,2-1.2,3-0.5
			c1.4,1,2.9,2,4.2,3.1c5.4,4.5,11.3,8.3,17.7,11.3c5.2,3.4,10.4,6.8,15.6,10.1c0.5,0.3,1.1,0.6,1.7,1c2.1,1.3,4,1.6,6.2-0.1
			c1.6-1.2,3.5-2.2,5.3-3.3c6.1-4,12.3-8,18.4-12c1.1-0.7,2.2-1.3,2.9-2.4c0.7-0.3,1.5-0.5,2.2-1c1-0.7,2-1,3,0s1.4,2.1,1,3.5
			c-0.6,1.7-1.7,3.1-3.1,4.1c-1.4,1-2.9,2-4.4,2.9c-6.8,3.8-13.6,7.8-20,12.2c-4.4,3-9,2.9-13.7,0.9c-1.9-0.8-3.7-1.9-5.4-3.1
			c-5.5-4.2-11.5-7.5-17.4-11C712.8,765.6,711.9,765.4,711,765.1z"
        />
        <path
          class="st458"
          d="M766.5,735.5c1.5,0,3-0.5,3.4-2.1c0.8-3.3,3.5-4.2,6.1-5.4c3.9-1.8,7-0.9,9.8,2.2c1,1.1,1.8,2.2,2.5,3.5
			c0.5,1,1.1,1.9,1.9,2.7c1.9,2.1,4.3,3.4,7.2,2.6c1.7-0.4,3.1,0,4.7,0.2c0.7,2-0.7,3-1.9,4c-3.7,2.9-7.8,4.9-12.3,6.4
			c-1.1,0.4-2.3,0.5-3.3,0.1c-6.2-2.9-12.3-6-17.2-11.2C766.7,737.7,766.1,736.7,766.5,735.5z"
        />
        <path
          class="st460"
          d="M766.5,735.5c1.4,1.1,2.7,2.3,4.1,3.3c3,2.4,6,4.8,9.6,6.3c0.7,0.3,1.6,0.5,2.1,1.1c2.4,3,4.9,1.8,7.6,0.5
			c4.4-2,8.6-4.3,12.2-7.5c1.3-0.6,2.6-1.5,3.8,0.1c1.3,1.8,1.4,4-0.2,5.2c-5.2,4-10.4,7.9-16.8,9.8c-1.3,0.4-2.6,0.4-3.7-0.2
			c-6.6-3.5-13.5-6.5-19-11.9c-0.5-0.5-0.9-0.9-1.3-1.4c-0.8-0.8-1.6-1.6-1.3-2.9C763.9,736.4,764.9,735.5,766.5,735.5z"
        />
        <path
          class="st461"
          d="M733,719.5c0.1-2.2,1.9-3.2,3.8-2.2c2.2,1.2,4.4,2.6,6.5,4c2.4,1.5,4.9,3,7.2,4.8c2.9,2.2,5.5,2,8.3-0.1
			c3.1-2.3,6.3-4.3,9.8-5.9c1.2-0.5,2.3-1,3.7-0.9c1.2,0.1,2,1.4,1.4,2.5c-0.2,0.4-0.5,0.8-0.8,1.2c-4.5,4.9-9.9,8.5-16.1,10.9
			c-1.9,0.7-3.7,0.7-5.5-0.2c-5.6-2.8-10.9-6.1-15.9-9.7C734,722.7,732.8,721.3,733,719.5z"
        />
        <path
          class="st456"
          d="M726.3,714.4c-5.5-0.7-10.4-2.8-14.8-6.3c-2.5-2-5.1-3.9-7.6-5.8c-1.3-1-2.7-2-1.9-3.9
			c0.6-1.5,2.8-1.7,5.1-0.3c1.6,1,3.2,2.1,4.7,3.2c2.1,1.5,4.1,3,6.1,4.6c2.7,2.1,5.6,2.3,8.5,0.6c1.8-1.1,3.7-1.9,5.6-2.7
			c2.4-1.1,4.5-2.5,5.9-4.7c0.8,0,1.6-0.1,2.4,0c1.9,0.2,2.7,1.6,2.3,3.4c-0.4,1.7-1.6,2.8-2.8,3.7c-2.9,2.3-5.9,4.3-9.2,6
			C729.2,712.9,727.8,713.6,726.3,714.4z"
        />
        <path
          class="st462"
          d="M247.2,704.5c0,1.2-0.4,2.2-1.3,3c-0.9,0.9-1.9,1.7-3,2.3c-5.6,3.6-11.3,7.1-17,10.7
			c-0.8,0.5-1.7,0.9-2.6,1.2c-1.3,0.4-2.5,0.1-3.5-0.9c-1.1-1-1.2-2.3-0.2-3.4c0.8-0.8,1.6-1.5,2.6-2.1c6.3-4.3,12.7-8.5,19-12.8
			c0.5-0.3,1.1-0.7,1.6-1c2.2-1.2,3.7-0.4,4.2,2C247.2,703.9,247.2,704.2,247.2,704.5z"
        />
        <path
          class="st463"
          d="M231.9,738.7c-0.2,2.6-1.9,4.5-4.2,5.9c-3,1.9-5.9,3.7-8.9,5.6c-2.4,1.5-4.9,2.9-7.4,4.4
			c-1.1,0.7-2.3,1.1-3.6,0.8c-1.8-0.3-2.6-2.3-1.5-3.7c0.5-0.6,1.1-1.1,1.8-1.6c1.9-1.3,3.7-2.5,5.6-3.7c4.6-2.8,9.1-5.8,13.3-9.4
			c0.4-0.3,0.7-0.6,1.1-0.9c0.8-0.6,1.7-0.8,2.7-0.2C231.6,736.6,231.9,737.5,231.9,738.7z"
        />
        <path
          class="st464"
          d="M206.1,726c0.2,2.7-0.7,4.2-2.1,5.2c-2.2,1.6-4.4,3.2-6.7,4.6c-3.1,1.9-6.6,3.2-9.5,5.6
			c-0.8,0.6-1.8,0.7-2.7,0.5c-1.1-0.3-2.1-0.9-2.4-2.1c-0.3-1.1,0.3-1.8,1.1-2.5c1.5-1.2,3-2.3,4.6-3.5c3.5-2.5,6.9-5.1,10.4-7.6
			c1.3-0.9,2.6-1.8,4-2.6c1.5-0.8,2.9-0.1,3.2,1.6C206.1,725.7,206.1,726.2,206.1,726z"
        />
        <path
          class="st465"
          d="M818.5,897.5c-0.3,0.3-0.7,0.7-1,1c-0.8-0.5-1.7-0.9-2.5-1.4c-0.2-0.1-0.2-0.5-0.5-1
			C816.1,896.2,817.4,896.5,818.5,897.5z"
        />
        <path
          class="st465"
          d="M817.5,898.5c0.3,0.3,0.7,0.6,1,0.9C818.1,899.1,817.8,898.8,817.5,898.5z"
        />
        <path
          class="st466"
          d="M390.5,701.5c-2.3-1.4-4.6-2.7-6.9-4.1c-1.6-1-3.2-2.1-4.8-3.1c-3.7-2.8-6.1-6.5-8.1-10.5
			c-2.5-5.3,0.4-10.6,6.2-11.7c1.8,2.4,4.4,2.7,6.9,1.9c6.1-1.8,12.2-2.1,18.5-1.9c4.6,0.1,9.3,0,13.9,0c2.4,0,4.8-0.2,6.9-1.5
			c2-1.2,4.2-1.5,6.4-1.5c4.3,0,8.6,0.2,12.9-0.8c1.7-0.4,3.5-0.1,5.2-0.2c3.5,0,7,0,10.5-0.7c2.5-0.5,5.1-0.2,7.6-0.2
			c1.3,0,2.5,0.6,3.4,1.5c0.8,0.8,1.6,1.6,2.5,2.3c0.7,0.6,1.5,0.3,2.3,0c3.8,2.4,7.1,2.8,11.3-0.2c2.3-1.7,5.5-4.2,9.1-2.1
			c0.4,0.3,0.8,0.7,1,1.1c0.4,0.9,1,1.8,1.1,2.9c5.8,30.6,13.7,60.8,18.8,91.6c0.5,2.9,1.2,5.9-2.9,6.8c-2.4,0.5-4.3-0.4-6.3-1.5
			c-23.2-12.5-45.5-26.4-68.6-39c-12.3-6.7-24.5-13.7-36.5-20.9C397,707.5,393.4,705,390.5,701.5z"
        />
        <path
          class="st467"
          d="M479.6,598.2c0.3,0,0.6,0,0.9,0c0.3-0.3,0.7-0.5,1-0.7c0.6-0.4,1.2-0.9,1.9-1.3c4.1-2.4,5.5,1.5,7.7,3.4
			c2.8,5.7,3.5,12.1,5.7,18c1.9,5,3.2,10.2,7.1,14.3c1.4,1.5,2.2,3.5,1.1,5.7c-5.2,2.6-5.2,2.5-4.1,8.5c0.9,4.9,2.5,9.5,3.2,14.5
			c1,4.8,2.5,9.4,3.9,14.1c0.8,2.8,2,5.6,1.8,8.6c0.4,5.8,4.3,11,3.2,17.2c-0.2,0.9,1.7,1.3,2.2,2.3c0.7,1.1,0.6,2.2,0.5,3.4
			c-0.3,4.5,1.2,7.7,6.1,8.7c1.1,0.2,2.1,0.8,2.9,1.5c1.1,0.9,2.5,1.9,2,3.5c-0.7,1.8-2.5,1.5-4.1,1.5c-1.4,0-3.3-2.1-4,0.2
			c-0.6,1.9,0.3,4.1,1.7,5.8c0.7,0.9,2,0.8,3,1.3c0.6,0.3,1.2,0.7,1.6,1.3c3.8,5.5,9.6,8.2,15.6,10.5c2,0.8,3.8-0.2,5.4-1.2
			c10.5-6.5,21.7-11.5,32.5-17.5c8-4.5,8-5.4,0.7-9.5c-3.3-1.9-6.2-4.5-7.2-8.6c0.5-3.5,2.4-5.3,5.9-5.8c2.7-0.3,5.4-0.7,8-1.2
			c11.3-2.3,21.2-7.7,30.3-14.6c1.7-1.2,3.3-2.6,4.7-4c2.4-2.3,5.1-4.3,8-5.9c2.1-0.5,4.1-0.2,6,0.9c6,3.5,12.4,6.5,18.1,10.5
			c1.8,1.3,3.9,2.3,3.5,5.1c-0.8,1.1-2,1.6-3.3,1.8c-10.9,1.4-20.9,5.6-30.8,10.2c-7,3.2-14,6.2-21.3,8.6c-3.2,1.1-5.2,2.7-5.3,6.4
			c-0.1,4.9-3,8.2-7,10.7c-4.8,2.9-9.6,5.7-14.6,8.2c-10.4,5.3-20.7,10.8-30.9,16.5c-5.1,2.9-10.5,4.7-16.5,3.1
			c-1.9-0.5-2.5,0-2.3,2.2c0.3,4.3,2.2,7.5,6.1,9.5c0.7,1.8-0.5,2.7-1.8,3.2c-2.4,1.1-4,2.9-5.5,4.9c-2.1,1.4-4.1,1.2-5.9-0.6
			c-2.3-5.2-2.5-10.8-3.5-16.3c-4.5-25.1-10.3-49.9-15.8-74.8c-5-22.3-9.9-44.7-14.2-67.2c-0.7-3.4-0.1-7.4-3.3-10.1
			C479,602.8,479.9,600.4,479.6,598.2z"
        />
        <path
          class="st468"
          d="M403.1,637.2c-0.1,0.7-0.1,1.5-0.2,2.3c-0.5,2.7-2.5,4.5-5.3,4.7c-1.5,0.1-2.8-0.3-4.2-0.7
			c-5.4-1.7-9.5-5.1-13.3-9.1c-3.2-3.4-5.7-7.3-7.5-11.6c-0.9-2.2-1.6-4.5-1.3-7c0.3-3.7,2.5-5.8,6.1-6.2c2.6-0.2,5.1,0.2,7.4,1.6
			c8.2,4.9,14.2,11.7,17.6,20.7c0.3,0.7,0.5,1.5,0.6,2.3C403,635.2,403,636.2,403.1,637.2z"
        />
        <path
          class="st469"
          d="M480.6,604.9c0-0.5,0-1,0.1-1.4c0-0.6,0.1-1.4,0.8-1.6c1-0.2,1.4,0.7,1.6,1.4c0.4,1.4,0.6,2.8,0.9,4.2
			c3.7,18.3,7.3,36.6,11.2,54.8c1.8,8.7,4.1,17.4,6.2,26.1c3.8,16.3,7.6,32.6,10.8,49c2,10.3,3.8,20.7,5.6,31
			c0.2,1.4,0.2,2.8,0.4,4.3c-4.1,1.7-5.2,1.2-6.7-2.8c2.8-0.8,3-1,2.5-3.5c-1.9-10-3.7-20-5.8-30c-3.1-14.8-6.4-29.5-9.6-44.3
			c-1.5-6.7-2.6-13.4-3.4-20.2c-0.5-0.5-0.3-1.4-0.9-1.9l0,0c-3.4-2.9-1.9-7.1-2.8-10.6c-0.2-1.2-0.2-2.6-0.6-3.8
			c-2.3-7.6-3.6-15.5-5.1-23.2c-1.6-8.4-3.2-16.8-4.8-25.3C480.6,606.5,480.6,605.7,480.6,604.9z"
        />
        <path
          class="st467"
          d="M376.9,672.1c-0.8,0.7-1.8,1.4-2.4,2.2c-3.9,5.8-4.1,5.6-0.4,11.3c1.8,2.8,3.2,5.8,4.7,8.7
			c-1.1-0.6-2.3-1-3.4-1.7c-9.8-5.7-19.5-11.5-29.3-17.2c-1.6-1-3.4-1.8-4.6-3.4c2.3-0.7,4.7-1,7.1-0.9c0.8,0,1.6,0.1,2.4-0.1
			c5.7-1.4,11.4-0.7,17.1-0.8C371.2,670.1,374.1,670.8,376.9,672.1z"
        />
        <path
          class="st467"
          d="M491.2,659.5c0.1,0.1,0.2,0.2,0.2,0.4c0.6,3.5,1.6,6.8,2.6,10.2c-3-1.5-5.5-0.3-7.8,1.6c-1.1,0.9-2,2-3.1,3
			c-2,1.6-4.1,1.6-6,0c-1.2-1-2.1-2.3-3.2-3.5c4.6,0,9-1.5,13.5-2.2c2.7-0.5,3.6-1.9,3.8-4.6C491.2,662.7,491.2,661.1,491.2,659.5z"
        />
        <path
          class="st467"
          d="M341.4,672c-0.5,0-0.9-0.3-1.1-0.7C340.7,671.5,341.1,671.7,341.4,672z"
        />
        <path
          class="st470"
          d="M511.3,770c1.3,0.8,2.6,1.6,4,2.4c0.8,0.5,1.8,0.7,2.7,0.4c1.6,0.6,3.2,0.8,4.8,0.1c1-0.9,2.3-1.3,3.6-1
			c6.2,1.3,11.6-1,16.8-4c29.5-16.4,59.2-32.8,88.4-49.7c7-4.1,14.1-8,21-12.3c5.4-3.4,6.8-8,4.5-14.4c-0.8-0.9-1.1-2-0.9-3.2
			c-6.8-5.3-14.5-9.1-22.1-13.2c-1.8-1-3.6-2-5.4-3c0.3-0.8,0.8-1.4,1.6-1.8c4.8-3.1,10.5-1.7,15.7-3c14-3.4,27.5-7.7,37.9-18.6
			c4.2-4.4,5.7-4.4,8.6-0.7c0.7,5.5,1.4,11,2.2,16.5c0.5,4.9,0.8,9.8,0.8,14.7c0,2.7,0,5.5,1,8c1.5,3.6,1.2,6.8-0.9,9.9
			c-1.5,3.8-1.3,7.9-1.8,11.8c-1.4,12.4-6,23.4-13.3,33.4c-6,8.2-13.1,15.2-21,21.5c-3.5,2.8-7.1,5.5-10.8,7.9
			c-17.2,11.3-35,21.6-53.6,30.5c-5.2,2.5-10.5,4.5-15.9,6.7c-1.5,0.6-2.9,1.4-4.6,1.2c-0.8,0.3-0.6,0.8-0.1,1.2
			c1,1.2,2.1,2.3,3.1,3.5c2.3,2.8,4.2,5.9,4.7,9.6c0.8,1.3-0.2,3,0.9,4.2l0,0c1.2,3.8-0.5,7.3-1.1,10.9c-0.1,0.8-0.4,1.7-1.3,1.7
			c-1.4,0-1.7-1.1-1.9-2.2c-0.2-0.5-0.2-1-0.2-1.5c0.1-1.4,0.4-2.8,0.3-4.3c0.4-5.6-0.8-10.7-4.8-14.9c-3.9-4.2-8.4-6.2-14-3.8
			c-1.2,0.5-2.5,0.6-3.8,0.3c-3.3-0.7-6.4-1-9.8,0c-4.6,1.4-8.9-1.7-13.5-1.7c-11-0.1-21.7-2.6-32.6-3.5c-2-0.2-4.2,0.1-5.5-2
			c-1.3,0-2.5-0.3-3.5-1.2c-1.9-1.7-4.1-3-5.9-4.9c-2.7-3-6.4-4.8-9.5-7.2c-2.5-1.9-3.7-3.8-2.7-6.9c1-2.8,0.2-4.9-1.9-6.9
			c-6.1-5.9-11.8-12.2-18-17.8c-4.5-4-8.9-8.2-13.8-11.7c-5.4-3.8-10.4-8.1-14.5-13.1c-2.9-3.6-6.2-6.8-9.4-10.2
			c-1-1-2.1-1.9-3.3-2.8c-2.9-2.2-5.9-4.3-8.8-6.6c-1-0.8-1.9-1.7-2.7-2.7c-3.1-3.6-6.1-7.3-9.2-11c-0.8-0.9-1.2-1.8-1.2-3
			c0.9,0.4,1.8,0.7,2.6,1.2c17.1,10.6,34.8,20.2,52.3,30.2c14.6,8.3,29.1,16.7,43.6,25.1c6,3.4,12,6.7,18,10
			C508.4,768.8,509.7,769.7,511.3,770z"
        />
        <path
          class="st471"
          d="M451.8,854.5c-1,0.8-2,1.5-3,2.4c-3.2,2.8-6.7,2.8-10.3,1.2c-2.6-1.2-5.1-2.7-7.6-4.1
			c-6.3-3.7-12.6-7.5-18.9-11.3s-12.5-7.7-18.7-11.6c-4.3-2.7-8.7-5.4-13-8.1c-1.7-1.1-3.4-2.4-5-3.6c-0.8-0.6-1.2-1.5-0.8-2.5
			c0.3-0.8,0.9-1.3,1.7-1.5c3.4-0.8,3.3-0.8,5.3-4c0.9-1.4,2.2-2.6,3.2-3.9c-0.6-0.7-1-0.5-1.5-0.3c-2.8,1.2-5.3,2.9-7.6,4.9
			c-1,0.8-2,1.7-3.1,2.3c-1.8,1-3.6,1-5.5,0c-7.4-4.4-14.9-8.7-22.2-13.2c-8.9-5.6-17.7-11.5-26.5-17.2c-0.7-0.4-1.3-0.9-2-1.3
			c-4.5-2.7-9.2-4.4-14.6-4c4.2-1.3,8.5-2.7,12.9-3.5c1.6-0.3,3.1-0.9,4.6-1.3c2.8-3.7,7.2-2.6,10.9-3.7c4.9-1.3,10-1.1,15-2
			c2.2-0.4,4,0.8,5.8,1.8c21.3,18.2,41.7,37.5,62.5,56.3c9.7,8.8,19.8,17.1,30,25.2C446,853.2,448.5,854.8,451.8,854.5z"
        />
        <path
          class="st472"
          d="M495,807.4c8.1-0.7,15.9,1,23.8,2.3c1.6,0.2,3.2,0.6,4.7,0.5c4.8-0.3,9.5,0.6,14.3,1
			c1.5,0.1,2.8,0.8,4.1,1.3c1.7,0.8,3.5,0.9,5.1,0c3-1.7,5.7-1.5,8.5,0.4c0.5,0.3,1.2,0.3,1.9,0.4c0.5,2.5-1.5,3.8-3,5
			c-6.4,5.3-12.2,11.1-17.5,17.5c-1.3,1.6-2.9,2.9-4.9,3.7c-5.3,1.5-7.7,6.5-11,10.1c-4.5,4.9-8.3,10.4-12.7,15.5
			c-5.8,6.8-15.4,9.5-23.9,6.9c-6.9-2.2-8.6-7.7-4.1-13.3c1.9-2.3,3.8-4.7,5.7-7c4.3-5.4,11.2-7.1,18.7-5
			c-7.3-2.5-15.8,0.2-19.9,6.8c-1.5,2.4-3.7,4.4-5.5,6.6c-1.3,1.6-3.1,1.7-4.7,0.6c-1.5-1-1.8-2.5-1.2-4.2c0.8-2.2,2.5-4.2,2.2-6.7
			c4.4-10.6,12.1-18.2,21.6-24.2c1.2-0.8,2.5-1.3,3.9-2.2c-1.1-0.8-2.4-0.6-3.4-1.4c-1-0.6-1.4-1.7-2.2-2.3
			c-1.9-1.5-4.4-1.2-6.5-2.2c-0.9-0.6-1.7-1.2-2.7-1.7c-1.9-1.9-4.1,0.6-6-0.6c-0.6-0.4-1-1-1.1-1.7c0.9-3.1,2.8-4.6,6.2-3.8
			C489,811,492.1,809.4,495,807.4z"
        />
        <path
          class="st473"
          d="M277.1,722c2-0.2,3.5-1.4,5.1-2.5c1.5-1,2.9-2,4.5-2.7c4.8-2,9.3,0.8,9.5,5.9c0.2,6.5,0.5,13.1-0.1,19.6
			c-0.6,6.4-2.6,12.5-6.4,17.8c-1.6,2.2-3.9,3.1-6.2,2.6c-4.1-0.8-8.3-0.5-12.4-0.5c-3.6,0-7-0.8-10.4-1.6c-1.4-0.3-2.7-0.9-3.8-1.9
			c-1.4-1.3-2.1-2.8-1.7-4.7c0.2-1.2,0.6-2.5,0.7-3.8c0.1-0.4-0.1-1.1-0.4-1.3c-0.7-0.4-1.1,0.3-1.4,0.8c-1.2,2.1-2.4,4.2-3.6,6.2
			c-0.4,0.7-0.9,1.3-1.4,1.9c-1.4,1.5-3.2,1.5-4.5-0.1c-1.5-1.9-2.7-4-2.9-6.5c-0.3-3.7-0.1-7.4,1.6-10.7
			c3.3-6.4,6.6-12.8,12.1-17.7c1-0.8,1.8-1.8,2.7-2.7c1.8-1.7,3.3-3.5,3.6-6c0.3-2.1,1.7-3.4,3.6-4.1c3.4-1.3,6.8-2.7,10.3-3.8
			c3.9-1.3,9.1,0.7,11.3,4.1c0.6,1,0.7,2.3-0.2,3.1c-1.2,1.1-2.4,2-3.8,2.9C280.7,717.9,278.6,719.6,277.1,722L277.1,722z"
        />
        <path
          class="st474"
          d="M456.6,850.5c-0.2-2.8-1.4-5.1-2.8-7.5c-6.3-10.6-5.5-20.9,1.3-31c2.8-4.1,6.4-7.5,10.6-10.2
			c4.6-2.9,7.4-2.8,11.8,0.5c1.9,1.4,3.5,3.2,5.3,4.8c-0.8,2.2-3,2.2-4.7,2.7c-3.3,0.9-5.6,3-7.7,5.5c-1,1.1-1.9,2.3-1.2,3.9
			c1.9,3.9-0.1,6.7-2.3,9.6c-2.5,3.2-3.7,7-4.8,10.9c-0.5,1.5-0.6,3.2,0.5,4.6c0.4,1.2,0.7,2.5,1.3,3.7c0.4,0.6,0.5,1.3,0.5,2
			c-0.1,0.7-0.3,1.3-0.8,1.8C461,852.9,458.8,851.4,456.6,850.5z"
        />
        <path
          class="st473"
          d="M312.7,754.8c0-0.3-0.1-0.6-0.1-1c-0.1-0.8,0.1-1.8-0.7-2.1c-1.2-0.5-1.6,0.8-2.1,1.4
			c-0.9,1.1-1.6,2.4-2.6,3.4c-0.7,0.8-1.7,1.5-2.6,2c-2.4,1.3-4.9,0.3-5.8-2.2c-0.3-0.9-0.5-1.9-0.5-2.8c0.1-4.9-0.5-9.8,0.7-14.7
			c0.5-2,0.2-4.1,0.2-6.2c0-1.8,0.2-3.5,1-5.1c0.7-1.5,2.2-2,3.5-1.2c1.4,0.8,2.4,2,3.2,3.4c0.6,1,1.1,2,1.8,2.8
			c0.7,0.8,1.7,0.9,2.6,0.3c0.6-0.4,1.1-1.1,1.8-1.6c1.2-0.9,2.3-0.8,3.3,0.4c-1,1.5-1.3,3.1-1,5c0.6,4.4,0.4,8.9,0.2,13.3
			C315.5,752,315.5,754.3,312.7,754.8z"
        />
        <path
          class="st471"
          d="M456.6,850.5c0.4-0.3,0.7-0.6,1-1c1-0.9,2-1,2.9-0.1c0.8,0.7,1.4,1.8,2.6,2c2.1,1,1.8,2.9,1.6,4.5
			c-0.3,3,0.8,5.2,2.8,7.1c2.4,3.5,2.4,7.6,3.5,11.5c1.2,6.4,1.8,12.8,2.4,19.3c0.2,1.8,0.1,3.5-1.6,4.6c-2.4,0-4-1.1-4.7-3.3
			c-1-3.2-2-6.4-2.7-9.7c-0.5-2-0.4-4.2,0.3-6.2c0.8-2.3,0.1-4.1-1.7-5.5c-2.2-1.8-4.5-3.5-6.8-5.2c-1.7-1.2-3.4-2.4-5.1-3.5
			c-1-0.6-1.8-1.3-2.3-2.4c-0.6-1.1-0.6-2.1,0.2-3.1c1.2-1.3,2.4-2.6,3.9-3.5c1-0.6,1.6-1.5,1.7-2.7
			C455.3,852.5,455.9,851.5,456.6,850.5z"
        />
        <path
          class="st475"
          d="M461.2,844.8c-0.6,0.2-1.3,0.4-1.5-0.4c-0.1-0.4-0.1-1,0-1.4c1.4-6.1,3.2-12,7-17.1
			c0.7-0.9,1.2-1.9,1.8-2.8c0.4-0.7,0.3-1.3-0.6-1.4c-1.5-0.2-1.6-1.3-1.6-2.5c0.1-1.5,0.6-2.8,1.5-4c3.2-4.2,7.1-7.3,12.6-7.7
			c0.8-0.1,1.6-0.1,2.4-0.2c1.3,0.1,2.1,0.9,2.5,2.1c-1.9,1.3-3.8,2.6-5.6,3.9c-0.5,1.1,0,1.3,1,1c1.6-0.6,3.1-1.3,4.8-1.8
			c1.2-0.3,1.7,0.4,1.7,1.5c-0.2,1.6-1.3,2.5-2.6,3.2c-9.4,5.9-15.9,14.2-20.2,24.3C463.7,842.9,463.1,844.4,461.2,844.8z"
        />
        <path
          class="st476"
          d="M351,769.8c-9.5,0.8-19,2-28.4,3.6c-1.1,0.2-2.2,0.2-3.3,0.3c-0.5-0.7-1.6-1.3-0.2-2.2
			c1.9-1.4,4.1-2.3,5.2-4.6c2.6-0.9,3.7-2.7,3.6-5.5c-0.1-2.9-0.2-5.8,0.2-8.6c0.3-1.9,0.8-3.8,2.7-4.8c1.3,1.2,1.5,3,2.1,4.5
			c0.6,1.6,1.2,3.2,2.8,4.1c1.1,0.6,2.2,1.4,2.7,2.6c1.1,3,3.4,3.5,6.1,3.1c0.9,0.7,1.7,1.4,2.7,1.9
			C349.4,765.4,350.7,767.3,351,769.8z"
        />
        <path
          class="st477"
          d="M312.7,754.8c1-1.2,0.9-2.7,0.9-4.1c0-4.8,0.1-9.6,0.1-14.4c0-1-0.1-1.9,0-2.9c0.1-1.4,0.4-1.6,2.7-1.7
			c0.4,0.6,0.7,1.2,1.1,1.8c4.4,7.7,2.9,15.9,2,24c-0.2,1.8-1.2,3.2-2.1,4.7c-1.2,1.5-0.8,3.3-1,5c-0.3,2.3-2.7,3.8-4.9,3
			c-0.4-0.1-0.8-0.7-0.8-1c-0.1-2.6-0.4-5-2.1-7.1c-0.5-0.6,0-1.2,0.5-1.6c0.2-0.2,0.5-0.4,0.8-0.5
			C312.5,759.2,313.1,757.2,312.7,754.8z"
        />
        <path
          class="st478"
          d="M317.4,762.3c0.3-6.1,0.6-12.2,1-18.4c0.2-2.8,0.4-5.7-1.3-8.2c-0.5-0.8,0.1-1.5,0.4-2.2
			c1.8,3.4,5.3,5.3,7.4,8.5c0.4,0.5,1.3,0.8,2.1,1c0.8,0.2,1.4,0.6,1.8,1.4c-2.2,1.7-1.3,3.9-1.2,6c0.4,5.9-1.1,8.6-6.1,10.8
			C320.1,761.3,318.7,761.6,317.4,762.3z"
        />
        <path
          class="st479"
          d="M321.5,761.2c3.5-3.4,4.8-7.6,4-12.4c-0.1-0.8-0.1-1.6,0-2.4c0.1-2.4,0.6-2.7,3.3-2.1
			c0.7,1.2,1.4,2.4,2,3.5c-0.6,4.7-0.8,9.4-0.8,14.2c0,1.3,0,2.5-0.2,3.8c-0.2,1.5-1.2,2.6-2.7,2.9c-1.7,0.4-2.1-0.9-2.8-2
			C324.5,764.4,324.5,762,321.5,761.2z"
        />
        <path
          class="st480"
          d="M344.5,762.2c-0.2,0.4-0.4,0.9-0.7,1.2c-2.5,2.1-4.2,1.9-5.9-0.7c-1.2-1.9-2-4-2.3-6.2
			c1.1-0.9,2.2-1.1,3.3,0C340.8,758.4,342.7,760.3,344.5,762.2z"
        />
        <path
          class="st481"
          d="M473.7,292.3c0.1-0.4,0.1-0.8,0.2-1.3c3.9,4.2,6.1,9.3,8.6,14.3c1.1,2.9,3.5,4.7,5.6,6.7
			c4.3,3,8.3,6.4,13.1,8.8c3,1.5,3.6,4.8,3.6,7.9c-0.1,7.4,3.2,13.6,6.7,19.6c6.3,10.8,16.4,16.5,28.1,18.9
			c18.2,3.7,35.3-0.2,50.4-11c7-5,12.9-11.5,17.1-19.2c0.8-1.6,1.9-3,3.1-4.2c1.4-1.4,3.1-1.9,4.8-0.8c15,10,31.8,17,46,28.4
			c4.1,3.3,8.2,6.6,10.5,11.5c-0.5,1.2-1.2,2.2-2.2,3c-2.6,2.4-5.2,4.8-7.7,7.2c-10.8,10.5-17.7,23.3-21.9,37.6
			c-1.1,3.8-2.1,7.7-2.7,11.6c-0.2,1.2-0.7,2.5-1,3.7c-2.6,4.4-2.4,9.5-3.5,14.3s-2.6,6.1-7.5,6.2c-2,0-3.8,0.4-5.6,1
			c-4.5,1.6-8.3,0.6-11-3.3c-2.4-3.5-5.5-4-9.2-3.3c-7.2,1.4-13.9,0.4-20-4c-3.6-2.6-7.8-2.2-12-1.7c-6.5,0.7-13.1,3.2-19.4,0.5
			c-7.7-3.4-15.6-4.1-23.8-3.9c-2,0-4-0.5-5.3-1.8c-3.1-3-6.4-2.3-9.8-1.1c-3.7,1.3-7.3,2-11.2,1.3c-4.1-0.7-7.9,0.4-11.6,2.3
			c-5.3,2.7-10.1,2.2-14.6-1.8c-4-3.5-9.8-3.9-13.9-7.4c-1-0.9-2.4,0.3-3.6,0.5c-3.6,0.8-5.6-0.4-5.1-4c1-8,0.1-16,1.1-23.8
			c0.5-3.9,0.2-7.5-0.2-11.3c-0.5-5.1-0.1-10.2-0.2-15.3c0.6-13.4-0.1-26.9,1.1-40.2C452.2,320.1,458.5,303.8,473.7,292.3z"
        />
        <path
          class="st482"
          d="M671.5,371.8c-1-0.9-2-1.6-2.8-2.6c-3.8-4.8-8.8-8.4-13.8-11.8c-9.2-6.3-18.7-12.2-28.8-17.2
			c-3-1.5-5.8-3.4-8.6-5.2c-1-0.6-1.8-1.3-2.8-1.8c-1.4-0.8-2.4-0.7-3.5,0.4c-0.9,0.9-1.7,1.9-2.3,3c-4.2,8.1-10.7,14.4-17.4,20.2
			c-4.7,4.1-10.5,6.7-16.4,8.9c-10.1,3.7-20.6,4.7-31.2,3.7c-8.7-0.8-16.6-4-23.8-9.4c-5.1-3.9-8.7-8.8-11.8-14.2
			c-3.3-5.7-5.1-11.8-5.5-18.2c-0.2-3.6-1.9-5.4-4.8-7c-4.1-2.1-7.5-5.4-10.8-8.7c-0.1-0.9,0.2-1.5,1.2-1.1c2,1.4,4,2.9,6.2,4.2
			c3.3,2.1,6.7,4.1,10.6,4.7c0.7,0.4,1.5,0.3,2.1,0.8l0,0c1.6,1.5,3.5,1.9,5.5,2c0.4,0.2,0.8,0.6,1.1,0.9l0,0c0.9,7.5,4,14.1,8.7,20
			c2-0.1,3.7,1,5.2,2.1c14.4,11.5,39,9.2,52.8-1.1c5.9-4.5,11-9.6,14.5-16.2c0.8-1.5,1.5-3.2,3.5-3.6c2.6-3.9,3.1-8.2,2.2-12.7
			c-0.3-1.2-0.4-2.5-0.6-3.7c-0.4-0.6-0.3-1.3-0.6-1.9c0-0.3,0-0.7,0-1c1.2,0.2,2.1,0.7,2.5,1.9c1.6,4.7,3.2,9.3,1.1,14.3
			c1.2,4.4-0.5,8.3-2.7,11.8c-12.5,20.5-41.5,33.5-66.8,23.1c-9.1-3.7-15.5-10.4-20.6-18.6c-1.1-1.7-1.8-3.7-4.3-5.4
			c1,8.9,4.8,15.7,11,20.7c19.5,15.6,42.1,16.3,64,2.4c8.8-5.6,16.8-12.1,21.5-21.9c0.8-1.6,1.7-3.1,3.7-3.5c2-2.6,2.8-5.6,2.8-8.9
			c0-1.1,0-2.2,0.1-3.3c0-0.6,0.2-1.4,1-1.4c1-0.1,1.3,0.7,1.5,1.5c0.3,1.3,0.2,2.5-0.1,3.8c-0.2,1.1-0.5,2.2-0.6,3.3
			c-0.3,2.6,0.5,4.7,2.7,6.2c1.4,1,2.9,2,4.4,2.9c10.5,6.1,21,12.1,31.4,18.2c3.7,2.2,6.9,5.1,10.3,7.7c3.8,3,6.7,6.7,9.7,10.4
			C672.2,371.1,671.9,371.5,671.5,371.8z"
        />
        <path
          class="st483"
          d="M466.6,181.7c0.5-0.8,0.8-1.7,1.4-2.4c1.1-1.3,1.9-1.5,3.4-0.8c1.2,0.5,2.3,1.1,3.4,1.8
			c14,7.7,28.9,12.6,44.7,14.8c9.2,1.2,18.4,1.7,27.7,1.1c2.9-0.2,5.7-0.7,8.5-1c5.9-0.5,7.1,0.7,6.5,6.7c-0.1,0.8-0.2,1.6-0.8,2.2
			c-1.2-2.2-3-3.2-5.5-3.1c-2.9,0.1-5.7-0.1-8.5,0.6c-1.5,0.4-3.2,0.4-4.7,0.4c-5.4,0.1-10.8-0.1-16.2,0.1c-5.5,0.1-10.8-0.8-16-2
			c-11.4-2.5-22.2-6.8-32.8-11.6c-3.2-1.5-6.2-3.2-8.9-5.5C468,182.3,467.3,182,466.6,181.7z"
        />
        <path
          class="st484"
          d="M528.9,237.6c4,0.4,7.2,1.2,10.4,2.2c3.8,1.2,5.7,3.8,5.8,7.8c0.1,2.7,0.1,5.4-1.2,8
			c-2.2,4.5-6.8,5.6-10.8,2.7c-1.8-1.4-3.1-3.2-3.7-5.4c-0.9-3-1.2-6-0.5-9C529.4,241.9,529.5,240,528.9,237.6z"
        />
        <path
          class="st485"
          d="M488.3,234.4c0.1,2.2-0.3,4.3-1.6,6.2c-1.7,2.5-4.4,3.2-7.1,1.9c-3.1-1.5-4.7-4.2-5.4-7.5
			c-0.6-3.3-0.5-6.7,0-10c0.6-3.2,1.9-4,5-3.2c4,1,6.7,3.4,8.1,7.3C488.1,230.8,488.3,232.5,488.3,234.4z"
        />
        <path
          class="st486"
          d="M544.4,235.6c-6,0.4-11.3-0.6-16.7-1.3c-2.2-0.3-4.3-1.2-5.7-3.1c-0.7-0.9-1.1-1.9-0.5-3.1
			c0.6-1.2,1.7-1.5,2.8-1.2c6.6,1.6,13.3,1.6,20,2c2.2,0.1,4.4,0.6,6.6,1.1c0.8,0.2,1.5,0.6,2.1,1.1c1,0.8,1.1,1.9,0.7,3.1
			c-0.3,1.1-1.1,1.5-2.2,1.5C549,235.6,546.4,235.6,544.4,235.6z"
        />
        <path
          class="st487"
          d="M518.7,295.3c-1,0-1.9-0.1-2.8,0c-7.8,0.7-14.8-1.2-21.1-6c-1.5-1.1-3-2.4-4-4.1c-0.2-0.4-0.2-1,0-1.3
			c0.3-0.6,0.9-0.9,1.6-0.6c1,0.4,2.1,0.9,3,1.5c5.6,3.7,12,5.2,18.4,6.2c3.7,0.6,7.3-0.5,10.9-1.2c1.3-0.2,2.5-0.6,3.8-0.5
			c0.8,0.1,1.5,0.5,1.8,1.4c0.2,0.8,0,1.6-0.7,2.2c-1.2,1.1-2.7,1.8-4.3,2c-2.2,0.3-4.4,0.5-6.6,0.7
			C518.7,295.4,518.7,295.4,518.7,295.3z"
        />
        <path
          class="st488"
          d="M469.5,206.4c0.2,0.1,0.9,0.1,1.4,0.4c5.9,2.8,11.8,5.5,17.2,9.1c0.9,0.6,1.7,1.4,2.5,2.2
			c0.6,0.6,0.6,1.5,0.2,2.2c-0.5,0.9-1.3,1.4-2.3,1.3c-0.5-0.1-0.9-0.2-1.4-0.4c-5.8-2.5-11.6-5.1-17-8.6c-1.1-0.7-2.1-1.4-2.8-2.5
			C466.5,208.6,467.5,206.5,469.5,206.4z"
        />
        <path
          class="st489"
          d="M488.6,311.1c-0.4,0.4-0.8,0.7-1.2,1.1c-1.4-0.7-2.4-2-3.5-3c-1.1-1.1-1.5-2.4-1.3-3.8
			C484.9,306.9,486.8,308.9,488.6,311.1z"
        />
        <path
          class="st441"
          d="M507.5,320.8c-0.8,0-1.5-0.2-2.1-0.8C506.2,319.9,506.9,320.2,507.5,320.8z"
        />
        <path
          class="st490"
          d="M520.1,96.4c4.3-1.2,8.5-2.5,12.9-3.3c7.1-1.2,14.3-1,21.4-0.5c5.7,0.5,11.3,2.1,16.6,4.2
			c2.5,1,4.9,2.2,7.4,3.3c2.3,2.8,0.5,5-1.4,6.6c-5.9,4.9-7.8,11.3-8,18.7c-0.2,8.2-3.6,11.9-11.8,13c-3.3,0.4-6.5,0.9-11,1.7
			c6,4.3,6.1,9.2,2.6,14.1c-2.9,3.9-5.1,9.2-11.6,8.3c-0.3,0-0.6-0.1-0.9-0.1c-0.3-0.3-0.7-0.5-1-0.8c-11.9-5.8-24.2-10.9-35.9-17.2
			c-3.2-1.7-6.5-3.5-9.3-5.9c-0.1-2.3,1.3-3.8,2.9-5.2c1.4-1.3,3.1-2.2,5-2.4c0.6-0.1,1.3-0.1,1.9-0.1c4.5,0.1,8-2,10.5-5.5
			c1.5-2.1,2.9-4.2,3.9-6.5c1.4-3.2,2.5-6.5,3.7-9.8C519.6,105,520.1,100.8,520.1,96.4z"
        />
        <path
          class="st491"
          d="M456.6,118.4c1.5-0.1,2.8,0.4,3.9,1.5c1.3,1.4,2.7,2.7,4,4.1c2.2,2.3,4.6,4.4,7.5,5.9
			c6,5.6,12.8,10,20.1,13.6c7.9,3.8,15.8,7.6,23.7,11.4c3.3,1.6,6.5,3.3,9.8,4.9c0.4,0.3,0.7,0.6,1,0.9l0,0c2.2,3.7-0.1,6.1-2.6,8.1
			c-2.2,1.7-4.8,2.6-7.6,2.5c-6.8-0.2-13.7,1.2-20.5-1l0,0c-0.5-0.6-1.4-0.4-1.9-0.9c-5.2-2.3-9.5-5.9-13.6-9.6
			c-4.2-3.7-8.2-7.5-11.6-12c-0.3-0.4-0.7-0.6-1.1-0.9c-0.1-0.1-0.3,0-0.4,0.1s-0.2,0.2-0.2,0.4c0,1.7,0.9,3,2.1,4.2
			c5.3,5.3,10.3,10.9,16.2,15.5c1.1,0.9,2.1,1.9,3.1,3c0.8,0.9,1,2,0.6,3.1c-0.3,0.9-1.4,1.6-2.2,1.4c-0.8-0.2-1.5-0.5-2.2-0.9
			c-6.3-3.4-12.2-7.6-17.3-12.7c-6.5-6.5-11.2-14.3-13-23.5C453,130.9,453,124.4,456.6,118.4z"
        />
        <path
          class="st447"
          d="M537.2,162.6c5.3-2.8,8.9-7,11.5-12.4c1.2-2.5,0.5-5.1-1.8-6.7c-1.3-0.9-2.7-1.8-3.9-2.8
			c-1-0.8-0.7-2.4,0.5-2.7s2.5-0.4,3.8-0.4c4.3-0.3,8.6-0.9,12.8-1.9c0.8-0.2,1.5-0.4,2.3-0.7c1.9-0.8,3.1-2,3.7-4.1
			c0.7-2.7,1-5.3,0.9-8.1c-0.1-4.1,1.4-7.8,3.1-11.4c1-2.1,2.6-3.9,4.7-5.4c1.9-1.4,3.9-2.9,3.6-5.8c1,0.4,2,1,3,1.3
			c2.9,0.8,5.3,2.5,7.7,4.3c2.3,2.9,2.3,6.1,1.2,9.5c-1,3.1-2.4,5.9-4.1,8.6c-2.3,3.7-4.1,7.6-6.1,11.4c-0.6,1.1-1.1,2.3-1.8,3.4
			c-1.6,2.5-3.6,4.4-6.8,4.8c-1.6,0.2-3.2,0.5-4.7,0.8c-3.8,0.7-5,2.3-4.3,6c1.6,7.6-0.9,13.7-6.5,18.9c-2.2,2-4.5,2.3-7.1,1.4
			c-0.6-0.2-1.2-0.4-1.7-0.8C543.7,167.7,540.2,165.5,537.2,162.6z"
        />
        <path
          class="st492"
          d="M606.3,179.4c0.1,3.1-1.4,5.9-3.6,8.2c-3,3.1-6,6.1-9.2,9.1c-2.1,2-4.3,3.8-6.5,5.6
			c-3.6,3.1-6.4,3.3-10.5,0.8c-0.4-0.2-0.8-0.5-1.2-0.8c-4.7-3.3-5.4-5.8-3.2-10.9c1.2-2.8,2.3-5.6,3.5-8.4
			c1.5-3.7,3.2-7.3,5.5-10.6c2-2.8,4.5-4.9,7.4-6.7c2.4-1.5,4.8-1.6,7.3-0.3c3.4,1.8,6.7,3.7,8.6,7.3
			C605.6,174.7,606.3,176.8,606.3,179.4z"
        />
        <path
          class="st493"
          d="M608.1,132.8c2.6,1.6,5.2,3.2,7.8,4.8c0.5,0.8,1.1,1.6,1.6,2.4c2,3.1,2.9,6.2,1.6,9.9c-1,3-1.3,6.2-1.3,9.4
			c0.1,3.5-1.1,6.6-3.2,9.3c-0.7,0.9-1.5,1.7-2.4,2.3c-2,1.4-3.3,1.2-5.1-0.4c-0.8-0.8-1.6-1.6-2.3-2.4c-2.5-2.7-5.4-5-8.8-6.5
			c-0.6-0.3-1.2-0.6-1.7-0.9c-3.6-2-4-3-2.1-6.9c1.5-3,3.2-5.8,4.8-8.8c1.6-2.9,3.2-5.9,4.5-8.9c1.1-2.5,2.7-3.8,5.5-3.4
			C607.5,132.9,607.8,132.8,608.1,132.8z"
        />
        <path
          class="st447"
          d="M496,170.2c5.6-1.4,11.3-0.9,17-0.9c2.2,0,4.4-0.1,6.6-0.7c3.2-0.9,5.3-2.6,6.4-5.7
			c0.3-0.8,0.5-1.5,0.7-2.3c4.6,1.2,8.3,3.8,11.6,7.2c2.5,2.6,2.2,5.6-1.4,7.3c-3.3,1.6-6.8,2.9-10.4,3.5c-5.7,1-11.4,1-17-0.8
			C504.5,176.3,499.7,174.2,496,170.2z"
        />
        <path
          class="st447"
          d="M494,169.3c0.8,0,1.4,0.3,1.9,0.9C495.2,170.2,494.5,169.9,494,169.3z"
        />
        <path
          class="st447"
          d="M526.7,160.6c-0.3-0.3-0.7-0.6-1-0.9C526,160,526.3,160.3,526.7,160.6z"
        />
        <path
          class="st447"
          d="M535.3,161.7c0.3,0.3,0.7,0.5,1,0.8C536,162.2,535.6,162,535.3,161.7z"
        />
        <path
          class="st494"
          d="M665.8,572.5c0.3-0.2,0.6-0.3,0.9-0.3c0.4-0.3,0.7-0.6,1-0.9l0.1-0.1c0.3-0.3,0.6-0.6,0.9-0.9l0,0
			c0.3-0.3,0.7-0.6,0.9-1l0,0c0.4-0.3,0.7-0.6,1-1l0,0c0.4-0.3,0.7-0.6,1-1l0,0c0.4-0.3,0.7-0.6,1-1l0,0c0.3-0.3,0.7-0.6,1-0.9
			l0.1-0.1c0.2-0.3,0.5-0.5,0.8-0.6s0.7-0.2,1-0.4c2.1-1.1,3.3,0.3,4.4,1.7c2,2.6,4.7,4,7.9,3.9c5.6-0.1,10.7,1.2,15.9,5.1
			c-1.9-2.7-5.6-4.4-2.7-7.3c2.6-2.6,5.7-1,8.2,1.1c7.5,6.1,9,17,3.3,25.5c-6.5,9.8-15.4,17.5-24.6,24.6
			c-13.6,10.5-28.4,19.3-42.2,29.6c-12,8.9-24.7,17-35.7,27.2c-6.7,6.2-15.3,9-23.5,12.5c-5.2,2.1-10.4,4-15.3,6.8
			c-1.6,1-3.5,1.5-4.9,2.8c-1,0.9-2.3,1.2-3.5,0.3c-1.1-0.7-0.8-1.9-0.8-3c0.2-2.8,2.1-5.3,1.9-8.2c0.2-2.7,2.1-4,4-5
			c-2,0.6-3.2,3-5.8,3c-6.1-0.1-8.5,5-11.8,8.7c-1.4,1.5-2.5,3.3-4.2,4.6c-1.3,1-2.8,1.8-4.4,1.1c-1.9-0.9-2-2.8-1.8-4.6
			c0.5-4.5,2.4-8.6,3.4-12.9c0.2-0.8,0.3-1.6,0.4-2.4c2.8-5.5,8.3-8,13.1-11.2c1.1-0.7,2.5-0.5,3.6,0.3c1.6,2.6-0.5,4.1-1.8,5.8
			c-0.5,0.8-1.6,1.5-1.1,2.4c0.7,1.2,2,0.7,3,0.4c2.6-0.8,5.2-1.5,7.9-2c4.4-0.9,8.3-0.2,11.3,3.4c1.8,2.2,3.5,2.5,5.7,0.7
			c0.2-0.2,0.5-0.5,0.8-0.5c10-2.8,16.2-10.6,23.4-17c7.2-6.3,16.5-10,22.5-17.7c5.5-7,12.8-11.3,20.1-15.9
			c11.8-7.4,22.9-15.8,31.5-26.9c2.3-2.9,4.2-6,5.7-9.4c3.3-8,1.7-12.9-9.3-14.5c-2.5-0.4-5.1,0-7.7-0.2c-2.6-0.2-5.1-0.5-6.5-3.2
			C665.5,573.4,665.6,573,665.8,572.5z"
        />
        <path
          class="st495"
          d="M522.8,729.6c-0.4,0.2-0.8,0.5-1.3,0.7c-1.9,0.5-2.9,0.2-3.5-1.4c-0.8-2.2-1.4-4.6-1.8-6.9
			c-0.2-1.3-1.3-3-0.2-4.1c1.4-1.4,2.9,0.3,4.3,0.7c1.2,0.4,2.4,1,3.6,1.4c0.6,0.2,1.2,0.3,1.8-0.5c-1.5-1.5-3.3-2.3-5.3-3.1
			c-1.2-0.4-2.5-0.8-3.6-1.3c-2.5-1.2-3.2-2.6-2.9-5.4c0.1-1,0.4-1.9,0.6-2.8c0.4-0.7,1-1,1.8-1.1c5,0.4,9.6,1.4,12.9,5.7
			c0.9,1.7,2,3.1,4,3.6c1.1,0.7,2.3,1.2,3.6,0.5c4.1-2.1,7.6-0.8,10.8,2c2.4,1.8,4.7,1.5,7-0.2c1.4-1,2.7-2.4,4.6-2.2
			c2.3-1.5,4.2-3.4,5.8-5.7c1.8-2.5,4-4.5,6.8-6c1.3,0.2,2.5,0.7,3.1,2c1.1,2.5,3.2,3.8,5.5,5.1c2.5,1.5,4.9,3,6.8,5.2
			c1.1,1.2,1,2.3-0.4,3.1c-2.7,1.7-5.5,3.3-8.3,4.8c-7.8,4.1-15.5,8.1-23.3,12.1c-2.7,1.4-5.4,2.7-7.7,4.7c-3,2.5-6.3,2.8-9.8,1.2
			c-4.6-2.2-8.7-5.2-13.1-7.9c-1.2-0.7-0.7-2-0.5-3.1c0.3-2.3,2-3.5,3.7-4.5C525.9,727,524.9,729.1,522.8,729.6z"
        />
        <path
          class="st496"
          d="M586.2,688.3c2.9-2.6,6.3-4.3,9.9-5.7c5.1-2,9.7-4.7,13.6-8.7c2.1-2.2,4.5-4,6.9-5.9
			c8.1-6.2,16.1-12.6,24.4-18.5c4.8-3.4,9.6-6.8,14.5-10.1c8.9-6,17.7-12,26.4-18.2c3.5-2.5,6.6-5.5,10-8.2c4-3.3,7.6-6.8,11.2-10.5
			c2.9-2.9,5.4-6.3,7.8-9.7c5.9-8.4,2.1-18.5-4.2-23.5c-1.5-1.2-3.2-1.3-5-0.7c-0.5,0.2-0.8,1.2-0.3,1.6c1.2,1,2.5,1.9,3.8,2.8
			c1,0.7,1.8,1.4,2.1,2.5c0.2,0.6,0.1,1.3-0.3,1.8c-0.5,0.7-1.3,1.1-2.1,0.7c-2-1-4-2.1-5.8-3.3c-2.9-2-6.1-2.8-9.5-2.6
			c-0.8,0-1.6,0-2.4,0c-4.1-0.1-7.4-1.8-9.5-5.5c-0.6-1-1-2-2.3-2.2c1-1,2-1.9,2.9-2.9c3.7-0.2,7.3,0.6,10.9,1.3
			c1.9,0.4,3.8,0.6,5.7,0.7c1.6,0.1,3.2,0.2,4.7,0.5c2.2,0.5,4.5,0.5,6.7,0.3c3.2-0.4,6.3-1.1,9.4-1.5c7.7-1.1,13,2.4,12.5,11.3
			c-0.1,1.4-0.4,2.8-0.8,4.2c-2.2,7.4-5.5,14.2-11.1,19.8c-5.8,5.8-12.2,10.8-18.7,15.7c-12.9,9.7-25.8,19.2-39.4,28
			c-4.6,2.9-9.3,5.6-13.5,9.1c-2,1.6-3.9,3.2-5.9,4.9c-4.9,4.1-10.5,7.3-15.8,10.8c-2.4,1.6-4.5,3.5-6.7,5.4
			c-7.5,6.5-15.3,12.6-25,15.5C589.7,688,588,688.5,586.2,688.3z"
        />
        <path
          class="st497"
          d="M692.6,648c-0.3-0.2-0.5-0.3-0.8-0.5c-2.2-1.4-2.3-1.4-4,0.3c-0.6,0.5-1.1,1.1-1.7,1.7
			c-6.3,7.6-14.9,11.9-23.9,15.3c-2.1,0.8-4.2,1.4-6.3,2.2c-6,2.2-12.3,3.2-18.7,3.2c-2.2,0-4.5,0-6.7,0.1c2.1-2.4,4.8-4.1,7.6-5.8
			c17.2-10.2,34.2-21,51.2-31.6c0.4-0.2,0.8-0.5,1.2-0.8c1.9-1.1,1.9-1.1,3.8,1C695,638.2,694,643.1,692.6,648z"
        />
        <path
          class="st496"
          d="M559.3,715.2c-1.2,2.5-3.2,4.5-5.8,5.3c-2.4,0.8-5,1.5-6.8-1.4c-2.6-4.4-2.1-8.6,0.3-13
			c3-5.6,6.4-10.9,10.7-15.6c1.9-2,3.6-5.1,7.3-4c-0.1,1.9,0.1,3.8-0.5,5.6c-0.6,1.6-0.5,3.4-1.7,4.8c1.4,0.9,1.8-0.3,2.5-0.8
			c0.8-0.5,1.6-1,2.6-1c0.9,0,1.9,0,2.8,0c-0.8,1-1.6,2-2.5,2.8c-2.8,2.6-5,5.6-7.4,8.5C558.8,709.1,557.8,711.9,559.3,715.2z"
        />
        <path
          class="st498"
          d="M490.1,600c-1.2-0.1-2.1-0.7-2.7-1.7c-0.9-1.6-2.3-2.1-4-2.1c0.7-4.1-1.6-6.6-4.8-8.5
			c-3.7-2.2-7.5-4.1-11.3-6.2c-4-2.2-8.2-4.2-12.1-6.7c-3.1-1.9-6.4-3.5-9.6-5.2c-1.4-0.7-2.9-1.4-4.3-2c1.6,0,2.8-0.6,3.6-2
			c1-1.8,2-3.6,2.9-5.5c0.7-1.4,1.3-2.9,2.9-3.7c2.2-1.7,4.6-0.2,6.9-0.5c2.3,0.5,3.1,2.6,4.3,4.2c3.7,5.1,7.9,9.5,14.4,10.9
			c11.1,6,21.7,12.8,32.5,19.2c21.3,12.8,42.8,25.3,64.1,38.2c2.3,1.4,4.9,2.5,5.6,5.5c0,2.1-1.1,3.3-3.1,3.8
			c-4.6-0.7-8.9-2.4-13-4.7c-18.3-10.4-36.8-20.6-54.9-31.4c-5-3-9.7-5.7-15.4-1.8C491.5,600.3,490.8,600,490.1,600z"
        />
        <path
          class="st499"
          d="M475.8,572.4c-4.4-2.8-8.1-5.8-13.3-9.7c-1.8-2.2-3.1-2.6-6-5.4c-3.7-2.8-2.9-4.6-3.9-7.5
			c-0.9-2.5-1.7-5.1-1.9-7.8c-0.5-1.1,0-2.3-0.6-3.4c-1.2-5.3-2.6-10.5-3.4-15.9c-1.5-11.1-0.7-22,2.3-32.8c1.1-4,2.5-7.9,3.7-11.9
			c0.8,0.2,1.4,0.6,1.8,1.4c0.9,3.1-0.2,6-1,8.9c-7.4,25.3-3.5,48.8,10.9,70.7c2.2,3.3,4.7,6,8.3,7.6c1.8,0.8,3.4,1.9,4,3.8
			C477.1,571.4,477.4,572.5,475.8,572.4z"
        />
        <path
          class="st500"
          d="M453.7,479.2c-0.3-0.4-0.7-0.7-1-1.1c0-1.5,0-3,0-4.6c0.8-2.6,0-4.9-1.9-6.8c-0.8-1.9-1.4-3.8-2.3-5.6
			c-1.1-2.2-1.6-4.4-1.5-6.8c0.4-0.3,0.8-0.4,1.3-0.3c2,0.8,2.7,2.6,3.4,4.4c1,2.7,2.3,5.2,3.7,7.7c1,1.9,58.2,5,57.2,7.3
			C511.2,476,456.6,477.4,453.7,479.2z"
        />
        <path
          class="st444"
          d="M514.2,323.7c-0.4-0.3-0.8-0.6-1.1-0.9C513.5,323.1,513.8,323.4,514.2,323.7z"
        />
        <path
          class="st501"
          d="M448,454.2c-0.3,0-0.7,0-1,0l0,0c-0.5-0.5-0.4-1.4-0.9-1.9c0-4-0.4-8,0.1-12c0.7-5.8,0.6-11.4,0-17.1
			c-0.5-4.9-0.3-9.9,0.6-14.8c0.6-3.6,0.2-7.3,0.3-11c0.1-3.7-0.1-7.3,0.7-11c0.4-1.7,0.1-3.5,0.2-5.3c0.1-3.7-0.2-7.4,0.8-11
			c0.2-0.9,0.2-1.9,0.2-2.9c0-9.4,0-18.9,0-28.3c1-8.2,2.7-16.3,6-23.9c3.3-7.7,8-14.4,14-20.3c1.4-1.3,2.8-2.6,4.9-2.6
			c-2.6,3-5.2,6-7.8,9c-6.3,7.3-9.9,16-12.1,25.2c-1.4,6-1.8,12.2-2.3,18.5c-0.8,11.1-0.4,22.3-0.8,33.4c-0.2,0.4-0.4,0.9-0.4,1.4
			c0.7,14.8-0.7,29.5-1,44.3c-0.1,3.6-0.2,7.2,1.2,10.8c1.2,3.1,0,6.6-0.6,9.9c-0.4,2.3-0.7,4.6-0.6,6.9
			C449.3,452.6,449,453.6,448,454.2z"
        />
        <path
          class="st500"
          d="M446.1,452.3c0.6,0.5,0.8,1.2,0.9,1.9C446.4,453.7,446.1,453.1,446.1,452.3z"
        />
        <path
          class="st431"
          d="M448,454.2c0-1,0-1.9,0-2.9c-0.7-1.9-0.7-3.8,0-5.8c0.6-5.4,1.4-10.7,1-16.1c-0.1-2.2-0.1-4.4-0.6-6.6
			c-0.6-2.3-0.6-4.8,0-7.1c0.6-2.5,0.6-5,0.6-7.6c0-3,0-6,0.7-9c0.3-1.4,0.2-2.8,0.2-4.3c0-4.1,0-8.3,0.1-12.4c0-1.4,0-2.9,0.8-4.1
			c0,2.9,0.1,5.8,0.1,8.6c0,3,0,6,0.7,9c0.4,2.1,0.5,4.5,0.1,6.7c-0.8,3.8-0.7,7.6-0.7,11.4c-0.1,3.7,0.2,7.3-0.8,11
			c-0.4,1.6-0.1,3.5,0,5.2c0.1,1.3,1.4,1.9,2.5,1.3c0.3-0.2,0.6-0.3,0.8-0.5c2.9-3,3.5-3.2,6.5,0c1.5,1.5,3.3,2.5,5.3,3.1
			c3.1,0.9,6,2.1,8.3,4.5c0.8,0.9,2.1,1.4,3.2,2c1.5,0.9,3,1,4.6,0.3s3.3-1.3,4.8-2.1c3.9-2,8-2.6,12.1-1.8c3,0.6,5.8,0.2,8.4-0.9
			c2.3-1,4.6-1.1,6.9-1.8c1.9-0.6,3.6-0.3,4.7,1.4c1.8,2.6,4.2,3.1,7.1,3c3-0.1,6.1,0.1,9.1,0c3.3-0.2,6.3,0.6,9.2,2.2
			c3.7,2.1,7.8,2.9,12,2.5c3.3-0.3,6.7-0.6,9.9-1.3c2.5-0.5,5.1-0.5,7.6-0.5c2.8,0,5.3,0.8,7.5,2.6c5,3.9,10.6,4.9,16.7,3.7
			c0.9-0.2,1.9-0.4,2.8-0.5c6-0.5,6.3-0.4,9.8,4.6c2.5,3.4,4,3.9,8.1,2.4c2.6-0.9,5.1-1.7,7.9-1c2.3,0.5,3.7-0.5,4-2.9
			c0.4-3.4,1.4-6.5,2.1-9.8c0.5-2.5,1.6-4.5,3.8-5.9c0,0.3,0.1,0.6,0,1c-1.2,5.3-0.7,10.8-1.5,16.2c-0.6,3.8-0.5,7.6,0.1,11.5
			c0.2,1.1,0.5,2.2,0.5,3.3c-0.2,7.1,1.1,14,2.1,20.9c1,7.1,2.5,14.1,3.6,21.2c0.5,2.9,1.5,5.5,2.7,8.1c0.8,1.7,1.6,3.4,2.2,5.3
			c1,3.4,1,6.6-0.3,9.8c-0.5,1.3-0.9,2.7-1.4,4.1c-1.4,3.7-1.4,7.5-0.9,11.3c1,7,1,14,0,21c-0.3,2.4-0.4,4.8-0.3,7.2
			c0,1.6,0.9,2.7,2.4,3.2c1.4,0.5,2.5-0.1,3.4-1.1c0.5-0.6,1-1.3,1.5-1.9c1.3-1.3,3.1-1,3.8,0.8c0.5,1.3,0.8,2.8,1.2,4.1
			c0.8,2.5-0.8,4-2.3,5.4c-17.7,16.7-35.6,33-55.7,46.8c-4.8,3.3-10.1,3.7-15.6,3.2c-1.7-0.1-3.1-0.8-3.8-2.5
			c-25.8-15.4-51.5-30.8-77.3-46.2c-5.9-3.5-11.8-7-17.7-10.6c-2.4-1.5-4.8-3.2-7.1-4.8c0.4-0.7,0.3-1.3-0.3-1.7
			c-1.3-0.8-2.7-1.7-4.1-2.4c-3.2-1.6-5.7-3.8-7.8-6.8c-6.5-9.4-11.1-19.7-13.8-30.7c-1.1-4.5-1.9-9-1.7-13.7c0.1-1.8,0.1-3.5,0-5.3
			c-0.6-8.2,1.2-16.1,3.4-24c0.8-2.9,1.6-5.8,2.4-8.7c0.1-1.1,0.2-2.2,0.4-3.3c0.6-2.6,51.7-0.8,54.3-3c-25.2,2.3-52.3,0.7-54.2-3.4
			C453.4,467.7,448.6,456,448,454.2z"
        />
        <path
          class="st502"
          d="M577.9,634c3.4,1.1,6.9,1,10.4,0.7c2.9-0.2,5.5-1.5,7.9-3.1c6.7-4.4,13.1-9.1,19.1-14.3
			c11.1-9.5,22.1-19.2,33.1-28.9c2.5-2.2,5.1-4.4,6.9-7.2c3.2-2,5.7-4.8,8-7.8c0.3-0.6,0.8-0.8,1.5-0.6c0.4,0,0.8,0.2,1,0.5
			c2.5,1.6,5.3,2.2,8.2,2c1.6-0.1,3.2,0.1,4.8-0.1c4.1-0.4,7.5,1.4,10.7,3.4c2.2,1.3,3.2,3.3,3.2,6c0,2.9-0.6,5.7-1.8,8.3
			c-1.7,3.6-3.8,6.9-6.3,10.1c-2.8,3.5-5.8,6.9-9.1,9.8c-7.3,6.4-14.9,12.5-23.2,17.5c-3.8,2.3-7.6,4.7-11.4,7.1
			c-1.2,0.8-2.4,1.7-3.2,2.9c-2.7,4-6.4,7.1-9.8,10.5c-1.1,1.1-2.5,2-3.8,2.9c-5.1,3.5-10.5,6.7-15.2,10.8c-3,2.6-6.2,5.1-8,8.7
			c-1,2-2.7,3.3-4.8,3.5c-4,0.4-7.3,2.2-10.3,4.7c-0.6,0.5-1.3,1-2,1.3c-1.9,1-3.2,0.8-4.6-1c-0.3-0.4-0.6-0.8-0.8-1.2
			c-1.9-3.5-5-4.2-8.6-3.6c-2.3,0.4-4.6,1.1-7,1.7c-1.7,0.4-3.4,1-5.1,1.3c-1.8,0.4-2.9-0.1-3.7-1.4c-0.6-1.1-0.5-2.2,0.7-3.5
			c1.4-1.5,2.9-3,4.3-4.5c0.4-0.5,0.7-1,1.1-1.6c-0.9-2.2-2.3-1.9-3.8-0.8c-10.7,7.6-21.6,14.8-27.4,27.4
			c-2.5,5.4-5.3,11.3-12.9,11.1c-0.5,0-1.1,0.1-1.6,0.1c-0.2-1.3-0.4-2.6-0.6-3.9c-2.1-3.9-1.8-7.8,0.3-11.6c1-1.2,2.3-2,2.8-3.7
			c4.8-15.1,14.2-26.2,28-31.7c-11.5,4.8-21.1,13.3-27.6,25.4c-1.3,2.4-3,4.6-6.2,4.4c-1.3-0.2-2.1-1.2-2.6-2.3
			c-0.7-2.7-2.4-5.2-2-8.2c0.3-3.8,3.1-6.8,3.8-10.5c4.1-5.7,7.8-11.7,12.3-17.2c8.3-7,18.9-7.8,28.9-10.1c7.6-1.8,15.3-2.3,23-1
			C576.3,636.4,577.5,635.7,577.9,634z"
        />
        <path
          class="st503"
          d="M664.9,573.2c-0.5,0-1,0.1-1.5,0.2c-1.1-0.2-2.2-0.6-3-1.4c-0.8-0.8-1-1.8-0.4-2.5c0.7-0.9,2.1-1,3-0.2
			c1,0.9,1.4,2.1,2,3.2c0.3,0.2,0.4,0.4,0.3,0.5C665.1,573.1,665,573.2,664.9,573.2z"
        />
        <path
          class="st496"
          d="M664.9,573.2c0-0.2,0-0.5,0.1-0.8c0.3,0,0.6,0,0.9,0.1c0,0.2,0,0.5,0,0.7
			C665.5,573.2,665.2,573.2,664.9,573.2z"
        />
        <path
          class="st496"
          d="M667.8,571.4c-0.3,0.3-0.7,0.6-1,0.9C667.1,571.9,667.4,571.7,667.8,571.4z"
        />
        <path
          class="st496"
          d="M668.7,570.4c-0.3,0.3-0.6,0.6-0.9,0.9C668.1,571,668.4,570.7,668.7,570.4z"
        />
        <path
          class="st496"
          d="M669.7,569.3c-0.3,0.3-0.6,0.7-0.9,1C668.8,569.8,669.2,569.5,669.7,569.3z"
        />
        <path
          class="st496"
          d="M670.6,568.4c-0.3,0.3-0.7,0.7-1,1C670,569,670.3,568.7,670.6,568.4z"
        />
        <path
          class="st496"
          d="M671.6,567.4c-0.3,0.3-0.7,0.7-1,1C670.9,568.1,671.2,567.8,671.6,567.4z"
        />
        <path
          class="st496"
          d="M672.5,566.5c-0.3,0.3-0.7,0.7-1,1C671.9,567.1,672.2,566.8,672.5,566.5z"
        />
        <path
          class="st496"
          d="M673.5,565.6c-0.3,0.3-0.7,0.6-1,0.9C672.7,566,673,565.7,673.5,565.6z"
        />
        <path
          class="st496"
          d="M674.4,564.9c-0.3,0.2-0.5,0.4-0.8,0.6C673.8,565.3,674.1,565.1,674.4,564.9z"
        />
        <path
          class="st504"
          d="M598.8,324.9c-0.5,3.3-2,6.1-3.9,8.7c-8.3,11.2-18.9,19-32.9,21.3c-7.9,1.3-15.9,1.4-23.7-1.2
			c-6-2-11.4-4.9-15.5-9.9c0.3-0.8,0.6-1.6,1.6-1.5c1.3,0.1,2.5,0.4,3.7,0.7c5.3,1.4,10.5,0.2,15.3-1.8c6.2-2.6,11.8-6.2,17.1-10.4
			c6.2-4.9,11-11,15.7-17.2c2.6-3.4,4.8-7.1,6.9-10.8c0.5-0.8,0.9-1.7,1.9-2c1.1,0.1,0.9,1.1,1.2,1.8c2.2,6.7,5.8,12.7,10,18.3
			C597.4,322.1,598.5,323.2,598.8,324.9z"
        />
        <path
          class="st445"
          d="M599.8,306.5c0.5,0.5,0.7,1.2,0.6,1.9C599.9,307.9,599.7,307.3,599.8,306.5z"
        />
        <path
          class="st505"
          d="M609.3,330.5c-2.7,7.8-7.4,14.1-13.6,19.4c-4.6,4-9.6,7.4-14.9,10.4c-8.7,4.9-17.9,7.7-27.9,7.5
			c-13.2-0.3-25.2-4.5-35.1-13.3c-6.4-5.7-10-13.2-11.3-21.6c-0.1-0.6-0.1-1.3,0-1.9c0-0.8,0.3-1.6,1.1-1.9c0.8-0.3,1.6,0,2.2,0.5
			c1.8,1.6,3,3.7,4.2,5.8c1.8,3,3.8,5.9,6,8.6c5.5,6.9,12.7,11,21.2,13c12.5,3,24.5,1.2,35.8-4.6c7.7-4,14.5-9.3,19.8-16.4
			c2.2-3,3.8-6.2,4.7-9.9c0.4-1.6,0.7-3.1,1.9-4.3c3.6,0.2,5.1,2.5,5.7,5.6C609.3,328.3,609.2,329.4,609.3,330.5z"
        />
        <path
          class="st430"
          d="M481.6,597.4c-0.4,0.2-0.7,0.5-1,0.7C480.9,597.9,481.2,597.7,481.6,597.4z"
        />
        <path
          class="st506"
          d="M531.6,838.1c1.9-1.3,3.4-2.9,4.8-4.7c4.6-5.8,9.8-11,15.6-15.5c1.9-1.5,3.6-3.1,5.5-4.7
			c0.6-0.2,1.2-0.4,1.8-0.7c5.9-2.8,11-1.4,15.7,2.9c4.2,3.9,6.2,8.7,6.5,14.3c0,1.1,0,2.2,0,3.3c-0.9,1.2,0.1,2.8-0.8,3.9
			c-0.1,0.3-0.1,0.6-0.1,0.9c-1.2,2.2,0.8,4.7-0.6,6.8c-1.8,5.3-7,9.2-7.1,15.3c-0.5,1.2-1.1,2.3-0.4,3.6c0.5,1,0.8,2.1,1.3,3.4
			c-1.8,0.5-3.7-0.2-5.1,1.1c-1.5,1.4-3.1,2.8-4.4,4.3c-3.1,3.7-5.5,7.8-7.8,12c-2.1,4-4.2,7.9-6.3,11.8c-2.6,4.8-5.9,9.1-9.3,13.3
			c-4.2,5-9.2,8.7-15.9,9.9c-1.2-0.5-2.5,0.4-3.7-0.2c-0.4-0.2-0.8-0.4-1.2-0.6c-2.8-1.1-5.7-0.7-8.5-0.5c-12.9,1.2-23-10-18.3-25.2
			c3.1-9.9,8.1-19,14.5-27.3c6.7-8.7,13.8-17.1,20.9-25.5C529.4,839.3,530.3,838.5,531.6,838.1z"
        />
        <path
          class="st507"
          d="M531.6,838.1c-3,4.3-6.5,8.2-10,12.2c-6.8,7.9-12.8,16.3-18.1,25.3c-2.7,4.7-5.1,9.6-7.3,14.5
			c-1.6,3.8-2.4,7.5-2.2,11.6c0.3,7.9,6.8,14.4,14.8,14.9c4,0.2,8,0.4,11.9-0.6c0.6-0.2,1.2,0,1.9,0.1c0.2,0,0.3,0.5,0.4,0.7
			c0,0.1-0.1,0.3-0.2,0.4c-0.6,0.2-1.2,0.4-1.8,0.5c-4.1,2.2-8.6,1-12.9,1.3c-0.6-0.2,0.2,0.1-0.4,0s-0.9-0.6-1.4-0.6
			c0.5-0.1,0.7,0.6,1.3,0.6c4.5,0,9,0.2,13.4-0.5c1.3,0.1,2.8-0.2,3.8,1c-5.6,2.1-11.5,2.3-17.4,1.6c-4.2-0.5-8.4-1.6-12.5-2.8
			c-0.9-0.3-1.8-0.4-2.8-0.6c-0.5-0.3-1.1-0.7-1.6-0.9c-6.8-3.1-12-8-16-14.3c-0.9-1.3-1.7-2.7-2.5-4c-1.1-7.9-1.4-15.9-3-23.8
			c-0.8-3.5,0-7-0.5-10.5c0.6-4.1,2.5-7.8,4.2-11.6c0.8-1.8,2.1-3.2,4.4-3.1c0.8,1,0.6,2-0.1,3.1c-0.9,1.5-1.7,3.1-2.5,4.6
			c-0.4,0.8-0.1,1.6,0.6,2.1s1.5,0.6,2.2,0.2c1.2-0.6,2-1.6,2.8-2.6c1-1.3,2-2.5,2.9-3.8c5-7.4,12.3-9.1,20.5-8.2c2,0.2,4,0.9,6,1.4
			c0.5,0.1,0.9,0.6,0.7,1.1c-0.1,0.4-0.6,0.8-1,0.9c-1.4,0.5-2.8,0-4.2-0.4c-1.7-0.5-3.3-1.1-5.1-1.3c-5-0.5-9.1,1.2-12.3,5.1
			c-1.8,2.2-3.6,4.5-5.6,6.5c-2.1,2.1-2.7,4.6-2.4,7.5c0.1,1.4,0.8,2.5,2,3c1.5,0.6,2.9,1.3,4.5,1.6c7.5,1.3,14.2-0.3,19.9-5.5
			c3.2-2.9,5.8-6.4,8.5-9.7c3.1-3.8,6-7.8,9.6-11.3c1.2-1.2,2.2-2.8,3.3-4C528.6,838.7,529.6,837.2,531.6,838.1z"
        />
        <path
          class="st508"
          d="M695.7,697c-0.1-1.4-0.3-2.8-0.3-4.2c-0.1-3.8,0.1-7.6-0.7-11.3c-0.3-1.2-0.2-2.5-0.2-3.8
			c0-4.4,0.1-8.8,0.2-13.2c0.2,0.6,0.7,1.2,0.7,1.7c0.2,4.9,1.5,9.8,1.2,14.7c-0.1,1.1,0,2.2,0.1,3.3c0.2,2.4,1,2.9,3,1.6
			c2.2-1.4,4.2-2.8,6.4-4.2c0.9-0.6,1.9-1.2,2.9-1.7c1.1-0.4,2.2-0.3,3.1,0.6c0.9,0.9,1.1,2.5,0.3,3.5s-1.7,2-2.7,2.7
			c-4,3.1-8.2,6.1-12.2,9.2C696.9,696.4,696.3,696.7,695.7,697z"
        />
        <path
          class="st509"
          d="M572.7,860.1c-1.6-1.5-1.9-3.2-0.7-5.1c2.2-3.4,3.7-7.3,6.4-10.5c1.4,0,1.7,1.2,2.1,2.2
			C578.9,851.8,575.7,855.9,572.7,860.1z"
        />
        <path
          class="st510"
          d="M580.5,846.7c-1-0.5-1.6-1.2-2.1-2.2c0.5-2.3,0.9-4.5,2.1-6.6c0.1,0.6-0.1,1.3,0.7,1.7
			c1.9-3.6,1.6-7.5,2.2-11.3c0.7,1,0.7,2.1,0.7,3.2C583.8,836.9,583.1,842,580.5,846.7z"
        />
        <path
          class="st511"
          d="M574.7,811.2c-0.5-0.3-1-0.6-1.5-1c-0.4-0.3-0.3-0.9,0-1.2s0.8-0.5,1.2-0.5c0.8,0.2,0.7,0.8,0.5,1.4
			C574.7,810.3,574.7,810.8,574.7,811.2z"
        />
        <path
          class="st510"
          d="M583.4,828.5c-1.2-1.2-1.1-2.7-0.9-4.2C583.4,825.5,583.5,827,583.4,828.5z"
        />
        <path
          class="st467"
          d="M494,670.1c0.6,0.5,0.8,1.2,0.9,1.9C494.4,671.5,494.1,670.9,494,670.1z"
        />
        <path
          class="st512"
          d="M574.7,636.5c-1,0.9-2.3,0.9-3.6,0.9c-8.5-0.1-16.7,1.8-25,3.4c-2.5,0.5-5,1-7.4,1.7c-4,1-7.9,2.3-11.5,4.6
			c-1.3,0.9-2.7,1.7-4.3,2c-6.6-0.3-17-6.9-18.3-11.8c-0.4-1.8-1.1-3.4-2.5-4.6c-2.5-2.2-3.8-5-5-8c-2.8-7-4.7-14.2-6.6-21.5
			c-0.3-1.1-0.3-2.2-0.4-3.3c0.9-0.9,1.8-1.9,2.8-2.6c3.1-2.2,6.4-2.7,9.9-0.7c5,2.9,9.9,5.8,14.9,8.6c11.2,6.4,22.5,12.8,33.7,19.2
			c4.3,2.4,8.6,4.9,12.9,7.3C567.6,633.7,571,635.3,574.7,636.5z"
        />
        <path
          class="st513"
          d="M656.3,688.3c1,0.2,1.7,0.9,1.9,1.9c0.8,3.8-1.5,6-4.2,7.8c-7.9,5.4-16.4,9.9-24.7,14.7
			c-29.6,17-59.4,33.7-89.3,50.3c-2.9,1.6-5.6,4.5-9.6,3.1c-5.1,0.1-6-0.5-7.1-5c-0.8-3.1-1.2-6.2-1.7-9.4c-0.2-1.1,0.9-1.8,1.9-1.3
			c0.3,0.1,0.6,0.3,0.8,0.4c4.9,2.9,9.8,2.5,14.6,0c4.7-2.4,9.3-5,13.9-7.5c5.1-2.7,10.2-5.3,15.2-8.1c6-3.3,12-6.6,18-9.9
			c0.1-0.1,0.3-0.2,0.4-0.2c5-2.3,7.7-6,7.3-11.7c-0.2-3.3,2-4.8,4.8-5.7c8.1-2.5,15.9-5.8,23.6-9.3c3.9-1.8,7.9-3.4,11.9-5.1
			c5.3-2.3,10.9-3.6,16.6-4.6C652.5,688.3,654.4,688.4,656.3,688.3z"
        />
        <path
          class="st514"
          d="M530.5,766.1c7.2-4.1,14.5-8.2,21.7-12.2c14.5-8.2,29-16.3,43.5-24.5c14.8-8.3,29.5-16.7,44.2-25.2
			c3.9-2.2,7.7-4.5,11.6-6.8c3-1.8,5.4-4,6.8-7.2c3.9,6.9,2.8,12.7-3.6,17c-4,2.6-8.2,4.9-12.4,7.3c-29.7,17.2-59.6,34-89.7,50.6
			c-3.6,2-7.2,4.1-10.9,6.1c-2.1,1.2-4.3,2.1-6.6,2.7c-4.2,1-8.2,0.5-12.2-0.9c-0.2-2.8,1.4-4.3,3.8-5.3
			C527.9,767.1,529.5,767.3,530.5,766.1z"
        />
        <path
          class="st515"
          d="M504.6,637.4c3.1,3,6.8,5.2,10.7,7c2.7,1.3,5.5,2.6,7.6,4.8c-0.4,1-0.7,2.2-1.3,3c-3.1,4.6-5.9,9.5-10,13.3
			c-4.1,0.4-6.5-2.4-9-4.9c-2.1-4.8-2.9-10.1-3.9-15.2c-0.4-2-1-4-0.9-6.1c0.1-2,1-2.9,3-2.7C502.2,636.7,503.4,637.1,504.6,637.4z"
        />
        <path
          class="st516"
          d="M502.7,660.6c3.9,0,6.4,2.4,9,4.8c0.5,1.8,0,3.4-1,4.8c-1.2,1.8-2.3,3.6-4,5
			C504.1,670.7,502.9,665.8,502.7,660.6z"
        />
        <path
          class="st517"
          d="M508.6,683.4c1,0.4,2,0.8,3,1.2c3,1.2,6,2.5,4.8,6.7c-2.6,3.5-2.2,7.6-2.4,11.6c-2.3-0.4-3-2.1-3-4.2
			c0-0.9,0.3-1.9,0.4-2.8c0.3-2.3-0.1-4.4-1.8-6.1C507.4,687.9,507.2,685.8,508.6,683.4z"
        />
        <path
          class="st518"
          d="M522.8,729.6c1.4-2.2,3.4-3.7,5.7-4.9c1.2-0.7,2.4-0.8,3.6,0c0.4,0.3,0.8,0.7,0.8,1.1
			c0.1,0.9-0.7,1-1.2,0.9c-3.5-0.3-5.6,1.8-7.6,4.1C523.6,730.4,523.2,730,522.8,729.6z"
        />
        <path
          class="st510"
          d="M580.5,837.1c-0.1-1.4,0-2.8,0.8-3.9C581.4,834.6,581.5,836,580.5,837.1z"
        />
        <path
          class="st519"
          d="M469.9,863.6c-0.8,3.7-0.6,7.4-0.9,11.1c-1-1.5-0.9-3.3-0.7-4.9c0.3-2.4-0.5-4.2-2.1-5.8
			c-2.2-6.4-2.2-12.7,0.6-18.9c3.2-7.2,8.1-13.2,13.2-19.1c4.4-5,10.2-7.2,16.7-8c1,0.6,1.2,1.6,1.2,2.6c0.9,3.3-2,4.3-3.8,5.8
			c-9.5,7.9-17.1,17.3-22.4,28.5C470.3,857.7,469.7,860.6,469.9,863.6z"
        />
        <path
          class="st520"
          d="M496.7,818c0.2,1.1,0.4,2.4-1.3,2.4c-6.4,0.2-10.9,3.8-15,8.2c-3.5,3.9-6.6,8.1-9.5,12.5
			c-3.9,5.9-5.7,12.3-4.9,19.5c0.1,1.1,0.1,2.2,0.1,3.3c-2.1-1.2-2.8-3.3-2.9-5.5c-0.1-2.3-0.1-4.6-0.2-7c0-0.7,0-1.3,0-2
			c-0.2-0.4-0.3-0.9-0.4-1.3c-0.4-5.5,3-9.6,5.6-13.9c3.9-6.5,8.7-12.2,15.2-16.4c1.7-1.1,3.4-2.4,5.7-1.8c0.5,0,1,0,1.4-0.1
			C493.1,815.5,495,816.2,496.7,818z"
        />
        <path
          class="st521"
          d="M469.9,863.6c-1-0.8-1-1.9-1.1-3c-0.1-4,1.1-7.5,3.1-10.9c5.7-9.7,12.8-18.3,21.5-25.4
			c1.5-1.2,2.9-2.4,4.4-3.7c1.2,0.5,2.5,0.2,3.8,0.1c1.1,0,2.2,0,3.2,0.7c0.4,0.3,0.7,0.7,0.9,1.1c0.3,0.8-0.4,1.1-1,1.3
			c-1.3,0.6-2.6,1.1-3.9,1.8c-6.2,3-11.4,7.4-15.9,12.7c-3,3.5-5.8,7.2-7.9,11.3c-1.8,1.6-2.8,3.7-3.7,5.8
			C472,858.2,471,860.9,469.9,863.6z"
        />
        <path
          class="st522"
          d="M489.3,816c-0.1,0.1-0.2,0.3-0.3,0.3c-10.6,6.4-17.9,15.8-23.3,26.8c-0.8,1.7-1.7,3.5-2.5,5.2
			c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1-0.2-0.3-0.4-0.4-0.6c-1.1-1-0.8-2.4-1.2-3.6c4.3-13.9,12.6-24.4,25.9-30.8
			C488,814.4,488.8,815,489.3,816z"
        />
        <path
          class="st523"
          d="M480.7,814.2c-1,1.2-2.4,1.2-4.1,1.5c0.6-1.7,2-2,3.1-2.5C480,813.5,480.4,813.8,480.7,814.2z"
        />
        <path
          class="st471"
          d="M462.4,848.4c0.2-0.1,0.5-0.1,0.7-0.1c0,0.4,0,0.8,0,1.2C462.9,849.1,462.7,848.8,462.4,848.4z"
        />
        <path
          class="st524"
          d="M542,249.1c0,0.3,0,0.6-0.1,0.9c-0.1,1-0.5,1.9-1.6,2c-1,0.1-1.9-0.4-2.1-1.5c-0.3-1.2-0.5-2.5-0.5-3.7
			c0-0.8,0.4-1.5,1.3-1.7c0.8-0.2,1.5,0.1,2.1,0.8C541.9,246.9,542,247.9,542,249.1z"
        />
        <path
          class="st525"
          d="M485.6,232.2c-0.1,0.3-0.2,1-0.4,1.5c-0.2,0.4-0.6,0.9-1,0.9c-0.3,0.1-0.9-0.3-1.1-0.6
			c-0.9-1.4-1.5-2.8-1.2-4.5c0.2-0.9,1.4-1.4,2.2-0.9C485.2,229.4,485.6,230.6,485.6,232.2z"
        />
        <path
          class="st496"
          d="M532.3,716.3c-0.2,0-0.3,0-0.5,0c-3.6-0.1-3.6-0.1-3.4-3.8c-0.9-2.1-1.2-4.4-0.6-6.6
			c2.2-9.2,6.3-17.3,12.7-24.3c1.2-1.3,2.3-2.8,4.4-1.9c-0.9,4.4-1.9,8.7-2.8,13.1c-0.3,1.2-0.6,2.5-0.8,3.7c-0.1,0.6,0,1.3,0.7,1.6
			c0.4,0.1,1,0,1.3-0.2c1.2-1,2.4-2.1,3.4-3.3c1.6-1.8,3.2-3.6,4.5-5.6c2.6-4,6.2-5.9,10.9-5.4c0.9,1.3,0.4,2.6-0.6,3.4
			c-7.9,6.5-12.5,15.6-18.2,23.8c-1.8,2.6-4.3,4.2-7,5.6C534.9,717.2,533.6,717.1,532.3,716.3z"
        />
        <path
          class="st526"
          d="M544.9,679.8c-7.8,7.9-13.2,17.1-15.4,28.1c-0.3,1.6-0.7,3.1-1.1,4.7c-2.2-2.1-4.9-3.5-7.8-4.3
			c-1.5-0.5-3-1-4.5-1.5c5.2-2.3,8.7-6.3,11-11.5c0.7-1.6,1.5-3.2,2.4-4.7c2.1-3.8,4.6-7.1,8.1-9.9c5.7-4.6,11.2-9.4,17.4-13.4
			c1.7-1.1,3.5-2.3,5.5-2.7c0.6-0.2,1.3,0,1.7,0.6s0.2,1.2-0.2,1.8c-0.6,0.8-1.2,1.5-1.8,2.2c-1.1,0.3-2.3,0.3-3.2,0.8
			C552.6,672.7,548,675.4,544.9,679.8z"
        />
        <path
          class="st527"
          d="M532.3,716.3c3.8-0.2,6.4-2.5,8.5-5.5c1.7-2.5,3.4-5,5-7.5c3.8-5.9,8.1-11.4,13.2-16.2
			c1.2-1.1,2.4-2.1,3.1-3.6c2.7-1.7,5.2-3.4,8.3-4.3c0.4-0.1,1,0.1,1.3,0.4c0.5,0.5,0.4,1.2-0.1,1.6c-0.8,0.7-1.7,1.3-2.7,1.9
			c-1.5,0.8-3,1.8-3.8,3.4c-6.5,3.7-10,10-13.8,16c-0.2,0.3-0.3,0.6-0.5,0.8c-3.1,4.8-4.8,9.9-4.1,15.7c-0.4-0.3-0.8-0.6-1.1-0.9
			c-2.5-2.4-5.2-2.9-8.2-1.2C535.4,718.1,533.8,718.1,532.3,716.3z"
        />
        <path
          class="st528"
          d="M866.4,656.8c0.4-0.4,0.8-0.8,1.2-1.2c0.8,0.7,1.6,1.4,2.4,2.2c0.3,0.3,0.7,0.8,0.2,1.1
			c-0.3,0.2-0.9,0-1.2-0.1C867.8,658.4,867,657.8,866.4,656.8z"
        />
        <path
          class="st500"
          d="M447.9,445.6c0,1.9,0,3.8,0,5.8C446.6,449.4,446.6,447.5,447.9,445.6z"
        />
        <path
          class="st529"
          d="M589.6,461.4c5.3,0.6,10.4,2,15.4,3.8C599.2,466.5,594.4,463.9,589.6,461.4z"
        />
        <path
          class="st530"
          d="M516.3,691.4c-1.2-2.5-3.2-4.5-4.8-6.7c1.8-0.2,2.7-1.5,3.5-3c0.8-1.4,1.4-2.9,2.2-4.2
			c5.9-9.6,13.6-17.2,24.2-21.5c2.3-1,4.6-2.2,6.9-3.3c0.6-0.3,1.2-0.3,1.8-0.4c0.3,0,0.7,0.2,0.8,0.4s0.1,0.6,0,0.9
			c-0.4,0.9-1.2,1.5-2,2c-1.4,0.8-2.8,1.5-4.2,2.2c-11.4,6-20,14.6-24.4,26.9c-0.6,1.7-1.2,3.3-1.9,4.9
			C518,690.4,517.3,691,516.3,691.4z"
        />
        <path
          class="st531"
          d="M521,918.6c-4.3,1.8-8.8,1-13.2,0.8c-0.4,0-0.8-0.5-1.2-0.7l0,0c4.8-0.1,9.7,0.3,14.5-0.9
			C521,918.1,521,918.3,521,918.6z"
        />
        <path
          class="st531"
          d="M506.5,918.7c-0.3-0.1-0.6-0.2-0.9-0.2C506.1,917.8,506.2,918.6,506.5,918.7L506.5,918.7z"
        />
      </g>
    </g>
    <g id="cloud_gear">
      <path
        class="st532"
        d="M785.7,335.3c-6.2,4-12.5,1.7-17.8-1.1c-23.4-12.5-47.1-24.7-67.8-41.7c-3.1-2.6-5.9-5.5-7.7-9.2
		c-2.6-5-4.8-10.2-7-15.4c0-0.3-0.1-0.7-0.2-1c2-5,3.4-10.4,9.3-12.6c5-1.9,9.7-4.7,15.1-5.8c3-0.6,3.6-0.5,3,2.8
		c-0.8,4.4-1.2,9,0.7,13.4c3,2,6.9,3.3,6,8.2c-0.2,1.2-0.4,2.3-1.1,3.3c-4.4,6-3.3,8.9,3.9,10.2c0.3,0.1,0.6,0.2,1,0.3
		c1.7,0.9,2.5,2.6,3.2,4.2c-0.1,5.6,5.6,6.5,8.2,10c1.5,3.1,4.5,2.4,7.1,2.9c1.5,0.4,2.9,1.2,3.5,2.6c2.2,4.9,4.8,4.6,7.8,0.7
		c0.4-0.5,1.2-0.6,1.8-0.7c3-0.4,4.1,4.5,7.5,2.6c0.2-3.5-2-7,0.4-10.4c0.3-0.6,0.8-1.1,1.4-1.6c3.8-1.9,5-4.8,3.5-8.8
		c-0.2-0.4-0.4-0.8-0.5-1.2c-3-2.4-3.9-5.4-2.4-9c0.3-1.4,1.1-2.5,1.5-3.8c1.1-3.6,3.7-2.2,6-2c-0.3-1.2-2-2.6-0.5-4.1
		c0.8-0.9,1.8,0,2.7,0.5c1.9,2.1,3,4.7,3.7,7.4c0.8,3.5,2,6.8,2.1,10.4c0.1,4.5,4.9,5.3,6.6,8.6c3.2,4.2,4.3,9.1,5.3,14.1
		c0.5,3,1.4,5.8,1.4,8.8c-0.5,4-1.2,8-2.7,11.9C789.5,332.1,788.2,334.2,785.7,335.3z"
      />
      <path
        class="st533"
        d="M773.5,268.8c-0.8-0.1-2.2-1.1-1.6,0.9c0.6,1.8,1.6,3.5,2.7,5.9c-2.9-1-5.2-6.1-7.4-0.4
		c-3.1-1.3-3-5-5.4-6.9c-0.2-0.4-0.5-0.8-0.7-1.2c-1.4-2.9-4.4-4.5-5.8-7.4c-1.9-2.4-1.5-6.7-5.9-7c-4-0.4-5.9-3.3-7.5-6.5
		c-2.2-3.4-5.8-4.1-9.3-5c-2.3-0.4-4.3,1.5-6.6,1.3c-5.2,0.4-5.1,0.4-3.9,5.3c0.5,2,1.2,3.9,1.3,6c0,2.3-0.8,4.1-3,5.2
		c-0.3,0.1-0.7,0.1-1,0c-5.4-1.8-5.4-1.8-6.2,4.1c-0.2,1-0.4,2-1.2,2.7c-1.6-5.6-1.8-11.2-0.4-16.4c-5.8,1-11.5,4.4-17.8,6.3
		c-1.4,0.4-2.6,1.3-3,2.5c-1,3.4-3.3,6-5.5,8.6c-3.5-6.5-1.9-13.6-2-20.5c1.6-4.9,5.7-6.8,10.2-8c1.5-0.4,3-0.4,4.6-0.5
		c2.2-0.1,3.8-1.2,3.7-3.4c-0.1-3.2,1.9-4.9,3.8-6.9c3.5-7.5,9.4-11.5,17.7-12.3c1.3,0,2.6,0,4,0.2c2.7,0.8,5.7,0.9,7.7,3.2
		c4.5,3.9,8.8,8.2,14.4,10.7c4.9,3.4,7,8.6,9.7,13.5c1.8,6,3.5,12,9.3,15.8c2.2,1.4,3.3,4.3,4.8,6.5
		C773.8,266.4,774.5,267.5,773.5,268.8z"
      />
      <path
        class="st534"
        d="M785.8,295.3c-8.4-4-7.2-12.4-9.1-19.4c12.4,1.8,18.7-6.8,25.4-14.8c0.6-0.7,1.3-1.4,2.2-1.7
		c1.5,1.5,0.7,3.4,0.9,5.2c-1.1,4.7-0.9,8.8,4.4,11.1c1.6,0.7,2.9,2.3,2.4,4.5C802.6,284.1,794.5,290.3,785.8,295.3z"
      />
      <path
        class="st535"
        d="M785.7,335.3c4.2-5,5.3-11.1,6.2-17.3c8.5-3.9,16.2-9,24-14c2.5-0.4,1.8-4.4,4.7-4.4
		c0.5,5.8-0.5,11.3-3.6,16.4c-0.9,1.5-1.5,3-3.5,3.2c-4.4,2.2-8.8,4.5-12.3,8.2C796.6,331,791.6,334,785.7,335.3z"
      />
      <path
        class="st536"
        d="M820.6,299.5c-1.8,1.4-0.4,5.2-4.1,5.1c-0.2-3.8,2-7.7-0.1-11.5c-0.4-4.5-4-7.8-5-12
		c0.4-2.2-1.1-3.6-2.6-4.5c-5-3.2-5.8-5.5-3.5-11.8c-0.5,4.3,1.2,7,4.7,9.8c5.8,4.5,8.5,11.3,9.5,18.5
		C819.8,295.2,820.2,297.3,820.6,299.5z"
      />
      <path
        class="st537"
        d="M723.3,216.5c-8,0.4-13.6,4.6-17.5,11.3c-0.3-0.2-0.4-0.4-0.3-0.7c0.1-0.2,0.2-0.4,0.2-0.4
		c1.7-5.1,5.4-8.2,10.1-10.7c6.9-3.7,13.6-7.6,20.7-10.7c1.1-0.7,1.8-0.6,2.3,0.7c-2.9,3.4-7.2,4.6-10.9,6.9
		C726.3,213.9,724.4,214.6,723.3,216.5z"
      />
      <path
        class="st538"
        d="M705.8,226.7c0,0.4,0.1,0.7,0.1,1.1c-1.1,2.1-3.1,3.5-2.6,6.6c0.4,2.6-1.3,5.2-5,4.6c-1.5-0.2-3,0-4.6,0.1
		c-2.5,1-2.5-0.3-2.1-2.1C696.1,233.4,702,231.5,705.8,226.7z"
      />
      <path
        class="st539"
        d="M738.7,206.3c-0.5-0.8-1.2-1-2-1c10.4-5.3,24.9-4,30.8,4c-0.2,0.2-0.4,0.3-0.6,0.5c-3.7,0.6-6.4-1.9-9.6-3
		c-4.7-1.7-9.2-2.4-13.9-0.3C741.8,207.3,740.1,207.8,738.7,206.3z"
      />
      <path
        class="st540"
        d="M783.7,227.8c0.1,0.1,0.4,0.2,0.4,0.3c2.2,7.6,8.5,12.2,13.7,17.4c1.3,1.4,2.6,2.7,2.3,4.8
		c-0.2,0.1-0.5,0.2-0.7,0.3c-1.9,0-3-1.3-4-2.8c-0.7-1.1-1.5-2.5-2.7-2.9c-5.7-2-8.4-6.4-9.7-11.9c-0.5-1.6-0.6-3.1,0.1-4.6
		C783.3,228.2,783.5,228,783.7,227.8z"
      />
      <path
        class="st541"
        d="M767.4,209.4c8.1,3.8,13.5,10,16.3,18.5l0,0c-1.5,0.7-2.8,0-3.5-1.2c-3.4-5.5-8-10.1-12.4-14.8
		c-0.6-0.6-0.8-1.5-0.5-2.4L767.4,209.4z"
      />
      <path
        class="st542"
        d="M801.2,327.3c-2.2-3,1.5-1.9,2.1-2.9c1.9-3.3,6-3.9,8.4-6.7c1.5-1.7,2.2-0.5,1.7,1.4
		C810.6,323.6,805.5,324.8,801.2,327.3z"
      />
      <path
        class="st543"
        d="M691.5,237c0.9,0.5,0.3,2.6,2.1,2.1c-3.8,1.9-8.2,2.9-10.3,7.2C683.9,241.3,687.6,239.1,691.5,237z"
      />
      <path
        class="st533"
        d="M685.5,267.8c3.1,4.8,5.5,9.9,7,15.4C688.8,278.6,686.5,273.5,685.5,267.8z"
      />
      <path
        class="st544"
        d="M804.3,259.5c-0.1,0.9-0.3,1.7-1.1,2.1c-2.3-2.9-4.2-6-3.9-9.9c0.2-0.5,0.5-0.9,0.9-1.3l0,0
		C803.3,252.6,803.6,256.1,804.3,259.5z"
      />
      <path
        class="st545"
        d="M800,251.4c-0.2,3.8,3.1,6.5,3.1,10.2c-4,7.9-11.6,11.5-18.6,16c-3.2,2.1-5.3-0.7-7.8-1.6
		c-1.1-2.4-2.2-4.8-3.2-7.2c-0.4-1-0.7-2-1.1-3c-0.3-1.1-0.3-2.3-0.9-3.4c-1.8-3.4-1.3-6.1,2.2-8.1c1.6-0.9,2.9-2.4,4.3-3.6
		c6-5.3,12.9-5.6,19.5-0.9C798.2,250.4,799.2,250.8,800,251.4z"
      />
      <path
        class="st546"
        d="M762.3,267.6c2.9,1.7,3.4,4.9,4.9,7.5c-0.7,0.9-1.3,1.8-2,2.7c-2.6,2.5-2.5,5.4-1.1,8.4
		c0.2,5,2.8,0.4,4.2,1.1c2.4,5.8,1.5,8.1-4,10.2c-1.7,1.2-2.5,0-3-1.3c-1.2-3.8-3.9-7-4.6-11.1c-0.5-3-2.6-5-4.6-7
		c-0.4-0.3-0.7-0.7-0.9-1.1c-3-8.3-7.8-15.3-15.2-20.3c-0.5-2.8-2.5-4.9-3.6-7.4c-0.6-1.9-1.3-3.8-1.6-5.8c-0.2-1.4-0.8-3.1,1.6-3.3
		c3.9,0.8,8.1,1.1,10.3,5.2c0.2,0.3,0.4,0.6,0.4,0.9c-0.7,6.1,0.6,7.1,6.8,5.3c5.5,0.1,4.3,5.2,6.1,8.1c1,2.5-3,3.4-1.8,5.9
		C756.3,268.7,759.9,265.8,762.3,267.6z"
      />
      <path
        class="st547"
        d="M811.3,281.1c3.3,3.1,5.3,6.9,6.2,11.3c0.9,5.8,0.4,7-4.6,9c-5.7,2.2-11.6,3.6-16.5,7.7
		c-1.6,1.4-3.8,1.2-5.7-0.2c-1.1-4.7-2.7-9.2-4.9-13.5c3-3.8,7.1-6.2,11.2-8.5c3.1-1.7,6.4-2.8,8.8-5.7
		C807.4,279.2,809.5,280.1,811.3,281.1z"
      />
      <path
        class="st548"
        d="M712.1,265.7c0-1.8-0.1-3.7,0-5.5c0.1-1.5-0.8-3.6,1.2-4.4c2.1-0.9,3.9,0,5.3,1.6c0.4,0.5,1.1,0.8,1.6,1.1
		c1.6-0.2,2.6-1.6,3.9-2.3c4.1-2.4,7.7-2.4,11.2,1c3.7,3.6,9.2,5.3,11.8,10.2c1.1,2,2.5,4,2.1,6.5c-3.2,1.5-3.7-1.6-5.2-3.1
		c-1.9-3.2-4.8-5.2-8-6.7c-5-1.5-7.7,0.3-8.2,5.3c-0.2,2.3,0.5,4.8-1.4,6.7c-2.4-0.4-1.9-2.5-2.4-3.9c-0.4-1.1-0.5-2.7-2.1-2.2
		c-1.4,0.4-1.2,1.9-1,3c0.5,2.5,1.8,4.7,2.2,7.3c0.3,2.3,1.4,4.6,0.3,7c-8.7-0.3-6.6,1.2-9.5-7.8c-0.4-1.3-0.5-2.2,0.7-3.1
		c5.7-4.3,5.4-6.9-1.1-10C713,266.2,712.5,265.9,712.1,265.7z"
      />
      <path
        class="st549"
        d="M790.7,308.8c3,0.2,5.4-0.6,7.7-2.9c3.7-3.8,9.2-4.1,13.9-5.7c4.1-1.4,5.8-3.7,5.2-7.9
		c1.9,4.3,1.7,8.4-1,12.3c-7.8,5.1-14.8,11.9-24.6,13.3C791.5,314.9,791.1,311.8,790.7,308.8z"
      />
      <path
        class="st550"
        d="M755,306.7c-1.2,1.1-2.8,2-3.6,3.4c-2.4,4.2-4,2.1-5.6-0.5c-1.1-1.9-1.9-4-4-5c0-0.4-1.2-7.5-1.4-8
		c1.1-1.6,3.3-1.6,4.5-3.1c0.9-0.8,1.7-1.6,3-1.4c1.9,2.3,1.7-0.4,2.4-1c1.5-5-0.5-9.9-0.7-14.8c1.2-1.3,1.8,0,2.5,0.6l0,0
		c3.6,4.5,2.6,9.5,1.5,14.5c2.5-1.6,3.1-1.4,3.7,1.2c0.7,3.1-0.6,6-0.7,9C756.5,303.5,756.5,305.3,755,306.7z"
      />
      <path
        class="st551"
        d="M723.3,287.3c-1.3-4.8-2.9-9.6-3.9-14.5c-0.4-1.8-1.3-4.8,2.4-5c3.1-0.1,3.6,1.9,3.6,4.4
		c0,1.3-0.3,2.8,1,3.8c2.3,0.8,2.4,3.4,3.8,4.9c1.3,3.4,4.7,5.5,5.6,9.1c0.3,2.8-0.7,3.6-3.1,1.8c-1-0.8-1.3-2.2-2.2-3
		c0.7,0.8,1.1,1.7,1.3,2.7c0.2,1.4,1.1,3.2-0.7,4.1s-2.7-0.9-3.6-2c-0.6-0.8-1.4-1.5-2.1-2.2C724.7,290,724,288.6,723.3,287.3z"
      />
      <path
        class="st552"
        d="M755.1,301.6c0.3-1.8,1.1-3.7,0.8-5.4c-0.3-1.7,2.5-5.5-2.5-5c0,0-0.3-1.8-0.4-2.7c-0.1-3.9,0.3-7.8-1-11.5
		c3.5,1.8,5.4,4.6,6.2,8.5c0.9,4.5,3.3,8.3,6.1,12c-0.3,0.3-0.7,0.7-1,1c-1.2,1.3-2.8,2-4.5,2.5
		C757.5,300.9,756.4,302.1,755.1,301.6z"
      />
      <path
        class="st553"
        d="M725.3,291.3c2.6-0.1,3.2,2.6,5.5,3.9c0.3-3.1-0.9-5.6-1.1-8.7c1.3,1.7,2.4,3.8,4.1,4.9
		c2,1.3,0.5-0.9,0.8-1.3c0.7-0.5,1.4-0.6,2.2-0.5c2.5,2.4,5.9,3.3,8.9,4.7c-1.3,1.7-3.1,2.1-5.1,2.1c-3.2,0.5-5.2,2.6-7.1,5
		c-0.8-0.8-1.5-2.4-2.4-2.4C726,298.8,726.2,294.7,725.3,291.3z"
      />
      <path
        class="st554"
        d="M733.6,301.5c0.1-0.1,0.4-0.2,0.4-0.3c-1.2-6.4,3-5.3,6.7-4.7c0,0.2,0,0.4,0.1,0.5c4.8,2,5,4.6,0.9,7.7
		C738.7,304.3,735,306,733.6,301.5z"
      />
      <path
        class="st555"
        d="M755.1,301.6c1.1-0.3,1.3-3.2,3.2-1c2.2,2.8,4.5,5.5,5,9.2c-0.8,1.9-1.9,2.8-3.7,1c-1.4-1.4-3-2.7-4.4-4
		C755,305,755,303.3,755.1,301.6z"
      />
      <path
        class="st556"
        d="M763.2,309.7c-1.9-2.9-4.4-5.5-5-9.2c1.5-1,2.9-2.5,5-2.1C761,302.2,764.9,306,763.2,309.7z"
      />
      <path
        class="st557"
        d="M763.2,286.2c-2-3.4-3.5-6.7,1.9-8.4c-0.3,3.3-0.7,6.7,3.2,8.5C766.6,287.3,764.9,287.6,763.2,286.2z"
      />
      <path
        class="st558"
        d="M763.2,286.2c1.7,0,3.4,0,5.1,0.1c0,0.3,0,0.7-0.1,1c-0.5,1.2-1.1,2.3-1.7,3.7
		C764.9,289.7,762,289.5,763.2,286.2z"
      />
      <path
        class="st559"
        d="M767.3,209.5c4.7,5.5,10.5,10.2,13.9,16.8c0.4,0.8,1.7,1.1,2.5,1.6c0,1.7,0,3.4,0,5.1c1.4,2.6-0.5,4-2.2,4.9
		c-4.6,2.5-9.4,4.6-14.2,6.5c-3,1.1-6.3,1.1-9.2-1.1c-2.7-4.7-5.1-9.6-9.3-13.2c-4.8-3.4-9.3-7.2-14.2-10.6c-2.1-1.8-5-1.5-7.3-2.9
		c-2.3-1.3-1.1-2.6,0-4c3.3-2.9,7.2-4.8,11.3-6.2c2.6,0.9,4.3-1.5,6.5-2.1c6.3-1.7,11.7,0.7,17,3.2
		C763.8,208.3,765.5,209,767.3,209.5z"
      />
      <path
        class="st560"
        d="M758.1,243.2c8.8,2.2,15.3-3.8,22.8-6.3c1.6-0.6,2.8-2,2.8-3.9c1.5,6.2,6.7,8.9,11.3,12.2
		c1.9,1.3,2.2,4.5,5,5.2c0,0.3,0,0.7,0,1c-1.1-0.4-2.3-0.6-3.3-1.2c-6.6-4-12.9-3.4-18.5,2.1c-0.8,0.8-1.6,2-2.6,2.4
		c-4.2,1.8-5.3,4.3-2.7,8.3c0.7,1-0.3,1.9-0.6,2.9c-2.9-3.7-6-7.2-9.5-10.5C759.8,252.4,759.4,247.4,758.1,243.2z"
      />
      <path
        class="st561"
        d="M733.5,249.2c1.5,2.2,3.9,4,3.2,7.2c-4.7-1.4-9.6-1.8-14.3-3.1c-0.7-2.4-1.1-5-2.1-7.3
		c-1.8-4.3-0.2-5.5,3.9-5c0.7,0.1,1.3,0,2,0.1c1.2,0.8,2.1,1.9,2.1,3.3C728.4,248,730.5,249,733.5,249.2z"
      />
      <path
        class="st552"
        d="M722.4,253.3c1.9-0.7,3.6-2.1,5.5,0.2c0.9,1,2.6,1.3,3.8,0.8c2.5-1.1,3.6,0.6,4.9,2.1
		c8.8,4.1,12.7,11.8,15.4,20.5c-0.7-0.3-1.4-0.6-2.1-1c-1.5,0.1-1.6-0.7-1.1-1.9c-0.4-4.8-3-8-7-10.5c-2.3-1.4-4.6-3-6.4-4.9
		c-3.6-3.7-7.2-3.8-11.2-0.9c-1.1,0.8-2.2,2.5-3.9,0.7C721.2,256.9,722.6,255.5,722.4,253.3z"
      />
      <path
        class="st562"
        d="M733.5,249.2c-3.5,1.9-6.2,2.3-6.2-3.1c0-1.6-0.7-3.3-1.1-4.9c2.3,1.1,3.8-2.8,6.2-1.1
		C730.6,243.4,734.1,246.1,733.5,249.2z"
      />
      <path
        class="st563"
        d="M750,251.4c-2.3,3.2-5.5,2.7-8,0.9c-3.5-2.5,1.1-4.6,0.8-7.1C744.6,248.1,745.7,251.7,750,251.4z"
      />
      <path
        class="st564"
        d="M753,265.8c-2-3.6,2.8-3.7,3.2-6.2c1.9,2.5,5.1,3.9,6.1,7.1C758.9,268.5,756.1,265.8,753,265.8z"
      />
      <path
        class="st565"
        d="M734.7,219.3c5.8,2.1,10.3,5.9,14.2,10.6c-0.9,1.1-1.7,0.6-2.5-0.1c-3.3-3.1-6.7-6.2-10.8-8.3
		C734.7,221.1,734.7,220.2,734.7,219.3z"
      />
      <path
        class="st566"
        d="M753,265.8c1.5-1,2.9-2.4,4.6-0.2c1.2,1.5,3,1.1,4.7,1.1c0,0.3,0,0.6,0.1,0.9
		C759,267.8,755.2,270.7,753,265.8z"
      />
      <path
        class="st567"
        d="M727.4,212.5c0.1,1.3-1,2.6,0,4c-1.3,0-2.7,0-4.1,0C722.2,212.6,725.5,213.3,727.4,212.5z"
      />
      <path
        class="st568"
        d="M736.8,263.6c-10.3,2.7-9,10.2-7.5,17.8c-2-1.3-1.9-3.6-3-5.4c0.5-4.4-1.7-9.3,3-12.8
		C732,261.2,734.5,260.6,736.8,263.6z"
      />
    </g>
    <g id="cloud_yt">
      <path
        class="st569"
        d="M685,88.2c4.8-2.3,6.1-1.7,11.5-6.2c1.3-1.1,4.4-3.3,7.5-4c3.7-0.8,8-2.4,10.5-1.7
		c15.3,4.3,26.7,17,27.8,18.2c8.9,10.3-0.9,18.2,9,30.2c4,4.9,1.3,5.4,4.9,10.6c2.3,3.3,8.3,10,6.6,22.7
		c-1.4,10.4-4.8,13.1-6.6,14.9c-8.9,9-13.9,8.7-17.5,8.9c-10.2,0.6-16.6-6.8-29-13.4c-6.6-3.5-15.9-6.2-27.5-18.6
		c-7-7.5-12.2-12.3-15.4-24.3c-1.5-5.4-3.7-13.3,2.5-20.4c4-4.6,6.1-3.9,7.6-8.8c1.4-4.6,0.3-0.9,2.9-4.6
		C680.6,90.5,683.6,88.9,685,88.2z"
      />
      <g>
        <path
          class="st570"
          d="M734.2,181.8c-0.5-1.1-0.1-1.5,1.1-1.5c2.1,0.1,4.2,0,6.2,0c1.5,0.4,3.1-1.5,4.6,0.1c-0.5,0.5-1,1-1.5,1.4
			C741.2,181.8,737.7,181.8,734.2,181.8z"
        />
        <path
          class="st571"
          d="M694,149.1c-8.3-5.3-10.9-13.1-10.3-22.5c0.3-4.4,0-8.9,0-13.3c0-1.6,0.1-3.2,1.3-4.5
			c1.7-2.5,3.7-2.6,6.3-1.1c6.8,3.9,13.3,8,20,11.9c4.2,2.4,8.3,4.8,13.1,5.6c0.3,0.4,0.6,0.8,0.9,1.3c2.4,3.4,2.4,7.4,2.4,11.3
			c0,6.8,0,13.5,0,20.3c0,2.3-1,4.1-2.7,5.4c-2,1.5-4.1,1.2-6.2,0.1c-3.7-2-7.4-4-10.9-6.2c-2.2-1.2-4.3-2.3-6.6-3.3
			C699,152.2,696,151.4,694,149.1z"
        />
        <path
          class="st572"
          d="M746.2,180.3c-1.5-0.4-3.1,1.5-4.6-0.1c5.8-3.5,6.9-9.3,7.5-15.3c0.6-6.6-1.1-12.7-3-18.9
			c-0.2-1.3,0.9-1.6,1.7-2.1c3.8-2,7.3-4.4,11.1-6.5c4.9-2.7,5.2-2.6,7.4,2.5c2.8,7.2,2.9,14.7,1.7,22.2c-0.7,4.4-3,7.9-6.8,10.1
			C756.2,175.1,751.2,177.7,746.2,180.3z"
        />
        <path
          class="st573"
          d="M738.7,135.6c-1.3-5.6-2.7-11.2-5.3-16.3c-0.7-2.7,1.3-3.8,3-4.9c2.9-1.9,5.9-3.6,8.9-5.3
			c3.7-2.1,4.1-1.9,6,2c1.4,4.5,2.5,9.1,4.5,13.4c1,1.4,0,2.4-1,3.1c-4.7,3.1-9.7,5.8-14.7,8.3C739.7,136.1,739.1,136,738.7,135.6z"
        />
        <path
          class="st574"
          d="M738.7,135.6c5.1-2.8,10.3-5.6,15.4-8.5c1-0.6,1.8-1.4,1.7-2.7c5.3,4,8.8,9.1,10.5,15.6
			c-1.7-3.8-3.8-4.1-7.3-1.7c-4.1,2.9-8.6,5.1-13,7.7C743.6,142.6,741.8,138.7,738.7,135.6z"
        />
        <path
          class="st575"
          d="M720.8,101.4c-1-0.9-2.1-1.7-3.1-2.6c-3.5-3.1-3.5-4.4-0.3-7.7c0.4-0.4,0.9-0.8,1.3-1.3
			c2.1-2.9,3.7-6.5,8.7-4.7c-3.9-5.6-9.8-6-15.1-7.3c-3-0.7-6.1,1-8.8,2.5c-3.6,1.9-7,4.2-10.5,6.2c-0.5,0.3-1.2,0.1-1.8,0.1l0,0
			c1.2-1.2,3-2.9,5.4-4.5c2.6-1.8,7.9-5.3,15.3-5.7c9.8-0.5,17.1,5.2,19.9,7.3c1.2,0.9,2.2,1.9,3.3,2.9c1,2,0,3.3-1.6,4.3
			C728.5,93.6,724.4,97.1,720.8,101.4z"
        />
        <path
          class="st576"
          d="M751.3,111.1c-1.5-2.3-2.8-3.5-5.6-1.1c-2.8,2.3-6.4,3.6-9.5,5.6c-1.3,0.8-2.7,1.9-2.8,3.7
			c-1.5-2.7-3-5.4-4.5-8.2c-0.3-1.9,1.1-2.7,2.4-3.3c3.1-1.5,5.9-3.4,8.8-5.4c2.8-1.9,5.1-2.1,7.5,0.4
			C748.6,105.7,751.1,107.9,751.3,111.1z"
        />
        <path
          class="st577"
          d="M720.8,101.4c2-5.5,6.9-8,11.3-10.8c1.7-1.1,2.8-2.1,2.8-4.1c3.8,2.8,6.5,6.4,8.9,10.5
			c-6.9-0.4-12,3.1-16.9,7.4c-0.7,0.6-1.5,0.8-2.4,0.7C723,104.2,721.8,102.9,720.8,101.4z"
        />
        <path
          class="st578"
          d="M724.6,105.1c3.4-2.6,6.5-5.6,10.4-7.4c2.9-1.4,5.8-2.5,8.9-0.7c2.3,1.3,2.6,3.9,3.8,5.9
			c-3-1.8-5.6-1.1-8,1c-3.2,2.9-7.9,3.6-10.6,7.2C727.5,109.1,726,107.1,724.6,105.1z"
        />
        <path
          class="st579"
          d="M724.5,125.2c-5.2,1.1-8.9-2.1-12.9-4.4c-7-4.1-14.2-7.9-20.9-12.4c-2.5-1.6-3.8-0.6-5.7,0.4
			c2.3-3.7,5-4.7,8.6-2.7c8.6,4.8,17.3,9.7,25.6,14.9C721,122.1,723.4,123,724.5,125.2z"
        />
        <path
          class="st580"
          d="M724.5,163.1c0.7-1.4,2.5-2.2,2.4-4.1c0.5-8.1,0.2-16.2,0.2-24.3c0-1.7-0.3-3.4,0.6-4.9
			c1.1,1,1.3,2.3,1.3,3.7c0.2,9.4-0.2,18.8,0.6,28.1c0.2,2.3,0.5,5.2-1.8,6.4c-2.5,1.3-5,0-7-1.9
			C721.1,163.9,723.9,164.9,724.5,163.1z"
        />
        <path
          class="st581"
          d="M682.2,91c-3,3-5.1,6.6-7.1,10.4c-0.2,0.4-0.5,1.1-0.9,1.1c-4.4,0.4-6.1,3.5-7.2,7.2c0,0.1-0.4,0.2-1.2,0.4
			c0-3.5,1.2-6.5,4.1-7.6c3.4-1.3,4.9-4,6.4-6.7c1.4-2.5,3.2-4.4,5.8-5.5c0.2,0.2,0.3,0.3,0.2,0.5C682.3,90.9,682.2,91,682.2,91z"
        />
        <path
          class="st582"
          d="M676.2,143.8c-5.2-6.8-9-14.3-10.5-23.1c1.1,1.2,4.1,6.2,4.3,8.1c0.5,3.6,2.2,6.4,4.5,9
			c1.2,1.4,1.8,3.2,1.6,5.2c0.2,0.2,0.2,0.3,0.2,0.5S676.3,143.8,676.2,143.8z"
        />
        <path
          class="st583"
          d="M724.5,163.1c0.2,2.7-2.7,1.7-3.7,3c-4.7-2.4-9.5-4.4-13.4-8.1c-0.2-0.7,0.1-1.1,0.7-1.3
			c3.5,1.9,7.1,3.7,10.5,5.7C720.6,163.6,722.5,164,724.5,163.1z"
        />
        <path
          class="st584"
          d="M676.2,143.8c0-0.2,0-0.5,0-0.8c2.3,0.8,3.7,2.8,5.1,4.5c2.5,3.2,6.3,5.2,8.3,8.9l0,0
			C683.8,153.6,680,148.7,676.2,143.8z"
        />
        <path
          class="st585"
          d="M727.7,129.8c0,8.2,0,16.4-0.1,24.6c0,1.5,0.5,3.2-0.7,4.6c-0.2-8.7-0.6-17.4-0.4-26.2
			c0.1-2.4-1-4.2-1.2-6.4C726.7,127.2,726.8,128.8,727.7,129.8z"
        />
        <path
          class="st586"
          d="M689.6,156.5c5.3,0.7,9,4,12.6,7.4l0,0C697.4,162.4,693.4,159.6,689.6,156.5L689.6,156.5z"
        />
        <path
          class="st587"
          d="M682.2,91c0-0.2,0-0.5,0-0.8c2.6-2.1,5.3-3.9,8.9-3.7l0,0c-0.2,2.2-1.4,3.4-3.6,2.9
			C685.3,88.9,684.2,91.2,682.2,91z"
        />
        <path
          class="st588"
          d="M708.1,156.8c-0.2,0.4-0.5,0.8-0.7,1.3c-2.9-0.5-5.1-2.3-7.5-3.8c0-1.2,0.7-1.2,1.5-0.8
			C704.2,153.8,706.2,155.2,708.1,156.8z"
        />
        <path
          class="st589"
          d="M701.5,153.4c-0.6,0.1-1.2,0.2-1.5,0.8c-2-1.7-5.8-1.4-6-5.2C696.8,150,699.4,151.4,701.5,153.4z"
        />
        <path
          class="st590"
          d="M709.7,168.4c4,0.7,7,3.1,9.8,5.8C716.1,172.4,712.3,171.5,709.7,168.4L709.7,168.4z"
        />
        <path
          class="st591"
          d="M702.3,163.9c3.4-0.1,5.8,1.6,7.4,4.5l0,0C706.6,168,704.5,165.8,702.3,163.9
			C702.2,163.9,702.3,163.9,702.3,163.9z"
        />
        <path
          class="st592"
          d="M723,176.2c2.5-0.4,4.2,1,6,2.4C726.4,179,724.8,177.4,723,176.2z"
        />
        <path
          class="st593"
          d="M703,128.1c2.2,2.4,4.5,4.8,6.7,7.3c1.3,1.5,0.8,2.4-1,2.9c-0.4,0.1-0.7,0.2-1.1,0.3
			c-8.2,1.5-8.2,1.5-7.7-8C699.7,127.3,700.3,126.9,703,128.1z"
        />
        <path
          class="st594"
          d="M703,128.1c-2.3-0.8-3,0.4-3.1,2.4c-0.2-0.8-0.4-1.6-0.5-2.4c-0.1-1,0-2.1,0-3.4
			C701.4,125.3,702.3,126.6,703,128.1z"
        />
        <path class="st575" d="M692,90.2" />
      </g>
    </g>
    <path
      class="st456"
      d="M816.7,749.2c-21.6,16-21.8,24.4-36.9,29.5c-5.1,1.7-13.8,4.7-21.3,0.8c-5.9-3-5.2-7.2-13.1-13.1
	c-8.1-6-13-4.9-15.6-9.8c-3-5.8,0.9-13.2,1.6-14.8c6.3-12.2,19.1-11.2,49.2-23c22.2-8.7,26.8-13.8,35.2-10.7
	c10.8,4,18.6,18.1,15.6,27.9C829.9,741.1,826.4,742,816.7,749.2z"
    />
    <g>
      <path
        class="st595"
        d="M777,661.7c0,0.1,0,0.2,0,0.3c0,0.4-0.3,0.7-0.2,1.1c-0.3,0.6-1,0.7-1.5,1c-1.8,1.1-3.6,2.2-5.4,3.3
		c-0.9,0.5-1.7,1.1-2.6,1.7c-0.5,0.4-1.1,0.7-1.6,1.1c-0.7,0.6-1.1,1.3-0.8,2.3c0.1,0.6,0.4,1.1,1,1.4c2.4,1.8,5,3.2,7.5,4.8
		c1.1,0.7,2.2,1.5,3.2,2.2c0.6,0.5,1.1,0.9,1.9,0.9c1.1,0.1,1.7-0.8,1.4-1.7c-0.2-0.5-0.6-0.4-1-0.4c-0.2,0-0.4-0.1-0.5-0.2
		c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.7,0,1.4-0.2,2-0.5c1.2-0.6,2.2-1.3,3.3-2c2-1.3,4.3-2.4,6.2-3.8
		c0.5-0.4,0.9-0.8,1-1.4c0-0.1,0.1-0.1,0.2-0.2c0.3,0,0.4,0.2,0.4,0.4c0,0.5,0.4,0.7,0.8,0.9c0.2,0.3,0.6,0.4,0.9,0.4
		c0.2,0,0.5,0.1,0.5,0.4c0,0.3-0.2,0.4-0.5,0.5s-0.8,0.2-0.9,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-2.7,1.7-5.4,3.3-8.1,5
		c-0.5,0.3-0.9,0.6-1.4,0.9c-0.6,0.6-1.5,1.1-1.4,2.2c-0.1,1,0.7,1.5,1.4,2c0.3,0.2,0.6,0.4,0.9,0.7c0.2,0.1,0.4,0.2,0.3,0.5
		c-0.3,0.2-0.6,0.2-0.9,0.2c-0.8-0.1-1.2-0.7-1.8-1.1c-0.8-0.5-1.6-1.1-2.6-1c-1-0.1-1.8,0.2-2.6,0.7c-3.1,1.8-6.1,3.6-9.1,5.5
		c-0.4,0.3-0.7,0.5-1.1,0.8c-1,0.7-1.3,1.7-0.9,2.9c0.2,0.7,0.9,1,1.5,1.5c1.2,0.9,2.5,1.5,3.7,2.3c1.7,1.1,3.5,2,5.1,3.2
		c0.7,0.6,1.4,1.2,2.2,1.6c0.6,0.5,1.3,0.2,2,0.5c0.3,0.3,0.5,0.7,0.2,1.1c-0.5,0.8-0.5,1.7-0.5,2.5c-0.1,1.3-0.2,2.7-0.1,4
		c0.1,1.4-0.1,2.7-0.2,4.1c0,1.1,0.2,2.2,0.1,3.2c-0.1,0.6,0.4,1,0.8,1.3c0.2,0,0.4,0,0.6-0.1c0.4-0.1,0.7-0.3,1-0.5
		c1.3-0.7,2.5-1.4,3.7-2.2c2-1.3,4.1-2.4,6.1-3.7c0.6-0.4,1.4-0.7,1.6-1.5c0-0.2,0.1-0.3,0.2-0.5c0.2-1.3,0.1-2.6,0.1-3.8
		c0-3.1,0-6.1,0-9.2c0-0.5,0.1-0.9-0.2-1.3c-0.1-0.2-0.3-0.2-0.4-0.3c-0.2-0.2-0.6-0.4-0.5-0.8c0.1-0.2,0.3-0.2,0.5-0.3
		c0.9-0.2,1.1-0.7,0.8-1.5c-0.1-0.3-0.2-0.5,0-0.8c0.5-0.2,1,0.4,1.5-0.1c0.7,0.1,1.4-0.1,2.1-0.1c0,0.8-0.3,1.4-0.6,2
		c-0.2,0.4-0.3,0.7-0.3,1.2c0.1,2.9-0.2,5.8-0.2,8.7c0,1.3,0.2,2.5,0.2,3.8c0,0.7,1,1.4,1.6,1.3c0.7-0.1,1.2-0.3,1.8-0.8
		c0.5-0.4,0.9-0.8,1.4,0.2c0-1.5,1.1-1.7,1.9-2.2c1-0.6,2.1-1.2,3.1-1.8c0.3-0.2,0.7-0.4,1.1-0.4c0.1,0,0,0.1,0.1,0
		c0.6-1,2-1,2.7-1.9c0.7-0.8,0.8-0.8,1.3,0.1c0.1,0.2,0.3,0.3,0.5,0.5c0.4,0.5,0.6,1.1,0.6,1.7c0.1,3.2,0,6.4,0,9.6
		c0,1.8,0,3.5,0,5.3c0,0.9-0.2,1.7-0.7,2.4c-0.3,0.3-0.3,0.6,0,0.9c0.7,1,0.9,2.2,0.8,3.4c-0.2,2.8,0,5.6-0.1,8.4c0,1.7,0,3.3,0,5
		c0,1.4-0.6,2.5-1.9,3.3c-3.2,2-6.4,4-9.6,6c-1.4,0.9-2.8,1.2-4.4,0.6c-0.6-0.2-1.1-0.1-1.2,0.5c-0.5,1.8-2,2.5-3.4,3.4
		c-3.2,2-6.4,4-9.5,6.1c-0.9,0.6-1.8,0.5-2.7,0.1c-0.8-0.3-1.7,0.1-1.7,1c0,0.8-0.4,1.2-1,1.5c-3.7,2.5-7.3,5-11,7.5
		c-0.7,0.5-1.2,0.4-1.7-0.1s-1.1-1-1.6-1.5c-0.4-0.3-0.7-0.4-1,0.1c-0.2,0.3-0.5,0.6-0.8,0.9c-0.6,0.7-1.4,0.9-2.2,0.4
		c-3.4-2.2-6.9-4.4-10.3-6.6c-0.2-0.2-0.5-0.3-0.5-0.7c0-0.5,0.4-0.4,0.6-0.2c1,0.5,2.1,1.1,3,1.8c0.3,0.2,0.7,0.4,1.1,0.5
		c1.5,0.6,2.8,1.6,4.2,2.4c0.7,0.5,1.5,0.9,2.2,1.3c1,0.6,1.7,0.2,1.7-1c0-4.1,0-8.2,0-12.3c0-0.9-0.3-1.7-0.8-2.4
		c-0.6-0.9-1.5-1.3-2.3-1.9c-2.4-1.5-4.8-2.9-7.2-4.4c-0.4-0.3-0.9-0.5-1.4-0.7c-0.8-0.3-1.4,0-1.7,0.8c-0.1,0.4-0.1,0.8-0.1,1.1
		c0,3.8,0,7.7,0,11.6c0,0.5,0.2,1,0,1.5c0,0.1-0.1,0.1-0.2,0.1c-0.4-0.8-1.9-1.4-2.8-1.1c-0.8,0.3-1.5,0.6-2.2-0.2
		c0.2-0.3,0.5-0.2,0.8-0.2c1.5,0.2,2.2-0.5,2.2-2c0-2.3,0.2-4.6,0.1-6.8c-0.1-2,0.1-4-0.7-5.8c-0.6-1.4-1.2-2.9-2.9-3.5
		c-0.4-0.1-0.7-0.6-1.2-0.5c-0.2,0-0.4-0.1-0.5-0.2c-2.3-1.5-4.7-2.8-7-4.3c-0.2-0.2-0.5-0.3-0.6-0.5c-0.4-0.3-0.9-0.2-1.1,0
		c-0.6,0.7-1.5,1.2-1.7,2.2c-0.1,0.3,0,0.8-0.5,0.8c-0.4,0-0.9-0.8-0.8-1.2c0-0.1,0,0,0-0.1c0.4-0.7,0.1-1.3-0.1-2.1
		c-0.4-1.3-1.2-2.2-1.9-3.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.7-0.2-1.1-0.6-1.3-1.3c-0.3-1-0.3-1.1,0.7-1.6c0.5-0.1,0.8,0.3,1.2,0.4
		c0.9-0.1,1.2-0.3,1.2-1.2c0-4.1,0-8.2,0-12.3c0-1-0.5-1.9-1.2-2.7c-0.5-0.6-1.1-1-1.8-1.4c-2.8-1.7-5.6-3.4-8.3-5.1
		c-0.2-0.2-0.5-0.3-0.7-0.5s-0.2-0.6-0.4-0.8c-0.2-0.3-0.4-0.6-0.6-0.9c-0.2-0.3-0.4-0.4-0.7-0.2c-0.3-0.2-0.8,0.3-1-0.2
		c0.2-0.4,0.5-0.9,0.7-1.3c0.7,0.2,1.5,0.2,2,1c0.2,0.4,0.8,0.4,1.3,0.6c1.1,0.5,2,1.3,3.1,1.6c0.2,0.1,0.4,0.3,0.5,0.5
		c0.2,0.4,0.6,0.6,1.1,0.7c0.7,0.1,1.2,0.5,1.8,0.9c0.7,0.7,1.7,1,2.5,1.6c0.4,0.3,1.1,0,1.6-0.3c0.3-0.2,0.5-0.4,0.8-0.5
		c0.4-0.1,0.6-0.4,0.7-0.8c0.1-0.5,0.1-0.9,0.2-1.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4c0.1,0.4,0,0.8,0.1,1.2c0,1,0,2.1,0.5,3.1
		c0.4,0.4,0.7,0.9,1.1,1.3c1.7,1.2,3.5,2.2,5.2,3.2c1.5,0.9,3,1.8,4.5,2.8c0.4,0.2,0.7,0.5,1.1,0.7c1.1,0.7,1.7,0.4,1.8-1
		c0.1-0.6,0.1-1.2,0-1.9c-0.3-2.9-0.1-5.8-0.2-8.6c0-1.4,0.1-2.8-0.5-4.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0,0.2,0
		c0.7,0.3,1.3,0.4,2-0.1c0.4-0.3,0.8-0.7,0.6-1.2c-0.1-0.6-0.7-0.6-1.2-0.7c-0.2-0.1-0.5-0.2-0.5-0.5c0-0.1,0-0.2,0.1-0.2
		c1.1-0.2,2.2-0.4,3.2-1.2c2.7-1.6,5.4-3.2,8-4.9c0.5-0.3,0.8-0.6,1.1-1.1c0.1-0.4-0.1-0.7-0.1-1c0-0.1,0-0.2,0.1-0.3
		c0.6,0.1,0.6,0.7,0.8,1.1c0.2,0.3,0.2,0.7,0.7,0.5c0.4-0.2,0.3-0.6,0.2-0.9c-0.2-0.4-0.5-0.8-0.8-1c-1.4-1-2.9-1.8-4.5-2.6
		c-1.2-0.8-2.3-1.6-3.5-2.3c-1.5-0.8-2.7-2.1-4.5-2.3c-0.3,0-0.6-0.1-0.9,0c-0.9,0.1-1.7,0.6-2.4,1.1c-3,1.9-6.1,3.7-9.1,5.6
		c-0.5,0.3-0.9,0.7-1.4,1c-0.7,0.6-1.2,1.4-1.1,2.4c0,0.5,0.2,1.1-0.2,1.6c-0.4,0.2-0.5,0.9-0.9,0.9c-1.3,0.2-0.8,1.1-0.9,1.9
		c0,0.3,0,0.7,0,1.1c0,0.3-0.1,0.7-0.6,0.5c-0.1-0.1-0.1-0.2-0.1-0.2c-0.1-1.4,0.1-2.9-0.1-4.3c0-0.2-0.1-0.3-0.1-0.5
		c-0.1-0.5,0.3-1-0.3-1.4c-0.4-0.3-0.5-0.6-0.5-1.1c0.1-0.2,0.3-0.1,0.4,0c0.6,0.2,1.2,0.3,1.7-0.2c0.9-0.9,0.7-1.6-0.5-1.9
		c-0.2-0.1-0.4-0.1-0.5-0.2c-0.4-0.3-0.2-0.4,0.1-0.5c0.5,0,1-0.2,1.5-0.3c1.2-0.2,2.2-1,3.2-1.6c1.7-1,3.4-2.1,5.1-3.1
		c0.9-0.5,1.6-1.2,2.5-1.8c0.1,0,0.2,0,0.2,0c1.1-0.1,1.1-0.1,1.1-1.1c0.2-0.3,0.4-0.5,0.7-0.6c0.6,0.1,1,0.7,1.7,0.8
		c0.2,0.1,0.4,0.1,0.7,0.1c1-0.1,1.6-0.9,1.4-1.9c-0.1-0.3-0.2-0.6,0-0.9c0.9-0.8,2.1-1.3,3.1-2c2.1-1.4,4.3-2.7,6.5-4
		c0.6-0.3,0.9-1,1.5-1.2c0.1,0,0.2,0,0.3,0c1.1,0.4,1.1,0.4,1.2-0.7c0.1-0.3,0.2-0.6,0.4-0.8c0.5-0.1,0.8,0.4,1.2,0.6
		c0.3,0.1,0.5,0.3,0.8,0.4c1.1,0.3,1.3,0.2,1.7-0.7c0.4-0.8,0.2-1.1-0.7-1.3c-0.3-0.1-0.5-0.1-0.7-0.3c-0.3-0.3-0.1-0.4,0.2-0.5
		c0.4,0,0.8-0.1,1.2-0.2c1.1-0.1,1.9-0.8,2.8-1.3c2.1-1.3,4.2-2.6,6.4-3.9c0.6-0.4,1.2-0.9,1.8-1.2c0.2-0.1,0.3-0.1,0.5-0.2
		c0.4-0.2,0.9,0,1.2-0.3s-0.2-0.8,0.1-1.1c0.5-0.1,0.7,0.2,0.9,0.6C776.4,661.1,776.7,661.4,777,661.7z"
      />
      <path
        class="st596"
        d="M742.2,681c-0.2,0.1-0.3,0.5-0.7,0.5c-0.5,0-0.7-0.4-1.1-0.6c-0.3-0.2-0.5-0.4-0.8-0.5
		c-1.6-0.8-3.1-1.9-4.6-2.9c-1.8-1.1-3.5-2.3-5.3-3.5c-0.4-0.3-0.9-0.5-1.4-0.3c-0.8,0-1.4,0.2-2.1,0.6c-3.1,2-6.3,4-9.4,5.9
		c-0.8,0.6-1.8,1-2.6,1.6s-1.4,1.3-1,2.5c0.2,0.6-0.1,1.2-0.1,1.7c-0.1,0-0.1,0.1-0.2,0.1c-0.4,1.2-0.6,2.3-0.7,3.5
		c-0.1,1.7-0.1,3.4-0.1,5c0,1,0.1,2,0,3.1c-0.1,0.5,0.1,0.8,0.4,1.2c0.5,0.6,0.4,1.6-0.1,2.3c-0.4-0.7-0.7-1.4-0.7-2.2
		c0-4.9,0-9.8,0-14.7c0-1.6,0.6-2.8,2-3.7c4.1-2.5,8.2-5.1,12.2-7.6c1-0.6,2.1-0.8,3.2-0.6c0.4,0.1,0.5,0,0.5-0.3
		c0.2-1.3,1-2.2,2-2.8c3.6-2.3,7.3-4.5,10.9-6.8c1.1-0.7,2.2-0.7,3.4-0.5c0.4,0.1,0.5,0,0.5-0.4c0.1-1.4,1.1-2.3,2.2-3
		c3.5-2.2,7-4.3,10.5-6.5c1.8-1.1,3.5-1.1,5.3,0.1c3.3,2.1,6.7,4.2,10,6.2c0.9,0.6,1.8,1.2,2.2,2.2c0.1,0.3,0.2,0.5,0.2,0.9
		c-0.4-0.1-0.8-0.2-0.8-0.8c-0.1-0.4-0.4-0.6-0.8-0.7c-0.6,0-1-0.4-1.5-0.7c-0.2-0.1-0.4-0.3-0.7-0.3c-1.6-0.8-3.2-1.9-4.7-2.9
		c-1.6-1-3.2-2.1-4.8-3.1c-0.7-0.4-1.3-0.8-2.1-0.6c-0.9,0.2-1.7,0.7-2.4,1.1c-3.3,2-6.6,4.2-10,6.2c-0.9,0.5-1.4,1.2-1.3,2.2
		c0,0.5,0.3,0.9,0.7,1.1c2.2,1.7,4.6,3,7,4.5c1,0.6,1.9,1.2,2.8,1.7c0.3,0.2,0.6,0.4,0.7,0.8c-0.1,0.3-0.2,0.5-0.3,0.8
		c-0.5,0.1-0.7-0.4-1-0.6c-0.3-0.2-0.5-0.5-0.9-0.6c-2.5-1.3-4.8-2.9-7.1-4.4c-0.5-0.3-1.1-0.5-1.6-1c-1-0.8-2-1.5-3.4-1.3
		c-1.1,0-1.9,0.6-2.7,1.2c-3.4,2.1-6.7,4.3-10.2,6.3c-0.8,0.4-1.2,1.1-1,2c0.1,0.5,0.2,0.9,0.7,1.3c0.7,0.7,1.6,1.2,2.5,1.7
		c2.4,1.5,4.7,3,7.1,4.4C741.5,680.2,742,680.5,742.2,681z"
      />
      <path
        class="st596"
        d="M792.3,674.3c0.1-0.3,0.1-0.8,0.7-0.7c0.2,0,0.6-0.1,0.6-0.4c0-0.4-0.4-0.2-0.6-0.2c-0.4,0-0.7,0-0.7-0.5
		c0.3-1.7-0.6-2.2-2.2-2.8c-2.1-1.2-4-2.5-6-3.7c-1.2-0.7-2.3-1.6-3.5-2.3c-0.5-0.3-1-0.6-1.5-0.7c-0.2,0-0.4,0-0.6,0
		c-0.6,0-1.2,0.2-1.8,0c-0.2-0.4,0-0.8,0.3-1c0.9,0.1,1.7-0.3,2.6,0c0.5,0.2,1,0.3,1.4,0.6c3.6,2.3,7.3,4.5,10.9,6.8
		c1.1,0.7,2,1.5,2.2,2.9c0,0.4,0.2,0.4,0.5,0.3c1.3-0.4,2.4,0,3.5,0.6c3.9,2.5,7.8,4.9,11.7,7.3c1.6,1,2.3,2.4,2.3,4.2
		c0,4.6,0,9.2,0,13.8c0,1.2-0.2,2.4-1,3.4c-0.4,0.5,0.2,0.8,0.2,1.2c-0.3-0.1-0.6-0.1-0.7-0.6c-0.1-0.5-0.5-0.5-0.7-0.2
		c-0.4,0.7-0.9,1.2-1.8,1.1c-0.4,0-0.5,0.6-0.8,0.9c-0.2,0.3-0.3,0.5-0.7,0.3s-0.8,0.2-1,0.5c-1.4,1-3,1.5-4.4,2.6
		c-0.3,0.2-0.4,0.3-0.3,0.6c0.1,0.3,0.1,0.6-0.2,0.7c-0.4,0.2-0.5-0.2-0.5-0.4c-0.1-0.4-0.3-0.2-0.5-0.1c-0.4,0.4-0.8,0.6-1.3,0.8
		c-1.2,0.5-1.8,0.3-2.6-0.6c-0.1-0.1-0.1-0.3-0.1-0.5c-0.2-2.5-0.3-5-0.1-7.5c0.1-1.5,0.2-3,0.1-4.5c-0.1-1.2,0.6-2.2,0.9-3.3
		c0.1-0.2,0.2-0.4,0.3-0.7c0-0.3,0.3-0.4,0.5-0.6c0.7-0.6,1.5-1,2.2-1.5c2.8-1.8,5.6-3.4,8.4-5.1c0.2-0.1,0.4-0.2,0.6-0.3
		c0.3-0.1,0.7-0.1,1-0.2c1-0.2,1.4-1.2,0.9-2.1c-0.6-1-1.4-1.7-2.6-1.9c-1.7-1.2-3.5-2.3-5.3-3.4c-1.7-1.1-3.5-2.1-5.2-3.2
		c-1.3-0.8-2.7-0.8-4.1-0.1C792.9,674.2,792.7,674.5,792.3,674.3z"
      />
      <path
        class="st597"
        d="M724.6,728.6c-0.2,0.4-1,0.3-0.8,0.9c0.2,0.8,0.5,1.6,1.4,1.7c0.3,0.2,0.3,0.4-0.1,0.4
		c-0.2,0-0.4-0.1-0.6-0.1c-1.1-0.5-2.1-1.1-3-1.9c0.1-0.5,0.5-0.4,0.8-0.5c0-0.3-0.1-0.5-0.4-0.7c-0.4,0.2-0.3,0.8-0.7,1
		c-0.2,0-0.3,0-0.5-0.1c-1.9-1.2-3.8-2.2-5.6-3.5c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.4-0.7-0.6-1c-0.4-0.5-0.6-0.4-0.9,0.1
		c-0.2,0.4-0.2,0.9-0.2,1.3c0,0.4,0,0.7,0.1,1.1c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.3,0,0.6-0.1,0.9c-0.2,0.5-0.5,0.5-0.7,0.2
		c0.4,0.4,0.5,0.9,0.5,1.6c0,1.7,0,3.4,0,5.1c0,0.5,0.2,1.1,0.2,1.6c-0.1,1.2-0.1,2.5-0.3,3.7c0,0.1-0.1,0.2-0.3,0.2
		c-0.5-0.2-0.4-0.7-0.4-1.1c0-4.8,0-9.5,0-14.2c0-0.9,0.4-1.7,1-2.5c0.2-0.2,0.3-0.4,0.1-0.7c-1.1-1.3-1.1-2.8-1-4.3
		c0-0.7,0-1.3,0-2c0.1-1.8,0-3.7,0-5.5c0-1,0.1-2,0.3-3c0.1-0.7,0.1-1.5-0.2-2.2c-0.1-0.3-0.1-0.6,0-1c0-0.4,0.2-0.7,0.4-1
		c0.2,0.1,0.3,0.2,0.5,0s0.4-0.1,0.5,0.1c0.1,0.6-0.2,1.1-0.1,1.7c0,0.4,0,0.8,0,1.3c0,4.2,0,8.4,0,12.6c0,0.9,0.2,1.7,0.8,2.4
		c1.3,1,2.7,1.8,4,2.6c0.8,0.6,1.8,0.9,2.6,1.7c0.3,0.5,0,0.8-0.4,1.2c0.7,0.2,0.6-0.8,1.1-0.7C722.4,727,723.6,727.7,724.6,728.6z"
      />
      <path
        class="st598"
        d="M724.6,731.4c0.2-0.3,0.3,0,0.5,0c0.3,0.1,0.2-0.1,0.2-0.2c0.6,0,0.6,0.5,0.8,0.8c0.5,0.8,1.3,1.4,1.4,2.5
		c0,0.1,0,0.2,0.1,0.2c0.6,0.8,0.5,1.5,0.2,2.3c-0.2,0.5,0.4,0.8,0.7,1c0.1,0.1,0.2-0.2,0.2-0.3c0-1.3,1.1-1.9,1.7-2.7
		c0.2-0.3,0.9-0.4,1.4-0.1c0,0,0,0,0,0.1c0,0,0,0-0.1,0c-0.2,0.2-0.4,0.2-0.7,0.3c-0.5,0.2-0.7,0.5-0.8,1s-0.2,1-0.2,1.5
		c0,3.6,0,7.1,0,10.7c0,0.8,0.1,1.7,0.6,2.4c0.2,0.2,0.2,0.5,0.5,0.6c2.1,1.6,4.5,2.8,6.7,4.2c1.4,0.9,2.8,1.7,4.2,2.6
		c0.3,0.2,0.6,0.4,1,0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.4,0.5-0.9,0.5-1.4c0-3.7,0-7.4,0-11.2c0-1.2-0.3-2.4-1.2-3.4
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.7-1.2-1.1-1.9-1.5c-0.3-0.2-0.6-0.3-0.6-0.7c0.4-0.2,0.8-0.2,1,0.3c0,0.1,0.1,0.2,0.2,0.2
		c1.8,0.1,2,1.9,2.9,2.8c0.4,0.4,0.6,1.2,0.6,1.9c0,1.9,0.5,3.7,0.3,5.6c-0.2,2.3-0.2,4.6-0.1,6.9c0,0.6-1.5,1.6-2.2,1.4
		c-0.3-0.1-0.7-0.1-1-0.1c-2.9-1.8-5.8-3.5-8.7-5.3l-0.1-0.1c-1.8-0.9-3.2-2.2-4.2-4.1c-0.3-0.7-0.8-0.9-1.4-0.5
		c-0.1,0.1-0.2,0.1-0.4,0.1c0.8-2.9,0.2-5.8,0.4-8.7c0.1-1.6,0-3.2,0-4.7c0-1.2-0.4-2.2-1.2-3.1
		C725.5,732.3,724.8,732.1,724.6,731.4z"
      />
      <path
        class="st599"
        d="M712.3,701.4c0.1-0.9,0.6-1.8-0.4-2.5c-0.2-0.1-0.1-0.4-0.1-0.6c0-1.1,0-2.2,0-3.3c0-1.7,0-3.5,0.1-5.2
		c0-1.3,0.3-2.5,0.7-3.7l0,0c0,0,0.1,0,0.1,0.1c0.3,0.4,0.2,0.8,0.2,1.3c0,3.9,0,7.8,0,11.7c0,0.9,0.2,1.7,0.7,2.4
		c0.5,0.6,1.2,1,1.9,1.4c3.2,2,6.5,4,9.7,5.9c0.3,0.2,0.6,0.4,1,0.1c0.5-0.3,0.6-0.7,0.6-1.3c0-4.1,0-8.2,0-12.3
		c0-0.9-0.5-1.6-0.9-2.4c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0-0.3,0.2-0.3s0.3,0,0.4,0.2c0.2,0.3,0.2,0.7,0.5,1c0.6,0.3,0.3,1,0.5,1.5
		c0,0.2,0,0.3,0,0.5c0.2,1.2,0,2.4,0.1,3.6c0,0.3-0.1,0.7,0.1,1c0,0,0,0.1,0,0.2c0.7,0.7,0.7,1.7,0.5,2.5c-0.3,1.6-0.3,3.2-0.4,4.7
		c0,0.4,0.1,0.9-0.2,1.2c-0.5,0.2-0.7,0.7-1.2,0.9c-0.7,0.2-1.2,0-1.8-0.5c-0.8-0.7-1.9-1.1-2.7-1.9c-0.4-0.3-1,0-1.3-0.4
		c-0.9-1.1-2.3-1.3-3.3-2.2c-0.1-0.1-0.3-0.2-0.5-0.3c-0.7-0.5-1.7-0.3-2.1-1.3c-0.1-0.2-1.1-0.3-1.5-0.7
		C712.7,702,712.4,701.7,712.3,701.4z"
      />
      <path
        class="st600"
        d="M726.4,749.5c-0.8,0.4-1.4,0.2-2.1-0.2c-3.5-2.1-7.1-4.2-10.6-6.3c-0.6-0.4-1.3-0.6-1.3-1.5
		c0.3,0,0.5-0.4,0.9-0.1c0.3,0.1,0.4,0.3,0.5,0.6c0.5,0.7,1.4,0.8,2,1.4c0.5,0.4,1.1,0.7,1.7,1c0.1,0,0.1,0.1,0.2,0.1
		c1.7,1.1,3.5,2.1,5.3,3.2c0.8,0.5,1.5,1,2.3,1.4c0.1,0.1,0.2,0.2,0.4,0.2C725.9,749.2,726.3,749.1,726.4,749.5z"
      />
      <path
        class="st601"
        d="M713.2,741.3c-0.3,0-0.6,0-0.9,0c-0.2-0.1-0.2-0.3-0.2-0.5c0.3-0.2,0.2-0.6,0.2-0.9c0.1-0.8-0.2-1.6,0.3-2.4
		c0-0.1,0-0.2,0-0.3c-0.7-2.3-0.1-4.7-0.3-7c0-0.5,0.1-1-0.4-1.4c-0.3-0.3-0.2-0.8,0-1.2c0.4,0.2,0.2,0.6,0.3,0.8
		c0,0.2,0,0.3,0.3,0.3c0.2,0,0.2-0.2,0.2-0.3c0-0.3-0.1-0.7,0.2-0.9c0.4,0.4,0.3,0.9,0.3,1.3c0,3.5,0,6.9,0,10.4
		C713.1,739.9,713.4,740.6,713.2,741.3z"
      />
      <path
        class="st602"
        d="M726.4,749.5c-0.2-0.2-0.6,0-0.8-0.2c1.3-0.5,1.1-1.6,1.1-2.7c-0.1-3.5,0-7.1,0-10.6c0-1.1-0.2-2.1-1.2-2.8
		c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.3,0.3-0.2,0.5-0.3c0.9,0.8,1.5,1.8,1.5,3c0,4.2,0,8.3,0,12.5c0,0.6-0.4,1-0.7,1.4
		C726.5,749.4,726.5,749.4,726.4,749.5z"
      />
      <path
        class="st595"
        d="M711.8,704.4c0,0.4,0.3,0.8,0.4,1.2c0.2,0.9,0.4,1.9-0.1,2.8c-0.2,0.3-0.3,0.6-0.3,1c0,1.9,0,3.9,0,5.8
		c0,0.3,0.1,0.6-0.1,0.9c0-3.7,0-7.3,0-11C711.7,704.8,711.7,704.6,711.8,704.4z"
      />
      <path
        class="st603"
        d="M746.3,760.4c0.1,0,0.1,0,0.2,0c0.6,0.2,0.6,0.8,1.1,1.1c1.2,0.8,2.5,1.5,3.8,2.3c0.2,0.2,0.7,0.2,0.5,0.7
		c-0.8,0.1-1.2-0.5-1.7-0.9c-0.8-0.5-1.7-0.9-2.4-1.5c-0.2-0.1-0.3-0.1-0.5,0.1C746.8,761.7,746.6,761.1,746.3,760.4z"
      />
      <path
        class="st604"
        d="M715.5,748c-1.1-0.5-2.2-0.9-2.3-2.3C713.5,746.9,714.8,747.2,715.5,748L715.5,748z"
      />
      <path
        class="st605"
        d="M715.5,748c0.9,0.2,1.7,0.5,2.3,1.3l0,0C717,749,716.1,748.6,715.5,748L715.5,748z"
      />
      <path
        class="st606"
        d="M719.4,750.3c-0.6-0.2-1.2-0.5-1.6-1l0,0C718.4,749.5,719,749.7,719.4,750.3L719.4,750.3z"
      />
      <path
        class="st607"
        d="M719.4,750.3c0.7,0,1.1,0.4,1.5,0.9C720.3,751,719.8,750.8,719.4,750.3L719.4,750.3z"
      />
      <path
        class="st608"
        d="M714,744c0,0.5-0.3,0.7-0.6,0.9C713.4,744.5,713.6,744.2,714,744z"
      />
      <path
        class="st609"
        d="M781.4,718.8c-0.3,0.2-0.6,0.5-1,0.5c-0.2-0.1-0.2-0.2-0.2-0.4c0-2.8,0-5.5,0-8.3c0-1.9,0-3.8-0.1-5.7
		c0.5-1.8,1.9-2.7,3.4-3.5c2.8-1.6,5.5-3.3,8.3-5c0.3-0.2,0.7-0.3,1-0.4c0.5,0.1,0.5,0.5,0.5,0.9c0,3.3,0,6.7,0,10
		c-0.2,1.1,0,2.1-0.1,3.2c0,0.3,0.1,0.6-0.1,0.9c0,0.1-0.1,0.2-0.2,0.3c-0.9,0.5-1.6,1.1-2.5,1.6c-3,1.7-5.9,3.7-9,5.3
		C781.2,718.5,781.9,718.8,781.4,718.8z"
      />
      <path
        class="st610"
        d="M792.5,735.8c0.7,0.1,0.8,0.6,0.8,1.2c0,1.2,0,2.5,0,3.8c0,3.1,0,6.1,0,9.2c0,0.9-0.3,1.6-1,2.2
		c-0.4-0.1-0.6,0-0.9,0.2c-2,1.1-3.9,2.4-5.9,3.5c-1.5,0.8-3,1.8-4.4,2.8c-0.7,0.5-0.9,0.5-1.2-0.4c0.5-2.4,0.4-4.8,0.3-7.2
		c0-0.8,0.2-1.6,0.1-2.3c0-0.9,0-1.7-0.2-2.6c-0.2-0.6,0.2-1,0.2-1.6c0.1-0.6,0.4-1.1,0.7-1.6c1-1,2.3-1.5,3.5-2.2
		c2-1.2,3.8-2.4,5.8-3.5c0.4-0.2,0.8-0.5,1.1-0.8C791.8,736,792.1,736,792.5,735.8z"
      />
      <path
        class="st610"
        d="M798.5,729c-0.4,0.3-0.8,0.7-1.4,0.5c-0.1-0.2-0.3-0.3-0.3-0.5s0-0.3,0.1-0.5c0.2-0.6,0-1.3,0.6-1.8v-0.2
		c-0.2-3,0-6-0.1-9.1c0-1.1-0.3-2.2,0-3.3c0.1-0.4,0.2-0.8,0.5-1.1c0.5-0.7,1.3-0.9,1.9-1.3c2.3-1.2,4.5-2.8,6.9-4
		c0.7-0.3,1.2-0.8,1.8-1.3c0.2-0.2,0.4-0.4,0.8-0.4c0.8,0.1,0.9,0.5,1.1,1.7c0.1,1,0,2.1,0,3.1c-0.1,2.2,0.1,4.5,0,6.8
		c0,1-0.3,1.9,0,2.9c0.1,0.5-0.1,0.9-0.6,1.2c-1.6,1-3.3,2.1-5,3c-2,1.2-4,2.4-6,3.6C798.6,728.5,798.5,728.7,798.5,729z"
      />
      <path
        class="st611"
        d="M765.2,728.3c-0.5,0.3-0.9,0.9-1.6,0.5c-0.1-0.2-0.2-0.4-0.2-0.7c0.2-4.2,0.1-8.5,0.1-12.8
		c0.2-1.4,0.8-2.6,2.1-3.3c2.6-1.6,5.2-3.2,7.9-4.8c0.6-0.3,1.3-0.6,1.2-1.5c0.4-0.2,0.7-0.2,1.1,0c0.4,0.1,0.6,0.4,0.7,0.9
		c0,0.8,0,1.7,0,2.5c0,3.6,0,7.2,0,10.8c0,0.4,0,0.9-0.2,1.3c-0.3,0.3-0.5,0.6-0.8,0.8c-0.2,0-0.3-0.3-0.5,0
		c-0.6,0.7-1.4,1.3-2.4,1.4c-0.5,0.1-0.8,0.6-1.3,0.8c-1.5,0.8-3,1.8-4.5,2.7C766.2,727.4,765.6,727.8,765.2,728.3z"
      />
      <path
        class="st611"
        d="M808.9,726.1c0.6-0.4,1,0,1.3,0.4c0,0.1,0.1,0.1,0.1,0.2c-0.4,2.4,0.2,4.8,0.1,7.1c-0.1,1.6-0.1,3.1-0.2,4.7
		c0,0.3,0,0.7,0.1,1c0.2,1.5-0.3,2.3-1.7,3.1c-1.8,1-3.7,2.1-5.5,3.2c-1,0.6-2.1,1.1-3,1.8c-0.3,0.2-0.5,0.4-0.8,0.7
		c-0.4,0.2-0.7,0.5-1.1,0.7c-0.7,0.2-1-0.3-1-0.8c0.1-1.2-0.3-2.4,0-3.7c0.1-0.3,0-0.7,0-1c-0.1-0.7-0.2-1.4-0.1-2.1
		c0.2-1.3,0.2-2.6,0-3.9c-0.1-0.7,0-1.4,0-2.1c0.2-1.3,0.8-2.2,2-2.9c3.1-1.8,6.1-3.6,9.1-5.5C808.7,726.8,809,726.6,808.9,726.1z"
      />
      <path
        class="st612"
        d="M775,745.6c0.8-0.2,1.3,0.1,1.3,1c0,1.9,0,3.7,0,5.6c0,0.6,0,1.2,0,1.9c-0.2,1.7-0.2,3.4,0,5.1
		c0.1,1.4-0.5,2.2-1.6,2.8c-2.4,1.3-4.6,2.9-7,4.2c-1,0.5-2,1-2.8,1.8c-0.3,0.4-0.8,0.5-1.3,0.2c-0.3-0.2-0.2-0.5-0.2-0.8
		c0-2.7,0.1-5.5,0.2-8.2c0-1,0-2.1,0-3.2c0-2.2,0.9-3.8,2.8-4.8c2.7-1.4,5.2-3.2,7.8-4.6C774.5,746.4,775,746.2,775,745.6z"
      />
      <path
        class="st610"
        d="M792.6,715.9c0.2,0,0.4,0.1,0.5,0.3s0.1,0.5,0.1,0.7c0,4.3,0,8.5,0,12.8c0,0.3,0,0.6-0.1,0.9
		c0,0.7-0.3,1.1-1,1.3s-1.3,0.8-2,1.2c-2.8,1.8-5.7,3.2-8.4,5.1c-0.4,0.3-0.8,0.7-1.3,0.2c0-0.8,0.5-1.7,0-2.5
		c0.4-1.3-0.2-2.7-0.1-4c0.1-1.5,0-2.9,0-4.4c0-0.7-0.1-1.4-0.1-2.1c0.1-0.4,0.3-0.7,0.3-1.1c0-0.3,0.2-0.6,0.3-0.9
		c0-0.2,0.1-0.4,0.3-0.5c0.1,0,0.1-0.1,0.2-0.1c3.4-2,6.8-4,10.1-6.1C791.8,716.3,792.1,715.9,792.6,715.9z"
      />
      <path
        class="st610"
        d="M775.5,725.7c0.6,0.1,0.8,0.6,0.8,1.1c0,0.7,0,1.3,0,2c0,3.7,0.2,7.3,0,11c0,0.3,0,0.7-0.2,1
		c-0.2,0.7-0.8,0.7-1.3,1c-1.4,0.8-2.8,1.7-4.2,2.5c-2,1.2-3.9,2.4-5.9,3.6c-0.8,0.5-1,0.4-1.3-0.5c0.1-2.2,0.2-4.4,0-6.6
		c-0.1-0.7,0.1-1.3,0.1-2c0-1.3,0-2.6,0-3.9c0.1-0.8,0.7-1.4,0.9-2.2c0.1-0.2,0.2-0.3,0.4-0.5c2.8-1.7,5.7-3.4,8.5-5.1
		c0.5-0.3,1.1-0.6,1.5-1.1C775,725.8,775.2,725.8,775.5,725.7z"
      />
      <path
        class="st613"
        d="M762.8,668.4c-0.4,0-0.8,0.3-1.3,0.1c-0.2-0.1-0.5,0-0.6-0.2c-0.5-0.9-1.4-1.4-2.3-1.9
		c-2.2-1.4-4.3-2.8-6.6-4.1c-0.2-0.1-0.4-0.3-0.6-0.4c-1-0.8-1-1.4,0-2.2c3.2-2,6.3-4,9.5-5.9c0.4-0.2,0.6-0.7,1-0.9
		c0.4-0.1,0.7,0,1.1,0.2c2.6,1.5,5,3.2,7.6,4.7c0.8,0.5,1.7,0.9,2.4,1.5c0.3,0.2,0.6,0.4,0.7,0.8c0.2,0.8-0.2,1.3-0.8,1.8
		s-1.2,0.8-1.8,1.2c-2.5,1.4-4.8,3-7.3,4.5c-0.5,0.3-1,0.5-1.5,0.5c0.2,0,0.3-0.1,0.5-0.1s0.4,0,0.3,0.2
		C763.1,668.3,762.8,668.3,762.8,668.4z"
      />
      <path
        class="st613"
        d="M729.3,689.3c-0.5,0.2-1,0.5-1.6,0.3c-0.2-0.1-0.4,0-0.5-0.1s-0.2-0.2-0.2-0.2c-0.5-1-1.5-1.3-2.3-1.8
		c-2.3-1.6-4.7-2.9-7-4.4c-1.1-1.1-1.1-1.5,0.2-2.3c3.2-2,6.5-4,9.7-6.1c0.4-0.2,0.8-0.5,1.2-0.6c1,0.4,1.9,1,2.7,1.6
		c1.9,1.3,3.9,2.5,5.9,3.7c0.7,0.4,1.3,0.8,1.9,1.3c0.3,0.3,0.7,0.7,0.5,1.3c-0.2,0.7-0.7,1.1-1.3,1.4c-2.9,1.6-5.6,3.5-8.4,5.1
		c-0.6,0.3-1.2,0.6-1.9,0.6C728.6,689.2,729.1,688.7,729.3,689.3z"
      />
      <path
        class="st614"
        d="M779.2,703c-0.7,0.3-1.4,0.1-2-0.3c0.1-0.2,0.3-0.2,0.4-0.2c0.9-0.4,1.2-0.9,0.8-1.8
		c-0.1-0.2-0.2-0.4-0.1-0.6c0.5-0.3,1.1-0.1,1.6-0.5c0.1,0,0.2-0.1,0.3-0.1c1-0.6,1.9-1.1,2.8-1.8c2.2-1.3,4.4-2.5,6.5-3.9
		c0.1-0.1,0.3-0.2,0.4-0.2c1.3-0.4,1.7-0.3,2.2,0.5c0,0.4-0.2,0.8-0.5,1.1c-1.3,1.1-2.8,1.8-4.2,2.8c-2,1.4-4.2,2.5-6.2,3.8
		c-0.5,0.3-0.8,0.7-1.3,1C779.8,702.8,779.6,703.1,779.2,703z"
      />
      <path
        class="st615"
        d="M760.2,712.9L760.2,712.9c1.1-0.7,1.1-0.7,1.8-2.3c-0.3,0.1-0.6,0-0.7-0.3c0.3-0.5,0.9-0.2,1.4-0.4
		c1.8-0.8,3.4-2,5.1-3c0.3-0.2,0.5-0.4,0.9-0.4c0.8-0.1,1.3-0.7,1.9-1c1.2-0.7,2.6-1.2,3.5-2.4c0.6,0.2,0.8,0.8,1.2,1.2
		c0.2,1-0.5,1.4-1.2,1.8c-1.5,0.9-3,1.8-4.5,2.7c-2.3,1.3-4.5,2.6-6.5,4.4C762.1,713.8,761.3,713.8,760.2,712.9z"
      />
      <path
        class="st615"
        d="M808.4,685c-1.9,1.3-4,2.3-5.9,3.6s-4,2.3-5.9,3.8c-0.7,0.4-1.4,0.4-2.1,0.2c-0.2,0-0.2,0-0.2-0.2
		c0.1-0.1,0.2-0.2,0.3-0.3c1.1-0.8,1.1-1,0.5-2.2c0-0.2,0.1-0.3,0.2-0.4c1.2-0.1,2.2-0.5,3.2-1.1c0.9,0,1.4-0.7,2.1-1.1
		c0.1,0,0.1-0.1,0.2-0.1c0.5-0.3,1.1-0.4,1.4-1c0.1-0.1,0.2-0.2,0.4-0.2c0.7-0.2,1.4-0.5,1.8-1.1c0.2-0.2,0.5-0.4,0.8-0.5
		c0.9-0.2,1.7-0.7,2.4-1.3c0.8-0.7,1-0.7,1.6,0c0.5,0.6,0.3,1.2-0.3,1.7C808.8,684.9,808.6,684.9,808.4,685z"
      />
      <path
        class="st616"
        d="M761,668.3c0.1,0.1,0.4,0,0.5,0.2c0,0.1-0.3,0-0.1,0.2s0.5,0.2,0.8,0.4c0.5,0.5,0.4,1.1,0.2,1.7
		c-0.2,0.5-0.7,0.8-1.3,0.6c-0.8-0.2-1.3-0.8-2-1.1c-1.7-1.3-3.6-2.2-5.4-3.4c-1.7-1.1-3.6-2.1-5.2-3.4c-0.3-0.3-0.7-0.6-0.5-1.2
		c0-0.1,0.1-0.1,0.2-0.2c0.6,0,1.2-0.3,1.8,0c2.9,1.8,5.8,3.7,8.8,5.5c0.5,0.3,1,0.5,1.5,0.8c0.3,0.2,0.5,0,0.8-0.2
		C760.9,668.2,761,668.2,761,668.3z"
      />
      <path
        class="st617"
        d="M730.8,711.8c-0.5-0.3-0.7-0.8-0.9-1.2c-0.2-0.1-0.2-0.3-0.2-0.5c-0.1-1.1,0-2.2,0-3.2c0-0.4,0-0.8-0.1-1.1
		c-0.1-2.1-0.2-4.2,0-6.2c0.1-0.8,0-1.5,0-2.3c0-0.4,0-0.8,0.2-1.2c0.5-0.5,1-0.8,1.7-0.8c0.5,0,0.9,0.3,1.4,0.5
		c0.3,0.2,0.6,0.3,0.8,0.5c0.8,0.7,1.8,1.1,2.7,1.7c0.3,0.2,0.6,0.3,0.9,0.5c0.6,0.5,1.3,0.9,2,1.2c0.1,0,0.1,0.1,0.2,0.1
		c0.4,0.3,0.7,0.5,1.1,0.7c0.1,0,0.1,0.1,0.2,0.1c0.2,0.2,0.5,0.4,0.8,0.5c0.1,0,0.1,0.1,0.2,0.1c0.3,0.3,0.6,0.5,0.9,0.7
		c0.2,0.2,0.5,0.5,0.3,0.8s-0.5,0.3-0.8,0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-1.7-1.2-3.5-2.3-5.4-3.3c-1.1-0.7-2.1-1.4-3.2-2
		c-0.3-0.2-0.5-0.3-0.9-0.5c-0.8-0.3-1.1-0.1-1.1,0.8c0,0.7,0,1.5,0,2.2c0,3.8,0,7.5,0,11.3C731,711.5,731.1,711.8,730.8,711.8z"
      />
      <path
        class="st618"
        d="M714,721.9c-0.7-0.5-1.1-1.1-1.1-2c0-4.5,0-8.9,0-13.4c0.5-1,1.4-1.3,2.1-1c2.7,1.7,5.4,3.3,8.2,5
		c0.6,0.3,1.2,0.7,1.7,1c0.3,0.2,0.7,0.4,0.8,0.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-1.2-0.5-2.2-1.4-3.5-1.9
		c-1.2-0.8-2.4-1.6-3.7-2.2c-0.1,0-0.1-0.1-0.2-0.1c-0.7-0.5-1.4-0.9-2.1-1.3c-0.1,0-0.2-0.1-0.2-0.1c-1.2-0.4-1.4-0.2-1.4,1
		c0,3.3,0,6.6,0,10c0,1.1,0,2.2-0.1,3.2C714.1,721.6,714.4,721.8,714,721.9z"
      />
      <path
        class="st619"
        d="M742.2,681c-3.2-2.2-6.6-4.1-9.9-6.3c-0.3-0.2-0.7-0.4-0.9-0.8c-0.5-0.8-0.4-0.8,0.5-1
		c0.8-0.2,1.4,0.3,2.1,0.6c1.4,0.6,2.6,1.6,3.9,2.3c1.2,0.7,2.3,1.5,3.5,2.2c0.3,0.2,0.5,0.4,0.8,0.6c0.4,0.2,0.7,0.3,1.1,0
		c0.1,0,0.2,0,0.2,0c0.3,0.1,0.6,0,0.8,0.4c0.1,0.1-0.3,0.2,0,0.2c0.2,0,0.4,0.1,0.6,0.3c0.2,0.3,0.2,0.5,0,0.8
		c-0.3,0.5-0.6,1.1-1.1,1.5C743.2,681.8,742.8,681.3,742.2,681z"
      />
      <path
        class="st620"
        d="M742,703c0.3,0.1,0.6,0,0.8-0.2c0.1,0,0.1,0,0.2,0c0.6,1.1,0.7,2.3,0.7,3.5c0,3.4-0.1,6.8,0.1,10.2
		c0,0.6,0,1.1-0.1,1.7c-0.2,1-0.8,1.3-1.7,0.8c-1.3-0.8-2.6-1.6-3.8-2.4c-2.2-1.4-4.5-2.8-6.8-4.1c-0.3-0.2-0.5-0.3-0.7-0.6
		c0-4,0.1-8.1,0.1-12.1c0.4,0.2,0.3,0.5,0.3,0.8c0.1,2.7,0.3,5.4,0.2,8.2c0,0.3,0,0.7,0,1c-0.3,1,0.1,1.6,1,2.1
		c3.2,1.9,6.4,3.8,9.6,5.8c0.2,0.1,0.4,0.3,0.7,0.2c0.2-0.1,0.2-0.4,0.2-0.6c0.1-3.9-0.2-7.7,0-11.6
		C742.7,704.6,742.4,703.8,742,703z"
      />
      <path
        class="st621"
        d="M747.4,761.6c-0.3-0.4-0.6-0.8-0.9-1.1c-0.2-0.5-0.2-1.1-0.2-1.7c0-4,0-7.9,0-11.9c0-0.3,0-0.7,0.2-1
		c0.3-0.7,0.9-1.1,1.7-0.8c0.4,0.1,0.7,0.3,1,0.5c2.9,1.8,5.8,3.6,8.8,5.4c0.6,0.3,1.1,0.7,1.4,1.3l-0.1,0.1c-0.5,0.3-0.9,0-1.4-0.3
		c-2.8-1.7-5.7-3.4-8.5-5.2c-0.5-0.3-1-0.8-1.5-0.5c-0.6,0.3-0.4,1-0.4,1.5c0,4.2,0,8.4,0,12.6C747.4,760.9,747.7,761.3,747.4,761.6
		z"
      />
      <path
        class="st622"
        d="M795.2,689.9c1,0.9,0.7,1.8-0.8,2.4c-0.4,0.2-0.7-0.1-1-0.3c-2.8-2-5.8-3.7-8.7-5.7
		c-0.3-0.2-0.7-0.4-0.8-0.7c-0.5-0.3-0.9-0.6-1.3-1c0-0.4-0.7-0.5-0.4-0.9c0.3-0.5,0.8-0.3,1.2-0.2c0.1,0,0.2,0,0.2-0.1
		c0.4,0,0.6,0.3,1,0.4c0.1,0,0.1,0.1,0.2,0.1c0.6,0.5,1.4,0.9,2.1,1.3c0.1,0,0.1,0.1,0.2,0.1c0.6,0.4,1.3,0.9,2,1.2
		c0.2,0.1,0.4,0.2,0.6,0.4c0.5,0.4,1,0.8,1.7,1c0.3,0.1,0.5,0.2,0.7,0.4C792.9,689.3,794,689.9,795.2,689.9z"
      />
      <path
        class="st623"
        d="M747.4,761.6c0-0.3-0.2-0.7,0-1c0.1-0.1,0.2-0.2,0.3,0c1.7,1.5,3.7,2.5,5.6,3.6c1.6,0.9,3.2,1.9,4.7,2.9
		c0.9,0.6,1.2,0.4,1.3-0.7c0-1.8,0-3.6,0-5.5c0-1.8,0-3.6,0-5.4c0-0.8-0.1-1.6-0.5-2.4c-0.3-0.5-0.1-0.7,0.5-0.7c0,0,0-0.1,0.1-0.1
		c0.7,0.7,1,1.6,1,2.5c0,4.2,0,8.3,0,12.5c0,0.6,0,1-0.7,1.3c-0.6,0.2-1,0.1-1.5-0.2c-2-1.2-3.9-2.4-5.9-3.6
		c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.5-0.9-0.7-1.4-1C749.4,762.9,748.4,762.3,747.4,761.6z"
      />
      <path
        class="st624"
        d="M776.5,681c-3.5-2.6-7.3-4.6-10.8-7.1c0-0.2-0.3-0.5,0-0.5c0.5-0.1,0.6-0.9,1.3-0.6c0.4,0.2,0.9,0.4,1.3,0.7
		c2.7,1.5,5.2,3.2,7.8,4.7c0.6,0.4,1.3,0.7,2,0.8c0.1,0,0.1,0.1,0.2,0.2v0.1c0.2,0.3,0.3,0.6-0.1,0.7
		C777.4,680.1,777,680.7,776.5,681z"
      />
      <path
        class="st625"
        d="M730,696c-0.3,0.4-0.3,0.9-0.3,1.3c0,2.5,0,5,0,7.6c0,0.3,0,0.5-0.1,0.8c0.2,1.5,0,3,0.1,4.5
		c0,0.2,0.1,0.3,0.1,0.4c-0.3,0.3-0.3,0-0.4-0.2c-0.2-1.2-0.4-2.5-0.3-3.8c0-0.3,0.2-0.7-0.2-0.7c-0.3,0-0.2,0.4-0.2,0.7
		c-0.1,0.5-0.1,1-0.2,1.4c-0.1,0.5-0.6,0.5-1,0.5c-0.1-1.7,0.2-3.3,0.2-5c0-0.5,0.3-0.8,0.4-1.3c0-0.8-0.2-1.4-0.3-2.1
		c0.4-0.2,0.3-0.5,0.3-0.8c0-0.8,0-1.6,0-2.3c0-0.2,0-0.6,0.4-0.4c0.6,0.3,0.7-0.1,1-0.5c0.1-0.2,0.1-0.5,0.5-0.3
		C730,695.7,730,695.8,730,696z"
      />
      <path
        class="st626"
        d="M775.1,701.1c-3-1.9-6-3.8-9-5.7c-0.5-0.3-1.2-0.7-1.4-1.4c0-0.4,0.1-0.8,0.7-0.8c0.5,0,1-0.5,1.4,0.1
		c1.9,1.4,3.9,2.6,5.9,3.9c1.2,0.8,2.4,1.4,3.5,2.1c0.3,0.2,0.7,0.3,0.6,0.8c-0.1,0.1-0.3,0.2-0.4,0.2c-0.5,0-1,0.1-1,0.8
		C775.3,701.1,775.2,701.1,775.1,701.1z"
      />
      <path
        class="st627"
        d="M725.4,712.8c0.1,0,0.1-0.1,0.2-0.2c1.1,0.5,1.4,1.6,1.5,2.6c0.1,4.2,0.1,8.3,0,12.5c0,1.1-0.4,1.4-1.4,1.4
		c-0.3-0.2-0.7-0.3-1-0.5c-1.2-0.7-2.3-1.4-3.5-2.1c-0.3-0.1-0.6-0.2-0.8-0.5c-1.5-1-3.1-1.9-4.6-2.8c-0.6-0.4-1.3-0.7-1.8-1.2
		c0-0.1,0-0.2,0-0.3c-0.1-0.2-0.1-0.4,0.1-0.5c0.6,0.1,1,0.5,1.5,0.8c1.2,0.8,2.4,1.7,3.8,2.2c1.9,1.1,3.7,2.3,5.5,3.5
		c0.3,0.2,0.6,0.6,1,0.3c0.4-0.2,0.3-0.7,0.3-1c-0.2-3.8-0.2-7.7,0-11.5c0-0.7-0.2-1.2-0.5-1.8C725.5,713.3,725.3,713.1,725.4,712.8
		z"
      />
      <path
        class="st628"
        d="M761.3,710.3c0.3,0.1,0.5,0.1,0.8,0.2c-0.5,2-0.8,2.2-2,2.2H760c-0.6,0-0.8-0.6-1.2-0.9
		c-0.3-0.2-0.6-0.4-0.9-0.6c-1.8-1.2-3.6-2.2-5.4-3.4c-0.6-0.4-1.3-0.8-1.9-1.2c-0.2-0.2-0.5-0.3-0.6-0.6c0.3-0.5,0.7-0.3,1.1,0
		c0.5,0.4,1,0.7,1.6,1.1c-0.1-0.3-0.3-0.3-0.4-0.4c-0.3-0.2-0.3-0.5-0.1-0.7c0.2-0.3,0.4-0.3,0.7-0.1c0.4,0.3,0.8,0.5,1.1,0.8
		c0.3,0.5,0.7,0.4,1.2,0.4c0.5,0.1,0.8,0.4,1.2,0.6c1.1,0.9,2.3,1.5,3.4,2.2C760.2,710.1,760.8,710,761.3,710.3z"
      />
      <path
        class="st629"
        d="M760.2,712.9c0.8,0.3,1.6,0.8,2.4,0.2c0.6-0.4,1.1-0.9,1.6-1.3c2-1.4,4.1-2.6,6.2-3.9
		c1.2-0.8,2.5-1.5,3.7-2.3c0.5-0.3,1-0.8,1-1.5c0.3-0.4,0.3-1,0.7-1.5c0.6,0.8,0.8,1.5,0.5,2.5c-0.1,0.3,0.1,0.5,0.1,0.8
		c0.2,0.7,0.2,1.4,0.1,2.1c0,0.4-0.1,0.8-0.1,1.2c0,3.7,0,7.4,0,11.1c0,0.3,0.1,0.7-0.3,0.9c0.2-0.7,0.2-1.5,0.2-2.2
		c0-3.9,0-7.9,0-11.8c0-1.3-0.5-1.6-1.8-1.2c-1.3,1.1-2.7,1.8-4.1,2.7c-1.8,1.2-3.7,2.2-5.5,3.3c-0.8,0.5-1.3,1.2-1.6,2.1
		c-0.1,0.3-0.1,0.6-0.4,0.7c-0.1,0-0.2,0-0.3,0C760.9,715.3,760.4,714.3,760.2,712.9z"
      />
      <path
        class="st630"
        d="M739.6,740.2c0.7,0.5,1.4,0.9,2,1.4c0.2,0.2,0.4,0.4,0.6,0.6c-0.1,0.5-0.5,0.3-0.7,0.2c-1-0.7-2-1.2-3-1.9
		s-2-1.4-3.1-2c-1.3-0.7-2.4-1.7-3.7-2.3c-0.3-0.1-0.6-0.4-0.9-0.2c-0.3,0.3-0.5,0.6-0.5,1.1c0.3,3.1,0.3,6.3,0.2,9.4
		c0,1.4-0.2,2.8,0,4.1c0,0.3,0,0.5-0.2,0.7c-0.6-0.6-0.7-1.4-0.7-2.2c0-4,0-7.9,0-11.9c0-1.5,0.4-2,1.8-2.1
		C734.2,736.8,736.9,738.5,739.6,740.2z"
      />
      <path
        class="st631"
        d="M775,745.6c0.7,0.3,0.2,0.5-0.1,0.8c-2,1.3-4.1,2.5-6.2,3.8c-1,0.6-2,1.1-3,1.8c-1.5,1-1.9,2.3-2,3.9
		c-0.1,2.1,0.1,4.2,0,6.3c-0.1,1.8,0,3.6-0.1,5.4c-0.3,0-0.4-0.3-0.4-0.6c0-0.2,0-0.3,0-0.5c0.1-3.2,0-6.5-0.1-9.7
		c0-1.9,0.4-3.5,1.8-4.8c2-1.4,4.1-2.5,6.1-3.8c1.1-0.7,2.2-1.3,3.2-2C774.4,745.8,774.7,745.6,775,745.6z"
      />
      <path
        class="st632"
        d="M746.3,707c0-0.2,0.1-0.3,0.2-0.5c0.1-0.7,0.7-0.9,1.3-1.1c0.8,0,1.4,0.3,2,0.7c0.9,0.7,1.9,1.3,2.8,1.8
		c1.7,1.2,3.5,2.2,5.2,3.3c0.4,0.3,0.8,0.6,1.2,0.9c0.2,0.2,0.4,0.5,0.4,0.8c-0.1,0.2-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2
		c-1.9-1.3-3.8-2.5-5.8-3.6c-1.1-0.6-2.1-1.3-3-2c-0.5-0.4-1.1-0.5-1.7-0.8c-0.5-0.2-1,0.2-1.2,0.7c-0.1,0.5,0,1,0,1.5
		c0,2.2,0,4.3,0,6.4c0,0.8-0.4,1.5-0.3,2.3c0,1,0.2,2,0.2,3.1c0,0.3,0.1,0.7-0.4,0.5c-0.3-0.5-0.5-1-0.5-1.5c0.1-2.5-0.2-5-0.1-7.5
		C746.1,710.3,746,708.6,746.3,707z"
      />
      <path
        class="st633"
        d="M757.3,690c-2.9-1.8-5.7-3.6-8.6-5.4c-0.1-0.3-0.3-0.4-0.5-0.6c-0.2-0.1-0.3-0.3-0.2-0.5
		c0.1-0.3,0.3-0.2,0.6-0.2c1.1,0,2.1,0.5,3.1,1s2,1.2,3,1.8c0.2,0.4,0.8,0.4,0.9,1c-0.2,0.3-0.2,0.6,0.1,0.9c0.3,0.2,0.5,0.2,0.8,0
		c0.3-0.1,0.5-0.1,0.8,0c0.2,0.1,0.3,0.1,0.4,0.3c0,0.2-0.2,0.2-0.3,0.3c-0.6,0.2-0.3,0.4,0,0.6c0.2,0.1,0.4,0.3,0.4,0.5
		C757.7,689.8,757.7,690,757.3,690z"
      />
      <path
        class="st634"
        d="M779.2,703c2.1-1.6,4.5-2.9,6.8-4.3c1.9-1.2,3.8-2.5,5.7-3.7c0.2-0.1,0.2,0,0.3,0.2c0,0.4,0.3,0.4,0.5,0.7
		c-4,1.9-7.7,4.5-11.4,6.8c-0.7,0.4-0.9,1.2-1.2,1.8c-0.5,0.9-0.4,2-0.4,3c-0.1,3.3,0,6.7,0,10c0,0.4,0.1,0.9,0.2,1.3
		c0,0.3,0.4,0.5,0.4,0.8c-0.7-0.2-1.3-0.8-1.2-1.5c0.1-0.8-0.1-1.7,0-2.5c0-2.5,0.2-5,0.2-7.5c0-0.9,0.1-1.8,0.1-2.7
		c0-0.3,0.1-0.7,0.3-0.9C779.5,704,779.5,703.5,779.2,703z"
      />
      <path
        class="st635"
        d="M746.9,740.7c-0.5-0.4-0.5-1-0.5-1.5c0-3.1,0-6.2-0.1-9.3c0-1,0-2,0-2.9c0-1.4,1-2,2.3-1.5
		c0.2,0.1,0.5,0.2,0.7,0.4c2.9,1.8,5.8,3.6,8.7,5.4c0.3,0.2,0.7,0.4,0.8,0.7c-0.4,0.4-0.7,0.1-1,0c-0.8-0.4-1.5-0.8-2.1-1.3
		c-1.7-1.2-3.6-2-5.2-3.3c-0.7-0.5-1.5-0.8-2.2-1.2c-0.5-0.2-0.9-0.1-1.1,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0,3.1-0.1,6.1,0,9.2
		c0,1.2-0.3,2.4,0.1,3.5C747.2,740.4,747.1,740.6,746.9,740.7z"
      />
      <path
        class="st636"
        d="M799.3,748.4c-0.2-0.4,0.1-0.6,0.4-0.8c1.6-1.1,3.4-1.9,5-3c1-0.7,2.2-1,3.2-1.8c0.5-0.4,1.1-0.4,1.5-1
		c0.6-0.7,0.7-1.4,0.6-2.2c-0.2-1.3,0-2.6,0.1-4c0.1-1.3,0.1-2.7,0-4s-0.2-2.6-0.2-4c0-0.4,0.5-0.8,0.3-1.2c0.3,0.4,0.3,0.8,0.3,1.3
		c0,4.2,0,8.4,0,12.5c0,1.1-0.6,1.8-1.4,2.2c-3.2,1.9-6.5,3.8-9.7,5.8C799.4,748.4,799.4,748.4,799.3,748.4z"
      />
      <path
        class="st637"
        d="M746.9,740.7c0-0.1,0-0.3,0-0.4c-0.4-3.5-0.2-7-0.1-10.5c0-1.1-0.2-2.2,0.1-3.2c0.2-0.7,0.7-0.9,1.4-0.6
		c1.6,0.7,2.9,1.8,4.4,2.6c1.9,1,3.6,2.2,5.4,3.3c0.2,0.1,0.5,0.1,0.7,0.1c0,0,0.1,0.1,0.2,0.2c0.1,0.3-0.2,0.4-0.4,0.5
		c-0.3,0.1-0.6,0-0.9-0.2c-2.7-1.8-5.5-3.3-8.2-5.1c-0.5-0.3-1-0.8-1.6-0.5c-0.6,0.4-0.5,1-0.5,1.6c0,3.9,0,7.9,0,11.8
		c-0.1,0.3,0.2,0.7-0.1,1C747.2,741.2,747,741,746.9,740.7z"
      />
      <path
        class="st638"
        d="M782.7,680.3c1.5-1.1,3.1-2,4.7-3c1.6-1,3.2-1.9,4.7-2.8c0.7-0.1,1.2-0.6,1.8-0.8c0.3-0.1,0.6-0.2,0.8,0.2
		c-0.3,0.5-0.8,0.7-1.2,0.9c-2.4,1.5-4.8,3.1-7.3,4.5c-0.7,0.4-1.3,0.9-2,1.3c-0.6,0.4-0.7,1.2-0.8,1.9c-0.4,0.1-0.3-0.3-0.5-0.5
		c0-0.1,0,0,0,0c0.1,0.1,0.1,0.3,0,0.3c-0.4,0.2-0.3-0.2-0.5-0.3C782.3,681.4,783,680.9,782.7,680.3z"
      />
      <path
        class="st639"
        d="M757.3,690c0.4-0.4,0-0.5-0.2-0.7c-0.3-0.2-0.7-0.4-0.6-0.8c0.1-0.4,0.6-0.2,1-0.3c-0.4-0.2-0.7-0.3-1.1,0
		c-0.5,0.3-0.8-0.1-1.1-0.5s-0.1-0.6,0.3-0.8c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1c0.5,0.3,0.9,0.6,1.4,0.7
		c0.7,0.2,1.3,0.8,2,1.2c0.2,0.2,0.6,0.3,0.4,0.7c-0.1,0.3,0,0.5,0.2,0.6c0.3,0.2,0.5,0.1,0.8-0.1c0.2-0.1,0.4-0.5,0.8-0.3
		c0.2-0.1,0.4,0.1,0.6,0.2c0.8,1.1,0.4,2.1-1,2.3c-0.6-0.1-1-0.4-1.5-0.8C758.8,691,758,690.5,757.3,690z"
      />
      <path
        class="st640"
        d="M779.7,704.1c0.6-1.8,2.4-2.4,3.7-3.3c2.3-1.5,4.7-2.9,7.1-4.4c0.6-0.3,1.1-0.8,1.9-0.6
		c0,0.2,0.2,0.2,0.3,0.2c-1.5,0.9-3,1.8-4.5,2.7c-2,1.2-3.9,2.4-5.9,3.6c-1,0.6-1.6,1.4-2,2.5c-0.3,0.2-0.5-0.1-0.6-0.2
		C779.7,704.4,779.6,704.3,779.7,704.1z"
      />
      <path
        class="st641"
        d="M730.5,731.4c0,0,0-0.1,0-0.2c-0.5-0.9-0.3-1.8-0.3-2.7c0.1-1.6,0.1-3.2,0-4.8c-0.1-0.7-0.1-1.5,0-2.2
		c0.1-1.1,0.2-2.1-0.1-3.1c-0.2-0.9,0.1-1.7,0.5-2.5c0.3-0.5,0.7-0.3,1-0.1c2.9,1.6,5.7,3.6,8.5,5.3c0.8,0.5,1.5,1,2.3,1.3
		c0,0.1,0.1,0.1,0.2,0.2c0,0.3-0.2,0.3-0.4,0.3c-0.5,0.1-0.8-0.2-1.2-0.4c-2.5-1.6-5.1-3.2-7.7-4.8c-0.4-0.3-0.8-0.6-1.4-0.7
		c-0.5-0.1-0.8,0-0.9,0.6c0,0.3,0,0.5,0,0.8c0,4.1,0,8.3,0,12.4c0,0.1,0,0.3-0.1,0.4C730.8,731.4,730.7,731.5,730.5,731.4z"
      />
      <path
        class="st642"
        d="M808.9,726.1c0.6,0.3,0.2,0.6-0.1,0.8c-1.6,1-3.1,1.9-4.7,2.9c-1.8,1.1-3.6,2.1-5.4,3.2
		c-0.8,0.5-1.1,1.4-1.3,2.2c-0.2,0.1-0.4,0-0.5-0.1c-0.2-0.3,0-0.6-0.1-0.9c0.3-0.7,0.7-1.3,1.4-1.7c3.5-2.2,7-4.3,10.5-6.4
		C808.7,726.2,808.8,726.2,808.9,726.1z"
      />
      <path
        class="st643"
        d="M798.5,729c-0.3-0.3-0.2-0.5,0.2-0.7c2.3-1.4,4.5-2.8,6.9-4.1c1.5-0.8,2.9-1.8,4.4-2.7
		c0.3-0.2,0.4-0.4,0.4-0.7c-0.2-0.7-0.1-1.3-0.1-2c0.1-1.9,0-3.8,0-5.7s-0.1-3.9,0.1-5.8c0.2,0.2,0.1,0.5,0.1,0.8c0,4,0,8.1,0,12.2
		c0,1.2-0.5,2-1.4,2.6c-3.3,2-6.7,4-10,6C798.9,728.8,798.7,728.9,798.5,729z"
      />
      <path
        class="st644"
        d="M743.5,702.7c-0.1,0-0.1,0-0.2,0c-0.7-0.3-1.1-1-1.8-1.3l0,0c-0.1-0.2-0.4-0.2-0.4-0.5
		c-0.1-0.7-0.7-0.8-1.1-1.2c-0.6-0.5-0.6-0.7,0-1.1c-0.2-0.3-0.8-0.3-0.6-0.9c0.6-0.1,1,0.4,1.5,0.7c1.1,0.7,2.2,1.3,3.4,1.7
		c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.5,0.6,1,0.2,1.7C744.8,702.8,744.3,703,743.5,702.7z"
      />
      <path
        class="st645"
        d="M742.2,722.8c0.1-0.1,0.3,0,0.3-0.2c1,0.8,1.3,1.9,1.3,3.1c0,3.8,0,7.7,0,11.5c0,1.7-0.8,2.1-2.2,1.3
		c-2.5-1.5-4.9-3.1-7.4-4.6c-1-0.6-2-1.2-2.9-1.8c-0.3-0.2-0.7-0.3-0.8-0.7c0.1,0,0.2,0,0.3-0.2c0.2-0.1,0.4,0,0.5,0.1
		c1.1,1,2.6,1.5,3.8,2.3c1.9,1.4,4.1,2.3,5.9,3.9c0.2,0.2,0.5,0.3,0.8,0.4c1.1,0.4,1.5,0.2,1.5-1c0-3.8,0-7.6,0-11.5
		C743.3,724.4,742.8,723.6,742.2,722.8C742.2,723,742.2,722.9,742.2,722.8z"
      />
      <path
        class="st646"
        d="M790.3,753.6c-0.6,0.5-1.2,1.1-2,1.3c-0.4,0.1-0.7,0.5-1,0.8c-1.2,0.7-2.5,1.3-3.7,2.1
		c-0.8,0.5-1.6,1-2.5,1.5c-0.3,0.2-0.7,0.4-1.1,0.4c-0.7,0-1.5-1.2-1.5-1.9c0.2-1.7,0.3-3.3,0-5s-0.2-3.4-0.2-5
		c0-0.6,0.2-1.2,0.1-1.8c0-0.2,0-0.4,0.3-0.4s0.2,0.3,0.2,0.4c0,0.8,0,1.5,0,2.3c0.3,0.3,0.2,0.7,0.2,1.1c0,2.7,0,5.4,0,8
		c0,0.6,0,1.1,0.5,1.6c0.3,0.2,0.7,0.2,1,0c2.8-1.8,5.6-3.4,8.4-5.1C789.6,753.7,789.9,753.3,790.3,753.6z"
      />
      <path
        class="st647"
        d="M796.9,735.2c0.2,0,0.3,0.1,0.5,0.1c-0.2,2,0.3,4,0.1,6c-0.2,1.7-0.1,3.5,0.1,5.3c0,0.4,0,0.8,0,1.2
		c-0.1,0.6,0,1.1,0.7,1.2c-0.5,0.3-1,0.8-1.6,0.2c-0.2-0.9-0.1-1.7-0.1-2.6c0-3.4,0-6.8,0-10.3C796.5,735.9,796.4,735.4,796.9,735.2
		z"
      />
      <path
        class="st648"
        d="M730.6,715.7c0,0.3,0.2,0.5-0.2,0.9c-0.5,0.5-0.5,1.3-0.3,1.9c0.2,0.6,0.3,1.1,0.2,1.7c-0.3,1.9,0,3.7,0,5.6
		c0,1.6-0.1,3.3-0.1,4.9c0,0.2,0.1,0.4,0.1,0.6c-0.6-0.5-0.7-1.2-0.7-2c0-3.9,0-7.7,0-11.6C729.6,716.9,729.6,716,730.6,715.7
		C730.7,715.6,730.7,715.6,730.6,715.7C730.7,715.7,730.7,715.7,730.6,715.7z"
      />
      <path
        class="st649"
        d="M794.7,674c-0.5-0.4-0.8,0-1.2,0.2c-0.5,0.1-0.8,0.6-1.4,0.4c0-0.1,0.1-0.1,0.2-0.2c1.1-0.7,2.3-1.1,3.7-0.9
		c0.5,0.1,0.9,0.2,1.3,0.5c3.1,1.9,6.2,3.8,9.2,5.8c0.5,0.3,0.9,0.7,1.3,1c0.7,0.6,0.8,1.6,0.2,2.3c-0.5,0.6-1.2,1-1.9,1.2
		c-0.1,0-0.2,0-0.2-0.1c-0.2-0.3,0-0.5,0.2-0.7s0.4-0.3,0.6-0.4c0.6-0.3,1.1-0.7,0.9-1.4c-0.2-0.7-0.6-1.2-1.3-1.4c0,0-0.1,0-0.2,0
		c-1.2-0.6-2.3-1.3-3.4-2.1c-2-1.3-4.1-2.4-6.1-3.8C796,674,795.4,674.1,794.7,674z"
      />
      <path
        class="st650"
        d="M778.2,679.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.4,0-0.4-0.3c0-0.2,0.3-0.2,0.4-0.3
		c0.1,0,0.2,0,0.2,0c0.5-0.1,1.1,0,1.6-0.3c2.2-1.2,4.2-2.5,6.3-3.7c0.8-0.4,1.5-0.9,2.2-1.3c0.5-0.3,1-0.6,1.4-0.9
		c0.5-0.3,0.7-0.7,0.8-1.2c0-0.1,0.1-0.1,0.1-0.2c0.5,0,0.4,0.4,0.5,0.7c0.1,0.8-0.4,1.1-0.9,1.5c-2.1,1.5-4.3,2.6-6.5,4
		c-1,0.7-2,1.4-3.2,1.9c-0.7,0.1-1.3,0.4-2,0.3C778.3,679.1,778.3,679.1,778.2,679.2z"
      />
      <path
        class="st651"
        d="M757,671.8c-0.9,1.1-2.1,1.7-3.3,2.4c-2.6,1.6-5.1,3.3-7.8,4.7c-0.1,0.1-0.2,0.1-0.3,0.1
		c-0.7-0.1-1.3,0-2-0.5c0.2-0.4,0.5-0.3,0.8-0.3c0.8,0.1,1.5-0.2,2.2-0.7c2.8-1.8,5.7-3.5,8.5-5.3c0.6-0.4,0.9-1,1.4-1.4
		C757.2,671,757.4,671.3,757,671.8z"
      />
      <path
        class="st652"
        d="M762.8,668.4c-0.1-0.1,0.4-0.3,0-0.3s-0.8,0-1.3,0c2-0.2,3.4-1.5,5-2.5c2-1.2,4-2.3,5.9-3.6
		c0.7-0.4,1.3-0.9,1.1-1.9c0.1-0.1,0.2-0.1,0.2-0.1c0.7,0.7,0.7,1.3,0,1.9c-2,1.5-4.3,2.7-6.4,4.1c-1,0.7-2,1.3-3,1.9
		C763.8,668.1,763.3,668.4,762.8,668.4z"
      />
      <path
        class="st653"
        d="M779.9,704.5c0.2,0.1,0.2,0.4,0.5,0.3c-0.3,0.5,0,0.9,0,1.3c0,3.5,0,7,0,10.5c0,0.9,0.2,1.7,0,2.6
		c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1-0.2-0.3-0.3-0.4-0.5c0-0.5-0.1-0.9,0-1.4c0.1-3.8,0-7.7,0-11.5c0-0.5,0-1,0.3-1.5
		C779.8,704.5,779.8,704.5,779.9,704.5z"
      />
      <path
        class="st654"
        d="M770.4,684.9c-2.2,1.5-4.5,2.9-6.9,4.2c-0.7,0.4-1.5,0.5-2.3,0.5c-0.4,0.2-0.7,0-0.9-0.3c0-0.1,0-0.2,0-0.4
		c0.2-0.2,0.4-0.2,0.6-0.2c1.1,0.1,2.1-0.3,3-0.9c2.6-1.6,5.3-3.1,7.8-4.8c0.7-0.4,1.2-1,1.6-1.7c0.1,0,0.2-0.1,0.2-0.1
		c0.2,0,0.3,0.2,0.3,0.3c0,0.8-0.6,1.2-1.1,1.6C772,684,771.1,684.2,770.4,684.9z"
      />
      <path
        class="st655"
        d="M755.8,693.9c-1.6,1.2-3.4,2.1-5.1,3.2c-1,0.6-2,1.2-2.9,1.7c-0.5,0.1-1,0.5-1.5,0.8c-0.7,0.3-1.4,0.2-2,0.3
		c-0.4,0-0.8,0.1-0.8-0.5c0.3-0.2,0.6-0.2,0.9-0.2c0.8,0.1,1.5-0.2,2.1-0.6c2.7-1.7,5.5-3.4,8.3-5c0.7-0.4,1.2-0.9,1.5-1.6
		c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.2,0,0.2,0c0.1,0.3,0.4,0.6,0.2,1C756.5,693.2,756.2,693.5,755.8,693.9z"
      />
      <path
        class="st656"
        d="M780.6,738.6c1.7-0.7,3-1.9,4.6-2.8c1.7-0.9,3.3-2,4.9-2.9c0.7-0.4,1.4-0.9,2.2-1.3c0.4-0.2,0.8-0.1,0.7-0.7
		c0.1-0.1,0.1-0.1,0.2,0c0,0.5-0.3,0.8-0.7,1.1c-1.6,1.2-3.4,2.1-5.1,3.2c-2,1.3-4.1,2.5-6.1,3.7c-0.4,0.3-0.9,0.5-1.4,0.3
		c0-0.2-0.2-0.3-0.2-0.5s0-0.4,0.2-0.5C780.1,738.2,780.3,738.5,780.6,738.6z"
      />
      <path
        class="st657"
        d="M763.6,768.1c0.5,0.2,0.9,0.3,1.3-0.4s1.2-0.9,1.8-1.3c2.2-1.1,4.2-2.5,6.3-3.8c0.8-0.5,1.6-0.8,2.3-1.4
		c0.5-0.4,0.8-0.9,0.8-1.5c0-1.6,0-3.2,0-4.8c0-0.3-0.1-0.6,0.2-0.8c0,2,0,4.1,0,6.1c0,1-0.7,1.7-1.5,2.2c-1.8,1.1-3.6,2.1-5.4,3.2
		c-1.6,1-3.3,1.9-4.9,2.9c-0.4,0.2-0.8,0.3-1.1-0.2C763.4,768.2,763.5,768.2,763.6,768.1z"
      />
      <path
        class="st658"
        d="M729.3,689.3c-0.3-0.3-0.8,0-1.2-0.2c1.9-0.5,3.5-1.8,5.2-2.7s3.3-2,5-3c0.6-0.3,1.1-0.8,1.4-1.4
		c0.5,0.1,0.5,0.4,0.3,0.8c-1.1,1.2-2.6,1.9-3.9,2.7c-2,1.2-3.9,2.5-5.9,3.5C730,689.2,729.7,689.4,729.3,689.3z"
      />
      <path
        class="st659"
        d="M779.4,738.9c-0.6-0.3-1.2-0.5-0.8-1.5c0.2-0.4-0.2-0.8-0.2-1.3c0-2.1,0.1-4.2,0-6.2c-0.1-1.4,0-2.7,0.2-4.1
		c0-0.1,0-0.2,0-0.3c0-0.8,0-0.8,0.8-1c0.1,0.1,0.1,0.2,0.1,0.4c-0.4,3.1-0.2,6.3-0.2,9.4C779.3,735.8,779.2,737.3,779.4,738.9z"
      />
      <path
        class="st660"
        d="M790.3,753.6c-1.4,0.6-2.6,1.5-3.8,2.2c-1.9,1.1-3.7,2.3-5.6,3.4c-0.3,0.2-0.6,0.4-1,0.1
		c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0-0.4,0.2-0.5s0.2,0.2,0.3,0.3c0.2,0.3,0.4,0.5,0.8,0.1c1.3-1.1,2.9-2,4.4-2.9
		c2.1-1.3,4.3-2.5,6.4-3.8c0.5-0.3,0.5,0.1,0.7,0.2C791.8,752.8,790.9,752.9,790.3,753.6z"
      />
      <path
        class="st661"
        d="M784,685.7c3,2,6.1,3.8,9,5.8c0.5,0.3,0.9,0.7,1.5,0.9c0,0.1,0.1,0.1,0.2,0.2c-0.1,0.5-0.5,0.3-0.8,0.2
		c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3,0.1-0.4-0.1-0.6-0.2c-0.7-0.6-1.4-1.2-2.2-1.6c-1.1-0.5-2.1-1.2-3.1-1.9c-1.2-0.9-2.6-1.4-3.8-2.3
		c-0.2-0.2-0.6-0.2-0.5-0.6C783.3,685.7,783.7,685.7,784,685.7z"
      />
      <path
        class="st662"
        d="M760,713.1c0.3,0.7,0.4,1.5,0.4,2.3c0,4,0,8,0,12.1c0,1.3-0.9,1.8-2,1.1c-3.5-2.2-7-4.3-10.5-6.5
		c-0.1,0-0.2-0.2-0.2,0c-0.3-0.1-0.5-0.3-0.5-0.6c0.1-0.2,0.2-0.2,0.4-0.2c1,0.2,1.6,0.9,2.4,1.4c1.7,1,3.4,2,5,3.1
		c0.9,0.6,1.9,1.3,2.8,1.9c0.3,0.1,0.6,0.3,0.9,0.5c0.6,0.3,1.1,0,1.2-0.6c0.1-0.3,0-0.6,0-0.9c0-2.5,0.1-5,0-7.5
		c-0.1-1.8,0-3.5-0.4-5.2c0-0.2,0-0.5,0.1-0.7C759.7,713,759.8,713,760,713.1z"
      />
      <path
        class="st663"
        d="M763.6,747.7c0.2,0.3,0.4,0.5,0.9,0.2c2.3-1.5,4.6-2.9,6.9-4.3c1.1-0.7,2.2-1.4,3.3-2
		c0.5-0.2,1.1-0.2,1.4-0.8c0,0.7-0.5,1-1,1.3c-3.3,2-6.7,4.1-10,6.1c-0.4,0.3-0.9,0.7-1.5,0.2c0-0.2-0.3-0.4-0.2-0.7
		C763.4,747.7,763.5,747.7,763.6,747.7z"
      />
      <path
        class="st664"
        d="M796.9,735.2c-0.3,0.5-0.2,1-0.2,1.5c0,3.8,0,7.5,0,11.3c0,0.4,0.1,0.8-0.2,1.2c-0.3-0.3-0.4-0.6-0.4-1
		c0-4.2-0.2-8.3,0-12.5c0-0.5,0.3-0.9,0.5-1.3C797.1,734.5,796.7,734.9,796.9,735.2z"
      />
      <path
        class="st665"
        d="M767.2,668.9c0.9-0.6,1.7-1.1,2.6-1.7c0.7,0.1,1.1-0.5,1.6-0.8c1.7-1,3.4-1.9,5-3c0.5-0.4,1.2-0.4,1.9-0.4
		c0.1,0.4-0.2,0.6-0.5,0.7c-3.6,2.1-7.2,4.2-10.5,6.7c0,0-0.1,0-0.2,0.1c-0.5,0.1-0.5-0.3-0.5-0.6
		C766.6,669.4,767.1,669.3,767.2,668.9z"
      />
      <path
        class="st666"
        d="M798.2,713.1c-0.2,0.3-0.3,0.7-0.5,1c-0.4,0.9-0.6,1.8-0.6,2.8c0,3.1,0,6.2,0,9.3c0,0.8,0,1.6-0.2,2.4
		c0,0.2,0,0.3,0,0.5c-0.7,0-0.7-0.5-0.7-1c0-1,0-2,0-2.9c0-2.4,0-4.7,0-7.1c0-0.9,0.3-1.8,0-2.7c0.2-1.1,0.6-2,1.5-2.7
		c0.2,0,0.3,0,0.5,0C798.3,712.8,798.3,712.9,798.2,713.1z"
      />
      <path
        class="st667"
        d="M743.5,699.4c0.2,0.4,0.5,0.3,0.8,0.3c0.1,0,0.2,0,0.1,0.2c0,0.1,0,0.1-0.1,0.2c-1.5,0.1-2.5-1-3.7-1.6
		c-0.4-0.2-0.8-0.6-1.3-0.7c-1.2-0.4-2.2-1.2-3.3-1.9c-1.1-0.9-2.4-1.6-3.7-2.2c-0.2-0.1-0.4-0.1-0.4-0.3c-0.2-0.6,0-1.1,0.3-1.6
		c0.1-0.3,0.4-0.5,0.6,0c0,0.8,0.5,1.3,1.1,1.7c3.1,1.8,6.1,3.9,9.3,5.6C743.4,699.2,743.5,699.3,743.5,699.4z"
      />
      <path
        class="st668"
        d="M781.4,718.8c0.1-0.8-0.5,0.3-0.5-0.1c0-0.3,0.1-0.4,0.3-0.5c2.5-1.3,4.9-3,7.4-4.4c1.3-0.8,2.7-1.5,3.8-2.5
		c0.2-0.2,0.5,0.1,0.6-0.1c-0.1,0.4-0.2,0.7-0.5,0.8C788.8,714.5,785.1,716.7,781.4,718.8z"
      />
      <path
        class="st669"
        d="M792.5,735.8c-3.1,2.6-6.8,4.4-10.2,6.5c-0.3,0.2-0.7,0.5-1,0.7c-0.1-0.1-0.1-0.2-0.1-0.3
		c0-0.2,0.2-0.3,0.2-0.4c2.4-1.7,4.9-3.1,7.4-4.6c0.8-0.5,1.6-1,2.4-1.5C791.5,736,791.9,735.6,792.5,735.8z"
      />
      <path
        class="st670"
        d="M798.2,713.1c0-0.2,0-0.3,0-0.5c0.2-0.5,0.8-0.6,1.2-0.9c3.1-1.9,6.3-3.8,9.4-5.6c0.2-0.1,0.3-0.1,0.3,0.2
		c-1.3,1.5-3.1,2.1-4.7,3.1c-1.9,1.2-3.8,2.4-5.8,3.3C798.4,712.8,798.5,713.2,798.2,713.1z"
      />
      <path
        class="st671"
        d="M779.4,738.9c-0.2-0.4-0.3-0.8-0.3-1.3c0-3.6,0-7.1,0-10.7c0-0.8,0.1-1.7,0.3-2.5c0.5-0.6,0.8-1.5,1.6-1.9
		c0.3,0,0.3,0.1,0.2,0.3c-0.2,0.1-0.1,0.3-0.2,0.5c-0.9,0.6-1.2,1.5-1.2,2.5c0,3.4,0,6.8,0,10.2c0,0.9,0,1.8-0.1,2.7
		c0,0.2,0.3,0.3,0.1,0.5C779.7,739.1,779.6,739,779.4,738.9z"
      />
      <path
        class="st672"
        d="M775.1,701.1c0.2-1.2,0.1-1,1.7-1c0.5-0.3,1.1-0.4,1.6,0.1s0.7,1,0.4,1.7s-0.8,0.9-1.5,0.9
		C776.5,702.2,775.8,701.6,775.1,701.1z"
      />
      <path
        class="st673"
        d="M797.7,712.6c-0.5,0.8-1.2,1.4-1.3,2.4c0.1,1.2-0.2,2.3-0.1,3.5c0,3.1,0,6.3,0,9.4c0,0.4,0.2,0.8,0.2,1.2
		c-0.8,0-0.8-0.7-0.8-1.2c0-0.4,0-0.7,0-1.1c0-3.5,0-7,0-10.4c0-1.5,1-2.5,1.8-3.7C797.4,712.6,797.6,712.7,797.7,712.6z"
      />
      <path
        class="st674"
        d="M792.6,715.9c-3.6,2.6-7.5,4.7-11.3,7c-0.2-0.4,0.3-0.5,0.3-0.8c1.4-1.1,3-1.8,4.4-2.8
		c1.6-1.1,3.3-2.1,5-3.1C791.5,716,792,715.8,792.6,715.9z"
      />
      <path
        class="st675"
        d="M732.6,690.4c0.8-0.5,1.5-1,2.3-1.5c2.5-1.6,5.1-3.1,7.7-4.7c0.5-0.3,1-0.5,1.6-0.5c0.4-0.2,0.7-0.1,0.7,0.5
		c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.1-0.5,0.2-0.8,0.2c-0.5,0-0.8,0.2-1.2,0.4c-0.9,0.6-1.7,1.4-2.8,1.7c-0.4,0.1-0.7,0.5-1.1,0.7
		c-1.2,0.8-2.4,1.6-3.7,2.3c-0.4,0.2-0.8,0.5-1.1,0.8c-0.3,0.2-0.7,0.6-1.2,0.4C732.8,690.7,732.7,690.6,732.6,690.4z"
      />
      <path
        class="st676"
        d="M775.5,725.7c-1.7,1.7-3.9,2.7-5.9,3.9c-1.5,0.9-3,2-4.7,2.7c-0.1-0.1-0.2-0.3-0.1-0.5
		c2-1.3,4.1-2.5,6.2-3.8c1.1-0.7,2.2-1.4,3.3-2C774.6,725.8,775,725.6,775.5,725.7z"
      />
      <path
        class="st677"
        d="M730.6,715.7c0,0,0-0.1,0-0.2c0.4-0.3,0.8-0.2,1.2,0c3.4,2,6.7,4.1,10,6.2c0.2,0.2,0.5,0.3,0.5,0.6
		c-0.4,0.3-0.7-0.1-0.9-0.2c-2.1-1.3-4.2-2.6-6.3-3.9c-1.3-0.8-2.6-1.6-3.9-2.3C731.1,715.8,730.9,715.8,730.6,715.7z"
      />
      <path
        class="st678"
        d="M781.3,742.3c0.1,0.2,0,0.3-0.1,0.4c-0.6,1-1.4,1.9-1.6,3.2c-0.1,0.6,0.1,1.2,0.1,1.8c0,1.3,0,2.5,0,3.8
		c0,1.9,0,3.8,0,5.7c0,0.5-0.3,1-0.1,1.6c0.1,0.2,0.1,0.4,0.2,0.6c-0.7-0.3-0.8-1-0.8-1.6c0-2.9,0-5.8,0-8.6c0-0.3,0-0.5,0-0.8
		c0-0.8,0-1.6,0.1-2.3C779.3,744.3,780,743.1,781.3,742.3z"
      />
      <path
        class="st679"
        d="M783,685.9c0.8,0.7,1.7,1.2,2.6,1.7c1.4,0.7,2.5,1.8,3.9,2.5c0.3,0.2,0.7,0.3,1,0.5c0.2,0.4,0.5,0.9,0.6,1.4
		s0.1,0.9-0.4,1.1c-0.4-0.3-0.2-0.8-0.3-1.2c-0.5-0.8-1.3-1.2-2-1.7c-0.2-0.1-0.3-0.2-0.4-0.4c-1.1-1-2.3-1.6-3.5-2.4
		c-1.2-0.8-2.4-1.7-3.7-2.3c-0.6-0.4-1.3-0.8-2-0.9c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.2-0.2,0.2-0.2
		c1.5-0.1,2.5,1,3.7,1.7C782.5,685.6,782.7,685.8,783,685.9z"
      />
      <path
        class="st680"
        d="M765.2,728.3c0.1-0.2-0.3-0.8,0.5-0.6c0.1,0,0.3-0.1,0.3-0.2c0.8-1,2-1.4,3-2.1c0.9-0.6,1.9-1.1,2.7-1.7
		c0.6-0.4,1.2-0.4,1.8-0.7c0.7-0.4,1.1-1.2,1.9-1.5c0.3,0.2-0.4,0.4,0,0.6c-1.4,1.1-3,1.9-4.5,2.9
		C769,726.2,767.2,727.3,765.2,728.3z"
      />
      <path
        class="st681"
        d="M778.6,683.7c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-1.8,0.5-3.2,1.8-4.8,2.8
		c-0.6,0.4-1.3,0.7-2,1c-0.4,0.2-0.7,0.4-0.9,0.8c0,0.1-0.1,0.1-0.2,0.2c-1.1,0.4-2.2,1-3.3,1.6c-0.2-0.1-0.2-0.2-0.2-0.4
		c2.4-1.7,5-3.2,7.6-4.7c0.6-0.4,1.2-0.7,1.8-1C777,683.9,777.7,683.7,778.6,683.7z"
      />
      <path
        class="st682"
        d="M727.2,689.5c0.2,0,0.3,0,0.5,0.1c0,0.1-0.4,0-0.1,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.5,0.4,1.1,0.1,1.7
		c-0.3,0.5-0.5,1.2-1.4,0.8c-0.1-0.1-0.2-0.2-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.5-0.2-0.3-0.5c0.2-0.2,0.5,0.1,0.8-0.1
		c-1-0.7-2-1.2-2-2.5c0.5-0.2,0.8,0.4,1.3,0.3C726.4,689.6,726.8,689.7,727.2,689.5z"
      />
      <path
        class="st683"
        d="M747.4,741.3c0.1-0.3-0.2-0.6,0-0.9c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.3c1.2,0.9,2.2,2,3.7,2.5
		c1,0.3,1.7,1.2,2.6,1.6c1.5,0.8,2.9,1.8,4.4,2.8c0.4,0.3,0.8,0.3,1.3,0.4c0,0.1,0,0.2,0,0.2c-0.5,0.4-0.9,0.2-1.3,0
		c-3.5-2.2-7-4.3-10.6-6.5C747.6,741.6,747.5,741.5,747.4,741.3z"
      />
      <path
        class="st684"
        d="M747.1,721.5c0.1,0.2,0.4,0.3,0.4,0.6c-0.6-0.2-1.6-1.5-1.6-2.2c0-1.8,0-3.6-0.1-5.4c-0.2-2.3,0-4.6,0.3-6.9
		c0-0.4-0.3-0.8,0-1.2c0.2,0.2,0.1,0.4,0.2,0.6c0,3.9,0,7.9,0,11.8c0,0.8-0.1,1.6,0.5,2.2C747,721,747.1,721.2,747.1,721.5z"
      />
      <path
        class="st685"
        d="M725.6,729.1c1.1-0.3,1.3-0.5,1.3-1.8c0-4,0-8,0-12c0-1-0.4-2-1.3-2.6c0.1,0,0.1-0.1,0.2-0.2
		c1,0.7,1.4,1.8,1.5,2.9c0,4.2,0,8.3,0,12.5C727.2,728.9,726.5,729.4,725.6,729.1z"
      />
      <path
        class="st686"
        d="M791,671.3c-0.1-0.2-0.3-0.4-0.4-0.6c-0.7-0.6-1.4-1.2-2.3-1.7c-0.1,0-0.1-0.1-0.2-0.1
		c-2.6-1.7-5.3-3.2-7.8-4.9c-0.2-0.2-0.5-0.2-0.7-0.3s-0.5,0-0.7-0.1c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1,0-0.2,0.1-0.3
		c0.1-0.2,0.2-0.2,0.3,0c0.6,0.2,1.1,0.4,1.7,0.7c2,1.2,3.9,2.5,5.9,3.7c1.2,0.8,2.5,1.5,3.7,2.3c0.5,0.4,1,0.9,0.9,1.6
		C791,671.2,791,671.3,791,671.3z"
      />
      <path
        class="st687"
        d="M753.1,688.3c1.6,0.8,3.1,1.7,4.6,2.7c0.4,0.3,0.7,0.7,0.9,1.2c0.2,0.4,0.1,0.8-0.4,1s-0.7-0.1-0.9-0.6
		s-0.3-0.9-0.6-1.3c-1-1-2.4-1.6-3.5-2.5C753.4,688.7,753,688.6,753.1,688.3z"
      />
      <path
        class="st688"
        d="M764.8,731.9c0,0.2,0.1,0.3,0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-1.5,1.7-1.4,3.7-1.1,5.7
		c-0.1,3.1,0.2,6.2,0,9.3c0,0.3,0.3,0.5,0.2,0.7c-0.5-0.3-0.7-0.8-0.7-1.4c0-3.9,0-7.9,0-11.8C763,733.8,763.5,732.7,764.8,731.9z"
      />
      <path
        class="st689"
        d="M749.7,700.7c2.4-1.7,5-3,7.5-4.6c0.8-0.5,1.7-1,2.5-1.5c0.2-0.1,0.2,0,0.2,0.1c-0.2,0.5-0.5,0.7-0.9,0.9
		c-1.1,0.6-2.3,1.1-3.2,1.8c-1.4,1.1-2.8,1.9-4.4,2.7c-0.3,0.2-0.6,0.5-0.9,0.7s-0.5,0.5-1,0.5C749.2,701.1,749.7,701,749.7,700.7z"
      />
      <path
        class="st690"
        d="M749.8,679.5c1.8-1.3,3.8-2.4,5.7-3.6c1.2-0.8,2.5-1.5,3.8-2.2c0.7-0.4,1.4-0.6,2.2-0.5
		c0.2,0.1,0.3,0.2,0.2,0.4c-1.6,0.3-3,1-4.4,1.9c-0.8,0.5-1.6,1.2-2.5,1.7c-1.4,0.7-2.7,1.6-4,2.4c-0.2,0.1-0.4,0.2-0.7,0.2
		C749.9,679.7,749.9,679.6,749.8,679.5z"
      />
      <path
        class="st691"
        d="M732.6,690.4c0.1,0,0.2,0.1,0.3,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.8,0.6-0.7,1.5-0.7,2.4
		c-0.1,0.2-0.2,0.3-0.3,0.5c-0.6,0.7-0.9,0.8-1.7,0c-0.1-1.1,0.5-1.8,1.2-2.5C731.7,691,732.1,690.8,732.6,690.4z"
      />
      <path
        class="st692"
        d="M763.6,768.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-0.3-0.5-0.7-0.5-1.1c0-4,0-8,0-12.1c0-1.3,0.6-2.5,1.8-3.3
		c-0.2,0.7-0.8,1-1,1.6c-0.4,0.8-0.6,1.6-0.6,2.5c-0.1,3.5,0.2,7,0.1,10.5c0,0.4,0.1,0.8,0.3,1.1
		C763.7,767.7,763.4,767.9,763.6,768.1z"
      />
      <path
        class="st693"
        d="M762.9,714.9c0.1-0.3,0.1-0.5,0.2-0.8c0.4,0.2,0.3,0.6,0.3,1c0,0.3,0,0.7,0,1c0,3.2,0,6.5,0,9.8
		c0,0.8-0.3,1.7,0.1,2.6c0.1,0.1,0.2,0.2,0,0.4c-0.6-0.3-0.7-0.9-0.7-1.5C762.9,723.2,762.9,719,762.9,714.9z"
      />
      <path
        class="st694"
        d="M749.7,700.7c0.3,0.4-0.3,0.4-0.3,0.7v0.1c-0.5,0.9-0.7,1.8-1.1,2.7c0,0.1,0,0.2-0.1,0.2
		c-0.1,0.4-0.4,0.6-0.8,0.3c-0.3-0.2-0.6-0.2-0.8-0.6C746.8,702.3,748.2,701.5,749.7,700.7z"
      />
      <path
        class="st695"
        d="M761.9,694.2c1-0.1,1.8,0.4,2.5,0.9c2.3,1.7,4.8,3.1,7.2,4.8c0.5,0.4,1.2,0.5,1.6,1c0.2,0.1,0.3,0.2,0.2,0.5
		c-0.4,0.1-0.6-0.2-0.9-0.4c-0.4-0.3-0.8-0.5-1.2-0.7c-1.5-0.8-2.8-1.8-4.2-2.6c-1-0.7-1.9-1.2-2.9-1.8c-0.7-0.5-1.6-0.8-2.4-1.2
		C761.7,694.4,761.8,694.3,761.9,694.2z"
      />
      <path
        class="st696"
        d="M796.4,729.1c-0.4-0.5-0.3-1-0.3-1.5c0-3.9-0.1-7.8,0.1-11.8c0-0.3-0.1-0.5,0.2-0.8c0.2,3-0.1,6,0,8.9
		c0,1.4,0,2.7,0,4.1c0,0.5,0,0.9,0.5,1.1c0.1,0.1,0.3,0.1,0.2,0.4C796.9,729.3,796.7,729.2,796.4,729.1z"
      />
      <path
        class="st697"
        d="M740.1,682.7c0-0.3-0.1-0.6-0.3-0.8c-0.1-0.9-0.8-1.4-1.4-1.8c-0.3-0.2-0.7-0.4-1-0.7
		c0.2-0.5,0.5-0.2,0.7-0.1c0.3,0.2,0.7,0.4,1,0.7c0.2,0.1,0.4,0.4,0.7,0.1c0.7,0.3,1.2,0.9,1.8,1.3c0,0.2,0,0.3,0.1,0.5
		c0.2,0.6,0,0.8-0.6,0.8C740.7,682.8,740.4,682.8,740.1,682.7z"
      />
      <path
        class="st698"
        d="M776.5,681c0.2-0.8,1-1.2,1.5-1.2c0.6,0,0.4-0.2,0.4-0.5c0.2,0.1,0.3,0.1,0.5,0.2c0.7,0.9,0.5,1.9-0.4,2.4
		C777.6,682,777,681.6,776.5,681z"
      />
      <path
        class="st699"
        d="M753.1,688.3c0,0.2,0.4,0.2,0.2,0.5c-0.4,0.1-0.6-0.1-0.9-0.3c-0.8-0.5-1.7-1-2.5-1.5
		c-0.1,0-0.1-0.1-0.2-0.1c-1.2-0.9-2.4-1.7-3.8-2.3c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1,0-0.1,0-0.2-0.1c-0.2-0.2-0.2-0.6-0.7-0.5
		c1-0.3,1.8,0.1,2.6,0.5c1.3,0.8,2.6,1.6,3.9,2.5C751.5,687.2,752.3,687.8,753.1,688.3z"
      />
      <path
        class="st700"
        d="M773.4,701.3c0-0.2-0.1-0.3-0.2-0.5c1.1,0.3,2,0.9,2.6,1.8c-0.1,0.6,0,1.2-0.7,1.5c-0.5-0.2-0.8-0.7-1.1-1.1
		C773.8,702.5,773.4,702,773.4,701.3z"
      />
      <path
        class="st701"
        d="M759.7,748.2c0-0.1,0-0.1,0-0.2c0.5-2.3,0.3-4.6,0.2-6.9c0-2.1-0.1-4.3-0.1-6.4c0-0.8-0.4-1.4-0.9-2
		c-0.1-0.1-0.1-0.1-0.2-0.2c0.1-0.2,0.3-0.1,0.3-0.3c0.8,0.8,1.3,1.7,1.3,2.9c0,3.9,0,7.8,0,11.7
		C760.4,747.3,760.2,747.9,759.7,748.2z"
      />
      <path
        class="st702"
        d="M757,671.8c0.1-0.4-0.1-0.7-0.4-1c-0.7-0.5-1.3-1.3-2.1-1.6c-0.1-0.1-0.2-0.2-0.3-0.3
		c0.2-0.4,0.5-0.2,0.7-0.1c0.7,0.3,1.3,0.8,1.8,1.5c0-0.2-0.1-0.4,0.1-0.6c0.8,0.2,1.2,0.9,1.8,1.3c0,0.1,0,0.3,0,0.4
		c0,0.9-0.4,1-1.1,0.6C757.4,672,757.2,671.9,757,671.8z"
      />
      <path
        class="st703"
        d="M749.8,679.5c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0.5-0.6,0.7-0.7,1.2c-0.2,0.6-0.2,1.2-0.3,1.8
		c-0.5,0.5-1,0.5-1.6,0.2c-0.4-0.7,0-1.2,0.4-1.7C748.3,680.3,749,679.8,749.8,679.5z"
      />
      <path
        class="st704"
        d="M761.5,673.5c0.1-0.2,0-0.2-0.1-0.3c1.4-0.1,2.6,0.6,3.7,1.3c1.5,1,3.1,2.1,4.7,3.1c0.1,0.1,0.1,0.2,0.2,0.2
		c-0.4,0.3-0.7,0-1-0.2c-1.5-0.9-3-1.8-4.4-2.8c-0.3-0.2-0.5-0.4-0.9-0.4c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.4-0.1-0.5-0.2
		c-0.3-0.1-0.6-0.1-0.8-0.4C761.5,673.6,761.5,673.5,761.5,673.5z"
      />
      <path
        class="st705"
        d="M772.7,679.5c1.1,0.4,2.1,1,2.7,2.1c0.3,0.4,0.3,0.9-0.2,1.2c-0.5,0.2-0.9,0-1-0.6c0-0.1-0.1-0.1-0.1-0.2
		c-0.3-0.4-0.6-0.9-0.8-1.4C773.2,680.2,772.6,680,772.7,679.5z"
      />
      <path
        class="st706"
        d="M767.2,668.9c0.1,0,0.3,0.1,0.1,0.2c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.8-0.3,1.7-0.7,2.4
		c-0.4,0.3-0.8,0.3-1.3,0.2c-0.3-0.9,0-1.7,0.7-2.3C765.9,669.7,766.5,669.2,767.2,668.9z"
      />
      <path
        class="st707"
        d="M730,693.9c0.9,0.7,1.4,0,1.9-0.5c0.1,0,0.1,0,0.2,0c1.3,0.5,2.5,1.3,3.6,2c0.2,0.1,0.4,0.2,0.5,0.5
		c-0.3,0.3-0.6,0.1-0.8-0.1c-0.6-0.5-1.4-0.8-2.1-1.3c-1.2-0.7-1.5-0.6-1.7,0.8c-0.1,0.1-0.3,0-0.4,0.1c-0.1,0,0,0.2,0,0.1
		c0-0.1,0-0.1,0-0.1c0.2,0,0.4-0.1,0.4,0.2c-0.6-0.2-1.1,0.2-1.6,0.5c-0.1-0.1-0.1-0.2-0.2-0.3C730.1,695.1,729.9,694.5,730,693.9z"
      />
      <path
        class="st708"
        d="M773.4,680.5c0.3,0.5,0.6,0.9,0.7,1.4c-0.1,0.3,0,0.7-0.4,0.8c-0.1-0.3,0.3-0.6,0.1-1c0-0.1,0-0.2-0.1-0.3
		c-2-2.2-4.8-3.4-7.2-5.1c-0.8-0.5-1.5-1-2.3-1.6c-0.2-0.1-0.4-0.3-0.4-0.5c0.9,0,1.4,0.6,2,1.1c1.2,0.9,2.6,1.6,3.9,2.5
		c0.1,0.1,0.3,0,0.4,0l0,0c0.7,0.6,1.4,1,2.1,1.4C772.6,679.6,773,680,773.4,680.5z"
      />
      <path
        class="st709"
        d="M809.1,706.3c-0.1-0.1-0.2-0.1-0.3-0.2c1-0.5,1.6,0,1.6,1.1c0,4.1,0,8.1,0,12.2c0,0.5,0,0.9,0,1.4
		c-0.5-0.5-0.3-1.1-0.3-1.6c0.2-2.2,0.2-4.3,0-6.5c-0.1-1,0.2-2,0.1-3c0-0.9,0.1-1.8-0.1-2.7C810,706.4,809.7,706.2,809.1,706.3z"
      />
      <path
        class="st710"
        d="M766.8,690.1c0.2,0.1,0.2,0.2,0.2,0.3c-0.6,0.8-0.9,1.8-1.1,2.7c0,0.1-0.1,0.2-0.3,0.2
		c-0.5-0.2-0.7,0.2-0.8,0.6c-0.5-1.5-0.3-2.2,1-3.2C766.1,690.6,766.5,690.3,766.8,690.1z"
      />
      <path
        class="st711"
        d="M778.6,662.9c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.3-0.2c-1.2,0.2-2.2,0.7-3.2,1.4c-1.5,1.1-3.2,1.7-4.7,2.8
		c-0.2,0.2-0.5,0.2-0.7-0.1c2.3-1.4,4.6-2.8,6.9-4.2C777.3,662.9,777.9,662.8,778.6,662.9z"
      />
      <path
        class="st712"
        d="M773.7,661.9c0.4-0.6,0.4-1.2,0-1.8c-0.2-0.3-0.5-0.5-0.5-1c0.3-0.2,0.4,0,0.7,0.2c0.5,0.4,1,0.6,1.5,1
		c0.1,0.3,0.3,0.5,0.3,0.8c0,0.5-0.1,0.8-0.7,0.7C774.5,661.6,774.2,662,773.7,661.9z"
      />
      <path
        class="st713"
        d="M791,671.2c-0.1-0.6-0.7-1.1-0.9-1.6c1.1,0,2.3,1.2,2.4,2.1c0,0.4-0.2,0.5-0.3,0.8c-0.6,0.1-1.1-0.1-1-0.8
		C791.3,671.4,791.2,671.3,791,671.2z"
      />
      <path
        class="st714"
        d="M745.6,678.8c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5,0.1,0.7c0.4,0.6,0.2,1.1-0.2,1.6c-0.3,0.6-0.7,0.9-1.4,0.8
		c0.1-0.6,0.4-1.1,0.5-1.7c0-0.3,0-0.5-0.2-0.7c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.1-0.2,0.1-0.3
		C744.7,678.7,745.2,678.9,745.6,678.8z"
      />
      <path
        class="st715"
        d="M743.5,702.7c0.7,0.2,1.2-0.1,1.5-0.7c0.3-0.5,0.5-1.1,0-1.7c0.7-0.1,1.2,0.2,1.4,0.9
		c0.1,0.4-0.8,1.6-1.4,1.7C744.5,703,743.9,703.2,743.5,702.7z"
      />
      <path
        class="st716"
        d="M782.7,680.3c0.4,0.1,0.5,0.2,0.3,0.6c-0.2,0.3-0.3,0.7-0.3,1.1c0.2,0.9,0.1,1-1,1c-0.3,0-0.3-0.2-0.4-0.4
		C781.1,681.4,781.9,680.9,782.7,680.3z"
      />
      <path
        class="st717"
        d="M778.4,681.9c0.7-0.7,0.7-1.6,0.4-2.4c1-0.2,1.3,0,1.3,1C780,681.7,779.4,682.3,778.4,681.9z"
      />
      <path
        class="st718"
        d="M747.4,682.8c0.5-0.1,1.1,0.2,1.5-0.3c0.3-0.1,0.4,0.1,0.6,0.2c0.5,0.3,0.9,0.6,1.4,0.9
		c0.3,0.2,0.7,0.4,0.6,0.8c-0.4,0-0.6-0.3-0.9-0.4c-0.7-0.4-1.5-0.2-2.3-0.6c-0.1-0.1-0.2,0-0.3,0.1c-0.1,0.2,0,0.2,0.1,0.3
		c0.2,0.2,0.7,0.2,0.5,0.7C748.1,684.1,747.3,683.7,747.4,682.8z"
      />
      <path
        class="st719"
        d="M764.7,672.5c0.3-0.3,0.9,0,1.2-0.4c0.2-0.3,0.4-0.2,0.7-0.1c0.3,0.3,0.5,0.6,0.9,0.8
		c0.3,0.2,0.8,0.3,0.7,0.8c-0.3-0.1-0.6-0.2-0.9-0.4c-0.4-0.2-0.8-0.6-1.1,0.2c-0.1,0.2-0.6,0.1-0.5,0.6
		C765.1,673.7,764.7,673.2,764.7,672.5z"
      />
      <path
        class="st720"
        d="M726.9,692.5c1.1,0,1.7-1.2,1.3-2.5c1.1,0,1.5,0.8,0.9,1.7C728.5,692.8,727.9,693,726.9,692.5z"
      />
      <path
        class="st721"
        d="M792.6,692.2c0.2,0.1,0.3,0.2,0.5,0.3c0,0.2,0,0.5,0.1,0.6c0.7,0.5,0.2,1,0,1.6c-0.2,0.7-0.9,0.1-1.3,0.4
		c-0.1,0-0.2-0.1-0.3-0.2c0.2-0.3,0.5-0.6,0.5-1C792.3,693.4,792.3,692.8,792.6,692.2z"
      />
      <path
        class="st722"
        d="M793.1,730.9c-0.1,0-0.1,0-0.2,0c0.2-0.8,0.2-1.6,0.2-2.4c0-4.1,0-8.2,0-12.3c0.3,0.3,0.2,0.6,0.2,0.9
		c0,4.2,0,8.5,0,12.8C793.3,730.2,793.3,730.6,793.1,730.9z"
      />
      <path
        class="st723"
        d="M761,692.3c1.2-0.6,1.4-0.9,1-2.3c0.2,0,0.4,0,0.5,0.1c0.5,0.1,1,0.2,0.6,0.9c-0.2,0.3-0.2,0.6-0.4,0.9
		C762.3,692.5,761.7,692.6,761,692.3z"
      />
      <path
        class="st724"
        d="M761,671.4c1.2-0.2,1.5-0.8,1.2-2.3c0.9,0,1.2,0.4,0.9,1.2C762.6,671.6,762.3,671.8,761,671.4z"
      />
      <path
        class="st725"
        d="M712.9,705.3c-0.1-0.6,0-1.1,0.3-1.6s0.6-0.5,0.9,0c0.2,0.4,0.4,0.7,0.7,1.1
		C714.2,705.4,713.5,705.2,712.9,705.3z"
      />
      <path
        class="st726"
        d="M746.6,704.1c0.3,0.1,0.6,0.2,0.8,0.4c0.4,0.4,0.5,0,0.6-0.2c0.3,0.4,0.2,0.7-0.1,1c-0.1,0-0.1,0.1-0.2,0.2
		c-0.5,0.2-1,0.5-1.3,1C746.5,705.7,746.3,704.9,746.6,704.1z"
      />
      <path
        class="st727"
        d="M781.2,682.6c0.1,0.1,0.2,0.2,0.3,0.3c0.5-0.1,1,0,1.6,0.1c0.2,0.1,0.6,0.2,0.5,0.6c-0.2,0,0,0.4-0.3,0.2
		s-0.7-0.3-1,0c-0.3,0.4,0.4,0.4,0.4,0.8l-0.1,0.1C781.8,684.2,781.1,683.7,781.2,682.6z"
      />
      <path
        class="st728"
        d="M749.8,686.9c0.9,0.2,1.6,0.8,2.4,1.3c0.3,0.2,0.7,0.4,1,0.7c0.8,0.5,1.5,1,2.2,1.4c0.5,0.3,0.9,0.7,1.2,1.2
		c0,0.1,0,0.2,0,0.3h-0.1h-0.1c-1.8-1.7-4-2.7-6-4.1C750.3,687.4,749.8,687.3,749.8,686.9z"
      />
      <path
        class="st729"
        d="M712.9,705.3c0.5-0.4,1.2-0.2,1.8-0.5c0.3,0.2,0.3,0.5,0.3,0.8c-0.9-0.2-1.5,0.5-2.1,1
		C712.9,706.2,712.9,705.7,712.9,705.3z"
      />
      <path
        class="st730"
        d="M760.3,689.3c0.3,0.1,0.6,0.2,0.9,0.3c0,0.1,0.1,0.1,0.1,0.2c-0.3,0-0.4,0.4-0.7,0.5s-0.6,0.4-0.9,0
		c-0.2-0.2-0.7-0.4-0.2-0.9c0.2-0.2-0.3-0.3-0.3-0.5C759.7,688.6,760,689,760.3,689.3z"
      />
      <path
        class="st731"
        d="M744.8,679.5c0.6,0.6,0.4,1.6-0.3,2.4c-0.2,0-0.5,0.1-0.7-0.2c0.1-0.6,0.6-1,0.9-1.5
		C744.9,680,744.9,679.8,744.8,679.5z"
      />
      <path
        class="st732"
        d="M773.7,681.8c0.3,0.3,0,0.7-0.1,1c-0.9,0.9-2,1.6-3.2,2.2c0.2-0.5,0.4-0.6,0.9-0.8c0.8-0.4,1.7-0.8,2.2-1.7
		C773.7,682.2,773.7,682,773.7,681.8z"
      />
      <path
        class="st704"
        d="M773.4,680.5c-0.8-0.8-1.8-1.4-2.8-2c-0.3-0.2-0.5-0.3-0.6-0.6c1,0.4,1.8,1.1,2.8,1.6
		C772.8,679.9,773.4,680,773.4,680.5z"
      />
      <path
        class="st733"
        d="M761.9,694.2c0,0.1-0.1,0.2-0.1,0.3s0,0.1-0.1,0.2c-0.5,0-0.9,0.5-1.5,0.5c-0.3,0-0.3-0.2-0.4-0.4
		c-0.1-0.1-0.1-0.1-0.2-0.2C760.3,694.1,761.1,694.1,761.9,694.2z"
      />
      <path
        class="st734"
        d="M744.5,699.9c0,0-0.1-0.1-0.1-0.2c1.3,0.1,2.3-0.7,3.4-0.9C746.8,699.5,745.8,700.1,744.5,699.9z"
      />
      <path
        class="st735"
        d="M760,713.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.2-0.3-0.3c-0.2-0.9-1-1-1.5-1.6c0.1-0.1,0.2-0.1,0.2,0
		c0.8,0.4,1.3,1,2,1.6C760,712.9,760,713,760,713.1z"
      />
      <path
        class="st625"
        d="M727.7,699.9c-0.3-0.4-0.1-0.9-0.2-1.4c0-1,0-2.1,0-3.2c0.3,0.5,0.1,1,0.2,1.6
		C727.7,697.9,727.7,698.9,727.7,699.9z"
      />
      <path
        class="st668"
        d="M793.1,711c0-1.1,0-2.2,0-3.3c0-0.3-0.1-0.5,0.2-0.8c0,1.2,0,2.4,0,3.6C793.3,710.7,793.3,710.9,793.1,711z"
      />
      <path
        class="st625"
        d="M728.7,695.1c0,0.3,0,0.5-0.3,0.5s-0.3-0.3-0.3-0.5s0-0.5,0.2-0.5C728.5,694.6,728.6,694.9,728.7,695.1z"
      />
      <path
        class="st715"
        d="M741.5,701.4c0.8,0.1,1.2,0.9,1.8,1.3c-0.1,0-0.2,0.1-0.3,0.1s-0.1,0-0.2,0
		C742.6,702.1,741.9,701.9,741.5,701.4z"
      />
      <path
        class="st736"
        d="M778.3,679c0.7-0.2,1.3-0.5,2-0.3C779.7,679,779,679.3,778.3,679z"
      />
      <path
        class="st737"
        d="M755.8,693.9c0.2-0.5,0.7-0.8,1-1.2c0,0,0,0,0.1,0c0,0,0,0,0,0.1C756.8,693.4,756.3,693.6,755.8,693.9z"
      />
      <path
        class="st625"
        d="M727.5,694.8c-0.3-0.5-0.1-1-0.5-1.5c0.3,0,0.5,0.1,0.5,0.4C727.5,694.2,727.5,694.5,727.5,694.8z"
      />
      <path
        class="st625"
        d="M728.4,709.4c-0.1,0.2-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.2-0.2,0.4-0.2
		S728.4,709.3,728.4,709.4z"
      />
      <path
        class="st738"
        d="M781.7,722.1c0.1,0.4-0.4,0.5-0.3,0.8c-0.1,0-0.1,0-0.2,0c0-0.2,0-0.3-0.2-0.3
		C781.2,722.3,781.4,722.2,781.7,722.1z"
      />
      <path
        class="st739"
        d="M726,692.4L726,692.4c-0.1,0.3-0.2,0.3-0.3,0.4c-0.1,0-0.2,0-0.2,0c-1.2-0.3-2.1-1.2-3.2-1.8
		c-0.5-0.3-1.1-0.6-1.6-1c-1.7-1.1-3.4-2.1-5.1-3.2c0,0,0,0-0.1,0c-0.4-0.2-0.7-0.5-1.1-0.2c-0.4,0.3-0.2,0.7-0.2,1.1
		c0.1,3.9,0,7.8,0,11.7c0,0.4-0.1,0.9-0.2,1.3c-0.2,0.3,0,0.7-0.3,0.9c-0.6-0.6-0.9-1.3-0.9-2.2c0-4.5,0-9,0-13.4c0,0,0.1,0,0.1-0.1
		c0.2-0.4,0.5-0.6,0.9-0.8c0.5-0.2,1-0.3,1.5,0c1.9,1.3,3.9,2.5,5.9,3.6c1.1,0.7,2.2,1.4,3.3,2.1C725.1,691.3,725.8,691.6,726,692.4
		z"
      />
      <path
        class="st740"
        d="M730.8,672.7c-0.2-0.9,0-1.6,0.8-2.1c3.9-2.2,7.6-4.7,11.5-7c0.5-0.3,1.1-0.7,1.8-0.5
		c0.2,0.1,0.3,0.2,0.3,0.5c-0.1,0.5-0.5,0.7-0.8,1c-0.9,0.8-2,1.3-3,1.9c-2,1.2-4,2.5-6,3.8c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.5,0.3-1.1,0.3-1.6,0c-0.5,0.1-0.7,0.4-0.7,0.8c-0.1,0.4-0.2,0.7-0.3,1C731.7,672.8,731.2,672.8,730.8,672.7z"
      />
      <path
        class="st741"
        d="M762,652.9c-0.5,0.9-1.5,1.3-2.3,1.8c-2.6,1.5-5.2,3.2-7.8,4.9c-0.1,0.1-0.3,0.1-0.4,0.2
		c-0.2-0.3-0.2-0.3-0.3,0c-0.2,0.4-0.4,0.6-0.9,0.3c-0.1-0.1-0.2-0.1-0.4,0c-0.6,0.1-0.7,0.6-0.7,1c-0.1,0.7-0.5,1.1-1.2,1.2l0,0
		c-0.1,0.1-0.2,0.1-0.3,0c-0.2-0.9,0-1.6,0.8-2.1c3.8-2.4,7.7-4.8,11.5-7.1c0.5-0.3,0.9-0.6,1.5-0.5
		C761.7,652.5,762,652.5,762,652.9z"
      />
      <path
        class="st742"
        d="M728.8,674.2c-1.2,0.8-2.4,1.5-3.7,2.2c-2.3,1.5-4.7,3-7,4.4c-1.4,0.8-1.5,1-0.3,2.1c-0.8,0.2-1-0.5-1.3-1
		c-0.2-0.3-0.5-0.8,0-1.1c0.2-0.2,0.2-0.5,0.3-0.7c2.5-1.6,5-3.1,7.5-4.7c0.8-0.5,1.6-1,2.5-1.5c0.5-0.3,1-0.4,1.6-0.2
		C728.6,673.7,728.7,673.9,728.8,674.2z"
      />
      <path
        class="st743"
        d="M716.8,680.1c0.1,0.4,0.3,0.8-0.3,1c-0.2,0-0.1,0.2-0.1,0.3c0.3,0.7,0.6,1.3,1.4,1.5
		c2.8,1.7,5.6,3.4,8.4,5.2c0.4,0.3,0.9,0.4,0.9,1.1c0,0.1-0.2,0.2-0.3,0.2c-1.1-0.2-1.9-0.9-2.8-1.4c-2-1.1-3.8-2.5-5.9-3.6
		c-0.8-0.4-1.7-0.9-2.3-1.7c-0.2-0.4-0.2-0.9-0.2-1.4c-0.6,0.5-0.5,1.2-0.7,1.8c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.2-0.5,0.6-0.8,0.8
		c-0.3,0.3-0.6,0.6-1,0.2c-0.4-0.9-0.2-1.7,0.6-2.3C714.6,681.2,715.7,680.7,716.8,680.1z"
      />
      <path
        class="st744"
        d="M728.8,674.2c-0.3,0-0.3-0.3-0.5-0.5c0.4-0.5,0.9-0.2,1.2,0c2.3,1.5,4.6,3.1,6.9,4.5c1.1,0.7,2.1,1.5,3.3,2
		c-0.1,0.2,0,0.5-0.4,0.3c-0.5-0.3-0.9-0.6-1.4-0.8c-0.2-0.1-0.4-0.4-0.7-0.1c-2.3-1.5-4.7-3-7-4.4
		C729.8,674.7,729.3,674.5,728.8,674.2z"
      />
      <path
        class="st745"
        d="M762,652.9c-0.1-0.2-0.3-0.3-0.5-0.4c0.9-0.2,1.5,0.2,2.2,0.6c3.2,2,6.4,4,9.5,6c0.2,0.3,0.5,0.6,0.5,1
		c-0.1,0-0.1,0-0.2,0c-0.3-0.5-0.8-0.8-1.3-1.2c-2.7-1.7-5.4-3.3-8.1-5.1C763.4,653.4,762.8,653,762,652.9z"
      />
      <path
        class="st746"
        d="M756.9,669.7c-0.1,0.3,0.2,0.7,0.1,1.1c-0.7-0.8-1.3-1.5-2.2-1.9c-0.2-0.1-0.4-0.2-0.6,0
		c-0.6,0.1-1-0.3-1.3-0.7c-0.7-0.8-1.7-1.3-2.6-1.8c-0.8-0.4-1.5-0.9-2.2-1.5c-0.2-0.1-0.7-0.5,0.1-0.5
		C751.1,666.1,754,667.9,756.9,669.7z"
      />
      <path
        class="st747"
        d="M748.2,664.3c-0.6,0.3,0,0.4,0.1,0.5c1.4,0.9,2.8,1.8,4.2,2.7c0.2,0.2,0.6,0.3,0.6,0.7
		c-0.5,0.1-0.9-0.3-1.3-0.6c-0.7-0.4-1.3-0.9-2-1.2c-1.6-1-3-2.2-4.8-2.9c0-0.2-0.1-0.4-0.2-0.5c0.2-0.2,0.5-0.2,0.7-0.2
		C746.5,663.2,747.5,663.5,748.2,664.3z"
      />
      <path
        class="st748"
        d="M730.8,672.7c0.3-0.4,1,0.1,1.2-0.5c0,0,0-0.1,0,0c0.7,0.4,1.5,0.7,1.9,1.5c-0.2,0.2-0.3,0-0.4-0.1
		c-0.7-0.6-1.5-0.4-2.2-0.3c-0.2,0.3,0.5,0.4,0.2,0.7C731,673.7,730.7,673.2,730.8,672.7z"
      />
      <path
        class="st749"
        d="M713,684.4c0.4,0.1,0.7,0,0.9-0.4c0.2-0.3,0.4-0.6,0.8-0.7c0.1,0.1,0,0.3,0.1,0.4c-0.3,0.6-0.7,1.1-1,1.7
		c-0.3,0.2-0.6,0.4-0.8,0.6C712.7,685.4,713.2,684.9,713,684.4z"
      />
      <path
        class="st750"
        d="M747.6,662.2c0.1,0,0.2,0,0.3,0c0,0.5,0.3,0.8,0.5,1.2C748,663.1,747.5,662.8,747.6,662.2z"
      />
      <path
        class="st751"
        d="M808.6,685.7c1-0.5,1.8-0.2,1.8,1c0.2,4.4,0,8.9,0.1,13.3c0,0.4,0,0.9-0.3,1.3c-0.4,0.7-1,1-1.8,1.3
		c-1.5,0.6-2.9,1.5-4.3,2.4c-1.1,0.7-2.3,1.2-3.3,2.1c-0.2,0.1-0.3,0.2-0.5,0c-0.3-0.4-0.7-0.1-1,0c-0.7,0.2-1.1,0-1.1-0.7
		c-0.1-1-0.1-1.9-0.8-2.6c-0.2-0.2-0.2-0.5-0.2-0.8c0.2-1.8-0.1-3.7,0-5.5c0-0.8-0.1-1.6,0.2-2.3c0.1-0.3,0-0.5-0.1-0.8
		c0-0.6,0.2-1,0.6-1.4c1.8-1.4,3.8-2.3,5.7-3.5c1.6-1,3.2-2.1,4.9-3C808.9,686.1,808.8,685.9,808.6,685.7z"
      />
      <path
        class="st752"
        d="M798.1,692.9c-0.3,0.3-0.5,0.7-0.6,1.1c-0.4,1.1-0.4,2.2-0.4,3.3c0,3.6,0,7.2,0,10.8c-0.2,0.4,0.2,1-0.2,1.3
		c-0.5-0.3-0.7-0.8-0.7-1.4c0-4.3-0.1-8.6,0.1-12.9c0.1-1.6,1-2.7,2.5-3.4c0.3,0.1,0.1,0.3,0,0.4
		C798.5,692.4,798.3,692.6,798.1,692.9z"
      />
      <path
        class="st753"
        d="M796.9,709.4c0.1-0.4-0.2-0.8,0-1.2c0.2-0.2,0.4,0,0.4,0.2c0.2,0.6,0.5,0.4,0.8,0.2s0.6-0.5,0.9-0.8
		c0.4-0.5,0.9-0.9,1.6-0.7c2-1.7,4.3-2.8,6.6-4.1c0.7-0.4,1.4-0.7,2.1-0.9c0.4-0.1,0.5-0.7,1-0.8c-0.2,1.1-1.2,1.4-2,1.9
		c-1.7,1.1-3.4,2.2-5.2,3.2c-1.7,0.9-3.2,2-4.9,2.9C797.8,709.4,797.4,709.7,796.9,709.4z"
      />
      <path
        class="st754"
        d="M798.1,692.9c-0.1-0.5,0.5-0.7,0.5-1.2c3.2-2.1,6.5-4.1,9.9-6c0.2,0,0.5,0,0.5,0.2s-0.2,0.3-0.4,0.5
		c-1.6,1-3.2,2-4.8,3C802,690.5,800,691.6,798.1,692.9z"
      />
      <path
        class="st755"
        d="M806,684.1c1.5-0.5,2.2-1.4,2.1-2.5c0-0.3-0.3-0.6-0.4-0.9c0.7-0.2,1.1,0.4,1.6,0.7c1.2,0.7,1.5,1.8,1,3
		c-0.2,0.6-0.7,0.4-1.1,0.6c-0.3,0.1-0.6,0.1-0.9,0.1c0.2-0.2,0.3-0.3,0.5-0.5c0.7-0.7,0.7-1.3-0.2-1.8c-0.3-0.2-0.4,0.1-0.5,0.2
		c-0.7,0.7-1.5,1.2-2.3,1.6c-0.2,0.1-0.3,0.2-0.5,0C805.4,684.2,805.8,684.2,806,684.1z"
      />
      <path
        class="st756"
        d="M780.6,663.5c-0.6-0.1-1.2-0.3-1.7-0.7C779.6,662.8,780.1,663.1,780.6,663.5z"
      />
      <path
        class="st757"
        d="M725.7,732.5c0,0.2-0.3,0.2-0.4,0.3c-0.3,0.2-0.7,0-0.9-0.2c-1.3-0.9-2.8-1.6-4.1-2.5c-1.5-1-3.1-2-4.7-2.9
		c-0.8-0.5-1.6,0-1.5,0.8c0.2,1.2,0.1,2.5,0.1,3.7c0,1.8,0,3.5,0,5.3c0,1.4-0.2,2.8-0.2,4.1c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.1-0.1,0.2-0.3,0.2c-0.2-0.2-0.3-0.3-0.5-0.5c-0.3-0.6-0.3-1.2-0.3-1.9c0-4,0-8,0-12c0-0.2,0.1-0.4,0.2-0.7
		c0.3-1.1,1.1-1.5,2.1-1.2c2,1.2,3.9,2.5,6,3.6c0.2,0,0.3,0.1,0.4,0.3c1,0.6,2,1.2,3.1,1.9C725,731.8,725.4,732,725.7,732.5z"
      />
      <path
        class="st758"
        d="M712.9,725.7c0-0.7-0.1-1.3,0.4-1.8c0.3-0.3,0.5-0.4,0.7,0c0.3,0.4,0.5,0.9,0.8,1.3c0,0.1,0,0.2-0.1,0.2
		C714,725.1,713.5,725.5,712.9,725.7z"
      />
      <path
        class="st759"
        d="M712.9,725.7c0.5-0.8,1.3-0.5,2-0.5c0.1,0.2,0.2,0.3,0.3,0.5c-1-0.1-1.6,0.4-2.1,1.2
		C712.7,726.5,712.9,726.1,712.9,725.7z"
      />
      <path
        class="st595"
        d="M721.5,729.6c-0.1-0.1-0.3-0.2-0.4-0.3c0.2-0.1,0-0.5,0.3-0.6c0.2-0.1,0-0.7,0.5-0.6c0.3,0,0.4,0.4,0.5,0.7
		c0.1,0.2,0.1,0.4,0.2,0.7C722.2,729.3,721.8,729.3,721.5,729.6z"
      />
      <path
        class="st595"
        d="M720.4,726c0.2,0.2,0.7,0.2,0.8,0.5c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.3-0.2,0.4-0.5,0.4c-0.2,0-0.4-0.1-0.5-0.3
		c-0.1-0.3,0.2-0.3,0.4-0.4C720.7,726.5,720.3,726.2,720.4,726z"
      />
      <path
        class="st760"
        d="M730.4,751.3c0.2-0.8-0.2-1.6-0.1-2.4c0.2-2.5,0.1-5,0.2-7.5c0-1.3-0.2-2.6-0.2-4c0-0.5,0-1,0.2-1.4
		c0.3-0.5,0.7-0.6,1.1-0.3c1.5,0.9,2.8,2,4.4,2.8c0.9,0.5,1.8,1,2.6,1.6c1,0.7,2.1,1.1,3,1.9c0.2,0.2,0.4,0.4,0.7,0.1
		c0.1,0,0.1,0.1,0.2,0.2c0,0.4-0.4,0.3-0.6,0.4c-0.6-0.1-1-0.5-1.4-0.7c-2.5-1.5-5-3.1-7.6-4.7l-0.1-0.1c-0.4-0.2-0.8-0.5-1.3-0.3
		s-0.3,0.8-0.3,1.2c0,3.8,0,7.7,0,11.5c0,0.4,0,0.8,0,1.2c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4-0.2,0.3
		C730.6,751.7,730.4,751.6,730.4,751.3z"
      />
      <path
        class="st761"
        d="M730.8,751.8c0.4-0.3-0.4-0.5,0-0.8c0.3-0.1,0.5,0,0.7,0.2c3.3,2,6.6,4,9.8,6.1c0.4,0.3,0.8,0.5,1.4,0.2
		c0.4,0,0.5,0.2,0.3,0.6c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.3-0.5,0.1-0.8,0.1c-3.4-2.1-6.9-4.2-10.3-6.3
		C731.2,752.1,731,752,730.8,751.8z"
      />
      <path
        class="st762"
        d="M742.4,758.5c0-0.3,0.2-0.4,0.4-0.5c0.3-0.2,0.2-0.3-0.1-0.5c-0.2-0.2-0.1-0.5-0.1-0.8c0-3.5,0-7.1,0-10.6
		c0-0.9,0-1.8-0.5-2.7c-0.1-0.2-0.2-0.5-0.1-0.8c0.4-0.1,0.6,0.1,0.8,0.4c0.6,0.9,0.8,1.9,0.8,2.9c0,3.7,0,7.5,0,11.2
		c0,0.6-0.1,1.2-0.6,1.7c-0.1,0-0.1,0.1-0.2,0.1C742.7,758.7,742.2,759.1,742.4,758.5z"
      />
      <path
        class="st763"
        d="M743,758.8c0.4-0.7,0.5-1.4,0.5-2.2c0-3.7,0-7.4,0-11.2c0-0.9-0.3-1.6-0.8-2.3c-0.2-0.2-0.4-0.4-0.7-0.4
		c-0.2,0.1-0.3,0.1-0.3-0.1c0.2-0.1,0.5,0,0.6-0.3c1,0.9,1.4,2.1,1.4,3.4c0,3.8,0,7.7,0,11.6C743.8,757.9,743.8,758.6,743,758.8z"
      />
      <path
        class="st763"
        d="M742.4,758.5c0,0.4,0.5,0.1,0.5,0.4c-0.4,0.1-0.8-0.1-1.1-0.3C741.9,758.4,742.2,758.7,742.4,758.5z"
      />
      <path
        class="st764"
        d="M713.7,701.7c0.1-0.2-0.2-0.5,0.1-0.7c0.1,0,0.1-0.1,0.2,0c1,1,2.3,1.5,3.4,2.4c1.2,0.7,2.3,1.5,3.5,2.2
		c0.8,0.5,1.7,1,2.5,1.5c0.9,0.6,1.9,1.3,2.9,1.8c0,0.1,0,0.2,0,0.2c-0.2,0.3-0.5,0.1-0.6,0c-3.8-2.3-7.7-4.7-11.5-7
		C714,702,713.8,701.8,713.7,701.7z"
      />
      <path
        class="st765"
        d="M726.2,709.1c0-0.1,0-0.1,0-0.2c0.7-1,0.5-2.1,0.4-3.2c-0.1-2.7,0-5.4,0-8.1c0-1.7,0.1-3.4-0.9-4.9
		c0.1,0,0.2-0.1,0.3-0.2c0.8,0.9,1.2,1.9,1.2,3c0,4,0,8,0,12C727.2,708.3,727.1,708.9,726.2,709.1z"
      />
      <path
        class="st766"
        d="M726,692.4c-1-1.1-2.4-1.6-3.6-2.4c-0.5-0.3-0.9-0.6-1.4-0.9c0-0.1,0.1-0.1,0.2-0.1c0.8,0.2,4.2,2.3,4.6,2.9
		c0.1,0.2,0.2,0.3,0.4,0.5C726.2,692.3,726.1,692.3,726,692.4z"
      />
      <path
        class="st767"
        d="M725.4,732.9c1.2,0.5,1.4,1.6,1.4,2.7c0,3.6,0,7.2,0,10.8c0,0.2,0,0.4,0,0.7c0,1.8,0.2,1.8-1.3,2.2
		c-0.1,0-0.3,0-0.3-0.2c-0.3-0.4-0.3-0.8,0.3-1c0.4-0.2,0.5-0.6,0.5-1c0-3.9,0-7.8,0-11.7c0-0.6-0.2-1.2-0.4-1.7
		C725.5,733.4,725.3,733.2,725.4,732.9z"
      />
      <path
        class="st768"
        d="M725.4,748.2c-0.3,0.2-0.1,0.5-0.2,0.8c-0.8-0.5-1.5-0.9-2.3-1.4c-1-0.8-1.9-1.6-3-2.1
		c-0.8-0.3-1.4-1.1-2.4-1.2l0,0c-1-0.6-1.8-1.4-2.9-1.9c-0.3-0.2-0.7-0.3-0.8-0.7c0-0.1,0-0.2,0-0.3c0.3-0.1,0.6,0.1,0.9,0.2
		c2.6,1.6,5.2,3.1,7.9,4.7c0.8,0.5,1.5,1,2.3,1.4C725.2,747.8,725.4,747.9,725.4,748.2z"
      />
      <path
        class="st769"
        d="M717.5,744.3c0.3-0.1,0.6-0.2,0.8,0c1,0.9,2.5,1.1,3.4,2.2c0.3,0.4,1,0.5,1.1,1.1
		C721.2,746.5,719.3,745.5,717.5,744.3z"
      />
      <path
        class="st770"
        d="M713.9,741.7c0.7,0.4,1.5,0.8,2.1,1.3c0.5,0.5,1.2,0.7,1.6,1.3c-1.3-0.5-2.3-1.5-3.6-2
		c-0.2-0.1-0.3-0.3-0.2-0.5C713.7,741.8,713.8,741.7,713.9,741.7z"
      />
      <path
        class="st771"
        d="M725.4,748.2c-2.9-1.8-5.9-3.6-8.9-5.3c-0.7-0.4-1.4-0.7-1.9-1.2c-0.6-0.3-0.7-0.7-0.7-1.4
		c0.2-4,0.1-8,0.1-12c0-0.5,0-0.9,0.4-1.2c0.5-0.3,0.9-0.1,1.4,0.1c1.6,0.9,3,2,4.7,2.8c1.2,0.7,2.5,1.4,3.7,2.2
		c0.3,0.2,0.7,0.3,1.1,0.5l0.1,0.1c0.7,1,0.8,2.1,0.8,3.2c0,3.7,0,7.4,0,11C726.2,747.7,726.3,748.4,725.4,748.2z"
      />
      <path
        class="st772"
        d="M779.7,758.7c-0.2-1.1-0.1-2.1-0.1-3.2c0-1.4,0-2.7,0-4.1c0.1-1.6,0.1-3.1,0-4.7c-0.1-1.1,0.2-1.8,1-2.2
		c-0.5,1.2,0.1,2.4,0,3.5c-0.1,2,0,4.1,0,6.1c0,1.5,0,3-0.5,4.4C779.7,758.1,779.8,758.5,779.7,758.7z"
      />
      <path
        class="st773"
        d="M780.5,744.4c-0.7,0.7-0.9,1.5-0.9,2.5c0,1.5,0.1,3-0.1,4.4c-0.1-1.7,0-3.4-0.1-5.1
		c-0.1-1.4,0.6-2.7,1.8-3.6c0,0.1,0,0.2,0,0.3C781,743.5,780.7,743.9,780.5,744.4z"
      />
      <path
        class="st774"
        d="M796.9,728.6c0-4.3,0-8.6,0-13c0-0.6,0.5-1,0.8-1.5c0,3.4,0,6.7,0,10.1c0,0.8-0.5,1.7,0.2,2.5
		c0.1,0.1,0,0.3-0.2,0.3c-0.8,0.3-0.6,0.9-0.5,1.5c0,0.1,0.1,0.3-0.1,0.3C797.1,728.9,797,728.7,796.9,728.6z"
      />
      <path
        class="st775"
        d="M763.3,716.1c-0.1-0.7,0.1-1.3-0.1-2c0.3-0.9,0.8-1.6,1.6-2.1c2.5-1.5,5-3.1,7.5-4.6c0.7-0.5,1.4-1,2.2-1.3
		c0.2,0,0.4,0.1,0.6,0.3c-0.8,0.7-1.7,1.2-2.5,1.7c-2.3,1.4-4.7,2.8-7,4.2c-1,0.6-1.5,1.6-1.8,2.8c-0.1,0.2,0,0.5-0.1,0.7
		C763.6,716,763.6,716.2,763.3,716.1z"
      />
      <path
        class="st776"
        d="M763.3,716.1c0.6-0.1,0.1-0.8,0.5-1c0,3.6,0,7.2,0,10.8c0,0.9-0.2,1.7-0.1,2.6c-0.4-0.2-0.3-0.6-0.3-0.9
		C763.2,723.7,763.3,719.9,763.3,716.1z"
      />
      <path
        class="st777"
        d="M780.6,738.6c-0.4,0.3-0.5-0.2-0.7-0.3c-0.2-0.3-0.2-0.7-0.2-1c0-3.2,0-6.5,0-9.7c0-0.7,0.2-1.3,0.3-1.9
		c0-0.2,0.1-0.4,0.4-0.4c0.3,2.8,0,5.5,0.2,8.3c0,0.8,0.4,1.7,0,2.5c-0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.8,0.7-0.1,1.1
		C780.4,737.7,780.6,738.2,780.6,738.6z"
      />
      <path
        class="st778"
        d="M780.4,725.2c-0.2,0.7-0.5,1.4-0.5,2.1c0,3.6,0,7.3,0,10.9c-0.2,0.1-0.1,0.3-0.2,0.5c-0.3-0.8,0-1.7-0.1-2.6
		c0.1-0.7,0.1-1.5,0.1-2.2c0-2.7,0-5.4,0-8c0-0.8,0.4-1.4,1-1.8C780.8,724.6,780.7,724.9,780.4,725.2z"
      />
      <path
        class="st779"
        d="M780.7,724.2c-1.1,1.1-1,2.6-1,4c0,2.4,0,4.7,0,7.1c0,0.3,0,0.7-0.1,1c0-3.5,0-7.1,0-10.6
		c0-0.6,0.2-1.2,0.5-1.7c0.2-0.4,0.5-0.6,0.9-0.5C781.1,723.8,780.7,723.8,780.7,724.2z"
      />
      <path
        class="st780"
        d="M763.4,747.7c-0.2-0.2-0.1-0.6-0.1-0.8c0-2.9,0-5.8,0-8.6c-0.2-1.1,0-2.2,0.1-3.3c0-0.2,0.2-0.3,0.4-0.3
		c-0.1,1.8-0.1,3.6-0.1,5.4c0,1.8,0.1,3.6,0.1,5.4c0,0.7,0,1.5-0.2,2.2C763.5,747.7,763.5,747.7,763.4,747.7z"
      />
      <path
        class="st781"
        d="M763.8,734.7c-0.7,0.5-0.3,1.3-0.4,2c-0.1,0.5,0,1.1-0.1,1.6c-0.3-0.8-0.2-1.7-0.1-2.5
		c0.1-0.8,0-1.8,0.7-2.5c0.2-0.2,0.4-0.5,0.8-0.6C764.5,733.4,764,734,763.8,734.7z"
      />
      <path
        class="st782"
        d="M749.9,659.7c0.2,0,0.3-0.1,0.4,0c0.4,0.5,0.5,0,0.6-0.2s-0.3-0.5,0.2-0.5s0.1,0.5,0.3,0.7
		c-0.8,0.8-0.9,1.3,0,2c1.3,0.9,2.7,1.7,4,2.6c1.5,1,3,1.9,4.5,2.9c0.4,0.3,0.8,0.7,0.9,1.2c-0.1,0-0.1,0-0.2,0
		c-1.1,0-1.8-0.7-2.7-1.2c-2.3-1.5-4.7-2.9-7-4.4c-0.5-0.3-1.1-0.6-1.4-1.2c-0.2-0.4-0.2-0.7-0.1-1.1
		C749.7,660.2,749.7,659.9,749.9,659.7z"
      />
      <path
        class="st783"
        d="M726.7,689.3c0.1,0,0.2,0,0.3,0c0.1,0.1,0.1,0.2,0.2,0.2c-0.3,0.7-0.8,0.3-1.2,0.2c0,0-0.1-0.1,0-0.1
		s0-0.1,0-0.1C726.2,689.2,726.5,689.6,726.7,689.3z"
      />
      <path
        class="st784"
        d="M790.5,691.8c0.4,0.4,0.1,0.8,0.2,1.2c0,0.4-0.3,0.5-0.5,0.7c-2.1,1.2-4.1,2.5-6.2,3.7
		c-0.2,0.2-0.5,0.3-0.8,0.4c-0.9,0.6-1.9,1-2.7,1.8c-0.1,0-0.2,0.1-0.3,0.2c-0.7,0.2-1.4,0.1-2,0.2c-0.4,0-0.8,0.1-0.9-0.5
		c0.1-0.1,0.2-0.2,0.3-0.2c0.9,0.2,1.7-0.1,2.5-0.5c2.9-1.7,5.8-3.4,8.7-5.3C789.4,693,790,692.4,790.5,691.8z"
      />
      <path
        class="st785"
        d="M777.3,699.4c0.2,0.5,0.5,0.5,1,0.4c0.6-0.1,1.3-0.3,1.9-0.1c-0.5,0.4-1.2,0.3-1.8,0.4
		c-0.5,0-1.1-0.1-1.6-0.1c-0.9-0.8-2-1.2-3-1.8c-2.2-1.4-4.4-2.8-6.6-4.3c-0.2-0.1-0.4-0.4-0.6-0.6c0.1-0.2,0.2-0.2,0.3-0.1
		c0.2,0.3,0.2,0,0.3-0.1c1.1,0.5,2.1,1.2,3.2,1.9c2.3,1.4,4.5,2.8,6.9,4.1C777.3,699.3,777.3,699.3,777.3,699.4z"
      />
      <path
        class="st786"
        d="M790.2,693.7c0.2-0.2,0.4-0.5,0.5-0.7c0.3-0.3,0.3-0.7,0.2-1.2c-0.1-0.4-0.5-0.8-0.4-1.3
		c0.8,0.5,1.5,1,2.1,1.7c0,0.6,0,1.3-0.5,1.8C791.6,693.2,790.9,693.5,790.2,693.7z"
      />
      <path
        class="st785"
        d="M780.5,699.6c0.7-0.9,1.7-1.4,2.7-1.8C782.4,698.6,781.4,699,780.5,699.6z"
      />
      <path
        class="st787"
        d="M768.6,706.6c-1.9,1.1-3.7,2.4-5.6,3.3c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0-1.2,0.1-1.8-0.1
		c-0.1-0.2-0.1-0.3,0-0.5c1.8-0.1,3.2-1.2,4.7-2.1c1.9-1.2,3.8-2.4,5.8-3.5c0.8-0.5,1.4-1,2-1.8c0.1,0,0.2,0,0.3,0.2
		c0.1,0.7-0.3,1.1-0.8,1.5c-0.3,0.2-0.7,0.5-1,0.8C770.8,705.3,769.7,706,768.6,706.6z"
      />
      <path
        class="st788"
        d="M773.5,702.3L773.5,702.3c-0.4-0.1-0.5-0.3-0.6-0.5c-0.4-0.7-1.2-0.9-1.5-1.5c0-0.1,0-0.1,0.1-0.2
		c0.7,0.3,1.3,0.8,2,1.3c0.3,0.5,0.8,1,0.7,1.7c-0.2,0.5-0.5,0.8-0.9,1.1c-1.3,0.8-2.7,1.6-4.1,2.4c-0.1,0.1-0.3,0-0.4,0
		c1-0.8,2.1-1.3,3.2-2.1c0.4-0.5,1.1-0.6,1.5-1.1C773.5,703.1,773.7,702.8,773.5,702.3z"
      />
      <path
        class="st789"
        d="M760.8,709.8c0.6,0.1,1.3,0.2,1.9,0.2c-0.4,0.3-1-0.1-1.4,0.3c-1.5,0.2-2.5-0.9-3.7-1.6
		c-0.5-0.3-1.1-0.5-1.4-1.1c0.4-0.3,0.7,0.2,1,0.1c0.9,0.5,1.8,1.2,2.7,1.5C760.3,709.5,760.6,709.6,760.8,709.8z"
      />
      <path
        class="st790"
        d="M806,684.1c-0.1,0.3-0.5,0.3-0.7,0.5c-0.2,0.1-0.4,0.3-0.7,0.4c-0.6,0.5-1.3,0.9-2,1.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.4,0.4-1,0.7-1.5,1l0,0c-0.7,0.5-1.3,1-2.1,1.2c-1.1,0.6-2.1,1.3-3.4,1.1c-0.2,0.1-0.4,0.2-0.6-0.1
		c0-0.2,0.1-0.3,0.3-0.3c1.1,0,2.1-0.2,3-0.9c1.9-1.2,3.8-2.3,5.7-3.4c0.4-0.2,0.8-0.4,1.1-0.7c0.2-0.2,0.5-0.3,0.8-0.2
		C805.9,684,806,684.1,806,684.1z"
      />
      <path
        class="st791"
        d="M794.9,689.5c0.1,0.2,0.3,0.1,0.5,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-1.5,0.5-2.4-0.5-3.4-1.3
		c0.5-0.3,0.8,0.1,1.2,0.2c0.3,0.2,0.6,0.5,1,0.3C794.4,689.1,794.7,689.2,794.9,689.5z"
      />
      <path
        class="st792"
        d="M798.8,688.5c0.7-0.4,1.3-0.9,2.1-1.2C800.4,688,799.7,688.5,798.8,688.5z"
      />
      <path
        class="st755"
        d="M802.7,686.2c0.7-0.4,1.3-0.8,2-1.2C804.2,685.8,803.5,686.1,802.7,686.2z"
      />
      <path
        class="st793"
        d="M800.9,687.3c0.4-0.4,1-0.7,1.5-1C802.1,687,801.5,687.2,800.9,687.3z"
      />
      <path
        class="st794"
        d="M749.7,660.4c-0.1,0.8,0.2,1.3,0.9,1.8c2.2,1.3,4.3,2.7,6.5,4c1.2,0.8,2.3,1.7,3.7,2.2
		c0.1,0.5,0,0.7-0.5,0.4c-1.7-0.8-3.2-1.9-4.9-2.9c-1.9-1.2-3.8-2.4-5.7-3.6c-0.3-0.5-0.9-1-0.4-1.7
		C749.4,660.4,749.5,660.3,749.7,660.4C749.7,660.3,749.7,660.3,749.7,660.4C749.7,660.3,749.7,660.4,749.7,660.4z"
      />
      <path
        class="st795"
        d="M749.7,660.4c-0.5,0.1-0.6,0.6-0.4,0.8c0.3,0.3,0.2,0.7,0.4,1c-0.6-0.2-1.2,0.3-1.8,0
		c0.8-0.2,1.1-0.9,1.1-1.6c0-0.6,0.4-0.8,0.9-0.9C749.8,660,749.9,660.2,749.7,660.4z"
      />
      <path
        class="st796"
        d="M742,703c0.6,0.5,0.8,1.1,0.8,1.9c0,1.7,0,3.5,0,5.2c0,1.2-0.1,2.5,0.1,3.7c0.2,1.2-0.3,2.5,0,3.7
		c0.1,0.5-0.5,0.7-1,0.4c-2.5-1.5-5-3.1-7.5-4.6c-1-0.6-2-1.2-3-1.8c-0.5-0.3-0.7-0.7-0.6-1.3c0.2-2.5,0.2-4.9,0-7.4
		c-0.1-1,0.1-2.1-0.1-3.2c0-0.8,0-1.5,0-2.3s0.4-1,1.1-0.8c0.3,0.1,0.7,0.3,1,0.5c1.1,0.7,2.3,1.4,3.4,2.2c0.5,0.6,1.3,0.9,1.9,1.3
		c1.1,0.7,2.3,1.4,3.4,2C741.8,702.8,741.9,702.9,742,703z"
      />
      <path
        class="st797"
        d="M731.6,695.2c-0.3-0.5-0.1-0.9,0.3-1.2c0.4-0.4,0.8-0.2,1.1,0.1c0.9,0.6,1.9,0.9,2.7,1.7
		c0.2,0.1,0.3,0.2,0.5,0.1c1.1,0.6,2.2,1.2,3.3,2c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.5,0.4c0,0.4-0.4,0.2-0.6,0.2
		c-0.4,0.2-0.1,0.3,0,0.5c0.4,0.3,0.9,0.6,1.3,1c0.2,0.2,0.6,0.4,0,0.6c-0.3,0.2-0.4-0.1-0.6-0.2l0,0c-0.4-0.3-0.9-0.6-1.3-0.8l0,0
		c-0.7-0.5-1.4-1-2.2-1.3c-0.2-0.1-0.5-0.3-0.7-0.4c-0.8-0.7-1.8-1.2-2.7-1.7c-0.2-0.2-0.5-0.3-0.7-0.4
		C732.6,695.6,731.9,695.6,731.6,695.2z"
      />
      <path
        class="st798"
        d="M741.7,702.7c-0.5,0-0.8-0.4-1.3-0.6c-1.2-0.7-2.4-1.3-3.5-2c-0.2-0.2-0.5-0.3-0.5-0.7
		C738.2,700.4,740,701.4,741.7,702.7z"
      />
      <path
        class="st799"
        d="M733.6,696.5c1.1,0.3,2,0.8,2.7,1.7C735.4,697.7,734.4,697.2,733.6,696.5z"
      />
      <path
        class="st595"
        d="M729.8,710.2c-0.2-1.5-0.1-3-0.1-4.5C729.8,707.2,729.7,708.7,729.8,710.2z"
      />
      <path
        class="st800"
        d="M737.1,698.6c0.9,0.2,1.6,0.7,2.2,1.3C738.4,699.7,737.8,699.1,737.1,698.6z"
      />
      <path
        class="st801"
        d="M731.6,695.2c0.4,0.3,1.1,0.3,1.3,0.9c-0.4-0.2-0.9-0.4-1.3-0.6c-0.1-0.2-0.3-0.1-0.6-0.3
		C731.3,695.2,731.4,695.2,731.6,695.2z"
      />
      <path
        class="st802"
        d="M739.3,699.9c0.5,0.1,1,0.3,1.3,0.8C740.1,700.6,739.6,700.3,739.3,699.9z"
      />
      <path
        class="st803"
        d="M740.6,700.7c0.2-0.1,0.4,0.2,0.6,0.2c0.1,0.2,0.3,0.2,0.4,0.5C741.1,701.3,740.8,701.1,740.6,700.7z"
      />
      <path
        class="st804"
        d="M725.4,712.8c0.1,0.2,0.2,0.4,0.4,0.7c0.2,0.7,0.5,1.5-0.3,2c-0.3,0.2,0,0.4,0.2,0.5
		c0.4,0.2,0.5,0.5,0.5,0.9c0,1.3,0,2.7,0,4c0,0.5,0,0.8-0.7,0.6c-0.6-0.2-1.2,0.2-1.2,1c0,0.6-0.2,1-0.6,1.5
		c-0.3,0.5-0.6,0.8-1.2,0.5c-0.2-0.1-0.4,0-0.6,0.2c-0.5,0.3-0.9,0.2-1.2-0.3s-0.5-0.5-0.8-0.1c-0.1,0.2-0.4,0.2-0.6,0.2
		c-1.3-0.4-2.3-1.4-3.5-2c-0.6-0.3-1.1-0.7-1.6-1.1c-0.1-0.3-0.1-0.5-0.1-0.8c0-4,0-8,0-12c0-1.5,0.2-1.6,1.6-1.2
		c0.1,0,0.2,0.1,0.3,0.1l0,0c0.6,0.6,1.4,0.9,2.1,1.3l0,0c1.3,0.8,2.5,1.6,3.8,2.3C723.2,711.3,724.2,712.2,725.4,712.8z"
      />
      <path
        class="st805"
        d="M716,707.2c-0.2,0-0.4,0.1-0.6,0c-0.9-0.3-1.2-0.2-1.2,0.8c0,4.1,0,8.2,0,12.3c0,0.2,0,0.5,0,0.7
		c-0.2,0.1-0.1,0.3-0.2,0.5c-0.4-0.4,0-0.9,0-1.3c0-4,0-8.1,0-12.1c0-0.5-0.2-1.1,0.4-1.4C715,706.5,715.5,706.9,716,707.2z"
      />
      <path
        class="st806"
        d="M721.9,710.8c-0.5,0.1-0.8-0.3-1.2-0.5c-0.9-0.6-1.9-0.9-2.6-1.8C719.4,709.2,720.7,710,721.9,710.8z"
      />
      <path
        class="st806"
        d="M718.1,708.5c-0.8-0.3-1.6-0.5-2.1-1.3C716.7,707.6,717.4,708,718.1,708.5z"
      />
      <path
        class="st613"
        d="M745,663.5c0.6-0.1,1.1,0.2,1.6,0.5c1.1,0.7,2.2,1.5,3.3,2.2c1.1,0.8,2.3,1.5,3.4,2.2
		c0.4,0.2,0.8,0.6,1.3,0.6c0.8,0.4,1.5,0.9,2,1.8c-0.2,0.7-0.7,1.1-1.2,1.5c-2.9,1.8-5.9,3.6-8.8,5.5c-0.8,0.5-1.7,0.6-2.7,0.5
		c0.5,0.6,1.3,0.1,1.8,0.5c-0.4,0.3-0.9,0.2-1.3,0.2c-0.2-0.2-0.6,0-0.8-0.3c-1.6-1.7-3.7-2.7-5.7-3.9c-1-0.7-2.1-1.3-3.1-2
		c-0.1-0.1-0.2-0.2-0.3-0.2c-1-0.8-1-1.4,0.1-2.2c2.2-1.3,4.4-2.7,6.5-4.1c1-0.6,2.1-1.2,3-1.9C744.4,664.1,744.8,663.9,745,663.5z"
      />
      <path
        class="st807"
        d="M734.5,670.4c-0.9,1-0.8,1.4,0.3,2.2c2,1.3,4,2.5,6,3.8c0.8,0.5,1.5,1,2.2,1.5c0.3,0.2,0.5,0.5,0.5,0.8
		c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.5,0-0.7-0.1c-3-1.9-6-3.7-8.9-5.7c-0.4-0.3-0.9-0.6-1-1.1c0-0.6-0.2-1.2,0.4-1.7
		C733.5,670.6,734,670.5,734.5,670.4z"
      />
      <path
        class="st808"
        d="M732.9,672c1.3,1.3,3,2.1,4.4,3.1c1.8,1.2,3.7,2.2,5.4,3.4c-0.6,0.3-0.9-0.2-1.3-0.3
		c-0.8-0.4-1.5-0.8-2.2-1.3c-1.8-1.1-3.5-2.2-5.3-3.3c-0.6-0.5-1.3-1-1.9-1.5c0-0.3,0.1-0.7,0.3-1
		C732.8,671.2,732.6,671.8,732.9,672z"
      />
      <path
        class="st809"
        d="M741.4,678.2c0.5,0,0.9,0.4,1.3,0.3c0.2,0.1,0.4,0.1,0.6,0.2c-0.2,0.8-0.8,0.3-1.1,0.2
		C741.9,678.8,741.7,678.4,741.4,678.2z"
      />
      <path
        class="st796"
        d="M759.3,752.3c-0.1,0.1-0.3,0.1-0.5,0.2c0.5,0.8,0.7,1.7,0.7,2.7c0,3.6,0,7.3,0,10.9c0,0.3,0,0.5-0.1,0.8
		c-0.2,0.8-0.6,0.8-1.2,0.4c-2-1.3-4-2.6-6.2-3.7c-1.4-0.7-2.7-1.7-4.1-2.5c-0.3-0.2-0.2-0.7-0.6-0.6c0-2.1,0-4.3,0-6.4
		c0-2.2,0-4.3,0-6.5c0-1.7,0.5-2,2-1.1c2.7,1.6,5.3,3.3,8,4.9C757.9,751.8,758.5,752.3,759.3,752.3z"
      />
      <path
        class="st810"
        d="M793.9,689.2c-0.1,0.1-0.1,0.3-0.4,0.2c-0.6-0.3-1-0.8-1.7-0.8c-0.2-0.2-0.4-0.3-0.7-0.4
		c-0.5-0.5-1-0.8-1.6-1.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7-0.4-1.4-0.9-2.1-1.3l0,0c-0.7-0.5-1.5-1-2.3-1.5l0,0
		c-0.1-0.1-0.2-0.2-0.4-0.3c-0.5-0.3-0.3-0.5,0.1-0.6c1.2,0.5,2.3,1.3,3.4,2c1.9,1.2,3.8,2.4,5.6,3.5
		C793.6,688.8,793.9,688.9,793.9,689.2z"
      />
      <path
        class="st811"
        d="M784.3,683.3c-0.5,0.5,0.4,0.5,0.3,0.8c-0.4,0-0.7-0.3-1-0.5c-0.6-0.6-1.3-0.6-2.1-0.7
		c0.1,0,0.2-0.1,0.4-0.1c0.7,0.1,0.8-0.2,0.8-0.8c0.2-0.3,0.1,0.7,0.3,0.1c0-0.1,0.2,0.4,0.4,0.4
		C783.8,682.6,784.3,682.7,784.3,683.3z"
      />
      <path
        class="st812"
        d="M784.6,684.1c0.8,0.3,1.7,0.8,2.3,1.5C786.1,685.2,785.2,684.8,784.6,684.1z"
      />
      <path
        class="st813"
        d="M786.9,685.5c0.8,0.3,1.5,0.7,2.1,1.3C788.2,686.6,787.5,686.2,786.9,685.5z"
      />
      <path
        class="st814"
        d="M789.5,687.2c0.7,0.2,1.3,0.4,1.6,1.1C790.5,688,790,687.7,789.5,687.2z"
      />
      <path
        class="st736"
        d="M768.2,673.6c-0.1-0.4-0.6-0.4-0.8-0.7c0-0.3,0.3-0.3,0.5-0.4c2.2,0.9,4.1,2.3,6.1,3.6
		c0.9,0.6,1.9,1,2.7,1.8c0.3,0.3,0.8,0.2,1.1,0.7c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2
		c-0.8,0.2-1.5-0.3-2.1-0.7C773.3,676.8,770.8,675.2,768.2,673.6z"
      />
      <path
        class="st815"
        d="M767.2,693.1c0,0.6-0.4-0.1-0.5,0.2c-0.4-0.4-0.7,0.3-1.1,0c0.1-0.2,0.2-0.5,0.3-0.8
		c0.1-0.9,0.4-1.7,1.2-2.2c1-0.8,2-1.4,3.3-1.6c0.2,0.4-0.2,0.5-0.4,0.6c-0.7,0.5-1.4,0.9-2.1,1.5c-0.6,0.5-1,1-0.6,1.9
		C767.3,692.9,767.2,693,767.2,693.1z"
      />
      <path
        class="st816"
        d="M719.2,724.2c0.2-0.1,0.5-0.2,0.6-0.4c0.5-0.7,0.5-0.7,0.9,0.2c0.2,0.5,0.6,0.8,1,0.2c0.3-0.3,0.6-0.5,0.9,0
		c0.1,0.2,0.3,0.2,0.4,0.1c0.3-0.6,1-0.9,0.9-1.7c-0.2-0.8,0.5-1.1,1-1.5c0.2-0.1,0.4-0.1,0.5,0c0.6,0.6,0.6,0.2,0.6-0.3
		c0-1.2,0-2.5,0-3.7c0-0.5-0.1-0.8-0.6-0.9c-0.2,0-0.5-0.1-0.5-0.4c0-0.3,0.2-0.4,0.4-0.5c0.4-0.2,0.6-0.5,0.4-1
		c-0.1-0.3-0.1-0.5,0-0.8c0.5,0.8,0.8,1.6,0.5,2.5c-0.1,0.4,0,0.9,0,1.3c0,2.7,0,5.5,0,8.2c0,0.6,0.1,1.2,0.1,1.8
		c0,1-0.5,1.3-1.4,0.8C723,726.8,721.1,725.5,719.2,724.2z"
      />
      <path
        class="st817"
        d="M747.8,705.3c0.3-0.3,0.3-0.7,0.3-1v-0.1c0.1-0.3,0.4-0.4,0.7-0.5c1.2-0.1,2,0.7,2.9,1.1
		c0.2,0.1,0.4,0.3,0.6,0.3c0.9,0.5,1.7,1.2,2.6,1.7c0.1,0.1,0.2,0.2,0.2,0.4c-0.3,0-0.7,0-0.8,0.5c0-1.2-1.1-1.2-1.6-1.8
		c-0.2-0.2-0.3,0-0.4,0.2c-0.1,0.2-0.2,0.4,0.1,0.5c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.4,0.3,0.2,0.5c-0.1,0.2-0.4,0.2-0.6,0
		c-0.4-0.5-1.1-0.6-1.5-1.1c-0.2-0.3-0.6-0.3-0.9-0.1C749.2,705.9,748.6,705.3,747.8,705.3z"
      />
      <path
        class="st818"
        d="M755.1,707.2c-0.9-0.6-1.9-1.3-2.8-1.9c-0.1-0.2-0.6-0.2-0.5-0.6c0.2-0.3,0.5-0.2,0.8-0.1
		c1.4,0.9,2.8,1.8,4.3,2.7c0.2,0.1,0.5,0.2,0.3,0.5c-0.4,0.3-0.6-0.2-1-0.2C755.8,707.7,755.5,707.3,755.1,707.2z"
      />
      <path
        class="st819"
        d="M758,711.2c-0.1,0-0.2,0-0.2,0c-1.8-1-3.5-2.1-5.2-3.2c-0.1-0.1-0.1-0.2,0-0.2
		C754.5,708.9,756.2,710,758,711.2z"
      />
      <path
        class="st820"
        d="M752.6,707.8c0,0.1,0,0.2,0,0.2c-1-0.6-2.1-1.3-3.1-1.9c-0.5-0.3-1.1-0.6-1.7-0.6v-0.1
		c0.9-0.1,1.5,0.4,2.3,0.8C750.9,706.6,751.8,707.2,752.6,707.8z"
      />
      <path
        class="st821"
        d="M759,712.8L759,712.8c0.5,0.5,0.6,1.1,0.6,1.7c0,4.1,0,8.2,0,12.3c0,1.2-0.4,1.4-1.5,0.8
		c-2.5-1.7-5.2-3.3-7.8-4.8c-0.9-0.5-1.8-1.1-2.6-1.7c-0.2-0.1-0.3,0-0.4-0.1c-0.2-0.4-0.2-0.8-0.2-1.2c0-4,0.1-8,0-12.1
		c0-1.1,0.7-1.6,1.8-1.2c1.7,0.7,3,1.9,4.6,2.8C755.3,710.5,757.1,711.7,759,712.8z"
      />
      <path
        class="st822"
        d="M757.9,727.5c1.4,0.7,1.4,0.7,1.4-0.9c0-4,0-7.9,0-11.9c0-0.6-0.2-1.2-0.3-1.7c0.2,0,0.3-0.1,0.3-0.2
		c0.1,0.1,0.2,0.2,0.3,0.3v0.1c0.4,1.5,0.2,3.1,0.4,4.6c0,0.2,0.1,0.5,0.1,0.7c0,2.9,0,5.8,0,8.7c0,0.5-0.2,0.9-0.6,1.2
		s-0.8,0.1-1.2-0.2c-0.3-0.2-0.7-0.2-0.9-0.6C757.5,727.3,757.7,727.4,757.9,727.5z"
      />
      <path
        class="st823"
        d="M759,712.8c-0.4,0-0.8-0.3-1.1-0.5c-2.6-1.6-5.3-3.3-7.9-4.9c-0.4-0.3-0.9-0.5-1.4-0.7
		c-0.7-0.2-1.1,0-1.3,0.7c0,0.2-0.1,0.3-0.1,0.5c0,4.2,0,8.5,0,12.7c0,0.2,0.1,0.3,0.1,0.5c0.1,0,0.1,0.1,0.1,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.2-0.1-0.2-0.4-0.3-0.5c0-1.5-0.4-3-0.1-4.4c0.5-2.6,0.1-5.2,0.3-7.8c0-0.5-0.2-0.9,0-1.4
		c0.2-0.4-0.2-1,0.6-1.2c0.6-0.2,0.9,0,1.4,0.2c1.8,0.7,3.2,2.2,4.8,3c1.8,0.9,3.4,2.2,5.2,3.1C759,712.6,759,712.7,759,712.8z"
      />
      <path
        class="st824"
        d="M760.3,689.3c-0.4,0-0.7-0.4-1.2-0.3c-0.6-0.4-1.2-0.8-1.9-1.2c-0.5-0.2-1-0.5-1.5-0.8l0,0
		c-0.4-0.3-0.8-0.6-1.3-0.8c-1-0.6-2-1.2-2.9-1.8c-0.5-0.8-1.6-0.9-2.2-1.7c-0.1-0.2,0.1-0.4,0.2-0.3c0.3,0.3,0.3-0.1,0.5-0.1
		c3.1,1.9,6.2,3.8,9.3,5.8c0.3,0.2,0.7,0.3,1,0.6c0,0.1,0.1,0.1,0,0.2C760.3,689.1,760.3,689.2,760.3,689.3z"
      />
      <path
        class="st825"
        d="M754.5,686.2c0.5,0.1,1,0.4,1.3,0.8c-0.1,0-0.2,0-0.3,0C755.1,686.7,754.8,686.4,754.5,686.2z"
      />
      <path
        class="st826"
        d="M779.6,717.8c-0.1,0.4,0.1,0.8-0.2,1.1c-0.3-0.4-0.3-0.8-0.3-1.2c0-3.9,0-7.8,0-11.7c0-0.7,0.2-1.4,0.5-2
		c0.1,0.1,0.1,0.2,0.2,0.4l0,0c-0.2,0.4-0.2,0.8-0.2,1.3c0,3.8,0,7.5,0,11.2C779.7,717.3,779.7,717.6,779.6,717.8z"
      />
      <path
        class="st827"
        d="M747.5,740.4L747.5,740.4c-0.1-1.5-0.1-3.1-0.1-4.6c0-2.6,0-5.2,0-7.8c0-0.5,0-1.2,0.5-1.4s1,0,1.4,0.3
		c2.9,1.8,5.8,3.6,8.7,5.4c0.2,0.2,0.5,0.3,0.8,0.2v0.1c0.6,0.5,0.8,1.2,0.8,1.9c0,4,0,7.9,0,11.9c0,0.4,0,0.9-0.5,1.1
		c-0.5,0.2-0.8,0-1.2-0.3c-1.4-1-3-1.8-4.4-2.7c-1.8-1.2-3.7-2.1-5.4-3.4C747.8,740.9,747.5,740.7,747.5,740.4z"
      />
      <path
        class="st828"
        d="M784.3,683.3c-0.3-0.3-0.5-0.5-0.8-0.8c-0.2-0.8,0-1.6,0.7-2c2.9-1.8,5.7-3.5,8.6-5.3
		c0.6-0.4,1.2-0.9,1.9-1.1c0.7-0.3,1.3-0.1,1.9,0.3c3.2,2,6.3,4,9.5,5.9c0.6,0.1,0.9,0.5,1.2,0.9c0.5,0.7,0.3,1.5-0.3,1.9
		c-2.2,1.5-4.6,2.9-7,4.3c-0.9,0.5-1.7,1.1-2.6,1.5c-0.7,0.4-1.4,0.6-2.2,0.6c-0.1,0-0.3,0-0.3,0.2c-0.3-0.1-0.7-0.1-1-0.2
		C790.7,687.3,787.5,685.3,784.3,683.3z"
      />
      <path
        class="st825"
        d="M755.8,687c0.6,0.1,1.1,0.4,1.5,0.8C756.7,687.8,756.2,687.5,755.8,687z"
      />
      <path
        class="st829"
        d="M742.2,722.8c0,0,0,0.1,0,0.2c0.8,0.8,0.9,1.9,0.9,3c0,2.5,0,5,0,7.4c0,1.1,0,2.2,0.1,3.3
		c0.1,1-0.4,1.5-1.3,1.4c-1.2-0.6-2.3-1.5-3.4-2.1c-2-1.2-3.8-2.4-5.8-3.5c-0.7-0.4-1.3-0.8-1.9-1.4c0-4.5,0-8.9,0-13.3
		c0-0.9,0.5-1.2,1.3-0.7c1.1,0.6,2.2,1.3,3.3,2c2,1.2,3.9,2.4,5.9,3.7C741.6,722.7,741.8,722.8,742.2,722.8z"
      />
      <path
        class="st830"
        d="M730.8,730.9c2.1,1.3,4.1,2.5,6.2,3.8c1.3,0.8,2.6,1.6,3.9,2.4c0.3,0.2,0.7,0.4,0.9,0.7
		c0.1,0.1,0.4,0,0.3,0.3c-0.1,0.2-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.2-0.7-0.3c-1.4-1.2-3.1-2.2-4.7-3.1c-0.9-0.5-1.7-1.2-2.6-1.7
		c-1-0.5-2-1-2.8-1.7C730.8,731.1,730.8,731,730.8,730.9z"
      />
      <path
        class="st831"
        d="M741.5,738.1c0.1,0,0.2-0.1,0.4-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c1,0.1,1.4-0.2,1.3-1.1
		c-0.2-1.7-0.1-3.4-0.1-5.1c0-1.6,0-3.2,0-4.8c0-1.3,0.1-2.6-0.6-3.8c0.8,0.6,1.3,1.4,1.3,2.4c0,4,0,8,0,12
		C743.5,738.6,742.9,738.8,741.5,738.1z"
      />
      <path
        class="st832"
        d="M795.2,689.3c2.1-0.5,3.8-1.7,5.6-2.8c1.8-1,3.5-2.1,5.3-3.2c1.9-1.1,1.9-1.9,0.1-3.2c0.6,0,1.5,0.7,1.6,1.3
		s0.1,1.2-0.6,1.6c-0.5,0.3-1.1,0.4-1.3,1c-1.2,0.7-2.4,1.4-3.6,2.1c-1.5,0.9-3,1.9-4.6,2.8C796.9,689.3,796.1,689.5,795.2,689.3z"
      />
      <path
        class="st833"
        d="M778.8,663.5c0.2,0,0.4,0,0.7,0c3,1.8,5.8,3.8,8.8,5.4l0,0c0.7,0.8,1.8,1,2.3,2c0,0.7-0.5,1.1-1,1.4
		c-0.4,0.3-0.9,0.5-1.3,0.8c-1,0.3-1.8,0.9-2.7,1.5c-1.9,1.2-3.8,2.3-5.7,3.4c-0.1,0-0.2,0.1-0.2,0.2c-0.5,0.4-1.1,0.4-1.7,0.3
		c-0.6-0.3-0.8-1-1.4-1.4c-0.5-0.3-0.9-0.7-1.6-0.8c-2.2-1.3-4.4-2.7-6.5-4.1c-1.1-0.7-1.1-1.7,0-2.4c2.2-1.5,4.3-3,6.6-4.3
		c0.9-0.5,1.8-1,2.7-1.7C778,663.6,778.3,663.4,778.8,663.5z"
      />
      <path
        class="st834"
        d="M779.6,678.2c0.3-0.7,1.1-0.9,1.7-1.3c1.6-1,3.3-2,4.8-3c0.6-0.4,1.2-0.9,2-0.8c-2.7,1.6-5.4,3.3-8.1,4.9
		C779.9,678.1,779.8,678.2,779.6,678.2z"
      />
      <path
        class="st835"
        d="M775,676.2c1.2-0.1,1.7,0.8,2.4,1.5c0.2,0.2,0.4,0.5,0.5,0.8c-0.1,0-0.1,0-0.2,0.1c-0.4-0.1-0.9-0.2-1.2-0.6
		c0.2-0.7-0.6-0.7-0.9-1C775.5,676.7,774.9,676.8,775,676.2z"
      />
      <path
        class="st836"
        d="M790.5,670.9c-0.5-0.4-0.8-1-1.6-1.1c-0.1,0-0.5-0.6-0.6-0.9c0.9,0.4,1.8,0.9,2.3,1.8
		C790.5,670.8,790.5,670.8,790.5,670.9z"
      />
      <path
        class="st640"
        d="M779.6,717.8c0-4.2,0-8.4,0-12.6c0-0.3,0.1-0.6,0.3-0.8c-0.3,1.6-0.1,3.2-0.2,4.9c0,2.5,0,5.1,0,7.7
		C779.7,717.3,779.8,717.6,779.6,717.8z"
      />
      <path
        class="st833"
        d="M760.3,689L760.3,689c0.2-0.9-0.5-0.9-1-1.1c-2.5-1.7-5.2-3.1-7.7-4.8c-0.2-0.1-0.4-0.3-0.6-0.4
		c-0.8-0.8-0.8-1.5,0.1-2.3c1.1-0.9,2.4-1.6,3.6-2.3c1.9-1.2,3.7-2.5,5.7-3.6c0.6-0.3,1.2-0.6,1.9-0.5c0.2,0,0.3,0.1,0.5,0.2
		c3,2,6,4,9.1,5.8c0.6,0.4,1.2,0.8,1.3,1.5c0,0.7-0.5,1.1-1,1.4c-3.1,2-6.3,4-9.6,5.8C762.1,689.1,761.2,689,760.3,689z"
      />
      <path
        class="st837"
        d="M773.4,681.4c-1-1.6-2.8-2.2-4.2-3.2c-2-1.3-4-2.6-5.9-3.9c-0.1-0.1-0.3-0.2-0.3-0.4
		c0.2,0.1,0.5,0.2,0.7,0.2c2.6,1.8,5.3,3.5,8,5.2c0.8,0.5,1.6,1,1.9,2C773.5,681.5,773.4,681.5,773.4,681.4z"
      />
      <path
        class="st833"
        d="M756.4,691.9c-0.1,0.7-0.5,1.1-1,1.5c-1.4,0.9-2.8,1.7-4.2,2.5c-1.5,0.9-2.9,1.8-4.3,2.7
		c-0.7,0.4-1.4,0.9-2.3,0.8c-0.4-0.1-0.8-0.2-1-0.7c-0.1-0.3-0.4-0.4-0.7-0.5c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.5-0.7-0.9-1.3-0.9
		c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.6-1.2-0.8-1.7-1.1c-1.2-0.7-2.3-1.5-3.6-2c-0.7-0.2-1.2-0.7-1.8-1.2s-0.5-0.9-0.1-1.4
		c0.5-0.6,1.2-1.1,1.9-1.5c2.9-1.7,5.7-3.6,8.5-5.3c0.2-0.1,0.4-0.2,0.6-0.2c1,0.1,1.8,0.7,2.6,1.2c1.7,1.1,3.4,2.2,5.1,3.3
		c0.9,0.6,1.9,1.1,2.8,1.7C755.9,691,756.3,691.3,756.4,691.9z"
      />
      <path
        class="st838"
        d="M756.4,691.9c-0.5-1.2-1.7-1.6-2.7-2.2c-2.1-1.3-4.2-2.6-6.2-3.9c-0.8-0.5-1.6-1-2.5-1.2
		c-0.1-0.1-0.2-0.1-0.2-0.3l0,0c0.1,0,0.1,0,0.2,0c1.4,0.2,2.4,1.1,3.5,1.7c0.5,0.3,0.9,0.6,1.3,0.9l0,0c1.5,1.1,3,2,4.5,3
		C755.1,690.4,756.1,690.9,756.4,691.9C756.5,691.8,756.5,691.9,756.4,691.9z"
      />
      <path
        class="st839"
        d="M742.7,698.1c0.5-0.1,0.8,0.1,1,0.5s0.5,0.6,0.8,0.8c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2-0.1-0.2-0.1
		C743.4,698.8,742.7,698.6,742.7,698.1z"
      />
      <path
        class="st840"
        d="M747.5,721.3c0-0.1,0-0.1-0.1-0.2c0.1-0.1,0-0.3,0.2-0.2c3.2,2.2,6.7,4.1,10,6.2c0.1,0.1,0.2,0.2,0.4,0.3
		c-0.1,0-0.3,0-0.4,0.2c-2.5-1.4-4.8-3.2-7.3-4.6C749.2,722.6,748.4,721.9,747.5,721.3z"
      />
      <path
        class="st841"
        d="M775,676.2c0.5,0.6,1.3,0.7,1.8,1.3c0.1,0.1,0.3,0.2,0.2,0.4s-0.3,0.1-0.4,0.1c-2.9-1.8-5.8-3.7-8.8-5.4
		c-0.5-0.2-1-0.5-1.1-1.1c0-0.5,0-0.9,0.4-1.2c2.2-1.8,4.7-3.3,7.2-4.7c1.2-0.7,2.3-1.6,3.6-2.2c0.2-0.1,0.3-0.3,0.5-0.5
		c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3c-3.2,2-6.4,4-9.6,6c-0.2,0.1-0.4,0.3-0.6,0.5c-1.1,0.9-1,1.5,0.2,2.2
		C770.8,673.5,772.9,674.9,775,676.2z"
      />
      <path
        class="st842"
        d="M767,670.4c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.2,0.1,0.5-0.3,0.5c-0.2-0.1-0.4-0.2-0.6,0
		c0.2-0.8,0-1.7,0.8-2.3C767,669.9,766.6,670.4,767,670.4z"
      />
      <path
        class="st843"
        d="M742.7,698.1c0.2,0.4,1.1,0.4,0.6,1.2c-0.7-0.2-1.4-0.7-2-1.1c-2.4-1.5-4.8-3-7.2-4.4
		c-0.7-0.4-1.4-0.9-1.3-1.9c0.1-1,1-1.3,1.7-1.7c1.8-1,3.5-2.2,5.2-3.2c1.3-0.8,2.5-1.8,4-2.4c0.1,0,0.2-0.1,0.4-0.1
		c0.3,0,0.5-0.2,0.8-0.1c0,0.1,0.1,0.2,0.2,0.3c-2,1.3-4,2.5-5.9,3.8c-1.4,0.9-2.9,1.7-4.2,2.7c-0.2,0.1-0.4,0.2-0.5,0.4
		c-0.6,0.6-0.6,0.9,0,1.5c0.5,0.4,1,0.8,1.5,1c1.3,0.8,2.7,1.6,4,2.4c0.4,0.3,0.9,0.5,1.3,0.8l0,0
		C741.8,697.4,742.3,697.6,742.7,698.1L742.7,698.1z"
      />
      <path
        class="st844"
        d="M744,684.4c-1.6,1-3.3,2.1-4.9,3.1c-1.5,0.9-2.9,1.8-4.4,2.7c-0.8,0.4-1.5,0.9-1.9,1.7
		c-0.1-0.3-0.2-0.2-0.3,0c-0.2,0.5-0.4,1-0.4,1.5c-0.1,0-0.1,0-0.2,0c0-1-0.1-2.1,1-2.7c0.2,0.2,0.3,0.1,0.5,0
		c1.9-1.3,4.1-2.3,5.9-3.8c0.6-0.5,1.5-0.5,2-1.1c0.5-0.5,1.3-0.5,1.7-1.2C743.1,684.2,743.6,684.3,744,684.4z"
      />
      <path
        class="st845"
        d="M790.5,691.8c-0.1,0.9-0.8,1.3-1.4,1.8c-2.9,1.9-5.9,3.7-8.9,5.4c-0.8,0.4-1.6,0.9-2.5,0.5
		c-0.8-1.3-1.8-2.2-3.3-2.5c-0.2-0.1-0.4-0.2-0.6-0.4c-0.3-0.7-1-0.8-1.6-0.9c-1.4-0.8-2.8-1.6-4-2.6c-0.8-0.7-0.8-1.3,0-2
		c0.9-0.8,2-1.3,3-2c2.2-1.3,4.2-2.8,6.5-4c0.2-0.1,0.5-0.1,0.7-0.2c0.7-0.7,1.3-0.3,2,0.1c2.2,1.3,4.2,2.8,6.4,4
		c0.1,0,0.1,0.1,0.2,0.1c0.5,0.3,1,0.8,1.5,1C789.2,690.5,790,691,790.5,691.8z"
      />
      <path
        class="st846"
        d="M777.6,685.2c-1.5,0.9-3,1.8-4.4,2.8c-1.6,1-3.3,1.9-4.8,3.1c-1,0.8-1,1.3,0.1,2c1.2,0.8,2.5,1.6,3.8,2.4
		c0.6,0.3,1.2,0.4,1.6,1c0.2,0.1,0.3,0.2,0.5,0.3c1,0.4,1.9,1,2.7,1.7c0.2,0.2,0.5,0.4,0.2,0.7c-1.1-0.3-2-1-2.9-1.6
		c-2.2-1.3-4.2-2.6-6.4-3.9c-0.3-0.2-0.5-0.4-0.8-0.7c-0.9-1.4-0.3-1.7,1.3-3c0.6-0.5,1.3-0.7,1.8-1.3l0,0c2.6-1.7,5.2-3.3,7.9-4.8
		c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.2C778.6,684.9,778.1,685,777.6,685.2z"
      />
      <path
        class="st847"
        d="M778.4,684.1c-2.5,1.5-4.9,3-7.4,4.5c-0.2,0.1-0.4,0.3-0.7,0.3c0.3-1.2,1.4-1.4,2.3-1.8
		c0.8-0.3,1.4-0.9,2.2-1.3c1-0.5,1.6-1.4,2.8-1.5C777.9,684.3,778.1,683.9,778.4,684.1C778.4,684,778.4,684,778.4,684.1z"
      />
      <path
        class="st848"
        d="M780.7,685c0.9,0.1,1.5,0.8,2.2,1.2c1.7,1,3.4,2.1,5,3.2c0.1,0.1,0.3,0.1,0.2,0.3c-0.5,0-1-0.3-1.3-0.7l0,0
		c-2-1.1-4-2.5-5.9-3.7C780.8,685.2,780.7,685.1,780.7,685C780.7,685.1,780.7,685.1,780.7,685z"
      />
      <path
        class="st849"
        d="M778.8,684.3c-0.1-0.1-0.1-0.1-0.2-0.2c0.8,0,1.6,0.3,2.2,1l0,0C780,685,779.4,684.6,778.8,684.3z"
      />
      <path
        class="st850"
        d="M786.8,689.1c0.4,0.2,0.9,0.5,1.3,0.7c0.1,0.1,0.2,0.2,0.3,0.3C787.7,690,787.1,689.7,786.8,689.1z"
      />
      <path
        class="st851"
        d="M725.9,691.8c-1.5-0.9-3-1.9-4.5-2.8c-1.7-0.9-3.3-2-5-3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.5-0.3-0.9-0.1-1.4,0
		c0-0.7,0.5-1.3,1-1.8c0.6-0.7,1.1,0,1.7,0.2c2.4,1.5,4.8,3,7.2,4.5c0.4,0.2,0.9,0.4,1.1,0.9c0.4,0.9,0.9,1.8,2,2.2
		c0.2,0.1,0.4,0.3,0.2,0.6s-0.3,0.2-0.6,0.1C726.2,691.8,726,691.8,725.9,691.8z"
      />
      <path
        class="st852"
        d="M724.8,689.1c-2.8-1.7-5.5-3.5-8.3-5.2c-0.1-0.1-0.1-0.1,0-0.2c0.4-0.2,0.6,0.1,0.9,0.3
		c2,1.2,4.1,2.4,6.1,3.7c0.9,0.6,1.9,1,2.7,1.8c0,0,0,0.1,0,0.2C725.5,689.8,725.2,689.2,724.8,689.1z"
      />
      <path
        class="st853"
        d="M747.5,740.4c1.3,1.1,2.8,1.8,4.2,2.7c2.1,1.4,4.3,2.5,6.4,3.9c0.3,0.2,0.6,0.5,1,0.3c0.3-0.2,0.3-0.6,0.3-1
		c0-3.9,0-7.9,0-11.8c0-0.8-0.5-1.3-0.6-2c0.5,0.1,1.2,1.2,1.3,1.8c0,0,0,0.1,0,0.2c0,4,0.2,8.1,0.2,12.1c0,0.5-0.3,0.9-0.5,1.3
		c-0.8,0.5-1.3-0.2-1.7-0.5c-1.4-1-3-1.8-4.4-2.9c-1.1-0.9-2.5-1.2-3.6-2c-0.7-0.6-1.5-1-2.2-1.6
		C747.5,740.9,747.4,740.7,747.5,740.4z"
      />
      <path
        class="st836"
        d="M788.2,668.9c-0.7-0.1-1.2-0.5-1.7-0.8c-2.3-1.4-4.6-2.9-6.8-4.3c-0.1-0.1-0.2-0.2-0.3-0.3
		c1.4,0.5,2.4,1.4,3.6,2.2C784.8,666.7,786.5,667.8,788.2,668.9z"
      />
      <path
        class="st854"
        d="M759.8,694.8c0.3-0.1,0.2,0.3,0.3,0.3c-0.2,0.7-0.9,0.9-1.4,1.2c-2.5,1.6-5,3.2-7.5,4.7
		c-0.4,0.3-0.8,0.7-1.3,0.8c-0.4,0-0.8,0-0.5-0.6l0,0c0.3,0,0.6-0.1,0.7-0.3c0.7-0.9,1.8-1.2,2.7-1.8c0.8-0.5,1.6-1,2.4-1.6
		c1.1-0.8,2.3-1.5,3.6-2.1C759.2,695.4,759.4,695,759.8,694.8z"
      />
      <path
        class="st855"
        d="M761.5,673.5c0,0,0,0.1,0.1,0.2c-1.5,0.7-2.9,1.5-4.2,2.4c-2.5,1.7-5.1,3-7.4,4.9c-0.4,0.3-0.8,0.4-0.8-0.3
		c0.1-0.5,0.5-0.7,0.8-1c1.1-0.5,2.1-1.2,3-1.8c0.8-0.6,1.8-0.9,2.6-1.6c0.9-0.7,1.9-1.4,3-1.9C759.6,673.9,760.5,673.5,761.5,673.5
		z"
      />
      <path
        class="st856"
        d="M749.4,701.4c-0.2,0.5,0.4,0.3,0.5,0.5c0.2,0.3,0,0.5-0.2,0.7c-0.2,0.1-0.3,0.3-0.3,0.5
		c0.1,0.5-0.3,0.6-0.7,0.7c-0.2,0.1-0.4,0.2-0.6,0.4C748.6,703.3,748.6,702.1,749.4,701.4z"
      />
      <path
        class="st857"
        d="M771.4,700.1c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.4,0-0.6-0.1c-1.2-0.7-2.3-1.4-3.4-2.2c-0.2-0.1-0.4-0.3-0.4-0.6
		c0.1-0.1,0.1-0.1,0.2,0C768.6,698.3,770,699.2,771.4,700.1z"
      />
      <path
        class="st858"
        d="M767.2,697.4c-0.1,0-0.2,0-0.2,0c-0.4,0.1-0.7-0.1-0.9-0.3c-0.6-0.5-1.4-0.7-1.8-1.4v-0.1
		C765.3,696.1,766.2,696.8,767.2,697.4z"
      />
      <path
        class="st859"
        d="M761.8,694.7L761.8,694.7c1,0,1.8,0.4,2.4,1v0.1c-0.9-0.1-1.6-0.5-2.4-0.9
		C761.8,694.8,761.8,694.7,761.8,694.7z"
      />
      <path
        class="st860"
        d="M749.9,686.8c-0.8-0.2-1.3-0.8-2-1.2c-0.9-0.6-1.8-1.1-2.9-1.4c0.2-0.3,0.3-0.1,0.5,0
		C747.1,684.8,748.6,685.7,749.9,686.8z"
      />
      <path
        class="st861"
        d="M754.6,669.1c-0.4,0.2-0.7-0.1-0.9-0.2c-1.1-0.7-2.1-1.5-3.3-2c-0.2-0.1-0.5-0.2-0.5-0.6
		c1.2,0.5,2.1,1.3,3.2,1.9c0.4,0.2,0.8,0.5,1.2,0.7C754.4,668.9,754.5,669,754.6,669.1z"
      />
      <path
        class="st862"
        d="M749.2,680.6c0.2,0.4,0.4,0.4,0.7,0.2c0,0.5,0.1,1.1,0.1,1.7c-0.1,0.5-0.6-0.2-0.6,0.3
		c-0.2-0.1-0.3-0.2-0.5-0.3C748.9,681.8,748.9,681.2,749.2,680.6z"
      />
      <path
        class="st863"
        d="M750,682.5c-0.4-0.5-0.3-1.1-0.1-1.7c1.1-1.1,2.5-1.8,3.7-2.6c1.8-1.2,3.7-2.4,5.5-3.5
		c0.8-0.5,1.5-1,2.4-1.1c0.3,0.1,0.6,0.2,0.8,0.3c-1.4,0.1-2.5,1-3.6,1.7c-2.3,1.4-4.6,2.9-6.9,4.4c-0.2,0.1-0.3,0.2-0.5,0.3
		c-1.1,0.8-1,1.6,0.1,2.3c1.9,1.2,3.9,2.4,5.8,3.5c0.7,0.4,1.5,0.8,2.1,1.4c0,0,0.1,0,0.1,0.1c0.2,0.2,0.5,0.2,0.4,0.5
		s-0.4,0.2-0.7,0.1c-2-1.2-4.1-2.5-6.1-3.7C752.2,683.9,751,683.4,750,682.5z"
      />
      <path
        class="st864"
        d="M766.5,672.2c0.1-0.2,0.2-0.3,0.3-0.5c0.3,0.3,0.7,0.7,1,1c-0.2,0.1-0.3,0.2-0.5,0.3
		C767,672.8,766.7,672.5,766.5,672.2z"
      />
      <path
        class="st843"
        d="M749.6,702.5c0.2-0.2,0.3-0.4,0.3-0.6c1.4-1.3,3.2-2.1,4.8-3.2c1.8-1.2,3.7-2.2,5.4-3.5
		c0.6-0.1,0.9-0.9,1.6-0.5c0,0,0,0,0.1,0.1c0.1,0.4-0.3,0.5-0.5,0.7c-0.5,0.4-1.1,0.7-1.6,1c-2.3,1.5-4.7,3-7.1,4.5
		c-0.4,0.3-0.9,0.6-1.2,0.9c-0.6,0.5-0.6,0.9,0.1,1.3c1.7,1.1,3.4,2.2,5.2,3.2c1.1,0.6,2.1,1.3,3.1,2.1c0.2,0.2,0.5,0.4,0.6,0.8
		c0,0.2,0,0.5-0.3,0.3c-1-0.4-2-1-2.8-1.7c-0.4-0.5-1-0.7-1.5-1c-1.1-0.7-2.2-1.4-3.2-2c-0.2-0.1-0.4-0.3-0.7-0.1
		c-0.8-0.3-1.5-0.7-2.1-1.4C749.5,703.1,749.3,702.8,749.6,702.5z"
      />
      <path
        class="st827"
        d="M714,701.1c-0.1,0-0.1,0-0.2,0c0.3-4.5,0.1-9,0.2-13.5c0.2-0.9,0.6-1.1,1.5-0.7c1.4,0.7,2.8,1.7,4.2,2.5
		c0.4,0.2,0.8,0.5,1.2,0.7c1.3,0.8,2.6,1.6,3.9,2.4c0.3,0.2,0.6,0.3,0.9,0.4c0.8,0.7,0.9,1.6,0.9,2.5c0,3.8,0,7.6,0,11.3
		c0,0.5,0.1,1.2-0.5,1.4c-0.6,0.3-1.1-0.1-1.6-0.4c-1.5-1-3.2-2-4.8-2.9c-0.7-0.4-1.2-0.9-2-1.2c-1-0.7-2.1-1.3-3-2
		C714.4,701.4,714.1,701.3,714,701.1z"
      />
      <path
        class="st865"
        d="M717.6,703.3c2.4,1.4,4.8,2.8,7.2,4.3c0.4,0.2,0.8,0.4,1.2,0.2s0.3-0.6,0.3-1c0-3.6,0-7.2,0-10.7
		c0-1.2,0.1-2.4-0.7-3.4c0,0,0.1,0,0.2,0c0.9,0.8,1.2,1.8,1.2,2.9c0,3.2,0,6.4,0,9.6c0,0.5,0,1.1,0.1,1.6c0.1,0.8,0,1.6-0.8,2
		c-0.5,0-0.8-0.2-1.2-0.5c-0.8-0.6-1.7-1.1-2.6-1.7c-0.6-0.4-1.3-0.9-2-1.1c-0.5-0.1-0.7-0.5-1.1-0.8c-0.7-0.4-1.5-0.8-2.1-1.4
		C717.3,703.4,717.5,703.4,717.6,703.3z"
      />
      <path
        class="st866"
        d="M720.8,690c-1,0-1.6-0.9-2.4-1.3c-1.1-0.5-2.1-1.3-3.3-1.9c-0.6-0.3-1,0.2-1.2,0.7c-0.2-0.4,0-0.7,0.3-1
		c0.3-0.2,0.6-0.2,0.9-0.1C717.1,687.5,719,688.7,720.8,690z"
      />
      <path
        class="st867"
        d="M715.2,685.4c0.6,0,1,0.4,1.4,0.7c1.6,0.9,3.2,1.8,4.7,2.9c-0.1,0-0.2,0-0.2,0
		C719.1,687.9,717.1,686.7,715.2,685.4z"
      />
      <path
        class="st868"
        d="M732.9,672c-0.5-0.1-0.4-0.5-0.6-0.8c-0.2-0.7,0.4-0.8,0.8-1C732.9,670.8,732.9,671.4,732.9,672z"
      />
      <path
        class="st869"
        d="M726,689.5c-1.2-0.5-2.2-1.4-3.4-2c-1.6-0.9-3.2-1.9-4.8-2.9c-0.5-0.3-0.8-0.7-1.4-0.8
		c-0.4-0.2-0.8-0.2-1-0.7c0-0.3,0-0.6,0.5-0.4c0.7,0.8,1.6,1.2,2.5,1.7c2.5,1.5,5,3.1,7.5,4.6c0.2,0.2,0.5,0.2,0.8,0.4
		C726.6,689.8,726.3,689.4,726,689.5z"
      />
      <path
        class="st870"
        d="M715.9,682.6c-0.3,0-0.3,0.3-0.3,0.5c-0.3,0.2-0.5,0.2-0.8,0c0-0.8,0-1.7,1-2.2
		C715.8,681.5,715.7,682.1,715.9,682.6z"
      />
      <path
        class="st871"
        d="M714.8,683.1c0.3,0,0.5,0,0.8,0c0.2,0.3,0.7,0.3,0.8,0.7c0,0.1,0,0.1,0,0.2c-0.5-0.2-1-0.7-1.6-0.3
		c-0.2-0.1,0-0.2,0-0.3C714.8,683.2,714.8,683.1,714.8,683.1z"
      />
      <path
        class="st872"
        d="M800.6,707.1c-0.7,0.3-1.3,0.5-1.7,1.2c-0.2,0.4-0.8,0.6-1.2,0.8c-0.3,0.2-0.5,0-0.5-0.4
		c0-0.2,0.1-0.5-0.3-0.5c0-4.3,0-8.6,0-12.9c0-0.6,0.3-1,0.6-1.4c0.2,0.4,0.5,0.8,0.1,1.3c-0.1,0.1-0.1,0.4,0,0.6
		c0.1,1.8-0.2,3.6,0.2,5.4c0,0.2,0,0.4,0,0.6c-0.1,0.7-0.4,1.5,0.5,2.1c0.3,0.2,0.3,0.8,0.4,1.3c0,0.3,0,0.5,0,0.8
		c0,0.8,0.3,1,1,0.6C800.2,706.3,800.4,706.3,800.6,707.1z"
      />
      <path
        class="st873"
        d="M720.5,730.1c-0.4,0.1-0.8-0.1-1.1-0.3c-1.1-1-2.5-1.5-3.6-2.3c-0.8-0.6-1.5-0.3-1.6,0.7c0,0.3,0,0.6,0,0.9
		c0,3.5,0,7,0,10.6c0,0.2,0,0.4,0,0.6c-0.2,0.7-0.1,1.2,0.5,1.6c-0.3-0.1-0.5-0.2-0.8-0.3c0.2-3.7,0.1-7.4,0.1-11.1
		c0-0.7,0-1.5-0.1-2.2c-0.1-0.9,0.7-1.6,1.5-1.2C717.1,727.9,718.9,728.8,720.5,730.1z"
      />
      <path
        class="st827"
        d="M741.8,742.6c0.1,0,0.2,0.1,0.3,0.1c0.7,1.1,0.8,2.3,0.8,3.7c0,3.3,0,6.7,0,10.1c0,0.4,0,0.8-0.2,1.1
		c-0.3,0.5-0.7,0.4-1.1,0.1c-2.9-1.8-5.8-3.6-8.7-5.4c-0.7-0.4-1.4-0.9-2.1-1.3c0-4.4,0.1-8.8,0-13.1c0-1.2,0.3-1.8,1.7-1
		c2.4,1.4,4.7,2.9,7,4.3C740.2,741.6,741,742.1,741.8,742.6z"
      />
      <path
        class="st874"
        d="M717.6,703.3c-0.1,0.1-0.2,0.2-0.3,0.2c-1-0.6-1.9-1.2-2.9-1.8c-0.2-0.1-0.4-0.3-0.4-0.6
		C715.2,701.8,716.4,702.6,717.6,703.3z"
      />
      <path
        class="st875"
        d="M777.3,699.3c-0.1-0.7-0.6-0.9-1.1-1.2c-0.7-0.4-1.3-0.8-1.9-1.3c0.4,0,0.7,0,1.1,0.2c0.8,0.6,1.7,1.1,2.3,2
		c0.1,0.2,0.3,0.3-0.1,0.4c-0.1,0-0.2,0-0.3,0V699.3z"
      />
      <path
        class="st833"
        d="M759.7,708.5c-1.5-1.2-3.2-2.1-4.9-3c-1.2-0.7-2.4-1.5-3.5-2.3c-0.6-0.4-0.6-0.9-0.1-1.4
		c1-1,2.3-1.6,3.6-2.4c1.7-1,3.3-2.1,5-3.1c0.7-0.2,1.2-0.6,1.8-1c0.5-0.3,0.8-0.3,1.3-0.1c0.7,0.3,1.4,0.8,2.1,1.2
		c2,1.3,4,2.7,6.2,3.9c0.7,0.4,1.4,1,2,1.5c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.6-0.6,1.1-1.2,1.4c-3,1.8-6,3.6-9,5.4
		c-0.7,0.4-1.4,0.7-2.2,0.5C760.4,709.3,760.2,708.7,759.7,708.5z"
      />
      <path
        class="st876"
        d="M773.5,702.3c0.3,0.4,0.1,0.8-0.2,1.2c-0.4,0.5-1,0.8-1.6,1.1c0.1-0.4,0.5-0.6,0.8-0.8
		C773.2,703.4,773.5,703,773.5,702.3z"
      />
      <path
        class="st877"
        d="M759.7,708.5c0.6,0.1,1,0.6,1.3,1c-0.1,0-0.2,0.2-0.2,0.3c-0.3,0-0.5-0.1-0.7-0.3
		C760.6,709,759.7,708.9,759.7,708.5z"
      />
      <path
        class="st878"
        d="M773,701.7c-1.2-0.4-2-1.3-3.1-1.9c-1.6-0.9-3.1-2-4.7-3c-0.8-0.5-1.7-1-2.5-1.5c-0.9-0.6-1.4,0.3-2,0.6
		c-0.4,0.2-0.7,0.4-1.1,0.4c0.6-0.7,1.6-0.8,2.2-1.6c0.9,0.1,1.6,0.6,2.3,1c0.9,0.6,1.8,1.2,2.7,1.8c1.5,1,2.9,2,4.5,2.8
		C772,700.8,772.7,701,773,701.7z"
      />
      <path
        class="st879"
        d="M749.6,702.5c0,0.8,0.7,1.2,1.3,1.5c0.3,0.2,0.8,0.3,1,0.7c0,0.3,0.4,0.3,0.5,0.6c-0.7-0.3-1.3-0.6-1.9-1
		c-0.5-0.4-1.1-0.5-1.7-0.5c0.3-0.2,0.6-0.3,0.5-0.7C749.1,702.8,749.3,702.6,749.6,702.5z"
      />
      <path
        class="st880"
        d="M759.3,688.3c0.2,0,0.5,0.1,0.5-0.1c0.1-0.3-0.3-0.3-0.4-0.5c0.5,0,0.8,0.3,1.1,0.7s0.1,0.4-0.2,0.5
		C760,688.7,759.6,688.6,759.3,688.3z"
      />
      <path
        class="st839"
        d="M741.2,697.1c-1.8-1-3.7-2-5.4-3.3c0.8,0,1.3,0.5,1.8,0.9c0.7,0.5,1.6,0.7,2.2,1.3
		C740.3,696.5,741,696.5,741.2,697.1z"
      />
      <path
        class="st839"
        d="M742.7,698.1c-0.5-0.2-1.1-0.5-1.5-1C742,697,742.4,697.5,742.7,698.1z"
      />
      <path
        class="st881"
        d="M778.8,684.3c0.7,0.1,1.3,0.5,1.9,0.8c1.9,1.2,3.8,2.4,5.7,3.7c0.2,0.1,0.3,0.2,0.4,0.3c-1.2-0.3-2-1-3-1.6
		c-1.4-0.8-2.7-1.7-4-2.6c-0.4-0.2-0.8-0.4-1.2,0.1c-0.2,0.2-0.6,0.3-0.9,0.2C778,684.9,778.5,684.7,778.8,684.3z"
      />
      <path
        class="st875"
        d="M773.8,696.5c-0.5-0.3-1.1-0.7-1.6-1C772.9,695.6,773.7,695.6,773.8,696.5z"
      />
    </g>
    <g id="molecule">
      <path
        class="st559"
        d="M56.2,869.3c-2.1,2-2.3,6.2,0.3,8.6c2.2,2,5.5,2.1,7.8,0.2c1.9-1.5,2.7-4.2,2-6.2
		C65,868.2,59,866.6,56.2,869.3z"
      />
      <path
        class="st559"
        d="M148.9,828.4c1.2-0.4,1.7,1.1,3.8,1.4c2,0.3,2.5-0.8,4.4-0.6s4,1.7,4.1,3.3c0.1,1.4-1.5,2.4-4.5,4.4
		c-2.3,1.5-3.6,2.4-4.5,1.9c-1.1-0.7,0-2.7-1.4-4.5c-1.2-1.6-3-2.3-3.5-3.6C146.8,829.4,147.8,828.7,148.9,828.4z"
      />
      <path
        class="st559"
        d="M93.6,813.7c-1.7-0.1-3.8,2.7-3,4.8c0.5,1.3,2.2,2.3,3.4,2c1.8-0.5,2.5-3.7,1.4-5.5
		C95.3,814.8,94.6,813.8,93.6,813.7z"
      />
      <path
        class="st559"
        d="M110.7,822.2c-1.3-3-5.8-4.6-9.3-3.1s-4.8,5.5-2.7,8.2c1.9,2.4,5.9,3.1,8.9,1.7
		C110.2,827.6,111.7,824.7,110.7,822.2z"
      />
      <path
        class="st559"
        d="M73.7,811.1c-1-2.8-4.5-4.3-7.3-2.9c-2.7,1.4-3.8,5.1-2.1,7.7c1.5,2.3,4.6,2.9,6.9,1.6
		C73.4,816.2,74.5,813.5,73.7,811.1z"
      />
      <path
        class="st882"
        d="M131.7,946.9c-3.8-2.4-7.8-4.5-11.8-6.8c-5.4-3-10.7-6.3-16.2-9.2c-0.9-0.5-1.7-1.1-2.6-1.5
		c-0.5-0.3-0.4-0.7-0.4-1.1c-0.2-0.2-0.2-0.5-0.2-0.8c0-1.6,0-3.1,0-4.7c0-1,0.4-1.2,1.2-0.7c2.5,1.4,5,2.8,7.1,3.9
		c-1.1-0.4-1.9-1.8-3.1-2.7c-0.3-0.2-0.4-0.5-0.5-0.8c-0.2-0.9-0.4-1.8-1.2-2.3c-0.4-0.3-0.1-0.6,0.2-0.9c0.7-0.4,1.3-0.8,2.2-0.8
		c0.2,0,0.5,0,0.6-0.2c0.3-1,1.3-1.1,1.9-1.7c1.1-0.6,2.2-1.2,3.3-1.8c0.3-0.2,0.7-0.5,1.1-0.4c0.6,0.5,0.1,0.8-0.2,1.1
		c-0.8,1-1.5,2.2-2,3.4c-0.3,0.7-0.4,1.2,0.5,1.6c0.2,0.1,0.3,0.3,0.3,0.5c-0.3,0.5-0.7,0.2-1.1,0.1c-0.4-0.2-0.8-0.5-1.3-0.7
		c-0.8,1.7-0.2,3.5,1.5,4.6c1.1,0.7,2.4,1,3.6,1.3c1.8,0.5,3.7,0.8,5.5,1.1c0.8,0.1,1.5-0.2,2.5,0c-0.8-0.5-1.5-0.8-2.1-1.3
		c-0.2-0.2-0.7-0.3-0.3-0.8c0.8-0.1,1.2,0.5,1.8,0.8c1.2,0.6,2.3,1.3,3.4,2c1.2,0.7,2.5,0.8,3.9,0.9c2.1,0.1,4.2,0.2,6.3,0.6
		c0.3,0.1,0.6,0.1,0.9-0.1c0.9-0.5,1.9-0.9,2.7-1.5c0.6-0.3,1.1-0.9,1.8-0.8c0.5,0.6-0.1,0.8-0.4,1c-0.8,0.5-1.8,1-2.6,1.5
		c-0.1,0,0,0.2,0,0.1c0,0,0-0.1,0.1,0c1.2,0.4,2.5,0.8,3.7,1.2c1.1,0.4,1.9,0.3,2.9-0.1c0.4-0.2,0.8-0.5,1,0.2
		c-0.2,0.5-0.7,0.7-1.1,0.9c-3.8,2.4-7.6,4.7-11.5,7c-1.1,0.7-1.5,1.6-1.3,2.8c0.1,1,0.1,1.9,0.1,2.9c0,0.3,0,0.6,0,1s0,0.8-0.1,1.1
		C131.9,946.8,131.8,946.8,131.7,946.9z"
      />
      <path
        class="st882"
        d="M100.4,928.4c-0.2,0.1,0.4,0.8-0.2,0.4c-2.3-1.3-4.6-2.6-6.9-4c-5.4-3.1-10.7-6.1-16.1-9.2
		c-3.3-1.9-6.7-3.8-10-5.7c-3.3-1.9-6.6-3.7-9.9-5.6c-3.9-2.2-7.8-4.5-11.8-6.8c-0.5-0.3-1.1-0.6-0.7-1.4c0.2-0.8,0.2-1.6,0.1-2.4
		c-0.1-1,0-1.9,0.2-2.8c0.2-0.8,0.4-0.9,1.2-0.5c2,1.2,4,2.2,6,3.3c7,4.1,14,8.1,21.1,12c2.4,1.4,4.9,2.8,7.3,4.2
		c5,2.8,9.9,5.8,14.9,8.4c0.8,0.4,1.5,0.9,2.2,1.4c2.8,1.6,2.9,1.6,2.8,4.8C100.5,926,100.8,927.2,100.4,928.4z"
      />
      <path
        class="st883"
        d="M100.4,928.4c0-1.8-0.1-3.6,0-5.4c0-0.8-0.2-1.3-0.9-1.7c-0.7-0.4-1.4-0.9-2-1.3c0.5-0.2,1.2,0,1.2-0.9
		c0-0.5,0-0.8-0.5-1c-0.4-0.1-0.8-0.3-0.9-0.8c-0.1-0.8-0.5-0.9-1.2-0.8c-0.6,0.1-1.2-0.1-1.9-0.1c-0.6,0-1-0.3-0.7-0.9
		c0.1-0.2,0-0.3-0.1-0.4c-0.7-0.5-0.9-1-0.2-1.8c-0.9,0.2-1.2,1-2,0.8c-0.7-0.1-0.9-0.8-1.6-1c-0.3-0.1-0.6-0.2-0.7-0.4
		c-0.3-1.1-1.2-1.6-2-2.2c-0.4-0.3-0.8-0.6-1.3-0.3c-0.4,0.2-0.8,0.1-1-0.3c-0.2-0.4-0.2-0.8,0.1-1.2c0.8-1,1.5-1.2,2.6-0.5
		c1.2,0.7,2.6,1.1,3.4,2.3c0.1,0.2,0.2,0.3,0.4,0.3c1.9,0.1,3.3,1.5,5,2.2c1.2,0.5,2.2,1.2,3.3,1.8c-1.8-1.1-3.8-2.2-5.8-3.2
		c-0.5-0.3-0.9-0.7-1.3-1.1c-0.8-0.9-1.8-1.7-3.2-1.7c-0.3,0-0.6,0.2-0.8,0c-0.9-0.8-2-1.2-3-1.8c-0.6-0.3-1.2-0.5-1.7-0.8
		c-0.6-0.4-1-0.9-1.5-1.3c-1-0.9-2.2-1.8-3.7-1.8c-1.4,0-2.4-0.9-3.5-1.5c-0.2-0.1-0.2-0.3,0-0.5s0.3-0.3,0.6-0.2
		c2.8,0.4,5.4-0.7,8-1.2c1.8-0.3,3.5-0.5,5.2-0.1c1.1,0.2,2.2,0.4,3.2,0.7s1.3,0,1.6-1c0.1-0.4,0.1-0.8,0.3-1.1
		c0.3-0.5,0.5-0.1,0.6,0.2c0.2,1.3-0.1,2.7,0.3,4c0.1,0.4,0.2,0.8,0.4,1.2c2.1,4.1,6.2,5.5,10.5,3.7c0.8-0.3,1.5-0.9,2.2-1.5
		c0.3-0.3,0.5-0.6,1-0.7c0.3,0.1,0.3,0.3,0.3,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.4,0.6-0.9,1.3-0.4,1.8c0.4,0.5,1.2,0.8,2,0.7
		c1.8-0.3,2.3,0.7,2.2,2.1c0,0.7,0,1.3,0.3,1.9c0.3,0.5,0.2,1-0.2,1.6c2.3-1.5,4.7-2.7,6.9-4.2c0.2-0.2,0.5-0.3,0.8-0.2
		c0.2,0.1,0.3,0.2,0.3,0.5c-0.8,0.9-1.9,1.2-2.8,1.9c-1.2,0.8-2.5,1.6-3.8,2.4c-0.3,0.2-0.7,0.5-1.2,0.4c-1.5,0.7-2.8,1.8-4.4,2.3
		c-1.1,0.7-2.1,1.5-3.7,0.9c0.4,1.1-0.4,1.4-0.9,1.8c-0.1,0.3-0.5,0.5,0.1,0.8c0.7,0.2,0.9,0.9,0.9,1.5c0.1,0.8,0.5,1.4,1.2,2
		c1,0.8,1.9,1.9,3.1,2.5c0.2,0.1,0.4,0.3,0.2,0.7c-1.6-0.8-3.2-1.5-4.6-2.5c-1.2-0.8-2.7-1.2-3.8-2.2c-0.6-0.5-0.6,0.2-0.6,0.6
		c0,1.8,0,3.7,0,5.5C100.6,928.3,100.5,928.3,100.4,928.4z"
      />
      <path
        class="st884"
        d="M75.2,901c0.4,0.3,0.9,0.5,1.3,0.8c0.9,0.6,1.9,0.8,3,0.9c1.3,0.1,2,1.2,3.2,1.6c0.1,0,0.2,0.3,0.2,0.5
		c0.1,0.2,0.1,0.5,0.1,0.5c0.8-0.1,1.2,0.7,1.8,0.8c0.1,0,0.3-0.2,0.4,0c0.6,1.4,2.5,0.8,3.1,2.2c0.1,0.2,0.4,0.2,0.6,0.2
		c1.1-0.4,1.8,0.5,2.7,0.9c1.3,0.6,1.7,2.2,3.2,2.6c1.8,0.5,3.3,1.8,5,2.6c0.2,0.1,0.5,0.2,0.6,0.6c-1,0-1.7-0.8-2.6-1.1
		c-1.9-0.8-3.6-2-5.4-2.9c-0.2-0.1-0.6-0.1-0.9-0.1c-0.5,0-0.8-0.2-1.1-0.6c-1.1-1.3-2.6-2-4.1-2.4c-0.7-0.2-1.2,0.6-1.6,1.2
		c-0.1,0.2,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.1,0.7,0c0.6-0.4,0.9-0.2,1.5,0.3c0.9,0.7,1.6,1.4,2.2,2.5c0.2,0.4,1.1,0.6,1.6,1.1
		c0.4,0.4,0.9,0.2,1.2-0.3c0.3-0.4,0.6-0.5,1.1-0.4c0.3,0.1,0.5,0.2,0.2,0.6c-0.7,0.8-0.6,1.1,0.4,1.5c0.1,0,0.2,0.1,0.3,0.1
		c0.1,0.5-0.8,0.1-0.6,0.7c0.2,0.5,0.6,0.1,0.9,0.2c0.7,0.3,1.4,0.3,2,0.1c0.7-0.2,0.9,0.1,0.9,0.7c0,0.6,0.3,0.9,0.7,1
		c1.5,0.1,0.8,1.1,0.7,1.8c-0.1,0.9-0.9,0.5-1.4,0.4c-3.4-2.2-7.1-3.9-10.5-6c-2.7-1.6-5.5-3.2-8.2-4.7c-3.7-2.1-7.4-4.2-11.2-6.3
		c-3.1-1.7-6.1-3.4-9.1-5.2c-2.2-1.2-4.2-2.6-6.4-3.8c-2-1.1-4.1-2.1-6-3.4c-0.5-0.3-0.7,0-0.8,0.4c-0.3,1.3-0.2,2.6-0.2,3.9
		c0,0.4,0.2,0.8-0.2,1.2c0-2-0.3-3.9,0.2-5.8c0.1-0.3,0.1-0.6,0.4-0.8c3.5-2,6.9-4,10.4-6c1.4-0.8,2.9-1.6,4.3-2.4
		c0.6-0.3,1-0.7,0.9-1.5c0.3-0.3,0.7-0.3,1.1-0.3c0.6,0.2,1.2-0.2,1.8,0c0.4,0.1,0.8,0,1,0.4c-0.1,0.3-0.4,0.4-0.6,0.5
		c-0.2,0.1-0.6,0.2-0.5,0.6c0.1,0.4,0.5,0.3,0.8,0.2c0.5-0.1,1-0.2,1.5-0.2c2.3-0.4,4.4,0.4,6.4,1.5c0.4,0.2,0.4,0.6,0.3,1
		c-0.4,0.4-0.6,0-0.9-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.3-0.7-0.5-1.1-0.6c-0.7-0.3-1.4-0.5-2.2-0.6c-0.7-0.2-1.3-0.2-2,0
		c-5,1.1-7.2,4.4-6.5,9.7c0.1,0.4,0.2,0.8,0.4,1.1c0.2,0.6,0.5,1.1,0.9,1.6c0.6,0.9,1.4,1.7,2.4,2.3c0.3,0.2,0.6,0.4,0.7,0.8
		c0,0.2,0,0.5,0,0.7c0,0.8,0.3,1.3,1.1,1.7c1,0.5,2.1,0.9,2.9,1.8c0.2,0.3,0.5,0.2,0.9,0.2c1,0,1.9,0.2,2.7,0.9
		c0.2,0.2,0.4,0.3,0.8,0.3c1,0,2,0,3,0c0.3,0,0.7,0,0.8-0.3c0-0.3-0.4-0.4-0.7-0.6c-0.4-0.2-0.9-0.2-1.2-0.6
		c-0.1-0.2-0.2-0.5,0.1-0.6C74.7,900.6,74.9,900.7,75.2,901z"
      />
      <path
        class="st885"
        d="M219.7,892.3c1.7,0.3,3.1,1.4,4.4,2.4c0.4,0.3,0.3,0.5,0,0.8s-0.6,0.6-1.1,0.7c-0.9-1.3-2.4-1.9-3.8-2.7
		c-0.2-0.1-0.3,0-0.5,0.1c-1.8-0.2-3.2,0.9-4.7,1.5c-1.4,0.7-2.8,1.5-4.2,2.3c-1.7,0.9-3.3,2-5.1,2.9c-1.7,0.9-3.2,2.1-4.8,3
		c-0.5,0.3-1,0.6-1.5,0.9c-1.1,0.6-2.4,0.8-3.3,1.9c-0.4,0.4-1,0.7-1.6,0.8c-2.3,0.7-4.2,2-6.3,3.2c-0.4,0.2-0.7,0.4-1.1,0.6
		c-0.9,0.4-1.8,0.8-2.4,1.6c-0.3,0.3-0.6,0.6-1,0.7c-1.9,0.4-3,1.8-4.2,3.1c-0.9,0.9-2.1,1.4-3.1,2.1c-2.2,1.5-4.5,2.8-6.7,4.3
		c-1.8,1.2-3.6,2.4-5.5,3.4c-0.5,0.3-1,0.4-1.3-0.2c-0.2-1.2-0.1-2.3-0.1-3.5c0.1-1.1,1.1-1.4,1.9-1.9c4-2.5,8.3-4.7,12.2-7.3
		c-0.4-0.3-0.9-0.3-1.4-0.3s-0.9-0.1-1.3-0.5c-0.2-0.3-0.8-0.3-0.7-0.9c0.3-0.3,0.7-0.1,1,0c0.5,0.2,1,0.3,1.5,0.4
		c1.2,0.3,2.3,0.2,3.5-0.5c2-1.2,4-2.4,6-3.6c0.6-0.3,0.5-0.6,0-0.9c-0.7-0.4-1.3-0.8-2.1-1.2c-0.3-0.2-0.7-0.3-0.9-0.7
		c-0.1-0.2-0.1-0.3,0-0.5c0.6-0.6,1.2-0.2,1.7,0c0.7,0.3,1.3,0.8,1.9,1.3c0.8,0.7,1.6,0.7,2.5,0.1c3.8-2.2,7.6-4.5,11.3-6.8
		c2.1-1.2,4.2-2.4,6.3-3.7c1.7-0.7,3.2-1.9,4.8-2.7c1.6-0.5,3-1.2,4.2-2.4c0.8-0.8,1.8-1.3,2.8-1.8c0.7-0.4,1.5,0.1,1.5,0.9
		c0,0.5,0.1,1.1,0,1.7C218.8,891.7,219.1,892.1,219.7,892.3z"
      />
      <path
        class="st886"
        d="M178.9,922.5c-1.8,1.4-3.8,2.5-5.7,3.5c-4.5,2.5-8.7,5.6-13.6,7.2c-1.1,0.4-1.9,1.3-2.9,1.9
		c-1.6,0.3-2.5,1.7-3.7,2.5c-4.7,3-9.4,6-14,9.1c-1.8,1.2-4.1,1.2-6.2,1.3c-0.1-0.2,0.1-0.3,0.2-0.4c6.9-4.4,14.1-8.4,21-12.6
		c3.9-2.4,7.9-4.6,11.8-7c3.2-2,6.5-3.9,9.7-5.8c3.2-1.9,6.4-3.9,9.8-5.7c1.1-0.6,2-1.5,3.3-1.8c0.2,0,0.3,0.1,0.3,0.3
		c-0.6,1-1.9,1.2-2.6,2.1c-0.2,0.2-0.5,0.4-0.6,0.7c-1.1,1.5-2.6,2.3-4.3,3c-0.4,0.2-0.8,0.3-1,0.7
		C179.9,921.9,179.3,922,178.9,922.5z"
      />
      <path
        class="st887"
        d="M186.8,857.7c1.1-0.4,2.2-0.6,3.3-0.5c-0.1,0.5-0.6,0.5-1,0.8c1.4,0.1,1.9,1.4,2.8,2
		c0.5,0.3,2.3,0.4,2.8,0.1c0.3-0.2,0.1-0.3,0-0.5s-0.3-0.5,0.2-0.6c1.5,1.2,2.4,2.7,2.7,4.6c0,0.5,0,0.9,0,1.3
		c0,0.5-0.1,0.9-0.5,1.2c-2.1,1.3-4.1,3.1-6.8,3.4c-1.2,0.2-2.4,0.8-3.6,0.2c-0.2-0.1-0.4,0.1-0.6,0.2c-0.3,0.2-0.6,0.5-1,0.4
		c-0.4-0.3-0.1-0.5,0.1-0.7c0.8-1.3,0.7-1.8-0.3-3.1c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.1-0.7-0.2-0.9-0.5c0.1-0.3,0.3-0.3,0.6-0.4
		c0.7-0.1,1.2,0.2,1.7,0.6c0.7,0.4,1.1,0.2,1.1-0.5c0.1-0.8,0.3-1.6,0.6-2.4c0.4-0.9-0.2-2-1.1-2.5c-0.3-0.1-0.6-0.1-0.9-0.3
		C185.7,859.3,186,858.3,186.8,857.7z"
      />
      <path
        class="st888"
        d="M191.8,873.2c-0.3,0.6,0.3,0.5,0.5,0.6c3.4,1.8,6.9,3.6,10.3,5.4c0.3,0.2,0.6,0.4,0.9,0.5
		c0,0.6-0.5,0.5-0.8,0.4c-0.5-0.1-1-0.3-1.5-0.7c0.5,0.7,1.4,0.8,2,1.3c0.3,0.2,0.8,0.3,0.6,0.9c-1,0.1-1.7-0.5-2.5-0.9
		c-1.6-0.7-3.1-1.7-4.7-2.5c-1-0.5-2.2-0.8-2.9-1.9c-0.6-0.6-1.2-1.1-1.9-1.7c-0.7-0.6-1.4-0.2-1.9,0.4c-2,1.5-4.2,2.7-6.4,4
		c-1.2,0.8-2.6,1.3-3.6,2.3c-0.5,0.8-1.3,0.9-1.9,1.5c-0.8,0.5-1.6,0.9-2.4,1.3c-0.4,0.2-0.8,0.4-1-0.2c0.9-0.8,2.1-1.2,3-2
		c0.5-0.4,0.8-0.8,0.9-1.4c0.2-1.1,0.9-2.1,1-3.2c0-0.7,0.1-1.4-0.1-2c-0.1-1.1-0.3-2.3,1-2.9c0.8-0.5,1.4-1.1,2.2-1.6
		c0.5-0.3,0.9-0.3,1.3,0.2c1.8,2,4.1,2.5,6.7,2.1C191.1,873,191.5,872.8,191.8,873.2z"
      />
      <path
        class="st889"
        d="M176.6,829.4c2,1.8,2.8,4.2,2.7,6.8c-0.1,0.1-0.2,0.1-0.3,0.1s-0.3,0-0.4-0.1c-1.7-0.6-2.7,0.1-3,1.8
		c-0.2,1,0.6,1.4,1.2,1.9c0.3,0.8-0.2,1-0.8,1.2c-2.7,0.7-5.5,0.9-8.2,0.3c-0.2-0.1-0.4-0.2-0.6-0.4c0.1-1.2,0.3-2.5,0.4-3.7
		c0.1-0.1,0.1-0.2,0.2-0.2c1.2,0.4,2.2-0.3,3-1c1.7-1.3,3.4-2.6,4.1-4.8C175.3,830.6,175.6,829.7,176.6,829.4z"
      />
      <path
        class="st890"
        d="M73.2,817.5c0.8,0.7,1.7,0.7,2.7,0.6c0.9,0,1.8,0.1,2.6,0.6c-1.2,2.9-2.7,5.5-5.3,7.3
		c-0.8,0.5-1.6,1.1-2.5,1.5c-1.1,0.6-2.1,1.3-2.4,2.6c-0.4-1-0.7-2.2-0.8-3.3c-0.2-1,0.1-1.9,0.8-2.8c0.4-0.5,0.8-1.3,0.7-2.1
		c-0.1-0.6,0.2-0.8,0.8-0.8c0.7,0,0.7-0.3,0.5-0.8c0.4-1,1.3-1.5,2.3-1.8C73.2,818.6,72.9,817.9,73.2,817.5z"
      />
      <path
        class="st891"
        d="M96.5,788.5c0.4,0.3,0.8,0.6,1.1,1.1c-0.1,1.6-0.9,2.8-1.9,3.9c-1.2,1.2-2.4,2.4-3.6,3.6
		c-0.3,0.3-0.8,0.6-0.5,1.1s0.9,0.5,1.4,0.4c0.4-0.1,0.9-0.3,1.3-0.5c1-0.4,2.1-0.5,3.1-0.1c0.5,0.5,1,0.9,0.8,1.7
		c-1.2,1.6-2.7,2.8-4.6,3.5c-1,0.1-2.1,0.4-3.1,0.3c-0.2,0-0.4-0.2-0.7-0.3c-0.3-0.4-0.9-0.4-1.3-0.6c-1.2-0.5-2.3-1.1-3.1-2.2
		c0-0.8,0.8-0.9,1.3-1.3c2.8-2.2,5.8-4.2,7.9-7.1c0.3-0.4,0.5-0.8,0.9-1.2c0.4-0.5,0.6-1,0.5-1.6C96,789,95.9,788.5,96.5,788.5z"
      />
      <path
        class="st892"
        d="M66.4,848.8c-1,4.2-3.7,6.6-8,6.8c-2.6,0.1-4.8-0.9-6.4-3c0.2-0.4,0.6-0.6,0.9-0.8c3.1-2.4,6.3-4.7,8.8-7.8
		c0.5-0.6,0.7-1.3,1.3-1.7c0.4-0.3-0.3-0.8,0.2-1.1c0.6,0,0.8,0.5,1.1,0.9c0.1,0.2,0.2,0.5,0,0.7c-1.8,2.4-3.7,4.7-6.1,6.6
		c-0.3,0.3-0.7,0.5-0.9,0.8s-0.5,0.5-0.3,0.9c0.2,0.3,0.5,0.5,0.8,0.5c1.4,0.2,2.7-0.2,3.9-0.9c0.3-0.2,0.6-0.4,0.9-0.5
		c1.2-0.2,2-0.7,2.7-1.7C65.9,847.9,66.1,848,66.4,848.8z"
      />
      <path
        class="st893"
        d="M138.1,785.9c-0.3-1-0.3-2.1-0.3-3.2c0-1,0.4-2,0.6-2.9c0.1-0.2,0.3-0.4,0.3-0.7c0-0.8,0.4-1.1,1.2-0.9
		c-0.3-1.7,0.4-2.3,2.1-1.8c0.5,0.1,1.1,0.1,1.6,0.1c0.4,0,0.7-0.3,0.5-0.6c-0.4-1,0.3-1.2,0.9-1.5c-0.2-0.3-0.5-0.1-0.6-0.3
		c0-0.1,0-0.1,0.1-0.1c1.5,0,3.1-0.3,4.2,1c-0.6,0.3-1,0.8-1.3,1.5c-1.1,2.7-3.1,4.7-5.2,6.4c0.1,0,0.4,0,0.4,0.4
		c0,0.2-0.2,0.2-0.4,0.3c-1.2-0.3-2.2,0-2.8,1.2C139.1,785.2,139,786,138.1,785.9z"
      />
      <path
        class="st894"
        d="M110.8,789.3c0.4,0.2,0.7,0.5,0.9,0.9c0,1-0.6,1.6-1.1,2.2c-1.7,2-3.5,3.9-5.6,5.6c-0.3,0.3-0.8,0.6-0.6,1.1
		c0.1,0.5,0.7,0.5,1.1,0.6s0.8,0,1.1,0c2.3-0.5,4.4-1.3,6.1-2.9c0.2-0.2,0.4-0.5,0.7-0.2c0.2,0.4,0.2,0.7,0.2,1.1
		c-0.6,3.3-4.1,6.2-7.6,6.4c-2.3,0.1-4.2-0.6-6-1.9c-0.3-0.2-0.6-0.5-0.7-0.9c0-0.3,0-0.7,0.4-0.6c0.8,0.1,1-0.3,1.2-0.9
		c0.1-0.3,0.4-0.5,0.6-0.7c2.9-2,5.7-4.2,7.9-7C110.1,791.2,110.3,790.2,110.8,789.3z"
      />
      <path
        class="st895"
        d="M187.4,808.3c-0.9,4.2-4.1,6.9-8.5,6.9c-0.7,0.4-1.4,0-2.1,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5-0.1-0.8-0.2
		c-0.1,0-0.2-0.1-0.3-0.2c-0.6-0.6-1.4-1-1.9-1.7c-0.2-0.3-0.6-0.5-0.4-0.9c1.4-0.7,2.8-1.6,4.1-2.6c1.6-1.3,3.3-2.6,4.7-4.2
		c0.8-1,1.6-2,2.4-3c0.5-0.6,0-1.3,0.5-1.8c0.6,0.3,0.9,0.9,1.3,1.4c-0.4,0.3-0.9,0.6-1.1,1c-1.4,2.4-3.4,4.2-5.4,6
		c-0.4,0.4-0.8,0.8-1.1,1.2c-0.2,0.2-0.4,0.4-0.3,0.7c0.2,0.3,0.5,0.3,0.8,0.3c0.9,0,1.8-0.1,2.5-0.5c1.4-0.7,3.1-1,4.1-2.5
		c0.2-0.3,0.6-0.4,0.9-0.6C187.2,807.3,187.2,807.3,187.4,808.3z"
      />
      <path
        class="st896"
        d="M191.8,873.2c-1.6,0.4-3.2,0.5-4.9,0c-1.3-0.4-2.4-1-3.1-2.2c0-0.8,0.5-0.9,1.1-0.8c0.4-0.2,0.8-0.4,1-0.6
		c0.2-0.2,0.4-0.3,0.7-0.2c2.7,0.4,5.1-0.3,7.5-1.7c0.8-0.5,1.4-1.3,2.5-1.6c0.4-0.1,0.9-0.6,0.8-1.3c-0.1-0.4-0.1-0.8,0.2-1.2
		c0.6,2.6-0.1,4.8-1.7,6.8C194.9,871.8,193.5,872.7,191.8,873.2z"
      />
      <path
        class="st897"
        d="M50.7,850.5c-1.3-2.8-0.4-7.1,1.9-9.1c2.9-2.5,7-2.9,9.8-1c-0.3,0.6-0.7,0.1-1.1,0.1c-0.5,0-1.2-0.4-1.1,0.6
		c0,0.3-0.4,0.6-0.6,0.8c-0.8,0.9-1.7,1.8-2.2,3c-0.6,1.2-1.7,2-3,2.7c-0.4,0.2-0.9,0.4-1.3,0.6C52.2,848.7,51,849.2,50.7,850.5z"
      />
      <path
        class="st898"
        d="M188.7,915.2c-0.1-0.1-0.1-0.1-0.2-0.2c0.3-0.9,1.2-1.1,1.9-1.5c6.7-4,13.4-7.9,20.2-11.8
		c2.8-1.6,5.5-3.3,8.3-4.9c0.8-0.5,0.9-0.8,0.2-1.5c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.5-0.1-0.9,0.2-1.3c0.1-0.6,0.5-0.2,0.7-0.1
		c1.1,0.7,2.2,1.4,3.3,2.1c0.4,0.2,0.3,0.5,0.2,0.8c-2.9,1.7-5.8,3.4-8.7,5.1c-3.3,1.9-6.5,3.8-9.8,5.7c-2.6,1.5-5.2,3-7.7,4.4
		c-1.7,0.8-3.1,2-4.9,2.6C190.9,914.2,189.9,914.9,188.7,915.2z"
      />
      <path
        class="st899"
        d="M66.4,848.8c-0.2-0.3-0.3-0.6-0.6-0.1c-1.4,2.1-3.9,2.2-6,3.1c-0.7,0.3-1.4,0.1-2.1,0.1
		c-0.5,0-0.8-0.3-1-0.7c-0.2-0.5,0.1-0.8,0.4-1.1c1.5-1.3,3-2.6,4.2-4.1c0.8-0.9,1.8-1.7,2.2-2.9c0.2-0.5,1-0.4,0.8-1.1
		c0.8,0.7,1.1,1.7,1.6,2.6c0.3,0.2,0.5,0.6,0.6,0.9c0.1,0.2,0.1,0.3,0.1,0.5c0,0.8,0.2,1.6-0.3,2.3
		C66.4,848.5,66.4,848.6,66.4,848.8z"
      />
      <path
        class="st900"
        d="M83.9,797.5c-1.8-4.3,1-9.8,6.1-10.6c1.8-0.3,3.5-0.2,5.1,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0-0.6,0
		c-1.1-0.2-1.2,0-1.4,1c-0.2,1-0.7,1.9-1.3,2.7c-1.3,1.8-3,3.1-5.1,3.9c-0.9,0.4-1.9,0.9-2.2,2C84,797.4,84,797.5,83.9,797.5z"
      />
      <path
        class="st901"
        d="M67,862.1c-0.5-1.1-0.7-2.2,0-3.3c0.5-0.8,0.5-1.8,0.7-2.8c0.2-0.8,0.6-1.1,1.2-1.4c0.9-0.3,1.7-0.7,2.3-1.5
		c0.4-0.6,0-1.3,0.5-1.8c0.1,0,0.2,0,0.3,0c0.2,0.2,0.4,0.5,0.4,0.8c0.3,0.8,0.9,1.1,1.7,1c0.8,0.2,1.7,0.3,2.4,0.9
		c0.1,0.6-0.2,1.1-0.6,1.5c-0.8,0.8-1.4,1.7-2.2,2.5c-1.4,1.6-3.3,2.3-5.1,3.3C68,861.5,67.6,862,67,862.1z"
      />
      <path
        class="st902"
        d="M113.3,796.5c-1.9,2.1-4.4,3-7.1,3.5c-0.6,0.1-1.2-0.1-1.8-0.3c-0.5-0.1-0.5-0.5-0.3-0.9
		c0.2-0.5,0.5-0.8,0.9-1.1c2.2-1.8,4.1-3.9,5.9-6c0.4-0.5,0.1-1.2,0.8-1.5c1,1,1.4,2.3,1.8,3.5c0.4,0.7,0.3,1.5,0.2,2.2
		C113.7,796.2,113.7,796.5,113.3,796.5z"
      />
      <path
        class="st903"
        d="M176,843.5c2.6,3.9,5.2,7.8,7.8,11.7c0.5,0.7,1.1,1.4,1.6,2.1c0.2,0.3,0.4,0.4,0.8,0.4
		c-0.6,1-0.9,2.3-2.4,2.3c-0.8-0.8-1.4-1.8-1.9-2.7c-0.8-1.5-1.5-3.1-2.6-4.4c-1.7-2-2.7-4.4-4.4-6.4c-0.7-0.8-1.3-1.7-2.3-2.2
		c0.7-0.8,1.7-0.9,2.6-1.3C175.6,842.8,175.6,843.5,176,843.5z"
      />
      <path
        class="st904"
        d="M187.4,808.3c-0.1-0.2-0.2-0.4-0.3-0.7c-1.9,2-4.2,3.4-6.9,4c-0.6,0.1-1.2,0.1-1.9,0.1s-0.9-0.6-0.5-1
		c0.5-0.6,1-1.2,1.6-1.8c1.9-1.7,3.8-3.4,5.1-5.7c0.3-0.5,0.4-1.4,1.4-1.1C187.4,804,187.6,806.1,187.4,808.3z"
      />
      <path
        class="st905"
        d="M153,782.7c-0.3,3.3-2,5.6-5.1,6.8c-0.4,0.2-1,0.2-0.9,0.9c-0.6,0-1.2-0.2-1.8-0.2s-1,0-0.5,0.8
		c0.8,1.2,0.8,2.6,1.1,3.9c0.4,1.3,0.7,2.6,1.1,3.9c0.1,0.2,0.1,0.4,0.3,0.5c0.3,0,0.6-0.1,0.9,0.1c0,0.5-0.3,0.7-0.7,0.7
		c-0.4-0.1-0.9,0-1.3-0.3c-0.5-2.3-1.2-4.5-1.7-6.8c-0.2-0.9-0.5-1.7-1.1-2.4c-0.2-0.5-0.6-0.8-1.1-1s-1-0.4-1.5-0.8
		c-0.1-0.8,0.6-1.2,1-1.6c0.1,0,0.2,0,0.3,0c0.9,0,1.1-0.7,1.3-1.3c0.1-0.3-0.1-0.6,0-0.9c0.3-0.7,0.8-0.4,1.3-0.3
		c0.2,0.1,0.4,0.3,0.6,0.5c0.4,0.3,0.8,0.4,1.2,0.4c1.9,0.1,3.5-0.6,5.1-1.5c0.5-0.2,0.6-0.7,0.8-1.2
		C152.5,782.6,152.7,782.2,153,782.7z"
      />
      <path
        class="st906"
        d="M73.4,838c-0.1,0-0.1,0-0.2,0c-0.2-0.3-0.3-0.7-0.3-1.1c0.2-1.5,0.3-3,0.3-4.5c0-0.9,0.5-1.6,1.3-2.1
		c0.9-0.2,1.5-0.9,2.5-1c1.1,0.7,2.3,0.5,3.4,0.2c0.9-0.2,2,0,2.6-1.2c0.1-0.2,0.4,0,0.6,0.1c0.3,1.1-0.4,1.9-0.9,2.7
		s-1.1,1.5-1.8,2.1c-0.1,0-0.2,0.1-0.3,0.1c-0.9,0.2-1.9,0.2-2.9,0.5c1.4,0.3,1.5,1.6,1.6,2.7c0.2,1.1,0.4,2.3-0.3,3.3
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.1-0.5-0.3c0-1.1,0.1-2.3-0.1-3.4c-0.1-0.4-0.1-0.8-0.4-1.1c-0.4-0.1-0.4,0.5-0.8,0.5
		c-0.2-0.2-0.3-0.4,0-0.9c-0.4,0.3-0.6,0.5-0.9,0.5c-0.4,0.1-0.7,0-1.1-0.2c-0.8-0.3-1-0.1-1,0.7c0,0.7,0.2,1.5-0.4,2.1
		C73.5,837.9,73.5,838,73.4,838z"
      />
      <path
        class="st907"
        d="M98.1,799.6c0.1-0.6-0.5-1-0.7-1.4c-0.4-0.3-0.5-0.7-0.3-1.2c1.4-1.6,3.2-2.8,5.1-3.8
		c0.3-0.2,0.6-0.3,0.6-0.6c0.1-1,0.8-1.5,1.4-2.1c0.3-0.3,0.6-0.8,0.8-1.2c0.2-0.4,0.4-0.7,0-1.1c-0.4-0.4-0.1-0.7,0.4-0.8
		c0.6,0,1.2-0.1,1.8,0.1c0.9,0.2,1.8,0.3,2.5,1c0.1,0.1,0,0.3,0,0.2c-1.1-0.4-1.2,0.6-1.4,1.2c-0.2,0.4-0.4,0.8-0.7,1.2
		c-2.3,2.8-5,5.2-8.2,7c-0.6,0.3-0.5,0.9-0.6,1.4c0,0.2-0.1,0.3-0.3,0.3C98.3,799.9,98.2,799.8,98.1,799.6z"
      />
      <path
        class="st908"
        d="M107.2,787.5c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.3,0.2,0.3,0.4,0.3c0.6,0.1,0.5,0.3,0.2,0.7
		c-0.7,0.9-1,2-2.1,2.6c-0.3,0.1-0.2,0.5-0.2,0.8c-0.1,0.7-0.4,1.3-1.2,1.3c-0.3,0-0.4,0.2-0.6,0.3c-1.4,1-2.8,2-4.2,3.1
		c-0.2-0.1-0.3-0.3-0.4-0.5c-0.4-1.2,0-4.5,1.5-5.8c0.7-0.3,1.1-1,1.7-1.5C102.2,787.5,104.6,786.9,107.2,787.5z"
      />
      <path
        class="st909"
        d="M171.2,805.1c0.4-1,0.8-2,1.4-3c2.3-3.5,6.9-4.1,10.2-2.8c0.2,0.1,0.3,0.2,0.3,0.4c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.8-0.1-1.5-0.5-2.3-0.6c-0.6-0.1-1-0.1-0.9,0.7c0.1,0.9-0.6,1.6-1.1,2.1s-0.8,1.1-1.1,1.7c-0.5,1-1.1,1.8-2.4,1.5
		c-0.3-0.1-0.5,0-0.7,0.3c-0.3,0.6-0.9,0.9-1.5,1.1c-1,0.3-1.1,1-1,1.9c0.1,0.5,0.3,1.1-0.5,1.2c-0.3-0.2-0.3-0.6-0.3-1
		C170.8,807.6,170.8,806.3,171.2,805.1z"
      />
      <path
        class="st910"
        d="M149.5,799.2c2.8,0.7,4.6,2.6,5.8,5.2c0.1,0.3,0.2,0.8,0.6,1c0.5,0,0.7,0.4,1,0.7c0.9,0.9,2.1,1.3,3.4,1.4
		c3.2,0,6.4,0,9.6,0c0.6,0,1.4-0.1,1.1,0.9c-0.4,0.3-0.9,0.3-1.3,0.3c-1.8,0-3.7-0.1-5.5,0.2c-0.7,0.1-1.4,0-2.2-0.1
		c-0.7-0.1-1.4-0.1-2.1,0.2c-1.1,0.4-2.3,0.1-3.4,0.3c-0.3,0-0.6-0.2-0.7-0.5c-0.3-0.7-0.9-0.8-1.6-0.7c-0.4,0.1-0.8,0.1-1.1,0.1
		c-0.7,0-1.1-0.3-1.1-1c0-0.8,0.5-1.4,0.8-2.1c0.4-0.9,0.4-1.8-0.1-2.6c-0.7-1.2-1.6-2-2.9-2.4C149.5,799.7,149.4,799.5,149.5,799.2
		z"
      />
      <path
        class="st911"
        d="M194.1,835.4c1.3,1.8,1.8,3.8,1.6,6c-0.4,0-0.3-0.4-0.4-0.5c-1.2,0.9-2.7,1.6-3.3,3.1c-0.1,0-0.3,0-0.4-0.1
		c-0.7-0.8-1.6-0.8-2.6-0.5c-0.8,0.1-1.2,0.5-1.3,1.3c-0.1,0.2-0.2,0.4-0.4,0.5c-1.3,0.1-1.7-0.3-1.2-1.5c0.8-1.1,2.1-1.7,3.1-2.5
		c1.4-1.2,2.5-2.6,3.5-4.2C193.1,836.4,193.2,835.5,194.1,835.4z"
      />
      <path
        class="st912"
        d="M67,862.1c0.2-0.5,0.7-0.8,1.2-1c2.5-1.2,4.9-2.5,6.6-4.8c0.5-0.8,1.3-1.3,1.7-2.2c0.1-0.1,0.2-0.1,0.3,0
		c0.4,0.6,0.5,1.3,0.7,2c-0.1,0.2-0.2,0.4-0.3,0.2c-0.7-0.5-1-0.1-1.2,0.5c-0.2,0.7-0.8,1-1.3,1.5c-1.2,1.3-2.8,2.2-4,3.5
		c-0.2,0.2-0.6,0.2-0.9,0.3c-0.2,0.1-0.3,0.2-0.5,0.4c0.2-0.1,0.4-0.1,0.6,0c0.6,0.5,0.7,1-0.1,1.5c-0.4,0.3-0.8,0.7-1,1
		c-1,1.5-2.5,2.5-3.6,3.9c-0.3,0.3-0.6,0.6-1.1,0.5c-0.5-0.2-0.7,0.1-0.8,0.5c-1.2,2.6-3.3,4.4-5.6,6c-0.6,0.4-1.2,0.7-1.7,1.2
		c-0.5-0.4-0.4-0.9-0.4-1.5c0.2-0.2,0.4-0.3,0.6-0.4c2.8-1.2,4.9-3.1,6.2-6c0.1-0.3,0.2-0.6,0.5-0.7c0.9,0.2,1.6-0.3,2.1-1
		c0.7-0.8,1.4-1.5,2.1-2.3c0.5-0.6,0.8-1.2,0.3-2C67.1,862.9,67.1,862.5,67,862.1z"
      />
      <path
        class="st913"
        d="M50.7,850.5c-0.1-1,0.4-1.4,1.2-1.9c1.4-0.9,3.1-1.3,4.3-2.6c0.4-0.4,0.8-0.8,1-1.3c0.5-1.3,1.5-2.2,2.5-3.1
		c0.3-0.3,0.4-0.6,0.3-1.1c-0.2-0.5,0-0.8,0.6-0.5s1.2,0.3,1.8,0.4c0.2,0,0.3,0.1,0.3,0.3c-0.6,1.6-1.6,2.9-2.9,4.1
		c-1.8,1.7-3.5,3.4-5.4,4.9c-0.7,0.5-1.4,1-2.2,1.3c-0.5,0.2-0.9,0.2-1.4-0.1C50.9,850.7,50.8,850.6,50.7,850.5z"
      />
      <path
        class="st914"
        d="M153,782.7c-0.2-0.1-0.4-0.1-0.4,0.2c0.1,0.8-0.4,1.2-1.1,1.5c-1.6,0.8-3.2,1.5-5.1,1.4
		c-0.5,0-1-0.1-1.3-0.6c0.1-0.3,0.3-0.4,0.4-0.5c1.7-1.2,2.8-2.9,4.3-4.3c0.9-0.9,1-2.2,1.5-3.2C152.8,778.8,153.1,780.7,153,782.7z
		"
      />
      <path
        class="st915"
        d="M176,843.5c-0.1,0.3-0.2,0.5-0.4,0.1c-0.2-0.7-0.5-0.6-0.9-0.2c-0.6,0.5-1.5,0.5-2.1,0.9
		c-0.1,0.2-0.2,0.2-0.4,0.3c-2.2,0.5-4.2,0.2-6.1-1.1c-0.1-1,0.6-1.7,1-2.5c0.1-0.1,0.2-0.1,0.3,0c2.6,0.6,5.2,0.8,7.8,0
		c0.4-0.1,0.8,0.1,1.2-0.3c0.3-0.2,0.4-0.4,0.2-0.7c0.7-0.3,0.9-0.3,1.3,0.2c-0.5,0.8-1,1.6-1.7,2.2
		C175.7,842.8,175.7,843.1,176,843.5z"
      />
      <path
        class="st916"
        d="M194.7,844.8c0.3,0.8,1.2,0.8,1.7,1.5c-0.6,0.2-1,0.6-1.4,1.2c-0.9,1.7-2.3,2.9-3.8,4
		c-0.6,0.5-1.6,0.5-2.1,1.3c-0.7-0.6-0.5-1.5-0.4-2.2c0.2-1-0.2-1.5-0.9-1.9c0.3-0.4,1.1-0.2,1.3-0.9c0.1-0.1,0.2-0.2,0.3-0.3
		c0.8-0.8,1.7-1.4,2.7-1.8c0.1,0,0.2-0.1,0.3-0.1C193.2,845.4,193.9,845,194.7,844.8z"
      />
      <path
        class="st917"
        d="M182.8,799.8c0.1,0,0.2,0,0.3,0s0.1,0,0.2,0.1c0.8,0.5,0.9,1,0.4,1.9c-0.7,1.6-1.9,2.8-3.1,4
		c-1.6,1.7-3.3,3.3-5.4,4.5c-0.3,0.2-0.6,0.4-0.9,0.7c-0.8,0.8-0.8,0.8-1.7,0.2c-0.3,0-0.5-0.2-0.8-0.4c-0.1-0.2-0.2-0.3-0.2-0.6
		c0.2-0.6,0.6-1,1.3-1.3c1.6-0.7,3.1-1.7,4.5-2.9c1.8-1.5,3.1-3.3,4.2-5.3C181.8,800.2,182.2,799.8,182.8,799.8z"
      />
      <path
        class="st918"
        d="M182,846c-0.4-0.3-0.8-0.7-1-1.2c-0.1-0.4,0.2-0.5,0.4-0.6c0.8-0.6,1.2-1.3,0.7-2.3c-0.1-0.1,0-0.3,0.1-0.4
		c0.7-0.2,1.3-0.6,1.8-1.2c1-0.3,2.1-0.2,3.1-0.7c2.3-1.1,3.9-2.9,5.1-5.2c0.1-0.2,0.1-0.3,0.3-0.4c0.4,0,0.6,0.1,0.8,0.5
		c0,0.5,0,1-0.3,1.4c-1.4,2.2-2.8,4.4-5.1,5.8c-0.3,0.2-0.5,0.4-0.8,0.4c-1.2,0.3-1.9,1.1-2.3,2.2C184.1,845.3,183.2,845.8,182,846z
		"
      />
      <path
        class="st919"
        d="M184.1,840.4c-0.4,0.7-0.9,1.2-1.9,1.1c-0.8,0.2-1.3-0.2-1.9-0.6c-0.8-0.4-1.5-1-2.5-0.7
		c-0.4-0.2-0.8-0.2-1.3-0.2c-1.3-0.8-1.6-1.5-0.9-3c0.6-1.3,1.4-1.7,2.8-1.1c0.2,0.1,0.5,0,0.6,0.3c-0.2,0.4-1,0.2-0.9,0.8
		c0.1,0.5,0.7,0.5,1.1,0.6c1.6,0.4,3.1,1,4.7,0.9c0.3,0.1,0.4,0.3,0.4,0.6c0,0.1,0,0.2,0,0.3C184.4,839.8,184.3,840.1,184.1,840.4z"
      />
      <path
        class="st920"
        d="M98.3,790.7c-0.7,1.5-1.4,2.9-1.3,4.6c0,0.6,0.1,1.1,0.2,1.6c-0.1,0.5,0.2,0.8,0.2,1.3
		c-1.5-0.5-2.9,0.1-4.3,0.7c-0.8,0.3-1.5,0-1.9-0.5s0.2-1,0.6-1.4c1.1-1.1,2.1-2.3,3.3-3.3c1-0.8,1.3-2.1,2.1-3
		c0.2-0.2,0-0.4-0.1-0.6c-0.2-0.5,0.2-0.4,0.4-0.4C97.8,790,98.1,790.3,98.3,790.7z"
      />
      <path
        class="st921"
        d="M84.9,822.9c0.3-0.8,0.3-1.6,0.6-2.4c1-2.5,2.7-4.1,5.2-5c0.3-0.1,0.5,0,0.7,0.1c0.4,0.3,0.9,0.4,1.3,0.6
		c-0.1,0.1-0.2,0.1-0.2,0.1c0.2,0,0.3,0,0.5,0.1c0.4,1.2-0.1,2.5-1,3.2c-0.3-0.1-0.3-0.4-0.4-0.7c-0.2-0.7-0.6-1-1.2-1.1
		c-0.8-0.1-1.2,0.5-1.6,1c-0.3,0.5-0.3,1.1,0.2,1.6c0.2,0.2,0.4,0.4,0.5,0.6c-0.3,0.9-0.5,1.9-1.7,2.1c-0.6-0.2-1.3-0.3-1.9,0
		C85.5,823.3,85,823.5,84.9,822.9z"
      />
      <path
        class="st922"
        d="M68.3,830.2c-0.1-1,0.2-1.7,1.2-2.2c1.2-0.6,2.2-1.3,3.4-2.1c2.2-1.4,3.6-3.5,4.7-5.7
		c0.3-0.5,0.5-1.1,0.9-1.5c0.7,0.1,1.3,0.7,1.9,1c-0.6,0.3-1.1,0.8-1.3,1.5c-0.2,0.7-0.8,1.2-1.2,1.8c-1.9,2.6-4.3,4.8-7.1,6.4
		c-0.7,0.4-1.3,0.8-1.7,1.5C68.8,830.8,68.7,830.4,68.3,830.2z"
      />
      <path
        class="st923"
        d="M96.5,788.5c-0.4,0.2-0.4,0.6-0.3,0.8c0.5,0.7,0,1.2-0.4,1.7c-2,2.7-4.4,5-7.1,6.9c-1.1,0.8-2.3,1.5-3.2,2.5
		c-0.4-0.4-0.9-0.7-0.9-1.4c0.4-0.9,1.2-1.4,2.1-1.8c2.9-1.5,5.1-3.9,7.1-6.4c0.6-0.8,1.2-1.6,1.3-2.6c0-0.2,0.2-0.2,0.4-0.3
		C96,788,96.3,788.1,96.5,788.5z"
      />
      <path
        class="st924"
        d="M133.1,785.9c1.3-0.1,2.5,0.2,3.7,0.8c-1.6,2.7-3.7,5-6.3,6.7c-0.6,0.4-0.9,1.2-1.8,1
		c-0.3-0.4-0.6-0.8-0.7-1.3c0-0.1,0,0,0-0.1c-0.3-1,0.4-1.4,1.2-1.6c1.5-0.1,1.9-1.2,2.1-2.4c0.4-0.2,0.6-0.5,0.6-0.9
		c0-0.5,0.1-0.9,0.1-1.3C132,786.1,132.4,785.9,133.1,785.9z"
      />
      <path
        class="st925"
        d="M69.8,843.8c0.6-2.2,1.7-4.1,3.6-5.4c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0.1,0,0.1-0.1c0.6-0.5,1.5-0.5,2.2-0.9
		c0.5-0.1,0.9-0.2,1.4,0.1c0.2,0.9,0.6,1.7,1.1,2.5c-0.4,0.5-0.5,1.2-1.1,1.5c-0.2-0.1-0.2-0.3-0.3-0.5c-0.2-0.7-0.6-1.2-1.3-1.2
		c-0.8,0-1.3,0.5-1.6,1.3c-0.3,0.8,0.2,1.5,1.1,1.5c0.3,0,0.6-0.2,0.9,0.1c0,0.2,0,0.3-0.2,0.4c-0.9,0.5-1.7,1-2.7,1.4
		c-0.8-0.2-1.7-0.3-2.5,0C70.2,844.2,69.8,844.3,69.8,843.8z"
      />
      <path
        class="st926"
        d="M69.1,831.1c-0.1-0.7,0.3-1.1,0.9-1.3c1.4-0.7,2.6-1.8,3.9-2.7c1.4-1,2.4-2.4,3.6-3.7c0.7-0.9,1.6-1.7,1.8-3
		c0.1-0.5,0.7-0.5,1.1-0.5c0.3,0.2,0.7,0.3,0.8,0.7c-0.4,1.5-1.3,2.7-2.3,3.8c-1.5,1.7-3.1,3.2-4.9,4.6c-1.1,0.9-1.9,2-3.2,2.6
		c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0-0.2,0-0.3-0.1C69.4,831.5,69.2,831.3,69.1,831.1z"
      />
      <path
        class="st927"
        d="M128.7,794.4c2-1.3,4-2.8,5.6-4.7c0.5-0.6,1-1.2,1.6-1.8c0.2-0.2,0.4-0.3,0.3-0.6c-0.1-0.5,0.3-0.5,0.6-0.6
		c0.3,0.3,0.9,0.2,1.1,0.6c0.5,0.7,0.1,1.3-0.3,1.8c-0.8,1.2-1.6,2.3-2.7,3.2c-0.9,0.7-1.6,1.7-2.6,2.2c-1,0.6-2.1,1.1-3.3,1
		C128.6,795.2,128.5,794.8,128.7,794.4z"
      />
      <path
        class="st928"
        d="M62.2,879.8c-0.4,0-0.8,0-1.2,0c-0.3-0.1-0.7-0.3-1.1-0.4c-0.7-0.2-1.4-0.4-2.1-0.7
		c-0.2-0.1-0.4-0.3-0.7-0.4c-0.1-0.2-0.1-0.4,0-0.4c0.7-0.2,1.2-0.8,1.8-1.2c0.6-0.2,1.1-0.6,1.6-0.9c0.3-0.2,0.6-0.6,1.1-0.5
		c0.3,0.2,0.5,0.4,0.9,0.4c0.7,0.1,1.3-0.1,2-0.2c1-0.1,1.2,0.2,1.2,1.2C65.3,878.5,63.8,879.3,62.2,879.8z"
      />
      <path
        class="st929"
        d="M176.6,829.4c-0.5,0.7-1.1,1.3-1.3,2c-0.8,2.6-3,4-5,5.4c-0.5,0.3-0.9,0.7-1.6,0.7c-0.4,0-0.5-0.2-0.8-0.4
		l-0.1-0.1c-0.2-0.6,0.1-1,0.6-1.3c1.6-1.1,3.2-2.3,4.6-3.8c0.8-0.8,1.1-1.8,1.4-2.8c0.1-0.4-0.1-0.6-0.4-0.7
		c-0.4-0.1-0.9-0.2-1.3-0.3c-0.6-0.3-1.4-0.5-1.5-1.4c0.2-0.3,0.4-0.1,0.7-0.1c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.2
		c0.6,0.3,1.2-0.1,1.9,0c-0.2,0.7,0.1,1.2,0.7,1.5C175.9,828.8,176.2,829.1,176.6,829.4z"
      />
      <path
        class="st930"
        d="M90.6,803.2c1.1-0.1,2.2-0.2,3.3-0.3c0.5,0.7,0.4,1.5,0.5,2.3c0.3,3.2,0.6,6.4,1.1,9.6c0,0.4,0,0.7-0.5,0.9
		c-0.3-0.3-0.6,0.2-0.9,0c-0.2,0.1-0.3,0.1-0.5,0c-0.7-3.4-0.8-6.9-1.7-10.2C91.7,804.5,91.1,803.8,90.6,803.2z"
      />
      <path
        class="st931"
        d="M174.8,827.1c-0.6,0.5-1.2,0.5-1.9,0.2c-0.1-0.3,0-0.6,0.1-0.9c1.4-3.2,2.3-6.5,3.4-9.8
		c0.2-0.5,0.1-1,0.2-1.5c0.8-0.2,1.5,0.2,2.3,0.2C177.5,819.2,176.1,823.2,174.8,827.1z"
      />
      <path
        class="st932"
        d="M219.7,892.3c-0.8,0.1-1.1-0.3-1.1-1c0-0.5,0-1.1,0-1.7c0-1-0.6-1.3-1.4-0.8c-0.9,0.5-1.9,0.8-2.6,1.7
		c-0.6-0.3-0.7-1-1-1.5c-0.9-1.2-1.9-2.3-3.2-3.2c0.1-0.9,1.1-0.7,1.5-1.2c0.5-0.2,0.9,0.1,1.3,0.3c1.8,1.1,3.4,2.3,5.4,3
		c0.4,0.1,0.4,0.4,0.4,0.7c0,0.7,0,1.4,0,2.1C219,891.4,219.1,891.9,219.7,892.3z"
      />
      <path
        class="st933"
        d="M71.8,851.3c-0.1,0-0.1,0-0.2,0c-0.5-0.9-1-1.7-1.3-2.7c-0.1-0.3-0.2-0.5-0.6-0.5c-1.1,0.2-2.3-0.2-3.4,0.3
		c0-0.8,0-1.7,0-2.5c0.6-0.5,1.3-0.3,1.9-0.3c1.7,0,3.3-0.2,5-0.3c0.1,0,0.2,0,0.4,0.1c0.9,1.1,0.4,2.7-1,3.3
		c-1.4,0.1-1.5,0.2-0.9,1.5C72,850.5,72.5,850.9,71.8,851.3z"
      />
      <path
        class="st934"
        d="M98.7,799.6c-0.4-1.1,0.3-1.4,1.1-2c2.2-1.5,4.6-2.9,6.2-5.2c0.7-1,1.8-1.5,2.1-2.8l0.1-0.1
		c0-1.1,0.6-1.3,1.6-1.1c0.1,0.1,0.1,0.2,0.2,0.2c0.5,0.4,0.5,0.8,0,1.4c-1.2,1.3-2.1,2.7-3.3,3.9c-1.8,1.8-3.7,3.3-5.7,4.8
		C100.3,799.3,99.6,799.7,98.7,799.6z"
      />
      <path
        class="st935"
        d="M129.1,791.7c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0.1-0.8,0.1-0.3,0.6c0.2,0.2-0.1,0.4-0.3,0.5
		c-0.2,0-0.4,0.1-0.5,0.3c-0.2-0.1-0.4-0.2-0.3-0.5c-0.4-0.3-0.9-0.5-1.4-0.3c-0.7,0.2-0.7-0.2-0.6-0.6c0.7-3.3,4.5-6.3,7.9-6.2
		c-1.1,0.2-0.6,1.1-0.8,1.7c-0.1,0.3-0.1,0.7-0.1,1.1c-0.1,0.5-0.4,0.6-0.8,0.5c-0.1,0-0.3,0-0.4-0.1c-1.1-0.6-1.7-0.6-2.3,0.2
		s-0.5,1.3,0.4,2.2C129.1,791.6,129.1,791.7,129.1,791.7z"
      />
      <path
        class="st936"
        d="M50.9,850.8c1.4,0.3,2.4-0.6,3.3-1.3c1.8-1.4,3.4-2.9,5-4.5c1.1-1.1,2.4-2.2,2.9-3.8
		c0.1-0.2,0.4-0.4,0.6-0.6c0.3,0,0.3,0.3,0.5,0.4c0.6,1.2-0.6,1.8-1.1,2.5c-1.5,2.2-3.7,3.7-5.6,5.4c-1.5,1.3-3,2.5-4.6,3.5
		C51.6,852,51.1,851.5,50.9,850.8z"
      />
      <path
        class="st937"
        d="M172.6,811.1c0.4-0.2,0.4-0.2,0.8,0.5c0.6-1,1.6-1.4,2.5-2c2.9-1.9,5.2-4.4,7.2-7.2c0.4-0.5,0.3-1.2,0.8-1.7
		c0.2-0.2-0.7-0.4-0.6-0.9c0.5,0.2,1.1,0.3,1.4,0.8c0.4,1.3-0.4,2.2-1.1,3.1c-1.6,2.1-3.5,3.9-5.6,5.6c-1,0.8-2.1,1.7-3.3,2.2
		c-0.5,0.2-0.8,1.1-1.6,0.8l-0.1-0.1C172.8,811.8,172.4,811.6,172.6,811.1z"
      />
      <path
        class="st938"
        d="M98.7,799.6c2.3-0.6,3.9-2.2,5.5-3.6c1.9-1.6,3.8-3.3,5.1-5.6c0.3-0.5,1.2-0.7,0.6-1.6
		c0.4,0,0.7,0.2,0.8,0.6c0.2,0.6,0.2,1.1-0.5,1.4c-0.1,0-0.2,0.2-0.2,0.2c-0.7,1.6-2.6,3.7-4.1,4.9s-3,2.4-4.6,3.5
		c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.8-0.3,1.2-1.1,0.8c-0.3-0.1-0.3,0.2-0.4,0.3c-0.7-0.1-1.1-0.5-1-1.3
		C98.5,799.7,98.6,799.7,98.7,799.6z"
      />
      <path
        class="st939"
        d="M182,846c0-0.2,0.1-0.4,0.3-0.4c1,0,1.6-0.8,2.2-1.5c0.6-0.3,1.1-0.8,1.9-0.4c-0.6,1-0.4,1.2,1,1.3
		c0.4,0.3,0.3,0.8,0.5,1.2c0.1,0.3,0.3,0.4,0.6,0.2c0.6,0.2,0.7,0.8,0.8,1.3c-0.1,1-0.8,0.8-1.5,0.9
		C185.5,848.5,183.6,847.8,182,846z"
      />
      <path
        class="st940"
        d="M184.3,839.2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.2-0.7-0.3-0.6-0.8c0-0.1,0-0.3,0.1-0.4
		c1.9-0.8,2.4-2.8,3.6-4.2c0.6-0.8,2-1.2,2.9-0.8c0.4,0.2,0.9,0.5,1.3,0.8c-0.8,0.5-1.3,1.3-1.8,2.1c-0.7,1.2-1.9,1.6-3,2.3
		c-0.5,0.3-1,0.4-1.4,0.8c-0.2,0.2-0.9-0.2-0.5,0.6C184.7,839,184.6,839.2,184.3,839.2z"
      />
      <path
        class="st941"
        d="M212,884.6c-0.2,0.8-1.1,0.6-1.5,1c-0.1,0.2-0.3,0.3-0.5,0.2c-1.6-1.2-3.3-2.1-5.1-3c-0.4-0.2-0.9-0.5-1.1-1
		c-0.5-0.8-1.3-1-2.1-1.4c-0.7-0.4-0.8-0.4-0.8-1.3c0.8,0.3,1.4,1.4,2.5,0.7c2.6,1.4,5.2,2.9,7.9,4.1
		C211.8,883.9,212,884.2,212,884.6z"
      />
      <path
        class="st942"
        d="M56,877.1c-0.1-0.5,0.3-0.6,0.6-0.8c2.8-1.8,5.3-3.9,6.7-7.1c0.1-0.2,0.2-0.5,0.4-0.4
		c1.5,0.9,1.7-0.8,2.4-1.3c1.5-1,2.3-2.9,3.8-3.9c0.6-0.4,0-0.8-0.1-1.2c0.8-0.5,1.3-0.4,2,0.6c-0.5,0.7-1.1,1.4-1.8,2
		c-0.7,0.7-1.4,1.5-2.2,2.2c-1.5,1.7-3.6,2.7-4.8,4.7c-0.6,1-1.4,1.9-2.3,2.7c-1.3,1.2-2.7,2.2-4.3,2.9
		C56.3,877.4,56.1,877.2,56,877.1z"
      />
      <path
        class="st943"
        d="M64.5,816c-1.1-0.6-0.8-1.5-0.5-2.4c0.2-0.9,1-1.3,1.9-1.4c1.7-0.2,3-1.6,3-3.4c0-0.3,0-0.6,0.4-0.6
		c0.9,0,1.8-0.4,2.6,0.4c-0.9,0.7-1.1,1.9-1.8,2.7c-1.2,1.5-2.5,2.8-4.3,3.7C65.2,815.4,64.9,815.8,64.5,816z"
      />
      <path
        class="st944"
        d="M69.8,831.7c1.8-0.8,3.1-2.2,4.6-3.4c1.9-1.6,3.7-3.2,5.2-5.2c0.6-0.8,0.9-1.6,1.3-2.5
		c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0.2,0.3,0.3,0.4,0.5c0.5,0.9-0.3,1.7-0.3,2.6c-0.2,0.5-0.5,1-0.7,1.5c-0.9,0.3-1.7,0.8-2.3,1.5
		c-0.7,0.8-1.5,1.5-2.4,2.1c-1.3,1.1-2.8,2-4.1,3.1c-0.4,0.3-0.8,0.6-1.4,0.6C70.1,832.2,69.9,832,69.8,831.7z"
      />
      <path
        class="st945"
        d="M62.9,868.5c-0.9,3-3.1,4.9-5.6,6.5c-0.5,0.3-1.1,0.4-1.7,0.5c-0.5-1.6-0.3-1.9,1.3-2.3
		c1-0.2,1.8-0.8,2.3-1.8c0.1-0.2,0.2-0.3,0.3-0.4c0.9-0.2,1.1-1,1.3-1.7C61.3,867.9,61.4,867.8,62.9,868.5z"
      />
      <path
        class="st946"
        d="M191.6,855.2c-0.2,0-0.3,0-0.4-0.2c-0.1-0.3-0.1-0.5,0.2-0.6c1.2-0.8,2.2-1.8,3.3-2.8
		c0.2-0.2,0.4-0.5,0.8-0.3c0.1,0.1,0.1,0.2,0.1,0.4c-0.2,1-0.1,1.1,0.9,1c0.4-0.1,0.9-0.2,1.2-0.4c0.4-0.3,0.9-0.4,1.4-0.1
		c-0.4,1.5-1.2,2.5-2.6,3.2C194.8,855.7,193.2,855.8,191.6,855.2z"
      />
      <path
        class="st947"
        d="M142.2,783.4c0.1,0,0.1,0,0.2,0c-0.7-0.7,0.1-0.9,0.4-1.2c1.7-1.7,3.4-3.3,4.3-5.6c0.3-0.7,0.4-1.7,1.5-1.5
		c0.3,0.5,0.9,0.3,1.3,0.6c-0.9,1-1.2,2.3-2,3.3c-1.5,1.8-2.9,3.6-4.9,5C142.6,784,142.2,783.9,142.2,783.4z"
      />
      <path
        class="st948"
        d="M129.1,795.4c2-0.3,3.6-1.3,5-2.7c1.1-1.1,2.1-2.1,3-3.3c0.5-0.6,0.7-1.3,0.7-2c0.1,0,0.2,0,0.3,0.1
		c0.4,0,0.6,0.2,0.6,0.6c0.1,1.3-0.6,2.2-1.3,3.2c-1.7,2.2-3.7,4.1-6.2,5.5c-0.8,0.4-1.4,0.3-2-0.2
		C129,796.1,128.8,795.8,129.1,795.4z"
      />
      <path
        class="st949"
        d="M95.6,787.9c-1.7,4.3-4.9,7.3-8.8,9.5c-0.8,0.5-1.3,1.1-2.1,1.6c-0.6-0.3-0.7-0.9-0.8-1.5H84
		c0.1-0.2,0.2-0.4,0.5-0.4c2.2-0.5,3.9-2,5.6-3.4c1.3-1,2.3-2.4,3.2-3.8c0.4-0.6,1.2-1.2,0.9-2.1c-0.1-0.2,0.4-0.1,0.6-0.2
		c0.1,0,0.3,0,0.4,0C95.3,787.6,95.5,787.7,95.6,787.9z"
      />
      <path
        class="st950"
        d="M148.1,799.4c-0.3,0.1-0.6,0.1-0.8,0.2c-0.5-0.3-0.3-0.8-0.4-1.2c-0.8-2.4-1.4-4.9-1.9-7.4
		c-0.1-0.3-0.1-0.7,0.2-1c0.6,0.1,1.3-0.3,1.8,0.4c0.5,2.8,0.7,5.6,1.5,8.3C148.7,799.2,148.5,799.4,148.1,799.4z"
      />
      <path
        class="st951"
        d="M186.4,843.8c-0.7-0.1-1.2,0.7-1.9,0.4c0.2-1.1,0.8-1.8,1.9-2.2c1.9-0.7,3.3-2.1,4.5-3.7
		c0.9-1.2,1.9-2.4,2.4-3.9c0.3,0.3,0.7,0.6,0.9,1c-0.8,0.7-1.3,1.7-1.8,2.6c-1,1.7-2.4,3-3.9,4.1C187.8,842.7,187,843.2,186.4,843.8
		z"
      />
      <path
        class="st952"
        d="M199,852.1c-1.1,0-1.9,0.9-3,0.8c-0.4,0-1,0.2-1.2-0.3c-0.2-0.4,0.2-0.7,0.4-1.1c0-0.1,0-0.2,0.1-0.3
		c0.1-0.7,0.6-1.1,1-1.6c0.4-0.6,0.9-1,1.1-1.7c0.1-0.3,0.3-0.5,0.6-0.4C199,848.9,199.6,850.3,199,852.1z"
      />
      <path
        class="st953"
        d="M69.1,818.6c-1.6-0.1-3.1-0.6-4.2-1.9c-0.2-0.4,0.1-0.5,0.3-0.6c3-1.1,4.7-3.5,6.2-6.1
		c0.2-0.4,0.3-0.9,0.9-0.8c0.2,0.1,0.3,0.2,0.4,0.4c0,2-1.3,3.4-2.5,4.8c-0.7,0.8-1.6,1.5-2.2,2.3c-0.6,0.7-0.6,0.8,0.3,1.1
		c0.3,0.1,0.7,0.1,0.9,0.4C69.4,818.5,69.3,818.6,69.1,818.6z"
      />
      <path
        class="st954"
        d="M95,815.6c0.3-0.1,0.1-0.5,0.4-0.6c0.2-0.2,0.4-0.1,0.6,0c1.6,0.7,3.1,1.5,4.1,3c0.7,1,0.7,1.2-0.2,2.1
		c-0.5,0.3-0.8,0.8-1.4,0.8c-1.2-1.2-2.1-2.5-2-4.3c-0.3,0-0.8,0-1.2,0.1c-0.8,0-1.7,0.2-2.4-0.2c-0.2,0-0.4,0-0.8-0.1
		c0.3-0.1,0.4-0.1,0.6-0.2C93.5,816.3,94.3,816.1,95,815.6z"
      />
      <path
        class="st955"
        d="M194.9,858.9c-0.2,0.2-0.1,0.3,0.1,0.5c0.7,0.8,0.1,0.9-0.5,1c-0.5,0-1,0-1.4,0.3c-0.3,0.2-0.6,0.1-0.8-0.2
		c-0.6-0.6-1.3-1-1.8-1.7c-0.2-0.3-0.5-0.6-0.9-0.5c-0.4,0-0.7-0.1-0.7-0.4c-0.1-0.5,0.5-0.1,0.6-0.4c0.1-0.2,0.5-0.2,0.7-0.4
		C191.9,857.2,193.6,857.7,194.9,858.9z"
      />
      <path
        class="st956"
        d="M156.4,805.2c3.8-0.2,7.6,0,11.4,0c0.6,0,1.2,0,1.8-0.1c0.7-0.2,1.1,0.2,1.4,0.8c0.1,0.1,0.1,0.2,0.1,0.3
		c-0.1,0.3-0.1,0.6-0.6,0.5c-3-0.2-6-0.1-9-0.3c-1.1-0.1-2.2-0.6-3.4-0.4c-0.3,0-0.6,0-0.9,0C156.7,805.8,156.4,805.7,156.4,805.2z"
      />
      <path
        class="st957"
        d="M73.3,810.2c1.4,2.1,1.4,4.1-0.2,6.1c-0.2,0.3-0.3,0.6-0.2,1c-0.6-0.1-0.8-0.5-1.1-1c0.1-0.7-0.8-0.5-0.9-1
		c-0.1-0.3,0-0.6,0.1-0.9c0.7-1.3,1.4-2.6,1.9-4C72.9,810.3,73.1,810.2,73.3,810.2z"
      />
      <path
        class="st958"
        d="M72.4,809.2c-0.2,0.2-0.5,0.3-0.5,0.5c-0.4,1.4-1.5,2.4-2.3,3.5c-1.2,1.5-2.5,2.7-4.4,3.1
		c-0.1,0-0.1,0.2-0.2,0.3c-0.3-0.1-0.3-0.4-0.4-0.6c0.1-0.5,0.4-0.8,0.8-1c2.5-1.1,4.2-3,5.4-5.5c0.2-0.4,0.4-0.9,1-0.8
		C72,808.9,72.2,809.1,72.4,809.2z"
      />
      <path
        class="st959"
        d="M184.1,840.4c0.1-0.3,0.2-0.7,0.3-1c0.3-0.4,0.6-0.7,1.2-0.8c2.6-0.5,4.6-1.9,5.7-4.4c0.2-0.3,0.4-0.9,1-0.7
		c0.1,0.1,0.1,0.2,0.2,0.4c0,1-0.7,1.7-1.2,2.4c-1.4,2-3.3,3.2-5.6,4C185.1,840.5,184.6,840.1,184.1,840.4z"
      />
      <path
        class="st960"
        d="M194.7,844.8c-0.6,1-1.6,0.8-2.5,0.8c-0.3-0.5-0.5-1-0.2-1.6c0.2-1.3,1.1-2,2.2-2.6c0.5-0.2,0.8-0.7,1.3-1.1
		c0.3,0.3,0,0.9,0.3,1.1C195.7,842.7,195,843.7,194.7,844.8z"
      />
      <path
        class="st961"
        d="M192.2,833.5c-0.8,0.3-1,1.2-1.4,1.8c-1.1,1.9-2.9,2.8-5,3.3c-0.5,0.1-1.1,0.3-1.5,0.6c0-0.1,0-0.2,0-0.2
		c-0.1-1,0.8-1.3,1.4-1.6c1.6-0.7,3.2-1.5,4.1-3.2c0.3-0.6,0.7-1.2,1.5-1.2C191.6,833.3,192,833.2,192.2,833.5z"
      />
      <path
        class="st962"
        d="M70.3,832.1c1.6-0.4,2.6-1.8,3.9-2.5c0.6-0.3,1-0.8,1.5-1.2c0.2,1-0.6,1.4-1,2.1c-1,0.7-1.3,1.5-1.2,2.8
		c0.1,1.2-0.2,2.5-0.3,3.7c0,0.3-0.1,0.7,0,1c-0.1-0.1-0.3-0.3-0.4-0.4c-0.3-0.4-0.3-0.9-0.3-1.5c0-0.8,0.2-1.6-0.3-2.3
		c-0.5-0.3-1-0.7-1.5-1C70.6,832.5,70.2,832.5,70.3,832.1z"
      />
      <path
        class="st963"
        d="M189.1,852.7c-0.1-0.5,0.2-0.8,0.6-0.9c2.5-0.8,4-2.8,5.3-4.8c0.4-0.7,0.7-0.9,1.4-0.7
		c0.3,0,0.6,0.1,0.9,0.4c-1.2,1-1.9,2.5-3,3.5s-2.2,2-3.4,2.8c-0.3,0.2-0.5,0.5-0.9,0.8C189.6,853.5,189.6,852.9,189.1,852.7z"
      />
      <path
        class="st964"
        d="M56.4,877.5c0.3-0.8,1.3-0.9,1.9-1.3c1.8-1.3,3.4-2.8,4.6-4.7s3.2-2.9,4.8-4.5c0.2,1-0.6,1.4-1.1,1.8
		c-1.2,1-2.2,2.2-3,3.5c-1.1,1.8-2.5,3.4-4.4,4.5c-0.5,0.7-1.3,1-1.9,1.5C56.8,878.2,56.6,877.8,56.4,877.5z"
      />
      <path
        class="st965"
        d="M127.2,792.9c0.1,0.2,0.2,0.3,0.5,0.3c0.5,0.2,0.7,0.6,0.4,1.1c-1,0.1-2,0-2.9,0.1c-2.8,0.2-5.7,0.5-8.5,0.5
		c-1,0-2-0.2-2.9-0.6c-0.1-0.1-0.1-0.2-0.1-0.3c3.2,0.1,6.3-0.3,9.5-0.4C124.5,793.5,125.8,792.9,127.2,792.9z"
      />
      <path
        class="st966"
        d="M143,784c-0.2-0.5,0.3-0.5,0.5-0.8c0.6-0.7,1.5-1.1,2-1.8c0.8-1.2,1.9-2.3,2.8-3.5c0.3-0.4,0.5-1,0.7-1.5
		c0.2-0.4,0.4-0.8,0.9-0.8c0.1,0.1,0.1,0.2,0.2,0.2c0.3,1.3-0.4,2.5-1.2,3.3c-1.3,1.5-2.4,3.3-4.1,4.5c-0.5,0.4-1,0.7-1.7,0.6
		C143.1,784.2,143,784.1,143,784z"
      />
      <path
        class="st967"
        d="M151.4,777.2c0,1.2-0.4,2.3-1.2,3.1c-1.5,1.5-2.7,3.3-4.5,4.5c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.2-0.1-0.3-0.2-0.4-0.4c0.3-0.7,0.8-1.2,1.3-1.7c1.9-1.9,3.6-3.9,4.7-6.4c0-0.1,0.2-0.1,0.3-0.1
		C151.2,776.8,151.4,777,151.4,777.2z"
      />
      <path
        class="st968"
        d="M151,776.7c-0.9,2.3-2.4,4.2-4.1,5.9c-0.7,0.7-1.2,1.7-2.1,2.2c-0.4,0.1-0.9-0.3-1.2,0.2
		c-0.3-0.2-0.6-0.4-0.4-0.8c1.5-0.2,2.6-1.2,3.4-2.3c1.1-1.5,2.6-2.5,3.2-4.4c0.2-0.5,0.3-1.1,0.4-1.6
		C150.4,776.1,150.8,776.3,151,776.7z"
      />
      <path
        class="st969"
        d="M198,847.5c-0.6,0.9-1.3,1.8-1.9,2.8c-0.2,0.4-0.5,0.7-0.8,1c-1.4,1.3-2.8,2.5-4.2,3.8
		c-0.4-0.1-0.6-0.3-0.8-0.7c-0.2-0.7,0.4-0.9,0.8-1.2c1.6-1.1,3.2-2.3,4.4-3.9c0.4-0.5,0.7-1,1.1-1.5c0.2-0.4,0.5-0.9,1.1-0.8
		C197.8,847.2,198,847.3,198,847.5z"
      />
      <path
        class="st970"
        d="M197.6,847.1c-1.1,0.6-1.4,1.9-2.1,2.7c-1.3,1.4-2.7,2.6-4.2,3.6c-0.4,0.2-0.9,0.4-1,1
		c-0.2-0.1-0.4-0.4-0.4-0.6c0-0.4,0.2-0.6,0.5-0.8c2.3-1.5,4.4-3.3,5.9-5.8c0.2-0.4,0.6-0.5,1-0.5
		C197.4,846.8,197.5,846.9,197.6,847.1z"
      />
      <path
        class="st971"
        d="M73.3,810.2c-0.5,1.5-1.2,2.9-2,4.2c-0.2,0.3-0.3,0.6-0.3,1c-0.2,0.3-0.6,0.3-0.8,0.5
		c-0.6,0.5-1.1,0.9-0.9,1.8c0.1,0.2,0.2,0.3,0,0.5c-0.8-0.1-1.5-0.3-2.2-0.9c2.2-2.4,4.8-4.5,5.7-7.8C73,809.8,73.1,810,73.3,810.2z
		"
      />
      <path
        class="st972"
        d="M69.3,818.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-1.4,0.8-2.3,2.1-2c0.3,0.6,0.8,1.1,1.1,1.7
		c0.2,0.3,0.4,0.7,0.1,1c-0.9,0.4-1.6,1.1-2.3,1.7c-0.2-0.7-0.5-1.3-1-1.8C69.2,818.5,69.2,818.4,69.3,818.3z"
      />
      <path
        class="st973"
        d="M92.2,815.4c-0.2,0-0.4,0.4-0.5-0.1c-0.2-2.5-0.7-4.9-0.8-7.3c-0.1-1.5-0.3-3-0.8-4.4
		c0.3-0.1,0.4,0.1,0.6,0.3c1.1,1.9,1.2,4,1.5,6.1c0.2,1.1,0.2,2.2,0.4,3.3C92.8,814,92.5,814.7,92.2,815.4z"
      />
      <path
        class="st974"
        d="M156.4,805.2c0.1,0.2,0.3,0.4,0.6,0.4c1.9,1.2,4,1.3,6.2,1.3c2.4,0,4.8-0.1,7.2,0.1c0.5-0.2,0.4-0.6,0.5-1
		c0-0.1,0-0.2,0-0.2c0.1-0.2,0-0.5,0.3-0.7c0,1.3-0.1,2.7,0.2,4c-0.2,0.1-0.3,0-0.5-0.1c-0.1-0.2-0.1-0.4,0.1-0.6
		c0.1-0.7-0.4-0.7-0.9-0.7c-3,0-6,0-9,0c-2.2,0.1-3.9-0.6-5.2-2.4C156.1,805.3,156.2,805.3,156.4,805.2z"
      />
      <path
        class="st975"
        d="M183.5,837.7c0.1,0.3,0.6,0.4,0.6,0.8c-1.5,0.6-2.9-0.3-4.3-0.5c-0.5-0.1-0.9-0.3-1.4-0.5
		c-0.3-0.1-0.8-0.2-0.7-0.7s0.5-0.7,0.9-0.7c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c0.5,0.7,1.4,0.5,2.1,0.9
		C182.1,837.4,182.8,837.5,183.5,837.7z"
      />
      <path
        class="st976"
        d="M80.6,825c-0.1-0.6,0.3-1,0.6-1.5c0.7-0.5,1.4-0.4,2.1,0c0.4-0.5,1-0.6,1.7-0.6c0.3,0.5,0.6,0.1,0.9,0
		c0.7-0.2,1.4-0.3,2,0.3c0.3,0,0.5,0.2,0.6,0.5c0,0.1,0,0.2-0.1,0.3c-0.8,0.4-1.7,0.3-2.6,0.3c-1.3,0-2.5,0.4-3.8,0.7
		c-0.4,0.1-0.8,0.3-1.3,0.2C80.7,825.1,80.6,825,80.6,825z"
      />
      <path
        class="st977"
        d="M90.6,803.8c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0.2,0,0.4,0,0.6,0c1.1,0.3,1.4,1.3,1.6,2.2
		c0.6,2.7,1.3,5.4,1.3,8.2c0,0.7,0.8,1.3,0.2,2c-0.9-0.1-0.8-0.8-0.9-1.3c-0.4-2.2-0.6-4.5-0.9-6.7
		C91.7,806.2,91.5,804.8,90.6,803.8z"
      />
      <path
        class="st978"
        d="M90.6,803.8c0.8,0.5,1.1,1.2,1.3,2.1c0.6,2.8,0.6,5.8,1.3,8.6c0.1,0.4,0.1,0.9,0.6,1.2c0.1,0,0.3,0,0.4,0.1
		c-0.7,0.4-1.3,0.2-1.9-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0.7-2.3-0.2-4.5-0.3-6.7C91.7,806.7,91.1,805.2,90.6,803.8z"
      />
      <path
        class="st979"
        d="M138.7,787.9c0-0.3-0.4-0.3-0.5-0.6c0.1-0.5-0.4-1-0.1-1.5c0.7-0.2,0.8-0.9,1.2-1.3c1.1-1.3,1.4-1.5,2.5-1
		c0.2,0.3,0.4,0.6,0.1,0.9c-1,0.9-2,1.8-2.5,3.1c-0.1,0.2-0.3,0.2-0.4,0.3C138.9,788,138.8,788,138.7,787.9z"
      />
      <path
        class="st980"
        d="M180.3,840.9c0.7,0,1.2,0.5,1.9,0.6c0.7,1.5,0.6,1.9-1.2,3.3C180.3,843.6,179.8,842.3,180.3,840.9z"
      />
      <path
        class="st981"
        d="M69.8,843.8c0.4,0.5,0.7,0,1,0c0.8-0.2,1.6-0.2,2.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.3-0.4,0.4-0.7,0.4
		c-1.6,0.2-3.3,0.4-4.9,0.5c-0.5,0-1.1,0.1-1.3-0.6c0.3,0,0.7,0,1,0C68.2,844.7,69.1,844.5,69.8,843.8z"
      />
      <path
        class="st982"
        d="M188.7,915.2c1-0.6,2.1-1,3.2-1.4c1.4-0.5,2.6-1.4,3.9-2.1c0.3-0.2,0.6-0.4,1.1-0.4
		c-1.8,1.1-3.6,2.4-5.7,3.1c-2,0.7-3.6,2-5.2,3.4c-0.1,0.1-0.2,0.2-0.3,0.2c0.2-0.8,0.8-1.3,1.5-1.7
		C187.7,915.9,188.2,915.6,188.7,915.2z"
      />
      <path
        class="st983"
        d="M66.2,844.6c0.3,0.3,0.6,0.4,1,0.4c1.8-0.2,3.6-0.3,5.5-0.5c0.1,0,0.3-0.2,0.4-0.3c0.2-0.1,0.4,0.1,0.6,0.2
		c0.2,0.3,0.4,0.7,0.1,1c-0.1,0-0.1,0-0.2,0c-1.2-0.2-2.4,0-3.6,0.1c-1.2,0-2.4,0.2-3.6,0c-0.2-0.3-0.4-0.5-0.4-0.9
		C66,844.6,66.1,844.6,66.2,844.6z"
      />
      <path
        class="st984"
        d="M83.3,823.5c-0.7,0-1.4,0-2.1,0c-0.3-0.5,0-1,0.2-1.4s0.2-0.8,0.2-1.2C82.4,821.7,82.9,822.5,83.3,823.5z"
      />
      <path
        class="st985"
        d="M183.7,860c0.9-0.1,1.5-0.7,1.8-1.5c0.1-0.4,0.4-0.6,0.7-0.8c0.2,0,0.4-0.1,0.7-0.1c-0.2,0.3-0.4,0.6-0.4,1
		c-0.1,0.7-0.7,1-1.2,1.4c-0.6,0.8-1.4,0.8-2.3,0.7C182.7,859.9,183.2,859.9,183.7,860z"
      />
      <path
        class="st986"
        d="M72.4,818.8c-0.1-0.7-0.4-1.3-0.8-1.8c-0.2-0.3-0.4-0.5-0.4-0.9c0.3-0.3,0.5-0.1,0.7,0.2
		c0.3,0.3,0.7,0.7,1,1.1c0.1,0.1,0.2,0.1,0.3,0.2C73.6,818.3,73.4,818.8,72.4,818.8z"
      />
      <path
        class="st987"
        d="M148.1,799.4c0.3-0.1,0.4-0.3,0.4-0.6c0.3,0.2,0.7,0.3,1,0.5c0.1,0.2,0.1,0.3,0.2,0.5
		c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.3s-0.5,0.2-0.8,0.2c-0.8,0-1.5,0.2-2.2-0.2c-0.1-0.4,0.2-0.4,0.4-0.5
		c0.4,0,0.8-0.1,1.2,0C147.8,799.9,147.9,799.7,148.1,799.4z"
      />
      <path
        class="st988"
        d="M72.2,833.8c1.1,0.6,0.6,1.6,0.6,2.5c0,0.4,0,0.9,0.1,1.3c-0.6-0.5-0.6-1.1-0.6-1.8
		C72.2,835.1,72.2,834.4,72.2,833.8z"
      />
      <path
        class="st989"
        d="M132.8,947.9c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0.2-0.4,0.6-0.8,0.4c-0.3-0.2-0.2-0.6-0.2-0.9
		c0-0.2,0.2-0.4,0.1-0.7c0.1,0,0.1,0,0.1,0c0.5-0.3,0.8,0.1,1.1,0.4C133.1,947.5,133.2,947.7,132.8,947.9z"
      />
      <path
        class="st969"
        d="M191.6,855.2c1.6,0.3,3.2,0.1,4.8,0C194.8,856,193.2,856.2,191.6,855.2z"
      />
      <path
        class="st990"
        d="M113.7,794c0,0.1,0,0.2,0,0.2c0.3,0.2,0.4,0.4,0.4,0.7c0.1,0.8-0.1,1.6-0.3,2.3c-0.1,0.3-0.2,0.5-0.6,0.6
		c0-0.5,0-0.9,0-1.3c0.4-0.9,0.1-1.8,0.2-2.8C113.6,793.8,113.6,793.9,113.7,794z"
      />
      <path
        class="st991"
        d="M152.9,937.7c1.1-1,2-2.4,3.7-2.5C155.1,935.7,154.2,937,152.9,937.7z"
      />
      <path
        class="st964"
        d="M57.9,878.8c0.8-0.1,1.4,0.3,2.1,0.7C59.2,879.4,58.5,879.2,57.9,878.8z"
      />
      <path
        class="st992"
        d="M178.9,922.5c0.3-0.6,0.8-0.8,1.4-0.9C180,922.2,179.4,922.3,178.9,922.5z"
      />
      <path
        class="st922"
        d="M70.3,832.1c0.1,0.2,0.3,0.4,0.4,0.6c-0.6-0.1-0.9-0.6-1.2-1c0.1,0,0.2,0,0.2,0
		C69.9,831.8,70.1,832,70.3,832.1z"
      />
      <path
        class="st982"
        d="M132.8,947.9c0.1-0.7-0.3-1-0.9-1c0-0.4,0-0.8-0.1-1.2c0.5-0.1,0.9-0.2,1.3-0.6c0.8-0.8,1.7-1.4,2.8-1.8
		c0.8-0.3,1.6-0.7,2.2-1.5c0.5-0.7,1.3-1,2-1.2c1.3-0.5,2.2-1.5,3.4-2.1c3.3-1.6,6.2-3.9,9.6-5.6c1.4-0.7,2.7-1.6,4-2.3
		c1-0.5,1.7-1.3,2.8-1.6c0.5-0.1,1-0.5,1.3-1c0.2-0.2,0.3-0.6,0.7-0.4c0.2,0.2,0.1,0.5,0.1,0.7c0-0.2,0-0.3,0-0.5
		c0.6-0.9,1.6-1.2,2.6-1.6c0.4-0.2,0.9-0.4,1.2-0.7c0.4-0.5,1-0.8,1.5-1.1c1.7-0.9,3.4-2,5.2-2.9c1-0.5,1.9-1.2,2.7-1.9
		c0.6-0.5,1.2-0.7,1.9-1.1c1.4-0.8,2.6-1.8,4-2.6c1.8-1.1,3.6-2.3,5.5-3.4c3.1-1.8,6.3-3.3,9.3-5.2c1.8-1.1,3.6-2.1,5.4-3.1
		c2.7-1.5,5.2-3.3,8.1-4.6c0.6-0.3,1.1-0.8,1.7-1.2c1.1-0.7,2.2-1.5,3.6-1.9c0.8-0.2,1.3-1,2.1-1.3c0.7-0.3,1.3-0.5,2.1-0.5
		c1.3,1,1.3,1.6,0,2.2c-2,1-3.8,2.2-5.7,3.3c-7,4.2-14.1,8.1-21,12.3c-1.3,0.8-2.6,1.5-3.8,2.3c-2.7,1.6-5.4,3.1-8.1,4.7
		c-4.2,2.5-8.5,4.9-12.6,7.5c-4,2.5-8.2,4.8-12.3,7.3c-3.3,2.1-6.8,3.9-10.1,5.9c-4,2.5-8.2,4.6-12,7.3
		C133.2,947.8,133,947.9,132.8,947.9z"
      />
      <path
        class="st993"
        d="M113.3,914.6c2.6-1.5,5.1-3.2,7.7-4.6c0.5-0.6,1.1-0.5,1.7-0.4c0.3,0.1,0.6,0.3,0.9,0.3c0.2,0,0.5,0.3,0.7,0
		s0-0.6-0.2-0.7c-0.6-0.6-0.3-1,0.2-1.3c0.3-0.3,0.6-0.5,1.1-0.4c0.8,0.4,1.4,1.1,2.3,1.3c0.4,1.8,2,1.9,3.3,2.4
		c0.4,0.2,0.9,0.2,1.3,0.2c2.1,0,4-0.8,5.6-2.1c0.2-0.2,0.4-0.4,0.7-0.5c1.5,0.3,2.6,1.3,3.8,1.9c-1-0.7-2.4-1.1-3.5-2.1
		c-0.1-0.6,0.5-0.9,0.7-1.4c0.1-0.3,0.3-0.5,0.6-0.7c1.3,0,2.3,0.9,3.3,1.6c1,0.8,2.1,1.3,3.2,1.8c0.9,0.4,1.8,1,2.7,1.5
		c0.7,0.5,1.1,1.2,1.4,2c0.4,0.8-0.5,0.6-0.8,0.9c1.5,0.8,2.9,1.6,4.3,2.4c0.4,0.2,1,0.4,1.2,0.9c0,0.2-0.1,0.3-0.3,0.3
		c-1.2-0.3-2.1-1.1-3.2-1.7c-0.6-0.3-1.2-0.7-1.9-1c-1.1-0.5-2.1-1.2-3.2-1.7c-0.9-0.4-1.7-0.7-2.8-0.3c-2.2,0.7-4.6,0.6-6.9,0.8
		c-0.9,0.1-1.8,0.5-3,0.3c0.6,0.5,1.1,0.7,1.4,1c0.2,0.3,0.9,0.3,0.4,0.9c-0.1,0-0.2,0-0.3,0c-1.3-0.5-2.4-1.2-3.6-1.9
		c-0.1-0.7-0.6-1-1.2-0.7c-0.6,0.2-1.1-0.1-1.7-0.2c-0.7-0.2-1.4-0.3-2-0.8c-0.4-0.4-1-0.4-1.6-0.3c-2.2,0.3-4.4,0.4-6.5,1.3
		c-2.8,1.2-5.2,3.8-5.1,6.8c0,0.8,0.5,1.2,0.7,1.8c0,0.4-0.2,0.8,0.5,0.8c0.4,0,0.9,0.1,1-0.6c0-0.2,0-0.3,0.1-0.5
		c0.2-0.1,0.4,0,0.5,0.2c0.3,0.8,1,1.3,1.5,2c0.4,0.6,0.4,1-0.4,1.1c-2.2,0.3-4.2-0.2-5.7-1.9c-0.1-0.1-0.2-0.2-0.2-0.3
		c-0.2-0.5-0.3-1,0-1.4s0.8,0.1,1.1,0.2c0.1,0,0.2,0.2,0.3,0.2c-0.4-0.5-1.3-0.4-1.7-1.1c-1.4-1-1.4-1-0.6-2.7
		c0.5-1.2,1.1-2.2,2-3.1C113.4,915,113.4,914.8,113.3,914.6z"
      />
      <path
        class="st994"
        d="M131.8,944.6c-0.1-1.2-0.1-2.5-0.1-3.7c0-0.9,0.3-1.5,1.1-2c3.4-2,6.7-4,10.1-6c1-0.6,1.9-1.2,2.9-1.8
		c0.1-0.2,0.3-0.4,0.5-0.5c1.7-1,3.3-2.1,5-3c3.4-1.8,6.6-4,10-5.9c0.5-0.1,0.8,0.1,0.8,0.6c0,1.4,0.1,2.9,0.1,4.3
		c-0.7,0.5-1.5,0.7-2.2,1c-1.7,0.5-3.1,1.5-4.6,2.3c-2.7,1.4-4.8,3.6-7.8,4.5c-1.4,0.5-2.5,1.6-3.8,2.4c-2.6,1.7-5.4,3-7.7,5.1
		c-0.9,0.8-2,1.5-2.9,2.4C132.9,944.5,132.4,944.9,131.8,944.6z"
      />
      <path
        class="st995"
        d="M131.8,944.6c1.4-0.3,2.2-1.5,3.2-2.3c2-1.7,4.1-3,6.2-4.3c1.4-0.9,2.8-1.8,4.2-2.8c1.2-0.9,2.7-1.4,4.1-2
		c0.6-0.2,1-0.6,1.5-0.9c2.4-1.8,5-3.4,7.8-4.7c0.8-0.3,1.7-0.3,2.3-1c0.2-0.2,0.6,0.2,0.9,0.1c0.3,0.3,0.3,0.7,0,1
		c-0.6,0.4-0.9,1.2-1.6,1.5c-1.8,0.8-3.5,1.9-5.2,2.8c-1.6,0.8-3.1,1.9-4.7,2.7c-1.8,0.9-3.4,2.1-5,3.3c-0.7,0.5-1.7,0.5-2.4,1.1
		c-0.1,0.1-0.3,0.1-0.4,0.2c-1,1.2-2.6,1.4-3.8,2.2c-0.2,0.2-0.6,0.3-0.7,0.5c-0.4,1.1-1.5,1.3-2.4,1.6c-1.1,0.4-1.9,1-2.8,1.8
		c-0.3,0.3-0.7,1-1.3,0.2C131.8,945.3,131.8,944.9,131.8,944.6z"
      />
      <path
        class="st993"
        d="M141.2,927.3c-0.6,0.3-1.1,0.6-1.7,0.9c-5.7-1.8-11.7-1.3-17.5-1.9c-0.6-0.3-1.1-0.6-1.7-0.8
		c-0.7-0.3-1.5-0.2-1.7-1.2c0.5-0.3,1.1-0.4,1.6-0.3c2.2,0.4,4.4-0.1,6.6-0.2c1.8,0,3.6-0.1,5.4,0.2c2.5,0.4,4.9,1.1,7.4,1.2
		c0.7,0,1.4,0.5,2.2,0.3c0.3-0.1,0.6,0.2,0.8,0.5C142.6,927,141.7,926.9,141.2,927.3z"
      />
      <path
        class="st996"
        d="M141.2,927.3c0.3-0.6,0.9-0.8,1.5-1c0.6-0.8,1.6-1,2.4-1.7c0.6-0.2,1.1-0.9,2-0.7c0.2,0.7-0.4,0.7-0.7,1
		c-0.8,0.4-1.5,0.8-2.2,1.3c2.1,0.9,4.5,0.5,6.6,1c0.3,0.1,0.7-0.1,0.7,0.4c-1.9,1-3.6,2.4-5.6,3.3c-0.2-0.3-0.4-0.2-0.6-0.1
		c-1.1,0.6-2.1,0.8-3.4,0.2c-1.2-0.5-2.6-0.8-3.9-1.5c0.6-0.7,1.4-0.9,2.1-1.4C140.5,928,141.2,928,141.2,927.3z"
      />
      <path
        class="st996"
        d="M122,926.2c0.1-0.4,0.3-0.5,0.7-0.3c0.5,0.3,1.1,0.3,1.7,0.3c3.7-0.1,7.5,0,11.2,0.7c1.3,0.2,2.8,0.2,4,1.2
		c-1,0.9-2.3,1.2-3.3,1.9c-0.1,0.1-0.3,0.1-0.4,0c-0.5-0.8-1.2-0.5-1.9-0.5c-1.1,0-2.2-0.2-3.3-0.4c-1-0.2-2.1,0.2-3.1-0.1
		c-0.4-0.1-0.9-0.2-1.2-0.4C124.9,927.9,123.4,927.1,122,926.2z"
      />
      <path
        class="st997"
        d="M118.7,924.4c0.6,0.3,1.2,0.5,1.7,1c0.5,0.9,1.6,1.1,2.3,1.7c0.2,0.1,0.5,0.1,0.5,0.5
		c-1.7,0-3.4,0.1-5.1-0.3c-2.2-0.5-4.3-0.8-6.4-1.7c-2.2-0.9-3.5-2.9-2.4-5.5c0.9,0.1,1.4,1.3,2.5,0.9c0.8,0.3,1.6,0.8,2.5,1.4
		c-0.9,0.2-1.3-0.8-2.1-0.7c-0.4,0.9,0.1,1.4,0.8,2c1.5,1.3,3.1,1.4,4.9,1.3c0.8-0.1,0.5-0.4,0.3-0.7
		C118.4,924.2,118.6,924.2,118.7,924.4z"
      />
      <path
        class="st998"
        d="M104.3,919.6c-0.2-0.1-0.1-0.2,0.1-0.3c0.9-0.7,0.9-0.7,0.3-2c1.6,1,2.9,0.1,4.2-0.4
		c-0.2,0.4-0.5,0.5-0.8,0.7s-0.9,0.2-0.8,0.9c0.1,0.3-0.4,0.3-0.5,0.3C105.8,918.8,105.1,919.3,104.3,919.6z"
      />
      <path
        class="st999"
        d="M120.8,909.6c-2.7,1.6-5.3,3.2-8,4.9c-0.1,0-0.2-0.1-0.3-0.1c0.8-0.8,0.9-1.6,0.3-2.6
		c-0.3-0.4-0.1-1.2-0.1-1.7c0-1.3-0.6-1.8-1.8-1.4c-0.9,0.3-1.7-0.3-2.4-0.6c-0.6-0.2-0.1-1.3,0.2-2c0.2-0.4,0.9-0.6,0.5-1.2
		c-0.3-0.4,0.1-0.6,0.2-0.9c0.4-0.7,0.7-1.5,0.9-2.3c0.1-0.8,0-1.7,0.3-2.5c0.1-0.2,0.2-0.4,0.5-0.4c1.8,1.2,3.7,1.9,5.8,1.7
		c0.3,0,0.6,0,0.9,0.1c0.3,0.4,0,0.6-0.3,0.7c-0.2,0.1-0.5,0.1-0.4,0.3c0,0.3,0.3,0.4,0.5,0.4c0.5,0.1,1,0.2,1.5,0.3
		c1,0.2,1.2,0.6,1.1,1.6c0,0.4,0.1,0.6,0.3,0.8c0.7,0.6,0.7,1.5,0.3,2.2c-0.4,0.8-0.2,1.3,0.3,1.8C121.1,909,121,909.3,120.8,909.6z
		"
      />
      <path
        class="st1000"
        d="M94.9,902.3c-0.7-1.3-0.3-2.8-0.4-4.2c0-0.8,0-1.5,0.6-2.1c0.3-0.5,0.5-1.1,0.9-1.7
		c0.1-0.2,0.3-0.4,0.5-0.6c0.9-1,2.1-1.4,3.2-2.1c0.4-0.2,0.9-0.4,1.4-0.5c1.3-0.1,2.5,0.1,3.8,0.4c0.5,0.1,1,0.2,1.3,0.7
		c-1.5,1.2-2.1,3.1-3.4,4.5c-1,1.1-1.9,2.3-3.2,3c-1,0.6-2.1,1.2-3,1.9c-0.4,0.3-0.7,0.7-1.3,0.3c-0.1,0,0,0.1-0.1,0.1
		C95.2,902.3,95.1,902.3,94.9,902.3z"
      />
      <path
        class="st1001"
        d="M95.3,896c-0.5,0.6-0.5,1.4-0.8,2.1V898l-0.1-0.1c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,1.3-0.8,1.6-2,1.2
		c-3-0.8-5.9-1.5-9-0.4c-2,0.6-4.1,0.8-6.1,1.3c-0.3,0-0.5,0-0.7-0.1c-0.4-0.3-0.8-0.2-1.2,0c-0.3,0.2-0.8-0.7-0.8,0.2
		c-0.1,0.1-0.3,0.2-0.4,0.2c-1.7,0.3-3.3-0.4-4.9-0.3c-1.6-0.2-3-0.8-4.2-1.8c-0.3-0.2-0.6-0.5-0.5-0.9c0.5-0.4,0.8,0,1.1,0.2
		c0.7,0.5,1.5,1,2.4,1.2c0.5,0.2,1.1,0.3,1.7,0.4c1.2,0.3,2.5,0,3.8,0.3c0-0.1-0.1-0.2,0-0.4c0.2-0.1,0.4-0.2,0.6-0.1
		c1.3,0.3,2.7,0,4-0.3c2.6-0.5,4.9-1.5,7.2-2.9c1.2-0.7,2.4-0.9,3.7-0.8c1.6,0.1,3.2,0.1,4.7,0.5c0.5,0.1,0.8,0,1.1-0.3
		c0.2-0.2,0.4-0.5,0.7-0.6C95.4,895.5,95.5,895.7,95.3,896z"
      />
      <path
        class="st1002"
        d="M110.5,901.7c-0.2,1.2-0.8,2.1-1.3,3.1c-0.9,1-1.8,1.9-3,2.4c-0.2-0.5,0.3-0.7,0.6-1
		c-0.6-0.3-1.2-0.4-2-0.3c-1.3,0.2-2.6,0.1-3.8-0.2c-1.8-0.4-2.1-1.6-1-2.9c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.6,1-1.1,1.6-1.4
		c1.5-0.9,2.5-2.2,3.8-3.4c0.8-0.8,1.3-1.9,1.8-2.9c0.4-0.6-0.4-1.1-0.8-1.7c-0.1-0.1-0.2-0.3-0.1-0.4c1.3-0.2,1.6,0.9,2.2,1.6
		c0,0.1,0,0.2,0.1,0.3c-0.4,1.7-1.6,2.8-2.7,4c-0.9,1-1.8,2-2.8,2.9c-0.2,0.2-0.4,0.4-0.5,0.6c-0.2,0.2-0.3,0.5-0.1,0.8
		c0.2,0.3,0.4,0.3,0.7,0.3c1.6-0.2,3-0.7,4.3-1.6c0.3-0.2,0.6-0.5,0.9-0.7C109.8,900.1,110.4,900.3,110.5,901.7z"
      />
      <path
        class="st1003"
        d="M106.6,892.7c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.6,0.1,1.2-0.2,1.7c-1.2,2.1-2.6,4-4.6,5.4
		c-1.2,0.9-2.4,2-3.7,2.9c-0.5,0.4-1,0.9-1.3,1.4c-0.9,0.1-1.2-0.7-1.8-1.1c-0.3-0.3-0.4-0.7-0.4-1c1-0.5,1.8-1.2,2.8-1.7
		c1.7-0.9,3.2-1.9,4.4-3.4c1.1-1.3,2.2-2.5,2.8-4.1c0.3-0.7,0.6-0.9,1.3-0.6C106.4,892.3,106.6,892.4,106.6,892.7z"
      />
      <path
        class="st1004"
        d="M95.3,903.3c0.7,0.1,1.1,0.8,1.7,1c0.6-1.2,1.7-1.6,2.9-1.9c0.2-0.1,0.5-0.2,0.7,0c0,0.1-0.1,0.1-0.1,0.2
		c-0.4,0.5-1.2,1-0.8,1.9c0.4,0.9,1.3,1.1,2.1,1c1.2-0.1,2.4,0.5,3.7-0.1c0.5-0.2,1.2,0.3,1.7,0.6c-0.1,0.6-0.9,0.5-0.9,1.1
		C102,909.2,97,907.4,95.3,903.3z"
      />
      <path
        class="st1005"
        d="M120.8,909.6c0.1-0.3,0.1-0.6,0.2-0.8c0.4-0.8,1.1-0.6,1.7-0.3s0.5,0.9,0.2,1.4c-0.6,0-1.3,0-1.9,0.2
		C120.9,909.8,120.8,909.7,120.8,909.6z"
      />
      <path
        class="st1006"
        d="M62.2,879.8c1-0.6,2.1-1.1,2.9-2c0.2-0.3,0.5-0.5,0.6-0.9c0.3-0.7,0.1-1.6,0.8-2.1c0.6,0.9,0.9,1,1.8,0.4
		s1.9-1.1,2.9-1.6c0.3-0.2,0.6-0.4,1-0.2c0,0.1,0.1,0.1,0,0.2c0,0.4-0.7,0.7-0.4,1.2c0.3,0.5,0.7,1.1,1.2,1.4c1.6,0.9,3.1,1.8,5,2
		c1,0.1,2,0.8,3.1,0.9c0.2,0.5-0.2,0.7-0.5,0.8c-2,1-3.9,2.1-5.7,3.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.2,0.1-0.3,0.2-0.5,0.2
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.5-0.3c-0.1-0.1,0-0.3,0.1-0.4c0-1-0.8-1.2-1.4-1.5c-2.2-1.1-4.6-1.3-6.9-0.5
		c-0.6,0.2-0.9-0.2-1.1-0.6c-0.3-0.5,0.2-0.5,0.5-0.7c0.3-0.2,0.5-0.3,0.8-0.4C63.9,879.5,63,880,62.2,879.8z"
      />
      <path
        class="st1007"
        d="M59.5,891.7c-1.1-4.2,1.1-8.4,5.3-9.7c0.4-0.2,0.9-0.2,1.4-0.3c0.7-0.1,1.3-0.1,2,0
		c0.8,0.1,1.6,0.3,2.3,0.7c0.1,0.2,0,0.3-0.2,0.3c-1.1-0.2-1.6,0.5-2.1,1.4c-1.3,2.6-3.4,4.3-5.7,5.9
		C61.6,890.5,60.4,890.8,59.5,891.7z"
      />
      <path
        class="st1008"
        d="M63.9,897.3c-1.1-0.9-2.4-1.6-3.1-3c1-0.9,2.2-1.4,3.2-2.2c2.2-1.8,4.4-3.6,6.2-5.8
		c0.6-0.7,1.2-1.5,2.4-1.4c0.5,0.5,0.4,0.9-0.1,1.3c-1.3,1.2-2.6,2.5-3.8,3.9c-0.6,0.8-1.5,1.3-2.2,1.9c-0.2,0.2-0.4,0.3-0.6,0.5
		c-0.9,1-0.7,1.7,0.5,2.1c0.9,0.3,1.8,0.5,2.7,0.1c0.2-0.1,0.3-0.1,0.5-0.1c0.4,0,0.8-0.1,1,0.3c0.2,0.4,0,0.8-0.3,1
		c-0.8,0.6-1.3,1.6-2.3,2c-1.1,0.2-2.2,0.1-3.3-0.1C64.5,897.7,64.1,897.6,63.9,897.3z"
      />
      <path
        class="st1009"
        d="M59.5,891.7c0-0.8,0.7-0.9,1.2-1.1c1.9-0.9,3.5-2.1,5.1-3.5c1-1,1.8-2.1,2.4-3.3c0.5-1.1,1.2-1.5,2.4-1.4
		c0.4,0.1,0.8,0.2,1,0.6c0.2,0.6-0.5,0.6-0.7,0.8c-0.7,0.8-1.2,1.7-1.9,2.6c-1.5,2.1-3.7,3.6-5.7,5.3c-0.7,0.6-1.5,1.1-2.4,1.3
		c-0.4,0.1-0.7,0.1-1-0.3C59.6,892.4,59.5,892.1,59.5,891.7z"
      />
      <path
        class="st1010"
        d="M59.9,892.7c0.8,0.4,1.6-0.1,2.1-0.4c2.5-1.9,5.1-3.7,6.9-6.3c0.7-1,1.7-1.7,2-2.9c0.1-0.3,0.6,0.4,0.8-0.2
		c0.2,0,0.3,0,0.4,0.2c0.2,0.5,0.8,1,0.6,1.7c0,0.1-0.1,0.2-0.2,0.2c-0.9,0.1-1.6,0.8-2.1,1.4c-1.8,2.3-4,4.2-6.3,6
		c-0.8,0.6-1.7,1.1-2.4,1.7c-0.3,0.2-0.6,0.1-0.8,0.2C60.3,893.9,60,893.3,59.9,892.7z"
      />
      <path
        class="st883"
        d="M73.9,901.2c0.1,0,0.3,0,0.4,0c0.5,0.2,1.1,0.4,1.6,0.6c0.3,0.1,0.6,0.4,0.5,0.7c0,0.4-0.4,0.5-0.7,0.5
		c-1.3,0.1-2.7,0.3-3.9,0c-0.6-0.1-1.2-0.9-2-1c-0.1,0-0.2-0.1-0.3-0.1c-2.2,0.2-3.4-2-5.4-2.5c-0.8-0.2-0.1-1.1-0.5-1.6
		c0.4,0,0.5,0.2,0.6,0.5c0.1,0.6,0.7,0.9,1.1,1.2c1.1,0.7,2.2,1.2,3.5,1.3c1.2,0.2,2.3,0.9,3.6,0.7C73,901.5,73.5,901.5,73.9,901.2z
		"
      />
      <path
        class="st883"
        d="M83.1,908.1c-0.7,0-1-0.3-1.1-0.9c0-0.5-0.2-1,0.7-0.8c0.5,0.1-0.2-0.6,0.2-0.5c0.4,0.1,0.9,0.3,1.2,0.6
		c0.3,0.5-0.3,0.7-0.3,1.1C83.7,908,83.4,908.2,83.1,908.1z"
      />
      <path
        class="st1011"
        d="M64.3,898.3c-0.2-0.2-0.4-0.3-0.6-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0.3-0.1,0.6,0.1,0.8,0.2
		c0.1,0.1,0.1,0.1,0.2,0.2c0.7,0.8,1.6,1.2,2.4,1.7c0.2,0.2,0.5,0.2,0.3,0.6c-1,0-1.7-0.7-2.4-1.2C64.9,898.7,64.7,898.3,64.3,898.3
		z"
      />
      <path
        class="st1008"
        d="M72.4,884.8c0.3-0.7-0.7-1-0.4-1.7c0.3,0.1,0.5,0.5,0.8,0.5c0,0.1,0,0.2,0,0.4c0.1,0.1,0.1,0.2,0.1,0.3
		C72.9,884.5,72.9,884.8,72.4,884.8z"
      />
      <path
        class="st883"
        d="M77.6,904.2c0,0.2-0.1,0.4-0.3,0.3c-0.2,0-0.4-0.1-0.4-0.4c0-0.2,0.2-0.4,0.4-0.3
		C77.5,903.8,77.6,904,77.6,904.2z"
      />
      <path
        class="st1006"
        d="M68.2,881.6c-0.7,0-1.3,0-2,0C66.9,881.3,67.6,881.4,68.2,881.6z"
      />
      <path
        class="st1012"
        d="M218.9,894.8c-0.9,0.3-1.7,0.8-2.6,1c-0.5,0.2-0.6,0.8-1.1,0.9c-3.2,0.9-5.5,3.5-8.5,4.7
		c-1.2,0.5-2.1,1.5-3.2,2c-1.9,0.8-3.3,2.3-5.3,2.9c-0.2,0.1-0.5,0.3-0.7,0.4c-1.2,1-2.7,1.4-3.9,2.3c-1.9,1.3-4.2,2-6,3.4
		c-1.2,0.9-2.7,1.5-4,2.3c-2.5,1.6-5.1,3.1-7.5,4.8c-2.8,1.9-5.8,3.4-8.6,5.2c-0.7,0.4-1.2,1.2-1.9,1.4c-0.9,0.3-1.6,0.9-2.5,1.2
		c-0.3,0.1-0.6,0.3-0.9,0.5c-0.1-0.8-0.2-1.5,0-2.3c1.1,0.3,1.8-0.6,2.6-1.1c2-1.2,4-2.5,5.9-3.8c2.3-1.5,4.7-2.9,7-4.4
		c1-0.7,1.9-1.8,2.8-2.7c0.5-0.5,1-0.9,1.8-0.9c0.6,0,1-0.4,1.3-0.8c0.4-0.6,1-1,1.6-1.3c1.8-0.7,3.3-1.8,5-2.7
		c1-0.5,2.1-1.1,3.2-1.5c0.8-0.3,1.6-0.7,2.2-1.4c0.6-0.8,1.8-0.8,2.7-1.3c1.8-1,3.6-2,5.3-3.2c0.6-0.4,1.2-0.6,1.8-1
		c1.7-1.2,3.8-2,5.5-3.2c1.1-0.8,2.5-1.3,3.8-1.9c0.6-0.3,1.3-0.5,1.9-0.8c0.7-0.4,1.5-0.5,2.3-0.2
		C218.9,893.8,218.9,894.3,218.9,894.8z"
      />
      <path
        class="st1013"
        d="M203.9,881.8c1.6,1.2,3.5,1.9,5.1,3c0.4,0.3,1,0.3,1.1,0.9c0,0.2,0.3,0,0.4,0c1.2,0.6,2.2,1.6,2.9,2.7
		c0.2,0.3,0.4,0.7,0.8,0.8c0.7,0.2-0.1,0.9,0.4,1.2c-1.3,1.1-2.6,2.1-4.4,2.3c-0.6,0-0.9-0.5-1.3-0.7c0.2,0.1,0.4,0.2,0.5,0.3
		c0.2,0.1,0.3,0.2,0.2,0.4s-0.2,0.2-0.4,0.3c-0.8,0.1-1.5,0.2-2.3,0.1c-1.9-0.4-3.8-0.3-5.6-0.8c-0.8-0.2-1.5-0.6-2.4-0.2
		c-0.5,0.2-0.5-0.3-0.3-0.7c0.3-0.6,0.9-0.8,1.5-1.1s1.1-0.7,1.7-1c1-0.2,2.1,0,2.9-0.9c0.1-0.1,0.1-0.1,0.1-0.3
		c0.4-0.7,1.2-0.8,1.8-1.3c-1.1-0.8-1.9-1.8-2.9-2.7c-0.5-0.4-1.1-0.7-1.8-0.7c-0.3,0-0.6,0-0.8-0.1c-3.9-1-7.8-0.8-11.7-0.2
		c-3.5,0.5-6.9,1-10.4,1.2c-1.4,0.1-2.8,0.4-4.2,0.1c-0.2,0-0.3-0.1-0.4-0.3c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3,0
		c0.3,0.4,0.6,0,0.9-0.1c0.8-0.4,1.6-0.8,2.4-1.2c4.9-0.8,9.9-1.7,14.9-2.2c2.8-0.2,5.6-0.2,8.5,0.1
		C202.2,881,203,881.4,203.9,881.8z"
      />
      <path
        class="st1014"
        d="M172.6,911.2c0.3,0.2,0.5,0.4,0.8,0.7c-1.3,0.3-2.3-0.5-3.1-1.3c-0.4-0.4-0.6-0.5-1.1-0.3
		c-0.1,0-0.3,0-0.4,0c-0.7-0.3-1.4-0.2-2,0c-1.6,0.3-3.3,0.1-4.8-0.2c-3.6-0.7-7.1-1.3-10.7-2.2c-1.3-0.3-2.7-0.5-3.8-1.2
		c-0.4-0.2-1-0.4-0.6-1c-0.2,0-0.4,0.2-0.6,0c-0.4-0.5-0.6-1-1.1-1.5c-0.5-0.6-0.5-1.2-0.4-2c0.2-0.9,0.2-1.8-0.1-2.7
		c-0.5-1.4-0.1-2,1.2-2.3c0.4,0.2,0.8,0.5,1.2,0.8c2.1,1.2,2.2,3.1,1.8,4.8c-0.1,0.3-0.2,0.7-0.2,1c0.3,0,0.6-0.2,0.9-0.2
		c0.3,0,0.7,0,0.5,0.4c-0.3,0.6,0.2,0.7,0.5,0.9c1.7,0.8,3.5,1.2,5.3,1.4c2.1,0.3,4.3,0.5,6.5,0.8c0.9,0.1,1.8,0.4,2.7,0.8
		c1.1,0.5,2.2,0.6,3.3,0.3c1.1,0.3,2.4,0.3,2.8,1.7c0.2,0.7,0.9,0.8,1.3,1.2C172.6,911.1,172.6,911.2,172.6,911.2z"
      />
      <path
        class="st1015"
        d="M205.6,895.4c-1.2,1-2.7,1.5-3.9,2.5c-0.7,0.5-1.6,0.9-2.3,1.3c-0.8,0.4-1.6,0.4-2.4,0
		c-0.5-0.2-1-0.1-1.5,0c-0.9,0.2-1.6,0.2-2.2-0.6c-0.1,0-0.1,0.2-0.1,0.1c0,0,0,0,0.1,0c0.6,0.4,0.4,0.7-0.1,1
		c-1.4,0.8-2.9,1.4-4.4,1.6c-1.6,0.2-3.1,0.5-4.7,0.7c-0.7,0.1-1.3,0-1.7-0.7c0-0.1,0-0.2,0-0.3c0.1-0.4,0.4-0.6,0.8-0.8
		c1.6-0.9,3.1-1.9,4.8-2.8c0.4-0.2,0.8-0.4,1.2-0.4c0.9,0.1,1.4-0.3,1.6-1.2c0.4-0.6,1-0.9,1.8-1c0.1,0.1,0.1,0.2,0.2,0.2
		c0,0.1,0.1,0.2,0.1,0.1c1.6-0.7,3.4-0.3,5-0.6s3.3,0.1,4.9,0.5c0.8,0.2,1.6,0.3,2.4,0C205.2,895.2,205.4,895.2,205.6,895.4z"
      />
      <path
        class="st1016"
        d="M182.2,901.2c0.8,1,1.8,0.5,2.7,0.4c1.4-0.2,2.9-0.2,4.3-0.7c1.4-0.4,2.9-0.8,4.1-1.8c-0.3-0.5-1-0.4-1.2-1
		c0.3-0.2,0.6,0,0.7,0.1c1,1,2.1,0.7,3.3,0.5c0.1,0,0.3-0.1,0.4-0.1c0.9,0.4,1.8,0.6,2.8,0.4c-2.2,1.4-4.5,2.7-6.7,4.1
		c-1.5,0.9-3,1.8-4.5,2.7c-1.4,0.9-1.5,0.9-2.9-0.2c-0.9-0.6-1.6-1.4-2.8-1.4c-0.2,0-0.3,0-0.5,0.1c-0.3-0.2-0.2-0.5-0.2-0.8
		c0.2-0.5,0.1-1,0.2-1.5C181.9,901.8,181.9,901.5,182.2,901.2z"
      />
      <path
        class="st1017"
        d="M169.1,910.2c0.4-0.2,0.9-0.3,1.3-0.7c-0.2,1.1,1.1,2,3.1,2.4c1,0.7,2.2,0.4,3.4,0.7
		c-0.1,0.2-0.3,0.3-0.6,0.4c-0.9,0.2-1.9,0.1-2.8-0.2c-0.5-0.2-1-0.4-1.6-0.6c-0.9-0.3-1.6-0.2-2.1,0.8c-0.2,0.5-0.7,0.8-1.1,1.1
		c-0.9,0.7-1.6,1.7-2.2,2.6c-0.2,0.3-0.3,0.7-0.2,1c0.2,0.9-0.3,1.3-1,1.7c-0.4,0.2-0.9,0.4-1.2,0.9c-1.6,0.7-2.2,1.8-1.9,3.5
		c0.1,0.6,0,1.2,0,1.8c0,0.8,0,1.5,0,2.3c0,0.2,0,0.4-0.1,0.9c-0.1-0.6-0.1-0.8-0.1-1.1s0-0.7,0-1c0-1.4,0-2.8,0-4.2
		c0-0.5-0.1-0.9-0.8-0.5c-0.9-1.5-2.7-1.7-3.8-2.9c0-0.1-0.1-0.2-0.1-0.3s0.1-0.2,0.1-0.2c0.9,0.1,1.5,0.9,2.4,1
		c0.6-1.6,0.8-3.3,2-4.6c0.5-0.7,1.3-1,1.9-1.5c0.3-0.3,0.7-0.4,1.1-0.6c0.5-0.3,1.1-0.5,1.4-1c0-0.1,0.1-0.2,0.1-0.2
		C167.2,910.9,168.1,910.5,169.1,910.2z"
      />
      <path
        class="st1018"
        d="M205.6,895.4c-1.2,0.6-2.4,0.3-3.6,0c-1.7-0.5-3.4-0.6-5.2-0.3c-0.7,0.1-1.4,0-2.2,0c-0.6,0-1.1,0-1.7,0.4
		c-0.5,0.3-0.5,0.3-0.7-0.3c0.9-1.1,2.3-1.5,3.5-2.3c0.7-0.3,1.3-0.7,2-1.1c0.4-0.2,0.7-0.6,1.2-0.4c0.1,0.2-0.1,0.7,0.1,0.5
		c1-0.7,1.8,0.1,2.7,0.2c1.4,0.2,2.9,0.2,4.2,0.5c1.2,0.2,2.4,0.1,3.8,0.3c-0.4-0.7-1.2-0.8-1.6-1.4c0.9,0.2,1.6,0.8,2.2,1.2
		C208.8,893.7,207.3,894.8,205.6,895.4z"
      />
      <path
        class="st1019"
        d="M181.8,904.8c1.4,0.6,2.6,1.3,3.9,2.3c-0.2,0.4-0.7,0.5-1,0.8c-1.9,1.2-3.8,2.2-5.7,3.5
		c-1.6,1-3.1,0.9-4.7,0.4c-0.5-0.2-1-0.5-1.6-0.5l0,0c-0.4-0.3-1-0.5-1.1-1.1c0-0.1,0.1-0.2,0.1-0.3c0.7-0.5,1.5-0.3,2.2-0.3
		c2.7-0.1,5-1,6.7-3.2C180.9,905.8,181.1,905.1,181.8,904.8z"
      />
      <path
        class="st1020"
        d="M164.1,920.2c-0.1-0.7,0.6-0.7,1-0.9c0.6-0.4,1.2-0.7,1-1.7c-0.2-1.1,0.9-1.9,1.5-2.8
		c0.3-0.4,0.7-0.8,1.2-1.1c0.3-0.2,0.6-0.5,0.8-0.8c1-1.4,1.1-1.5,2.6-0.9c1.3,0.5,2.7,0.7,4.1,1c-2,1.5-4.2,2.6-6.4,3.9
		C168,918,166.1,919.1,164.1,920.2z"
      />
      <path
        class="st1021"
        d="M181.8,904.8c-1.2,2.2-2.9,4-5.5,4.6c-1.1,0.3-2.2,0.4-3.4,0.3c-0.4,0-0.9-0.1-1.3,0.2
		c-0.4-0.2-0.8-0.4-0.2-0.8c1.4,0.1,2.9,0.2,4.3-0.2c2.6-0.8,4.5-2.3,5.5-4.9c0.1-0.2,0.1-0.5,0.4-0.6c0.1,0,0.1,0,0.1,0.1
		c0,0.3,0,0.6,0,0.8C181.8,904.5,181.8,904.7,181.8,904.8z"
      />
      <path
        class="st1022"
        d="M185,870.2c-0.4,0.2-1,0.2-1.1,0.8c-0.2-0.4-0.5-0.3-0.8,0c-0.7,0.5-1.5,1-2.3,1.6c-0.4,0-0.7,0-1.1,0.2
		c-0.4,0.2-0.8,0.2-1.1-0.1c-0.5-0.7-0.1-1.2,0.4-1.5c2.1-1.4,4-3.1,5.9-4.7C186.3,867.5,186.3,868.4,185,870.2z"
      />
      <path
        class="st1023"
        d="M183.7,865.4c-0.2,0-0.5,0-0.6,0.2c0.2,0.7-0.4,1-0.8,1.2c-0.4,0.2-0.6-0.1-0.7-0.5
		c-0.3-0.9-0.3-1.8-0.1-2.8c0.1-0.3,0.1-0.7,0.1-1c0.1-0.8,0.2-1.5,1-1.9c0.1,0,0.2,0,0.3,0c0.6,0.1,1.2-0.6,1.6,0
		c0.6,0.8,1.2,1.5,1.2,2.6c0,0.3,0,0.6-0.3,0.7C184.7,864.3,184.3,865,183.7,865.4z"
      />
      <path
        class="st1024"
        d="M183.7,865.4c0.4-0.6,0.6-1.5,1.6-1.5c0.2,0,0.2-0.2,0.2-0.4c0-1.1-0.8-1.9-1.3-2.7
		c-0.2-0.4-0.9,0.2-1.4,0.1c-0.1-0.1-0.1-0.2,0.1-0.2c0.7-0.2,1.5-0.2,2.2-0.6c1.8,0,2.8,1.7,2.1,3.3c-0.3,0.6-0.5,1.2-0.4,1.9
		c0.1,0.5-0.1,0.8-0.5,1c-0.4,0.3-0.7,0-1-0.2C184.8,865.7,184.3,865.3,183.7,865.4z"
      />
      <path
        class="st1025"
        d="M184.9,866.3c0,0.2,0,0.4-0.1,0.5c-1.9,1.5-3.7,3.1-5.7,4.4c-0.5,0.3-0.8,0.7-0.4,1.3
		c-0.2,1.3-1.3,2.4-2.5,2.5c-0.3,0-0.6,0-0.7-0.3c-0.1-0.5,0.3-0.8,0.6-1.2c0.7-0.8,1.1-1.7,1-2.7c0-0.2,0.1-0.3,0.2-0.4
		c0.1,0,0.2,0,0.3,0c0.6,0.3,1,0.2,1.5-0.2c0.9-0.8,1.9-1.4,2.6-2.4c0.1-0.2,0.4-0.2,0.6-0.3c0.6-0.4,1-1.2,1.8-1.4
		C184.4,865.9,184.6,866.1,184.9,866.3z"
      />
      <path
        class="st1026"
        d="M184.1,865.9c-0.4,0.6-1,1.2-1.6,1.6c-0.5,0.2-0.9,0-1.2-0.5c-0.5-1.3-0.2-2.6,0-3.9
		c0.1-0.3,0.1-0.6,0.5-0.7c0,1.4-0.6,2.8,0.1,4.3c0.4-0.4,1-0.5,1.1-1.1C183.4,865.6,183.7,865.8,184.1,865.9z"
      />
      <path
        class="st1027"
        d="M201.4,880.6c0,0.2,0,0.3,0.1,0.5c-2.2-0.8-4.6-0.5-6.8-0.5c-1.9,0.1-3.9,0.4-5.8,0.6
		c-1.4,0.1-2.7,0.6-4.1,0.7c-1.8,0.2-3.5,0.5-5.3,0.7c-0.6,0.1-1.1,0.4-1.7,0.7c0.2-0.3,0.3-0.5,0.4-0.7c0.5-0.7,1.4-0.8,1.9-1.4
		c1.1-1,2.4-1.5,3.7-2.3c2-1.2,4-2.3,5.9-3.6c0.2-0.1,0.4-0.2,0.7-0.2c0.6,0.1,1.1,0.1,1.7,0c0.9-0.2,1.3,0.7,2,1
		c1.1,0.9,2.2,1.6,3.6,2.1c0.4,0.2,0.9,0.4,1.3,0.7C199.5,879.7,200.6,879.9,201.4,880.6z"
      />
      <path
        class="st1028"
        d="M175.6,874.6c0.8,0.3,2-0.2,2.5-1c0.2-0.3,0.4-0.7,0.6-1c0.4,0.2,0.7,0.2,1.1-0.1c0.3-0.3,0.7-0.3,1,0.1
		c-1.4,0.6-1,1.8-1,2.9c0.2,0.7,0.1,1.1-0.6,1.5c-1.6,0.8-3.1,1.8-4.8,2.5c-0.4,0.2-0.5,0.4-0.3,0.8c0.2,0.4,0.4,0.8-0.2,1
		c-1,0.2-2.1,0-3.1,0.3c-0.3-0.2-0.2-0.4,0-0.6c1.2-0.7,1.1-2.1,1.6-3.2c0.8-1.1,1.9-2,2.8-3C175.1,874.6,175.3,874.5,175.6,874.6z"
      />
      <path
        class="st1029"
        d="M173.9,881c0.1-0.1,0.2-0.2,0.1-0.3c-0.6-1.2-0.5-1.2,0.7-1.7c1.4-0.7,2.7-1.6,4.1-2.3
		c0.6-0.3,1.1-0.5,0.9-1.3c0.4,1.4,0.1,2.8-0.4,4.1c-0.1,0.2-0.3,0.4-0.4,0.6c-0.3,2.5-2.5,2.9-4.2,3.9c-0.1,0-0.1,0-0.2,0
		c-0.1-0.9-0.3-1.8-0.8-2.6C173.8,881.2,173.8,881.1,173.9,881z"
      />
      <path
        class="st1030"
        d="M193.9,876.3c-0.2-0.1-0.5-0.2-0.7-0.3c-0.5-0.4-1-0.7-1.7-0.3c-0.4,0.2-0.9,0-1.3-0.2
		c-0.2-0.1-0.4-0.2-0.2-0.4c0.8-1.1,1.8-1.2,2.6-0.1c0.3,0.4,0.6,0.6,1,0.8C193.7,875.9,194.1,875.9,193.9,876.3z"
      />
      <path
        class="st1031"
        d="M189.9,875c0.1,0.1,0.1,0.3,0.2,0.4c-2.1,1.2-4.3,2.5-6.4,3.8c-0.1,0-0.2-0.1-0.2-0.2
		C185.6,877.6,187.8,876.3,189.9,875z"
      />
      <path
        class="st1032"
        d="M183.4,879c0.1,0.1,0.1,0.1,0.2,0.2c-1.2,0.7-2.5,1.5-3.7,2.2c-0.3-0.2,0-0.3,0.1-0.4
		C181.2,880.2,182.3,879.6,183.4,879z"
      />
      <path
        class="st1033"
        d="M167.4,841.1c-0.1,0-0.1,0-0.2,0c-0.4,0.4-0.9,0.6-1.5,0.8c-1,0.2-2,0.8-2.9,1.4c-0.4,0.3-0.9,0.6-1.2-0.2
		c-0.5-1.6-0.1-2.2,1.4-3c1.3-0.8,2.5-1.7,3.9-2.3c0.3-0.1,0.4-0.5,0.5-0.7c0.1-0.1,0.2-0.1,0.3,0l0,0
		C167.9,838.4,167.8,839.8,167.4,841.1z"
      />
      <path
        class="st1034"
        d="M141.8,783.8c-1.1-0.7-1.8,0.2-2.5,0.8c0.7-1.5,1.2-1.7,2.9-1.2c0.3,0.2,0.6,0.4,0.8,0.7
		c0,0.1,0,0.1,0.1,0.1c0.1,0.3,0.3,0.5,0.4,0.8c0.3,1,0,2-0.9,2.5c-0.3,0.2-0.5,0.2-0.6-0.2c0.3-0.3,0.4-0.6,0.6-0.9
		c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.4-0.9,0.9-1.2,1.5c0,0.3-0.2,0.5-0.5,0.5c-0.5-0.1-1-0.2-1.1-0.9c0.6-0.8,1.4-1.2,2-1.9
		c0.5-0.5,1.1-0.9,1-1.8C142.2,784.4,142.1,784,141.8,783.8z"
      />
      <path
        class="st1035"
        d="M171,826.7c0.4-0.3,0.6-0.6,0.7-1.1c0.2-1.7,1.2-3.2,1.5-4.9c0.2-1.4,0.8-2.6,1.4-3.9c0.3-0.7,1-1.2,1.1-2
		c0.1,0,0.1,0,0.2,0.1c0.3,0.4,0.2,0.8,0.1,1.2c-0.3,1.1-0.5,2.2-1,3.3c-0.8,1.8-1.2,3.8-1.8,5.6c-0.2,0.7-0.2,1.7-1.2,2
		c-0.2-0.1-0.4-0.1-0.6-0.2C171.1,826.8,171,826.8,171,826.7z"
      />
      <path
        class="st1036"
        d="M176.6,815c0.3,0.3,0.3,0.7,0.2,1.1c-0.8,2.3-1.4,4.7-2.3,7c-0.5,1.4-1,2.8-1.6,4.1c-0.2,0-0.5,0-0.6-0.2
		c0.8-1.4,1-2.9,1.5-4.4c0.8-2.4,1.8-4.7,2.3-7.2c0-0.2,0.2-0.4,0.4-0.5C176.4,815,176.5,815,176.6,815z"
      />
      <path
        class="st1037"
        d="M176.3,815c-0.6,3.3-2,6.3-2.9,9.5c-0.2,0.7-0.4,1.4-0.6,2c-0.1,0.3-0.2,0.5-0.6,0.5
		c-0.1-0.1-0.3-0.1-0.4-0.2c0.6-0.6,0.8-1.4,1-2.2c0.6-1.9,1-3.9,1.8-5.7c0.6-1.3,0.9-2.7,1.1-4.1C176,814.9,176.1,815,176.3,815z"
      />
      <path
        class="st1038"
        d="M54.3,844.8c-0.7,0-1.1-0.4-1.2-1.1c-0.1-0.7,1-1.7,1.7-1.7s1.1,0.5,1.1,1.2
		C56,844.2,55.2,844.8,54.3,844.8z"
      />
      <path
        class="st1039"
        d="M66.4,845.5c0.5-0.4,1.1-0.2,1.6-0.2c1.6,0.1,3.2-0.3,4.7-0.2c0.3,0,0.6,0.1,0.8,0.3
		c-2.3,0.5-4.7,0.4-7,0.5C66.4,845.7,66.4,845.6,66.4,845.5z"
      />
      <path
        class="st1040"
        d="M94.7,787.5c-0.7,0.3-0.3,1-0.6,1.4c-1.1,1.9-2.3,3.6-4,5c-1.8,1.5-3.7,3.1-6.2,3.5c0-0.6-0.1-1,0.7-1.5
		c2.9-1.7,6.1-2.9,7.5-6.4c0.1-0.3,0.2-0.6,0.3-0.9c0.2-1.4,0.4-1.6,1.9-1.2C94.4,787.5,94.6,787.5,94.7,787.5z"
      />
      <path
        class="st1041"
        d="M89.4,790.7c0,0.9-1.1,1.9-1.8,1.8c-0.7-0.1-1-0.6-1-1.3s0.9-1.7,1.6-1.6C89,789.7,89.3,790.2,89.4,790.7z"
      />
      <path
        class="st1042"
        d="M71.8,851.3c0.1-0.2,0.3-0.5,0-0.8c-0.2-0.2-0.3-0.4-0.4-0.7c-0.6-1.4-0.4-1.5,1.1-1.4
		c0.4,0.3,0.8,0.7,1,1.2c0.8,1.4,2.1,2.1,3.6,2.4c1.3,0.2,2.5,0.2,3.8,0.1c0.6,0,1-0.5,1.4-0.8c0.2-0.2,0.5-0.4,0.8-0.2
		s0.2,0.5,0.2,0.7c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.3,0.2-0.5,0.3c-0.5,0.1-0.6,0.5-0.8,0.9c-0.2,1.3-1.1,0.7-1.8,0.7
		c-0.4,0-0.8,0-1.2,0.1c-0.8,0.1-1.5,0-2.2-0.4h-0.1h-0.1c-0.8-0.3-1.7-0.6-2.5-0.8c-0.8,0-1.3-0.4-1.7-1.1
		C72.2,851.8,71.7,851.7,71.8,851.3z"
      />
      <path
        class="st559"
        d="M69.2,855.4c-0.2,0.4,0.1,1-0.6,1c-0.2,0-0.5-0.1-0.4-0.4C68.2,855.4,68.8,855.6,69.2,855.4z"
      />
      <path
        class="st559"
        d="M72.2,852.1c0.6,0.3,0.9,1,1.7,1.1c-0.6,0.2-1.9-0.5-1.9-1C72,852.1,72.2,852.1,72.2,852.1z"
      />
      <path
        class="st1043"
        d="M182.9,860.6c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0c-3.1-5.2-6.5-10.2-9.9-15.2
		c-0.2-0.4-0.6-0.7-0.5-1.2c0.1,0,0.3,0,0.4-0.1c0.6,0,1.1,0.2,1.5,0.8c0.9,1.3,2,2.4,2.7,3.8c0.9,1.6,1.8,3.2,3,4.5
		c1.7,2,2.3,4.5,3.8,6.6C183.5,860.3,182.8,859.9,182.9,860.6z"
      />
      <path
        class="st1044"
        d="M140.3,789.2c0.2,0,0.3-0.2,0.4-0.4c0.9,0.5,2.2,0.4,2.7,1.6c0.9,2.5,1.3,5,1.6,7.7
		c0.1,0.8,0.3,1.5,1.1,1.7c0.4,0.1,0.9-0.2,1.2,0.2c-0.6,0.1-1.1,0.3-1.6,0.4c-0.6,0.4-1.1,0.1-1.7,0c-1.1-0.9-2.2-1.9-3.4-2.7
		c0.2-0.7,0.6-1.3,0.6-2c0.5-2-0.1-3.8-1-5.5C140.2,789.9,139.7,789.5,140.3,789.2z"
      />
      <path
        class="st1045"
        d="M146.2,799.8c-1,0.2-1.3-0.2-1.3-1.2c-0.2-2.8-1-5.4-1.4-8.2c1.2,1,1.2,2.5,1.5,3.8
		c0.3,1.5,0.9,2.9,1.1,4.4C146.2,799,146.5,799.4,146.2,799.8z"
      />
      <path
        class="st1046"
        d="M145.2,790c-0.2,1.2,0.3,2.2,0.5,3.3c0.3,1.7,0.8,3.4,1.4,5.1c0.1,0.3-0.1,0.8,0.1,1.1
		c-0.3,0-0.5,0.1-0.6-0.3c-0.6-2.3-1.1-4.6-1.7-7c-0.1-0.5-0.3-0.9-0.6-1.3c-0.2-0.2-0.5-0.4-0.3-0.8c0.2-0.3,0.5-0.1,0.8-0.2
		C144.9,790,145,790,145.2,790z"
      />
      <path
        class="st1047"
        d="M73.4,844.4c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1,0-0.1-0.1-0.2c0.5-0.4,0.9-1,1.7-0.9c0.2,0,0.9-0.3,1.1-0.8
		c0.2-0.6,0.7-1,1.3-1.2c0.2-0.6,0.4-1.2,1.1-1.5c0.1-0.1,0.2-0.1,0.4,0c0.1,0.2,0.2,0.2,0.4,0.2c1.2,0.6,1.4,1.8,0.5,3.1
		c-0.9,1.1-2.1,1.8-3.4,2.3c-0.5,0.2-1.1,0.6-1.7,0.4S73.9,844.6,73.4,844.4z"
      />
      <path
        class="st1048"
        d="M83.7,828.1c0,0.1,0,0.3,0,0.4c-0.1,0-0.3-0.1-0.4,0c-0.2,0.3-0.2,1-0.6,1c-1.6,0.1-3.1,0.8-4.7,0.5
		c-0.4-0.1-0.7-0.3-0.9-0.6c0.1-1.2,2.8-3.8,3.9-3.8c0.2,0,0.5,0,0.7,0.2c-0.1,0.8-0.8,0.8-1.2,1.1c0.6-0.1,1.3-0.1,2-0.1
		C83.8,826.9,83.8,826.9,83.7,828.1z"
      />
      <path
        class="st1049"
        d="M74.3,845.4c1.6-0.5,3.1-1,4.3-2.2c1.4-1.4,1.4-2.1,0.3-3.5c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.2,0.6-0.2
		c1.8,0.6,2.4,2.9,1.2,4.4c-1,1.2-2.3,1.8-3.7,2.3c-0.6,0.2-1.2,0.3-1.8,0.6c-0.6,0.3-0.7-0.4-1.1-0.6C74.2,846,74,845.8,74.3,845.4
		z"
      />
      <path
        class="st1050"
        d="M81.6,825.8c-0.9-0.1-1.7,0.2-2.3,0.9c-0.7,0.9-1.8,1.5-2.3,2.6c-0.8,0.3-1.4,1-2.3,1
		c0.1-0.8,0.8-1.3,1-2.1c1-0.7,1.8-1.5,2.7-2.4c0.6-0.5,1.2-1.1,2.1-1l0,0c0.6,0.5,1-0.2,1.4-0.2c1.8,0,3.6-0.6,5.4-0.7
		c0.4,0,0.9-0.2,0.8,0.5c-0.6,0.5-1.4,0.8-2.2,0.8c-1.2,0-2.4,0.2-3.6,0.5C82.2,826.1,81.9,826.1,81.6,825.8z"
      />
      <path
        class="st1051"
        d="M79.7,839.8c-0.2,0-0.4,0-0.6,0c0.3-1.6,0.3-3.2-0.3-4.7c-0.3-0.9-0.7-1.1-1.5-0.6c0-0.9,1-1.3,3.5-1.3
		c0.2,1-0.1,2.1,0.2,3.1c0,0.1,0.1,0.2,0.1,0.2c0.1,0.3,0.3,0.7,0,1c-0.4,0.4-0.1,1.1-0.4,1.5C80.3,839.3,80.2,839.7,79.7,839.8z"
      />
      <path
        class="st1052"
        d="M78.5,839.6c-0.1,0-0.2,0-0.4,0c-0.8-0.6-1.3-1.4-1.1-2.5c-0.4-0.5-0.7-1,0-1.5c0.3-0.2,0.2-1,0.8-0.9
		c0.5,0.1,0.6,0.7,0.6,1.2C78.6,837.2,78.9,838.4,78.5,839.6z"
      />
      <path
        class="st1053"
        d="M75.8,837.1c-0.6,0.7-1.5,0.5-2.2,0.8c0.2-0.6,0.3-1.2,0.2-1.8c-0.1-0.5-0.1-1,0.3-1.3s0.8-0.1,1.2,0.2
		c0.2,0.2,0.5,0.2,0.8,0.1C76.4,835.8,76.3,836.5,75.8,837.1z"
      />
      <path
        class="st1054"
        d="M75.8,837.1c0.1-0.7,0.2-1.4,0.2-2.1c0.3,0,0.7,0,1-0.5c0,0.5,0,0.8,0,1.1c-0.4,0.5-0.1,1,0,1.5
		C76.6,837.1,76.2,837.1,75.8,837.1z"
      />
      <path
        class="st1055"
        d="M81,836.2c-0.4-1-0.1-2-0.2-3.1c0.1,0,0.1,0,0.2,0C81,834.2,81,835.2,81,836.2z"
      />
      <path
        class="st1056"
        d="M102.9,791.1c0,1.2-0.7,1.8-1.6,1.8c-0.8,0-1.1-0.5-1.1-1.2s1-1.6,1.7-1.5
		C102.6,790.3,102.9,790.8,102.9,791.1z"
      />
      <path
        class="st1057"
        d="M171.8,810.2c-0.1-0.1-0.1-0.3-0.2-0.4c0.5-0.6-0.1-1.3,0-1.9c0.1-0.7,0.2-1,0.9-1.3s1.7-0.5,1.7-1.7
		c0.7-0.1,1.4,0.6,2.1,0c0.6-0.5,1-1,1.2-1.8c0.2-1,1.2-1.3,1.6-2.2c0.2-0.5,0.6-0.9,0.3-1.5s0.1-0.6,0.5-0.6c1.1,0,2.1,0.4,3,0.8
		c-1.1,0.6-1.4,1.9-2.1,2.8c-0.9,1.2-1.9,2.3-3,3.4c-1.3,1.2-2.7,2.1-4.2,2.9C172.9,809.3,172,809.3,171.8,810.2z"
      />
      <path
        class="st1058"
        d="M177.1,802.8c0,0.8-1.1,1.9-1.8,1.8c-0.7-0.1-1.3-0.6-1.4-1.3c-0.1-0.5,1.3-1.7,1.9-1.7
		C176.4,801.5,177.1,802.2,177.1,802.8z"
      />
      <path
        class="st1059"
        d="M171,809c-3.6,0-7.2,0-10.8,0.1c-1.3,0-2.5,0.1-3.8,0.3c-0.4,0.1-0.6,0.3-0.7,0.6c-0.5,0.9-1.3,1.5-2.3,1.8
		c-1.7,0.5-3.4,1-5,1.5c-0.4,0.1-0.7,0-1.1-0.2c-0.2-0.6-0.3-1.3-0.5-1.9c0-0.3,0.2-0.5,0.4-0.7c1.7-0.9,2.9-2.3,4.3-3.5
		c0.2-0.2,0.3-0.4,0.6-0.3c-0.1,0.8,0.2,1.1,1,1c0.4,0,0.9-0.1,1.3-0.2c0.7-0.1,1.3-0.1,1.4,0.9c0,0.6,0.5,0.6,0.9,0.5
		c1.3-0.2,2.7-0.5,3.9-0.5c3.4,0.2,6.8-0.3,10.1-0.1C171,808.6,171,808.8,171,809z"
      />
      <path
        class="st1060"
        d="M152.2,806.7c-1.5,1.4-2.8,3-4.7,3.9c-0.2,0.1-0.3,0.3-0.5,0.5c-0.3,0-0.4-0.1-0.5-0.4
		c0.1-0.2,0.2-0.4,0.4-0.6c1.8-1.3,3.2-2.9,4.5-4.7c0.7-1,0.9-2,0.1-3.1c-0.6-0.8-0.9-1.8-2.1-2c-0.1-0.1-0.2-0.2-0.1-0.3
		c0.1-0.1,0.2-0.2,0.4-0.2c1.8,0,3.8,2.5,3.7,4c0,0.8-0.6,1.5-0.9,2.3C152.4,806.3,152.2,806.4,152.2,806.7z"
      />
      <path
        class="st1061"
        d="M192,844c0.1,0.5,0.3,1,0.2,1.6l0,0c-0.1,0.1-0.2,0.1-0.3,0c-0.3-0.3-0.4-0.7-0.6-1.1c-0.5-0.7-1.2-1-2-0.8
		c-0.8,0.3-1.1,0.9-1,1.8c0,0.3,0.1,0.5,0,0.8c0,0.2,0,0.4-0.1,0.5c-0.2,0.2-0.4,0-0.5-0.2c-0.3-0.5-0.5-1.1-0.5-1.7
		c0.1-0.2,0.1-0.3,0.2-0.4c0.1-0.8,0.7-1.1,1.4-1.4C190.2,842.8,191.2,842.9,192,844z"
      />
      <path
        class="st939"
        d="M189,843.1c-0.5,0.4-1.1,0.7-1.4,1.4C187.5,843.5,188.2,843.3,189,843.1z"
      />
      <path
        class="st1062"
        d="M76.6,854c0.8,0,1.5,0.1,2.3,0.2c0.5-0.2,0.9,0.1,1.2,0.5c2.5,3.8,5.5,7.2,7.6,11.2
		c0.3,0.6,0.5,1.2,0.1,1.8c-0.2,0.2-0.4,0.4-0.7,0.3c-0.5-2.2-1.8-3.9-3.1-5.6c-0.5-0.7-1-1.4-1.4-2.2c-0.8-1.4-1.8-2.5-2.5-3.5
		c0.7,1,1.5,2.5,2.3,3.9c0.2,0.4,0.5,0.9,0.1,1.3c-0.9,0.4-1.8,0.4-2.7,0.4c-1.1,0-2.1,0.4-3.1,0.2c-0.5-0.3-0.1-0.7,0-1
		c0.4-1.1,1.2-1.9,2-2.8c0.3-0.4,0.7-0.8-0.1-1.2c-0.5-0.3-0.8-0.8-1.1-1.3c-0.1-0.1-0.1-0.3-0.2-0.4
		C76.9,855.4,76.7,854.7,76.6,854z"
      />
      <path
        class="st1063"
        d="M77.2,856c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.4,0.1,0.8-0.1,1.2c-1.2,1.5-2.5,2.9-3.9,4.2
		c-0.4,0.4-1,0.7-1.7,0.8c-0.7-0.3-1.5-0.2-2.2-0.5c1.8-1,3.3-2.4,4.8-3.8c0.6-0.6,1.4-1.1,1.5-2.1C75.9,855.9,76.8,855.7,77.2,856z
		"
      />
      <path
        class="st1064"
        d="M69.5,861.9c0.8-0.2,1.6,0,2.3,0.5c0.7,0.2,0.9,0.8,1.1,1.4c0.3,1.4-0.1,2.1-1.5,2.8
		c-0.4,0-0.7-0.2-0.3-0.6c0.4-0.5,0.5-1.1,0.6-1.6c0.1-0.3,0.1-0.5,0-0.8c-0.1-0.2-0.1-0.3-0.1-0.5c-0.5-0.7-1.1-1-1.9-0.7
		c-0.3,0.2-0.7,0.5-1.1,0.7C68.7,862.5,69.1,862.2,69.5,861.9z"
      />
      <path
        class="st1065"
        d="M166.1,843.4c-0.5,0.1-0.7,0.5-1,0.8c-1.2,1.2-2.6,2-4.3,2.1c-0.5-0.6-0.1-1,0.1-1.5
		c0.6-0.4,0.6-1.1,0.8-1.6c0.1-0.1,0.2-0.1,0.3,0c0.1,0.6,0.4,0.2,0.6,0.1c0.9-0.6,1.8-1.2,2.8-1.5c0.6-0.2,1.2-0.4,1.9-0.6
		C167.1,841.9,166.6,842.6,166.1,843.4z"
      />
      <path
        class="st1066"
        d="M192,845.6c0.1,0,0.1,0,0.2,0c-0.9,0.6-1.9,1-2.7,1.9c-0.7-0.5-1-1.3-0.9-2.1c0.1-0.6,0.3-1.2,1-1.4
		c0.8-0.2,1.4,0.4,1.9,0.9C191.7,845.1,191.8,845.4,192,845.6z"
      />
      <path
        class="st1067"
        d="M192,845.6c-0.6-0.2-0.7-1-1.4-1.2c-0.4-0.1-0.8-0.4-1.1-0.2c-0.4,0.3-0.7,0.7-0.7,1.3c0,0.8,0.3,1.4,0.7,2
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3-0.4-0.6-0.8-0.9-1.2c-0.5-0.9-0.6-1.7,0.2-2.5c0.5-0.5,1.1-0.7,1.8-0.5
		C191.4,843.8,191.8,844.7,192,845.6z"
      />
      <path
        class="st895"
        d="M173.1,812.3c-0.5-0.4-0.9-1-1.1-1.7c0.2,0.2,0.4,0.3,0.7,0.5C172.7,811.5,173.2,811.8,173.1,812.3z"
      />
      <path
        class="st1068"
        d="M88.2,823.6c-0.1-0.1-0.3-0.3-0.4-0.4c0.8-0.4,1-1.3,1.5-1.9c0.9-0.4,1.8-0.9,2.5-1.7c1-0.8,0.8-2,1-3.1
		c0.9,0,1.8,0,2.7,0c0,0.6,0,1.2,0.4,1.8s0.1,1.2-0.2,1.8c-0.8,1.2-1.6,2.2-2.9,2.9c-1.2,0.6-2.3,1.2-3.5,1.8
		C88.7,824.5,88.7,823.8,88.2,823.6z"
      />
      <path
        class="st1069"
        d="M91.8,819.6c-0.5,1.1-1.3,1.6-2.5,1.7c-1.3-0.9-1.5-1.8-0.6-2.8c0.5-0.6,1.1-1.1,2-0.8
		C91.7,818,91.8,818.7,91.8,819.6z"
      />
      <path
        class="st1070"
        d="M141.6,795.8c-0.2,0.7-0.2,1.4-0.8,1.9c-0.4,1.2-1.2,2.1-2,3.1c-0.2,0.2-0.4,0.4-0.6,0.5
		c-1,0.5-1.9,1.1-2.3,2.2c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.1-0.6-0.2-0.9c-0.3,0.2-0.7,0.2-1.1,0.3c-0.5,0.1-1.1,0.1-1.6,0.1
		c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.3-0.6-0.3-0.9-0.4c-2.2-0.4-3.8-1.7-4.9-3.6c-0.3-0.8,0-1.3,0.6-1.8c0.5-0.4,1.1-0.8,1-1.7
		c-0.1-0.4,0.1-0.8,0.1-1.2s-0.1-0.7-0.3-0.9c0-0.5,0.2-0.1,0.4-0.1c0.2,0.4,0.4,0.8,0.6,1.2c0.2,0.3,0.1,0.8,0.4,1.1
		c0.2,1.1,1.1,1.5,2,1c2.9-1.5,5.1-3.8,7-6.5c0.4-0.6,0.2-1.4,0.6-2c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.2,0,0.3
		c0.6,0.6,0.4,1.2-0.1,1.8c-1.5,2-2.9,4-4.7,5.8c-0.1,0.1,0,0-0.1,0.1c-0.3,0.5-0.9,0.9-0.6,1.5c0.2,0.4,1.4,0.6,2.1,0.4
		c0.9-0.3,1.5-0.9,2.4-1.3c1-0.5,2-1,2.2-2.3c0-0.2,0.2-0.4,0.3-0.5s0.3-0.3,0.5-0.2s0.2,0.3,0.2,0.5
		C141.6,794.8,141.4,795.3,141.6,795.8z"
      />
      <path
        class="st1071"
        d="M129.1,791.7c-0.8-0.2-1.2-0.8-1.2-1.5c0.1-0.8,0.6-1.5,1.4-1.8c0.7-0.3,1.3-0.2,1.7,0.4
		c0.1,0.1,0.2,0.2,0.3,0.3C131.5,790.8,130.9,791.6,129.1,791.7z"
      />
      <path
        class="st1072"
        d="M77.1,841c-0.3,0.5-0.8,0.9-1.3,1.2c-1.4,0.3-2.1,0-2.3-1c-0.2-1,0.8-2.2,1.9-2.3
		C76.2,838.9,77,839.9,77.1,841z"
      />
      <path
        class="st1073"
        d="M66.6,874.8c-0.3,0.7-0.4,1.5-0.9,2.1c0.2-1.2-0.6-1.2-1.3-1c-0.7,0.2-1.3,0.2-2,0.2
		c-0.2-0.2-0.1-0.5,0-0.7c0.7-1.1,1.5-2.2,2.1-3.4c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0.2,0.4,0.7,0.8,0.1c0.2-0.3,0.5-0.6,0.8-0.1
		C66.7,872.7,67.2,873.8,66.6,874.8z"
      />
      <path
        class="st1074"
        d="M71.6,863.1c0.2,0.1,0.2,0.2,0.3,0.4c-0.3,0.8-0.9,1.3-1.5,1.8c-2,1.7-3.5,3.8-5.2,5.6
		c-0.8,0.8-1.4,1.9-2,2.9c-0.4,0.6-0.6,1.2-1.3,1.5c-0.6,0.1-1,0.7-1.5,1c-0.4,0.3-0.7,0.5-1.2,0.5c1.3-1.3,2.8-2.5,3.8-4.2
		c0.2-0.4,0.5-0.7,0.7-1.1c0.7-1.6,2.3-2.4,3.5-3.7c0.2-0.3,0.5-0.5,0.5-0.8c0.8-1.4,2.5-2.1,3.1-3.6
		C70.8,863.4,71.3,863.3,71.6,863.1z"
      />
      <path
        class="st1075"
        d="M61.8,875.4c1-1.5,1.8-3,2.9-4.4c0.7-0.9,1.6-1.7,2.4-2.6c1.5-1.8,3.1-3.3,4.8-4.9c0.2,0.2,0.1,0.5,0.1,0.8
		c-1.7,1.6-3.3,3.3-4.9,5c-0.4,0.5-0.9,0.8-1,1.5c-0.1,0.4-0.5,0.7-0.9,0.8c-0.9,1.5-1.8,2.9-2.7,4.4
		C62.2,875.9,61.8,875.8,61.8,875.4z"
      />
      <path
        class="st1076"
        d="M171,826.7c0.1,0,0.1,0,0.2,0c0.2,0.7,0.8,1,1.4,1.2c0.2,0.3,0.5,0.7,0.2,1.1c-1.2,2.3-3.1,3.9-5.1,5.4
		c-0.1,0.1-0.3,0.1-0.5,0.1c-0.7-0.3-0.4-1-0.6-1.4c0.3-0.3,0.7-0.3,1-0.5c0.6-0.4,1-0.9,0.9-1.6c0-0.5-0.3-0.8-0.8-1
		c-0.7-0.3-1.2,0.1-1.7,0.5c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.2-0.3,0.3-0.5,0.3s-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.5-0.3
		c0-0.1-0.1-0.2-0.1-0.3c0.3-0.8,1-1.3,1.6-1.8c1.2-1,2.7-1.6,4.3-1.9C170.2,827,170.7,827,171,826.7z"
      />
      <path
        class="st1077"
        d="M167.2,834.4c1.7-0.9,2.9-2.2,4.2-3.6c0.3-0.3,0.4-0.7,0.7-1c0.4-0.5,0.7-1.2,0.5-1.9c0.7,0.3,1.9,0,2,0.7
		c0.1,1.1-0.4,2.3-1.2,3.2c-1.5,1.5-3.1,3-5,4.1c-0.5,0.3-0.5,0.7-0.5,1.1c-0.1,0-0.1,0-0.2,0C167.3,836.2,166.7,835.4,167.2,834.4z
		"
      />
      <path
        class="st1078"
        d="M74.3,845.4c0,0.3,0.1,0.6,0.2,0.8c0.6,1.2,0.9,1.4,2.2,1c1.7-0.5,3.3-1.1,5-1.3c0.4,0,0.6-0.3,0.8-0.6
		c0.2-0.3,0.3-0.6,0.8-0.6c0.3,0.4,0.1,0.8,0,1.2c-0.1,0.6-0.3,1.1-0.2,1.7c0.1,1,1,1,1.5,1.3c0.1,0.1,0.1,0.3,0.2,0.4
		c0.1,0.4-0.1,0.8-0.2,1.2c-0.4,0.5-0.6,1.3-1.4,1.3c-0.1-0.2,0.2-0.6-0.1-0.8c-0.3-0.2-0.6,0.1-0.7,0.3c-0.8,1.3-2.1,0.9-3.2,0.9
		c-1.2,0-2.4-0.1-3.5-0.6c-0.8-0.3-1.4-0.8-1.9-1.5c-0.4-0.7-1.1-1.2-1.3-2c1.3-0.4,1.7-1.5,1.2-3c-0.1-0.3-0.2-0.7-0.3-1
		C74,844.5,73.9,845.2,74.3,845.4z"
      />
      <path
        class="st1079"
        d="M99.7,820c0.2,0.2,0.8,0,0.7,0.6c-0.1,0.5-0.6,0.8-1.1,0.9c-0.9,0.3-1.2,0.7-0.9,1.5c0,0.1,0.2,0.3,0.2,0.3
		c-0.2,0.5-0.3,1.1,0.2,1.2c0.7,0.2,1.4,0.2,2.2,0.1c1.5-0.3,1.7-1.7,2.7-2.4c0.3-0.2,0.2-0.6,0.2-1c0-1.1,0.8-2.2,1.8-2.6
		c0.5-0.2,0.7,0.3,1.1,0.3c0.1,0.1,0.1,0.3,0,0.4c-0.8,0.9-1.2,2-1.9,3c-0.8,0.3-0.3,0.9-0.3,1.3c0,0.5-0.3,0.8-0.6,1.1
		c-0.4,0.4-0.7,0.8-0.7,1.4c0,0.4,0,0.8-0.2,1.1c-1-0.3-0.1-0.9-0.3-1.3c-0.3,1.3-3.6,2.7-5.8,2.4c-0.9-2-0.8-4,0.2-6
		c0.4-0.5,0.6-1.2,1.3-1.5C98.9,820.5,99.2,820.2,99.7,820z"
      />
      <path
        class="st1080"
        d="M98.5,820.8c-0.3,0.5-0.6,1-0.9,1.5c-1.7,2-4.1,2.8-6.4,3.8c-1.2,0.5-2.2-0.3-2-1.5
		c1.2-0.9,2.6-1.3,3.8-2.1c1.2-0.7,2-1.8,2.7-3c0.3-0.4,0.4-1-0.1-1.4c-0.6-0.6-0.5-1.1,0-1.6c1-0.4,1.2,0.4,1.2,1
		c0.1,1.1,0.3,2.1,1.4,2.6C98.6,820.3,98.1,820.7,98.5,820.8z"
      />
      <path
        class="st1081"
        d="M170.9,806c0,0.4,0.1,0.9-0.2,1.2c-0.9-0.2-1.9-0.2-2.8-0.2c-2.5,0-5.1,0-7.6-0.2c-1-0.1-2-0.2-2.6-1.2
		c2.1,0.2,4.2,0.4,6.3,0.6c1.6,0.1,3.2,0,4.9,0.1c0.5,0,1-0.1,1.5,0.1C170.9,806.6,170.7,806.1,170.9,806z"
      />
      <path
        class="st1082"
        d="M157.8,805.6c1.3,0.8,2.6,1,4.1,1.1c2.6,0.1,5.2,0.2,7.9,0.2c0.4,0,0.7,0.2,0.9,0.4c-0.6,0.5-1.1,0-1.7,0
		c-2,0.1-4.1,0-6.1,0c-1.2,0-2.4-0.1-3.6-0.2c-0.9-0.1-1.7-0.7-2.3-1.4C157.3,805.7,157.6,805.7,157.8,805.6z"
      />
      <path
        class="st1083"
        d="M71.8,816.2c-0.2-0.1-0.4-0.2-0.7-0.2c-1.3,0-2,0.7-2.1,1.9c-0.4-0.9,0-1.8,1-2.2c0.3-0.1,0.6-0.2,0.9-0.3
		c0.3,0.1,0.7,0.2,1,0.4C72.3,816,72.2,816.2,71.8,816.2z"
      />
      <path
        class="st1084"
        d="M128.1,794.2c0.3,0.4,0.1,0.8,0.2,1.2c-1.3-0.7-2.8-0.5-4.2-0.4c-2.7,0.2-5.4,0.4-8.1,0.3
		c-0.7,0-1.5,0-2.1-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c1.5,0.2,2.9,0.5,4.4,0.4c2.6-0.3,5.2-0.2,7.7-0.6
		C126.6,793.9,127.3,794,128.1,794.2z"
      />
      <path
        class="st1085"
        d="M89.1,824.6c0.4,1.5,1,1.7,2.3,1.2c1.5-0.6,2.8-1.5,4.3-2.2c0.7-0.3,1.1-1,1.8-1.2c-1.2,1.9-1,4-0.4,6
		c0.5,0.5,0.7,1.2,0.9,1.9c0,0.1,0,0.2-0.1,0.3c-0.6,0.7-1.3,1.1-2.2,1.2c-0.4,0.1-0.9,0.1-1.3,0.3c-1.7,0.7-3.1-0.1-4.3-1
		c-0.9-0.7-1.6-1.6-2.3-2.5c-0.3-0.4-0.6-0.8-0.2-1.3c1.2-0.7,0.9-1.8,0.8-2.9c-0.1-0.2,0-0.4-0.1-0.6c0-0.1,0-0.1,0-0.2
		C89,823.5,88.9,824.2,89.1,824.6z"
      />
      <path
        class="st1086"
        d="M88.3,823.8c0.2,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.2,0.4-0.2,0.4c-0.5-0.5-1.1-0.2-1.7-0.2
		c-1.6,0.3-3.2,0.7-4.8,0.6c-0.1,0-0.1-0.1-0.1-0.1l0.1-0.1c0.9-0.5,2-0.7,3-0.8C86,823.9,87.2,824.1,88.3,823.8z"
      />
      <path
        class="st944"
        d="M81.8,824.8c0,0.1,0,0.2,0,0.2c-0.4,0.2-0.8,0.9-1.2,0C81,824.9,81.4,824.9,81.8,824.8z"
      />
      <path
        class="st1087"
        d="M139.1,788.2c-0.1-0.1-0.1-0.1-0.2-0.2c0.8-1.4,1.6-2.8,3-3.7c0.1,0,0-0.3-0.1-0.5c0.4,0.1,0.6,0.3,0.6,0.8
		C141.5,786,140.4,787.2,139.1,788.2z"
      />
      <path
        class="st1088"
        d="M143.9,800.2c0.6-0.2,1.2,0.2,1.9,0.2c0.8,0.1,1.6,0.1,2.5,0c0.5,1,0.4,2-0.2,3c-1.4,2.5-3.6,4.1-6.1,5.2
		c-0.9,0.4-1.6,0.8-2,1.6c-0.9-0.2-0.8-1-0.8-1.7c0-0.4,0.1-0.8,0-1.2c-0.1-0.9-0.1-1.7,0.9-2.1c0.1,0,0.2-0.1,0.2-0.2
		c0.4-1.6,1.9-2.1,3-3.2c-0.4,0.1-0.7,0.3-0.9,0c-0.1-0.7,0.5-0.9,0.7-1.3C143.4,800.4,143.6,800.3,143.9,800.2z"
      />
      <path
        class="st1089"
        d="M149.3,800c0.1,0.1,0.1,0.2,0.2,0.2c2.3,1.5,2.6,3.5,0.9,5.7c-1.3,1.6-2.8,3.1-4.5,4.3
		c-0.4,0-0.8-0.2-1.2-0.2c-0.6-0.1-1,0-1.4,0.5c-0.1,0.3-0.3,0.6-0.5,1.1c0.7-1,1.4-1.5,2.4-1.3c0.2,0.1,0.4,0.2,0.5,0.4
		c0.1,0.7-0.2,1.4-0.7,1.9c-0.9,0.9-1.8,1.9-2.8,2.8c-0.4,0.3-0.8,0.8-1.5,0.5c-0.2-0.1-0.4-0.3-0.4-0.6c1.1-1.1,1.2-2.3,0.3-3.7
		c0.2-1.5,1.4-2,2.5-2.5c2.3-1,4.3-2.4,5.7-4.5c0.8-1.1,0.9-2.2,0.2-3.4c-0.2-0.3-0.4-0.6-0.1-1C148.9,800,149.1,800,149.3,800z"
      />
      <path
        class="st1090"
        d="M148.9,800.2c0,0.2,0,0.4,0.1,0.5c1.1,2.2,1,3.1-0.6,5c-1.5,1.7-3.3,2.8-5.3,3.7c-1,0.4-2.1,1-2.3,2.3
		c-0.7-0.2-0.9-0.8-0.8-1.5c0.1-0.9,0.7-1.3,1.5-1.7c2.9-1.2,5.2-3,6.8-5.8c0.5-0.8-0.1-1.6,0.1-2.4
		C148.5,800.3,148.7,800.3,148.9,800.2z"
      />
      <path
        class="st1091"
        d="M113.9,794.8c3,0.5,6,0.1,9.1,0c1.4,0,2.7-0.2,4.1-0.2c0.6,0,1,0.2,1.2,0.8c0.2,0.8-0.3,1.3-0.9,1.6
		c-0.7,0.4-0.9,1-0.9,1.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-1.7-1.6-1.7-3-1.6c-3.1,0.2-6.3,0.3-9.4,0.4
		C113.8,796.5,113.8,795.6,113.9,794.8z"
      />
      <path
        class="st1092"
        d="M164.9,913c-0.3,0.2-0.7,0.4-1,0.6c-2,1.2-3.9,2.5-5.9,3.7c-0.3,0.2-0.6,0.4-1,0.5c-0.5,0-1.1,0.4-1.6,0
		c-1.8-1.1-3.6-2.1-5.4-3.1c-0.2-0.1-0.6-0.2-0.5-0.4c0.1-0.3,0.4-0.3,0.7-0.2c0.2,0,0.4,0.1,0.6-0.1c0.1-0.2-0.1-0.3-0.2-0.4
		c-0.8-1.8-2.4-2.5-4-3.2c-1.9-0.8-3.4-2.2-5.2-3.1c-0.4-0.2-0.7-0.4-1.1-0.4c-0.3-0.9,0.2-1.7,0.3-2.5c0-0.2,0.1-0.3,0.2-0.4
		c0.4-0.2,0.6,0.1,0.7,0.4c0.5,0.9,1.3,1.4,2.2,1.9c0.8,0.4,1.5,0.5,2.2-0.2c0.2-0.1,0.5-0.2,0.8-0.1c0.3,0.4,0,0.6-0.2,0.8
		c-0.7,0.6-0.4,1,0.2,1.3c1,0.6,2.2,0.8,3.3,1.2c1.6,0.5,3.2,0.9,4.8,1.2c1.7,0.2,3.2,0.9,4.9,1.1c2.1,0.3,4.2,0.4,6.3-0.1
		c0.7-0.2,0.7-0.2,0.5,0.4c0,0.1,0,0.1,0,0.2C166,912.4,165.3,912.4,164.9,913z"
      />
      <path
        class="st1093"
        d="M118.7,924.4c-0.1,0-0.3,0-0.4-0.1c-0.5-0.7-1.4-1.1-1.6-2.1c0-0.1-0.2-0.1-0.3-0.1
		c-0.5,0.3-1.1,0.5-1.7,0.2c-0.3-0.3-0.9-0.7-0.9-1c0.1-1.2,0-2.4,0.6-3.4c1.1-2.1,2.8-3.5,4.9-4.5c2.3-1,4.7-0.9,7.1-1.4
		c0.4-0.1,0.7,0.3,0.9,0.5c0.6,0.5,1.3,0.5,1.9,0.9c1.2,0.5,1.2,0.5,0.1,1.4c-0.3,0.5-0.8,0.7-1.3,1.1c-0.3,0.2-0.6,0.4-1,0.3
		c-0.1-0.1-0.2-0.2-0.2-0.4c0.1-0.3,0.4-0.4,0.6-0.6c-2.2-0.5-4.4-0.9-6.6-0.2c-1.7,0.5-3.1,1.4-4.2,2.7c-0.8,1-0.8,1.9-0.2,2.8
		c0.4,0.7,0.9,1,1.7,0.5c0.2-0.1,0.5-0.3,0.7,0c0.2,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.4c2.1,0.7,4.3,0.6,6.4,0.5
		c2.8-0.2,5.6-0.9,8.5-0.3c0.3,0.1,0.7,0.1,1,0.2c3.2,1.5,6.6,1.9,10.1,1.9c0.4,0,0.7,0.2,0.9,0.6c-0.8,0.6-1.6,1-2.5,1.5
		c-0.4-0.5-0.8-0.8-1.4-0.3c-0.1,0-0.2,0-0.3,0c-1.2-0.8-2.7-0.5-4-0.7c-2.4-0.3-4.7-1.4-7.3-1.1c-0.9,0.1-2,0-2.9,0
		c-1.8,0.1-3.6,0.3-5.4,0.3C120.5,924.3,119.6,924,118.7,924.4z"
      />
      <path
        class="st1094"
        d="M122.8,909.8c-0.3-1.4-0.6-1.5-1.9-1.1c-0.8-0.4-1.1-0.9-0.4-1.8c0.4-0.6,0.6-1.6-0.4-2.2
		c-0.4-0.2-0.5-0.6-0.3-1c0.6-0.9-0.2-1.1-0.7-1.3s-1.1-0.2-1.6-0.3c-0.5,0-0.9-0.3-1-0.8s0.5-0.4,0.8-0.5c0.3,0,0.4-0.2,0.5-0.5
		c0-0.3,0.3-0.4,0.5-0.5c1.5-0.4,2.7-1.2,3.8-2.3c0.2-0.1,0.3-0.2,0.6-0.2c1,0.4,1.8,1.1,2.7,1.6c0.5,0.3,0.7,0.8,0.4,1.3
		c-0.2,0.4-0.4,0.7-0.2,1.2c0,0.5-0.2,0.9-0.3,1.3c-0.3,0.9-0.3,1.8,0.1,2.7c0.5,1.2,0.4,1.2-0.6,1.8c-0.2,0.1-0.3,0.2-0.3,0.4
		c0,0.1,0.3,0.3-0.1,0.3c-0.6,0-0.5,0.1-0.2,0.5c0.3,0.3,0.9,0.7,0.5,1.2C123.9,910.7,123.4,910.2,122.8,909.8z"
      />
      <path
        class="st1095"
        d="M127.2,916c0.3-0.1,0.6-0.3,0.8-0.4c0.6-0.4,1.2-0.2,1.8,0c1.9,0.6,3.9,1.1,5.9,0.7c0.1,0,0.1,0,0.2,0
		c0.8,0.2,1.6,0.4,2,1.2c-0.4,0.5-1.1,0.4-1.6,0.8c-0.9,0.7-1,1.2-0.4,2.1c0.7,0.9,1.8,1.3,2.9,1.6c2,0.6,4,0.9,6,0.5
		c0.7-0.1,1.4,0.1,2,0.2c0.1,0.4-0.1,0.7-0.4,0.9c-0.7,0.5-1.3,0.6-2.1,0.4c-1-0.3-2-0.2-3-0.3c-2.5-0.2-4.8-1-7-2.2
		c-1.2-0.7-2.4-0.8-3.8-0.5c-2.9,0.6-5.8,1.2-8.7,1.2c-0.8,0-1.6-0.1-2.2-0.7c-0.4-0.4-0.4-0.7,0.1-0.9c1.3-0.3,2.7,0.2,4,0.1
		c1.9-0.1,3.6-0.6,5.3-1.2c1.1-0.4,1.1-1,0.2-1.7c-0.4-0.3-0.9-0.6-1.4-0.6c-0.5,0-0.8-0.3-0.9-0.7C126.9,916.1,127,916,127.2,916z"
      />
      <path
        class="st1096"
        d="M137.9,917.5c-0.6-0.4-1.3-0.8-1.9-1.2c0.4-0.5-0.4-0.4-0.4-0.7c1-0.2,2-0.3,3-0.4c2.6-0.1,5.2-0.4,7.8-0.2
		c1.3,0.1,2.5,0.2,3.8,0.2c1.8,0.7,3.4,1.8,5,2.7c0.3,0.9-0.4,1.1-1,1.4c-1.4,0.5-2.7,0.3-4.1-0.3s-2.8-1.3-4.4-1.5
		c-2.2-0.3-4.3-0.4-6.5,0c-0.4,0.1,0.2,0.3,0,0.5C138.5,918.3,138.2,917.9,137.9,917.5z"
      />
      <path
        class="st1097"
        d="M140.8,904.1c-0.2,0.8-0.4,1.7-0.6,2.5c-0.1,0.2-0.3,0.4-0.4,0.6c-1.8,0.3-3.6,1-5.4,1.3
		c-1.8,0.3-2.8-0.5-2.8-2.4c0-0.2,0-0.4,0-0.6c0.2-1.5-0.2-2.8-1.7-3.5c-0.2-0.1-0.4-0.2-0.4-0.5c1.3-0.5,2.5-0.1,3.2,1
		c0.3,0.5,0.5,1.1,0.8,1.6c0.4,0.7,0.9,1,1.7,0.8c1.4-0.3,2.6-1,3.9-1.7C140.1,902.8,140.5,903.1,140.8,904.1z"
      />
      <path
        class="st1098"
        d="M150.1,915.2c-0.2,0.4-0.5,0.5-0.9,0.4c-1.6-0.3-3.2-0.4-4.8-0.3c-1.9,0.1-3.8,0.2-5.7,0.2
		c-0.5,0-0.9,0.3-1.4,0.4c-0.7,0.1-1.2-0.2-1.9-0.2c-0.5-0.2-0.9-0.4-1.4-0.7c-0.2-0.1-0.5-0.2-0.4-0.5s0.4-0.2,0.6-0.3
		c3.2-0.3,6.4-0.5,9.5-1c0.5-0.1,0.9-0.2,0.9-1c0.4,0.6,0.5,1.2,1.3,0.9c0.4-0.2,0.6,0.4,1,0.5C148.2,913.9,149.2,914.6,150.1,915.2
		z"
      />
      <path
        class="st1099"
        d="M131.8,905.4c0,0.4,0,0.8,0,1.1c0,1.1,0.7,1.8,1.8,1.8c1.2,0,2.4-0.5,3.6-0.8c0.8-0.3,1.6-0.5,2.5-0.2
		c-0.1,0.5-0.6,0.8-0.8,1.3c0.1,0.2-0.1,0.3-0.3,0.4c-1.8,1.7-3.9,2.7-6.4,2.7c-0.3-0.7-0.8-1.3-1.4-1.7c-0.3-0.2-0.6-0.5-0.6-0.8
		C130.4,907.7,130.8,906.5,131.8,905.4z"
      />
      <path
        class="st1100"
        d="M131.8,905.4c-0.4,0.8-0.8,1.7-1.1,2.5c-0.4,0.9-0.3,1.7,0.7,2.1c0.7,0.3,1,0.8,0.9,1.6
		c-1.5-0.2-2.9-0.7-4.1-1.5c-0.5-0.3-0.8-0.7-0.5-1.4c0.2-0.6,0.7-1,1.1-1.5c1-1.4,1.4-2.8,1-4.5c-0.1-0.3-0.2-0.7,0.2-0.9
		c0.8,0.2,1.8,1.4,1.9,2.3C131.8,904.6,131.8,905,131.8,905.4z"
      />
      <path
        class="st1101"
        d="M124.3,907.9c-0.2-0.2-0.1-0.3,0.1-0.4c1.2-0.3,1-1,0.6-1.8c-0.5-1-0.3-2,0-3c0.1-0.4,0.2-0.7,0.3-1.1
		c0.2-1.7,0.7-1.9,2.3-1c0.4,0.2,0.8,0.4,1,0.8c0.3,1.7,0.1,3.3-1.3,4.5c-0.4,0.3-0.7,0.8-1,1.2c-0.3,0.3-0.6,0.5-1,0.4
		C124.9,907.5,124.7,907.8,124.3,907.9z"
      />
      <path
        class="st1102"
        d="M125.3,907.5c2.1-1.5,3.5-3.3,3.1-6.1c0.3-0.2,0.5,0,0.8,0c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.1,0.4,0.2
		c0,0.2-0.2,0.4-0.1,0.5c0.9,2.7-0.3,4.7-2.2,6.4C126.6,908.9,126.1,908,125.3,907.5z"
      />
      <path
        class="st1096"
        d="M135.8,916.2c-0.3,0.8-1,0.5-1.4,0.5c-1.6,0-3.3-0.3-4.8-0.9c-0.5-0.2-1-0.2-1.5-0.2c0.4-0.3,0.8-0.7,1.3-1
		c0.9-0.8,1.9-0.7,2.9-0.3C133.4,914.9,134.6,915.5,135.8,916.2z"
      />
      <path
        class="st1103"
        d="M154.1,919.1c0.5-0.3,1-0.5,1-1.2c0.1-0.1,0.2-0.2,0.2-0.2c0.6,0,1.1-0.3,1.7-0.2c0.1,0,0.2,0.1,0.3,0.2
		c0.1,0.2,0.1,0.5,0.1,0.8c0,0.1,0,0.2,0,0.2c-1.2,0.5-2.5,0.6-3.7,0.9c-0.1-0.1-0.1-0.2-0.1-0.3C153.7,919.1,153.8,919,154.1,919.1
		z"
      />
      <path
        class="st1104"
        d="M132.2,914.3c-1,0.2-2-0.3-2.9,0.3c-0.1-0.5,0.5-0.4,0.7-0.8c-0.1-0.3-0.6-0.3-0.7-0.6c0.6,0.1,1.2,0.3,2,0
		C131.9,913.1,132.5,913.4,132.2,914.3z"
      />
      <path
        class="st1105"
        d="M114.7,922.3c0.5-0.2,1.1-0.3,1.7-0.2c0.3,0.4,0.4,0.9-0.1,1.1c-0.5,0.2-1.2,0.3-1.7-0.1
		C114.2,922.9,114.6,922.6,114.7,922.3z"
      />
      <path
        class="st1106"
        d="M138.6,909c0.1-0.1,0.2-0.3,0.3-0.4c1.4,0.8,2.8,1.4,4,2.4C141.4,910.5,140.1,909.4,138.6,909z"
      />
      <path
        class="st1107"
        d="M157.4,918.9c1.1,0.6,2.1,1.3,3.2,1.8c0.6,0.3,0.3,0.8,0.6,1.2c-3.2,2-6.5,4-9.8,5.8
		c-0.8-0.1-1.5-0.3-2.2-0.4c-1.6-0.2-3.3,0-4.8-0.7c-0.3-0.2-0.7,0.3-1-0.2c1-0.4,1.7-1.3,2.8-1.5c0.8-0.4,1.6-0.4,2.5-0.3
		c2,0.1,3.9-0.3,5.6-1.4c0.8-0.5,1.3-1.3,1.6-2.2C156.2,920,156.4,919.1,157.4,918.9z"
      />
      <path
        class="st1108"
        d="M119.9,920.6c-0.3,0.1-0.7,0-0.3,0.5c0.7,0.7,1.6,0.7,2.4,0.7c2.6,0,5.2-0.5,7.8-1c1.1-0.2,2.2-0.6,3.4-0.2
		c0.3,0.1,0.6,0.1,0.9,0.3c2.8,1.9,6,2.6,9.3,2.6c0.5,0,1,0,1.4,0.2c0.7,0.3,1.1-0.3,1.6-0.4c0.2,0.1,0.5,0.2,0.6,0.4
		c0,0.1,0,0.2-0.1,0.2c-0.8,0-1.2,0.6-1.9,0.8c-0.9-0.6-1.9-0.3-2.8-0.4c-2.7-0.3-5.4-0.7-7.9-1.8c-1.4-0.6-3-0.7-4.6-0.5
		c-3.1,0.3-6.1,0.7-9.2,0.6c-1,0-1.9-0.4-3-0.7c0.4-0.3,0.8-0.7,1.3-1c-0.1,0-0.2-0.1-0.2-0.1c-0.6,0.1-1.1,1.2-1.8,0.5
		c-1.4-1.5-1.5-2.7-0.5-3.8c1.7-2,3.9-3.1,6.6-3.3c1.2-0.1,2.4-0.1,3.6,0.4c0.2,0.1,0.3,0,0.5,0.1c0.4,0.1,1-0.5,1.1,0.2
		c0.1,0.6-0.7,0.6-1,0.9c0,0-0.1,0-0.1,0.1c-0.3,0.4-0.8,0.7-1.2,0.8c-0.8,0-1.5-0.3-2.3-0.4c-1.6-0.2-3,0.4-4.3,1.2
		c-0.8,0.5-1.1,1.4-0.8,1.9c0.3,0.5,0.7,0.8,1.3,0.5c0.2-0.1,0.5-0.3,0.7-0.4c0.4-0.2,0.9-0.5,1.1,0c0.2,0.4-0.4,0.7-0.8,0.8
		C120.5,920.3,120.2,920.5,119.9,920.6z"
      />
      <path
        class="st1109"
        d="M147,924c0-0.1,0-0.1,0-0.2c0.1-0.2,0.4-0.3,0.6-0.3c0.7-0.1,1-0.5,1.1-1.1c0.1-0.4,0.4-0.6,0.7-0.8
		c0.9-0.4,1.7-1.1,2.7-1.3c0.6,0,1-0.5,1.6-0.6c1.1-0.7,2.4-0.8,3.7-0.8c0,0.1,0,0.1,0,0.2c-0.6,0.5-1.1,1.1-1.2,1.7
		c-0.7,2.7-2.9,3.4-5.2,3.9c-1.1,0.2-2.2,0.5-3.4,0c-0.6-0.2-1,0.5-1.5,0.2C146.4,924.5,146.7,924.2,147,924z"
      />
      <path
        class="st1110"
        d="M122.7,897.7c-1.4,1.3-2.9,2.4-4.8,2.9c-2.1,0.2-4,0.2-5.9-1c-0.4-0.2-0.8-0.5-1-0.9
		c-0.4-0.2-0.3-0.4,0-0.7s0.7-0.5,1.1-0.5c0.2,0,0.5,0,0.7-0.1c1.8-1,3.8-1,5.7-1.2c0.9-0.1,1.7-0.6,2.4-1.2
		c0.8-0.7,1.8-1.3,2.2-2.4c0.1-0.3,0.4-0.5,0.8-0.4c0.3,0.1,0.5,0.4,0.5,0.7c0.1,0.3,0.1,0.5,0.1,0.8c0,0.4-0.1,0.8-0.1,1.2
		C124,896,123.6,897,122.7,897.7z"
      />
      <path
        class="st1111"
        d="M110.5,901.7c-0.4-1.2-0.8-1.3-1.8-0.5c-1.5,1.2-3.2,1.9-5.1,2.2c-0.5,0.1-0.9,0.1-1.2-0.3
		c-0.3-0.5,0-1,0.3-1.3c0.8-0.8,1.6-1.6,2.3-2.3c1.2-1.2,2.4-2.5,3.2-4c0.2-0.3,0.2-0.5,0.4-0.8c0.1-0.1,0.2-0.1,0.3-0.1
		c0.6,0.4,0.9,1.1,1.2,1.8c0.5,0.9,0.6,1.9,0.5,2.9C110.7,900,110.6,900.8,110.5,901.7z"
      />
      <path
        class="st1112"
        d="M110.7,899.1c-0.2-1-0.4-2-0.8-2.9c0.5-0.9,1.4-1.4,2.2-2c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0.3,0.5,0.8,0.7,1.2
		c0.2,1.5-0.1,2-1.4,2.4c-0.3,0.3-1.2,0.2-1,1C110.8,898.8,110.8,899,110.7,899.1z"
      />
      <path
        class="st1113"
        d="M106.6,892.7c-0.1-0.2-0.3-0.3-0.5-0.2c-1.3-1-2.8-0.9-4.3-1c-0.4,0-0.8,0-1.1,0c0.2-0.6,0.7-0.6,1.2-0.7
		c0.3,0,0.5-0.1,0.4-0.5c-0.2-0.5-0.4-1.1-0.6-1.7c-0.1-0.5-0.1-0.9-0.1-1.4c0.2-1.2,0.2-1.3,1.3-0.6c1.2,0.8,2.7,1.3,3.9,2.2
		c0.3,0.2,0.5,0.1,0.6-0.2c0.6-1.3,1.4-2.5,2.4-3.6c0.2-0.2,0.2-0.5,0.2-0.8c0.2-1.5,0.8-1.9,2.2-1.2c0.5,0.3,1.1,0.3,1.6,0.8
		c-0.2,1.3,0.8,1.7,1.7,2.2c0.7,1.2,0.7,1.8-0.4,2.9c-1,1-1.8,2.2-2.8,3.2c-0.3,0.3-0.6,0.5-1,0.5c-1.2,0-2,0.6-2.6,1.6
		c-0.4-0.4-0.8-0.8-1.2-1.2C107.3,892.9,107,892.6,106.6,892.7z"
      />
      <path
        class="st1114"
        d="M95.3,896c-0.4-0.6-0.6,0.1-0.6,0.2c-0.4,0.7-0.8,0.7-1.7,0.6c-3-0.5-6.1-1.2-9,0.8
		c-2.1,1.4-4.5,2.1-7.1,2.6c-1.1,0.2-2.2,0.3-3.3,0.1c0.2-0.3,0.6-0.4,0.9-0.4c1.6-0.2,3-0.7,4.5-1.2c1.3-0.4,2.5-1.1,3.5-2
		c1-1,1-1.7,0.1-2.9c0.1-0.6,0.6-0.7,1-0.8c0.3-0.1,0.6,0,0.9,0.1c1.1,0.3,2.1,0.1,3-0.5c1.5-1.1,3.2-2,4.6-3.3
		c0.4-0.4,0.9-0.8,1.6-1c0.5-0.1,1-0.2,1.3,0.5c0,0.6-0.5,0.8-0.9,1.1c-1.1,0.8-2.1,1.6-3.1,2.4c-0.8,0.7-0.7,1.7,0.3,2.1
		c1,0.5,2.2,0.6,3.3,0.8c0.5,0.1,0.7-0.3,1-0.6c0.2-0.1,0.3-0.2,0.6-0.1C95.9,894.9,95.6,895.5,95.3,896z"
      />
      <path
        class="st1115"
        d="M102.2,891c-0.5,0-1,0.2-1.4,0.5c-0.4,0.1-0.6,0.4-1,0.4c-0.4-0.3-0.6-0.7-0.7-1.2c0-0.5-0.2-0.8-0.4-1.3
		c-0.1-0.5-0.4-1-0.6-1.5c-0.1-0.4-0.3-0.8-0.5-1.1c-0.2-0.8-0.7-1.2-1.6-1.2c-0.1-0.1-0.1-0.2,0-0.3c0.7-1.2,0.7-1.2-0.3-2.3
		c-0.1-0.4-0.3-0.8-0.4-1.3c0-0.3-0.1-0.7-0.1-1c-0.1-0.5-0.1-0.9,0.4-1.2c0.6-0.3,1.1-0.3,1.6,0.2c0.7,0.7,1.3,1.4,1.5,2.3
		c0.3,1.1,1.3,1.9,1.1,3.2c1,1.6,1.4,3.5,2.4,5.1C102.3,890.5,102.5,890.7,102.2,891z"
      />
      <path
        class="st1116"
        d="M98.9,889.4c0.2,0.4,0.5,0.8,0.4,1.2c-1.2-0.4-2.1,0.3-3,0.9c-0.5,0.3-0.4,0.8-0.1,1.2
		c0.2,0.4,0.5,0.7,0.5,1.2c-0.1,0.2-0.2,0.4-0.5,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.8,0-1.6-0.3-2.2-0.7c-1-0.5-1.2-1.3-0.5-2.1
		c1.1-1.2,2.3-2.2,3.9-2.5c0.2,0,0.4-0.1,0.6-0.2C98.2,888.4,98.6,888.6,98.9,889.4z"
      />
      <path
        class="st1117"
        d="M96.6,894C96.6,893.9,96.6,893.9,96.6,894c-0.4-0.8-1.4-1.4-0.8-2.3c0.6-0.9,1.8-1.1,2.7-1.5
		c0.5-0.2,0.5,0.4,0.8,0.5c0.1,0.5,0.5,0.8,0.5,1.3C98.8,892.6,97.6,893.1,96.6,894z"
      />
      <path
        class="st1118"
        d="M98.9,897.2c-0.6-0.2-1.3-0.1-1.4-1c0-0.6,1.3-1.7,1.9-1.7c0.8,0,1,0.6,1.1,1.3
		C100.5,896.5,99.7,897.2,98.9,897.2z"
      />
      <path
        class="st1119"
        d="M82.7,893.8c1,0.5,1.2,1.1,0.8,2.1c-0.2,0.6-0.7,1-1.2,1.4c-2.1,1.6-4.6,2.3-7.1,2.8
		c-0.5,0.1-1,0.2-1.5,0.2c-0.2,0-0.4,0-0.6,0c-0.3,0.3-0.7,0.3-1,0.3c-0.9-0.1-1.8,0.1-2.8-0.1c-0.6,0-1.2,0-1.7-0.4
		c-0.1-0.2-0.2-0.3-0.4-0.5c0.2-0.2,0.4-0.2,0.7-0.1c2.3,0.8,4.7,0.5,7-0.1c2.2-0.2,4.1-1,5.8-2.3c0.9-0.7,0.9-1.6,0.7-2.6
		c0.2-0.5,0.6-0.6,1-0.7C82.6,893.7,82.6,893.7,82.7,893.8z"
      />
      <path
        class="st882"
        d="M73.9,901.2c0.1,0.2,0.1,0.3-0.2,0.4c-1.7,0.5-3.3,0-4.8-0.8C70.6,900.7,72.2,901.3,73.9,901.2z"
      />
      <path
        class="st1011"
        d="M69.3,900.4c1.3-0.1,2.5,0.2,3.8-0.2c0,0.1,0.5,0.3,0,0.4C71.8,900.6,70.6,900.8,69.3,900.4z"
      />
      <path
        class="st1120"
        d="M108.8,894.3c-0.1-1.2,1-1.8,1.6-1.7c1.4,0.1,2.1-0.9,2.8-1.7c0.7-0.7,1.4-1.5,2-2.3
		c0.6-0.7,0.9-1.5,0.3-2.4c0.3-0.3,0.7-0.2,1.1-0.2c1.6,0.5,2.1,1.9,1.1,3.3c-1,1.5-2.4,2.5-3.8,3.6c-0.5,0.4-1.2,0.6-1.8,0.5
		c-0.4-0.1-0.8-0.3-1.2-0.3c-0.5,0-0.8,0.1-0.9,0.6c-0.3,0.4-0.5,0.8-1.1,0.8c-0.1,0-0.2,0-0.2,0
		C108.7,894.5,108.7,894.4,108.8,894.3z"
      />
      <path
        class="st1121"
        d="M100.6,902.5c-0.8,0.2-1.4,0.6-2.2,0.7c-0.4,0-0.6,0.3-0.7,0.7c-0.1,0.3-0.2,0.7-0.6,0.5
		c0.1-1.3,1.3-1.6,2.1-2.2c1.9-1.6,4-2.9,5.6-4.9c1-1.3,2-2.6,2.2-4.3c0.5,0.3,0.7,0.9,1.1,1.4c0.2,0.2,0.1,0.4-0.1,0.6
		C106,898,103.4,900.3,100.6,902.5C100.6,902.4,100.6,902.5,100.6,902.5z"
      />
      <path
        class="st1122"
        d="M73.3,884.1c0.1,0,0.1-0.1,0.2-0.1c0.2,0.1,0.3,0.4,0.4,0.6c0.4,0.8,1.1,0.7,1.7,0.4
		c1.9-0.8,3.7-1.8,5.5-2.9c1.3-0.8,2.6-1.5,3.9-2.4c0.2-0.2,0.5-0.2,0.8-0.2c0.4,0.1,0.6,0.4,0.8,0.8c0.1,0.7-0.4,0.9-0.9,1.2
		c-2.7,1.6-5.4,3.2-8.1,4.9c-0.9,0.5-1.8,1.1-2.5,1.8C73.6,887.6,72.7,885.5,73.3,884.1z"
      />
      <path
        class="st1123"
        d="M72.2,873.4c0.1,0,0.2,0,0.4,0c2.3,3,5.6,3.7,9.1,3.9c0.7,0,1.4-0.1,2.1,0.2c0.3,0.2,0.1,0.5-0.1,0.6
		c-0.8,0.5-1.5,1.2-2.5,1.2c-0.8,0.1-1.6,0-2.3-0.5c-0.4-0.3-1-0.2-1.5-0.3c-2.2-0.6-4.3-1.5-5.8-3.3
		C71,874.1,72.1,873.9,72.2,873.4z"
      />
      <path
        class="st1124"
        d="M81.2,879.2c0.9-0.6,1.9-0.9,2.7-1.7c0.1-0.4,0.4-0.7,0.9-0.7c0.3,0.2,0.4,0.6,0.6,0.9
		c0.1,0.3,0.3,0.6,0.1,0.9c-2.2,1.4-4.5,2.8-6.7,4.1c-1,0.6-2,1.1-3.1,1.4c-0.6,0.1-1.3,0.5-1.7-0.3c1-0.3,1.7-1.2,2.6-1.7
		c1.3-0.8,2.6-1.7,4-2.3C80.8,879.6,81.1,879.5,81.2,879.2z"
      />
      <path
        class="st1125"
        d="M73.9,883.8c0.7,0.4,1.5,0.2,2.2-0.1c1.6-0.6,3-1.5,4.4-2.4c1-0.6,2-1.1,2.9-1.7c0.6-0.4,1.1-1.2,2-1
		c0.2,0,0.4,0,0.5,0.2c0.2,0.4,0.7,0.8,0.6,1.4l-0.1,0.1c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.6-0.1-0.9,0.1c-2.4,1.6-5,3-7.5,4.5
		c-0.6,0.4-1.3,0.6-2,0.8c-0.6,0.2-1.2,0.1-1.6-0.3c-0.2-0.2-0.2-0.4-0.2-0.6C73.6,883.9,73.7,883.8,73.9,883.8z"
      />
      <path
        class="st1126"
        d="M73.3,884.1c-0.1,1.2,0.3,2.2,0.9,3.2c0.2,0.3,0.5,0.7,1,0.6c0.8,1,0.5,2.2,0.3,3.3
		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.5-0.7-0.2-1.5-0.3-2.3c-0.1-0.2-0.2-0.4-0.4-0.5c-1-0.5-1.5-1.3-1.7-2.4c0.2-0.6-0.1-1.3,0.2-1.9
		c0-0.1,0-0.2,0-0.2C73,884,73.1,884,73.3,884.1z"
      />
      <path
        class="st1127"
        d="M63.2,887.1c-0.6,0.1-1-0.3-1.1-0.9c-0.1-0.6,1-1.6,1.7-1.6c0.8,0,1.1,0.5,1.2,1.2
		C64.9,886.5,64.1,887.1,63.2,887.1z"
      />
      <path
        class="st1128"
        d="M72.8,884.2c0,0.7,0,1.4,0,2.1c0,0.3-0.1,0.5-0.3,0.6c-1.8,1.2-2.9,3.2-4.5,4.5c-0.3,0.2-0.6,0.6-0.4,1
		c0.2,0.5,0.7,0.4,1.2,0.3c1.4-0.3,2.6-1.1,3.8-1.8c0.2-0.1,0.4-0.3,0.6-0.4c0.8-0.2,1.4-0.6,1.5-1.6c0-0.1,0.1-0.2,0.3-0.2
		c0,0.8,0.1,1.7,0.1,2.5c0.3,0.5,0,1-0.1,1.6c0,0.1-0.1,0.2-0.2,0.3c-1.2,2.2-3.1,3.7-5.4,4.6c-0.4,0.1-0.8,0.5-1.2,0
		c0.7-0.4,0.9-1.2,1.6-1.6c0.4-0.2,0.7-0.6,0.6-1c-0.2-0.5-0.7-0.3-1.1-0.2c-1.1,0.5-2.2,0.3-3.2-0.1c-1.4-0.5-1.6-1-0.8-2.2
		c0.3-0.5,0.8-0.7,1.2-1c1.8-1.6,3.4-3.4,5-5.2c0.4-0.4,1.4-0.4,0.8-1.4c0-0.1,0-0.1,0-0.2C72.6,884.6,72.7,884.4,72.8,884.2z"
      />
      <path
        class="st1126"
        d="M68.3,897.6c2.8-0.4,4.8-2,6.2-4.3c0.1-0.1,0.1-0.3,0.2-0.3c0.2,0.2,0.2,0.4,0.2,0.6c-0.7,2-2.3,3.1-4.1,4
		c-0.8,0.4-1.7,0.7-2.7,0.9c-1.2,0.2-2.4,0.2-3.4-0.7c0-0.1-0.1-0.2-0.1-0.3C65.9,897.7,67.1,897.8,68.3,897.6z"
      />
      <path
        class="st1129"
        d="M64.8,897.8c3,1.8,8.9-1.1,10-4.2c1-0.7,2.1-0.2,3,0.2c1,0.3,1.7,1.3,2.8,1.5c0.4,0.1,0.4,0.5,0.3,0.9
		c-0.2,0.7-0.6,1.2-1.2,1.6c-1.4,0.9-2.9,1.5-4.5,1.9c-2.6,0.8-5.3,0.9-7.9,0C66.3,899.1,65.3,898.8,64.8,897.8z"
      />
      <path
        class="st1130"
        d="M174.7,884.4c4-0.1,8-0.5,12-1c3.2-0.4,6.3-1.1,9.6-0.9c1.5,0.1,3,0.2,4.4,0.6c0.8,0.3,1.7,0,2.5,0.4
		c1.2,0.6,1.9,1.6,2.8,2.4c0.5,0.4,0.8,1,1.6,0.9c-0.9,0.6-1.7,1.1-2.6,1.7c-0.9,0.7-1.8,0.3-2.7,0.1c-0.3-0.4,0-0.7,0-1.1
		c0.2-1.2-0.3-2.3-1.5-2.7s-2.2-0.8-3.4-1c-1.4-0.2-2.7,0.1-4,0.2c-3.6,0.3-7.2,0.7-10.7,1.2c-0.6,0.1-1.1,0.2-1.6,0.3
		c-0.4,0.1-0.8,0.3-1,0.8c-0.5,1.2-1.3,1.8-2.5,2.1c-0.8,0.2-0.8,0.4-0.1,0.9c0.3,0.2,0.5,0.3,0.8,0.5c0.2,0.2,0.4,0.3,0.2,0.7
		c-1.3,0-2.1-0.9-3.1-1.5C174.7,888.2,174.4,885.2,174.7,884.4z"
      />
      <path
        class="st1131"
        d="M174.7,884.4c0.2,1.6,0.2,3.2,0.9,4.8c0.1,0.2,0.4,0.5,0.3,0.7c-0.2,1.4,0,2.6,0.9,3.7
		c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.1-0.8-0.3c-0.7-1.3-0.8-2.8-1.1-4.3c-0.4-2-0.7-4.1-1.1-6.1c-0.1-0.4-0.6-0.6-0.4-1.1
		c0.8-0.1,1.5,0.5,1.5,1.4c0,0.3-0.1,0.5,0,0.8C174.6,884.2,174.6,884.3,174.7,884.4z"
      />
      <path
        class="st1132"
        d="M174.5,884.1c-0.3-0.5-0.1-1.2-0.6-1.7c-0.2-0.2-0.4-0.6-0.8-0.5c-0.2,0.2-0.5,0.2-0.7,0.2
		c-0.3,0-0.6,0-0.9,0.2c-0.8,0.3-0.6,1-0.7,1.7c-0.2,0.7-0.6,0.5-0.9,0.1c-0.5-0.6-0.2-1.7,0.5-2.2c1.1-0.7,2.2-0.9,3.5-0.5
		c0.8,0.8,0.9,1.6,0.7,2.7C174.5,884,174.5,884.1,174.5,884.1z"
      />
      <path
        class="st1133"
        d="M202.2,888.6c0.9-0.2,1.8,0.3,2.7,0c-0.6,1-0.7,1.1-1.8,1.1c-0.4,0-0.9,0-1.3,0c0,0,0-0.1-0.1-0.1
		C201.8,889.1,201.8,888.7,202.2,888.6z"
      />
      <path
        class="st1134"
        d="M198.9,891.5c-1.1,0.4-1.9,1.3-3.1,1.7c-0.1,0-0.2,0.1-0.3,0c0.2-0.9,1.2-1,1.8-1.5
		c0.7-0.4,1.4-0.9,2.1-1.2c0.3-0.1,0.5-0.3,0.9-0.2c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1C199.9,890.9,199.4,891.2,198.9,891.5z"
      />
      <path
        class="st1135"
        d="M200.3,890.6c0-0.1,0-0.1,0-0.2c0.2-0.8,0.8-1,1.5-0.8l0,0C201.5,890.1,200.8,890.2,200.3,890.6z"
      />
      <path
        class="st1136"
        d="M168.4,908.3c-1,0.7-2.1,0.4-3,0c-2.1-0.9-4.4-1-6.6-1.3c-3.2-0.4-6.5-0.5-9.4-2.5c0.1-0.3,0.6-0.4,0.5-0.8
		c-0.7-0.1-1,0.5-1.6,0.7c0-0.9,0.4-1.6,0.5-2.4c0.2-1.6-0.1-3-1.7-3.9c-0.4-0.2-1-0.3-1.1-0.9c0.1-0.1,0.1-0.1,0.2-0.2
		c1,0,2-0.4,3.1,0.2c-0.2-0.2-0.4-0.2-0.5-0.4c0.1-0.4,0.4-0.6,0.8-0.7c1-0.3,1.8-1,2.6-1.7c0.1-0.1,0.2-0.2,0.3-0.3
		c0.3-0.3,0.5-0.6,0.9-0.8c0.9-0.2,1.6,0.5,2.3,0.8c1.5,0.7,3,1.5,4.4,2.3c0.2,0.1,0.5,0.2,0.5,0.6c-0.1,0-0.1,0.1-0.2,0.1
		c-1.3-0.4-2.6,0.3-4,0.1c-0.8-0.1-1.5,0.3-2.2,0.3c-0.9,0-1.7,0.2-2.6,0.2c0.4,0.3,0.8,0.5,0.7,1.1c0,0.1-0.1,0.2-0.2,0.2
		c-0.3,0.1-0.6-0.1-1-0.2c0.4,0.9,0.7,1.8,0.7,2.7c0,0.5,0.3,0.5,0.7,0.4c0.3-0.1,0.7-0.3,0.7,0.3c-0.3,0.6-1,0.7-1.5,1.2
		c0.8,0.7,1.7,0.8,2.6,1.1c2.5,0.7,5.1,0.8,7.7,0.5c0.9-0.1,1.7-0.3,2.5-0.7c0.5-0.2,0.9-0.5,1.5-0.1c0.8,1.2,1.4,2.6,2.4,3.7
		C168.5,908,168.6,908.2,168.4,908.3z"
      />
      <path
        class="st1137"
        d="M166.4,911.7c0-0.1,0.2-0.5-0.1-0.3c-2.2,0.8-4.5,0.7-6.8,0.3c-3.3-0.6-6.6-1.3-9.8-2.3
		c-1.2-0.4-2.4-0.7-3.6-1.3c-1-0.6-1.1-0.9-0.1-1.6c0.2-0.1,0.6-0.2,0.4-0.6c0,0-0.1-0.1,0-0.1s0-0.1,0.1-0.1c0.5-0.2,1-0.4,1.5-0.6
		c0,0.2,0,0.2-0.1,0.3c-0.9,0.8-1,0.8,0.2,1.4c1.9,1,4.1,1.1,6.2,1.6c3.4,0.8,6.8,1.5,10.2,1.9c1.1,0.2,2.2-0.1,3.3-0.3
		c0.6-0.1,0.9,0,1.3,0.3C168.2,910.8,167.3,911.2,166.4,911.7z"
      />
      <path
        class="st1138"
        d="M146.4,905.6c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c-2.3-0.6-3.4-2-3.5-4.3c0-0.6-0.1-1.2-0.3-1.8
		c-0.1-0.5,0.4-0.5,0.5-0.7c-0.6,0.3-1.1,0.9-1.8,1.1c-0.3-0.3-0.2,0.2-0.4,0.2c-0.8-1-1.4-2-1.5-3.3c0.5-0.8,1.2-1.4,1.9-2
		c0.2-0.2,0.5-0.3,0.8-0.1c0.3,0.1,0.5,0.3,0.8,0.5c1.2,0.8,2.6,1,4,1.5c0.1,0.1,0.1,0.2,0,0.3c-0.1,0-0.1,0-0.2,0
		c-1.1,0.5-1.5,1.1-1,2.1c0.5,0.9,0.4,1.8,0.2,2.7s-0.1,1.6,0.6,2.2C146.3,904.7,146.5,905.1,146.4,905.6z"
      />
      <path
        class="st1139"
        d="M168.4,908.3c-1.1-1.2-2-2.5-2.5-4c-0.6-0.8-0.6-1.6-0.7-2.5c0.1-0.5-0.1-1,0.4-1.3c0.6,0.9,0.4,2,0.7,2.9
		c0.1,0.7,0.7,1.2,0.9,1.9c0.6,1.5,1.8,2.4,3.1,3.2c0.4,0.2,0.9,0.2,0.9,0.8c-0.3,0.3,0.2,0.4,0.2,0.6c0,0.1,0,0.2,0,0.2
		c-0.2,0.2-0.4,0.2-0.6,0c0.1-0.8-0.4-1-0.9-1.2C169.5,908.8,169,908.5,168.4,908.3z"
      />
      <path
        class="st885"
        d="M171,910.2c0.2,0,0.4,0,0.6,0c0.4,0.3,0.8,0.6,1.1,1C171.9,911.1,171.4,910.7,171,910.2z"
      />
      <path
        class="st1140"
        d="M197.4,891.9c-0.6,0.4-1.3,0.8-1.9,1.2c-1,0.7-2.1,1.5-3.3,1.9c-0.6,0.3-1.2,0.5-1.7,1
		c0,0.1-0.1,0.1-0.1,0.1c-1.6,0-2.5,1.4-3.7,2c-1.3,0.7-2.6,1.5-3.8,2.2c-0.3,0.2-0.5,0.4-0.6,0.7c0,0.1,0,0.1,0,0.2
		c-0.1,0.3,0.1,0.6-0.2,0.8c-0.2-0.1-0.3-0.2-0.3-0.4c0.1-1.5-0.4-3-0.8-4.4c0.2-0.5,0.7-0.7,1.2-0.8c0.8-0.1,1.5-0.5,2-1.1
		c0.3-0.4,0.7-0.5,1.2-0.5c0.1,0,0-0.2,0-0.1c-0.8,0-1.4-0.3-1.7-1c0.4-0.3,0.7-0.1,1.1,0c0.3,0.2,0.6,0.3,1,0.5
		c0.2,0.1,0.5,0.3,0.7,0.4c0.4,0.3,0.8,0.5,1.2-0.1s1.3-0.6,1.9-1c1.9-1.2,4-1.8,6.1-2.1C196.4,891.4,197.1,891.2,197.4,891.9z"
      />
      <path
        class="st1141"
        d="M182.2,901.1c-0.2-0.6,0.3-0.7,0.7-0.9c1.5-0.9,3-1.8,4.6-2.7c0.4-0.2,0.8-0.4,0.9-1c0.1-0.5,0.6-0.5,1-0.5
		s0.8,0.1,1.2-0.1c0.2,0.3-0.3,0.5-0.2,0.8c0.2,0.5-0.1,0.5-0.5,0.5c-1.1-0.1-2.1,0.3-3,0.9C185.4,899.2,183.6,899.9,182.2,901.1z"
      />
      <path
        class="st1142"
        d="M192.2,895c1.1-0.6,2.2-1.3,3.3-1.9c0.1,0,0.2,0,0.3,0c-1.2,0.8-2.4,1.5-3.6,2.2
		C192.2,895.2,192.2,895.1,192.2,895z"
      />
      <path
        class="st1021"
        d="M181.1,897.1c0.7,1.6,1,3.2,0.9,5c-0.2,0.5,0.1,1.1-0.2,1.5l0,0c-0.4-0.4-0.3-0.9-0.3-1.3
		c0.2-2.2-0.3-4.2-1.8-5.9c-0.6-0.8-1.2-1.5-2.1-1.9c-0.4-0.2-0.7-0.3-1.1-0.5c-0.1-0.1-0.2-0.2-0.1-0.4c0.1,0,0.3,0,0.4,0
		c0.4-0.3,0.8-0.2,1.1,0c0.6,0.4,1.3,0.8,1.8,1.3C180.2,895.6,181.1,896.1,181.1,897.1z"
      />
      <path
        class="st1107"
        d="M157.4,918.5c-0.2-0.2-0.4-0.5-0.2-0.8c1.4-1.1,2.9-1.9,4.4-2.8c0.1-0.1,0.3,0,0.4,0.1
		c-1.1,1.1-1,2.7-1.8,3.9c0,0-0.1,0-0.1,0.1c-0.1,1-0.1,1-1.2,0.5C158.4,919.2,157.9,918.8,157.4,918.5z"
      />
      <path
        class="st1143"
        d="M162,915c-1.5,0.7-2.8,1.5-4.2,2.4c-0.2,0.1-0.4,0.3-0.6,0.3c-0.1-0.1-0.1-0.1-0.2-0.2
		c1.9-1.2,3.8-2.3,5.7-3.5c0.4-0.2,0.7-0.5,1.1-0.5C163.4,914.2,162.5,914.3,162,915z"
      />
      <path
        class="st1144"
        d="M164.9,913c0.2-0.8,1-0.7,1.5-1.1C166.1,912.6,165.5,912.7,164.9,913z"
      />
      <path
        class="st1145"
        d="M177.6,894.2c0.9,0.4,1.7,0.9,2.1,1.9c0,1.3-0.9,2.1-1.5,3.1c-1.2,1.7-2.7,3.3-4.2,4.7
		c-0.3,0.3-0.7,0.6-0.5,1.1c0.3,0.5,0.8,0.4,1.2,0.2c1.2-0.5,2.5-0.4,3.6-1c1-0.5,1.8-1.1,2.5-2c0.4-0.5,0.7-0.6,1,0
		c0,0.5,0,1,0,1.4c-0.8,2.7-2.6,4.4-5.1,5.4c-1.7,0.7-3.5,0.8-5.3,0.4c-1.2-0.7-2.4-1.2-3.3-2.4c-0.4-0.5-0.9-0.9-0.9-1.6
		c2-0.2,3.4-1.4,4.9-2.5c1.6-1.2,2.9-2.7,4.2-4.2C177.6,897.4,178.8,896.1,177.6,894.2z"
      />
      <path
        class="st1146"
        d="M169.7,861.9c3.5,1.9,7.1,3.7,10.6,5.6c0.2,0.1,0.4,0.3,0.7,0.5c0.1,0.2,0,0.4-0.1,0.5
		c-1.1,0.7-2,1.7-3.2,2c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.6-0.3c-0.4-0.4-0.8-0.8-1.3-0.9c-0.4-0.2-0.9-0.3-1.3-0.4
		c-1.3-0.5-2.6-0.2-3.9-0.3c-0.5,0-0.9,0.1-1.4-0.2c-0.3-0.4-0.1-0.7,0-1c0.3-0.8,0.5-1.5,0.6-2.3
		C169.8,863.9,169.3,862.9,169.7,861.9z"
      />
      <path
        class="st1147"
        d="M177,869.9c0.1,0.2,0.3,0.3,0.4,0.4c0,1.7-0.9,3-1.9,4.2c-1.2,0.9-1.9,2.3-3.1,3.1c-0.3,1-1.1,1.8-1.4,2.8
		c-0.1,0.4-0.5,0.7-1,0.5c-0.1-1,0.7-1.7,0.7-2.6c0-0.2,0.1-0.5,0.1-0.7c0.5-1.2,1.2-2.4,2.4-3.2c1.6-1,3-2.2,3.4-4.2
		C176.7,870.1,176.8,870,177,869.9z"
      />
      <path
        class="st1147"
        d="M177.6,870.4c0.8-0.6,1.7-1.2,2.5-1.8c0.3-0.2,0.7-0.4,0.8-0.7c0.4-0.1,0.5-0.5,0.5-0.8
		c0.3,0.1,0.7,0.3,1,0.4c-0.8,0.1-0.9,1-1.4,1.4c-0.7,0.5-1.4,1-2.1,1.7C178.4,871,178,871,177.6,870.4z"
      />
      <path
        class="st1148"
        d="M171.2,877.7c-0.1,1.2-0.6,2.3-1,3.3c0.2,0.3-0.2,0.5-0.2,0.8c0.2-0.2,0.3-0.4,0.6-0.4
		c1.1-0.7,2.2-0.3,3.3-0.4c0,0.1,0,0.2,0,0.3c-1.2-0.2-2.3-0.1-3.3,0.7c-0.7,0.6-0.8,1.4-0.4,2c0.1,0.1,0.1,0.3,0.3,0.1
		c0.1-0.1,0.1-0.3,0.1-0.4c0.9,0.7,0.7,1.7,0.7,2.7c-0.6,0.3-1.2,0.1-1.8,0c-0.2-0.2-0.2-0.3,0-0.5s0.6-0.1,0.8-0.5
		c-0.6-0.4-0.8,0.6-1.3,0.4c-0.2-0.2-0.2-0.6-0.4-0.9c-1.1-1-2.5-1.6-3.8-2.3c-1.1-0.5-2.1-1.2-3.2-1.6c-0.1,0-0.2-0.1-0.3-0.2
		c-1-1-1-1.2,0.2-1.8c0.7-0.4,1.2-1,2.1-1.1c0.4,0.5,0.5,1.2,0.8,1.7c0.1,0.4,0.4,0.8,0.6,1.1c0.8,1.2,1.3,1.3,2.5,0.6
		c0.6-0.3,1.2-0.7,1.8-1.2c0.7-0.3,0.8-1.1,1.1-1.6C170.6,878.3,170.6,877.7,171.2,877.7z"
      />
      <path
        class="st1029"
        d="M170.6,881.4c-0.2,0.2-0.5,0.5-0.7,0.8c-0.3-0.5,0.1-0.8,0.3-1.1c0.4-0.2,0.7-0.3,0.8-0.8
		c0.2-1,1-1.7,1.5-2.6c0,0.4-0.1,0.8-0.3,1.2C171.6,879.8,171.6,880.9,170.6,881.4z"
      />
      <path
        class="st1149"
        d="M167.2,834.4c-0.1,1,0.6,1.8,0.4,2.7c0.1,0.7-0.2,1-0.8,1.2c-1.1,0.4-2.2,1-3,1.9c-0.1,0.1-0.3,0.2-0.4,0.2
		c-1.5,0.6-1.8,1.2-1.3,2.7c-0.1,0-0.1,0-0.2,0c-0.7-0.1-0.9-0.7-1.3-1.1c0.2-0.3-0.3-0.4-0.2-0.6c0.2-1.5,1.4-2.3,2.2-3.3
		c1.2-1.5,2.3-3,3.1-4.7c0.1-0.2,0.2-0.5,0.6-0.6c0.2,0,0.4,0.1,0.4,0.4C167.1,833.4,166.8,834.1,167.2,834.4z"
      />
      <path
        class="st1150"
        d="M119.1,819.2c0.8,0.7,1.1,1.7,1.7,2.5c0.3,0.1,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.5,0.2,0.7
		c0.1,0.6,0.2,1.3,0.3,1.9c-0.2,1.5-0.4,3-1.3,4.2c-0.6,0.3-1.2,0.5-1.8,0.5c-0.9,0-1.8,0.3-2.6,0.7c-0.6,0.3-1.1,0.3-1.7,0
		c-1.8-1-2.5-0.9-3.9,0.7c-0.5,0.5-1.1,0.9-1.6,1.3c-0.4-0.1-0.8-0.3-1-0.7c-0.2-0.7-0.8-1.3-0.7-2.2c0.1-0.1,0.3-0.1,0.5-0.1
		c0.8,0.3,1.6,0,2.1-0.6c2.3-2.5,5.1-4.5,7.3-7C117.6,821,118.4,820.2,119.1,819.2z"
      />
      <path
        class="st1151"
        d="M103.1,826.1c-0.1-0.7,0.3-1.2,0.8-1.6c0.3-0.3,0.6-0.5,0.6-1c0-0.4-0.1-0.9,0.2-1.2c0.5-1.2,1.1-2.4,2-3.3
		c1.9-1.4,3.9-2.4,6.3-2.5c0.9,0,1.8,0.2,2.7,0.6c-0.7,2-2.1,3.4-3.6,4.8c-1.6,1.5-3.5,2.7-5.2,4.1c-0.1,0.1-0.3,0.2-0.4,0.4
		c-0.3,1-1,1.6-1.6,2.4c-0.2,0.1-0.4,0-0.6-0.1C103.9,827.9,103.3,827.1,103.1,826.1z"
      />
      <path
        class="st1152"
        d="M105.2,828.8c0-0.5-0.1-0.9,0.5-1.1c0.1,0,0.3-0.2,0.3-0.3c0.5-1.8,2.1-2.4,3.4-3.3
		c0.2-0.2,0.4-0.3,0.7-0.5c1.6-1.3,3.1-2.8,4.4-4.4c0.5-0.7,0.5-1.6,1.3-2.1c0.9,0.2,1.6,0.6,2.2,1.3c-1.1,1.3-2.1,2.7-3.3,4
		c-1.8,2-4,3.5-6,5.2c-0.7,0.6-1.2,1.3-1.8,2l-0.1,0.1c-0.1,0-0.2,0-0.3,0c-0.4-0.3-0.9-0.5-1.3-0.8
		C105.2,828.9,105.2,828.8,105.2,828.8z"
      />
      <path
        class="st1153"
        d="M106.8,829.5c0.9-1.8,2.6-2.9,4.1-4.1c2.4-1.9,4.7-4.1,6.4-6.7c0.2-0.4,0.6-0.2,0.8-0.5
		c0.5,0.1,0.7,0.5,1,0.8c-0.1,1.5-1.4,2.3-2.3,3.2c-1.7,1.8-3.3,3.6-5.3,5c-0.5,0.4-1,0.9-1.4,1.4c-0.7,1-2,1.3-3.1,0.8
		C107,829.7,106.9,829.7,106.8,829.5z"
      />
      <path
        class="st1154"
        d="M125.5,819.6c0.2-0.6,0.3-1.1,0.5-1.7c0.9-3,3.1-4.5,6-5.2c0.4-0.2,0.6,0,0.7,0.4c0,0.2-0.1,0.3-0.1,0.4
		c-0.3,0.3-0.1,0.8-0.1,1.2c0,0.5,0.1,1.1-0.6,1.3c-0.3,0-0.4-0.2-0.6-0.4c-0.4-0.7-1-0.7-1.7-0.3c-0.7,0.5-1.2,1.6-0.9,2.1
		s0.9,0.4,1.3,0.7c0,0.5-0.4,0.4-0.6,0.5c-0.4,0.1-0.6,0.3-0.7,0.7c-0.1,0.7-0.5,0.6-1,0.6s-1,0.1-1.5,0.2
		C125.8,820.2,125.4,820.2,125.5,819.6z"
      />
      <path
        class="st1155"
        d="M132.6,813.1c-0.1-0.3-0.4-0.4-0.6-0.4c0.8-0.7,0.6-1.7,0.6-2.5c0-2.5,0.4-5-0.4-7.5
		c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.1,0.1,0.1,0.1c0.6,0.7,0.4,1.5,0.5,2.3c0.4,2.3,0.6,4.7,0.3,7c0,0.5,0,1-0.8,1
		C132.8,813.3,132.7,813.2,132.6,813.1z"
      />
      <path
        class="st1156"
        d="M120.9,822.3c-0.1-0.2-0.2-0.4-0.2-0.6c0.3-0.1,0.6-0.3,0.9-0.2c1.7-0.2,3.3-1,5.1-1.3
		c0.6-0.1,1.2-0.2,1.8,0c0.4,0.2,0.6,0.5,0.5,0.9c0,0.1-0.1,0.2-0.2,0.2c-1.1-0.4-2.1,0-3.2,0.3c-1.2,0.3-2.4,0.6-3.7,0.8
		C121.6,822.4,121.3,822.5,120.9,822.3z"
      />
      <path
        class="st1157"
        d="M128.6,820.4c-1.8-0.3-3.5,0.5-5.2,0.9c-0.6,0.1-1.1,0.4-1.7,0.2c0.8-0.6,1.9-0.4,2.8-0.9
		c0.5-0.3,0.8-0.6,1.2-1c0,0.4,0.1,0.5,0.5,0.3c0.6-0.4,1.2-0.3,1.8-0.3c0.3,0.1,0.5,0.3,0.7,0.5
		C128.6,820.2,128.6,820.3,128.6,820.4z"
      />
      <path
        class="st1158"
        d="M141.6,795.8c-0.4-0.6,0-1.3-0.3-1.9c-0.4,0-0.6,0.4-0.6,0.6c-0.2,1.6-1.4,2.1-2.6,2.6
		c-0.4,0.2-0.8,0.5-1.1,0.7s-0.6,0.4-0.9,0.5c-0.7,0.3-2.4,0-2.6-0.5c-0.3-0.8,0.4-1.4,0.8-1.8c1.8-1.9,3.3-4.1,4.9-6.2
		c0.6-0.8-0.2-1-0.1-1.5c0.1-0.1,0.1,0,0.2,0c0.2,0.5,0.7,0.5,1,0.8c-0.1,0.1-0.2,0.2-0.1,0.3C141.6,791.4,142,793.5,141.6,795.8z"
      />
      <path
        class="st1159"
        d="M139.3,788.4c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.1,0-0.2c1-1.2,2.1-2.5,3.3-3.6c0.1,0.2,0.4,0.4,0.1,0.8
		C141.6,786.6,140.6,787.6,139.3,788.4z"
      />
      <path
        class="st905"
        d="M142,787.3c0.1-0.5,0.4-0.9,0.8-1.4C142.8,786.6,142.6,787.1,142,787.3L142,787.3z"
      />
      <path
        class="st1160"
        d="M128.6,820.4c0-0.1-0.1-0.1-0.1-0.2c0.3-0.3,0.4-0.3,0.9,0.3c0.4,0.6,0.6,1.3,0.8,2c0.5,0.9,1.3,0.7,2,0.3
		c2-1.1,3.7-2.6,5-4.5c0.5-0.7,0.5-1.5,0.4-2.4c-0.1-0.5-0.5-1.3,0.4-1.6c0.3,0.2,0.9,1.2,1.1,1.9c0.4,1.2,0,2.3-0.7,3.2
		s-1.2,1.8-1.8,2.8c-0.4,0.8-1.3,1-1.7,1.6c-0.3,0.4-1,0.6-0.7,1.2c0.3,0.5,0.9,0.6,1.5,0.5c0.7-0.2,1.4,0,2-0.2s0.5,0.3,0.5,0.6
		c0.1,0.7,0.5,0.9,1.1,0.4c0.5,0.1,1.1,0,1.2,0.7c-1,1.3-2.6,1.9-4,2.7c-1.2,0.6-2.6,0.5-3.9,0.3c-0.1,0-0.2,0-0.3,0
		c-1.7-0.4-3.2-1.1-4.4-2.3c-0.4-0.4-0.7-0.8-0.8-1.3c-0.1-0.3-0.2-0.5-0.3-0.8c-0.3-0.6-0.1-1.2,0.5-1.4c1.4-0.4,1.7-1.4,1.6-2.6
		c0-0.1,0-0.3,0.1-0.4C128.8,821,128.7,820.7,128.6,820.4z"
      />
      <path
        class="st1161"
        d="M139.1,826.5c-0.1,0.3-0.3,0.7-0.7,0.5c-0.8-0.3-0.4-1-0.6-1.6c-0.2,0.1-0.2,0.7-0.7,0.3
		c-0.3-0.2-0.7-0.1-1,0c-0.4,0.2-0.9,0.2-1.3,0.1c-0.5-0.1-1.1-0.3-1.2-0.8c-0.2-0.5,0.3-0.8,0.6-1c1.9-1.2,2.8-3.2,3.9-4.9
		c0.8-1.1,1-2.5,0.1-3.8c-0.2-0.3,0.2-0.8-0.4-0.9c-0.2-0.1-0.4-0.2-0.3-0.5c0.1-0.4,0.5-0.1,0.8-0.2c0.8,0.3,1.6,0.6,2,1.5
		c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.2,0.5,0.4,0.7,0.7c0.7,0.8,0.9,1.8,1,2.8c0.3,1.5,0.3,3,0,4.6c-0.1,0.1-0.2,0.1-0.3,0
		c-0.1,0-0.1-0.1-0.2-0.2c-0.5-0.5-1.1-0.7-1.6-0.2c-0.7,0.6-1.3,1.3-1.3,2.3C139,825.9,139.2,826.2,139.1,826.5z"
      />
      <path
        class="st1162"
        d="M151.6,840.3c-0.1-0.4-0.1-0.8-0.2-1.1c-0.6-1.2,0.3-2,0.6-3c0-0.1,0.1-0.1,0.1-0.1
		c1.1-0.1,1.1-1.2,1.5-1.8c0.4-0.5,0.7-0.6,1-0.1c0.4,0.6,0.7,0.5,0.9-0.1c0.2-0.4,0.6-0.5,0.9-0.6c0.8-0.4,0.9-0.9,0.2-1.5
		c-0.3-0.2-0.9-0.4-0.6-0.9c0.2-0.4,0.5-0.8,1.2-0.8s1.4-0.1,1.9-0.7c0.4-0.4,0.9-0.3,1.4-0.2c0.6,0.1,1.3,0.2,1.8,0.6
		c-1.2,0.9-1.4,2.4-2.1,3.5c-1.1,1.6-2.4,2.9-4,4c-1,0.6-1.9,1.3-3.1,1.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.3,0,0.7-0.4,0.7
		C151.8,840.4,151.7,840.3,151.6,840.3z"
      />
      <path
        class="st1163"
        d="M132.4,829.8c1.3,0.4,2.6,0,4,0c-0.3,1-0.3,2-0.3,3c0.1,3.9,0.1,7.7,0.1,11.6c0.3,0.8,0.3,1.8,1.1,2.4
		c-0.3,0.4-0.6,0.8-1.1,1c-0.5,0.1-1,0.4-1.6,0.2c-0.7-0.4-0.6-1-0.6-1.6c0-3.4,0-6.8,0-10.2c0-1.4-0.5-2.8-0.5-4.2
		c0-0.5-0.3-0.8-0.6-1.2C132.7,830.4,132.4,830.2,132.4,829.8z"
      />
      <path
        class="st1164"
        d="M143.1,824.2c2.6,1.8,5.5,3.2,7.9,5.2c0.5,0.5,1,1,1.5,1.4c1.1,1,1,1.5-0.4,2.1c-0.3,0-0.5-0.1-0.6-0.3
		c-0.6-0.8-1.4-1.1-2.2-1.5c-2.9-1.4-5.6-3.1-8-5.3c-0.1-0.1-0.3-0.3-0.4-0.5c-0.4-0.7-0.1-1.3,0.7-1.4
		C142.1,823.9,142.6,824.1,143.1,824.2z"
      />
      <path
        class="st1165"
        d="M147.5,813.2c2.3-0.4,4.4-1.1,6.6-1.8c0.6-0.2,1.1-0.8,1.6-1.3c-1,4.2-5.1,7-9.4,6.3
		c-0.6-0.1-1.2-0.1-1.8-0.2c-0.1-0.8,0.5-1.6,1.3-1.8c0.4-0.1,0.8-0.1,1-0.4C147.1,813.7,147.1,813.3,147.5,813.2z"
      />
      <path
        class="st1166"
        d="M151.8,840.2c0.4,0,0.3-0.2,0.3-0.5c-0.1-0.7,0.3-1.1,0.9-1.1c2.1,0,3.4-1.4,4.8-2.6
		c1.4-1.2,2.6-2.7,3.2-4.6c0.2-0.7,0.7-1.2,1.4-1.4c0.5,0.2,0.9,0.4,1.4,0.4c0.1,0.1,0.1,0.2,0.1,0.2c-0.7,0.5-1.1,1.2-1.5,1.9
		c-1.9,3.4-4.6,6.2-8,8.1c-0.7,0.4-1.5,0.3-2.2,0.6c-0.3-0.1-0.4-0.3-0.5-0.5C151.7,840.6,151.7,840.4,151.8,840.2z"
      />
      <path
        class="st1167"
        d="M151.8,840.2c0.1,0.1,0.1,0.3,0.2,0.4c0.6,1.2-0.4,1.8-1.1,2.4c-2.8,2.4-6.1,4-9.1,6
		c-0.4,0.3-0.8,0.4-1.2,0c-0.1-0.1-0.1-0.2-0.1-0.3c0.5-0.8,1.4-1.2,2.2-1.7c1.8-1.1,3.3-2.6,4.7-4.2c1.4-0.9,2.8-1.8,4.2-2.7
		C151.7,840.3,151.7,840.3,151.8,840.2z"
      />
      <path
        class="st1168"
        d="M147,814c-0.1,0.5-0.5,0.5-0.8,0.5c-1.1,0-1.4,0.8-1.7,1.6c-0.6,0.6-1.1,1.2-1.7,1.8
		c-0.4,0.4-0.5,0.8-0.5,1.3c-0.4-0.9-0.5-2-1.2-2.8c1.6-1.1,2.8-2.7,4.2-4.1c0.4-0.4,0.6-0.8,1.2-0.9c0.8,0.3,0.7,1,0.7,1.7
		C147.2,813.4,147,813.7,147,814z"
      />
      <path
        class="st1169"
        d="M142.2,823.5c0.1,0,0.1,0.1,0.1,0.2c-0.2,0.4-0.6,0.2-0.9,0.3c-0.6,0.1-0.9,0.5-0.7,1.1
		c0.2,0.7,0.5,1.3,1.3,1.7c1.5,0.7,2.7,1.8,4.1,2.7c1.5,0.9,2.9,1.8,4.4,2.7c0.5,0.3,1,0.7,1.3,1.3c0,0.3-0.2,0.4-0.5,0.5
		c-3.3-2.1-6.6-4.3-9.8-6.4c-0.3-0.2-0.5-0.3-0.8-0.5c-1.2-1.3-1.3-1.9-0.8-2.9C140.4,823.2,141.1,823,142.2,823.5z"
      />
      <path
        class="st1170"
        d="M151.6,833.6c-1.5-1.4-3.3-2.3-5-3.4c-1-0.6-1.9-1.3-2.9-2c-0.5-0.4-0.9-0.8-1.6-1c-0.9-0.3-1.3-1-1.7-1.8
		s0-1.5,0.9-1.7c0.3,0,0.6,0,0.9-0.1c0.1,0,0.1,0,0.1,0c0.3,0,0.5,0.2,0.7,0.4c-0.4,0.5-0.8-0.1-1.3,0c-0.3,0.1-0.7,0-0.9,0.3
		c-0.2,0.4,0.1,0.7,0.3,0.9c1.3,1.5,3,2.5,4.6,3.5s3.2,1.9,5,2.6c0.6,0.3,0.7,1.1,1.4,1.2C152.4,833.3,152.1,833.6,151.6,833.6z"
      />
      <path
        class="st1171"
        d="M147.4,842.9c-0.7,1.5-2,2.6-3.4,3.5c-1.1,0.8-2.4,1.6-3.5,2.3c-0.3,0-0.5-0.2-0.5-0.5c0.2-1,1-1.5,1.8-1.8
		C143.7,845.3,145.4,843.8,147.4,842.9z"
      />
      <path
        class="st1172"
        d="M141.8,846.5c-0.7,0.6-1.4,1-1.7,1.9c-0.4,0.1-0.6-0.2-0.9-0.3c-0.6-0.5-1.4-0.6-1.9-1.2
		c-1.2-0.5-1.2-1.5-1-2.5c1.2,0.5,2.4,1.1,3.6,1.8C140.4,846.5,141.1,846.9,141.8,846.5z"
      />
      <path
        class="st1173"
        d="M142.2,823.5c-0.7-0.1-1.5-0.3-2,0.5c-0.6,0.8-0.8,1.6,0.1,2.4c0.2,0.2,0.3,0.4,0.3,0.7c-0.1,0-0.1,0-0.2,0
		c-0.4-0.2-0.8-0.5-1.3-0.7c-0.6-1.2,0-3,1.3-3.5C141.2,822.6,141.8,822.9,142.2,823.5z"
      />
      <path
        class="st1174"
        d="M151.6,833.6c0.3-0.2,0.6-0.4,0.7-0.8c1.1-1,1.1-0.9-0.1-2c-0.5-0.4-1-0.8-1.2-1.5c1.1,0.6,2.2,1.3,3.2,2.2
		c-0.9,0.6-1.4,1.6-1.9,2.5c-0.5,0.7-0.8,0.6-1.2,0C151.3,833.9,151.4,833.7,151.6,833.6z"
      />
      <path
        class="st1175"
        d="M163.9,830.6c0-0.1,0-0.1,0-0.2c0.5-0.5,0.9-1.2,1.7-1.4c-0.4,0.7-1.2,1.1-1.5,1.9
		C163.9,830.8,163.8,830.8,163.9,830.6z"
      />
      <path
        class="st1176"
        d="M86.8,853.8c-0.4-0.4-1-0.5-1.5-0.5c-0.9,0-1.5-1-2.4-1c0.1-0.1,0.2-0.2,0.2-0.3c0.6-0.3,0.8-0.9,1.2-1.3
		c1-0.7,0.8,0.5,1.2,0.8c0.4,0.6,0.9,0.3,1.4,0c1.5-0.8,2.7-2.1,3.9-3.2c2.2-2,4.4-4.1,5.5-7c0.1-0.2,0.2-0.3,0.4-0.4
		c0.3,0,0.5,0,0.7,0.2c0.2,0.1,0.3,0.3,0.2,0.5c-1.6,3.2-3.9,5.8-6.4,8.2c-0.8,0.8-1.5,1.5-2.3,2.3
		C88.3,852.6,87.7,853.3,86.8,853.8z"
      />
      <path
        class="st1177"
        d="M81.8,853.5c1.5,2.5,2.9,4.9,4.4,7.3c0.5,0.8,1,1.5,1.4,2.2c0.6,0.3,0.8,1,1.1,1.4c0.4,0.6,0.8,1.2,1.3,1.7
		c0.5,1-0.4,1-0.9,1.2c-0.3,0.1-0.7,0.3-1-0.1c-0.3-1.3-0.9-2.5-1.6-3.6c-0.9-1.5-1.8-3-2.8-4.5c-0.9-1.3-1.8-2.6-2.9-3.8
		c-0.4-0.4-0.9-0.8-0.8-1.5c0.6,0.3,1.2,0.3,1.5-0.4c0.1,0,0.1,0.2,0.1,0.2C81.7,853.7,81.8,853.6,81.8,853.5z"
      />
      <path
        class="st1178"
        d="M80.2,854c1.2,1.5,2.5,3,3.5,4.7c0.9,1.5,2.2,2.6,2.7,4.3c0.3,1,1.4,1.6,1.6,2.8c0.1,0.5,0.2,1,0.3,1.5
		c-0.1,0.2-0.2,0.4-0.5,0.5h-0.1c0.5-1.4-0.7-2.3-1.2-3.3c-1.2-2.2-2.6-4.2-4.2-6.2c-0.9-1.1-1.8-2.2-2.4-3.5
		c-0.2-0.3-0.5-0.5-0.9-0.6C79.3,853.8,79.7,854,80.2,854z"
      />
      <path
        class="st1078"
        d="M81.8,853.5c-0.1,0.1,0,0.2-0.1,0.2l-0.1-0.2c0.1-0.5,0-1.1,0.8-1C82.3,852.9,81.7,853,81.8,853.5z"
      />
      <path
        class="st1179"
        d="M143.9,800.2c-0.1,0.2-0.2,0.4-0.5,0.4c-0.4,0.3-0.6,0.7-1.1,1c-0.4,0.3-0.8,0.8-1.4,0.4
		c-0.7-0.5-1.5-1-2.2-1.4c0.9-0.9,1.4-2,2.1-2.9C142.2,798.2,143.1,799.1,143.9,800.2z"
      />
      <path
        class="st1180"
        d="M88.3,824.6c0.2-0.1,0.2-0.2,0.2-0.4c0.5,1.6,0.3,2.6-0.8,3.1c-0.6,0.3-1.2,0.6-1.9,0.5
		c-0.9-0.8-1.5-0.6-2.1,0.3c-0.2-1-0.7-1.2-2.3-1.1c-0.3,0-0.7,0.1-1.1,0.2c0.1-0.9,0.9-0.9,1.3-1.3
		C83.8,825.2,86.2,825.5,88.3,824.6z"
      />
      <path
        class="st1181"
        d="M85.7,827.8c0.6-0.3,1.3-0.4,1.9-0.5c0,0.1-0.1,0.3-0.1,0.4c1.1,1.5,2,3,3.8,3.8c2.5,1,4.4,0,6.4-1.1
		c0.5,0.2,0.4,0.6,0.2,1c-0.7,0.5-1.4,0.9-2.2,1c-0.4-0.1-0.8,0-1.2,0c-0.5-0.1,0.1,0.3-0.2,0.3c-0.9,0.5-1.8,0.2-2.7,0.1
		c-0.5-0.1-1-0.3-1.5-0.3c-0.7-0.2-1.3-0.5-1.8-1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.5s-0.4-0.5-0.5-0.8
		C86.6,829.2,86.2,828.5,85.7,827.8z"
      />
      <path
        class="st1182"
        d="M87.1,829.9c0.3,0.2,0.5,0.4,0.6,0.7c0,0.6-0.3,1-0.6,1.5c-1.3,1.9-2.5,3.7-4,5.5c-0.4,0.5-0.8,0.9-1.3,1.3
		c-0.2,0-0.3,0-0.5,0c-0.6-0.3-0.8-0.7-0.5-1.3c0.3-0.3,0.1-0.7,0.2-1.1c1.2,0.3,2.4-0.4,2.8-1.8c0.2-0.7,0.7-1.2,1.1-1.8
		C85.8,832,86.4,830.9,87.1,829.9z"
      />
      <path
        class="st1183"
        d="M80.8,837.5c-0.1,0.6,0.1,0.9,0.6,1.2c-0.3,1.8,0.8,3.5,2.6,4c0.8,0.2,0.9,0.8,0.4,1.5
		c-0.3,0.3-0.5,0.6-1,0.5c-0.2,0.1-0.4,0.1-0.4,0.2c-0.3,1.4-1.4,1.3-2.4,1.4c-1.4,0.2-2.7,0.9-4.1,1.2c-1.2,0.2-1.9-0.2-2-1.4
		c0.6,0.4,0.6,0.4,1.4,0.2c1.7-0.4,3.2-1.1,4.5-2.2c1.7-1.5,0.6-3.6-0.8-4.4c0.5-0.7,1-1.4,0.9-2.3
		C80.6,837.5,80.7,837.5,80.8,837.5L80.8,837.5z"
      />
      <path
        class="st1184"
        d="M147,814c-0.3-0.2-0.1-0.5-0.1-0.8c0.1-0.7,0-1.3-0.6-1.7c-0.5,0-0.6-0.5-0.9-0.8c-0.1-0.1-0.3-0.2-0.4-0.3
		c-0.8-0.5-1.5,0.2-2.2,0c0.4-0.8,1.1-0.8,1.8-0.7c0.4,0,0.7,0.2,1.1,0.3c0.3,0.2,0.7,0.2,0.8,0.6c0.1,0.1,0.3,0.3,0.4,0.4
		c0.4,0.6,0.5,1.3,0.5,2.1C147.4,813.5,147.3,813.8,147,814z"
      />
      <path
        class="st1185"
        d="M146.6,810.6c-0.3-0.2-0.5-0.4-0.8-0.6c0.2-0.5,0.7-0.7,1.1-1c1.6-1.2,2.8-2.6,3.9-4.2
		c0.8-1.2,0.4-2.8-0.6-3.8c-0.2-0.2-0.4-0.5-0.7-0.8c0.3-0.1,0.6-0.1,0.9,0.2c0.7,0.7,1.3,1.5,1.7,2.4c0.4,0.8,0.2,1.5-0.3,2.2
		c-1.4,2.1-3,3.9-5.1,5.3C146.7,810.5,146.7,810.6,146.6,810.6z"
      />
      <path
        class="st1186"
        d="M85.1,877.7c-0.2-0.2-0.3-0.5-0.4-0.8c-1.9,0.2-3.7-0.3-5.6-0.4c-1.6-0.1-3.1-0.7-4.4-1.7
		c-1-0.8-0.9-1.8,0.1-2.5c0.7-0.5,1.4-0.8,2.2-0.8c1.6-0.1,3.2-0.3,4.8-0.1c0.8,0.1,1.7,0.2,2.5-0.2c0.5-1.1,0.9-2.2,1.9-3.1
		c0.2-0.2,0.5-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2l0,0c0.8,0.1,0.8,0.7,0.8,1.3c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,1.1,0.3,1.9,1.4,1.8
		c0.2,0,0.5-0.1,0.7,0.1c0.2,0.3-0.1,0.5-0.2,0.6c-0.9,0.8-1.7,1.8-2.8,2.3c-1,0.5-1.2,1.5-1.6,2.4
		C85.4,877.4,85.4,877.6,85.1,877.7z"
      />
      <path
        class="st1187"
        d="M82.7,861.9c-0.1,1.2-0.6,2.2-1.3,3.1c-1.2-0.2-2.4,0.1-3.6,0.2c-1.2,0.2-2.4-0.1-3.6-0.3
		c-0.3-0.1-0.6-0.2-0.7-0.5c0.7-1.1,1.6-1.7,2.8-1.9c2-0.2,4-0.4,6.1-0.6C82.5,861.8,82.6,861.8,82.7,861.9z"
      />
      <path
        class="st1188"
        d="M82.7,861.9L82.7,861.9h-0.2c-0.4-1.7-1.6-3-2.3-4.5c-0.2-0.5-0.6-1-0.7-1.6c1.1,1.3,2.4,2.5,3.2,4
		c1.1,2,2.7,3.7,3.8,5.7c0.4,0.8,1.2,1.4,0.7,2.4c-0.1,0.2-0.4,0.2-0.6,0.2c-1-1-1.7-2.2-2.1-3.6C83.8,863.7,83.2,862.8,82.7,861.9z
		"
      />
      <path
        class="st1189"
        d="M72.7,863.7c-0.2-0.5-0.6-0.9-0.9-1.4c2.1-1.2,3.6-3.2,5.2-5c0.3-0.3,0.4-0.7,0.4-1.1
		c0.3,0.4,0.7,0.8,1,1.3c0.3,0.6-0.2,1-0.5,1.3c-1.1,1.1-2.2,2.3-3.3,3.5c-0.3,0.4-0.8,0.6-1.3,0.9
		C73.2,863.3,73.1,863.8,72.7,863.7z"
      />
      <path
        class="st1190"
        d="M73.3,863.1c1.7-1.2,2.8-3,4.4-4.3c0.4-0.3,0.7-0.8,0.8-1.3c1,0.3,1.1,0.6,0.3,1.4c-1,1.1-1.8,2.2-2.4,3.6
		c-0.9,0.6-1.8,1.1-2.7,1.7C73.2,864,73.1,863.6,73.3,863.1z"
      />
      <path
        class="st1191"
        d="M73.3,863.1c0.1,0.3,0.3,0.7,0.4,1c0.8,0.9,1.8,0.7,2.8,0.8c1.1,0.2,2.2-0.1,3.3-0.3
		c0.6-0.1,1.1-0.2,1.5,0.4c-1.2,2.2-4.9,3.7-7.6,3c-1.1-0.2-2.1-0.5-3-1.2c0.1-0.4,0.4-0.3,0.7-0.4c0.8-0.7,1.8-1.4,1.3-2.8
		C72.9,863.6,72.8,863.1,73.3,863.1z"
      />
      <path
        class="st1192"
        d="M71.4,866.5c-0.2,0.1-0.4,0.3-0.7,0.4c-1.4,1.1-2.3,2.5-3.5,3.8c-0.2,0.2-0.4,0.5-0.7,0.8
		c-0.4,0.4-0.8,0.8-1.2,1.2c-0.3-0.3-0.1-0.6-0.2-0.9c1.4-2.4,3.4-4.3,5.3-6.2c0.4-0.5,0.9-0.9,1.6-1.1c-0.3,0.6,0,1.5-0.9,2
		C71.1,866.3,71.3,866.5,71.4,866.5z"
      />
      <path
        class="st1193"
        d="M159.9,850.6c0.4,0.3,0.9,0.6,1.3,0.8c-0.6,0.7-1.1,1.5-1.6,2.3c-1.2,2.1-3.1,3.7-4.9,5.4
		c-1,0.9-2,1.7-3,2.5c-0.6,0.5-1.3,0.8-2.1,0.8c-0.9-0.5-1.2-1.4-1-2.4c1-0.6,2.1-0.8,3.1-1.4c2.7-1.7,5.2-3.6,6.5-6.7
		c0.3-0.7,0.8-1.1,1.5-1.3C159.7,850.5,159.8,850.6,159.9,850.6z"
      />
      <path
        class="st1194"
        d="M163.1,853.3c1.1,1,1.4,2.4,1.9,3.7c0.2,0.2,0.3,0.5,0.3,0.8c0,0.5,0.1,1-0.2,1.4c-0.8-0.3-0.8-0.3-2,0.4
		c-1.1,0.7-2.4,1.1-3.7,1.3c-0.6,0.1-1.3,0.1-1.5-0.7c0.1-0.8,0.7-1.2,1.2-1.8c1.3-1.6,2.8-3,3.7-4.9
		C162.8,853.5,163,853.4,163.1,853.3z"
      />
      <path
        class="st1195"
        d="M161.8,851.9c0.3,0.2,0.6,0.5,0.8,0.8c-2.1,3.5-4.8,6.6-7.6,9.5c-0.8,0.5-1.3,1.3-2.1,1.9
		c-0.8,0.6-1.5,0.7-2.3,0c-0.3-0.2-0.5-0.4-0.4-0.8c0.1-0.1,0.1-0.3,0.3-0.3c1.9-0.7,3.2-2.2,4.7-3.6c1.8-1.7,3.6-3.3,4.9-5.5
		c0.3-0.5,0.6-1,0.8-1.6C161,852,161.2,851.6,161.8,851.9z"
      />
      <path
        class="st1196"
        d="M161.8,851.9c-0.4,0-0.6,0.2-0.7,0.5c-1.5,3.5-4.4,6-7.1,8.5c-0.7,0.6-1.3,1.4-2.2,1.8
		c-0.5,0.3-1,0.3-1.4,0.6c-0.4-0.2-0.9-0.4-0.6-1c1.5-0.2,2.4-1.3,3.4-2.2c1.8-1.4,3.4-3,4.9-4.6c0.8-0.9,1.4-2,2-3.1
		c0.3-0.4,0.5-0.9,1.1-1C161.4,851.6,161.6,851.7,161.8,851.9z"
      />
      <path
        class="st1197"
        d="M154.9,862.1c1.3-2,3.2-3.4,4.6-5.3c0.8-1.1,1.6-2.1,2.2-3.3c0.2-0.4,0.5-0.6,0.9-0.8
		c0.1,0.2,0.3,0.4,0.4,0.6c-0.4,1.9-1.9,3.2-3.1,4.6c-0.6,0.8-1.3,1.5-1.9,2.3c-0.6,0.9-1,1.9-2.1,2.2
		C155.6,862.4,155.2,862.4,154.9,862.1z"
      />
      <path
        class="st1198"
        d="M164.9,859.4c0-0.5,0-1,0-1.4c0.5,0.1,0.4,0.5,0.4,0.8c0.1,0.2-0.1,0.6,0.3,0.6c0.3,0,0.4-0.3,0.4-0.6
		c0-0.4,0.1-0.8,0.7-0.8c0.6,0.4,1.1,0.8,1.5,1.5c-0.2,0.8-0.8,1.2-1.6,1.4c-0.5,0.1-0.5,0.3-0.6,0.8c-0.3,1.3-0.3,2.6-1.2,3.7
		c-0.4,0.2-0.7,0.4-1,0.6c-0.6,0-1-0.3-1.1-0.9c0.4-0.9,0.9-1.8,1.1-2.8C164.4,861.2,164.4,860.2,164.9,859.4z"
      />
      <path
        class="st1199"
        d="M169.7,861.9c0.1,1,0.3,2,0.2,3.1c-1,0.4-1.9,1.1-3,0.3c-0.5-0.7-0.6-2.5,0.1-3.3c0.5-0.6,1-1.1,1.1-1.9
		c0-0.2,0.2-0.3,0.4-0.4C169.1,860.4,169.3,861.2,169.7,861.9z"
      />
      <path
        class="st1200"
        d="M168.5,859.7c-0.4,0.9-0.7,1.8-1.4,2.6c-0.6,0.7-0.8,1.9-0.1,2.9c-0.4,0.4-0.9,0.4-1.3,0.6
		c-0.8,0.3-0.9,0.2-1-0.8c0.8-1.2,1.1-2.5,1.1-3.8c0-0.5-0.1-0.8,0.6-0.7c0.9,0.1,1.2-0.7,1.9-1.2
		C168.4,859.5,168.4,859.6,168.5,859.7z"
      />
      <path
        class="st1201"
        d="M159.9,850.6c-0.1,0-0.1,0-0.2,0.1c-0.5,0.1-0.9,0-1.1-0.5c0.3-2.1,0.7-4.2,1.3-6.2
		c0.1-0.4,0.2-0.8,0.6-1.1c0.8,0,0.7,0.7,0.8,1.1c0.1,0.3-0.3,0.5-0.1,0.8c-0.2,0.5-0.5,0.9-0.4,1.5
		C160.3,847.7,160.1,849.1,159.9,850.6z"
      />
      <path
        class="st1202"
        d="M166.8,857.9c-0.1,0.2-0.5,0.3-0.4,0.8c0.1,0.4-0.2,1-0.9,1s-0.4-0.6-0.4-1c0-0.3,0.1-0.6-0.2-0.9
		c0-0.3,0-0.6,0-0.8C165.6,857.2,166.3,857.4,166.8,857.9z"
      />
      <path
        class="st1203"
        d="M161.2,844.8c-0.4-0.5,0.1-1.4-0.7-1.8c-0.3,0-0.6-0.2-0.9-0.3c-0.3-0.1-0.5-0.1-0.8-0.1
		c-0.7,0.1-1.1,0.6-1.4,1.2c-0.1,0.3-0.1,0.8-0.6,0.8c-0.4-0.4-0.3-0.8-0.3-1.3c0.7-1.8,2.6-2.5,4-1.4c0.5,0.3,0.9,0.8,1.3,1.2
		C161.8,843.8,161.9,844.4,161.2,844.8z"
      />
      <path
        class="st1204"
        d="M135.9,803.5c0,0.1,0,0.2,0,0.2c-0.2,2.8,0,5.5-0.1,8.3c-0.3,0.7-0.5,1.5-1.6,1.3c-0.4-0.4-0.3-1-0.3-1.5
		c0.1-2.7-0.3-5.3-0.4-7.9c0-0.6-0.5-0.8-0.9-1.1c0,0,0-0.1,0.1-0.1c0.9-0.1,1.8-0.3,2.7-0.4C136.2,802.4,135.5,803.3,135.9,803.5z"
      />
      <path
        class="st1205"
        d="M163.9,830.6c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0.1,0.1,0.2c0.4,0.8-0.3,1.4-0.6,2c-1.8,3.2-4,5.9-6.9,8.2
		c-0.9,0.7-2,1-3,1.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5-0.4-0.9-0.8-0.8-1.5c0.4-0.5,1-0.2,1.5-0.5c2-1,3.9-2.2,5.3-4
		c0.5-0.7,1.1-1.2,1.7-1.8c1-1.1,1.6-2.4,2.1-3.7C163.1,830.7,163.6,831,163.9,830.6z"
      />
      <path
        class="st1206"
        d="M153.1,842.7c0.1-0.1,0.3-0.1,0.4-0.2c1.5,0.3,2.5-0.5,3.6-1.4c2-1.5,3.5-3.5,5.1-5.4
		c1.1-1.3,1.9-2.8,2.4-4.5c0.2,0,0.3,0,0.4,0.2c0.6,0.7,0.2,1.3-0.1,1.9c-0.8,1.7-1.8,3.2-3.1,4.6c-0.8,0.9-1.5,1.9-2.3,2.8
		c-0.2,0.2-0.1,0.4-0.1,0.6c-0.9,0.2-1.9,0.5-2.4,1.4c-0.1,0.2-0.3,0.4-0.1,0.6c0.1,0.4-0.2,0.9,0.2,1.2c0.4,0.5,0.2,0.8-0.2,1.1
		c-1,0-1.6-0.8-2.3-1.2c-0.4-0.2-0.7-0.5-1-0.8C153.2,843.4,152.9,843.1,153.1,842.7z"
      />
      <path
        class="st1175"
        d="M164.5,831.2c0.2,1.2-0.5,2-1,3c-1.2,2.1-2.8,4-4.5,5.6c-1,1-2.2,2.1-3.6,2.7c-0.7,0.3-1.3,0.3-1.9-0.1
		c0.2-0.5,0.6-0.3,0.9-0.5c1.7-0.8,3.2-1.7,4.4-3.2c1.3-1.7,2.9-3.1,4-5c0.5-0.9,1-1.8,1.3-2.8C164.3,831.1,164.4,831.2,164.5,831.2
		z"
      />
      <path
        class="st1207"
        d="M159.3,841.2c-0.6-0.4,0-0.7,0.2-0.8c1-1.3,2.1-2.4,3.1-3.8c1-1.4,2.2-2.8,2.4-4.7c0-0.2,0-0.3-0.1-0.5
		c0.1-0.1,0.2-0.1,0.3-0.2c0.5,0.5,0.8,1,0.9,1.6c-0.5,1.2-1.3,2.2-2,3.2c-0.8,1.3-1.8,2.4-2.8,3.5c-0.6,0.6-0.6,1.3-1.1,1.9
		C159.9,841.6,159.6,841.5,159.3,841.2z"
      />
      <path
        class="st1208"
        d="M166.2,832.9c-0.4-0.5-0.9-1-0.9-1.6c0.2-0.6,0.5-0.9,1-1.3c0.7-0.5,1.4-0.6,2.1,0c0.8,0.7,0.7,1.4,0.2,2.1
		c-0.4,0.7-1.1,1-1.9,1.1C166.5,833.1,166.3,833,166.2,832.9z"
      />
      <path
        class="st1209"
        d="M84.6,849.4c0-0.1,0-0.3-0.1-0.4c-0.2-0.2-0.2-0.4-0.3-0.7c0-1.3-0.2-2.6,0.4-3.8c0.3-0.5,0.6-1.1,0.8-1.6
		c0.9,0.2,1.4-0.2,2-0.9c1-1,2.3-1.7,3.7-2.2c0.8-0.3,1.6-0.5,2.4-0.6c0.5-0.1,0.9,0,1.3,0.3c0.3,1-0.1,2-0.6,2.8
		c-1.6,2.6-3.7,4.7-6.3,6.4c-0.8,0.5-1.8,0.7-2.7,1C84.9,849.8,84.7,849.7,84.6,849.4z"
      />
      <path
        class="st1210"
        d="M84.6,849.4c1.4,0.1,2.7-0.5,3.8-1.2c2.1-1.5,3.9-3.3,5.3-5.5c0.6-1,0.9-2,1.1-3.1c2.1-1.8,4-3.8,5.8-5.8
		c0.2-0.2,0.3-0.4,0.6-0.5c0.1,0,0.2,0,0.3,0c0.4,0.5,0.1,1-0.3,1.3c-1.6,1.6-3,3.5-4.6,5.1c-0.4,0.4-0.3,0.9-0.5,1.3
		c-0.5,1.3-1.2,2.6-2.1,3.7c-2.1,2.6-4.5,4.9-7.4,6.5c-0.4,0.2-0.7,0.4-1.2,0.3c-0.4-0.2-0.2-1.1-1-0.8
		C84.3,850.2,84.7,849.8,84.6,849.4z"
      />
      <path
        class="st1211"
        d="M84.9,844.4c-0.6,1.3-0.3,2.6-0.5,4c-0.6,0-1.1-0.3-1.6-0.7c-0.4-1.1,0.1-2,0.5-2.9
		c0.2-0.2,0.5-0.4,0.7-0.7C84.5,844,84.7,844.1,84.9,844.4z"
      />
      <path
        class="st1212"
        d="M82.9,847.7c0.6,0,1.2,0.3,1.6,0.7c0,0.2,0,0.4,0,0.6C83.7,848.9,82.9,848.8,82.9,847.7z"
      />
      <path
        class="st1213"
        d="M97.9,830.2c-0.3-0.6-0.6-1.2-0.8-1.9c1.2-0.3,2.4-0.3,3.5-0.8c0.9-0.4,2-0.6,2.2-1.9
		c0.3,0.6-0.2,1.2,0.3,1.7c0.6,0.2,0.6,0.7,0.7,1.2c-0.8,0.3-1.3,1-1.9,1.5C100.6,830.9,99.3,831.1,97.9,830.2z"
      />
      <path
        class="st1214"
        d="M103.7,828.5c-0.2-0.4-0.4-0.8-0.6-1.2c0-0.4,0-0.8,0-1.2c0.6,1,1.2,2,2.1,2.7l0,0c0.1,0.5-0.1,0.8-0.6,0.9
		C104,829.5,103.8,829.1,103.7,828.5z"
      />
      <path
        class="st559"
        d="M104.7,822.3c0.1,0.4-0.2,0.8-0.2,1.2C104.4,823.1,103.7,822.5,104.7,822.3z"
      />
      <path
        class="st1215"
        d="M103.7,828.5c0.3,0.4,0.6,0.7,0.8,1.1c0.1,0.2,0.2,0.3,0,0.5c-1,1.1-1.9,2.3-3.2,3.2c-0.1,0-0.1,0-0.2,0.1
		c-0.3,0.2-0.5,0-0.7-0.2c-0.9-0.6-1.6-1.2-2.4-1.9c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.1,0-0.1,0.1-0.2c0.1,0,0.3-0.1,0.4,0
		c2,0.6,3.2,0.2,4.4-1.4C103.1,828.2,103.4,828.9,103.7,828.5z"
      />
      <path
        class="st1216"
        d="M142.5,801.9c-0.2,0.2-0.3,0.4-0.5,0.6c-0.4-0.3-0.4-0.3,0.3-1c0.4-0.2,0.6-0.8,1.1-0.8
		C143.1,801.1,142.8,801.5,142.5,801.9z"
      />
      <path
        class="st1217"
        d="M145.5,810.7c0.5,0.1,0.6,0.5,0.9,0.8c-1.2,1.7-2.9,3-4.2,4.6c-0.3,0.3-0.7,0.3-1,0.3
		c-0.2-0.2-0.3-0.5-0.5-0.8c0.2,0,0.4,0.1,0.6,0c1.5-1.3,3.2-2.6,4.1-4.5C145.5,811,145.5,810.8,145.5,810.7z"
      />
      <path
        class="st1218"
        d="M142.9,810.5c0.7-0.5,1.5-0.7,2.2,0c-1.3-0.2-1.9,0.8-2.6,1.7C142.5,811.4,142.8,811,142.9,810.5z"
      />
      <path
        class="st1219"
        d="M138.9,897.1c0.1,1.2,0.9,2.2,1.4,3.3c0.7,1,1,2.1,0.5,3.3c0,0.1,0,0.3,0,0.4c-0.6-1-0.7-1-1.8-0.4
		c-1.2,0.7-2.4,1.3-3.7,1.6c-1.2,0.3-1.8,0-2.2-1.2c-0.3-0.8-0.5-1.7-1.4-2.2c-0.7-0.4-1.4-0.3-2.1-0.3c-0.1,0-0.1,0-0.2,0
		c-0.1-0.5,0.5-0.3,0.6-0.7c-0.5-0.4-0.9-0.9-0.9-1.7c0.2-0.5,0-1.2,0.7-1.5c1.9,0.5,3.6,1,5.1,2.4c0.6,0.6,1.4,0.8,2.2,0.3
		s1.3-1.3,1.3-2.3c0-0.3-0.2-0.6-0.1-0.9C138.4,897.1,138.6,896.9,138.9,897.1z"
      />
      <path
        class="st1137"
        d="M140.8,903.8c0.1-1.1-0.2-2.2-0.5-3.3c0.1-0.1-0.1-0.4,0.2-0.3c0.6,0.2,0.9-0.5,1.4-0.4
		c0.8,0.5,0.6,1.4,0.6,2.1c0,1.4,0.8,2.9,2.1,3.5c0.4,0.2,0.8,0.4,1.1,0.7c-0.5,0.6-1,0.8-1.8,0.4
		C142.6,905.9,141.5,905,140.8,903.8z"
      />
      <path
        class="st1220"
        d="M125.8,916.5c0.4-0.3,0.6-0.7,1.2-0.7c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.3,0.4-0.7,0.7-1.2,0.8c-0.1,0-0.2-0.1-0.2-0.1C125.5,916.6,125.7,916.5,125.8,916.5z"
      />
      <path
        class="st1221"
        d="M122.7,897.7c0.5-0.9,1-1.8,1.5-2.7c0.2-0.1,0.4,0,0.5,0.2c0,0.1,0,0.3-0.1,0.4c-0.7,1.7-0.7,1.6,0.8,2.6
		c1.1,0.7,2.2,1.3,3.5,1.6c0,0.8-0.6,1-1.3,1.1c-0.1,0-0.1,0-0.2,0c-1.5-0.8-1.5-0.8-2.1,0.8c-0.4-0.4,0-0.7,0.1-1
		c0.3-0.6,0.2-0.9-0.4-1.3C124.2,898.9,123.3,898.5,122.7,897.7z"
      />
      <path
        class="st1222"
        d="M125.6,916.9c0.5-0.2,1-0.4,1.4-0.7c0.3,0.3,0.6,0.5,1.1,0.5c0.9,0.1,1.7,0.7,2.1,1.5
		c0.3,0.5-0.1,1-0.6,1.2c-0.9,0.5-2,0.7-2.9,1c-1.9,0.6-3.9,0.6-5.9,0.2c-0.3-0.1-0.5,0.5-0.8,0.1c0.3-0.6,1.2-0.6,1.6-1.3
		c-0.7,0.1-1.2,0.4-1.6,0.8c-0.4,0.3-0.9,0.4-1.2,0.1c-0.3-0.4-1-0.8-0.7-1.6s0.7-1.3,1.4-1.7c2.1-1.1,4.2-1.3,6.4-0.5
		C125.7,916.6,125.6,916.7,125.6,916.9c-0.2,0.2-0.5,0.3-0.8,0.5C125.1,917.2,125.3,917,125.6,916.9z"
      />
      <path
        class="st1223"
        d="M149.5,921.7c-0.2,0.2-0.4,0.4-0.7,0.6c-0.6,0.4-1.4,0.2-2.1,0.4c-0.3,0-0.5,0.4-1,0
		c-0.4-0.3-1,0.1-1.6,0.1c-2.8,0.1-5.4-0.2-7.9-1.7c-1.6-1-1.6-2.2-0.1-3.2c0.5-0.3,1.1-0.3,1.6-0.5c0.5,0,0.8,0.5,1.2,0.5
		c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.2,0.3-0.4,0.3c-0.4,0-0.9-0.3-1,0.5c-0.1,0.7-0.1,1.2,0.5,1.7c1.1,0.8,2.3,1,3.5,1
		c0.8,0,1.6,0,2.4-0.3c-0.5-0.6-1.3-0.7-1.7-1.3c-0.4-0.9-1.4-0.8-2.1-1.3c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.7,0.4-0.7,0.9-0.7
		c2.2,0.2,4.2,0.8,6.2,1.8C148.4,920.1,148.9,920.9,149.5,921.7z"
      />
      <path
        class="st1224"
        d="M146.8,922.7c0.6-0.8,1.4-0.3,2.1-0.4c0,1.4,0,1.4-1.9,1.5c-0.3,0-0.5-0.1-0.6-0.4
		C146.5,923.1,146.7,922.9,146.8,922.7z"
      />
      <path
        class="st1225"
        d="M149.5,921.7c-0.2,0-0.5,0-0.6-0.2c-0.9-1.6-2.4-2-4-2.5c-1.2-0.4-2.4-0.6-3.7-0.8
		c-0.4-0.1-0.9-0.1-0.7,0.6c-0.7,0.2-1.1-0.1-1.4-0.6c0-0.1,0-0.1,0-0.2c-0.1-0.5-0.7-0.4-0.8-0.7c1.7-0.3,3.4-0.5,5.1-0.3
		c2,0.2,3.9,0.3,5.7,1.4c0.9,0.5,2,0.5,2.8,1.2c0.3,0.2,0.6-0.1,0.9-0.2c0.4-0.1,0.8-0.6,1.3-0.2c-0.1,0.1-0.3,0.1-0.4,0.2
		c-0.4,0.6-1,0.8-1.6,1.1C151.1,920.6,150.5,921.5,149.5,921.7z"
      />
      <path
        class="st1097"
        d="M127.6,900.8c0.3-0.4,1.1-0.4,1.3-1.1c-0.1-0.3,0.1-0.4,0.4-0.4c0.1,0.3,0.1,0.8,0.4,0.9
		c1.3,0.8,0.4,1-0.3,1.4c-0.3-0.1-0.6-0.2-0.8-0.2C128.2,901.2,127.9,901,127.6,900.8z"
      />
      <path
        class="st1226"
        d="M152.1,920.4c0.5-0.4,1-0.7,1.6-1.1c0,0.1,0,0.2,0,0.3C153.2,920,152.8,920.3,152.1,920.4z"
      />
      <path
        class="st1227"
        d="M112,897.7c1-0.6,1.5-1.3,1.2-2.5c0-0.7,0.3-1.1,1.1-1c0.4,0.1,0.8,0,1.1-0.3c1.6-1.2,2.9-2.5,3.9-4.2
		c0.3-0.6,0.3-1.2,0.1-1.8c-0.4-1.4,0.4-2.7,1.9-2.9c0.8,0.2,1,1,1.5,1.5c0.7,1,1.2,2,1.5,3.2c0.1,0.3,0.2,0.6,0.2,1
		c0.3,1,0.2,1.9-0.1,2.8c-0.1,0.1-0.1,0.2-0.2,0.2c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.4,0.2-1.1-0.4-1.2c-0.7-0.1-0.7,0.7-0.9,1.1
		c-0.3,0.5-0.6,1-1.2,1.2c-0.8,0.2-1,1.1-1.8,1.5c-1.2,0.5-2.3,0.6-3.5,0.6c-0.9,0-1.7,0.2-2.4,0.6
		C113.3,897.6,112.7,898.1,112,897.7z"
      />
      <path
        class="st1228"
        d="M124.3,893.8c0-0.1,0.1-0.1,0.1-0.2c0.5-0.3,0.8,0.1,1.2,0.2c0.8,0.3,1.5,0.7,2.3,1
		c0.4,0.1,0.6,0.4,0.6,0.9c-0.1,0.5-0.5,0.7-1,0.8c-1.3,0.3-2.1-0.5-2.9-1.2c-0.1-0.1-0.2-0.2-0.4-0.2
		C124,894.5,124.3,894.2,124.3,893.8z"
      />
      <path
        class="st1229"
        d="M108.9,894.6c0.3-0.3,0.7-0.6,1-0.9c0.5-0.6,1.2-0.5,1.8-0.3c0.4,0.1,0.7,0.4,0.5,0.9
		c-0.7,0.7-1.5,1.3-2.3,1.9C109.6,895.7,109.3,895.1,108.9,894.6z"
      />
      <path
        class="st1110"
        d="M121.4,885.2c-1.4,0.3-2.2,1.5-1.6,2.8c0.3,0.6,0.2,1.2-0.2,1.8c-1,1.8-2.5,3.3-4.2,4.5
		c-0.2,0.2-0.6,0.4-0.9,0.2c-0.8-0.4-1,0.2-1.3,0.6c-0.2-0.4-0.4-0.7-0.6-1.1c-0.3-0.2-0.7-0.3-0.6-0.8c2.7-0.6,4.2-2.6,5.8-4.5
		c0.7-0.8,0.1-2.2-1-2.6c0.2-0.3,0.5-0.4,0.7-0.7c0.5-0.5,0.3-1.3,0.7-1.9c0.5-0.3,0.9-0.1,1.3,0.1c0.6,0.3,1.1,0.8,1.9,0.5
		c0.1,0,0.1,0.1,0.2,0.2C121.6,884.8,121.6,885,121.4,885.2z"
      />
      <path
        class="st1113"
        d="M112,893.3c0.2,0.3,0.6,0.3,0.6,0.8c-0.1,0.2-0.2,0.2-0.4,0.2c-0.4-0.9-0.9-1.1-2.3-0.7
		c-0.1-1,0.6-0.8,1.1-0.8C111.4,892.9,111.8,893,112,893.3z"
      />
      <path
        class="st1230"
        d="M114,884c-0.6-0.1-1.3-0.3-1.8-0.6c-1-0.6-1.9-0.2-1.7,1c0.1,0.6-0.2,0.8-0.4,1.1c-1.1,1.2-2,2.5-2.6,4.2
		c-1.6-1.3-3.6-2-5.3-3.2c-0.1,0.3-0.1,0.5-0.2,0.7c-0.8-0.5-0.8-1.5-1.3-2.2c-0.3-0.4-0.6-0.9-0.5-1.5c0.1-0.3,0.2-0.6,0.5-0.8
		c0.1-0.1,0.3-0.1,0.4-0.1c0.8,0.1,1-0.6,1.5-0.9c0.1,0,0.2-0.1,0.3,0c1.6,0.2,3.2,0,4.7-0.7c0.3-0.2,0.6-0.1,0.9,0.1
		c0.4,0.3,0.9,0.5,1.3,0.8c-0.1-0.7,0.4-0.7,0.8-0.8c1.2-0.2,2.3-0.6,3.5-0.5c0.2-0.1,0.4-0.1,0.6,0.1c0.3,0.8,0.2,1.7,0,2.5
		C114.5,883.4,114.5,883.9,114,884z"
      />
      <path
        class="st1231"
        d="M102.2,891c-0.4-1-0.9-1.8-1.3-2.8c-0.8-2.1-1.6-4.2-2.4-6.2c-0.3-0.8-0.8-1.6-1.5-2.2
		c-0.4-0.4-0.8-0.2-1.3,0s-0.2,0.6-0.2,0.9c0,0.4,0.1,0.8,0.1,1.2c-0.3,0-0.4-0.3-0.6-0.5c-0.4-1.5-0.2-2.2,0.9-2.9
		c1.2-0.4,2.2,0.6,2.8,2c0.8,1.8,1.4,3.7,2.5,5.4c0.5,0.8,0.5,1.8,0.8,2.8c0.2,0.6,0.5,1.1,0.7,1.7C103,891.1,102.6,891,102.2,891z"
      />
      <path
        class="st1232"
        d="M112.2,889.6c-1,0-1.5-0.5-1.5-1.4s1-2.1,1.9-2.1c0.8,0,1.5,0.8,1.5,1.7
		C114.1,888.8,113.3,889.6,112.2,889.6z"
      />
      <path
        class="st1233"
        d="M102,888.7c-0.8-2.2-1.9-4.2-2.8-6.4c-0.4-0.9-0.6-1.9-1.3-2.7c-0.6-0.6-1.1-1.1-2-1c0.3-0.8,1-0.7,1.6-0.6
		c1,0.5,1.5,1.5,1.8,2.5c0.4,0.9,1,1.7,1,2.7c-0.1,0.5,0.2,0.9,0.5,1.3c0.5,0.8,0.8,1.6,1.2,2.4C102,887.6,102,888.2,102,888.7z"
      />
      <path
        class="st1234"
        d="M114,884c0.3-0.2,0.4-0.5,0.4-0.8c0.4,0,0.3,0.3,0.4,0.6c0.1,0.4,0,0.8,0.3,1.1c0.4,0.5,0.8,0.8,1.4,0.7
		c0.5,0,0.9-0.1,1.3,0.2c-0.3,0.3-0.6,0.4-1,0.5c-0.4,0-0.8,0-1.2,0c-0.3-0.1-0.6-0.2-0.9-0.3C113.7,885.5,113.5,885,114,884z"
      />
      <path
        class="st1235"
        d="M96,885.6c1-0.7,1.4,0,1.7,0.8c0.2,0.5,0.6,0.9,0.6,1.5c-0.3,0.2-0.5,0-0.8,0c-0.4,0-0.8,0.2-1,0.6
		c-0.5,1-0.5,1-1.4,0.4c-0.1,0-0.1-0.1-0.1-0.1c-0.6-0.8-1.3-0.4-1.7,0c-1.5,1.3-3.3,2.3-4.9,3.6c-1.4,1.1-3,1.3-4.7,0.8
		c-0.1-0.1-0.1-0.2,0-0.3c2.8-1.7,5.7-3.3,8.5-5.1c0.5-0.3,1.1-0.8,1.7-0.1c0.1,0,0-0.2-0.1-0.1c0,0.1,0,0.1-0.1,0
		c-0.3-0.5,0-0.7,0.3-0.9c0.6-0.3,1.2-0.8,1.8-1C95.9,885.6,95.9,885.6,96,885.6z"
      />
      <path
        class="st1236"
        d="M94.9,888.7c0.5-0.1,0.8,0.4,1.3,0.4c-0.2-0.4-0.4-0.7,0.1-1.1c0.6-0.4,1.2-0.6,1.9-0.2
		c0.3,0.4,0.5,0.9,0.6,1.5c-0.4-0.3-0.7-0.8-1.2-0.1c-0.1,0.2-0.4,0.3-0.6,0.4c-1.6,0-2.6,1.1-3.7,2c-1,0.8-0.7,1.7,0.5,2.2
		c0.6,0.2,1.3,0.2,1.8,0.7c-0.3,0.7-0.7,1.1-1.6,0.7c-0.9-0.3-1.9-0.4-2.8-0.7c-0.8-0.2-1.1-0.8-1.2-1.5c-0.1-0.6,0.4-0.9,0.8-1.2
		C92.3,890.8,93.6,889.8,94.9,888.7z"
      />
      <path
        class="st1237"
        d="M83.7,893c0,0.1,0,0.1,0,0.2c-0.3,0.2-0.8,0.3-1,0.6l0,0c-0.1,0-0.2,0.1-0.2,0.1c-0.2-0.9-1.1-0.5-1.6-0.7
		c-1.4-0.6-3-0.2-4.5-0.6c-0.4-0.1-0.8,0.2-1.1,0.4c-0.1,0-0.2,0-0.3,0c0.1-0.6,0.2-1.1,0.3-1.7h0.1c1.7,0.2,3.5,0.1,5.2,0.2
		c1.1,0.1,2.2,0.4,3.4,0.5c0.2,0,0.4,0.1,0.4,0.3C84.3,892.6,83.9,892.7,83.7,893z"
      />
      <path
        class="st1238"
        d="M95.2,881.4c0.1,0.2,0.2,0.3,0.4,0.5c0,0.4,0.4,0.6,0.2,1c-1.5,0.5-3.1,1.1-4.7,0.5
		c-1.2-0.3-2.2-0.8-3.2-1.5c-0.3-0.2-0.6-0.4-0.7-0.8c0.8-2.4,2.4-4,4.4-5.4c1.5-1,2.7-2.3,3.7-3.9c0.5-0.7,0.8-1.5,0.6-2.5
		c-0.2-0.7,0-1.2,0.7-1.3c0.6-0.1,0.5-0.6,0.6-1c0.6-0.3,1,0.2,1.4,0.5c0.1,0.1,0.1,0.2,0.1,0.3c0.3,2.2-0.7,4-2,5.7
		c-1,1.2-2,2.3-2.9,3.6c-0.6,0.9-0.5,1.1,0.6,1.1c0.8,0,0.6,0.6,0.4,1C94.6,880,94.6,880.8,95.2,881.4z"
      />
      <path
        class="st1239"
        d="M91,883.1c1.6,0.7,3.2-0.3,4.8-0.2c1.3,0.7,1.3,1.5,0.2,2.7l0,0c-2.8,1.5-5.4,3.3-8.2,4.8
		c-0.2,0.1-0.4,0.5-0.7,0.1c-0.2-0.2,0-0.5,0.2-0.7c0.3-0.4,0.5-1,1.1-1.1c0.2,0,0.3-0.2,0.4-0.3c0.7-1.3,1.4-2.5,1.9-3.8
		c0.1-0.3,0.2-0.6-0.1-0.9C90,883.2,90.7,883.3,91,883.1z"
      />
      <path
        class="st1240"
        d="M74.9,893c0.1,0,0.2,0,0.2,0c0.2-0.2,0.4-0.2,0.6-0.2c1.1,0.1,2.3,0.2,3.4,0.4c0.9,0.2,1.7,0.7,2.5,1.2
		c0.5,1.7,0.3,2.1-1.2,3.1c-1.6,1.2-3.4,1.8-5.3,2c1.4-0.6,2.8-1.1,4.1-1.9c0.5-0.3,1.1-0.7,1.3-1.2c0.4-0.9,0.2-1-0.7-1.1
		c-0.6,0-0.9-0.7-1.4-1c-1.1-0.7-2.3-0.9-3.6-0.8c0-0.2,0-0.3-0.1-0.5C74.8,893,74.8,892.9,74.9,893z"
      />
      <path
        class="st1114"
        d="M81.6,894.4c-0.3,0-0.5,0.4-0.8-0.2c-0.3-0.7-1.3-0.6-2-0.8c-1.2-0.3-2.4-0.3-3.7-0.5
		c0.3-0.7,0.8-0.8,1.4-0.7c1.8,0.4,3.6,0.1,5.3,0.8c0.3,0.1,0.7,0,0.9-0.2c0.2,0.4-0.8,0.3-0.2,0.8C82.4,894.1,81.8,894,81.6,894.4z
		"
      />
      <path
        class="st1241"
        d="M84.4,864.6c0.7,1.2,1.4,2.4,2.1,3.6c-0.7,1-1.3,2-1.7,3.1c-0.3,0.2-0.6,0-0.8-0.1
		c-1.3-1.1-2.8-0.7-4.3-0.4c-0.9,0.2-1.8,0.3-2.6,0.6c-0.3,0.1-0.7,0.2-0.9-0.3c1.4-1,2.8-2.1,4.5-2.7c-0.6-0.3-0.3-0.6,0.1-0.9
		c1-0.7,2.1-1.5,3.1-2.2C84.1,865,84.3,864.9,84.4,864.6z"
      />
      <path
        class="st1242"
        d="M76.2,871c0.3,0.2,0.5,0.1,0.9,0c1.6-0.5,3.2-0.8,4.8-1c1.2-0.1,1.9,0.7,2.9,1.2c0,0.5-0.2,0.5-0.7,0.5
		c-2.1,0-4.2-0.1-6.3,0c-1.1,0-2.1,0.3-3,1.1c-0.7,0.6-0.7,1.3,0.1,1.8c1.4,1,2.9,1.8,4.7,1.7c1.4,0,2.9,0.7,4.3,0.3
		c0.6-0.2,0.7,0,0.9,0.4c-0.3,0.2-0.5,0.4-0.8,0.6c-2.3,0.2-4.5,0-6.7-0.7c-1.7-0.5-3.3-1.3-4.4-2.8c-0.1-0.2-0.1-0.5-0.2-0.7
		C73.8,872.5,74.9,871.7,76.2,871z"
      />
      <path
        class="st1243"
        d="M91,883.1c0.2,0.2-0.9,0-0.1,0.5c0.3,0.2,0.4,0.5,0.2,0.9c-0.7,1.2-1.4,2.4-1.9,3.7c-0.4,1-1.8,1.1-2,2.3
		c1.2-0.7,2.4-1.4,3.6-2.1c1.7-1,3.3-2.2,5.2-2.8c-0.4,0.6-1.1,0.9-1.8,1.2c-0.5,0.2-0.6,0.5-0.2,1c-0.6-0.5-1.1-0.2-1.7,0.1
		c-2.6,1.6-5.2,3.2-7.8,4.7c-0.3,0.2-0.6,0.2-0.9,0.3c0.2-0.2,0.3-0.5,0.6-1c-0.6,0.4-1,0.2-1.4,0.1c-2.2-0.5-4.3-0.4-6.5-0.4
		c-0.4,0-0.9,0.2-1-0.4c0-1.1,0.2-2.2-0.2-3.3c0.1-0.5,0.5-0.8,0.9-1c3.2-1.9,6.4-3.8,9.6-5.8c0.3-0.2,0.8-0.3,0.8-0.9l0,0
		c0.6-0.1,0.7,0.5,0.8,0.8C88.2,882.2,89.6,882.7,91,883.1z"
      />
      <path
        class="st1244"
        d="M73.7,884.6c1.9,0.5,3.3-0.6,4.8-1.4c1.2-0.7,2.4-1.5,3.6-2.2c0.9-0.5,1.9-1,2.7-1.6c0.4-0.3,0.7-0.4,1,0.1
		c-1.6,0.9-3.2,1.9-4.7,2.8c-1.8,1.1-3.7,2-5.6,2.9C74.9,885.6,73.9,885.8,73.7,884.6z"
      />
      <path
        class="st1245"
        d="M88.3,869c-0.1-0.5-0.1-1-0.6-1.2c0.2-0.1,0.4-0.3,0.6-0.4c0.3,0.4,0.6-0.1,0.8,0c1.5-0.7,2.8,0.1,4.1,0.8
		c1.2,0.6,1.6,1.6,1,2.8c-0.5,1.2-1.2,2.2-2.2,3.1c-1.9,1.6-4.2,2.8-5.6,4.9c-0.2,0.4-0.5,0.1-0.7,0c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1-0.3-0.2-0.5-0.2-0.8c0.3-1.6,1-2.9,2.6-3.7c0.9-0.5,1.6-1.4,2.4-2.2c-0.1-0.4,0.3-0.5,0.4-0.8c0.4-0.6,0.5-1.3,0-1.9
		c-0.4-0.6-1-0.6-1.7-0.3C88.8,869,88.5,869.1,88.3,869z"
      />
      <path
        class="st1246"
        d="M85.7,878.8c0.2,0,0.3,0.2,0.5-0.1c0.8-1.2,1.9-2.1,3-2.9c1.7-1.2,3.5-2.5,4.5-4.4c0.6-1.2,0.6-2.6-0.9-3.1
		c-0.6-0.2-1.1-0.5-1.7-0.8c-0.5-0.2-1-0.3-1.4,0s-0.4-0.1-0.7-0.2c0.4-0.3,1.2-0.2,0.8-1.1c0-0.2,0.2-0.3,0.4-0.4
		c2.1-0.5,4.2-0.5,6.2,0.5c0.3,0.2,0.7,0.3,0.6,0.7c0.2,0.7,0,1.2-0.7,1.2c-0.6,0.1-0.6,0.5-0.5,0.8c0.4,1,0,1.9-0.5,2.8
		c-1,1.5-2.1,2.9-3.7,3.9c-1.1,0.7-1.9,1.6-2.9,2.4c-0.4,0.3-0.8,0.7-0.8,1.2c-0.1,0.7-0.4,1.3-1,1.7c-0.3-0.3-0.6-0.5-0.8-0.8
		C86.2,879.7,85.9,879.2,85.7,878.8z"
      />
      <path
        class="st1247"
        d="M75.1,888.8c0.2,1.7-1.4,1.8-2.4,2.5c-1.2,0.7-2.3,1.4-3.7,1.8c-0.7,0.2-1.3,0.1-1.7-0.4
		c-0.3-0.6,0.2-1.1,0.7-1.5c1.1-0.8,1.9-2,2.8-3c0.6-0.7,1.4-1.1,2-1.8c0.5,0.9,0.8,1.9,2,2.1C75.1,888.4,75.1,888.6,75.1,888.8z"
      />
      <path
        class="st1248"
        d="M184.9,894c-0.4-0.1-0.7-0.2-1.1-0.4c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.6,0.1-0.9,0.6-1.2
		c1.7-1,3.4-1.9,5.3-2.6c1.3-0.5,2.7-0.9,4.1-1.2c0.4-0.1,0.9-0.3,1.4-0.8c-1.3-0.1-2.3,0.1-3.4,0c-0.1,0-0.1,0-0.2,0
		c-3-0.2-5.9,0.6-8.7,1.7c-1.4,0.6-2.9,0.7-4.5,0.8c-0.2-0.1-0.4-0.3-0.6-0.4c0-1.2,0-1.2,1.5-1.1c1.7-0.3,3.2-0.8,4.5-2
		c0.7-0.7,1.5-0.9,2.4-0.9c2.6,0.1,5.2-0.5,7.8-0.8c1.4-0.2,2.9,0,4.1,0.7c1.4,0.8,1.4,2.1,0,3c-1.1,0.6-2.3,0.8-3.4,1.1
		c-2.8,0.8-5.6,1.7-8.1,3.3C185.7,893.4,185.3,893.6,184.9,894z"
      />
      <path
        class="st1249"
        d="M178.7,889c-1-0.5-0.9,0.4-1.3,0.8c-0.4-0.3-1.2-0.3-1.1-0.9c0.1-0.6,0.8-0.6,1.3-0.7
		c1.1-0.3,1.9-0.8,2.1-2c0.1-0.6,0.6-0.7,1-0.8c1.7-0.5,3.4-0.6,5.1-0.8c2.4-0.3,4.9-0.7,7.3-0.8c1.7-0.1,3.4-0.6,5-0.1
		c0.8,0.3,1.7,0.5,2.6,0.8c1.7,0.4,2.3,2,1.6,4.1c-0.1,0.3-0.3,0.7-0.4,1c-0.5,0.2-1.1,0.4-1.5,0.8c-0.3,0.1-0.5,0.2-0.8,0.3
		c-0.2-0.7,0.3-0.9,0.7-1.1c-0.9,0.2-1.8,0.5-2.8,0.7c-3.4,0.5-6.6,1.7-9.7,3.3c-0.5,0.3-1.1,0.8-1.7,0.1c0-0.4,0.3-0.6,0.6-0.7
		c3-1.8,6.3-3,9.8-3.7c0.8-0.2,1.4-0.5,2.2-0.7c0.5-0.1,1-1.6,0.7-2.2c-0.4-0.8-1.1-1.3-2-1.5c-2.4-0.5-4.8-0.1-7.2,0.2
		s-4.8,0.5-7.2,1c-0.4,0.1-0.8,0.1-1,0.4c-0.7,1.1-1.8,1.6-2.9,2.1C178.9,888.7,178.8,888.8,178.7,889z"
      />
      <path
        class="st1250"
        d="M178.1,890.2c0-0.3,0.1-0.5,0.4-0.5c2.5,0.3,4.8-0.7,7-1.6c0.8-0.3,1.6-0.5,2.5-0.6c2.2,0,4.4-0.1,6.6-0.2
		c0.2,0,0.6-0.1,0.6,0.2c0.1,0.4-0.3,0.4-0.5,0.6c-0.4,0.4-1,0.5-1.6,0.7c-1.5,0.4-3.1,0.6-4.6,1.3c-1.6,0.8-3.2,1.4-4.6,2.4
		c-0.3,0.2-0.5,0.5-0.3,0.9c-0.4,0.3-0.8,0-1.1-0.2c-0.4-0.2-0.8-0.5-1.2-0.5c-1.1-0.5-2.2-0.9-2.8-2
		C178.3,890.5,178.2,890.3,178.1,890.2z"
      />
      <path
        class="st1251"
        d="M178.5,890.6c0.9,0.7,1.9,1.4,2.9,1.9c0.5,0,0.9,0.2,1,0.8c0,0.5-0.3,0.8-0.8,1c-0.4,0.2-0.6,0.4-1,0.6
		c-0.3,0.2-0.6,0.4-0.9,0.1c-1-0.4-1.7-1.5-2.9-1.5c-1.3-0.6-1.3-1.8-1.2-3c0-0.5,0.1-0.9,0-1.4
		C176.6,889.6,177.4,890.4,178.5,890.6z"
      />
      <path
        class="st1252"
        d="M176.4,893.5c0.1,0.1,0.1,0.2,0.2,0.2c-0.4,3.8-2.9,6.1-5.8,8.2c-1.4,1-3,1.2-4.6,1.8c-0.4-1-0.5-2-0.5-3.1
		c-0.1-0.1-0.1-0.3-0.1-0.4c0.4-1.8,1.2-3.4,2.2-4.9c0.9-0.9,1.9-1.5,3.1-1.8c0.5-0.1,0.9-0.4,1.4-0.5c0.7-0.1,1.4-0.3,2.1-0.2
		c0.2,0,0.4,0,0.6,0.1c0.3,0.1,0.5,0.2,0.7,0.3C175.9,893.5,176.1,893.5,176.4,893.5z"
      />
      <path
        class="st1253"
        d="M175.7,893.3c-0.2,0.1-0.4,0.1-0.6-0.2c-0.6-0.8-0.7-1.8-0.9-2.7c-0.5-2.3-0.7-4.6-1.4-6.9
		c-0.1-0.6-0.7-0.9-0.7-1.6c0.3,0,0.6,0,0.8,0c0.8,0.7,0.8,1.7,0.9,2.6c0.4,2.3,0.7,4.6,1.2,6.9C175.4,892,175.4,892.7,175.7,893.3z
		"
      />
      <path
        class="st1254"
        d="M174.5,893.1c-0.8,0.1-1.5,0.1-2.3,0.2c-0.5-1-0.5-2-0.7-3c-0.1-0.9-0.4-1.9-0.5-2.8c0-0.3-0.1-0.7,0.1-1
		c-0.3-0.9-0.1-1.9-0.6-2.7c-0.1-0.7-0.1-1.4,0.8-1.7c1.5,0.3,1.5,1.6,1.7,2.7c0.4,2.1,0.8,4.3,1.4,6.4
		C174.6,891.8,174.9,892.4,174.5,893.1z"
      />
      <path
        class="st1255"
        d="M174.5,893.1c-0.4-2.3-0.8-4.6-1.5-6.8c-0.3-0.8-0.1-1.7-0.4-2.5c-0.3-0.7-0.6-1.2-1.2-1.6
		c0.2-0.2,0.6-0.2,0.9-0.2c1.4,1.2,1.3,2.9,1.6,4.4c0.5,2.2,0.8,4.5,1.4,6.8C174.9,893.1,174.7,893.1,174.5,893.1z"
      />
      <path
        class="st1256"
        d="M186.2,893.8c0.5,0.2,0.9,0,1.3-0.2c3.2-1.8,6.7-3.1,10.4-3.6c0.9-0.1,1.7-0.4,2.5-0.7
		c0.2-0.1,0.5-0.1,0.7,0.2c-0.6,0.4-1.4,0.4-1.6,1.2c-0.7,0.4-1.4,0.8-2.1,1.2c-1-0.5-2,0-3,0.1c-1.8,0.3-3.5,1.1-5.1,2.1
		c-0.2,0.2-0.6,0.2-0.9,0.3c-0.6,0.2-0.8,0.5-0.8,1.3c-0.6-0.6-1.5-0.7-1.9-1.3C185.3,893.8,185.8,893.8,186.2,893.8z"
      />
      <path
        class="st1257"
        d="M153.3,893.5c0,0.4-0.3,0.7-0.6,0.8c-0.5,0-0.9-0.2-1.3-0.5c-1-0.8-2.2-1.2-3.4-1.3c-0.6,0-1.2-0.1-1.4-0.8
		s0.2-1.2,0.6-1.7c1.6-1.7,3.1-3.5,4.3-5.5c0.5-0.8,0.9-1.5,1.8-1.9c0.7-0.1,0.8,0.4,1.1,0.8c0.5,0.8,0.9,1.6,1.1,2.5
		c0.3,0.9,0.2,1.8,0.2,2.8c0,0.5,0.1,1-0.2,1.5c-0.5,0.2-0.9-0.2-1.3-0.4c-0.8-0.4-1.4-0.3-1.9,0.4c-0.7,0.9-0.9,2-0.5,2.6
		c0.4,0.5,1,0.4,1.5,0.6C153.2,893.5,153.2,893.5,153.3,893.5z"
      />
      <path
        class="st1258"
        d="M153.3,882.7c-0.1,0.3,0.1,0.9-0.6,0.6c-0.2-0.1-0.3,0.2-0.3,0.3c-0.9,2.3-2.9,3.8-4.1,5.9
		c-0.2,0.4-0.7,0.6-1.1,1c-0.3,0.4-0.6,0.8-0.4,1.3c0.2,0.4,0.6,0.5,1.1,0.5c1.9,0,3.4,1.1,4.9,2.1c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.1,0.1-0.2,0.1c-2.3-0.1-4.5,0.9-6.8,0.6c-1.2-0.2-2.3,0.2-3.5,0.4c-0.2-0.2-0.4-0.3-0.6-0.4c-0.3-0.5,0-0.8,0.3-1
		c1.4-0.6,1.4-1.7,1.1-2.9c0-0.5,0.4-0.8,0.8-1c3.4-1.5,5.7-4.1,7.3-7.4c0.4-0.8,0.7-1.4,1.8-1.2
		C153.1,881.9,153.3,882.2,153.3,882.7z"
      />
      <path
        class="st1259"
        d="M153.3,902.1c-0.1-0.2-0.3-0.2-0.4-0.1c-1.1,0.5-1.1,0.5-1.2-0.8c-0.1-1-0.5-1.8-0.9-2.8
		c0.6,0.2,1.1,0.4,1.6,0.6c0.3,0.1,0.5,0.3,0.8,0.4c-0.2-0.2-0.5-0.2-0.7-0.5s-0.1-1-0.8-0.7c-0.2,0.1-0.4-0.1-0.3-0.3
		c0.1-0.3,0.2-0.7,0.7-0.7c1.4,0.2,2.7-0.4,4-0.3c1.6,0.1,3.2-0.7,4.7,0c0.1,0,0.2-0.1,0.3,0c1.6,0.9,3.3,1.5,4.8,2.7
		c0.1,0.1,0.1,0.2,0.1,0.3c-0.4,1.1-0.5,1.2-1.5,0.7c-0.8-0.4-1.6-0.8-2.4-1.2c-1.2-0.7-2.5-1-3.7,0.1c-0.4,0.3-1.2,0.4-1.3,0.8
		c-0.4,1.1-1.1,1-2,1.1c-0.5,0.1-0.8,0.6-1.4,0.7C153.4,902.1,153.3,902.1,153.3,902.1z"
      />
      <path
        class="st1259"
        d="M165.5,901.9c0.1,0.8,0.3,1.7,0.4,2.5c-0.4-0.3-0.8-0.1-1.2,0.1c-1.4,0.8-3.1,0.8-4.6,0.9
		c-3.2,0.2-6.1-0.5-9.1-1.8c0.7-0.7,1.5-1,2.2-1.5l0,0c0.3,0.4,0.6,0.6,1.2,0.6c0.8,0,1.6,0.1,2.4,0.3c1.4,0.4,2.9,0,4.3,0.1
		c1,0,2.2-0.5,3.1-1.4C164.7,901.3,165.2,900.9,165.5,901.9z"
      />
      <path
        class="st1260"
        d="M142,895.6c0.3-0.4,0.7-0.4,1.2-0.5c2.3-0.5,4.7,0.3,7-0.7c0.6-0.3,1.5,0.1,2.3,0.1
		c-0.6,0.9-1.6,1.5-2.6,1.8c-0.4,0.1-0.7,0.3-1,0.5c-0.9,0.3-1.8,0.5-2.7,0.2C144.7,896.9,143.2,896.5,142,895.6z"
      />
      <path
        class="st1261"
        d="M165.6,899.8c-1.7-0.9-3.4-1.8-5-2.7c-2.1-1.1-4.1-2.2-6.2-3.3c-0.3-0.2-0.7-0.3-1.1-0.3l0,0
		c-0.3-0.3-0.7-0.7-1-1.1c-0.5-0.8-0.2-1.7,0.7-2.1c0.3,1.8,1.8,2.6,3.1,3.3c2,1.1,4.1,2,6,3.2c1.2,0.8,2.7,1.3,3.7,2.5
		C165.8,899.6,165.9,899.8,165.6,899.8z"
      />
      <path
        class="st1014"
        d="M146.2,897.1c0.9-0.1,1.8-0.1,2.7-0.2c0.3,0.2,0.6,0.4,0.9,0.6c-1.2-0.7-2.4,0.1-3.6-0.2
		C146.2,897.2,146.2,897.2,146.2,897.1z"
      />
      <path
        class="st1262"
        d="M143.1,891.2c0.1,0.4,0.2,0.7,0.2,1.1c0.1,0.8,0,1.5-0.9,1.8c-0.5,0.2-0.9,0.5-1,1.1
		c-0.4-0.3-0.6,0.1-0.8,0.3c-0.6,0.5-1.1,1-1.6,1.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.5-0.3-0.6-0.6
		c0-0.1,0-0.2,0-0.3c0.1-0.3,0.2-0.5,0.5-0.7c1.8-1.2,3.3-2.6,4.1-4.7c0.1-0.1,0.2-0.2,0.3-0.3C142.7,890.6,142.9,890.9,143.1,891.2
		z"
      />
      <path
        class="st1263"
        d="M142,899.8c-0.5,0.2-0.8,0.8-1.4,0.4c0.9-0.5,1.6-1.1,2.6-1.3C142.8,899.2,142.4,899.5,142,899.8z"
      />
      <path
        class="st1264"
        d="M165.5,901.9c-0.3-0.5-0.7-0.6-1.1-0.1c-0.8,1.1-2.3,1-3.3,1.8c0,0-0.1,0-0.2,0c-2.4-0.5-4.8-0.3-7.1-0.7
		c-0.7-0.1-0.3-0.6-0.6-0.9c0.2-0.2,0.4-0.3,0.6-0.5c0.7-0.4,1.4-1.1,2.4-0.3c-0.5-0.6-0.5-0.8,0.2-1.1c0.9-0.4,1.6-1,2.5-1.5
		s1.8,0,2.6,0.4c1,0.4,1.9,1,2.8,1.5c0.6,0.3,1.1,0.5,1.2-0.5c0.2-0.1,0.3,0,0.2,0.2c0,0.1,0,0.3,0,0.4
		C165.4,901,165.6,901.5,165.5,901.9z"
      />
      <path
        class="st1265"
        d="M166.2,903.7c0.5-0.7,1.3-0.7,2.1-0.9c1.2-0.3,2.2-1,3.2-1.7c1.8-1.3,3.2-2.9,4.2-4.9
		c0.2-0.4,0.5-0.8,0.3-1.2c-0.3-0.7,0.2-1,0.5-1.4c0.4,0,0.7,0.2,1,0.4c1.1,1.3,1.1,2,0,3.4c-1.7,2.2-3.6,4.3-5.9,6
		c-1.1,0.8-2.3,1.3-3.5,2.1c-0.5,0.3-0.7-0.3-1.1-0.2C166.9,904.9,166.3,904.4,166.2,903.7z"
      />
      <path
        class="st1266"
        d="M186.2,893.8c-0.1,0.3-0.7,0.1-0.4,0.6c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.3-0.1-0.6,0.2-0.7
		c1.9-0.8,3.6-2.2,5.6-2.7c2.1-0.5,4-1.3,6-1.8c0.4-0.1,0.7-0.2,1-0.5c0.9-0.8,0.9-1.6-0.2-2.2c-1.1-0.7-2.4-0.8-3.6-0.8
		c-1.8,0-3.5,0.5-5.2,0.7c-1,0.1-2,0.1-3,0.1s-1.8,0.3-2.5,1c-1.3,1.2-2.9,1.8-4.7,1.9c0-0.5,0.4-0.7,0.8-0.8
		c0.9-0.4,1.8-0.8,2.2-1.8c0.2-0.4,0.6-0.5,1.1-0.5c2.9-0.6,5.9-0.8,8.9-1.2c1.7-0.2,3.4-0.5,5.2,0c0.9,0.2,1.8,0.3,2.4,1.1
		c0.6,0.7,0.9,1.3,0.2,2.2c-0.4,0.5-0.4,0.9-1.2,1.2c-1.6,0.4-3.2,1-4.9,1.3c-2,0.4-3.8,1.4-5.5,2.4
		C187.4,893.1,186.6,893,186.2,893.8z"
      />
      <path
        class="st1266"
        d="M179.7,895c0.9-0.1,1.6-0.8,2.2-1.2c0.6-0.4-0.1-0.9-0.6-1.2c0.5-0.4,0.8,0.2,1.1,0.3
		c0.4,0.2,0.8,0.3,1.2,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.7,1.2,0.8,1.6,1.2c-1.1,1.3-2.6,2-4.3,2.3
		C180.7,896.4,180,895.8,179.7,895z"
      />
      <path
        class="st1267"
        d="M181.8,902.1c-0.3-0.1-0.5-0.4-0.8,0.1c-0.9,1.4-2.3,2.2-3.8,2.6c-0.8,0.2-1.6,0.2-2.3,0.5
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4,0-0.9,0.2-1.2-0.4c-0.2-0.5,0.1-0.8,0.4-1.2c0.9-1,2.1-1.8,2.9-2.9c1.2-1.6,2.6-3,3.3-4.8
		c1.3,1.1,1.9,2.6,2.1,4.3C181.9,900.9,181.8,901.5,181.8,902.1z"
      />
      <path
        class="st1268"
        d="M171.2,877.7c-0.5,0.6-0.7,1.3-1.1,2c-0.2,0.3-0.3,0.7-0.8,0.7c-0.3-0.3-0.2-0.7,0-1.1
		c0.8-1.8,1-3.6-0.1-5.4c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.6,0.8-0.8,0.8-1.4c0.1-0.3,0.1-0.7,0.3-1c0.4-0.2,0.6,0.1,0.9,0.2
		c0.4,0.2,0.7,0.5,1.1,0.6c0.8,0.3,1.7,0.7,2.4,0c0.7-0.6,1.4-1.4,0.8-2.5c-0.1-0.2,0.1-0.3,0.2-0.4c0.5,0.2,0.9,0.5,1.3,1
		c-0.3,2.4-1.9,3.8-3.7,4.9C172.2,875.5,171.8,876.7,171.2,877.7z"
      />
      <path
        class="st1269"
        d="M164.7,865c0.2,0.6,0.4,1,1.1,0.4c0.3-0.3,0.8-0.2,1.2-0.2c0.7,0.3,1.3,0.4,1.9-0.1
		c0.3-0.2,0.6-0.3,0.9-0.1c0,1.1-0.5,2.2-1,3.1l-0.1,0.1c-0.4,0.3-0.7,0.8-1.2,1c-0.3,0-0.5-0.2-0.7-0.4c-0.8-0.8-1.6-1.6-2.5-2.3
		c-0.3-0.2-0.6-0.5-0.5-0.9C163.9,865.2,164.3,865.1,164.7,865z"
      />
      <path
        class="st1270"
        d="M175.8,869c0.4,0.9,0.3,1.7-0.3,2.5c-0.7,1-1.3,1.3-2.6,1c-1-0.2-1.8-0.8-2.5-1.4c-0.1-0.1-0.1-0.1-0.1-0.2
		c-0.5-0.9-1-1.7-1.4-2.7h0.1c0.5,0,1,0,1.4,0c0.2,0.9,1.9,2.5,2.8,2.6c0.6,0,0.8-0.4,1-0.9c0.2-0.4,0-0.9,0.4-1.3
		C174.9,868.7,175.3,868.8,175.8,869z"
      />
      <path
        class="st1271"
        d="M174.5,868.5c0,0.3,0,0.7,0,1c-0.4,1.4-1.2,1.9-2.3,1c-0.6-0.5-1.2-0.9-1.8-1.4c-0.4-0.4-0.4-0.7-0.1-1
		C171.7,868.3,173.2,867.8,174.5,868.5z"
      />
      <path
        class="st1272"
        d="M155.1,885.8c-0.3-0.8-0.7-1.5-1-2.3c0.1-0.8,1-0.7,1.3-1.2c0.8-0.8,1.9-1.1,2.8-1.8
		c1.1-0.7,2.2-0.5,3.1,0.3l0,0c1.1,0.5,2.1,1.2,3.1,1.6c1.4,0.8,2.8,1.6,4.2,2.3c0.3,0.3,0.1,0.7,0.1,1c-0.1,0.6-0.8,0.6-1.1,1
		c-0.2,0.2-0.4,0.4-0.7,0.5c-0.6,0.2-1.2,0.4-1.4,1c-0.9,1-2.3,1.3-3.3,2.1c-0.4,0.3-0.9,0.6-1.3,0.8c-0.3,0.2-0.8,0.4-1,0
		c-0.2-0.3,0.3-0.5,0.5-0.7c0.2-0.1,0.3-0.2,0.5-0.3c0.8-0.6,1.7-1.1,2.6-1.5c-1.9-0.8-3.9-1.3-5.6-2.4
		C157.1,885.8,156.1,886,155.1,885.8z"
      />
      <path
        class="st1273"
        d="M167.1,887.1c0.2-0.1,0.4-0.3,0.5-0.4c0.6-0.2,1-0.8,1.7-0.4c0.6,0.1,1.3-0.1,1.9,0.2
		c0.2,1.3,0.4,2.5,0.6,3.8c-1.2,0.7-2.4-0.1-3.6-0.2c-0.8-0.1-1.4-0.6-2.2-0.9c-0.6-0.2-0.6-0.6-0.3-1
		C166.1,887.6,166.6,887.4,167.1,887.1z"
      />
      <path
        class="st1274"
        d="M164.1,879.8c-0.3-0.6-0.6-1.2-0.7-1.8c0-0.1,0-0.1,0-0.2c0.2-0.6,0.8-0.5,1.2-0.6c1.6-0.1,2.1-0.6,2.2-2.2
		c0-0.4-0.1-0.9,0.3-1.3c0.3-0.2,0.5-0.2,0.8,0c1.4,2.5,0.2,5.5-2.5,6.4C165,880.2,164.5,880.2,164.1,879.8z"
      />
      <path
        class="st1275"
        d="M169.3,873.3c0.1,0.8,0.6,1.3,0.8,2c0.4,1.3,0.1,2.5-0.4,3.7c-0.2,0.5-0.4,0.9-0.4,1.5
		c-0.6,0.8-1.6,1.2-2.4,1.6c-0.9,0.4-1.6,0-2.1-1.2c0.1-0.1,0.2-0.1,0.4-0.1c3-0.5,4.4-2.8,3.6-5.7c-0.1-0.5-0.3-1,0.1-1.5
		C168.9,873.3,169.1,873.2,169.3,873.3z"
      />
      <path
        class="st1276"
        d="M168.9,873.6c-0.5,1,0.3,1.9,0.4,2.9c0.3,2.4-2.3,5-4.5,4.4c-0.2-0.3-0.6-0.6-0.6-1c3.1-0.1,4.6-2,4-5
		c-0.1-0.3,0-0.7-0.1-1C168.3,873.5,168.5,873.3,168.9,873.6z"
      />
      <path
        class="st1277"
        d="M161.4,880.8c-1-0.3-2.1-0.8-3.1,0c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.4,0.3-0.8,0.5-1.1
		c0.1-0.1,0.3-0.2,0.4-0.2c1.7,0,2.9-1,4.2-2c0.4,0.1,0.3,0.4,0.2,0.7h-0.1c-0.4,0.5-1.1,0.8-1.7,1.2
		C160.6,879.8,160.6,879.8,161.4,880.8z"
      />
      <path
        class="st1278"
        d="M169.3,886.2c-0.6-0.1-1.1,0.5-1.7,0.4c0.3-0.5,0.9-0.6,1.3-0.9c0.5-0.2,0.5-1.1,1.2-0.8
		c0.2,0.1,0.6,0.1,0.6,0.5c0,0.3-0.3,0.3-0.5,0.4C169.9,886,169.6,886.1,169.3,886.2z"
      />
      <path
        class="st1278"
        d="M168.7,884.8c-1.5-0.6-2.9-1.4-4.3-2.3c0.1-0.1,0.2-0.1,0.3,0C166.2,883.1,167.5,883.8,168.7,884.8z"
      />
      <path
        class="st1279"
        d="M164.7,882.5c-0.1,0-0.2,0-0.3,0c-1.1-0.4-2.1-1-3.1-1.7C162.7,881,163.6,881.9,164.7,882.5z"
      />
      <path
        class="st1280"
        d="M159.3,841.2c0.3,0.1,0.7,0.2,1,0.2c0.1,0.1,0.4,0.2,0.2,0.4c-1.8-0.5-3,0-3.8,1.4
		c-0.4-1.1,0.5-1.4,1.1-1.8C158.3,841.2,158.8,841.2,159.3,841.2z"
      />
      <path
        class="st1281"
        d="M120.1,829.2c-0.8,1.4-2,2.3-3.3,3.1c-0.2,0.2-0.1,0.6-0.5,0.5c-0.5-0.2-0.9,0.2-1.3,0.3
		c-0.5,0.1-0.4,0.7-0.7,0.9c-0.8-0.2-1.3-1-2.2-0.8c-0.5,0-1,0-1.5,0c-0.7-0.1-1.6,0.1-1.9-0.9c0.6-0.8,1.5-1.4,2.1-2.2
		c0.7-0.9,2.2-0.9,3.4,0c0.6,0.4,1.1,0.4,1.7,0.1c1-0.5,2-0.9,3.2-0.7C119.5,829.5,119.6,828.8,120.1,829.2z"
      />
      <path
        class="st1282"
        d="M97.7,841.5c-0.1-0.1-0.2-0.3-0.3-0.4c0.3-0.4,0-0.9,0.4-1.3c2.9-2.8,5.2-6,8-8.8c0.4-0.4,0.7-0.9,0.9-1.3
		v-0.1c0.1,0,0.2,0,0.2,0c0.3,0.7,0.5,1.4,0.8,2.1c-0.7,1.1-1.8,1.9-2.6,3c-2.1,2.5-4.3,5-6.5,7.5c-0.1,0.1-0.3,0.1-0.4,0.1
		c-0.2,0-0.3-0.1-0.4-0.2C97.8,841.9,97.7,841.7,97.7,841.5z"
      />
      <path
        class="st1283"
        d="M127.2,826.5c-0.2-0.2-0.4-0.4-0.5-0.6c-1-1.6-1-1.6-2.9-1.2c-0.8,0.2-1.6,0.3-2.4,0.4
		c-0.2-0.7-0.3-1.4-0.2-2.1c0.1-0.3,0.4-0.4,0.6-0.5c2.1-0.4,4.2-0.8,6.2-1.2c0.3,0,0.5,0.2,0.8-0.1h0.1c0.6,1.7,0.2,2.6-1.3,3.2
		c-0.7,0.2-1,0.6-0.5,1.3C127.3,825.9,127.2,826.2,127.2,826.5z"
      />
      <path
        class="st1284"
        d="M105.1,834.6c0.8-1.1,1.6-2.1,2.7-2.9c0.2,0.3,0.5,0.5,0.8,0.6c0.5,0.6,1.2,0.6,1.9,0.6
		c0.4,0.2,1.3-0.3,1.3,0.8c-0.4,0.3-1,0.1-1.4,0.5c-1.5,0.6-3,1.1-4.6,0.7C105.6,834.8,105.3,834.8,105.1,834.6z"
      />
      <path
        class="st1285"
        d="M128.8,821.2c-0.6,0.5-1.4,0.5-2.1,0.6c-1.9,0.3-3.7,0.7-5.6,1.1c-0.2-0.2-0.2-0.4-0.2-0.6
		c2.2-0.3,4.4-0.9,6.7-1.5C128.1,820.8,128.5,820.9,128.8,821.2z"
      />
      <path
        class="st1286"
        d="M111,820.5c0,1.1-1.1,2-2.2,2c-0.7,0-1.3-0.7-1.4-1.5c0-1,1.3-2.3,2.2-2.3C110.4,818.8,111,819.6,111,820.5
		z"
      />
      <path
        class="st1287"
        d="M101.4,833.3c0.8-1,1.7-2,2.6-2.9c0.2-0.2,0.5-0.4,0.6-0.8c0.3-0.2,0.5-0.5,0.6-0.8
		c0.6,0.1,1.1,0.2,1.4,0.8c-0.3,0.7-0.9,1-1.4,1.5c-2.5,2.5-4.6,5.3-7.1,7.8c-0.6,0.5-1.1,1.1-1.3,2c-0.8,1.3-1.3,2.8-2.3,3.9
		c-1.3,1.6-2.8,3-4.3,4.3c-1.1,1-2.1,2.1-3.6,2.8c-0.6,0.3-1.1,0.3-1.3-0.4c2.3-1,4.3-2.6,6.1-4.4c1.8-1.8,3.8-3.6,4.4-6.3
		c0.3-1.5,1.6-2.3,2.5-3.4c0.9-1.1,1.8-2.2,2.8-3.1C101.4,834.1,101.6,833.7,101.4,833.3z"
      />
      <path
        class="st1288"
        d="M96.8,840.8c-0.3-0.4-0.2-0.7,0.2-1.1c2.2-2.6,4.6-5,6.8-7.5c0.8-1,1.6-2,2.8-2.6c0.1,0,0.1,0,0.2,0
		c0.1,0.5-0.1,0.8-0.4,1.1c-2.5,2.6-4.9,5.4-7.2,8.2c-0.6,0.7-1.4,1.3-1.8,2.2C97.2,841,97,840.9,96.8,840.8z"
      />
      <path
        class="st1289"
        d="M134.3,813.3c0.8,0.2,0.8-1,1.5-1c0.1-0.1,0.1-0.1,0.2,0c0.5,0.8,1.7,0.7,2.3,1.4c0,0.1,0,0.4-0.2,0.3
		c-0.7-0.3-0.3,0.1-0.2,0.4c-0.5,0.4-0.3,0.9-0.2,1.3c0.5,1.6-0.2,2.8-1.1,3.8c-1.2,1.4-2.6,2.5-4.2,3.5c-1,0.6-2.1,0.4-2.4-0.5
		c0-0.5,0.4-0.6,0.8-0.8c1.6-0.9,3.4-1.8,4.5-3.5c0.4-0.7,0.9-1.3,0.9-2.2c0-1.1-0.6-1.7-1.8-1.7c-0.7-0.2-1.5-0.2-1.8-1.1
		c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.2,0C133.3,813.4,133.9,812.9,134.3,813.3z"
      />
      <path
        class="st1290"
        d="M132.4,813.3c0.5,0.5,1.2,0.7,1.8,0.9c0.2,0.3,0.6,0.7-0.1,0.9H134c0.7,0.5,0.3,1.2,0,1.7
		c-1,1.9-2.7,3.1-4.7,3.9c-0.1,0-0.3,0-0.4,0c-0.2-0.2-0.2-0.6-0.7-0.4c-0.2-0.2-0.4-0.4-0.7-0.6c0.2-0.2,0.7,0.3,0.6-0.2
		c-0.2-1.1,0.5-1,1.1-1.1c0.1,0,0.2-0.1,0.4-0.2c0.9-0.5,1.5-1.3,1.8-2.3c0.7-0.4,0.6-1.1,0.3-1.6
		C131.9,813.7,132.1,813.5,132.4,813.3z"
      />
      <path
        class="st1291"
        d="M131.8,815.8c0,1.4-0.5,2-1.8,2.3c-1.4-0.1-1.8-0.4-1.6-1.3c0.2-1.3,1.4-2.5,2.4-2.2
		C131.3,814.8,131.4,815.4,131.8,815.8z"
      />
      <path
        class="st1292"
        d="M134.3,813.3c-0.5,0-1,0.2-1.4-0.2c0.4,0,0.5-0.1,0.6-0.5c0.2-2.6,0.1-5.2-0.4-7.7c-0.1-0.7,0.1-1.4-0.4-2
		c1.1-0.3,1.2,0.4,1.3,1.2c0.1,2.8,0.6,5.6,0.4,8.5C134.2,812.8,134.3,813.1,134.3,813.3z"
      />
      <path
        class="st1293"
        d="M129.1,820.6c1.6-0.7,2.9-1.6,4.1-2.8c1-1,1-2.3,1-3.6c0.5,0.2,1.1-0.5,1.4,0.1c0.4,0.8,0.9,1.6,0.5,2.7
		c-1.1,2.5-3.2,3.8-5.5,4.9c-0.3,0.2-0.5,0.4-0.8,0.6C129.7,821.9,129.4,821.2,129.1,820.6z"
      />
      <path
        class="st1294"
        d="M132.4,830.8c-0.1-0.3-0.2-0.7-0.2-1c0.1,0,0.2,0,0.2,0c1.2,0.8,1.3,2,1.5,3.3c0.7,4.5,0.1,9,0.4,13.5
		c0,0.5-0.1,1.1,0.6,1.2c-0.6,0.8-1.1,0.4-1.6-0.1c-0.2-0.6-0.2-1.2-0.2-1.7c0-3.5,0-6.9,0-10.4c0-1.2,0-2.5-0.4-3.7
		C132.8,831.5,132.6,831.2,132.4,830.8z"
      />
      <path
        class="st1295"
        d="M132.4,830.8c0.1,0.2,0.3,0.4,0.4,0.6c0.3,1.1,0.4,2.2,0.4,3.4c0,3.7,0.1,7.5-0.3,11.2
		c-0.1,0.6,0,1.2,0.5,1.6c0.5,0.1,0.9,0.4,1.5,0.1c0.4,0.1,0.8-0.4,1.2-0.2c-0.1,0.7-0.7,0.7-1.2,0.9c-0.5,0-0.9,0.2-1.4,0.1
		c-0.8-0.2-1.5-0.3-1.9-1.1c-0.2-0.4-0.5-0.3-0.9-0.2c-0.7,0.3-1.3,0.8-1.9,1.3c-0.3,0.3-0.6,0.6-1.1,0.5c-0.4-0.5-0.6-1.2-1-1.8
		c-0.7-0.9-1.5-1.8-2.5-2.3c-0.7-0.5-1.5-0.9-2.3-1.1c-0.5-0.1-1-0.2-1.4-0.6c-0.2-0.4-0.5-0.7-0.2-1.2c1-0.6,1.9-1.2,2.9-1.8
		c1.6-1,3.2-1.1,4.9-0.2c1.2,0.6,2.4,1.2,3.5,1.8c0.7,0.4,1,0.1,1-0.6C132.6,837.8,132.9,834.3,132.4,830.8z"
      />
      <path
        class="st1296"
        d="M134.9,848.2c0.5-0.1,0.9-0.3,1.2-0.7c0.4-0.2,0.5-0.8,1.1-0.7c0.7,0.2,1.5,0.5,2,1.1
		c0,0.5-0.5,0.2-0.6,0.5c-0.2,0.2-0.3,0.5-0.2,0.8c0.7,3.3-1.3,5.4-3.6,7.3c-0.5,0.4-1,0.8-1.5,1.1c-1.4,1.1-2.6,0.6-2.9-1.1
		c0.1-0.3,0.3-0.4,0.5-0.4c2.7-0.6,4.2-2.6,5.6-4.7c0.3-0.5,0-2.4-0.3-2.8c-0.2-0.2-0.5-0.1-0.8-0.1
		C135.3,848.5,135,848.5,134.9,848.2z"
      />
      <path
        class="st1297"
        d="M153.1,842.7c0,0.3,0.4,0.5,0.4,0.8c-0.9,1-2.2,1.5-3.2,2.2c-1.6,1.2-3.4,2.1-5,3.2c-1.1,0.7-2,1.5-3.2,1.8
		c-0.8-0.3-1.3-0.9-1.4-1.8c0.6,0.3,1.1-0.2,1.6-0.4c2.4-1.5,4.7-2.9,7-4.4c0.9-0.6,1.8-1.4,2.6-2.2c0.3-0.3,0.2-0.5,0-0.8
		c-0.2-0.2-0.2-0.4,0.2-0.5c0.1,0.2,0.2,0.4,0.3,0.6C152.4,841.8,152.7,842.3,153.1,842.7z"
      />
      <path
        class="st1298"
        d="M140.6,849c0.5,0.6,0.8,1.2,1.4,1.7c1,1.2,1.1,2.6,1.2,4.1c-0.3,0.4-0.6,0.2-0.9,0.1
		c-1.2-0.2-1.8,0.3-2.2,1.5s-0.1,2.1,0.9,2.6c0.1,0.1,0.2,0.1,0.3,0.2c0,0.4-0.3,0.5-0.7,0.6c-1-0.3-2-0.5-3.1-0.3
		c-0.5,0.1-1,0-1.6,0c-0.5,0-0.9-0.2-1.1-0.7c-0.2-0.5-0.1-1,0.3-1.2c1.2-0.9,1.8-2.4,2.7-3.5s1.1-2.4,0.9-3.7
		c-0.1-0.7-0.1-1.4-0.1-2.2c0.2-0.1,0.4-0.1,0.6-0.2c0.3,0.2,0.6,0.3,0.8,0.5c0.1,0.1,0.3,0.2,0.4,0.4
		C140.5,848.8,140.6,848.9,140.6,849z"
      />
      <path
        class="st1299"
        d="M97.2,867.1c-1.8-1.3-3.8-1.3-5.8-1.1c-0.5,0-1,0-1.5,0.3c-0.7-0.1-0.8-0.8-1.2-1.3
		c-0.4-0.6-0.7-1.2-1.1-1.8c3.6-2.3,7.1-4.5,10.7-6.8c0.8-0.5,1.8-0.8,2.3-1.7c1.4,0.3,2.6,1.6,2.8,3c0.1,0.9,0.2,1.9,0.2,2.8
		c0,1-0.1,2,0.4,2.9c-0.2,0.2-0.5,0.3-0.7,0.2c-0.5-0.2-0.9-0.2-1.3,0.1c-1.3,0.7-2.1,1.8-2.7,3.2c-0.2,0.4-0.2,0.8-0.7,1
		C98.1,867.5,97.6,867.3,97.2,867.1z"
      />
      <path
        class="st1300"
        d="M98.9,853.5c-1.1,0.9-2.1,1.8-3.5,2.1c-1.8,0.6-3.3-0.3-4.9-0.9c-0.6-0.2-0.8-0.8-0.7-1.5
		c0.6-1.1,1.6-1.9,2.5-2.7c0.2-0.2,0.6-0.5,0.9-0.1c0.1,0.9,0.6,1.2,1.4,1c1.3-0.2,2.6-0.1,3.9-0.3c0.8-0.1,1.4,0.2,1.5,1.1
		C99.9,852.9,99.5,853.3,98.9,853.5z"
      />
      <path
        class="st1301"
        d="M98.9,853.5c0.3-0.4,0.6-0.8,1-1c0.2-0.1,0.4,0,0.6,0.1c0.8,0.6,1.7,1.2,2.6,1.7c0.7,0.4,0.9,1,1.1,1.8
		c0.4,2,0.4,4,0.5,6c0,0.8,0.9,0.7,1.1,1.3c-0.7,0.5-1.3,0.7-1.9-0.1c-0.6-0.6-0.8-1.2-0.6-2.1c0.2-1.4-0.1-2.9-0.2-4.3
		c0-0.3-0.2-0.6-0.5-0.8c-0.7-0.5-1.4-1-2.1-1.6C100.1,854.1,99.6,853.7,98.9,853.5z"
      />
      <path
        class="st1302"
        d="M89.9,853.1c-0.1,1,0.4,1.5,1.3,1.6c1.4,0.2,2.6,1.2,4.1,0.9c-3.2,0.9-6,0.2-8.6-1.9
		c0.5-0.8,1.3-1.3,2.1-1.9c0.4,0,0.9-0.3,1.3,0C90.4,852.4,90.1,852.8,89.9,853.1z"
      />
      <path
        class="st1288"
        d="M90.2,851.9c-0.4,0.2-0.8,0.4-1.2,0c1.1-1.1,2.1-2.3,3.2-3.4c1.9-1.8,3.6-3.8,4.9-6.2
		c0.2-0.3,0.3-0.7,0.7-0.8c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.9-0.4,1.6-0.8,2.2c-1.9,2.8-4.2,5.2-6.6,7.5
		C90.5,851.8,90.3,851.9,90.2,851.9z"
      />
      <path
        class="st1088"
        d="M142.3,801.5c0,0.4-0.7,0.5-0.3,1c-0.1,0.2-0.2,0.5-0.3,0.5c-0.3,0-0.2-0.3-0.2-0.5
		c-0.1-0.2-0.3-0.4-0.5-0.6C141.6,802.3,141.8,801.5,142.3,801.5z"
      />
      <path
        class="st1303"
        d="M90.1,832.3c-1.8,2.8-3.8,5.3-5.8,7.9c-0.8,0.2-1.4-0.2-1.7-0.9c2.1-2.6,4.2-5.2,5.9-8.1
		C89,831.6,89.6,831.9,90.1,832.3z"
      />
      <path
        class="st1304"
        d="M81.8,838.8c0.9-1.1,1.8-2.2,2.7-3.3c1.1-1.5,2.2-3,3-4.7c0,0,0.1-0.1,0.2-0.1c0.2,0.2,0.4,0.3,0.6,0.5
		c0,0.9-0.7,1.6-1.2,2.2c-1.2,1.7-2.4,3.5-3.8,5.2c-0.3,0.4-0.6,0.8-1.2,0.6C81.9,839.1,81.8,839,81.8,838.8z"
      />
      <path
        class="st1244"
        d="M82,839c0.3,0,0.6,0,0.8-0.2c0.8-1.2,1.9-2.3,2.7-3.6c0.7-1.2,1.6-2.2,2.3-3.3c0.1-0.2,0.3-0.5,0.4-0.7
		c0.1,0,0.2,0.1,0.3,0.1c-0.2,1.3-1,2.2-1.8,3.1c-1.1,1.4-2.1,2.9-3.2,4.3c-0.2,0.3-0.4,0.6-0.8,0.7S82.1,839.4,82,839z"
      />
      <path
        class="st1085"
        d="M95.8,832.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.1,0-0.2-0.1-0.2-0.1C94.8,832.3,95.2,831.9,95.8,832.3z"
      />
      <path
        class="st1305"
        d="M82,839c0.2,0.1,0.4,0.3,0.7,0.4c0.5,0.3,1,0.8,1.7,0.8c-0.1,1.1,0.9,1.7,1.1,2.7c0.3,0.7-0.2,1-0.5,1.5
		c-0.3-0.1-0.6-0.1-0.8-0.2c0.1-0.3,0.7-0.3,0.4-0.8c-0.2-0.4-0.5-0.5-0.8-0.5c-0.7,0.1-1.1-0.3-1.4-0.8c-0.5-1-1.6-1.7-1.2-3
		c0.1-0.2,0.2-0.3,0.3-0.3c0.1,0,0.2,0,0.3,0.1C81.8,838.9,81.9,838.9,82,839z"
      />
      <path
        class="st1306"
        d="M81.1,875.6c-1.4,0.2-3-0.1-4.4-1c-1.1-0.7-1.1-1.2,0-1.8c1.4-0.8,3-0.4,4.6-0.4c0.6,0,1.1,0.2,1.7,0.3
		c1.2,0.2,1.2,0.2,1.1,2c0,1-0.7,0.9-1.4,0.9C82.3,875.6,81.8,875.6,81.1,875.6z"
      />
      <path
        class="st1307"
        d="M88.3,869c0.7-0.3,1.3-1,2.2-0.4c0.7,0.5,0.8,1.1,0.7,1.9c-0.1,0.7-0.6,1-1,1.3c-1.8,0.3-2.7-0.3-2.5-1.8
		C87.7,869.6,88.1,869.4,88.3,869z"
      />
      <path
        class="st1308"
        d="M149.7,862.3c0,0.5,0.5,0.7,0.6,1c0,0.3,0.1,0.5,0.4,0.6c0.5,0.4,1,0.9,1.7,1.1c0.4,0.1,0.5,0.4,0.7,0.7
		c0.8,0.8,0,1.6-0.2,2.4c-0.3,0.9-1,1.6-1.4,2.5c-0.1,0.2-0.3,0.4-0.6,0.2c0-1.1,0.4-2.1,0.8-3.1c0.2-0.4,0.6-0.9,0.1-1.3
		c-0.4-0.4-0.9-0.2-1.3-0.1c-1.8,0.6-3.7,1.1-5.4,2c0,0.2,0.1,0.2,0.2,0.3c0.3,0.3,1,0.5,0.7,1c-0.3,0.4-0.9,0-1.2-0.2
		c-0.7-0.5-1.6-0.6-2.2-1.1c-0.4-0.2-0.7,0-1.1,0.2c-3.8,1.9-7.8,2.7-12,2.7c-1.6,0-3.1,0.4-4.6,0.6c-0.5,0.1-0.9,0.2-1.4,0
		c-0.6-0.5-0.8-1.2-0.9-2c0.8-0.2,1.7-0.3,2.4-0.7c0.6-0.3,1.2-0.5,1.7-1.1c0.3-0.4,0.8-0.6,1.3-0.3s0.9,0.2,1.4-0.2
		c0.2-0.2,0.5-0.4,0.8-0.4c0.9,0,1.5-0.4,1.9-1.2c0.1-0.2,0.3-0.4,0.6-0.4c0.3-0.1,0.5,0,0.8,0c2.8,0.3,4.9-0.8,6.4-3.1
		c0.8-1.3,1.7-2.5,2.7-3.7c0.9-0.2,1.8,0.1,2.8,0.1s2,0.1,3,0.3c0.3,0.2,0.4,0.4,0.3,0.8C148.7,860.9,148.9,861.7,149.7,862.3z"
      />
      <path
        class="st1088"
        d="M148.7,860c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0-0.4-0.1-0.7c0-0.4,0-0.9,0-1.3
		c0-0.4,0.1-0.8,0.2-1.1c0.7-1.4,1.2-3,2.8-3.7c1.6-0.5,2.8-1.5,4.2-2.4c0.3-0.2,0.3-0.6,0.4-1c0.3-1,0.2-2.1,0.9-3
		c0.9,0,0.8,0.6,0.6,1.1c-0.2,0.6-0.4,1.2-0.5,1.8c-0.1,1,0,1.2,1.1,1.2c0.2,0,0.3,0,0.5,0s0.3,0.1,0.4,0.2c0.3,0.2,0.7,0.3,1,0.5
		c-1.6,0.9-1.7,2.8-2.9,4.1c-2.1,2.2-4.3,4.2-7.2,5.1C149.3,860,149,860,148.7,860z"
      />
      <path
        class="st1309"
        d="M164.9,859.4c0,1-0.4,1.9-0.8,2.7c-0.1,0.2-0.2,0.4-0.4,0.4c-1.4,0.1-2.7,0.8-4.1,1
		c-0.9,0.1-1.9,0.1-2.8-0.2c-0.5-0.2-1-0.3-0.8-1.1c0.9-0.5,1.2-1.6,2.1-2.1c0.1,0.8,0.7,0.7,1.2,0.6c1.5-0.3,3-0.7,4.2-1.8
		C164.1,858.5,164.6,858.5,164.9,859.4z"
      />
      <path
        class="st1310"
        d="M156,862.3c0.2,1.1,1.2,0.9,1.9,1c1.5,0.2,3-0.2,4.4-0.9c0.6-0.3,1.3,0.2,1.9-0.3c-0.2,1-0.5,2-1.3,2.7
		c-0.4,0.2-0.8,0.4-1.1,0.7c-1.3,1-2.9,1.3-4.5,1.5c-1.4-0.1-2.7-0.8-4.1-1c-0.1,0-0.2,0-0.3-0.1c0-0.2-0.1-0.5-0.4-0.5
		c-0.8-0.1-1.2-0.7-1.7-1.3c0.5-0.1,0.7,0.8,1.1,0.5c0.6-0.4,1.4-0.7,1.8-1.3c0.3-0.4,0.6-0.9,1.2-1
		C155.3,862.1,155.6,862.1,156,862.3z"
      />
      <path
        class="st1311"
        d="M161.8,865.2c0.3-0.3,0.5-0.7,1-0.4c0.3,0.4,0.6,0.7,1.1,0.9c1.2,1.2,2.5,2.3,3.8,3.5
		c1,0.4,1.8,1.2,2.7,1.7c0,0.1,0,0.1,0,0.2c-0.1,0.4,0.2,0.8-0.3,1.1c-0.4,0.6-0.9,1.2-1.7,1.2c-0.1-2.3-1.9-3.5-3.4-4.9
		c-0.8-0.7-1.5-1.5-2.5-2C162.2,866.2,161.8,865.8,161.8,865.2z"
      />
      <path
        class="st1312"
        d="M158.7,850.2c-0.1,0-0.3,0-0.4,0c0.3-2.6,0.4-5.2,1.4-7.7c0.4,0,0.5,0.3,0.8,0.5c-0.7,1.9-1.1,3.8-1.4,5.9
		C159.1,849.3,158.9,849.8,158.7,850.2z"
      />
      <path
        class="st1313"
        d="M157.9,850.2c-1.5,0-1.8-0.2-1.4-1.7c0.1-0.5,0.1-1.1,0.5-1.6c0.3-0.4,0.2-0.9-0.4-1c0,0-0.1-0.1,0-0.1
		v-0.1c0.5-0.2,0.4-0.7,0.4-1.1c0.2-1.1,0.4-2.2,1.9-2.2c0.3,0.3,0.5,0.6,0.3,1.2c-0.6,1.8-0.5,3.9-1.3,5.7
		C157.7,849.5,157.9,849.9,157.9,850.2z"
      />
      <path
        class="st1314"
        d="M157.9,850.2c-0.4-0.3-0.5-0.7-0.3-1.2c0.6-1.3,0.8-2.6,0.9-4c0-0.3,0.1-0.6,0.2-0.8
		c0.3-0.6,0.4-1.2,0.2-1.9c0.3-0.1,0.6,0,0.9,0.2c0.2,1-0.4,1.8-0.5,2.7c-0.2,1.4-0.4,2.8-0.6,4.2c-0.1,0.3-0.2,0.5-0.3,0.7
		C158.1,850.2,158,850.2,157.9,850.2z"
      />
      <path
        class="st1070"
        d="M136,812.3h-0.1h-0.1c0-2.5,0-5,0-7.5c0-0.3-0.1-0.7,0.2-1C135.9,806.6,136,809.5,136,812.3z"
      />
      <path
        class="st559"
        d="M88.9,843.5c0,0.5-0.2,0.7-0.7,0.7c-0.2,0-0.6,0-0.5-0.3c0.1-0.4,0.4-0.7,0.8-0.8
		C88.9,842.9,88.9,843.2,88.9,843.5z"
      />
      <path
        class="st1315"
        d="M137.4,896.5c0,0.1,0,0.1,0,0.2c-0.2,0.6-0.6,1.2-0.9,1.8c-0.9,1.2-1.6,1.3-3.2,0.5c-1.1-0.6-2.3-0.7-3.4-1
		c-0.7,0.3-0.3,1-0.7,1.4c-0.1,0.1-0.3,0.3-0.4,0.4c-1.3,0.4-2.2-0.6-3.3-1.1c-0.3-0.1-0.5-0.3-0.8-0.5c-1.4-0.8-1.4-1-0.6-2.4
		c0.1-0.2,0.2-0.4,0.3-0.5c0.5,0.1,1,0.3,1.4,0.8s1,0.2,1.5,0.3c1,0.5,2,0.8,3.2,0.8c0.6,0,1.2,0.1,1.8,0.4c0.3,0.2,0.6,0.4,0.9,0.5
		c0.6,0.3,1.2,0.2,1.5-0.4c0.5-0.7,0.9-1.5,0.5-2.4c0.3-1.1,1.4-1.4,2.1-2.2c0.3-0.3,0.7-0.6,1-0.9c0.5-0.4,0.7-1.1,1.4-1.3
		c0.1,0,0.3,0,0.4,0.1c0.4,0.7,0.2,1.4-0.6,2.1c-0.9,0.7-1.3,1.8-2.1,2.6C137.1,895.8,137.2,896.2,137.4,896.5z"
      />
      <path
        class="st1316"
        d="M129.9,897.9c1.2,0,2.5,0.1,3.5,0.8c2.1,1.6,2.7,1.5,4.1-0.8c0.1-0.3,0.2-0.6,0.5-0.8
		c0.1,0,0.2,0.1,0.2,0.2c0.8,1.3,0.1,2.4-0.9,3.3c-0.9,0.8-2,0.5-3-0.5c-1-0.9-2.2-1.5-3.4-1.8C130.6,898.3,130.2,898.2,129.9,897.9
		z"
      />
      <path
        class="st1317"
        d="M125.6,916.9c-0.3,0.4-0.8,0.6-1.3,0.7C124.6,917.2,125,917,125.6,916.9
		C125.5,916.8,125.6,916.9,125.6,916.9z"
      />
      <path
        class="st1318"
        d="M139.1,918.1c0.5,0.2,0.9,0.6,1.4,0.6l0,0c0.6,0.5,1.6,0.6,2.1,1.2c0.7,0.5,1.5,0.9,2.2,1.3
		c-0.1,0.2-0.2,0.4-0.4,0.5c-2.1,0.1-4.2,0.5-6.1-0.9c-0.7-0.5-0.8-1-0.7-1.8c0.1-1,0.3-1.4,1.3-0.8
		C138.8,918.4,139,918.2,139.1,918.1z"
      />
      <path
        class="st1225"
        d="M142.6,920c-0.7-0.5-1.5-0.6-2.1-1.2C141.3,919.1,142.3,919,142.6,920z"
      />
      <path
        class="st1319"
        d="M124.3,890.6c-0.1-0.3-0.1-0.6-0.2-0.8c0.1-0.2,0.1-0.5,0.3-0.5c1,0.2,1.3-0.7,1.8-1.2
		c1.4-1.2,1.5-3.2,0.3-4.6c-0.3-0.3-0.3-0.6-0.1-0.9c0.4-0.5,0.9-0.8,1.5-1.1c1.5-0.1,1.7-0.5,1-1.8c-0.1-0.2-0.3-0.5-0.2-0.8
		c0.5-0.2,0.7,0.2,0.9,0.4c0.3,0.2,0.5,0.5,0.8,0.7c0.9,0.8,1.6,0.5,1.8-0.7c0-0.2,0-0.4,0.2-0.6c0.7,0,1.1,0.4,1.5,0.9
		c-0.3,1.2-0.2,2.5-0.8,3.7c-0.2,0.3-0.3,0.7-0.6,1c-2,1.8-3.7,3.9-5.8,5.7c-0.5,0.4-0.8,1-1.4,1.3
		C124.8,891.6,124.7,890.9,124.3,890.6z"
      />
      <path
        class="st1320"
        d="M126.6,882.7c0.2,0.1-0.2,0.3,0,0.4c0.9,0.6,0.8,1.4,1,2.4c0.2,1.7-0.8,2.4-1.7,3.4
		c-0.5,0.5-1.3,0.4-1.8,0.9c-0.3-1.1-0.9-2.1-1.5-3.1c0.1-0.3,0.1-0.5,0.5-0.3c0.7,0.3,1.5-0.2,1.9-1.1c0.3-0.8,0.2-1.5-0.6-1.9
		c-0.8-0.4-1.5-1-2-1.7c0-0.6,0.3-1,0.7-1.3c0.5,0,0.6,0.5,0.8,0.8c0.3,0.5,0.5,1,1.1,1.3C125.6,882.8,126.1,882.4,126.6,882.7z"
      />
      <path
        class="st1321"
        d="M122.6,881.6c0.8,1,2.2,1.2,2.8,2.3c0.5,1-0.8,3.1-1.9,3c-0.3,0-0.2-0.2-0.3-0.4c-0.2-0.4-0.3,0-0.5,0.1
		c-0.4-0.5-0.9-1-1.3-1.4c0-0.2,0-0.4,0-0.6c-0.1-0.9,0.2-1.8,0.6-2.5C122.2,881.9,122.3,881.6,122.6,881.6z"
      />
      <path
        class="st1322"
        d="M124.3,890.6c0.6,0,0.6,0.7,1,0.8c0.4,0.5,1,0.6,1.5,0.8c0.4,0.6,0.9,0.8,1.6,1.1c0.8,0.3,1.7,0.5,2.2,1.2
		c0,0.2-0.1,0.3-0.2,0.4c-0.7,0.2-1.2,1-2.1,0.7c0-0.2,0-0.5-0.3-0.6c-1.3-0.3-2.4-1-3.7-1.5C124.3,892.6,124.6,891.6,124.3,890.6z"
      />
      <path
        class="st1323"
        d="M128.3,895.6c0.8-0.1,1.2-0.9,2.1-0.8c0.9-0.5,1.9-0.4,2.8-0.1c0.3,0.5-0.1,0.4-0.5,0.5
		c0.9-0.2,1.1,0.2,1,0.7c0,1.1-0.5,1.7-1.3,1.8c-0.9-0.4-1.8-0.4-2.7-0.5c-0.9,0-1.8-0.1-2.3-1C127.7,896.1,128.1,896,128.3,895.6z"
      />
      <path
        class="st1324"
        d="M117.8,885.8c-0.6,0.2-1.1,0.1-1.7,0.1c-0.3-0.4-0.3-0.9-0.2-1.4c0.4-2.3,0.2-4.6,0.6-6.9
		c0.5-3.5,0.9-6.9,0.8-10.4c0.2-0.2,0.4-0.2,0.7-0.1c0.6,0.5,0.5,1.2,0.5,1.8c-0.2,2.5-0.4,5-0.6,7.6c-0.1,1.1-0.2,2.1,0.2,3.2
		c0.3,0.8,0.1,1.7,0.3,2.5c0.1,0.3,0.1,0.7,0.1,1.1c0,0.2-0.1,0.5-0.2,0.7C118.2,884.5,118.3,885.2,117.8,885.8z"
      />
      <path
        class="st1325"
        d="M118.3,883.8c0-0.2,0-0.4,0-0.7c0.2-0.2,0.5-0.3,0.8-0.4c1-0.3,2.2-0.2,3.2-0.6c-0.3,0.8-0.6,1.7-0.8,2.5
		c-0.6,0.4-1.2,0.1-1.6-0.3C119.4,883.9,118.9,883.8,118.3,883.8z"
      />
      <path
        class="st1326"
        d="M114.9,885c-0.8-0.4-0.1-1.3-0.6-1.8c0.2-0.9,0.2-1.8,0.2-2.8c-0.2-1.2,0.1-2.4,0.2-3.6
		c0-0.5-0.1-0.8-0.6-1.1c-0.8-0.4-1.5-1-2.4-1c-0.2,0-0.3,0-0.5,0.2c0.8,0.7,0.9,1.7,1.2,2.6c0.2,0.4,0.2,0.9-0.2,1.2
		c-0.9,0-1.2-1-2-1.2c-0.2-0.3,0.1-0.4,0.2-0.6c0.2-0.5-0.1-1-0.2-1.5c-0.1-0.3-0.1-0.6-0.1-1c0.1-0.9,0-1.8,0.8-2.5
		c0.8,0.6,1.8,0.8,2.8,1.3c0.7,0.3,1.3,0.7,1.6,1.4c-0.2-0.9,0.2-1.8,0.1-2.7s0.2-1.8,0.2-2.8c0-0.4,0-0.8-0.4-1.1
		c-0.3-0.5-0.4-1-0.4-1.6c0.4-0.9,0.7-0.9,1.5-0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.9,1,0.9,2.2,0.8,3.4c-0.3,2.9-0.4,5.9-1,8.7
		c-0.4,1.8-0.2,3.6-0.7,5.3C115.4,884.6,115.4,885,114.9,885z"
      />
      <path
        class="st1327"
        d="M110.2,877.5c0.9,0,1.2,1,1.9,1.3c0.5,0.4,1,0.9,1.7,1.1c0.3,0.1,0.6,0.3,0.4,0.8c-0.1,0.1-0.3,0.2-0.3,0.2
		c-1.2-0.6-2.2,0.5-3.4,0.4c-0.2,0-0.4,0.2-0.5,0.3c-0.2,0.4,0.6,0.3,0.5,0.8c-0.8-0.2-1.4-0.7-2.1-1.1c-0.3-0.2-0.7-0.7-0.8,0.2
		c0,0.2-0.2,0.2-0.4,0.2c-0.8-0.2-1.5,0.1-2.2,0.3s-1.3-0.2-1.9,0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.5,0.5-0.6,0.8-1
		c0.2-0.2,0.5-0.3,0.8-0.4c0.8,0,1.7-0.4,2.5-0.2c-0.5-0.3-0.5-0.7-0.1-1C107.8,878.3,108.8,877.6,110.2,877.5z"
      />
      <path
        class="st1328"
        d="M104.3,880.4c-0.2,0.1-0.4,0.3-0.6,0.4c-1,0.6-1.9,1.3-2.9,1.9c-0.1,0.2-0.3,0.4-0.4,0.6
		c-0.4-0.8-0.8-1.7-1.2-2.5c-0.1,0-0.2-0.2-0.1-0.2c0.7-1.1,1.1-2.4,2.1-3.4c1.1,0.3,2.2,0.9,3.1,1.5
		C105.2,879.3,105.2,879.6,104.3,880.4z"
      />
      <path
        class="st1329"
        d="M114.1,880.6c0-0.4-0.2-0.6-0.7-0.6c-0.8-0.1-1.2-0.5-1.4-1.2c0.4-0.5,0.2-1-0.1-1.5
		c-0.4-0.7-0.2-1.7-1.2-2c-0.3-0.1-0.2-0.5,0.2-0.6c0.8-0.2,1.5,0,2.3,0.3c0.5,0.2,0.8,1.1,1.5,0.7c0.1,0,0.1,0.1,0.1,0.2
		c0,1.5-0.1,3.1-0.4,4.6C114.4,880.5,114.2,880.5,114.1,880.6z"
      />
      <path
        class="st1330"
        d="M100.8,882.7c0-0.1-0.1-0.2,0-0.2c1-0.6,1.8-1.3,3-1.7c-0.2,0.4-0.6,0.5-0.9,0.8c-0.1,0-0.1,0-0.2,0
		c-0.5,0.3-0.8,0.9-1.5,1C101.1,882.7,100.9,882.7,100.8,882.7z"
      />
      <path
        class="st1327"
        d="M101.2,882.7c0.4-0.5,0.9-0.8,1.5-1C102.2,882.1,102.1,883,101.2,882.7z"
      />
      <path
        class="st1331"
        d="M95.2,881.4c-0.1,0.2-0.1,0.4-0.3,0.1c-0.4-0.8-0.7-1.6-0.2-2.5c0.1-0.1,0-0.3,0-0.4
		c-0.2-0.2-0.3-0.1-0.5,0c-0.3,0.1-0.7,0.3-0.9-0.1s-0.3-0.7,0-1.2c0.9-1.4,1.9-2.6,3-3.8c1.2-1.2,1.6-2.8,2.2-4.3
		c0.2-0.5-0.3-0.9,0-1.4c0.5,0,0.6,0.5,0.9,0.7c0.2,0.2,0.3,0.3,0.5,0.5s0.4,0.4,0.5,0.7c0.5,0.6,0.7,1.3,1,2
		c0.1,0.6,0.3,1.2,0.3,1.9c0,0.2-0.1,0.5-0.3,0.5c-0.5-0.1-0.2-0.5-0.3-0.8c0,1.2-0.6,2.3-1.1,3.3c-0.5,0.9-1.3,1.1-2.2,0.8
		c0.4,0.2,0.8,0.4,1.1,0.8c0.2,0.2,0.2,0.5,0,0.7s-0.4,0-0.6-0.1c-0.2-0.2-0.4-0.3-0.7-0.5c-0.6,0-1.2-0.2-1.7,0.4
		C94.8,879.4,94.8,880.3,95.2,881.4z"
      />
      <path
        class="st1332"
        d="M97.7,878.3c0.4,0,0.8,0.3,1.3,0.5c-0.3-0.8-0.9-1.1-1.5-1.5c1.9,0,3-1,3.2-3c0-0.5,0.6-0.8,0.2-1.6
		c0.5,0.5,0.3,0.8,0.4,1.1c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.2,0.2-0.2c0.5,0.1,0.4,0.5,0.5,0.9c0.1,0.8-0.2,1.5-0.4,2.2
		c-0.1,0.2-0.1,0.3-0.3,0.4c-0.5,1.3-1.4,2.3-2,3.6C98.8,879.9,98.4,879,97.7,878.3z"
      />
      <path
        class="st1333"
        d="M114.9,885c0.5-0.7,0.6-1.6,0.6-2.4c0-2.5,0.8-5,0.9-7.5s0.8-4.9,0.4-7.4c-0.1-0.5-0.6-0.6-0.6-1
		c0.5-0.2,0.9,0,1.3,0.4c0.4,1.8,0.3,3.7,0,5.5c-0.1,0.6-0.2,1.2-0.2,1.8c-0.5,3.3-0.5,6.6-1,9.8c-0.1,0.5,0,1-0.1,1.5
		C115.6,885.8,115.2,885.5,114.9,885z"
      />
      <path
        class="st1334"
        d="M99.3,868.7c-0.5-0.1-0.5-0.6-0.8-0.8c0,0,0-0.1,0-0.2c0.6-1.2,1.1-2.5,2.1-3.5c0.5-0.5,1.1-0.7,1.6-1.1
		c0.3-0.2,0.7-0.1,0.9,0.2c1.1,0.5,2.3,0.4,3.4,0.1c0.3,0.5-0.1,0.7-0.4,0.9C103.8,865.7,101.6,867.3,99.3,868.7z"
      />
      <path
        class="st1335"
        d="M161.1,890.4c-0.4,0.2-0.7,0.5-1.2,0.8c1,0.1,1.5-0.8,2.2-1c1.3,0.4,2.5,1,3.6,1.6c0.7,0.4,1.5,0.6,2.1,1.1
		c0.1,0.1,0.3,0.1,0.5,0c1.1-0.8,1.8,0.2,2.5,0.7c-1,0.5-2,1-2.9,1.7c-0.4,0.4-0.7,1-1.3,1.3c-3-1.6-6-3.2-9-4.7
		c-0.2-0.1-0.5-0.3-0.7-0.4c-0.6-0.4-1.4-0.5-1.9-1.2c0-0.1,0-0.2,0.1-0.3c0.4-0.5,0.1-1,0.2-1.5c0.1-0.1,0.3-0.2,0.5-0.1
		c1.7,0.6,3.5,1,5.3,1.6C161.2,890.2,161.2,890.3,161.1,890.4z"
      />
      <path
        class="st1336"
        d="M170.9,893.7c-0.2-0.3-0.7-0.1-0.9-0.4c-0.4-0.6-0.9-0.7-1.4-0.1c-0.1,0.2-0.4,0.3-0.6,0.1
		c-1.4-1-3-1.6-4.5-2.4c-0.4-0.2-0.8-0.5-1.2-0.8c1.1-0.7,2.2-1.4,3.3-2c0.1-0.2,0.3-0.1,0.4,0c-0.1,0.3-0.5,0.5,0.1,0.7
		c1.2,0.4,2.2,1.4,3.6,1c0.6,0.8,1.4,0.1,2.1,0.4c0.1,1,0.5,2,0.4,3C171.8,893.4,171.4,893.8,170.9,893.7z"
      />
      <path
        class="st1337"
        d="M171.2,897.3c0,0.7-0.9,1.6-1.6,1.6c-0.7,0-1-0.4-1-1c0-0.8,1-1.6,1.8-1.6
		C171,896.3,171.2,896.7,171.2,897.3z"
      />
      <path
        class="st1139"
        d="M166.8,896.7c0.4-0.5,0.7-1,1.2-1.3c-1,1.5-1.7,3.1-2.3,4.8c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0-0.2,0-0.2
		c0.2-0.1,0.2-0.3,0.2-0.4c-0.1-0.1-0.1-0.3-0.1-0.5c0.1-0.6,0.3-1.1,0.6-1.6C166.3,897,166.3,896.5,166.8,896.7z"
      />
      <path
        class="st1338"
        d="M158.7,879.6c0.1,0.4-0.2,0.7-0.4,1c-0.6,0.4-1.3,0.8-2,1.1c-0.3,0.2-0.8,0.2-0.8,0.7
		c-0.2,0.7-1.1,0.5-1.4,1c-0.3-0.2-0.3-0.9-0.9-0.8c-0.3-0.2-0.1-0.7-0.6-0.9c-1.4-0.6-1.6-1-1.3-2.3c0.4-0.9,0.5-1.9,1.1-2.7
		c0.6-0.7,1.3-0.7,2.1-0.4c1.3,0.6,2.3,1.6,3.6,2.2C158.6,878.8,158.6,879.2,158.7,879.6z"
      />
      <path
        class="st1336"
        d="M161.1,890.4c-0.5-0.1-0.9-0.1-1.4-0.3c-1.4-0.6-3-0.7-4.4-1.4c0-1,0-2-0.2-2.9c0.2-0.1,0.4-0.4,0.5-0.4
		c0.6,0.4,1.5,0.1,2,0.4c2,1.2,4.2,1.8,6.4,2.8C163,889.3,162.1,889.8,161.1,890.4z"
      />
      <path
        class="st1339"
        d="M155.2,890.2c0,0.1,0,0.1,0,0.2c-0.7,0.4-1.4,0.1-2.1,0c-0.9,0.8-1,1.5-0.3,2.3c0.2,0.2,0.6,0.4,0.5,0.8
		c-1.5,0-2-0.3-2.1-1.3s0.9-2.8,1.7-3C153.8,889.2,154.5,889.8,155.2,890.2z"
      />
      <path
        class="st1340"
        d="M151.6,879.6c-0.7,1.3,0.6,1.5,1.1,2.2c-0.9,0-1.3,0.5-1.6,1.4c-0.7,1.7-1.8,3.1-3.1,4.4
		c-1.3,1.3-2.6,2.6-4.5,3.1c-0.3,0.1-0.4,0.3-0.4,0.6c-0.3-0.1-0.4-0.5-0.8-0.4c-0.3-0.2-0.6-0.4-0.7-0.7c0-0.2,0-0.5,0.3-0.5
		c1.5,0.3,2.4-0.9,3.4-1.6c1.4-0.9,2.3-2.2,3.4-3.5c0.9-1,1.1-2.2,1.4-3.4c0.1-0.5-0.1-0.8-0.7-0.9c-0.2,0-0.4-0.1-0.6-0.2
		c-0.2-0.1-0.4-0.2-0.7-0.3c-0.2-0.1-0.4-0.2-0.4-0.4c0-0.3,0.3-0.3,0.6-0.3c0.2,0,0.4,0,0.6,0.1c0.3,0.1,0.6,0.1,0.9,0.2
		c0.6,0.3,1.2,0.4,1.9-0.1h0.1C151.7,879.5,151.6,879.5,151.6,879.6z"
      />
      <path
        class="st1136"
        d="M152.2,898.9c0.5,0.2,0.9,0.3,1.6,0.9C153,899.6,152.5,899.4,152.2,898.9L152.2,898.9z"
      />
      <path
        class="st1341"
        d="M153.1,890.4c0.7-0.5,1.4,0,2.1,0c0.6,0.3,1.2,0.7,1.9,1.1c-0.2,0.7-0.6,0.2-1,0.1
		c-0.5-0.1-0.8-0.6-1.5-0.5c0.4,0.9,0.9,1.5,1.7,1.9c2.1,1.1,4.3,2.1,6.3,3.3c0.7,0.4,1.5,0.9,2.2,1.3c0.5,0.3,0.8,0.7,0.9,1.3
		c0,0.1,0,0.3,0,0.4c-1.4-0.9-2.8-1.6-4.2-2.5c-1.6-1.1-3.4-1.9-5.2-2.7c-1.3-0.6-2.2-1.7-3.3-2.6
		C152.6,891.2,153.1,890.8,153.1,890.4z"
      />
      <path
        class="st1342"
        d="M137.4,896.5c-1.1-0.4-0.4-0.9,0-1.3c0.9-1.1,1.8-2.2,2.7-3.3c0.2-0.3,0.2-0.5,0.1-0.8
		c0.1-0.6,0.5-0.4,0.9-0.4c0.3,0.1,0.5,0.3,0.5,0.7c0,0.5-0.2,0.8-0.5,1.2c-1,1.1-2.1,2.2-3.2,3.2
		C137.7,896,137.6,896.2,137.4,896.5z"
      />
      <path
        class="st1343"
        d="M138,897.1c-0.1,1.1-0.8,1.9-1.5,2.6c-0.6,0.5-1.3,0.5-1.9,0c-0.4-0.3-0.9-0.5-1.1-1
		c1.9,0.8,2.4,0.6,3.3-1.4c0.2-0.4,0.5-0.4,0.7-0.7C137.6,896.8,137.8,897,138,897.1z"
      />
      <path
        class="st1344"
        d="M137.8,895.8c0.2-0.7,0.7-1.2,1.3-1.7c0.8-0.5,1.1-1.4,1.9-2c0.2-0.2,0.5-0.8,0.2-1.1s-0.7-0.3-1.1,0
		c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.1-0.2,0-0.3c0.5-0.6,1.1-1,1.9-0.5c0.4,0.1,0.6,0.3,0.6,0.7C141.4,893,139.9,894.7,137.8,895.8z"
      />
      <path
        class="st1345"
        d="M168.4,873.3c0.6-0.4,1.1-0.8,1.6-1.2c0.2,0.7-0.5,0.8-0.8,1.2c-0.1,0.1-0.3,0.2-0.4,0.3
		c-0.3,0.1-0.6,0.1-0.8,0.2c-0.3,0-0.6,0-0.8,0s-0.3-0.1-0.2-0.3C167.5,873.2,167.9,873.1,168.4,873.3z"
      />
      <path
        class="st1346"
        d="M170.3,870.8c-1.2-0.1-1.9-1-2.7-1.7c0.3-0.4,0.6-0.9,1.2-1C169.6,868.9,170.1,869.8,170.3,870.8z"
      />
      <path
        class="st1347"
        d="M155.6,882.5c-0.5,0-0.3-0.3-0.1-0.4c0.9-0.5,1.7-1.2,2.8-1.5c0,0.1,0,0.1,0,0.2
		C157.4,881.3,156.4,881.9,155.6,882.5z"
      />
      <path
        class="st1348"
        d="M166,888.1c-0.1,0-0.3,0-0.4,0c0.1-0.9,0.9-0.8,1.5-1.1C166.7,887.4,166.3,887.8,166,888.1z"
      />
      <path
        class="st1349"
        d="M166.9,873.6c0.1,0.1,0.2,0.2,0.3,0.2c0,0.7,0.1,1.4-0.1,2.1c-0.4,1.1-0.9,1.5-2.1,1.6
		c-0.5,0-1.1-0.2-1.5,0.4c0-0.2,0-0.5-0.2-0.6s-0.3-0.3-0.3-0.5c-0.2-1.9,0.3-3.7,1.2-5.3c0.1-0.2,0.2-0.2,0.4-0.3
		C165.7,871.7,166.2,872.7,166.9,873.6z"
      />
      <path
        class="st1272"
        d="M164.9,871.3c-0.5,0-0.5,0.4-0.6,0.7c-0.8,1.7-1.2,3.4-1,5.3c-0.8,1.3-2.1,1.8-3.4,2.1
		c-0.4,0.1-0.8,0-1.2,0.2c-0.9-1.1-2.3-1.7-3.4-2.5c-0.8-0.6-1.7-0.8-2.6-0.2c-0.2-0.5,0-0.9,0.1-1.3c0.7-1.8,1.5-3.5,2.2-5.3
		c0.1-0.3,0.2-0.7,0.6-0.7c0.6,0.8,1.5,0.9,2.4,1c0.2,0,0.5,0,0.7,0.1c0.4,0,0.9,0,1,0.5s-0.4,0.6-0.8,0.8c-1,0.7-2.1,1.4-3.4,1.1
		c-0.4-0.1-0.7,0.3-0.9,0.5c1.5,0.5,1.5,0.5,2.6-0.5c0.5-0.4,1.1-0.7,1.6-1.1c0.4-0.2,0.7-0.5,1.1-0.7c0.7-0.4,1.3-1.1,2.2-0.7
		c0.3,0.1,0.5-0.1,0.7-0.4c0.1-0.3-0.1-0.4-0.3-0.6c-0.2-0.3-0.6-0.5-0.4-1c0.4-0.3,0.8,0,1.1,0.2c0.5,0.5,1,0.9,1.5,1.4
		C165.1,870.5,165.2,870.9,164.9,871.3z"
      />
      <path
        class="st1350"
        d="M120.4,842.1c0.1,0.3,0.2,0.7,0.4,1.1c0.2,0.9-0.4,0.9-1,0.9c-0.1-0.7-0.8-0.4-1.1-0.6
		c-0.3-0.2-0.5-0.4-0.5-0.8c0.2-0.4,0.2-0.8,0-1.3c-0.9-2.1-1.8-4.2-2.3-6.4c-0.2-0.7-1-1.2-0.9-2c0.4-0.2,0.8-0.7,1.4-0.3
		c0.1,0,0.3-0.2,0.5-0.3c-0.1,1.1,0.5,1.9,0.9,2.8C118.5,837.5,119.3,839.8,120.4,842.1z"
      />
      <path
        class="st1351"
        d="M118.3,842.7c0.1,0.2,0.3,0.4,0.4,0.6c0.7,0.7,1.1,1.4,1.3,2.3c-0.2,0.7-0.7,1.1-1.3,1.4
		c-0.3,0.1-0.7,0.3-1.1,0c0.5-1.5-0.6-2.6-1.1-3.8c-0.5-1.3-0.9-2.6-1.4-4c-0.6-1.8-1.3-3.6-2.4-5.1c-0.3-0.4-0.7-0.7-0.6-1.3
		c0.9-0.1,1.5,0.4,2.1,1c0.6,0.1,0.7,0.6,0.8,1c0.9,2.3,1.9,4.5,2.9,6.8C118.2,842,118.2,842.4,118.3,842.7z"
      />
      <path
        class="st1352"
        d="M112.2,833c1.3,1.5,2.1,3.3,2.8,5.2c0.7,2.1,1.3,4.3,2.3,6.3c0.1,0.3,0.3,0.5,0.5,0.8
		c0.5,0.7,0.4,1.3-0.1,1.9c0,0.5-0.4,0.4-0.7,0.4c-0.4,0-0.6-0.3-0.6-0.6c-0.2-3.3-1.9-6-3.1-9c-0.1-1.2-0.5-2.2-1.2-3.1
		c-0.2-0.3-0.4-0.6-0.3-1c-0.1-0.8-0.8-0.5-1.2-0.8C111.1,833,111.7,833,112.2,833z"
      />
      <path
        class="st1353"
        d="M118.3,842.7c-1-2.4-2.1-4.8-3.1-7.2c-0.2-0.5-0.4-1.1-0.8-1.5c0-0.5-0.3-1.1,0.6-1
		c1.2,1.6,1.5,3.6,2.2,5.4c0.4,1.1,0.8,2.2,1.4,3.2C118.8,842,118.7,842.4,118.3,842.7z"
      />
      <path
        class="st1354"
        d="M90.2,851.9c1.1-1.1,2.2-2.2,3.2-3.3c1.8-2.1,3.7-4,4.7-6.7c0.1,0.1,0.2,0.1,0.2,0.2
		c0.7,0.7,0.1,1.3-0.2,1.9c-1.1,1.8-2.4,3.3-3.7,5c-0.3,0.4-0.7,0.9-0.9,1.3c-0.1,0.1-0.2,0.2-0.4,0.2c-1.3,0.6-2,1.9-3.1,2.7
		C89.9,852.7,90.1,852.3,90.2,851.9z"
      />
      <path
        class="st1355"
        d="M93.3,850.2c0.2-1,1-1.5,1.6-2.3c1.1-1.4,2.2-2.8,3.2-4.3c0.3-0.4,0.7-1,0.3-1.6c0.1,0,0.3,0,0.4,0
		c0.2,0.7,0.9,1.1,1.1,1.9c-0.2,0.2-0.2,0.6-0.5,0.8c-1.4,1-2.1,2.7-3.4,3.9c-0.4,0.5-1,1-1.6,1.4C94,850.2,93.7,850.5,93.3,850.2z"
      />
      <path
        class="st1356"
        d="M114.7,844.8c0.5-0.3,0.3-0.7,0.1-1.1c-1.3-2.9-2.5-5.8-3.8-8.7c-0.2-0.3-0.3-0.7-0.5-1
		c0.3-0.7,0.8-0.2,1.2-0.2c0.2,0.3,0.3,0.6,0.4,0.8c0.4,1,1.1,2,1.2,3.1c1.1,2.6,2.4,5,3.1,7.8c0.1,0.3,0.1,0.6,0.1,0.9
		c0,0.8,0.3,1.1,1.1,0.7c0.3-0.1,0.7-0.1,1-0.2c-0.1,1-1,0.8-1.7,1c-0.4,0-0.9-0.1-1.3-0.3c-0.7-0.3-1.1-0.8-0.9-1.6
		C114.9,845.6,114.7,845.2,114.7,844.8z"
      />
      <path
        class="st1357"
        d="M133.5,847.7c-0.9,0-0.9-0.6-0.8-1.1c0.1-1.6,0.2-3.2,0.3-4.7s0.1-3.1,0.1-4.7c0-1.9,0.1-3.8-0.2-5.7
		c0.5,0.8,0.6,1.7,0.6,2.5C133.5,838.6,133.5,843.1,133.5,847.7z"
      />
      <path
        class="st1358"
        d="M127.9,849c1.1-0.6,1.9-1.6,3.1-2c0.4-0.2,0.8-0.5,0.9,0.3c0.2,0.8,1,0.8,1.6,1.1c0.9,0.2,1,0.5,0.4,1.2
		c-0.3,0.4-0.3,0.9-0.6,1.3c-0.3,0.4-0.5,0.9-1.2,0.9c-0.3-0.3-0.2-0.6-0.3-1c-0.2-0.6-0.6-1-1.3-1c-0.5,0-1.5,1-1.6,1.5
		c-0.1,0.7,0.2,1.1,0.9,1.4c0.3,0.1,0.6,0.1,0.8,0.3c0.2,0.6-0.3,0.7-0.6,0.8c-0.6,0.3-0.7,0.6-0.2,1.1c0.2,0.2,0.5,0.5,0.2,0.9
		c-0.7,0.1-1-0.4-1.4-0.8c-0.6-0.8-1.2-0.4-1.9,0c-0.4,0.2-0.6,0.9-1.3,0.5C125.3,852.8,126,850.7,127.9,849z"
      />
      <path
        class="st1359"
        d="M124.5,844.8c1.1,0.4,1.8,1.4,2.5,2.3c-0.1,0.9-0.9,1-1.5,1.3c-0.3,0.2-0.7,0.2-1,0.3
		c-1,0.1-1.5,0.5-1.4,1.6c0.1,0.7-0.4,1.1-1.1,1.2c-0.2,0-0.4,0-0.6-0.1c-0.4-0.3-1.1-0.3-1.4-0.8c-0.2-1.1-0.2-2.3,0-3.4
		c0.1-0.8,0.4-1.3,1.3-1.4c1-0.1,2,0,2.7-0.9C124.2,844.8,124.4,844.9,124.5,844.8z"
      />
      <path
        class="st1360"
        d="M122,851.4c0.6-0.3,1-0.7,0.8-1.5s0.3-1.2,1.1-1.4c1.1-0.2,2.2-0.5,3-1.4c0.4,0.6,0.8,1.1,0.9,1.8
		c-0.8,1.4-1.8,2.7-2,4.3c-0.1,0.7-0.1,1.4-0.1,2.1c0.1,0.1,0.1,0.3,0,0.4c-0.4,0.2-0.6-0.1-0.7-0.4c-0.2-0.4-0.4-0.9-0.6-1.2
		c-0.3-0.4-0.5-0.8-0.8-1.1c-0.2-0.2-0.4-0.4-0.6-0.7c-0.1-0.2-0.3-0.3-0.5-0.3C122.3,851.9,122.1,851.8,122,851.4
		C122,851.5,122,851.5,122,851.4z"
      />
      <path
        class="st1361"
        d="M124.5,844.8c-0.7,1.2-1.8,1.3-3,1.2c-0.7,0-1.1,0.4-1.2,1.1c-0.1,1-0.4,2,0,3c0.1,0.3-0.2,0.3-0.2,0.5
		c-0.1,0.1-0.2,0.2-0.3,0.2c-1.6-0.2-3.2-0.2-4.8,0.1c-0.4-0.1-0.6-0.3-0.1-0.6c0.4-0.2,0.8-0.3,1.3-0.4c0.8-0.2,1-0.7,0.5-1.2
		c-0.8-0.7,0-0.7,0.3-0.9c0.5-0.3,1.3-0.1,1.6-0.8c0.5-0.3,0.9-0.8,1.3-1.2c0.1-0.6-0.5-1.1-0.2-1.7c0.4-0.2,1-0.2,1-0.8
		c0.5,0.5,1.2,0.4,1.9,0.6C123.3,844,124,844.2,124.5,844.8z"
      />
      <path
        class="st1362"
        d="M132.2,851.7c0.5-0.3,1-0.7,1.1-1.4c0.1-0.6,0.4-1.1,0.9-1.5c-0.2-0.3-0.6-0.1-0.7-0.3v-0.1
		c0,0,0,0,0.1-0.1c0.5,0,0.9-0.3,1.4-0.1c0.1,0.2,0.4,0.2,0.6,0.2c0.3,1.6-0.1,3-1.3,4c-1,1-2,2-3.2,2.8c-0.4,0.2-0.2,0.9-0.8,0.8
		c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3-0.2-0.5-0.4-0.7c-0.9-1-0.9-1,0.3-1.5c0.3-0.1,0.6-0.2,0.7-0.5
		C131.1,852.3,131.6,851.9,132.2,851.7z"
      />
      <path
        class="st1363"
        d="M130.1,855.7c0,0.1,0,0.1,0,0.2c0.1,0.2,0.5,0.3,0.4,0.6c0.3,0.9,0.6,1.8,1.9,1.5c0.5-0.1,0.8-0.6,1.2-0.8
		c1.7-1.1,3.1-2.5,4.2-4.3c0.6-1.1,0.5-2.3,0.3-3.4c-0.1-0.6-0.1-1,0.6-1.2c0.3,2,1.1,4.1-0.6,5.9c-0.4,0.4-0.7,1-0.9,1.5
		c-0.4,0.8-0.8,1.4-1.6,1.9c-0.4,0.2-0.8,0.7-0.6,1.2s0.7,0.5,1.1,0.5c1.4,0.2,2.8-0.6,4.1,0.2c0.1,0.1,0.2,0.1,0.3,0.2
		c-0.6,1.2-1.7,1.8-2.8,2.2c-1,0.4-2.1,0.6-3.2,0.3c-1.3-0.3-2.6-0.4-3.9-0.6c-0.8-0.2-1.5-0.4-1.6-1.5c1-0.9,1.3-2,0.9-3.2
		c-0.1-0.2-0.1-0.3-0.3-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.5-1-0.5-1.6-0.5c-0.3,0-0.8,0-0.8-0.4s0.4-0.5,0.8-0.6s0.8-0.1,1.1,0.3
		C129.3,855.1,129.7,855.4,130.1,855.7z"
      />
      <path
        class="st1364"
        d="M130.6,856.4c-0.1-0.2-0.3-0.4-0.4-0.6c1-1.1,2.1-2,3.2-2.9c1.2-1,2.2-2.2,2-3.9c0-0.2,0.1-0.5,0.2-0.7
		c0.7-0.4,1.2,0,1.2,0.6c0,0.7,0.8,1.3,0.4,2.1c-0.7,1.4-1.6,2.7-2.8,3.6C133.2,855.5,132.1,856.5,130.6,856.4z"
      />
      <path
        class="st1365"
        d="M145.1,848.7c0.9,0.9,2.1,1.2,3.1,1.8s2.2,0.9,3,1.7c-1.2,0.9-1.9,2.2-2.5,3.5c-1.9,0.1-3.8,0-5.6-0.7
		c-0.1-0.1-0.1-0.2,0-0.3c-0.2-1.4-0.5-2.8-1-4.1C143,850,144.1,849.4,145.1,848.7z"
      />
      <path
        class="st1366"
        d="M129.3,860c0.1,0.7,0.6,1.1,1.2,1.2c1.8,0.3,3.5,0.7,5.3,0.8c0.8,0,1.8-0.2,2.5-0.7
		c0.8-0.5,1.5-1.1,2.2-1.7c0.3,0,0.5-0.1,0.6-0.4c0.7-0.7,0.8-0.7,1.3-0.2c-0.5,1.3-1.6,2.3-2.4,3.5c-2.9,2.4-7.2,2.7-10.4,0.7
		c-0.5-0.3-0.9-0.6-1.4-0.9c-0.5-0.5-1.1-0.9-1.4-1.5c0-0.3,0.1-0.4,0.3-0.5C127.9,860.1,128.6,859.7,129.3,860z"
      />
      <path
        class="st1367"
        d="M142.5,859c-0.5-0.5-0.9,0-1.3,0.2c-0.9,0.1-1.3-0.4-1.5-1.2c-0.4-1.4,0.2-2.8,1.4-3.4
		c0.7-0.3,1.3,0.1,2,0.2c0,0.1,0,0.1,0,0.2c-0.4,0.5-0.9,0.5-1.4,0.7c-0.8,0.6-0.7,1.2,0,1.7c0.3,0.2,0.7,0.3,1.1,0.4
		c1.5,0,2.9,0.5,4.3,0.5c0.6,0,1.1,0.1,1.4,0.7c0,0.1,0,0.3,0,0.4c-1.6,0-3.2-0.2-4.9-0.4C143.2,859,142.9,859,142.5,859z"
      />
      <path
        class="st1368"
        d="M106.6,863.5c-1.2,0.6-2.4,0.7-3.5-0.1c0.3,0,0.5,0,0.8,0c0.6,0.2,1.3,0.4,1.9,0c-0.6-0.6-0.9-1.3-0.9-2.1
		c0-0.5,0.1-1-0.1-1.4c-0.5-1.3,0.2-2.2,1.1-3c0.1-0.1,0.2-0.2,0.4-0.1c0.3,0.2,0.2,0.5,0.2,0.7c0.1,1.8,0,3.6,0.8,5.2
		C107.3,863.1,107.1,863.4,106.6,863.5z"
      />
      <path
        class="st1369"
        d="M93.3,850.2c1.1-0.2,1.7-1.1,2.5-1.7c0.9,0.3,1.7,0.1,2.5-0.3c0.8-0.4,1.8-0.4,2.6-1c0.3-0.2,0.8,0,1,0.3
		c1.2,1.2,2.8,1.6,4.4,1.8c0.2,0,0.3,0.1,0.5,0.1c1.4,0.4,1.7,1.4,0.6,2.4c-0.3,0.3-0.7,0.1-0.9-0.1c-1.2-0.6-2.3-1.3-3.4-2
		c-0.3-0.2-0.7-0.4-1-0.5s-0.7-0.7-1-0.1c-0.2,0.5-0.2,1.1,0.4,1.4c0.6,0.3,1.2,0.5,1.7,0.8c0.3,0.2,0.8,0.4,0.8,0.9
		c-0.7,0.5-1.3,0.1-1.8-0.2c-0.8-0.4-1.3-0.2-1.8,0.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-1.1-1-1.2-1.9-1c-1.4,0.3-2.7,0.1-4.1,0.3
		c-1.2,0.2-1-0.7-0.9-1.4C93.1,850.3,93.2,850.3,93.3,850.2z"
      />
      <path
        class="st1370"
        d="M100.3,852.7c0.4-1.3,0.4-1.2,1.6-0.7c0.6,0.3,1.3,0.3,2,0.5c0.3-0.3,0.7,0,1,0.1c1.6,0.5,3,1.4,4.7,1.5
		c0.5,0,0.8,0.4,0.9,0.9c0,0.3-0.1,0.5-0.3,0.7c-1.4-0.4-2.9-0.8-4.5-1c0.6,0.7,1.2,1.2,1.8,1.5c0.4,0.2,0.9,0.4,0.6,1
		c-0.6,0.2-1.3,0-1.9,0.2c0-0.2,0-0.3,0-0.5c-0.2,0.4-0.4,0.8-1,1c-0.3,0-0.4,0.5-0.3,0.8c0.4,1.6-0.1,3.3,0.8,4.8
		c-0.9,0-1.2-0.4-1.3-1.4c-0.2-1.6,0.3-3.3-0.5-4.9c0-0.1,0-0.1,0-0.2c0.4-1.8-0.9-2.3-2.1-3C101.3,853.5,100.8,853.2,100.3,852.7z"
      />
      <path
        class="st1371"
        d="M123.5,871.7c3.3-0.7,6.7-0.8,10-1c1.4-0.1,2.6-0.7,4-1c1.6-0.3,3-0.9,4.4-1.7c0.4-0.3,0.7,0,1,0.1
		c1,0.5,2,1,3.1,1.5c-0.4-0.6-1-0.8-1.8-1.3c1.9-0.3,3.2-1.5,4.9-1.6c0.6,0,1.1-0.6,1.8-0.7c0.5-0.1,0.9,0,1.2,0.3
		c0.4,0.4,0.2,0.8,0,1.1c-0.6,1.1-0.8,2.3-1.2,3.5c0,0.6-0.2,1.1-0.7,1.4c-0.9,0-1.5-0.6-2.3-1c-0.6-0.3-1.1-0.2-1.7,0.1
		c-3,1.6-6,2.8-9.6,2c-2.8-0.6-5.6-0.9-8.5-0.5c-0.6,0.1-1.1,0.1-1.7,0c-0.8-0.1-1.7,0-2.4,0.2C123.5,872.9,123.2,872.4,123.5,871.7
		z"
      />
      <path
        class="st1372"
        d="M123.5,871.7c0.2,0.5,0.4,1,0.6,1.5c0.4,0.3,0.4,0.7,0.7,1.1c0.2,0.6,0.6,1.1,0.9,1.7
		c0.3,0.8,0.7,1.6,1.4,2.3c0.2,0.1,0.3,0.3,0.2,0.5c0.1,0.2-0.7-0.2-0.2,0.3s0.9,1,0.9,1.8c0,0.4,0,0.8-0.5,0.8
		c-1-0.4-1.8-1.1-2.3-2.1c-0.8-1.5-1.8-2.9-2.4-4.5c-0.7-2.1-2.2-3.7-3.3-5.5c-0.2-0.2-0.2-0.5-0.2-0.5c0.2,0.5,0.2,1.3,0.2,2
		c0,1.4-0.2,2.8-0.2,4.1c-0.3,1.7-0.2,3.4-0.5,5.1c-0.1,0.6,0.1,1.3-0.4,1.8c-0.3-2.2-0.9-4.4-0.5-6.8c0.3-2.2,0.5-4.5,0.6-6.8
		c0-0.5-0.1-1-0.2-1.4c0.2-0.3,0.5-0.2,0.8-0.2c-0.3-0.3-1,0.2-0.9-0.6c0.1,0,0.2-0.1,0.3-0.1c1.6,0.1,3.1-0.3,4.4-1.1
		c0.5,0.3,1-0.4,1.4,0.1c0.1,0.5-0.2,0.8-0.5,1.1c-0.8,0.8-1.3,1.7-0.8,2.9c0.1,0.2,0,0.4-0.1,0.6C123,870.4,123.2,871,123.5,871.7z
		"
      />
      <path
        class="st1373"
        d="M122.7,869.8c-1.3-2.1,0.5-3.2,1.4-4.6c0,0,0-0.1,0-0.2c0.2-0.5,0.6-0.7,0.9-1.1c0.8-0.7,1.4-1.5,1.5-2.6
		c0-0.2,0-0.6,0.4-0.5c0.4,0.4,0.8,0.8,1.3,1.2c0.4,0.4,0.8,0.8,1.2,1.3c0.7,0.9,1.4,1.7,2.6,1.9c0.2,0,0.4,0.2,0.6,0.4
		c-0.5,0.4-0.4,1.2-1.2,1.5c-0.9,0.3-1.9,0.5-2.7,1c-0.2,0.2-0.5,0-0.7-0.1c-0.6-0.5-1-0.2-1.4,0.3
		C125.6,869.4,124.3,870,122.7,869.8z"
      />
      <path
        class="st1374"
        d="M150.3,872.1c0.2-0.4,0.4-0.8,0.6-1.3c0.7-1.1,1.5-2.1,1.9-3.3c0.1-0.6,0.6-1.1,0-1.7c0.1,0,0.1,0,0.2,0
		c0.4-0.1,0.6,0.2,0.8,0.4c0.4,0.8,0.1,1.6-0.1,2.4c-0.4,1.1-1,2-1.4,3.1c-0.7,2.1-1.8,4-2.6,6.1c-0.2,0.5-0.3,1.2-0.8,1.5
		c-0.2,0-0.4,0-0.6,0c-0.2-0.2-0.5,0-0.7-0.2c0.4-1.7,1.1-3.3,1.8-5C149.6,873.4,149.7,872.6,150.3,872.1z"
      />
      <path
        class="st1375"
        d="M132.6,865.6c-2.2-0.3-3.3-1.9-4.3-3.6c1.2,0.6,2.3,1.5,3.8,1.8c2.5,0.6,4.8,0.4,7.1-1
		c0.3-0.2,0.6-0.5,1-0.4c-0.9,2.2-4.2,3.8-6.8,3.3C133.1,865.7,132.9,865.7,132.6,865.6z"
      />
      <path
        class="st1376"
        d="M141.6,855.4c0.5-0.2,0.9-0.4,1.4-0.5c1.3,0.5,2.8,0.4,4.1,0.6c0.5,0.1,1,0.1,1.5,0.2
		c-0.1,0.3-0.1,0.7-0.2,1.1c-0.2,0.4-0.5,0.7-1,0.6c-2.1-0.3-4.3-0.4-6.1-1.5C141.2,855.7,141.4,855.5,141.6,855.4z"
      />
      <path
        class="st1377"
        d="M141.4,855.8c1.7,0.9,3.7,1,5.5,1.5c0.5,0.1,1.1,0,1.5-0.4c0,0.5,0,1,0,1.4c-1.5-0.1-3.1-0.3-4.6-0.5
		c-0.8-0.1-1.5-0.5-2.3-0.7c-0.3-0.1-0.6-0.3-0.7-0.7C140.9,856.1,140.9,855.8,141.4,855.8z"
      />
      <path
        class="st1378"
        d="M141.4,855.8c-0.5,0.6-0.3,1,0.5,1.2c1.9,0.5,3.8,0.9,5.7,0.9c0.4,0,0.6,0.3,0.8,0.4c0,0.2,0,0.4,0,0.6
		c-0.9-0.8-2.1-0.2-3.2-0.6s-2.2-0.1-3.3-0.5c-0.6-0.2-1.2-0.5-1.2-1.4c0-0.6,0.2-1,0.8-1.1C141.5,855.6,141.5,855.7,141.4,855.8z"
      />
      <path
        class="st1379"
        d="M153.7,866.3c-0.2-0.2-0.4-0.3-0.6-0.4c0.3-0.4,0.6-0.1,0.9,0c1.1,0.3,2.1,0.7,3.2,0.9
		c0.1,0.3-0.1,0.3-0.3,0.4c-0.5,0.3-0.5,0.9-0.7,1.3c-0.2,0.4-0.2,0.9-0.6,1.1c-1,2.4-2,4.8-2.9,7.3c-0.4,0.9-0.7,1.8-1.1,2.7
		c-0.5,0-0.6,0.2-0.6,0.7c-0.3-0.5-1-0.2-1.3-0.7c-0.4-0.2-0.3-0.5-0.1-0.9c1.3-2.5,2.2-5.3,3.4-7.9c0.5-1.1,1-2.2,1.1-3.4
		C154.1,866.9,154,866.5,153.7,866.3z"
      />
      <path
        class="st1380"
        d="M156.8,866.9c0.1-0.1,0.3-0.2,0.4-0.2c1.7-0.1,3.2-0.5,4.6-1.5c0.9,1.3,2.5,1.9,3.5,3.1
		c0.7,0.9,1.7,1.5,2.4,2.3c0.6,0.8,0.9,1.7,0.8,2.7c-0.5,0.2-1,0.3-1.6,0.3c-0.9-0.6-1.5-1.4-2-2.3c0.1-0.6-0.3-0.9-0.7-1.2
		c-0.7-0.5-1-1.4-2-1.5c-0.6,0.1-0.9-0.4-1.1-0.7c-0.7-0.9-1.4-1.1-2.5-0.7c-0.4,0.2-0.8,0.2-1.2,0.2
		C157.1,867.3,156.8,867.3,156.8,866.9z"
      />
      <path
        class="st1381"
        d="M147.7,879.1c0.2,0.1,0.4,0.1,0.6,0.2c-0.1,0-0.3,0-0.5,0.1c0.4,0.2,0.8,0.4,1.1,0.6
		c-0.9,3.5-2.7,6.3-5.9,8.2c-0.9,0.5-1.9,0.7-2.5,1.6c-0.3,0.3-0.7,0.6-0.8,1.1c0,0,0,0.1,0,0.2c-0.6,0.2-0.6,1-1.2,1.3
		c-0.1,0.1-0.2,0.1-0.3,0c-0.2-0.1-0.2-0.3-0.3-0.5c-0.5-0.8-0.1-1.8-0.3-2.7c0-0.1,0-0.2,0.1-0.2c1-0.2,0.8-1.5,1.7-1.8
		c0.8-0.3,1.5-0.8,2.4-0.9c1.9-0.2,3.9-2.7,3.8-4.7c0-0.7-0.2-1.2-1-1.3c-0.4,0-0.8-0.1-0.8-0.6C145,878.9,146.3,879,147.7,879.1z"
      />
      <path
        class="st1382"
        d="M138.3,892.3c0.1,0,0.1,0,0.2,0c-1.1,1-2.1,1.9-3.2,2.9c-0.8,0.5-1.4,0.2-2.1-0.3c-1,0-1.9,0-2.9-0.1
		c0.1-0.1,0.1-0.1,0.2-0.2c1-0.5,2.1-0.4,3.1-0.9c0.7-0.3,1.1-0.9,1.6-1.4c1-1.1,1.4-1.2,2.6-0.3C138,892.1,138.1,892.2,138.3,892.3
		z"
      />
      <path
        class="st1383"
        d="M133.2,894.8c0.7,0,1.3,0.5,2.1,0.3c0.5,0.6,0.3,2-0.4,2.8s-1.2,0.7-2.5-0.2c1.1-0.3,0.8-1.3,1-2
		c0.1-0.6-0.5-0.4-0.9-0.5C132.8,895,133,894.9,133.2,894.8z"
      />
      <path
        class="st1384"
        d="M125.4,876c-0.3-0.5-0.6-1.1-0.8-1.7c0.2-0.4,0.4-0.7,0.8-0.6c1.8,0.4,3.7-0.1,5.6,0.1
		c1.4,0.2,2.8,0.4,4.1,1c2,1,2.5,2.6,1.1,4.4c-0.3,0.4-0.6,0.9-1,1.2c-0.3,0.2-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.3-0.4-0.5
		c-0.5-0.3-0.9-0.8-1.4-0.8c-0.3,0.2-0.5,0-0.7-0.3c-0.1-0.2-0.4-0.3-0.2-0.7c0.6-1.2,0.2-1.9-1.2-1.9c-0.5,0-1,0-1.5-0.2
		c-0.3-0.2-0.7-0.2-1-0.1C127,876.1,126.2,876.1,125.4,876z"
      />
      <path
        class="st1385"
        d="M133.9,879.8c0.1,0.1,0.3,0.1,0.4,0.2c0.4,1.3,0.5,2.6-0.1,3.8c-0.1,0.2-0.3,0.3-0.4,0.5
		c-0.5,0.8-0.3,1.1,0.6,1.2c0.8,0,1.5,0,2.3,0c0.6,0,1.1,0.1,1.4,0.7c0.1,0.3,0,0.5-0.2,0.7c-1.2,0.1-2.4,0.1-3.5-0.3
		c-1.7-0.5-2.8,0.3-3.7,1.5c-1.2,1.5-2.8,2.6-3.9,4.2c-0.6-0.1-1.5,0.3-1.4-0.8c1.7-2,3.8-3.7,5.6-5.7c0.9-1,2-1.7,2.3-3.2
		c0.1-0.6,0.2-1.1,0.2-1.7C133.4,880.5,133.5,880.1,133.9,879.8z"
      />
      <path
        class="st1386"
        d="M131.8,878.5c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.7,0.2,1.4-0.5,1.8c-0.7,0.3-1.2-0.1-1.7-0.6
		c-0.4-0.4-0.9-0.8-1.3-1.2c-0.6,0.2-0.6-0.2-0.5-0.6c0.9-0.8,1.9-0.3,2.8-0.1C131.4,878.3,131.6,878.4,131.8,878.5z"
      />
      <path
        class="st1387"
        d="M128.5,878.3c0,0.3,0,0.6,0.4,0.6c0,0.3,0.2,0.5,0.4,0.7c0.4,0.5,0.6,1,0.3,1.5c-0.3,0.6-0.9,0.6-1.6,0.5
		c0.2-0.9,0.2-1.8-0.6-2.5c-0.2-0.1-0.2-0.4-0.1-0.6C127.6,878.1,128,878,128.5,878.3z"
      />
      <path
        class="st1388"
        d="M127.4,878.8c1.3,1.2,1.4,1.8,0.7,2.9c-0.5,0.3-1,0.7-1.5,1c-0.5,0.1-1.1,0.4-1.6,0c0-0.5,0.5-0.4,0.8-0.5
		c0.6-0.1,1.2-0.4,1.7-0.7c0.7-0.5,0.2-1,0-1.5c-0.1-0.3-0.4-0.5-0.6-0.8C126.5,878.5,127.1,878.8,127.4,878.8
		C127.3,878.7,127.4,878.7,127.4,878.8z"
      />
      <path
        class="st1389"
        d="M125.8,882.3c-0.2,0.3-0.7,0-0.9,0.4c-1-0.4-1-1.5-1.7-2.2c-0.6-0.7-1.3-0.7-2.1-0.5
		c-0.5,0.1-1.1,0.4-1.6,0.1c-0.2-0.5,0.2-0.6,0.5-0.7c0.4-0.2,0.8-0.4,1.2-0.7c0.9-0.8,0.8-1.8-0.2-2.3c-0.7-0.3-1.5-0.6-2.1-1.2
		c-0.2-1.5,0.2-2.9,0.2-4.3c0.6-0.2,0.7,0.3,0.9,0.6c1.4,1.9,2.3,4.1,3.5,6.1c0.6,0.9,1.1,1.9,1.6,2.9
		C125.5,881,125.8,881.6,125.8,882.3z"
      />
      <path
        class="st1390"
        d="M119.5,880c0.8-0.1,1.6-0.2,2.3-0.4c0.9-0.2,1.3,0.1,1.5,0.9c-0.2,0.4-0.4,0.8-0.7,1.2
		c-0.1,0.2-0.3,0.3-0.4,0.5c-0.6,0.8-1.4,0.7-2.3,0.7c-0.6,0-1.1,0.2-1.7,0.4c0-0.3,0-0.7,0-1c0-0.6,0-1.3,0-1.9
		C118.7,879.9,119,879.6,119.5,880z"
      />
      <path
        class="st1391"
        d="M126.8,892.3c0.2-0.8,0.7-1.4,1.4-2c0.9-0.7,1.6-1.5,2.3-2.4c1.2-1.7,2.9-2,4.8-1.4
		c0.9,0.3,1.8-0.2,2.6,0.3c0.1,0.1,0.1,0.2,0,0.3c-1,0.3-2.1,0.3-3.1,0c-1.5-0.4-3.4,0.8-3.7,2.3c-0.1,0.4,0.1,0.6,0.4,0.8
		c0.4,0.3,0.8,0.7,1.3,0.9c1.2,0.6,1,1.8,1.2,2.8c-1.1,0.3-2.1,0.8-3.3,0.6c-0.9-0.5-1.9-1-2.9-1.2
		C127,893.2,126.9,892.8,126.8,892.3z"
      />
      <path
        class="st1392"
        d="M117.8,866.5c0.3,0.3,0.7,0.1,1.1,0.2c0.2,0,0.4-0.1,0.5,0.2c0,0.1-0.1,0.2-0.1,0.4c-0.2,0.3-0.3,0.1-0.5,0
		c-0.2-0.2-0.5-0.1-0.8-0.1c-0.2,0-0.4,0-0.6,0c-0.4-0.1-0.8-0.2-1.3-0.4l-0.1-0.1C116.6,865.8,117.2,866.1,117.8,866.5z"
      />
      <path
        class="st1393"
        d="M101.2,871.7c-0.3-0.6-0.7-1.2-1-1.9c0.3-0.6,1-0.5,1.5-0.9c2.4-1.7,5-3.2,7.2-5.1c0.2-0.2,0.4-0.3,0.6-0.4
		c0.6,0.1,1.1,0.4,1.8,0.3c0.6-0.1,1,0.4,1.3,0.8c0.1,0.2,0.2,0.3,0.1,0.5c-0.5,0.4-0.8,1.1-1.6,0.9c-0.5,0.3-0.3,0.7,0,1
		s0.6,0,0.8-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.8,0.2,1.7,1.1,1.7,1.9c0,0.7-0.5,1-1.1,1c-0.5,0-1.1,0.2-1.6,0c-0.2-0.1-0.4,0-0.5,0.2
		c0,0.1,0,0.1,0,0.2c0.2,0.2,0.4,0,0.6,0c0.4-0.1,0.7-0.5,1.1-0.1c0.2,0.5-0.3,0.7-0.5,1c-0.4,0.4-0.5,1-1,1.3
		c-0.5,0.8-0.6,1.6-0.6,2.5c-0.7-0.3-0.4-0.9-0.4-1.4c0.1-1.2,0.2-1.2-1-1.5c-0.6-0.2-1-0.5-1.3-1.1c-0.2-0.5-0.4-1-0.6-1.4
		c-0.1-0.3-0.2-0.7-0.7-0.5c-0.4,0.2-0.8,0.4-1.1,0.7c-1.2,0.8-2.5,1.7-3.8,2.4C101.3,871.8,101.2,871.7,101.2,871.7z"
      />
      <path
        class="st1394"
        d="M112.7,865c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1-0.5,0.3-0.7,0.5-0.7c0.9,0.1,1.3-0.6,1.8-1.1
		c0.1-0.1,0.2-0.2,0.4-0.3c0.9,0.2,1.6-0.5,2.4-0.4c0.3,0.3,0.6,0.5,1,0.5c1.6,0.1,3.2,0.2,4.7-0.5c0.5-0.2,1-0.5,1.4,0.1
		s0.1,1.1-0.3,1.6c-0.5,0.5-0.7,1.2-1.5,1.4c-1.5,1.3-3.2,1.4-5,1.3c-0.6,0.1-1.2-0.2-1.8,0.1c-0.3-0.3-0.6-1-1.1-0.2
		C114,866.2,113.2,865.8,112.7,865z"
      />
      <path
        class="st1395"
        d="M106.2,868.5c0.3-0.5,0.7-0.5,0.9,0c0.2,0.4,0.4,0.8,0.5,1.2c0.3,1,0.8,1.7,2,1.7c0.9,0,0.7,0.8,0.7,1.3
		c-0.1,0.6-0.4,1.2,0,1.8c0,0.3,0,0.7,0,1c-0.2,0.3-0.5,0.4-0.8,0.4c-0.7-0.9-1.7-0.9-2.7-1.1c-1.5-0.3-2-1.1-1.7-2.5
		C106,871.3,106,869.9,106.2,868.5z"
      />
      <path
        class="st1396"
        d="M109.5,875.8c0.3-0.1,0.5-0.2,0.8-0.2c0.2,0.5,0.6,0.9,0.3,1.5c-1.3,0.5-2.5,1.2-3.7,1.9
		c-0.9,0.5-1.6,0.5-2.5-0.1c-1-0.7-2.2-1.2-3.2-2c0.1-1,0.7-2.1,0.2-3.2c-0.1-0.7-0.2-1.4-0.2-2.1c0.1,0,0.2,0,0.2,0
		c0.5,0.6,0.5,1.4,0.7,2.1c0,0.3,0,0.5,0,0.8c-0.1,1,0.3,1.4,1.2,1.7c1.5,0.6,3,0.5,4.6,0C108.4,876.1,109,876,109.5,875.8z"
      />
      <path
        class="st1397"
        d="M111.8,870.7c0.2-0.3,0.4-0.5,0.7-0.8c1-0.5,1.7-1.4,2.7-1.7c0.4-0.2,0.6-0.1,0.6,0.4
		c-0.1,1.2-0.2,2.3-0.2,3.5c-0.1,0.5-0.4,0.7-0.9,0.6c-1.2-0.3-2.2-0.8-2.9-1.8C111.7,870.8,111.8,870.7,111.8,870.7z"
      />
      <path
        class="st1398"
        d="M115.2,868.1c-0.8,0.7-1.7,1.3-2.7,1.7c-0.5-0.3-0.8,0.2-1.2,0.3c-0.3,0.1-0.5,0.4-0.8,0
		c-0.2-0.2-0.2-0.5,0-0.8s0.6-0.5,0.8-0.3c0.6,0.6,1.2,0.1,1.8,0.2c0.6,0.1,1.1-0.4,0.7-1.1c-0.3-0.6-0.6-1.3-1.4-1.4
		c-0.6-0.2-1.2-0.3-1.3-1c0.8,0.5,1-0.7,1.7-0.7c0.7,0.5,1.6,0.8,2.2,1.5C115,867,115.1,867.6,115.2,868.1z"
      />
      <path
        class="st1399"
        d="M111.8,870.7c0.6,1,1.6,1.4,2.7,1.7c0.5,0.2,0.8,0,1.1-0.3c0.1,1-0.2,2-0.3,3.2c-0.8-1.6-2.1-1.9-3.3-2.4
		c-0.5-0.2-0.8-0.5-1-0.9C111.1,871.5,111.4,871,111.8,870.7z"
      />
      <path
        class="st1400"
        d="M101.2,876.9c0.8-0.2,1.3,0.6,1.8,1c2.2,2,4.2,1.1,6.2-0.3c0.4-0.3,0.9-0.5,1.4-0.5
		c-0.2,0.1-0.3,0.3-0.4,0.4c-0.7,0.4-1.5,0.8-2.2,1.1c-1.5,0.7-1.5,0.7-0.4,1.9c-1.1-0.8-2.1,0-3.2-0.1c0.8-0.9,0.2-1.4-0.6-1.8
		c-0.9-0.5-1.8-0.7-2.6-1.4C101.2,877.1,101.2,877,101.2,876.9z"
      />
      <path
        class="st1401"
        d="M106.6,863.5c0.2-0.3,0.6-0.4,0.6-0.8c0.6-0.3,1.1-0.5,1.7-0.1c0.1,0.2,0,0.4-0.1,0.6
		c-2.5,2.6-5.3,4.7-8.6,6.1c-0.2,0.1-0.3,0.1-0.5-0.1c-0.1-0.2-0.3-0.3-0.4-0.4c0.3-0.8,1.1-1.2,1.8-1.5c1.8-0.9,3.2-2.3,5-3.1
		C106.4,864,106.5,863.7,106.6,863.5z"
      />
      <path
        class="st1402"
        d="M99.7,869.2c3.1-1.4,5.9-3.1,8.2-5.7c0.3-0.3,0.9-0.4,0.9-1c0.2-0.4,0.3-0.1,0.4,0c0.1,0.2,0.2,0.5,0.3,0.8
		c0,0.1,0,0.2-0.1,0.2c-2.4,2.2-5.1,3.8-7.8,5.6c-0.5,0.3-1.1,0.4-1.6,0.7C100,869.6,99.9,869.4,99.7,869.2z"
      />
      <path
        class="st1403"
        d="M165.8,899c-0.7-1.2-2-1.7-3.1-2.3c-2.1-1.3-4.4-2.3-6.6-3.5c-0.8-0.4-1.3-1.2-1.9-1.9
		c-0.3-0.3,0.1-0.6,0.5-0.6s0.6,0.2,0.9,0.4c0.4,0.2,0.8,0.8,1.4,0.4c3.2,1.4,6.2,3.3,9.5,4.8c0.2,0.1,0.3,0.2,0.4,0.5
		C166.2,897.3,166.1,898.2,165.8,899z"
      />
      <path
        class="st1404"
        d="M139.7,890.8c-0.2-0.6,0-1,0.6-1.1c0.7-0.6,1.6-0.6,2.3-0.9c1.9-0.9,3.1-2.4,4.4-3.9c0.9-1,1.7-2.1,1.7-3.6
		c0-0.4,0.3-0.8,0.5-1.2c0.9-0.1,1.1,0.3,1,1.2c-0.6,3.2-2.8,5.2-5.1,7.1c-0.6,0.6-1.5,0.9-2.3,1.4c-0.3,0.2-0.5,0.3-0.8,0
		c-0.2-0.2-0.4-0.2-0.5,0.2C140.9,890.1,140.3,890.3,139.7,890.8z"
      />
      <path
        class="st1405"
        d="M149.3,880.2c0.1,0.2,0.2,0.3,0.1,0.5c-0.5,2.7-2.1,4.7-4.1,6.5c-1.4,1.4-3.1,2.3-5,2.6
		c-0.1-0.5,0.2-0.8,0.6-0.9c2.7-0.9,4.6-2.9,6.2-5c0.3-0.4,0.5-0.9,0.6-1.4c0.1-1,0.4-1.7,1.1-2.4
		C149,880.1,149.2,880.1,149.3,880.2z"
      />
      <path
        class="st1406"
        d="M153.7,866.3c1.2,0.4,0.7,1.4,0.5,2c-0.3,1.4-0.9,2.7-1.5,4c-0.9,2-1.7,4-2.5,6c-0.1,0.4-0.9,0.6-0.5,1.2
		c-0.3,0-0.6,0.1-0.8-0.2c0.3-0.8,0.6-1.7,0.9-2.5c1.2-2.8,2.4-5.5,3.6-8.3C153.8,867.9,153.8,867.1,153.7,866.3z"
      />
      <path
        class="st1407"
        d="M156.8,866.9c0.9,0.4,1.8,0,2.7-0.3c0.5-0.2,0.8-0.2,1.1,0.3c0.5,0.6,1.1,1.1,1.6,1.6
		c0,0.4,0.4,0.5,0.5,0.7c0.2,0.3,0.9,0.3,0.4,0.9c-0.4,0.5-0.7,0.6-1.3,0.3c-0.3-0.2-0.4,0.1-0.6,0.2c-0.8,0.3-1.5,1-2.4,1.2
		l-0.1-0.1c0.2-0.2,0.5-0.4,0.9-0.8c-0.9-0.4-1.9-0.3-2.7-0.4c-0.8-0.1-1.3-0.3-1.5-1.1C155.9,868.7,155.9,867.6,156.8,866.9z"
      />
      <path
        class="st1408"
        d="M158.9,871.9c-0.7,0.6-1.6,1.1-2.1,1.9c-0.3,0.4-2,0.3-2.3-0.1c-0.2-0.3,0-0.6,0.2-0.8s0.5-0.3,0.6-0.2
		C156.7,873,157.7,872.4,158.9,871.9C158.9,871.8,158.9,871.9,158.9,871.9z"
      />
      <path
        class="st1409"
        d="M119.8,844c0.1,0.6,0.7,1.1,0.2,1.7c-0.4-0.8-0.8-1.6-1.3-2.3C119.1,843.4,119.8,843.1,119.8,844z"
      />
      <path
        class="st1284"
        d="M113.5,837.7c-0.3-1.1-1.2-1.9-1.2-3.1C113,835.5,113.5,836.5,113.5,837.7z"
      />
      <path
        class="st1410"
        d="M110.3,855c-0.3-0.5-0.7-0.7-1.4-0.8c-1.6-0.1-3-1.1-4.5-1.5c-0.2-0.1-0.4-0.1-0.6-0.2
		c-0.5-0.7-1.3-0.9-2-1.2c-1.3-0.6-1.6-1-1.2-2.4c0.1-0.5,0.4-0.3,0.6-0.2c1.3,0.7,2.6,1.4,3.8,2.1c0.3,0.2,0.5,0.4,0.8,0.5
		c0.5,0.2,0.9,0.8,1.3,0.5c0.3-0.2,0.1-0.9,0.6-1.3c0.2-0.2-0.3-0.4-0.5-0.5c-1-0.3-2-0.7-3-0.7c-1-0.1-1.6-0.8-2.3-1.2
		c-0.6-0.4-0.9-0.7-1.7-0.2c-1.1,0.6-2.3,0.8-3.5,1.1c-0.3,0.1-0.9,0.3-1.1-0.4c0-0.5,0.4-0.7,0.7-1c0.5-0.5,1-1.1,1.3-1.8
		c0.4-0.9,1.4-1.1,1.9-1.8c0.3,0.5,0.4,1.1,0.8,1.5c0.4,0.3,1,0.2,1.4,0.5c2.3,1,4.6,1.8,7,2.6c0.3,0.1,0.5,0.2,0.8,0.3
		c0.5,0.2,0.7,0.7,0.7,1.2c0,0.4,0,0.8,0.2,1.1c0.4,0.9,0.7,1,1.6,0.6c0.3-0.1,0.5-0.2,0.7,0.1C112.1,852.9,111.4,854.1,110.3,855z"
      />
      <path
        class="st1411"
        d="M112.8,851.9c-0.1-0.2-0.3-0.2-0.4-0.1c-0.4,0.2-0.7,0.8-1.2,0.5c-0.6-0.5-1.1-1-1.1-1.9
		c0-0.5,0.1-1-0.4-1.4c0.4-0.4,1.1-0.5,1.2-1.1c0.1-0.3,0.4-0.4,0.6-0.6c1.1-0.8,1.8-2,3.2-2.5c0.4,0.2,0.4,0.6,0.4,1
		c-0.2,1.3,0,1.5,1.2,1.8c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0.2-0.8,0.6,0.1,0.8c0.3,0.1-0.1,0.5,0.3,0.9s-0.5,0.5-0.8,0.6
		c-0.5,0.2-1.1,0.3-1.7,0.5c0,0,0,0.1,0,0.2C114.5,851.4,113.7,851.7,112.8,851.9z"
      />
      <path
        class="st1412"
        d="M111.6,847.3c0,0.3-0.3,0.4-0.4,0.7c-0.3,0.6-0.7,0.5-1.2,0.4c-2.7-0.7-5.5-1.3-8.1-2.2
		c-0.4-0.3-1.1,0.1-1.3-0.6c2-0.1,3.9,0.5,5.8,0.7C108.2,846.2,109.9,846.6,111.6,847.3z"
      />
      <path
        class="st1413"
        d="M132.2,851.7c-0.4,0.5-0.9,1-1.4,1.2c-1.3,0-2-0.5-2.1-1.4c-0.1-0.6,1-1.9,1.7-2.1c0.7-0.2,1.7,0.5,1.8,1.3
		C132.3,851,132.2,851.3,132.2,851.7z"
      />
      <path
        class="st1414"
        d="M128.9,854.8c-0.7-0.4-1.2-0.1-2,0.4c1.3,0,2.3-0.2,2.9,0.9c-1.4,0.1-2.8,0.8-4.3,0.3
		c-0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.3,0.1-0.4c0.7-0.5,1.2-1.2,2.1-1.2C128.4,854.2,128.9,854.1,128.9,854.8z"
      />
      <path
        class="st1415"
        d="M119.9,850.6c0.1,0,0.1,0,0.2,0c0.5,0.2,1,0.3,1.4,0.7c-0.1,1.6-0.8,2.9-1.8,4c-1.7,2.1-3.6,3.9-5.7,5.5
		c-0.8,0.7-1.7,1.4-2.6,1.8c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.3-0.4-0.5-0.6-0.8c-0.1-0.4-0.1-0.7,0-1.1
		c0.3-0.5,0.8-0.7,1.3-0.8c2.3-0.7,4.1-2.3,5.7-4c1.1-1.2,1.9-2.5,2.7-3.8C119.7,851.5,120.2,851.2,119.9,850.6z"
      />
      <path
        class="st1416"
        d="M110.8,862.7c1.6-0.6,2.9-1.8,4.2-2.9c1.4-1.3,2.9-2.5,4.1-4c1-1.2,1.9-2.5,2.2-4.1c0-0.1,0.2-0.2,0.3-0.4
		c0.1,0,0.3,0.1,0.4,0.2v0.1c0.2,0.8-0.1,1.4-0.4,2c-1.2,2.2-2.8,4-4.7,5.6c-0.8,0.7-1.6,1.4-2.4,2.2c-0.9,0.9-2,1.6-3.2,2.1
		C111,863.4,110.7,863.2,110.8,862.7z"
      />
      <path
        class="st1417"
        d="M125.7,855.8c-0.1,0.1-0.1,0.3-0.1,0.4c0.3,0.8,1,0.6,1.6,0.5c1,0,1.9-0.4,2.8-0.5c0.1,0.2,0.1,0.3,0.2,0.5
		c0.1,0.5-0.3,0.5-0.6,0.6c-0.6,0.2-1.3,0.3-1.9,0.3c-0.8,0-1.4,0.3-1.6,1.1c-0.6,0-1.1,0.2-1.6,0.5c-1.3,0.7-2.8,0.9-4.1,1.6
		c-0.6,0.3-1-0.1-0.9-0.8c0.9-1.3,2-2.5,3-3.8c0.5-0.7,0.9-1.4,1.5-2c0.2-0.2,0.3-0.5,0.7-0.4C125.2,854.4,125,855.4,125.7,855.8z"
      />
      <path
        class="st1418"
        d="M110.8,862.7c0.1,0.3,0.3,0.5,0.6,0.6c0.6-0.1,1.2-0.3,1.7-0.7c3.2-2.7,6.4-5.3,8.5-9
		c0.3-0.6,0.2-1.4,1.1-1.7c0.2,0,0.3,0.2,0.4,0.4c0.1,0.5-0.1,1-0.3,1.5c-1.4,2.9-3.8,5-6,7.3c-0.5,0.5-1,1.2-1.6,1.6
		c-0.1,0.2-0.2,0.3-0.4,0.5c-0.5,0.4-0.7,1.3-1.7,0.9c-0.2-0.1-0.6,0.2-0.7,0.5c-0.5-0.5-1-1-1.9-0.6c-0.5,0.2-0.7-0.3-1-0.5
		c0-0.1,0-0.1,0-0.2c0.5-0.2,0.5-0.8,1-1C110.6,862.4,110.7,862.6,110.8,862.7z"
      />
      <path
        class="st1419"
        d="M115.2,862.7c0.2-0.7,0.9-1.2,1.4-1.7c2.3-2.4,4.7-4.7,6.3-7.7c0.2-0.4-0.3-0.8,0.3-1
		c0.2,0.2,0.4,0.4,0.6,0.6c-0.1,1.4-1.1,2.4-1.7,3.5c-0.6,1-1.4,1.8-2.1,2.6c-0.4,0.5-0.8,1-1.1,1.6c-0.4,0.5-0.8,0.9-1.3,1.4
		C116.8,862.7,116,863,115.2,862.7z"
      />
      <path
        class="st1401"
        d="M122.6,851.9c-0.4,0.8-0.8,1.5-1.2,2.3c-1.1,2-2.7,3.5-4.3,5.1c-1.3,1.3-2.7,2.7-4.2,3.7
		c-0.4,0.3-0.9,0.5-1.5,0.3c2.7-1.4,4.7-3.7,6.8-5.7c1.8-1.7,3.3-3.6,3.8-6.1C122.2,851.6,122.3,852,122.6,851.9z"
      />
      <path
        class="st1420"
        d="M118.5,860.6c0.6-1.2,1.5-2.1,2.4-3.1c1.2-1.4,2-3,2.8-4.6c0.4,0.2,0.6,0.7,0.8,1c-1.4,1.8-2.9,3.6-4.3,5.4
		c-0.2,0.2-0.5,0.4-0.7,0.6C119.2,860.3,119.1,860.9,118.5,860.6z"
      />
      <path
        class="st1421"
        d="M119.9,850.6c0.3,0.4,0.3,0.6-0.1,1.1c-1.1,1.5-2,3.1-3.3,4.4c-1.5,1.5-3.1,3.1-5.2,3.6
		c-0.5,0.1-0.9,0.4-1.4,0.6c-0.8-0.5-0.4-1.3-0.5-2.1c0.1-1-0.2-2,0.7-2.8c0.1-0.2,0.1-0.4,0.2-0.6c0.8-1.1,1.4-2.3,2.5-3.1
		c0.7-0.4,1.4-0.8,2.1-1.2C116.6,850.3,118.3,850.1,119.9,850.6z"
      />
      <path
        class="st1422"
        d="M126,858.8c0-1.3,0.1-1.2,1.9-1.4c0.8-0.1,1.6-0.2,2.3-0.7c0.6,1.7,0.4,2.5-0.8,3.3
		c-0.7,0.1-1.4,0.3-2.1,0.4c-0.1,0-0.1,0-0.2,0c-0.5,0.2-1,0.8-1.5,0.3c-0.4-0.4,0.1-1.1,0.2-1.6C125.8,859,125.9,858.8,126,858.8z"
      />
      <path
        class="st1423"
        d="M130,856.2c-1.1,0.8-2.3,0.8-3.6,0.9c-0.6,0-0.9-0.2-0.8-0.9c1.4,0.3,2.8-0.6,4.2-0.2
		C129.9,856.1,129.9,856.2,130,856.2z"
      />
      <path
        class="st1424"
        d="M126,858.8c0.1,0.7-0.3,1.2-0.3,1.9c0.6,0.2,1-0.8,1.6-0.2c-0.2,0.1-0.2,0.2-0.2,0.4
		c-0.4,1.3-0.5,2.6-1.9,3.3c-0.5,0.2-0.8,0.6-1.1,1l0,0c-0.3-0.1-0.6,0.1-0.9,0.2s-0.3,0-0.3-0.3c0.5-0.6,1-1.2,1.5-1.8
		c0.3-0.4,0.2-0.7,0.1-1c-0.2-0.4-0.6-0.2-0.8-0.1c-1.7,0.8-3.6,0.6-5.4,0.7c-0.6,0-0.9-0.2-0.9-0.8c0-0.7,0.7-1,1.1-1.5
		c0.3-0.2,0.7-0.4,1-0.6c0,0.7,0.3,1,0.9,0.6c0.8-0.5,1.7-0.8,2.6-1c0.7-0.2,1.4-0.5,1.9-1C125.4,858.2,125.7,858.4,126,858.8z"
      />
      <path
        class="st1425"
        d="M109.7,858.3c0.1,0.7-0.2,1.4,0.2,2.1c0,0.4,0,0.8,0,1.2c0,0.6-0.3,0.9-0.8,1c-0.1-0.1,0-0.4-0.3-0.2
		c-0.6-0.1-1,0.5-1.6,0.2c-0.8-0.2-0.7-1-0.8-1.5c-0.2-1.3-0.2-2.6-0.2-3.8c0.6-0.7,1.2-0.3,1.9-0.2
		C108.8,857.2,109.4,857.6,109.7,858.3z"
      />
      <path
        class="st1426"
        d="M109.7,858.3c-0.6-0.4-1.1-0.8-1.7-1.2c0-0.4-0.2-0.6-0.6-0.7c-0.8-0.3-1.4-1-2-1.6
		c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.1,0.3-0.1,0.4-0.1c1.4,0.5,2.9,0.4,4.3,1.2c0.1,0,0.2,0.1,0.2,0.2
		C109.8,856.5,109.7,857.4,109.7,858.3z"
      />
      <path
        class="st1427"
        d="M134.9,880.4c0.4-0.8,1.2-1.3,1.5-2.2c0.4-1.1,0-2.3-1-2.9c-1.4-0.8-3-1.1-4.6-1.2c-1.6-0.1-3.1,0.1-4.6,0
		c-0.6,0-1.2-0.4-1.7,0.2c-0.2-0.4-0.4-0.8-0.5-1.2c0.7-0.4,1.3-0.7,2.2-0.5c1,0.3,2.1-0.1,3.2-0.1c3.1-0.1,6.2,0.4,9.3,0.9
		c0.8,0.1,1.4,0,2.1-0.2c1.3-0.4,2.6-0.8,3.9-1.2c0.8-0.2,1.1-0.9,1.8-1.2c0.4-0.2,0.6-0.3,1.1,0c0.8,0.5,1.8,0.9,2.6,1.4
		c-0.9,2.3-1.8,4.7-2.7,7c-1.2,0.4-2.7,0-3.8,0.8c-0.6,0.6-1.4,0.8-2.1,1.2c-1.1,0.7-1.8,1.9-2.7,2.8c-0.6,0.4-1.3,0.3-1.9,0.1
		c-0.7-1.1-1.2-2.4-2.1-3.4C134.9,880.6,134.9,880.5,134.9,880.4z"
      />
      <path
        class="st1428"
        d="M127.4,878.8c-0.1,0-0.1,0-0.2,0c-0.9-0.8-1.4-1.7-1.8-2.7c1.3,0,2.5-1,3.9-0.3c0.5,0.2,1.1,0.1,1.7,0.1
		c0.7,0,1.2,0.4,0.9,1.1c-0.1,0.4,0.6,1.1-0.4,1.3c0,0,0.4,0,0.2,0.3c-1.1-0.1-2.2-0.5-3.3-0.2C128,878.3,127.6,878.3,127.4,878.8z"
      />
      <path
        class="st1429"
        d="M125.8,882.3c-0.6-0.4-0.5-1.1-0.8-1.7c-1.8-3.1-3.4-6.3-5.3-9.4c-0.1-0.2-0.3-0.2-0.5-0.3
		c0-0.8,0-1.5,0-2.3c0.5,0.9,0.9,1.8,1.6,2.5c1.4,1.5,1.8,3.6,2.9,5.3c1.1,1.8,1.8,4.1,3.9,5.2C127.1,882.1,126.5,882.2,125.8,882.3
		z"
      />
      <path
        class="st1430"
        d="M119.5,880c-0.4,0.1-0.8,0.1-1.2,0.2c0.3-1.2,0.2-2.5,0.4-3.7c0.1-0.4-0.1-0.9,0.2-1.3
		c0.9,0.1,1.6,0.7,2.4,1.1c1.1,0.5,1.1,1.8,0.1,2.5C120.8,879.3,120.2,879.6,119.5,880z"
      />
      <path
        class="st1372"
        d="M124.1,865.2c0-0.7,0.2-1.2,1.1-1C124.9,864.6,124.5,865,124.1,865.2z"
      />
      <path
        class="st1382"
        d="M138.9,883.8c0.8-0.9,1.4-2,2.5-2.7c0.8-0.5,1.5-1,2.4-1.1h0.1c0.7,0.3,2-0.1,2,1.3c0,0.9,0,1.7-0.5,2.5
		c-0.6,1-1.5,1.8-2.3,2.5c-0.4,0.3-0.8,0.5-1.2,0.4c-1-0.1-1.8,0.8-2.7,1c-0.3,0.1-0.3,0.5-0.3,0.8c0,0.4,0.1,0.8-0.6,0.5
		c-0.4-0.2-0.4,0.2-0.4,0.5c0,0.6,0.5,1.1-0.1,1.7c-0.1,0.2,0.2,0.3,0.4,0.4c0.3,0.2,0.1,0.3-0.1,0.3c-1.3-0.1-1.6-0.4-1.2-1.5
		c0.2-0.6,0.3-1.1,0.2-1.7c-0.2-0.8,0.1-1.3,0.8-1.5c0-0.1,0-0.2,0-0.2c0.1-0.2,0.1-0.4,0.2-0.6
		C137.8,885.2,138.3,884.5,138.9,883.8z"
      />
      <path
        class="st1431"
        d="M137.9,887.1c-0.6,0.5-0.8,1.1-0.5,1.8c0.2,0.5,0,1-0.2,1.3c-0.6,1.1-0.1,1.5,0.9,1.7
		c0.1,0.1,0.2,0.3,0.2,0.4c-1.5-1-2.6-0.5-3.6,0.9c-0.2,0.3-0.5,0.6-0.9,0.7c-0.2-0.6-0.5-1.1-0.6-1.9c0-0.5-1.1-1-1.7-1.5
		c-1.1-0.8-1.2-0.8-0.4-2c0.8-1,1.5-2.4,3.1-1.9C135.5,887.1,136.6,887,137.9,887.1z"
      />
      <path
        class="st1432"
        d="M134.9,880.4c0,0.1,0,0.1,0,0.2c0.9,1.8,1.1,2-0.1,3.3c-0.3,0.1-0.6,0.1-0.8-0.2c0.5-1.2,0-2.5,0.2-3.7
		C134.5,880.1,134.7,880.3,134.9,880.4z"
      />
      <path
        class="st1433"
        d="M134.1,883.7c0.3,0,0.6,0,0.9,0c0.7-0.3,1.4-0.1,2,0.2c0.6-0.1,1.3,0.3,1.9-0.2c-0.3,0.8-0.6,1.7-0.9,2.5
		c-0.6-0.6-1.3-0.4-2-0.4c-0.4,0-0.8,0-1.2,0c-0.5-0.1-1.2,0.2-1.4-0.3C133.1,884.8,133.4,884.1,134.1,883.7z"
      />
      <path
        class="st1434"
        d="M101.8,874.6c0-0.3,0-0.5,0-0.8c1.2-1.5,2.6-2.8,3.3-4.6c0.3-0.2,0.7-0.4,1-0.6c0.4,1.5,0.1,2.8-0.8,4
		C104.4,873.5,103.4,874.5,101.8,874.6z"
      />
      <path
        class="st1435"
        d="M105.2,869.2c0.3,0.8-0.4,1.3-0.8,1.9c-0.7,1-1.5,2-2.6,2.7c-0.1-0.7-0.4-1.4-0.4-2.1
		C102.6,870.8,103.9,870,105.2,869.2z"
      />
      <path
        class="st1436"
        d="M110.9,865.6c0.4,0.3,0.9,0.7,1.3,1c-0.2,0.5-0.6,0.8-1.2,0.8c-0.5,0-0.5-0.6-0.6-1
		S110.3,865.7,110.9,865.6z"
      />
      <path
        class="st1437"
        d="M101.8,874.6c1.3-0.4,2.3-1.4,3.5-2.1c-0.6,1.5,0.7,2.1,1.9,2.3c0.3,0,0.7,0,1,0.1c0.6,0.1,1,0.5,1.3,1
		c-2.2,1.3-4.6,1.5-7,0.5C101.8,875.9,101.8,875.3,101.8,874.6z"
      />
      <path
        class="st1401"
        d="M109.1,862.7c0.3-0.3,0.5-0.7,0.8-1c0.4,0,0.6,0.2,0.6,0.6c-0.2,0.5-0.5,0.9-1,1
		C109.4,863.1,109.3,862.9,109.1,862.7z"
      />
      <path
        class="st1438"
        d="M101.8,846.1c1.1,0,2.2,0.4,3.2,0.7c1.8,0.5,3.6,1,5.4,1.5c0.4,0.1,0.5-0.2,0.7-0.2
		c-0.2,0.8-0.7,1.1-1.4,1.1c-2.4-0.5-4.6-1.6-6.9-2.4C102.4,846.5,102.1,846.2,101.8,846.1z"
      />
      <path
        class="st1439"
        d="M113.4,848.4c0-0.4,0.2-0.7,0.4-1c0.2-0.2,0.4-0.5,0.8-0.3s0.3,0.5,0.3,0.8c-0.1,0.4-0.2,0.9-0.3,1.3
		c-0.1,0.3-0.1,0.8-0.6,0.8c-0.4,0-0.4-0.5-0.5-0.8C113.5,848.9,113.5,848.6,113.4,848.4z"
      />
      <path
        class="st1440"
        d="M137,883.9c-0.7,0.2-1.3-0.2-2-0.2c0.9-1,0.1-2.1,0-3.1C136.1,881.4,136.7,882.6,137,883.9z"
      />
      <path
        class="st1441"
        d="M144.5,883.9c0,0.9-1.3,2.1-2.2,2.1c-0.7,0-1.3-0.8-1.3-1.5c0-1.1,1.1-2.2,2.1-2.2
		C143.9,882.3,144.5,883.1,144.5,883.9z"
      />
    </g>
    <g id="chart">
      <path
        class="st1442"
        d="M117.3,292.1c-2.6,5.4-4.9,11-7.8,16.3c-2.3,4.3-3.5,4.3-6.9,1.1c-4.5-4.2-4.4-4.2-7.6,1.2
		c-2.4,4-3.8,8.6-7.2,12c-2.2,0.1-1.9-2.3-3.2-3.2c0.4-3.9,2.5-7.2,4.2-10.5c2.1-4.2,4.4-8.3,6.5-12.5c1.2-2.3,2.5-4.3,5-5.4
		c0.9-0.3,1.7-0.3,2.6,0.2c0.6,3,1.1,5.9,3.7,8.6c2-5.1,4.8-9.7,6.7-14.7c1.8-2.6,2.3-6.1,5.1-8.1c1.2-0.2,2.2,0.3,2.1,1.5
		c-0.1,2.1,1.1,3.9,0.8,6C120.4,287.3,118.3,289.4,117.3,292.1z"
      />
      <path
        class="st1443"
        d="M164.7,270.7c-5.7,11.4-11.3,22.8-17.1,34.1c-2.6,5-4.4,5.4-9.1,2c0.2-1.3,0.9-2.3,1.9-3.1
		c3.4-1,4.9-3.4,4.6-6.9c0.1-1.3,0.5-2.5,1.1-3.6c3.9-7,6.6-14.7,11.7-21c2.1-0.3,4.3-0.8,6.1-2
		C164.3,270.1,164.6,270.3,164.7,270.7z"
      />
      <path
        class="st1444"
        d="M85.4,320.1c1.7,0,1.1,2.3,2.5,2.5c-3.5,3.7-6.2,0.5-9-1.1c-2.9-1.6-2.3-4.1-1.1-6.5
		c3.8-7.8,7.7-15.5,11.3-23.4c1.8-4,4.3-5.5,8.2-3.2c4.1,2.5,5.9,0.5,7.4-3.1c1.7-4.1,3.7-8,6.2-11.7c2.3-3.4,4.8-3.8,8.2-1.8
		c2.3,1.4,4.4,2.9,6.1,5c0.1,0.7-0.4,1-1,1.1c-0.4,0-0.8-0.1-1.1-0.2c-1.3-0.8-2.6-1.7-3.9-2.5c-2.9-2.4-5-1.2-6.9,1.5
		c-2.8,4.1-4.4,8.9-6.8,13.1c-0.6,0.9-1.3,1.6-2.5,1.8c-0.9,0-1.9,0.1-2.8,0.1c-0.4,0.2-0.8,0.2-1.2,0.2c-0.7-0.1-1.4-0.2-2-0.6
		c-3-1.8-4.7-0.6-5.8,2.4c-2.7,6.8-6.6,12.9-9.7,19.4C79.4,317.6,80.7,319.4,85.4,320.1z"
      />
      <path
        class="st1445"
        d="M140.3,304.2c0,1.3-0.7,2.2-1.9,2.7c-6.3-4.3-11.3-10.1-16.9-15.2c-2-1.8-3-1.6-4.2,0.5
		c0.1-3,1.1-5.5,3.5-7.5c3.4-0.5,4.3,2.9,6.3,4.4c1.3,1,2.5,2.5,3.9,3.5c1.4,1.2,2.7,2.5,3.7,4.1
		C136.5,299.2,139.9,300.7,140.3,304.2z"
      />
      <path
        class="st1446"
        d="M186,239.1c1.3,3.1,0.5,6.4,0.8,9.5c0.2,2.2-1.1,2.6-2.7,2.3c-4.2-0.8-6.6,1.1-7.9,4.8
		c-1.9,1-1.3,3.4-2.7,4.8c-0.7,0.7-1,1.8-2.3,1c-1.1-1.5-1.5-3.2-1.5-5c0-0.6,0.1-1.3,0.3-1.9c2.6-3.1-0.6-6-0.4-9
		c2.6-1.4,3.4,1.2,4.8,2.3c0.2,0.5,0.5-0.7,0-0.3c-0.1,0.1-0.2,0-0.3-0.2c-0.5-0.7-0.3-1.3,0.1-2c1.2-0.9,2.6-1.4,3.9-1.9
		c1.5-0.1,2,0.8,2.4,2c0.3,0.9,0.5,2.3,1.8,2.1c1.5-0.2,1.3-1.7,1.4-2.8c0.1-1.4,0-2.9,0.7-4.3C184.8,239.7,185.3,239.3,186,239.1z"
      />
      <path
        class="st1447"
        d="M124.3,277.5c0.3-0.2,0.6-0.5,0.9-0.7c4.9,3.7,8.4,8.9,13.8,12.3c5.9-11,11.6-21.9,17.4-32.7
		c0.6,0.5,0.8,1.1,0.8,1.8c-0.1,2.6-1.7,4.6-2.7,6.9c-4.1,8.9-9.5,17.2-12.6,26.6c-1.3,2-3.1,1.2-4.2,0.2
		c-4.6-4.2-9.4-8.2-13.6-12.8C123.7,278.7,123.9,278.1,124.3,277.5z"
      />
      <path
        class="st1448"
        d="M156.6,258.4c-0.1-0.7-0.1-1.4-0.1-2c2.2-4.4,4.2-8.9,6.5-13.2c0.8-1.5,0.3-2.4-0.5-3.6
		c-0.8-1.3-1.5-2.6,0.2-3.8c0.7-0.4,1.1,0,1.5,0.5c0.6,1,1.1,2,2.2,2.6c2.7,2.2,7.3,2.2,7.9,6.8c0,1.9,2.2,1.3,3.2,2.3
		c-4.2,3.3-4.7-3.2-7.7-2.4c-1.7,1.3-3.6-1.8-5.3,0.2c-0.3,0.4-0.6,0.9-0.8,1.4c-0.7,2-1.8,3.8-2.5,5.8c-0.4,1.1-0.8,2.2-1.4,3.3
		c-0.4,0.7-0.8,1.4-1.4,2C157.8,258.6,157.2,258.7,156.6,258.4z"
      />
      <path
        class="st1449"
        d="M171.5,261.5c2.3-1.5,2.4-4.4,4.1-6.3c0,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3
		c-1.3,3.7-2.7,7.4-4.1,11.2c-0.4,1-1.1,2.5-2.1,1.7c-3.6-3.1-3.7,1.3-5.4,2.3c-0.1-0.1-0.2-0.2-0.3-0.3c-1.1-2.4-0.5-4.4,1.7-5.9
		c2.1,0,3.6-1,4.3-3C170.8,261.3,171.1,261.3,171.5,261.5z"
      />
      <path
        class="st1450"
        d="M163.7,236.9c-0.3-0.4-0.7-0.7-1-1.1c5.1-4.4,10.7-7.9,16.2-11.8c1.6-1.1,3.5-1.7,5,0.3
		c-0.2,0.3-0.5,0.6-0.9,0.7c-2,0.8-3.3,2.7-5.3,3.4c-3.7,1.7-6.7,4.5-10.2,6.6C166.3,235.8,165.3,237.1,163.7,236.9z"
      />
      <path
        class="st1451"
        d="M183,224.5c0.3,0,0.6,0,0.9-0.1c2.9,2.2,1.8,5.3,2,8.1c0.1,2.2,0.1,4.4,0.1,6.6c-0.3,0.4-0.7,0.8-1,1.1
		c-0.2-0.2-0.4-0.3-0.5-0.5c-1.6-2.8-1.3-5.9-2.2-8.9C182,228.7,181.5,226.5,183,224.5z"
      />
      <path
        class="st1452"
        d="M85.4,320.1c-0.7,2-2,2.2-3.3,0.8c-1.4-1.5-4.2-2.4-2.8-5.3c1.6-3.3,3.4-6.5,5-9.8c2-4.1,4-8.2,6-12.3
		c2.4-3.6,5.7-2.9,9-1.7c0.3,0,0.5,0,0.8,0C93.7,300.4,89.2,310.1,85.4,320.1z"
      />
      <path
        class="st1453"
        d="M104.6,289.9c2-4.7,4-9.5,6.8-13.8c3-4.6,4.4-4.7,8.6-1.2c0.5,1.6-1.3,1.7-1.7,2.7
		c-1.6,2.6-2.2,5.8-4.4,8.1C110.9,287.5,108.8,290.9,104.6,289.9z"
      />
      <path
        class="st1454"
        d="M104.6,289.9c2.8-1.4,6.1-1.9,7.9-4.8c0.3-0.5,1,0.1,1.4,0.6c-2.3,5.5-4.7,10.9-7,16.4
		c-1.5-3.8-7-5.7-3.9-10.4C103.5,291.1,104,290.5,104.6,289.9z"
      />
      <path
        class="st1455"
        d="M118.2,277.6c-0.2-1.4,1-1.9,1.7-2.7c1.3,0.7,2.9,1,3.6,2.5c0.4,4.7,3.6,7.9,6.2,11.4
		c1.1,1.5,2.4,2.7,0.9,4.6c-3.9-2.2-6.2-6.3-9.8-8.8C118.3,282.9,120.6,279.4,118.2,277.6z"
      />
      <path
        class="st1456"
        d="M156.6,258.4c0.5-0.2,1.1-0.4,1.6-0.5c5.8,0.3,7.1,2.1,4.5,6.9c-1.4,2.6-1.9,5.6-4.4,7.5
		c-3.9,7.1-7.7,14.2-11.6,21.2c-2.3,1.1-3.7-0.5-5.2-1.8c-0.9-2.2,0.4-4,1.3-5.8C147.4,276.8,152,267.6,156.6,258.4z"
      />
      <path
        class="st1457"
        d="M141.5,291.7c2.1-0.6,3.2,1.9,5.2,1.8c-0.3,1.1-0.7,2.3-1,3.5c-2.6,5.6-4.1,5.9-8.9,1.8
		c-0.8-0.7-1.8-1-2.6-1.7c-1.2-1.2-2.3-2.4-3.5-3.6c-0.4-4.2-4.2-6.3-6-9.7c-1.1-2.1-2.6-3.9-1.1-6.3c0.2,0,0.5,0.1,0.8,0.1
		c4.1,5.2,9.6,8.9,14,13.7C139,292,140.3,292.7,141.5,291.7z"
      />
      <path
        class="st1458"
        d="M158.3,272.2c0.8-2.1,1.1-4.7,2.4-6.3c3.6-4.3,2.1-6.6-2.6-8c0.1-0.8,0.4-1.4,1.2-1.7
		c1.5-1.2,2.8-0.4,4.2,0.2c2.4,2.5,4.8-0.2,7.1-0.2c0.3,1.8,0.6,3.5,0.8,5.2c-0.3,0-0.5,0.1-0.8,0.1c-1.4,1-2.5,2.2-3.6,3.5
		c-2.7,0.9-1.6,3.6-2.6,5.4C162.4,271.2,161.3,274.8,158.3,272.2z"
      />
      <path
        class="st1459"
        d="M134.1,297c1.4-0.1,2.7,0.2,3.6,1.4c2.8,3.7,5,3,6.8-0.9c0.1-0.3,0.8-0.4,1.2-0.5c0.6,5.1-0.5,6.6-5.3,7.2
		C138.3,301.8,136.2,299.4,134.1,297z"
      />
      <path
        class="st1460"
        d="M99.3,291.8c-3.2-0.4-6.2-0.2-9,1.7C92.5,288.1,93,288,99.3,291.8z"
      />
      <path
        class="st1461"
        d="M183,224.5c0.1,2.3,0.2,4.7,0.3,7c0.4,1.1,0.3,2.2,0.1,3.3c-3.2,2.2-3.7,6.1-5.6,9.1
		c-0.3,2.4-2.2,1.6-3.7,1.8c-1.9-3.6-6.2-3.8-8.8-6.3c-0.2-0.4-0.3-0.9,0.1-1.2c4.6-2.9,8-7.2,12.4-10.4
		C179.8,227.1,180.3,224,183,224.5z"
      />
      <path
        class="st1462"
        d="M177.9,243.9c0.4-3.8,0.5-7.7,5.2-9c0.6,0.4,0.5,1.1,0.5,1.7c-0.2,0.6-0.7,0.9-1,1.4
		c-0.1,0.2-0.6,0.8,0.4,0.6c0.5-0.1-0.2-0.1,0.4-0.3c1.4,0,0.9,1.4,1.7,2l0,0c0,1.9,0,3.8,0,5.7c0,1.7-0.1,3.2-2.5,3.2
		c-2,0-3.1-0.7-2.9-2.8C179.6,245.2,179.6,244,177.9,243.9z"
      />
      <path
        class="st1463"
        d="M163.6,245.5c2.1-4.1,4.1-0.1,6.1,0.1c1.9,2.8,4.4,5.5,1.1,8.9c-1.5-0.6-2.4-1.7-2.8-3.2
		C167.9,248.3,165.3,247.3,163.6,245.5z"
      />
      <path
        class="st1464"
        d="M168.9,250.9c0.6,1.2,1.3,2.4,1.9,3.7c0,0.6-0.1,1.1-0.1,1.7c-2.3,0.9-4.1,5.3-7.1,0.9
		C165.5,255.1,166.1,252.1,168.9,250.9z"
      />
      <path
        class="st1465"
        d="M168.9,250.9c-1.5,2.3-2,5.5-5.3,6.2c-1.4-0.3-2.8-0.6-4.2-0.9c-0.3-1.3,0.5-2.3,0.8-3.5
		c1.5-1.8,2.9-3.7,2.8-6.2c0.2-0.3,0.4-0.7,0.6-1C167,245.7,168.7,247.5,168.9,250.9z"
      />
      <path
        class="st1466"
        d="M177.8,227.8c-3.3,4.8-7.5,8.6-12.4,11.6c-1-0.6-2-1.1-1.7-2.5C168,233.3,172.4,229.7,177.8,227.8z"
      />
      <path
        class="st1467"
        d="M163,246.5c0.9,2.9-0.5,4.8-2.8,6.2C161.1,250.7,162.1,248.6,163,246.5z"
      />
      <path
        class="st1446"
        d="M167.1,265c-0.3-2.7,0.9-3.9,3.6-3.5C170.7,264,170.7,266.4,167.1,265z"
      />
      <path
        class="st1446"
        d="M185,240.3c-0.6-0.6-1.1-1.2-1.7-1.8c-0.6-0.5-0.6-1.1-0.2-1.7c0-0.6,0-1.2,0-1.9c0.1-1.1,0.2-2.3,0.3-3.4
		C183.9,234.4,184.4,237.3,185,240.3z"
      />
      <path
        class="st1461"
        d="M183.1,236.8c0.1,0.6,0.1,1.1,0.2,1.7c-0.4,1,0.3,2.3-1.5,3.7C181.7,239.8,180.1,238,183.1,236.8z"
      />
    </g>
    <g id="apple">
      <path
        class="st1468"
        d="M136.4,701.1c-0.3,0.5-0.7,1-1,1.6c-3.1,1.3-5,3.9-7.1,6.2c-5.2,5.7-12,8.6-18.8,11.5
		c-2.1,0.9-4.3,1.2-6.2-0.7c-1.2-2.6-0.4-4.8,1.6-6.4c6.4-5.3,9.1-12.2,8.8-20.3c0.8-4.1,1.6-8.2,4.2-11.5c4.7-3.7,10-5.9,15.6-7.6
		c5.6,0.8,5.1,5.2,4.9,8.9c-0.1,3.7-0.9,7.3,0.6,10.9C139.3,696.5,136.9,698.5,136.4,701.1z"
      />
      <path
        class="st1469"
        d="M103.8,719.7c9.6-0.9,17.1-5.8,24.1-12c2.2-1.9,3.3-6,7.6-5c-10.5,13.7-23,24.1-41.5,23.9
		c-20.4-0.2-35.1-10.1-45.2-27.2c-3.6-6.1-6.4-12.6-6.7-19.9c0.7-0.8,0.1-2.2,1.5-2.6c0.6,0.1,1,0.3,1.3,0.8
		c2.9,6.2,3.4,13.2,7,19.2C57,705.4,63.6,711.7,73,715c0.9,0.2,1.7,0.4,2.5,0.8c7,3.2,14.3,4.1,21.9,3.1
		C99.6,718.6,101.8,718.1,103.8,719.7z"
      />
      <path
        class="st1470"
        d="M68.8,632.3c0.2-0.7,0.5-1.7-0.5-1.8c-11.9-1.4-19.6-9.6-27.8-17.1c0.2-1,0.4-2,1.4-2.6
		c2.3,0.2,3.3,2.3,5,3.3c5,5.4,11.2,8.7,18.2,10.8c4.4,1.8,8.9,1.9,13.4,0.1c1.9-0.8,3.9-1,5.9-0.2c0.8,0.5,1.4,2,1.5,2
		c2.1,0-3.1,1.9,0.3,1.2c1.7-0.3,3.2-0.4,4.4,1.2c0,0.7-0.2,1.2-0.8,1.5c-4,1.6-8.2,0.5-12.3,0.9C74.5,630.8,71.8,634.2,68.8,632.3z
		"
      />
      <path
        class="st1471"
        d="M136.4,701.1c-0.6-2.8,1.4-4.8,2-7.2c-1.1-2.2-0.2-4.3,0.2-6.5c1.4-6.9,2.8-13.8,1.4-20.9
		c-0.5-3.8-1.7-7.5-2.6-11.2c0.4-2-2.2-4.7,1.2-6.1C148.1,663.2,147.1,686.6,136.4,701.1z"
      />
      <path
        class="st1472"
        d="M83.4,612.3c3.7,2.9,4.7,7.9,8.4,11.1c1.4-1.5,1.6-3.3,2-5c0.7-2.5,1.8-4.5,5.1-3.4c3.2,1,4.6,3,3.6,6.3
		c-0.1,0.3-0.3,0.7-0.4,1c-2.6,6.3-2.6,6.3,3.9,7c0,1.5-1.2,1.7-2.3,2c-1.1,0.2-2.2,0.2-3.3,0c-5.7-1.8-2.7-5.7-2.2-9.9
		c-2,2.1-3,4.3-4.8,5.7c-0.6,0.5-1.2,0.9-2,1.2c-4-1.3-4.2-5.9-7.3-8c-0.8-0.6-1.5-1.2-2.2-2c-0.3-0.4-0.6-0.8-0.8-1.2
		c-0.6-1.3-1.8-2.4-1.2-4C81,612.2,82.1,611.7,83.4,612.3z"
      />
      <path
        class="st1473"
        d="M44,677.3c-0.3,0-0.7,0-1,0c-1.4-3.6,0.3-7.5-1-11.2c2.2-15.2,9.5-26.6,24.1-32.7c0.9-0.4,1.6-0.1,2,0.7
		c0.2,3.2-2.7,3.7-4.5,4.9c-5.9,4-11,8.6-13.8,15.3c-0.5,1-1,2-1.5,3c-1.4,5.3-3.1,10.5-2.7,16.1C45.7,674.8,45.3,676.3,44,677.3z"
      />
      <path
        class="st1474"
        d="M41.8,611.2c0,1-0.2,1.9-1.3,2.3c-1.3-0.9-3.5-1-3.7-2.9c-0.2-2.3,2.2-2.8,3.8-3.5
		c13.1-6.3,26.1-6.7,38.6,1.7c-0.1,2.7-2,1.5-3.1,1.2c-2.1-0.6-4-1.8-6.3-1.9c-9.1-3.1-17.9-2.4-26.5,1.8
		C42.6,610.1,42,610.4,41.8,611.2z"
      />
      <path
        class="st1475"
        d="M138.6,649.1c-1.9,2,0.9,3.8,0.1,5.7c-0.4,0.4-0.9,0.4-1.4,0.2c-1.8-2-2.8-4.7-5.1-6.4
		c-2.4-3.1-6.3-4.9-7.5-9c0.2-0.5,0.5-0.9,1.1-1.2c1.2-0.3,2.2,0.4,3.3,0.6C133.3,641.5,136.3,645,138.6,649.1z"
      />
      <path
        class="st1476"
        d="M129.2,639.1c-1.3,0-2.7,0-4,0.1c-3.2,0.6-5.2-2.1-7.9-3.1c-1.9-0.7-3.6-1.8-5.7-1.8
		c-1.5-0.1-3-0.5-4.4-0.8c-1.2-0.3-2.4-0.8-3.4-1.7c-0.3-0.4-0.4-0.9-0.3-1.4c0.8-0.4,1.6-0.7,2.4-1.1
		C114.4,630.9,122.7,633,129.2,639.1z"
      />
      <path
        class="st1477"
        d="M69.8,606.8c3.5-1.4,5.8,3.6,9.4,1.9c1.9,0.6,3.3,1.8,4.2,3.6c-1.1,0.4-2.1,0.8-3.2,1.2
		c-3.8,1.4-5.6-2.2-8.4-3.4C70.7,609.3,69.6,608.4,69.8,606.8z"
      />
      <path
        class="st1478"
        d="M67.8,634.7c-0.6-0.4-1.1-0.9-1.7-1.3c0.6-1,1.6-1.1,2.7-1c2.8-0.1,4.9-3.2,8.1-2.1c0.6,0.8,0.5,1.7,0,2.5
		C73.9,634,71.4,636.7,67.8,634.7z"
      />
      <path
        class="st1479"
        d="M42,666.1c2,3.6,0.4,7.5,1,11.2c-0.3,0.7-0.7,1.5-1,2.2C41.4,675,41.2,670.5,42,666.1z"
      />
      <path
        class="st1480"
        d="M47.5,655.9c0.5-0.7,0.9-1.5,1.4-2.2c2.9,0.8,2.1,3.1,2,5c-0.2,2.3-0.2,4.5,0.5,6.6c1.7,7.5,7,12.6,11.9,18
		c3.6,3.9,7.3,4,11.8,0.9c3.2-2.2,6.2-1.3,9.6-0.6c6.9,1.5,13.9,2.9,20.7-0.6c4.4-0.7,8.5-4.4,13.2-1.2c-1.1,3.7-2.3,7.4-3.4,11.2
		c-1.3,3.8-2,7.7-3.8,11.5c-2.7,5.9-7.8,8.2-12.7,11.2c-2.1,1.3-4.3,0.8-6.5,0.1c-9.1-2.9-17.3-7.9-26.4-10.7
		c-1.2-0.4-1.8-1.2-1.9-2.5c-0.8-8.1-3.8-15.6-10-20.8C45.3,674.3,45.1,665.6,47.5,655.9z"
      />
      <path
        class="st1481"
        d="M47.5,655.9c0,1.7,0.3,3.4,0,5c-2.1,9.8,3.5,16.2,9.9,22c2.1,1.9,3.4,3.7,3.9,6.5c0.4,2.5,1.3,5,3.5,6.8
		c0.7,0.5,1.8,1.8,1.6,2.2c-3.8,6.8,2.8,4.5,5.1,6.4c5.4,4.6,12.2,5.4,18.1,8.4c6.5,3.3,15.2,0.1,18.6-6.4c2.4-4.6,4.6-9.2,6.9-13.8
		c0.2,8.6-1.8,16.1-9.4,21.3c-2,1.3-1.9,3.5-1.8,5.5c-7.1,1.1-14.2,1.9-21.4,0.3c-2.9-0.7-5.7-1.4-7.9-3.4c-0.9-1.1,1.3-2.2,0-3.3
		c-1.8,0.4-0.9,2.5-2.2,3.2c-14-5.7-22.7-15.9-26-30.7c-0.7-2.9-2.4-5.4-2.3-8.5C43.7,669.9,44.3,662.7,47.5,655.9z"
      />
      <path
        class="st1482"
        d="M118.5,681.8c-4.8-0.9-8.8,2.6-13.5,2.2c-6.6,2.1-13.2,1.2-19.8,0.5c-3.5-2.5-7.3-3.8-11.7-3.7
		c-0.9-0.1-1.8-0.4-2.5-0.9c-2.7-3-2.3-6-0.3-9.1c4-1.2,6,2.5,9.1,3.6c9.2,2.8,18.7,3.2,28.2,2.7c2.2-0.1,3.2-1.8,4.3-3.2
		c1.5-1.3,3.4-1.5,5.2-2c2.8-0.3,5-1.8,6.8-3.9c0.7-0.7,1.4-1.4,2.1-2.1c0.3-0.4,0.7-0.7,1-1c1.1-1,2-2.1,2.8-3.3
		c1.9-2.9,4.5-5,7.4-6.8c0.4,0,0.7,0.1,1.1,0.1c0.9,3.7,2.6,7.2,2.4,11.1c1.3,3.9,0.5,6.6-4,7.4c-1.4,0.3-2.8,0.7-4,1.6
		C128.2,677.2,123.3,679.5,118.5,681.8z"
      />
      <path
        class="st1483"
        d="M133,674.9c1.7-1.7,3-3.7,6-3.9c3.6-0.2,1-3.5,2-5.2c2.1,7.5,0.7,14.8-1,22.2c-0.5,2-1.1,3.9-1.6,5.8
		c-3.5-3.2-1.6-7.2-1.5-10.9C137,679.7,138.3,675.7,133,674.9z"
      />
      <path
        class="st1480"
        d="M72.3,716.5c0.7-1.8-2.4-4.6,1.5-5.4c1.7-0.3,2.9,0.9,3.5,2.3c1.1,2.6-2.5,1.6-2.8,3.2
		C73.7,716.5,73,716.5,72.3,716.5z"
      />
      <path
        class="st1484"
        d="M41.8,611.2c-0.3-0.4-0.5-0.9-0.8-1.3c3.5-0.9,6.8-3.1,10.7-2.6c1,2.2,2.2,3.4,4.9,4
		c7.3,1.6,13.8,5.5,20.8,7.8c2.3,0.7,3.8,0.7,5.1-1.3c0.7,0.5,1.4,1,2.1,1.5c0.5,2.2,3.3,4.4,0,6.6c-4.8-1-8.7,3.2-13.6,2.2
		c-2.4-0.5-4.8-0.3-6.8-2.1c-6.2-3.6-13-6.1-18-11.4C43.9,614.7,43.5,612,41.8,611.2z"
      />
      <path
        class="st1485"
        d="M91.2,628.2c0.5-0.5,1-1,1.5-1.4c3.9,1.2,3.5,4.9,4.5,7.7c0.2,2,0.2,4-0.1,5.9c-0.1,0.5-0.2,1.1-0.3,1.6
		c-0.5,1.4-0.3,2.9-0.6,4.3c-0.8,4.3,0.6,7.3,4.9,8.9c0.5,1.2,0.3,2.4-0.4,3.5c-7.7,4.5-12.6,1.9-13-6.6c-0.1-1.1,0-2.2-0.1-3.3
		c-0.2-1.6,0.1-3.1,0.1-4.7c0.1-2.5-0.1-5.1-0.4-7.6c0-1.9,1.3-3.4,2-5.1c0.3-0.4,0.6-0.8,1-1.2c0-0.4,0-0.9,0-1.3
		C90.4,628.6,90.8,628.3,91.2,628.2z"
      />
      <path
        class="st1486"
        d="M46.3,614.5c6.2,3.5,12.7,6.6,18,11.4C57.2,623.9,50.5,621.2,46.3,614.5z"
      />
      <path
        class="st1487"
        d="M91.2,628.2c-0.3,0.3-0.6,0.6-0.9,0.9c-0.9,0-1.8-0.1-2.7,0c-2.8,0.5-4.1-0.4-2.9-3.3c0-2.2,0-4.4,0-6.6
		C88.1,621.3,88.9,625.3,91.2,628.2z"
      />
      <path
        class="st1488"
        d="M90.2,630.4c0,0.2-0.1,0.5-0.2,0.7c-1.6,2.1-4,1.7-6.1,2c-2.5,0.5-5,1.5-7.2-0.8c0-0.7,0-1.3,0.1-2
		C81.3,630.3,85.8,630.3,90.2,630.4z"
      />
      <path
        class="st1489"
        d="M82.5,617.7c-0.1,1.6-0.2,3.3-0.3,4.8c-9.8-3.2-18.5-9-28.8-10.2c-4.3-0.5-2.2-2.4-1.5-4.2
		c6-3.8,11.5-1.5,16.8,1.5c4.9,1.6,9.2,4.5,13.5,7.3C82.4,617.1,82.5,617.4,82.5,617.7z"
      />
      <path
        class="st1490"
        d="M96.1,634.8c-1-2.8-1.3-5.8-3.4-8.1c0.7-2.1,3-3.2,3.4-5.6c0.3-1.6,1.7-2.7,3.3-1.8
		c1.6,0.9,1.1,2.6,0.3,3.9c-1.6,2.6-3.5,5.2,0.7,7.2C99.7,632.6,98.3,634.1,96.1,634.8z"
      />
      <path
        class="st1491"
        d="M96.1,634.8c0.9-2,1.8-4,4.4-4.4c1,0,2.1,0,3.1-0.1c0.2,0.2,0.3,0.5,0.4,0.8c0.8,1.2,1.8,2.5-0.1,3.6
		c-3.7,2-3.7,4.3-0.8,7.1c0.7,0.7,0.2,1.8-0.7,2.4c-3,0.9-5.2,0.3-6.3-2.9C96,639.2,96.1,637,96.1,634.8z"
      />
      <path
        class="st1492"
        d="M82.3,616.8c-5.2-0.2-8.3-5.2-13.3-5.6c-0.4-2.9,1.9-1.8,3.1-2.2c2.7,1.5,5.4,3,8.1,4.4
		C80.9,614.6,81.6,615.7,82.3,616.8z"
      />
      <path
        class="st1493"
        d="M76.8,632.2c2.4,0.6,4.7,0.2,6.9-0.8c1.9,1,2.9,2.9,4.5,4.2c2,2.5,0.7,5.2,0.7,7.9
		c-1.9,4.9-6.4,5.7-10.7,6.8c-0.3,0.4-0.6,0.8-0.8,1.2c-1.4,2.8,1.1,5.3,0.7,8c-2.6,3-4.6,1.1-6.5-0.9s-2.5-4.7-2.6-7.2
		c-0.2-4.3-2.2-4.6-5.4-3.1c-2.4,1.1-4.5,2.7-6,4.9c-1.4,2.1-1.2,4.2,0.5,6.1c1.6,1.8,3.2,2.3,5.3,0.8c1.6-1.1,3.5-2.3,3.9,1.1
		c-2,4.4,3.3,5.8,3.8,9.2c-0.3,3-1.5,6,0,9c-7.4-0.2-13-4.1-18-9.2c-1.3-1.3-1.7-3.3-3.4-4.3c-3-3.9,1.7-8.4-1-12.3
		c3.1-9.5,11.4-13.9,19-19C71,634.8,73.4,631.7,76.8,632.2z"
      />
      <path
        class="st1494"
        d="M72.1,609.1c-1.1,0.7-2.1,1.4-3.1,2.2c-5.2-3.7-10.9-4.2-17-3.2c-3.8,0-7.1,2.3-11,1.8
		c9.1-5.9,18.7-7,28.8-3C70.5,607.6,71.3,608.3,72.1,609.1z"
      />
      <path
        class="st1495"
        d="M66.6,661.5c-0.1-1.8-1-1.8-2-0.7c-2.1,2.3-3.9,3.7-6.7,0.6c-2.8-3-4.1-5.8-1.4-9.3
		c2.1-2.7,4.8-4.8,8.1-5.7c2.9-0.8,6.6-2.8,5.7,3.8c-0.8,6.4,2.9,9.8,7.6,8.9c2.5-1.6,4.1,0.9,6.1,1.3c8,2.9,15.7,1.6,23.3-1.4
		c3-1.2,5.8-3.1,5.8-6.7c0.1-3.4-3.1-4.3-5.4-5.7c-1.7-1-4-1.2-5-3.2c-4.5-4-6-7.5,1.2-10.2c0.8-0.3-1.4-1.5,0-2.2
		c1.4-0.2,2.3,0.7,3.4,1.3c1.6-0.2,1.4,6.9,4.3,1c5.5-0.5,9.4,3,13.6,5.6c0.1,0.4,0.1,0.8,0.2,1.2c2.5,2.8,1.8,8.8,8,7.8
		c2.2,1.7,3.9,3.7,4.2,6.7c-0.8,3.9-5.3,4.8-6.9,8c-0.8-0.1-1.4-0.4-2.1-0.8c-1.5-1-3.2-1.6-4.6-0.1c-1.2,1.2-0.7,2.8,0,4.2
		c0.4,0.8,0.9,1.6,1.2,2.5c-1.9,2.2-3.5,4.9-7.1,4.2c-2.1-0.8-4-0.8-5.3,1.3c0,3.7-1.6,4.5-5.5,4.7c-8,0.5-15.7-0.8-23.5-1.4
		c-1.9-0.1-3.7-0.2-4.8-2c0.1-0.2,0.2-0.5,0.2-0.7c0.8-0.9,2-1.5,2.5-2.7c-2.9-3.8-8.7-3.3-11.1-7.7
		C69.8,662.6,68.4,661.8,66.6,661.5z"
      />
      <path
        class="st1496"
        d="M133.4,648.1c-3.5,2.5-6,1.5-7.3-2.4c-0.6-1.7-0.5-3.6-0.7-5.4C128.5,642.5,131.1,645.2,133.4,648.1z"
      />
      <path
        class="st1497"
        d="M111.6,633.5c0,1.2,2.4,1.8,1.2,3.2c-0.8,0.9-1.9,1.4-3.5,0.8c-2.6-1.1-2.4-2.9-1.9-5
		C108.9,632.2,110.3,632.7,111.6,633.5z"
      />
      <path
        class="st1498"
        d="M49.8,666c6.2,5.9,13.2,10.7,21.4,13.5c0.7,0,1.5,0,2.2,0c3.7,1.4,7.6,1.9,11.3,3.3
		c6.6,2.5,13.5,0.4,20.3,1.1c-1.1,2-2.7,3-5.1,2.5c-5.9-1.2-12.3,1.1-18.1-2.3c-1.4-0.9-4-0.8-5.8,0.7c-5.4,4.6-10.2,4.2-14.7-1.3
		C56.8,678.1,51.7,673.1,49.8,666z"
      />
      <path
        class="st1499"
        d="M66.6,661.5c1.1-0.7,2.2-2.6,3.5-0.3c2.5,4.6,6.7,6.6,11.5,8c1.4,0.4,5,1.4,0.9,3.7
		c-1.9-0.5-2.5,1.2-3.5,2.1l-0.1,0.3c-2.6-1.6-5.2-3.2-7.8-4.7C68.8,667.9,62.6,667.3,66.6,661.5z"
      />
      <path
        class="st1500"
        d="M125.2,668.4c-3-1-3.8-3.4-3.6-6.2c0.1-1.6,1-2.8,2.8-3c3-0.5,5,0.8,6.3,3.5c-0.9,1.3-1.3,3.1-3.2,3.4
		c-0.5-0.5-1-1.1-1.5-1.6c0.4,0.6,1,1.1,1.5,1.6C127.2,667.3,126.3,668,125.2,668.4z"
      />
      <path
        class="st1493"
        d="M84.7,682.9c-3.8-1.1-8.4,0.6-11.3-3.3C77.7,678.8,81.3,680.4,84.7,682.9z"
      />
      <path
        class="st1501"
        d="M112.8,673.9c-1.1-2,0-3.1,1.8-3.4c1.6-0.3,3.5-0.6,3.5,2.1C116.8,674.6,114.6,673.5,112.8,673.9z"
      />
      <path
        class="st1502"
        d="M102.7,643.5c2.8,0.8,5.7,1.6,8.2,3.3c5.6,3.8,5.7,9.2-0.3,12.4c-8.6,4.6-17.8,6.1-27.2,2.4
		c0.4-0.5,0.9-0.9,1.4-1.1c4.4-1.1,8.9-0.2,13.3,0.1c3.1,0.3,1.3-1.9,2.2-2.5c0-1.1,0-2.2,0-3.3c0.2-3,1.4-5.9-3.8-6.7
		c-2.5-0.4-1.1-4.4-0.8-6.8h0.1C97.9,642.9,100,644.1,102.7,643.5z"
      />
      <path
        class="st1503"
        d="M100.3,658.2c0.9,2,2,3.8-1.9,3.5c-4.6-0.3-9.1-0.2-13.7-0.2c-3.6-3-10.4-3.8-8-11.1l0.1-0.1
		c1.2-1.2,3.2-1.6,4.1-0.8c3.6,3.2,5.9,1.1,8.3-1.4c0,0.9,0.1,1.8,0.1,2.7C89.2,659.8,91.4,661.2,100.3,658.2z"
      />
      <path
        class="st1504"
        d="M95.8,641.3c0.9,2.2-2.2,6.1,4.2,5.8c3.5-0.2,3.3,4.8,0.4,7.7c-4.5,1.4-6.5,0-5.7-4.9
		C95.2,647.1,93.9,644,95.8,641.3z"
      />
      <path
        class="st1505"
        d="M89.1,648.1c-0.3,0.6-0.8,1.2-1,1.9c-0.5,1.5,0.7,3.7-1.2,4.5c-2,0.8-2.9-1.4-3.9-2.5
		c-1.8-2.1-4.1-1.4-6.2-1.7c0.3-0.6,0.8-1.8,1.1-1.7c4.8,0.5,7.8-2.7,11.2-5C89,645,89.1,646.5,89.1,648.1z"
      />
      <path
        class="st1506"
        d="M88.3,635.7c-3.7,0.9-3.6-2.2-4.5-4.2c2.1-0.1,4.2-0.3,6.4-0.4C90.6,633,90,634.6,88.3,635.7z"
      />
      <path
        class="st1505"
        d="M76.7,650.4c0.1,3.2,0.1,6.2,4.4,7.1c1.4,0.3,2.4,2.6,3.6,4c-0.5,0.1-0.9,0.1-1.4,0.2
		c-1.8-0.8-3.7-1.6-5.5-2.4C76,656.5,74,653.7,76.7,650.4z"
      />
      <path
        class="st1507"
        d="M79.1,674.9c-0.1-1.7-0.1-3.5,2-4c1.3-0.3,1.2,1,1.5,1.9C81.8,674.2,80.8,675.2,79.1,674.9z"
      />
      <path
        class="st1508"
        d="M127.5,666.1c-1.1-0.3-2.3-0.3-3.1-0.9c-0.8-0.6-1.5-1.8-0.9-3c0.2-0.4,0.9-0.6,1.4-0.7
		c1.2-0.1,2.2,0.4,2.4,1.7C127.5,664.3,127.5,665.2,127.5,666.1L127.5,666.1z"
      />
    </g>
    <g id="light_bulb">
      <path
        class="st1509"
        d="M242.3,240.1c1.4-1.5,2.8-3,4.2-4.6C249.2,240.8,245.4,240.1,242.3,240.1z"
      />
      <path
        class="st1510"
        d="M245.5,163.7c-0.8,0.3-1.6,0.7-2.3,1c-3.8,2.5-8.2-0.1-12.2,1.5c-1.7,0.6-3.6,0.4-5.2,1.5
		c-3.6,1.5-6.9,3.2-10.2,5.2c-1.5,0.9-3,2.3-5,0.9l-0.2-0.1C219,163.6,233.7,159.4,245.5,163.7z"
      />
      <path
        class="st1511"
        d="M260,182.8c-2-2.1-4.7-3.2-6.1-6.1c-1.2-2.5-2.7-1.3-4.1,0.5c-1.6,2.1-1.8,6.8-6.7,3.6
		c-1.3-0.9-1.7,0.9-1.8,2.4c-0.1,1.7-0.1,3.9-2.7,4.1c-3.9,0.6-7.5-1.1-11.2-1.7c-1.6-0.3-3.1-1.7-3.5-3.3c-1.3-5.1-2-10.3,1.1-15.2
		c3.8-3.6,8.7-2.3,13.1-2.8c1.6-0.2,3.3,0.3,5,0.5C250.9,168.8,257.6,173.9,260,182.8z"
      />
      <path
        class="st1512"
        d="M225.1,167.1c0.6,0.3,1.2,0.7,1.8,1c-3.7,2.7-1.8,6.5-2.1,10c-0.5,5.3,2.2,7.2,6.8,6.9
		c2.9-0.2,4.8,1.5,7.2,2.3c-2.2,3.5-5.4,2.7-8.3,1.4c-5.4-2.5-10.4-3.3-15.3,0.9c0-1.8,0-3.7,0-6.3c-3.5,2.9-6.1,2.9-7.5-1.3
		c0.2-3,3.8-4.6,3-8C215.7,172.2,219.4,167.6,225.1,167.1z"
      />
      <path
        class="st1513"
        d="M238.8,167.1c3.9-0.9,5.4,2.1,6.4,5.2c0.7,2.2-4.6,6.1-7.9,5.7c-3.4-0.4-6.6-2.3-6.3-5.8
		C231.3,168.3,234.6,166.8,238.8,167.1z"
      />
      <g>
        <path
          class="st1514"
          d="M192.6,193.1c1.1-10.7,6.1-19.8,13.4-27.2c6.7-6.8,14.6-12.3,25.2-12.3c7.8,0,15.6-1.1,22.5,3.5
			c11.1,7.7,22.1,15.7,21,31.4c-0.3,3.9,2.9,8-1.1,11.9c-2.7-3.2,0.7-7.8-2.4-10.8c-2.8-0.4-2.5-3-3.1-4.7
			c-4.5-13.5-17.5-24.3-34.9-23.1c-13.5,0.9-26.5,10.1-30.6,23.9c-1.6,5.3-2.6,10.8-1,16.5c0.6,2.4,1.9,5,0.3,7.5
			c-2.7-2.5-8.2-0.3-9.2-5.6C193.4,200.4,193.4,196.7,192.6,193.1z"
        />
        <path
          class="st1515"
          d="M250.2,232.2c1.3,8.3,0.7,16.1-4.2,23.5c-3.2,4.9-7.3,7.8-12.8,7.4c-5.2-0.3-8.6-3.4-10.8-8.9
			c-2.4-6-4.2-11.7-3.4-18.2c0.5-4.5-2.8-7.9-5.2-11.4c-0.3-0.5-0.4-0.9-0.1-1.5c5.8,0.4,6.8,5.5,8.9,9.4c1.5,2.8,2.6,5.7,5.6,7.3
			c0.5,0.2,0.9,0.4,1.4,0.7c3.6,1.8,7.1,1.8,10.7-0.2c0.6-0.4,1.3-0.7,2-1c3.1,0.1,4-1.5,3.4-4.4c0.2-1.5,0.8-2.6,2.2-3.3
			C248.7,231.4,249.5,231.5,250.2,232.2z"
        />
        <path
          class="st1516"
          d="M213.9,223c-0.1,0.5-0.1,1.1-0.2,1.6c-5.6-3.8-8.8-9.3-12-14.9c-4.6-14-2.9-27,7.3-38
			c9.7-10.5,22.2-13.6,35.8-10.4c13.8,3.3,21.8,12.9,25.4,26.3c0.2,0.7,0.5,1.3,0.8,2c1.2,13.5-3.4,24.7-13.7,33.7
			c-2.9,2.6-5.6,5.4-7.3,9c-0.9,0-1.7,0-2.5,0c-0.6-0.7-0.8-1.5-0.6-2.3c0.2-1.1,0.8-2.1,1.4-3c1.4-2,3.6-3.3,5.4-4.8
			c15.2-12.6,17.9-31.2,6.6-46.8c-3.9-5.4-9.2-8.6-15.5-10.5c-12.7-2.1-24.3-0.7-33.4,9.6c-11.2,13.5-11.2,29-0.2,42.7
			c0.7,0.9,1.4,1.8,1.9,2.7C213.8,220.8,214.3,221.8,213.9,223z"
        />
        <path
          class="st1517"
          d="M213.9,223c-0.4-0.7-0.7-1.4-1.1-2.1c0.2-0.8,0.6-1.3,1.4-1.5c1.7,0.1,3.2-0.8,4.9-0.9
			c1.3,0.7,1.5,2,1.5,3.2c1.1,5.1,3.3,9.8,6.1,14.2c0.9,1.5,2.9,2,3.2,3.9c-0.3,0.8-0.8,1.3-1.6,1.4c-3.9-0.6-5.4-3.6-6.4-6.7
			C220.4,229.8,217.7,226.1,213.9,223z"
        />
        <path
          class="st1518"
          d="M227.3,247.6c3.1-1,6.1,0.9,9.2,0.1c4.4-1.2,9.1-2,12.2-7.5c-1.1,8.2-7,10.8-19.1,9.8
			C228.8,249.1,226.5,249.9,227.3,247.6z"
        />
        <path
          class="st1519"
          d="M225.8,253.7c6.7-0.1,13.1,0.6,19.7-3.2C240,258.1,232.5,259.1,225.8,253.7z"
        />
        <path
          class="st1520"
          d="M228.4,241.2c0.5-0.5,1-0.9,1.6-1.4c0.7-0.7,1.6-1.2,2.5-1.1c7,0.9,10.1-3.4,12.4-8.9c2.1-1.6,2.1-1.6,3,0
			c0,0.8-0.1,1.6-0.1,2.4c-0.4,1.1-0.8,2.2-1.1,3.3c0,2.8-2.3,3.5-4.2,4.6c-0.4,0.3-0.8,0.6-1.2,1c-4.2,1.4-8.4,1.4-12.6,0.1
			C228.5,241.2,228.4,241.2,228.4,241.2z"
        />
        <path
          class="st1521"
          d="M227.3,247.6c0.8,0.8,1.6,1.6,2.4,2.3c-3.9,0.1-7.2-0.9-9.3-5.3C223.4,244.6,224.7,247.7,227.3,247.6z"
        />
        <path
          class="st1517"
          d="M228.5,241.2c4.2,0,8.4-0.1,12.6-0.1C236.9,243.6,232.7,244,228.5,241.2z"
        />
        <path
          class="st1522"
          d="M245.5,163.7c17.1,4.2,27,23.4,21.7,41c-3.1,10.3-10,17.1-18.3,23.1c-0.8-0.6-0.9-1.4-0.8-2.3
			c1.9-4.2,4.9-7.7,7.4-11.6c2.3-3.6,4.1-7.4,4.8-11.6c1.2-6.3,0.4-12.4-1.5-18.5c-0.4-4.9-3.9-8-7-11.4c-2.7-3-6.9-4.4-9-8
			C243.4,163.1,244.4,163,245.5,163.7z"
        />
        <path
          class="st1523"
          d="M213.9,219.5c-0.4,0.5-0.7,0.9-1.1,1.4c-14-14.6-15-33.8-2.5-47.1c0.2,0.2,0.4,0.4,0.6,0.6
			c1.2,3.5-2,5.5-2.8,8.4c-5.1,10.3-2.1,19.9,2.6,29.3C212,214.4,214.5,216.3,213.9,219.5z"
        />
        <path
          class="st1524"
          d="M248.8,225.6c0,0.7,0.1,1.5,0.1,2.2c-0.4,0.7-0.8,1.4-1.2,2c-0.7-1.1-1.4-0.2-2.1,0
			c-1.1,0.5-1.7-0.2-2.2-1c-1-2.6-0.6-5.3-0.6-8c0-1,1.1-2.4-1.2-2.5c-0.9,0-1.5-1.4-0.4-2.5c4.5-1.5,6.1,0.9,6.6,4.8
			c0.2,1.3-0.4,2.6,0.1,3.8C248.4,224.8,248.6,225.2,248.8,225.6z"
        />
        <path
          class="st1525"
          d="M213.9,219.5c-4.6-6.8-8.4-13.9-9.5-22.3c-0.7-5.6,0-10.7,3.1-15.4c2,0.3,3.6,1.5,6.1,1.3
			c4.1-0.4,2,4.1,3.1,6.2c1.7,4.8,5.5,7.9,9.3,10.8c2.6,2.1,5.4,4,7.8,6.3c3.1,2.9,4.1,6.2,0.8,9.7c-1.8,0.9-3.6,1.6-5.6,1
			c-0.6-1.2-1.5-2-2.9-1.3c-3.1-0.8-5.5,0.2-7.4,2.6C217.8,222.2,215.8,220.7,213.9,219.5z"
        />
        <path
          class="st1526"
          d="M244.2,228.3c0.5,0.5,0.9,1,1.4,1.5c-2.4,9.3-4.7,10.8-15.7,10c-0.9-1.5-3.3-0.9-3.7-2.8
			c-0.9-2.4-3.3-4.5-1.9-7.5c1.4-2.6,2.2-6.4,6.3-2.6c2.4,2.2,5,1.9,7.7,0.8C240.4,226.8,242.4,226.1,244.2,228.3z"
        />
        <path
          class="st1527"
          d="M225.1,229.7c-1.7,2.7,1.5,4.7,1,7.3c-3.5-4.6-6.2-9.4-6.7-15.3c0.6-0.4,0.9-1.2,1.8-1.1
			c0.4-0.2,1.4,1.9,1.4-0.3c0-2,0.9-2.4,2.7-1.8c0.4,0.4,0.7,0.9,0.9,1.5C227.2,223.3,226,226.5,225.1,229.7z"
        />
        <path
          class="st1528"
          d="M220.8,220.8c-0.4,0.3-0.9,0.6-1.4,0.9c-0.3-1.1-0.5-2.2-0.8-3.2c0.6-6.2,4.1-5.1,7.9-3.2
			c2,3.1,1.9,3.4-1.3,3.6l-0.4-0.1C221.6,215.6,222.2,220.1,220.8,220.8z"
        />
        <path
          class="st1529"
          d="M234.1,215.4c2.2-3.4,1.5-5.7-1.7-8.4c-6.2-5.3-14.2-9-17.4-17.4c3.9-4.8,5.9-5.3,12.6-3.3
			c3.3,1,6.6,3,10,0.4c1.9-1.3,1.5-4.5,2.7-5.1c4.1-2,7.6-5.4,11.6-6.6c2.9-0.9,5.2,4.1,7.4,7c0.2,0.3,0.3,0.7,0.5,0.9
			c2.4,6.6,3.6,13.4,1.2,20.3c-1.6-3.4-3.7-4-7.1-1.9c-3.2,1.9-6.4,3.9-9.9,5.2c-4.6,1.7-3.1,6.4-4.8,9.5c-0.5,0.8-1.1,1.3-2,1.5
			C235.9,217.4,234.2,217.6,234.1,215.4z"
        />
        <path
          class="st1530"
          d="M238.8,215.6c-0.5-6.1,1.6-10.2,8.1-11.9c2.9-0.7,5.5-3,8.3-4.4c5.7-2.9,5.8-2.8,6,3.8
			c0.4,5.3-3,9.1-5.5,13.1c-2.1,3.3-4.6,6.3-6.9,9.4c-0.4,0-0.8-0.1-1.1-0.1c-1.1-1.5-0.7-3.3-0.4-4.8c0.9-4.6-1-6.3-5.5-5.5
			C240.8,215.3,239.8,215.9,238.8,215.6z"
        />
        <path
          class="st1531"
          d="M238.8,215.6c0.8-0.4,1.5-0.7,2.2-1.1c0.6,0.7,0.6,1.5-0.1,2.2c-0.2,2.3,3.9,0.5,3.2,3.3
			c-1,1.2,0.3,4.1-2.8,3.7c-1.5-0.2-0.9-2.4-2.4-2.5c-0.5,1.8-0.7,3.9-3.2,3.7c-2.2-0.1-2.1-1.9-2.5-3.6c-0.6-2.2-2.1,0.6-3.1-0.1
			c-0.5-0.2-0.9-0.6-1-1.2c1.1-5.6,6-1.8,8.6-3.5C238,216.2,238.4,215.9,238.8,215.6z"
        />
        <path
          class="st1532"
          d="M241,223.2c2.3,0.2,1.7-2.5,3.2-3.2c0,2.8,0,5.6,0,8.4c-2-0.6-3.7-0.2-5.7,0.8c-2.9,1.4-6.4,2.2-8.7-1.6
			c-0.4-0.2-0.5-0.5-0.4-0.8s0.2-0.4,0.3-0.4c3.1-0.6,6.7,0.6,9.3-2.3C239.5,223.5,240.2,223.1,241,223.2z"
        />
        <path
          class="st1533"
          d="M240.9,216.6c0-0.7,0.1-1.4,0.1-2.2c2.1-1.8,4.1-1.4,6.1,0.1c2.1,1.5,1.5,3.4,1,5.5
			c-0.4,1.8-0.3,3.6-0.4,5.5c-2.5-0.7-1.1-2.8-1.1-4.1C246.6,217.4,245.1,215.6,240.9,216.6z"
        />
        <path
          class="st1534"
          d="M229.9,226.6c0,0.3-0.1,0.6-0.2,1c-3.7-3.7-2.9,1.9-4.6,2.2c0.1-3.6,0.1-7.3,0.2-10.9
			c2.8-0.4,1.3-2.2,1.3-3.6c1.6-1,2-0.2,2.2,1.2c1.2,1.3-0.5,3.2,0.8,4.5l0,0C230.7,222.8,231.2,224.6,229.9,226.6z"
        />
        <path
          class="st1535"
          d="M229.6,220.9c-1.6-1.3-1.4-2.9-0.8-4.5c1.8-0.4,3.5-0.7,5.3-1.1c0.9,1.3,2.3,0.9,3.6,1.1
			C235.3,218.6,230.2,215.7,229.6,220.9z"
        />
        <path
          class="st1536"
          d="M220.8,220.8c0.1-1.8-1.1-4.3,1.6-4.9c1.5-0.3,2.6,1,2.5,2.7c-3.5,1.4,1.3,4.8-2.1,6.3
			C220.7,224.7,222.4,221.8,220.8,220.8z"
        />
        <path
          class="st1537"
          d="M229.9,226.6c0.1-1.9-1.6-3.7-0.3-5.6c1.4-0.8,2.3-2.6,4.3-2.1c0.5,0.1,2.2-0.5,0.9,1.3
			c-1,1.4-1.5,3.5,0.9,3.8c2.5,0.3,1.9-2.1,2-3.7c0.1-0.8,0.3-1.4,1.1-1.7c1.2,1.9,1.4,4,1,6.2C236.9,227.7,233.7,228.8,229.9,226.6
			z"
        />
        <path
          class="st1538"
          d="M239.8,224.7c-0.8-2-1.3-4-1-6.2c3.6,0.2,1.6,3.1,2.2,4.6C240.6,223.7,240.2,224.2,239.8,224.7z"
        />
      </g>
    </g>
    <g id="ipad">
      <path
        class="st1539"
        d="M313.5,240.9c-3.2-2-5.7-4.7-6.8-8.4c-0.5-11.1-0.4-22.2-0.1-33.2c-0.1-24.1-0.2-48.3-0.3-72.4
		c0-1.8,0.3-3.6,0.2-5.4c-0.4-6.9,3.4-10.8,9.3-13.3c0.8-0.3,1.1,0.2,1.3,0.8c-1.1,3.2-5.4,3.5-6.4,6.8c-0.7,14.1-1.6,28.3-0.2,42.4
		c0.7,6.8-1.1,13.5-0.8,20.4c0.7,15,0,29.9-0.1,44.9c0,5.4,1,10.1,3.8,14.6c0.3,0.4,0.5,0.9,0.5,1.4
		C314,240,313.8,240.5,313.5,240.9z"
      />
      <path
        class="st1540"
        d="M342.8,93.3c7.1-4,14.1-8.2,21.4-11.9c6.5-3.3,12.9,0.6,13,7.8c0.1,4.7,0,9.4-0.1,14.1
		c-0.7,1.4-0.4,3-0.4,4.5c-0.1,24.4,0,48.9-0.1,73.3c-0.2,0.6-0.6,0.9-1.2,0.7c-1.5-1.6-1-3.6-1.2-5.5c-0.2-27.5-0.3-55.1,0-82.6
		c-0.1-2.5,0.4-5.2-0.9-7.6c-0.7-1-1.9-1-2.9-1.4c-0.5-0.2-0.9-0.6-1.1-1.1c-1.5-1.8-2.8-0.2-3.9,0.3c-5,2.2-9.6,5-14.3,7.7
		c-2.3,1.3-4.5,2.8-7.3,2.8C343.1,94.4,342.7,94.1,342.8,93.3z"
      />
      <path
        class="st1541"
        d="M313.5,240.9c0-0.3,0-0.6,0.1-0.9c0.9-0.7,1.9-0.9,3-1c3.9-0.3,7-2.6,10.3-4.4c6.5-3.4,12.9-7.1,19.5-10.4
		c2.2-0.9,4.4-1.6,6.3-3.1c5-2.6,9.8-5.6,14.7-8.3c3.4-1.9,5.2-5.1,7.5-8c0.8-0.1,1.1,0.4,1.3,1c-0.8,4.2-3.1,7.3-6.9,9.3
		c-16,8.6-32,17.1-48.1,25.7C318.7,242.2,316.1,243,313.5,240.9z"
      />
      <path
        class="st1542"
        d="M376.2,205.8c-0.3-0.3-0.5-0.6-0.8-0.9c-2.3-4.7-1-9.6-0.9-14.4c1.3-2.7-0.2-6,1.7-8.6
		c0-24.6,0.1-49.2,0.2-73.8c0-1.6-0.6-3.4,0.7-4.8c0.1,15,0.1,30,0.2,45c0.1,17.5-0.4,35,0.5,52.5
		C377.9,202.8,377,204.2,376.2,205.8z"
      />
      <path
        class="st1543"
        d="M342.8,93.3c0.3,0.3,0.5,0.5,0.8,0.8c0.2,1.7-1.2,2.1-2.2,2.6c-7,3.2-13.3,7.5-20,11.1
		c-1.4,0.8-2.8,2-4.6,1.3c-0.4-0.3-0.7-0.6-1.1-1C324.4,102.5,333.7,98,342.8,93.3z"
      />
      <path
        class="st1544"
        d="M306.6,199.2c0,11.1,0,22.2,0.1,33.2c-1.4-7.4-0.5-14.8-0.6-22.2C306,206.6,306.4,202.9,306.6,199.2z"
      />
      <path
        class="st1544"
        d="M313.5,239.2c-3.5-3-4.8-7.1-4.9-11.5c-0.2-10.8-0.4-21.6,0.3-32.4c0.3-4.8-0.3-9.7-0.4-14.5
		c-0.1-16.7-0.2-33.5,0-50.2c0.1-5.5,0-11,1.4-16.4c0.9,0.6,1.1,1.6,1.2,2.6c4.4,20.6,2,41.4,2.5,62.1c0.1,5.2,0.4,10.6-0.2,15.7
		c-0.5,4.8-2.4,9.6-1.2,14.3c1.4,5.6-1.4,11.2,0.8,16.6c0.5,1.2-0.2,2.4-0.7,3.6c-0.3,0.5,0.7,0.1,0.2-0.1c0,0,0-0.1,0.1-0.4
		c0.2-0.5,0.4-1,1-1.3C316.4,230.8,316.2,236.9,313.5,239.2z"
      />
      <path
        class="st1545"
        d="M313.5,239.2c1.4-3.9,1.2-7.8,0-11.7c-1.2-3.6-1.8-7.2-0.6-11c0.9-3-0.9-5.9-0.7-9
		c1.6-25.3,0.6-50.7,0.9-76c0-5.2-2-10.4-0.4-15.6c2.1-2.2,2.3,0.2,3.1,1.3c0.4,1,0.4,2,0.5,3s0.1,2.1,0,3.1c0,32.3,0,64.6,0,96.9
		c0.1,6.5-0.1,13.1,0.6,19.6c-1.1,0-2.3,0.1-3.4,0.1C313.5,239.7,313.5,239.5,313.5,239.2z"
      />
      <path
        class="st1546"
        d="M310.2,116.5c0-0.8-0.1-1.5-0.1-2.2c1.7-2.4,4.6-3.3,6.8-5.2c8.9-5,17.9-10,26.8-15
		c7.7-2.9,14.3-8,21.8-11.3c1.3-0.6,2.7-1.8,4.3-0.9c0.9,0.4,1.2,1.1-0.1,1.6c-2.7,3.3-6.7,4.9-10.4,6.7
		c-15.3,7.6-30.1,16.1-45.1,24.4C312.5,114.5,312.4,117.4,310.2,116.5z"
      />
      <path
        class="st1547"
        d="M317,239.8c-2.5-3.1-1.6-6.8-1.8-10.3c-0.1-2.9,0-5.8,0-8.7c0.5-0.4,0.9-0.8,1.4-1.1
		c7.6-4.2,15.4-8.1,23.2-11.9c3.7-0.3,6.9-1.6,9.5-4.4c5-4.5,11.2-6.9,17-10.1c2.2-1.2,5-2,5.5-5.1c1.3-2.4,1.4-4.8,1.1-7.4
		c-0.2-1.7-1.1-4.6,2.7-4.3c0,1.6,0,3.3,0,4.9c0.5,2.9,0.5,5.8,0,8.6c-0.1,4.9-0.1,9.8-0.2,14.7c-1.4,4.1-4.2,6.9-8,9
		c-4.9,2.6-9.6,5.3-14.4,8c-2.2-0.3-1.1-2-1.4-3.1c0.2-1.6,1-3.1,1-4.7c0.1-4-1.1-4.9-4.9-3.5c-0.8,0.3-1.5,0.7-2.2,1.1
		c-2.4,1.4-3.9,3.2-4.2,6.1c-0.4,3.2,0.6,5,4,4.9c1,0.5,1.9,1,1.6,2.4c-8.2,4.4-16.3,8.9-24.5,13.2C320.8,239.1,319,240.2,317,239.8
		z"
      />
      <path
        class="st1548"
        d="M315.3,122.6c0-0.9,0.1-1.9,0.1-2.8c0.1-2.6,1.4-4.4,3.6-5.7c3.4-2.1,6.9-4.2,10.8-5.3
		c2.7-0.3,5.3-0.8,6.9-3.4c0.5-0.7,1.2-0.9,2-0.7c1.8,0.8,0.8,3.5,2.9,4.1c2,0.2,3.4-1.2,4.9-2.1c1.4-0.9,2-2.3,2.3-3.7
		c0.6-3,2.2-3.1,4.4-1.7c1.1,0.8,2.3,0.9,3.3-0.1c0.8-0.7,0.4-1.5-0.2-2.1c-1-0.8-2.6-0.5-3.5-1.6c-0.2-1.3,0.7-2,1.6-2.6
		c5.6-3.3,11.5-6.1,17-9.6c0.7-0.4,1.6-0.3,2.4-0.3c2.8,2,1.4,4.9,1.6,7.5c-0.2,2.4-0.2,5-3.4,5.8c-0.4,0.4-0.9,0.8-1.4,1.1
		c-10.8,6.2-21.7,12.1-32.6,18c-5.9,3.5-12.2,6.1-17.8,10c-1,0.7-2,1.5-3.4,1.5c-0.4-0.1-0.8-0.3-1-0.6
		C315.3,126.3,316,124.4,315.3,122.6z"
      />
      <path
        class="st1549"
        d="M371.3,97.5c2-1.2,4-2.4,4-5c0.1,21.2,0.2,42.3,0.3,63.5c0,6.9-0.1,13.8-0.1,20.7c-3.1,0.9-2,3.7-1.5,5.1
		c1.1,3.2-0.2,5.4-2,7.7c-0.4-0.4-0.6-0.9-0.8-1.4c-0.5-2.9-0.2-5.8-0.2-8.7c0-24.5,0-49,0-73.5C371,103,371,100.2,371.3,97.5z"
      />
      <path
        class="st1550"
        d="M373.8,84.9c-6.6,4.7-14.5,7.5-21,12.4c-4.1,3.7-8.6,6.7-14.3,7.5c-0.5,0.3-1.1,0.6-1.6,0.9
		c-1.6,2.3-4.1,2.8-6.6,3.4c-3.9,1.6-7.5,3.7-11,5.9c-1.8,1.1-3.3,2.5-3.9,4.7c-0.1-0.8-0.2-1.6-0.3-2.3c-0.8-1.9,0-3,1.5-4.2
		c3.4-2.8,7.6-4.1,11.3-6.3c12.8-7.6,26.5-13.6,39.3-21.3c1.1-0.7,2.3-1,3.5-1.4C371.8,84.1,372.9,83.8,373.8,84.9z"
      />
      <path
        class="st1551"
        d="M370.7,84.2c-9.5,6.3-19.6,11.4-29.8,16.6c-8,4.1-15.7,8.8-23.6,13c-1.7,0.9-2.1,2-2.2,3.6
		c-0.5-0.8-1-1.6-1.4-2.4c-0.3-0.1-0.4-0.2-0.4-0.3c0.1-0.2,0.1-0.2,0.2-0.2c6.8-5.2,14.6-8.7,22-12.9c11.2-6.3,22.7-12.1,34.1-18.1
		C370,83.7,370.3,83.9,370.7,84.2z"
      />
      <path
        class="st1549"
        d="M375.6,190.2c0-2.9,0-5.8,0-8.6c0.2,0.1,0.4,0.2,0.6,0.3C375.8,184.6,376.6,187.5,375.6,190.2z"
      />
      <path
        class="st1552"
        d="M347,225c-0.3-0.8-0.9-1.3-1.7-1.7c0-1.8,1.8-1.6,2.7-2.3c1.3-0.5,2.1-2.1,3.9-1.8c1.7,0.3-0.1,2.1,1.2,2.6
		C351.4,223.7,349.1,224.2,347,225z"
      />
      <path
        class="st1553"
        d="M313.5,114.4c0,0.2,0.1,0.4,0.1,0.6c-0.1,32-0.1,64-0.2,96c0,5.5,0,11.1,0,16.6c0,2,0,4,0,6
		c-1.5-1.7-3.5-3.1-1.5-5.3c1.1-1.2,0.1-2.4-0.1-3.5c-0.8-2.9-1.8-5.8,0.5-8.6c1.5-1.8-1.5-2.3-1.3-3.8c0.6-5-1.4-10.2,1.4-15
		c0.5-0.9,0.2-2.2,0.2-3.3c0-20.8,0.1-41.5-0.1-62.3c0-5.2-3-10-2.4-15.3C311.3,115.8,312.4,115.1,313.5,114.4z"
      />
      <path
        class="st1554"
        d="M315.3,122.6c1.4,1.8,0.3,4,0.8,5.9c1.5,1.8,1,4,1,6c0,25.5,0,51.1,0,76.6c0,2.1-0.2,4.2,0.5,6.2
		c0.8,1.4,0,2.4-0.7,3.5c-0.6,0-1.2,0.1-1.7,0.1C315.2,188.1,315.3,155.3,315.3,122.6z"
      />
      <path
        class="st1555"
        d="M317,220.7c0.2-0.8,0.5-1.6,0.7-2.4c-0.2-0.4-0.2-0.8,0-1.1c6.1-3.9,12.8-6.7,19.1-10.2
		c9.9-5.4,19.9-10.5,29.8-15.9c1.8-0.8,3.3-2.6,5.6-1.9l-0.1,0.2c1.6,2.2-0.8,2.3-1.7,2.8c-6.5,3.7-13.1,7.2-19.6,10.9
		c-0.5,0.3-0.9,0.6-1.3,0.9c-2.6,2.3-5.7,3.6-9,4.5C332.6,212.6,324.8,216.7,317,220.7z"
      />
      <path
        class="st1556"
        d="M351.8,219.2c-1.1,0.8-2.1,1.6-3.2,2.4c-0.6-0.2-1-0.6-1-1.3c0.4-1.1,1.4-1.8,1.8-2.8
		c1.5-3.1,0.5-4.7-3.1-4.8c-0.8-0.4-1.1-1.1-1-1.9c2.4-1.1,5-4,7.3-1.6c2.3,2.2,1.4,5.7,0,8.6C352.4,218.3,352.1,218.7,351.8,219.2z
		"
      />
      <path
        class="st1557"
        d="M347.9,220.9c0.2,0.2,0.5,0.5,0.7,0.7c-1.1,0.6-2.2,1.1-3.4,1.7c-1.7-0.3-4.4,1.3-4.9-1.4
		c-0.6-3.1-0.8-6.6,1.8-9.3c0.9-0.9,1.9-1.5,3.1-1.8c0.7,0.3,0.9,0.9,0.9,1.6c0,1.4-1.1,2.1-1.8,3.1c-2.1,3.1-1.5,4.5,2.2,4.8
		C347.1,220.4,347.6,220.5,347.9,220.9z"
      />
      <path
        class="st1558"
        d="M340.4,208.5c2.9-1.6,5.7-3.6,9-4.5C347.4,207.5,344.3,208.7,340.4,208.5z"
      />
      <path
        class="st1559"
        d="M337.7,116.5c10.9-6.1,21.9-12.1,32.8-18.2c1.6,1.6,1.2,3.6,1.2,5.5c0,23.8,0,47.5,0,71.3
		c0,1.4-0.4,2.5-1.7,0.8c-1-1.2-0.8-2.9-0.6-4.4c0.4-3.7-0.5-6.9-2.4-10c-0.4-0.6-0.4-1.4-1.2-1.4c-1,0-1.4,0.8-1.8,1.5
		c-0.5,1-0.5,2.1-0.4,3.2c0.5,4.3-0.9,7.9-4.4,10.4c-1.3,0.8-2.7,1.6-4.2,0.4c-1.5-1.2-0.8-2.8-0.7-4.3c0.2-2.4,2.2-4.6,1-7.2
		c-0.5-0.8-1.2-1.5-1.1-2.6c0.3-0.4,0.7-0.8,1-1.2c-2.4,1.1-4.5,3.2-7.5,2.2c-0.9-1.5-0.2-2.6,0.8-3.6c0.2-0.2,0.6-0.3,0.7-0.6
		c-0.5,0.2-1,0.3-1.5-0.1c1.2-5,2.1-5.5,4.7-3c0.3,0.3-0.1,1.9,0.9,0.8c1.1-1.1,1.4-2.9,2-4.4c1.4-3.9,4.1-7.6,2.3-11.9
		c-1.1-2.5-2-5.1-2.8-7.7c-0.4-0.6-0.8-1.3-1.3-1.9c-2.9-2.4-5.9-4.2-9.9-2.5c-5.3,1.4-7,6-9.4,10.1c-0.1,0.4-0.3,0.7-0.5,1.1
		c-2.8,4.4-3.5,9.6-5.2,14.4c-0.7,1.5-0.6,3.1-0.1,4.6c0.3,1.7,0.3,3.5,0.3,5.2c-0.1,1.6,0.1,3.2-0.8,4.6c-2.8,1.2-3.4-1.2-4-2.8
		c-3.1-8-5.7-16.2-6.5-24.8c-0.3-3.5,0.5-6.9,1-10.3c0.4-2.5,2.5-3.7,4.9-4.5C329.1,123.9,333.5,120.4,337.7,116.5z"
      />
      <path
        class="st1560"
        d="M337.7,116.5c1.6,2.4-1.1,3.1-2,3.4c-4.6,1.9-7.5,7.1-13.2,7.1c-1,0-3,1.6-3,3.6c0.1,2.8-1.2,5.7-0.7,8.2
		c1.1,6.1,0.6,12.5,3.8,18.1c1.1,1.9,1.6,4.1,2.1,6.3c0.5,2,0.9,4.1,3.2,5.1c0.6,0.8,0.5,2.2,2.1,2.1c3-0.2,3,1.3,1.6,3.2
		c-2.6,3.5-2.9,7.3-1.6,11.3c-0.3,2.3,3.8,2.1,2.6,4.8c-0.4,1.1-1.1,1.9-1.4,3c0.1,2.6,0,5.1-1.9,7.3c1.4-0.1,2.2-1,3.3-1.4
		c1.2-0.6,2.4-1.1,3.5-1.9c0.6-0.5,1.3-0.9,2-1.2c4.6-1.8,8.7-4.7,13-7.2c1.3-0.8,2.5-1.9,4-2.1c4.3-0.5,4.9-3.3,4.5-6.8
		c-0.1-1.5-0.8-2.9-0.5-4.5c2.9-3.4,5.1-6.9,3.4-11.8c-0.4-1.2,0.7-2.6,1.6-3.6c0.7-0.8,1.3-2.1,2.6-1.6c0.9,0.3,0.9,1.6,1.5,2.4
		c3.3,4.4,2.4,9.6,2.6,14.5c0,0.6,0.5,1.2,0.8,1.8c1,3.4,0.2,6.8,0.4,10.2c0.1,1.3-0.8,1.9-1.7,2.6c-0.9,0.7-2.5,0.8-2.7,2.4
		c-16.4,8.7-32.6,17.8-49.3,25.9c-2.7-0.1-1.5-2.2-1.5-3.4c-0.1-7.9-0.1-15.8-0.1-23.7c0-19.3,0-38.5,0-57.8c0-1.5-0.4-3.1,0.4-4.5
		C323.2,123.4,330.5,120.1,337.7,116.5z"
      />
      <path
        class="st1561"
        d="M367.3,191.8c-0.6-0.5-1.2-1.3-0.2-1.5c4.5-1.2,4.4-4.6,4.2-8.2c-0.1-1.8,0-3.6-0.1-5.4
		c0-24.3,0-48.7,0.1-73c0-1.8-0.5-3.5-0.9-5.3c0.3-0.3,0.6-0.6,0.9-0.9c1.1,0.8,0.8,2,0.8,3c0,29.6,0,59.1,0,88.7
		C370.5,190.1,368.9,190.9,367.3,191.8z"
      />
      <path
        class="st1559"
        d="M316.9,128.3c0,28.1,0,56.3,0,84.4c0,1.7-0.8,3.7,1.1,5c-0.1,0.2-0.2,0.4-0.4,0.6c-2.9-0.3-1.5-2.6-1.5-3.9
		c-0.1-20.5-0.1-41-0.1-61.5c0-8.2,0-16.4,0.1-24.5C316.3,128.3,316.6,128.3,316.9,128.3z"
      />
      <path
        class="st1562"
        d="M338.5,104.8c4.8-2.5,9.5-5,14.3-7.5c1.4,0.4,3.1-0.6,4.2,1.1c-1,1.6-2.6,2.4-4.2,3.2
		c-1.5-0.4-2.8-1.2-3.1,1.6c-0.1,1.4-0.5,3.7-2.7,4.2c-2.3-0.1-4.7-0.6-5.7,2.4C338,109.5,339.8,106.3,338.5,104.8z"
      />
      <path
        class="st1563"
        d="M352.8,101.6c0.7-2,2.4-2.7,4.2-3.2c1.8,1.1,2.5,2.4,0.2,3.6C355.7,102.8,353.9,105.2,352.8,101.6z"
      />
      <path
        class="st1564"
        d="M341.3,109.8c-1.6-1.7-1.3-3.1,1.1-3.1c1.5,0,3.6-2.1,4.6,0.7C345.2,108.5,343.6,110,341.3,109.8z"
      />
      <path
        class="st1562"
        d="M330.3,109.2c2.2-1.1,4.4-2.3,6.6-3.4C335.9,109.3,333.1,109.2,330.3,109.2z"
      />
      <path
        class="st1565"
        d="M347.9,220.9c-2,0.2-4.7,2.1-5.7-0.9c-1.2-3.5,1-5.9,3.9-7.7c1.4,0.2,1.9-2.4,3.5-1
		c1.4,1.2,1.6,2.7,1.5,4.5C351.1,218.2,349.3,219.4,347.9,220.9z"
      />
      <path
        class="st1566"
        d="M352,155.8c-0.6-0.6-1-2.6-2.1-0.3c-0.5,1.1-1.4,1.9-2.1,2.9c-0.3,0.3-0.6,0.5-1,0.6
		c-3.2-0.1-6.3,0.9-9.4,1.5c-4.7-4.2-4.1-9.4,0.6-15.9c-5.2,6.6-5.2,12.9,0.7,17.6c1.1,2.1,2.3,1.6,3.9,0.5s3.6-2.7,4.7,0.7
		c-1.4,3.8-5.4,4.2-8.2,6.2c-1.8,1.6-3.4,0.1-5.1-0.4c-1.1-1.7-3.4-2.7-3.1-5.2c0.4-0.6,2.2-0.5,1.6-1.4c-1.3-2-0.6-4.7-2.6-6.3
		c-1.5-1-1.2,1.1-2.1,1.2c-1.7-1.4-0.6-2.8,0-4.2c1.5-0.9,1.7-2.4,1.9-3.9c0.6-4,2.2-7.6,4-11.1l0,0c2.8-4,4.3-9.3,10.1-10.4
		c3.3-0.1,6.1,1.2,8.6,3.1c1.3,1.1,2.3,2.5,3,4c3.2,5.8,2.5,11.6-0.1,17.4C354.4,153.7,354.4,155.9,352,155.8z"
      />
      <path
        class="st1567"
        d="M358.8,175c2.6,2.8,1.6,6.2,1.4,9.4c0,0.9-0.6,1.9-1.7,2.1c-6.5,1.1-11.1,5.6-16.5,8.7
		c-1.3,0.7-2.9,0.9-4.3,1.4c-0.7-0.5-1.6-0.8-1.6-1c0,0,0.2,1,0,1.9c-0.8,1-2,1.5-3.2,1.7c-1.8-1.8,0-5.1-2.6-6.6
		c-0.1-1.2,0.5-2.1,1.5-2.7c3.3-0.6,4.6-3.9,7.2-5.3c1-0.5,0.8-2.7,2.5-2.3c1.9,0.4,1.7,2.2,1.8,3.7c1.5-3.3-1.9-6.4,0.1-9.7
		c-2.3-0.2-5.3,0.7-4.5-3.1c0.9-1.8,2.8-2.2,4.4-3c2.4-0.6,4.7-1.4,6.6-3.2c1.8-1.8,3.8-3.3,6.4-3.6c1.8,1.7,0.5,3.6,0,5.3
		c-0.4,1.2-0.5,2.4-0.9,3.5C353.9,176.2,356.2,175.7,358.8,175z"
      />
      <path
        class="st1568"
        d="M356.2,163.3c-1.9,1.4-3.8,2.8-5.7,4.1c-2.2,1.3-4.6,2.3-7,3.4c-1.3,0.8-2.7,1.7-4,2.5
		c-0.5,2-1.6,3.2-3.9,2.8c-1.5,1.2-3.8,1.4-4.2,3.7c1.2,1.3,1.7,2.8,0.9,4.5c-0.7,1.3-1.6,2.4-3,0.6c-2.8-5.1-0.6-9.4,3.1-14.4
		c-3.6,1.4-6.5,2.4-4.7-2.3c0-1.7,0-3.4,0.1-5.1c1.3-0.3,2.1,0.4,2.6,1.4c0.9,1.9,2.3,3.1,3.9,4.3c1.5-0.7,3,1.6,4.5,0.1
		c4.8-1.4,8.3-5.4,13.1-6.9c0.9-0.3,1.7-1,2.7-0.4C355.1,162.3,355.7,162.8,356.2,163.3z"
      />
      <path
        class="st1569"
        d="M354.6,152.5c1.7-5.8,3.2-11.5-0.1-17.3c-0.3-3-0.3-3,1-3.6c3.6,6.9,5.4,13.8,0.7,20.9
		C355.6,152.9,355.1,153,354.6,152.5z"
      />
      <path
        class="st1570"
        d="M334.2,169c-1.8-0.5-3.8-0.4-4-3.1c-0.1-1.1-1.2-2.2-2.4-2.7c0-1.9,0-3.8,0-5.7c0.8-0.6-0.7-4.6,2.4-1.6
		c1.1,2.7,2.2,5.4,0.9,8.3C331.6,166.1,334.5,166.6,334.2,169z"
      />
      <path
        class="st1571"
        d="M352,131.7c-2.5-1.7-5.5-2.4-8.3-3.3c-0.3-0.2-0.4-0.4-0.3-0.6s0.2-0.3,0.3-0.3c0.7-0.4,0.4-2,1.8-1.6
		c3.2,0.9,6.8,1,8.9,4.2C354.2,131.6,353.4,132.1,352,131.7z"
      />
      <path
        class="st1572"
        d="M354.6,161.8c-3.9,2-7.7,4.1-11.6,6c-1.3,0.7-2.6,1.6-4.2,1.3c2.4-2.5,6.1-3,8.4-5.8c0-0.4,0.2-0.6,0.6-0.6
		c3.2-1.4,6.4-2.7,9.6-4.1c0.1,0.2,0.3,0.4,0.5,0.6C356.7,160.1,355.6,160.9,354.6,161.8z"
      />
      <path
        class="st1573"
        d="M347.8,162.7c-0.2,0.2-0.4,0.4-0.6,0.6c-2.1-3-3.5-0.2-4.9,0.8c-2.5,1.8-3.7,1.7-3.6-1.7
		c0.2-0.1,0.3-0.2,0.5-0.3c2.9-0.8,5.2-2.9,8-3.8c0.2,0,0.5,0,0.7,0c0.7-0.1,1.3-0.2,2.4-0.4C349.7,159.8,347.4,160.7,347.8,162.7z"
      />
      <path
        class="st1574"
        d="M343.7,127.4c0,0.3,0,0.6,0,0.9c-3.8,1.4-6,4.4-8.1,7.6c-0.6,0.9-0.8,2-2,2.3c1.1-4.8,4.5-8,7.9-11.2
		C342.3,126.4,343,127,343.7,127.4z"
      />
      <path
        class="st1575"
        d="M333.6,138.3c-0.9,4.6-3.8,8.7-3.4,13.7c0.1,1.5-1.3,1.4-2.5,1.4c1.1-4.4,2.2-8.8,4.1-13
		C332.2,139.4,332.4,138.5,333.6,138.3z"
      />
      <path
        class="st1576"
        d="M354.6,152.5c0.5,0,1,0,1.6,0c-0.7,1.5-1.9,2.9-1.9,4.4c0,2.5-1.7,1-2.1,0.9c-1.7-0.4-0.1-1.3-0.1-2.1
		C352.9,154.7,353.7,153.6,354.6,152.5z"
      />
      <path
        class="st1577"
        d="M352,131.7c0.8-0.6,2-0.5,2.5-1.6c0.4,0.5,0.7,1,1.1,1.5c-2.2,0.6,1.1,2.9-1,3.6
		C353.6,134,352.8,132.9,352,131.7z"
      />
      <path
        class="st1578"
        d="M329.3,185c1.6,0.7,2.1-0.5,2.5-1.6c0.9-2.2-1.5-2.4-2.4-3.5c1.4-2.2,4.9-2,5.7-4.9
		c1.9,0.6,3.3-0.1,4.3-1.7c-0.2,2.4,1,2.4,2.8,1.7c0.6-0.2,1.5-1,2.2,0c0.6,0.8,0.6,1.6-0.2,2.2c-2.4,2,1.5,3.7,0.3,5.8
		c-0.5,0.8,0.8,2.1-0.1,2.7c-0.9,0.6-0.3,2.7-2,2c-1-0.4-0.8-1.4-0.1-2.4c0.8-1-0.5-1.6-1-2.6c-0.6,3.8-5.2,3.8-6.3,7.1
		c-0.1,0.4-2.1,1.6-3.2,0.1c0-0.9,0.4-2.3-1.1-2.1C327,188.4,329.1,186.3,329.3,185z"
      />
      <path
        class="st1579"
        d="M330.3,192.6c2.1-0.4,2.7,0.9,2.7,2.6c0.1,1.3-0.1,2.7-0.2,4.1c-1.4,1.1-2.7,2.3-5.1,2.8
		C328,198.6,330.4,195.9,330.3,192.6z"
      />
      <path
        class="st1580"
        d="M336.1,197.5c-0.3-0.4-0.8-0.9-0.8-1.4c0.2-1.2-0.9-3.3,1-3.5c2.5-0.3-1,2.1,0.8,2.6
		c0.3,0.1,0.4,0.9,0.6,1.4C337.2,196.9,336.6,197.2,336.1,197.5z"
      />
      <path
        class="st1581"
        d="M346.8,144.6c-0.7,1.6-2.1,1.6-2.8,2.4c-1.7,1.9-5.3,3.4-1.7,6.7c0.7,0.7,0.8,2.2-0.6,2.8
		c-1.2,0.5-1.9-0.1-2.4-1.4C337.6,150.2,340.7,145.6,346.8,144.6z"
      />
      <path
        class="st1582"
        d="M338.6,162.5c-3.7-0.4-3.9-3.6-4.6-6.1c-1.6-5.3,1.4-11.8,6.8-14.5c-4.4,5.7-9,11.1-3.1,18.2
		C338.8,160.6,339.5,161.2,338.6,162.5L338.6,162.5z"
      />
      <path
        class="st1559"
        d="M338.6,162.5c-0.3-0.8-0.6-1.6-0.9-2.5c2.9-1.9,6-2.6,9.4-1.7C344.8,160.8,342.3,162.9,338.6,162.5z"
      />
      <path
        class="st1568"
        d="M331.1,164.2c-0.3-2.8-0.6-5.5-0.9-8.3c0.3-0.5,1-0.9,1.3-0.4c1.1,2.5,1.3,5.3,2.5,7.9
		C334.9,165.2,332.1,163.5,331.1,164.2z"
      />
      <path
        class="st1583"
        d="M351.5,145.4c1-1.3-0.1-2.9,0.9-4.1c0,0,0.8,0.3,0.9,0.6C353.9,143.5,353.9,145,351.5,145.4z"
      />
      <path
        class="st1584"
        d="M340.2,136.2c0.9-3.1,2.2-3.1,4.6-2.1C343.2,134.8,342.1,135.3,340.2,136.2z"
      />
      <path
        class="st1582"
        d="M351.4,136.5c-1.7,1.4-3.1,2.5-4.5,3.6C347.4,137.8,348.4,136,351.4,136.5z"
      />
      <path
        class="st1585"
        d="M348.1,152.1c1.2-0.4,2-1.9,3.5-0.8c0.5,0.4,0.7,1,0.3,1.4C350.4,154.1,349.5,152.5,348.1,152.1z"
      />
      <path
        class="st1586"
        d="M343.5,170.9c1.9-2,4.2-3.1,7-3.4C348.8,169.9,346.2,170.5,343.5,170.9z"
      />
      <path
        class="st1587"
        d="M350.4,187.2c-0.9-1.7-1.8-2.7-0.9-4.1c0.1-0.2,1-0.4,1-0.3C351,184,351.4,185.2,350.4,187.2z"
      />
      <path
        class="st1588"
        d="M347.1,174.4c1.8,2.9,0.6,3.8-1.5,4.5C345.3,177.1,347.5,176.5,347.1,174.4z"
      />
      <path
        class="st1589"
        d="M335.1,175.1c0.5,1.4,0.6,2.2-1.4,2.5c-1.5,0.3-2.9,1.5-4.3,2.3C330.2,177,333.7,177.2,335.1,175.1z"
      />
    </g>
    <g id="gaming">
      <path
        class="st1590"
        d="M289.9,917.8c0.5-0.2,1.7,0.5,1.4-0.9c0.9,0.1,1.8,0.1,2.6,0.2c0.4,1.2,1.8,1,2.5,1.8
		c-0.2,0.5,0.2,0.6,0.5,0.6c1,0.3,1.7,0.9,2.4,1.6l0,0c1.9,0.9,3.2,2.3,4.5,3.9c0.5,0.6,0.5,1.4,0.6,2.2c-0.1,0.5-0.4,0.9-1,1
		c-0.2-0.1-0.4-0.2-0.5-0.4c-2.3-5.1-6.9-7.1-11.6-9.1C290.8,918.5,290,918.7,289.9,917.8z"
      />
      <path
        class="st1591"
        d="M210.3,991c0.1,0,0.2,0,0.4,0c0.5,0.7,0.4,1.5,0.3,2.2c-0.2,3-0.4,5.9-0.7,8.8c0,0.5-0.1,1.1-0.6,1.5
		c-0.1,0-0.2,0-0.3,0C209.7,999.4,210,995.2,210.3,991z"
      />
      <path
        class="st1592"
        d="M326.1,1026.9c-5.3,1.5-10.4,1.9-15.7-0.1c-4.2-1.6-7.6-4-9.6-8.1c-0.5-1.1-0.7-2.3-0.8-3.5
		c-0.3-3.1,0.2-6.2,0.4-9.3c0.4-0.7,0-1.5,0.4-2.3c1.1,0.6,1.5,1.7,2.1,2.6c3,4.5,7.4,6.5,12.5,7.3c2.3,0.3,4.7,0.4,7.1,0.1
		c2.6-0.3,4.8-1.3,6.9-2.8c0.6-0.5,1.1-1,1.8-1.4c0.6-0.4,1.3-0.7,1.8,0.2c0.9,1.5,2.3,2.4,3.5,3.5c4,3.6,9,4.2,14,4.8
		c4.4,0.5,8.9,0.7,13.3-0.3c0.8-0.2,1.6-0.3,2.4-0.6c4.8-1.2,9.4-3,13.4-6c0.2-0.1,0.3-0.1,0.5-0.1c1.4,0.6,0.7,1.4,0.1,2.1
		c-0.8,1-2.1,0.8-3,1.4c-4.5,3.2-9.5,4.7-14.8,5.5c-5.6,0.8-11.2,0.7-16.7-0.4c-3.9-0.8-7.6-2.2-11.1-4.1c-1-0.5-1.6-0.3-2.4,0.5
		c-1.8,2-2.7,4.4-2.8,7c0,0.2,0,0.4,0,0.7C329.1,1026.2,328.6,1026.7,326.1,1026.9z"
      />
      <path
        class="st1593"
        d="M271.6,1003.6c-1.8-0.3-3.5-0.3-5.3-0.7c-1.1-0.3-2.1-0.9-3.1-1.2c-1.3-1.2-2.8-2-3.9-3.5
		c-0.3-0.5-0.8-0.8-1-1.3c-0.4-0.7-0.5-1.6-1.1-2.2c0-1-1.1-1.6-1.2-2.6c-0.2-0.8-0.3-1.7-0.7-2.5c-0.6-1.1,0.4-1.9,0.4-2.9
		c0.3-1.9,0.1-3.9,0.5-5.8c0.9-1.3,1.3-1.3,1.9,0.1c1.9,4.4,5.4,6.9,9.9,8c0.6,0.2,1.3,0.3,1.8,0.8c0.6,0.8,1.4,1,2.3,0.8
		c0.7-0.1,1.4-0.1,2.1,0c0.6,0.1,1.1,0.3,1.2,1c0.1,0.6-0.5,0.8-0.9,1c-0.5,0.2-1.1,0.2-1.4,0.8c0.4,0.7,1.2,0.8,1.9,0.8
		c0.9,0.1,1.9,0.2,2.8,0.5c0.9,0.2,1.7,0,2.5-0.3c2-0.8,4.1-1.5,6-2.6c1.2-0.7,2.4-1.4,2.9-2.8c0.2-0.5,0.5-0.9,0.8-1.2
		c1.1-1.4,1.8-3,1.9-4.8c0.6-1.4,1.1-1.6,2.1-0.8c3.1,2.2,6.4,4.2,9.8,6c1.6,0.9,0.5,1.9,0.1,2.9c-0.3,0.5-0.7,0.8-1.2,1.1
		c-2,1.5-1.5,3.9-2.1,5.9c-0.2,0.8,0.1,1.9-0.8,2.2s-1.6-0.5-2.3-1c-1.8-1.5-3.8-2.5-6.1-3.1c-0.8-0.5-0.9-1.2-0.8-2
		c0.1-1,0.2-2,0.1-3.1c-2,2.1-3.9,4.2-6.8,5.2c-2.8,0.9-5.5,1.9-8.4,2.6c0.6,0.4,1.2,0.5,1.7,0.8c0.4,0.3,1,0.5,0.9,1.1
		s-0.7,0.6-1.2,0.6c-0.3,0-0.7,0-1,0c-0.4,0-0.5,0.1-0.6,0.6c-0.2,1.9-0.8,2.3-2.6,2.2C272.5,1003.8,272,1003.9,271.6,1003.6z"
      />
      <path
        class="st1594"
        d="M377.3,1013.9c0.7-0.9,2.1-0.1,2.7-1.1c0.4-0.6,1.4-1,0.1-1.7c0.1-1,0.7-1.8,1.5-2.2c2-1.2,3.1-3.2,4.5-4.9
		c1.1-2.2,2.4-4.4,2.9-6.8c0.1-0.5,0.3-1,0.9-1.3c1,0.2,1.2,0.9,1,1.7c-0.4,1.4-0.7,2.8-0.5,4.2c0.1,0.7-0.6,1.1-0.6,1.8
		c0.6,0.1,0.5-0.9,1.3-0.6c-0.3,3.9-0.2,7.9-1.6,11.7c0.1,1-1.1,1.3-1.2,2.2c-2.7,4-6.5,6.8-10.7,9.1c-1.2,0.7-1.5,1.8-1.7,3
		c0,0.8-0.2,1.4-0.8,1.9c-1.3,2.5-2.7,5-3.6,7.7c-1.1,2.6-2.4,5.1-3.8,7.5c-0.3,0.6-0.5,1.2-0.8,1.8c-0.3,1.1-1,1.9-1.5,2.9
		c-2,3.5-4.1,7.1-6.6,10.3c-2.7,3.5-6,6.4-10.3,7.9c-0.4,0.1-0.9,0.4-1.4,0c0.1-1.3,1.6-1,2.2-1.9c-1-0.3-1.8,0.1-2.7,0.4
		c-1.4,0.6-2.9,0.9-4.4,0.2c0.2-0.9,1-0.7,1.6-0.9c3.5-0.7,6.5-2.3,8.9-4.9c3.9-4,6.5-8.8,9.1-13.7c2.6-4.8,4.9-9.7,7.3-14.6
		c0.2-0.4,0.3-0.8,0.4-1.2c1.7-3.5,3.1-7.1,4.7-10.6C375.7,1019.4,377,1016.8,377.3,1013.9z"
      />
      <path
        class="st1595"
        d="M377.3,1013.9c0.3,0.2,0.3,0.6,0.3,0.9c-0.4,2.6-1.4,5-2.8,7.3c-0.5-1.4-0.3-2.4,0.7-5
		c-0.5-0.3-1,0-1.4,0.2c-2.4,1.2-5,1.6-7.3,3c-0.2,0.1-0.4,0.2-0.6,0.2c-3-0.5-5.2,1.3-7.6,2.6c-5.6,1.2-11.1,1.1-16.6-0.2
		c-0.8-0.2-1.5-0.4-2.2-0.5c-3.1-0.7-5.4,0.8-6.1,3.9c-0.6,2.9-1.2,5.9-1.6,8.9c-0.7,4.6-1.6,9.1-2.1,13.7c-0.2,2.1,0.1,4,0.3,6.1
		c0,0.2-0.2,0.3-0.4,0.4c-1.2-0.1-1.2-1.1-1.4-1.9c-0.1-0.5-0.1-1.1-0.8-1.1c-0.6,0-0.6,0.7-0.7,1.2c-0.1,1.3-0.2,2.5-0.3,3.8
		c-0.2,1,0.2,2.2-0.7,3c-0.5-0.7-0.2-1.4-0.1-2.1c0.7-9.7,1.3-19.3,2.1-28.9c0.1-1.6-0.3-2.2-1.9-2.1c2.5-0.7,2.7-0.8,2.9-3.7
		c0.1-3.3,1.4-6,3.6-8.3c0.5-0.5,0.8-0.8,1.5-0.3c6.7,4,14.1,5.6,21.8,5.5c6.8-0.1,13.5-1.4,19.5-4.8
		C376.1,1014.8,376.7,1014.3,377.3,1013.9z"
      />
      <path
        class="st1596"
        d="M303.4,928c0.4-0.2,0.5-0.6,0.8-1c0.3-0.1,0.5,0.1,0.6,0.3c0.8,2.2-0.3,4-1.2,5.9c-0.3,0.7-1,1.2-0.5,2.1
		c0.4,1.3,1.6,1.8,2.7,2.3c0.9,0.5,0.4-1.2,1.3-0.9c-0.1,1.1,0.2,1.8,1.3,2.5c5,3.2,9.8,6.8,15.1,9.5c0.9,0.4,1.5,1.3,2.2,1.9
		c2.7,1.5,5.2,3.4,7.9,5.1c2.3,1.4,4.4,2.9,6.7,4.3c1.9,1.2,3.6,2.6,5.7,3.6c0.8,0.4,1.4,1.2,1.7,2.1c-0.2,0.5-0.6,0.5-1,0.5
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.5-1-1.5-1.4-2.4-1.9c-1.8-1.2-3.6-2.4-5.5-3.5c-0.1-0.1-0.3-0.2-0.4-0.2c-1.7-1.2-3.5-2.3-5.3-3.3
		c-5.7-3.8-11.5-7.3-17.1-11c-2.7-1.8-5.5-3.5-8.1-5.2c-0.7-0.6-1.6-1-2.5-1.6c0.1,0.8,0.3,1.4,0.4,2.1c0.3,1.8,0.6,3.5,0.1,5.3
		s-0.4,3.8-1.8,5.3c-0.6,0.3-1.3,1.9-1.9,0c0.5-2.2,1.3-4.4,1.4-6.8c0.1-2-0.2-3.8-0.7-5.7c-1.6-5.6-5.2-9.7-9.9-12.9
		c-3.2-2.1-6.7-3.8-10.3-5.1c-0.5-0.2-1.4-0.2-1.4-1c0.5-0.8,0.9-1.8,2.1-1.8c0.1,0.3-0.2,0.6-0.2,0.9c5.4,2.1,10.5,4.6,15,8.3
		c0.1,0.1,0.2,0.2,0.3,0.3c1,1,1.9,2.1,2.9,3.2c0.4,0.3,0.5,1.2,1.1,1c0.6-0.1,0.6-0.8,0.9-1.3C302.9,930.1,302.8,928.9,303.4,928z"
      />
      <path
        class="st1597"
        d="M271.6,1003.6c1-0.1,1.9,0.4,2.8-0.4c0.6-0.5,1.3-0.8,0.4-1.7c-0.6-0.7,0.1-0.9,0.7-0.9
		c0.8,0,1.6,0.1,2.7,0.2c-1.1-1.6-2.9-1.1-3.9-2.3c2-0.5,3.8-0.9,5.6-1.4c0.9-0.2,2-0.3,2.6-1.2c0.1-0.1,0.3-0.3,0.4-0.2
		c1.8,0.6,2.8-1.2,4.3-1.6c1.2-0.3,1.2-1.7,2.1-2.4c0.4-0.3,0.7-0.9,1-1.3c0.3-0.3,0.7-0.4,1.1-0.2s0.1,0.6,0.1,0.9
		c0,1.5-0.8,3.1,0,4.7c0,0.1,0,0.2,0,0.2c-1.5,1.4-2.6,3.3-4,4.8c-3.5,1.5-7.1,3-11,3.3C274.8,1004.2,273.2,1004.3,271.6,1003.6z"
      />
      <path
        class="st1598"
        d="M326,1060c0.1-1.1,0.2-2.2,0.3-3.3c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.2,0.5,0.3,0.8c1.8,7.1,6.2,9,12.6,9.3
		c0.9,0.1,1.9-0.1,2.7,0.7c1.6-0.1,3.2,0.2,4.6-0.8c0.6-0.4,1.6-0.5,2.5-0.3c0.3,0.1,0.6,0.2,0.7,0.6c0,0.4-0.3,0.5-0.6,0.7
		c-0.8,0.4-1.8,0.4-2.4,1.2c-1.1,0.9-2.5,0.8-3.8,1.3c-2.4-0.2-4.9,0-7.2-0.5c-4.3-1.1-7.8-3.3-9.6-7.6
		C326.4,1061.6,325.9,1060.9,326,1060z"
      />
      <path
        class="st1599"
        d="M329.8,1011c-1.9,1.7-4.1,2.5-6.6,2.9c-3.4,0.6-6.9,0.5-10.1-0.4c-5.3-1.5-9.8-4.3-12.1-9.7
		c-0.5,0.7,0,1.6-0.7,2.1c0.2-1.7-0.2-3.3-0.4-4.9c0.7-0.4,0.1-1.5,1-1.7c0.4,0.2,0.5,0.6,0.6,0.9c1.2,5.7,5.1,8.8,10.4,10.5
		c0.4,0.1,0.7,0.2,1.1,0.3c2.1,0.6,4.3,0.6,6.5,0.8c2.3,0.5,4.5,0.3,6.7-0.7C327.4,1010.5,328.6,1010.3,329.8,1011z"
      />
      <path
        class="st1600"
        d="M390.6,988.1c-0.1,0.2-0.1,0.6-0.2,0.7c-1,1.1-0.8,2,0.3,2.9c-0.8,1.3-0.5,3-1.2,4.3
		c-1.4,3.8-3.5,7-6.2,9.9c-1.2,1.3-2.6,2.4-4.1,3.3c-0.5,0.3-1,0.8-1.6,0.4c-0.2-0.3-0.2-0.7-0.1-1c1.1-1.3,2.4-2.4,3.6-3.6
		c4.6-4.3,7.1-9.5,6.8-16c-0.1-2.7-1.2-5-2.4-7.3c-0.3-0.6-0.8-1.2-0.8-2c0-0.4,0.2-0.8,0.5-1.1c0.8-0.1,0.5-0.8,0.6-1.2
		c0-0.7,0.3-1.3,0.9-1.7c0.9-0.5,0.9-1.4,0.9-2.3c0-0.7,0-1.5,1.1-0.8c0.5,2.3-0.5,4.4-1,6.6c-0.2,0.6-0.3,1.2,0.1,1.7
		c1.1,1.8,1.8,3.8,2.3,5.9C390.1,987.5,390.3,987.8,390.6,988.1z"
      />
      <path
        class="st1601"
        d="M209.7,1003.5c0.1-0.5,0.1-0.9,0.2-1.4c0.2-0.6-0.1-1.3,0.4-1.9c0.8,0.3,0.8,1,0.9,1.6
		c0.5,3.3,2.6,5.1,5.5,6.4c2.1,0.9,4.3,1.1,6.5,1.3c2,0,3.9-0.8,5.8-0.9c0.6,0.1,1,0.4,1.6,0.5c0.5,0.1,1.1,0.2,1.1,0.8
		c0.1,0.5-0.4,0.8-0.9,1.1c-0.2,0.1-0.5,0.3-0.7,0.4c-1.8,0.6-3.6,1.1-5.5,1c-2.2-0.2-4.4-0.1-6.6-0.6c-0.6,0-1.1-0.1-1.5-0.5
		C212.8,1010,210.6,1007.3,209.7,1003.5L209.7,1003.5z"
      />
      <path
        class="st1602"
        d="M388.5,972.7c-0.8,0-0.5,0.4-0.5,0.8c0.1,1.1,0,2.1-1.2,2.6c-0.3-0.4-0.3-0.8-0.3-1.2
		c0.2-1.6-0.4-2.9-1.3-4.1c-0.5-1-1.3-1.6-2.1-2.2c-0.1-0.1-0.2-0.2-0.4-0.3c-1.3-1.2-2.9-2-4.4-2.8c-3.4-1.5-6.9-2.2-10.5-0.8
		c-0.7,0.3-1.4,0.6-2,1.1c-1.3,0.4-2.4,0.2-3.4-0.6c2.2-1,4.4-2.2,6.7-2.8c2.1-0.6,4.3-0.3,6.3,0.3c2.1,0.6,4.1,1.4,6.1,2.5
		c3.6,2,6.2,5.1,8.8,8c0.7,0.7,1.9,1.8,1.4,3.3C389.8,976,389.5,974.1,388.5,972.7z"
      />
      <path
        class="st1603"
        d="M362.5,965.2c1.1,0.2,2.2,0.4,3.4,0.6c0.9-0.4,1.6,0.2,2.4,0.4c4.8,1.7,9.4,4,13.3,7.4
		c1.2,1,2.2,2.1,3.2,3.3c0.4,0.5,1,1.1,0.7,1.9c-0.1,0.4-0.3,0.7-0.5,1.1c-1.1-0.2-1.4-1.1-2-1.8c-1.4-2.1-3.2-3.7-5.4-4.9
		c-4.6-3.4-9.9-5.4-15.5-6.5c-1.1-0.2-2.2-0.3-3.1-0.9c0-1.1,1-0.2,1.3-0.7C360.9,965.1,361.7,965.2,362.5,965.2z"
      />
      <path
        class="st1604"
        d="M390.6,988.1c-0.4,0.1-0.8,0.7-0.9-0.2c-0.5-2.5-1.3-4.9-2.7-7.1c-0.2-0.4-0.2-0.8,0-1.3
		c0.5-0.1,0.5,0.5,1,0.6c0.3-0.9,0.7-1.8,1-2.7c0.2-0.6,0.4-1.3,1.2-1.4c0.7,0,0.8,0.7,1.2,1.2c0.1,0.1,0.2,0.3,0.2,0.4
		C391.5,981.2,391.6,984.7,390.6,988.1z"
      />
      <path
        class="st1605"
        d="M218.1,1011.8c2.2,0.2,4.5,0.4,6.7,0.4c1.9,0,3.9-0.2,5.6-1.3c1.6-0.4,3-1.5,4.3-2.4
		c1.8-1.3,3.8-2.4,5.5-3.9c4.4-3.9,8.9-7.7,13.3-11.6c0.9-0.8,1.8-1.5,3.1-1c0,1,1,1.6,0.9,2.6c0.1,0.1,0.1,0.3-0.1,0.4
		c-0.3-0.2-0.3-0.4-0.4-0.7c-0.6-1.6-1.2-1.7-2.5-0.6c-4.2,3.7-8.4,7.4-12.7,11c-3.5,2.9-7.1,5.6-11.5,7.1c-2,0.7-4.1,1.1-6.3,0.8
		C222,1012.3,219.9,1012.7,218.1,1011.8z"
      />
      <path
        class="st1606"
        d="M360.2,965.1c-0.3,0.5-1,0-1.4,0.5c-0.5,0.5-1.1,0.5-1.7,0.4c-2.5-0.3-5,0-7.5,0.2c-0.7,0.1-1.4,0.2-2-0.3
		c-3-3.2-7.2-4.8-10.6-7.5c-1.2-1-2.8-1.2-3.7-2.5c0.2-0.2,0.5-0.2,0.7,0c1.9,0.5,3.4,1.8,5,2.9c2.5,1.6,4.9,3.3,7.5,4.8
		c1.8,1.1,3.4,1,5.2,0.7c0.7-0.1,1.5-0.2,2.1,0C356,965,358.1,964.4,360.2,965.1z"
      />
      <path
        class="st1607"
        d="M389.2,995.9c0.2-1.3,0.8-2.6,0.7-4c0-0.8,0.5,0,0.8-0.2c0.8,1.8,1.1,3.7,1,5.6c-0.2,4.4-0.6,8.8-0.9,13.2
		c-0.1,1.5-0.6,3-1.3,4.3c0.7-3.2,1.3-6.4,1.3-9.6c0-0.6,0.1-1.2,0.1-1.9c-0.6,0.4-0.1,1.5-1.1,1.5c-0.6,0-0.6-0.7-0.8-1.1
		c0-0.1-0.1-0.3,0-0.3c1.9-1.7,0.1-4.2,1.5-6c0.5-0.7-0.2-1.1-0.7-1.4C389.6,996.1,389.4,996.1,389.2,995.9z"
      />
      <path
        class="st1608"
        d="M307.1,936.8c-0.6,0.3-0.3,1.1-0.7,1.7c-1.2-1-3.1-0.9-3.5-2.9c0.7-1.7,1.6-3.3,2.7-4.8
		c0.6-0.8,1.2-0.1,1.7,0.4c0.6,1.5,0.1,2.9-0.1,4.3C307.2,936,307.1,936.4,307.1,936.8z"
      />
      <path
        class="st1609"
        d="M343.5,1070.5c1.1-0.9,2.6-0.7,3.8-1.3c2.2-0.5,4.2-1.4,6-2.8c5.2-3.9,8.4-9.4,11.6-14.9
		c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.3-0.1,0.4,0.1c-3.3,6.2-6.9,12.2-12.7,16.5c-2,1.5-4.5,2.2-6.9,2.7
		C345.3,1070.2,344.5,1070.9,343.5,1070.5z"
      />
      <path
        class="st1610"
        d="M391.7,977.8c-0.9-0.1-0.9-1-1.5-1.5c-1.1,1.5-1.2,3.5-2.3,5.1c-0.3-0.7-0.6-1.2-0.9-1.8
		c0.8-2.2,1.2-4.5,1.4-6.8c1.4,1,1.8,2.9,3.3,3.8C391.8,976.9,391.7,977.3,391.7,977.8z"
      />
      <path
        class="st1597"
        d="M300.8,999.3c-0.5,0.5,0.4,1.7-0.9,1.7c-0.6-0.4-1.2-0.8-1.8-1.2c-2.1-1.4-4.1-3.1-6.7-3.7
		c-0.1,0-0.1-0.2-0.1-0.3l0.1-0.1c2.6,0.2,4.7,1.5,6.6,3.2c0.5,0.5,1.1,1,1.7,0.8c0.7-0.2,0.3-1,0.5-1.6c0.6-2,0.2-4.3,1.8-6
		c0.2-0.2,0.4-0.5,0.8-0.5c0.3,0.5,0.3,1,0,1.4c-0.9,1.7-1.3,3.6-1.5,5.5C301.2,998.8,301.1,999.1,300.8,999.3z"
      />
      <path
        class="st1611"
        d="M375.9,1029.1c0-1.7,0.6-2.7,2.2-3.5c3.1-1.6,5.7-4,8.2-6.4c0.7-0.7,0.9-1.8,1.9-2.1
		c-1.3,3-3.9,4.9-6.4,6.8c-1.2,0.9-2.6,1.8-4,2.5C376.8,1026.9,376.5,1028.2,375.9,1029.1z"
      />
      <path
        class="st1612"
        d="M307.2,935.5c0-0.9,0-1.8,0.1-2.8c0-0.6,0.1-1.2-0.5-1.6c-0.9-1.2-1.2-2.7-2.4-3.7
		c-0.1-0.1-0.2-0.3-0.4-0.4c-0.5-2.9-3-4.2-4.9-6c1.1,0.1,1.9,0.7,2.6,1.5c1.4,1.5,2.9,2.9,3.9,4.6
		C307.4,929.7,308.6,932.5,307.2,935.5z"
      />
      <path
        class="st1613"
        d="M291.4,995.7c2.6,0.8,4.9,2.1,6.7,4c-1.8-0.5-3.1-1.8-4.6-2.7c-1.4-0.8-2.3-0.6-3.3,0.6
		c-0.9,1-1.3,2.5-2.8,3C288.5,998.9,289.5,996.9,291.4,995.7z"
      />
      <path
        class="st1614"
        d="M334,956c-0.2,0-0.5,0-0.7,0c-2.1-0.7-3.4-2.5-5.3-3.5c-0.8-0.4-1.8-0.8-2.3-1.7c2.5,0.9,4.6,2.4,6.7,4
		C332.9,955.1,333.8,955.2,334,956z"
      />
      <path
        class="st1591"
        d="M209.7,1003.5c0.8,1.4,1.3,3,2.3,4.3c1.2,1.5,2.9,2.4,4.5,3.5C213.2,1011,209.6,1007,209.7,1003.5z"
      />
      <path
        class="st1615"
        d="M371.6,1038.8c0.1-1.3,0.5-2.5,1.3-3.6c0.9-1.3,0.9-3.1,2.3-4.1c-0.2,1.6-1.1,2.8-1.8,4.1
		C372.8,1036.4,372.5,1037.7,371.6,1038.8z"
      />
      <path
        class="st1616"
        d="M258.6,996.7c1.2,1.5,2.3,3.1,4.1,4c0.4,0.2,0.5,0.5,0.5,1c-2.1-1.2-4-2.6-5-5
		C258.4,996.6,258.5,996.6,258.6,996.7z"
      />
      <path
        class="st1617"
        d="M299.3,921.1c-1-0.5-2-1-2.9-1.6c-0.4-0.2-0.7-0.5,0.1-0.6C297.4,919.7,298.9,919.7,299.3,921.1z"
      />
      <path
        class="st1618"
        d="M365.8,1050.9c-0.1,0-0.3-0.1-0.4-0.1c0.4-1,0.9-2,1.6-2.8C367.1,1049.2,366.3,1050,365.8,1050.9z"
      />
      <path
        class="st1619"
        d="M258.6,996.7c-0.1,0-0.2,0-0.4,0c-0.7-0.4-0.6-1.1-0.9-1.8c0-0.1,0-0.2,0.1-0.4
		C258.2,995.1,258.3,996,258.6,996.7z"
      />
      <path
        class="st1620"
        d="M280.5,920.4c4.1,1.6,8.2,3.3,11.9,5.7c4.3,2.8,7.6,6.5,9.6,11.3c0.2,0.5,0.3,1,0.4,1.6
		c0.2,2.5,1.1,4.9,0.5,7.5c-0.4,1.7-1.1,3.3-1.5,5c-0.2,0.8-0.9,1.8-2.1,1.4c-1-0.4-1-1.3-0.8-2.2c0.3-0.9,0.1-1.6-0.7-2.1
		c-1-0.7-1.5-1.5-1.2-2.8c0.3-1.1-0.6-1.2-1.3-1.5c-0.2-0.1-0.3-0.2-0.4-0.3c-0.5-1.9-0.7-3.9-1.6-5.7c-1.8-3.8-4.8-6.4-8.3-8.4
		c-6.3-3.7-13.1-4.9-20.3-4.3c-5.7,0.3-10.9,2-15.6,5.3c-6.4,4.6-8.8,12.5-5.7,19.1c0.8,1.1,1.1,2.4,1.8,3.6
		c0.6,1.2,1.4,2.2,2.7,2.7c4.2,3.2,8.9,4.9,14.1,5.8c0.5,0.1,1,0.1,1.4,0.5c0.3,0.4,0.2,0.8-0.1,1.2c-0.8,0.7-1.2,1.6-1.3,2.7
		c-0.1,1,0,2.8-1.4,2.6s-0.5-1.8-0.5-2.8c0-0.6,0.5-1.2,0.1-2.2c-0.2,1.2-0.6,2.2-0.8,3c-0.4,1.5-1.2,2.1-2.6,2.1
		c-0.6,0-1.1-0.1-1.6-0.2c-5.7-1.8-11-4.4-15.3-8.6c-3.2-3.2-5.1-7-6.2-11.3c-0.4-1.5-0.1-2.9-0.3-4.4c-0.1-8.5,4.5-14.5,11.1-19.1
		c5.8-4,12.5-6.3,19.5-6.6c5-0.2,10,0,14.9,1.3C279.2,920.4,279.9,920.4,280.5,920.4z"
      />
      <path
        class="st1621"
        d="M211.5,989.5c2-4.4,4.2-8.8,6.5-13c2.8-5.1,5.6-10.2,8.4-15.4c0.3-0.5,0.6-0.9,0.9-1.3
		c1.3-1.3,1.9-3.1,2.9-4.7c0.9-1.5,1.8-1.5,2.6,0c0.6,1.2,1.1,2.3,1.9,3.5c0.3,0.9,1,1.7,0.7,2.8c-3,1.8-5.1,4.5-7.2,7.1
		c-2.7,3.3-5.4,6.6-7.8,10.2c-2.9,4.2-6,8.3-7.3,13.3c-0.1,0.2-0.2,0.4-0.3,0.6c-0.8,0.3-0.9-0.3-1-0.9
		C211.5,990.9,211.5,990.2,211.5,989.5z"
      />
      <path
        class="st1622"
        d="M211.5,989.5c0.9,0.8,0.1,2.2,0.9,3c0.4,0.3,0.4,0.7,0.4,1c0.2,4,2.6,6.1,6.1,7.2c0.6,0.2,1.1,0.3,1.7,0.6
		c0.4,0.2,0.9,0.3,1.4,0.4c0.8,0.2,1.3,0.7,1.4,1.6c0.1,1.3,0.2,2.5,0.8,3.7c0.2,0.4,0.2,1-0.2,1.2c-0.7,0.3-0.4,1.1-0.7,1.5
		c-1.6,0.4-3.1-0.1-4.6-0.4c-1.8-0.5-3.6-1.1-5-2.5c-1.6-1.5-2.8-3.2-2.8-5.5c0-0.2-0.2-0.5-0.4-1c-0.2,0.8-0.4,1.3-0.5,1.8
		c0.2-3.7,0.5-7.4,0.7-11.1C210.9,990.5,211.2,990,211.5,989.5z"
      />
      <path
        class="st1623"
        d="M303.4,928c-0.1,2.6-0.4,3.4-1.8,5.3c-0.8-0.4-1-1.2-1.4-1.9c-0.7-1.2-1.6-2.3-2.8-3.2l0,0
		c-4.4-4-9.7-6.6-15.3-8.5c-0.2-0.1-0.3-0.1-0.4-0.3c0.3-0.3,0.5-0.5,0.8-0.8c2.3-1,4.8-1.2,7.3-0.8c3.8,1.1,7.2,2.9,10.2,5.4
		C301.7,924.5,302.9,926,303.4,928z"
      />
      <path
        class="st1624"
        d="M234.4,958.6c-0.8-0.8-1.4-1.8-1.7-2.9c-0.2-0.7-0.6-1.4-1.4-1.5c-0.7,0-0.8,0.8-1,1.3
		c-0.7,1.4-1.5,2.8-2.3,4.2c-0.2,0.4-0.5,0.1-0.7,0c1.8-3.2,3.6-6.4,5.4-9.6c0.1,0.1,0.1,0.1,0.2,0.2c0.5,2.3,0.9,4.7,2,6.8
		c0.2,0.4,0.3,0.9,0,1.4C234.7,958.7,234.6,958.7,234.4,958.6z"
      />
      <path
        class="st1625"
        d="M233.1,944.5c0.5,5.9,2.7,11.1,6.8,15.5c-0.8,0.6-1.5,0.1-1.9-0.4c-1.6-1.6-3.2-3.2-3.4-5.6
		c-0.1-1-0.8-1.9-1.1-2.9c-0.1-0.4-0.2-0.8-0.7-0.9l-0.1-0.1C232.8,948.3,233,946.4,233.1,944.5z"
      />
      <path
        class="st1626"
        d="M366.5,1017.2c-2.2,1.2-4.6,1.2-7,1.5c-3.6,0.4-7.2,0-10.8-0.4c-3.9-0.4-7.6-1.5-11-3.4
		c-0.8-0.5-1.7-1.3-2.5-2.1c-0.7-0.8-1.3-1.6-2.3-2c-0.6-0.3-0.5-0.7,0-1.1c0.1-0.1,0.3-0.1,0.5-0.1c3.2,1.8,6.5,3.5,10.1,4.5
		c1.6,0.5,3.3,0.9,5,1c0.7,0.3,1.5,0.2,2.3,0.5c2.2,0.1,4,1.4,6.2,1.6c2.5,0.3,4.9,0.1,7.3-0.6
		C365.1,1016.2,366,1016.2,366.5,1017.2z"
      />
      <path
        class="st1627"
        d="M366.5,1017.2c-0.8-0.3-1.5-0.5-2.4-0.2c-3.5,0.9-7.1,1.3-10.6-0.6c-0.9-0.5-2,0.1-2.8-0.6
		c0.4-0.4,0.9-0.4,1.4-0.4c5.6,0.5,11.1-0.1,16.5-1.8c0.4-0.1,0.8-0.2,1.1-0.1c0.5,0.2,0.5,0.4,0.2,0.9c-0.4,0.5-0.6,0.7-1.4,1
		c1.8-1.2,4.5-1.1,6.7-2.3c1.1-0.6,2.1-1.4,3.2-1.9c0.5-0.2,1-0.7,1.4,0.1c-2.2,2.2-5,3.5-7.9,4.4
		C370.1,1016.1,368.5,1017.2,366.5,1017.2z"
      />
      <path
        class="st1628"
        d="M379.8,1011.2c-1.4-0.2-2.4,0.8-3.3,1.4c-1.5,1.1-3.2,1.8-5,2.1c-1.1,0.2-2.1,0.5-3,1.2
		c-0.3,0.2-0.6,0.6-1,0.1c-0.1-0.2-0.4-0.5-0.3-0.7c0.1-0.4,0.5-0.3,0.9-0.3c1,0,1.5-0.5,1.9-1.3c0-0.1,0-0.3,0.1-0.3
		c2.5-1.2,4.9-2.7,7.5-3.6c1.9-1.2,3.7-2.4,5.3-4c0.7,0.1,1.2-0.1,1.8-0.6c0.4-0.5,0.8-1.1,1.6-0.8c-1.3,1.7-2.4,3.6-4.4,4.8
		c-0.7,0.4-1.2,1.3-1.8,2C380,1011.2,379.9,1011.2,379.8,1011.2z"
      />
      <path
        class="st1629"
        d="M333.2,1009.5c-0.1,0-0.2,0-0.4,0c-1.5-0.6-2,1.1-3.1,1.4c-1.6,0-3,0.5-4.5,1c-1.7,0.6-3.7,1-5.6,0.3
		c0.1-0.7,0.7-0.6,1.1-0.7c0.3,0,0.6-0.1,1-0.2c3.4-0.5,6.6-1.4,9.3-3.8c0.8-0.6,1.4-1.6,2.6-1.8
		C335,1007.3,333.8,1008.4,333.2,1009.5z"
      />
      <path
        class="st1630"
        d="M321.8,1011.7c-0.2,0.1-0.5,0.2-0.7,0.2c-1.1-0.5-2.2,0.1-3.4,0c-1.7-0.1-3.4-0.9-5.1-0.7
		c-3.9-1.2-7.4-2.9-9.7-6.5c-1.1-1.7-1.6-3.6-2-5.5c0-2.3,0.3-4.5,1.6-6.5c0.2-0.3,0.2-0.8,0.3-1.2c0.3-0.2,0.6-0.4,1-0.6
		c0.8-0.9,1-2.6,2.9-2.2c0.3,0.9,0.1,2,0.5,2.9c0.7,1.7-0.5,3.1-0.8,4.7c-0.2,1.1-0.4,2.1-0.2,3.2c0.6,4.1,4.7,7.3,9.4,8
		c0.6,0.1,1.3,0.1,2,0.3c1.9,0.3,3.8,0.9,5.8,0.2c0.7-0.2,1.5-0.4,1.8,0.7c0.2,1-0.1,1.6-1,2C323.2,1011,322.3,1011,321.8,1011.7z"
      />
      <path
        class="st1631"
        d="M256.6,992c-2,0.2-3.2,1.7-4.6,2.8c-4.2,3.6-8.3,7.4-12.6,10.8c-2.4,1.9-5,3.8-7.9,5.1
		c-0.4,0.2-0.7,0.2-1.1,0.2c0.4-0.4,1-0.4,1.2-1.1c-0.7-0.5-1.8,0-2.4-0.9c0.1-1,1-0.9,1.5-1.4c0.2-0.2,0.5-0.3,0.8-0.5
		c1-0.7,2.1-1.3,3-2c0.1-0.1,0.2-0.2,0.4-0.3c0.9-0.5,1.5-1.2,2.3-1.8c5.7-5,11.5-9.9,17.2-14.9c0.6-0.4,1-1.2,2-1.1
		C254.9,988.7,256.5,990.3,256.6,992z"
      />
      <path
        class="st1632"
        d="M292.3,982.8c0,2.1-0.7,3.9-2.2,5.3c-0.7,0.7-0.3,1.8-1.4,2.5c-2.2,1.4-4.2,3-6.9,3.3
		c-1,0.2-1.5,1.2-2.4,1.2c-2-0.2-4-0.5-6-1c-0.5-0.1-1-0.3-1-0.9c0-0.6,0.6-0.6,1.1-0.8c0.6-0.3,1.8-0.2,1.7-0.9
		c-0.1-1-1.4-0.7-2.1-0.7c-1.3,0-2.7,0.6-3.5-1c0.5-0.5,1.1-0.3,1.6-0.3c3.9,0.3,7.7,0.4,11.4-1.1c1.2,0,2.5,0.1,3.7-0.5
		c0.5-0.3,1-0.5,1.4-1.1c0.2-0.5,0.6-0.9,1.2-0.7c1.4,0.5,1.5-0.5,1.8-1.4C291,983.9,291,982.8,292.3,982.8z"
      />
      <path
        class="st1633"
        d="M292.3,982.8c-1.2,0.5-1,1.8-1.4,2.8s-0.6,1.9-1.9,0.9c-0.3-0.3-0.6-0.2-0.8,0.2c-1.1,2.3-3.2,2.3-5.2,2.1
		c-0.1-0.5,0.2-0.6,0.5-0.7c2.1-0.8,3.6-2.4,5.2-3.8c0.6-0.5,1.1-1.2,1.1-2.1c0.7-1.2,1.2-2.5,2.1-3.5c0.5-0.2,1,0,1.4,0.3
		c4.4,2.7,8.7,5.4,13.1,8.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.5,0.1,0.9-0.4,1.2c-1.3,0.1-1.7,1.5-2.7,2c1-1.7,0.3-2.5-1.4-3.3
		c-3-1.5-6-3.2-8.5-5.4C293,981.6,292.4,981.8,292.3,982.8z"
      />
      <path
        class="st1634"
        d="M256.2,986.9c-0.6,0.4-1.1,0.8-1.7,1.2c-0.4-0.8,0.1-1.3,0.4-2c0.3-0.9,0.4-1.6-0.9-1.3
		c-1.1,0.3-1.5-0.3-1.6-1.2c-0.2-1.7-0.3-3.3,1.5-4.3c0.7-0.4,0.6-1.2,1-1.8c0.1-0.3,0.3-0.7,0.5-1c1.1-2.1,1.1-2.1-0.9-3.2
		c-0.5-0.3-1.2-0.5-0.8-1.3c0.8-0.4,1.4,0,2.1,0.2c0.6,0.2,0.9,0,1.1-0.5c0.1-0.3,0.3-0.9,0.9-0.4c0.4,1.5-0.1,2.9-0.2,4.3
		c-0.7,1.5-0.4,3.2-1,4.7C256.6,982.5,256.4,984.7,256.2,986.9z"
      />
      <path
        class="st1635"
        d="M256.7,980.3c-0.1-1.6-0.2-3.1,0.6-4.6c0.1,0.1,0.2,0.2,0.3,0.4c0.2,3,1.3,5.6,3.5,7.7
		c0.7,0.6,1.4,1,2.3,1.3c1.7,1.5,4,1.6,5.9,2.7c0.8,0.5,2.2,0.6,2,2.1c-0.5,0-1.1-0.1-1.6-0.1c-0.9-0.7-2-0.7-3.1-1
		c-2.2-0.7-4.1-2.1-5.9-3.5c-1.4-1.1-2.2-2.8-2.9-4.5C257.7,980.2,257.5,979.6,256.7,980.3z"
      />
      <path
        class="st1636"
        d="M358.8,1022.6c2.1-1.4,4.1-3.1,6.9-2.5c0.2,0.1,0.6,0.1,0.8-0.1c2.1-1.7,5-1.7,7.3-3.1
		c0.2-0.1,0.4-0.2,0.6-0.2c0.6-0.2,1.5-0.5,1.8-0.1c0.5,0.6-0.5,0.9-0.7,1.4c-0.4,1.4-0.8,2.7-0.8,4.2c-1.4,3.6-2.8,7.2-4.7,10.6
		c-1.5,1.5-2.3,3.4-3,5.4c-0.6,1.7-1.3,3.2-3,4.1c-0.5,0.3-0.5,0.8-0.6,1.3c-0.1,0.8-0.1,1.5-0.3,2.3c-0.6,1.5-1.1,1.8-2.7,1.6
		c-1.1-0.1-2.7,1.1-3,2.4c-0.2,1.1-0.6,1.9-1.3,2.8c-0.6,0.8-1.1,1.7-0.1,2.6c0.4,0.4,0.5,1,0.2,1.5c-0.5,0.8-0.8,1.7-1.1,2.6
		c-0.2,0.7-0.8,1.1-1.5,0.9c-0.9-0.3-0.6-1-0.5-1.7c0.1-0.5,0.5-1,0.6-1.5s0-0.9-0.5-1s-1,0.2-0.9,0.7c0.3,1.8-0.8,1.8-2,1.7
		c-0.7-0.1-1.3,0.3-1.9,0.6c-0.8,0.3-1.6,0.6-2.3-0.1c-0.1-0.4,0.2-0.6,0.4-0.8c4.3-2.7,7-6.8,9.3-11.1c3.2-6,5.8-12.2,7.8-18.6
		c0.2-0.8,0.5-1.6,0.5-2.4c0.1-1.9-0.7-2.8-2.6-3.1C360.6,1022.8,359.6,1023.3,358.8,1022.6z"
      />
      <path
        class="st1637"
        d="M346,1058.8c1,0.3,2-0.1,2.9-0.5c0.8-0.3,1.5-0.7,2.4-0.3c0.8,0.4,1-0.2,0.7-0.8c-0.4-0.6-0.4-1.1,0.3-1.5
		c0.6-0.3,1.3-0.7,1.8-0.2c0.5,0.4,0.6,1.1,0.1,1.8c-0.3,0.4-0.4,0.9-0.6,1.3s-0.4,0.9,0.1,1.2c0.6,0.3,1.1-0.2,1.2-0.6
		c0.2-1.1,0.6-2.1,1.3-3c0.2-0.3,0.1-0.7-0.2-0.9c-2.1-1.3-0.4-2.5,0.2-3.5c0.5-0.7,0.8-1.3,0.9-2.2c0.2-1.8,2.3-3.2,4-2.6
		c0.6,0.2,0.9-0.1,1.2-0.5c0.9-1.1,0.8-2.3,0.9-3.6c0-0.9,0.1-1.2,1.3-1.5c0.9-0.2,1.8-1.5,2-2.6c0.4-1.9,1.7-3.3,2.1-5.1
		c0.2-0.8,1-0.9,1.7-1.2c0.1,1.4-0.8,2.4-1.4,3.5c-3.1,6-5.9,12.1-9.4,17.9c-1.9,3.1-3.9,6-6.3,8.5s-5.3,4.3-8.9,4.7
		c-0.4-0.4-0.7-0.8-0.4-1.3c0.9-1.2,0-1.9-0.6-2.7c-1.5-1.8-1.5-2.1,0-3.9C344.1,1059,344.9,1058.3,346,1058.8z"
      />
      <path
        class="st1638"
        d="M343.3,1059.6c-1.1,1.3-1.2,2.4,0.3,3.6c0.7,0.6,2.2,1.7,0.4,2.9c-0.1,0.1,0.1,0.7,0.1,1.1
		c-0.5,0.4-1.2,0.1-1.7,0.6c-1-0.1-2-0.1-3-0.2c-0.8-0.5-1.6-0.7-2.5-0.6c-1,0.1-2-0.2-2.8-0.8c-1.1-0.7-2.1-1.6-3.5-2
		c-0.9-0.3-1.2-2.2-0.6-3.7c0.4-1.2,1.3-1.2,2.3-0.9c0.4,0.1,0.7,0.3,1.4,0.2c-1.6-0.6-2.7-1.6-3.6-2.7c-0.5-0.6-1-1.2-0.4-2
		c0.1,0,0.3,0,0.4,0c0.4,0,0.6,0.2,0.9,0.4c2.6,3.2,6.2,4.1,10.1,3.9C341.8,1059.3,342.6,1059,343.3,1059.6z"
      />
      <path
        class="st1639"
        d="M386.3,1004.3c-0.8,0.3-1.3,0.9-1.8,1.5c-0.6,0.9-1.1,0.6-1.6-0.1c2.7-2.9,4.8-6.2,6.3-9.8
		c0.2,0,0.4,0,0.6,0C389.1,999,388,1001.8,386.3,1004.3z"
      />
      <path
        class="st1640"
        d="M343.3,1059.6c-4,0.6-7.8,0.1-11.2-2.3c-0.9-0.6-1.2-1.6-2-2.3c-1.4-3.2-0.6-6.5-0.2-9.7s1-6.3,1.5-9.4
		c0.6-3.6,1-7.3,2.1-10.8c0.8-2.8,3.4-4.2,6.2-3.4c3.2,0.9,6.5,1.5,9.9,1.6c3.1,0.1,6.2-0.3,9.3-0.7c1.1,0,2.2-0.2,3.3-0.1
		c1.6,0.2,2.5,1.3,2.5,2.9c-0.1,2.3-1,4.5-1.8,6.6c-2.6,7.3-5.7,14.4-10.1,20.8c-1.7,2.5-4,4.5-6.7,6
		C345.1,1059,344.2,1059.3,343.3,1059.6z"
      />
      <path
        class="st1641"
        d="M329.7,1055c0.4,2.2,2,3.3,3.9,4.2c0.4,0.2,1.1,0.2,0.9,0.9c-0.2,0.5-0.8,0.5-1.3,0.2
		c-0.4-0.2-0.9-0.4-1.4-0.5c-0.8-0.2-1.4,0.1-1.5,1.1c0,0.7-0.1,1.3-0.1,2s0.3,1.1,1,1.2c0.1,0,0.2,0,0.3,0c2.2,1.4,4.5,2.9,7.3,2.8
		c0.2,0,0.4,0.4,0.6,0.7c-3.2,0.1-6.1-0.9-8.8-2.7c-1.2-0.8-2.1-1.9-2.6-3.3c-0.6-1.6-1.1-3.3-1.7-4.9c0.1-1.3,0.2-2.6,0.3-3.9
		c0-0.7,0.3-1.4,1-1.4c0.8,0,1.3,0.5,1.3,1.4C328.8,1053.6,329.1,1054.4,329.7,1055z"
      />
      <path
        class="st1642"
        d="M306.5,987.4c-1.9-0.7-3.5-2-5.2-3c-3.1-1.8-6.1-4-9.4-5.7c-0.4-0.4-0.3-0.9-0.1-1.4
		c0.8-1.7,0.5-3.5,0.3-5.3c-0.2-1.4-0.4-2.7-1.2-3.9c-0.3-0.4-0.5-0.9-0.3-1.4c0.3-0.7,1-1,1.5-1.5c5.3-3.6,9.5-8.1,11.7-14.3
		c0.5-1.5,0.7-3.1,1-4.6c0.2-1.9,0.1-3.7-0.2-5.5c0.7-1.3,1.7-1.1,2.7-0.5c8.1,5.1,16.1,10.2,24.2,15.4c0.4,0.3,0.8,0.6,1.1,0.9
		c0.5,1,1.6,1.1,2.4,1.8c0.6,0.8,0.6,1.7,0.7,2.5c0.1,1.1,0.5,1.6,1.7,1.5c2.4-0.1,2.8,0.4,2.6,2.8c-0.2,2.6,0.9,4.1,3.5,4.7
		c0.6,0.1,1.5,0,1.4,1c-0.1,0.9-0.6,1.5-1.6,1.7c-1.8,0.2-3.3,1-4.6,2.2c-0.5,0.4-1,0.8-1.6,0.5c-0.6-0.4-0.4-1.1-0.1-1.8
		c-0.8,0.6-1.6,0.8-2.5,0.7c-0.3-0.1-0.7-0.1-0.8,0.3c-0.1,0.4,0.1,0.7,0.4,0.8c0.8,0.3,1.2,0.8,1.1,1.6c0,0.4-0.2,0.8-0.5,1
		c-2.2,1.6-3.5,3.9-5.2,5.9c-0.4,0.5-0.9,0.7-1.4,0.3c-1.2-1.1-2.8-1.9-2.9-3.9c-0.8,0.2-1.5,0.6-2.3,0.5c-0.4-0.1-0.9-0.1-1.1-0.6
		s0.1-0.8,0.4-1.2c0.1-0.1,0.2-0.1,0.2-0.2c0.6-0.6,1.6-0.8,1.5-2c-0.2-1.4-0.4-2.8-1.7-3.6c-1.5-1-3-1.9-4.5-2.7
		c-1.1-0.6-2.6-1-3.5,0.1c-0.8,0.9-1.7,2-0.9,3.4c1.3,1.6,3.2,2.3,4.9,3.3c1.4,1.4,1.3,1.6-0.5,2.1c-1.2,0.4-2.6,0.2-3.7,1
		c-1.4,0.7-2.9,1.2-4.1,2.2c-0.9,0.8-1.6,1.6-2.1,2.6C307.2,985.8,307.2,986.8,306.5,987.4z"
      />
      <path
        class="st1643"
        d="M334.8,976.9c-0.2-0.4,0-1.1-0.9-1.1c-1,0-1-0.8-0.9-1.6c0.1-1.1,0.8-0.6,1.3-0.4c0.8,0.3,1.5,0,2.2-0.5
		c0.4-0.3,0.7-0.8,1.4-0.4c-0.4,0.7-1.4,1.1-0.8,2.2c0.7-0.3,1.7-0.3,1.4-1.5c1.8,0.3,2.8-1.7,4.5-1.4c0.6,0.1,0.9,0,1.2-0.5
		c0.4-0.7,0.6-1-0.5-1.2c-3.3-0.4-4.7-2.4-4.1-5.7c0.2-1.3-0.4-2-1.7-2c-2.4,0.1-2.4,0.1-2.7-2.2c-0.1-0.7-0.3-1.4-0.5-2.1
		c0.8-0.4,1.1,0.5,1.6,0.8s1.1,0.5,1.6,0.8l0,0c0.4,0.8,1,1.3,1.8,1.6c1.4,0.3,2.3,1.5,3.5,2.1c0.8,0.4,1.4,1.5,0.7,2.7
		c-0.4,0.4-0.9,0.2-1.3,0.4c1.3-0.1,2.5-1.1,3.9-0.8c0.4,0,0.8,0.1,1.1-0.2c3.7-0.3,7.5-0.7,11.2-0.3c6.2,0.8,11.9,2.7,17.2,5.9
		c0.7,0.4,1.2,0.9,1.8,1.4c2.1,1.3,3.6,3.3,5.5,5c0.5,0.6,1.1,1.2,1.6,1.9c1,2.2,2.3,4.2,2.9,6.6c1.1,4.6,0.4,8.9-1.8,13
		c-2,3.8-5.1,6.6-8.4,9.3c-1.6,1.8-3.9,2.7-6.1,3.6c-6.6,2.8-13.6,4-20.8,3.2c-0.8-0.1-1.6-0.2-2.5-0.1c-2.4,0.1-4.5-0.8-6.6-1.5
		c-3-1-5.9-2.3-8.5-4.3c0.2-0.7,0.2-1.4,0.5-2s0.3-1.1-0.3-1.6c0.2-1.3,1.3-2,2.2-2.7c0.8-0.1,1.4,0.2,2,0.5
		c2.6,1.3,5.3,2.5,8.1,3.5c4.5,1,8.9,1.6,13.5,1.1c7-0.9,13-3.6,17.6-9.2c5.3-7.8,2.8-16.5-4.3-21.7c-2.3-1.7-4.8-3.1-7.6-3.9
		c-4.5-1.7-9.2-2-13.9-1.5c-4.1,0.2-8,1.3-11.7,3.2c-0.7,0.4-1.4,0.8-2.2,1C336.3,976.4,335.7,977.1,334.8,976.9z"
      />
      <path
        class="st1644"
        d="M295.3,944c2.1,0.1,2.3,0.2,1.7,1.8c-0.5,1.4,0.3,2,1.2,2.5c1,0.6,1.3,1.2,0.7,2.2c-0.2,0.3-0.2,0.6-0.2,1
		c-0.1,0.5,0.2,0.8,0.6,1.1c0.5,0.4,0.8,0,1.1-0.4c0.4-0.4,0.9-0.7,0.9-1.3c-0.1-1.2,0.5-2.1,0.9-3.1c0.8-2,1.1-4,0.2-6.2
		c-0.3-0.8,0-1.7,0.1-2.6c1.5,4.1,1.1,8.2-0.4,12.2c-0.1,0.2-0.2,0.5-0.2,0.8c-1.2,4.1-3.9,7.1-7.2,9.6c-0.6,0.5-1.3,0.8-1.9,1.2
		c-1.1,0.6-2,1.5-3.3,1.9c-0.5,0.1-0.9,0.2-1.4,0.4c-0.7,0.3-1,0-1.2-0.7c-0.1,0.6,0,1.4-1,1.3c-0.4-1.7-0.1-3.5-0.9-5.1
		c-0.4-0.8,0.5-1.2,1.1-1.6c2.7-1.7,5-3.7,6.6-6.5c1.3-2.2,2.1-4.5,2.1-7.1C294.8,944.9,294.6,944.3,295.3,944z"
      />
      <path
        class="st1645"
        d="M304.8,946.5c-0.2,6-3,10.7-7.1,14.9c-2.1,2.1-4.5,3.8-6.9,5.5c-0.7,0-1-0.5-1-1.1c0.8-1.4,2.5-1.6,3.5-2.7
		c4.4-2.8,7.6-6.7,9.9-11.3C304.2,950.2,304.1,948.2,304.8,946.5z"
      />
      <path
        class="st1646"
        d="M303.1,951.8c-0.5,2.5-2.1,4.5-3.6,6.4c-1.7,2.1-3.8,3.8-6.1,5.2c-0.6,0-1.1,0-1-0.8
		c4.2-2.5,7.1-6.3,9.3-10.6C302,952.5,302,952.5,303.1,951.8z"
      />
      <path
        class="st1647"
        d="M304.5,927.4c1.9,0.5,1.9,2.3,2.4,3.7c-0.5-0.4-1-0.7-1.3,0.2c-0.4,1.2-1.5,2-1.6,3.2
		c-0.1,0.7-1.1,0.3-1.1,1c-0.8-0.7-0.6-1.3-0.1-2.1C304.1,931.7,305,929.8,304.5,927.4z"
      />
      <path
        class="st1648"
        d="M343.9,966.2c0.4-0.9-0.5-2-0.6-2c-1.4-0.2-1.8-1.7-3.1-2c-0.9-0.2-2.5-0.3-2.1-2.1c2.9,1.4,5.3,3.4,8,5
		c0.1,0.1,0.1,0.4,0.2,0.6C345.4,966,344.8,966.6,343.9,966.2z"
      />
      <path
        class="st1649"
        d="M281.8,919.4c5.4,1.5,10.2,4.2,14.6,7.5c0.4,0.3,0.8,0.7,1,1.3c-4.1-3.1-8.6-5.7-13.4-7.6
		c-0.8-0.3-1.6-0.6-2.4-1v-0.1C281.7,919.5,281.8,919.5,281.8,919.4z"
      />
      <path
        class="st1648"
        d="M338,960.2c-1.4,0.2-2.2-1-3.2-1.5c-0.9-0.5-2.1-0.5-2.2-1.9C334.5,957.8,336.3,958.8,338,960.2z"
      />
      <path
        class="st1650"
        d="M307.3,940.5c-1-0.3-1.9-0.2-2.7,0.5c-0.2-0.8-0.3-1.6-0.5-2.6C305.2,939.1,306.2,939.8,307.3,940.5z"
      />
      <path
        class="st1651"
        d="M343.9,966.2c0.8,0,1.5-0.6,2.3-0.5c0.1,0.1,0.2,0.2,0.3,0.4c-1.5,0.5-3,0.9-4.8,1
		C342.3,966.2,343.3,966.9,343.9,966.2z"
      />
      <path
        class="st1652"
        d="M297.4,928.3c1.5,0.5,2.3,1.7,2.8,3.2C299.3,930.4,298.2,929.5,297.4,928.3z"
      />
      <path
        class="st1653"
        d="M312.6,1011.3c0.5-0.4,1-0.2,1.6-0.1c2,0.3,4,0.9,6.1,0.4c0.3-0.1,0.6,0,0.9,0.3c-0.5,0.1-1-0.2-1.4,0.3
		C317.3,1012.2,314.9,1012,312.6,1011.3z"
      />
      <path
        class="st1654"
        d="M368.1,966.5c-0.8-0.2-1.5-0.5-2.3-0.8c0.6-1.5,2.1-1.7,3.3-1.9c3.3-0.7,6.6-0.3,9.6,1.5
		c1.4,1.2,2.8,2.2,4.5,2.9l0,0c0.7,0.8,1.4,1.5,2.2,2.2c1.6,1.6,1.8,3.6,1.4,5.8c-0.8,0.3-0.6,1-0.8,1.6c-0.5,0.3-0.6,0-0.9-0.3
		c-1.2-1.1-2.1-2.7-3.5-3.6c-3.8-3.5-8.3-5.8-13.1-7.4C368.4,966.4,368.2,966.5,368.1,966.5z"
      />
      <path
        class="st1655"
        d="M348.3,1015.3c0.6-0.6,1.3-0.3,1.9-0.2c9.7,0.8,18.7-1.1,27-6.3c0.1-0.1,0.3-0.1,0.5-0.1c0,0.4,0,0.7,0,1.1
		c-2.3,1.8-5,2.7-7.6,3.9c-3.4,0.9-6.7,1.8-10.2,2.2c-3.1,0.3-6.1,0.1-9.1,0C349.9,1015.5,349,1015.8,348.3,1015.3z"
      />
      <path
        class="st1656"
        d="M381.7,973.8c1.7,0.6,2.3,2.5,3.7,3.5c0.2,0.2,0.4,0.4,0.6,0.4c-0.2,0.3,0.5,1.3-0.7,0.9
		c-0.9-2-2.7-3.2-4-4.9C381.5,973.7,381.6,973.7,381.7,973.8z"
      />
      <path
        class="st1657"
        d="M231,1007.7c-0.6,0.4-1.2,0.8-1.7,1.2c-2,0.3-3.9,1.2-6,0.9c0.1-0.7-0.6-0.9-0.8-1.5c1.6,0,2-0.5,0.9-1.8
		c-0.4-0.5-0.5-1.5-0.4-2.2c0.2-1.6-0.5-2.2-2-2.2c-0.2,0-0.4-0.2-0.6-0.3c1.2-0.9,2.4-0.2,3.7-0.3c1.5,0.2,2.9-0.1,4.4-0.1
		c1,0,1.2,0.2,1.1,1.3C229.4,1004.4,230.1,1006.1,231,1007.7z"
      />
      <path
        class="st1658"
        d="M383.2,968.3c-2-0.2-3.4-1.3-4.5-2.9C380.4,966.1,381.9,966.9,383.2,968.3z"
      />
      <path
        class="st1658"
        d="M385.4,970.5c-1.1-0.3-1.9-1.1-2.2-2.2C384.1,968.8,384.9,969.5,385.4,970.5z"
      />
      <path
        class="st1659"
        d="M381.7,973.8h-0.1h-0.1c-4-3.2-8.5-5.6-13.4-7.3c0.2-0.2,0.5-0.3,0.8-0.2c4.5,1.4,8.5,3.7,12.2,6.6
		C381.4,973.2,381.5,973.5,381.7,973.8z"
      />
      <path
        class="st1660"
        d="M383.3,977.9c-0.2,0-0.5,0-0.6-0.2c-1.6-1.6-3.2-3.2-4.9-4.8C380.1,974.1,381.9,975.8,383.3,977.9z"
      />
      <path
        class="st1661"
        d="M257.4,976.1c0-0.1,0-0.2,0-0.3c0.1-1.5,0.2-3.1,0.3-4.6c0.1-1-0.7-1.6-1.2-2.4c0.6-0.5,1.9,0,1.9-0.5
		c0-1.2,1.1-1.9,1.1-3.1c0-0.4,0-0.9,0.1-1.3c0.1-0.3,0.2-0.6,0.6-0.6c0.3,0,0.4,0.3,0.5,0.6c0.4,1-0.3,1.8-0.3,2.7
		c0,0.8-0.6,2.1,0.1,2.2c1.1,0.2,0.7-1.2,0.9-1.9c0.3-1.2,0-2.6,1.5-3.3c0.4,0.4,0.5,0.8,0.5,1.4c0,0.2,0,0,0,0.2
		c0,3-0.8,5.8-1.5,8.6c-0.3,1.3,0.2,2.9,1,4.2c0.1,0.1,0.2,0.3,0.2,0.4c0.4,1.1,1,2,2.2,2.3c1.3,0.7,2.5,1.4,3.7,2.1
		c0.1,0.1,0.3,0.2,0.4,0.4c1.5,1,3.2,0.8,4.8,1c1.2,0.1,2.5,0,3.8,0.5c0.4,0.2,0.8,0.4,0.9,0.9c0,0.5-0.4,0.9-0.8,0.9
		c-4.6,0.1-9.2,0.8-13.6-1.3c-1.3-0.6-2.4-1.8-3.6-2.5c-2.3-1.2-2.5-3.6-3.1-5.7C257.4,976.6,257.6,976.3,257.4,976.1z"
      />
      <path
        class="st1662"
        d="M256.5,968.8c1.3,0.3,2.1,1,1.2,2.4c-0.3,0.2-0.4,0.5-0.4,0.8c0,1.2-0.5,1.2-1.4,0.7
		c-1.1-1.4-2.7-1.5-4.2-2.2c-4.4-1.8-8.6-3.9-12.2-7c-3.6-3-5.3-7-6.4-11.4c-0.1-0.4-0.4-1.2,0.5-1.2c0.7,1.2,1.4,2.4,1.5,3.9
		c0.1,2,2.9,4.8,4.8,5.2c4.4,4.1,9.6,6.8,15.3,8.5C255.6,968.7,256.1,968.7,256.5,968.8z"
      />
      <path
        class="st1663"
        d="M243.2,950.3c-1.8-3.1-1.9-6.4-1.1-9.7c1.8-7.7,7.4-11.5,14.4-13.9c2.6-0.9,5.2-1.6,8-1.5
		c4.2-0.2,8.4,0.1,12.6,1.2c0.4,0.1,0.7,0.2,1,0.5c0.1,0.3-0.1,0.5-0.4,0.7c-1.5,0.2-2.8-0.7-4.3-0.6c-8-1.1-15.6-0.2-22.6,4
		c-0.6,0.4-1.1,0.9-1.7,1.3c-3.4,2.5-5.5,5.7-6,9.9c-0.2,1.9,0.1,3.7,0.2,5.5C243.1,948.6,244.1,949.5,243.2,950.3z"
      />
      <path
        class="st1664"
        d="M243.2,950.3c0.4-0.8-0.8-1.6-0.1-2.4c1.1,0.7,1.4,2,2.1,3.1c2.8,4.5,6.9,7.2,11.9,8.8
		c1.6,0.5,3.2,0.6,4.7,1.4c0.3,0.7,1.7,0.2,1.5,1.5c-3.2-0.2-6.1-1.5-9.2-2.1c-1.3-0.3-2.5-1.4-3.7-2.3c-0.9-0.6-2.1-0.7-2.6-1.8
		c-1.1-0.7-1.8-1.8-2.6-2.8C244,952.8,244,951.4,243.2,950.3z"
      />
      <path
        class="st1665"
        d="M295.3,944c-0.4,1.7-0.2,3.5-0.8,5.2c-1.5,4.5-4.5,7.7-8.4,10.2c-0.9,0.5-1.3,0.9-0.6,2
		c0.8,1.3,0.4,2.9,0.5,4.4c0.5,1,0.3,2,0.1,3c-1,0.5-1-0.4-1.1-1c-0.3-1.1,0.2-2.3-0.5-3.4c-0.2-1.2-0.6-2.3-0.9-3.4
		c0.1-0.6,0.1-1.2,0.6-1.8c2.6-1.8,5.3-3.6,7.2-6.3c1.7-2.5,2.6-5.1,2.6-8.1c0-0.3,0.1-0.7,0.4-0.9c0.3-0.1,0.5-0.1,0.7,0.1
		C295.2,944,295.3,944,295.3,944z"
      />
      <path
        class="st1666"
        d="M263.2,962.7c-0.1-0.9-1.3-0.4-1.5-1.2c0.8-0.5,1.7-0.8,2.5-1.3c0.6,0.2,0.7,0.7,0.9,1.2
		c0.1,1.8-0.4,3.7,0.9,5.3c0.2,0.6,0.9,1,0.6,1.8c-2.8,1.5-3.4,3.9-3.2,6.8c-0.6,1.1,0.4,2,0.5,3.1c0,0.2-0.1,0.3-0.2,0.4
		c-0.4,0.2-0.6-0.1-0.7-0.4l0,0c-1.4-2.2-1.4-4.5-0.6-6.8c0.6-1.8,1-3.5,0.7-5.4c-0.1-0.4,0-0.9,0.1-1.3c0-0.5-0.1-0.9-0.1-1.4
		C263,963.3,263.1,963,263.2,962.7z"
      />
      <path
        class="st1667"
        d="M295.1,943.9c-0.2,0-0.4,0-0.6,0c-0.5-0.2-0.5-0.7-0.5-1.1c-1-5-4-8.5-8.1-11.3c-0.5-0.3-1-0.5-1.2-1.1
		c0-0.6,0.4-0.7,0.8-0.7c4.9,2.8,8.5,6.7,9.5,12.5C295.1,942.7,295.1,943.3,295.1,943.9z"
      />
      <path
        class="st1668"
        d="M285.5,929.7c-0.2,0.2-0.5,0.4-0.7,0.6c-0.8,0.3-1.3-0.2-1.7-0.8c-1.1-0.9-2.6-1.1-3.9-1.7
		c-0.4-0.2-0.9-0.2-1.1-0.6c-0.6-0.8-1.6-0.7-2.4-0.8c-3.7-0.8-7.4-0.8-11.1-1.1c5-0.8,9.9,0,14.6,1.5
		C281.4,927.4,283.5,928.3,285.5,929.7z"
      />
      <path
        class="st1669"
        d="M245,953.8c0.9,0.9,1.8,1.8,2.6,2.8C246.2,956.2,245.5,955,245,953.8z"
      />
      <path
        class="st1640"
        d="M224.4,1001.9c-1.3-0.1-2.6-0.1-3.9-0.2c-1.4-0.5-2.8-0.8-4.1-1.6c-2.9-1.7-4.2-4.3-3.9-7.6
		c0.7-4.2,3-7.6,5.2-11c3.7-5.6,7.9-10.8,12.3-15.9c1.2-1.4,2.6-2.6,4-3.9c0.3-0.3,0.7-0.8,1.2-0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c1.8,6,6.5,9.4,11.6,12.4c0.9,0.5,1.8,1.1,2.7,1.6c1.9,1.1,2.9,2.7,2.3,4.9c-0.2,0.7-0.6,1.2-1.1,1.6c-5.9,5.7-12.1,11-18.3,16.2
		C230.3,1000.5,227.6,1001.9,224.4,1001.9z"
      />
      <path
        class="st1670"
        d="M235.2,961.6c0-0.1,0-0.2,0-0.3c-0.3-0.9-0.5-1.8-0.8-2.7c0.1,0,0.2,0,0.3,0c0.6-0.2,0.8,0.2,1.1,0.6
		c1.2,1.7,2.4,3.5,4,5c0.2,0.2,0.3,0.5,0.2,0.8c-0.9,0.5-1.4,0.1-2-0.6C237.2,963.4,236.2,962.5,235.2,961.6z"
      />
      <path
        class="st1671"
        d="M239.9,965c-2-1.8-3.3-4.3-5.1-6.3c-0.4-2.1-1.7-4-1.9-6.1c-0.1-0.8-0.3-1.5-0.1-2.2
		c0.1-0.1,0.4-0.4,0.4-0.4c0.4,0.3,0.4,0.7,0.4,1.1c-1.1,1.2,0,2.4,0.4,3.3c1.9,5.3,5.3,9.5,10.5,12.2c3.5,1.8,6.9,3.6,10.7,4.8
		c0.8,0.2,0.9,0.7,0.8,1.4c-0.8-0.2-1.5-0.3-2.3-0.5c-1.2,0.3-2.2-0.4-3.3-0.8c-3.4-1.2-6.5-2.9-9.4-5.1
		C240.5,966,240,965.6,239.9,965z"
      />
      <path
        class="st1653"
        d="M321.8,1011.7c-0.3-0.7,0.1-0.9,0.6-1c0.4-0.1,0.8-0.1,1.1-0.2c0.9-0.3,1.4-0.8,1.1-1.8s-1.2-0.4-1.5-0.3
		c-2,0.9-3.8,0.1-5.7-0.2c0.5-0.7,1.3-0.4,1.9-0.4c3.5-0.1,6.6-1.3,9-3.9c0.4-0.5,0.9-1,1.3-1.5c2.1-3,1.4-5.9-0.2-8.8
		c-0.5-1-0.8-1.9-0.4-3c0.4-1,0.1-2.1,0.6-3.1c0.5-0.5,1-0.2,1.5,0c3.2,1.9,6.9,6.8,5.9,11.7c-1.1,3.4-3.2,6.1-5.6,8.6
		C328.6,1010.4,325.4,1011.4,321.8,1011.7z"
      />
      <path
        class="st1672"
        d="M331.4,1007.9c0.1-1.2,1.3-1.5,1.8-2.4c1.2-2,2.6-4,3.4-6.3c0.5,0.7,0.3,1.4,0.2,2.1
		c-0.3,0.7-0.5,1.5-1.1,2.1c-0.7,0.9-1.5,1.7-2.2,2.6C332.8,1006.6,332.1,1007.3,331.4,1007.9z"
      />
      <path
        class="st1673"
        d="M328.7,1004c-2.5,2.9-5.7,4.1-9.4,4.2c-0.6,0-1.3,0-2,0c-3.9-0.3-7.5-1.5-10-4.7c-0.9-1.1-1.5-2.4-1.6-3.9
		c-0.1-0.7-0.3-1.4,0.3-2c0.6,0.1,0.5,0.6,0.5,1c0.1,1.4,0.6,2.6,1.2,3.8c1.5,2.1,3.5,3.5,6,4.1c1.9,0.8,3.9,0.8,6,0.8
		c0.2,0,0.3-0.1,0.5-0.1c1.1-0.1,2.2-0.3,3.1-0.8c1.5-0.7,3-1.4,4.3-2.3C327.9,1003.9,328.3,1003.6,328.7,1004z"
      />
      <path
        class="st1674"
        d="M306.5,987.4c0-1,0.4-1.8,0.9-2.5c0.4-0.1,0.7-0.7,1.1-0.1c0.1,1.6-0.5,3.2-0.2,4.8c0,1,0.9,1.7,0.9,2.7
		c-1.4,1.4-1.8-0.1-2.5-0.8c-0.1,0-0.1,0-0.2,0c-0.4-0.8-0.5-1.7-0.2-2.6C306.4,988.4,306.5,987.9,306.5,987.4z"
      />
      <path
        class="st1674"
        d="M306.2,997.7c-0.6,0.5-0.3,1.3-0.5,2c-0.7-1.7,0-3.4,0.5-4.9c0.4-1.1,0.6-2.1,0.4-3.3h0.1
		c1.1,0.2,0.8,1.2,0.9,1.8C307.8,994.9,307.3,996.4,306.2,997.7z"
      />
      <path
        class="st1675"
        d="M224.4,1001.9c4.3-0.6,7.4-3.2,10.6-5.8c5.2-4.5,10.5-9,15.5-13.8c0.5-0.5,0.9-1.1,1.4-1.6
		c0.5-1,1.5-1.7,2-2.8c0.3-0.6,0.9-0.8,1.5-0.3c-0.4,1-0.4,2-1.8,2.5c-0.9,0.3-1.2,3.6-0.6,4.4c0.3,0.4,0.7,0.2,1,0
		c0.5-0.4,0.9-0.6,1.4,0c0.4,0.5,0.6,1.1,0.2,1.7c-0.5,0.6-0.8,1.3-1,2c-1.9,2.1-4.2,3.8-6.3,5.7c-3.6,3.2-7.3,6.2-11,9.3
		c-1.1,0.2-1.6,1.2-2.4,1.8l0,0c-1,0.8-2.5,1.1-3.2,2.4c-0.2,0.1-0.5,0.2-0.7,0.3c-0.1,0-0.2,0-0.3,0c-1.5-0.6-0.6-2-1.1-3
		c-0.5-0.9-1.7-1.8,0.6-2.9C227.8,1001.8,226.1,1001.9,224.4,1001.9z"
      />
      <path
        class="st1657"
        d="M231.6,1007.4c0.3-1.7,1.8-2,3.2-2.4C234.1,1006.2,232.7,1006.6,231.6,1007.4z"
      />
      <path
        class="st1657"
        d="M234.8,1005c0.5-1.1,0.9-2.2,2.4-1.8C236.6,1003.9,235.9,1004.8,234.8,1005z"
      />
      <path
        class="st1676"
        d="M271.3,990c-0.8-1.8-2.8-2.3-4.2-2.6c-1.5-0.4-2.6-1-3.7-1.9c0.1-0.3,0.3-0.4,0.6-0.5
		c1.2,0.3,2.3,1,3.5,1.4c6.5,2.2,12.7,1.7,18.7-1.7c0.3-0.2,0.7-0.5,0.9-0.8c0.8-0.9,1.6-1.9,3-1.5c0.6,1.1-0.3,1.7-0.9,2.2
		c-1.8,1.7-3.7,3.5-6.2,4.3C279.2,990.4,275.3,990.3,271.3,990z"
      />
      <path
        class="st1677"
        d="M290,982.3c-1.1,0.3-2,1-2.8,1.8c-0.8-0.4-0.3-0.9,0-1.2c2.7-2.7,4-6,4.4-9.8c0-0.5,0-1.2,0.8-1.2
		c0.4,1.9,0.8,3.8-0.2,5.7c-0.2,0.4-0.2,0.8-0.3,1.2C291.7,980.2,290.8,981.2,290,982.3z"
      />
      <path
        class="st1678"
        d="M255.3,977.6c-0.6,0-1.2,0-1.3,0.6c-0.2,1.3-1.3,1.8-2.2,2.4c0.8-3.7-2.2-4.6-4.5-5.9
		c-3.9-2.2-7.4-4.8-10.1-8.5c-1-1.4-1.7-2.9-2-4.6c1.5,0.1,2.1,1.5,3,2.4c0.5,0.6,1,0.9,1.7,0.9c3.6,2.9,7.6,5.2,12.1,6.5
		c0.6,0.2,1.1,0.5,1.6,0.7c0.4,0.8,1.3,1.2,2,1.2c1.9,0.1,1.2,1.1,0.9,1.9C256.2,976.1,255.7,976.8,255.3,977.6z"
      />
      <path
        class="st1634"
        d="M263.7,985.2c-0.1,0.1-0.2,0.2-0.4,0.3c-3.1-0.9-4.5-3.4-5.5-6.2c-0.4-1-0.7-2.1-0.5-3.2
		c0.8,2.2,1.3,4.6,3.2,6.2C261.6,983.2,263.1,983.7,263.7,985.2z"
      />
      <path
        class="st1679"
        d="M292.4,971.9c-0.9,1.1-0.4,2.5-0.8,3.8c-0.8,2.9-2,5.6-4.3,7.6c-0.2,0.2-0.5,0.5-0.1,0.8
		c-0.1,0.3-0.4,0.5-0.7,0.6c-1.1-0.7-0.4-1.7-0.2-2.5c0.3-1.1,0.7-2.2,1.2-3.2c0.4-0.8-0.1-1-0.6-1.3c-0.3-1.2,0.4-2.2,0.4-3.3
		c0-1.9-0.8-3.7-1.3-5.5c-0.5-1-0.1-2,0-3c0.3-0.5,0.5-1,0.8-1.6c0.7,1.7,1.7-0.2,2.5,0.2c0.2,0.5,0.8,0.8,0.6,1.4
		c0.2,0.5,0.5,0.9,0.9,1.1C291.4,968.6,292.6,970,292.4,971.9z"
      />
      <path
        class="st1680"
        d="M334.8,976.9c0.7-0.3,1.3-0.9,2.1-0.8c0,0.6-0.5,1-0.9,1.3c-2.8,2.3-5,5.2-6.4,8.6c-0.8,1-1.2,0.2-1.6-0.4
		c-2.5-4-6.4-5.3-10.9-5.5c-0.8,0-1.5,0.2-2.3,0.2c-0.5,0-0.9,0.1-1.2-0.3c1.5-1.2,3.5-0.7,5.2-1.5c-0.3-0.4-0.5-0.8-0.8-1.2
		c0.1,0,0.1-0.1,0.2-0.2c0.6-0.2,1.1,0.1,1.5,0.3c0.8,0.5,1.7,1,2.6,0.4c0.8-0.6,0.6-1.6,0.5-2.4c-0.1-0.8-0.6-1.2-1.2-1.7
		c-1.6-1.1-3.4-2-5.1-3.1c-0.9-0.6-1.7-0.3-2.3,0.5c-0.2,0.5-0.4,0.9-0.6,1.4c-0.2,0.4-0.3,0.8-0.5,1.2c-0.1,0-0.1,0.1-0.2,0.1
		c-1-1.4,0.2-4.4,1.9-4.5c1.5-0.1,2.9,0,4.2,1.1c0.7,0.6,1.5,1.2,2.4,1.5c2.6,0.8,2.6,3.1,3.1,5.1c0.2,0.7-1,1.2-1.7,1.7
		c-0.4,0.3-1,0.4-0.8,1.1c0.2,0.5,0.9,0.3,1.3,0.4c0.2,0,0.4-0.1,0.6-0.2c1.1-0.6,2-1.6,1.7,1c-0.1,1,1.8,2.2,2.9,3
		c1.7-2.1,2.9-4.5,5.1-6.1C334.3,977.8,334.8,977.5,334.8,976.9z"
      />
      <path
        class="st1681"
        d="M313.7,980.1c3.5-0.7,6.9-0.5,10.2,1.2c1.9,1,3.6,2.3,4.6,4.2c0.2,0.4,0.4,1.2,1,0.3
		c0.4,0.3,0.4,0.8,0.5,1.3c0,0.2,0,0.3-0.1,0.4c-0.4,1.1,0,2.3-0.6,3.3c-0.7-0.8-0.2-2-0.9-2.8c-0.2-0.3-0.4-0.2-0.6-0.3
		c-0.1-0.1-0.3-0.2-0.3-0.4c-1-3.9-3.9-5.6-7.6-6.2c-3.6-0.8-7-0.3-9.7,2.4c-0.5,0.5-0.8,1.2-1.7,1.1c-0.4,0.1-0.7,0.2-1.1,0.3
		C308.7,982.2,311.2,981,313.7,980.1z"
      />
      <path
        class="st1682"
        d="M299.5,962.1c0.7-1.2,1.6-0.8,2.4-0.3c2,1.2,4,2.5,6,3.8c1.2,0.8,0,1.1-0.2,1.6c-0.9,0.1-1.4-0.5-2-0.9
		c-1.9-1-3.6-2.3-5.5-3.2C299.6,963.1,298.9,962.9,299.5,962.1z"
      />
      <path
        class="st1683"
        d="M305.8,970c-1-0.6-1.9-1.1-2.8-1.7c-1.2-1.2-2.8-1.8-4-2.9c-0.4-0.3-0.9-0.5-0.8-1.1s0.6-0.8,1.2-0.8
		c2.6,1.2,5.1,2.8,7.5,4.5c0.4,0.5,0.8,1-0.2,1.3C306.3,969.4,306,969.7,305.8,970z"
      />
      <path
        class="st1684"
        d="M306.6,968.3c-2.4-1.5-4.9-3-7.3-4.5c-0.7-0.5-0.7-1.1-0.1-1.7c0.1,0,0.1,0,0.2,0c0,0.5,0.4,0.5,0.7,0.7
		c2.6,1.3,5,2.8,7.4,4.5c0.1,0,0.1,0,0.2,0.1C307.8,968.2,307.5,968.7,306.6,968.3z"
      />
      <path
        class="st1685"
        d="M299.3,962.1c-0.3,0.6-0.2,1.1,0.1,1.7c-1,0.2-1.4,0.5-0.3,1.2c1.2,0.8,2.3,1.6,3.5,2.4
		c0.3,0.2,0.9,0.3,0.5,0.9c-1.4-0.4-2.5-1.3-3.7-2.1C297.4,965,297.4,963.7,299.3,962.1z"
      />
      <path
        class="st1686"
        d="M306.6,968.3c0.7,0.1,1-0.3,1.2-0.9c0.5,0.5,1.2,1.1,0.6,1.8s-1.5,1.3-2.5,0.8c-0.2-0.7-0.1-1,0.7-1.1
		C307.5,968.9,306.5,968.5,306.6,968.3z"
      />
      <path
        class="st1680"
        d="M318.2,977.4c-2-0.7-3.9-1.5-5.1-3.3l0,0C315.1,974.8,317,975.6,318.2,977.4L318.2,977.4z"
      />
      <path
        class="st1687"
        d="M377.2,999.3c-4.5,5.8-10.5,8.5-17.6,9.4c-4.8,0.6-9.4,0-14-1.1c-0.3-0.7,0.2-0.8,0.6-0.7
		c0.6,0,1.2,0.2,1.7,0c1.3,0,2.6,0.2,3.9,0.4c7.1,0.5,13.8-0.4,19.9-4.4c1.8-1.2,3-2.9,4.7-4.1C376.9,998.8,377.1,998.9,377.2,999.3
		z"
      />
      <path
        class="st1688"
        d="M377.2,999.3c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.7,0.2-1.2,0.5-1.7c3.5-6.4,1.7-12.7-3.1-17.4
		c-2.7-2.7-6.1-4.3-9.7-5.5c-0.8-0.2-1.6-0.3-2.2-1c0.3-0.9,0.9-0.6,1.5-0.5c0.5,0.1,1,0.4,1.6,0.3c6.6,2.2,12,5.9,14.2,12.8
		C381,990.8,380.2,995.3,377.2,999.3z"
      />
      <path
        class="st1689"
        d="M329.8,987.1c-0.5-0.4-0.2-0.9-0.3-1.4c1.1-3.4,3.2-6,5.8-8.3c0.5-0.5,1.1-0.9,1.6-1.4
		c3.8-2.3,7.9-3.8,12.3-4.3c0.5-0.1,1,0,1.5-0.1c1.2,0.2,2.4,0,3.6,0.1c0.2,0.2,0.4,0.3,0.4,0.7c-0.3,0.5-0.8,0.5-1.3,0.5
		c-1.8,0-3.5,0.2-5.2,0.5c-6.4,1-11.8,3.6-15.6,9c-0.7,1-1.2,2.1-1.7,3.2C330.7,986.2,330.6,986.9,329.8,987.1z"
      />
      <path
        class="st1690"
        d="M348,1007.2c-0.4,0.6-1,0.4-1.5,0.2c-0.4-0.1-0.7-0.3-0.9,0.3c-3.4-1.2-6.6-2.9-9.9-4.3
		c0-0.7,0.1-1.4,0.8-1.9c0.7,0,1.1,0.5,1.6,0.8c2.7,2,5.7,3.2,8.9,4.2C347.3,1006.5,347.9,1006.5,348,1007.2z"
      />
      <path
        class="st1691"
        d="M354.4,972c-1.2,0.1-2.5,0.5-3.7-0.3c5-0.6,9.8-0.4,14.5,1.6c-1,0.8-2-0.7-3,0.1c-1.2,0.4-2.3,0.2-3.3-0.6
		c-0.8-0.5-1.7-0.2-2.5-0.3C355.7,972.5,355,972.4,354.4,972z"
      />
      <path
        class="st1642"
        d="M343.1,968.2c0.6-0.4,0.9-1.1,1.8-0.7c0.1,0,0.1,0.5,0.1,0.5C344.4,968.5,343.9,968.8,343.1,968.2z"
      />
      <path
        class="st1692"
        d="M289.9,965.8c-0.2-0.5-1.4-0.5-0.6-1.4c1-0.6,2.1-1.2,3.1-1.8c0.3,0.2,0.6,0.5,1,0.8
		C292.2,964.2,291.1,965,289.9,965.8z"
      />
      <path
        class="st1693"
        d="M287,977.8c0.4,0,0.7-0.7,1.1-0.2c0.4,0.4,0,0.8-0.1,1.2c-0.5,2-2,3.7-1.5,5.9c-3,2.2-6.5,3-10.1,3.3
		c-4.6,0.4-8.7-0.9-12.8-2.9c-1-1-2.4-1.6-3.1-2.8c1.3-0.4,2.1,0.9,2.7,1.5c1.5,1.3,3.1,1.8,5,2c3,0.4,6,0.2,9,0.3
		c0.5,0,1.2,0,1.1-0.6c0-0.5-0.7-0.6-1.2-0.7c-1.8-0.1-3.6-0.2-5.4-0.4c-1.1-0.1-2.2-0.2-3-1.1c0.7-0.6,1.4-0.2,2,0
		c1.5,0.5,3.1,0.6,4.7,0.4c1.9,0.1,3.6-0.4,5.4-1.1c1.6-0.5,2.8-1.6,4.1-2.5c0.9-0.4,1.4-1.3,1.9-2.1
		C286.9,977.9,287,977.8,287,977.8z"
      />
      <path
        class="st1620"
        d="M263,964.9c0.5,2.5,0.3,5-0.6,7.4c-0.8,2-0.3,4.1,0.5,6.1c-1.5-1.2-1.7-3.2-1.5-4.8
		c0.4-2.7,1.8-5.3,1.4-8.2C262.7,965.3,262.9,965.1,263,964.9z"
      />
      <path
        class="st1694"
        d="M263.5,978.9c0-0.1,0.1-0.3,0.1-0.4c0.4,0,0.7,0.2,1,0.5c1,1.2,2.1,2.2,3.6,2.7c0.4,0.1,0.7,0.5,0.8,1
		c0,0.2-0.1,0.3-0.2,0.4c-1.4-0.3-2.4-1.4-3.7-2C264.4,980.3,263.4,980,263.5,978.9z"
      />
      <path
        class="st1694"
        d="M275.7,984c-1.9,0.5-3.7,0-5.5-0.4c-0.5-0.1-0.9-0.4-1.4-0.3c0-0.1,0-0.2-0.1-0.3l0.1-0.1
		c0.3-0.2,0.6-0.3,0.9-0.2c1.9,0.5,3.9,0.5,5.9,0.9C275.8,983.6,275.8,983.8,275.7,984z"
      />
      <path
        class="st1695"
        d="M263.5,978.9c0.5,0.7,1.1,1.4,1.6,2.1c-1.4-0.3-2.1-1.1-2.2-2.5C263,978.6,263.2,978.7,263.5,978.9z"
      />
      <path
        class="st1696"
        d="M294.3,944.9c0.3,4.2-1.7,7.5-4.5,10.5c-1.6,1.7-3.5,3-5.5,4c-1.2,0-1.7-1.3-2.8-1.5
		c-1.4-1.2-3.5-1.2-4.9-2.6c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.2,0.5-0.3,0.8-0.2c1.1,0.6,2.3,0.3,3.4,0.2c2.9,0.3,5.2-2.6,4.2-5.2
		c-0.5-3-2.2-4.5-5.3-4.7c-0.8-0.1-1.8,0.1-2.4-0.7c1.2-0.7,2.5-0.2,3.8-0.5c1.8,0.2,3.1,1.2,3.9,2.1c-1.3-1.3-3-2.9-5.6-1.9
		c-0.7,0.2-1.6-0.5-2.1,0.4c-0.5,0.4-1.1,0.6-1.7,0.8c-1.3,0.1-2,1-2.6,2c-0.3,0.5-0.5,1.1-1.2,0.9c-0.6-0.2-0.2-0.8-0.4-1.3
		c0.1,1.3-0.3,2.4-1.5,3c-1,0.5-0.9-0.9-1.5-1c-0.4,0.1-0.5,0.5-0.6,0.7c-0.3,0.4-0.4,1-1.1,0.7c-0.6-0.2-0.8-0.7-0.8-1.3
		c0-0.4,0.2-0.7,0.4-1.1c0.2-0.5,0-0.7-0.5-0.9c-2.1-0.6-3.8-1.9-5.5-3.2c0.2-0.7,0.9-0.8,1.3-1.2c0.2-0.2,0.3-0.3,0.5-0.5
		c1-0.7,1.1-1.9,1.4-2.9c0.3-0.7,0.2-1.3,0-2c0-1.7-1-2.9-2.5-3.5c-3-1.3-6-1.6-8.7,0.7c-0.2,0.2-0.5,0.4-0.9,0.4
		c-2.1-0.7-2.4-1.1-2.4-2.7c3.9-3.3,8.5-4.9,13.4-5.5c3.7-0.5,7.5-0.9,11.2,0.1c0.8,0.4,2,0.2,2.2,1.5c0,0.2,0.3,0.2,0.6,0.2
		c0.5,0,1.2-0.3,1.4,0.4c0.2,0.8-0.7,0.9-1,1.3c-0.1,0.1-0.1,0.2-0.2,0.2c-1.3,0.9-3.1,0.9-4.1,2.3c-0.3,0.2-0.5,0.5-0.7,0.8
		c-0.1,0.3-0.3,0.5-0.5,0.7c-0.5,0.5-0.9,1.1-1.3,1.7c-0.8,1.5-0.6,3.1,0.5,4.3c1.4,1.5,4.4,2.5,5.7,1.9c1.3-0.2,2.6-0.2,3.9-0.5
		c1.8-0.2,2.8-1.6,4-2.6c0.9-0.7,1-1.6,0.7-2.7c-0.2-0.8-0.5-1.6-0.2-2.4c0.1-0.4,0.4-0.8,0.9-0.9c0.5,0,0.7,0.3,0.8,0.7
		c0.2,0.8,0.6,1.4,1.5,1.5c0.5,0,0.6,0.6,0.8,1c0.6,1.4,1.6,2.4,3.2,2.4c0.7,0,1.1,0.4,1.4,1c0.4,0.8,0.7,1.7,0.5,2.5
		C293.2,943.2,293.7,944,294.3,944.9z"
      />
      <path
        class="st1697"
        d="M260.3,943.6c1.5,1,3.3,1.7,4.7,3l0.1,0.1c2.5-0.6,2.1,0.5,1.2,2c-0.3,0.5,0,1.1,0.4,1.3
		c0.6,0.3,0.7-0.4,0.8-0.8c0.1-0.6,0.3-1.1,1-1.1s0.7,0.5,0.7,1c0,0.4,0.3,0.8,0.6,0.4c0.5-0.7,1.7-1.1,1.1-2.3
		c-0.3-0.6-0.1-0.9,0.3-1.3c0.2-0.1,0.4-0.3,0.6-0.1c0.4,0.4,0.3,0.8,0.1,1.2c-0.1,0.2-0.2,0.4,0,0.7c0.9-0.3,0.8-1.3,1.4-1.9
		c0.7-0.6,1.1-1.4,2.2-1.1c-1.1,1.4-2.1,2.8-3,4.3c-1.5,1.3-1.7,2.6-0.6,4.4c0.3,0.5,0.9,1,0.8,1.7c0,0.2-0.1,0.3-0.2,0.4
		c-0.8,0.5-1.7,0.5-2.5,0.8c-1.7,0.5-3.2,1.5-4.4,2.7c-0.4,0.4-0.8,0.9-1.4,1.1c-0.5,1.1-1.3,1.4-2.4,1.2c-1.4-0.8-3-0.8-4.5-1.2
		c-6.6-2-11.5-5.9-14.1-12.3c-0.4-2.4-0.8-4.9-0.1-7.3c0.9-3.6,2.8-6.5,5.9-8.6c0.1,1.4,1.5,1.7,2.2,2.6c0.1,0.1,0.2,0.3,0.2,0.4
		c0,0.7-0.4,1.3-0.7,2c-0.1,0.1-0.1,0.3-0.2,0.4c-1.6,2.8-1.3,3.9,1.7,5.9c0.4,0.4,0.9,0.5,1.4,0.7c0.8,0.4,1.3,0.9,1,1.9
		c-0.7,1.1-1.7,1.9-2.1,3.2c-0.2,0.5-0.4,1-0.8,1.4c-1.2,1-1.1,3,0.3,4.2c1.3,1.2,3,1.5,4.7,1.7c1.3,0.1,2.7,0.3,4,0
		c2-0.4,3.7-1.2,4.4-3.4c0.5-1.5,0.2-2.9-0.1-4.4c-0.8-0.7-1.5-1.6-2.3-2.3c-0.9-0.5-1.8-0.9-2.7-1.3c-0.3-0.3-0.7-0.7-0.6-1.2
		C259.5,943.5,259.9,943.2,260.3,943.6z"
      />
      <path
        class="st425"
        d="M294.3,944.9c-1.2-0.5-1.8-1.2-1.2-2.7c0.3-0.8,0.1-1.9-0.4-2.8c-0.2-0.3-0.4-0.7-0.9-0.6
		c-2.1,0.3-3.1-1-3.8-2.8c-0.1-0.3-0.2-0.8-0.5-0.7c-1.6,0.6-1.4-0.6-1.6-1.4c-0.1-0.3-0.1-0.7-0.5-0.7c-0.5,0-0.6,0.5-0.6,0.8
		c0,0.9,0.2,1.7,0.5,2.5c0.3,1.1,0.1,2.1-1.1,2.5c-0.5-0.9-0.2-1.9-0.2-2.8c0-0.7-0.1-1.4-0.2-2.1c-0.8-2.7-3.1-3.1-5.3-3.6
		c-0.7-0.2-1.5,0-2.2-0.5c0-0.9,1-0.8,1.3-1.3c-0.3-0.6-0.7-0.4-1-0.1c-0.5,0.4-1.2,0.2-1.2-0.3c0-1.3-1.3-1.1-1.9-1.8
		c1.5,0,2.8,0.5,4.3,0.8c2,0.1,3.7,0.9,5.4,1.9c0.6,0.3,1.1,0.6,1.7,0.9c4.1,2.5,7.5,5.6,9,10.4c0.3,1.1,0.5,2.2,0.7,3.3
		C294.4,944.2,294.4,944.6,294.3,944.9z"
      />
      <path
        class="st1698"
        d="M283.1,929.3c-1.8-0.6-3.6-1.3-5.4-1.9c0.1-0.1,0.2-0.1,0.3-0.2c1.9,0.2,3.6,1,5.2,1.8v0.1
		C283.2,929.2,283.1,929.3,283.1,929.3z"
      />
      <path
        class="st1699"
        d="M286,968.8c1.8,2.4,2.1,5,1.3,7.9c-0.1,0.4-0.2,0.7-0.3,1.1l0,0c-0.3,0.2-0.1,1-0.8,0.6
		c-0.2-0.3-0.2-0.6-0.1-1c0.5-1.7,0.4-3.4,0-5.1c-0.1-0.6-0.2-1.5-1.3-1.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.6,0.1-1.3,0.2-1.9,0.3
		c-1,0.1-1.9-0.2-2.2-1.3c0.2-1.3,1.6-1.4,2.3-2.2c0.6-0.5,0.8-1.2,1.1-1.9c0.2-0.5,0.6-0.9,0.7-1.4c0.1-0.3,0.2-0.6,0.5-0.7
		c0.2-0.2,0.6-0.8,0.6,0.2c0,1.1,0.1,2.3,0.1,3.4C285.4,968.5,285.5,968.8,286,968.8z"
      />
      <path
        class="st1700"
        d="M284.8,964.4c-0.1,0.3-0.1,0.6-0.2,1c-1-0.3-0.2-1-0.4-1.3c-0.2,0.5,0,1.3-0.7,1.6
		c-0.3-0.1-0.5-0.4-0.5-0.6c0-1.9-1-3.3-1.9-4.8c-0.7-1.2-1.8-1.7-2.9-2.2c-0.6-0.3-1.2-0.6-0.9-1.3c0.3-0.6,1-0.3,1.5,0
		c0.9,0.5,1.8,0.8,2.6,1.3c1,0.8,2.1,1.4,2.5,2.8C284.4,961.9,285,963.1,284.8,964.4z"
      />
      <path
        class="st1701"
        d="M283.9,960.8c-0.8-0.8-1.7-1.7-2.5-2.5c0.2-0.2,0.6-0.2,0.4-0.6c1.1,0.2,1.7,1.2,2.5,1.8
		C284.2,959.9,284.1,960.3,283.9,960.8z"
      />
      <path
        class="st1702"
        d="M280.3,970.4c0.5,1,1.2,1.2,2.3,1c0.5-0.1,1.1-0.4,1.7,0c0,1.3-1,2.1-1.8,3c-1.6,0.9-2.8,2.4-4.8,2.5
		c-2.7,0.3-5.3,0.2-7.9-0.8c-1-0.3-1.9-0.8-2.7-1.5c-0.7-0.9-1.6-1.7-2.5-2.5c-0.6,0.7-0.6,1.5-0.7,2.3c-0.1,0.5,0,1.1-0.8,1
		c-0.7-2.4,0.3-4.4,1.5-6.3c0.4-0.7,1.2-0.6,1.9-0.7c1.4,0.1,2.5,1.1,3.8,1.5c2.6,0.9,5.1,1,7.8,0.4
		C278.8,970.2,279.6,969.8,280.3,970.4z"
      />
      <path
        class="st1703"
        d="M280.3,970.4c-1.6,0.1-3.1,0.7-4.6,0.8c-3.4,0.3-6.4-0.8-9.1-2.7c-0.3-0.5-0.6-0.9-0.9-1.4
		c1.1-1,1.7,0.3,2.6,0.6c4.2,2.4,8.5,2.7,13,0.5c0.5-0.3,1.4-0.9,1.6,0.4C282.2,969.5,280.9,969.5,280.3,970.4z"
      />
      <path
        class="st1704"
        d="M267.9,967.9c-0.7-0.3-1.2-1.2-2.2-0.8c-1.5-1.7-1.2-3.7-0.9-5.7c0.1-0.1,0.2-0.2,0.4-0.2
		c1.1-0.1,1.9,0.3,2.1,1.4c0,0.8,0.1,1.7,0.4,2.5c0,0.6,0.2,1.1,0.5,1.7C268.3,967.1,268.5,967.6,267.9,967.9z"
      />
      <path
        class="st1705"
        d="M263,975.5c0.5-0.3,0.3-0.8,0.4-1.3c0.5-0.1,0.6,0.3,0.6,0.6c0.2,3.4,1.8,5.7,5.1,6.7
		c2,1.4,4.2,1.4,6.4,1.2c0.9,0,1.7-0.2,2.5,0.4c-0.5,1.2-1.5,0.6-2.3,0.6c-2.4,0.3-4.6-0.4-6.9-0.8c-0.2-0.3-0.4-0.8-0.7-0.8
		c-2.1-0.5-3.2-2.1-4.6-3.5C263.5,977.5,262,976.7,263,975.5z"
      />
      <path
        class="st1706"
        d="M265,961.4c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.4-0.7-0.6-1c1.9-3,4.8-4.4,8.2-4.8c0.3-0.1,0.4,0.1,0.4,0.4
		c-0.6,0.7-1.6,0.7-2.4,1.1C268.1,958,266.4,959.5,265,961.4z"
      />
      <path
        class="st1707"
        d="M348,1007.2c-3.5-1-6.9-2.2-9.9-4.3c-0.6-0.4-1.1-0.8-1.6-1.4c0-0.8,0.1-1.5,0.1-2.3
		c0.6-4.7-2.1-9.3-6.7-11.8c0-0.1,0-0.2-0.1-0.3c1.3-3.5,3.1-6.6,6.1-8.9c3.6-2.8,7.6-4.5,12.1-5c-0.1,1.6-1.4,2-2.6,2.3
		c-0.9,0.2-1.7,0.4-1.8,1.5c-0.1,0.6-0.6,0.9-1.2,1.1c-0.9,0.3-1.6,0.8-2.2,1.4c-1.1,1-2.9,1.5-2,3.7c0.3,0.8-1.1,1.7-0.3,2.8
		c0,0.2,0.1,0.3,0.1,0.5c-0.2,0.8,0,1.5,0.7,2c1.1,0.7,2.1,1.3,3.2,2.1c0.5,0.4,0.6-0.2,1-0.2c0.6,0.1,1,0.4,1.3,0.9
		c0,0.9-0.7,1.2-1.3,1.6c-0.7,0.5-1.6,0.7-2.1,1.5c0,0.2-0.1,0.3-0.1,0.4c0,1-0.4,1.8-0.6,2.8c-0.2,0.6-0.5,1.1,0.2,1.6
		c2.4,1.6,4.7,3.1,7.6,3.6c0.6,0.4,0.7,1.1,1.1,1.6c0.6,0.7,0.8,1.7,2.2,1.3c1-0.3,1.3,0.8,0.6,2.5
		C350.6,1007.6,349.3,1007.4,348,1007.2z"
      />
      <path
        class="st1708"
        d="M313.5,1006.9c-2.6-0.6-4.5-2-6-4.1c-0.2-1-0.6-1.9-0.9-2.8c-0.5-1.4,0.2-2.7,0.5-4
		c0.1-0.5,0.7-0.7,1.2-0.3c0.6,0.5,1.4,1,1.2,2c-1.7,0-1.8,0.2-1.6,1.9c0.3,2.2,1.6,3.8,3.4,4.8c2.7,1.5,5.7,2,8.7,1.7
		c0.6-0.1,1.2-0.3,1.9-0.3c1.1,0.1,2-0.5,3-0.9c0.7-0.5,1.3-1,1.9-1.7c1.7-1.9,2.1-4,1.2-6.4c-0.3-0.7-0.4-1.4,0-2
		c0.7-0.5,1.2-0.3,1.6,0.4c1,2.6,1.4,5.2-0.1,7.8c-0.2,0.5-0.5,0.9-1,1.2c-1.7,1-3.4,2.1-5.3,2.8c-1.1,0.1-2.2,0.7-3.3,0.8l0,0
		c-1.7,0.2-3.3,0-4.9-0.5C314.6,1007,314.1,1006.9,313.5,1006.9z"
      />
      <path
        class="st1709"
        d="M329.7,1002.8c1-2.6,0.4-5.2-0.2-7.8c-0.5-1-1-1.3-2-0.5s-1.8,0.5-2.3-0.7c0-1.2,1.8-1.2,1.7-2.4
		c0.1-0.4,0.2-0.8,0.2-1.2c0.4-0.8,0.2-1.8,0.9-2.6c0.1-0.3,0.1-0.8,0.5-0.2c0.8,1,0.8,2.2,0.6,3.3c-0.2,1,0,1.9,0.6,2.9
		C331.9,996.9,331.8,1000.4,329.7,1002.8z"
      />
      <path
        class="st1710"
        d="M325.4,993.8c0.6,0.8,1.1,1.2,2.1,0.5c1.5-1.2,1.7-1.1,2.1,0.8c-0.4-0.3-0.9-0.7-1.3,0
		c-0.5,1.4-1.5,2.5-2.5,3.6c-1.5,1.1-2.9,2.2-4.8,2.5c-3.6,0.6-7,0-10.1-2c-0.6-0.4-1.3-0.7-1.4-1.5c-0.5-0.7-1-1.9-1.7-1.8
		c-1,0.1-0.5,1.5-0.9,2.3c-0.4,1,0,1.9,0.3,2.9c0.2,0.6,0.6,1.2,0.3,1.8c-1-1.5-1.6-3.2-1.3-5.1c0.4-1.6,1.3-3.1,1-4.8
		c-0.1-0.5,0-1.1-0.5-1.4c0-0.2,0-0.5,0.1-0.6c0.5-0.3,0.5,0.2,0.6,0.5c0.3,1,0.8,1.5,1.9,1c0.5-0.3,0.9,0.1,1.3,0.3
		c4.2,2.9,8.7,3.3,13.4,1.2C324.4,993.7,324.9,993.3,325.4,993.8z"
      />
      <path
        class="st1710"
        d="M313.5,1006.9c0.4-0.5,0.9-0.4,1.3-0.2c1.6,0.9,3.5,0.7,5.2,0.9C317.8,1008,315.6,1007.9,313.5,1006.9z"
      />
      <path
        class="st1710"
        d="M320.1,1007.6c1.1-0.3,2-1.2,3.3-0.8C322.4,1007.5,321.3,1007.7,320.1,1007.6z"
      />
      <path
        class="st1711"
        d="M327.5,990.6c0.1,0.4-0.2,0.7-0.4,1c-1.3,1.8-3.3,2.2-5.2,2.8c-5.6,1.7-10-0.4-13.9-4.4
		c-0.5-1.8-0.1-3.6,0.5-5.3c2.7-3.4,6.1-4.8,10.4-4.2c0.4,0,0.8,0,1,0.4c-0.4,0.4-0.8,0.3-1,0.2c1.2,0.2,2.6,0.8,4,1.3
		c3.4,1.2,4.6,4.3,4.4,7.9C327.4,990.3,327.4,990.5,327.5,990.6z"
      />
      <path
        class="st1712"
        d="M308.1,989.9c1.7,0.6,2.8,2.2,4.4,3c5.2,2.8,10,1.4,14.7-1.4c0.4,1.5-1.4,1.3-1.8,2.2
		c-2.4,1.2-4.8,1.9-7.5,1.8c-3.2-0.2-6.1-1.3-8.6-3.3C308.8,991.5,308.1,990.9,308.1,989.9z"
      />
      <path
        class="st1713"
        d="M314.1,971.1c1-1.3,2-1.5,3.3-0.5c1.6,1.2,3.3,2.1,5,3.2c0.6,0.4,1.1,0.7,0.9,1.6c-0.2,0.5-0.4,0.9-0.9,1
		c-2.5-2-5.2-3.7-8.1-5C314.3,971.3,314.2,971.2,314.1,971.1z"
      />
      <path
        class="st1714"
        d="M314.1,971.1h0.2c0.8,1.3,1.9,2,3.2,2.7c1.7,0.9,3.5,1.6,4.8,3.1c0.4,1.3-0.3,1.6-1.3,1.2
		c-2.4-1.1-4.8-2.4-6.9-4c-0.4-0.3-0.8-0.7-0.5-1.4C313.7,972.2,313.9,971.6,314.1,971.1z"
      />
      <path
        class="st1715"
        d="M313.5,972.7c0.1,0.7,0.6,1,1.1,1.3c2,1.3,4.1,2.5,6.2,3.6c0.6,0.3,1.5,0.8,1.1-0.7
		c0.1-0.3,0.4-0.5,0.6-0.7c0.1-0.4,0.5-0.7,0.9-0.9c0.3,1.1,0.5,2.2-0.5,3c-1,0.7-2,0.3-2.9-0.3c-0.5-0.3-1.1-0.5-1.6-0.8
		c-1.6-1.2-3.6-1.9-5.1-3.3C313.2,973.6,313.1,973.1,313.5,972.7z"
      />
      <path
        class="st1716"
        d="M327.5,990.6c-0.2,0-0.8,0.5-0.6-0.1c0.7-3-1-7.1-4.1-7.9c-1.4-0.4-2.8-1-4.3-1.6c0.5-0.4,1-0.1,1.5-0.2
		c4.2,0.5,7.1,2.6,8.1,6.9C328.2,988.7,327.7,989.6,327.5,990.6z"
      />
      <path
        class="st1717"
        d="M337.8,986c-0.9-0.4-1.2-0.8-0.4-1.7c0.5-0.5,0.8-1.3,0.2-2.1c-0.6-0.7-0.3-1.2,0.5-1.7
		c1.6-1,2.9-2.5,4.8-3.1c0.2,0,0.5-0.3,0.4-0.5c-0.3-1.8,0.9-1.6,2.1-1.8c1.1-0.2,2.1-0.9,2.6-2c1.7-0.5,3.4-0.5,5.2-0.6
		c0.5,0,1.1,0.2,1.6-0.2c1.4-0.2,2.8-0.1,4.2,0.3c1,0.6,2.2,0.4,3.3,0.8c6.1,1.5,11.5,4.2,15,9.7c3.1,4.9,3,9.9,0,14.8
		c-0.2,0.3-0.4,0.7-0.6,1c-4.6,5.5-10.5,8.2-17.5,8.8c-2.4,0.2-4.9,0.3-7.3,0c-0.1-0.8-1.2-1.6-2.2-1.8c-0.1,0-0.4-0.2-0.4-0.2
		c0.4-1.4-1.4-2-1.3-3.3c-0.1-0.1-0.1-0.3,0-0.4c0.2-0.3,0.5-0.6,0.8-0.8c1.3-0.8,2.5-1.7,3.8-2.5c0.5-0.3,1.1-0.7,1.8-0.7
		c1.7,0.5,3,1.7,4.6,2.5c1.1,0.6,2.1,1.4,3.5,1.3c0.6-0.2,1-0.7,1.5-1c1.1-0.8,2.3-1.5,3.5-2.1c1.6-0.8,2.4-2,2.2-3.8
		c0-0.5,0.1-0.9,0.1-1.4c-0.1-0.8-0.8-1-1.4-1.3c-1.2-0.8-2.6-1.3-3.7-2.4c-0.2-0.3-0.3-0.6-0.2-0.9c0.2-0.3,0.4-0.6,0.7-0.7
		c2.7-0.9,3.2-3,3-5.4c0-0.5-0.4-0.7-0.8-0.9c-1.7-1.1-3.5-2-5.2-3.1c-0.9-0.6-1.7-0.5-2.6,0c-1.6,1-3.1,2.2-4.7,3.3
		c-1.2,0.8-2.2,1-3.4,0c-1.4-1-2.9-1.8-4.2-2.8c-0.9-0.7-1.6-0.6-2.5,0c-1.7,1.2-3.4,2.2-5.1,3.3c-0.4,0.2-0.6,0.6-0.6,1.1
		C338.6,984.6,338.7,985.5,337.8,986z"
      />
      <path
        class="st1718"
        d="M358.9,972.6c-1.4-0.1-2.8-0.2-4.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.4c1.5,0.1,3,0.2,4.5,0.4v0.1
		C359,972.5,358.9,972.6,358.9,972.6z"
      />
      <path
        class="st1705"
        d="M286.4,977.5c0,0.3,0,0.7,0,1c0,0.9-0.8,1.3-1.2,1.9c-1.3,0.8-2.4,2-4,2.4c-0.3,0.1-0.6,0.1-1,0
		c-0.1-0.1-0.2-0.2-0.2-0.4c2.2-1.5,4.3-2.9,6-5C286.2,977.3,286.3,977.4,286.4,977.5z"
      />
      <path
        class="st1719"
        d="M280.1,982.6c0.4,0.1,0.7,0.1,1.1,0.2c-1.7,0.8-3.5,1.5-5.5,1.2c0-0.1,0-0.2,0-0.3c0.8-0.3,1.6,0,2.4-0.5
		C278.6,982.6,279.3,982.4,280.1,982.6z"
      />
      <path
        class="st1679"
        d="M285.1,980.3c0.4-0.6,0.8-1.2,1.2-1.9c0.2-0.2,0.4-0.4,0.6-0.7C287,979.1,286.3,979.9,285.1,980.3z"
      />
      <path
        class="st1720"
        d="M272.2,949c0.9-1.5,1.5-3.3,3.3-4.1c0.6-0.3,1.1-0.7,1.8-0.6h0.1c0.8,0.1,1.6,0.3,2.5,0.2
		c2.6-0.2,4,1.4,5.4,3.2c0.5,0.7,0.1,1.5,0.2,2.2c0.6,2.6-1.4,5.1-4.4,5.3c-1.5,0.8-2.9,0.1-4.3-0.3c-0.3,0.2-0.6,0.3-0.9,0.2
		C273.3,954.8,271.2,951.4,272.2,949z"
      />
      <path
        class="st1721"
        d="M251,934.9c0-0.1,0-0.2,0-0.3c2.9-3,6.3-3,9.9-1.5c1.7,0.7,2.9,1.9,2.8,4c-0.7,2.6-2.8,3.4-5.1,4
		c-2.9,0.1-5.6-0.4-7.4-3C250.5,937,251.1,936,251,934.9z"
      />
      <path
        class="st1722"
        d="M271.4,933c0.1-0.3,0.2-0.6,0.6-0.7c1.3-0.3,2.2-1.4,3.6-1.7c2.8-0.6,5.8,0.5,7.2,2.9
		c0.5,0.8,0,2.3-1.2,3.3c-1,0.8-1.9,1.7-3.1,2.1c-1.7,0.7-3.1-0.3-4.6-0.8C271.5,937,271,935.9,271.4,933z"
      />
      <path
        class="st1723"
        d="M265,961.4c0.8-2.5,2.9-3.6,5-4.6c0.8-0.4,1.7-0.7,2.7-0.8c1.3,0.1,2.5-0.8,3.8,0.1c-0.3-0.2-0.2-0.5,0-0.7
		c0.1,0,0.1,0,0.2-0.1c1.6,1,3.7,1,5.1,2.4c0.2,0.4,0.1,0.7-0.4,0.6c-1.1-0.2-2.1-0.7-3-1.4c-0.2-0.2-0.7-0.3-0.9,0
		c-0.1,0.3,0.1,0.6,0.5,0.7c3.7,1.2,5,4.2,5.5,7.7c0.5-0.3,0.4-1.1,0.8-1.6c0.3,0.5-0.2,1.2,0.4,1.7c0,0.6-0.5,0.9-0.8,1.3
		c-0.1,0.1-0.2,0.1-0.3,0.2c-1.9,0.5-1.9,0.5-1.9-1.5c0.6-3.5-1.6-6.2-5.6-6.7c-3.4-0.5-6.3,0.4-8.3,3.4c-0.2,0.3-0.3,0.6-0.7,0.5
		C266.7,961.5,265.7,961.6,265,961.4z"
      />
      <path
        class="st1724"
        d="M274,937.8c1.5,0.1,2.9,1.5,4.6,0.7c2.3-0.6,4.3-1.5,5-4.1c0.1-0.3,0.2-0.5,0.6-0.5c0.5,1.7,0.3,3.5,0,5.2
		c-1,1.3-2,2.5-3.8,2.7c-0.7-0.4-1.4,0.4-2,0c-0.4-0.1-0.7-0.3-0.9-0.7c-0.6-1.1-1.5-1.9-2.7-2.3C274.2,938.7,273.7,938.5,274,937.8
		z"
      />
      <path
        class="st1725"
        d="M274,937.8c0.1,0.1,0.1,0.4,0.2,0.4c2,0.4,3.1,2,4.1,3.5c-2,0.7-3.8-0.2-5.7-0.6c-2.7-1.1-3.7-3.4-2.5-6
		c0.2-0.5,0.4-1,0.8-1.4c0.1-0.2,0.3-0.4,0.4-0.7C271.4,935.1,271.9,936.9,274,937.8z"
      />
      <path
        class="st1726"
        d="M284.1,934c-0.8,3.1-2.7,4.7-5.5,4.6c1.5-0.7,2.8-1.7,3.7-3.1c0.6-0.8,0.6-1.3-0.2-2.3
		c-2.1-2.6-4.5-2.6-7.4-1.9c-0.8,0.2-1.1,0.8-1.7,1.2c-0.4,0.3-0.8,0.7-1-0.1c1-1.5,2.7-1.7,4.2-2.2c2.2,0.2,4.3,0.2,6.2,1.6
		C283.2,932.3,283.8,933,284.1,934z"
      />
      <path
        class="st1727"
        d="M251.4,937.9c1.9,2.4,4.6,2.8,7.4,2.9c0.5,0.1,1-0.1,1.5,0c1.1,0.3,1.8,0,2.2-1c0.2-0.4,0.5-0.9,1.1-0.7
		c0,1.3-0.3,2.4-1.4,3.2c-3.8,1.5-7.1,0.9-9.9-2.1C251.7,939.6,250.9,939,251.4,937.9z"
      />
      <path
        class="st1728"
        d="M251.4,937.9c0.9,3,4.7,5.2,7.9,4.7c1-0.2,1.9-0.5,2.9-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
		c-2.4,1.4-5,1.3-7.6,0.8c-1.4-0.3-2.5-1.2-3.6-2.2c-0.1-0.1-0.2-0.3-0.2-0.4c0.2-1.3-0.1-2.7-0.1-4c0.3-0.7,0.6-1.3,0.8-2
		C251.2,935.9,251.3,936.9,251.4,937.9z"
      />
      <path
        class="st1729"
        d="M272.2,949c-0.2,3.2,1.5,5.4,4.5,5.9c0,0.1,0,0.2,0,0.3s0,0.1-0.1,0.2c-1.4,0.3-2.8,0.8-4.2-0.1
		c-0.4-1.1-1.4-1.9-1.6-3.1C270.5,950.8,270.5,949.6,272.2,949z"
      />
      <path
        class="st1730"
        d="M271,933.7c-0.2,0.6-0.3,1.3-0.5,1.9c-0.8,2.5-0.2,3.8,2.1,5.2c1.2,0.5,2.4,0.7,3.4,1.5
		c-0.3,0.2-0.2,0.8-0.8,0.6c-1.7-0.5-3.5-1-4.9-2.2C268.1,938.6,268.4,936.3,271,933.7z"
      />
      <path
        class="st1731"
        d="M250.5,940.9c2.3,1.9,4.9,2.6,7.9,2.5c1.2,0,2.2-0.9,3.5-0.8c-0.4,0.5-0.9,0.7-1.5,0.9
		c-0.3,0.1-0.7,0.2-1,0.3c-1.1,1-2.5,0.9-3.9,0.7c-1.2-0.2-2.3-0.6-3.4-1.3C251.2,942.7,250,942.4,250.5,940.9z"
      />
      <path
        class="st1732"
        d="M263.6,939.1c-0.4,0.4-0.9,0.8-1,1.2c-0.3,1.1-1.3,1.2-1.8,0.9c-0.7-0.3-1.5,0.2-2-0.5
		c2-0.7,3.8-1.7,4.9-3.7C263.9,937.8,263.9,938.5,263.6,939.1z"
      />
      <path
        class="st1720"
        d="M279.5,943.8c0.3-0.8,1-0.5,1.5-0.4c2.2,0.4,3.9,1.6,5.1,3.5c0.1,0.1,0,0.4-0.1,1.1c-1.3-2.1-2.7-3.3-4.6-4
		C280.8,944.2,280.1,944.3,279.5,943.8z"
      />
      <path
        class="st1733"
        d="M281,955.2c0.9-0.3,1.8-0.6,2.5-1.2c1.3-1.1,2.1-2.3,1.9-4.1c0.7,0,0.6,0.5,0.6,1c-0.1,2-2.4,4.5-4.5,4.7
		C281.4,955.6,281.2,955.3,281,955.2z"
      />
      <path
        class="st1734"
        d="M276,942.3c-1.1-0.6-2.5-0.5-3.4-1.5c1.9,0.2,3.8,1,5.8,1c0.6,0.3,1.3-0.6,2,0.1
		C278.9,942.3,277.4,942.4,276,942.3z"
      />
      <path
        class="st1735"
        d="M279.5,943.8c0.6,0.1,1.3,0.2,2,0.2c-1.3,0.8-2.8-0.2-4.1,0.3h-0.1C277.8,943.1,278.8,944.1,279.5,943.8z"
      />
      <path
        class="st1736"
        d="M259.9,944.8c1.1,0,2,0.7,2.9,1.2c0.3,0.7,0.7,1.3,1.1,1.9c0.6,0.9,0.5,1.9,0.1,2.9c-1.9,2.4-4.3,2.9-8,1.6
		c-1.9-0.7-3.2-1.8-3.2-4c0.5-1.7,2-2.5,3.3-3.6C257.3,944.6,258.5,943.9,259.9,944.8z"
      />
      <path
        class="st1737"
        d="M261,956.8c-1.5,0.4-3,0.3-4.6,0c-2.2-0.4-4.2-1.3-5.1-3.6c-0.3-0.9-0.6-1.8,0.1-2.7
		c0.2-0.5,0.3-1.1,0.5-1.6c0.4-0.1,0.6,0,0.8,0.3c0.5,1.9,1.9,2.8,3.5,3.4c2.2,0.4,3,2.6,4.8,3.6C261.3,956.3,261.2,956.6,261,956.8
		z"
      />
      <path
        class="st1738"
        d="M261,956.8c0-0.4-0.2-0.6-0.6-0.6s-0.9,0-1-0.4c-0.5-1.8-2.2-2.1-3.4-2.9c0.4-0.5,0.9-0.2,1.4-0.1
		c2.2,0.6,4.2,0.4,6-1.2c0.2-0.2,0.4-0.2,0.6-0.2c1,0.2,1.4,0.8,1.4,1.7C264.8,955.4,263,956.2,261,956.8z"
      />
      <path
        class="st1739"
        d="M251.5,950.5c-0.4,3.8,2.1,5.2,5,6.3c-1.7-0.1-3.3-0.4-4.7-1.5c-1.5-1.2-1.8-3.2-0.9-4.8
		C251.2,949.7,251.3,950.5,251.5,950.5z"
      />
      <path
        class="st1740"
        d="M259.9,944.8c-1.4-0.1-2.7,0-4,0.5c-0.6,0.2-0.9,1-1.7,0.7c0.2-0.8-0.1-1.4-0.9-1.6
		c-0.6-0.1-1.2-0.3-1.5-0.8c0.3-0.1,0.3-0.4,0.4-0.7c2.3,1.1,4.7,1.8,7.2,1C259.5,944.2,260,944.3,259.9,944.8z"
      />
      <path
        class="st1741"
        d="M252.1,942.9c0.1,0.4,0.2,0.7-0.4,0.7c-3.2-1.9-3.6-3.6-1.6-6.4c0.7,1.2,0.1,2.5,0.4,3.7h-0.1
		C250.7,941.8,251.2,942.5,252.1,942.9z"
      />
      <path
        class="st1742"
        d="M263.8,950.7c0.2-1.2,0.2-2.5-0.9-3.3c-0.5-0.5-0.1-0.9-0.1-1.3c1,0.5,1.5,1.5,2.5,2.2
		c-0.3,0.9,0.4,2.1-0.6,2.9C264.2,951.3,263.9,951.1,263.8,950.7z"
      />
      <path
        class="st1743"
        d="M264.4,951c0.8-0.8,0.2-2,0.9-2.8c0.5,1.6,0.9,3.3,0.1,4.9c-0.5-0.5-0.6-1.2-1.3-1.5
		C263.9,951.3,264.1,951.2,264.4,951z"
      />
      <path
        class="st1744"
        d="M272.4,955.3c1.4,0,2.8,0,4.2,0.1c-0.5,0.5,0.4,0.5,0.3,1c-1.4-1.2-2.9,0.6-4.2-0.5
		c-0.1-0.1-0.2-0.3-0.4-0.4C272.4,955.5,272.4,955.4,272.4,955.3z"
      />
      <path
        class="st1745"
        d="M254.1,946c0.6-0.2,1.2-0.5,1.7-0.7c-0.8,1.1-2.3,1.6-2.9,3c-0.4,0.3,0,0.8-0.4,1c-0.2-0.1-0.4-0.3-0.6-0.4
		C252.4,947.7,253,946.7,254.1,946z"
      />
      <path
        class="st1731"
        d="M250.5,940.9c-1-1.1-0.3-2.5-0.4-3.7c0.1-0.1,0.1-0.2,0.1-0.3C250.9,938.2,251.1,939.5,250.5,940.9z"
      />
      <path
        class="st1746"
        d="M281.9,965.5c0.3,0.5,0.2,0.9-0.1,1.6c0.7-0.5,1.3-0.9,2-0.5c-0.1,0.8-0.6,1.4-1.1,1.9
		c-0.5-0.8-1-0.3-1.5,0c-2.9,1.6-6,1.9-9.1,1.2c-1.5-0.3-3-1-4.3-1.9c0.5-0.6-0.1-1-0.3-1.4c-0.2-0.4-0.6-0.8-0.2-1.3
		c0.6-0.2,0.9,0.3,1.3,0.6c3.5,2.8,8.3,2.9,11.8,0.3C280.9,965.8,281.3,965.3,281.9,965.5z"
      />
      <path
        class="st1747"
        d="M282.3,974.2c0.6-1,1.3-1.9,1.9-2.8c0.1-0.1,0.2-0.1,0.3-0.2c0.8-0.3,0.9,0.4,1,0.8
		c0.5,1.4,0.8,2.9,0.4,4.4c-0.5,1.2-0.8,2.5-2.2,2.9C282.7,977.8,282.3,976,282.3,974.2z"
      />
      <path
        class="st1748"
        d="M285.6,976.4c0.2-1.8,0.1-3.6-1-5.2c0.6-0.9,1.5-1,1.8,0.1c0.6,2,1,4.1,0.1,6.2c-0.1,0-0.2,0-0.3,0
		C285.7,977.2,285.6,976.8,285.6,976.4z"
      />
      <path
        class="st1749"
        d="M267.1,974.2c2.7,2.2,5.9,2.6,9.3,2.5c0.5,0,1-0.2,1.5,0c0,0.6-0.4,0.8-0.8,1.1c-2.3,1.9-2.4,2.6-0.5,4.5
		c-1.7,0.8-3.5,0.5-5.1,0c-2.5-0.8-5-1.5-6.5-4c-0.9-1.4-0.9-2.9-1-4.4c0-0.5,0.1-1,0.6-1.2s0.9,0.2,1.1,0.5
		C266,973.7,266.6,973.7,267.1,974.2z"
      />
      <path
        class="st1750"
        d="M276.5,982.1c-0.5-0.2-1.6,0.2-1.6-0.7c0-1-0.5-2.2,0.5-3c0.8-0.6,1.6-1.2,2.3-1.7c1.7-0.4,2.8-1.9,4.5-2.4
		c0.5,1.7,0.9,3.3,1.3,5c0.1,0.9-0.6,1.1-1.1,1.4c-1.2,0.8-2.6,1.3-4,1.9C277.8,982.6,277.1,982.7,276.5,982.1z"
      />
      <path
        class="st1751"
        d="M276.5,982.1c0.8,0,1.5,0.1,2.3,0.1c0.5-0.2,0.9-0.3,1.3,0.1c0,0.1,0,0.2,0,0.3c-0.7,0.2-1.3,0.4-2,0.6
		c-0.9-0.1-1.8-0.2-2.7-0.2c-2.2-0.2-4.6,0.3-6.5-1.4c-3.3-0.8-5.1-3-5.2-6.4c0-0.3,0-0.7-0.3-0.9c0-0.3,0-0.6,0.1-0.8
		c0.3-0.6,0.1-1.6,0.8-1.8c0.7-0.2,1,0.7,1.4,1.2s1.2,0.6,1.3,1.4c-0.6,0-1.2-0.1-1.6-0.6c-0.2-0.3-0.4-0.8-0.9-0.6
		s-0.5,0.7-0.5,1.1c-0.2,2.7,0.9,4.8,3.2,6.1C270.2,981.8,273.2,982.7,276.5,982.1z"
      />
      <path
        class="st1700"
        d="M281.9,965.5c-1,0.5-1.6,1.4-2.6,1.9c-3.9,1.9-8.2,1.1-11.9-2.3c-0.7-0.8-0.6-1.8-0.5-2.7
		c2.7-5.4,9.6-5.5,13.5-2.4C282.1,961.5,282.9,963.3,281.9,965.5z"
      />
      <path
        class="st1752"
        d="M268.9,981.5c1.6,0.7,3.2,1.4,5.1,1c0.5-0.1,1.1-0.1,1.5,0.4C273.1,983.3,270.7,983.5,268.9,981.5z"
      />
      <path
        class="st1753"
        d="M364.4,989.3c0.1,0.2,0.2,0.4,0.3,0.6c0.9,0.9,1.8,1.7,3,2.2c1.5,0.7,1.6,1.4,0.2,2.3
		c-1.7,1.1-3.4,2.2-5,3.4c-0.8,0.2-1.4-0.3-2.1-0.5c-0.8-0.2-1.3-0.8-2-1.2c-0.8-0.5-1.6-0.9-2.3-1.5c-0.6-0.7-1.3-0.7-2.1-0.2
		c-1.7,1.1-3.4,2.1-5.1,3.3c-0.5,0.3-0.9,0.8-1.6,0.8c-2.6-1.3-5.3-2.6-7.4-4.8c1.1-1.1,2.8-1.3,3.7-2.6c0.1-0.1,0.1-0.2,0.3-0.3
		c0.2-0.2,0.5-0.3,0.8-0.4c0.1-0.1,0.3-0.1,0.4-0.2c1.6-1.1,1.6-1.1,0-2.1c-1.8-1.2-3.7-2.2-5.4-3.5c-0.7-0.5-1.8-0.8-1.2-2.1
		c2-1.3,3.9-2.5,5.8-3.8c0.7-0.5,1.2-0.3,1.8,0c1.6,1.1,3.4,2.1,4.9,3.3c1,0.8,1.8,0.7,2.7,0c1.8-1.3,3.6-2.5,5.4-3.7
		c0.6-0.4,1.2-0.5,1.9-0.1c1.9,1.2,3.9,2.4,5.9,3.6c-0.6,1.7-2.3,2.1-3.5,2.9c-0.9,0.6-2,0.9-2.3,2.1c-0.5,0.8,0.5,0.5,0.7,0.9
		c0.4,0.3,0.8,0.6,1.2,0.8C363.9,988.8,364.4,988.9,364.4,989.3z"
      />
      <path
        class="st1754"
        d="M345.1,990.8c-0.2,0.1-0.5,0.2-0.7,0.3c-2-1.1-3.5-2.9-5.8-3.5c-0.5-0.2-1-0.5-0.8-1.2c0-0.1,0-0.2,0-0.3
		c0.2-0.9,0.4-1.7,0.6-2.6c0.1-0.1,0.2-0.2,0.3-0.1c1.8,2,4.4,2.9,6.5,4.4C347.1,989.1,347.2,989.4,345.1,990.8z"
      />
      <path
        class="st1755"
        d="M340.4,993.8c2.5,1.5,5,3.1,7.5,4.6c0.8,0.2,1.5,0.2,2.2-0.3c0.4-0.2,0.9-0.5,1.3-0.1
		c0.3,0.7-0.1,1.2-0.6,1.5c-0.8,0.5-1.4,1.3-2.3,1.6c-1.6-2.2-4.2-3.2-6.4-4.7c-0.5-0.4-1.1-0.7-1.6-1.2c-0.2-0.3,0-0.8-0.2-1.1
		C340.3,994,340.4,993.9,340.4,993.8z"
      />
      <path
        class="st1756"
        d="M348,1002.3c0,0.1,0,0.2,0,0.3c-1.6,0.5-2.9-0.5-4.3-1.2c-1.3-0.6-2.4-1.6-3.6-2.2c-1.2-0.6-1-1.1-0.3-1.8
		C342.5,999,345.4,1000.4,348,1002.3z"
      />
      <path
        class="st1757"
        d="M337.8,986.3c0.1,0.5,0.5,0.8,0.9,1c1.5,0.8,3.1,1.5,4.1,3c-0.5,0.3,0.2,0.6,0,1c-1.6-0.8-3.1-1.7-4.5-2.8
		C337.5,988,337,987.3,337.8,986.3z"
      />
      <path
        class="st1758"
        d="M348,1002.3c-2.9-1.4-5.9-2.6-8.2-5c0.2-1.1,0.4-2.1,0.5-3.2c0.3,0.3,0.2,0.6,0.2,1
		c0.5,0.9,0.1,2.4,1.5,2.8c2.2,1.3,4.4,2.8,6.7,3.7C348.5,1001.9,348.2,1002.1,348,1002.3z"
      />
      <path
        class="st1759"
        d="M342.9,990.3c-1.2-1.2-3-1.7-4.1-3c0.9-1.1,1.2,0.4,1.6,0.6c1.5,0.8,3,1.7,4.1,3.1c-0.1,0-0.2,0-0.3,0.1
		C343.5,991.2,343.3,990.6,342.9,990.3z"
      />
      <path
        class="st1760"
        d="M309.5,997.6c2.9,2.4,6.2,3.6,10.1,3.1c0.5-0.1,1.1,0,1.6,0c-0.2,1-1.2,1.4-1.8,2.2c-1,1.6-0.9,2.5,0.7,3.4
		c-2.3,0.3-4.6,0.4-6.8-0.7c-1.2-0.6-2.5-0.8-3.5-1.7c-1.8-1.7-2.7-3.6-2.4-6c0-0.4-0.1-1,0.4-1.2c0.4-0.2,0.6,0.4,0.9,0.6
		C309,997.4,309.3,997.5,309.5,997.6z"
      />
      <path
        class="st1761"
        d="M320.2,1006.3c-1.8-0.2-2.4-1.4-1.4-3c0.6-1.1,1.4-2,2.5-2.6c1.7-0.5,3-1.6,4.5-2.4
		c1.6,1.4,1.6,3.2,1.3,5.1c-0.5,0.7-1.1,1.2-1.8,1.6c-1.1,0.3-2,0.8-3.1,1.1C321.5,1006.2,320.9,1006.2,320.2,1006.3z"
      />
      <path
        class="st1762"
        d="M327,1003.5c-0.4-1.7-0.4-3.5-1.3-5.1c0.7-1.2,1.5-2.3,2.5-3.3c-0.2,0.6,0.1,1.2,0.3,1.7
		C329.5,999.4,329,1001.6,327,1003.5z"
      />
      <path
        class="st1760"
        d="M322.1,1006.1c1-0.6,2-1.1,3.1-1.1C324.3,1005.6,323.3,1006.2,322.1,1006.1z"
      />
      <path
        class="st1763"
        d="M318,982.7c2.2,0.1,4,0.3,5.6,1.6c3,2.5,2.9,5.7-1,7.6c-3.9,1.9-7.5,1-10.9-1.3c-2.1-1.4-1.8-4.7,0.4-6.3
		C313.9,982.9,316,982.1,318,982.7z"
      />
      <path
        class="st1764"
        d="M322.5,976.3c0,0.4-0.3,0.6-0.6,0.7c-2-1.5-4.2-2.5-6.3-3.8c-0.8-0.5-1.5-1-1.3-2c2,0.2,3.3,1.6,4.9,2.5
		C320.4,974.3,321.8,974.9,322.5,976.3z"
      />
      <path
        class="st1765"
        d="M348.8,1001.7c-0.9,0.1-1.8-0.1-2.5-0.8c-1.3-1.1-3.2-1.4-4.3-2.7c1.9-0.3,3.2,0.8,4.7,1.6
		c0.6,0.3,1.2,0.8,1.7,1.2c0.6-0.6,1.1-1.3,2-1.7c0.6-0.2,0.9-0.8,0.9-1.4c0.8-1.4,2.1-2.3,3.1-3.5c0.6-0.7,1.6-0.6,2.4-0.1
		c0.7,0.5,1.5,1,2.2,1.5c0.9,1.2,2.4,1.7,3.5,2.7c0.8,0.7,1.5,1.3,1.1,2.5c-0.4,0.7-0.9,0.2-1.4,0.1c-1.8-0.3-3-1.7-4.7-2.1
		c-1.1-0.3-2-1.1-3.2-0.6C352.4,999.5,350.6,1000.6,348.8,1001.7z"
      />
      <path
        class="st1766"
        d="M360.9,996.9c0.6,0.3,1.3,0.5,1.9,0.8c2-1.1,3.8-2.5,5.7-3.7c0.5-0.3,0.9-0.7,1.5-0.4
		c0,0.6-0.1,1.1-0.1,1.7c-0.2,0.2-0.3,0.4-0.4,0.6c-1.6,1.2-3.6,1.8-5.1,3.3c-0.2,0.2-0.7,0.3-0.9,0.2
		C362.6,998.6,361.2,998.3,360.9,996.9z"
      />
      <path
        class="st1767"
        d="M354.2,998.4c1.1-1.5,2.1,0.1,3.1,0.2c1.6,0.6,2.9,1.8,4.4,2.4c0.5,0.2,0.9,0.5,0.8,1.1
		c-0.4,0.8-0.9,0.2-1.4,0.1C358.6,1001.3,356.5,999.7,354.2,998.4z"
      />
      <path
        class="st1768"
        d="M370.1,993.6c-2.1,1.1-4,2.5-5.9,3.7c-0.4,0.2-0.6,1-1.3,0.4c0.7-1.4,2.4-1.6,3.4-2.7
		c0.1-0.1,0.2-0.1,0.3-0.1c0.6-0.6,1.9-0.7,2-1.5c0.2-1.2-1.3-0.8-1.9-1.4c-0.7-0.7-1.6-1.2-1.9-2.1c1.5,0.9,3,1.7,4.5,2.6
		C369.6,992.8,370.2,992.9,370.1,993.6z"
      />
      <path
        class="st1769"
        d="M345.1,990.8c0.2-0.2,0.4-0.5,0.6-0.7c0.6-0.6,0.9-1-0.2-1.7c-1.8-0.9-3.3-2.3-5.2-3c-0.9-0.3-1.3-1.3-2-2
		c-0.1-0.6,0.2-0.8,0.7-0.9c-0.1,1,0.8,1.4,1.4,1.8c2.4,1.5,4.8,3.1,7.4,4.7C346.9,989.7,346.3,990.6,345.1,990.8z"
      />
      <path
        class="st1765"
        d="M369.6,995.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2,1.8-0.3,3-2.1,3.8c-1.4,0.6-2.6,1.5-4,2.3
		c-0.1-0.1-0.2-0.2-0.2-0.4c0.4-1.6,1.3-2.8,2.9-3.2C367.9,997.3,368.8,996.5,369.6,995.5z"
      />
      <path
        class="st1770"
        d="M364.4,989.3c-0.4-0.1-0.7-0.1-0.9-0.5c-0.1-1.5,0.8-2.6,2.6-2.9c1.1-0.2,1.7-0.6,1.6-1.8
		c0-0.5,0.1-1,0.3-1.5c0.1-0.2,0.2-0.2,0.4-0.2c0,0.1,0,0.2,0.1,0.3C369.2,986.3,367.3,988.2,364.4,989.3z"
      />
      <path
        class="st1771"
        d="M368.4,982.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,1-1.4,1.3-2.2,1.8c-1.4,0.9-2.7,2-4.4,2.5
		c-1-0.2-0.5-0.7-0.1-1c0.9-0.7,1.7-1.3,2.7-1.8c1.2-0.6,2.4-1.4,3.3-2.4C367.9,982,368.3,982.1,368.4,982.5z"
      />
      <path
        class="st1772"
        d="M363.9,1001.1c0,0.1,0,0.2,0,0.2c-0.5,0.3-0.8,0.8-1.4,0.8c-1.5-1.5-3.7-2-5.2-3.5c0.9-0.1,1.6,0.4,2.2,0.9
		c1.1,0.9,2.5,1.1,3.8,1.5C363.5,1000.9,363.7,1000.9,363.9,1001.1z"
      />
      <path
        class="st1707"
        d="M342.4,979.6c-0.1,0.3-0.4,0.5-0.7,0.6c-0.4,0.1-0.4-0.2-0.4-0.5c0-0.4,0.1-0.6,0.6-0.6
		C342.1,979.1,342.4,979.3,342.4,979.6z"
      />
      <path
        class="st1773"
        d="M280.1,982.3c-0.4,0-0.9-0.1-1.3-0.1c0.9-1.1,2.5-1,3.5-1.9c0.4-0.4,1-0.6,1.3-1.1c1-0.7,1.2-1.9,1.9-2.8
		c0.4,0.2,0.6,0.6,0.5,1C284.7,979.8,282.7,981.5,280.1,982.3z"
      />
      <path
        class="st1774"
        d="M252.6,949.3c0.1-0.4,0.1-0.8,0.4-1c0.1,1.8,0.9,3,2.7,3.7c1.5,0.5,3,0.9,4.6,0.8c1.5-0.1,2.4-1.1,3.4-2
		c0.2,0.1,0.4,0.2,0.6,0.3c-0.1,0.2-0.2,0.4-0.4,0.7c-1.6,0.7-2.9,2-4.8,1.7c-1.1-0.2-2.1-0.7-3.2-0.5
		C254.4,952.1,252.9,951.3,252.6,949.3z"
      />
      <path
        class="st1775"
        d="M356.8,994.3c-1.3-0.2-2.2,0-3,1.3c-0.5,1-1.6,1.7-2.6,2.2c-0.7,0.2-1.2,0.7-1.8,1
		c-0.6,0.3-1.1-0.1-1.5-0.6c2.1-1.6,4.3-3,6.5-4.4C355.4,993.4,356.1,993.4,356.8,994.3z"
      />
      <path
        class="st1776"
        d="M363.9,1001.1c-0.2,0-0.4,0-0.6,0c0.3-2.3-2-2.5-3-3.7c-0.5-0.5-1.2-0.8-1.3-1.6c0.6,0.4,1.3,0.7,1.9,1.1
		c0.5,0.9,1.7,1.2,2.3,2c0.2,0.3,0.8,0.3,1,0c1.4-1.7,3.5-2.3,5.3-3.4c-0.5,1.2-1.2,2.1-2.6,2.4
		C365.4,998.3,364.5,999.5,363.9,1001.1z"
      />
      <path
        class="st1777"
        d="M361.6,987c2-1.6,4.4-2.9,6.6-4.3c0,0.5-0.1,1-0.1,1.5c-0.5,0.9-1.2,1.6-2.2,1.8c-1.4,0.3-2.1,1.9-3.6,2.1
		C361.9,987.8,361.2,987.8,361.6,987z"
      />
      <path
        class="st1778"
        d="M362.2,988c1.7-1.7,4-2.4,5.9-3.9c0.1,1.5-0.4,2.2-2.1,2.3c-1.3,0-2.3,1-2.5,2.4
		C362.9,988.8,362.6,988.3,362.2,988z"
      />
      <path
        class="st1779"
        d="M348.4,1001c-1.9-0.5-3.5-1.8-5.4-2.5c-0.4-0.1-0.7-0.3-1.1-0.3c-1.9-0.4-1.5-1.8-1.4-3.1
		c2.4,1.2,4.7,2.8,7,4.2C348.2,999.8,348.2,1000.4,348.4,1001z"
      />
    </g>
  </svg>
</template>

<script>
import { ref, onMounted } from 'vue';
export default {
  name: "OmarSVGVue",
  setup() {
    const currClassState = ref('init');
    onMounted(() => {
      setTimeout(() => {
        currClassState.value = 'loop';
      }, 3000);
    });

    return { currClassState };
  }
};
</script>

<style scoped>
@keyframes scaleUp {
  0%, 50% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scaleUpDelayed {
  0%, 85% {
    transform: scale(0);
  }
  95% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fadeUp {
  0%, 50% {
    transform: translateY(5vw);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes fadeUpDelayed {
  0%, 75% {
    transform: translateY(2vw);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes hovering {
  0%,100% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(0.5vw);
    opacity: .9;
  }
}
svg {
  animation: fadeUp 2s linear 0s 1;
}
.init #apple {
  transform-origin: 10% 65%;
  animation: scaleUpDelayed 2.3s linear 0s 1;
}
.init #gaming {
  transform-origin: 30% 92%;
  animation: scaleUpDelayed 2.25s linear 0s 1;
}
.init #molecule {
  transform-origin: 13% 82%;
  animation: scaleUpDelayed 2.35s linear 0s 1;
}
#chart {
  animation: fadeUpDelayed 2.5s linear 0s 1;
}
.init #cloud_gear {
  animation: fadeUpDelayed 2.5s linear 0s 1;
}
.init #light_bulb {
  animation: fadeUpDelayed 2.55s linear 0s 1;
}
.init #cloud_yt {
  animation: fadeUpDelayed 2.55s linear 0s 1;
}
.init #ipad {
  animation: fadeUpDelayed 2.6s linear 0s 1;
}
.loop #light_bulb {
  animation: hovering 2.55s ease 0s infinite;
}
.loop #cloud_yt {
  animation: hovering 2.55s ease 0s infinite;
}
.loop #cloud_gear {
  animation: hovering 2.4s ease 0s infinite;
}
.loop #ipad {
  animation: hovering 2.6s ease 0s infinite;
}
.st0 {
  fill: #310748;
}
.st1 {
  fill: #5663ad;
}
.st2 {
  fill: #182032;
}
.st3 {
  fill: #371364;
}
.st4 {
  fill: #331258;
}
.st5 {
  fill: #403d85;
}
.st6 {
  fill: #27163d;
}
.st7 {
  fill: #210b31;
}
.st8 {
  fill: #1a2234;
}
.st9 {
  fill: #282a47;
}
.st10 {
  fill: #240f25;
}
.st11 {
  fill: #5162ce;
}
.st12 {
  fill: #bdc9d5;
}
.st13 {
  fill: #230d23;
}
.st14 {
  fill: #956995;
}
.st15 {
  fill: #4b2c72;
}
.st16 {
  fill: #432258;
}
.st17 {
  fill: #797185;
}
.st18 {
  fill: #c5a8c1;
}
.st19 {
  fill: #42377f;
}
.st20 {
  fill: #424c8a;
}
.st21 {
  fill: #434e93;
}
.st22 {
  fill: #c2f7fa;
}
.st23 {
  fill: #504c97;
}
.st24 {
  fill: #403175;
}
.st25 {
  fill: #3c4d84;
}
.st26 {
  fill: #b4edfa;
}
.st27 {
  fill: #3b206c;
}
.st28 {
  fill: #4c4390;
}
.st29 {
  fill: #cbf6f9;
}
.st30 {
  fill: #bdf6fa;
}
.st31 {
  fill: #1c0d39;
}
.st32 {
  fill: #afe5ee;
}
.st33 {
  fill: #637bc5;
}
.st34 {
  fill: #6e629a;
}
.st35 {
  fill: #8d91c6;
}
.st36 {
  fill: #3d2774;
}
.st37 {
  fill: #c8e9f7;
}
.st38 {
  fill: #b1cde1;
}
.st39 {
  fill: #41418d;
}
.st40 {
  fill: #3f3c89;
}
.st41 {
  fill: #404187;
}
.st42 {
  fill: #a4bcf0;
}
.st43 {
  fill: #96ccf3;
}
.st44 {
  fill: #8fb8e9;
}
.st45 {
  fill: #9ec9f7;
}
.st46 {
  fill: #3b5e96;
}
.st47 {
  fill: #7ebcde;
}
.st48 {
  fill: #bcdaef;
}
.st49 {
  fill: #84bbe9;
}
.st50 {
  fill: #6a9dcc;
}
.st51 {
  fill: #a4b5df;
}
.st52 {
  fill: #9fbbef;
}
.st53 {
  fill: #85a7e1;
}
.st54 {
  fill: #435498;
}
.st55 {
  fill: #b5d6f4;
}
.st56 {
  fill: #4265a8;
}
.st57 {
  fill: #5076b5;
}
.st58 {
  fill: #7097cf;
}
.st59 {
  fill: #5473ad;
}
.st60 {
  fill: #6b90c7;
}
.st61 {
  fill: #98b1e4;
}
.st62 {
  fill: #6e87c8;
}
.st63 {
  fill: #5b87b8;
}
.st64 {
  fill: #759fd8;
}
.st65 {
  fill: #7fa2d9;
}
.st66 {
  fill: #7ca1df;
}
.st67 {
  fill: #85a7e6;
}
.st68 {
  fill: #8ab2f1;
}
.st69 {
  fill: #404b94;
}
.st70 {
  fill: #474c90;
}
.st71 {
  fill: #495b9b;
}
.st72 {
  fill: #8e9cd5;
}
.st73 {
  fill: #8c9cd5;
}
.st74 {
  fill: #6e7fb6;
}
.st75 {
  fill: #4f6094;
}
.st76 {
  fill: #a8b9e5;
}
.st77 {
  fill: #7486c1;
}
.st78 {
  fill: #b7c9ea;
}
.st79 {
  fill: #acbcea;
}
.st80 {
  fill: #8695cc;
}
.st81 {
  fill: #acbbdd;
}
.st82 {
  fill: #a6b4ed;
}
.st83 {
  fill: #606eb1;
}
.st84 {
  fill: #cee0f5;
}
.st85 {
  fill: #b2c8f2;
}
.st86 {
  fill: #7380b3;
}
.st87 {
  fill: #b7c4fb;
}
.st88 {
  fill: #bcc8f3;
}
.st89 {
  fill: #a0b0e7;
}
.st90 {
  fill: #7895c7;
}
.st91 {
  fill: #bdc8f7;
}
.st92 {
  fill: #3e4990;
}
.st93 {
  fill: #90beee;
}
.st94 {
  fill: #87a6e2;
}
.st95 {
  fill: #7ea7d2;
}
.st96 {
  fill: #7d8bc2;
}
.st97 {
  fill: #6377b3;
}
.st98 {
  fill: #8799cc;
}
.st99 {
  fill: #3e4f96;
}
.st100 {
  fill: #8dafe4;
}
.st101 {
  fill: #404087;
}
.st102 {
  fill: #3f509b;
}
.st103 {
  fill: #403e8f;
}
.st104 {
  fill: #447fae;
}
.st105 {
  fill: #bdecfe;
}
.st106 {
  fill: #3e5597;
}
.st107 {
  fill: #5085c6;
}
.st108 {
  fill: #6c8cc0;
}
.st109 {
  fill: #4772b1;
}
.st110 {
  fill: #3a4494;
}
.st111 {
  fill: #4c609e;
}
.st112 {
  fill: #4a5d9b;
}
.st113 {
  fill: #4971b2;
}
.st114 {
  fill: #7086bc;
}
.st115 {
  fill: #51669e;
}
.st116 {
  fill: #556da1;
}
.st117 {
  fill: #788cc6;
}
.st118 {
  fill: #788ac4;
}
.st119 {
  fill: #7991c3;
}
.st120 {
  fill: #768ec1;
}
.st121 {
  fill: #8ca9e2;
}
.st122 {
  fill: #7e96c6;
}
.st123 {
  fill: #8aa2d7;
}
.st124 {
  fill: #3c5699;
}
.st125 {
  fill: #394594;
}
.st126 {
  fill: #2c4c87;
}
.st127 {
  fill: #93c2ef;
}
.st128 {
  fill: #3a4e97;
}
.st129 {
  fill: #94b6e6;
}
.st130 {
  fill: #80aed8;
}
.st131 {
  fill: #6f95d3;
}
.st132 {
  fill: #426aa4;
}
.st133 {
  fill: #3e6aa2;
}
.st134 {
  fill: #3d4c8e;
}
.st135 {
  fill: #7386bd;
}
.st136 {
  fill: #8496d3;
}
.st137 {
  fill: #8ba5dd;
}
.st138 {
  fill: #97a7df;
}
.st139 {
  fill: #8a9dd7;
}
.st140 {
  fill: #8497d0;
}
.st141 {
  fill: #7f8ed8;
}
.st142 {
  fill: #92afe6;
}
.st143 {
  fill: #8cb3eb;
}
.st144 {
  fill: #9abbf1;
}
.st145 {
  fill: #4656a0;
}
.st146 {
  fill: #a2c9f7;
}
.st147 {
  fill: #7cade0;
}
.st148 {
  fill: #647bb7;
}
.st149 {
  fill: #98b2ef;
}
.st150 {
  fill: #6683b5;
}
.st151 {
  fill: #7d9edc;
}
.st152 {
  fill: #8298cc;
}
.st153 {
  fill: #6179b1;
}
.st154 {
  fill: #8da5d8;
}
.st155 {
  fill: #a4bef0;
}
.st156 {
  fill: #5c77ac;
}
.st157 {
  fill: #899fd5;
}
.st158 {
  fill: #a1bde7;
}
.st159 {
  fill: #9dc7de;
}
.st160 {
  fill: #3d2e7a;
}
.st161 {
  fill: #41508d;
}
.st162 {
  fill: #3d5399;
}
.st163 {
  fill: #42488f;
}
.st164 {
  fill: #506292;
}
.st165 {
  fill: #435496;
}
.st166 {
  fill: #4c609d;
}
.st167 {
  fill: #566da9;
}
.st168 {
  fill: #5b71a9;
}
.st169 {
  fill: #889dd4;
}
.st170 {
  fill: #7c90c6;
}
.st171 {
  fill: #7185bd;
}
.st172 {
  fill: #8da3d8;
}
.st173 {
  fill: #91abda;
}
.st174 {
  fill: #8da5e1;
}
.st175 {
  fill: #6e80c5;
}
.st176 {
  fill: #849bd6;
}
.st177 {
  fill: #8da2da;
}
.st178 {
  fill: #8fa9dc;
}
.st179 {
  fill: #788ec4;
}
.st180 {
  fill: #94a9df;
}
.st181 {
  fill: #738ac8;
}
.st182 {
  fill: #464e96;
}
.st183 {
  fill: #414d93;
}
.st184 {
  fill: #4665a9;
}
.st185 {
  fill: #778ebc;
}
.st186 {
  fill: #a0aee2;
}
.st187 {
  fill: #aebef2;
}
.st188 {
  fill: #3e3a85;
}
.st189 {
  fill: #5967a8;
}
.st190 {
  fill: #5f70b7;
}
.st191 {
  fill: #6d7fc1;
}
.st192 {
  fill: #6b7bbe;
}
.st193 {
  fill: #6877ba;
}
.st194 {
  fill: #6d86c9;
}
.st195 {
  fill: #849ee3;
}
.st196 {
  fill: #5464ab;
}
.st197 {
  fill: #7184c3;
}
.st198 {
  fill: #7a93d7;
}
.st199 {
  fill: #5e62a0;
}
.st200 {
  fill: #7f93d4;
}
.st201 {
  fill: #7f95d6;
}
.st202 {
  fill: #687cc9;
}
.st203 {
  fill: #7084ca;
}
.st204 {
  fill: #7387ca;
}
.st205 {
  fill: #768bcc;
}
.st206 {
  fill: #445ca1;
}
.st207 {
  fill: #5074b6;
}
.st208 {
  fill: #434889;
}
.st209 {
  fill: #90abe5;
}
.st210 {
  fill: #778dcf;
}
.st211 {
  fill: #6a88c1;
}
.st212 {
  fill: #6e89c9;
}
.st213 {
  fill: #6888cb;
}
.st214 {
  fill: #425099;
}
.st215 {
  fill: #58a7d1;
}
.st216 {
  fill: #476cb4;
}
.st217 {
  fill: #98ccf6;
}
.st218 {
  fill: #87d3e4;
}
.st219 {
  fill: #5d89c3;
}
.st220 {
  fill: #5d75ad;
}
.st221 {
  fill: #9cb1e4;
}
.st222 {
  fill: #859ad5;
}
.st223 {
  fill: #7a90c4;
}
.st224 {
  fill: #839acd;
}
.st225 {
  fill: #90a8dd;
}
.st226 {
  fill: #6278b0;
}
.st227 {
  fill: #7a93ca;
}
.st228 {
  fill: #94aad9;
}
.st229 {
  fill: #a1b8e9;
}
.st230 {
  fill: #8ea6da;
}
.st231 {
  fill: #a9c3ec;
}
.st232 {
  fill: #9cb6e4;
}
.st233 {
  fill: #8fa8d7;
}
.st234 {
  fill: #a6b6f0;
}
.st235 {
  fill: #a5bee9;
}
.st236 {
  fill: #3f5a9c;
}
.st237 {
  fill: #98b3de;
}
.st238 {
  fill: #98b2df;
}
.st239 {
  fill: #4361a6;
}
.st240 {
  fill: #5b75ac;
}
.st241 {
  fill: #98b6e4;
}
.st242 {
  fill: #85a1d4;
}
.st243 {
  fill: #8da8d6;
}
.st244 {
  fill: #7e94c8;
}
.st245 {
  fill: #8ca3d1;
}
.st246 {
  fill: #889fd3;
}
.st247 {
  fill: #88a2da;
}
.st248 {
  fill: #7b91c4;
}
.st249 {
  fill: #8aa0db;
}
.st250 {
  fill: #96ace5;
}
.st251 {
  fill: #4d83c3;
}
.st252 {
  fill: #578fc9;
}
.st253 {
  fill: #8199cc;
}
.st254 {
  fill: #3c5ca7;
}
.st255 {
  fill: #90acea;
}
.st256 {
  fill: #90b2e9;
}
.st257 {
  fill: #8bb0e8;
}
.st258 {
  fill: #4e6fa5;
}
.st259 {
  fill: #7997da;
}
.st260 {
  fill: #a4c7ec;
}
.st261 {
  fill: #779ddb;
}
.st262 {
  fill: #a0beed;
}
.st263 {
  fill: #7d9edd;
}
.st264 {
  fill: #a2c9ef;
}
.st265 {
  fill: #83a5e2;
}
.st266 {
  fill: #b3d1ee;
}
.st267 {
  fill: #658bd0;
}
.st268 {
  fill: #93b5ef;
}
.st269 {
  fill: #6787d4;
}
.st270 {
  fill: #83a9e2;
}
.st271 {
  fill: #bdf6f7;
}
.st272 {
  fill: #96dff5;
}
.st273 {
  fill: #475095;
}
.st274 {
  fill: #5560a5;
}
.st275 {
  fill: #6368a3;
}
.st276 {
  fill: #6570b8;
}
.st277 {
  fill: #605aa0;
}
.st278 {
  fill: #828fce;
}
.st279 {
  fill: #6781af;
}
.st280 {
  fill: #6b79b3;
}
.st281 {
  fill: #7b81be;
}
.st282 {
  fill: #6369ae;
}
.st283 {
  fill: #7b84c0;
}
.st284 {
  fill: #858ac4;
}
.st285 {
  fill: #6b80b1;
}
.st286 {
  fill: #acc9e7;
}
.st287 {
  fill: #b3d1f3;
}
.st288 {
  fill: #a1b4ef;
}
.st289 {
  fill: #454589;
}
.st290 {
  fill: #95b0e4;
}
.st291 {
  fill: #5674b4;
}
.st292 {
  fill: #6e80b6;
}
.st293 {
  fill: #768cc1;
}
.st294 {
  fill: #768cc3;
}
.st295 {
  fill: #adc0f5;
}
.st296 {
  fill: #8ea4d8;
}
.st297 {
  fill: #7896d7;
}
.st298 {
  fill: #7e92cc;
}
.st299 {
  fill: #7a97ca;
}
.st300 {
  fill: #8297d4;
}
.st301 {
  fill: #6f7fbf;
}
.st302 {
  fill: #99a7d9;
}
.st303 {
  fill: #92a6d8;
}
.st304 {
  fill: #4d6099;
}
.st305 {
  fill: #5d6db1;
}
.st306 {
  fill: #6c7eb6;
}
.st307 {
  fill: #7f92cb;
}
.st308 {
  fill: #9aade7;
}
.st309 {
  fill: #9cb4ec;
}
.st310 {
  fill: #7786c7;
}
.st311 {
  fill: #8696d2;
}
.st312 {
  fill: #8699db;
}
.st313 {
  fill: #8a9fd6;
}
.st314 {
  fill: #98aade;
}
.st315 {
  fill: #b1c4f6;
}
.st316 {
  fill: #8093d4;
}
.st317 {
  fill: #7589be;
}
.st318 {
  fill: #4f6597;
}
.st319 {
  fill: #536db0;
}
.st320 {
  fill: #e3f9f5;
}
.st321 {
  fill: #e7f9f9;
}
.st322 {
  fill: #4c82be;
}
.st323 {
  fill: #7785c6;
}
.st324 {
  fill: #788cca;
}
.st325 {
  fill: #3e4c91;
}
.st326 {
  fill: #2f3c85;
}
.st327 {
  fill: #4d91c3;
}
.st328 {
  fill: #86d6ec;
}
.st329 {
  fill: #4f6b9f;
}
.st330 {
  fill: #4b669c;
}
.st331 {
  fill: #5f7ab9;
}
.st332 {
  fill: #8097d7;
}
.st333 {
  fill: #5d78b7;
}
.st334 {
  fill: #9dbaf9;
}
.st335 {
  fill: #a2b9e6;
}
.st336 {
  fill: #a4bff0;
}
.st337 {
  fill: #708bc7;
}
.st338 {
  fill: #8ca7df;
}
.st339 {
  fill: #7a94c9;
}
.st340 {
  fill: #aac3f6;
}
.st341 {
  fill: #829bd3;
}
.st342 {
  fill: #859bd6;
}
.st343 {
  fill: #7990d8;
}
.st344 {
  fill: #7693c6;
}
.st345 {
  fill: #3d5c8b;
}
.st346 {
  fill: #b1c3ef;
}
.st347 {
  fill: #7e99ce;
}
.st348 {
  fill: #7a8fc9;
}
.st349 {
  fill: #7790cd;
}
.st350 {
  fill: #8ca1e2;
}
.st351 {
  fill: #b7c8ee;
}
.st352 {
  fill: #a3bbef;
}
.st353 {
  fill: #4f7abc;
}
.st354 {
  fill: #c7f6fa;
}
.st355 {
  fill: #4c7cba;
}
.st356 {
  fill: #aaf9fc;
}
.st357 {
  fill: #518bc7;
}
.st358 {
  fill: #568ec4;
}
.st359 {
  fill: #caf9fa;
}
.st360 {
  fill: #cbf9fb;
}
.st361 {
  fill: #87a9e1;
}
.st362 {
  fill: #61bce0;
}
.st363 {
  fill: #67b5df;
}
.st364 {
  fill: #8cb1e5;
}
.st365 {
  fill: #90b4ee;
}
.st366 {
  fill: #8cb4e8;
}
.st367 {
  fill: #83a9e6;
}
.st368 {
  fill: #91b3eb;
}
.st369 {
  fill: #6c93d0;
}
.st370 {
  fill: #8bb1e9;
}
.st371 {
  fill: #8eb5e2;
}
.st372 {
  fill: #5b83b0;
}
.st373 {
  fill: #718bcc;
}
.st374 {
  fill: #5273b3;
}
.st375 {
  fill: #7090cc;
}
.st376 {
  fill: #94b0e3;
}
.st377 {
  fill: #6f8acb;
}
.st378 {
  fill: #8299d4;
}
.st379 {
  fill: #8ea9e3;
}
.st380 {
  fill: #7896de;
}
.st381 {
  fill: #7391d8;
}
.st382 {
  fill: #62c9e4;
}
.st383 {
  fill: #75d8f2;
}
.st384 {
  fill: #55b3d3;
}
.st385 {
  fill: #8592d4;
}
.st386 {
  fill: #2f3f79;
}
.st387 {
  fill: #7f8fd2;
}
.st388 {
  fill: #445c9f;
}
.st389 {
  fill: #7686c5;
}
.st390 {
  fill: #66aadd;
}
.st391 {
  fill: #86d8e8;
}
.st392 {
  fill: #70e3ef;
}
.st393 {
  fill: #65d1f0;
}
.st394 {
  fill: #63d8e4;
}
.st395 {
  fill: #6ce1f4;
}
.st396 {
  fill: #5ad4e8;
}
.st397 {
  fill: #baf0f0;
}
.st398 {
  fill: #5fa7d5;
}
.st399 {
  fill: #8de5ef;
}
.st400 {
  fill: #68cfe9;
}
.st401 {
  fill: #8ae7ef;
}
.st402 {
  fill: #b6f2ec;
}
.st403 {
  fill: #9eeff1;
}
.st404 {
  fill: #7bd6f0;
}
.st405 {
  fill: #7ce1f2;
}
.st406 {
  fill: #8af1f2;
}
.st407 {
  fill: #9ee5ec;
}
.st408 {
  fill: #72d8ed;
}
.st409 {
  fill: #a1eefa;
}
.st410 {
  fill: #93e7ef;
}
.st411 {
  fill: #5fe6ee;
}
.st412 {
  fill: #a8f8f1;
}
.st413 {
  fill: #b4f1ef;
}
.st414 {
  fill: #abe8ea;
}
.st415 {
  fill: #829ed3;
}
.st416 {
  fill: #415e88;
}
.st417 {
  fill: #5cbfda;
}
.st418 {
  fill: #8ceef5;
}
.st419 {
  fill: #64c2dd;
}
.st420 {
  fill: #c3fcfe;
}
.st421 {
  fill: #91e6f2;
}
.st422 {
  fill: #9eeff5;
}
.st423 {
  fill: #aee8f8;
}
.st424 {
  fill: #cbf2fa;
}
.st425 {
  fill: #e6fafb;
}
.st426 {
  fill: #88e4fb;
}
.st427 {
  fill: #e9ecfb;
}
.st428 {
  fill: #78b8ec;
}
.st429 {
  fill: #fcfdfe;
}
.st430 {
  fill: #cccee8;
}
.st431 {
  fill: #ae3478;
}
.st432 {
  fill: #553869;
}
.st433 {
  fill: #f9ae98;
}
.st434 {
  fill: #634a7d;
}
.st435 {
  fill: #cad1f7;
}
.st436 {
  fill: #b2b8ec;
}
.st437 {
  fill: #53325e;
}
.st438 {
  fill: #dde0f8;
}
.st439 {
  fill: #f47e70;
}
.st440 {
  fill: #f9b29a;
}
.st441 {
  fill: #f58c79;
}
.st442 {
  fill: #af3578;
}
.st443 {
  fill: #88235d;
}
.st444 {
  fill: #f3766a;
}
.st445 {
  fill: #b83878;
}
.st446 {
  fill: #fbfcfc;
}
.st447 {
  fill: #684a79;
}
.st448 {
  fill: #c24383;
}
.st449 {
  fill: #54345e;
}
.st450 {
  fill: #6c316b;
}
.st451 {
  fill: #f47e6f;
}
.st452 {
  fill: #c8d0f0;
}
.st453 {
  fill: #77dabe;
}
.st454 {
  fill: #a9f19e;
}
.st455 {
  fill: #4f3a60;
}
.st456 {
  fill: #b6bce8;
}
.st457 {
  fill: #c7ceef;
}
.st458 {
  fill: #dcdff8;
}
.st459 {
  fill: #d5daf5;
}
.st460 {
  fill: #b0b2e1;
}
.st461 {
  fill: #b0b7e2;
}
.st462 {
  fill: #c1c5e9;
}
.st463 {
  fill: #bac1e9;
}
.st464 {
  fill: #bfc3e5;
}
.st465 {
  fill: #d8dcef;
}
.st466 {
  fill: #b6b9ea;
}
.st467 {
  fill: #b7b8dd;
}
.st468 {
  fill: #665591;
}
.st469 {
  fill: #a09bcc;
}
.st470 {
  fill: #4e315c;
}
.st471 {
  fill: #b0b6e1;
}
.st472 {
  fill: #6e5586;
}
.st473 {
  fill: #614376;
}
.st474 {
  fill: #b0b6dc;
}
.st475 {
  fill: #e5e2f0;
}
.st476 {
  fill: #634578;
}
.st477 {
  fill: #d9cfe3;
}
.st478 {
  fill: #634677;
}
.st479 {
  fill: #c4b2d2;
}
.st480 {
  fill: #b4a1c0;
}
.st481 {
  fill: #ca4689;
}
.st482 {
  fill: #982959;
}
.st483 {
  fill: #f3867c;
}
.st484 {
  fill: #693c50;
}
.st485 {
  fill: #5e3854;
}
.st486 {
  fill: #6c3754;
}
.st487 {
  fill: #f37862;
}
.st488 {
  fill: #663652;
}
.st489 {
  fill: #a43061;
}
.st490 {
  fill: #8a6791;
}
.st491 {
  fill: #83628e;
}
.st492 {
  fill: #805f8a;
}
.st493 {
  fill: #87658f;
}
.st494 {
  fill: #f79f89;
}
.st495 {
  fill: #5d4a79;
}
.st496 {
  fill: #f79e85;
}
.st497 {
  fill: #972d6b;
}
.st498 {
  fill: #e7e9f5;
}
.st499 {
  fill: #933665;
}
.st500 {
  fill: #913061;
}
.st501 {
  fill: #842559;
}
.st502 {
  fill: #f9b39b;
}
.st503 {
  fill: #a53272;
}
.st504 {
  fill: #f79c85;
}
.st505 {
  fill: #d84f8a;
}
.st506 {
  fill: #7a69ac;
}
.st507 {
  fill: #5a416e;
}
.st508 {
  fill: #a8afe7;
}
.st509 {
  fill: #5c4471;
}
.st510 {
  fill: #583f6a;
}
.st511 {
  fill: #9698bd;
}
.st512 {
  fill: #ada4d0;
}
.st513 {
  fill: #f6f6fa;
}
.st514 {
  fill: #9f9bcc;
}
.st515 {
  fill: #ebecf5;
}
.st516 {
  fill: #8570a1;
}
.st517 {
  fill: #845484;
}
.st518 {
  fill: #a4a5cc;
}
.st519 {
  fill: #705787;
}
.st520 {
  fill: #e2d4e6;
}
.st521 {
  fill: #e3d6e8;
}
.st522 {
  fill: #4f405f;
}
.st523 {
  fill: #8b749d;
}
.st524 {
  fill: #cdb6c7;
}
.st525 {
  fill: #c7a7b4;
}
.st526 {
  fill: #e66f65;
}
.st527 {
  fill: #e67066;
}
.st528 {
  fill: #a5a8e3;
}
.st529 {
  fill: #bf3f83;
}
.st530 {
  fill: #f07464;
}
.st531 {
  fill: #6a588f;
}
.st532 {
  fill: #94a8d4;
}
.st533 {
  fill: #9badd6;
}
.st534 {
  fill: #ecf4fb;
}
.st535 {
  fill: #ccdff5;
}
.st536 {
  fill: #d0f3fb;
}
.st537 {
  fill: #c8ebf8;
}
.st538 {
  fill: #dbeff4;
}
.st539 {
  fill: #cdf2fa;
}
.st540 {
  fill: #d1f3fb;
}
.st541 {
  fill: #cff2fa;
}
.st542 {
  fill: #ace0fa;
}
.st543 {
  fill: #c5e1fa;
}
.st544 {
  fill: #ceeffa;
}
.st545 {
  fill: #fbfdfd;
}
.st546 {
  fill: #f8fbfb;
}
.st547 {
  fill: #fafcfc;
}
.st548 {
  fill: #c1c9dd;
}
.st549 {
  fill: #e8f2fc;
}
.st550 {
  fill: #b8c4d7;
}
.st551 {
  fill: #d9e0eb;
}
.st552 {
  fill: #c9cfe0;
}
.st553 {
  fill: #ccd5e4;
}
.st554 {
  fill: #c7c8d8;
}
.st555 {
  fill: #e7f1fb;
}
.st556 {
  fill: #a3b0c9;
}
.st557 {
  fill: #a6b0ce;
}
.st558 {
  fill: #eaf4f9;
}
.st559 {
  fill: #fefefe;
}
.st560 {
  fill: #f4f7fb;
}
.st561 {
  fill: #e5edf5;
}
.st562 {
  fill: #98a7c6;
}
.st563 {
  fill: #adb9d7;
}
.st564 {
  fill: #afbade;
}
.st565 {
  fill: #9ca9c2;
}
.st566 {
  fill: #c4c8e2;
}
.st567 {
  fill: #defcfc;
}
.st568 {
  fill: #798ab4;
}
.st569 {
  fill: #f8fdff;
}
.st570 {
  fill: #d0eaf8;
}
.st571 {
  fill: #fd3a3c;
}
.st572 {
  fill: #8095c7;
}
.st573 {
  fill: #8a9dcd;
}
.st574 {
  fill: #bdcde8;
}
.st575 {
  fill: #e7f3fa;
}
.st576 {
  fill: #b1c2e3;
}
.st577 {
  fill: #d4e3f3;
}
.st578 {
  fill: #c5d4ed;
}
.st579 {
  fill: #f95b53;
}
.st580 {
  fill: #a3b4d5;
}
.st581 {
  fill: #e2f8fd;
}
.st582 {
  fill: #e0f8fc;
}
.st583 {
  fill: #c1bfe4;
}
.st584 {
  fill: #dbf4fb;
}
.st585 {
  fill: #5b413d;
}
.st586 {
  fill: #dbf5f9;
}
.st587 {
  fill: #e0f9fc;
}
.st588 {
  fill: #bfc8da;
}
.st589 {
  fill: #e5dae2;
}
.st590 {
  fill: #d1edf9;
}
.st591 {
  fill: #d9f3fa;
}
.st592 {
  fill: #d2f0fa;
}
.st593 {
  fill: #f8f4f3;
}
.st594 {
  fill: #e8edce;
}
.st595 {
  fill: #040343;
}
.st596 {
  fill: #080a39;
}
.st597 {
  fill: #05063b;
}
.st598 {
  fill: #050535;
}
.st599 {
  fill: #06082f;
}
.st600 {
  fill: #140a30;
}
.st601 {
  fill: #05091d;
}
.st602 {
  fill: #eb8a4c;
}
.st603 {
  fill: #30163a;
}
.st604 {
  fill: #b2c3ce;
}
.st605 {
  fill: #b4c8d1;
}
.st606 {
  fill: #b9ccd3;
}
.st607 {
  fill: #b7cdd2;
}
.st608 {
  fill: #bdcdd0;
}
.st609 {
  fill: #008284;
}
.st610 {
  fill: #ee3327;
}
.st611 {
  fill: #026ac8;
}
.st612 {
  fill: #008385;
}
.st613 {
  fill: #40d4fc;
}
.st614 {
  fill: #2a4c90;
}
.st615 {
  fill: #2e4d91;
}
.st616 {
  fill: #4663a8;
}
.st617 {
  fill: #f8eee1;
}
.st618 {
  fill: #e5f9ef;
}
.st619 {
  fill: #4861a8;
}
.st620 {
  fill: #cf053a;
}
.st621 {
  fill: #f3efe6;
}
.st622 {
  fill: #5070b5;
}
.st623 {
  fill: #cb0439;
}
.st624 {
  fill: #536bb7;
}
.st625 {
  fill: #060535;
}
.st626 {
  fill: #526fba;
}
.st627 {
  fill: #09727a;
}
.st628 {
  fill: #3d6cb3;
}
.st629 {
  fill: #061153;
}
.st630 {
  fill: #f0f2ea;
}
.st631 {
  fill: #18c0b0;
}
.st632 {
  fill: #f2f4e3;
}
.st633 {
  fill: #526ebe;
}
.st634 {
  fill: #021436;
}
.st635 {
  fill: #f0f5e4;
}
.st636 {
  fill: #135dae;
}
.st637 {
  fill: #f5f3c6;
}
.st638 {
  fill: #dbcbb9;
}
.st639 {
  fill: #556bac;
}
.st640 {
  fill: #21c2bb;
}
.st641 {
  fill: #fbf4bb;
}
.st642 {
  fill: #17c7ec;
}
.st643 {
  fill: #da181a;
}
.st644 {
  fill: #406dae;
}
.st645 {
  fill: #c52e40;
}
.st646 {
  fill: #030423;
}
.st647 {
  fill: #0387ce;
}
.st648 {
  fill: #eeecda;
}
.st649 {
  fill: #d0d9cb;
}
.st650 {
  fill: #d63b0e;
}
.st651 {
  fill: #0b79ca;
}
.st652 {
  fill: #0e79cc;
}
.st653 {
  fill: #03a094;
}
.st654 {
  fill: #dc620c;
}
.st655 {
  fill: #e5750a;
}
.st656 {
  fill: #d11c1f;
}
.st657 {
  fill: #106b79;
}
.st658 {
  fill: #0b7dc6;
}
.st659 {
  fill: #050224;
}
.st660 {
  fill: #d72023;
}
.st661 {
  fill: #050c36;
}
.st662 {
  fill: #c32e3b;
}
.st663 {
  fill: #d51e23;
}
.st664 {
  fill: #5cdaf0;
}
.st665 {
  fill: #dfc1a1;
}
.st666 {
  fill: #f3e9ad;
}
.st667 {
  fill: #b3647b;
}
.st668 {
  fill: #0d6979;
}
.st669 {
  fill: #eb7035;
}
.st670 {
  fill: #ee7230;
}
.st671 {
  fill: #f1d8b5;
}
.st672 {
  fill: #566da6;
}
.st673 {
  fill: #020321;
}
.st674 {
  fill: #ee6f32;
}
.st675 {
  fill: #d8c8c2;
}
.st676 {
  fill: #ed6f34;
}
.st677 {
  fill: #f2f6ec;
}
.st678 {
  fill: #e7d8b8;
}
.st679 {
  fill: #3a3e70;
}
.st680 {
  fill: #115db0;
}
.st681 {
  fill: #cfc1c7;
}
.st682 {
  fill: #4069a5;
}
.st683 {
  fill: #dc7f23;
}
.st684 {
  fill: #020424;
}
.st685 {
  fill: #276c87;
}
.st686 {
  fill: #513931;
}
.st687 {
  fill: #1f2b7b;
}
.st688 {
  fill: #faecda;
}
.st689 {
  fill: #cec8d2;
}
.st690 {
  fill: #ccc3ca;
}
.st691 {
  fill: #eff1de;
}
.st692 {
  fill: #4ccfca;
}
.st693 {
  fill: #4ccdee;
}
.st694 {
  fill: #f0f1de;
}
.st695 {
  fill: #424070;
}
.st696 {
  fill: #f1dfb2;
}
.st697 {
  fill: #162670;
}
.st698 {
  fill: #4b6095;
}
.st699 {
  fill: #3d4280;
}
.st700 {
  fill: #142876;
}
.st701 {
  fill: #e38a4d;
}
.st702 {
  fill: #0e236a;
}
.st703 {
  fill: #f1efde;
}
.st704 {
  fill: #3b3a73;
}
.st705 {
  fill: #192a78;
}
.st706 {
  fill: #edf1e1;
}
.st707 {
  fill: #5d71a0;
}
.st708 {
  fill: #3a2720;
}
.st709 {
  fill: #e74251;
}
.st710 {
  fill: #edefe6;
}
.st711 {
  fill: #cdc6d0;
}
.st712 {
  fill: #0d2576;
}
.st713 {
  fill: #152470;
}
.st714 {
  fill: #081362;
}
.st715 {
  fill: #18246d;
}
.st716 {
  fill: #f6f3d9;
}
.st717 {
  fill: #0c156f;
}
.st718 {
  fill: #757abc;
}
.st719 {
  fill: #786db3;
}
.st720 {
  fill: #172c76;
}
.st721 {
  fill: #0c1859;
}
.st722 {
  fill: #e84750;
}
.st723 {
  fill: #14206d;
}
.st724 {
  fill: #12226e;
}
.st725 {
  fill: #eff1eb;
}
.st726 {
  fill: #747eab;
}
.st727 {
  fill: #7f7eba;
}
.st728 {
  fill: #32261d;
}
.st729 {
  fill: #b993ac;
}
.st730 {
  fill: #807cae;
}
.st731 {
  fill: #354c89;
}
.st732 {
  fill: #dc604c;
}
.st733 {
  fill: #a7aba8;
}
.st734 {
  fill: #ec8950;
}
.st735 {
  fill: #2e3361;
}
.st736 {
  fill: #c95353;
}
.st737 {
  fill: #de7e5e;
}
.st738 {
  fill: #e6b36f;
}
.st739 {
  fill: #f5f7da;
}
.st740 {
  fill: #daf8e5;
}
.st741 {
  fill: #dcf7e5;
}
.st742 {
  fill: #dff9e6;
}
.st743 {
  fill: #eff1e8;
}
.st744 {
  fill: #1c4088;
}
.st745 {
  fill: #1a6faf;
}
.st746 {
  fill: #203a8b;
}
.st747 {
  fill: #0c3573;
}
.st748 {
  fill: #7778a9;
}
.st749 {
  fill: #6d82b2;
}
.st750 {
  fill: #938ec8;
}
.st751 {
  fill: #c7004b;
}
.st752 {
  fill: #f6e7db;
}
.st753 {
  fill: #b30838;
}
.st754 {
  fill: #ed275c;
}
.st755 {
  fill: #14256c;
}
.st756 {
  fill: #4c4e9b;
}
.st757 {
  fill: #f6f7dc;
}
.st758 {
  fill: #f1edeb;
}
.st759 {
  fill: #bb90a0;
}
.st760 {
  fill: #faf8b7;
}
.st761 {
  fill: #d37b10;
}
.st762 {
  fill: #eb8605;
}
.st763 {
  fill: #eda043;
}
.st764 {
  fill: #d78236;
}
.st765 {
  fill: #eb8a4b;
}
.st766 {
  fill: #1a3251;
}
.st767 {
  fill: #ed7d08;
}
.st768 {
  fill: #d27408;
}
.st769 {
  fill: #da812e;
}
.st770 {
  fill: #d77a2f;
}
.st771 {
  fill: #fdc900;
}
.st772 {
  fill: #f34c1d;
}
.st773 {
  fill: #f7e390;
}
.st774 {
  fill: #f84f15;
}
.st775 {
  fill: #26c8ee;
}
.st776 {
  fill: #14a4f4;
}
.st777 {
  fill: #f63f1b;
}
.st778 {
  fill: #d9570e;
}
.st779 {
  fill: #f5e190;
}
.st780 {
  fill: #f97c3c;
}
.st781 {
  fill: #f1dea6;
}
.st782 {
  fill: #eff3ee;
}
.st783 {
  fill: #aa678d;
}
.st784 {
  fill: #cb6613;
}
.st785 {
  fill: #c85745;
}
.st786 {
  fill: #18317a;
}
.st787 {
  fill: #e5760d;
}
.st788 {
  fill: #4c2a2d;
}
.st789 {
  fill: #a96272;
}
.st790 {
  fill: #deefe9;
}
.st791 {
  fill: #b678a6;
}
.st792 {
  fill: #1b3759;
}
.st793 {
  fill: #1a3052;
}
.st794 {
  fill: #c76880;
}
.st795 {
  fill: #bf9b98;
}
.st796 {
  fill: #fe1760;
}
.st797 {
  fill: #3164ba;
}
.st798 {
  fill: #c23655;
}
.st799 {
  fill: #203660;
}
.st800 {
  fill: #1a3464;
}
.st801 {
  fill: #232b61;
}
.st802 {
  fill: #21305c;
}
.st803 {
  fill: #1f2f5d;
}
.st804 {
  fill: #00b8a2;
}
.st805 {
  fill: #1f957a;
}
.st806 {
  fill: #229a83;
}
.st807 {
  fill: #eff4ea;
}
.st808 {
  fill: #c76c7c;
}
.st809 {
  fill: #8a587c;
}
.st810 {
  fill: #ba4062;
}
.st811 {
  fill: #b885ad;
}
.st812 {
  fill: #d889c2;
}
.st813 {
  fill: #ce89b8;
}
.st814 {
  fill: #d079b4;
}
.st815 {
  fill: #d1b9a4;
}
.st816 {
  fill: #0bb09d;
}
.st817 {
  fill: #455cb7;
}
.st818 {
  fill: #c76784;
}
.st819 {
  fill: #233d6e;
}
.st820 {
  fill: #193355;
}
.st821 {
  fill: #fe8200;
}
.st822 {
  fill: #db2116;
}
.st823 {
  fill: #f7f4bc;
}
.st824 {
  fill: #cf5047;
}
.st825 {
  fill: #b47dc1;
}
.st826 {
  fill: #73dcdd;
}
.st827 {
  fill: #fccc01;
}
.st828 {
  fill: #fbfbd5;
}
.st829 {
  fill: #fe7f00;
}
.st830 {
  fill: #c81b07;
}
.st831 {
  fill: #da2911;
}
.st832 {
  fill: #bfcda9;
}
.st833 {
  fill: #fbce00;
}
.st834 {
  fill: #fcc928;
}
.st835 {
  fill: #f5d650;
}
.st836 {
  fill: #ecc745;
}
.st837 {
  fill: #eac64a;
}
.st838 {
  fill: #e7c748;
}
.st839 {
  fill: #f3d145;
}
.st840 {
  fill: #c71808;
}
.st841 {
  fill: #fbf4c4;
}
.st842 {
  fill: #d59794;
}
.st843 {
  fill: #fbf2d3;
}
.st844 {
  fill: #d9b98d;
}
.st845 {
  fill: #facd00;
}
.st846 {
  fill: #fbf4cc;
}
.st847 {
  fill: #d4be99;
}
.st848 {
  fill: #342619;
}
.st849 {
  fill: #3e2c28;
}
.st850 {
  fill: #e9c74c;
}
.st851 {
  fill: #2c66b6;
}
.st852 {
  fill: #754a8c;
}
.st853 {
  fill: #e38106;
}
.st854 {
  fill: #d9be99;
}
.st855 {
  fill: #daba9e;
}
.st856 {
  fill: #c7a1a5;
}
.st857 {
  fill: #3b271c;
}
.st858 {
  fill: #402d20;
}
.st859 {
  fill: #412824;
}
.st860 {
  fill: #3f3222;
}
.st861 {
  fill: #69d8f5;
}
.st862 {
  fill: #ca8d81;
}
.st863 {
  fill: #faf6c8;
}
.st864 {
  fill: #cb5a6b;
}
.st865 {
  fill: #e3800a;
}
.st866 {
  fill: #f3d440;
}
.st867 {
  fill: #1a3a5c;
}
.st868 {
  fill: #d0968f;
}
.st869 {
  fill: #bd5a82;
}
.st870 {
  fill: #d79494;
}
.st871 {
  fill: #69468c;
}
.st872 {
  fill: #de0b41;
}
.st873 {
  fill: #f1d437;
}
.st874 {
  fill: #c66f02;
}
.st875 {
  fill: #f1d138;
}
.st876 {
  fill: #e68c48;
}
.st877 {
  fill: #f9d582;
}
.st878 {
  fill: #eac647;
}
.st879 {
  fill: #b2607f;
}
.st880 {
  fill: #f6eb85;
}
.st881 {
  fill: #e8c750;
}
.st882 {
  fill: #c9dddc;
}
.st883 {
  fill: #cee4e2;
}
.st884 {
  fill: #daede6;
}
.st885 {
  fill: #44829b;
}
.st886 {
  fill: #073d59;
}
.st887 {
  fill: #8f011d;
}
.st888 {
  fill: #7ab0bc;
}
.st889 {
  fill: #012a42;
}
.st890 {
  fill: #fbfbee;
}
.st891 {
  fill: #a91837;
}
.st892 {
  fill: #b42542;
}
.st893 {
  fill: #f7f5e5;
}
.st894 {
  fill: #aa1f3b;
}
.st895 {
  fill: #a91c3a;
}
.st896 {
  fill: #a01536;
}
.st897 {
  fill: #fa6277;
}
.st898 {
  fill: #063e5c;
}
.st899 {
  fill: #980322;
}
.st900 {
  fill: #fa617a;
}
.st901 {
  fill: #f9fae8;
}
.st902 {
  fill: #950422;
}
.st903 {
  fill: #315b73;
}
.st904 {
  fill: #950223;
}
.st905 {
  fill: #7296a3;
}
.st906 {
  fill: #6b939f;
}
.st907 {
  fill: #fa516b;
}
.st908 {
  fill: #fa7082;
}
.st909 {
  fill: #f86c7d;
}
.st910 {
  fill: #3c6073;
}
.st911 {
  fill: #516e7f;
}
.st912 {
  fill: #e5e7df;
}
.st913 {
  fill: #f1415c;
}
.st914 {
  fill: #587889;
}
.st915 {
  fill: #04405d;
}
.st916 {
  fill: #f9f9ea;
}
.st917 {
  fill: #e93853;
}
.st918 {
  fill: #a29b9c;
}
.st919 {
  fill: #4e6a7a;
}
.st920 {
  fill: #98021f;
}
.st921 {
  fill: #4a99ab;
}
.st922 {
  fill: #e5ded2;
}
.st923 {
  fill: #dc2e4b;
}
.st924 {
  fill: #3c879e;
}
.st925 {
  fill: #4c96aa;
}
.st926 {
  fill: #d2c7bf;
}
.st927 {
  fill: #1d6980;
}
.st928 {
  fill: #7c9ea8;
}
.st929 {
  fill: #0a4b63;
}
.st930 {
  fill: #345974;
}
.st931 {
  fill: #3a6477;
}
.st932 {
  fill: #69a2b0;
}
.st933 {
  fill: #3a6479;
}
.st934 {
  fill: #e73954;
}
.st935 {
  fill: #4b98ab;
}
.st936 {
  fill: #d52e4a;
}
.st937 {
  fill: #c21e3d;
}
.st938 {
  fill: #cd2744;
}
.st939 {
  fill: #6c95a3;
}
.st940 {
  fill: #f6efdd;
}
.st941 {
  fill: #6da2af;
}
.st942 {
  fill: #d8cec5;
}
.st943 {
  fill: #f5eedf;
}
.st944 {
  fill: #aca4a0;
}
.st945 {
  fill: #f4efde;
}
.st946 {
  fill: #739aa7;
}
.st947 {
  fill: #ded3c8;
}
.st948 {
  fill: #064b63;
}
.st949 {
  fill: #f3445f;
}
.st950 {
  fill: #3e637b;
}
.st951 {
  fill: #7d7e84;
}
.st952 {
  fill: #627b88;
}
.st953 {
  fill: #b0ada9;
}
.st954 {
  fill: #07364a;
}
.st955 {
  fill: #b4122e;
}
.st956 {
  fill: #ddd6cd;
}
.st957 {
  fill: #5b7988;
}
.st958 {
  fill: #dad0c7;
}
.st959 {
  fill: #bfb7ad;
}
.st960 {
  fill: #658f9f;
}
.st961 {
  fill: #d5cac0;
}
.st962 {
  fill: #90a5ac;
}
.st963 {
  fill: #e4dbcf;
}
.st964 {
  fill: #b5b4b1;
}
.st965 {
  fill: #af9f9d;
}
.st966 {
  fill: #c2b3af;
}
.st967 {
  fill: #7d757a;
}
.st968 {
  fill: #a39696;
}
.st969 {
  fill: #9fa4a5;
}
.st970 {
  fill: #c5bdb6;
}
.st971 {
  fill: #808688;
}
.st972 {
  fill: #ece2d2;
}
.st973 {
  fill: #b8b5b2;
}
.st974 {
  fill: #6d7680;
}
.st975 {
  fill: #949596;
}
.st976 {
  fill: #cbc5c1;
}
.st977 {
  fill: #627982;
}
.st978 {
  fill: #91969b;
}
.st979 {
  fill: #e0dccf;
}
.st980 {
  fill: #cecdc9;
}
.st981 {
  fill: #c6d2d0;
}
.st982 {
  fill: #2d5d73;
}
.st983 {
  fill: #a09ea0;
}
.st984 {
  fill: #8c7773;
}
.st985 {
  fill: #280915;
}
.st986 {
  fill: #c0c1bb;
}
.st987 {
  fill: #1c2d30;
}
.st988 {
  fill: #ced2d2;
}
.st989 {
  fill: #8eb0bc;
}
.st990 {
  fill: #65303e;
}
.st991 {
  fill: #668a9b;
}
.st992 {
  fill: #6b8f9e;
}
.st993 {
  fill: #93bec6;
}
.st994 {
  fill: #4a89a0;
}
.st995 {
  fill: #2f728d;
}
.st996 {
  fill: #b0d1d4;
}
.st997 {
  fill: #a9cdd1;
}
.st998 {
  fill: #f5f8eb;
}
.st999 {
  fill: #bad8db;
}
.st1000 {
  fill: #fa5e75;
}
.st1001 {
  fill: #abced3;
}
.st1002 {
  fill: #a71c3a;
}
.st1003 {
  fill: #e93b57;
}
.st1004 {
  fill: #b72946;
}
.st1005 {
  fill: #e5eedf;
}
.st1006 {
  fill: #d1e6e4;
}
.st1007 {
  fill: #fa657b;
}
.st1008 {
  fill: #b82340;
}
.st1009 {
  fill: #f54860;
}
.st1010 {
  fill: #e33753;
}
.st1011 {
  fill: #97a1a7;
}
.st1012 {
  fill: #2d6f8a;
}
.st1013 {
  fill: #508da1;
}
.st1014 {
  fill: #4f8ca1;
}
.st1015 {
  fill: #7fb3bc;
}
.st1016 {
  fill: #92bfc5;
}
.st1017 {
  fill: #97c0c7;
}
.st1018 {
  fill: #679faf;
}
.st1019 {
  fill: #0a3550;
}
.st1020 {
  fill: #add1d2;
}
.st1021 {
  fill: #572e45;
}
.st1022 {
  fill: #364e61;
}
.st1023 {
  fill: #c22d43;
}
.st1024 {
  fill: #a01b2f;
}
.st1025 {
  fill: #61616b;
}
.st1026 {
  fill: #a3676f;
}
.st1027 {
  fill: #6ba3b4;
}
.st1028 {
  fill: #517082;
}
.st1029 {
  fill: #5a8495;
}
.st1030 {
  fill: #cad8d0;
}
.st1031 {
  fill: #b1dad9;
}
.st1032 {
  fill: #b1dce0;
}
.st1033 {
  fill: #568596;
}
.st1034 {
  fill: #2b4251;
}
.st1035 {
  fill: #d0d0c6;
}
.st1036 {
  fill: #798689;
}
.st1037 {
  fill: #a7a8a3;
}
.st1038 {
  fill: #fadfcc;
}
.st1039 {
  fill: #767a82;
}
.st1040 {
  fill: #f94964;
}
.st1041 {
  fill: #fbe7d4;
}
.st1042 {
  fill: #054e6b;
}
.st1043 {
  fill: #5a8090;
}
.st1044 {
  fill: #bcd8dc;
}
.st1045 {
  fill: #abb2ad;
}
.st1046 {
  fill: #69828d;
}
.st1047 {
  fill: #367d94;
}
.st1048 {
  fill: #577c8f;
}
.st1049 {
  fill: #196278;
}
.st1050 {
  fill: #80858b;
}
.st1051 {
  fill: #476679;
}
.st1052 {
  fill: #bfc5be;
}
.st1053 {
  fill: #566e7c;
}
.st1054 {
  fill: #a4cdd2;
}
.st1055 {
  fill: #0f323f;
}
.st1056 {
  fill: #f8e6ce;
}
.st1057 {
  fill: #fb556c;
}
.st1058 {
  fill: #fae8dd;
}
.st1059 {
  fill: #577d8e;
}
.st1060 {
  fill: #7b767d;
}
.st1061 {
  fill: #152430;
}
.st1062 {
  fill: #567a89;
}
.st1063 {
  fill: #dbd3c6;
}
.st1064 {
  fill: #28404c;
}
.st1065 {
  fill: #6a97a8;
}
.st1066 {
  fill: #cbc7c2;
}
.st1067 {
  fill: #9e9fa3;
}
.st1068 {
  fill: #358198;
}
.st1069 {
  fill: #b6cbc9;
}
.st1070 {
  fill: #023c56;
}
.st1071 {
  fill: #b5cdc8;
}
.st1072 {
  fill: #b3c8c9;
}
.st1073 {
  fill: #627b8a;
}
.st1074 {
  fill: #a19996;
}
.st1075 {
  fill: #808182;
}
.st1076 {
  fill: #3c889d;
}
.st1077 {
  fill: #1b6983;
}
.st1078 {
  fill: #013852;
}
.st1079 {
  fill: #f5f4ea;
}
.st1080 {
  fill: #145a74;
}
.st1081 {
  fill: #c3bcb3;
}
.st1082 {
  fill: #a39f9c;
}
.st1083 {
  fill: #1a2b2c;
}
.st1084 {
  fill: #676c7a;
}
.st1085 {
  fill: #023c58;
}
.st1086 {
  fill: #a8a5a2;
}
.st1087 {
  fill: #b1a69f;
}
.st1088 {
  fill: #fafced;
}
.st1089 {
  fill: #c5beb6;
}
.st1090 {
  fill: #e7dfd2;
}
.st1091 {
  fill: #2e5b6f;
}
.st1092 {
  fill: #80b2bc;
}
.st1093 {
  fill: #72a8b7;
}
.st1094 {
  fill: #accfd3;
}
.st1095 {
  fill: #40839c;
}
.st1096 {
  fill: #649eaf;
}
.st1097 {
  fill: #658b99;
}
.st1098 {
  fill: #7cb1be;
}
.st1099 {
  fill: #779fab;
}
.st1100 {
  fill: #93a2a7;
}
.st1101 {
  fill: #e4e1d7;
}
.st1102 {
  fill: #c0c0bd;
}
.st1103 {
  fill: #d2d6ce;
}
.st1104 {
  fill: #c3dad5;
}
.st1105 {
  fill: #d1dbcf;
}
.st1106 {
  fill: #c6e8e9;
}
.st1107 {
  fill: #78acb8;
}
.st1108 {
  fill: #5090a3;
}
.st1109 {
  fill: #6197aa;
}
.st1110 {
  fill: #06445d;
}
.st1111 {
  fill: #950222;
}
.st1112 {
  fill: #426174;
}
.st1113 {
  fill: #3a7d93;
}
.st1114 {
  fill: #87b9c2;
}
.st1115 {
  fill: #e4dbcd;
}
.st1116 {
  fill: #5695aa;
}
.st1117 {
  fill: #3c8297;
}
.st1118 {
  fill: #f8e2d4;
}
.st1119 {
  fill: #4d8ea3;
}
.st1120 {
  fill: #266e89;
}
.st1121 {
  fill: #ca2341;
}
.st1122 {
  fill: #4f7d8b;
}
.st1123 {
  fill: #b0d2d4;
}
.st1124 {
  fill: #e1ded2;
}
.st1125 {
  fill: #b7b2ad;
}
.st1126 {
  fill: #47192e;
}
.st1127 {
  fill: #f8ded1;
}
.st1128 {
  fill: #aa1936;
}
.st1129 {
  fill: #08415f;
}
.st1130 {
  fill: #3d7f9a;
}
.st1131 {
  fill: #435d6b;
}
.st1132 {
  fill: #122735;
}
.st1133 {
  fill: #9ca7a8;
}
.st1134 {
  fill: #abd5d6;
}
.st1135 {
  fill: #a8d2da;
}
.st1136 {
  fill: #317690;
}
.st1137 {
  fill: #6fa6b5;
}
.st1138 {
  fill: #609cae;
}
.st1139 {
  fill: #63576b;
}
.st1140 {
  fill: #5a95a5;
}
.st1141 {
  fill: #c8d9d2;
}
.st1142 {
  fill: #b6e2e8;
}
.st1143 {
  fill: #c3e7e5;
}
.st1144 {
  fill: #d0ede9;
}
.st1145 {
  fill: #ad1b3b;
}
.st1146 {
  fill: #a3cbd3;
}
.st1147 {
  fill: #7b7b82;
}
.st1148 {
  fill: #74949e;
}
.st1149 {
  fill: #516f83;
}
.st1150 {
  fill: #022b42;
}
.st1151 {
  fill: #428da4;
}
.st1152 {
  fill: #267089;
}
.st1153 {
  fill: #0e546d;
}
.st1154 {
  fill: #4b9aaf;
}
.st1155 {
  fill: #a7acac;
}
.st1156 {
  fill: #b8ada6;
}
.st1157 {
  fill: #d8dad5;
}
.st1158 {
  fill: #012438;
}
.st1159 {
  fill: #848286;
}
.st1160 {
  fill: #023a52;
}
.st1161 {
  fill: #032639;
}
.st1162 {
  fill: #fbfaea;
}
.st1163 {
  fill: #355b73;
}
.st1164 {
  fill: #d6ccbf;
}
.st1165 {
  fill: #6e97a5;
}
.st1166 {
  fill: #efe6d9;
}
.st1167 {
  fill: #667981;
}
.st1168 {
  fill: #3d5465;
}
.st1169 {
  fill: #98a3a3;
}
.st1170 {
  fill: #bdb8b2;
}
.st1171 {
  fill: #a3a4a4;
}
.st1172 {
  fill: #bcdbe1;
}
.st1173 {
  fill: #6a7a81;
}
.st1174 {
  fill: #6eaaaf;
}
.st1175 {
  fill: #bbb0ab;
}
.st1176 {
  fill: #c6c8c2;
}
.st1177 {
  fill: #295167;
}
.st1178 {
  fill: #406579;
}
.st1179 {
  fill: #e2dad1;
}
.st1180 {
  fill: #3f677b;
}
.st1181 {
  fill: #11546f;
}
.st1182 {
  fill: #ddd9cf;
}
.st1183 {
  fill: #0c5067;
}
.st1184 {
  fill: #1b3f4a;
}
.st1185 {
  fill: #a19697;
}
.st1186 {
  fill: #438aa3;
}
.st1187 {
  fill: #658f9e;
}
.st1188 {
  fill: #8e9fa3;
}
.st1189 {
  fill: #b1adab;
}
.st1190 {
  fill: #858990;
}
.st1191 {
  fill: #7aa1ac;
}
.st1192 {
  fill: #49626d;
}
.st1193 {
  fill: #e7dfd0;
}
.st1194 {
  fill: #527282;
}
.st1195 {
  fill: #afacab;
}
.st1196 {
  fill: #cdc7bf;
}
.st1197 {
  fill: #89858b;
}
.st1198 {
  fill: #87848a;
}
.st1199 {
  fill: #466878;
}
.st1200 {
  fill: #6a7079;
}
.st1201 {
  fill: #52636f;
}
.st1202 {
  fill: #ad9f9d;
}
.st1203 {
  fill: #1f2e35;
}
.st1204 {
  fill: #356177;
}
.st1205 {
  fill: #cec6c0;
}
.st1206 {
  fill: #9aa2a6;
}
.st1207 {
  fill: #80797f;
}
.st1208 {
  fill: #b2c8c6;
}
.st1209 {
  fill: #fbfaed;
}
.st1210 {
  fill: #e9e4d7;
}
.st1211 {
  fill: #ccc5c0;
}
.st1212 {
  fill: #8f989d;
}
.st1213 {
  fill: #d5d2ca;
}
.st1214 {
  fill: #1f4a5a;
}
.st1215 {
  fill: #9da7a5;
}
.st1216 {
  fill: #8faaa7;
}
.st1217 {
  fill: #908c8b;
}
.st1218 {
  fill: #4f727f;
}
.st1219 {
  fill: #58707f;
}
.st1220 {
  fill: #8fc4cd;
}
.st1221 {
  fill: #98a1a4;
}
.st1222 {
  fill: #2b708c;
}
.st1223 {
  fill: #31738d;
}
.st1224 {
  fill: #a6acae;
}
.st1225 {
  fill: #47879d;
}
.st1226 {
  fill: #aed8db;
}
.st1227 {
  fill: #01283e;
}
.st1228 {
  fill: #9bcad0;
}
.st1229 {
  fill: #afa49e;
}
.st1230 {
  fill: #5a97ab;
}
.st1231 {
  fill: #b9b2a9;
}
.st1232 {
  fill: #a9c3bf;
}
.st1233 {
  fill: #728086;
}
.st1234 {
  fill: #cde1dd;
}
.st1235 {
  fill: #9bc2c7;
}
.st1236 {
  fill: #76abb8;
}
.st1237 {
  fill: #a5cace;
}
.st1238 {
  fill: #05445f;
}
.st1239 {
  fill: #b3d3d4;
}
.st1240 {
  fill: #2a6c86;
}
.st1241 {
  fill: #b9d6dc;
}
.st1242 {
  fill: #7eb2be;
}
.st1243 {
  fill: #c7dfdf;
}
.st1244 {
  fill: #878f91;
}
.st1245 {
  fill: #2c748d;
}
.st1246 {
  fill: #145c74;
}
.st1247 {
  fill: #9e0424;
}
.st1248 {
  fill: #1b6381;
}
.st1249 {
  fill: #317490;
}
.st1250 {
  fill: #0e5676;
}
.st1251 {
  fill: #0c4766;
}
.st1252 {
  fill: #fa526b;
}
.st1253 {
  fill: #7d8186;
}
.st1254 {
  fill: #cdc5bc;
}
.st1255 {
  fill: #aea7a2;
}
.st1256 {
  fill: #3f7f98;
}
.st1257 {
  fill: #02253a;
}
.st1258 {
  fill: #013854;
}
.st1259 {
  fill: #185e7d;
}
.st1260 {
  fill: #094968;
}
.st1261 {
  fill: #82989d;
}
.st1262 {
  fill: #315365;
}
.st1263 {
  fill: #a0cfcf;
}
.st1264 {
  fill: #074463;
}
.st1265 {
  fill: #db2e4b;
}
.st1266 {
  fill: #2f708a;
}
.st1267 {
  fill: #970323;
}
.st1268 {
  fill: #999191;
}
.st1269 {
  fill: #5b8394;
}
.st1270 {
  fill: #b9aca5;
}
.st1271 {
  fill: #d4c7be;
}
.st1272 {
  fill: #5c98ac;
}
.st1273 {
  fill: #5c96a6;
}
.st1274 {
  fill: #d7d3c8;
}
.st1275 {
  fill: #a1a7a7;
}
.st1276 {
  fill: #bfbcb6;
}
.st1277 {
  fill: #aac3c2;
}
.st1278 {
  fill: #aed3d6;
}
.st1279 {
  fill: #9bc6d0;
}
.st1280 {
  fill: #c9ded9;
}
.st1281 {
  fill: #0f4b66;
}
.st1282 {
  fill: #425b68;
}
.st1283 {
  fill: #366175;
}
.st1284 {
  fill: #6595a5;
}
.st1285 {
  fill: #827f83;
}
.st1286 {
  fill: #b5ccca;
}
.st1287 {
  fill: #ddd4c8;
}
.st1288 {
  fill: #b0a6a1;
}
.st1289 {
  fill: #0b4d64;
}
.st1290 {
  fill: #3e879e;
}
.st1291 {
  fill: #b3cbc3;
}
.st1292 {
  fill: #6b818b;
}
.st1293 {
  fill: #216d86;
}
.st1294 {
  fill: #7a878d;
}
.st1295 {
  fill: #bcdbdf;
}
.st1296 {
  fill: #094861;
}
.st1297 {
  fill: #356679;
}
.st1298 {
  fill: #03293c;
}
.st1299 {
  fill: #bcdbe0;
}
.st1300 {
  fill: #789da8;
}
.st1301 {
  fill: #a9b5b2;
}
.st1302 {
  fill: #9dafb3;
}
.st1303 {
  fill: #3c6475;
}
.st1304 {
  fill: #beb6ae;
}
.st1305 {
  fill: #052a3d;
}
.st1306 {
  fill: #1b6583;
}
.st1307 {
  fill: #b1c3c4;
}
.st1308 {
  fill: #afd1d8;
}
.st1309 {
  fill: #608d9f;
}
.st1310 {
  fill: #729eab;
}
.st1311 {
  fill: #325c72;
}
.st1312 {
  fill: #9b9797;
}
.st1313 {
  fill: #e2dbd1;
}
.st1314 {
  fill: #c0bab2;
}
.st1315 {
  fill: #c7c0b7;
}
.st1316 {
  fill: #8b8589;
}
.st1317 {
  fill: #7fb9cc;
}
.st1318 {
  fill: #175e7c;
}
.st1319 {
  fill: #beb4ae;
}
.st1320 {
  fill: #d5cec4;
}
.st1321 {
  fill: #f4efdf;
}
.st1322 {
  fill: #96b0b4;
}
.st1323 {
  fill: #f9f9ee;
}
.st1324 {
  fill: #507285;
}
.st1325 {
  fill: #216988;
}
.st1326 {
  fill: #b9bbb6;
}
.st1327 {
  fill: #7db0bd;
}
.st1328 {
  fill: #88bcc6;
}
.st1329 {
  fill: #a0a4a3;
}
.st1330 {
  fill: #c0e2e2;
}
.st1331 {
  fill: #01273b;
}
.st1332 {
  fill: #0c3c52;
}
.st1333 {
  fill: #80939b;
}
.st1334 {
  fill: #f7fbee;
}
.st1335 {
  fill: #2f6f8c;
}
.st1336 {
  fill: #42839c;
}
.st1337 {
  fill: #f7cec9;
}
.st1338 {
  fill: #6aa2b3;
}
.st1339 {
  fill: #62757e;
}
.st1340 {
  fill: #094860;
}
.st1341 {
  fill: #b0b1ad;
}
.st1342 {
  fill: #9e9896;
}
.st1343 {
  fill: #aca49f;
}
.st1344 {
  fill: #68787e;
}
.st1345 {
  fill: #111e2b;
}
.st1346 {
  fill: #8c8688;
}
.st1347 {
  fill: #a2d1d9;
}
.st1348 {
  fill: #a2cecf;
}
.st1349 {
  fill: #f0ebdc;
}
.st1350 {
  fill: #39586c;
}
.st1351 {
  fill: #325c70;
}
.st1352 {
  fill: #517788;
}
.st1353 {
  fill: #5b7581;
}
.st1354 {
  fill: #989497;
}
.st1355 {
  fill: #74717b;
}
.st1356 {
  fill: #8d9ea5;
}
.st1357 {
  fill: #a7a6a3;
}
.st1358 {
  fill: #468ba2;
}
.st1359 {
  fill: #063e54;
}
.st1360 {
  fill: #042332;
}
.st1361 {
  fill: #1a6176;
}
.st1362 {
  fill: #337b92;
}
.st1363 {
  fill: #01314b;
}
.st1364 {
  fill: #1b647c;
}
.st1365 {
  fill: #b9d8dd;
}
.st1366 {
  fill: #084562;
}
.st1367 {
  fill: #4f7180;
}
.st1368 {
  fill: #577486;
}
.st1369 {
  fill: #5a8191;
}
.st1370 {
  fill: #758b95;
}
.st1371 {
  fill: #90bec8;
}
.st1372 {
  fill: #305c72;
}
.st1373 {
  fill: #bedbdd;
}
.st1374 {
  fill: #afb2b0;
}
.st1375 {
  fill: #618d9a;
}
.st1376 {
  fill: #ded7c8;
}
.st1377 {
  fill: #beb9b1;
}
.st1378 {
  fill: #8c979b;
}
.st1379 {
  fill: #2f5e70;
}
.st1380 {
  fill: #437082;
}
.st1381 {
  fill: #27708a;
}
.st1382 {
  fill: #357c95;
}
.st1383 {
  fill: #eae0d1;
}
.st1384 {
  fill: #5d98ad;
}
.st1385 {
  fill: #a2a09f;
}
.st1386 {
  fill: #d3c8c2;
}
.st1387 {
  fill: #9f999b;
}
.st1388 {
  fill: #1b2f40;
}
.st1389 {
  fill: #72909a;
}
.st1390 {
  fill: #45869d;
}
.st1391 {
  fill: #7599a2;
}
.st1392 {
  fill: #133342;
}
.st1393 {
  fill: #426677;
}
.st1394 {
  fill: #789faa;
}
.st1395 {
  fill: #688c9a;
}
.st1396 {
  fill: #9fc9cf;
}
.st1397 {
  fill: #f5f2e1;
}
.st1398 {
  fill: #1b4558;
}
.st1399 {
  fill: #dbd3c8;
}
.st1400 {
  fill: #cddfd6;
}
.st1401 {
  fill: #c6beb6;
}
.st1402 {
  fill: #8d8e8f;
}
.st1403 {
  fill: #d1c8bf;
}
.st1404 {
  fill: #165c76;
}
.st1405 {
  fill: #185f78;
}
.st1406 {
  fill: #6a7b83;
}
.st1407 {
  fill: #85b2bd;
}
.st1408 {
  fill: #a2a8a9;
}
.st1409 {
  fill: #83b2c0;
}
.st1410 {
  fill: #4a7081;
}
.st1411 {
  fill: #488ba1;
}
.st1412 {
  fill: #c2bcb3;
}
.st1413 {
  fill: #b3c9c4;
}
.st1414 {
  fill: #b9cccb;
}
.st1415 {
  fill: #e3dad1;
}
.st1416 {
  fill: #cac0bb;
}
.st1417 {
  fill: #527183;
}
.st1418 {
  fill: #ada9a7;
}
.st1419 {
  fill: #909192;
}
.st1420 {
  fill: #787377;
}
.st1421 {
  fill: #f8faf5;
}
.st1422 {
  fill: #2f6073;
}
.st1423 {
  fill: #96938d;
}
.st1424 {
  fill: #5b8698;
}
.st1425 {
  fill: #b7dadd;
}
.st1426 {
  fill: #90999b;
}
.st1427 {
  fill: #76aaba;
}
.st1428 {
  fill: #45889d;
}
.st1429 {
  fill: #497084;
}
.st1430 {
  fill: #75a4b0;
}
.st1431 {
  fill: #558595;
}
.st1432 {
  fill: #8d8484;
}
.st1433 {
  fill: #bebab3;
}
.st1434 {
  fill: #99a1a2;
}
.st1435 {
  fill: #bac1bd;
}
.st1436 {
  fill: #9aa4a3;
}
.st1437 {
  fill: #7ca0a9;
}
.st1438 {
  fill: #92969c;
}
.st1439 {
  fill: #81b2c1;
}
.st1440 {
  fill: #5f676e;
}
.st1441 {
  fill: #aac2c4;
}
.st1442 {
  fill: #660e42;
}
.st1443 {
  fill: #600944;
}
.st1444 {
  fill: #441721;
}
.st1445 {
  fill: #390f3f;
}
.st1446 {
  fill: #630b43;
}
.st1447 {
  fill: #571c24;
}
.st1448 {
  fill: #3e142e;
}
.st1449 {
  fill: #49153b;
}
.st1450 {
  fill: #4c3e3d;
}
.st1451 {
  fill: #461c1c;
}
.st1452 {
  fill: #f91c4d;
}
.st1453 {
  fill: #f42c56;
}
.st1454 {
  fill: #f51c42;
}
.st1455 {
  fill: #a5063d;
}
.st1456 {
  fill: #f82442;
}
.st1457 {
  fill: #dd0935;
}
.st1458 {
  fill: #89052f;
}
.st1459 {
  fill: #910544;
}
.st1460 {
  fill: #c23d62;
}
.st1461 {
  fill: #ee0926;
}
.st1462 {
  fill: #ab043c;
}
.st1463 {
  fill: #a8072e;
}
.st1464 {
  fill: #da163b;
}
.st1465 {
  fill: #f53146;
}
.st1466 {
  fill: #f9f2ee;
}
.st1467 {
  fill: #da6568;
}
.st1468 {
  fill: #9a0143;
}
.st1469 {
  fill: #380836;
}
.st1470 {
  fill: #352c47;
}
.st1471 {
  fill: #4d1621;
}
.st1472 {
  fill: #3d2032;
}
.st1473 {
  fill: #4f1629;
}
.st1474 {
  fill: #2f252a;
}
.st1475 {
  fill: #881f2f;
}
.st1476 {
  fill: #5d2125;
}
.st1477 {
  fill: #614e64;
}
.st1478 {
  fill: #402634;
}
.st1479 {
  fill: #1b050a;
}
.st1480 {
  fill: #b6023d;
}
.st1481 {
  fill: #a50342;
}
.st1482 {
  fill: #f5132c;
}
.st1483 {
  fill: #c3043d;
}
.st1484 {
  fill: #307959;
}
.st1485 {
  fill: #3c0945;
}
.st1486 {
  fill: #4e6c7e;
}
.st1487 {
  fill: #4b667b;
}
.st1488 {
  fill: #6d0719;
}
.st1489 {
  fill: #30a654;
}
.st1490 {
  fill: #690c41;
}
.st1491 {
  fill: #df2836;
}
.st1492 {
  fill: #76a486;
}
.st1493 {
  fill: #ea0d34;
}
.st1494 {
  fill: #b4d59f;
}
.st1495 {
  fill: #fa3c2b;
}
.st1496 {
  fill: #faddc4;
}
.st1497 {
  fill: #f5eec9;
}
.st1498 {
  fill: #c90238;
}
.st1499 {
  fill: #f95a3b;
}
.st1500 {
  fill: #fde0b0;
}
.st1501 {
  fill: #f2bc8b;
}
.st1502 {
  fill: #b7063a;
}
.st1503 {
  fill: #690645;
}
.st1504 {
  fill: #8d0639;
}
.st1505 {
  fill: #a0053c;
}
.st1506 {
  fill: #dd7439;
}
.st1507 {
  fill: #f9985e;
}
.st1508 {
  fill: #e2fce6;
}
.st1509 {
  fill: #533b72;
}
.st1510 {
  fill: #eecd99;
}
.st1511 {
  fill: #f4b437;
}
.st1512 {
  fill: #f9a219;
}
.st1513 {
  fill: #f9f5e7;
}
.st1514 {
  fill: #faf7e4;
}
.st1515 {
  fill: #270f41;
}
.st1516 {
  fill: #2d1d29;
}
.st1517 {
  fill: #cca8a9;
}
.st1518 {
  fill: #a690ca;
}
.st1519 {
  fill: #958fbc;
}
.st1520 {
  fill: #e8c98d;
}
.st1521 {
  fill: #a087c8;
}
.st1522 {
  fill: #eee0c1;
}
.st1523 {
  fill: #edc8bf;
}
.st1524 {
  fill: #e1937c;
}
.st1525 {
  fill: #f5780b;
}
.st1526 {
  fill: #e1450e;
}
.st1527 {
  fill: #b35f35;
}
.st1528 {
  fill: #641c40;
}
.st1529 {
  fill: #fa9609;
}
.st1530 {
  fill: #f98109;
}
.st1531 {
  fill: #9f481a;
}
.st1532 {
  fill: #791b2e;
}
.st1533 {
  fill: #71261a;
}
.st1534 {
  fill: #9f1e1e;
}
.st1535 {
  fill: #f49340;
}
.st1536 {
  fill: #b68a8e;
}
.st1537 {
  fill: #ebc04b;
}
.st1538 {
  fill: #fdda0b;
}
.st1539 {
  fill: #1a0e30;
}
.st1540 {
  fill: #271930;
}
.st1541 {
  fill: #2e1137;
}
.st1542 {
  fill: #14071f;
}
.st1543 {
  fill: #42364d;
}
.st1544 {
  fill: #7363ad;
}
.st1545 {
  fill: #52417b;
}
.st1546 {
  fill: #ece4ea;
}
.st1547 {
  fill: #917bb9;
}
.st1548 {
  fill: #9882b8;
}
.st1549 {
  fill: #7a6998;
}
.st1550 {
  fill: #ebe1ee;
}
.st1551 {
  fill: #322844;
}
.st1552 {
  fill: #7c5c9d;
}
.st1553 {
  fill: #8f83c0;
}
.st1554 {
  fill: #baa6df;
}
.st1555 {
  fill: #e6e0f7;
}
.st1556 {
  fill: #502d6d;
}
.st1557 {
  fill: #3f1a57;
}
.st1558 {
  fill: #695b88;
}
.st1559 {
  fill: #341359;
}
.st1560 {
  fill: #2e074b;
}
.st1561 {
  fill: #18052d;
}
.st1562 {
  fill: #846ea3;
}
.st1563 {
  fill: #412565;
}
.st1564 {
  fill: #351954;
}
.st1565 {
  fill: #a693bd;
}
.st1566 {
  fill: #32276d;
}
.st1567 {
  fill: #341e5f;
}
.st1568 {
  fill: #2b1f5e;
}
.st1569 {
  fill: #59498e;
}
.st1570 {
  fill: #686cac;
}
.st1571 {
  fill: #5b5797;
}
.st1572 {
  fill: #6e67a0;
}
.st1573 {
  fill: #5b5692;
}
.st1574 {
  fill: #7774b4;
}
.st1575 {
  fill: #6f71b0;
}
.st1576 {
  fill: #544b8b;
}
.st1577 {
  fill: #7e85bf;
}
.st1578 {
  fill: #362c6d;
}
.st1579 {
  fill: #55477f;
}
.st1580 {
  fill: #624e88;
}
.st1581 {
  fill: #514797;
}
.st1582 {
  fill: #6761a8;
}
.st1583 {
  fill: #5b5493;
}
.st1584 {
  fill: #9490d2;
}
.st1585 {
  fill: #9baad5;
}
.st1586 {
  fill: #63548f;
}
.st1587 {
  fill: #8471ae;
}
.st1588 {
  fill: #6962a0;
}
.st1589 {
  fill: #9c93ca;
}
.st1590 {
  fill: #cdf6fb;
}
.st1591 {
  fill: #becfd4;
}
.st1592 {
  fill: #0d2465;
}
.st1593 {
  fill: #0f2263;
}
.st1594 {
  fill: #073176;
}
.st1595 {
  fill: #054ba6;
}
.st1596 {
  fill: #a5f4fa;
}
.st1597 {
  fill: #0a2b6c;
}
.st1598 {
  fill: #0d256c;
}
.st1599 {
  fill: #093d91;
}
.st1600 {
  fill: #9cecfc;
}
.st1601 {
  fill: #0c266a;
}
.st1602 {
  fill: #b0f7f2;
}
.st1603 {
  fill: #a9f6fc;
}
.st1604 {
  fill: #0c3a85;
}
.st1605 {
  fill: #3c6684;
}
.st1606 {
  fill: #c2f7ea;
}
.st1607 {
  fill: #143b88;
}
.st1608 {
  fill: #0e3b80;
}
.st1609 {
  fill: #a7bfcb;
}
.st1610 {
  fill: #1174d5;
}
.st1611 {
  fill: #426e7b;
}
.st1612 {
  fill: #b7cbd7;
}
.st1613 {
  fill: #8fb5bf;
}
.st1614 {
  fill: #c0dcdd;
}
.st1615 {
  fill: #3b6989;
}
.st1616 {
  fill: #3c6078;
}
.st1617 {
  fill: #b3c4cd;
}
.st1618 {
  fill: #8aafb7;
}
.st1619 {
  fill: #3f5976;
}
.st1620 {
  fill: #2593fd;
}
.st1621 {
  fill: #0552b0;
}
.st1622 {
  fill: #053c94;
}
.st1623 {
  fill: #2597f8;
}
.st1624 {
  fill: #124c91;
}
.st1625 {
  fill: #113d98;
}
.st1626 {
  fill: #143287;
}
.st1627 {
  fill: #0f3995;
}
.st1628 {
  fill: #0d48aa;
}
.st1629 {
  fill: #084ea9;
}
.st1630 {
  fill: #1c91fa;
}
.st1631 {
  fill: #0a3379;
}
.st1632 {
  fill: #072c73;
}
.st1633 {
  fill: #0d327f;
}
.st1634 {
  fill: #07449e;
}
.st1635 {
  fill: #0f3686;
}
.st1636 {
  fill: #0453b6;
}
.st1637 {
  fill: #044faf;
}
.st1638 {
  fill: #033d99;
}
.st1639 {
  fill: #0d5ab4;
}
.st1640 {
  fill: #198bfe;
}
.st1641 {
  fill: #05388a;
}
.st1642 {
  fill: #1e92fe;
}
.st1643 {
  fill: #2796fb;
}
.st1644 {
  fill: #2a98fa;
}
.st1645 {
  fill: #084f9f;
}
.st1646 {
  fill: #99e3fc;
}
.st1647 {
  fill: #2379c6;
}
.st1648 {
  fill: #2b9be4;
}
.st1649 {
  fill: #0b65af;
}
.st1650 {
  fill: #218cc9;
}
.st1651 {
  fill: #74dbfd;
}
.st1652 {
  fill: #2683c3;
}
.st1653 {
  fill: #2da1fa;
}
.st1654 {
  fill: #2b95fb;
}
.st1655 {
  fill: #7ce3fc;
}
.st1656 {
  fill: #3890d0;
}
.st1657 {
  fill: #0248a6;
}
.st1658 {
  fill: #3297d6;
}
.st1659 {
  fill: #1373bd;
}
.st1660 {
  fill: #3994cb;
}
.st1661 {
  fill: #1a93f9;
}
.st1662 {
  fill: #163180;
}
.st1663 {
  fill: #0f377a;
}
.st1664 {
  fill: #1d427c;
}
.st1665 {
  fill: #133f7f;
}
.st1666 {
  fill: #172262;
}
.st1667 {
  fill: #073470;
}
.st1668 {
  fill: #093592;
}
.st1669 {
  fill: #79c1f7;
}
.st1670 {
  fill: #154394;
}
.st1671 {
  fill: #0f1d5f;
}
.st1672 {
  fill: #65c5fb;
}
.st1673 {
  fill: #180b56;
}
.st1674 {
  fill: #172566;
}
.st1675 {
  fill: #0350ad;
}
.st1676 {
  fill: #06449b;
}
.st1677 {
  fill: #57bdf3;
}
.st1678 {
  fill: #0554ae;
}
.st1679 {
  fill: #37a4fb;
}
.st1680 {
  fill: #3190f1;
}
.st1681 {
  fill: #164594;
}
.st1682 {
  fill: #e6f9f9;
}
.st1683 {
  fill: #1f2f62;
}
.st1684 {
  fill: #7cabd1;
}
.st1685 {
  fill: #17408d;
}
.st1686 {
  fill: #1c3793;
}
.st1687 {
  fill: #0c377c;
}
.st1688 {
  fill: #062d6a;
}
.st1689 {
  fill: #0b336d;
}
.st1690 {
  fill: #224a89;
}
.st1691 {
  fill: #0b388b;
}
.st1692 {
  fill: #71d6fb;
}
.st1693 {
  fill: #249dfb;
}
.st1694 {
  fill: #21074e;
}
.st1695 {
  fill: #6692ed;
}
.st1696 {
  fill: #f4fdfb;
}
.st1697 {
  fill: #edfcfc;
}
.st1698 {
  fill: #0b2561;
}
.st1699 {
  fill: #23256a;
}
.st1700 {
  fill: #6891bf;
}
.st1701 {
  fill: #3e5682;
}
.st1702 {
  fill: #251e54;
}
.st1703 {
  fill: #364379;
}
.st1704 {
  fill: #465b91;
}
.st1705 {
  fill: #5e0c4b;
}
.st1706 {
  fill: #263a58;
}
.st1707 {
  fill: #edfcfb;
}
.st1708 {
  fill: #952c52;
}
.st1709 {
  fill: #1f2d6e;
}
.st1710 {
  fill: #221f53;
}
.st1711 {
  fill: #4b699f;
}
.st1712 {
  fill: #334377;
}
.st1713 {
  fill: #e4f9fa;
}
.st1714 {
  fill: #17215e;
}
.st1715 {
  fill: #1d419a;
}
.st1716 {
  fill: #678bc7;
}
.st1717 {
  fill: #f7fdfc;
}
.st1718 {
  fill: #011149;
}
.st1719 {
  fill: #1b0954;
}
.st1720 {
  fill: #fefdfd;
}
.st1721 {
  fill: #fb3f58;
}
.st1722 {
  fill: #10bb89;
}
.st1723 {
  fill: #4d6ba2;
}
.st1724 {
  fill: #104868;
}
.st1725 {
  fill: #182e50;
}
.st1726 {
  fill: #29bf9f;
}
.st1727 {
  fill: #97053f;
}
.st1728 {
  fill: #700b44;
}
.st1729 {
  fill: #afbed2;
}
.st1730 {
  fill: #9ec4d9;
}
.st1731 {
  fill: #38113f;
}
.st1732 {
  fill: #bb0942;
}
.st1733 {
  fill: #bcaab6;
}
.st1734 {
  fill: #172340;
}
.st1735 {
  fill: #d48d94;
}
.st1736 {
  fill: #0dbb89;
}
.st1737 {
  fill: #112d53;
}
.st1738 {
  fill: #0c4a6b;
}
.st1739 {
  fill: #8fbdcf;
}
.st1740 {
  fill: #bff6f8;
}
.st1741 {
  fill: #96abc7;
}
.st1742 {
  fill: #5ae7b9;
}
.st1743 {
  fill: #21687c;
}
.st1744 {
  fill: #475e84;
}
.st1745 {
  fill: #2a987b;
}
.st1746 {
  fill: #4f6fa2;
}
.st1747 {
  fill: #f5e9e5;
}
.st1748 {
  fill: #6b235e;
}
.st1749 {
  fill: #e90541;
}
.st1750 {
  fill: #f73a55;
}
.st1751 {
  fill: #8f0539;
}
.st1752 {
  fill: #8d0335;
}
.st1753 {
  fill: #fe3d55;
}
.st1754 {
  fill: #5d134c;
}
.st1755 {
  fill: #8d0840;
}
.st1756 {
  fill: #a2b9d8;
}
.st1757 {
  fill: #b6bdd1;
}
.st1758 {
  fill: #340f3b;
}
.st1759 {
  fill: #311134;
}
.st1760 {
  fill: #de0640;
}
.st1761 {
  fill: #f63653;
}
.st1762 {
  fill: #f1e2e9;
}
.st1763 {
  fill: #6790bd;
}
.st1764 {
  fill: #739fcf;
}
.st1765 {
  fill: #62124c;
}
.st1766 {
  fill: #be0e46;
}
.st1767 {
  fill: #a1bfd7;
}
.st1768 {
  fill: #eb5b74;
}
.st1769 {
  fill: #920836;
}
.st1770 {
  fill: #601a49;
}
.st1771 {
  fill: #f8667b;
}
.st1772 {
  fill: #3a1945;
}
.st1773 {
  fill: #9c1643;
}
.st1774 {
  fill: #2db5a2;
}
.st1775 {
  fill: #bd0d4a;
}
.st1776 {
  fill: #8c0f4e;
}
.st1777 {
  fill: #be1348;
}
.st1778 {
  fill: #880e46;
}
.st1779 {
  fill: #680e48;
}
</style>
