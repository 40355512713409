<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    style="enable-background: new 0 0 500 500"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M426.1,153.7c-2.1,1.5-4.1,1.5-6.5,0.1c-14.5-8.3-29.1-16.4-43.6-24.6c-10.5-6-21-11.9-31.3-18.1
	c-3.7-2.2-6.5-5.4-6.7-10.5c-0.6-13,0-26-0.3-39c0-1.6-0.1-3.2-0.1-4.9c0-1.7,0-3.5,0-5.2c-1.4-2.9,0.5-5.6,0.4-8.4
	c-0.1-6-0.6-11.9,0.3-17.9c0.1-0.2,0.1-0.4,0.1-0.6c1.7-1.4,3.1-0.1,4.3,0.7c17.9,11.1,36.1,21.4,54.3,32c4.2,2.4,7.8,5.8,12.4,7.5
	c0.9,0.2,1.5,0.7,2.2,1.2c4.7,2,9.2,4.5,13.4,7.4c1.9,1.3,2.9,2.9,2.9,5.2c0,23.6,0,47.1,0,70.7c0,1-0.2,2-0.4,3
	C427.2,153,426.9,153.6,426.1,153.7z"
    />
    <path
      class="st1"
      d="M243,55.3c0-6.5,0-13,0-19.5c0-1.8,0.1-3.5,0-5.2c-0.1-2.7,1-4.6,3.7-5.2c5,0.8,8.7,4.1,12.8,6.4
	c13.9,7.9,28,15.7,41.6,24.2c4.6,2.9,9.7,5.1,13.8,8.9l0.1,0.3c2.5,1.5,2.2,4,2.2,6.4c0,21.9,0,43.7,0,65.6c0,3.9,0,7.8,0,11.6
	c0,1.5,0,3-0.8,4.3c-1.4,1.4-1.9,0.2-2.4-0.8c-0.7-1.5-0.6-3.2-0.6-4.8c0-10.7,0-21.4,0-32.4c-1.1,1.7-1.4,3.9-3.7,4.2
	c-1.6-1.2-1.5-3-0.9-4.4c2.1-4.9,0.5-9.6-0.4-14.4c-2.2-6.2-3.3-12.8-7-18.4c-0.6-2.3-2.6-3.8-3.3-6.1c-0.1-2.8-1.9-4.2-4.1-5.3
	c-3.1-1.6-5-4.5-7.7-6.6c-4.8-3.5-9.9-6.1-16-6.6c-11.9-0.9-21,9.9-20.9,19.4c0,1.5,0,3-0.8,4.4c-2.2,2-3.4-0.7-5.1-1.1
	c0-5.5,0-11-0.1-16.5C242.8,60.8,244,58,243,55.3z"
    />
    <path
      class="st2"
      d="M408.7,65.5c-1.1,0.2-1.9-0.3-2.8-0.9c-19.7-12.4-39.9-23.8-60-35.6c-2.5-1.5-4.7-3.7-7.7-4.4
	c-0.5-3.5,0.1-6.8,1.8-10c0.7-0.8,1.7-0.6,2.7-0.6c7.2,3.9,14.1,8.3,21.2,12.4c16.8,9.6,33.3,19.8,50.3,28.9c4.1,2.2,8.3,4.7,11,8.8
	c0.7,1.1,2.4,0.1,2.5,1.6c0.3,1.6,0.4,3.2,0.4,4.9c0,25.8,0,51.5-0.1,77.2c0,1.6,1,3.8-1.4,4.8c0-25.5,0-50.9,0-76.4
	c-5.4-3.3-10.8-6.5-16.1-9.8c0.6-1.2,2.2-0.8,2.8-2c0.1-0.5,0.2-0.9,0.2-1.4c-0.3-1.6-1.4-2.1-2.9-2.3
	C409.1,62.1,410.5,64.4,408.7,65.5z"
    />
    <path
      class="st3"
      d="M426.9,65.1c-0.1,0.7-0.3,1.5-0.5,2.6c-4.9-8.2-13.2-11.2-20.6-15.5C391,43.5,376.1,35,361.3,26.3
	c-4.8-2.8-9.6-5.8-14.3-8.7c-1.8-1.1-3.7-1.2-5.7-1.4c-0.2-1.2,1.2-1.3,1.5-2.1c4.4-0.6,7.5,2.4,10.8,4.3
	c20,11.7,39.9,23.7,60.2,35.1c4.8,2.7,10.2,4.8,13.1,10C427.2,64,427.2,64.6,426.9,65.1z"
    />
    <path
      class="st4"
      d="M426.9,65.1c0-0.5,0-1.1-0.1-1.6c0.5,0,0.9,0,1.4,0c0.1,1,0.1,2.1,0.2,3.1c0.4,2.5,0.2,5,0.2,7.5
	c0,24.6,0,49.2,0,73.8c0,1.4,0.2,2.8-0.2,4.1c-0.4,1-0.3,2.7-2.3,1.7c0.2-0.3,0.5-0.7,0.7-1c1-1,0.8-2.3,0.8-3.5
	c0-26.4,0-52.9,0-79.3C427.5,68.3,427.8,66.6,426.9,65.1z"
    />
    <path
      class="st5"
      d="M338.2,25.3c-0.4,8.8,0.2,17.5-0.7,26.3c-0.6-0.5-0.9-1.2-0.8-2c1.3-7.4,0.4-14.8,0.7-22.3
	C337.5,26.5,337.7,25.8,338.2,25.3z"
    />
    <path
      class="st6"
      d="M315.7,153.6c0,17,0,34,0,50.9c-3.4,0-5.5-2.6-8-4.1c-11.4-6.8-22.8-13.6-34.4-20.1
	c-8.1-4.6-16.2-9.2-24.5-13.5c-1.2-0.6-2.6-1-2.8-2.7c-3.5-2.2-3-6.2-2.7-9.1c0.6-5.7,0.2-11.4,0.5-17.1c0.2-19.3-1.3-38.5,0-57.8
	c0.6-0.4,1.2-0.7,1.8-1c1.5,0.3,1,2.6,2.8,2.5c0.1,0.1,0.3,0.2,0.4,0.3c1.4,1.5-0.5,4.2,2.1,5.2c2.4,2.4,2.4,5.8,4,8.6
	c2.5,4.3,5,8.5,8.3,12.3c3.4,3.9,5.2,8.5,4.3,13.8c-0.3,4.2-0.1,8.3,2.3,12c0.8,1.3,0.2,2.6,0,3.9c-0.6,2.7,0.6,4.6,2.8,6
	c2.1,1,3.4,3.2,5.8,3.7c0.5,0.1,0.9,0.3,1.4,0.4c4,0.2,5.5-0.7,7.3-4.5c0.7-1.8,0.7-3.7,0.6-5.5c-0.2-3.5,1.5-5.6,4.6-6.8
	c1.6-0.6,3.3-1.2,4.9-1.8c4.4-1.5,8-4,10.5-8c0.5-0.9,1.1-1.7,2.2-1.9c2-2.7,2.2-6.1,3.7-9.1c2,1.2,1.4,2.7,1.4,4
	c0.1,11.8,0.1,23.5,0.1,35.2C315,150.7,314.6,152.2,315.7,153.6z"
    />
    <path
      class="st7"
      d="M140.3,129.4c3.1,0.9,3.7,3.4,3.7,6.2c0.1,11.6,0.2,23.2,0.2,34.8c0,22.4,0,44.7,0,67.1
	c0.2,6.7,1.6,13.3,1.3,20.1c-0.2,5.5-2.2,9.4-7.1,12.2c-17.6,10.1-35.4,19.7-53,29.5c-3.9,2.2-7.5,4.9-11.5,7
	c-1.5,0.6-2.9,1.5-4.7,1.6c-3-0.8-3.6-3.4-3.7-5.8c-0.2-10.1-0.4-20.2,0.2-30.3c0.7-11.3,0.7-22.7-0.1-34.1
	c-0.5-7.1-0.6-14.2,0-21.3c0.5-6.6,0.5-13.2,0-19.8c-0.5-6.9-1.2-14,0.8-20.8c0-3.4,2.3-5.4,4.7-7.2c4.1-3.1,8.5-5.6,13-8.1
	c16.7-9.2,33.1-18.9,49.4-28.8C135.5,130.3,137.5,128.7,140.3,129.4z"
    />
    <path
      class="st8"
      d="M165.6,249.7c-0.2,0-0.4-0.1-0.6-0.2c-2.6-1.6-3-4.1-3.1-6.8c-0.3-8.4,0.5-16.7,0.7-25c0.2-7-0.2-14,0.1-20.9
	c0.2-7.4-1.1-14.7-0.7-22c0.5-9.8-0.3-19.6-0.1-29.5c0.1-4,0.3-8,3.6-11c1.4-2.3,3.6-3.8,5.8-5.1c14.5-8.4,28.9-16.9,43.2-25.6
	c1.8-1.1,3.7-2.3,5.9-1c0.5,0.3,1,0.6,1.4,0.9c1.2,2.5,1.3,5.2,1.4,8c0.1,23.4,0.2,46.9,0.2,70.3c0,1.4,0.1,2.7,0.2,4.1
	c0.6,0.8,0.6,1.6,0,2.4c0,6.2,0,12.5-0.1,18.7c2.6,5.9,1.1,11.3-4.5,14.9c-5.2,3.4-10.6,6.5-16,9.6c-10.2,5.7-20.4,11.6-30.3,17.8
	C170.4,250.6,168,250,165.6,249.7z"
    />
    <path
      class="st9"
      d="M74.2,305c-0.6,0.9-1.2,1.8-1.7,2.5c-1.3,0.8-3.3,0.1-3.6,2.1c-2.9-0.6-4.9-2.5-6.8-4.6
	c0-41.7,0-83.4,0.1-125.1c0-3-0.9-6.3,2.2-8.5c1.2,0.9,0.2,2.3,0.7,3.4c0.8,0.2,1.5-0.9,2.4-0.2c-1.1,8.2-0.8,16.4-0.2,24.6
	c0.5,7-0.2,13.9-0.4,20.9c-0.2,12.1,0.3,24.1,0.5,36.2c0.2,14.5-1,28.9-0.6,43.3c0.1,2.5,0.4,4.9,1.2,7.2
	C70.6,307.3,72.1,305.1,74.2,305z"
    />
    <path
      class="st10"
      d="M167,134c-3.1,3.4-3.5,7.5-3.4,11.8c0.2,24.9,0.5,49.8,0.5,74.8c0,7.3-0.5,14.7-0.5,22c0,2.4,0.3,4.7,1.5,6.9
	c-4.3-1.5-6-3.8-6-8.7c0-17.7,0-35.5-0.1-53.2c0-1.6-0.1-3.2-0.2-4.9c0-1.5,0-3,0-4.6c-0.4-6.4-0.3-12.9,0-19.3
	c0.1-6,0.2-12,0.2-17.9c0-2.6,0.6-4.9,2.3-6.9C163.2,133.5,165.1,132.7,167,134z"
    />
    <path
      class="st11"
      d="M67.5,174.5c-1.3,0.9-1.6,3.2-4,2.7c-1.5-2.2,1.1-3.8,0.9-5.8c0.7-3.5,3.8-4.8,6.4-6.3
	c17.3-10.1,34.7-20.1,52-30.1c3.5-2.1,7-4.3,10.5-6.4c2.5-1.5,4.9-1.7,6.9,0.9c-15.2,8.8-30.3,17.6-45.5,26.5
	c-7.4,4.4-15.2,8-22.2,13C70.6,170.4,68.2,171.8,67.5,174.5z"
    />
    <path
      class="st12"
      d="M167,134c-1.9,0.6-3.8,1-5.8,0c2.3-3.7,6.2-5.3,9.7-7.4c13.8-8.3,27.7-16.4,41.5-24.5c2.8-1.7,5.5-2.5,8,0.4
	c-4.3,0.4-7.3,3.5-10.8,5.5c-12.5,7.2-24.9,14.6-37.3,21.9C170.4,131.1,168.8,132.7,167,134z"
    />
    <path
      class="st13"
      d="M158.8,158.9c0,6.4,0,12.9,0,19.3C157.8,171.8,157.9,165.3,158.8,158.9z"
    />
    <path
      class="st14"
      d="M243,55.3c1.4,2.6,0.4,5.5,0.7,8.2C242.2,60.9,243.3,58,243,55.3z"
    />
    <path
      class="st15"
      d="M327.7,132.6c-0.1-1.1-0.2-2.3,0.5-3.2c1.6-2.5,2.1-5.2,1.1-8.2c2.3-0.4,4.4-1.8,6,0.9
	c-0.4,0.5-1.4,0.6-1.1,1.6c2.5,3.1,6.3,4.2,9.5,6.1c9.1,5.5,18.2,11.1,27.9,15.5c0.3,0.2,0.6,0.5,0.9,0.7c4.8,4.2,10.4,7.2,16,10.2
	c2.6,1.4,5.1,3,7.8,4.3c2.4,1.2,3.4,3.5,3.4,6.2c-0.1,5.2,0.1,10.5,0.2,15.7c0.2,8.9-0.7,17.9,0.2,26.8c0.2,2.1,0,4.1-2.8,4.3
	c-3.2-0.6-4.8-2.9-5.6-5.7c-0.8-2.6-2.1-4.9-3.8-7.1c-0.7-1.3-1.7-2.4-2.4-3.6c-3-6.1-5.9-12.2-11.1-16.8c-0.6-0.6-1.4-0.9-2.3-1.2
	c-0.5-0.2-1-0.2-1.7,0c-2.3,1.9-4.1,5.5-8.3,4.4c-1.8-2.2-1.6-5.1-2.8-7.6c-0.7-1.4-0.9-3.1-2.3-4.2c-1.3-0.9-2.9-0.6-4.4-0.8
	c-0.9,0-1.8,0.1-2.6,0.3c-4.3,0.9-5.2,3.4-3,8.3c0.4,1.5,1.4,2.4,2.7,3.2c0.2,0.2,0.4,0.5,0.6,0.8c0.2,0.5,0.3,1,0.2,1.5
	c-0.5,1.6-1.4,3.1-1.1,4.8c-1.7,2.3-3.8,3.2-6.5,2.9c-2.9-0.9-3.6-4.2-6-5.7c-2,1.1-1.1,2.3-0.3,3.4c1.3,1.8,3.3,2.5,5,3.8
	c0.9,1.2,1.1,2.5,0.2,3.9c-3.2,0.9-6.4-0.5-9.6,0c-1.3-0.1-1.5,1-1.9,1.9c-0.4,1.1-0.9,2.2-2.4,1.2c0-12.3,0-24.6,0-36.9
	c-0.7-1.8-0.7-1.8-1.3-4c0.3-1.4,2-0.9,2.7-2.1c0-0.8,0.1-1.8,0.1-2.8c3.4-1,5,2.5,7.8,3c3.9,1.9,7.2,5.2,11.9,5.4
	c0.8-0.2,1.5-0.6,1.5-1.4c0.1-4.8,0.8-9.6-0.5-14.3c-0.2-0.3-0.4-0.6-0.5-0.9c-0.3-2.2-1.6-3.8-3.3-5.1c-3.7-2.7-7.6-5.1-11.4-7.6
	c-1.2-1.5-2.3-3.2-4.7-2.3c-0.3,0.2-0.5,0.4-0.9,0.5C328.6,133.1,328.1,133,327.7,132.6z"
    />
    <path
      class="st16"
      d="M233.2,308.8c0-29.8,0-59.5,0-89.2c-0.9-2.3-1.2-4.5-0.1-6.8c0.8-1.7,0.4-3.4,0.5-5.1
	c0.2-5.8-0.4-11.6,0.3-17.4c0.3-1,0.3-2.2,1.6-2.7c0.4-0.1,0.7-0.1,1.1,0c2.1,0.9,3.6,2.7,5.7,3.6c1.1,0.5,2.1,1.5,1.5,3
	c-0.9,0.9-2.5,0.2-3.5,1.6c1.1,4,7.1,2.7,8.1,6.9c-0.7,1.6-2.3,1.5-3.6,1.9c-1.8,0.1-1.4,1.8-1.9,2.8c-1.2,6.3-0.9,12.7-0.9,19
	c0,24.1,0,48.1,0,72.2c0,1.7-0.2,3.5,0.4,5.2c1.1,3.4,0,6.9,1.1,10.6c5.6,4.6,12.5,7.6,18.8,11.5c3.5,2.2,7,4.3,10.7,6.1
	c1.2,0.6,2.5,1.2,2.6,2.9c-2.2,2.8-5.3,2.1-8.4,2.3c-3.1-5.5-8.2-7.1-13.8-7.7c-1.3-0.1-2.5-0.6-3.3-1.7c-2.5-3.4-5.6-3.7-9.2-2
	c-2.3,0-4.1-1.2-6-2.4c-2-1.6-2.5-3.9-2.9-6.3C231.9,314.4,230.8,311.3,233.2,308.8z"
    />
    <path
      class="st17"
      d="M403.3,265.8c-1.4,1.8-2.6,0.5-3.8-0.2c-18.8-10.8-37.5-21.7-56.3-32.5c-5.1-2.9-10.3-5.5-14.9-9.1
	c-0.9-2-0.4-4-0.4-6c1.2-1.1,2.5-0.9,3.6,0.1c0.8,0.7,1.5,1.6,2.7,1.9c3,1.1,5.3,3.1,7.7,5c4.4,3.8,9.5,6.4,14.9,8.5
	c3,0.3,5.2,2.4,7.6,3.7c10.1,5.6,19.9,11.6,29.8,17.6c0.7,0.7,1.3,1.7,2.4,2c0.9,0,1.1-0.8,1.5-1.4c0.4-1.6,2-1.1,3.1-1.5
	c0.5-23.6,0.1-47.2,0.2-70.7c0-4.1-0.1-8.2,0-12.3c0-1.7-0.1-3.6,1.7-4.6c1.9,0.7,1.6,2.3,1.6,3.8c0,30.3,0,60.6,0,90.9
	C404.6,262.7,404.1,264.3,403.3,265.8z"
    />
    <path
      class="st18"
      d="M233.9,190.2c0,8.7,0,17.4,0,26.2c0,1.1,0.2,2.3-0.8,3.2c-9.5,5.2-19,10.5-28.5,15.8c-0.6,0.6-0.6,1.6-1.5,2.1
	c-0.8,0.9-2.1,0.2-3,0.9c-7,3.3-13.6,7.6-20.7,10.8c-4.6,2-9.2,2.9-13.9,0.6c5,0.7,8.2-3.1,12.1-5.2c7.8-4.3,15.3-9.2,23.2-13.5
	c6.3-3.4,12.4-7.3,18.6-11.1c2.7-1.6,3.9-4.2,4-7.2c0.1-2,0.1-4,0.1-6c3.1,1.4,5.5,4.2,9.6,5.6c0-5.3-0.1-10.1,0-14.9
	C233.3,195.1,232.6,192.6,233.9,190.2z"
    />
    <path
      class="st19"
      d="M402.8,166.2c0,25.5-0.1,50.9,0,76.4c0,3.8-1.1,7.6-0.2,11.5c0.4,1.5-0.2,3.2-1.9,4.1
	c-1.7-0.6-0.5-2.3-1.3-3.2c-0.4,0.8-0.8,1.5-1.2,2.2c-0.6-0.4-0.9-1-1.1-1.7c-0.5-2-0.6-4-0.3-6c-0.1-3.7-0.9-7.5,1.7-10.8
	c-2.2-4.9-2.1-10-1.7-15.1c0.2-3.6-1.4-7.2-0.3-10.8c0.9-0.1,2.2-0.1,2-1.1c-0.7-3.6,0.2-7.5-1.4-11.5c2.5-2.5,1.1-6,1-9.4
	c-0.2-7.6-0.2-15.1,0-22.7c0.1-3.9-1.3-5.8-4.8-7.7c-6.9-3.6-13.9-7.1-20.1-11.9c-0.9-0.7-1.8-1.4-2.1-2.5c2.9-1.3,4.7,1,6.8,2.1
	c6.7,3.6,13.2,7.5,19.7,11.5c1,0.6,2,1.8,3.2,0.1c0.8,0,1.5,0.1,1.9,0.8C403.9,162.4,404.7,164.3,402.8,166.2z"
    />
    <path
      class="st20"
      d="M236.2,188.1c-0.2-0.2-0.5-0.4-0.7-0.5c1-1.7,2.7-1.9,4.4-1.6c5.9,3.5,11.9,6.7,17.8,10.1
	c11.6,6.8,23.3,13.4,34.9,20.2c5.7,3.3,11.5,6.4,17.3,9.5c7.6,4.2,14.8,9.2,22.4,13.4c12.3,6.9,24.3,14.1,36.6,21.1
	c7.9,4.5,15.9,8.8,23.5,13.8c1.7,1.1,3.2,2.5,3.5,4.6c0,0.6-0.1,1.1-0.5,1.6c-1.5,0.8-2.6,0-3.7-0.9c-8.8-6.5-18.6-11.5-28.1-16.9
	c-3.4-2-6.8-4.1-10.3-6c-12.5-7.1-24.7-14.6-37.1-21.7c-16.7-9.5-33.3-19.1-50-28.7C256.2,200.4,246.1,194.4,236.2,188.1z"
    />
    <path
      class="st21"
      d="M399.7,410c-0.5,0.5-1,1-1.5,1.5c-0.8,0.9-1.9,0.8-2.9,0.8c-0.7-0.1-1.4-0.2-1.6-1.1c-0.1-1.5,1.5-1.9,1.8-3.1
	c0.2-1.2,0.1-2.4-0.2-3.6c-0.1-39.6-0.1-79.2-0.1-118.8c0-2,0.6-3.9,0.7-5.9c0.4-2.3,1.4-2.7,3.1-1c0.3,0.7,1.1,1.2,0.9,2.1
	c0.5,1.2,0.3,2.5,0.3,3.7c0,40.6,0,81.2,0,121.7C400.1,407.6,400.4,408.8,399.7,410z"
    />
    <path
      class="st22"
      d="M398.9,278.8c-1-0.7-2-0.1-2.9,0c-0.3,0.4-0.5,0.4-0.7,0c-2.7-4.4-7.2-6.4-11.4-8.8
	c-14.6-8.6-29.4-16.8-44-25.3c-12.2-7.1-24.2-14.7-36.7-21.4c-12.2-6.7-24.3-13.7-36.3-20.7c-9.2-5.4-18.6-10.4-27.9-15.8
	c0.3-0.3,0.6-0.5,0.9-0.8c6.6,1.4,11.8,5.6,17.5,8.8c20.7,11.8,41.3,23.6,62,35.6c24.7,14.3,49.4,28.8,74,43.2
	C395.6,274.8,398.1,276,398.9,278.8z"
    />
    <path
      class="st23"
      d="M335.1,134.1c4.7,1.8,8.6,5.1,12.8,7.8c1.6,1,2.8,3.1,2.4,5.5c-2.5,0.2-4.9-1.8-7.5-0.6
	c-2,0.2-3.8-1.5-6.1-0.4c-2,4.1,0.8,7.6,1.9,11.4c-0.3,1-0.9,1.6-1.9,1.8c-3.2,0.2-4.8-2.9-7.5-3.8c0-0.2,0-0.5-0.1-0.7
	c-1.4-5.4-0.4-10.9-0.6-16.4c-0.1-2-0.6-4.3,0.7-6.2l0,0C331.7,131.4,333.3,133.1,335.1,134.1z"
    />
    <path
      class="st24"
      d="M402.8,166.2c0-2,0-3.9,0-5.8c3,1.2,2.9,3.8,2.9,6.3c0.1,14.2,0.1,28.4,0.1,42.6c0,16.9,0,33.9,0,50.8
	c0,2.3,0,4.5-2.5,5.7c0-31.7,0.1-63.5,0.1-95.2C403.3,169.2,403.7,167.6,402.8,166.2z"
    />
    <path
      class="st25"
      d="M400.9,159.6c0.3,0.5,0.9,0.9,0.6,1.9c-1.7,1-2.8-0.4-4.2-1.1c-8.6-4.9-16.9-10.4-26-14.4l0,0.1
	c-12.4-6.5-24.2-14-36.5-20.9c-0.8-0.4-1.6-0.8-1.7-1.8c-0.3-0.6-1.3-0.9-1-1.8c1.2-0.6,2.2,0.2,3.2,0.5
	c19.5,11.5,39.4,22.2,58.7,34.1C396.1,157.5,398.3,159,400.9,159.6z"
    />
    <path
      class="st22"
      d="M399.7,410c0-41.9,0-83.8,0-125.7c0-1.1,0.1-2.2,0.1-3.4c1.4,3.4,0.3,7.1,0.8,10.6c0,29-0.1,57.9-0.1,86.9
	c0,9.1,0,18.2,0,27.3C400.5,407.2,400.9,408.8,399.7,410z"
    />
    <path
      class="st26"
      d="M333.7,220.9c-2.5,0.2-3.1-3.7-6-2.9c0-5.5,0-11-0.1-16.5c2.9-0.9,0.4-4.1,2.4-5.4c0.4,0.5,0.9,1.1,1.3,1.6
	c1.8,0.5,2.2,1.9,2.1,3.5c-0.3,4.3-1.9,8.3-3.7,12c1.8,1.2,2.8-0.4,3.8,0.3C334.6,216,334,218.5,333.7,220.9z"
    />
    <path
      class="st14"
      d="M428.3,152c0-28.5,0.1-56.9,0.1-85.4c0.9,1.2,0.6,2.6,0.6,4c0,25.8,0,51.6,0,77.4
	C429,149.4,429.3,150.8,428.3,152z"
    />
    <path
      class="st27"
      d="M223.7,188.1c0-0.8,0-1.6,0-2.4c1.1-4.4,0.2-8.9,0.5-13.4c0.1-1.4,0-2.9,0-4.3c0.3,0,0.6,0,0.9,0
	c0,12.2,0,24.4,0,36.5C223.4,199.2,225.1,193.5,223.7,188.1z"
    />
    <path
      class="st28"
      d="M329.2,132.6c0,7.5-0.1,15-0.1,22.6c-1.9-0.6-1.4-2.2-1.4-3.5c0-6.4,0-12.7,0-19.1
	C328.2,132.6,328.7,132.6,329.2,132.6z"
    />
    <path
      class="st29"
      d="M110.1,304.3c1.5-3.2,4.8-3.8,7.4-5.3c14.5-8.7,29.2-17,43.5-26.1c2.8-1.8,5.8-3.4,9.1-4.6
	c4.9,1.4,8.8,4.7,13.1,7c7.5,4,14.7,8.4,22,12.7c1.3,0.8,2.7,1.5,3.2,3c0.1,2.1-1.6,2.6-3,3.2c-7.6,3.7-14.7,8.3-22,12.5
	c-10.3,6.5-21.2,11.8-31.4,18.5c-0.8,0.5-1.8,0.9-2.8,1.1c-1.6,0.2-2.9-0.5-4.2-1.3c-6.7-4-13.7-7.6-20.7-11.1
	c-4.6-2.3-8.4-5.8-13.1-7.8C110.4,305.7,109.9,305.2,110.1,304.3z"
    />
    <path
      class="st30"
      d="M108.8,317.8c0-0.8,0-1.5,0-2.3c1.6-1.1,2.8-0.1,4.1,0.6c6.2,3.4,12.9,5.8,18.5,10.2c1,0.8,2.3,1.5,3.8,1.2
	c0.4-0.1,0.7-0.1,1.1,0c4.2,1.5,7.9,4,11.3,6.9c0.5,0.9,1.2,1.6,2.2,2.1c5-0.4,8.2-5.9,13.8-4.9c-4.6,4.8-10.2,8.1-16.1,11
	c-2.4,0-4.2-1.4-6.1-2.6c-3.6-2.4-7.5-4.4-11.5-6.2c-3.5-1.9-7.2-3.3-9.8-4.9c2.5,1.5,6,3,9.5,4.7c7.1,3.4,13.9,7.4,20.4,11.8
	c0.4,1.8-1.4,2.7-1.3,4c-1.2,1.1-2.4,1.3-3.6,1.5c-5.2-1.4-9.8-4.2-14.5-6.6c-3.6-1.8-6.8-4.5-10.5-6.3c-2-1-2.5-3-3.7-4.6
	c-2.2-2.3-4.9-3-7.8-3.3c-2.1,1.7-1.4,4.6-3.2,6.3c-0.8,0.6-1.6,1-2.6,0.4c2.4-2.9,0.5-6.8,2.4-9.8c0.8-1.3,1.6-2.3,3.2-2.9
	c0.1-1.1,0.2-2.2,0.4-3.3c2-1.4,3.5,0,5.2,0.8c9.5,4.5,18.2,10.4,28.1,14.1c-10.1-3.8-19.1-9.8-28.8-14.5
	C111.7,320.2,110,319.3,108.8,317.8z"
    />
    <path
      class="st31"
      d="M147,335.1c-3.7-2.4-7.1-5.1-11.2-6.8c-0.8-0.5-1.6-1.2-2.5-1.6c0.9,0.4,1.7,1.1,2.5,1.6
	c-2.6,1.5-4.2-0.7-5.9-1.8c-6.7-4.3-14-7.4-21-11c0.5-2.4-0.5-4.9,0.7-7.2c1.5-0.6,2.2,1.7,3.8,0.8c-0.8-1.9-3.2-1.3-4.2-2.7
	c-0.5-1.1,0.2-1.6,1-2.1c12.2,6.8,24.4,13.6,36.6,20.4c0.7,0.4,1.6,0.5,2.5,0.7c0.8,1.4,1.6,2.7,0.2,4.2c-0.5,0.5-1.2,0.8-1.9,0.9
	c-5-2.3-9.9-5-14.6-7.9c-5.8-3.6-11.6-7.2-15.3-8.6c8.9,3.7,19,11.1,30,17.1c0.4,0.1,0.7,0.3,1,0.5c1.2,1.2,3.7,2.1,1.1,4.3
	C148.7,336,147.7,335.8,147,335.1z"
    />
    <path
      class="st32"
      d="M147.6,329.7c0.5-0.2,1.1-0.5,1.6-0.8c3.8-3.9,8.5-6.4,13.1-8.9c8.1-4.4,15.7-9.6,23.8-13.9
	c7.5-4.1,14.7-8.8,22.3-12.6c0.6,0.1,1,0.4,1,1.1c-0.1,0.3-0.1,0.5-0.2,0.8c-1.1,3-3.3,4.9-6,6.3c-12.5,6.4-24.1,14.1-36.3,21
	c-0.2,0.1-0.4,0.3-0.5,0.5c-4.8,4.3-10.9,6.2-16.5,9.2c-0.6,0.3-1.5,0.4-2.1-0.2l-0.1,0C146.5,331.3,146.7,330.5,147.6,329.7z"
    />
    <path
      class="st33"
      d="M165.8,322.3c6.2-4.5,13.2-7.8,19.6-12c7.8-5.2,17-8.2,23.8-15c0.5,0.3,1,0.6,1.5,0.9
	c-0.2,0.5-0.5,0.9-0.8,1.4c0,0.2-0.1,0.4-0.1,0.5c-3,4-7.5,6.3-11.7,8.4c-8.7,4.2-16.3,10-24.4,15.1c-1.1,0.7-2.1,1.4-3.3,1.5
	c-0.8,0.1-1.6,0-2.5,0.1c-0.3,0-0.7,0-1,0C166.4,323,166,322.7,165.8,322.3z"
    />
    <path
      class="st34"
      d="M108.8,317.8c3.7-0.1,6,2.8,8.9,4.3c9,4.7,17.6,10.2,27.1,13.8c0.6,0.2,1.1,0.6,1,1.5c-0.9,1-1.6,0.3-2.5-0.2
	c-10.8-5.4-21.7-10.8-32.5-16.2c-0.5-0.3-1.2-0.2-1.8-0.4C108.8,319.7,108.8,318.8,108.8,317.8z"
    />
    <path
      class="st33"
      d="M209.4,294.5c-0.3-0.2-0.6-0.5-0.9-0.7c-7.5,4.2-14.7,8.9-22.5,12.6c-1.9,0.9-1.9,1-3.2-0.8
	c7.3-4.4,14.4-9,22.2-12.6c1.3-0.6,2.4-1.3,3.4-2.2C210.3,291.7,210.3,293,209.4,294.5z"
    />
    <path
      class="st35"
      d="M362.6,116.8c-5-1.4-9-4.8-13.5-7.2c-1.4-0.7-2.7-2.1-2.6-3.9c0.2-8.4,0.5-16.8,0.8-25.1
	c3-0.6,5.4,3.2,8.1,0.3c3.1-0.4,7.2,2.1,8.4,5.1c-0.9,0.2-2-0.3-2.9,1.3c4,2.5,8.3,4.9,9.6,10c-1.7,1.2-2.8-0.1-3.8-1.1
	c-3-2.8-7-3-10.6-4c-1.7-0.5-2.8,0.7-3.6,2c-0.9,1.5-0.9,3-0.1,4.6c1.9,3.7,4.6,6.7,7.6,9.5c1.1,1.6,2.1,3.3,2.8,5.1
	C363.2,114.4,364.2,115.7,362.6,116.8z"
    />
    <path
      class="st36"
      d="M359.2,108.5c-4.5-1.9-6-6.5-8.9-10.1c0-1.2,0-2.6,0-4.1c1-1.1,2-2.4,3.4-4c4.8,1.4,10.1,1,13.9,5.2
	c0.8,0.8,1.6,1.6,2.8,1.7c0.9,2.7,1.3,5.5,1.4,8.3c-1,0.5-2.1,0.8-3,1.4C365.9,109.1,362.7,109.7,359.2,108.5z"
    />
    <path
      class="st37"
      d="M359.2,108.5c0.1-0.6,0.2-1.2,0.3-1.8c1.8-0.5,2.2,1.6,4,1.6c1.9-0.9,4.2-1.8,6.3-3.1c1.1-0.6,1.5-0.1,2.1,0.4
	c0.5,5.6,1,11.2,1.6,17.2c-4.8-1-7.2-4.6-10.8-6C362.3,113.7,359.7,111.6,359.2,108.5z"
    />
    <path
      class="st38"
      d="M419,145.5c0.2,0.8,0.4,1.6-0.3,2.4c-1,0.3-1.7-0.2-2.4-0.7c-10.2-6.3-20.7-12.1-31.2-18
	c-2-1.1-4.7-2.7-3.6-6.8C393.4,131.4,406.9,136.9,419,145.5z"
    />
    <path
      class="st39"
      d="M419.5,119.2c-12.1-7.1-23.9-14-35.6-20.8c-1.4-0.8-2.4-1.7-1.8-3.9c2.8,0.3,4.6,2.4,6.7,3.6
	c9.6,5.3,19,10.8,28.5,16.2C419,115.4,420.6,116.4,419.5,119.2z"
    />
    <path
      class="st40"
      d="M382.1,99.8c12,10,27.5,13.4,37.9,24.8C411.8,123.5,382.8,104.8,382.1,99.8z"
    />
    <path
      class="st41"
      d="M382.2,109.7c-0.4-1.2-0.6-2-0.1-2.9c0.2-0.1,0.5-0.4,0.7-0.3c12.1,7.6,24.6,14.6,37.1,21.5
	c0.4,0.2,0.4,1.3,0.9,3.3C407,124,394.3,117.1,382.2,109.7z"
    />
    <path
      class="st42"
      d="M382.5,54.6c3.4,1.9,6.7,3.5,9.7,5.5c7.8,5.2,16.1,9.5,24.2,14.2c1.5,0.9,3.7,1.5,2.2,4.7
	c-12.1-7.2-24.1-14.3-36.1-21.5C381.5,56.9,381.9,56,382.5,54.6z"
    />
    <path
      class="st43"
      d="M419.1,113.1c-12.2-7.7-25.2-14.6-37.6-22.5c0.4-0.6,0.7-1.3,1.1-1.9c12.2,7.3,25,13.7,36.9,21.6
	C420.6,111,420.7,112,419.1,113.1z"
    />
    <path
      class="st44"
      d="M381.7,114.5c0.1-0.6,0.2-1.2,0.2-1.8c5.2,1.3,36.6,20,38.5,23c-3.1,0.4-5.1-1.5-7.4-2.6
	c-1.7-0.7-3-2.2-5.1-2.1c-2.7-0.4-4.6-2.3-6.8-3.7c-5.6-3.5-11.4-6.6-17-10.2C383,116.5,382.4,115.5,381.7,114.5z"
    />
    <path
      class="st44"
      d="M407.2,131.8c3,2.7,6.6,4.2,10.1,6.2c1.2,0.6,3.1,0.9,2.2,3.4c-4-0.8-7.2-3-10.6-4.9
	c-7.3-4.2-14.5-8.5-21.7-13c-2.5-1.5-6-2.5-5.5-6.6c0.8-0.9,1.7-0.4,2.3,0.1c6.6,5.1,14.4,8.4,21.2,13.1
	C406,130.6,406.8,131,407.2,131.8z"
    />
    <path
      class="st45"
      d="M413.8,88.1c-5.2,0.2-31-15.9-32.3-20.3C393.1,74.3,403.7,81,413.8,88.1z"
    />
    <path
      class="st46"
      d="M413.8,83.7c-3.4-0.2-5.6-2.6-8.2-4.1c-6.9-4-13.7-8.1-20.5-12.2c-1.7-1-3.2-2-2.7-4.3
	C389.4,65.5,413.2,80.9,413.8,83.7z"
    />
    <path
      class="st47"
      d="M347.9,41.3c4.7,0.8,22.7,12.2,23.8,15.5c-3.7-0.7-6.5-2.7-9.3-4.6c-4.8-3.4-10.5-5.4-15-9.2
	C347.1,42.6,347.4,41.7,347.9,41.3z"
    />
    <path
      class="st48"
      d="M371.1,63.9c-7.6-4.4-15.3-8.8-22.9-13.2c-0.8-0.5-1-1.4,0.1-2.9c7.9,4.8,16.5,8.4,23.6,14.6
	C371.7,62.9,371.4,63.4,371.1,63.9z"
    />
    <path
      class="st49"
      d="M406.2,91c-7.9-4.6-15.8-9.2-23.6-13.9c-1-0.6-1.2-1.5-0.2-2.9c8.3,4.9,17,9.3,24.7,15.3
	C406.7,90,406.5,90.5,406.2,91z"
    />
    <path
      class="st50"
      d="M407.2,131.8c-7.8-4-15.7-7.9-22.7-13.3c-0.9-0.7-1.5-1.7-2.8-1.7c0-0.8,0-1.5,0-2.3
	c7.6,4.6,15.2,9.1,22.8,13.8c1.2,0.8,2.7,1.3,3.4,2.8C407.7,131.3,407.5,131.6,407.2,131.8z"
    />
    <path
      class="st51"
      d="M371,84.6c-7.8-4.7-15.6-9.3-23.4-13.9c-0.6-1-0.3-1.7,1.1-2.5c7.4,5.2,16.2,8.9,23.2,15.1
	C371.7,83.7,371.4,84.1,371,84.6z"
    />
    <path
      class="st52"
      d="M347.5,55.3c3.5-0.4,22.6,11.2,23.1,14.2C362.7,65.1,354.5,61.3,347.5,55.3z"
    />
    <path
      class="st53"
      d="M363.8,86c-2.4-2.3-5.1-4.1-8.4-5.1c-0.5-0.3-1-0.6-1.5-0.9c-2.7-1-5.1-2.2-6.1-5.1c1.4-1,2.2,0.2,3.2,0.6
	c6,4.4,13.1,7,19,12.3C366.9,88.9,365.7,86.5,363.8,86z"
    />
    <path
      class="st54"
      d="M414.6,64.9c-0.2,3.3-2.6,1.1-3.9,1.6c-0.6-0.3-1.3-0.7-1.9-1c0-2-0.6-4.1,0.5-6.5c2.7,0.5,5.5,0.7,5.3,4.6
	c-1,0.9-1.9-0.3-2.9-0.2C412.5,64.2,413.8,64.1,414.6,64.9z"
    />
    <path
      class="st35"
      d="M351,75.5c-0.7-0.1-1.5-0.3-2.5-0.5c0.8,3,4,3.1,5.5,5.1c-1,0-2-0.1-3.1-0.1c-1.1-1.7-3.1-2.7-3.6-5.1
	C348.9,73.6,350,74,351,75.5z"
    />
    <path
      class="st55"
      d="M266.4,122c0.8-6.6-2.9-11.3-6.5-16c-4-5.3-8-10.7-9-17.5c0-0.3-0.7-0.5-1.1-0.8c-1.7-1.8,0.2-4.4-1.5-6.1l0,0
	c0.3-4.5,0-9,2-13.5c2.9-6.5,8.1-10.1,14.3-11.5c8.5-1.9,16.4,0.7,22.8,6.9c-0.1,0.7-0.4,1.5,0.2,2.1c5.7,5.4,7.7,13.1,12,19.4
	c0.3,0.4,0.6,0.8,0.9,1.2c3.2,6.3,4.7,13.1,5.4,20c0.9,8-1.9,14.9-7.2,20.8c-2.4,2.8-6.3,2-9,4c-1.2,0.8-1.1,2.5-2.2,3.4
	c-1.2,0.7-2.4,0.6-3.5,0c-0.7-0.5-1.4-0.9-1.9-1.6c-0.9-1-1.2-2.2-1.3-3.4c-0.5-7.2,0.4-14.4-1.1-21c1.3-2.4,3.6-3.2,4.1-5.9
	c-0.5-1.6-0.6-3.9-2.9-4.7c-1.7,0.3-1.8,1.7-2.3,2.6c1.5,8.4,2.3,16.7,1.9,25c-0.1,2,1.1,4.1-0.3,6.1c-1.8,0.7-3.1-0.3-4.5-1.2
	c-1.2-1-1.8-2.4-2.5-3.8c0.2-10.7-0.8-21.4,0.3-32.2c-0.1,1.2-0.1,2.5-1.5,3.1c-2-0.2-2.4-1.9-3-3.1c0.4,1.8,1.9,3.1,3.3,4.6
	c1.6,8.5,0,17.3,1,26c-2.1,1.9-3.9,1.2-5.6-0.5C266.9,123.8,267,122.7,266.4,122z"
    />
    <path
      class="st56"
      d="M297.6,126.5c10.3-11.9,7.1-24.8,2.6-37.7c-0.3-0.9-0.7-1.9-1-2.8c-0.6-2.6-0.4-4.7,3.1-4.6
	c2.3,0.7,1.9,3.1,2.9,4.6c2.6,4,2.5,9,4.5,13.3c1,6.4,1.1,12.8-1.2,19c-1.3,3.7-4.1,6.2-7.2,8.4
	C300.2,127.6,298.8,127.7,297.6,126.5z"
    />
    <path
      class="st57"
      d="M297.6,126.5c4.2-0.7,9.3-6.6,10.7-11c1.8-5.4,1-10.8,1.3-16.2c0.8,5,2.6,10,0.5,15.1
	c-0.6,1.5-0.5,3.1-0.4,4.7c-2.2,7-8,9.7-14.2,11.9c-2.4,0.9-5.2,1.1-6.7,3.7c-0.9,3,0.5,6.2-0.9,9.1c-2.7-3.1-1.7-6.5-1-9.9
	c0.1-1.4,0.2-2.7,0.3-4C290.8,128.4,294.6,128.7,297.6,126.5z"
    />
    <path
      class="st58"
      d="M302.3,81.5c-2,0.9-2,3.1-3.1,4.6c-3.4-2.9-5-7.2-6.7-11c-1.9-4.4-5.2-7.2-7.8-10.6c0.6-1.4,1.8-0.8,2.7-1.1
	c3.2,1.2,4.6,4.4,7.3,6.1c1.8,1.8,3.2,3.9,4.6,6C299.8,77.8,302.8,78.7,302.3,81.5z"
    />
    <path
      class="st59"
      d="M299.2,75.6c-2.7-1.1-3.5-3.6-4.6-6C297.2,70.8,299.2,72.5,299.2,75.6z"
    />
    <path
      class="st60"
      d="M367.4,38.7c-3.2-1.5-3.4-3.3-2.1-5.9C368.8,33.9,368.6,35.9,367.4,38.7z"
    />
    <path
      class="st61"
      d="M414.6,64.9c-1.4,0.1-2.9,0.5-4.5-0.4c-0.9-1.2-1-2.5,0.1-3.8c2.2-0.1,2.6,2.5,4.4,2.9
	C414.6,64.1,414.6,64.5,414.6,64.9z"
    />
    <path
      class="st62"
      d="M354.8,30c-1.8,0.4-2.6-0.4-3.4-1.2c-0.3-1.3,0.3-2.3,0.8-3.6C354.8,26.4,353.6,28.6,354.8,30z"
    />
    <path
      class="st63"
      d="M346.4,25.9c-2.7-0.4-2.2-2.2-2-4C347.5,22.3,347.6,22.4,346.4,25.9z"
    />
    <path
      class="st64"
      d="M358.2,30.6c2.1,0.8,2.6,2.3,2.7,4.6C359.3,33.8,357.1,33.4,358.2,30.6z"
    />
    <path
      class="st65"
      d="M287,173.6c-7.4-2.5-9.6-6.5-6.5-12.1c1.1-0.2,2.3-0.5,3.4-0.7c1.7-1.5,1.6-4.1,3.5-5.4
	c6.2-2.3,13,2.3,15.1,15.4c5.7,3.4,7.5,8.1,4.9,12.6c-4.6,0.6-7.1-3.6-10.6-5c-1.7,0-1.8,1.9-3.8,2.1
	C290.4,179.2,287.1,178.1,287,173.6z"
    />
    <path
      class="st66"
      d="M282.7,132.5c0.5,0.2,1.1,0.1,1.1,0.9c1.5,2,2,4.1,1.2,6.5c-0.4,1-1,1.7-2.1,2.1c-2.1-0.6-4-1.8-5.7-2.5
	c1.5,1.2,4.3,1.4,5.7,3.5c-0.2,1.9-1.4,2.6-2.6,2.1c-4.1-1.7-8.6-3.1-11-7.4c-0.4-0.8-2-1.7,0-2.5c-3.7-3.8-3.1-8.6-2.9-13.2
	c1,0,1.2,0.8,1.4,1.5c0.7,2.2,2.6,2.4,4.2,2.4c1.8,1,2.4,2.9,4.1,3.5c1.5-0.2,2.4,1.2,3.8,1.5C280.7,131.8,282,131.6,282.7,132.5z"
    />
    <path
      class="st67"
      d="M258,159.5c-1.4-2.3-0.6-4.8-0.6-7c1.5-1.9,3.4-1,5.1-1.3c1.2-1.3,2.6-2.6,4.6-2.8c3.5,1,8.7,7.6,8.8,11.1
	c0.1,2-1,3.1-3.9,3.7c-0.9-0.7-1.8-1.5-2.8-2.2c-2.4-0.6-4.5-2-6.6-2.7c-1.6,0.1-1.5,1.8-2.7,2.1C259.1,160.5,258.6,160,258,159.5z"
    />
    <path
      class="st68"
      d="M269.3,137.7c4.2,2.1,7.7,5.8,12.8,6c-2.3-3.2-6.9-3-9.6-6.7c3.9,1,6.9,2.6,9.7,4.2c1.2,0.1,1.3-0.9,1.9-1.3
	c2.6,2.7,1.2,5.2,0,7.5c-1.7,1.2-3.5,0.8-4.9,1.6c-0.3-0.2-0.5-0.4-0.8-0.5c-2-1.3-3.4-3.5-6.1-3.9
	C268.6,142.7,267.3,139.7,269.3,137.7z"
    />
    <path
      class="st69"
      d="M279.1,149c1-1.3,2.7-1.1,4-1.9c1-2.3,1.4-4.6,1-7.1c-0.1-2.2-0.1-4.4-0.2-6.5c1,0.6,2.1,0.5,3.2,0.6
	c2,3.1,0.4,6.6,1,9.9C286.8,147.8,284.4,149.1,279.1,149z"
    />
    <path
      class="st70"
      d="M258,159.5c0.6,0.1,1.2,0.1,1.8,0.2c0.9-0.9-0.6-2,0.7-3c3.6-0.3,6.2,2,8.8,4.3c-2.3-0.4-1.7,2.8-4.3,3
	C262.7,163.2,259.8,162.3,258,159.5z"
    />
    <path
      class="st1"
      d="M248.2,81.5c2.1,1.7,1,4.1,1.5,6.1c-0.1,0.5-0.2,0.9-1.2,0.9C247.9,86.2,248.3,83.8,248.2,81.5z"
    />
    <path
      class="st69"
      d="M272.2,144.6c-0.1-0.4-0.1-0.7-0.3-1.5c2.5,1.8,5,2.9,6.4,5.4C275.7,148,274.1,146,272.2,144.6z"
    />
    <path
      class="st71"
      d="M104.2,163.9c9.1-5.4,18.2-10.9,27.2-16.3c2-1.2,4.1-2.3,6.7-3.8c0,37.3,0,74,0,110.6c-2.2,1.5-3.8,3-6.2,3.4
	c-1.6-0.3-3.5,0.3-4.7-1.3c-0.3-1.3,0.8-1.9,1.5-2.6c0.8-0.9,1.3-1.7,0.3-2.8c-0.5-0.5-1-0.9-1.2-1.5c-0.2-1.5,1-2.6,1.7-3.8
	c-0.9-0.2-1.6,0.2-2.3-0.1c-0.9-0.4-1.5-1-1.7-2c0.2-2.2,2.4-2.7,3.9-5c-5.1,2.6-9.1,5.5-13.4,7.8c-1.4,0.7-2.7,1.9-4.5,1.4
	c-0.9-0.4-1.5-1-1.8-1.9c0-1.8,1.4-2.5,2.6-3.2c4.5-2.6,8.7-5.6,13.4-7.8c1-0.5,2-0.9,2.6-2c-2,0.4-3.5,2.1-5.6,1.7
	c-1-0.4-2-0.9-2.5-2c0-2.1,1.8-2.5,3.1-3.2c1.6-0.9,3.3-1.6,4.7-3c-2.2,1-4.4,2.1-6.5,3.2c-1.2,0.6-2.3,1.5-3.8,1
	c-6.9-0.6-12.2,3.4-18,6c-1.8,0.8-3.5,1.9-5.2,2.9c-0.2,0.2-0.5,0.4-0.8,0.6c-1.7,1-3.7,1.5-5.1,3.1c-5,3.4-6.1,3.4-9.1-0.1
	c4.2-6.3,11.6-8.3,17.4-12.1c0.8-1.5-0.6-2.6-0.1-3.8c0.3-2,2-2.4,3.4-3c0.7-0.4,1-0.6,0.4-1.3c0.2-2,1.7-2.4,3.2-2.9
	c6.8-3.9,13.7-7.8,20.5-11.7c4.3-2.5,6.1-5.8,6-10.6c-0.2-10.1,0.2-20.2-0.2-30.3c-0.5-1.7-2.8-2.3-2.7-4.4c0.4-1.9,1-3.8,1-6.1
	c-5.8,2.6-10.7,6.5-16.4,9.1c-1.3,0.6-2.6,1.5-4.1,1.5C106.5,166.4,103.4,167.4,104.2,163.9z"
    />
    <path
      class="st72"
      d="M127.5,256.3c1.4,0.8,3.5-0.8,4.5,1.5c-19.4,11-38.8,22.1-58.5,33.3c0-16.8,0-33.3,0-49.8
	c1.1-4.7-0.4-9.6,0.8-14.1c2.8-1.4,4.4-0.3,5.3,1.9c1.1,2.5,2.7,3.4,5.3,2.2c0.8-0.4,1.7-0.8,2.5-1.3c3-2,5.9-4.1,9.1-5.8
	c1.5-0.8,3-1.8,4.9-0.9c-0.1,1.7,0,1.8-1,2.2c-1.1,0.4-2.2,0.5-2.4,1.6c-0.5,1.9,2.3,1.5,1.8,3.5c-6.1,4.3-13.5,6.8-18.8,12.3
	c0.1,0.2,0,0.6,0.2,0.7c0.7,0.5,1.4,1,2.2,1.5c1.8-1,3.5-2,5.2-2.9c2.1-0.4,3.9-1.2,5.3-3l0,0c1.7-0.8,3.5-1.6,4.9,0.5
	c0.2,2.4-1.9,3-3.4,4c-4.4,2.8-9.1,5-13.2,8.1c2.9-0.8,5.4-2.4,8-3.8c2.9-1.5,5.6-3.8,9.3-3.3c0.7,0.4,1,1,1.1,1.8
	c-0.3,2-2.1,2.6-3.6,3.4c-4.1,2.1-8.3,3.9-12.2,6.3c-1,0.6-2.3,0.9-3,3.1c6.3-3.6,12.5-6.2,18-9.9c1.5-1,1.4-2.5,1.4-4
	c0.3-1.2,0.7-2.4,1.6-3.3c1.8-1.1,3.3-0.3,4.8,0.8c0.9,1.1,2.1,1.9,2.9,3c0.8,0.2,1.5,0.5,1.5,1.5c0.2,1.4-1.4,1.9-1.8,3.2
	c4.2-1.9,8.1-3.9,12-6c1.2-0.7,2.4-1.9,4.1-0.9c0.5,0.5,1,1,1.5,1.5c-1,2.9-3.8,3.8-6.1,5.1c-3.6,2.1-7.8,3.4-10.8,6.5
	c4.6-1.7,8.8-4.2,13.1-6.4c1.4-0.7,2.7-2,4.5-0.9c0.5,0.3,1.1,0.6,1.6,0.9c-0.2,2-1.8,2.6-3.1,3.4c-4.4,2.9-9.4,4.7-13.8,7.5
	c-1.2,0.8-2.7,1.4-3.6,3c1.6,0.5,2.6-0.5,3.7-1.1c3.5-2,7-3.9,10.5-5.8C124.6,256.7,125.8,255.5,127.5,256.3z"
    />
    <path
      class="st73"
      d="M86.2,232.5c-1.6,1.1-3.4,0.3-4.2,0.4c-3.8-0.8-2.5-5.6-6.1-5.7c-2.1,0.8-1.5,2.6-1.7,4.2
	c-0.4,3.3,0.8,6.7-0.8,9.9c-0.2-19.5-0.3-38.9,0.1-58.4c11.2-1.5,19.4-9.5,29.1-14.1c2-1,3.9-2,6.1-2.4c7.2-4.1,14.5-8.3,21.8-12.5
	c-2.1,2.6,0.8,6.6-2.4,8.8c-0.7,0.9-1.4,1.7-2.5,2.2c-12.1,6.1-23.5,13.5-35.4,20c-2.9,1.6-5.4,3.5-7.7,5.9
	c-0.8,0.7-0.9,1.6-0.9,2.7c0.2,9,0.2,18,0,27C81.6,225.2,81.6,229.6,86.2,232.5z"
    />
    <path
      class="st74"
      d="M108.7,166.3c-7.6,5.4-16.2,9.2-24.1,14.2c-3,1.9-6,3.2-9.6,3.2c-0.8,0-1-0.6-1.5-0.9c0.4-2.1,2.3-2.9,3.8-3.8
	c8.9-5.1,17.9-10.1,26.8-15.2C104.8,166.3,107.2,165.5,108.7,166.3z"
    />
    <path
      class="st75"
      d="M108.8,152.8c-2,3.4-5.2,4.9-8.7,6.2C102,155.7,105.2,154,108.8,152.8z"
    />
    <path
      class="st76"
      d="M106.6,279.8c0.4-2.1,1.1-3.1,3.4-3C110.2,279.1,109.2,279.8,106.6,279.8z"
    />
    <path
      class="st77"
      d="M217.5,209.1c-10.1,6-20.2,11.9-30.3,17.9c-5.9,3.5-11.8,7-18.2,10.7c0-31.1,0-61.8,0-92.9
	c0.5-0.8,1.5-1.5,2.6-2c0.8,0.8,1.1,1.8,0.7,2.7c-2.2,6.3,0.5,12.5,0,18.7c0.1,13.2,0.4,26.4-0.1,39.6c-0.3,8.1,1.1,16.1,0.8,24.2
	c0,1.1,0.1,2.1,1.6,1.7c5.2-3.1,10.6-5.9,15.8-9.1c7.6-4.6,15.7-8.6,22.7-14.2c0.6-0.5,1.3-1.1,1.6-2.1c-8,5.5-16.1,10.5-23.9,16
	c-1,0.2-2,0.1-2.7-0.7c-0.6-1-0.2-1.9,0.5-2.7c2.7-2.1,5.7-3.9,8.6-5.6c4.7-2.5,9-5.9,12.6-7.6c-3.7,1.9-8.1,5-12.8,7.8
	c-2.2,1-4.1,2.4-6.2,3.5c-2.1,0.2-3.2-0.9-3.6-2.8c-0.5-2.1,0-3.9,1.7-5.3c1.8-1.4,4.1-2.2,6-3.6c5.6-3,11.3-5.7,16.8-8.9
	c1.4-0.8,2.8-1.7,4.4-0.2c0.4,2.1-1.1,2.9-2.5,3.7c-6.5,3.9-13.4,7.2-19,10c6.5-3.6,14.8-6.9,21.5-11.8c0.2-2-0.3-3-1.6-3.8
	c-6.5,4.4-13.7,7.9-20.9,11.2c-6.6,2.7-12.4,7.4-19,9.2c-2.2-1-2.7-2.4-2.2-4c0.4-1.5,1.6-1.8,3-1.5c1.4,0.3,2.8,0,4-0.7
	c8.4-4.8,17.2-9,25.6-13.9c2.2-1.6,4.6-2.9,7.2-4c1.5-0.6,3-1.3,3.6-3c1.1-2,0.1-4.6,1.8-6.4c2.4-0.4,2.7,1.1,2.7,2.9
	c0,8.1,0,16.2,0,24.3C219.4,207.5,218.5,208.3,217.5,209.1z"
    />
    <path
      class="st78"
      d="M217.5,209.1c0.7-1,1.3-2.1,2.2-3.6c-0.2-8.3,0.4-17-1-25.8c-2.2,2.3-0.3,5.4-2,7.6c-1.9-1.6-1.5-3.9-1.5-5.9
	c0-14.8,0-29.7,0-44.5c0-1.4,0.4-3-0.7-4.7c-5.8,2.1-10.4,6.7-16.3,8.6c-3-0.6-5.1,2-7.8,2.5c-1.3-0.4-1.4-1.3-1.3-2.4
	c0.6-1.5,2-2.1,3.3-2.9c5.4-3.2,10.9-6.1,16.2-9.2c1.1-0.6,2.6-1,2.8-3.5c-11.1,5.8-21.3,12.3-31.7,18.5c-1.6,1-2.8,2.5-4.6,3.3
	c-3.5,1.5-2.5,3.8-1.1,6.1c0.5,2.4,1.4,4.8,0.6,7.2c-1.4,1-1.7,3.2-3.7,3.6c-0.3-4.1,0.2-8.2-0.7-12.3c-0.8-3.1,0.9-6,1.4-9
	c6.8-5.3,14.8-8.7,22.1-13.1c8.6-5.2,17.3-10.3,25.9-15.5c1.2,0.9,0.7,2,0.7,2.9c0.1,28.8,0.1,57.7,0.2,86.5
	C220.6,205.9,220.6,208.4,217.5,209.1z"
    />
    <path
      class="st79"
      d="M188.3,124.5c3.4-3,6.3-4.7,10-6.1C197.2,121.7,192.6,124.6,188.3,124.5z"
    />
    <path
      class="st80"
      d="M362.2,183.6c2.7-1.3,5.9-2.2,6.6-5.7c2.6-0.5,4.7-0.4,6.1,1.9c1.6,2.9,1.3,5.9,0.5,8.8
	c7,5.8,7.6,9.3,2.5,12.3c-0.6,0.2-1.3,0.3-1.9,0.3c-0.3,0-0.4,0.2-0.4,0.4c0.3,0.2,0.7,0.2,1.1,0.2c5.9,0.5,7,1.9,5.1,6.2
	c-0.9,0.7-2,0.8-3,1.1c-2,0.1-3.9-0.3-5.9-0.6c-3.7-0.7-6.1-2.7-7.1-6.3c-0.8-2.4-1.7-4.7-1.6-7.3c0-1.1-0.4-1.9-1.4-2.4
	c-1.7-1-2.8-2.2-2.7-4.3C360.5,186.6,359.9,184.4,362.2,183.6z"
    />
    <path
      class="st81"
      d="M341.2,198.6c-0.1-1.3,0.4-2.8-0.9-3.8c-0.1-1.7,0.7-2.4,2.3-2.2c1.9-0.8,4.2-0.7,5.7-2.8
	c0.4-0.8-0.7-1.3-0.8-2.3c0.2-1,2-1,1.9-2.4c1.8-0.1,2,1.2,2.2,2.5c0,1.8-1.6,3.3-0.7,5.2c0.5,1.9,0.9,4,2.7,5.2c1,1.3,2,2.8,4,2.7
	c0.6,0.2,1.1,0.5,1.5,1c1.4,2,1.7,4,0.4,6.1c-1.9,1.7-4.2,1.3-6.4,1.3c-2.2,0-4.2,0.3-5.9,1.7c-1.7,0.4-2.2-1.2-3.4-1.8
	c-1.8,0.2-4,2.9-5-1c1.5-1.4,1.7-3.2,1.6-5.1C340.4,201.4,339.9,199.8,341.2,198.6z"
    />
    <path
      class="st82"
      d="M396.7,212.8c1.6,3.3,1.6,6.9,1.4,10.4c-2.2,2-4.5,1.5-6.6,0.4c-1.2-1.2-1.2-2.5-1.7-3.7
	c-5-1.9-5.1-1.9-3.8-7.2c0.5-2.2-0.4-3.9-1.5-5.6c-0.6-1.5-1-3-0.8-4.6c0.4-2.7,1.7-3.4,4.6-2.5c2.1,2,3.7,4.3,4.2,7.2
	C393,209.8,394.6,211.5,396.7,212.8z"
    />
    <path
      class="st83"
      d="M342.6,146.2c1.5-1.6,3.5-0.6,5.2-1.1c1.3,0.4,0.7,2.6,2.5,2.4l0,0.1c1.4,5.1,0.4,10.4,0.6,15.5
	c0,1.4-1.2,1.7-2.1,2.4c-4.9-0.1-8.5-3-12-5.8c0.1-1,0.8-1.5,1.7-1.8c1.7-1,4.4,0.4,5.6-2.3C345.7,152.3,342.5,149.5,342.6,146.2z"
    />
    <path
      class="st82"
      d="M377.2,200c0.9-0.6,2.1-0.8,2.8-2.1c0.6-2.1-1.1-3.8-1.6-5.5c-1.2-1.5-3-1-4.7-2.3c0.3-3.2,1.6-6.7,1.2-10.4
	c5.7,4.5,8.8,10.7,11.4,17.3c-0.7,3.1-5.9,5.9-8.5,4.5C377.2,201.3,377,200.7,377.2,200z"
    />
    <path
      class="st84"
      d="M387.8,187.4c0-5.6,0-11.1,0-17c3.1,0.3,5.1,1.7,7.3,3.6c0.1,1.1,0.2,2.3,0.3,3.6c0.7,1.3,0.8,2.5,0,3.6
	c-1.4,0.4-1.8-0.8-2.5-1.3c-0.3-0.2-0.5-0.5-1.2-0.3c0.3,1.4,1.8,1.4,2.7,2c1.4,0.4,1.6,1.4,1.6,2.6c-0.2,2.5,0.5,5.1-0.6,7.9
	C391.9,191.8,390.4,188.7,387.8,187.4z"
    />
    <path
      class="st85"
      d="M341.2,198.6c0.5,2.9,1.3,5.7,0.7,8.7c-0.5,1.2-1.7,1-2.8,1.2c-2.8,1-3.4-1.5-4.6-3c-0.9-1.2-1.4-2.5-1.5-4
	c-0.5-1.2-1-2.4-1.5-3.7C334.8,196.7,338.1,196.3,341.2,198.6z"
    />
    <path
      class="st86"
      d="M362.2,183.6c-0.4,1.5-0.7,3-1.1,4.4c-0.4,0.1-0.7,0-0.9-0.3c-0.5-0.7-0.7-1.5-1.2-2.2
	c-0.8-0.6-1.8-0.6-2.8-0.8c-0.7,0-1.4,0-2.1,0c-1.5-0.2-3.4,1-4.5-1.1l-0.3,0c-0.2-2.1,2.4-1.7,3-3.3c1-2.6,3.1-4.7,3.1-7.7
	c0-1.2,0.3-2.6,2.2-1.8C360.5,174.6,361.8,178.9,362.2,183.6z"
    />
    <path
      class="st87"
      d="M371.2,152.7c0.9,0.4,1.8,0.8,2.8,1.3c0,4.6,0,9,0,13.5c-6.6-1.3-10.3-4.5-10.2-8.6c1-1,2.1-0.4,3.1-0.2
	c1.1,0.3,1.9,1.1,3.3,1c0.3-2,0.1-4.1,0.5-6.1C370.8,153.2,371,152.9,371.2,152.7z"
    />
    <path
      class="st88"
      d="M346.4,180.6c-0.7-0.8-1.4-1.7-1.8-2.2c-1.1-4,0.4-6.4,3.9-8.2c1.1,0,2.6,0,4,0c0.8,0.8,1.5,1.6,1.3,2.9
	c-0.2,1.3-1.4,2-1.7,2.7c-0.5,1.8,0,3.4-0.9,5C349.6,182,348,182.8,346.4,180.6z"
    />
    <path
      class="st89"
      d="M346.4,180.6c1.5,0.8,2.8,0.4,4.2-0.5c0.1-1.9-0.8-3.6,0-5.7c0.7-0.3,1.5-0.7,2.2-1.1c0.9-1.3-1.2-2-0.3-3.1
	c1.8,0,3.6-0.7,5.2,0.6c-2.8,0.9-0.2,2.8-0.7,4.1c-1.4,2.5-2.8,5-4.2,7.5c-0.9,0.8-2.5,0.1-3.4,1.2
	C347.4,183.5,346.3,182.6,346.4,180.6z"
    />
    <path
      class="st90"
      d="M333.1,123.3c11.8,6.8,23.6,13.6,35.3,20.5c1,0.6,2.4,0.8,2.8,2.2c-7.5-2.6-13.9-7-20.7-10.8
	c-5.3-3-10.5-6.2-15.8-9.3C333.8,125.4,332.1,125.2,333.1,123.3z"
    />
    <path
      class="st91"
      d="M387.8,187.4c1,0.8,2.2-0.3,3.6,0.4c1.5,0.3,1.4,2.8,3.6,3.7c-0.2-3.5,1.7-6.7-1.2-9.5
	c-1.1-1.1-1.3-2.2-0.1-3.4c0.4,0.6,0.7,1.1,1.2,1.9c0.2-1,0.3-1.9,0.5-2.9c1,0.3,1.3,1.1,1.3,2c0,3.5,0,7,0,10.5
	c0,2-0.9,3.4-2.8,3.9c-2,0.5-3.6-0.4-4.3-2.2C389,190.3,387.5,189.2,387.8,187.4z"
    />
    <path
      class="st92"
      d="M377.2,200c2.3,1.4,7.1-0.2,9.1-3c0.7,1,1.4,2,2.1,3c-3.1-0.2-3.9,1.5-3.4,4.2c0.2,1.1,0.3,2.2,0.5,3.3
	c-0.2,2-1.6,2.2-3.1,2.1c-1.2-0.1-2.1-0.7-1.4-2.1c1.1-1.2,0.9-2.5,0.1-3.8c-1.9-1.3-4.1-0.5-6.2-0.7c-0.6-1-1-2,0-3
	C375.7,200,376.5,200,377.2,200z"
    />
    <path
      class="st93"
      d="M342.6,192.5c-0.8,0.7-1.5,1.5-2.3,2.2c-3.1-0.2-4.9-2.5-6.2-4.7c-1.4-2.4,0.7-3.7,2.7-5
	C339.5,187.1,340.4,190.3,342.6,192.5z"
    />
    <path
      class="st94"
      d="M366.7,159.6c-1-0.3-2-0.5-2.9-0.8c0-2.5-1.9-4.7-1.2-7.5c0.5-1,1.3-1.8,2.9-2.1c1.9,0.9,4.2,1.7,5.9,3.4
	c0,0.3,0,0.6-0.1,0.9c-1,1.1-2.4-0.1-3.5,0.5C366.6,155.7,368.3,158,366.7,159.6z"
    />
    <path
      class="st23"
      d="M348.7,165.4c2.1-2.9,1.4-6.2,1.5-9.3c0.1-2.9,0-5.7,0.1-8.6c1.7,0.5,1.3,2,1.3,3.1c0.1,4.7,0,9.3,0,13.5
	C350.7,165.6,349.7,165.6,348.7,165.4z"
    />
    <path
      class="st95"
      d="M349.6,183.6c1.4,0.7,2.9-0.3,4.3,0c0.5,2.4,1.3,4.8-1.7,6.2c-1-0.1-1.3-0.8-1.3-1.7c0-1.3-0.5-2.3-1.4-3.1
	C349.5,184.5,349.6,184.1,349.6,183.6z"
    />
    <path
      class="st28"
      d="M335.1,134.1c-2.1,0.1-4.1-0.4-5.9-1.6c-0.2-0.9-0.1-1.7,0.5-2.6C331.6,131.3,334.6,131,335.1,134.1z"
    />
    <path
      class="st96"
      d="M235.5,323.1c2,0.1,3.4,1.7,5.2,2.1c1-0.2,2.1-0.3,2.9,0.3c4.6,2.8,9.9,4.2,14.6,6.8
	c26.4,15,52.9,29.7,79.4,44.5c14.5,8.1,29,16.2,43.1,25c2.8,1.7,5.8,3,8.6,4.6c1.6,0.9,3.4,1.9,2.8,4.3c-1.2,2.5-3.4,4-5.6,5.3
	c-22.7,13.1-45.1,26.7-68.1,39.1c-7,3.8-14,7.7-20.8,12c-2.5,1.6-5.1,3.2-8.1,3.8c-5.2-0.4-9.4-3.2-13.7-5.7
	c-17-9.6-34.2-18.9-51.1-28.8c-1.5-0.9-3.1-1.7-3.8-3.5c-0.4-1.1-0.8-2.1-1.8-2.8c-4.5-2.9-9.1-5.5-14-7.7c-0.8-0.3-1.5,0.1-2.2,0.1
	c-3.6-0.8-6.7-2.7-9.8-4.5c-14.2-7.9-28.5-15.9-42.7-23.9c-4.4-2.5-8.9-5-12.3-8.9c-0.7-2.2,0.5-3.5,2.1-4.7c6-4.5,12.6-8,19-11.9
	c8.1-4.9,16.2-9.8,24-15.1c13.9-9.1,28.3-17.4,42.5-26C228.8,325.8,231.7,323.5,235.5,323.1z"
    />
    <path
      class="st97"
      d="M275.1,335.1c-7.8-4.5-15.6-9.2-23.5-13.6c-3.4-1.9-6.6-3.9-10.2-6.3c0-3.3,0-6.7,0-10.2c0-30.2,0-60.5,0-90.7
	c0-0.6,0.1-1.2,0.3-1.8c2.1-0.9,2,0.8,2.4,2c0.6,29.2-0.1,58.4,0.3,88.5c45.9,25.3,91.4,51.6,137,77c2.9-1.7,2.4-3.9,2.4-5.8
	c0.1-15.6,0-31.2,0-46.8c0-1.8,0-3.5,0.3-5.2c0.1-1.5,0-3.1-0.3-4.6c0.1-7-0.1-13.9,0-20.9c0.3-1,0.3-2.1,0.8-3
	c1.2-1.7,2.1,0.2,3.2,0.3c1.1-1.1,0.5-2.6,1-3.9c0.4-1,0.7-2.4,2.3-1.1c1.1,1.5,1,3.3,1,5.1c0,28.8,0.1,57.7-0.1,86.5
	c0,5.3-0.3,10.7-0.4,16.1c0,1.3-0.4,2.4-1.9,3.1c-1.7,0.5-3.3-0.5-4.8-1.3c-29.2-17-59.1-33-88.4-49.8c-6.1-3.5-12.2-6.9-18.3-10.2
	C277.1,337.6,275.4,337,275.1,335.1z"
    />
    <path
      class="st98"
      d="M390.7,398.1c0-33.5,0-67.1,0-100.7c0-2.9,0.7-6-0.8-8.8c-2.1,0-3.4-1.7-5.2-2.3c-18-10.2-36-20.5-53.8-30.9
	c-20.8-12.1-41.8-23.9-62.5-36.1c-6.8-4-13.6-7.8-20.4-11.7c-1.5-0.9-3.6-1.4-3.6-3.8c1-0.4,2-0.8,3.1-1.2c-2-2.5-5.1-2.6-7.6-3.9
	c-1.1-1.5-2-2.9-0.9-4.6c1.6-1.5,3.1-0.2,4.7,0c0.7-0.2,1.4-0.1,2,0.3c2.4,2.6,5.2,4.3,8.7,4.9c7.6,3.4,14.6,8,21.8,12.1
	c13.2,7.6,26.5,15,39.6,22.6c12.5,7.3,24.9,14.5,37.4,21.8c11.2,6.9,22.8,13.2,34.2,19.9c1.7,1,3.3,2.3,4.8,3.5
	c0.8,0.7,1.6,1.7,2.9,1c0-0.5,0.1-1,0.1-1.5c0.2,0,0.5,0,0.7,0c0.9,1.5,0.8,3.1,0.8,4.7c0,40.7,0,81.4,0,122
	c-0.8,0.6-0.6,1.9-1.8,2.5c-1.5,0.2-3.1,0.3-4.4-1.3c3-5.5,3.8-11.5,2.8-16.9c-0.1,1.3,0.5,3.5,0.1,5.5
	C393.2,396.7,393.5,398.9,390.7,398.1z"
    />
    <path
      class="st99"
      d="M390.7,398.1c1.9-0.5,1.4-2.1,1.5-3.4c0.1-3.2,0-6.4,0-9.9c3.9,2.5,3.3,19.5-0.6,21.9c2,0.9,3.5-0.2,5.1-1.1
	c-0.2,2,0.9,4.5-2.1,5.4c-0.1,0-0.1,0.3-0.1,0.3c0.2,0.1,0.4,0.2,0.6,0.3c-1,0.7-1.9,1.5-2.9,2.2c-1.3-0.7-1.2-1.8-0.9-3
	c0.5-1.6-0.6-2.1-1.7-2.7c-27.4-15.4-54.7-30.9-82.1-46.4c-17-9.6-34.1-19.2-51.2-28.7c-5.1-2.8-10.9-4.1-15.7-7.6
	c4.9-3.4,6.3-3,10.8,2.7c5.8,1.1,12.6,1,16,8.2c2.2-0.3,4.9-0.8,7.6-1.2c16.4,9.1,32.8,18.3,49.1,27.7c18.9,10.8,38,21.2,57.1,31.7
	c2.2,1.2,4.2,2.6,6.4,3.8C388.7,398.9,389.8,399.8,390.7,398.1z"
    />
    <path
      class="st100"
      d="M244.4,203.8c11.6,6.8,23.2,13.6,34.9,20.3c32.6,18.8,65.2,37.6,97.8,56.5c2.8,1.6,5.9,2.6,8.2,5
	c1.3,2.5,0.3,5,0.2,7.5c-0.1,1-0.2,2-0.3,3c-1.5,0.4-2.5-0.4-3.3-1.6c-0.7-1.3-0.2-2.7-0.4-4.3c-2.3-3.8-5.8-6.3-9.9-8.5
	c-8.5-4.4-16.9-9.2-25.1-14.2c-20.7-12.4-41.8-24.2-62.6-36.3c-1.9-1.1-3.7-2.6-5.9-3.3c-10.3-6-20.6-11.8-30.7-18.1
	c-1.4-0.9-2.7-1.8-4.4-1.9c-0.3-0.1-0.6-0.2-0.9-0.5c-0.2-0.5-0.2-1,0.1-1.5c0.1-0.7,0.2-1.4,0.3-2.2
	C243.1,203.1,243.8,203.8,244.4,203.8z"
    />
    <path
      class="st101"
      d="M353.2,256.3c-12.3-6.8-24.5-13.9-36.6-21c-14.5-8.6-29.2-16.7-43.9-24.9c-6.2-3.5-12-7.4-18.5-10.4
	c-3.3-1.2-6.2-3.2-9-5.2c-0.5-0.2-1-0.5-1.5-0.8c-1.8-2.9-5.7-3.1-7.5-6c5.3,1.6,9.7,5,14.5,7.7c20.9,12,41.6,24.3,62.6,36
	c13.7,7.7,27.1,15.8,40.6,23.8C354.1,256.3,354,256.7,353.2,256.3z"
    />
    <path
      class="st102"
      d="M242.1,206.1c0.1,0.5,0.1,1,0.2,1.5c1.1,1.5,1.3,3,0,4.5c0,27.5,0,55.1-0.1,82.6c0,3.5,0.4,7-0.8,10.4
	c-1.1-1.3-0.7-2.9-0.7-4.3c0-28.2,0-56.5,0-84.7C240.7,212.6,241.2,209.3,242.1,206.1z"
    />
    <path
      class="st103"
      d="M333.7,220.9c0-1.2-1.6-1.6-1.2-3.9c0.2-0.5,0.7-2,1.2-3.5c0.1-0.1,0.2-0.3,0.3-0.4c1.8-1.1,3.4-0.2,4.8,0.8
	c11.3,7.7,23.6,13.6,35.3,20.5c1.8,1.3,3.4,2.9,5.6,3.7c0.3,0.1,0.6,0.3,0.9,0.5c4.6,3.1,9.7,5.4,14.4,8.3c1.2,0.7,2.4,1.3,2.9,2.7
	c0,2-0.1,4-0.1,6c-1.3,1.5-2.8,0.9-4.2,0.4c-11.9-7-23.8-14-35.7-21c-0.5-0.3-1.2-0.4-1.8-0.5c-4.9-3.2-10.1-5.9-15.2-8.8
	C338.7,224.1,336.2,222.5,333.7,220.9z"
    />
    <path
      class="st104"
      d="M341.1,225.6c3-0.3,5.1,1.7,7.3,3.1c2.7,1.7,6.2,2.5,7.9,5.7C350.8,232.3,345.3,230.1,341.1,225.6z"
    />
    <path
      class="st105"
      d="M393.8,255.9c1.4-0.1,2.8-0.2,4.2-0.4c0.1,0.5,0.2,1.1,0.2,1.6C395.9,260.1,395.1,257.2,393.8,255.9z"
    />
    <path
      class="st105"
      d="M398.1,249.5c-6.2-3.1-12.6-5.9-18.1-10.4c0.8-2.8,3.7-2.1,5.8-3.2c0.9-2,0.4-4.5,1.5-6.6
	c0.2-1.2,0.9-2.3,1-3.6c-0.1-1.4-1.1-2.6-1.8-4c-0.2,1.1-0.2,2.4-1.6,2.6c-2.8-1.4-2.3-4.3-2.5-6.5c-0.3-3-1.2-5.5-3.4-7.5
	c-0.4-0.6-0.5-1.3-0.4-2c0.6-0.7,1.4-0.7,2.3-0.7c1.5,3,3,0.4,4.5,0c3,2.2,3.1,4,0.4,8.6c0.3,0.4,0.6,1,1.1,1.4
	c0.4,0.4,0.9,0.7,1.6,1.2c0.8-0.6,1.6-1.1,2.4-1.7c1.3,1.9,0,4.1,1.2,6c1.9,0.8,4,0.3,6.1,0.4C398.3,232,399.4,240.8,398.1,249.5z"
    />
    <path
      class="st106"
      d="M353.2,256.3c0.2-0.2,0.5-0.5,0.8-0.7c13.8,8.1,28.1,15.4,41.2,24.7c-0.3,1.6-1.1,1.4-2,0.6
	c-8.7-7.1-19.2-11.4-28.7-17.3C360.7,261.3,356.6,259.3,353.2,256.3z"
    />
    <path
      class="st107"
      d="M342.6,146.2c1.6,1.9,2.9,3.9,3.2,6.5c0.3,3.3-0.6,5.6-4.5,6.1c-0.8-0.3-1.8-0.6-2.9-0.9
	c-3.7-3.7-4.1-8.1-3.2-12.8C338.1,143.5,340.4,144.9,342.6,146.2z"
    />
    <path
      class="st108"
      d="M333,201.4c2.6,1.9,2.7,5.9,6.1,7.1c0.9,0.7,1.8,0.5,2.5,0.4c1.6-0.6,0.7-2.2,1.8-3.1c1.7,1,2.1,3.1,3.1,4.6
	c-1.6,3.2-3.2,4-6.9,3.2c-1.9-0.4-3.8-1-5.8-0.1l0,0c-1.6,0.4-1.5,3.1-3.9,2.7c-0.9-1.2-1-2.9-1.4-4.6c2.8-2.1,3.2-5.3,3.6-8.5
	C332.1,202.3,332.1,201.6,333,201.4z"
    />
    <path
      class="st109"
      d="M182.8,305.6c1.4,1.6,2.4,0.2,3.6-0.4c7-3.3,13.7-7.3,20.4-11.2c0.5-0.3,1-0.8,1.7-0.3
	c-5.1,4.3-10.9,7.4-16.8,10.4c-8.9,4.5-16.8,10.7-25.7,15.2c-5.8,2.9-11.3,6.3-16.9,9.5c0.8-1.3-0.6-1.3-1-1.8
	c-0.2-1,0.9-1.1,1.1-1.8C160.2,318.3,171.6,312.2,182.8,305.6z"
    />
    <path
      class="st110"
      d="M153.6,280.9c4.4,3,9.5,4.2,13.8,7.7c-3,2.6-6.4,4.1-9.6,5.7c-4.5-2-9.3-3.3-12.6-7.8
	C147.7,284.2,150.2,282.2,153.6,280.9z"
    />
    <path
      class="st111"
      d="M184.9,298.9c-3.8,3.2-6.9,4.8-10.2,6c-5.2-1.7-9.1-5.4-13.8-7.9c2.1-2.6,5.5-3.2,7.9-5.5
	C174.9,292.3,178.6,297.3,184.9,298.9z"
    />
    <path
      class="st112"
      d="M138.5,305.7c-6.2,6.3-17.3,4.4-19.7-3.3c2.3-2.3,5.3-3.4,8.4-4.5C131.4,299.6,135.4,301.7,138.5,305.7z"
    />
    <path
      class="st113"
      d="M144.4,296.5c-1.3-1.5-4.3-1.2-4.6-4c-1.5-0.1-2.7-0.2-3.9-0.4c3.4-3.7,5.3-3.8,10,0c2.1,1.7,4.1,3.4,6.8,4.3
	c-6,6.8-10,7.3-16.7,2.1c-1.5-1.1-3.4-1.5-4.5-3.2c0.3-1.6,1.5-2.1,3.1-2.4c3.1,1.3,5.2,4.4,8.7,5
	C143.8,298.1,144.4,297.5,144.4,296.5z"
    />
    <path
      class="st114"
      d="M155.2,310.3c-2.8-1.1-6-1.8-7.7-5.3c2.2-2.9,5.7-3.9,8.7-5.8c2.8,1.4,5.9,1.9,8,4.3c-0.9,1-2.6,0.8-3.2,1.8
	c-1,1.5-0.3,3-1,4.1C158.7,311.2,156.9,310.3,155.2,310.3z"
    />
    <path
      class="st115"
      d="M143.2,317.8c-2.6-1.2-5.1-2.4-7.2-4.4c6.5-6.8,11.1-5.7,19.6,2C151.1,320.2,147.7,320.8,143.2,317.8
	c1-1.4,2.3-2.4,3.6-3.6c-0.8-1.5-2-1.7-3.5-1.5c0.2,1,1.5,0.9,1.5,2C144.8,315.9,144,316.9,143.2,317.8z"
    />
    <path
      class="st116"
      d="M155.5,279.5c2-0.8,2.9-1.2,3.7-1.5c7.5,3.6,13.8,9,21.7,13.1c1,2.5,5.8,2,6.7,6.2
	C175.9,293.6,167.9,283.8,155.5,279.5z"
    />
    <path
      class="st117"
      d="M178.5,281.1c-2.7,2.8-5.5,2.6-8.6,0.5c-2.6-1.8-5.6-3.1-8.8-4.8c1.8-1.3,3.2-2.3,4.6-3.3
	c1.2-0.6,2.6-0.7,3.6,0c2.8,1.9,5.6,3.7,8.7,5C178.9,278.9,179.3,280,178.5,281.1z"
    />
    <path
      class="st118"
      d="M155.2,310.3c1.1-1.3,3-0.1,4.1-1.3c0.7-1.3-0.7-2-0.9-3.2c1.7-1.3,2.8-4,5.8-2.3c1.9,0.5,3.1,1.9,4.5,3.8
	c-2.1,2.8-4.9,4.6-8.2,5.8C158.5,312.4,156.7,311.7,155.2,310.3z"
    />
    <path
      class="st119"
      d="M198.6,288.5c-0.7,2.9-5.5,6-8.6,5.2c-3.4-1-6.8-2.3-9.2-5.2c-1.4-3,0.6-2.9,2.6-2.8c1.6,0.6,2.7-0.6,4-1.1
	c1.4-0.2,2.7-0.4,3.1,0c1.8,1.7,0.6,3.3,1.6,4.5C194.4,290.4,196.2,286.9,198.6,288.5z"
    />
    <path
      class="st120"
      d="M178.5,281.1c-0.1-0.4-0.1-1-0.2-1c-4.8-1-7.6-5.8-12.6-6.5c1.4-0.8,2.8-1.6,4.4-2.5c4.3,2,8.4,4.2,12.3,7
	C181.4,280,180.1,280.7,178.5,281.1z"
    />
    <path
      class="st121"
      d="M198.6,288.5c-1.9,0.9-3.7,1.9-5.7,2.8c-1.2-0.3-2.6-0.6-3.2-2.5c-0.5-0.9,1-1.6,1-2.7c-1-1-2.2-0.5-3.2-0.6
	c-0.5-1-1.5-1.6-2.2-2.5c0-0.2-0.1-0.3-0.1-0.5c0.7-1.7,1.9-1.6,3.4-1C192.3,283.4,195.9,285.3,198.6,288.5z"
    />
    <path
      class="st121"
      d="M183.7,287c-0.7-0.1-1.5-0.3-2.2-0.4c-1,0.3-0.6,1.2-0.8,1.9c-1.2-0.7-2.3-1.3-3.8-2.2c2.5-2.2,5-3.9,8.3-3.8
	l0,0C186.7,284.7,185.2,285.8,183.7,287z"
    />
    <path
      class="st122"
      d="M145.6,350c0.8-0.3,1.5-0.7,2.3-1c0.8-0.8-0.5-1.2-0.4-1.5c0.4-1.6,2-0.9,2.4-2c-0.4-1.1-2.4-1.6-1.3-3.4
	c4.2-2.1,7.7-5.5,12-7.4c1.1-0.5,2.1-1.4,3.7-0.3c-0.3,3.6-4.1,2.9-5.8,5c7.7-4.6,13.1-12,20.3-16.1c2.2,0.9,1.2,2.4,2.2,3.5
	c2.7,0.5,3.7-3.4,6.5-3c7.2-4.8,14.8-9,22.4-13.2c0.3,2,0.2,4.1-0.2,6.1c-1.5,1.8-4.1,2-5.3,4.7c2-0.5,3.3-2.2,5.2-2.2
	c0.9,1.7-0.1,2.6-1.3,3.3c-6.2,3.7-12.3,7.4-18.5,11.1c-11.5,6.9-22.9,14-34.8,20.2c-1.8,1-3.4,2.4-5.1,3.7c-2.6-2.4-2.6-4,0.1-5.7
	c1.5-0.9,3-1.7,4.2-2.9c0.3-0.4-0.3-0.5,0-0.2c0.1,0.1,0.1,0.2-0.1,0.3c-1.3,1.1-2.7,2.1-4.3,2.8C147.7,352.7,146.2,352.4,145.6,350
	z"
    />
    <path
      class="st123"
      d="M187.5,323.8c-1.9,1.7-3.7,3.3-5.7,5c-0.7-0.9-1.7-1.1-2.5-1.3c-1.2-1.2,0.5-2.1,0.1-3.7
	c-8,6.4-14.8,13.7-24.5,17.9c2.2-3.6,6.3-4.1,9.4-7.4c-5.1,1.5-8.4,4.2-11.9,6.7c-1.3,0.9-2.4,1.8-4,1.5c-0.6,0.2-0.8-0.1-0.9-0.7
	c5.1-3.3,10.2-6.6,15.2-9.8c-4.8,1-8.3,4.7-13.2,5.9c-0.7-1.1-2.7-1-2.7-2.9c1,0.1,2,0.1,3,0.2c4.6-2.8,8.8-6.2,14.3-7.2
	c1.1-0.2,1.9-1.2,2.8-1.8c3.7-2.5,7.4-5,11.7-6.5c3.5-1.3,6-4.4,9.1-6.4c5.4-3.6,10.6-7.5,17.2-8.5c0.6-0.1,1.2-0.2,1.8,0.1
	c0.3,0.5,0.6,1,0.8,1.5c0,2-1.7,2.6-3,3.5c-6.9,4.6-14.2,8.5-20.2,13.8c-0.8,0.2,0,0.5-0.2,0.1c-0.1-0.1,0.1-0.1,0.3-0.2
	c0.7-0.4,1.4-0.4,2,0.2C186.9,323.8,187.2,323.8,187.5,323.8z"
    />
    <path
      class="st124"
      d="M145.6,350c0.8,0.5,1.7,1,2.2,1.4c2.7,0,4.4-2,7.2-2.7c-2.2,2.5-5.1,3.2-6.5,5.7c0.4,1,0.9,2,1.3,3
	c-0.9,0.7-1.5,1.8-2.8,1.6c-4.4-4.6-10.3-7.2-15.7-10.4c-4.5-2.7-9.1-5.1-13.6-7.6c-2.1-3-2.2-5.9-0.1-8.9c1.3,0.8,0.5,2.4,1.4,3.5
	C127.6,340.7,136.2,346,145.6,350z"
    />
    <path
      class="st125"
      d="M206.9,305c-4.7,1.6-9.4,3.2-13.5,6.1c-2,1.4-4.3,2.6-6.1,4.2c-6.5,5.9-14.8,9-22.2,13.3
	c-4.5,2.6-9.5,5.8-15,6.7c0.8-2.1-1.6-2.1-2.2-3.2c6.1-3,12.5-5.5,18-9.7c0.5,0,1,0,1.5,0c-0.2,2.1-1.8,3-3.4,3.9
	c-2.7,1.5-5.3,3.2-7.5,3.9c1.4-0.7,3.6-1.8,5.8-3c2.3-1.3,4.5-2.6,5.9-5c0.5-0.7,1-0.6,1.5,0c1.2,1.1,2.2-0.1,3-0.6
	c4.8-3.2,10.1-5.8,14.7-9.5c6.2-4.9,14-7.2,20.5-11.9c1.2-0.9,1.5-1.9,2.4-2.8l0,0c1.2,1.4,0.7,2.9,0.5,4.6
	C209.4,303.1,208.1,304,206.9,305z"
    />
    <path
      class="st126"
      d="M186.6,323.8c-1.7,0.8-3.3,1.5-5,2.3c7.4-8.3,17.7-12.7,26.1-19.6c0.5-0.3,1.1-0.6,1.7-0.9
	c1,0.6,0.5,1.5,0.5,1.9C202.3,313.5,193.1,316.6,186.6,323.8z"
    />
    <path
      class="st127"
      d="M147.6,341.9c0.3,0.2,0.6,0.5,0.9,0.7c0.5,1,2.9,1,1.4,2.9c-2.6,0.1-4.3-1.9-6.2-3.1c-4.6-3-9.8-5.1-14-8.8
	l0,0c3.9,0.5,7.1,2.5,10.4,4.5C142.5,339.5,144.9,341,147.6,341.9z"
    />
    <path
      class="st127"
      d="M129.7,333.6c-4.6-1-8.6-3.2-12-6.4C121.7,329.3,126.3,330.4,129.7,333.6
	C129.7,333.5,129.7,333.6,129.7,333.6z"
    />
    <path
      class="st109"
      d="M147.6,329.7c-0.4,0.8-0.5,1.6,0.1,2.4c-8.1-4.8-16.2-9.6-24.4-14.3c-4-2.3-8-4.3-11.9-6.7
	C124.5,315.2,135.7,323.2,147.6,329.7z"
    />
    <path
      class="st30"
      d="M135.7,328.3c-3.5-1.1-3.5-1.1-5.8-3.8C132.5,325.6,134.6,326.1,135.7,328.3L135.7,328.3z"
    />
    <path
      class="st32"
      d="M210,297.5c0,1.8-0.9,2.5-2.4,3.6c-8.3,5.7-18,9-25.7,15.8c-3,2.6-7.1,4.1-10.6,6.2c-1.1,0.6-1.6,0.5-1.8-0.8
	c4.7-1.7,8.7-4.8,12.6-7.6c6.8-4.9,14.5-8.1,21.4-12.7C205.7,300.5,207.8,299,210,297.5z"
    />
    <path
      class="st32"
      d="M168,322.3c-0.6,3.4-3.4,4.3-5.9,5.6c-3.2,1.6-6.4,3.2-9.9,4.1c3.8-2.1,7.5-4.2,11.3-6.2
	c1.6-0.8,2.9-1.9,3.8-3.4C167.5,322.3,167.7,322.3,168,322.3z"
    />
    <path
      class="st128"
      d="M276.1,129.5c-3,0.1-4.2-4.1-7.4-3c-1.1-0.9-1-1.9-0.9-2.9c1.5,0.3,2.5,2.3,4.9,0.8c-2.5-9.2,3-19.7-4.8-29.4
	c-0.1-1.2-0.5-3.3,0.5-5.5c0.9,2.5,1.8,4.9,3.3,7.5c1.2-2.5,0.1-4.7,0.3-7.1c2.3,1.1,1.8,3.7,3.1,4.7c-2.6,10.2-0.9,20.3-1.4,30.3
	C273.6,126.8,275.4,127.9,276.1,129.5z"
    />
    <path
      class="st129"
      d="M282.7,132.5c-1.2,0-2.7,0.4-2.9-1.5c-0.3-10,0.6-20-2.9-29.8c0.3-1.5,1-2.9,2.1-4.1c2.5-1.2,4,0.1,5,2.2
	c1.7,3.5,1,5.4-3.6,9.2c1,6.8,0.6,14,0.7,21.2C281.2,130.9,282.6,131.3,282.7,132.5z"
    />
    <path
      class="st130"
      d="M107.1,243.6c-1.1-0.5-2.2-1-3.3-1.4c-1.5,0.9-1.5,2.4-1.9,3.7c-0.7,0.5-1.2,1.4-2.2,0.6
	c-0.2-0.5-0.5-0.9-0.7-1.4c-0.6-1.4,1.1-2,1.1-3.2c-0.3-0.9-1.5-0.6-1.9-1.4c-0.9-2.5-3-1.1-4.5-1.5c2.9-2,5.6-4.4,9.2-5.2
	c3-0.7,4.7-3.7,8.7-4.2c1.3,0.2,3.4,0.7,5.5-0.6c1-0.6,0.8,0.4,0.6,1c-0.4,2.4-2.6,2.8-4.2,3.9c-1.2,0.9-2.6,1.5-3.6,2.7
	c2.2-0.8,4-2.3,6-3.4c1.5-0.8,2.9-2.1,4.8-0.8c1,0.1,1.8,0.5,2.3,1.5c-0.1,1-0.7,1.6-1.4,2c-4,2.1-7.7,4.9-11.6,7.2
	C109,243.5,108.1,243.7,107.1,243.6z"
    />
    <path
      class="st131"
      d="M127.5,256.3c-6.3,3.7-12.5,7.5-19.2,10.4c-0.5-0.7-0.8-0.9-0.9-1.2c-0.1-0.7,0-1.4,0-2.1
	c6.9-5.5,15.3-7.9,22.2-13c3.1,0.1,1.1,2.2,1.5,3.4C129.9,254.6,128.7,255.5,127.5,256.3z"
    />
    <path
      class="st132"
      d="M128.1,249.4c-6.5,3.4-13,6.7-19.4,10.1c-1.5-2.2-0.5-3.4,1-4.3c2.8-1.6,5.7-3.1,8.5-4.7
	c3.1-1.8,6.2-3.6,9.3-5.4c1.4,0.2,2-1.4,3.3-1.6C132.3,246.5,129.4,247.6,128.1,249.4z"
    />
    <path
      class="st133"
      d="M126,243.6c-5.9,3.1-11.8,6.2-17.6,9.2c-1.3-1.3-0.9-2.2-0.5-3.2c1.2-0.8,2.5-1.5,3.7-2.3
	c6.7-2.9,12.4-7.5,19.2-10.7c0.7,1.9,0.2,3.3-1.3,4.4C128.3,241.8,127.2,242.7,126,243.6z"
    />
    <path
      class="st134"
      d="M107.1,243.6c5.1-3.5,10.2-7.1,15.8-9.8c2.6-1.1,5.1-2.3,7.7-3.5c1.1,1.9,0.2,2.9-1.2,3.7
	c-5.4,3.2-10.9,6.4-16.3,9.6c-1,0.6-2.3,0.9-3,2.1c-0.8,0-1.6,0-2.2,0C107,245,107.2,244.2,107.1,243.6z"
    />
    <path
      class="st135"
      d="M120.7,232.3c-4.6,1.4-8,5.1-12.6,6.7c-0.8-2.9-0.8-2.9,9.6-9c4.6-1.5,8.3-4.6,13-6.4
	c0.6,2.4-0.3,3.6-2.2,4.5C125.8,229.4,123.3,230.9,120.7,232.3z"
    />
    <path
      class="st130"
      d="M93.7,239.1c-0.8,2.6-2.3,4.1-5.3,3C89.8,240.3,91.8,239.8,93.7,239.1z"
    />
    <path
      class="st136"
      d="M80.1,269.1c-0.7-1.9-0.6-3.1-0.2-4.5c7.1-4.1,14.3-8.3,21.6-12.6c0.7,1.6,0.3,3-0.1,4.7
	C94.8,261.3,86.8,263.7,80.1,269.1z"
    />
    <path
      class="st137"
      d="M80.1,275.6c-0.8-1.5-0.5-2.6-0.3-4c6.5-5,14.3-8,21.5-11.9c1,1.1,0.6,2.2,0.4,3.8
	C94.7,267.4,87.5,271.5,80.1,275.6z"
    />
    <path
      class="st137"
      d="M101.4,266.1c1.8,2.1,0.8,3.6-0.6,4.5c-6.5,4.1-13.1,8.1-20.1,11.4c-1.5-3.2,0.7-4.3,2.4-5.3
	C89.1,273.1,95.2,269.7,101.4,266.1z"
    />
    <path
      class="st138"
      d="M99.7,246.4c0.7-0.2,1.5-0.4,2.2-0.6c0.1,1.2,0.2,2.5-0.7,4.1c-6.7,3.6-13.8,7.3-20.7,11
	c-1.3-1.2-1-2.2-0.9-3.4C85.7,253,93.4,250.9,99.7,246.4z"
    />
    <path
      class="st139"
      d="M98.2,240.5c1-0.4,2-0.7,3.2-1.1c1,1.1,0.5,2.3,0.5,3.6c-1,0.7-2,1.4-2.9,2.1c-6.6,2.2-12.1,6.6-18.7,9.1
	c-1.4-2.5,0.1-3.6,1.8-4.5C87.4,246.6,92.8,243.5,98.2,240.5z"
    />
    <path
      class="st140"
      d="M171,164c1.2-1.1,0.1-2.8,1.2-3.9c1-0.3,1.2,0.8,1.9,0.9c0.7-0.8,1.6-1.2,2.6-1.1c1.8-0.3,3.5-2,5.5-0.6
	c1.9,2.6-1.6,3.8-1.6,5.9c-0.1,2.7-0.3,5.4,0.2,8.3c3.5-0.6,5.9-2.6,8.2-4.4c1.6-3.3-0.5-7,2-9.8c2.2-0.4,2.6,1.2,2.6,2.7
	c0,3.2-0.1,6.5-0.8,9.6c-0.4,1.8-1.1,3.3-2.9,4.1c-1.3,0.6-2.1,1.7-2.5,3.1c-2,3.4-5.6,4.6-8.8,6.4c-0.2,0.2-0.4,0.5-0.7,0.7
	c-1.3,1.1-0.7,2.7-0.8,4.1c0,0.6,0,1.1,0,1.7c0,0.6-0.1,1.2-0.3,1.8c-0.2,3.4,0.1,6.9,0,10.3c-0.1,1.7,0.1,3.5-1.6,4.6
	c-0.5-0.3-1-0.7-1.8-0.3c-0.5,1.2-0.6,2.3,0.7,3.6c6.3-3.1,12.7-6.3,19.1-9.5c1.1,0.1,2.1,0.4,2.4,1.6c-1.9,2.1-4.3,3.2-6.8,4.2
	c-1.1,1.7-0.7,3.4-0.4,4.9c0.6,1.2,1.6,1.2,2.6,1.3c0.4,1.4-1.1,1.9-1.4,2.9c-0.4,0.5-0.7,1-1.1,1.4c0.6,1.2,1.7,0.7,2.5,0.8
	c0.6,1.5-0.6,1.7-1.5,2c-4.9,1.8-9,5.2-13.7,7.4c-0.7,0.3-0.1,0.8,0.2,1.1c-0.8,1.7-2.6,1.6-4.2,2.6
	C170.2,209.6,171.2,186.8,171,164z"
    />
    <path
      class="st141"
      d="M197.1,139.9c6-2.7,11.3-6.6,17.1-9.8c0.5-0.3,1-0.6,2.5-1.6c0,20.1,0,39.4,0,58.7c-3.7,2-7.4,4.1-11.2,6.1
	c-2.7-1.8-0.7-3.4,0.3-5c1-1.6,3.3-2.4,2.5-4.9c-0.2-2.5,1.1-5.1,0-7.6c-0.6-1.9,0.7-3.4,1.2-5c0.1-0.6,0.4-1.2,0-1.9
	c-1.2-0.6-2.4,0.5-3.6,0.2c-1-0.3-1.9-0.6-2.4-1.7c-0.2-1.8,0.8-3,2.3-3.8c1.2-0.7,2.3-1.5,2.6-3c0.2-0.5,0.3-1,0.5-1.5
	c1-3.2,0.4-6.6,0.5-9.9c0-0.6-0.4-1.2-1.2-0.8c-0.7,0.4-1.3,1-2,1.3c-1.6,0.3-2.8,1.8-4.5,1.3c-2.1-1.1,0-2.7-0.5-4.1
	c0.1-3.4-3.7-3.8-5.1-6.1C196.2,140.3,196.4,139.9,197.1,139.9z"
    />
    <path
      class="st142"
      d="M208.9,183.5c2.4,2.5,0,3.7-1.4,5c-1.5,1.4-2.8,2.7-2,4.9c-5.7,4.1-12.3,6.6-18.3,10
	c-2.8,1.6-5.7,3.2-8.5,4.7c-1,0.5-2.2,1.6-3.3,0.2c0.5-5.3-0.4-10.6,0.5-15.8c1.8,0.5,3.5,0.3,5.1-0.6c0.5,0,1,0.1,1.5,0.4
	c0.6,0.7,0.8,1.5,1,2.5c0.8-1.2,0.6-3,2.6-3.4c1.9,0.6,1.6,2.3,1.5,3.9c3.1-1.8,1.9-5,2.6-7.5c0.3-1.3,1.2-2.1,2.3-2.7
	c3.2-1.5,6.2-3.5,9.4-4.9c1.7,0.2,2,2.2,3.1,2.6C206.5,183.6,207.8,182.9,208.9,183.5z"
    />
    <path
      class="st143"
      d="M176.2,230c-0.8,0.1-1.7,0.1-3,0.2c3.9-4.5,10.4-4.8,13.4-9.7c1.6-0.2,3.5,1.5,4.5-1
	c4.6-5.1,10.9-7.8,16.3-11.7c2.8-2,5.7-3.9,8.8-6c1.2,3.4-1,4.8-2.9,6.2c-4.7,3.5-9.8,6.4-14.9,9.2
	C191.1,221.5,183.7,225.8,176.2,230z"
    />
    <path
      class="st144"
      d="M195.8,203.9c-1.1-0.2-1.9-0.7-2.4-1.6c7.7-3.9,15.7-7.1,22.5-12.9c1.5,2.6,0.5,5.1,0.8,7.7
	c-8.2,5.3-17.3,9.1-26.2,13.1c7.7-6.1,17.4-9.1,24.9-15.7C208.6,197.1,202.6,201.4,195.8,203.9z"
    />
    <path
      class="st143"
      d="M197.2,211.3c0.6-2.5,3.2-2.6,4.8-3.6c3.8-2.5,7.8-4.6,11.9-6.5C208.4,204.6,203.8,209.6,197.2,211.3
	L197.2,211.3z"
    />
    <path
      class="st143"
      d="M189.7,217.3c-0.2-1.3,0.7-2.1,1.4-2.9c1.5-2.2,3.3-3.5,6.1-3l0,0C195.9,214.8,192,215,189.7,217.3z"
    />
    <path
      class="st145"
      d="M179.4,143.2c4.2-3.6,9.3-6.1,14-9c5.4-3.3,10.9-6.5,16.3-9.7c0.7-0.4,1.5-1,2.5-0.7c1.2,0.8,1.1,2,1.3,3.8
	c-7.8,4.4-15.8,8.9-23.8,13.5c-1.8,3.1-5.1,2.4-7.8,3.1C180.9,144.2,180,144,179.4,143.2z"
    />
    <path
      class="st146"
      d="M179.4,143.2c0.8,0,1.7-0.1,2.5-0.1c1.7,1,2.7,2.3,2.2,4.4c-0.3,0.6-0.8,1-1.3,1.2c-4.5,1.6-5.9,4.7-4.8,9.3
	c0.3,1.2,0.5,2.5-1.1,3c-0.9,0-1.8,0-2.7,0c-0.3-2.7-0.7-5.4-1-8.1c-0.9-1.2-0.5-2.5-0.6-3.8c0.7-1.1,1.5-2,2.2-3
	C176.4,145.3,177.4,143.4,179.4,143.2z"
    />
    <path
      class="st77"
      d="M183.8,147.4c-0.4-1.5-2.1-2.5-1.9-4.3c2.4-1.4,5.5-0.3,7.8-2.1c0.1,0.5,0.1,0.9,0.2,1.4
	c2.7-0.1,4.2-3.6,7.2-2.4c-0.2,0.3-0.5,0.5-0.8,0.8c-3,3.5-6.8,5.8-11.2,7.4C184.6,148.1,184.1,147.9,183.8,147.4z"
    />
    <path
      class="st77"
      d="M174.7,146c0.2,2.5-2.1,4.3-1.6,6.8c-2.4-1.1-1-3.2-1.4-4.9C172.6,147.1,173.5,146.3,174.7,146z"
    />
    <path
      class="st147"
      d="M391.4,410.7c0.3,1,0.6,2,0.9,3c-0.3,0.8-0.5,1.6-0.8,2.4c-1.1,4.4-4.7,5-8.5,5.6c-2.8,5.2-8.4,6.6-13.1,9.1
	c-23.8,12.8-46.8,26.9-70.3,40.2c-2.8,1.6-5.4,3.7-8.7,4.3c-2.3,0.4-4.8,0.9-6.1-2c1.2-1.7,3.3-2,4.7-3.4c7-2.9,13.2-7.5,19.8-11.2
	c25.7-14.1,50.9-28.8,76.2-43.6C387.6,414,389.7,412.6,391.4,410.7z"
    />
    <path
      class="st148"
      d="M284.9,473.2c3.1,2.6,6.2,1.4,9-0.2c11.4-6.4,22.6-13.1,34-19.5c15.2-8.5,30-17.8,45.5-25.8
	c2.3-1.2,4.6-2.5,6.5-3.6c1.7-1.2,0.7-3.4,2.2-3.9c1.5-0.9,2.5,0.4,3.8,0.2c2.3-0.9,3.7-2.9,5.7-4.2c2,2.3-0.8,3.5-1.1,5.4
	c-2.3,0.5-4.1,2.3-6.5,2.2c-1.7,3.7-5.5,3.9-8.3,5.5c-8.2,4.6-16.8,8.5-25.1,12.7c-1.8,0.9-3,2.6-3.8,4.3
	c-8.1,2.4-14.8,7.3-21.8,11.3c-4.4,2.5-9.4,4-13,7.9l0,0c-1.8,0.9-3.5,2-5.3,3c-3.9,2.2-7.7,4.5-11.6,6.6c-3.9,2.1-7.6,2.6-11.5-0.3
	c-2.5-1.9-5.5-3.1-8.4-4.7c-0.8-1.3,0-1.8,1.1-2.1C279.6,468.9,282.6,470.4,284.9,473.2z"
    />
    <path
      class="st92"
      d="M378.7,208.2c0.2,0.5,0.5,1,0.7,1.5c0.2,2.4-1,4.2-2.4,5.9c-2.3,1.7-4,3.7-3.7,6.8c0.3,3.5,3.3,5,5.3,7.2
	c0.5,0.6,0.8,1.3,0.9,2c0.1,0.6,0,1.2-0.1,1.8c-0.4,0.9-0.7,1.8-1.8,2.2c-1.2,0.3-2.3-0.1-3.4-0.5c-1.8-0.8-3.5-1.9-4.8-3.5
	c-0.6-1.8,0.1-4.4-2.5-5.4c-1.4-0.6-2.9,0.4-4.4-0.3c-3.8-4.5-2.8-6.6,4.5-9.3c-0.3-3.6-5.5-5.1-4.3-9.3c0.9-2.3,2.4-4.1,4.1-5.8
	c0.8,4.5,4.2,5.4,7.9,5.9C376.1,207.6,377.4,207.6,378.7,208.2z"
    />
    <path
      class="st149"
      d="M366.8,201.5c-0.1,1-0.1,1.9-0.2,2.7c-0.6,1.6-2.7,1.6-2.9,3.2c-1.4,1.6-2.9,3-4.5,0.1c0.2-2-0.1-4-0.8-5.9
	c-0.2-2.5,2.4-2.6,3.4-3.8c1-2.2-0.2-5,2.1-6.6c1.3,0.5,1.6,1.6,1.5,2.7C365,196.6,366.1,199.1,366.8,201.5z"
    />
    <path
      class="st150"
      d="M363.8,191.2c-0.2,2.8,0.3,5.6-0.4,8.5c-1.8,0.6-3.8,0.2-5,2c-0.5-0.1-1-0.2-1.5-0.3c-0.7-0.6-1.8-1-1.8-2.4
	c-0.1-1.6,1.8-2.2,2.5-3.7c1.4-2.6-1.6-7,3.4-8.1c0.1,0.3,0.2,0.5,0.2,0.8C360.7,190.2,362,190.9,363.8,191.2z"
    />
    <path
      class="st82"
      d="M375,200.1c0.1,1,0.6,2,0,3c-1.6-0.2-1.5-1.3-1.4-2.4C373.6,199.7,374.1,199.4,375,200.1z"
    />
    <path
      class="st151"
      d="M359.2,207.5c1.5,1.7,3,0.1,4.5-0.1c-0.2,2.2,0.9,3.7,2.6,5c1.7,1.3,3.5,2.9,2.2,5.6
	c-1.9,1.5-4.5,0.1-6.4,1.5c-1.3,2.1,0.5,3.7,0.3,5.9c1.7-0.6,2.8-1,4-1.4c2.9,1.5,5.3,3.2,3.8,6.9c1.1,1.6,2.6,2.6,4,3.5
	c0.1,0.2,0.2,0.3,0.2,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c-11.9-6.1-23.7-12.5-34.9-19.9c-1.7-1.1-3.3-2.3-5.5-2c1.7-1.5,3.7-1.5,5.7-1.1
	c1.4,0.2,2.7,0.5,3.7,0.3c1.5-0.5,1.8-2.1,3.2-2.2c0.4-0.9,0.9-1.7,1.5-3C351.6,207.5,355.4,207.5,359.2,207.5z"
    />
    <path
      class="st152"
      d="M360.9,187.2c-1.8,0.9-1.7,2.6-1.6,4.2c0.2,3.1-0.2,5.9-3.2,7.2c-0.5,1.4,1,1.9,0.8,2.9c-0.8,0-1.7,0-2.4,0
	c-0.5-1-1-1.9-1.4-2.7c-1.4-2,0.7-3.2,1.1-4.6c-0.2-1.4-1.2-2.3-1.4-3.9c2-1.6,0.1-5.4,3.4-6.6c1.2-0.2,2.5-0.3,3.7,0.2
	C361,184.7,360.9,185.9,360.9,187.2z"
    />
    <path
      class="st153"
      d="M356.2,183.6c-0.8,2.2-1.7,4.5-2.5,6.7c0.5,1.7,3.1,2.3,2.3,4.9c-0.6,1.2-3.2,1.3-2.9,3.5
	c-2.8-1.2-3-3.7-3-6.2c-0.7-1.7,0.8-2.2,1.7-3.1c2.5-1.3,0.7-4.1,2.1-5.8C354.7,183.6,355.4,183.6,356.2,183.6z"
    />
    <path
      class="st15"
      d="M351.8,189.4c0.1,1.4-1.3,1.9-1.7,3.1c-2.1-1.9,0.5-2.9,0.8-4.3C351.2,188.6,351.5,189,351.8,189.4z"
    />
    <path
      class="st154"
      d="M393.8,178.6c0,1.1,0.1,2.3,0.1,3.4c-1.1,0.2-2.2,0.5-3.2-0.2c-1.2-1.5-0.8-3.1-0.5-4.6
	C392.1,176.3,392.7,177.9,393.8,178.6z"
    />
    <path
      class="st155"
      d="M366.7,159.6c-0.6-2.6-1.1-5.2-0.3-7.8c2.1-0.4,3.1,1.8,4.8,1.7c1.7,2.4-1.2,5.5,2.1,7.9
	C370.3,161.8,368.4,160.9,366.7,159.6z"
    />
    <path
      class="st156"
      d="M275.1,420c-0.1,1.5-0.8,2-1.4,2.4c-7.4,4.5-15.1,8.8-22.3,13.6c-5.1,3.5-9.7,2.3-14.3-0.5
	c-11-6.7-22.5-12.3-33.5-18.8c-2.6-1.5-5.6-2.8-6.6-6.4c4.4-4.1,9.8-6.6,14.8-9.5c3.8-2.2,7.3-4.8,11.1-6.9c1.8-1,3.6-1.7,5.6-0.6
	c15.3,8.3,30.6,16.7,45.5,25.8C274.4,419.4,274.8,419.7,275.1,420z"
    />
    <path
      class="st157"
      d="M228.7,386.1c-0.3-0.3-0.6-0.6-0.8-0.8c0.2-2.8,3.4-2.6,4.7-4.7c-1.9-1.4-4-2.4-6-3.5c-1.8-1-3.3-0.3-4.9,0.7
	c-2,1.2-3.7,3-6.3,3c-0.9-0.3-1.8-0.6-2.3-1.5c2.2-2.4,5.6-3.2,7.9-5.9c-1.7-1.6-3.5-2.7-5.8-3c-3.5,1-5.9,4.3-9.7,4.5
	c-0.8-0.5-1.5-0.9-2.2-1.4c1.7-2.6,5.1-2.9,6.9-5.4c0.5-1.1-0.9-2-0.2-3.2c7.5-1.4,12.4-7.5,18.5-10.1c1-1.6,0-2.5,0-3.4
	c0.1-0.8,0.7-1.3,1.5-1.5c4.2-0.9,7.2-3.7,10.1-6.6c1.3-1.1,2.7-2,4.5-1.5l-0.1,0c2.6,2.4-0.9,3.8-1,5.7c-0.3,0.5-0.6,0.8-1.1,1.1
	c-2.1,0.9-4.6,1.1-5.8,3.5c1.3,1.7,3.3,2.1,5.1,3c1.2,0.7,2.2,0.6,3.3-0.4c1.1-1,2.2-1.9,3.8-1.9c0.3,0.1,0.6,0.3,0.7,0.7
	c0.5,2-1.8,2.8-2,4.7c1.3,1.7,3.3,2.2,5.1,2.3c1.9-0.3,2.9-1.9,4.6-1.9c0.4,0,0.7,0.2,1,0.4c0.8,1.7-1.1,2.7-1.2,4.1
	c2.7,3.4,4.4,3.8,8.2,2c0.7-0.3,1.4-0.6,2.1-0.5c0.3,0.1,0.6,0.3,0.7,0.7c0.2,1.3-1,2.2-0.8,3.6c2.2,2.3,5,2.6,8,1.6
	c0.3,0.1,0.7,0.2,1,0.4c0.6,1-0.2,2.2,0.4,3.3c2.2,1.9,4.9,2.5,7.7,2.6c0.3,0.2,0.5,0.5,0.7,0.8c1.2,3,3.9,3.5,6.4,4.2
	c2.3-0.4,3.3-3,5.6-3c0.3,0,0.7,0.2,1,0.4c0.7,2.3-2,3.2-2.3,5.5c2.1,2.1,5.4,2.5,7.8,4.2c0.3,0.1,0.6,0.3,0.9,0.5
	c2,2,4.6,3.2,7.2,4.2c1,0.5,1.8,1.4,2.9,1.9c2.2,1,4.1,2.7,6.3,3.2c1.9-0.1,3.2-1.4,4.9-1.1c0.5,0.2,0.9,0.5,1,1.1
	c0,0.9-0.6,1.6-0.5,2.7c1.6,1.6,3.5,3.2,5.9,3.5c2.5-0.7,3.6-3.7,6.4-3.5c1.2,0.5,1,1.3,0.6,2.2c-0.6,1.3-2.2,2-2.6,3.5
	c3.3,2.9,4.5,3,8,0.7c1.1-0.7,2.2-1.8,3.7-1c0.2,0.3,0.3,0.6,0.3,1c-0.5,2-2.1,3-3.6,4c2.8,2.8,7.6,2.9,9.8,7.2
	c-4.2,1.5-6.7-2.3-10.1-2.3c-1.4,0.2-2.3,1.4-3.8,1.5c-0.4,0-0.7-0.2-1-0.4c-0.7-1,0.3-2.2-0.3-3.4c-1.5-1.5-3.5-2.4-5.7-2.8
	c-1.9,0.8-3.4,2.1-4.7,3.5c-0.2,1.3,1.1,1.7,1.4,2.6c0.2,0.8-0.1,1.4-0.8,1.8c-2.5,0.5-4.2-1.1-6.1-2.3c-4.8-3-7-2.9-11.8,1.1
	c3.1,3.6,8.7,3.9,11,8.1c-0.1,1.3-0.8,1.4-1.6,1.5c-3.5-2.5-7.5-1.4-11.3-1.1c-0.8-1.4,0.9-2.2,0.8-3.4c-3.3-3.1-7.4-3.1-11.4-3.4
	c-0.6-0.9,0.2-2-0.4-3.3c-2.5-1.5-5.4-2.8-8.8-2.4c-1,0.1-2,0.4-2.9-0.3c-0.6-1,0.4-1.9,0.1-3.3c-10.1-4.4-19.4-10.7-29.2-16.1
	c-5.4-3-11.2-5.6-16.4-9c-3.4-2.2-6.4-2.5-9.6,0c-0.7,0.5-1.5,0.9-2.4,1C229.4,386.5,229,386.3,228.7,386.1z"
    />
    <path
      class="st158"
      d="M284.9,473.2c-3.1-0.9-5.6-2.9-8.3-4.6c-5.4-1.1-9.6-4.5-14.2-7.1c-12.6-6.9-25.3-13.6-37.4-21.2
	c-15.4-9.7-31.7-17.9-47.3-27.1c-10.7-6.3-21.9-11.9-32.6-18.2c-2.2-1.3-4.6-2.5-5.6-5.1c-0.4-0.7-0.9-1.5-1.3-2.2
	c-0.1-0.9-0.1-1.8,0.8-2.5c17.8,12.3,37.5,21.5,56,32.6c2.6,1.6,5.5,2.8,8.2,4.1c3.9,1.7,6.8,5,10.5,7c2.8,1.4,5.4,2.9,8.5,3.7
	c3,3.7,7.3,5.5,11.2,7.5c11.1,5.8,21.9,12.3,32.9,18.3c7.6,4.1,14.8,9.3,23.3,11.6c-0.4,1-0.8,2-1.3,3.1
	C287,473.2,286,473.2,284.9,473.2z"
    />
    <path
      class="st159"
      d="M229.5,386.1c2.2-1.9,4.7-3.2,7.4-4.4c18.2,9.1,35.1,20.6,53.6,29.2c-1,1.8-3.4,1.6-3.7,3.6
	c-0.6,1.6-1.7,2.3-3.4,1.7c-1.3-0.3-1.9,1.1-3.1,1.3c-1.4-0.3-2.7-0.9-3.8-1.9c-0.6-2-2.7-2-4.1-2.8c-12.7-7.3-25.2-14.9-38.3-21.6
	C232.2,390,229,389.5,229.5,386.1z"
    />
    <path
      class="st160"
      d="M228.9,351.5c0.9,0.7,1.8,1.3,3.2,2.3c-6.4,3.9-12.4,7.6-18,11.7c-1.5-0.2-2.8-0.3-4.1-0.5
	c-0.9,0-1.6-0.4-2.3-0.9c-2.5-2.8-6.8-3.3-9-6.5c-0.8-0.5-1.2-1.2-1.5-2.4c4.3-0.8,6.6-6.5,12-4.5c2.3,0.5,4.2,2,6,2.7
	c-1.6-1.1-4.2-1.5-5.8-3.5c-0.2-0.5-1.2-0.9-0.4-1.5c2.3-1.6,4.6-3.3,7.6-3.5c4.2,0.1,7,3.4,10.8,4.3
	C228.5,349.7,228.2,350.9,228.9,351.5z"
    />
    <path
      class="st161"
      d="M340.5,417.6c1-0.9,2.1-1.7,3.4-2.8c3,1.3,6.3,2.8,9.5,4.2c-3.2-2.6-7.1-4-10.4-6.9c1.7-1.4,3.3-2.8,4.9-4.2
	c3.3-0.5,5.8-3.1,9-3.8c1.9,1.2,4.1,1.9,5.8,3.8c-0.2,1.1-1.2,1.7-2,2.4c-0.9,1-2,0.9-3.1,0.6c2.3,0.5,4.4,1.5,6.5,2.6
	c1.3,0.7,3.2,1.3,2.7,3.5c-5.1,3.2-10.2,6.5-15.2,9.8c-0.7,0.5-1.4,1-2.1,1.5c-0.3,0.2-0.6,0.4-1,0.4c-5.3-0.5-8.9-4.1-13.2-6.3
	C336.1,418.7,338.4,418.2,340.5,417.6z"
    />
    <path
      class="st162"
      d="M368.2,403.4c-40.4-22.5-80.8-45-121.8-67.8c2.7-1.2,4.2-0.6,5.7,0.3c23.6,14,48.1,26.3,71.8,40.1
	c13.1,7.7,26.9,14.2,39.6,22.6C365.3,399.8,367.4,400.8,368.2,403.4z"
    />
    <path
      class="st163"
      d="M321,427.2c1.6,0,0.8-0.6,0.2-1.1c-2.6-2.1-5.7-3.5-8.6-5.1c-1.3-0.7-2.5-1.5-4-2.5c3.3-2.8,6.7-4.5,10.9-5.2
	c3.1,1.8,6.3,3.8,9.6,5.8c0.7,0.3,1.5-0.2,2.2,0.3c5,3.7,11,5.6,15.9,9.4c-2.7,2.9-6.9,3.2-9.4,6.2c-4-1.2-7-3.7-10.5-5.3
	c-1.3-0.6-2.6-1.5-4-0.1c-1.5-0.1-2.3,1.4-3.9,1.8c-2.7,0-4.8-1.6-6.9-3.6c1.1-2.1,2.9-1.8,4.6-1.5
	C318.4,426.7,319.6,427.4,321,427.2z"
    />
    <path
      class="st164"
      d="M326.2,398.7c-0.2-0.2-0.5-0.4-0.7-0.6c-0.5-1,0.6-1.8,0.2-3c-1.9-1.4-4.1-2.2-6.6-2.7c-2.2,1-3.6,3-5.6,4.2
	c-0.8-0.7-1.5-1.4-2.3-2.1c0.5-2.1,3.2-2.4,3.6-4.5c-2.7-3.1-4.7-3.5-7.9-1.3c-1,0.7-1.9,1.7-3.3,1.1l0.1,0
	c-1.6-2.3,1.4-3.4,1.4-5.5c-1.2-3-5.4-2.3-7.2-5c-0.3,0-0.6,0-1-0.1c-2.3-1.7-4.6-3.2-7.1-3.6c-2.4,0.1-3.4,2.8-5.7,2.2
	c0-0.3,0-0.7-0.1-1c-0.5-1.3,0.8-2.3,0.6-3.7c-0.9-1.6-2.5-2.2-3.8-2.6c-1.9-0.3-3.1,1.4-4.7,0.5c-0.4,0-0.8-0.1-1.2-0.2
	c-0.6-1.2,0.7-2,0.5-3.4c-1.4-1.7-3.2-2.7-5.6-2.2c-0.8,0.2-1.7,0.3-2.4-0.5l0,0c-0.9-2.9-3.5-3.5-5.8-4.5c-1.2-0.5-2.6-0.6-3.8-1.4
	c-0.3,0-0.7,0-1-0.1c-0.7-2.6-2.5-3.8-5-4.4c-1.2-0.3-2.4-0.6-3.3-1.6l0,0.1c-0.7-0.6-1.6-1-2.4-1.5c-1.5-1.2-4.1-1.2-4.3-3.8
	c0.3-0.3,0.5-0.5,0.8-0.8c3.6,0,7.2,1,10.3-0.1c1.8,1.3,0.7,2.8,1.5,3.9c1.5,1.3,3.4,1.6,5.5,1.8c-0.8-1.9-3.4-2.3-3.1-4.6
	c1.5-1.5,2.5,0,3.5,0.7c1.9,1.3,3.7,2.8,5.6,4.2c-0.4,1.4-2,1.8-2.6,3.4c2.7,1.5,5.5,2.7,9,3.4c1.6,3,5.1,3.5,7.9,5.3
	c0,0.3,0,0.5,0,0.8c2.9-0.4,4.2,3,7.3,3c1.2-0.2,2.4-1.4,4-1.6c1.6,0.2,2.8,0.8,3.7,2.2c-0.1,1.2-1.4,1.7-1.5,3c2,1.5,5,1.5,6.6,3.7
	c0.2,0.3,0.5,0.6,0.7,0.9c3.5,1.9,6.9,4.3,11.3,2.9c0.3,0.3,0.6,0.6,0.9,0.9c0.5,0.9-0.5,1.9,0.2,2.8c2.2,3.3,6.5,0.8,8.9,3.3
	c0.2,0.2,0.5,0.4,0.7,0.6c0.8,3.6,4.3,3.1,6.5,4.6c1.5-0.4,2.8-0.7,4-1.5c0.9,0.3,1.9,0.5,2.3,1.5c-0.2,0.9-0.7,1.8-0.9,2.8
	c0.7,1.7,3.4,1.6,3.5,3.9c-0.2,0.5-0.5,0.9-1,1.1c-0.1,0-0.3,0.1-0.4,0.1C333.5,398.6,329.9,398.2,326.2,398.7z"
    />
    <path
      class="st165"
      d="M228.9,351.5c-0.5-0.5-1-0.9-1.5-1.4c-3.2-1.5-6.6-2.7-8.7-5.9c-1-0.8-0.6-1.5,0.3-2c2.4-1.5,4.5-3.5,7.7-3.9
	c0.8,0.3,1.8,0.8,2.9,1.2c0.4-0.5,0.8-1,1.2-1.5c0.8,0.2,1.6,0.6,2.6,0.2c-0.6-0.2-1.3-0.4-1.4-1.1c0.7-0.6,1.3-1.4,2.6-1.4
	c1.3,0.4,2.7,1,3.8,2.1c1.6,1.5,1.1,2.7-0.7,3.8c1,0.8,2.4,1.1,2.9,2.3C237.8,348.1,233.9,350.6,228.9,351.5z"
    />
    <path
      class="st166"
      d="M283.4,416.1c1.1-0.5,2.3-1.1,3.4-1.7c1.7-0.7,3.4-1.5,5.1-2.2c3.1,2,7.2,1.8,9.7,4.8c-0.2,1.7-2,1.6-2.5,2.8
	c-0.8,2.7-2.2,4.2-5.2,3c-4.8,1.1-7.6-3-11.7-4.4C282.7,417.5,283,416.8,283.4,416.1z"
    />
    <path
      class="st167"
      d="M293.8,422.8c2.2-0.2,3.6-1.8,5.2-3c1.5,0.3,2.5-1,4.1-1.4c3.4,1.2,6.9,2.7,10.3,4.6c-0.9,2-3.5,1.8-4,3.9
	c-0.6,1.8-1.8,2.5-3.6,1.9c-5.1,1.1-8.2-2.8-12.2-4.6C293.6,424.1,293.8,423.3,293.8,422.8z"
    />
    <path
      class="st168"
      d="M215.3,380.7c2.8-2.5,6-4.3,9.3-6.3c3.5,2,7,3.9,10.6,5.9c-1.9,2.7-5.1,3-7.4,4.9c-1.7,0.9-3.2,0.7-4.8-0.2
	C220.5,383.5,217.9,382.2,215.3,380.7z"
    />
    <path
      class="st169"
      d="M208.7,363.6c0.4,0.5,0.8,1,1.2,1.5c0.3,1.3,2.3,1,2.5,3.1c-2.6,2.2-5.5,4.6-9.3,5.3
	c-2.9-1.6-6.3-2.6-8.5-5.3C197.1,363.1,202.3,361.4,208.7,363.6z"
    />
    <path
      class="st170"
      d="M205.5,374.8c3.1-2.1,6.2-4.3,9.4-6.4c3.1,0.7,5.3,3,8.5,4.6c-3.2,2.9-6.1,5.7-10.3,6.3
	C210.5,377.7,208,376.3,205.5,374.8z"
    />
    <path
      class="st171"
      d="M305.9,428.8c1.2-0.6,2.4-1.2,3.6-1.9c1.9-0.8,3.8-1.7,5.8-2.6c2,0.5,4,1.4,5.7,2.8c-2.4,2.2-5-0.9-8,0.7
	c2.8-0.1,3.7,2.3,5.7,2.7c1.7,0.1,2.6-3,4.6-0.9c-1.9,3.2-5.8,3.6-8.2,6.3c-2.8-1.6-5.5-3.2-8.3-4.5
	C305.5,430.7,305.3,430,305.9,428.8z"
    />
    <path
      class="st172"
      d="M229.5,386.1c0.9,2.4,3,3.5,5.1,4.4c7.8,3.5,15,8,22.5,12.1c7,3.9,13.2,9.1,21,11.5c-0.2,0.8-1,0.9-1.6,1.3
	c-10.8-6-21.7-12-32.5-18c-4.8-2.7-9.5-5.5-14.2-8.2c-1.1-0.7-2.6-1.4-1.1-3.1C229,386.1,229.2,386.1,229.5,386.1z"
    />
    <path
      class="st173"
      d="M280.5,365c-4.1,0.3-7.7-1.8-8.5-4.6c-4,0.4-6.8-3-10.9-2.6c1.2-3,2.5-4.7,5.2-4.9c1.6,0.8,3.3,1.7,4.7,2.4
	c0.7,1,0,1.6-0.3,2.4c4,0.6,8,1,11.2,4C281.9,363.1,280.8,363.9,280.5,365z"
    />
    <path
      class="st174"
      d="M192,367.3c-2.3-1.3-5-2-6.8-4.4c2.4-2.6,5.9-3.2,8.6-5.2c0.9-0.7,1.9,0.2,2.8,0.6c3.8,1.2,3.8,1.2,5.1,4.1
	C198.7,364.6,196.2,367.6,192,367.3z"
    />
    <path
      class="st175"
      d="M257.2,347.9c1.9,1.5,3.8,3,5.6,4.5c-2.4,2.8-2.9,2.7-10.4-1.2c-0.5-1.6,1.4-2.1,1.7-3.5
	c-2.1-1.2-3,1.1-4.8,1.6c-2.2,0.1-4.2-1.2-6.3-2.3c-1.4-1.5,0.3-2.2,0.9-2.9c0.7-0.8,1.4-1.3,0.5-2.3
	C249.3,342.6,252.5,346.8,257.2,347.9z"
    />
    <path
      class="st176"
      d="M222,432.6c-2.1,2-3.7,0-5.3-0.7c-5.2-2.4-10.3-5-13.7-9.9c0.6-1.6,1.7-1.1,2.7-0.5c4.9,2.7,9.8,5.4,14.7,8.2
	C221.7,430.2,222.4,431.1,222,432.6z"
    />
    <path
      class="st177"
      d="M316.7,436.6c1.3-2.2,3.2-2.8,5.3-3.3c3.1,1.3,6.1,2.9,8.7,5.6c-0.7,1.7-2.7,1.6-3.6,2.9
	C323.3,440.2,319.8,438.9,316.7,436.6z"
    />
    <path
      class="st178"
      d="M322.5,386.9c-0.8,0.4-1.7,0.8-3.1,1.5c0.5-1,0.8-1.5,1.2-2.3c-4.2,2.6-6.4-1.2-9.4-2.2
	c0.2-1.8,1.6-2.2,2.3-3.2c4.5-1,6.7,3.2,10.4,4.6C323.3,386.1,322.9,386.5,322.5,386.9z"
    />
    <path
      class="st179"
      d="M338.3,398.8c-1.4-2-4.2-1.5-5.7-3.5c0.5-1.6,2.2-2.1,3.3-3.2c2.4,1.2,4.8,2.4,7.1,3.6c1,0.5,2.2,0.9,1.2,2.4
	C342.2,401.5,340.8,401.7,338.3,398.8z"
    />
    <path
      class="st180"
      d="M333.6,390.6c0,0.2,0,0.6-0.1,0.7c-0.9,0.9-1.8,1.7-2.8,2.6c-3.2-0.8-5.7-3.3-9-3.5c-0.7-1.8,1.2-1.9,1.4-2.9
	c2-1.7,3.9-0.9,5.7,0.3C330.5,388.8,331.7,390.3,333.6,390.6z"
    />
    <path
      class="st181"
      d="M361.6,410.9c1.7-0.1,2.2-1.9,4-2.4c3,0.6,5.4,2.5,8.1,4.6c-1.6,1.7-3.4,2.2-5.4,2.3c-2.7,0.2-4.5-2-6.7-3
	C361,412.2,361,411.4,361.6,410.9z"
    />
    <path
      class="st182"
      d="M312.6,379.9c-1,0.7-2,1.4-3,2.2c-2.9-0.4-5.2-2.5-8.1-3.3c-1.1-0.3-0.3-1.2-0.2-1.8c1-0.9,2.1-1.7,3.2-2.7
	c2,1.1,3.8,2.3,5.9,3.1C311.4,377.7,312.9,378.2,312.6,379.9z"
    />
    <path
      class="st183"
      d="M345,399.5c0.7-0.4,1.5-0.8,2.4-1.4c2.5,1.5,5.2,3,7.8,4.6c0.4,2.5-1.5,3.3-3.1,3.1
	c-3.7-0.3-7.4-0.9-10.2-3.6C341.8,400.1,343.2,399.6,345,399.5z"
    />
    <path
      class="st184"
      d="M291.8,367.3c-1.1,1-2.2,1.9-3.8,3.3c-3.1,0.2-4.9-3-7.5-4.8c0.9-0.7,1.7-1.4,2.7-2.2
	C286.6,363.3,288.8,366.2,291.8,367.3z"
    />
    <path
      class="st185"
      d="M300.6,376.1c-3.4,0-6.1-2-9.3-3.1c1.1-1.7,2-3.3,4.2-3.6c2.4,1.2,4.8,2.5,7,3.7
	C302.4,374.7,301.1,375.1,300.6,376.1z"
    />
    <path
      class="st186"
      d="M342,401.8c2.8,1.8,6.1,1.9,9.1,3c1.8,0.6,3.3-0.3,4.2-2.1c0.9,0,1.4,0.6,1.7,1.3c-2,0.7-2.6,2.9-4.4,4.1
	c-1.5-0.1-3.1-0.2-4.6-0.3c0-0.3,0-0.5,0-0.8c-2.9-1-5.5-2.9-8.8-2.7c-0.5,0-0.8-0.5-0.9-1.1c0.1-0.5,0.6-0.9,0-1.4
	c-0.2-0.7,0.2-1,0.8-1.2C340.2,400.4,341.2,400.9,342,401.8z"
    />
    <path
      class="st187"
      d="M192,367.3c3.7-0.6,6.1-3.3,9.3-4.9c-1.4-2.3-3.6-2.7-4.8-4.2c0.7-0.1,1.6,0.1,2.2-0.6
	c3.8,1.2,6.9,3.6,10.1,5.9c-1.7,0.1-3.4-0.1-5.1-0.7c-3.2,1.3-6.1,3.2-9,5.2C193.7,368.1,192.7,368.1,192,367.3z"
    />
    <path
      class="st188"
      d="M347.2,428.8c-6.1-3-12.3-5.9-18-9.7c0.2-0.4,0.5-0.9,0.7-1.3c1.7-1.3,2.9,0.7,4.2,0.6c2,0.3,3.5-1.8,5.5-0.8
	c0.3,0,0.6,0,0.9,0c-0.9,1.7-2.9,2.1-4.8,4c5,1.8,8.5,5.7,13.7,6.5C348.9,428.8,348.1,428.9,347.2,428.8z"
    />
    <path
      class="st175"
      d="M240.7,344c-2.2,0.2-3.4-1.7-5.3-2.2c0.4-1.8,2-0.6,2.7-1.3c0.9-0.8,0.7-1.7,0.4-2.7c2.4,0.7,4,2.6,6,4
	C243.2,342.5,242,343.3,240.7,344z"
    />
    <path
      class="st189"
      d="M361.6,410.9c1.3,2.9,4.5,2.8,6.6,4.5c-0.1,0.8-0.6,1.3-1.4,1.5c-2.1-2.6-5.2-3.4-8-4.5
	c-1.7-0.7-3.6-1.2-4.4-3.4c2.2,0.4,4.3,0.8,6.4,1.2C361,410.4,361.3,410.7,361.6,410.9z"
    />
    <path
      class="st190"
      d="M209.4,349.9c3.6,1.5,7.1,3,10,5.8c-3.5-1.4-7.1-2.7-10.1-5C209.3,350.5,209.3,350.2,209.4,349.9z"
    />
    <path
      class="st191"
      d="M218.7,344.2c2.9,2,5.8,3.9,8.7,5.9c-4.2-0.4-7.2-3.5-10.8-5.1C217.3,344.8,218.2,345,218.7,344.2z"
    />
    <path
      class="st157"
      d="M342,401.8c-1.1,0-2.2-0.2-3.2-0.8c-0.7-0.3-1.4-0.7-1.3-1.6c0.3-0.2,0.5-0.4,0.8-0.7
	c1.1,0.4,2.2,0.9,3.3,1.3c1-0.7,1.8-1.3,2.7-2c0.2,0.5,0.5,0.9,0.7,1.4C344,400.2,343,401,342,401.8z"
    />
    <path
      class="st175"
      d="M232.1,337.1c1.3,0.3,2.6,0.6,3.9,1c-1.8,0.3-3.6,3.2-5.1-0.1C231.2,337.4,231.6,337.2,232.1,337.1z"
    />
    <path
      class="st192"
      d="M382.7,294.3c0.8,0.6,1.7,1.2,2.5,1.8c0,7.2,0.1,14.5,0.1,21.7c-0.6,0.8,0,2-1.2,2.8c-1.5,0.6-3,0.2-4.4-0.9
	c-0.6-1.1-2-1.8-2.2-2.9c0-1.7,1.5-1.9,1.9-3c-1.6-2.7-0.6-6.3-3.1-8.8c-0.5-1.2-0.3-2.6-0.4-3.9c0.5-4.7-2.2-7.3-5.9-9.4
	c-3.1-1.8-6.4-3.2-9.3-5.3c-5.7-3.3-11.4-6.5-17.4-9.2c-1.2,2.2-0.9,4-1.1,5.6c0.7,5,0.4,10,0.3,15c-0.2,0.6-0.3,1.3-0.3,1.9
	c0.4,2.1-0.2,4.3,0.2,6.4c0.1,0.4,0,0.8,0,1.3c0.4,5.9,0,11.8,0,17.7c0,1.8,0.4,3.2,2.1,3.9c6.4,2.6,11.9,6.7,18,9.8
	c4.4,2.2,8.9,4.5,11.3,6c-6-3.6-14.3-7.3-22.1-12.1c-1.9-1.2-4.6-1.7-5-4.6c0.1-0.6,0.4-1,0.9-1.2c1.6-0.5,2.9,0.4,4.2,1.1
	c8.3,4.6,17.3,8,25,13.7c0.6,0.4,1.1,0.5,1.6-0.2c-0.3-4.4-0.6-8.8,0.5-13.2c0.3-1.2-0.3-2.4-0.9-3.5c-0.2-0.6-0.4-1.1-0.4-1.7
	c0.2-1.8,1.2-2.7,2.9-2.7c1.7,0.3,3.5,0.2,4.6,1.8c0,18,0.1,36,0,54c0,4.9-2.6,6.3-6.7,4c-21.4-12.2-42.8-24.3-64.1-36.5
	c-21.7-12.4-43.4-24.7-65.4-36.5c-2.2-1.2-4.2-2.7-6.3-4c0-13.9,0-27.6,0-41.4c0-15.6,0.1-31.2,0.2-46.9c1.9-0.9,3.4,0.3,4.9,1.1
	c15.6,9.1,31.6,17.5,47.3,26.6c16.8,9.8,33.8,19.1,50.7,28.9c9.6,5.5,18.9,11.6,29.1,16.2C378.1,289.3,381.1,291,382.7,294.3z"
    />
    <path
      class="st193"
      d="M382.7,294.3c-7.6-5.9-16.8-9.1-24.9-14.1c-9.7-6-19.8-11.4-29.8-17.1c-16.3-9.3-32.5-18.6-48.8-27.8
	c-12-6.8-24.1-13.4-36.1-20.1c-0.1-1,0-2.1-0.7-3c0-1.5,0-3,0-4.5c0.2,0,0.4,0,0.6,0c2.1,3.5,5.6,5.2,8.9,7
	c8.5,4.6,16.5,9.8,25.1,14.1c1.1-0.1,0.7-1.3,1.2-1.7c7.3,3,13.7,7.6,20.5,11.4c10.2,5.5,20,11.5,30,17.4
	c11.5,6.7,22.7,13.6,34.4,20c4.1,2.2,8,5,12.5,6.6c3.4,1.2,4.6,5.1,7.2,6.9C383.7,291.1,382.3,292.6,382.7,294.3z"
    />
    <path
      class="st194"
      d="M385.3,322.4c-1.4-0.3-2.9-0.6-4.3-0.8c-5.7-0.4-9.8-4.5-14.7-6.8c-7.1-3.3-14.1-6.8-20.7-11.1
	c-1.7-1.1-3.6-1.9-4.3-4c0-0.4,0-0.9,0-1.3c1.5-1,2.9-0.3,4.2,0.5c9.3,5.1,18.8,9.8,28,15c2.4,1.4,4.3,3.3,6.7,4.7
	c0.9,0.9,2,1.4,3.3,1.3c1.3-0.1,1.2-1.4,1.8-2.1C385.4,319.3,385.4,320.8,385.3,322.4z"
    />
    <path
      class="st195"
      d="M385.5,293.1c-2-2.5-0.5-5-0.2-7.5c2.1,0.1,3.4,1.5,4.5,3c0,1.7,0,3.4,0,5.1c0,1.6-0.6,2.6-2.3,2.7
	C386.3,295.7,386.5,294,385.5,293.1z"
    />
    <path
      class="st16"
      d="M245.2,194.8c3.6,0.8,6.3,3,9,5.2C250.3,199.8,247.3,198.2,245.2,194.8z"
    />
    <path
      class="st196"
      d="M278.2,227c0.4,1.3,2.3,2.2,0.4,4.2c-11.7-6.6-23.4-13.4-35.1-20c-0.8-1.2-0.4-2.5-0.6-3.7
	c1-2,2.2-0.4,2.9,0.1C256.7,214,267.4,220.5,278.2,227z"
    />
    <path
      class="st92"
      d="M388.4,229.3c-1.1,2.8-0.5,5.8-1,9c-2.8-0.9-5.1-0.2-7.3,1c-0.2,0-0.4-0.1-0.6-0.2c-2-2-0.6-4.3-0.4-6.4
	c1.3-1.5,1.1-4.4,4-4.5C384.8,228.7,386.8,228.3,388.4,229.3z"
    />
    <path
      class="st151"
      d="M379.4,231.6c0,2.5,0.1,4.9,0.1,7.4c-2.2-0.7-4-1.8-5.4-3.6l0.2-0.2c1.2-0.8,2.3,0.5,3.5,0.3
	c1-0.5,0.2-1.8,0.9-2.5c0-0.5,0.1-1,0.1-1.4C379,231.3,379.2,231.3,379.4,231.6z"
    />
    <path
      class="st197"
      d="M377.9,230.1c-2.9-1.8-5.6-3.7-5.7-7.6c2-1.1,4-2.1,4.8-4.4c0.4-1.2-0.5-2.1-0.5-3.2c1.3-1.5,1-4,2.9-5.1
	c2.7,1.4,4.2,3.3,4,6.7c-0.1,2.6,0.9,5.2,1.4,7.7c0.4,0.5,0.4,1,0.2,1.5c-1.4,2-4.6,2-5.5,4.8C379.5,230.8,378.5,230.5,377.9,230.1z
	"
    />
    <path
      class="st198"
      d="M396.9,239.8c-3.7,1.7-5.8-0.8-8.2-2.5c-0.4-1.9-0.6-3.5,0.5-5.3c2.2-0.7,4.6-1.2,6.5,0.6
	C397.9,234.6,397.8,237.2,396.9,239.8z"
    />
    <path
      class="st199"
      d="M384.6,225.5c0.1-0.5,0.2-0.9,0.2-1.4c1.3-1.7,0.2-3.8,0.7-6c1.6,2.4,3,4.5,4.2,6.4c0.4,2-0.6,3.3-1.4,4.7
	c-1.9,0.9-3.8,1.5-5.2-0.7C382.5,227,384.4,226.7,384.6,225.5z"
    />
    <path
      class="st200"
      d="M340.9,157c-4.9-3-5.5-6.3-2-9.3C341.7,149.1,343,154.7,340.9,157z"
    />
    <path
      class="st201"
      d="M156.4,287.8c-3.3,0.9-5.7,1.9-7.2-1.9C152.1,283.5,153.8,285.6,156.4,287.8z"
    />
    <path
      class="st202"
      d="M168.1,293.3c3.1,0.3,4.6,2.7,6.9,4.2c-2,2.5-4.2,0.2-6.3,0.8C168.6,296.5,165.7,295.6,168.1,293.3z"
    />
    <path
      class="st203"
      d="M128.4,304.5c-2,0.9-3.4,0.4-4.4-1.6C127.6,301.4,127.9,301.5,128.4,304.5z"
    />
    <path
      class="st29"
      d="M143.2,317.8c-0.5-1.3,0-2.3,0.9-3.2c-1.1-1.3-2.4,0.4-3.7-0.5c0-2.1,1.5-2.9,3.2-3c2.4-0.1,4.5,0.9,5.8,3.4
	C147.3,315.8,145.4,317.1,143.2,317.8C143.2,317.8,143.2,317.8,143.2,317.8z"
    />
    <path
      class="st204"
      d="M183.7,287c0-1.7,1.9-2.7,1.5-4.5c1.3,0.4,2.5,0.8,3.8,1.2c0.2,1.3-1.3,1-1.5,1.9
	C186.5,286.6,185.5,287.8,183.7,287z"
    />
    <path
      class="st125"
      d="M186.8,318.4c3.3-4.8,8.5-6.7,13.6-8.8C195.9,312.5,191.4,315.5,186.8,318.4z"
    />
    <path
      class="st205"
      d="M139.4,389.9c11.2,7.5,23.2,13.7,34.9,20.2c23.2,13,46.3,26.1,69.1,39.6c9.9,5.8,20,11.2,30,16.8
	c1.1,0.6,2.4,0.8,3,2c-0.9,0.1-1.2,0.7-1.3,1.5c-6.6-3.7-13.1-7.7-19.8-11.2c-9.2-4.7-18.2-9.8-27.1-15c-5.5-3.3-10.7-7.3-16.3-10.3
	c-6.5-3.5-12.9-6.9-19.4-10.4c-4.4-2.3-8.4-5.3-12.8-7.7c-6.2-3.4-12.4-6.8-18.7-10.1c-6.6-3.5-13.2-7-19.3-11.3
	C140.4,393.3,138.7,392.1,139.4,389.9z"
    />
    <path
      class="st206"
      d="M280.1,105.2c-0.6-2-0.6-4-0.1-6.1c3.5,0.1,2.7,2.5,2.7,4.3C282.8,105,282,105.9,280.1,105.2z"
    />
    <path
      class="st207"
      d="M185.1,147.4c3.9-2,7.1-5.2,11.3-6.7c1,1.2,2.6,0.5,4.1,1.2c0.5,1.9,1.8,3.5,2.7,5.3c0.7,1.6-2.3,1.8-1.3,3.4
	c0,3.3-3.8,3.2-5.2,6c-0.6,2.3-0.3,5.3-0.2,8.2c0.1,0.6,0.2,1.2,1.3,1.5c2.7-2.2,6.5-2.7,9.5-4.8c0.8-0.6,1.7-1.1,2.7-0.6
	c-0.2,3.5-4.9,2.9-5.5,6c0.2,1,1.2,0.7,1.7,1.3c-0.1,1.2-0.9,2-1.9,2.5c-5.3,2.9-10.7,5.5-16,8.4c-0.6,0.3-1.2,0.2-1.7-0.4
	c-0.3-3.1,2.3-3.7,4.3-5c1.7-4,1.2-8.4,1.7-12.6c0.1-1-0.5-1.6-1.5-1.7c-3.6,2.6-7.2,5.3-11.9,5.3c-0.4-2.5,3.4-2.8,2.9-5.4
	c-0.3-0.4-0.5-0.9-0.3-1.4c1.5-2.6,2-5.4,2.1-8.4C184,148.8,184.6,148.1,185.1,147.4z"
    />
    <path
      class="st208"
      d="M202.3,181c-2.8,2.7-6.5,3.8-9.8,5.5c-0.7,0.4-1.3,0.6-1.5,1.4c-2.2,3-5.8,3.6-9,4.9c-0.5,0-0.9,0-1.4,0
	c-0.9,0.6-1.8,0.6-2.8,0.3c-0.8-0.4-1-1.3-1.6-1.9c0-0.5,0-1,0.1-1.6c1.2-1.3,0.1-3.3,1.3-4.6h0c1.5-1,2.2,0.7,3.3,0.9
	c9-4.4,17.5-9.8,26.3-14.7c1.1-0.7,2.2-1.4,3.6-0.5c0.4,2-2,3.2-1.5,5.2C208.1,179.2,205.6,180.6,202.3,181z"
    />
    <path
      class="st209"
      d="M179.2,164.9c3.9-1.9,7.5-4.6,11.9-5.3c0,3.4,0.5,6.7-0.1,10.2c-3.5,2.4-7.1,4.3-11.5,6.4
	C178.8,172.2,179.2,168.5,179.2,164.9z"
    />
    <path
      class="st210"
      d="M185.1,147.4c0.3,4.3,1.2,8.8-2.9,12.1c-2.1-0.7-3.3,2-5.3,1.4c0.9-1.1,0-2.1-0.3-3.1
	c-1.5-6.1,1-9.6,7.3-10.5C184.2,147.4,184.7,147.4,185.1,147.4z"
    />
    <path
      class="st211"
      d="M179.8,188.1c-1-0.9-0.1-3-2.2-3.1c2.4-3,6.4-3.4,9.1-6c6.5-3.5,13-7.1,19.5-10.6c1.5,0,2.5-2.2,4.5-1.2
	c1.3,1,1.9,2.2,0.1,3.6c-1.1,0.5-2.2,1-3.3,1.4c-4.5,0.2-7.4,3.7-11.3,5.4c-4,1.7-7.5,4.3-11.5,6.2
	C182.7,184.8,181.1,186.3,179.8,188.1z"
    />
    <path
      class="st212"
      d="M183.4,212c-1.6,2.5-3.8,4-6.6,4.8C178,213.9,181.1,213.4,183.4,212z"
    />
    <path
      class="st211"
      d="M176.3,191.1c1.5,0.5,2.7,1.8,4.4,1.6c-1.7,2.1-3.3,1.9-4.8-0.2C176,192.1,176.1,191.6,176.3,191.1z"
    />
    <path
      class="st211"
      d="M177.6,184.9c-0.3,1.5,1,3.6-1.3,4.6C176.5,187.9,175.2,185.9,177.6,184.9z"
    />
    <path
      class="st213"
      d="M205.9,149.1c0.6-0.5,1.2-1.1,1.8-1.6c2.2-0.8,2.2,0.8,2.3,2.1c0.1,2.6,0.1,5.1,0,7.7
	c-1.7,4.2-4.5,6.2-8.7,6.1c-4.2-2.6-4.7-6.3-1.1-9.6C201.9,152.1,203.9,150.7,205.9,149.1z"
    />
    <path
      class="st214"
      d="M205.9,149.1c-0.7,2.8-3.4,3.7-5.1,5.6c-2.9,3.2-2.9,4.7,0.4,7.7c-0.2,3.4-2.8,3.9-5,4.7
	c-0.9-0.4-0.7-1.2-1.1-1.7c0-3.4,0-6.9,0-10.1c1.9-2.5,5-2.7,6.8-4.8C203.2,150.4,204.3,148.9,205.9,149.1z"
    />
    <path
      class="st215"
      d="M202.3,181c2.3-1.7,4.7-3.4,7-5c2,2.6,0.8,5.1-0.3,7.6c-1,0.6-2,1.3-3.4,1.4
	C203.9,184.1,202.5,183.1,202.3,181z"
    />
    <path
      class="st216"
      d="M195,165.5c1.2,1.8,2.3,0.5,3.3-0.2c1.1-0.8,2-1.8,2.9-2.8c3.7,0.4,5.6-2.5,8-4.4c1.4-0.1,1.2,0.6,0.8,1.5
	c0,0.5,0,1,0,1.5c-3.3,3.4-8.1,3.9-11.9,6.5C196.7,168.4,194.8,168.1,195,165.5z"
    />
    <path
      class="st217"
      d="M210,159.5c-0.3-0.5-0.5-1-0.8-1.5c0-2.4,0-4.9,0-7.3c0-1.3,0.2-2.8-1.6-3.2c0.4-1.4,1.3-1.6,2.9-0.8
	C210.5,150.8,211.4,155.2,210,159.5z"
    />
    <path
      class="st218"
      d="M182.1,192.8c2.8-2,5.5-4.1,9-4.9c0.1,2.1,0.2,4.2,0.3,6.2c-1.8,1.6-3.3,3-5.7,3.4c0.5-2,2-3.6,0.6-5.6
	c-2.3,1.6-0.4,4.9-2.7,6.7C181.5,197,182.8,194.6,182.1,192.8z"
    />
    <path
      class="st219"
      d="M376.5,214.9c0.8,0.2,1.8,0.2,2.1,1.2c0.7,2.2-0.8,3.8-2.1,5.2c-1.1,1.3-2.3,2.8-4.3,1.2
	C371.9,219,372.5,216,376.5,214.9z"
    />
    <path
      class="st105"
      d="M384.6,225.5c0.1,1.3-0.9,2-1.4,3c-2.3,0.5-1.5,2.2-1.3,3.9c-1-0.4-1.8-0.6-2.5-0.8l-0.3,0h-0.3
	c-0.3-0.5-0.5-1-0.8-1.5c0.5-0.1,1.4-0.2,1.4-0.3C379.5,226.4,382.5,226.5,384.6,225.5z"
    />
    <path
      class="st105"
      d="M378.6,233c0,1.2,0,2.4,0,3.9c-1.6-0.6-3-1.2-4.4-1.7c0-0.2,0-0.5-0.1-0.7c1.1,0,2.1,0,3.4,0
	C377.9,234.2,378.3,233.6,378.6,233z"
    />
    <path
      class="st220"
      d="M224.4,371.4c-3.2-1.3-6.3-2.8-9.4-5.2c3.4-1.5,5.6-4.2,9.1-5c2.9,1.2,5.8,2.4,8.7,4.3
	C230.2,368.1,227.2,369.5,224.4,371.4z"
    />
    <path
      class="st221"
      d="M315.7,412.4c-2.3,2.2-5.4,3-8.1,4.7c-3.2-0.9-6.3-1.8-9.4-4c2.3-2.8,5.3-4.2,7.8-5.9
	C310.4,407.2,312.5,410.8,315.7,412.4L315.7,412.4z"
    />
    <path
      class="st222"
      d="M225.5,373c2.8-2.6,5.7-4.4,8.9-5.8c2.9,1.2,5.7,2.3,9,3.6c-3.1,2.4-5.9,4.6-8.9,7
	C231.9,375.7,228.6,374.8,225.5,373z"
    />
    <path
      class="st223"
      d="M296.1,399.4c6.9-7,8.8-7.2,16.5-1.8c-1.9,2.9-5,4.2-7.6,6.4C301.8,403,299.6,400.3,296.1,399.4z"
    />
    <path
      class="st224"
      d="M263.3,383.1c-1.8,2.5-5.7,2.7-7.9,5.5c-3.6-1-6.5-3.2-9.7-5c0.2-0.8,1-0.6,1.4-1
	C252.4,377.6,257.8,378.3,263.3,383.1z"
    />
    <path
      class="st221"
      d="M274.4,388.6c-3,2.4-6.2,3.2-8.4,5.9c-3.2-1.3-6.2-2.9-9.1-5.1c3-2,5.8-3.9,8.7-5.7
	C268.4,385.3,271.2,386.8,274.4,388.6z"
    />
    <path
      class="st225"
      d="M339.6,417.5c-1.6,0.9-3.2,1.8-4.8,2.7c-1.6-0.8-3.2-1.6-4.9-2.5c-1.2-0.5-2.4-1.1-4.1-1.9
	c2.7-2.3,5.1-4.3,7.5-6.4c2.9,1.4,5.7,2.9,8.5,4.3C342,415.7,340.2,416.2,339.6,417.5z"
    />
    <path
      class="st226"
      d="M273.1,376.4c-2.9,1.9-5.5,3.6-8.3,5.3c-2.5-2-5.6-2.9-8.9-4.3c2.2-2.7,5.1-3.9,7.4-6
	C266.7,372.5,270.1,373.6,273.1,376.4z"
    />
    <path
      class="st227"
      d="M304.5,405.9c-2.6,3.2-6.6,3.5-9.3,5.9c-2.8-1.8-5.4-3.5-8.2-5.2c2.8-2.4,6.6-2.5,9.1-4.8
	C299.2,402.5,301.7,404,304.5,405.9z"
    />
    <path
      class="st228"
      d="M276.2,400c-3.1-1.7-6.3-2.6-9.2-4.8c3-1.8,6.2-3,8.8-5.3c2.8,1.3,5.7,2.7,9.3,4.5
	C281.8,396.5,279.1,398.2,276.2,400z"
    />
    <path
      class="st229"
      d="M291,389.7c-2,0.1-3.1,1.9-5.1,2.4c-3.2-0.2-5.9-1.7-8.7-3.8c1.9-2.6,4.8-3.9,7.1-6c3.2,1,5.9,2.6,8.1,4.5
	C292.8,388.4,291,388.6,291,389.7L291,389.7z"
    />
    <path
      class="st230"
      d="M337.5,399.4c0.5,0.5,0.9,1.1,1.3,1.6c-0.2,0.2-0.4,0.5-0.6,0.8c-2.2,1.2-3.8,3.1-5.5,4.9
	c-3.8-0.4-6.2-3.5-9.4-4.8c0.6-1.7,1.9-2.2,2.8-3.2c1.4-0.7,2.8-1.4,4.2-2.1C333.1,396.8,335.1,398.6,337.5,399.4z"
    />
    <path
      class="st231"
      d="M286.3,396c3,0.5,5.3,2.6,8.2,3.9c-2.2,3.5-6.3,3.6-9.4,5.6c-2.8-0.9-5.1-2.5-7.6-4.3
	C280.6,399.3,283.4,397.7,286.3,396z"
    />
    <path
      class="st232"
      d="M236.3,378.2c2.7-2.3,5.4-4,8.3-5.6c2.9,0.8,5.1,2.8,7.8,4.2c-2.4,3.4-6.5,3.2-9.3,5.5
	C240.6,381.6,238.2,380.6,236.3,378.2z"
    />
    <path
      class="st233"
      d="M298,379.4c3.6,0.5,6.7,2,9.2,4.4c0,2.9-3.4,3.5-3.5,6.1c-3.8-1.1-7.2-2.8-10.7-4.7
	C294.1,382.5,296.5,381.3,298,379.4z"
    />
    <path
      class="st234"
      d="M253.5,365.7c3,1.2,5.7,2.8,8.5,4.7c-2,2.9-5.4,3.2-7.7,5.3c-3.1-1-5.6-2.7-8.4-4.2
	C248,368.7,250.9,367.5,253.5,365.7z"
    />
    <path
      class="st235"
      d="M315.7,412.5c-0.5-1.9,0.7-2.9,2-3.9c1.7-1.3,3.4-2.5,5.1-3.8c3.2,0.8,6.4,1.4,8.7,4.2
	c-1.7,2.7-4.5,3.8-7.1,5.3C321.7,412.5,319.1,410.6,315.7,412.5C315.7,412.4,315.7,412.5,315.7,412.5z"
    />
    <path
      class="st236"
      d="M225.3,359.9c2.8-1.8,5.1-3.2,7.6-4.7c3.1,0.3,5.8,2,8.9,3.5c-1.9,3.3-4.7,5-8.1,5.9
	C231,363,228.4,361.6,225.3,359.9z"
    />
    <path
      class="st237"
      d="M244,360c2.8,1.4,5.3,2.7,8,4.1c-2,3.1-5.4,4-8.2,5.9c-2.4-1.7-5.5-2.2-8.1-4.4
	C238.6,363.7,241.2,362,244,360z"
    />
    <path
      class="st238"
      d="M290.9,389.8c1.4,0.3,2-1.3,3.7-1.3c2.4,0.8,5.5,0.9,7.7,3.4c-1.6,3.3-5.3,3.8-7.6,6.1c-3-0.6-5-2.7-7.5-3.9
	C287.8,391.9,290.6,391.9,290.9,389.8C291,389.7,290.9,389.8,290.9,389.8z"
    />
    <path
      class="st239"
      d="M313.5,396.5c0.4-3.3,3.5-4.1,5.5-6.1c3.3,1.5,6.4,2.9,9.5,4.3c-0.4,2-2.3,2.1-3,3.3
	c-1.4,0.8-2.7,1.6-4.1,2.4C318.3,399.9,316.4,397.2,313.5,396.5z"
    />
    <path
      class="st240"
      d="M313.8,398.7c3.2,0.6,5.3,2.9,8.1,4.2c-1.6,3-4.6,4-7.2,5.8c-2.7-1.2-5.3-2.5-8.2-3.9
	C309,402.7,311.3,400.8,313.8,398.7z"
    />
    <path
      class="st241"
      d="M267,383c2.7-2.1,4.9-3.8,7.2-5.6c2.9,1,5.8,1.9,8.3,4.1c-1.4,2.8-4.3,3.6-6.4,5.4
	C272.9,386.2,270.1,385,267,383z"
    />
    <path
      class="st242"
      d="M258.2,359.1c4.3-1.1,7.2,2,10.8,3.4c-0.5,1.1-0.9,1.8-1.2,2.5c-1.9,0.3-3.1,2-4.9,2.9c-3-0.7-5.7-2-8.2-4.3
	C255.2,361.6,257.3,360.8,258.2,359.1z"
    />
    <path
      class="st243"
      d="M284.3,377.9c1.4-1.2,2.7-2.5,4.3-3.9c3.3,0,5.7,2.3,8.5,3.7c0.8,0.4,0.2,1.1-0.1,1.6
	c-1.7,1.4-3.3,2.9-5.2,4.5c-2.6-1.2-5.2-2.5-7.6-3.5C283.4,379.2,284.2,378.6,284.3,377.9z"
    />
    <path
      class="st244"
      d="M248.9,353c3.7-0.2,6.7,1.1,9.4,3.8c-0.4,0.7-0.8,1.4-1.2,2.2c-1.2,1-2.5,2-3.8,3.1c-3-0.5-5.8-1.7-8.4-3.3
	C244.8,355.8,249,355.9,248.9,353z"
    />
    <path
      class="st245"
      d="M276.3,371.2c1.2-0.8,2.5-1.5,3.8-2.4c2.5,1.1,5.1,2.2,7,4.2c-0.5,1.8-2.2,2.5-3,3.9c-0.7,0.5-1.3,1.1-2,1.7
	c-2.9-0.9-5.2-3.2-8.6-3.4C274,373.3,275.5,372.5,276.3,371.2z"
    />
    <path
      class="st246"
      d="M267.7,365c4.1-2,7.3-0.3,10.4,2.5c-0.3,1.8-2.4,2.1-3,3.5c-1,0.7-2,1.5-3,2.3c-2.6-1.4-5.2-2.7-7.6-4
	C264.9,367,267.3,366.9,267.7,365z"
    />
    <path
      class="st247"
      d="M303.7,389.8c2.6-1.5,4.1-5.1,8.2-3.6c2.1,0.7,3.5,2.2,5.5,3.2c-1.5,2.6-3.6,4.1-6.2,5
	C308.3,393.5,305.3,392.8,303.7,389.8z"
    />
    <path
      class="st161"
      d="M338.2,403.2c2.7,0.1,5.4-0.2,7.8,1.7c0.8,0.6,1.7,1.1,2,2.1c-0.6,0.3-1.4,0.5-1.9,1c-5.1,4-6.6,3.9-12.2-0.4
	C335,405.8,336.4,404.3,338.2,403.2z"
    />
    <path
      class="st248"
      d="M242.2,347.8c1.7,1,3.5,2,5.2,3c-0.3,3.7-3.1,4.6-6,5.2c-2.5-1.1-5-2.2-8.1-3.6
	C236.3,350.3,238.1,347.1,242.2,347.8z"
    />
    <path
      class="st249"
      d="M241.5,356.1c2-1.8,4.5-2.9,6-5.2c1.5,0.1,1.4,1.3,1.5,2.3c-1.4,1-2.8,1.8-4.1,2.9
	C243.7,357.1,242.6,357.2,241.5,356.1z"
    />
    <path
      class="st250"
      d="M250.7,255.1c2.6-0.7,4,0.1,5.2,0.8c10.2,5.5,20.3,11.1,30.5,16.6c3.5,1.9,5,4.5,4.7,8.5
	c-0.4,5.1,0.4,10.4-0.6,15.4c-2.3,0.6-3.3-1.1-4.7-1.8c-10.7-5.8-21.4-11.7-32.1-17.4c-2.8-1.5-4-3.5-3.8-6.6
	C250.3,265.3,250.4,260.2,250.7,255.1z"
    />
    <path
      class="st251"
      d="M348,327.5c-0.3,0.3-0.6,0.6-0.8,0.9c-0.5,0.7-1.2,0.7-1.9,0.6c-0.1,0-0.2,0-0.3,0c0.1,0.5,0.4,1,0,1.6
	c-1.5-0.1-2.4-1.1-3.7-2.3c0-6.8,0-13.9,0-21c2.2-0.5,3.8,1,5.3,2.1c9.1,6.3,19.2,10.9,28.7,16.5c0.4,0.2,0.9,0.4,1.6,0.5
	c1.1,0,1.7-1.2,2.6-1.8c2.1,3,0.2,6.2,0.1,9.3c0,3,0.5,5.8,0.5,8.8c-2.1,0.6-3.2-1-4.6-1.9c-8.5-5.4-17.6-9.6-26.5-14.5
	c-1-0.5-2-1-2.8-1.8C347.1,325.3,348.6,325.8,348,327.5z"
    />
    <path
      class="st252"
      d="M335.9,280.3c0.1,4.5,0.3,9.1-0.4,14.7c-13.1-7-25.5-13.5-37.7-20c0-3,0-5.2,0-7.5c1.5-3.7,4.6-1.9,7-2
	c1.5-0.3,2.4-1.4,3.6-2.1c3.1-1.9,5.5-1.2,6.9,2.2c1,2.5,1.4,5.2,1.9,7.8c3.1,0.7,3.2-5.1,6.8-2.9c1.1,2.1-1.2,3.7-0.7,6.2
	C327.1,278.3,331.3,280,335.9,280.3z"
    />
    <path
      class="st253"
      d="M250.4,284c0.1-1-0.2-2,1.3-3.7c12.8,6.9,25.7,13.8,38.6,20.8c1.3,3,0.7,6.2,0.7,9.2c-0.7,0.7,0,1.8-0.8,2.4
	c-0.6,0.4-1.2,0.4-1.8,0c-3.5-2.3-7-4.1-11.4-3.9c-1.6,0.1-2.9-1.2-3.9-2.7c-1.1-6-5-9.9-9.8-12.8c-2.1,0.5-2.9,2.4-4.7,2.9
	c-3.4,0.8-5.9-1.1-8.4-3C250.1,290.1,249.9,287,250.4,284z"
    />
    <path
      class="st254"
      d="M335.8,308.1c0,4.3,0,8.7,0,14c-13.3-6.5-25-14.5-37.7-20.2c-0.7-3-0.5-5.6-0.3-8.2c0.7-1.5,1.8-2.6,3.5-2.1
	c3.2,0.9,6.4,0.5,9.6,1c2.5,2.1-0.8,4-0.2,6.2c1.8,2.1,4.4,0.6,6,1c1.9,0.5,2.3,2.2,3.9,2.3C325.1,305.5,330.8,306,335.8,308.1z"
    />
    <path
      class="st255"
      d="M297.8,320c0-4.3,0-8.7,0-13c3-0.9,4.7,1.2,6.7,2.3c9.4,5.1,18.8,10.3,28.1,15.5c1.2,0.6,2.6,1.1,2.7,2.8
	c0.4,3.7,0.2,7.4,0.1,11.8c-4.8-2.1-9.2-4.1-14.2-3.8c-1.9-1.1-1.3-3-1.4-4.7c-0.1-1.4-0.2-2.7-0.7-4.5c-2.5-1.2-3.8-5.4-8.1-3.7
	C306.3,323.5,302.1,321.6,297.8,320z"
    />
    <path
      class="st256"
      d="M380.2,318.6c-4.8-1.5-8.8-4.6-13.2-6.8c-8.6-4.4-17.2-8.9-25.8-13.3c0-5.5,0-11.1,0-16.6
	c3.2-0.7,4.5,2.4,6.8,3.4c0.8,1.4,0,2.6-0.3,3.9c-0.7,3,0.6,4.6,3.2,5.5c1.8-1.3,1.2-3.7,3-4.8c3.8,1.2,6.2,3.6,6.4,7.7
	c0,1,0.1,2,1.1,2.6c1.5,1.2,3.1,2,4.7,3c3,2,6.1,2.4,9.5,1.2c0.6,0,1.1,0.3,1.5,0.7c4.4,0.9,1.5,4.7,2.9,6.9c0.4,1,2.1,0.7,2.1,2.3
	c-0.4,2-2.6,0.9-3.6,1.9C378.5,317.3,380.4,317.2,380.2,318.6z"
    />
    <path
      class="st257"
      d="M335.8,308.1c-0.6,0.4-1,1.6-1.7,1.1c-4.2-3-10.5-1.6-13.8-6.4c-1.5-1-1.3-2.8-2-4.3
	c-1.4-3.1-3.4-5.5-7.1-5.5c-3.5,1.4-7,1.1-10.3-0.5c-1.4-0.3-2.1,0.8-3.1,1.2c0-4.4,0-8.7,0-12.9c1.5-1.1,2.9-0.5,4,0.1
	c11.2,6.1,22.5,12.3,33.7,18.5C336.3,302.4,335.9,305.2,335.8,308.1z"
    />
    <path
      class="st258"
      d="M370.9,367.8c-5.8,0.2-18.2-7.7-20.4-13.4c-2-5.1-3.3-10.4,0.5-15.8c6.1,1.2,11,4.3,16,7.1
	c7.1,3.9,8.1,10.2,7.2,17.3C373.9,365.2,372.5,366.6,370.9,367.8z"
    />
    <path
      class="st259"
      d="M297.8,320c1.8-1.2,3.7-0.3,5.2,0.3c2.7,1.2,5.4,1.2,8.2,1.3c2,2.7,3.9,5.5,6.9,7.2c1.2,0.7,1.6,1.9,0.6,3.4
	c-2.2,1.1-3.5-1.1-5.1-1.8c2.3,1.7,5,3,7.4,4.6c2.8-1.8,9-0.7,12.5,2.2c0.3,0.3,0.7,0.6,1.8,1.7c0-4.4,0-7.9,0-11.4
	c1,6.9,0.9,13.8-0.5,21.5c-12.8-7.3-24.9-14.2-36.7-21C297.5,324.9,297.6,322.5,297.8,320z"
    />
    <path
      class="st260"
      d="M335.9,280.3c-0.4,0.6-0.8,1.2-1.5,2.2c-3.9-2.7-8.8-2.9-13-4.8c-0.8-2.7,1.6-4.4,2.1-6.9
	c-3.6,0-2.5,4-4.4,4.9c-1.3,0.9-0.8-2.9-2.5-0.6c-1.8-4-0.9-8.8-4.4-11.9c-3.3-0.1-4.4,2.9-6.8,4.1c-1.5-0.4-3-0.8-4.2-1.2
	c-1.6-0.1-2.3,0.9-3.3,1.4c0-4.6,0-9.2,0-13.7c2.4-0.8,3.9,0.6,5.4,1.5c9.2,5.1,18.4,10.5,27.7,15.5
	C335.1,272.9,336.8,275.8,335.9,280.3z"
    />
    <path
      class="st261"
      d="M250.4,284c0,2.7,0,5.4,0,8.2c3.2,1.2,5.5,3.9,9.1,3c3,8.3,4.4,10.1,9.7,12.4c1.9,0.2,2.5-2.5,4.6-1.8
	c1.4,0.3,2.2,1.4,3.4,2.2c5-1.5,8.5,1.4,12.2,4.1c1.2,0,0.8-1.4,1.6-1.8c0.1,4.3,0.3,8.6-0.2,12.8c-2.8,1-4.3-0.9-5.8-1.8
	c-10.5-5.6-20.9-11.6-31.3-17.3c-2.6-1.4-3.9-3.4-3.8-6.3C250,293.1,249.1,288.5,250.4,284z"
    />
    <path
      class="st262"
      d="M291,257.8c0,3.8,0,7.7,0,12.7c-14.1-7.5-27.5-14.6-41.2-21.8c0.3-6.1-0.7-12.4,0.6-18.6
	c2.4,1.8,0.7,4.8,2,7.1c1,1,2.5,1.3,4,1.5c1.5-0.2,2.1-2.3,3.9-1.9c1.8,1.9,1.4,4.2,1.2,6.4c-0.3,3,0.8,5,3.4,6.5
	c2.2,1.3,4.5,2.6,6.6,4.2c0.4,0.6,0.2,1.1-0.3,1.7c4.5-4.5,8.4-1.8,12.4,0.6c1.8,1.1,3.6,2.3,5.3,3.4
	C289.7,259.1,289.5,257.3,291,257.8z"
    />
    <path
      class="st263"
      d="M260.3,236.8c-1.6,0.5-1.3,2.2-2.2,3.4c-2.6,0.8-4.5-1.7-7-1.4c-1.1-3-0.2-6-0.7-8.9c0-0.9-0.1-1.8,0.9-2.6
	c1.7,0.2,3.3,1.1,4.9,2c9.5,5.2,19,10.5,28.6,15.5c4.4,2.3,7.4,5.1,6,10.5c-3.1-3.1-6.9-4.9-11-6.2c-2.1-0.6-4-1.1-6.1-1
	C270.3,243.2,266.2,238.9,260.3,236.8z"
    />
    <path
      class="st264"
      d="M348.2,285.7c-3.2,0.2-4.7-2.5-7-3.9c0-1.8,0-3.6,0-6.5c7.9,2.3,14,6.5,20.2,10.3c-0.7,2.8,1,4.7,2.7,6.6
	c1.7,2,3.6,3.9,2.2,6.8c-1.6,1.6-4,1-5.5,2.3c-2.9-1.4-0.9-4.3-2.2-6.8c-1.2-1.2-2.9-2.8-4.6-4.4C351.2,289.7,350,287.3,348.2,285.7
	z"
    />
    <path
      class="st265"
      d="M360.8,301.2c0-0.8-0.4-1.7,0.4-2.5c1.5-0.4,3.1,1.3,4.7-0.4c0.3-5.1-6.8-6.8-6.2-12.4
	c0.5-0.1,1.1-0.2,1.7-0.3c3.9,2.3,8.2,3.9,11.9,6.6c3,2.1,4.7,4.6,3.9,8.4c-1,0.9-2,0.8-2.9,0c-1-0.9-2.2-0.9-2.5-0.8
	c1.4-0.6,2.8,1.1,4.3,2.1c0.5,1.2,0.7,2.2-0.4,3.1c-2.5,2-5.1,2.2-7.8,0.5C365.5,304,363.1,302.6,360.8,301.2z"
    />
    <path
      class="st266"
      d="M379.5,324.5c0,1.4-0.4,2.7-1.3,4.2c-12.4-7-25.2-13.2-36.9-21.5c0-0.2,0-0.4,0-0.7c0.6-0.8-0.1-2.1,1.4-2.9
	c1.2-0.6,2.6,0.1,3.9,0.9c7.8,4.8,16.1,8.5,24.2,12.7c3,1.6,6.3,3.1,8,6.5C379,324,379.2,324.3,379.5,324.5z"
    />
    <path
      class="st267"
      d="M273.8,248c1.1-1.5,2.7-2.1,4.2-1c3.5,2.5,8.3,2.1,12,6c0.1,0.2,0.5,1.3,0.8,2.5c0,0.8,0.1,1.5,0.1,2.3
	c-1.2,1-0.3,2.6-1.2,3.9c-4.7-3-9-6.7-14.2-7.8c-2.2,0.7-3.2,3.8-5.7,1.5c0.5-0.4,1-0.8,1.5-1.3C270.3,251.3,271.6,249.5,273.8,248z
	"
    />
    <path
      class="st268"
      d="M378.7,323.8c-11.1-7.8-24.2-12.1-34.9-19.4c-2,0.2-1.8,1.6-2.5,2.2c0-2.3,0-4.6,0-6.9
	c12.8,8.1,26.7,14.1,39.7,21.8C379.4,321.5,378.6,322.1,378.7,323.8z"
    />
    <path
      class="st269"
      d="M348,327.5c-1.2-2.6-4.5-2.9-5.5-5.7c12.7,6.7,26.1,12.2,37.7,20.8c-0.8,2.4-2.2,1.4-3.5,0.5
	C367.8,336.6,357.5,332.8,348,327.5z"
    />
    <path
      class="st270"
      d="M345,330.5c-0.4-1.1-1.8-1.6-2-3.2c1.4,0.3,2.8,0.7,4.1,1c8.2,6.1,17.8,9.8,26.5,15c2,1.2,3.8,2.6,5.3,4.5
	C366.8,343.7,356.5,336,345,330.5z"
    />
    <path
      class="st271"
      d="M375.6,305c-0.2-2.6-0.2-2.6-3.1-4.2c-1.5-0.2-1.5,1.6-2.8,1.8c-0.3-0.2-0.8-0.3-1.1-0.7
	c-0.8-1.5-0.2-2.6,1.1-3.3c2.9-0.7,4.8,2,7.5,2c0,1.5-0.1,3-0.1,4.5C376.6,305,376.1,305,375.6,305z"
    />
    <path
      class="st272"
      d="M139.2,447.5c-1-3.2-3.6-4-6.3-4.8c1.7-2.5,4-3.7,6.3-4.9c3.5,1,6.4,2.9,10.1,5.2
	C145.4,444.2,143.1,447.2,139.2,447.5z"
    />
    <path
      class="st273"
      d="M179.8,188.1c0.1-1,0.2-2,0.3-3c8-4.3,16-8.7,23.9-13c1.1-0.6,2.3-0.8,3.5,0c-7.3,4.2-14.6,8.6-22.1,12.6
	C183.5,185.8,182.3,188.2,179.8,188.1z"
    />
    <path
      class="st274"
      d="M262.8,269.3c3.7,3.9,7.3,7.8,10.8,11.6c1.6-0.6,2.3-1.4,2.7-2.6c-1.1-0.6-2.2-1.1-3.6-1.8
	c-1.1-4.2-5.6-5.4-8.2-8.9c2.7-1,3.7,1.3,5.1,2.5c1.2,0.2,1.8-1.4,3.4-0.9c0.8,4.3,6.8,4.9,7.3,10c-1.6,2.9-4,4.5-7.8,4.8
	c-3.8-4.5-7.8-9-10.7-14.3C262.3,269.7,262.6,269.5,262.8,269.3z"
    />
    <path
      class="st275"
      d="M260.3,265.8c0.7-2.1,2-3.4,4.3-4C265,265.4,261.7,264.5,260.3,265.8z"
    />
    <path
      class="st276"
      d="M273.7,305.8c-1.6,0.8-1.8,3-3.7,3.6c-6.9-1.7-12.1-8.6-10.6-14.1c0.4-1.7,1.6-2.7,2.9-3.6
	C268.2,293.2,273.7,300.1,273.7,305.8z"
    />
    <path
      class="st277"
      d="M311.2,293c1.2-2,2.6-1.8,4.2-0.4c3.3,2.6,5.1,5.9,4.9,10.2c-2.2,1.4-2.4-0.4-2.8-1.9
	c-3.1,0.4-6.4,1.4-8.9-1.8C308.7,296.8,311.2,295.4,311.2,293z"
    />
    <path
      class="st278"
      d="M321,335c0,0.1-0.1,0.3-0.1,0.3c-2.8,0.3-9.2-4.3-10.1-7.5c3.1,0.2,4.8,3,7.5,3.9c0.6-2.2-1.2-2.3-2.1-3
	c-2.5-1.8-5.3-3.5-4.9-7.3c0.4-0.6,0.8-1.2,1.3-2.1c2.7,2,5.3,3.9,8.1,6C321.5,328.6,320.7,331.8,321,335z"
    />
    <path
      class="st279"
      d="M348.2,285.7c3.2-0.2,4.1,2.6,5.8,4.5c-0.2,2.4-0.1,5-2.5,6.5C345.3,293.7,344.6,291.5,348.2,285.7z"
    />
    <path
      class="st280"
      d="M354.7,351.1c0.2-3.8,2.3-5.3,5.3-4.5c3.6,1.1,7.2,6.5,6.6,10.2c-0.3,2-1.4,3.5-3.3,4.1
	c-1.9,0.7-3.1-0.3-4.5-1.7C356.3,356.9,355.1,353.9,354.7,351.1z"
    />
    <path
      class="st281"
      d="M273.8,248c-0.8,2-1.6,4-2.4,6c-5-0.8-8.4-4.1-11.8-7.2c0.8-3.6,0.7-6.8,0.7-10c0.5-0.6,1.3-1,2-0.7
	C267.2,239,272.4,241.7,273.8,248z"
    />
    <path
      class="st282"
      d="M362.9,357.3c-3.5-0.2-5.4-3.6-4.6-7.8C362.4,349.9,364.1,352.8,362.9,357.3z"
    />
  </svg>
</template>

<script>
export default {
  name: "Apps100Vue",
};
</script>

<style scoped>
.st0 {
  fill: #fafcfb;
}
.st1 {
  fill: #5197dd;
}
.st2 {
  fill: #7cc4f7;
}
.st3 {
  fill: #e8fafc;
}
.st4 {
  fill: #5e88b4;
}
.st5 {
  fill: #6bc6fd;
}
.st6 {
  fill: #6cb7f7;
}
.st7 {
  fill: #2a3250;
}
.st8 {
  fill: #2a314e;
}
.st9 {
  fill: #476198;
}
.st10 {
  fill: #41669f;
}
.st11 {
  fill: #4e78ab;
}
.st12 {
  fill: #4b78ae;
}
.st13 {
  fill: #6fcafd;
}
.st14 {
  fill: #5fbdfe;
}
.st15 {
  fill: #8bc3ea;
}
.st16 {
  fill: #355082;
}
.st17 {
  fill: #4990e0;
}
.st18 {
  fill: #336fb6;
}
.st19 {
  fill: #65a6e4;
}
.st20 {
  fill: #5078ab;
}
.st21 {
  fill: #1d2a44;
}
.st22 {
  fill: #20487d;
}
.st23 {
  fill: #f87b57;
}
.st24 {
  fill: #22579f;
}
.st25 {
  fill: #d8f8fb;
}
.st26 {
  fill: #87d6a0;
}
.st27 {
  fill: #65c1fe;
}
.st28 {
  fill: #c2a6a6;
}
.st29 {
  fill: #f0f9f8;
}
.st30 {
  fill: #97cef1;
}
.st31 {
  fill: #b4e5f6;
}
.st32 {
  fill: #4a69aa;
}
.st33 {
  fill: #7096c9;
}
.st34 {
  fill: #c8edf9;
}
.st35 {
  fill: #d7f1fa;
}
.st36 {
  fill: #fae9d7;
}
.st37 {
  fill: #f8c36a;
}
.st38 {
  fill: #bbe4f8;
}
.st39 {
  fill: #81cdf4;
}
.st40 {
  fill: #c1e6f6;
}
.st41 {
  fill: #b8e3f4;
}
.st42 {
  fill: #74bfef;
}
.st43 {
  fill: #7ccaf3;
}
.st44 {
  fill: #bbe3f7;
}
.st45 {
  fill: #f08c72;
}
.st46 {
  fill: #e9917d;
}
.st47 {
  fill: #7cc8f4;
}
.st48 {
  fill: #76c6f0;
}
.st49 {
  fill: #eb8872;
}
.st50 {
  fill: #ecf7fa;
}
.st51 {
  fill: #7ccbf0;
}
.st52 {
  fill: #74c8ed;
}
.st53 {
  fill: #8fcced;
}
.st54 {
  fill: #c67e81;
}
.st55 {
  fill: #fdd064;
}
.st56 {
  fill: #e69332;
}
.st57 {
  fill: #5681bd;
}
.st58 {
  fill: #f5b04c;
}
.st59 {
  fill: #a89786;
}
.st60 {
  fill: #e4fbfe;
}
.st61 {
  fill: #f35122;
}
.st62 {
  fill: #e4f7fe;
}
.st63 {
  fill: #ecf4f8;
}
.st64 {
  fill: #c6eef8;
}
.st65 {
  fill: #fafdfd;
}
.st66 {
  fill: #adcceb;
}
.st67 {
  fill: #f7fbf9;
}
.st68 {
  fill: #7093c4;
}
.st69 {
  fill: #355ea3;
}
.st70 {
  fill: #b9def1;
}
.st71 {
  fill: #82a8da;
}
.st72 {
  fill: #afd3f0;
}
.st73 {
  fill: #ccd8e7;
}
.st74 {
  fill: #97bde3;
}
.st75 {
  fill: #495c83;
}
.st76 {
  fill: #436298;
}
.st77 {
  fill: #97c4ec;
}
.st78 {
  fill: #7fafda;
}
.st79 {
  fill: #46587d;
}
.st80 {
  fill: #7dcd9e;
}
.st81 {
  fill: #6caee7;
}
.st82 {
  fill: #479292;
}
.st83 {
  fill: #f85a46;
}
.st84 {
  fill: #f3f4ee;
}
.st85 {
  fill: #ade3ee;
}
.st86 {
  fill: #569592;
}
.st87 {
  fill: #f0ede0;
}
.st88 {
  fill: #b3efb6;
}
.st89 {
  fill: #8bd2a4;
}
.st90 {
  fill: #639bce;
}
.st91 {
  fill: #bcb4c7;
}
.st92 {
  fill: #4dab97;
}
.st93 {
  fill: #effaf8;
}
.st94 {
  fill: #f28177;
}
.st95 {
  fill: #65859a;
}
.st96 {
  fill: #c7e4fa;
}
.st97 {
  fill: #4a8bd5;
}
.st98 {
  fill: #3e5a8e;
}
.st99 {
  fill: #344977;
}
.st100 {
  fill: #8ccaf2;
}
.st101 {
  fill: #213a69;
}
.st102 {
  fill: #2e3346;
}
.st103 {
  fill: #d9eb87;
}
.st104 {
  fill: #7fbcd1;
}
.st105 {
  fill: #6bba9e;
}
.st106 {
  fill: #2a3d69;
}
.st107 {
  fill: #f7e9db;
}
.st108 {
  fill: #61b1a2;
}
.st109 {
  fill: #84b4e1;
}
.st110 {
  fill: #7db7ed;
}
.st111 {
  fill: #f0806c;
}
.st112 {
  fill: #f8cf73;
}
.st113 {
  fill: #92e0c5;
}
.st114 {
  fill: #beefcf;
}
.st115 {
  fill: #8bd1f3;
}
.st116 {
  fill: #d3bbb1;
}
.st117 {
  fill: #f08e85;
}
.st118 {
  fill: #96e2af;
}
.st119 {
  fill: #f37d6c;
}
.st120 {
  fill: #eec1b7;
}
.st121 {
  fill: #eab29a;
}
.st122 {
  fill: #688ec6;
}
.st123 {
  fill: #5879bb;
}
.st124 {
  fill: #c7e8f9;
}
.st125 {
  fill: #799ad2;
}
.st126 {
  fill: #759cd0;
}
.st127 {
  fill: #c5edf8;
}
.st128 {
  fill: #f6e0af;
}
.st129 {
  fill: #f9eabc;
}
.st130 {
  fill: #9dc3e7;
}
.st131 {
  fill: #5577bb;
}
.st132 {
  fill: #4869ae;
}
.st133 {
  fill: #4d6ebb;
}
.st134 {
  fill: #476cb3;
}
.st135 {
  fill: #4468a9;
}
.st136 {
  fill: #4f76b8;
}
.st137 {
  fill: #577fbc;
}
.st138 {
  fill: #527abc;
}
.st139 {
  fill: #557abc;
}
.st140 {
  fill: #65a4df;
}
.st141 {
  fill: #406baf;
}
.st142 {
  fill: #497bbd;
}
.st143 {
  fill: #678ac5;
}
.st144 {
  fill: #6084bc;
}
.st145 {
  fill: #f2f8f8;
}
.st146 {
  fill: #efcf93;
}
.st147 {
  fill: #6081b0;
}
.st148 {
  fill: #28406e;
}
.st149 {
  fill: #686a85;
}
.st150 {
  fill: #e34243;
}
.st151 {
  fill: #7bcf99;
}
.st152 {
  fill: #f67b58;
}
.st153 {
  fill: #e1a59f;
}
.st154 {
  fill: #da6560;
}
.st155 {
  fill: #ed483c;
}
.st156 {
  fill: #8cb1dd;
}
.st157 {
  fill: #c0d4ee;
}
.st158 {
  fill: #89b2dd;
}
.st159 {
  fill: #344368;
}
.st160 {
  fill: #303d5d;
}
.st161 {
  fill: #343e5d;
}
.st162 {
  fill: #88b0d7;
}
.st163 {
  fill: #344266;
}
.st164 {
  fill: #abc0df;
}
.st165 {
  fill: #3b4665;
}
.st166 {
  fill: #324460;
}
.st167 {
  fill: #354761;
}
.st168 {
  fill: #32405a;
}
.st169 {
  fill: #33415e;
}
.st170 {
  fill: #2f3e57;
}
.st171 {
  fill: #313e59;
}
.st172 {
  fill: #182a49;
}
.st173 {
  fill: #3e4d6d;
}
.st174 {
  fill: #2d415f;
}
.st175 {
  fill: #304262;
}
.st176 {
  fill: #6490c5;
}
.st177 {
  fill: #3b4465;
}
.st178 {
  fill: #3b4d73;
}
.st179 {
  fill: #374666;
}
.st180 {
  fill: #34415e;
}
.st181 {
  fill: #3b496f;
}
.st182 {
  fill: #38456b;
}
.st183 {
  fill: #3a4c6f;
}
.st184 {
  fill: #354767;
}
.st185 {
  fill: #344461;
}
.st186 {
  fill: #9fb6d9;
}
.st187 {
  fill: #a1b8d4;
}
.st188 {
  fill: #95aacb;
}
.st189 {
  fill: #a2b8d9;
}
.st190 {
  fill: #879cbd;
}
.st191 {
  fill: #7891ad;
}
.st192 {
  fill: #f9fcfb;
}
.st193 {
  fill: #c6ebfb;
}
.st194 {
  fill: #9dcfed;
}
.st195 {
  fill: #6aabf3;
}
.st196 {
  fill: #f3fafb;
}
.st197 {
  fill: #8bd59d;
}
.st198 {
  fill: #a6e392;
}
.st199 {
  fill: #317584;
}
.st200 {
  fill: #f07c62;
}
.st201 {
  fill: #def5f7;
}
.st202 {
  fill: #f0edd9;
}
.st203 {
  fill: #f0faeb;
}
.st204 {
  fill: #fbefef;
}
.st205 {
  fill: #2d4c88;
}
.st206 {
  fill: #f3c264;
}
.st207 {
  fill: #4e80c7;
}
.st208 {
  fill: #e04f4e;
}
.st209 {
  fill: #beddf2;
}
.st210 {
  fill: #dea262;
}
.st211 {
  fill: #d97170;
}
.st212 {
  fill: #aae2f8;
}
.st213 {
  fill: #e69978;
}
.st214 {
  fill: #e4edee;
}
.st215 {
  fill: #cfd2df;
}
.st216 {
  fill: #83a6d7;
}
.st217 {
  fill: #a28b99;
}
.st218 {
  fill: #cee4f0;
}
.st219 {
  fill: #d6f4ef;
}
.st220 {
  fill: #334363;
}
.st221 {
  fill: #324161;
}
.st222 {
  fill: #303d58;
}
.st223 {
  fill: #33425e;
}
.st224 {
  fill: #273a56;
}
.st225 {
  fill: #2d3f59;
}
.st226 {
  fill: #293a57;
}
.st227 {
  fill: #283954;
}
.st228 {
  fill: #2c3b56;
}
.st229 {
  fill: #2f3d59;
}
.st230 {
  fill: #364462;
}
.st231 {
  fill: #324160;
}
.st232 {
  fill: #354662;
}
.st233 {
  fill: #3c4968;
}
.st234 {
  fill: #2d3c59;
}
.st235 {
  fill: #2d3b54;
}
.st236 {
  fill: #333d55;
}
.st237 {
  fill: #36425b;
}
.st238 {
  fill: #303d56;
}
.st239 {
  fill: #33415c;
}
.st240 {
  fill: #33405a;
}
.st241 {
  fill: #2d3954;
}
.st242 {
  fill: #2d3d5a;
}
.st243 {
  fill: #35405d;
}
.st244 {
  fill: #2f3e5b;
}
.st245 {
  fill: #2f3e59;
}
.st246 {
  fill: #323f58;
}
.st247 {
  fill: #34455d;
}
.st248 {
  fill: #283754;
}
.st249 {
  fill: #56658a;
}
.st250 {
  fill: #fa7258;
}
.st251 {
  fill: #ddf2fc;
}
.st252 {
  fill: #9bd6f9;
}
.st253 {
  fill: #76bbf5;
}
.st254 {
  fill: #a2d9f9;
}
.st255 {
  fill: #77baf4;
}
.st256 {
  fill: #ddf2f9;
}
.st257 {
  fill: #75b8f5;
}
.st258 {
  fill: #f66c59;
}
.st259 {
  fill: #9cd5fb;
}
.st260 {
  fill: #79bdf5;
}
.st261 {
  fill: #9ed7f9;
}
.st262 {
  fill: #a1d7f8;
}
.st263 {
  fill: #70b5ef;
}
.st264 {
  fill: #f3e5c8;
}
.st265 {
  fill: #f58265;
}
.st266 {
  fill: #b0daf6;
}
.st267 {
  fill: #83c9fa;
}
.st268 {
  fill: #d8f5fb;
}
.st269 {
  fill: #a6d7f4;
}
.st270 {
  fill: #abd4eb;
}
.st271 {
  fill: #f0d0bf;
}
.st272 {
  fill: #effbfa;
}
.st273 {
  fill: #f7dfab;
}
.st274 {
  fill: #f6ebd6;
}
.st275 {
  fill: #fce3bf;
}
.st276 {
  fill: #d4eff7;
}
.st277 {
  fill: #d7f3fa;
}
.st278 {
  fill: #def8f8;
}
.st279 {
  fill: #6bb7ca;
}
.st280 {
  fill: #f7f4e5;
}
.st281 {
  fill: #e2f5f8;
}
.st282 {
  fill: #f37764;
}
</style>
