<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 900 900"
    style="enable-background: new 0 0 900 900"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M761.2,288c1.9,1.2,3.9,2.3,5.4,4.1c1,2.6-0.7,3.9-2.5,5.1c-8.6,5.5-17.5,10.3-26.3,15.6
	c-6.4,3.8-13,7.3-19.4,11.1c-1.2,0.7-2.3,1.5-3.7,1.8c-1.7,0-3.1-0.7-4.5-1.5c-8.3-4.8-16.5-9.7-25-13.9
	c-6.8-3.4-12.8-7.9-19.4-11.6c-7.5-4.2-8.5-6.6-0.3-11.8c1.4-0.9,2.9-1.5,3.7-3c2.1-1.7,3.9-4.2,7.2-2.7c5.6-3.2,11.2-6.3,16.7-9.5
	c5.4-3,10.7-6.2,16.2-9.1c3.2-1.7,6.1-1.7,9.7,0.5c10.9,6.8,22.2,12.9,33.4,19.3c1.5,0.9,2.9,1.8,4.4,2.7
	C758,286.4,759.8,286.9,761.2,288z"
    />
    <path
      class="st1"
      d="M746.1,433.4c-7.7,4.9-15.2,10.2-24.1,13c-0.7,0.2-1.4,0.4-2.2,0.6c-5.9,1.7-8.2,0-8.3-6.1
	c-0.2-6.7,0.2-13.5-0.1-20.2c-0.2-4.7,1.8-7.6,5.8-9.5c8.5-3.9,16.3-9.1,24.8-13.8c-3.8,1.9-7.5,3.3-11.5,3.2
	c-4.8-0.1-9.2,1.8-14.1,1.6c-7.7-0.3-13.1-5-19.1-8.8c-0.9-0.6-1.4-1.8-0.8-3c1.8-2.1,3.7-1.3,5.7-0.3c4,2,7.4,4.9,11.5,6.8
	c2.4,0.4,4.5-1.8,7-0.9c1.5,0.6,2.9,0.4,4.4-0.2c9.4-4,19.3-7.1,28.2-12.5c3.9-2.4,7.9-4.8,12.1-6.6c1.7-0.7,3.2-2,4.4-3.5
	c3.1-3.6,2.9-5.7-0.6-8.8c-0.5-0.4-0.8-0.9-1.1-1.5c-0.4-2.9-0.7-5.9,0.2-8.7c1.5-2,3.6-2,5.3-0.8c5.5,3.8,11.7,6.6,16.1,11.9
	c1.6,2,4.4,1.1,5.8,3c0,1.7,0.1,3.4,0.1,5.1c0.5,0.7,1.1,1.4,1.2,2.3c0.7,7.3,0.7,14.7,0.2,22c-0.1,1.4-0.9,2.3-2.3,2.6
	c-0.3,0.5-0.6,1.1-0.8,1.6c-3.4,4-8.4,5.7-12.4,8.9c-0.8,0.6-1.6,1.1-2.5,1.6l0,0C768.8,420.5,756.8,425.9,746.1,433.4z"
    />
    <path
      class="st2"
      d="M404.8,113.9c14.1-8.3,28.1-16.6,42.3-24.8c2.2-1.2,4.7-1,7.1,0.5c14.1,8.4,28,17,42.3,24.9
	c2.3,1.3,4.9,2.9,6.1,5.6c0.1,1.7-1.3,2.3-2.4,3c-8.2,5.2-16.4,10.4-24.8,15.1c-6.2,3.5-12.2,7.2-18.2,10.9
	c-2.2,1.4-4.4,2.8-7.2,2.7c-4.8,0.6-7.9-2.8-11.4-4.9c-12.5-7.7-25.4-14.8-37.7-23c-1.5-1-3.1-1.8-3.3-3.8c0-1.6,1.1-2.4,2.2-3.2
	l0,0C401.5,116,403.3,115.2,404.8,113.9z"
    />
    <path
      class="st3"
      d="M676.3,281.2c-1.9,1.3-4.2,2-5.9,3.7c-2.2,1.6-4.7,2.7-7.1,4.1c-3.8,2.1-5.3,5.4-5.4,9.5c-0.1,4,0.1,8-0.1,12
	c-0.1,1.6,0.2,3.3-1.7,4.2c-5,0.7-8.8,3.9-13.3,5.9c-1.1,0.5-2.1,1.3-3.2,1.9c-3.5,1.5-6.8,3.3-9.8,5.6c-2.5,1.9-5.5,2.7-8.2,4.1
	l0,0c-6.7,3.3-13.9,4.9-21,6.7l0,0c-2.3-0.2-4.4,0.8-6.6,0.8c-3-0.1-4.9-1.3-5.5-4.4c-1.3-5.9-0.7-11.9-0.6-17.9
	c0.1-2.5,1.7-4,3.8-5.2c5.2-3,10.4-5.8,16-9c-5.2-0.6-10,0.4-14.7-0.1c-1.6-0.2-3.3-0.1-3.8-2.2c0.1-0.9,0.6-1.4,1.4-1.8
	c7.1-0.5,13.5-3,19.6-6.7c4.2-2.6,7.6-6.5,12.2-8.5c9.2-3.9,13.5-10.3,11.4-20.5c-0.4-1.7-0.4-3.8,0.9-5.4c2.5-1.6,4.5-0.4,6.5,1
	c5.4,3.9,11.3,7.2,16.5,11.4c2,1.6,3.8,1.5,5.8,0.2c2.9-1.7,5.7-3.5,8.6-5.1c1.4-0.8,2.9-1.5,4.4,0.1
	C676.3,270.8,676.3,276,676.3,281.2z"
    />
    <path
      class="st4"
      d="M450.2,151c16.6-9.2,32.7-19.1,48.9-29c1.2-0.7,2.4-1.2,3.6-1.9c0.5,0.5,0.9,1,1.3,1.4c0.1,12,0.1,24,0.2,36
	c1.3,1.6,1.5,3.6,1.5,5.6c0.1,4.2-0.1,8.4,0.1,12.6c-0.4,1.7-0.6,3.4,0,5.1c-0.3,5.2-2.7,9.4-7.1,12c-11,6.4-21.5,13.4-32.8,19.4
	c-4.6,2.4-8.5,6-13.8,7.3c-1.7,0.4-3.1,1-4.3-0.7c-0.2-0.4-0.2-0.9-0.2-1.3c1-6.4,0.2-12.9,0.4-19.4c0.4-13.8,0.1-27.6,0.2-41.5
	C448.3,154.7,447.9,152.4,450.2,151z"
    />
    <path
      class="st5"
      d="M135.3,280.4c-0.2-2.1,2.6-2.5,2.7-4.4c7.3-4.1,14.9-8,22-12.5c6.6-4.2,13.7-7.5,20.4-11.5
	c0.3-0.2,0.8-0.2,1-0.5c4-4,8.3-3.7,12.8-1c4.5,3,9.2,5.7,13.8,8.4c5.7,3.4,11.4,7,17.2,10.1c2.9,1.6,5.2,4.4,8.8,4.5
	c4.4,0.8,5.5,1.7,6.1,5.4c-0.4,1.2-1.1,2.2-2.3,2.9c-8,4.5-15.6,9.9-23.7,14.2c-7.3,3.9-14.2,8.3-21.2,12.5
	c-1.2,0.7-2.3,1.6-3.7,1.7c-4.3,0.2-7.8-2.2-10.8-4.4c-7-5-15-8.1-22.2-12.8C149.4,288.5,142.2,284.6,135.3,280.4z"
    />
    <path
      class="st6"
      d="M720.7,396.9c-2.4-0.2-4.1,3.5-6.8,1.3c-1.8-1.5-1.6-3.6-1.6-5.5c0-8.2,0.5-16.5,0.3-24.7
	c-0.3-11.5-0.1-23-0.4-34.5c0-1.8,0-3.5,0.1-5.2c0.1-1.6,0.7-2.9,2.3-3.5c4.7-4.4,10.7-6.8,16.1-10c11.1-6.6,22.2-13.1,33.3-19.6
	c1.3-0.7,2.1-1.7,2.5-3.1c2.3,1.4,2.7,3.6,2.7,6.1c0,14.6,0.1,29.2,0.1,43.8c2.3,3.4,2.3,8.8,0,12c-0.1,3.2-0.2,6.5-0.3,9.7
	c1.5,2.6,0.4,4.4-1.7,6c-7.8,6-16.5,10.6-24.9,15.7c-5.5,3.3-11.4,6-16.6,9.9C724.4,396.4,722.6,397.1,720.7,396.9z"
    />
    <path
      class="st7"
      d="M129.8,460.5c0-10.7,0.1-21.4,0-32.1c0-2.9,0.9-4.8,3.8-5.5c2.3-1.3,4.1,0.2,5.9,1.2
	c10.8,5.9,21.3,12.4,32.1,18.4c17.2,9.6,33.9,20,51.3,29.2c3.6,1.9,6.7,4.3,9.2,7.5c0.2,0.6,0.4,1.2,0.7,1.8c1.2,2,1.4,4.2,1.4,6.5
	c0,18.4,0,36.9,0,55.3c0,2.2,0.4,4.6-1.6,6.3c-2.1,1.4-4.2,1.2-6.4,0.1c-2-1-4-2-5.9-3.3c-2.2-1.5-4.3-3-7-3.3
	c-0.9-0.1-1.6-0.6-2.4-1.1c-14.2-9.7-29.7-17.3-44.4-26.2c-5.4-3.3-10.9-6.5-16.4-9.8c-2.8-1.7-5.5-3.5-9.1-3.9
	c-2.9-0.3-5.1-2.9-7.5-4.7c-3-3.1-4.3-6.9-4.2-11.2C129.7,477.4,129,469,129.8,460.5z"
    />
    <path
      class="st8"
      d="M134.8,496.2c4.4,4,10.6,4.2,15.6,7.3c18,11.2,36.7,21.3,54.9,32.2c6.3,3.8,13.3,6.2,19.2,10.7
	c1.8,1.4,3.9,2.8,6.5,2.4c1.1,1.6,1,1.5-0.3,2.8c-2.5,2.4-5.9,3-8.6,5c-5.3,4-11.8,5.9-16.9,10.3c-0.6,0.4-1,1-1.5,1.5
	c-0.3,0.2-0.6,0.4-0.9,0.6c-4.8,1.8-9,4.6-13.3,7.3c-5.2,3.3-9.7,2-14.5-1.5c-6.1-4.5-13-7.7-19.7-11.3c-4.7-2.5-9.4-4.9-13.7-8
	c-1.4-3.4-4.9-4.1-7.7-5.6c-1.5-0.8-3.1-1.5-4.6-2.1c-2.4-1.2-4.8-2.4-6.8-4.3c-2.7-2.5-6.2-3.6-9.5-4.9
	c-6.6-2.7-11.7-7.7-17.9-10.8c-1.5-0.8-2.6-2.2-2.2-4.2c1.7-3.7,5.7-4.3,8.4-6.7c2-3.3,5.8-4.6,8.7-6.7c6.6-4.7,13.7-8.6,20.6-12.9
	C132,496.3,133.3,495.9,134.8,496.2z"
    />
    <path
      class="st9"
      d="M635.3,258c0.1,5,0.1,10.1,0.3,15.2c0.1,2.1-0.7,3.6-2.1,4.9c-2.4,2.1-4.7,4.3-7.7,5.8
	c-4.6,2.2-8.4,5.7-12.6,8.5c-6.7,4.5-13.5,8.9-22.2,7.7c-2.2-1.5-1.6-3.8-1.7-5.8c-0.5-13.3,0.6-26.6,0.8-39.9
	c2.6-3.4,1.3-8.4,4.7-11.4c2.8,1.9,6,3,8.7,4.7c1.4,0.9,1.5,3.7,3,5.3c0.8,0.9-0.4,2.4-1.5,3.1c-1.6,1-3,2.2-4.7,3
	c-2.6,1.2-4.2,5.7-2.6,7.9c1.4,2,2.9,0,4.1-0.8c1.3-0.8,1.2-2.4,0.7-3.8c-1-2.4,0.1-3.8,2.2-4.7c1-0.5,2-1,2.8-1.8
	c2.9-2.5,4-2.2,5.7,1.9c-2.1-6,2.9-7.1,6.1-9.3c1.6-1.1,2.9-1.7,1.9-4c-0.8-1.9,1.5-3,1.5-5.4c-1.9,2-4.1,2.8-6,4.1
	c-1.4,0.9-2.9,0.7-4-0.8c-1-1.4-0.7-3,0.6-3.6c2.8-1.3,4.8-3.8,6.9-5.3c-2.2-0.1-3.7,3.3-6.7,2.4c-0.7-0.2-1.4-0.4-1.5-0.7
	c0.7,1.1,0.9,2.7,0.3,4.6c-1.1,3.1-0.4,4.1,2.7,4.3c1.3,0.1,2.6,0,3.1,1.5c0.4,1.3-0.3,2.4-1.2,3.4c-1.7,1.9-4,3-5.7,4.8
	c-0.8,0.9-2.3,1.1-3.5,0.4c-1.3-0.9-1.1-2.1-0.5-3.3c0.7-1.5,0.6-2.4-1.3-2.5c-1.3,0-2.2-0.7-2.3-2c-0.2-1.5,0.3-2.7,1.6-3.6
	c3.1-2.3,3.1-2.3,2.9-6.5c0.1,0.9,0.2,1.8-0.2,2.6c-0.3,0.9-0.8,1.6-1.8,1.7c-1.2,0.1-2-0.7-2.2-1.7c-0.5-2.7-1.2-5.4-1-8.1
	c0.1-1.8,1-2.8,2.7-3.1c2.1-0.4,3.8-1.6,6.3-2.9c-3.3,0.7-5.1,2.8-7.6,3.3c-1.2,0.2-2.3,0.6-3.1-0.5c-0.9-1.3-0.3-2.4,0.6-3.4
	c0.8-0.8,1.8-1.4,2.7-2c6.3-4.5,13.3-7.8,19.7-12c1.4-0.9,3-1.4,4.6-0.6c1.7,0.8,1.8,2.2,1.6,3.9c-0.1,1.2-1.4,1.2-2,1.9
	c0.4,0.8,1.6,0.4,2,1.3c0.4,1.2,0.1,2.5,0.1,3.7c-1.2,1.3-2.9,2.7-0.2,4.2c0.2,0.5,0.2,1,0.1,1.6c-2.4,2-2.4,4.5-2.5,7.5
	c-0.1,3.5,2.2,7.1,0.1,10.6c-0.1,0.2-0.2,0.5-0.1,0.7c2.4,5-1.3,9.9-0.2,14.9c0.2,0.9,0.1,2-0.5,2.9c-1.1,1.6-2.3,1.8-3.4,0.1
	c0.4,2.6-1,4.2-3.1,5.5c-1.4,0.9-3.3,1.2-4,3.1c-0.4,3.1-3.4,3.7-5.6,5.6c5.2-3.8,10.7-6.6,15.8-10.1c2.6-1.8,3.5-4,3.5-6.9
	c0-8.9-0.2-17.7,0-26.6c0.3-12,0.2-24.1-0.2-36.1c-0.1-3.1,1-6.1,2-9.1c0.4-1.4,1.3-2.5,3.1-1.9c0,12.2,0.1,24.4,0.1,36.5
	c1,3.8-0.6,7.7,0.9,11.5c1.5,7.4,0.8,14.8,0.5,22.2C636.6,257,635.9,257.5,635.3,258z"
    />
    <path
      class="st10"
      d="M548.1,184.6c-0.4-3.9,1.8-6.3,4.8-8.2c1.2-0.8,2.5-1.6,3.8-2.4c0.2-0.8,0.7-1.2,1.5-1.3
	c4.3-1.4,7.9-4,11.7-6.3c4.6-2.8,9.1-5.6,13.7-8.4c7-4.2,13.5-2.4,20,1.7c8.6,5.5,17.5,10.5,26.1,15.9c2.9,1.8,5.3,4.2,4.9,8.2
	c0.4,2.8-1.8,4.5-3.4,5.5c-11.9,7.8-24.4,14.8-37.1,21.3c-0.7,0.3-1.4,0.5-2.2,0.5c-3.8,0.1-7.1-1.7-10-3.6
	c-9.8-6.3-20.4-11.2-30.4-17.3C549.2,189,547.8,187.3,548.1,184.6z"
    />
    <path
      class="st11"
      d="M635.3,258c0-8,0-15.9-0.1-23.9c0.3-0.3,0.3-0.9,0.8-1c6.6,1.6,11.4,6.5,17.2,9.6c5.6,3,10.9,6.5,16.4,9.6
	c5.1,3,8,7,6.7,13.2c-4.3,0.7-7.4,3.9-11,6c-3.3,1.9-5.9,2.7-9.4-0.1C649.4,266.4,642.7,261.7,635.3,258z"
    />
    <path
      class="st12"
      d="M775.4,522c3.6,3.1,7.9,5.2,12.1,7.5c5.4,3,8.7,6.9,7.6,13.5c-0.2,1.5-0.1,3-0.1,4.5c-3.6-2.7-6.3-0.4-9,1.6
	c-2.2,1.5-4.6,2.5-6.9,3.8c-3.2,1.7-6.4,0.2-8.8-1.5c-4.9-3.6-10.4-6.4-14.9-10.6c-0.5-0.4-0.8-1-1-1.6c-0.6-7.9-0.3-15.8-0.2-23.7
	c0-1.6-0.1-3.6,2.3-4C762.8,515.2,768.8,519.1,775.4,522z"
    />
    <path
      class="st13"
      d="M591.7,210.1c13.5-7,26.5-14.9,39.6-22.6c1.4-0.8,2.7-1.8,3.1-3.5c1,0.4,0.8,1.3,0.8,2.1
	c-1,0.6-1.5,1.4-1.8,2.6c-2.2,8.1-2,16.3-1.9,24.5c0.1,10.7,0,21.4,0,32.1c0,5.6,0,11.2,0,16.8c0,1.2,0.1,2.6-1,3.5
	c-7.2,5.8-15.6,9.5-23.7,13.9c2.5-3.6,8.1-4.3,8.9-9.4c2.6,0,4.3-1.7,6.3-3c1.4-0.9,2.5-2.3,4.2-3c1.7-0.6,2.1-2.5,1.9-4.1
	c-0.5-5,0.7-10,0.5-15.1c-0.2-4.7-0.1-9.5-0.3-14.3c-0.1-1.8,0.3-3.5,1.6-4.8c0-0.5,0-1,0-1.5c-1-1.2-1.3-2.5,0-3.7c0-1.5,0-3,0-4.5
	c-0.7-0.7-0.7-1.5,0-2.2c0.2-1.5-0.3-2.7-1.4-3.7c-1.3-2.1,0-4.4-0.7-6.6c-0.6-1.9-1.4-2.5-3.2-1.5c-8.3,4.4-16.2,9.6-24.2,14.5
	c-2.4,1.5-3.2,3.4-3.3,6.2c-0.2,5.1,0.7,10.2,0.7,15.2c0,2-0.2,4-2.4,5.1c-1.1,3.5-2,7.2-3.8,10.5c-2.1-0.1-2.3-1.7-2.3-3.2
	c-0.3-12.2-0.3-24.5,0-36.7C589.4,211.9,590.1,210.7,591.7,210.1z"
    />
    <path
      class="st14"
      d="M231,479.4c-6-5.3-13.4-8.3-20.1-12.2c-24.6-14.3-49.3-28.4-73.9-42.6c-1.1-0.6-2.2-1.1-3.3-1.7
	c0-0.5,0-0.9,0-1.3c3.5-2.7,6,0.8,9,1.3l0.1,0c6,4.3,12.6,7.5,19,11.2c17.4,10.1,34.9,20.1,52.3,30.2c5.5,3.2,10.9,6.5,16.3,9.7
	C231.2,475.7,235.3,477,231,479.4z"
    />
    <path
      class="st15"
      d="M716.1,463.5c0.7,1.2,1.7,1.6,3,1.7c8.7,5.9,17.9,10.8,27.1,16c2.6,1.5,5,3.1,7.3,4.9c1.3,0.9,1.8,2.3,1,3.5
	c-1.1,1.8-3.2,0.6-4.8,0.9c-11-7-22.3-13.7-33.7-20.1c-13.7-7.8-27.3-15.7-40.8-23.7c-1.8-1-3.9-1.8-4.1-4.4c0.9-4,3.4-2.8,5.6-1.5
	c6.4,3.7,12.7,7.5,19.1,11.2c0.7,0.4,1.6,0.7,2.4,1l0,0c3.2,2.5,6.6,4.7,10.5,6l0,0c0.6,1.4,2.1,1.5,3.2,2.1
	C713.7,461.1,715.1,462,716.1,463.5z"
    />
    <path
      class="st16"
      d="M769.4,354c0-4,0-8,0-12c3.8-0.4,5.5,3.1,8.3,4.5c4,2,7.7,4.6,11.7,6.6c4.9,2.5,6.8,6.2,6.3,11.5
	c-0.1,1.2-0.1,2.5-0.1,3.7c-3.3,1-5.2-0.3-8-2.5C782.1,361.2,776.8,355.8,769.4,354z"
    />
    <path
      class="st17"
      d="M749.9,489.8c0.8-0.2,1.1-1.6,2.2-1.2c4.7,1.7,1-1.6,1.5-2.4c3,2.9,2.7,6.8,3,10.5c0.4,4.9,0.1,9.9,0.1,14.8
	c-0.9,1.1-0.7,2.3-0.7,3.6c0,8.3,0.1,16.7,0.1,25c2.6,4.8,1.8,9.9,0.8,14.8c-0.7,3.3-3.6,4.1-6.5,2.5c-1.1-2.1-0.8-4.4-0.8-6.6
	c-0.1-16.2,0-32.4,0-48.5c0-3,0.1-6-0.5-8.9C748.9,492,748.8,490.7,749.9,489.8z"
    />
    <path
      class="st18"
      d="M794.9,400.4c1-1.6,0.8-3.3,0.8-5c0-7.3,0-14.6,0.1-21.9c2.8,0.8,3.2,2.5,3.5,5.5c0.4,3.7,2.8,7.3,4.2,10.9
	c0.9,2.3,0.7,4.7-0.2,6.9C802.2,402.2,799.1,403.6,794.9,400.4z"
    />
    <path
      class="st19"
      d="M134.8,496.2c-11.7,6-22,14.3-33.5,20.6c-0.8-1.6,0.3-2.3,1.3-3c5.7-4.2,11.6-8.1,17.8-11.6
	c2.8-1.6,5.5-3.3,8.3-4.9c1.8-1,2.5-2,1.4-4.2c-1-1.8-1.2-4-1.2-6.2c0-7.6,0-15.2,0.1-22.8c0-1.2-0.1-2.5,0.7-3.6
	c0,8.5,0.1,17,0.2,25.5C130,490.2,130.7,494,134.8,496.2z"
    />
    <path
      class="st20"
      d="M93,523.5c0.5,1.4,1.1,2.6,2.6,3.5c11.5,6.5,22.9,13.1,34.3,19.7c0.4,0.5,0.5,1,0.2,1.6c-1.5,1.4-3,0.8-4.4,0
	c-7.7-4.4-15.2-9.3-23.1-13.3c-2-1-3.4-2.8-5-4.4c-1.7-1.7-4-2.7-5.2-4.9C92.4,524.9,92.3,524.1,93,523.5z"
    />
    <path
      class="st13"
      d="M636.1,233.2c-0.3,0.3-0.6,0.6-0.8,0.9c0-3.8,0-7.7,0-11.5C635.6,226.1,635.8,229.6,636.1,233.2z"
    />
    <path
      class="st21"
      d="M404.8,113.9c-1,2.1-2.7,3.3-5.1,3.2c0-0.5,0-0.9,0.1-1.4C401.9,116,402.8,113.4,404.8,113.9z"
    />
    <path
      class="st22"
      d="M761.2,288c-2-0.1-3.6-1-4.6-2.9C758.6,285.4,760.2,286.3,761.2,288z"
    />
    <path
      class="st23"
      d="M297.6,418.6c7-5.4,15-9.2,22.5-13.7c16.3-9.6,32.8-19.1,49.3-28.6c0.7-0.4,1.6-0.5,2.5-0.8
	c2.6-0.9,3.2,1.6,4.6,2.7c1,2.5-0.2,4.4-2,5.9c-6.8,5.9-13.1,12.3-20,18c-4.6,3.9-4.9,7.7-0.1,11.4c3.2,2.5,5.5,6.2,9.6,7.7
	c4.1,3,6.6,3,11.1-0.4c7.5-5.6,14.8-11.4,22.3-16.9c1.4-1,2.7-2.2,4.6-2.1c2.5,0.7,4.3,2.6,6,4.2c4.4,4.4,9.3,8.1,13.3,12.8
	c0.8,0.7,1.6,1.3,2.4,1.8c1.1,1.1,2.1,2.2,3.5,2.8c8.7,8.2,17.4,16.3,26.4,24.2c3.8,3.4,7.6,6.8,11.2,10.5c1.5,1.5,3.4,2.4,5.5,3
	c2.2,1.2,4,0.9,6.3-0.4c8.4-4.9,17.4-8.7,26.2-12.8c6-2.8,11.7-5.5,14.5-12c2.5-3.8,3-8.2,3-12.5c0.1-15.2,0-30.4,0.1-45.6
	c0-2-0.6-4.4,1.8-5.7c9.3,4.6,17.8,10.4,26.8,15.4c11.4,6.4,22.7,13.1,34,19.7c5.1,3,10.2,6.1,15.3,9.2c2,1.3,3.6,3.1,4,5.6
	c-1.1,2.7-3.2,4.3-5.7,5.7c-32.9,18.9-66,37.7-98.9,56.6c-14.6,8.4-29.2,16.7-43.7,25.1c-1.6,0.9-3.2,1.9-5.2,1.6
	c-4,0.5-6.7-2.4-9.8-4c-9.6-5-19-10.5-28.3-16c-16.1-9.5-32.2-19-48.4-28.2c-15.5-8.8-30.6-18.3-46.2-26.8
	c-5.3-2.9-10.7-5.9-15.8-9.1C296,424.6,295.6,423,297.6,418.6z"
    />
    <path
      class="st24"
      d="M606.1,450c6.7,2.9,12.6,7.3,19.1,10.7c2.5,1.3,5.2,2.2,7,4.7c1.6,2.2,2.6,4.4,1.6,7.2
	c-9.1,5.8-18.7,10.9-28,16.5c-11.3,6.8-22.8,13.4-34.2,19.9c-10.6,6-21.3,12-31.9,18c-18.1,10.4-36.1,20.7-54.1,31.2
	c-10.6,6.2-21.2,12.1-31.7,18.4c-2.8,1.6-3.8,3.6-3.7,6.7c0.1,3.2,0.1,6.5,0,9.8c0,1.5,0.1,3-1.3,4.2c-3.7,1.3-6.2-1.6-8.8-3.1
	c-8-4.5-16.3-8.4-23.9-13.5c-7-4.6-14.6-8.2-21.7-12.8c-4.4-2.9-9-5.7-13.9-7.6c-0.9-0.3-1.5-0.9-2-1.5c-7-2.9-13.2-7.3-19.7-11.1
	c-7.8-4.5-15.7-9-23.5-13.6c-4.9-2.9-9.8-5.8-14.8-8.4c-9-4.8-17.4-10.6-26.3-15.4c-9.1-4.8-17.7-10.5-26.5-15.8
	c-1.6-1-2.4-2.4-2.4-4.4c0-7.1,0.1-14.2,0-21.3c-0.1-2.9,1.9-4.3,3.8-5.5c7.6-4.7,15.1-9.6,23.3-13.2c0.8,0.8,1.4,1.6,1.2,2.8
	c-0.5,2.7,0.8,5.2,1,7.7c0.1,1,0.2,2,0.1,3c-0.4,3.5,1.2,5.7,4.2,7.2c9.2,5,18.2,10.3,27.2,15.6c11.6,6.8,23,13.9,34.6,20.7
	c7,4.1,13.8,8.4,20.9,12.3c9.8,5.3,19.3,10.9,28.7,16.8c7.2,4.5,15,7.8,22,12.6c4.8,3.3,10.4,5.3,14.8,9.3c1.7,1.5,3.3,0,5-0.2
	c1.9-0.5,3.6-1.8,5.6-1.9c4.1-0.5,7.5-2.7,10.9-4.6c8.5-4.7,16.8-9.8,25.3-14.7c11.5-6.7,23.5-12.4,34.7-19.6c1.5-0.9,2.8-2,4.2-3.1
	c0.3-0.2,0.6-0.4,0.9-0.5c5.8-2,10.5-5.9,16.1-8.2c1.4-0.6,2.7-1.4,3.4-2.8c0.2-0.3,0.5-0.5,0.8-0.6c4.3-0.3,7.4-3.4,10.7-5.4
	c5.9-3.6,12.3-6.5,18.1-10.6c4.1-2.9,9-5,13.7-7.3c4.9-2.4,9.6-5.2,14.2-8.3c3.2-2.1,3.2-3.9,0.1-6c-2.5-1.7-3.4-4.6-5.4-6.5
	c-0.3-0.8-0.2-1.8-0.7-2.6c-0.2-1.5-0.6-2.9,0.2-4.4C605.5,450.3,605.8,450.1,606.1,450z"
    />
    <path
      class="st25"
      d="M706.5,613.5c-12.5-7.6-25.1-14.9-37.8-22c-9-5-17.6-10.9-26.6-15.8c-8.3-4.5-9.8-11.8-9.7-20.1
	c0.1-6.5,0.3-13,0.6-19.4c0.1-2.8,1.8-4.6,4.1-5.9c7.3-4.3,14.7-8.4,21.9-12.8c3.3-2,7.5-3,9.2-7.2c0.6-0.7,1.5-0.5,2,0
	c4.8,4.8,11.2,7.2,16.7,11c1.3,1.9,0.4,3.2-1.1,4.4c-3.8,3-3.8,3.3,0.5,5.6c0.4,0.2,0.8,0.6,1.1,1c0.5,2.1-1.1,2.9-2.4,3.7
	c-2,1.1-3.9,2.2-5.8,3.4c-2.9,1.8-3,3.1-0.3,5.5c1,0.9,2.3,1.5,3.3,2.4c2.7,2.8,5.9,4.8,9.4,6.4c4.8,2.3,8.9,5.7,13.7,8
	c1.3,0.6,2.7,1.3,4.2,1.5c4.9,2,8-1.9,11.4-4c4-2.6,7.6-3.4,12.1-1.2c3.2,1.6,6.4,3.4,9.8,4.6c1.6,0.5,2.9,1.5,4.1,2.6
	c2.3,2.2,2.2,5.3-0.5,7.1c-6.2,4.1-12.8,7.7-19.2,11.5c-1.2,0.7-2.4,1.4-3.5,2.1c-9.8,5.8-8.3,2.7-8.4,15.1c0,3.5,0.1,7-0.1,10.5
	c-0.1,1.6,0.1,3.3-1.5,4.4C710.5,616.7,708.8,614,706.5,613.5z"
    />
    <path
      class="st26"
      d="M267.8,237.8c-0.3-6.1-4.2-10.9-6.7-16c-3.5-7.2-7.6-14-12-20.7c-1-0.7-1.8-1.4-0.1-2.3c0.2,0,0.5,0,0.7,0
	c7.3-2.9,14.8-1.5,22.3-1.4c2.2,1.2,3.7,3.2,4.9,5.3c10.4,17.9,20.8,35.8,31,53.8c1.5,2.7,2.1,2.7,3.7,0.1
	c10.3-17.5,20.3-35.2,30.5-52.8c1.3-2.2,2.8-4.1,4.8-5.7c6.5-1.7,13.1-1.1,19.6-0.4c0.8,0.1,1.7,0.5,2.5,0.5
	c2.5,0.8,1.6,2.2,0.7,3.8c-4.9,8.5-9.9,17-14.8,25.6c-1.7,2.9-3.2,5.9-4.7,8.9c0.8,3.2-0.8,5.9-2,8.5c-3.5,7.3-6.9,14.5-10.1,21.9
	c-4.6,9.5-8.1,19.5-12.1,29.3c-1.2,3-3.8,5.2-5.5,7.9c-6.7,5.8-13.3,5.6-19.6-0.4c-6.7-6.3-11.1-14-14.5-22.4
	c-2.3-5.9-5.2-11.6-8.1-17.2c-1.9-3.6-3.3-7.5-4.7-11.4c-1.1-3.1-2.7-5.9-4.1-8.8C268.7,241.9,267.7,240,267.8,237.8z"
    />
    <path
      class="st27"
      d="M697.7,390.6c-0.1,0.2-0.3,0.6-0.2,0.7c7.9,5.7,15.5,11,26.4,8.8c7.6-1.6,15.2-3,22.8-4.6
	c-2.3,3-5.6,4.5-8.5,6.3c-7.1,4.1-14.3,8.1-21.5,12c-2.6,1.4-3.5,3.2-3.5,6c0.1,7,0,14,0.1,20.9c0.1,5.5,0.9,6.3,6.7,6.4
	c-2.2,2.1-4.8,1.5-7.4,1.1c-7-1-12.6-5.2-18.8-8.1c-12.4-5.7-21.9-15.5-32.9-23.1c-5.5-3.8-11.4-7.1-17.2-10.6
	c-3.4-2.1-6.7-4.3-10.1-6.4c-4.8-3-4.4-8.3-4.8-12.7c-0.8-8.5-0.2-17.2,0.4-25.8c0.2-3,1.9-5,4.1-6.4c6.8-4.3,13.8-8.5,20.7-12.6
	c0.6-0.4,1.4-0.4,2.2-0.5c1.9,0.9,1.6,2.6,1.7,4.2c0.1,4,0,8,0.1,11.9c0.1,3.9,1.5,7,5.2,8.8c2.5,1.2,4.5,3,6.7,4.7
	c8.2,5.2,16.3,10.6,25.1,14.6C696.6,387.2,698.7,388,697.7,390.6z"
    />
    <path
      class="st28"
      d="M192.9,427.7c-2.3,1.9-4.5,1.2-7,0.2c-7.5-2.9-13.9-7.8-21-11.4c-10.6-5.4-19.7-12.9-29.2-20
	c-7.7-5.7-15.2-11.6-23.8-16.1c-5.7-3-9.3-7-8.6-14.2c0.6-6.3,0.5-12.7,0-19.1c-0.4-5.3,2-8.8,6.1-11c7.1-3.8,13.9-8.2,20.9-12.2
	c1.1-1.5,2.4-2.8,2.9-4.7c0.2-1,0.8-1.9,2.1-1.3c1.5,1.3,1.2,3.2,1.2,4.9c0.1,5.4,0.3,10.7,0,16.1c-0.4,5.7,2.5,9.2,6.9,11.8
	c8,4.6,16.3,8.6,24,13.6c6.8,4.4,13.8,8.4,20.5,12.8c4.8,4.7,10.7,5.6,16.9,4.9c3.3-0.4,6.7-1,9.7-2.5c1.9-1,4.1-1.3,5.3,0.9
	c1.3,2.4-0.3,3.9-2.3,5.1c-8.3,5.2-17.2,9.6-25.5,14.9c-2.2,1.4-3.1,3-3.1,5.5c0,3.6,0,7.2,0,10.9
	C189.2,420.8,188.6,425,192.9,427.7z"
    />
    <path
      class="st29"
      d="M569.1,702c-4.3-1.4-7.8-4.4-11.6-6.7c-10.2-6-20.3-12-30.6-17.9c-3.3-1.9-6.8-3.5-9.9-6c-2.7-2.2-4-4.7-4-8
	c0-9,0-18,0-27c0-3.1,1.5-5.4,3.8-7.4c5.6-4.8,12.4-7.4,18.6-11.1c2.8-1.6,5.2-3.8,8.4-4.8c1.3-0.8,2.6-0.6,3.2,0.7
	c1.7,3.4,5.2,5.3,6.8,8.8c1.2,2.5,3.8,1.9,5.8,2.4c0.5-0.1,0.6,1.8,1.3,0.5c0.6-1.2,0.5-2.6,0.8-3.9c0.3-1.4,0.6-3,2.8-2.2
	c0.7,0.8,0.6,1.8,0.7,2.8c0.1,0.6,0.3,1.2,0.4,1.7c0.2,2.2-0.5,4.4-0.8,6.6c-1,8.7,1.3,16.4,6.4,23.4c0.2,1.8,1.2,3.5,2.8,4.2
	c6.8,2.7,11.8,8.8,19.5,9.8c1,0.1,1.5,1.2,2.4,1.7c4.1,2.5,8.6,3.3,13.2,3.6c2.1,0.2,4.2,0.2,4.9,2.5c0.8,2.6-1,3.9-2.8,5.1
	c-4.8,3.2-9.9,5.8-14.8,8.8c-3,1.8-4.4,3.8-3.9,7.6c0.6,4.3,0.2,8.7,0.1,13.1c0,1.2,0,2.5-1,3.5c-1.6,1.4-2.7,0-3.9-0.7
	C581.5,709.6,575.7,705.1,569.1,702z"
    />
    <path
      class="st30"
      d="M448.4,270.2c-3.8,0.4-6.3-2.2-9.1-4c-11.4-7.5-23.8-13.5-34.5-22c-6.2-4.9-12.5-9.9-18.9-14.6
	c-3.3-2.4-6.3-5.2-9.6-7.6c-2.6-1.9-2.5-5-2.5-7.5c-0.3-11.7,0-23.4-0.3-35.1c-0.2-5.7,2-9.6,6.8-12.3c5.2-3,10.4-5.9,15.6-8.8
	c2.2,0.8,1.8,2.8,1.8,4.5c0.1,6.1,0.1,12.2,0,18.3c0,3,1,5,3.6,6.8c4,2.8,8.2,5.2,12.4,7.6c2.8,1.6,4.9,3.8,6.6,6.4
	c7.5,4.9,15,9.8,22.8,14.3c1.3,0.7,2.4,1.8,3.9,2c1.2,1.2,2.4,1.8,4.3,1.3c2.4-0.6,4.8-1.3,7.2-1.7c9.9-0.9,18.8-4.3,27-9.8
	c3.9-2.6,8.5-3.6,12.4-6c0.7-0.4,1.7-0.6,2.5-0.5c1.2,0,2.2,0.4,2.8,1.6c0.5,1.2-0.1,2.2-1,2.9c-2.6,1.9-5.3,3.8-8,5.5
	c-8.7,5.4-17.6,10.4-26.2,15.9c-4.6,3-9.5,5.5-14.2,8.4c-2.5,1.5-3.7,3.4-3.7,6.3c0.1,7.7,0.1,15.5,0,23.2
	C450.1,267,450.6,269.1,448.4,270.2z"
    />
    <path
      class="st31"
      d="M448.5,597c0-5,0.2-10,0-15c-0.1-3,0.9-4.8,3.5-6.3c17.3-9.9,34.5-20,51.8-29.9c22.1-12.7,44.2-25.5,66.5-37.9
	c19.8-11,39.2-22.8,58.7-34.2c1.5-0.8,2.9-2.2,4.8-1c0.2,1,0.3,2,0.5,3c-0.6,5.3,1.9,10.3,1.8,15.7c0,1.9,0.2,3.5-1.7,4.4l0,0
	c-9.5,5.2-18.4,11.3-28,16.3c-7.6,4-15,8.4-22.4,12.7c-4.2,2.5-8.4,5-12.7,7.4c-7.3,4.2-14.7,8.2-21.9,12.5
	c-11.4,7-23.3,12.9-34.6,20c-4.3,2.7-8.7,5.1-13.4,7.2c-3.4,1.5-5.9,4.4-9.4,6c-4,1.7-7.9,4-11.6,6.4c-5.3,3.4-10.9,6.3-16.1,9.7
	l0,0c-3.9,2-7.8,4-12.2,4.9C450.4,599.2,448.4,600,448.5,597z"
    />
    <path
      class="st32"
      d="M97.5,530.6c10.1,4.5,19.3,10.7,28.9,16.1c1.1,0.6,2.2,1.1,3.3,1.6c4.7,1,8.6,3.5,12.3,6.5
	c3.5,3.4,8,5.3,12.2,7.6c7.9,4.2,15.5,9,23.1,13.6c2.6,1.6,5.3,3.2,8.6,2.1c0.7,0,1.3,0.3,1.9,0.6c2.1,0.6,3.7-0.6,5.2-1.5
	c9.4-6,18.9-12,28.8-17.2c5-2.6,9.6-6.9,16-6.3c2.3,0.2,4.7-0.1,5.7,2.5c0.9,2.6-1.2,4-2.9,5.1c-8,5.1-15.9,10.2-24,15.2
	c-8.4,5.2-16.7,10.4-25,15.7c-1.8,1.1-2.5,2.7-2.5,4.7c0,6.9-0.1,13.8,0,20.6c0.1,3.6,1.4,4.2,4.8,2.5c1.5-0.8,3-2.8,4.9-0.7
	c0.4,1.1-0.3,1.8-1,2.4c-5,2.7-9.5,3.6-14.9,0.1c-7.8-5-15.6-10-23.3-15c-6.1-4-12.1-8.2-18.5-11.8c-3.5-1.9-6.8-4-10.1-6.2
	c-7.2-4.7-14.4-9.5-21.9-13.9c-2.3-1.4-4.4-3.2-6.6-4.8c-2.1-1.6-2.7-3.7-2.7-6.3c0.1-8.6-1.2-17.2-0.2-25.7c0.2-1.3,0.2-2.8-0.6-4
	c-0.5-0.2-0.5-0.4-0.1-0.8C98.5,532.3,98,531.5,97.5,530.6z"
    />
    <path
      class="st33"
      d="M438.8,796.4c-10.6-7.2-21.2-14.4-31.9-21.5c-8.3-5.5-16.7-10.7-25.1-16.1c-5.4-3.5-9-7.7-8.4-14.9
	c0.6-6.9,0.4-14,0-21c-0.4-6.1,2.9-9.3,7.5-12.2c6.5-4,13.5-7.1,19.4-11.8c3,0.9,3.4,3.2,2.6,5.5c-0.8,2.3,0,3.5,1.7,4.6
	c0.6,0.4,1.3,0.8,1.8,1.3c1.4,2-0.4,3-1.2,4.4c-1.3,1.5-3,2.7-2.9,5c-0.5,1.3,0.6,2.1,1.2,3c0.1,0.3,0.2,0.7,0.2,1
	c-0.7,2.8,1.7,3.7,3.3,4.8c7.5,5.5,16,9.6,23.8,14.6c5.6,3.6,11.7,6.6,16.6,11.2c0.7,0.8,1.6,1.4,2.4,2.1c2.1,2.2,4.3,0.6,5.8-0.4
	c5.9-3.9,12.7-4.7,19.3-6.4c4-1,8-1.8,11.7-3.8c1.8-1,4.1-1.1,5.2,1.2c1,2.2-0.6,3.3-2.4,4.3c-11.5,7.1-23.1,13.8-34.8,20.4
	c-2.5,1.4-3.6,3.2-3.5,6c0.1,6.6-0.1,13.2,0.1,19.9c0,1.1,0.2,2.3-0.7,3.2c-1,0.8-1.8,0.2-2.6-0.3
	C444.9,799,441.8,797.9,438.8,796.4z"
    />
    <path
      class="st34"
      d="M306.8,713.2c-13.9-8.3-27.8-16.5-41.7-24.8c-8.2-4.9-16.6-9.5-24.4-15.1c-3.3-2.4-5.1-5.2-5.1-9.3
	c0.1-9.7,0.2-19.5-0.1-29.2c-0.2-5.7,4-7.8,7.6-10.1c7-4.5,14.4-8.4,21.6-12.6c2.1,0.9,1.8,2.8,1.8,4.5c-0.1,4.3,0.5,8.6,1.5,12.8
	c0,5.8,4.1,9.7,6.6,14.3c0.5,1,1.5,1.7,2,2.7c0.2,2.2,1.8,3.8,2.7,5.8c0.1,0.7-0.5,1.6,0,2.2c4,4.6,8,9.3,14.5,10.5
	c0.6,0.2,1,0.5,1.4,1c4,2.9,8.6,4.4,13.4,4.9c1.8,0.2,3.3,1,5,1.4c4.8,1.1,9.6,1.3,14.5,1c1.1-0.1,2.2-0.1,3.3,0.3
	c3.2,1.2,3.8,4.1,1.3,6.4c-2.7,2.5-5.9,4.2-9.2,5.7c-9.2,4.4-9.8,5.9-8.7,16.3c0.5,4.3,1.5,8.9-1,13.1
	C311.2,715.5,308.9,714.8,306.8,713.2z"
    />
    <path
      class="st35"
      d="M589.5,300.8c1.3,1.1,2.8,0.9,4.3,0.8c5.3-0.4,10.6,0.5,15.9-0.9c0.8-0.2,1.8-0.6,2.3,0.5
	c0.6,1.3-0.7,1.7-1.4,2.2c-5.5,3.9-11.5,6.9-17.4,10.1c-2.7,1.4-3.8,3.3-3.6,6.3c0.3,5-0.1,9.9,0.6,14.9c0.5,3.7,2.4,4.5,5.6,4.3
	c1.7-0.1,3.4-1.2,5.1,0c-4.2,1.6-8,1.9-12.4-0.3c-10.5-5-19.7-12.2-29.6-18.1c-7.9-4.8-15.9-9.2-23.7-14c-5-3.1-10.1-6.3-14.9-9.7
	c-0.9-0.6-1.8-1.5-2.8-1.7c-4.7-0.9-6-3.5-5.8-8.2c0.4-8.2,0.3-16.5,0-24.7c-0.2-4.9,1.8-8.2,5.8-10.5c10.1-6,20.2-11.9,30.3-17.8
	c2.1,0.1,1.6,1.6,1.6,2.9c-0.1,9.6,0,19.2,0,28.8c0,3.8,0.4,7.9,3.7,10.5c2,1.6,4.1,3.1,6.3,4.5c3.4,2.2,7.2,4,10.7,6.1
	c5.9,3.5,12.1,6.4,17.6,10.6C588.5,298.4,589.4,299.3,589.5,300.8z"
    />
    <path
      class="st36"
      d="M192.9,427.7c-5.3-0.6-5.3-0.6-5.3-6c0-5.8,0-11.5,0-17.2c0-2.2,0.7-3.8,2.7-4.9c9.1-5.2,18.1-10.5,27.2-15.7
	c1.1-0.6,2.6-1.3,1.9-2.7c-0.7-1.4-2.2-0.8-3.3-0.3c-8.5,4.1-17,4.2-25.5-0.1c-1.3-0.6-2.2-1.6-3-2.7c0.2-0.6,0.7-0.9,1.3-1
	c5.1,1.5,10.2,1.8,15.2-0.3c8.4-3.5,16.4-7.7,24.3-12.2c1.7-1,3.5-2,5.2-3c6.4-3.8,8.3-9.5,7.2-16.5c-0.2-0.9-0.1-1.7,0.5-2.5
	c1.9-1.5,3.7-0.9,5.5,0.3c4,2.5,7.8,5.2,11.6,8c3.4,2.5,6.5,3.1,10.1,0.5c2-1.4,4.1-2.8,6.2-4c2.3-1.4,3.4-0.9,3.9,1.6
	c0.9,2.9-0.8,6,0.5,8.9c-0.9,3.7,1.5,6.7,2.4,9.8c1.7,5.6,1.5,9.4-3,13.3c-2.9,2.5-5.4,5.4-8.6,7.6c-4.7,1.9-8.8,4.9-12.7,8
	c-2,1.6-4.3,2.3-6.3,3.7c-6.4,3.1-12.7,6.6-18.3,10.9c-3.8,2.9-8.5,4.2-12,7.5c-0.2,0-0.5,0.1-0.8,0.1c-3.6,0.7-5.8,4.4-9.6,4.5
	l-0.1,0c-4.8,2-10,3-15,4.4C194.3,427.5,193.6,427.6,192.9,427.7z"
    />
    <path
      class="st27"
      d="M267.8,237.8c3.7,3.5,5,8.3,6.7,12.6c2.6,6.5,5.6,12.7,8.4,19c2.4,5.5,5.1,10.8,7.4,16.3
	c2.7,6.8,7.3,12.5,12.6,17.5c4.9,4.6,9.6,4.5,16.5-0.2c0.7-0.5,1.5-0.8,2.4-1.1c2.2-0.9,4-0.3,5.2,1.6c1.2,2,0.2,3.7-1.4,4.9
	c-2.7,2-5.4,4.1-8.5,5.4c-5,2.1-7.6,5.4-7.3,11.3c0.2,3.4,1.8,6.9,0.2,10.3c-0.6,0.4-1.2,0.3-1.7-0.1v0c-1-1.1-2.5-1.5-3.7-2.3h0
	c-1.8-0.9-3.2-2.4-5.2-3c-7.2-3.1-13.3-8.3-20.1-12c-9.7-5.2-18.8-11.6-29.3-15.4c-1.6-0.6-3-1.7-4.6-2.5c-1.8-0.9-2.8-0.6-3,1.6
	c-0.1,1.7,0.2,3.5-0.6,5.1c-2.2-1.1-1.8-3.1-1.8-4.9c-0.2-7.7,0.2-15.4-0.6-23.1c-0.3-3.1-4.5-2.5-5.2-5.3c0-2.4,0.4-4.8,0-7.1
	c-1.1-6.6,2.3-10.4,7.6-13.5c8.4-4.9,16.8-10,25.2-15C267,237.8,267.5,237.8,267.8,237.8z"
    />
    <path
      class="st37"
      d="M570,655c-7.6-9.1-8.1-19.7-6-30.7c2.5-5.1,6-5.9,12.5-2.9c1.2,0.6,2.4,1.4,3.6,2c5.2,3,10.7,0,11.6-5.9
	c0.8-4.8,0.1-9.4-0.3-14.1c-1-4-2.6-7.6-6.3-10c-6-3.9-12.4-7.2-18.6-10.8c-2.5-3-6.5-2.2-9.5-4.2c-1-0.7-2-1.3-3.2-1.6
	c-1.1-0.3-2-1-1.1-2.4c1.4-0.4,2.5-1.4,3.8-2c4-1.7,7.5-4.1,7.6-9.2c0-1.2,0.9-2,1.9-2.5c5.7-4.4,11.4-4,17.2-0.2
	c4.2,2.8,8.8,5.1,12.9,8c0.8,1,1.3,2.2,2.6,3c2.6,1.6,4.7,3.7,4.8,7.1c0,1.2,1.2,1.9,1.6,2.9c0.6,2,1.1,4.1,1.6,6.1
	c0.2,9,0.3,18-0.1,27c0.6,2.2,1.5,2.1,2.6,0.3c1.8-2.9,1-6,0.3-9c0-2.7-0.4-5.6,3-6.7c5.9,1.2,9.3,5.9,13.5,9.5
	c2.2,2,2.9,5.2,5.1,7.2c1,1.5,0.8,3.5,1.7,5c1.5,9.1,2.8,18.2-4.7,25.7c-1.5,2-3.6,3-6,3.7c-3.8,1-7.1-0.5-10.3-1.9
	c-4-1.7-7.7-4-11.2-6.5c-2.7-1.9-5.8-3.1-8.6-5c-1.4-0.9-0.3,1-0.9,1.1c-0.6,0.6-1.3,0.9-0.1,1.9c3.1,2.7,6.5,4,10.6,3.5
	c2.2,0.6,3.5,2.2,3.9,4.2c1.4,7,3.1,14.2-1.7,20.8c-0.5,0.7-1.2,1.2-2.1,1.3c-3.8,1-7.1-0.6-10.3-2.1
	C583.8,664.5,576.6,660.3,570,655z"
    />
    <path
      class="st38"
      d="M198.8,619.5c-2.5,0.1-3.8,2.5-6.1,3c-4,0.9-5.1,0.3-5.1-3.8c0-7.7,0-15.5,0-23.2c0-1.9,0.5-3.3,2.2-4.4
	c8.6-5.3,17.1-10.7,25.6-16c8.3-5.2,16.6-10.4,24.9-15.7c1-0.7,2.9-1.3,2.3-3c-0.7-1.7-2.4-1.2-3.9-1.2c-8.3,0.1-14.3,5.6-21.1,9.1
	c-10,5-18.8,12-29.2,16.4c-0.7,0.3-1.3,0.5-2.4,0c0.7-0.5,1.2-0.9,1.7-1.2c0.4-0.9,0.4-2,0.9-2.9c0.2-0.3,0.5-0.5,0.8-0.6
	c4.7-2.3,9.3-4.8,13.3-8.2c0.2-0.1,0.4-0.2,0.6-0.2c1.7-0.6,3.1-1.6,4.3-2.8c7.1-3.6,13.7-7.9,20.7-11.7c3.5-1.9,6.5-4.7,6.4-9.6
	c-0.1-3.8-1.1-7.7,1.1-11.3c1.8-1.4,3.6-0.8,5.2,0.3c4.8,3.3,9.8,6.3,14.4,9.9c2.2,1.7,4.6,2.1,7.3,0.7c3.7-1.9,7.1-4.1,10.6-6.2
	c1.3-0.8,2.6-1.6,4.1-0.6c1.1,5.4,0.6,10.9,0.9,16.4c0.8,2.2,1.8,4.3,2.4,6.5c0.7,2.9-0.4,5.3-2.3,7.1c-4.4,4.1-9.4,7.6-14.4,10.9
	c-4.1,2.7-8.2,5.5-11.5,9c-2.4,2.5-5.7,2.8-8.4,4.6c-7.5,5-15,10-22.5,14.9c-6.5,4.2-12.6,9.1-19.3,13
	C201.2,619.4,200.1,619.7,198.8,619.5z"
    />
    <path
      class="st39"
      d="M713.1,615c0-7.2,0.1-14.5,0.1-21.7c0-2.9,0.8-4.7,3.6-6.2c9.5-5.2,18.7-10.8,28-16.3c2.7-1.6,2.9-3.4,0.2-5
	c-3.4-2-7-3.8-10.7-5.1c-2.5-0.8-4.1-3.3-7.4-3c-4,0.4-6.4,3.2-9.5,4.8c-2.3,1.2-4.7,2.2-7.3,2.8c-1,0.2-1.8,0.1-1.4-1.3
	c0.1-0.7,0.5-1.2,1.1-1.5c6.1-2.8,11.7-6.5,17.2-10.3c1-0.7,2.2-1.2,3.5-1c4.4,2.5,9.3,3.8,13.4,7c1.8,1.4,3.9,1.5,5.7-0.4
	c0.4-0.5,1-0.7,1.7-0.4c3.1-0.5,4.6-2.5,4.7-5.6c0.1-3.9,0.2-7.9,0.2-11.8c4.4,1.9,8.2,4.8,12,7.5c7.2,5.1,8,5.4,15.1,1.1
	c2.6-1.5,5.2-3,7.5-4.9c2.4-1.9,3.5-1.6,3.4,1.6c0,0.8,0.2,1.5,0.7,2c0,3.2,0.1,6.5,0.1,9.7c1.5,3.5,4.3,6.2,3.8,10.7
	c-0.6,5-2.7,8.4-6.6,11.5c-6,4.9-12.8,8.5-19.3,12.5c-6.2,3.8-12.4,7.7-18.7,11.4c-5.8,3.4-11.4,6.8-17.7,9.3
	c-3.2,1.2-6,3.3-9.5,4.1c-3.8,0.8-7.7,0.5-11.4,1.3C713.8,618.3,711.9,617.7,713.1,615z"
    />
    <path
      class="st40"
      d="M591,713.9c-0.4-6.3,0.4-12.7-0.6-19c-0.3-2.1,0.5-4,2.7-5.2c5.9-3.3,11.8-6.9,17.6-10.5c1-0.6,2.6-1,2.2-2.7
	c-0.4-1.7-2.1-1.4-3.4-1.6c-5.2-0.8-10.3-1.5-14.9-4.4c0.6-1.1,1.6-0.8,2.5-0.9c5.6,1.1,11.5,1.1,16.9,0.7c6.2-0.5,12.6-3,16.2-9.2
	c1.6-2.7,1.1-4.1-1.4-5.8c-1.9-1.3-5.2-0.7-5.9-3.4c-0.8-3,3.1-2.7,4.1-4.6c0.2-0.4,0.3-0.9,0.7-1.3c1.2-1.3,2.2-2.8,4.3-2.1
	c5.1,3,10.6,5.4,16,7.8c1.7,0.8,3.4,0.7,4.9-0.4c3.4-2.5,7.3-4.2,10.8-6.6c1.4-1,3.2-2.2,5-0.4c0.1,6.2,0.1,12.4,0.2,18.6
	c0.6,2.3,2.5,3.7,3.5,5.7c3.5,7.1,2,12.7-4.5,18.1c-3.3,2.7-7.2,4.4-10.7,6.8c-7.7,5.2-15.8,9.7-24.3,13c-9.9,3.9-19.6,8.9-30.5,10
	c-2.1,0.2-4.2,0.3-6.3,0.5C594.1,717.3,591.8,716.6,591,713.9z"
    />
    <path
      class="st41"
      d="M313.5,714.7c-0.2-7-0.4-13.9-0.7-20.9c-0.1-2.9,1-4.6,3.3-6.1c4.9-3.2,10.6-5.1,15.2-8.8
	c0.8-0.7,2.3-1.1,1.7-2.5c-0.4-1.2-1.7-1.4-2.8-1.6c-0.6-0.1-1.2,0-1.9,0.1c-6.3,0.5-12.2-0.5-18.3-2.3c-4.5-1.3-9.1-2.2-13.5-4.2
	c-0.9-0.4-1.6-1.1-2.5-1.7c0.9-2.3,2.5-1.9,4.3-1.3c9.5,3.2,19.4,4.9,29.4,4.1c9.7-0.7,19.1-2.8,27.5-8.4c3.5-2.3,6.2-5.4,8.5-8.8
	c1.8-2.5,1.5-5.4,0.4-8.2c-0.5-1.2-1.4-2.1-2.8-2c-1.1,0.1-2.2,0.2-3.2-0.7c-0.5-0.6-0.9-1.4-0.6-2c1.5-3.7,1.9-7.8,4.9-10.8
	c4-1,7.4-0.5,10.5,2.5c3.5,3.5,8,5.8,12.2,8.4c2.5,1.5,4.8,0.3,7-1c4.2-2.4,5.4-1.9,6,2.7c-0.7,4.2,0.5,8.4,0.2,12.7
	c4.2,5,2.9,13.6-2.3,17.4c-2.9,2.1-4.9,5.3-8.4,6.6c-7.2,2.8-12.7,8.4-19.7,11.7c-4.8,2.3-9,6-13.8,8.5c-6.8,3.6-13,8.2-20.1,11.4
	c-3.8,1.7-7.5,4.1-11.5,5.9c-1.8,0.8-3.8,1.3-5.6,1.8C315.2,717.7,313.5,717.1,313.5,714.7z"
    />
    <path
      class="st42"
      d="M448.4,270.2c0-9.7,0.2-19.5,0.1-29.2c0-3,1.1-4.8,3.6-6.3c12.8-7.6,25.8-15.1,38.3-23.2c4-2.6,8.2-5,12.7-7.8
	c-2.4-1.6-4-0.2-5.5,0.4c-9.2,3.8-17.2,9.9-26.8,12.8c-4.1,1.2-8.2,2-12.4,2.3c0.9-3.2,4-6.1,7.4-6c6.8,0.1,11.3-4.8,16.9-7.3
	c1.8-0.8,3.4-2.4,5.4-3.2c6-2.5,11.6-5.8,16.8-9.6c0.6-0.5,2.1-0.8,1.2-1.8c-2-2.1-2.1-4.2-1.4-6.8c0.2-0.8,0-2-0.5-2.9
	c0-1.8,0-3.5,0-5.3c3.1-1,5.3,1.1,7.3,2.7c3.6,2.7,7.7,4.5,11,7.6c0.4,0.3,0.8,0.7,1.3,0.7c4.5-0.3,4.3,2.5,3.7,5.6
	c0.2,5.7,1.3,11.4,0,17.1c1.5,2.4,1.8,5.1,2.4,7.8c0.3,1.4,0.2,3-0.8,3.9c-2.9,2.6-5.3,5.8-8.8,7.8c-5.9,3.5-11.6,7.3-17.4,11
	c-7.1,4.5-13.1,10.3-20.5,14.3c-4.3,2.3-8,5.4-12.3,7.8c-5.7,3.2-11.5,6.2-17.8,8.2C450.9,270.8,449.5,271.2,448.4,270.2z"
    />
    <path
      class="st43"
      d="M450.1,800.9c-1.7-8.7-0.6-17.4-0.7-26.1c0-1.9,1.6-2.9,3.2-3.8c11.4-6.4,22.8-12.8,33.8-19.9
	c0.8-0.5,1.8-0.8,2.7-1.3c0.8-0.4,2.2-0.7,1.6-2c-0.5-1.1-1.9-1-2.7-0.5c-4.4,2.9-9.5,4.1-14.4,4.8c-7.5,1.1-14.1,4.5-21.2,6.7
	c-1.3,0.4-2.7,0.5-4-0.1c-0.9-0.4-0.7-0.9,0.1-1.2c1.5-2.1,4-2.8,6.1-4.1c12.9-8,26.2-15.4,39.4-23c3.7-2.2,5.8-4.9,5.9-9.1
	c0.2-0.8,0.6-1.3,1.4-1.6c1-0.1,1.8,0.3,2.2,1.1c2.2,4.6,7.7,5.8,10.4,9.9c0.8,1.2,2.1,1,3.4,0.4c2.1-0.9,3.8-2.4,5.8-3.4
	c1.3-0.7,2.7-1.6,4-0.1c0.2,4-0.6,7.9,0.1,11.9c-0.1,1.9-0.3,3.6,0.9,5.6c2.2,3.7,2.7,8.5,1.5,12.2c-1.2,3.6-4.4,6.8-8.2,8.5
	c-5.8,2.5-10.4,6.8-15.8,9.8c-5.7,3.2-11,7.1-16.8,10.2c-5.7,3.1-11.3,6.5-16.8,10c-5.7,3.5-11.8,5.8-18.4,6.6
	C452.3,802.5,450.8,802.4,450.1,800.9z"
    />
    <path
      class="st44"
      d="M420,203.3c-0.4,0-0.8,0.1-1.1,0.1c-1-0.1-2.5,0.5-2.9-0.9c-0.4-1.4,1.5-1.1,2.2-2.1c-6.1-3.8-12-7.6-18-11.2
	c-3.1-1.9-4.3-4.2-4.2-7.9c0.2-7.7-0.1-15.4-0.2-23.1c0-4-0.1-2.3-0.7-7.3c-0.8-5.1-0.6-6.2-0.6-7.4c0.1-7.2-0.3-14.4,0.2-21.6
	c0.8-1.4,1.5-2.7,3.5-1.7c9.3,6.5,19.1,12,28.7,17.8c5.4,3.2,10.9,6.4,16.2,9.8c2.2,1.5,4.6,2.4,7.1,3.2
	c-0.1,11.3-0.2,22.6-0.2,33.9c0,9.6,0,19.1,0,28.7c0,1.6,0.3,3.3-1.4,4.4c-1.8,1.1-2.9-0.3-4.2-1.1c-6.8-4.2-13.9-8-20.5-12.6
	C422.5,203.5,421.3,203.5,420,203.3z"
    />
    <path
      class="st45"
      d="M396.7,359.2c1.5,0.9,2.6,0,3.6-1c9.1-8.3,18.2-16.6,27.3-24.9c12.2-11.2,24.4-22.4,36.6-33.5
	c1.3-1.2,2.7-2.2,4.4-2.7c4.2-0.6,8.1,1.8,8.2,5.8c0.5,12.4,0.4,24.8,0.1,37.3c0,0.9-0.4,1.7-1,2.4c-3.8,5.1-9.6,7.9-14.4,11.8
	c-5.8,4.6-12.1,8.6-17.7,13.7c-3.4,3.1-7.7,5.1-11.6,7.6c-2.8,1.8-3.5,3.5-1.9,5.7c13.7,10.2,27.2,20.6,40.8,30.8
	c2,1.5,4.3,3,4.2,6.1c-0.1,0.4-0.4,0.6-0.7,0.9c-4.4,0.4-7.3-2.2-9.8-5.3c-0.7-0.8-1-1.9-2-2.5c-5.2-1.9-9.2-5.5-12.8-9.6
	c-0.8-0.7-1.7-1.2-2.6-1.6c-8.2-4.6-15.3-10.7-22.4-16.6c-4.8-4-9.6-7.8-14.4-11.6c-1.8-0.5-2.9-1.9-4.2-3.1c-0.8-1.3-2-2.3-3.1-3.3
	c-1.8-2.2-4.9-2.2-6.9-4.1c-0.2-0.3-0.2-0.7-0.2-1C396.3,359.7,396.5,359.4,396.7,359.2z"
    />
    <path
      class="st46"
      d="M474,343c1.2-1.8,1.5-3.8,1.5-6c-0.1-10.6,0-21.2-0.1-31.9c0-4.3-2.3-7-6.7-8.1c2.2-1.7,4.5-1.8,7-0.6
	c12.2,5.9,24.4,11.7,36.7,17.5c2.8,1.3,3.9,4.1,5.3,6.5c1.2,3.3,1.6,6.8,1.6,10.3c-0.1,12.9,0.1,25.7-0.1,38.5
	c0.2,3.1-1.8,5.7-2.4,8.6c-0.4,1.8-2.1,2.4-3.8,2.4c-10.3,0.2-20.7,0.7-31-0.3c-3.6-0.4-3.8,1.2-3.8,3.7c-0.1,10.2-0.2,20.5,0,30.7
	c0,1.9,0.4,4.3-2.9,3c-1.3-2.8-1.4-5.7-1.4-8.7c0-17.8,0-35.6,0-53.4c0-2.2,0.1-4.5,0.3-6.7C473.5,346.8,474.4,344.9,474,343z"
    />
    <path
      class="st47"
      d="M448.6,510.1c7-2.5,12.8-7.1,19.3-10.6c13.2-7.3,26.2-14.9,39.2-22.4c15.4-8.9,30.8-17.9,46.2-26.7
	c14.1-8.1,28.3-16.1,42.5-24.2c2.2-1.2,4.2-2.7,6.3-4c0.2,2.8,0.6,5.6,0.7,8.4c0.2,4.4-1.2,8.1-5.2,10.5c-1.9,3.4-5.4,4.7-8.5,6.5
	c-11.2,6.5-22.6,12.6-33.8,19.3c-14,8.4-28.4,16-42.5,24.3c-15.9,9.4-32.1,18.2-48,27.5c-3.2,1.9-6.4,3.8-9.7,5.6
	c-3.9,2.1-5.8,1.6-7.8-1.6c-0.8-3.1-0.5-6.3-0.4-9.5C447,511.8,447.5,510.8,448.6,510.1z"
    />
    <path
      class="st48"
      d="M751.2,557.4c-1.2,3.3-3.7,5-6.5,3.1c-4.2-2.8-9.1-4.2-13.1-7.2c-0.5-0.3-0.8-1-1.1-1.4
	c-2.3-3.2-5.5-5.5-9-7.3c-9.5-4.9-18.7-10.4-27.6-16.3c-3-1.6-5.7-3.5-7.7-6.3c-6.6-3.1-13.2-6.2-18.1-11.9c-1-1.6-0.6-3.4-0.6-5.1
	c0.1-19.5,0.2-38.9,0.3-58.4c0-2.5,0.4-4.2,3.3-4.3c24.3,14.2,48.6,28.3,72.9,42.5c2.2,1.3,4.5,2.6,5.9,4.9
	c1.3,3.3,1.7,6.7,1.7,10.2c-0.1,16.9,0,33.9,0,50.8C751.5,553,751.3,555.2,751.2,557.4z"
    />
    <path
      class="st49"
      d="M448.6,510.1c0,4.5,0,9,0,13.5c0.2,0.3,0.2,0.7-0.1,1c-2.3,0.9-4.3,0.2-6.4-0.8c-8.8-4.3-17-9.4-25.3-14.4
	c-11.2-6.7-22.7-12.8-33.9-19.7c-13-7.9-26.2-15.3-39.3-23c-12.2-7.2-24.4-14.5-36.7-21.5c-2.2-1.3-4.9-2.1-5.9-4.9
	c-7.8-4.4-5.2-11.7-5.1-18.2c0-1.3,0.2-2.8,1.7-3.5c-1.4,3.4,0.3,5.3,3.2,6.9c12.4,7.1,24.8,14.2,37.2,21.4
	c18.1,10.5,36.2,21.1,54.3,31.6c17.4,10,34.8,19.9,52.2,29.9C445.8,509.1,447,510.1,448.6,510.1z"
    />
    <path
      class="st50"
      d="M411,370.5c4.6,3,9.5,5.4,13.1,9.9c1.8,2.2,4.5,3.8,6.9,5.6c5.8,4.4,11.7,8.7,17.5,13c0.4,1,1,1.7,2.2,1.5
	c4,3.7,8.1,7,12.8,9.8c1.2,1.9,2.3,4,0.9,6.2c-1.6,2.4-3.9,1.6-6.3,1.7c-4.3,0.2-8.7,0.3-13,0.6c-4.6,0.3-9.1,1.2-13.5-0.9
	c-1-0.5-2-0.1-2.9,0.3c-0.3,0.2-0.6,0.4-0.9,0.5c-2.2,1.4-4.7,1.2-7.1,1.2c-6.4-5.6-12.8-11.2-18.7-17.3c-1.8-0.6-4,0.1-5.3-1.9
	c-5.7-4.1-10-9.9-15.8-13.9c-2-1.3-3.7-2.9-4.1-5.4c0-0.9,0.3-1.8-0.4-2.6c-0.2-0.1-0.5-0.2-0.8-0.2c-1.3-1.1-2.5-2.1-3.8-3.2
	c-1.9-1.4-3.7-3-5.1-5c1.2-2.6,3.1-3.3,5.4-1.5c3.5,2.8,8.3,3.6,11.2,7.2c0.3,0.4,0.9,0.7,1.3,0.5c5.5-2.4,11.1-0.3,16.6-0.7
	c-4.8-0.3-9.6-0.5-14.5-0.4c-1.4,0-2.8,0.2-4-0.5c-1.5-0.8-2.8-1.9-2.5-3.8c0.2-2,1.7-2.6,3.5-2.7c7.5-0.4,15,0,22.4,0.4
	C407.9,369.4,409,371.8,411,370.5z"
    />
    <path
      class="st51"
      d="M589.5,300.8c-2.6-2-6.3-2.4-8-5.7c-0.2-0.3-0.5-0.5-0.9-0.6c-8.6-2.6-15.4-8.5-23.2-12.4
	c-6.9-3.5-10.4-9-10-17.1c0.5-9.9-0.3-19.9,0.4-29.9c0-0.3-0.3-0.7-0.4-1c0-15.4,0-30.8,0.1-46.2c0-1.1-0.5-2.2,0.6-3.1
	c0.6,1.8,2,2.8,3.5,3.7c11.4,6.5,22.8,13.1,34.3,19.5c1.8,1,3.9,1.5,5.9,2.3c-0.6,1.1-0.8,2.4-0.8,3.6c0,12.2,0,24.4,0.1,36.6
	c0,1.1,0,2.2,0.7,3.2c-0.1,15.5-1.1,31-0.8,46.5C590.5,300.3,590,300.5,589.5,300.8z"
    />
    <path
      class="st52"
      d="M697.7,390.6c-0.3-2-2.6-2-3.5-3.4c-0.3-2.8,2.8-4.2,2.7-6.9c3.8-5.5,7.5-11.1,10.9-16.8c0.7-1.1,1.4-2,1-3.4
	c-2.1-7.7-4.2-15.3-6.7-22.8c-0.3-1.1-1.3-1.8-1.6-2.9c-0.4-2.8-1.7-5.3-2.7-7.9c-9.3-6.1-19.1-11.4-28.7-17.1
	c-2.8-1.7-3.4-1.2-3.7,2.2c0.1,0.4,0.1,0.7,0.1,1c2.5,8.3,4.5,16.8,6.8,25.1c0.5,1.7,1.3,3.5-0.6,4.7c-1.8,1.3-3.3,0.1-4.7-0.9
	c-0.7-0.5-1.3-1.1-2.1-1.5c-0.7-0.4-1.5-1.2-2.3-0.5c-0.8,0.7-0.2,1.6,0.2,2.4c1.7,5.3,3.4,10.7,5,16c0.2,3.6,2.3,6.8,2.8,10.3
	c0.2,1.2,1,2.3,0.5,3.6c-0.3,0.5-0.8,0.9-1.4,1c-3.2-1.9-6.4-3.8-9.6-5.7c-3.2-1.9-3.8-5-3.8-8.4c-0.1-5.6-0.1-11.2-0.1-16.8
	c0-9.1,0-18.2-0.1-27.2c0-5.5,0.1-10.9,0.1-16.4c0-4.2,1.3-7.3,5.4-9.1c3-1.3,5.6-3.5,8.8-4.3c-1.7,2.7-5,3.1-7.3,5
	c-1.9,1.7-3.2,2.6-0.1,4.9c3.9,2.9,8.5,4.7,12.4,7.5c8.7,6.1,18.5,10.4,27.6,15.7c3.9,2.3,7.8,4.5,11.6,6.7
	c-0.8,1.9-0.7,3.9-0.7,5.9c0.2,12.3,0.2,24.6,0.3,36.9c0.1,10.2-0.1,20.4-0.5,30.6c-4.2-0.2-6.7-3.7-10.2-5.1
	C701.7,392.2,700.4,389.5,697.7,390.6z"
    />
    <path
      class="st53"
      d="M300.9,440.3c10.3,4.9,19.8,11.2,29.7,16.8c9.6,5.4,19,11.1,28.5,16.7c15.5,9,30.9,18,46.4,27
	c12.7,7.3,25.3,14.7,38,22c1.5,0.8,3.3,1,5,1.4c1.3,2.9,2.9,5.7,1.8,9.1c-0.7,1.4-1.6,2.7-2.8,3.7c-1.4,0.6-2.6,0-3.9-0.5
	c-9-4.2-17.2-9.5-25.6-14.8c-10.8-6.7-21.9-12.8-32.9-19.3c-12.3-7.2-24.8-14.3-37-21.6c-13-7.8-26.2-15.3-39.3-23.2
	c-3.3-2-7.3-2.7-9.9-5.9c-1.3-1.6-2.5-3.2-1.2-5.4C299.3,444.5,301.2,443,300.9,440.3z"
    />
    <path
      class="st54"
      d="M189,378c-0.5,0-1,0.1-1.5,0.1c-4.2-1.3-7.7-4.1-11.4-6.4c-10.7-6.9-21.7-13-32.8-19.1
	c-5.3-2.9-8.8-6.8-8.3-13.6c0.5-7,0-14,0-20.9c-1.9-2.5-1-5.5-0.9-8.2c0.2-5,0.6-10,1-15.1c0.1-4.8,0.2-9.6,0.3-14.4
	c2.2-0.7,4.2,0.1,6,1.3c12.1,8.1,25,14.9,37.5,22.3c3.3,1.9,6.5,4.2,10.3,5c1.4,2.3,1.7,4.9,1.7,7.5c0,18.5,0.1,37.1,0,55.6
	c0,1.6-0.1,3.2-0.9,4.7C189.6,377.3,189.3,377.7,189,378z"
    />
    <path
      class="st55"
      d="M419.1,665.9c3.6-10.8,10.6-18.2,21.6-21.8c2.3-0.8,4.7-0.5,7-0.8c2.5-0.9,5-1,7.5,0.1l0,0
	c2.3,1.4,5.1,0.9,7.7,2.3c5.1,2.8,7.9,6.4,8.2,12.2c0.6,9.4-1.2,18.3-7,25.7c-3.8,4.9-9,8.5-15,10.8c-7.9,3-15.4,1.3-22.7-2.3
	c-2.3-1.1-3.7-2.9-5-4.9C417.1,680.6,416.5,673.4,419.1,665.9z"
    />
    <path
      class="st56"
      d="M276.1,586.6c6.6-8.7,15.9-13.5,26.1-16.2c6.3-1.7,13-1.9,19.6-0.3c6.2,1.3,7.8,6.2,8.8,11.5
	c0.5,2.3,1.1,4.5,1.3,6.9c0.1,2.3,0.6,4.6,1.8,6.7c0.9,1.5,1.1,3.4-1.2,4.3c-1.5-0.7-2.9-1.7-4.9-1.5c-1.9,0.2-2.9-1.2-3.8-2.9
	c-2.7-4.8-5.9-8.9-12.3-8.7c-4.9,0.2-7.6,3.3-9.9,6.9c-1.8,2.8-3.6,5.2-6.1,4.4c3.4,0.6,4,1.5,3.4,6.5c0,0.2,0,0.4,0,0.4
	c1.8,6.4-2.2,9.1-7.1,11.5c-5,2.4-9.2,5.9-12.6,10.3c-0.8,1.1-1.8,2.1-3.4,1.8c-1.4-0.5-2.1-1.8-1.7-3c1-3.3-0.7-5.8-2.2-8.3
	c-5.5-9.2-4.7-18.7,2.1-27.2C274.8,588.7,275.2,587.5,276.1,586.6z"
    />
    <path
      class="st57"
      d="M189.1,377.2c0-19.7-0.1-39.4,0-59c0-3.1-1.7-6.1,0-9.2c16.7-10,33.5-20.1,50.2-30.1c2.1,1.5,2.2,3.8,2.2,6
	c0.1,7.3,0.1,14.6,0.2,22c0,3.7,0,7.4,0,11.1c1.3,2.1,1.7,4.3,1.6,6.7c-0.2,5.4,0.4,10.9-0.4,16.3c-1.2,1.4-2.3,2.8-2,4.9
	c0.2,1.7-1.3,2.8-2.6,3.9c-7.9,7.1-17.4,12-26.2,17.9c-5.4,3.6-11.4,6.2-17,9.3C193.1,378,191.1,378.5,189.1,377.2z"
    />
    <path
      class="st58"
      d="M363.1,629.2c-1.6,4-3.2,8-4.7,12c0.8,1.9-0.3,3.4-1.2,4.8c-6.9,10.8-16.9,16.8-29,20.1
	c-12.9,3.5-25.3,2-37.5-3.2c-3.2-1.8-6.4-3.6-9.2-6.1c-1.4-1.2-3-2.3-3.2-4.3c-1-1.7-2.9-3-3-5.3c2.3-1.2,4,0.5,6,1.2
	c10.5,3.5,21,4.9,31.6,0.8c4.6-1.8,9.2-3.4,13.5-5.9c2.5-0.6,5-0.8,7.6-0.1c4.6,1.2,10.9-3.5,11.8-8.7c0.5-2.6,1.3-5,3.6-6.7
	c1.7-1.3,1.9-3.5,2.6-5.3c2.2-6,5.5-11.7,5.8-18.4c0.1-2.1-0.6-3.9-0.9-5.8c-0.3-1.6-1-3.6,1.7-4c3.2,4,4.2,8.7,5.4,13.5
	C366.6,612.8,366.2,625.5,363.1,629.2z"
    />
    <path
      class="st59"
      d="M559.4,625.6c-2.4,2.4-5.1,2.1-6.9-1.6c-1.9-3.7-6.3-5.5-7.1-10c-0.1-0.3-1.1-0.5-1.6-0.7
	c0.9-2.9-0.7-5.3-1.4-7.9c-1.8-7.4-1-14.3,3.6-20.5c4.8-4.7,10.2-4.4,15.8-1.6c8,4,15.5,8.9,23.1,13.6c1.6,1,2.2,3.1,4.2,3.6
	c1.3,0.4,2.3,1.4,2.1,2.7c-0.7,4.1,0.2,8.2-0.7,12.2c-1.3,5.3-5.1,6.9-9.7,4.2c-2.2-1.2-4.3-2.4-6.6-3.4c-0.4-0.1-0.7-0.2-1.1-0.3
	c-5.6-3.7-10-1.8-11.5,4.8C561.2,622.5,561.6,624.6,559.4,625.6z"
    />
    <path
      class="st60"
      d="M336.8,266.3c3.4-10.5,9-20,13.4-30c8.7,5.3,17.5,10.5,26,16c4.6,3,7.5,7.1,7.1,13.1c-0.3,4.5,0,9,0.1,13.5
	c-0.2,0.1-0.3,0.2-0.5,0.3c-1-0.1-1.7-0.9-1.7-1.7c-0.4-3.6-2.2-2.4-4-1.3c-2.9,1.7-5.6,3.6-8.4,5.5c-3,2-5.6,2-9,0.1
	c-4.9-2.7-9.4-6-14.3-8.6C342.2,271.5,339.3,269.1,336.8,266.3z"
    />
    <path
      class="st61"
      d="M411,370.5c-0.1,1.6-0.9,2.7-2.3,1.7c-3.5-2.4-7.5-1.6-11.2-1.7c-4.5-0.1-9,0-13.5,0c-1,0-2.2-0.2-2.5,1.1
	c-0.2,1.4,1,2,2.1,2.2c1.2,0.3,2.5,0.4,3.7,0.4c4.9,0,9.7,0,14.6,0c1.8,0,4.6-0.5,4.7,1.8c0.1,2.6-2.8,1.7-4.4,1.9
	c-3.5,0.4-6.9-0.6-10.4-0.7c-2.4-0.1-4.7,0-6.4,2.1c-1,1.2-2.1,1.5-2.7-0.4c-1.2-4.2-5-4.8-8.3-6.2c-0.9-0.4-2.1-0.2-2.5-1.3
	c-1.1-3.2-3-2.1-4.9-1c-5-4.3-10-8.6-14.8-13c-3.9-3.6-4-7.3-0.1-11.3c2.7-2.9,5.8-5.5,8.8-8c2.6-2.1,5.7-2.1,8.3-0.2
	c6.9,4.9,13.8,9.8,19.9,15.6c2.4,2.3,5.8,2.9,7.8,5.5c0,0.3,0,0.5,0,0.8c-0.3,0.1-0.4,0.3-0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.2
	c3,0.3,6,0.6,7.4,3.8c0.5,1.5,1.5,2.5,3,3C409,367.9,409.5,369.9,411,370.5z"
    />
    <path
      class="st62"
      d="M449.2,533.2c0.2-2.1,0.3-4.2-1.7-5.8c-1.9-1.6,1-2.1,1-3.3c0-0.2,0.1-0.4,0.1-0.5c2.2,1.2,4.2,0,5.9-1
	c22.3-12.8,44.6-25.6,67-38.4c22.2-12.7,44.4-25.4,66.7-38c3.1-1.8,5.8-4.3,9.4-5.1c1,4.8,5.4,6.3,8.5,9l-0.2,0l-0.2,0
	c-1.5,2.1-3.5,1.7-5.4,0.9c-1.5-0.7-2.6-0.7-4.1,0.2c-12.5,7.4-25.1,14.6-37.6,22c-13.5,7.9-27.1,15.8-40.8,23.5
	c-13.3,7.5-26.8,14.7-39.7,22.9c-8,5.1-16.8,8.3-24.6,13.7C452.2,534.1,450.6,534.7,449.2,533.2z"
    />
    <path
      class="st63"
      d="M297.8,446.3c1.5,4.2,4.5,6.4,8.6,8.1c5.2,2.2,10.1,5.5,15,8.4c13.4,7.9,26.8,15.8,40.3,23.6
	c12.4,7.3,24.9,14.5,37.3,21.8c13.7,8.1,27.4,16.3,41.2,24.4c2.4,1.4,4.7,3.3,7.7,3.6c2.2-2.4,5-2,7.4-0.9c2.5,1.1,2.7,3.5,2,6
	c-1.6,3.1-4.8,3.9-7.6,5.1c-1.9,0-3.6-0.5-5.2-1.6c-7.5-4.9-15.1-9.7-22.9-14c-8.7-4.9-17.3-9.9-25.8-15c-11.9-7.1-23.8-14-35.7-21
	c-9.2-5.4-18.5-10.8-27.7-16.1c-12.2-7-24.4-13.9-36.3-21.3c-1.2-1.3-2.2-2.8-3.2-4.2c0-1,0-2,0-3
	C294.4,448.9,295.2,446.3,297.8,446.3z"
    />
    <path
      class="st64"
      d="M332.4,599.3c0.5-0.6,0.9-1.5,0.5-2c-4.2-5.1-2.6-11.7-4.6-17.5c-1.2-3.5-2.2-6.4-5.5-8.2
	c-0.5-0.3-0.7-1-1-1.5c5.3-0.4,9.7,2.3,14.4,4c5.9,2.1,10.1,6.3,14.1,10.9c5.2,4.3,6.5,10.1,5.8,16.5c-0.9,8.7-6.4,15.5-9.5,23.3
	c-0.2,0.5-0.7,0.7-1.1,1c-2.4,0.8-3.2-1.5-4.6-2.5c-3.2-3.9-7.7-6.2-11.6-9.3c-3.4-2.7-5.3-9.6-3.2-13.4
	C327.5,597.8,329.9,598.2,332.4,599.3z"
    />
    <path
      class="st65"
      d="M277.5,536.3c-4.7,2.7-9,6.1-14,8.4c-3.5,1.6-6.5,1.1-9.6-1.2c-4.9-3.7-10.1-7.1-15.2-10.5
	c-0.6-0.4-1.4-0.3-2.2-0.5c-1.5-0.6-1.7-2-1.8-3.4c-0.2-6-0.3-11.9,0-17.9c0.1-1.5,0.1-3.4,2.5-3.4c11.5,6.9,23,13.7,34.4,20.6
	C274.5,530.2,277.5,532.1,277.5,536.3z"
    />
    <path
      class="st65"
      d="M241.5,342c0.1-8,0.1-16,0.2-24.1c10.3,6.6,21,12.5,31.7,18.7c5,2.9,6.5,6.9,5,12.2c-1-2.3-2.2-0.8-3.2-0.2
	c-2.3,1.5-4.7,2.8-6.9,4.5c-3.5,2.8-6.7,2.1-10-0.3c-4.2-3-8.6-5.9-12.8-9c-1.2-0.9-2.4-1.3-3.8-1.4
	C241.2,342.6,241.1,342.4,241.5,342z"
    />
    <path
      class="st66"
      d="M476.3,655.4c6.4,7.2,8,15.8,7.5,25c-0.1,2.3,0.1,4.4,1.3,6.4c-0.2,0.8-0.7,1.1-1.4,1.4
	c-1.8,0.7-1.5,2.9-2.8,4c-2.3,2.4-3,6.1-6.2,7.8c-1.6,0.2-2.5,1.3-3.2,2.6c-0.3,0.2-0.6,0.4-1,0.6c-12,8.1-24.6,8.1-37.6,3.1
	c-2.2-0.2-4-1-5-3.1c-0.8-1.5-2.3-2.5-3.5-3.7c-0.5-0.7-0.8-1.4-0.7-2.3c0.6-2.2,2.2-2.2,3.9-1.6c5.2,1.9,10.7,2,16.1,2.3
	c6.5,0.3,12.1-2.4,17.3-6c9.1-6.3,13-15.4,13.6-26.2c0.1-2.5,0.9-4.9,0.3-7.4C474.7,657.1,475.1,656,476.3,655.4z"
    />
    <path
      class="st67"
      d="M363.1,629.2c1.1-7.2,0.3-14.5,0.7-21.7c9,5.3,17.8,10.9,26.9,15.9c7.4,4.1,8.4,10.4,7.4,17.8
	c-1-3.2-2.4-3.4-5-1.4c-3.3,2.7-6.8,3-10.5,0.3c-4.1-3.1-8.8-5.5-12.5-9.2C368.6,629.5,365.5,629.7,363.1,629.2z"
    />
    <path
      class="st68"
      d="M476.3,655.4c1.3,7.2,0.3,14.3-1.7,21c-3.2,10.2-10.4,16.7-20.2,21.2c-6.1,2.8-12.2,1.5-18.3,1.9
	c-3.1,0.2-6.2-1.5-9.3-2.4c-0.8-0.2-1.6-0.9-2.4,0c-1.9,1.2-2.9-0.5-3.5-1.6c-1.4-2.3-3-4.2-5.2-5.8c3.2-3.2,1.9-6.7,1.5-10.5
	c-0.5-4.4-0.5-9.1,1.9-13.3c-1.7,6.5-1.4,13,2.4,18.5c2,2.9,3.5,6.8,8.5,6.9c1.5,0,3.4,1.7,5.4,2.2c7.7,2.2,14.2-0.4,20.3-4.5
	c11.1-7.5,14.4-18.5,13.7-31.1c-0.3-6.6-4.8-11.7-11.4-13c-1.1-0.2-2.2-0.4-2.8-1.5c8.3-0.5,13.9,4.3,19,9.8
	C475,654,476.1,654.3,476.3,655.4z"
    />
    <path
      class="st69"
      d="M668.9,644.3c-5.4,2-9.9,5.6-14.8,8.5c-1.5,0.9-3,1.9-4.5,1.3c-5.8-2.2-11.7-4.5-16.9-8.1
	c-0.2-0.2-0.3-0.7-0.4-1c-0.7-2,0.2-3.4,2-4.3c3.4-1.6,6.2-4,9.3-5.9c3.6-2.1,5.4-5.3,5.7-9.5c0.1-1.3,0.1-2.8,1.7-3.4
	c2.8,3.8,7.3,5,11.1,7.1c5.6,3.2,7.5,7.4,6.8,13.4C668.9,643,668.9,643.7,668.9,644.3z"
    />
    <path
      class="st70"
      d="M464.2,593.9c-0.1-0.8-0.5-1.9,0.7-2.2c3.7-1,6.6-3.6,9.5-5.7c5-3.6,10.8-5.7,15.9-9
	c6.9-4.5,14.1-8.5,21.4-12.4c4.8-2.6,9.5-5.4,14-8.5c2.5-1.8,5.7-2,8-4c4.3-3.7,9.8-5.8,14.6-8.8c6.3-3.8,13.1-7,19.3-11
	c4.6-2.9,9.9-4.3,14.3-8.1c5-4.3,11.2-7.5,17.3-10.3c5-2.2,9-5.6,13.7-8c3.1-1.6,7.1-2.3,9.2-4.8c2.8-3.2,7.2-2.7,9.8-5.8
	c0.6-0.7,1.7,0,2.5,0.1c0.2,0.6,1.7,1.3,0.4,1.7c-6.9,2.5-12.3,7.3-18.5,10.8c-6.1,3.5-12.1,7.2-18.5,10.1c-2.2,1-3.7,2.7-5.8,3.9
	c-7.6,4.5-15.4,8.6-22.9,13.3c-6,3.8-11.9,8.5-19.1,10.3c-0.2,0.1-0.5,0.2-0.7,0.3c-5.4,5.8-13.5,6.8-19.6,11.5
	c-8.7,6.9-19.1,10.9-28.9,16c-2.7,1.4-4.9,3.7-7.6,5.2c-5.6,3.3-11.3,6.4-16.9,9.5C472.6,590.5,469.4,594.6,464.2,593.9z"
    />
    <path
      class="st71"
      d="M591,713.9c2.4,2.1,5,2.5,8.3,2.2c6.3-0.7,12.3-2,18.1-4.9c4.1-2,8.8-3.2,13-5.3c8.5-4.2,17.3-7.9,25.2-13.4
	c3.4-2.4,7.6-3.6,10.7-6.6c6.8-6.6,8.8-8.9,3.3-18.5c-0.8-1.4-0.7-2.9-0.6-4.3c2.4,2.2,5.1,4.1,7.1,6.6c4.2,5.2,2.6,12.5-2.3,19.2
	c-6.4,8.7-15.6,13.2-24.8,17.6c-5.5,2.6-11.9,3.2-18.1,3.2c-3.2,0-6,1-8.9,2.4c-6.6,3.2-13.9,4.2-21.1,5.7
	c-6.6,1.4-11.4-1.9-16.4-5.3c-5.1-3.5-10.3-6.8-15.4-10.2c3.1,0.3,5.4,2.2,7.9,3.7C581.7,708.5,586,711.8,591,713.9z"
    />
    <path
      class="st72"
      d="M595.5,570.1c-4.8-2.3-9.2-5.2-13.6-8.1c-5.1-3.3-10.4-3.3-15.8-1.1c4.1-5,10.3-6.7,15.2-10.4
	c8.3-6.3,15.8-4.7,23.4,1.2c3.7,2.8,7.9,5,10.3,9.2c-1.4,3.2-4.9,4-7.2,6.1c-1.3,1.2-3.2,1.8-4.7,3C600.4,571.8,598,572,595.5,570.1
	z"
    />
    <path
      class="st73"
      d="M527.1,727.6c-3.6,1.1-6.3,3.9-9.7,5.5c-1.7,0.8-3,1-4.3-0.5c-2.5-2.9-5.2-5.4-8.6-7.1
	c-1.8-0.9-1.8-3.5-2.8-5.1c-0.5-1.3,0.1-2.9-1.3-3.8c-0.6-0.6-1.1-1.3-1.4-2.1c-0.8-1.9-2.6-3.1-3.7-4.9c0.2-2.7,2.7-4.8,1.7-7.8
	c0.6-1.6,1.1-3.3,3.4-2.8c7.1,4.3,14.2,8.7,21.3,13.1c4.1,2.5,5.9,6.2,5.5,11.1C527.1,724.6,527.2,726.1,527.1,727.6z"
    />
    <path
      class="st74"
      d="M415.7,689.7c2.1-1.4,3.5-1.1,4.6,1.5c0.9,2.2,2.2,4.3,4.1,5.9c0.3,0.6,0.6,1.2,0.9,1.8
	c4.9,9.5,13,14.3,23.1,16.5c3,0.7,6,1.4,9.2,1.6c1.8,0.1,3,1.1,2.8,3.2c-3.9,4.1-8.6,6.9-14.2,8c-6.6-4.3-13.6-8.1-20.3-12.2
	c-5.4-3.2-10.5-7-15.8-10.3c-1.7-1-3.3-2.1-5.1-3c-1.9-1-4.2-2.2-2.9-5.1C406.6,695,411.1,692.3,415.7,689.7z"
    />
    <path
      class="st75"
      d="M279.7,192.1c5.4,0,10.7,0,16.1-0.1c1.9-0.1,2.9,0.7,3.8,2.3c2.8,4.9,5.7,9.6,8.4,14.5c1.4,2.5,2.5,2.5,3.8,0
	c2.7-4.8,5.5-9.5,8.2-14.3c1-1.8,2.2-2.7,4.4-2.6c5.3,0.2,10.7,0.2,16.1,0.2c2.9,1.3,6.4,2,5.8,6.5c-1.1,1.7-2.9,1.8-4.6,1.8
	c-4.5,0.1-9,0-13.5,0c-2.5,0-4.2,1.1-5.4,3.3c-2.8,5.1-5.7,10.2-8.7,15.2c-3,4.9-5.2,5-8.3,0c-3.1-5-5.9-10.1-8.8-15.2
	c-1.3-2.3-3.1-3.4-5.7-3.4c-4.1,0-8.2,0.1-12.3,0c-1.7,0-3.6,0.1-4.7-1.7C273.2,194.2,277,193.6,279.7,192.1z"
    />
    <path
      class="st76"
      d="M517.4,369.8c0.1-16.4,0.1-32.9,0.2-49.3c3.2,3.6,4.5,7.6,4.4,12.6c-0.3,13-0.1,26.1,0,39.1
	c0,16-0.2,31.9,0.1,47.9c0.1,6.5-1.1,12.3-4.2,17.9c-2.5-0.7-2.1-2.9-2.1-4.7c-0.2-7.9-0.1-15.7-0.1-23.6c0-6.2,0.2-12.5-0.1-18.7
	C515.4,383.8,516.7,376.8,517.4,369.8z"
    />
    <path
      class="st77"
      d="M605.4,616.3c-0.1-9.8-0.2-19.5-0.3-29.2c-0.3-2.2,0.5-3.8,2.3-4.9c4-2.4,8.3-4.5,11.2-8.3
	c0.8-1.1,2.2-1.8,3.6-0.6c2.7,3.1,2.4,7.1,2.9,10.8c0.8,5.6,0.5,5.7,6.2,5.5c0.3,0.3,0.5,0.6,0.4,1c-2.7,4.4-7.5,5.9-11.7,8.2
	c-2,1.1-4.1,2.5-6.7,1.5c-3.2,1.4-2.2,4.2-2.2,6.7c1,3.2-0.6,5.8-2,8.4C608.4,616.7,607.1,617.3,605.4,616.3z"
    />
    <path
      class="st78"
      d="M651.1,621.9c-0.7,2.8,0.3,5.6-0.9,8.4c-1.9,4.4-6,6.2-9.5,8.9c-2.7,2.1-6.8,2.2-8.4,5.8
	c-2.1-0.6-3.4,0.8-4.8,1.9l-0.3-0.1l0-0.4c6.3-7.7,5.6-16.6,4.2-25.6c-0.2-1.6,0.8-2.4,2-3.2c1.3-0.9,2.8-1.7,4.2-2.4
	c4.1-2.2,7.3-5.2,9.6-9.2C649.8,611,650.8,616.3,651.1,621.9z"
    />
    <path
      class="st79"
      d="M527.3,192.8c-0.5-1.6,1.1-5.3-3.2-3.3c-0.4,0.2-1.3-0.2-1.7-0.5c-5.6-5-12.3-8.4-18.3-12.7
	c0-6.2,0-12.5,0.1-18.7c5.8,3.4,11.8,6.8,17.5,10.4c3.8,2.4,6.2,5.7,5.6,10.5C526.6,183.2,529,188,527.3,192.8z"
    />
    <path
      class="st80"
      d="M713.1,615c0.2,2.4,2,2.4,3.6,2.1c4.2-0.8,8.9-0.2,12.7-2.6c5.8-3.6,12.3-6,18.2-9.4
	c9.4-5.5,18.7-11.2,28.1-16.7c6.5-3.8,13.9-6.6,18.9-12.8c2.2-2.8,3.1-10,1.2-13c-1.1-1.8-1-3.5-0.9-5.4c4.3,1.8,7.9,4.7,11.2,7.8
	c1.4,1.3,1.3,2.4-0.2,3.7c-3.9,3.2-7.8,6.5-11.5,9.9c-7.3,6.8-16.2,11.2-24.4,16.7c-8.1,5.4-16.4,10.2-24.8,15
	c-8.9,5.2-18.6,8.2-29,8.4c-4.2,0.1-7-2.8-10-5.1C708.9,613.1,710.7,615.3,713.1,615z"
    />
    <path
      class="st81"
      d="M313.5,714.7c1.1,1.9,2.5,2.4,4.7,1.5c4.9-2,9.7-4,14.2-7.2c3.2-2.2,7.1-3.4,10.4-5.6c5-3.4,10.3-6.3,15.4-9.5
	c7.4-4.7,15.2-8.8,22.2-14c3.1-2.3,6.9-3.6,10.2-5.8c7-4.6,9.7-10.3,7.8-18.4c-0.1-0.6,0-1.2,0-1.9c3.4,0.4,6.4,1.6,8.5,4.4
	c2.1,2.7,1.9,5.7-0.9,7.5c-6.1,3.8-10.8,9.5-17.1,12.8c-7,3.7-13.2,8.6-20.2,12.3c-7.5,3.8-14.3,8.8-21.7,12.8
	c-8.6,4.7-16.9,9.7-26.2,13.1c-5.4,2-9.9,0.2-13.9-3.6C309,713.7,311.2,714.2,313.5,714.7z"
    />
    <path
      class="st82"
      d="M308.2,335.3c0.5,0,1.1-0.1,1.6-0.1c0.4-0.6,0.7-1.4,1.5-1.7c12.9-5.9,23.9-15,36.9-21
	c10.3-4.7,19.3-11.8,28.9-17.6c3.8-2.3,5-5.4,4.3-9.5c-0.4-2.5-0.6-4.7,1.7-6.4l0.3-0.1c2.1,0.9,3.6,2.6,5,4.2c2,2.2,0.7,9-2.1,10.8
	c-5,3.2-9.7,6.8-15,9.7c-3.5,1.9-6.9,4.3-10.2,6.6c-6.6,4.7-13.8,8.5-20.5,12.9c-6.2,4-12.3,8.5-19.1,11.6
	C317.3,336.6,312.8,338.6,308.2,335.3z"
    />
    <path
      class="st83"
      d="M237.1,507.8c-0.9,0.7-0.8,1.7-0.8,2.7c0,7.3,0.1,14.6,0.1,21.9c0,2.9-0.5,5.8,0,8.6c1.1,6.1-2.5,10-6.7,12.6
	c-7,4.3-14,8.7-21.7,11.9c-1.3,0.6-2.4,2.9-4.1,0.6c8.4-6,17.9-10.2,26.4-15.9c0.6-0.4,1-0.7,0.6-1.5c1.5-1,1.7-2.6,1.7-4.2
	c0-19.5-0.1-39-0.1-58.5c0-1.9-0.5-3.7-1.4-5.3c1.1-2.6,2.9-0.3,4.4-0.7c1.3,2.8,0.6,5.9,0.7,8.8
	C236.6,495.2,235.8,501.5,237.1,507.8z"
    />
    <path
      class="st84"
      d="M450.1,800.9c7.7,1.6,14.4-1.3,20.4-5.3c6.4-4.2,13.2-7.8,19.7-11.9c7.6-4.7,15.3-9.2,22.5-14.4
	c3.4-2.4,7.5-4,10.7-6.5c2.7-2.1,5-4.3,4.5-9.1c-0.4-4.6-2.7-9.4-0.7-14.2c0.4,3.8,2,6.4,6.2,7.2c2.1,0.4,2.2,4.2,5.2,3.9
	c-0.5,2-1.2,4.1-2.8,5.4c-13.2,10.4-27.2,19.7-41.8,28c-8.1,4.5-15.8,9.7-24,14.1c-5.1,2.8-10.6,4.6-16.5,5.1
	c-1,0.1-2.2,0.2-2.9-0.2c-3.9-2.2-8.2-3.5-11.7-6.4C442.9,796.9,446.3,799.5,450.1,800.9z"
    />
    <path
      class="st85"
      d="M198.8,619.5c6.4-3,11.3-8.3,17.4-11.8c6-3.4,10.9-8.4,17.4-11.2c3.6-1.5,5.8-5.7,9.7-6.8
	c7-2,10.9-8,16.7-11.5c4.5-2.8,8.7-6.1,13.1-9c4.7-3,7.2-7.1,5.5-12.9c-0.3-1.2-0.2-2.5-0.3-3.7c2.9,1.6,5.8,3,8.3,5.2
	c1.5,1.2,1.6,2,0.1,3.2c-7.9,6.3-15.5,12.9-23.7,18.8c-8.5,6.2-17.6,11.6-26.3,17.5c-9.8,6.6-19.9,12.8-29.6,19.7
	c-2.7,1.9-5.8,3.8-9.2,4.6C198.3,620.9,198.5,620.2,198.8,619.5z"
    />
    <path
      class="st86"
      d="M452.2,270.7c7.6-4.2,15.7-7.4,22.8-12.5c4.3-3,9.7-5,12.9-8.7c3.2-3.8,7.8-4.9,11.3-8.1
	c3.3-2.9,6.8-6.8,11.8-8c1.8-0.4,3.1-3.5,4.8-3.8c4.9-0.8,6.9-5.3,10.6-7.4c2.1-1.2,2.1-3.4,1.4-5.3c-0.8-2.3-0.7-4.6-0.6-7
	c2.4,1.9,4.8,3.9,7.3,5.7c1.1,0.8,1,1.5,0.2,2.4c-3.8,4.2-7.7,8.3-12.5,11.7c-8.3,5.7-17.8,9.4-25.3,16c-6.1,5.4-13.2,9.4-19.9,13.7
	C469.5,264.4,461.8,269.8,452.2,270.7z"
    />
    <path
      class="st87"
      d="M276.1,586.6c-2,4.1-3.9,8.2-5.5,12.5c-2.1,5.7-1.2,10.5,1.7,15.2c2.4,3.8,5.4,7.2,3.2,12.1
	c-0.1,0.3,0.4,0.9,0.6,1.3c0.4,0.3,0.9,0.7,0.8,1.2c-0.6,3.3,0.2,6.5-0.2,9.8c-2.7,1-5.8-0.5-7.4-3.3c-0.9-1.6-1-3.6-2.7-4.7
	c-1.9-6-2-12.2-1.9-18.5C266.6,602.8,268.7,593.5,276.1,586.6z"
    />
    <path
      class="st88"
      d="M344.9,625.5c1.2-4.6,3.6-8.6,5.7-12.8c4.6-9.1,5.8-18.6-0.4-27.8c2.1-0.2,3.3,0.6,4,2.8
	c0.7,2.4,4,3.3,4.2,6.2c-0.8,1-0.7,2.1-0.2,3.2c2.4,5.5,1.3,10.8-1,16.1c-1.9,4.3-3.3,8.8-5,13.2c-0.3,0.7-0.5,1.7-1.1,1.9
	c-3,1.4-3.1,4.2-3.7,6.8C345.5,632.3,344,629.2,344.9,625.5z"
    />
    <path
      class="st89"
      d="M588.1,602.3c-1.9-3.1-5.1-4.8-8-6.6c-5.2-3.2-10.4-6.4-15.7-9.4c-5.8-3.3-11.9-5-18.3-1.5
	c0.7-2.1,2.8-2.9,4.2-4.5c-0.3-1.6-2.5,0.3-2.7-1.4c2.9-2.7,6.2-3,9.7-1.7c3.2,1.3,7.5,0.3,9.4,4.4c2.1,3.2,5.8,4,8.7,5.9
	c3.4,2.2,7.1,4,10.5,6.3c3.5,2.4,4.6,5.5,3.1,8C588.8,602.3,588.4,602.4,588.1,602.3z"
    />
    <path
      class="st90"
      d="M595.5,570.1c5.4,0.7,8.9-3.2,12.7-5.8c2.2-1.4,4.1-3,6.7-3.4c1.8,1.6,3.5,3.4,3.9,5.9c1.3,2,0.4,3.5-1.4,4.3
	c-2.8,1.2-4.8,3.5-7.6,4.8c-1.5,0.7-2.6,1.8-3.3,3.4c-0.6,1.2-1.2,2.5-2.8,2.8c-2.2-2.4-1.6-6.6-5-8.4
	C597.2,572.8,596.1,571.6,595.5,570.1z"
    />
    <path
      class="st91"
      d="M269.3,387.8c3.3-3.5,6.6-6.9,9.9-10.5c2.3-2.5,1.4-5.2,0.6-8.2c-1-3.7-2.2-7.6-0.8-11.5
	c-0.1,3.6,2.7,5.1,5.1,6.8c0.9,0.7,1.9,1.1,2.9,1.7c4.9,3.1,5.6,7.5,0.9,10.5c-6.5,4.2-12.5,9-18.9,13.4c-0.2,0.2-0.7-0.1-1.1-0.1
	C267.2,388.6,268.6,388.4,269.3,387.8z"
    />
    <path
      class="st70"
      d="M448.5,597c2.5,2.8,5,1,7.4,0c2.7-1.2,5.3-2.7,8.3-3c-4.3,4.9-14.5,6.8-20,3.7c-8.7-4.9-17.5-9.8-26.1-15
	c-12.2-7.3-24.7-14.2-37.1-21.2c-1.1-0.6-2.3-1.1-2.3-2.6c7.1,2,12.9,6.5,19.1,10.3c4.8,3,10.1,5.3,14.8,8.3
	c10,6.4,20.7,11.6,31.1,17.4C445.3,595.7,446.9,596.2,448.5,597z"
    />
    <path
      class="st92"
      d="M279.7,192.1c-1.8,2-3.5,3.9-5.3,5.9c-0.2,0.6-0.5,1.2-1.4,0.8c-7.8,0-15.6,0-23.4,0c2.6-2.9,6.5-4,9.3-6.5
	c0.8-1.1,1.8-1,2.8-0.3C267.8,192,273.8,192.1,279.7,192.1z"
    />
    <path
      class="st93"
      d="M446.3,727.2c5-1.6,9.1-4.7,13.5-7.4c1.4-2.6,3.8-3.8,6.3-4.8c1.4-0.6,2.5-1.5,3.9-2.1
	c3.5-0.6,5.6-3.4,8.5-5.1c3.8-2.2,8-3.9,11.3-6.9c3-1.9,6-3.7,9.4-4.7c0.5,0.9,0.9,1.8,1.3,2.6c-0.8,1-1.7,1.9-2.5,2.9
	c-0.3,1.9-1.8,2.6-3.2,3.3c-9.4,4.4-17.9,10.3-26.6,15.9c-5,3.2-10.8,5.2-15.6,8.7c-1.3,1-2.9,0.3-4.4,0.4c-0.9,0-1.6-0.3-2.1-1.1
	C445.7,728.3,445.8,727.8,446.3,727.2z"
    />
    <path
      class="st92"
      d="M345.9,198c-1.8-1.9-3.6-3.9-5.4-5.8c2.8-0.1,5.5-0.2,8.3-0.2c3.5-1.4,7-2.7,10.5,0c4.4,0.2,6.6,4,9.7,6.2
	c-5.4,1.4-10.8,0.3-16.2,0.6c-2.1,0.1-4.2-0.3-6.2,0.8C345.9,199.2,346.2,198.5,345.9,198z"
    />
    <path
      class="st94"
      d="M603.7,582c0.7-3.8,2.6-6.7,6.2-8.2c2.7-1.1,4.4-4,7.6-4.5c0.9-0.1,1.1-1.6,1.3-2.5c2.5,1.4,3.3,3.8,3.6,6.4
	c-0.6,0.9-1.9,0.7-2.5,1.6c-2.7,4.2-7.2,6.2-11.3,8.6c-1.6,0.9-2.9,1.9-3.4,3.7C604.7,585.4,604.2,583.7,603.7,582z"
    />
    <path
      class="st95"
      d="M647.1,606c0.4,3.7-1,6.3-4.4,8.1c-3.9,2-7.5,4.5-11.3,6.8c-0.9-1.3-1.1-2.9-1.5-4.4c-0.8-2.3-0.3-3.8,2-5.1
	c4.5-2.6,7.7-6.8,11.4-10.3c0.6-0.2,1.1,0,1.5,0.3C646.1,602.7,646.8,604.3,647.1,606z"
    />
    <path
      class="st96"
      d="M499.1,696.3c-1.7,3.8-5.8,4.1-8.9,6c-1.5,1.1-2.6,0.3-3.4-0.9c-2.6-4.4-4.9-8.9-4.2-14.3
	c0.8-0.1,1.7-0.2,2.5-0.3C489.3,690.6,495,692.4,499.1,696.3z"
    />
    <path
      class="st97"
      d="M446.3,727.2c0,0.5,0,1,0,1.5c-1.8,1.7-3.2,0.1-4.6-0.7c-11.8-7-23.6-14-35.4-21.5c-1,1.2,0.7,4-2.2,3.7
	c-2.3-0.7-3.1-2.6-2.5-4.6c0.7-2.5,0.1-4.5-1.2-6.5c0.5-0.5,1.1-0.9,1.7-1.4c-0.1,2,1.5,2.6,2.8,3.5c13.3,8.2,26.6,16.5,39.9,24.7
	C445.4,726.2,446.1,726.4,446.3,727.2z"
    />
    <path
      class="st70"
      d="M634.4,495.7c0.7-4.3-1-8.5-0.6-12.8c0.2-2.4-1.1-4.9,0.5-7.3c1.2,0.9,0.3,2.1,0.4,3.1c0,1.4,0.5,2.7,1.8,2.9
	c3,0.3,3.8,3.3,6.1,4.5c1.4,0.7,0.5,2.4,0,3.2C640.5,492.1,638.2,494.9,634.4,495.7z"
    />
    <path
      class="st98"
      d="M220.5,418.5c0.5-1.6,1.7-3,3.2-3.5c4.5-1.4,7.3-5.3,11.2-7.4c5.1-2.8,9.5-7.1,15.6-7.8
	c0.2,0.2,0.3,0.4,0.2,0.5c-0.1,0.2-0.1,0.3-0.2,0.3c-4.3,3.7-9.5,6.1-14.3,9c-4.6,2.8-9,5.8-13.6,8.6
	C222.1,418.5,221.3,418.4,220.5,418.5z"
    />
    <path
      class="st99"
      d="M250.6,400.6c0-0.3,0-0.5,0-0.8c6.3-3.8,10.8-10.6,18.8-12c-0.5,0.8-1,1.5-1.5,2.2
	C262.7,394.6,256.9,398,250.6,400.6z"
    />
    <path
      class="st100"
      d="M135,294.8c0,7.8,0,15.5,0,23.3c-0.8,2.5-1.9,4.7-4.4,5.9c2-4.1,2.2-8.5,2.4-13
	C133.1,305.5,134.2,300.2,135,294.8z"
    />
    <path
      class="st18"
      d="M779.2,412.5c4.1-4.7,9.2-8,14.9-10.5c0.8,0.9,0.5,1.7-0.3,2.2C789,407,785,411.3,779.2,412.5z"
    />
    <path
      class="st35"
      d="M600.7,339c1.1-2,3.3-1.8,4.9-2.1c5.5-1.1,10.5-3.7,16.1-4.6C615.4,336.8,608.3,338.5,600.7,339z"
    />
    <path
      class="st35"
      d="M621.7,332.3c5.1-3.2,10.2-6.4,15.4-9.5c0.7-0.4,1.7-0.2,2.6-0.3c-4.7,2.9-9.3,5.9-14.1,8.8
	C624.5,332,623.2,332.4,621.7,332.3z"
    />
    <path
      class="st101"
      d="M235.6,480.1c-1,3.2-3-0.2-4.4,0.7c-0.1-0.4-0.1-0.9-0.2-1.3c1.9-2.1-1.2-3.5-0.8-5.4
	C233.5,474.8,234.9,477.1,235.6,480.1z"
    />
    <path
      class="st28"
      d="M195.1,427.5c4.7-2.5,9.7-3.8,15-4.4C205.7,426.7,200.3,427,195.1,427.5z"
    />
    <path
      class="st28"
      d="M210.2,423.1c3.4-1.1,5.1-5.9,9.6-4.5C217.1,421.2,214.4,423.9,210.2,423.1z"
    />
    <path
      class="st102"
      d="M557.3,577.3c-3.4-0.2-6.6,0.7-9.7,1.7c1.2-2.1,2.8-3.7,5.2-4.4c0.4,1.3,1.6,1.2,2.7,1.4
	C556.2,576.1,557.6,575.6,557.3,577.3z"
    />
    <path
      class="st68"
      d="M455.2,643.5c-2.5,0-5-0.1-7.5-0.1C449.6,641.4,452.8,641.4,455.2,643.5z"
    />
    <path
      class="st103"
      d="M637.7,592.3c2.2,0.8,3.9,2.1,4.5,4.5c-0.1,0.4-0.3,0.6-0.6,0.8c-0.7,0.3-1.4,0.3-2.1-0.1
	c-1-1.4-2.9-2.3-2.5-4.5C637.1,592.7,637.3,592.4,637.7,592.3z"
    />
    <path
      class="st103"
      d="M631.4,590.1c0-0.2,0-0.4-0.1-0.6c2.8-0.6,4.6,1.1,6.3,2.8c-0.1,0.1-0.3,0.3-0.4,0.4
	C634.3,594.2,632.4,593,631.4,590.1z"
    />
    <path
      class="st21"
      d="M396.1,121.6c-1-2.4,1.5-2.8,2.3-4.3c0.5-0.1,0.9-0.2,1.4-0.2c0.1,1.4-0.9,2.2-1.6,3.1
	C397.1,120.1,396.7,120.9,396.1,121.6z"
    />
    <path
      class="st82"
      d="M299.3,330c2.4-0.2,3.9,1.3,5.2,3C302.2,333.1,300.9,331.2,299.3,330z"
    />
    <path
      class="st103"
      d="M644.9,601.4c-0.4,0-0.9,0-1.3,0c-2.2-0.7-2.9-2.1-1.9-4.2c0.1-0.1,0.3-0.3,0.4-0.4
	C643.9,597.8,644.7,599.5,644.9,601.4z"
    />
    <path
      class="st82"
      d="M304.5,333.1c1.7,0.1,3.2,0.3,3.7,2.3C306.5,335.3,305.3,334.5,304.5,333.1z"
    />
    <path
      class="st104"
      d="M720.7,396.9c14-10,29.7-17.2,43.8-27c2.2-1.6,4.5-3,4.7-6.1c4,0.4,5.7,3.2,4.2,7c-1.6,4.1-4.8,6.9-8.6,8.4
	c-4.8,1.8-8.8,5-13.4,7.3c-8.9,4.5-18,8.4-27.6,11.1c-0.7,0.2-1.4,0.5-2,0.8c-0.6,0.3-1.2,1.3-1.8,0.4
	C719.4,398.1,720.5,397.5,720.7,396.9z"
    />
    <path
      class="st105"
      d="M465.8,170.8c0-7.2,7.5-17.6,14.2-19.7c2.1-0.7,4.4-0.9,5.5,1.6c1.1,2.5-0.6,4-2.8,4.8c-3.1,1.2-5,3.6-7,6.1
	c-0.5,0.6-1.2,1.5-0.7,2.4c0.5,1,1.5,0.6,2.3,0.6c8-0.3,10.2,3.4,7,10.9c-3,7.2-8,11.6-15.5,13.3c-2.4,0.5-4.7,0.6-5.4-2.4
	c-0.6-2.7,1.5-3.7,3.7-4.3c4.2-1.1,7.9-3,9.7-7.4c0.8-2,0.4-2.9-1.8-2.4c-1.2,0.3-2.4,0.8-3.6,1.1
	C468,176.3,465.8,174.6,465.8,170.8z"
    />
    <path
      class="st106"
      d="M504.1,181.6c0.3,0.7,1,1.4,0.9,2c-0.4,3.6,1.5,6,3.9,8.3c0.7,0.6,1.3,1.4,0.8,2.3c-0.6,1.1-1.4,0.5-2.3,0.2
	c-3-0.9-4.1,2.1-6.1,3.2c-2.5,1.3-4.8,3-7.4,4.2c-7,3-13.4,7.4-20,11.1c-1.1,0.6-2.3,1.5-3.4,1.4c-5-0.6-8.5,2.2-12.2,4.9
	c-3.8,2.1-7.6,1.8-11.4,0.1c0.5-0.6,1.1-0.5,1.7-0.2c5.5-0.4,9.5-4,13.9-6.5c11.3-6.5,22.5-13.4,33.7-20.2
	C500.3,189.8,504.5,187.3,504.1,181.6z"
    />
    <path
      class="st107"
      d="M448.6,218.9c-0.6,0.1-1.2,0.1-1.7,0.2c-4.7-1.7-8.7-4.6-12.9-7.1c-4.8-2.8-9.3-5.8-14-8.7
	c1.4-1.1,2.5-0.6,4,0.2c7.1,4.2,14.3,8.2,21.2,12.8c1,0.7,2.2,1.1,3.3,1.6C448.5,218.3,448.6,218.6,448.6,218.9z"
    />
    <path
      class="st108"
      d="M187.9,284.3c-2.2,0.1-4-0.4-5.7-1.4c-1.6-0.9-3.6-1.5-3.5-3.9c0.2-2.2,1.8-3.1,3.5-4c3.5-1.8,7-1.6,10.5-0.3
	c2.2,0.8,3.3,2.7,3.4,5c0.1,2.1-1.9,2.4-3.3,3.1C191.2,283.6,189.5,284.5,187.9,284.3z"
    />
    <path
      class="st109"
      d="M755.8,315.9c2.9,5.6,5.7,11.3,8.7,16.9c0.9,1.7,1,3.2,0.3,4.9c-4.1,9.6-9,18.9-13.6,28.2
	c-1.2,2.6-2.5,5.2-3.8,7.7c-1.3,2.4-2.8,3.2-5.8,2c-7-2.9-13.3-7.3-20.2-10.4c-1.2-0.5-3.1-1.5-2.2-3.7c0.3-0.7,0.6-1.4,1.2-1.9
	c6.4-4.4,13-8.3,19.8-11.9c1.6-0.8,3-2,3.6-3.7c2.6-8.4,6-16.6,8.4-25.1C752.9,317.3,753.4,315.5,755.8,315.9z"
    />
    <path
      class="st110"
      d="M720.7,360.7c-0.5,0.3-0.9,0.6-1.4,0.9c-0.1-8,2.9-15.3,5.7-22.5c1-2.7,0.3-6.1,3.2-8.1
	c2.2-1.5,4.4-3.2,6.7-4.6c5.5-3.2,10.9-6.5,15.7-10.7c1.4-1.2,3.5-1,5.2,0.2c-1.5,0.8-1.4,2.5-2.1,3.7c-4.2,5.5-10.3,8.6-16.1,11.9
	c-4,2.4-6.7,5.4-7.8,10c-1,4.5-3.1,8.7-4.4,13.1C724.8,357.2,722.3,358.6,720.7,360.7z"
    />
    <path
      class="st111"
      d="M225.8,495.6c0.9,0.6,0.7,1.5,0.7,2.4c0,13.2,0,26.5,0,39.7c0,0.5-0.1,0.9-0.2,1.6
	c-7.3-4.2-14.3-8.2-21.4-12.3c-13.7-8-27.9-15.2-41.2-23.9c-1.7-1.6-1.9-3.7-1.9-5.8c0-6.4-0.1-12.7,0-19.1c0.4-1.8-0.1-3.6,0-5.4
	c0.3-3.8-0.4-7.6,0-11.5c0.1-0.4,0.3-0.8,0.6-1.1c1.9-1.3,3.6-0.4,5.2,0.5c13.5,7.8,27.2,15.3,40.7,23c5.1,2.9,9.9,6.3,15.1,9
	C224.7,493.4,225.3,494.5,225.8,495.6z"
    />
    <path
      class="st112"
      d="M162.8,460.5c0.9,4,0.9,8,0.5,12c-3.5,1.5-5.2-1-7.1-3.2c0,0.6-0.3-0.1-0.2,0.1c0.6,2.8-1,3.9-3,2.6
	c-2.9-1.8-6.3-2.2-9.2-3.8c-0.5-0.2-0.8-0.6-1.2-1c-1.7-2.1-5-2.5-6-5.4c0-1,0-1.9,0-2.9c0.9-1.1,1.9-1,2.9-0.2
	c2.3,2,5.3,2.6,7.6,3.8c-1.4-0.9-3.5-1.2-5.2-2.3c-1-0.7-2.4-1.2-2-2.8c0.9-3.7-1.7-3.7-4.1-3.9c0-1.9,0-3.9,0.1-5.8
	c0.7-1.1,1.4-0.7,2.2-0.2c2.6,1.6,5.5,2.7,8,4.1c-2.2-1-4.6-2.3-7-3.5c-1.1-0.6-2.5-1.3-2.5-3c0.9-1.6,0.2-3.3,0-4.9
	c1.5-1.6,2.9-0.4,4.2,0.2c1.6,0.8,3.2,1.6,5,2.1c0.9,0.4,1.4,1.2,2.2,1.7c8.6,5.5,18,9.5,26.7,14.7c8.7,5.2,17.4,10.4,26.2,15.5
	c7.7,4.5,15.2,9.4,23,13.6c0.8,0.5,1.8,0.9,2.6,1.5c0.2,2-0.2,4-0.7,5.9c-11.5-5.5-22.1-12.9-33.4-18.8c-7.5-4-14.5-9-22.5-12.2
	C167.5,463.7,165.9,460.8,162.8,460.5c-2.4-0.5-4.6-1.8-6.7-3C158.3,458.7,160.4,460,162.8,460.5z"
    />
    <path
      class="st113"
      d="M143.5,466.5c0.2,0.1,0.4,0.3,0.5,0.4c3.4,2.7,7.4,4.3,11.5,5.8c3.1,1.1,6.2,1.8,7.9,5
	c0.1,8.5,0.2,17,0.3,25.5c-4.3-1-8.3-2.6-11.4-5.9c-1.7-2.8-4.8-3.4-7.5-4.5c-0.3-0.2-0.5-0.5-0.8-0.8c-2.6-2.1-5.7-3.7-8.2-6
	c0.7-1.4,0.8-3,0.8-4.6c1.2-0.8,2.1,0,3,0.5c-1-0.5-1.9-1.3-3-0.5c-3.5-3.3-0.4-6,0.7-9c0.7-0.9,1.5-0.7,2-0.4
	c-1.4-0.4-2.4-1.7-3.3-3.1c0.7-1.2,0.8-2.5,0.6-3.8c1.1-1.3,2.2-0.6,3.2,0.1C140.9,465.9,142.1,466.4,143.5,466.5z"
    />
    <path
      class="st114"
      d="M147.2,445.4c-0.8-0.3-1.3-0.8-1.7-1.5c-1.9-1.2-3.8-2.1-5.8-3.1c-0.9-0.4-1.9-0.6-1.7-2
	c-0.1-1.2-0.8-2.1-1.6-2.9c-1.1-1.2-2-2.7-0.9-4c1.3-1.4,2.9-0.1,4,0.7c4.1,2.8,8.4,5.3,12.8,7.7c8,4.5,15.9,9.2,24,13.6
	c9.9,5.3,19,11.9,29,17c5.3,2.7,10.3,6.1,15.6,8.8c4.3,2.2,6.7,5,5.6,10c-1,0.5-2,1.1-2.9,0c0.4-1.8-1.2-2.3-2.2-2.9
	c-17-10.4-34.5-20.1-51.6-30.4c-6.7-4-13.8-7.3-20.7-10.9C148.4,445.3,147.8,445.1,147.2,445.4z"
    />
    <path
      class="st115"
      d="M135.8,453.8c0.8-0.8,0.5-3.5,2.9-1.7c1.6,1.2,4.7,1.7,3.1,4.6c-1.2,2.1-0.2,2.4,1.3,3.2
	c2.4,1.4,5.3,2,6.8,4.8c-4.4-1.9-8.9-3.8-13.3-5.7C135.5,457.4,135.9,455.5,135.8,453.8z"
    />
    <path
      class="st116"
      d="M136.5,445.3c4.6,3.1,10.1,4.7,14.1,8.9c-5.6-0.3-9.9-4-14.7-6.3C136,447,134.6,445.8,136.5,445.3z"
    />
    <path
      class="st117"
      d="M135.9,468.9c3,1.1,5.7,2.7,7.7,5.9c-3,0.5-4.2-2.2-6.3-2.3C135.7,471.7,136,470.3,135.9,468.9z"
    />
    <path
      class="st118"
      d="M143.5,466.5c-1.2,1.2-2.4,0.9-3.6,0c-1-0.8-2-1.4-3.3-1.4c0.3-1-0.8-2.1,0-3.1
	C138.9,463.5,141.5,464.5,143.5,466.5z"
    />
    <path
      class="st119"
      d="M144.9,492.8c4.4-0.1,7,1.5,7.5,4.5C149.8,495.8,147.1,494.8,144.9,492.8z"
    />
    <path
      class="st120"
      d="M138,438.8c2.1,2.2,5.9,2,7.5,5c-3.4-0.1-5.7-3.1-9-3.5C135.8,438.8,135.8,438.8,138,438.8z"
    />
    <path
      class="st121"
      d="M135.8,486c3.2,1.3,6.4,2.7,8.2,6C141,490.5,137.5,489.5,135.8,486z"
    />
    <path
      class="st122"
      d="M213,546.7c2.4,1.3,4.8,2.5,7.5,4c-2.2,1.3-3.8,2.9-6,3.4c-3-0.9-5.6-2.5-8.3-3.9c2,1.1,4.1,2,6.1,3.2
	c0.7,0.4,1.3,0.8,0.8,1.7c-5.5,4.6-12.3,7.2-18,11.5c-0.9,0.7-1.7,0-2.4-0.3c-2.3-1.2-4.5-2.5-6.8-3.8c0-1.1,0.5-2,1.4-2.5
	c1.1-0.6,0.8-1.2,0.2-2.1c-3.2-4.3-3.2-4.3,1-7.9c-1.4,1-2.9,1.9-4,3.2c-2.1,2.5-4.3,3-7.3,1.7c-2.4-1-4.6-3.8-4.2-6.8
	c0.2-2.3,1.8-3.5,4.2-3.3c0.8,0.1,1.3-0.7,1.8-1.2c1.5-1.3,3.2-1.8,4.7-0.5c1.9,1.6,0.5,3.1-0.4,4.2c1-2.6,5-2.2,6.1-5.1
	c1.3-0.7,2.8-1.1,3.9-2.1c2.5-2.2,4.7-2.1,7,0.3c1,1,3.1,1,2.5,3c-0.6,2-2.2,2.9-4.2,2.8c3.1,0,5.1-3.5,8.4-2.8
	c1.5,0.8,3,1.5,4.5,2.3C211.8,546.7,212.4,546.7,213,546.7z"
    />
    <path
      class="st123"
      d="M129.7,529.6c-6.7-3.2-12.8-7.5-19.3-11.2c-1.7-0.9-2.1-2.2,0-3.1c5.7-2.4,10.1-6.7,15.3-9.7
	c2.3-1.3,4.7-2.6,7-4.1c1.2-0.8,2.1-0.3,3,0.3c2.5,1.5,5.1,3,7.6,4.5c-0.8,3.1-2.7,1-3.8,0.9c1,1.2,3.7,0.1,4.2,2.5
	c0.4,1.6-1.1,2-2.1,2.6c-1.9,1.8-3.7,0.5-5.5-0.1c-0.2,0-0.4-0.1-0.5-0.1c0.7,0.1,1.4,0.2,2,0.6c0.7,0.4,1.4,0.9,2.2,1.1
	c0.8,0.2,1.8,0.2,2.6,0.6c1.2,1.3,3.7,1.7,2.9,4.4c-2.3,3-4.6,0.4-6.7,0.1c0.9,0.9,3.5,0.5,3.1,3.1c-2.8,2.8-4.5,2.8-7-0.1
	c1.2,1.2,0.8,2.2-0.2,3.2c-0.9,0.8-2,1.1-3.1,0.7c-1.8-0.8-3.6-1.7-4.7-3.5c-0.5-0.9-1.4-1-2.2-1.2c-0.3-0.1-0.5-0.2-0.6-0.2
	c0.2,0.1,0.5,0.2,0.7,0.2c2.6,0.5,3.2,3.7,5.9,4.3c1.1,0.3,1.6,1.6,1.4,2.8C131.6,529.4,130.9,530,129.7,529.6z"
    />
    <path
      class="st124"
      d="M207.1,543.6c-1.6,2.5-5.2,2.1-6.6,5c-0.3,0.7-5.9-2.2-6-3.1c0-1,0.7-1.5,1.5-2c0.9-0.5,1.8-0.5,2.2,0.5
	c1,2,2.2,1.4,3.2,0.2c1.5-1.8-0.7-1.6-1.4-2.4c-2.6-2.7-4.6-2.4-6.4,0.7c-1,1.7-2.5,0.2-3.7,0.5c-0.2-0.1-0.3-0.1-0.5-0.2
	c-1.8-2-5-2-6.7-4.2c-1.4-0.7-1.8,0.4-2.6,1.3c-1.7,1.9-3.8,2.5-5.9,0.7c-2.1-1.8-4.7-2.7-7.1-3.9c-3.6-1.8-3.9-3.3-1.1-6.1
	c0.7-0.7,1.5-1.3,1.7-2.3c0.3-0.7,0.8-1.2,1.5-1.5c2.3,0.4,4-2.1,6.3-1.5c6.2,3.1,12.1,6.7,18,10.4c0.1,0.9,0.6,0,0.8,0.1
	C198.7,538.2,203.3,540.2,207.1,543.6z"
    />
    <path
      class="st123"
      d="M139.5,535.6c-3.1-1-5.2-3.6-8.1-4.8c0.3-2.1-1-5.3,3.2-4.7c1.1,0.2,1-0.9,1.5-1.3c0.9-0.8,1.8-1.2,3-1.3
	c1.6-0.1,2.7,0.7,3.2,2.1c0.6,1.8-1.2,1.8-2.1,2.5c-1.5,1.9,0.2,2.7,1.3,3.5c4.6,3.2,9.4,6,14.2,8.9c1.7,1,3.5,1.8,5.1,3
	c-6-3.4-11.8-7.1-17.7-10.7c-1.5-0.9-4.4-1.6-3-4.6c2.2-1.1,4.8-1.5,6.5-3.6c0.8-1,2.3-1.4,3.7-1c0.4,0.1,0.7,0.3,1,0.6
	c1.3,2,0.8,3.5-1.2,4.7c-0.9,0.6-2.1,1.1-1,2.5c0,0.5,0.2,0,0.2,0c-0.7-1-0.4-1.9,0.7-2.4c1.7-1.7,3.6-0.8,5.5-0.3
	c0.5,0.1,0.9,0.3,1.3,0.6c0.3,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6,0,1,0.1c2.4,0.8,4.4,2.1,5.5,4.6c0.6,1.4,2.1,2.1,3.5,2.6
	c1.5,0.6,2.5,1.4,1.9,3c-0.6,1.6-2,1.7-3.2,1c0.5,0.3,1.2,0.8,1.8,1.1c3.5,2,3.7,3.3,0.9,6.2c-0.9,0.9-1.4,2.5-3,2.6
	c-3.2-1-6.1-2.7-8.9-4.5c-2.4,0.2-4.5-0.8-6.5-2.1c-2.6-1.7-5.3-3.1-8-4.6C140.9,538.4,139.9,537.3,139.5,535.6z"
    />
    <path
      class="st125"
      d="M139.5,535.6c5.9,3.3,11.9,6.3,17.4,10.3c-0.5,0.6-1,1.4-0.3,2c1.3,1.2,3.1,2.3,0.8,4
	c-2.1,1.6-4.2,4.1-7.3,2.4c-6.7-3.7-13.2-7.6-19.8-11.3c-2-1.2-2.3-1.9-0.4-3.7C132.7,536.8,135.8,535.7,139.5,535.6z"
    />
    <path
      class="st124"
      d="M189.8,543c-0.3,1.6-1.3,2.8-2.8,3.2c-2,0.6-3,2.2-4.2,3.6c-0.7,0.8-1.3,1.5-2.5,1.3
	c-0.7-0.1-1.2-0.5-1.2-1.2c0-1.2,0.3-2.2,1.5-2.8c1.2-0.6,3.8-0.6,2.5-2.5c-1.2-1.8-3-0.6-4,1.3c-0.4,0.8-1.3,0.8-2,0.7
	c-1.4-0.3-2.7,0-3,1.4c-0.3,1.9,1.9,1.1,2.7,2.3c-0.7,0.8-1.5,1.6-2.5,2.8c1.9,0.6,4.6-2.4,5.2,1.5c0.1,0.7,2,0,2.5-0.8
	c1.7-3.2,5.6-4.1,7.5-7c0.6-0.9,1.9-0.9,2.7-0.1c0.8,0.8,0.2,1.6-0.6,2.1c-1.9,1.3-1.9,4.5-4.9,4.9c-0.5,0.1-0.7,1.4-0.1,1.6
	c2.3,0.7,2.7,3.9,5.8,3.4c-1.6,3-4.8,2.2-6.5,4.1c-6.5-3.8-12.9-7.5-19.4-11.3c0.2-1.8,1.4-2.9,2.8-4c1.5-1.2,2.5-2.7,0.3-4.1
	c-1.9-1.2-1-2.6-0.2-3.8c0.9-1.5,2.7-1.3,3.5-0.3c2.8,3,5.2,3.4,7.8-0.2c0.5-0.7,1.5-0.7,2.4-0.3C185.1,540.3,187.5,541.6,189.8,543
	L189.8,543z"
    />
    <path
      class="st126"
      d="M156,530.2c-0.3-0.2-0.5-0.5-0.8-0.7c-2.6-0.8-2.3-4.1-4.4-5.2l0,0c-1.1-0.8-2-1.6-0.2-2.6
	c0.3-0.2,0.4-0.2,0.4-0.3c-0.3,0.1-0.7,0.2-1.1,0.2c-0.7-0.3-1.2-0.8-1.5-1.4c-1-3.3-4-3.9-6.7-4.8c-1,0-2,0-2.9,0
	c-0.4-0.6-0.6-1.3-1.4-1.4c-0.9-0.9-2-0.9-3.2-0.9c-0.2-1.3,0.7-1.4,1.7-1.5c1.8,0.6,3.7,1.2,5.2-0.7c1.3,1,2.6,1.1,3.3-0.6
	c0.5-1.3,1.6-1.4,2.7-1.5c6.1,2.6,11.5,6.5,17.2,9.7c1.5,2.3,2.2,5.2,5.2,6.4c1.1,0.4,1.6,1.7,0,2.5c-0.2,0.4-0.5,0.9-0.7,1.3
	c-0.3,0.7-0.8,0.8-1.5,0.9c-0.2,0-1.1-1.7-1,0.4c0.1,2.4-2.7,3.1-5,1.4c-1.2-0.9-2.1-2.2-3.8-1l0,0C157,530.7,156.5,530.7,156,530.2
	z"
    />
    <path
      class="st127"
      d="M157.5,530.2c0.2-2.4,1.2-3,3-1.2c0.8,0.8,1.3,1.8,2.6,1.9c0.9,0,1.9,0.2,2.2-0.9c0.4-1.1-0.3-1.6-1.2-1.9
	c-0.8-0.3-1.9-0.2-2.1-1.3c-0.1-0.5,0.3-0.9,0.8-1c1.2-0.2,2.3-0.2,2.8,1.1c0.6,1.4,1.7,1.8,3,1.6c1.8,1.9-0.6,1.8-1.2,2.3
	c-3.1,2.5-2.9,2.8,0.5,4.9c2.3,1.5,5.4,1.4,7.2,4.1c0.8,1.2,3.1,0.6,3.8-0.8c1.2-2.1,2.8-2.2,4.7-2c0.7,1.1,0.2,1.8-0.8,2.2
	c-1.5,0.3-2.9,0.8-3.6,2.3c-1.3,2.9-2.9,2-4.6,0.4c-0.7-0.7-1.5-1.3-2.2-1.9c-0.7-0.6-1.5-1-2.4-0.4c-0.7,0.5-1,1.2-0.8,2
	c0.2,0.9,1,1.4,1.9,1.5c1,0.2,2.5-0.5,2.9,0.9c0.5,1.9-1.5,1.5-2.4,2.2c-1.8,1.6-3.6,3.3-5.4,5c-0.2-0.3-0.5-0.5-0.7-0.8
	c0.1-2,1.7-2.9,3-4c0.9-0.9,1.6-2.4,0.2-2.6c-2.8-0.4-3.7-3.8-6.6-3.8c-0.8,0-1.5-0.5-1.1-1.4c0.4-0.8,1.2-0.9,1.9-0.4
	c1.1,0.8,2.3,1.5,3.7,1.7c0.6,0.1,1.3,0,1.6-0.7c0.1-0.3,0-0.9-0.2-1C163.6,537,162.2,531.4,157.5,530.2z"
    />
    <path
      class="st127"
      d="M150,521.2c0.5-0.4,0.8-1,1.4-1.2c1.2-0.5,3-1.7,3.7-0.2c0.7,1.7-1.5,1.9-2.6,2.3c-1.2,0.4-2.7,0.2-1.6,2.2
	c-1.3,0.3-2.9,0.5-3.7,1.6c-1.8,2.7-4.3,2.3-6.8,2.2l0,0c1.3-1.1,1.7-2.2,0-3.1c-1.3-0.7-2.8-0.8-3.1,1.1c-0.3,2.2-2.2,2.7-3.2,4
	c-1.7-1.4,1.8-2.7-0.1-3c-2.3-0.4-1.8,2.4-2.5,3.8c-0.6-0.4-1.2-0.8-1.7-1.2c2.3-2.5,0.2-3.3-1.5-4.3c-1.1-0.6-3.5,0.4-2.8-2.6
	c0.2-0.8-1.7-0.6-2.6-0.7c-0.9-0.1-2.1,0.5-2.6-0.7c-0.3-0.6,0.2-1.2,0.7-1.6c0.9-0.8,2.1-1.1,3.1-0.3c1.7,1.4,4,2,5.3,3.9
	c0.7,1,1.7,1.4,2.9,1.5c1,0.1,1.8-0.6,2-1.4c0.4-1.3-0.8-1.2-1.6-1.5c-0.9-0.2-2.1,0.2-2.4-1.2c0-0.2,0.4-0.7,0.6-0.8
	c1.2-0.3,2.7-0.6,3.4,0.7c1.8,3,4.3,2.4,6.8,1.4c0.2,0,0.3,0.1,0.5,0.1c1.5,1.5,2.9,1.6,4.4-0.1C147.1,520.9,148.5,520.3,150,521.2z
	"
    />
    <path
      class="st128"
      d="M186,578.9c-1.6,0.7-3.1,1-4.9-0.1c-12.5-7.4-25-14.7-37.5-22c-0.7-0.4-1.3-1.2-1.9-1.8
	c0.3-0.3,0.7-0.4,1-0.2c11.8,7,24.4,12.6,35.7,20.6c3.2,2.3,7.2,2.4,10.7-0.1c0.4,0.5,0.4,0.9,0.1,1.4l0,0
	C188.6,578.3,187.3,578.6,186,578.9z"
    />
    <path
      class="st127"
      d="M169.5,527.2c0.5-2-1.3-1.3-2.1-1.7c-3.6-1.3-4.2-3.9-3.1-7.3c3.8,2.2,7.8,4.1,11.2,6.8
	C173.8,526.5,172.5,529.1,169.5,527.2z"
    />
    <path
      class="st129"
      d="M189.1,576.8c0-0.5,0-1-0.1-1.4c4.4-2.7,8.4-6.1,13.5-7.5C199.6,573.1,194.6,575.2,189.1,576.8z"
    />
    <path
      class="st124"
      d="M213.1,555.1c-3.6,0-5.3-3.8-8.7-4.3c-0.7-0.1-2.4-1.2-1.1-2c1.4-0.8,2.2-2.9,4.2-2.6c0.2,0,0.5,0.4,0.5,0.5
	c-1.8,5.3,3.3,4.3,5.5,6c0.5,0.4,0.9,0.8,1.1,1.4C214,554.5,213.6,554.8,213.1,555.1z"
    />
    <path
      class="st127"
      d="M147.1,508.6c-0.7,1-1.2,2.3-2.1,3c-1.3,1-3,1.5-3.9-0.8c0.7-0.2,2.1-0.4,1.5-1c-0.8-0.9-3.1-2-3.5-1.1
	c-0.6,1.1-0.8,1.3-1.7,0.8c-0.7-0.4-1.2-1-1.1-1.8c0.2-1.1,1.1-1.2,1.9-1.4c1.7-0.5,3.3,1.5,5,0.1
	C144.5,507.1,145.8,507.8,147.1,508.6z"
    />
    <path
      class="st130"
      d="M142.7,554.9c-0.3,0-0.7,0.1-1,0.2c-4.1-2-8.1-4.3-11.9-6.8c0-0.5,0-1.1,0.1-1.6c3.3,1.6,6.6,3.2,9.8,4.8
	C141.1,552.2,142.5,553,142.7,554.9z"
    />
    <path
      class="st131"
      d="M194.4,535.6c-0.1,0.5-0.3,0.9-0.3,1.4c0,1.3-0.5,2.1-1.9,2.2c-0.4,0-1.1-0.3-1.2-0.5
	c-0.2-1.1,0.8-1.7,1.4-2.4c0.3-0.3,0.8-0.6,1.2-0.8C193.8,535.5,194.1,535.6,194.4,535.6z"
    />
    <path
      class="st124"
      d="M213,546.7c-0.6,0.9-1,2.6-2.3,1.8c-1.5-0.9,0-1.8,0.8-2.6C212.1,546.2,212.6,546.5,213,546.7z"
    />
    <path
      class="st129"
      d="M203.9,566.2c1.5,0.8,2.7-0.3,4.1-0.6c-1.2,1.6-2.5,3-4.7,2C203.2,567,203.2,566.4,203.9,566.2z"
    />
    <path
      class="st132"
      d="M595.5,243c1.8-6.4-0.1-12.9-0.2-19.3c-0.1-5.1,2.1-7.8,6-10c7.6-4.4,15.1-8.9,22.7-13.4
	c1.1-0.6,2.2-1.1,3.4-1.5c0.7-0.3,1.7-0.2,1.7,0.8c0.2,3.5,2,7.1-0.6,10.5c-3.8,0.4-6.1,3.5-9.4,5c-5.7,2.6-10.8,6.6-16.2,10
	c-0.5,0.3-1.1,0.7-0.9,1.9c4.6,0,7-4.8,11.4-5.1c0,1.4,0,2.7,0,4.1c0,0.6-0.4,1.7-0.7,1.5c-2.8-1.6-4,3-6.9,1.4
	c-1.5-0.8-1.3,1.1-1.3,1.9c0.2,2.8,0.6,5.6,1,8.4c0.3,0,0.6,0,0.9,0c0-1.8-0.1-3.6,0-5.4c0-1.1,0.2-2.5,1.7-2.5
	c1.6,0,1.6,1.3,1.6,2.5c0,2.2,0,4.5,0,6.7c0,2.2-0.3,4-3.3,3.7c-1.9-0.2-1.2,1.5-1.5,2.6c1.7,0.2,3.4-2,4.5-0.2
	c0.9,1.4,0.7,3.4-0.5,5c-0.3,0.5-1.1,0.9-0.5,1.5c0.5,0.5,1.4,0.4,1.6-0.1c1.5-3.2,5.7-3.6,7.2-7.2c-1.5-0.7-2.8-0.8-3.8,0.8
	c-0.4,0.6-0.7,1.5-1.6,1.1c-0.8-0.3-0.8-1.2-0.9-1.9c-0.8-5.1,0.8-10.2,0.4-15.3c0-0.6,0.3-1.3,1.1-1.2c0.8,0.1,0.9,0.8,0.9,1.4
	c0,0.7,0.1,1.5-0.1,2.2c-0.8,2.4,0.7,2,1.8,1.5c2.3-0.9,4.5-2,6.8-3c0.8-0.4,1.9-1.2,2.6,0.2c0.5,1,0,2.1-1.1,2.4
	c-2.3,0.6-3.9,1.6-4.5,4.2c-0.2,1.2-1.8,0.9-3,0.9c-1.3-0.1-2.1,0.7-2.5,1.9c-0.1,0.3,0.2,1,0.6,1.2c1.2,0.9,1.7-0.3,2.5-0.8
	c2-1.3,4.3-2.1,5.4-4.8c0.5-1.3,2.4-1.4,2.8,0.8c0.2,1,0.4,2.2,0,3c-1.1,2.2-2.2,4.2-0.4,6.7c0.7,1-0.8,1.9-1.9,2.2
	c-0.9,0.3-1.8,0.4-2.8,0.9c-3.6,1.9-6.3,3.9-4.7,8.7c0.6,1.5-0.4,3.1-2.4,3c-1.9-0.1-1.2-1.9-1.3-3c-0.2-1.2,0.3-2.5-1.1-3.7
	c-1.4,2.2-3.1,3.8-5.7,4.3c-1.9,0.3-1.1,1.8-0.7,3c1.1,3.6-2.4,8.2-6.1,7.9c-2-0.2-2.3-1.9-2.1-3.4c0.4-2.5,0.7-4.9,0.6-7.4
	c0-1.5,0.6-2.3,2.3-2.2c3.2,0,4.9-2.6,8.2-4.3c-2.6-0.5-4.4,1.4-5.5-0.4c-1.3-2,1.4-2.5,2-3.9c-1.5-0.9-2.8-2.5-4.3-0.1
	c-0.4,0.7-1.1,1-2,0.7c-1.1-0.3-1-1.3-1.1-2.2C595.6,245.9,596.8,244.4,595.5,243z"
    />
    <path
      class="st132"
      d="M629.9,225.8c0,11.3,0,22.7,0.1,34c0,2.4-0.6,3.9-2.8,5.1c-2.8,1.5-5.4,3.5-8.1,5.3c-1.2,0.8-2.4,1.6-3.4-0.2
	c0.7-1.3,2.1-1.7,3.3-2.4c2.6-1.4,4.7-3,2.6-6.4c-0.6-1-0.2-2,0.5-2.7c0.4-0.4,1.2-0.8,1.6-0.7c1.3,0.3,0.9,1.6,1,2.5
	c0.1,0.7-0.1,1.5,0.6,2.1c1.4-0.4,0.8-1.6,0.9-2.5c0.6-3.7-1.3-7.8,1.6-11.1c0.1-0.1,0.1-0.3,0.1-0.3c-2.4-2.5-3.1-4.9-0.1-7.5
	c0.4-0.3-0.6-0.9-1.2-1.1c-0.9-0.4-1.5-1.5-1.1-2.1c1.9-2.8,0.2-5.6,0.4-8.4C626.3,226.9,627.8,226.1,629.9,225.8z"
    />
    <path
      class="st132"
      d="M615.1,225.9c-0.4-4.6,3.3-9.5,7.6-10.3c1.3-0.2,2.2,0.6,1.9,1.5c-1.3,3.2,0.3,6.3,0.1,9.4
	c-0.1,1.3-0.3,2.8-1.9,2.7c-2.1-0.1-0.9-1.9-1.1-2.9c-0.1-1,0-2,0-3c0-0.9,0.7-2.2-0.8-2.4c-1.2-0.1-2.2,1-2.3,2
	c-0.1,1.5-0.8,3.2,0.2,4.7c0.7,1.2,0.9,2.4-0.4,3.3c-0.7,0.5-1.5,1.1-2.4,0.7c-1.5-0.7-0.9-2.1-0.9-3.3
	C615.2,227.6,615.1,226.8,615.1,225.9z"
    />
    <path
      class="st132"
      d="M629.9,213.8c0,0.7,0,1.5,0,2.2c-1,0.7-0.8,3.3-2.7,2.3c-1.7-0.9-1.2-2.9-0.5-4.4
	C627.6,212.2,628.8,212.5,629.9,213.8z"
    />
    <path
      class="st132"
      d="M616.5,256.6c0.1-1.7,0.2-3.4,1.9-3.2c1.6,0.2,1,2,1,3.1c0,1.1,0.3,2.8-1.5,2.7
	C616.1,259.1,616.7,257.4,616.5,256.6z"
    />
    <path
      class="st132"
      d="M621.2,253.4c0-1.2,0.8-2.4,2.5-2.1c1.5,0.2,1,1.5,0.9,2.4c-0.1,1.3-0.5,2.6-2.1,2.6
	C620.8,256.3,621.1,254.9,621.2,253.4z"
    />
    <path
      class="st132"
      d="M629.9,220.5c0,1.2,0,2.5,0,3.7c-1.3-0.4-3.9,1.4-3.6-1.8C626.5,220.2,627.9,219.7,629.9,220.5z"
    />
    <path
      class="st133"
      d="M612.6,170.4c4.1,2.6,8.1,5.3,12.3,7.8c4.9,2.9,5.2,6.1,0.2,8.9c-9.6,5.5-19.4,10.7-28.7,16.7
	c-3.8,2.5-7.1,2-11.3-1.4c8.9,3.4,13.9-2.9,19.9-6c5.6-2.9,11.2-6.1,16-10.2c0-0.5,0-1.1,0.3-1.4c3.2-2.6,1.2-3.9-1.3-5.1
	c-0.8-0.5-1.6-1.3-2.5-1.6c-2.1-0.8-4-1.9-5.9-2.9c-1.5-0.8-2.2-2.5-3.4-3.5c-0.3-0.5-0.4-1.1-0.2-1.7
	C609.6,168.1,611.1,170.2,612.6,170.4z"
    />
    <path
      class="st134"
      d="M612.6,170.4c-1.5,0.4-3-1.1-4.5-0.2c-1.6,1.4-3.3,1-5,0.4c-2.2-1.5-4.1-3.5-6.6-4.7c-2.3-1.1-4.5-1-6,1.4
	c-3.6,4.5-8.4,6.9-13.8,8.2c-0.7-2.2-2.8-1.9-4.4-2.3c0.4-3.8,3.9-4.6,6.4-6c3-1.6,5.8-3.3,8.4-5.4c3.2-2.9,6.6-0.6,9.9-0.6
	C602.2,164.3,607.4,167.4,612.6,170.4z"
    />
    <path
      class="st135"
      d="M608,177.6c-6.7,4.2-13.6,8.2-20.1,12.6c-4.4,2.9-7.7,3.6-10.9,1.5c-2.2-1.4-1.6-2.8-0.2-4.2
	c0.3-0.3,0.9-0.5,1.3-0.4c0.6,0.1,0.5,0.7,0.4,1.2c-0.7,2.3,0.4,2.1,1.8,1.3c7.9-4.4,16.2-8.1,23.4-13.7c0.3-1.6,1.6-1.3,2.7-1.3
	C607.8,175.1,608.8,175.9,608,177.6z"
    />
    <path
      class="st136"
      d="M587.3,162.9c-4.3,4.5-10.5,6.2-15.1,10.4c-0.3,0.2-0.6,0.4-0.8,0.6c-1.9,0.7-3.2,2.5-5.2,3.1
	c-2.6,1.5-5.1,3-7.9,4.2c-2.4,1.1-2.5,1.7-1.8,4.5c-1.4-0.9-3.4-0.8-3.6-2.8c-0.1-1.7,1.3-2.6,2.6-3.4c7.8-4.8,15.6-9.6,23.6-14.2
	c2.2-1.3,4.5-2.5,6.6-4C586.4,161.8,587.2,161.9,587.3,162.9z"
    />
    <path
      class="st137"
      d="M576.7,175.5c1.2-2.2,3.4-3.6,5.4-4.2c3.1-0.9,4.9-3.5,7.6-4.7c1.2-1.2,2.4-0.7,3.5,0.2
	c1.8,4,1.4,5.1-2.4,6.9c-1.3,0.6-2.6,1.4-3.7,2.4c-3.9,3.4-4.5,3.4-9.1,0.9C576.9,177.1,577,176.1,576.7,175.5z"
    />
    <path
      class="st138"
      d="M596,190.9c1.4-0.1,2.9-1.7,4.7-2.5c2-0.9,3.2-0.4,4.1,1.1c1,1.6-0.6,2.4-1.6,3.2c-4.5,3.4-6.3,3.5-9,0.7
	c-0.6-0.6-1.6-1.1-1.2-2.1C593.3,190.2,594.4,190.7,596,190.9z"
    />
    <path
      class="st139"
      d="M620.2,178.4c2.7,0.1,5.7,1.6,6,3.5c0.4,2.6-3.4,2.8-5.3,4.2c-1.6-0.6-2.7-2-3.9-3.2
	C615.8,179.8,617.6,178.8,620.2,178.4z"
    />
    <path
      class="st140"
      d="M577.9,177c3.5,1.2,6.6,1.1,8.5-2.7c0.7-1.4,2.1-1.1,3.2-1.5c2.9-0.9,4.2-2.5,2.8-5.5c2.2-1,3.3,0.5,4.6,1.8
	c1,2,1,3.4-1.5,4.3c-1.9,0.7-3.7,1.9-5.4,3.2c-2.8,2.1-5.8,3.4-9.4,2.7C579.8,178.5,578.5,178.3,577.9,177z"
    />
    <path
      class="st141"
      d="M580.7,179.4c5.6-0.8,9.8-4.5,14.8-6.8c1.6-0.7,0.8-1.8,0.9-2.8c2.3-1.8,3.9-0.3,5.6,1.1
	c0.5,1.1,0.5,2.1-0.5,3c-1.6-1-3.1-1.1-4.8,0c-2.4,1.7-5.2,2.9-7.4,4.7C586.4,180.8,583.8,181.2,580.7,179.4z"
    />
    <path
      class="st142"
      d="M612.1,174.1c2.6-0.3,3.9,2.1,6,3c-0.2,3.3-1.7,4.5-5.7,4.5c-2-0.6-2.7-2.3-3.5-3.9
	C608.2,175,610,174.4,612.1,174.1z"
    />
    <path
      class="st143"
      d="M601.4,173.8c0-0.9-0.1-1.7-0.1-2.6c0.2-1,0.7-1.5,1.7-1.6c1.7-0.1,3.5-0.4,5.1,0.6c0.4,0.4,0.7,0.7,1.1,1.1
	c2.6,4.1-1.8,3.2-3.2,4.5c-0.7,1.6-1.6,0.6-2.4,0.2C603.1,175,602.7,174,601.4,173.8z"
    />
    <path
      class="st144"
      d="M587.3,162.9c-0.8-0.2-1.4-0.6-1.6-1.5c3.7-3.1,7.4-2.7,11.2-0.1c-0.8,0.8-1.8,0.9-2.7,0.6
	C591.7,160.9,589.5,161.8,587.3,162.9z"
    />
    <path
      class="st145"
      d="M612.3,181.6c2.2-1.2,4.6-2,5.7-4.5c1,0,1.9,0.3,2.2,1.4c-1.3,1.3-2.6,2.6-3.2,4.5
	C615.4,182.7,613.6,183,612.3,181.6z"
    />
    <path
      class="st146"
      d="M606.1,175.7c0.6-1.8,4.1-1.6,3.2-4.5c1.7,0.2,1.7,2.1,2.8,2.8c-1.1,1.2-2.2,2.4-3.2,3.6
	c-0.3,0-0.5,0-0.8-0.1C607.8,176.5,607.3,175.8,606.1,175.7z"
    />
    <path
      class="st133"
      d="M576.1,197.2c2.9,0.4,4.9,2.5,7.2,4C580.3,201.1,578.4,198.8,576.1,197.2z"
    />
    <path
      class="st134"
      d="M566.2,177c1.5-1.4,2.8-3.3,5.2-3.1C569.9,175.4,568.6,177.1,566.2,177z"
    />
    <path
      class="st147"
      d="M517.4,369.8c0,21.1,0,42.2,0,63.4c0,1.6-0.2,3.3,0.5,4.8c-2.1,5-6,7.7-10.9,9.9
	c-10.2,4.5-20.2,9.6-30.3,14.5c-2.5,1.2-4.9,1.8-7.5,0.5c0.1-0.8,0.5-1.4,1.2-1.8c2.6-2,3.3-4.7,3.3-7.9c0-9.6,0-19.2,0-28.8
	c0-2-0.2-4,1.1-5.8c0-0.2,0-0.5-0.1-0.7c0.1-0.4,0.3-0.4,0.7-0.2c1-0.2,1.4-0.9,1.3-1.9c-1-11-0.1-22-0.5-32.9
	c-0.1-3.1,2.6-5.1,2.7-8c0-0.2,0.6-0.5,0.9-0.5c0.7,0.1,1,0.8,0.9,1.4c0,2.9,1.8,3,4,3c8.6,0,17.2-0.2,25.8,0.1c4,0.1,5.7-1.3,6-5.1
	C516.7,372.4,516.5,370.9,517.4,369.8z"
    />
    <path
      class="st148"
      d="M474.8,418.6c0.8,1.1,0.6,2.4,0.6,3.6c0,10.9,0.1,21.8,0,32.8c0,4.7-1.6,6.6-6.2,7.9
	c-4.7-1.4-7.3-5.4-10.7-8.4c-10.9-9.9-22.2-19.5-32.6-29.8c-0.7-1.3-1.4-2.6-3.1-2.8c-1-0.3-1.8-0.8-2.2-1.9
	c0.6-0.7,0.2-2.8,1.9-1.8c1.9,1.1,3.2-0.2,4.8-0.4c0.3,0.4,0.7,0.7,1,1c-0.3-0.4-0.7-0.7-1-1c0.2-2.5,1.8-2.5,3.4-1.6
	c3.6,2.1,7.6,1.7,11.3,1.1c5-0.8,10.3,1.5,14.9-2c0.2-0.2,1,0.1,1.3,0.4c1.1,1.1,2.2,2.4,3.8,1.1c1.6-1.3,2.1-3,1.1-5
	c-0.2-0.4,0.1-1,0.1-1.4C466.8,413.7,470.3,416.8,474.8,418.6z"
    />
    <path
      class="st149"
      d="M375.6,378.8c0-0.3,0-0.6,0.1-0.9c2.3-0.1,1.8,1.9,2.3,3.2c0.4,1.9,1.7,3.1,3.1,4c6.2,3.8,10.3,10,16.2,14.1
	c0.1,0.1,0,0.4-0.1,0.7c-0.6,3.8-3.2,5.8-6.3,7.8c-5.2,3.4-11.1,5.7-15.3,11c-3,3.8-7.6,5.4-12.7,4.1c-5-3-9.3-6.9-12.9-11.5
	c-2.5-3.1-1.7-5.7,2.2-9.3c7.1-6.5,14.3-13,21.5-19.5C375.1,381.4,376.1,380.5,375.6,378.8z"
    />
    <path
      class="st150"
      d="M363,422.8c4.6-0.2,8.4-2.9,11.9-5.2c1.9-1.3,3-4.6,5.5-5.5c3.8-1.4,6.2-4.7,10-6.1c2-0.7,4.1-1.9,4.6-4.4
	c0.2-1,1.2-1.5,2.3-1.7c1.6,1,3.1,1.9,4.7,2.9c-7.2,5.4-14.3,10.8-21.5,16.1c-2.4,1.8-4.7,3.6-7.1,5.3c-2.5,1.9-5,0.8-7.4,0
	C365,423.8,364,423.2,363,422.8z"
    />
    <path
      class="st50"
      d="M422.9,421.8c1.7-0.7,2.3-0.2,3.1,2.8C424.9,423.7,423.9,422.8,422.9,421.8z"
    />
    <path
      class="st151"
      d="M449.3,545.3c2.2-1.3,4.4-2.6,6.6-3.9c4.2-5.6,10.8-7.6,16.5-11c15.7-9.4,31.6-18.4,47.5-27.4
	c20.4-11.5,40.5-23.4,60.8-35c7.4-4.2,14.8-8.3,21.9-12.8c1.2-0.7,2.4-1.4,3.8-0.6c0.1,1,0.2,1.9,0.3,2.9c2.1,3.7,1.8,7.7,1.4,11.7
	c-0.3,2.4-2.1,4-4,5.2c-6.5,3.9-13.1,7.6-19.8,10.9c-2.8,1.4-5.1,3.4-7.8,4.9c-7.4,4.1-14.6,8.7-22.3,12.3l0,0
	c-5.3,4.2-11.7,6.8-17.5,10.1c-1,0.6-1.9,1.1-2.8,1.8l0,0c-8.7,5-17.3,10.2-26.1,14.9c-10.2,5.5-20.1,11.5-30.2,17.2
	c-6.4,3.6-12.5,8-19.4,10.7c-1.8,0.8-3.5,1.5-5.3,2.3c-4.7,0.6-6.2-0.4-6-4.8C447,551.5,445.8,547.8,449.3,545.3z"
    />
    <path
      class="st152"
      d="M449.3,545.3c-0.2,3.3-0.5,6.6-0.8,9.9c-0.2,3.2,1.3,4.6,4.5,4.3c-2.5,1.5-4.8,0.6-7-0.7
	c-14.3-8.3-28.6-16.6-42.8-25c-12.7-7.4-25.2-15.1-38-22.4c-14.9-8.4-29.2-17.6-44.1-25.9c-7.4-4.1-14.5-8.9-22-12.7
	c-5.2-2.6-6.2-6.6-5.7-11.7c-0.6-1-0.3-2.1-0.3-3.2c-0.1-2.7,1.2-3.1,3.4-2c8,4.6,15.9,9.3,23.9,13.8c12.3,7,24.4,14.4,36.7,21.4
	c18,10.1,35.6,21,53.5,31.2c12,6.8,23.6,14.2,35.5,21.4C447,544.3,448.2,544.8,449.3,545.3z"
    />
    <path
      class="st153"
      d="M606.5,454.6c-8.2,5.9-17.3,10.4-25.9,15.5c-11.6,6.9-23.2,13.8-34.8,20.4c-16.2,9.2-32.5,18.4-48.7,27.6
	c-8.6,4.9-17.4,9.6-25.8,14.9c-4.9,3.1-10.2,5.5-15.4,8.2c0.5-4.3-0.1-5-4-5.1c-1.4,0-2.7,0-4.1-0.1c-0.8-1.6,0.3-2.3,1.4-3
	c2.8-0.7,5.2-2.3,7.7-3.8c5-3,10.2-5.4,15.4-8c3.2-1.6,6.6-4.3,10-6.4c9.2-5.6,18.4-11.2,27.9-16.3c12.2-6.6,24.2-13.6,36.1-20.7
	c6.9-4.1,14.1-7.8,20.9-12.2c5.1-3.3,10.7-5.4,15.6-9.2c2.7-2.1,6.3-3.8,9.7-5.4c1.4-0.7,2.9-1.6,3.9-2.8c1.5-1.6,2.5-1.8,3.8,0.1
	c1.4,2,3.6,1.2,5.5,1.3C606.1,451.5,606.8,453,606.5,454.6z"
    />
    <path
      class="st154"
      d="M554.2,502.5c0.9-2.3,3.3-3,5.2-3.9c9.3-4.4,17.7-10.4,26.7-15.4c5.3-3,10.4-6.2,15.7-9.2
	c3.3-1.9,5.3-4.3,4.9-8.3c-0.2-2.7,0-5.5,0-8.2c2.3,2.2,4.6,4.5,7,6.6c2.7,2.4,2.1,4.4-0.3,6.5c-4.2,3.6-9.4,5.7-14.2,8.1
	c-7.8,3.8-15.2,8.3-22.7,12.6c-6.3,3.7-12.8,7-19,10.9C556.7,502.8,555.4,504,554.2,502.5z"
    />
    <path
      class="st155"
      d="M458.2,557.2c2.5-3.8,7.2-4,10.7-6.5c9-6.5,19.1-11,28.5-16.7c10.6-6.2,21.6-11.7,31.9-18.3
	c1.4-0.9,2.9-1.5,4.6-1.3c-2.5,3.5-6.5,4.8-10,6.9c-6.9,4-13.7,8.1-20.7,11.8c-14.6,7.5-28.2,16.9-43,24.1
	C459.6,557.5,459,557.9,458.2,557.2z"
    />
    <path
      class="st154"
      d="M534,514.5c0.2-2.3,2.3-2.5,3.7-3.2c5.5-3,10.5-6.8,16.5-8.8c-0.2,1.7-1.3,2.6-2.7,3.2
	c-5.4,2.4-10.1,6.3-15.8,8.4C535.2,514.3,534.6,514.3,534,514.5z"
    />
    <path
      class="st53"
      d="M296.3,456c-4.1-0.1-2.7,3.1-3.1,5.2c-1.3-2.6-0.5-5.4-0.6-8.2C294.4,453.3,295.4,454.6,296.3,456z"
    />
    <path
      class="st156"
      d="M681,548.2c-1.5-1-3-1.9-4.5-2.9c-3.2-2.4-3.1-4.7,0.4-6.9c3.2-2,6.6-3.9,9.9-5.8c0.6-2.9,2.8-3.6,5.3-3.7
	c0.9,0.2,1.6,0.8,1.9,1.7c1,4.1,4.4,5.8,7.7,7.6c6.1,3.4,12,7,18.1,10.2c1.5,0.8,2.2,2.3,3.1,3.6c0.4,2.1-0.6,3.8-2.3,4.7
	c-3.5,1.9-6.7,4.2-10.4,5.8c-2.1,0.9-3.8,0.8-5.6-0.3c-6.5-4.3-13.7-7.4-20.1-12C683.5,549.3,682,549.2,681,548.2z"
    />
    <path
      class="st157"
      d="M681,548.2c1.8,0.2,3.1,1.2,4.6,2.1c6.5,3.9,13.1,7.5,19.7,11.3c0.8,0.5,1.6,1.2,2.4,0.7
	c5.2-2.9,11.3-4.7,14.6-10.3c-0.8-2-0.5-4.2-1.2-6.2c-0.3-0.8,0.1-1.7,1.1-2c2.9,1.4,5.6,3.1,8.1,5.2c1.3,1.1,1.8,2,0.1,3.1
	c-5.8,3.9-11.5,7.8-17.8,10.8c-1.3,0.6-2.5,1.1-3.9,1.3c-7-1.4-12.1-6.4-18.3-9.5C687,552.9,683.6,551.1,681,548.2z"
    />
    <path
      class="st158"
      d="M692.1,529.4c-1.7,1.2-3.3,2.4-5.3,3.1c-1-0.1-2.1,0.1-2.9-0.3c-1.5-0.7-3.4-1.5-3.4-3.5
	c0.1-1.7,1.1-3.1,2.8-4c1.1-0.5,3.2-0.5,2.8-2.7c3.2,1,5.5,3.3,8.3,5C694.5,528.7,693.4,529.1,692.1,529.4z"
    />
    <path
      class="st159"
      d="M383.1,279.1c-0.2,2.8-0.2,5.7,0.1,8.5c0.3,3.8-1.2,6.6-4.6,8.6c-9.8,5.7-19,12.5-29,17.8
	c-6.6,3.5-13,7.3-19.8,10.4c-4.4,2.1-7.3,6-11.5,8.2c-2.7,1.4-5.5,2.3-8.5,2.6c-2.3-5.1-1.9-10.6-1.3-15.9c0.4-3.7,4.2-5.4,7.3-6.9
	c3.1-1.5,5.8-3.5,8.6-5.3c0.9-0.6,2.2-1.2,1.8-2.5c-0.4-1.5-1.8-1.5-3.1-1.6c-1.2,0-2.5,0-3.7,0c4.3-5.4,7-11.5,9-17.9
	c2.1-6.6,5.2-12.7,8.4-18.7c1.6-0.8,2.7,0.4,3.7,1.2c6.8,5.5,14.7,9.1,21.9,13.9c1.5,1,3.1,0.4,4.5-0.5c4-2.7,8.1-5.3,12.2-7.9
	c0.8-0.5,1.5-1.6,2.6-1c1.1,0.5,0.8,1.7,0.9,2.7C382.6,276.1,382.3,277.7,383.1,279.1z"
    />
    <path
      class="st160"
      d="M345.9,198c0.2,0.5,0.5,1,0.7,1.5c-11.5,19.9-23,39.8-34.5,59.7c-2.2,3.9-2.3,3.9-4.5,0
	c-11.1-19.2-22.2-38.5-33.3-57.8c-0.5-0.9-0.8-1.8-1.2-2.7c0.5-0.3,0.9-0.5,1.4-0.8c4.3,1.7,9,1.1,13.3,0.6c6.7-0.8,10.3,2,12.9,7.8
	c1.9,4.3,4.6,8.3,7,12.3c2.2,3.6,2.4,3.6,4.4,0.1c3.1-5.4,6.3-10.7,9.2-16.2c1.5-2.7,3.2-4.1,6.4-3.9c4.7,0.3,9.5,0.1,14.2,0.1
	C343.3,198.8,344.7,198.9,345.9,198z"
    />
    <path
      class="st161"
      d="M698.2,381c-0.4,2.6-2.6,4.1-4,6.1c-8.6-4-16.4-9.4-24.5-14.3c0.3-0.3,0.7-0.6,1-0.9c1.2-1.1,2.2-0.4,3.2,0.4
	c1.2,0.9,2.4,1.5,3.9,1.6c0.3,0.1,0.7,0.2,0.9,0.4c3.8,3.5,8.4,5.9,13,8.3c1.3,0.7,2.3,0.2,3.3-0.7
	C695.9,381.1,696.8,380.1,698.2,381z"
    />
    <path
      class="st162"
      d="M592.6,603.1c1.2,5.3,1.1,10.7,0.2,15.9c-1,6.2-8.6,9.2-13.9,5.9c-2.7-1.7-5.6-2.6-8.6-3.4
	c-2.8-0.8-4.5,1.3-6.4,2.8c0-0.6,0-1.2,0.1-1.8c2.1-6.4,6.4-8.1,12.2-4.9c1.5,0.8,3,1.6,4.7,2.2c5.9,2.8,9.3,1,10-5.5
	c0.2-2.5,0-5,0.1-7.4C591,605.5,590.9,603.9,592.6,603.1z"
    />
    <path
      class="st163"
      d="M559.4,625.6c0.2-2,0.4-3.9,0.8-5.8c1.5-7.1,7.8-9.3,13.6-4.8c-0.1,0.2-0.2,0.6-0.4,0.6
	c-3.5,0.2-6.3,2.3-9.2,3.9c-1.7,1.8-0.5,4.4-1.5,6.4c-0.7,1.4-0.2,4.2-2.6,3.7C557.3,629.1,560.3,626.8,559.4,625.6z"
    />
    <path
      class="st164"
      d="M597.6,670.6c-1,0-2,0-2.9,0c-3.2-1.5-6.5-2.9-9.6-4.7c-4.3-2.4-8.5-5.1-12.7-7.8c-1.2-0.7-2.2-1.7-2.4-3.2
	c6,3.4,12,6.8,18.1,10.1c4.1,2.2,8.3,4.6,13.3,3.8C601.3,671.2,601.2,671.2,597.6,670.6z"
    />
    <path
      class="st165"
      d="M564.2,619.4c2-4,5.5-4.9,9.6-4.5l0,0c1.6,0.9,3.1,1.9,4.8,2.7c1.2,0.6,3.2,1.2,2.3,3.5
	c-1.1,0.1-1.8-0.5-2.7-1c-7.2-4.1-8.8-3.8-14.1,2.4C564.1,621.5,564.1,620.5,564.2,619.4z"
    />
    <path
      class="st129"
      d="M186,578.9c1-0.8,1.8-2,3.2-2.1c1,1.8-0.4,2.2-1.6,2.8C187,579.4,186.5,579.1,186,578.9z"
    />
    <path
      class="st166"
      d="M400.6,719.9c-0.7-3.5,1.8-4.8,4.1-6.2c2.5-1.3,4.4-0.5,6.8,0.9c11.1,6.7,22.1,13.7,33.2,20.4
	c1.5,0.9,3.4,1.6,5.3,1.6c2.1,0.4,4.2-0.1,6.3-0.2c2,0,4.4-0.8,5.8,1.4c1.3,2.1-1.2,3.2-1.7,4.9c-3,2.1-6.2,4.1-9.5,5.7
	c-1.2,0.6-2.2,1-3.5,0.4c-4.9-1.9-9.1-4.9-13.4-7.8c-5.9-4.1-12.8-6.5-18.7-10.7c-3.5-2.5-7.3-4.5-10.9-6.7
	C403.2,722.2,401.6,721.5,400.6,719.9z"
    />
    <path
      class="st167"
      d="M500.9,721.5c1.8,6.8-3.5,8.8-7.8,11.3c-11.7,6.9-23.3,13.7-35,20.5c-3,1.7-5.9,3.7-9.5,4.1
	c-0.7-0.5-1.4-1.1-2.1-1.6c1.1-1.8,0.5-4.1,1.8-5.7c13.1-7.4,26.1-15,39-22.7c3.2-1.9,6.5-3.6,9.6-5.8
	C498.2,720.5,499.5,720.1,500.9,721.5z"
    />
    <path
      class="st168"
      d="M404.1,710.2c1.8-1.2-0.4-2-0.4-3c0-1.2-1.3-2.5,0.1-3.4c1.1-0.8,2,0.4,3,1c12.6,7.8,25.2,15.5,37.9,23.3
	c0.5,0.3,1.1,0.5,1.7,0.8c0.6,0.1,1.3,0.2,1.9,0.4c3.2,2,1.8,4,0.2,6.1c-1.5,1.1-2.9,0.4-4.2-0.4c-6.6-4.1-13.1-8.3-19.9-12.2
	c-5.5-3.2-10.7-7.1-16.5-9.8c-0.9-0.4-1.3-1.5-2.3-1.9C405.1,710.7,404.6,710.4,404.1,710.2z"
    />
    <path
      class="st169"
      d="M448.3,750.2c0.5,2.2,2.4,4.9-1.8,5.5c-9.5-7.2-20-12.7-30.1-18.9c-4.4-2.7-9.1-5-12.9-8.6
	c-1.6-1.5-2.4-2.9-0.5-4.7c3.8-0.6,6.5,2,9.1,3.9c4.5,3.3,9.5,5.6,14.2,8.5c7.2,4.5,14.6,8.8,21.8,13.2
	C448.2,749.5,448.3,749.9,448.3,750.2z"
    />
    <path
      class="st170"
      d="M405.7,710.9c5.1,1.4,9.3,4.7,13.8,7.3c7.7,4.5,15.3,9.3,23,13.9c1.7,1,3.1,2.6,5.2,2.8
	c1-0.9,2.1-1.4,3.4-0.7c1.1,0.8,0.8,1.7,0,2.5c-0.6,0.5-1.3,0.8-2,1.1c-6.5-1.6-11.6-5.9-17.2-9.2c-6.5-3.9-13.1-7.7-19.6-11.8
	c-2.4-1.5-4.4-3.7-7.6-3.2C404.5,712.6,406.7,712.3,405.7,710.9z"
    />
    <path
      class="st171"
      d="M447.8,749.9c-12.7-7.1-25-14.6-37.3-22.3c-2.4-1.5-4.9-2.9-7.5-4c-0.1-0.2-0.2-0.4-0.2-0.7
	c0.5-0.7,1.2-0.8,1.9-0.6c7.5,3,13.5,8.4,20.6,12c7.6,3.9,14.7,8.9,22,13.5C448,748.4,448.6,749,447.8,749.9z"
    />
    <path
      class="st172"
      d="M404.6,722.3c-0.6,0.2-1.3,0.4-1.9,0.6c-1.6-0.4-2-1.6-2-3C402.1,720.5,403.6,721,404.6,722.3z"
    />
    <path
      class="st173"
      d="M266.5,630.6c1.3-0.4,1.7,0.4,2.1,1.4c1.3,3.6,4.7,5,7.6,6.9c0.7-0.4,1.6-0.4,2.1,0.2
	c4.7,4.6,10.3,4,15.8,2.6c5-1.3,9.7-3.2,13.8-6.5c0.7-0.6,1.5-1.5,2.2-1.3c4.4,1.6,6.9-2.2,10.4-3.2c3.8-1.1,7.8-1.4,11.7-2.4
	c2.9-0.7,5.7-1.9,8.5-2.9c5.2,1.9,7.2,9.3,3.6,13.2c-1.8,2-4,3.4-6.5,4.2c-1.9,0.6-3.7,0.5-5.1-1.2c-0.9-1.2-1.5-2.8-3.5-2.2
	c-1.8,0.6-1.1,2.3-1.5,3.5c-0.1,0.4-0.3,0.7-0.5,1c-11.4,8.1-24.1,10.8-37.7,8.6c-4.9-0.8-10-2.2-14.1-5.4
	C270.7,642.5,268.4,636.7,266.5,630.6z"
    />
    <path
      class="st174"
      d="M291,661.5c16.5,7.7,32.6,6,48.5-1.7c8.3-4,14.3-10.7,18.9-18.6c2.1,0.5,4-3.5,6.3-0.3
	c2.4,3.3,2.8,9.3,0.8,12.4c-5.4,8.3-13.5,13.4-22.5,15.7c-13.5,3.4-27.5,4-41-1.1c-2.5-0.9-5-2.6-7.8-1.1c-0.3-0.2-0.5-0.5-0.8-0.7
	c-0.9-0.6-2.2-0.4-3.1-1.2c-0.8-0.7-1.3-1.6-1.1-2.6C289.3,661.2,290,660.8,291,661.5z"
    />
    <path
      class="st41"
      d="M291,661.5c-1.7,0.6-1.2,1.6-0.4,2.5c0.8,0.8,1.7,1.3,2.6,2c-4.6-0.7-8.7-2.3-11.4-6.3
	c-0.9-1.3-2.7-1.7-3.3-3.4c-0.4-1.2-1.5-2.4-0.3-3.8C282.5,655.5,286.8,658.5,291,661.5z"
    />
    <path
      class="st175"
      d="M241.5,342c0.1,0.2,0.2,0.4,0.2,0.6c-0.3,2.5,0.8,4.9,0.9,7.4c0.3,6.6-3.9,10.2-8.5,13.1
	c-10.3,6.2-20.8,12.3-32.3,16.1c-4.4,1.5-8.8,1.8-12.8-1.2c0-0.3,0.1-0.5,0.1-0.8c6.5-1.8,12.3-5.3,18.1-8.7
	c6.2-3.6,12.5-7.3,18.4-11.4C231.6,353.1,238.5,349.5,241.5,342z"
    />
    <path
      class="st176"
      d="M597.6,670.6c1.3-0.4,3.2,0.5,3.8-1.6c0.2-0.8,0.8-0.8,1.4-0.9c3.8-4,8.9-6.2,13.3-9.4
	c2.4-1.8,4.7-3.3,4.3-6.8c-0.1-1,0.6-1.9,1.5-2.4c1.9-0.7,3.8-1.5,5.4-3c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,1.8-1.6,2.3-2.8,3.2
	c-0.4,0.3-0.8,1-0.8,1.6c0.1,0.8,0.9,0.6,1.5,0.6c2.3-0.1,4.1,1.3,5.7,2.4c2.2,1.5,2.9,4,1.1,6.5c-3.1,4.3-6.7,7.3-11.9,9.5
	c-7.3,3-14.2,0.4-21.2,0.8C598.7,671.4,598.1,670.9,597.6,670.6z"
    />
    <path
      class="st177"
      d="M621.9,649.5c1.7,6-1.8,9.1-6.4,11.8c-4.1,2.4-8,5.3-12.7,6.8c4-6.4,2.2-13.1,1.2-19.8
	c-0.2-1.4-1.1-3.1-3-3.4c-2.6-1-5.2-2-7.6-3.5c-1.2-0.7-2.3-1.4-2.3-3c-0.7-1.6-2.7-2.1-3.8-3.7c1.8-1.2,3,0.2,4.1,0.8
	c5.9,3.2,11.8,6.5,17.6,9.8C613,647.5,617.2,649.1,621.9,649.5z"
    />
    <path
      class="st178"
      d="M631.4,590.1c1.9,0.9,3.9,1.8,5.8,2.6c0.8,1.5,2.4,2.5,2.6,4.2c-2.6,6.8-8.5,9.7-14.4,12.7
	c-4.3-2.8-8.2-6.2-12-9.6C619.7,597.3,625.6,593.7,631.4,590.1z"
    />
    <path
      class="st179"
      d="M580.8,621.2c-1.2-3.4-5.6-3.1-7-6.2c2.9,0.2,4.9,2.2,7.3,3.5c4.1,2,6.9,0.8,7.9-3.6c0.9-3.8,0-7.7,1.1-11.5
	c0.5-1.9-1.7,0.1-2-1c1.9-3.6-0.3-5.8-2.9-7.4c-4.6-3-9.4-5.6-14.1-8.4c-1.8-1.1-3.6-2.2-5.3-3.4c-0.4-0.3-1.4-0.5-0.8-1.2
	c0.2-0.3,1.1-0.1,1.7-0.2c7.5,3.6,14.9,7.4,21.4,12.6c2.5,2,3.7,5.5,4.5,8.8c0,4.2,0,8.4-0.2,12.7c-0.1,2.7-1.2,5.2-3.7,6.4
	C586,623.6,583.2,623.5,580.8,621.2z"
    />
    <path
      class="st180"
      d="M625.5,609.7c0.9-2.3,2.5-3.8,4.8-4.8c2.8-1.2,5.7-2.5,6.1-6.3c0.2-1.6,2.1-1.5,3.4-1.6
	c0.6,0.1,1.2,0.2,1.9,0.2c0.6,1.4,1.2,2.8,1.9,4.2c-0.1,0.9,0.8,2.1-0.3,2.8c-3.8,2.4-6.2,6.6-10.3,8.6c-1.8,0.9-2.7,1.9-3,3.7
	C628.2,614.4,626.2,612.5,625.5,609.7z"
    />
    <path
      class="st181"
      d="M596.7,656.4c0.1,1.5-1.1,2.8-2.9,3.2c-2.1,0.4-4.8-3.4-4.8-6.6c0-2,0.7-3.4,3-3.4
	C594,649.6,596.7,653.1,596.7,656.4z"
    />
    <path
      class="st182"
      d="M594.1,584.6c0,1.4-0.3,3.1-2.6,3.3c-1.8,0.2-4.9-3.9-4.8-6.3c0.1-1.8,0.7-3.5,2.8-3.8
	C591.8,577.6,594.1,580.9,594.1,584.6z"
    />
    <path
      class="st183"
      d="M576.1,574.7c-0.1,1.5-0.2,3.1-2.5,3.1c-2.1,0-4.8-3.3-4.6-6c0.1-1.6,0.3-3.4,2.6-3.3
	C574,568.7,576.1,571.5,576.1,574.7z"
    />
    <path
      class="st184"
      d="M591,638.4c3.6,1.8,7.2,3.4,9.9,6.5c-7.1,2.3-10.4-3.9-15.9-6.5C587.5,636.6,589.2,638,591,638.4z"
    />
    <path
      class="st185"
      d="M605.4,616.3c4-1.9,5.4-5.4,5.8-9.5c1.6,3.5-0.2,6.7-1,10c-0.4,1.5-2.1,1.8-3.3,2
	C605.4,618.9,605.4,617.5,605.4,616.3z"
    />
    <path
      class="st186"
      d="M447.8,749.9c0.2-0.8-1-1.2-0.5-2.1c3.2,0.2,5.2-2.2,7.8-3.5c1.3-0.7,2.6-1.3,3.9-2c4.3-4.8,9.8-8,15.3-11.2
	c6.9-4,13.8-7.9,20.5-12.3c2.2-1.5,4.7-2.3,7.1-3.3c1.9,1.6,1.2,3.2,0,4.8c-0.3,0.4-0.6,0.8-0.9,1.2c-4.9,2.8-9.8,5.6-14.8,8.4
	c-10.9,6.3-21.7,12.6-32.6,18.8c-1.5,0.9-3.2,2-5.2,1.6C448.1,750.1,447.9,750,447.8,749.9z"
    />
    <path
      class="st187"
      d="M424.5,165.4c0.9-3.7,0.9-7.5,0.7-11.2c-0.2-2.6,0.4-5.1,3.4-5.3c3-0.3,3.6,2.4,3.7,4.6
	c0.5,9.7,0.3,19.5-0.9,29.1c-1.1,8.8-6.1,10.6-14.4,6.2c-5.8-3.1-9.8-13.6-8.2-20.1c0.3-1.1,0.7-2.3,2-2.4c1.5-0.1,2.2,1.1,2.5,2.4
	c0.3,1.7,0.3,3.5,0.4,5.2c0.3,3.5,1.8,6.3,4.8,8c3.7,2,5.6,0.9,5.8-3.5C424.7,174.2,424.5,169.8,424.5,165.4z"
    />
    <path
      class="st23"
      d="M475.4,417.7c-0.2,0.1-0.4,0.1-0.7,0.2c-1.3-3-4.2-4.4-6.6-6.2c-12.2-9.3-24.4-18.5-36.6-27.7
	c-0.8-0.6-1.7-1-2-2.1c-0.6-2.2,1.2-3.2,2.5-4.1c13.1-9.7,26-19.7,39-29.5c1.3-1,2.7-1.9,4.4-0.9
	C475.5,370.7,475.5,394.2,475.4,417.7z"
    />
    <path
      class="st188"
      d="M475.6,347.2c-12.4,9.3-24.9,18.7-37.3,28c-2.9,2.2-5.8,4.4-8.6,6.5c-2.1-0.8-5-0.3-1.8-4.3
	c3-3.8,7-5.4,10.6-7.8c2.4-1.7,5.3-2.9,6.7-5.7c0.2-0.4,0.5-0.9,0.9-1.1c10-5.7,18.6-13.3,27.9-19.9
	C476.4,343.7,475.2,345.8,475.6,347.2z"
    />
    <path
      class="st50"
      d="M407.3,367.5c-0.2,0-0.5,0.1-0.7,0.1c-1.3,0.2-2.9,1.3-3.8,0c-0.8-1.2,1.5-1.8,1.5-3.1
	C405.5,365.3,406.5,366.3,407.3,367.5z"
    />
    <path
      class="st50"
      d="M396.8,360.7c-0.7,1-1.5,1.8-2.7,0.7c-0.4-0.3-0.7-0.8-0.4-1.3c0.9-1.2,2-0.5,3-0.1
	C396.8,360.2,396.8,360.5,396.8,360.7z"
    />
    <path
      class="st148"
      d="M450.8,400.5c-0.2,0.6-0.5,1.3-1.2,0.9c-0.9-0.5-1.5-1.3-1-2.5C449.3,399.5,450,400,450.8,400.5z"
    />
    <path
      class="st189"
      d="M738,488.2c4,1.6,6.8,3.6,7.1,8.8c0.7,14.8,0.2,29.7,0.4,44.5c0,2.9-1.2,2.7-3.2,1.5
	c-10.6-6.5-21.2-13-32-19.2c-11.3-6.5-22.7-12.9-33.9-19.6c-2.3-1.4-3.5-2.9-3.5-5.6c0-15-0.1-30-0.1-44.9c0-1.2-0.2-3.6,2-2.3
	c7.7,4.4,15.8,7.9,22.7,13.5c-1.5,4.4,1.3,5.9,4.5,7.6c11.6,6.2,23.1,12.7,35,19.1C737.7,490.4,736.7,488.9,738,488.2z"
    />
    <path
      class="st190"
      d="M738,488.2c-0.1,1.3,0.6,2,1.8,2.4c0.8,0.2,1.3,0.9,1.1,1.7c-0.2,1.1-1.2,1.1-2.1,1.1
	c-1.3,0.1-2.5-0.3-3.6-0.9c-12.7-6.9-25.4-13.7-38-20.8c-4.3-2.4-4.2-2.9,0.2-6.9C711.1,472.4,724.7,480.1,738,488.2z"
    />
    <path
      class="st191"
      d="M692.1,529.4c0.8-0.8,1.6-1.6,2.3-2.4c9.2,5.6,18.5,11.1,27.8,16.7c0.4,2.7,2.7,5.5,0.1,8.2
	c-7.4-4.3-14.8-8.5-22.2-12.8C696.3,536.9,692.4,534.6,692.1,529.4z"
    />
    <path
      class="st192"
      d="M707.2,531.7c-1.5-0.7-2.2-1.9-2.5-3.1c-0.3-1-0.2-2,1.2-2.2c1.8-0.2,2.3,1.1,2.7,2.5
	C708.8,530.1,707.8,530.8,707.2,531.7z"
    />
    <path
      class="st61"
      d="M378,381.1c-0.9-1-1-2.5-2.3-3.2c-0.2-0.7,0-1.4,0.8-0.8C377.9,378,378.7,379.3,378,381.1z"
    />
    <path
      class="st193"
      d="M579,254.3c2.2,1,4.7,2.5,4.5-2c0-1.2-0.1-2.8,1.7-2.8c1.4,0.1,1.9,1.5,2,2.8c0.3,4-0.9,7.9-0.5,11.9
	c0.2,2.2-0.1,4.5-0.2,6.7c-0.1,3.4-1.3,4.2-4.5,3.2c-1.5-0.5-2.9-1.5-4.3-2.1c-3.4-1.4-3.6-1.2-3.2,2.6c1.6,1.5,0.5,3,0,4.5
	c0.8,1.2,1.6,2.5,3,4.6c-3.2-1.4-5.4-2.1-7-4.2c-1-1.3-2.2-2.2-4.1-2.2c-6.6,0-11-3.6-14.5-8.8c0.7-1.1,1.8-1.1,2.9-1
	c2.7,1.4,5.6,2.4,6.9,5.5c0.4,1.2,1.7,2.9,2.3,1.5c1-2.5,3.1-2.9,4.9-3.9c1.5-0.8,1.1-2,0.6-3.2c-1.2-3.2-0.6-6.3,0.5-9.4
	c1.6,0.9,1.5,2.4,1.6,3.9c0.1,1.2,0.2,2.3,1.9,1.7c1.6-0.2,2.5,0.8,3.5,1.7c1,1,2,1.8,3.4,1.9c2.8-0.6,3.4-3.6,3.2-5
	c-0.3-2.2-3-1.6-4.9-1.3c-1.7,0.2-3.1-0.7-4.6-1c-1.7,0-2.9-0.9-4.1-2c0-2,0-4,0-6c1.5-0.7,1.5-2,1.5-3.3c0-1.5,0.3-2.9,2.4-2.9
	C575.6,248.6,576.9,251.7,579,254.3z"
    />
    <path
      class="st194"
      d="M554.1,243.2c-3.1-0.8-3.7-2.9-3.7-5.8c-0.3-9.9,0.8-19.7,0.8-29.5c0-2.6-1.1-5.1-0.2-7.8
	c0.6-1.7,1.3-2,2.7-1.1c3.1,1.9,6.2,3.7,9.1,5.9c2.9,2.1,4.8,11.9,3.1,14.8c-0.6,1.1-2.4,0.9-2.7,2.4c-0.1,0.1-0.2,0.3-0.3,0.4
	c-1.5,1-2.8,0.3-4.1-0.3c-0.8-0.4-1.8-1.2-2.6-0.1c-0.7,0.9-0.4,1.9-0.2,2.8c0.3,2,2.5,2.7,2.9,4.6c0.3,1.8,0,3.3-1.7,4.3
	c-2.6,2.4-0.4,5.5-1.3,8.2C555.5,242.6,555,243.1,554.1,243.2z"
    />
    <path
      class="st194"
      d="M554.3,268.4c-0.8,0.7-1.6,0.2-2.4,0.2c-1.6-1.1-1.4-2.8-1.4-4.4c0-2.4,1-4.7,0.5-7.1
	c1.8-0.3,0.8,1.8,2.1,1.8c1.2-1.2,0.6-2.1-0.4-3.1c-1.7-1.7-3-8.7-2.1-10.6c0.6-1.2,1.4-0.6,2.1-0.2c0.9,0.5,1.8,1.2,2.2-0.5
	c1-1.9,2.8-1,4.3-1c1.5,0,2.8-0.5,3.3-1.9c0.6-1.6,1.4-3.5-0.3-4.8c-2.6-2-2.2-4.4-1.2-6.9c0.8-2.6-0.4-5.7,2.2-7.9l0.1-0.1
	c2.4,3.2,3.1,6.9,2.8,10.9c-0.2,3.4,0,6.9,0.2,10.4c0.4,5.4-1.2,10.8-0.1,16.3c0.7,3.5-0.8,4.3-4.3,3.2c-1.4-0.4-2.8-1-4.2-1.4
	c-1.8-0.5-2.6,0.3-2.4,2.1C556.7,265.4,556.4,267,554.3,268.4z"
    />
    <path
      class="st195"
      d="M574.6,224.9c-1.7,2-1.1,4-0.2,6c1.2,2.7,1.3,5.7,1.6,8.6c-0.6,1.7-1.8,2.8-3.6,2.5c-1.9-0.2-2.6-1.6-2.6-3.5
	c-0.8-9-0.5-18,0.1-27c0.1-2.2,0.5-3.1,2.8-1.8c2,1.1,4.4,1.5,6.1,4c-3-0.1-4.1,1.8-5,3.9C575.9,219.9,576.1,222.4,574.6,224.9z"
    />
    <path
      class="st193"
      d="M569.9,238.5c1.4,2.4,3.1,3.2,5.3,0.8c1.9-1.2,2.9-0.1,3.8,1.4c2.5,0.5,5.8,2.1,4.6-3.1
	c-0.2-0.9,0.2-1.8,1.3-1.9c1.2-0.1,1.9,0.8,2,1.8c0.3,2.4,1.3,5.2-0.7,7.1c-2.5,2.4-5.3,1-8-0.1c-1.3,1.7-2.9,1.6-4.6,0.9
	c-1.1-0.6-2.4-0.2-3.4-0.9C570,242.6,568.5,240.6,569.9,238.5z"
    />
    <path
      class="st196"
      d="M554.3,268.4c0.3-1.6,0.5-3.3,0.8-4.9c3.4,0.4,5.5,3.2,8,5.1c1.5,1.1,0.1,3.3-1.4,4.6
	C558.8,272.3,556.6,270.3,554.3,268.4z"
    />
    <path
      class="st197"
      d="M562.4,231c-0.9,2-1.4,3.2,1.2,5c2.9,2.1,0.2,5.4-0.6,8c-0.7,2.3-2.3,2.1-4.1,0.9c-1.2-0.8-2.6-0.4-4-0.4
	c-0.3-0.5-0.5-0.9-0.8-1.4c0.3-0.6,0.6-1.2,0.9-1.8c1-1,2-0.6,3.1-0.3c0.9,0.3,2,0.7,2.7-0.5c0.7-1.2,0.1-2.1-0.6-3.1
	c-1-1.3-2.7-1.9-3.6-3.2c-0.4-0.5-0.5-1.1-0.2-1.8c1.5-0.4,1.2-1.9,1.5-2.9C560,228,561.2,229.7,562.4,231z"
    />
    <path
      class="st198"
      d="M573.9,245.4c1.2-1.5,2.8-0.9,4.3-0.8c0.5,2.5,2.4,4.7,1.9,7.6c-0.2,0.9-0.2,1.6-1.1,2.1
	c-4.5-1-5.6-4.2-5.3-8.3C573.5,245.7,573.5,245.5,573.9,245.4z"
    />
    <path
      class="st199"
      d="M579,240.8c-1.3-0.5-2.5-0.9-3.8-1.4c-2.3-2.6-2-5.5-0.8-8.4C580.2,233.5,581.5,236.3,579,240.8z"
    />
    <path
      class="st200"
      d="M574.6,224.9c-1.1-2.3-1.2-4.8-0.7-7.3c1.4-0.1,2.8,0,3.8,1c1.9,1.8,2.9,4.4,1.7,6.7
	C578.1,227.8,576.2,224.9,574.6,224.9z"
    />
    <path
      class="st201"
      d="M573.9,245.4c0,0.2-0.1,0.4-0.2,0.6c-1.7,2.5,0.1,5.9-2.1,8.3c-1.2-0.3-0.8-1.8-1.6-2.3c0-2.5,0.1-5,0.1-7.4
	C572.1,241.4,573,243.3,573.9,245.4z"
    />
    <path
      class="st202"
      d="M574.5,279.1c-1.5-1.5,0-3,0-4.5c0.5,0.1,1,0.1,1.4,0.3c1.7,0.9,4.5,1.4,3.9,3.8c-0.5,2.1-2.8-0.2-4.3,0.4
	C575.2,279.1,574.8,279,574.5,279.1z"
    />
    <path
      class="st203"
      d="M666.2,358.6c-1.6-5.5-3.8-10.9-4.8-16.6c0.4-2.8,1.5-3.3,4.1-1.7c7.5,4.6,15.4,8.5,22,12.4
	c-3.2-1.5-7.1-4.2-11.2-6.4c-2.2-1.2-4-2.6-3.2-5.5c0.1-0.5,0-1-0.2-1.5c-2.7-5.1-2.8-11-4.3-16.4c-1-3.8-3.1-7.2-4.2-10.9l0,0
	c-0.1-1.9,1.3-1.9,2.5-1.8c1.5,0.1,2.9,0.5,4.2,1.2c6.1,3.5,12.4,6.7,18.3,10.6c2.9,1.9,5.8,3.8,9.6,3.3c1.7,2.4,2.7,5.1,3,8.1
	c1.9,6.9,3.6,13.9,6.2,20.7c1.7,4.5,1.5,8.5-2.5,11.8c-1.2,1-1.9,2.3-2.1,3.9c0,0.2,0,0.6-0.2,0.7c-3.5,3.5-5,8.5-8.7,11.9
	c-1.8,1.6-3.5,1-5.3-0.3c-3.6-2.5-7.2-5.3-11.3-7h0c-0.7-0.9-1.8-1.3-2.8-1.8c-3.2-1.6-5.3-4.2-5.8-7.7
	C669,362.9,667.8,360.7,666.2,358.6z"
    />
    <path
      class="st204"
      d="M664.4,312.1c3.7,5.3,7.3,10.6,6.3,17.6c-0.2,1.6,0.3,3,1.3,4.2c1.6,2,2.7,4.6,2.4,6.9
	c-0.4,2.8,1.1,3.3,2.8,4.3c4.5,2.6,9,5.3,13.5,8c1.1,0.7,2.9,1.5,1.9,3.1c-1.3,2-2.5,0-3.7-0.7c-8.3-4.7-16.7-9.4-25-14.2
	c-1.2-0.7-2.1-0.6-2.6,0.8c-1.4-1.4-1.8-3.2-0.4-4.6c1.3-1.3,2.8,0,4.1,0.7c0.5,0.3,1.1,0.6,1.6,1c1.5,1,2.6,3.7,4.5,2.3
	c1.5-1.1-0.2-3.5-0.6-5.2C668.8,328.1,665.6,320.4,664.4,312.1z"
    />
    <path
      class="st205"
      d="M677.9,375.1c1.3-0.4,2.4-0.3,3.5,0.5c2.9,1.9,6,3.4,8.5,5.9c1.8,1.9,3.2,0.6,4.3-0.8c0.8-0.9,1.8-2.2,1.9-3
	c0.7-4.3,6-5.6,6.1-10.4c0.1-1.9,2.5-3.3,4-4.7c2-1.8,2.7-3.7,1.8-6.3c-1.9-5.9-4.1-11.7-5.4-17.8c-0.4-1.7-0.9-3.3-0.7-5.1
	c3.5,8.4,5.5,17.2,8,25.8c0.9,3.3-1.2,5.8-2.7,8.2c-2.8,4.7-6.1,9-9.2,13.5c-3.2,4.5-7,3.9-10.7,1
	C684.4,379.6,680.4,378.3,677.9,375.1z"
    />
    <path
      class="st204"
      d="M699,325.4c-2.8,2-5.1,1.8-8.1-0.2c-6.7-4.5-13.9-8.3-20.9-12.3c-1.7-0.9-3.5-2-5.6-0.8
	c-0.7-0.7-0.6-1.6-0.7-2.5c-0.1-3.7,1.2-4.5,4.5-2.8c3,1.5,5.8,3.4,8.7,5C684.4,316.2,691.9,320.4,699,325.4z"
    />
    <path
      class="st205"
      d="M666.2,358.6c2.4,1.1,3.7,2.7,3.7,5.7c0,2.8,1.7,5,4.2,6.6c1.6,1,3.7,1.7,3.9,4.1c-3.1,0.5-4.7-2.3-7.2-3.1
	C669.2,367.5,667.7,363.1,666.2,358.6z"
    />
    <path
      class="st206"
      d="M177.6,320.7c-0.5,2,0.6,4.2,2.6,6.4c2.8,3.1,4.8,6.7,5.8,10.8c1.3,5.8-0.8,9.5-5.6,10
	c-2.2,0.2-2.7,1.3-2.6,3.2c0.1,2.2,0.2,4.5-0.1,6.7c-0.7,4.9-4,6.3-7.9,3.3c-2.4-1.8-4.2-4.2-5.9-6.8c-1.1-1.7-2.2-2.3-4.3-1.8
	c-5,1.3-7.3,0.2-10.2-4c-2.8-4.1-3.4-8.8-3.2-13.6c0.1-2.5-0.5-4.5-2.2-6.4c-3.8-4.4-7-9-6.8-15.3c0.2-3.7,1.8-6,5.4-6.5
	c2.6-0.3,3-1.5,2.9-3.7c-0.1-2.5-0.2-5,0.2-7.5c0.8-4.4,4.2-5.5,7.7-2.8c2.6,2,4.5,4.7,6.3,7.4c0.9,1.4,1.8,2.1,3.7,1.5
	c6.9-2.4,10.7,2.2,12.8,8C177.4,312.9,178,316.3,177.6,320.7z"
    />
    <path
      class="st207"
      d="M428.2,665c-0.3-8.1,8-15.6,14.5-16.8c6.3-1.2,12,3.8,10.9,9.6c-0.7,3.8-3.2,6.9-7.1,8.3
	c-2.3,0.8-3.7,2.1-4.3,4.6c-1.3,5.5-6.1,6.7-10.4,2.8C429.2,671,427.6,668.2,428.2,665z"
    />
    <path
      class="st208"
      d="M340.6,626.4c-6,3.7-12.8,4.6-19.6,5.6c-1.9,0.3-2.9,0.8-3,2.9c-0.1,3-1.8,3-3.7,1.5
	c-2.4-1.9-4.1-1.2-6.5,0.5c-6.9,5-14.6,8-23.4,6.7c-3.4-0.5-6-2.1-8.2-4.7c-2.2-3.7-1.7-7.5-0.2-11.2c5-7.3,11.8-12.4,19.8-15.8
	c1.4-0.6,2.4-11.4,1.1-12.2c-1.2-0.8-2.7-0.5-4-0.1c-1.7,0.5-2.7,0-2.7-1.9c0-1.8,1.1-3,2.5-2.3c4.8,2.1,5.8-1.4,8-4.3
	c7.6-9.8,18.5-7.6,24.1,2.7c1,1.8,1,3.7,4,2.3c2-0.9,3.3,1,3.4,3.2c-5.3,1.6-5.2,1.6-5.3,7.5c-0.1,2.7,0.9,4.2,3,5.8
	c4.2,3,8.6,5.8,12,9.9C343.2,624.3,342,625.5,340.6,626.4z"
    />
    <path
      class="st209"
      d="M211.8,300c4.3,2.6,6.1-1.2,8.3-4.4c0.8-1.1,1.9-2,2.8-3c1.5-1.6,3.3-2.9,5.6-2.1c2.2,0.8,2.8,2.9,3.1,4.8
	c0.3,2.3,0.3,4.7,0.3,7.1c0,1.8,0.5,2.5,2.4,3c5.3,1.3,7.5,5.1,6.2,10.4c-1,4.2-2.9,8-5.6,11.3c-1.7,2.1-2.4,4.2-2.2,6.9
	c0.3,4.5,0.1,9-2,13.2c-2.5,5.2-5.8,7-11.5,5.7c-2-0.4-2.7,0.4-3.6,1.7c-2.1,3.1-3.9,6.4-7.5,8.2c-2.9,1.5-5,0.7-6.1-2.4
	c-1.1-3-0.9-6.1-0.9-9.3c0-1.9-0.5-2.8-2.7-3.2c-4.7-0.9-6.7-4.3-6.1-9.1c0.8-5.4,3.5-9.8,6.8-14c1.3-1.6,1.7-3.1,1.5-5.1
	c-0.6-5.5,0.3-10.7,3.2-15.5C205.4,301.5,207.7,299.8,211.8,300z"
    />
    <path
      class="st210"
      d="M340.6,626.4c0.5-1.3,1-2.7,1.4-4c0.9,1,1.9,2.1,2.8,3.1c1.5,3,2.8,6.2,2.6,9.7c-3.2,7.1-7.2,9.4-15.3,9
	c-1.7-0.1-3.4,0.3-5.1-0.2c-2.2-4.1-1.7-5.9,1.8-6.8c2.6-0.7,3.7,0.9,4.4,2.8c0.6,1.6,1.5,2.3,3,1.8c3.3-1,6.6-2.5,7.6-5.9
	C345.2,632.2,343.9,628.8,340.6,626.4z"
    />
    <path
      class="st211"
      d="M482.6,687.1c2.7,2.3,0.9,6.4,3.2,8.6c2,1.9,0.8,5.8,4.4,6.6c-5.5,4.7-12.2,7.6-18.2,11.5
	c-0.4,0.2-1,0.1-1.5,0.1c-0.9-0.7-1.5-0.2-2.4,0.2c-3.5,1.5-7.1,2.2-11,1.6c-1.4-0.2-2.8-0.3-3.6-1.6c0.1-3,2.8-3.2,4.5-3.4
	c3.1-0.5,6-1.7,9-1.8c4.6-0.2,6.6-3.5,8.4-6.9c1.7-3.3,2.9-6.8,4.5-10.1C480.2,690,480.8,688.2,482.6,687.1z"
    />
    <path
      class="st212"
      d="M453.7,713.7c4.4,0.6,8.7,0.8,13-0.6c1.4-0.4,2.9-1,3.9,0.8c-3.4,2.3-6.8,4.7-10.8,5.9
	c-0.5-1.3-1.6-1.4-2.7-1.4c-8.7-0.1-16.6-2.9-23.8-7.6c-4.3-2.8-7.4-6.6-8-12c2.7,1.7,4,5.1,7.3,6c1.6,0.7,2.9,1.7,4.4,2.4
	c4.5,2.8,9.4,4.7,14.7,5C452.7,712.5,453.3,712.9,453.7,713.7z"
    />
    <path
      class="st213"
      d="M453.7,713.7c-6.4,0.1-12.2-1.3-17.1-5.7c1-1.2,2.2-0.7,3.4-0.3c10.7,3.9,20.5,1.5,29.8-4.4
	c0.5-0.3,0.8-1,1.2-1.4l0,0c1.2-0.8,2.1-1.9,3.1-2.9c2-2.4,4-4.8,6-7.2c-0.4,7.1-2.8,13.2-8.5,17.7c-0.8,0.7-1.4,0.8-2.4,0.9
	C463.9,711,458.6,711.5,453.7,713.7z"
    />
    <path
      class="st214"
      d="M471,702c0.1,4.1-4,4.5-6.1,5.5c-7.9,3.8-16.3,5-24.7,1.2c-1.2-0.5-2.4-0.7-3.6-0.7c-1.7-0.5-3.1-1.5-4-3.1
	c4,0.4,7.4,2.7,11.4,3c8.9,0.8,17.3-0.2,24.9-5.4C469.5,702.2,470.3,702.2,471,702z"
    />
    <path
      class="st214"
      d="M474.1,699.1c0.1,2.2-1,3.1-3.1,2.9C471.3,700.2,472.3,699.2,474.1,699.1z"
    />
    <path
      class="st215"
      d="M501.8,715.5c-1.4,3.7-5.4,4.1-8.2,5.9c-9.1,5.8-18.4,11.3-27.6,17c-2.3,1.4-4.7,2.7-7.1,4
	c0.2-1.7,1.9-2.2,2.7-4c-4.2-1-8.4,0-12.5-0.5c0.3-0.5,0.7-0.9,1-1.4c13-6.8,25.3-14.7,38.5-21.1c0.8-0.4,1.4-1,2.2-1.5
	c5.6-3.4,5.1-3.5,9.4-0.1C500.7,714.4,501.3,714.9,501.8,715.5z"
    />
    <path
      class="st216"
      d="M451.6,735c-1.3,0-2.6,0-3.9,0c0.6-1.9,1.7-3.8,0.5-5.9c1.1-0.2,2.4-0.1,3.2-0.7c5.8-4.5,12.8-7,19-11
	c8.9-5.7,17.5-11.9,27.5-15.5c2,3.3,0.7,5.6-2.2,7.5c-0.8,3.6-4.4,4.5-6.8,5.7c-10.9,5.7-21.3,12-32,18.1
	C455.3,734.1,453.7,735.4,451.6,735z"
    />
    <path
      class="st217"
      d="M451.6,735c11.4-6.6,22.8-13.1,34.2-19.7c3.3-1.9,6.6-4,9.9-5.9c2.6,0.3,3.3,2.7,4.5,4.4
	c-4-1.9-6.9,0.4-10.3,2.3c-5.8,3.2-11.7,6.5-17.6,9.6c-5.2,2.7-9.8,6.2-15.1,8.5c-2.2,1-4.7,1.6-7,2.3
	C450.6,736,451.1,735.5,451.6,735z"
    />
    <path
      class="st218"
      d="M720.7,360.7c2.1-4.9,4.4-9.7,5.9-14.8c0.2-0.8,0.8-1.6,0.9-2.4c1-7,5-11.7,11.3-14.7c5.3-2.5,9.4-7,14.9-9.2
	c-2.6,8.2-5.2,16.4-7.9,24.5c-0.6,1.8-1.7,3.5-3.3,4.4C735.2,352.4,728.3,357.2,720.7,360.7z"
    />
    <path
      class="st219"
      d="M196.5,507.8c-1.9-1-3.7-1.5-5.2,0.6c-1.6,0.5-3.1,1-3.6-1.4c1.9-1.4,1.2-2.5-0.5-3.4
	c-5.2-2.9-9.8-6.7-15.5-8.6c-1.3-0.4-2.5-1.1-2.5-3.1c4.1,0.2,6.8,4.3,11.4,4.3c-1.6-2.7-3.9-3.7-6.3-4.6c-5.4-2.2-6.4-5.4-3.7-10.6
	c0.2-0.3,0.4-0.9,0.4-0.9c-2.7-1.5-1.1-3.6-1.1-5.7c0-1.9-1.7-3.7,0.3-5.6c0.4-0.4-1.4-1-0.9-2.4c1.8,0.6,3.9,0.6,2.9,3.7
	c-0.5,1.6,0.7,2.8,2,3.1c4.8,1.2,8.2,5.2,13.4,6.1c-0.9,1.1-1.9,2.3-2.8,1.4c-2.4-2.4-6.2-1.8-8.5-4.4c-0.6-0.7-1.4-1.4-2.2-0.8
	c-1,0.7-0.5,1.8,0.1,2.6c0.5,0.7,1.1,1.2,0.7,2.3c-2.1,6.4-0.6,8.5,5.2,11.9c3,1.8,6.9,3.6,7.2,8.5c0.1,2.5,3.2,3.5,5.6,4.2
	c1.6,0.5,3.5,0.4,4.7,2C197.3,507.5,197,507.8,196.5,507.8z"
    />
    <path
      class="st220"
      d="M187.6,507.1c1,1,2.8,0,3.6,1.4c2.1,1.1,4.3,2,6.3,3.2c1.3,0.8,2.2,2,2.3,4c-3.7-0.9-6.3-3.2-9.2-4.8
	c-5.2-2.9-10.2-6.1-15.7-8.6c-1.1-0.5-1.9-1.4-3.4-0.6c-1.4,0.8-1.5-0.9-1.8-1.7c-0.3-1-1.3-2.1-0.3-3.1c0.6-0.6,1.5-0.4,2.1,0.1
	c4.2,3.6,9.5,5.3,14.1,8.2C186.5,505.7,187,506.5,187.6,507.1z"
    />
    <path
      class="st221"
      d="M163.4,477.7c-6.4-2.4-12.3-5.8-18.3-9c-0.7-0.4-1.1-0.9-1.1-1.8c3.7,1.5,7.3,3,11,4.6
	c0.4-1.2-0.3-1.5-0.8-1.7c-0.8-0.4-2.2-0.2-2-1.7c0.1-0.5,0.5-1.2,1-1.4c1.1-0.5,2.4-0.4,3,0.7c1.6,2.8,4.5,3.7,7.1,5.2
	C164,474.3,163.4,476,163.4,477.7z"
    />
    <path
      class="st222"
      d="M178.5,480.9c1-1.1,1.8-0.7,2.6-0.4c8.5,3.3,16.2,8.1,24.2,12.3c0.8,0.4,1.7,0.9,1.1,1.9
	c-0.6,1.1-1.7,0.9-2.6,0.4c-2.5-1.3-5-2.7-7.5-4.1C190.4,487.7,184.5,484.4,178.5,480.9z"
    />
    <path
      class="st220"
      d="M202.5,514.8c0.2,0.1,0.5,0.1,0.7,0.3c4.2,3.5,9.5,4.8,14.1,7.4c0.7,0.4,1.4,0.9,0.9,1.8s-1.3,0.6-2,0.2
	c-4.4-2.8-9.8-3.7-13.8-7.1c-0.6-0.5-1.1-1-1.1-1.8C201.5,514.8,202,514.7,202.5,514.8z"
    />
    <path
      class="st223"
      d="M189.5,481.2c3-0.8,5.1,0.5,7.2,1.8c4,2.5,8,4.8,12.2,6.9c0.7,0.4,1.4,0.8,1.1,1.6c-0.3,0.6-1.1,0.7-1.6,0.4
	C202.1,488.3,195.8,484.7,189.5,481.2z"
    />
    <path
      class="st224"
      d="M177.4,483.3c2.8,1.9,5.8,3.8,8.7,5.9c0.6,0.4,0.2,1.4-0.2,2c-0.5,0.7-1.4,0.8-2,0.3c-1.7-1.7-4-2.4-6.1-3.5
	c-1.4-0.7-1.7-2.1-1.9-3.5C175.8,483.6,176.2,483.1,177.4,483.3z"
    />
    <path
      class="st220"
      d="M196.5,507.8c0.3-0.3,0.6-0.6,0.9-0.9c1,0.7,2.5,0.9,3,2.7C198.6,510,197.5,509,196.5,507.8z"
    />
    <path
      class="st225"
      d="M147.2,445.4c0.7-1.9,1.7-0.6,2.7-0.3c10.9,4.3,20.7,10.8,30.8,16.7c9.1,5.4,18.5,10.3,27.8,15.5
	c4.7,2.6,9.2,5.5,13.7,8.4c1.2,0.8,3.7,1.7,1.5,4c-8.3-5-16.7-9.8-24.9-14.9c-11.8-7.2-23.6-14.3-35.8-20.8
	C157.6,451.2,152.1,448.8,147.2,445.4z"
    />
    <path
      class="st226"
      d="M162.8,460.5c-4,1.9-5.4-2.4-8.2-3.3C157.9,457.2,160.7,458,162.8,460.5C162.8,460.6,162.8,460.5,162.8,460.5
	z"
    />
    <path
      class="st227"
      d="M136.6,481.5c0-1-0.4-2.7,1.5-1.7c1.8,0.9,3.4,2.2,4.4,5C140.1,483.4,138.3,482.4,136.6,481.5
	C136.6,481.5,136.6,481.5,136.6,481.5z"
    />
    <path
      class="st228"
      d="M140.9,479.3c-1.4-1-2.9-2-4.9-3.4c3.1-0.4,4,1.6,5.5,2.4C141.3,478.7,141.1,479,140.9,479.3z"
    />
    <path
      class="st127"
      d="M141.1,522c-1.3-2.1-3.5-2.8-5.5-3.8c-1-0.5-2-1.2-1.5-2.2c0.7-1.4,2.1-0.8,2.8,0c2.1,2.6,4.9,3.1,8,2.9
	c0.2,0.1,0.4,0.2,0.6,0.4c0.9,1.1,1.1,2.2-0.1,3.2C143.8,523.7,142.3,523.1,141.1,522L141.1,522z"
    />
    <path
      class="st127"
      d="M141.8,515.2c3.4-1.5,5.5,1.5,8,2.6c0.8,0.3-0.2,1.2-0.6,1.8c-1.7,1.5-3,0-4.4-0.6l0,0
	C144.9,516.9,142.5,516.7,141.8,515.2z"
    />
    <path
      class="st229"
      d="M122.9,510c1.3,0,2.3,0.3,2.1,1.6c-0.3,1.8-2.1,1.4-3.2,1.9c-0.9,0.4-1.9-0.2-1.7-1.3
	C120.3,510.8,121.7,510.3,122.9,510z"
    />
    <path
      class="st127"
      d="M126,515.2c0.1-1.2-0.1-2.7,1.7-2.8c2-0.1,3.3,0.9,4.7,3.3c-2.6-0.6-4.5-1.5-6.3-0.4L126,515.2z"
    />
    <path
      class="st127"
      d="M126.1,515.3c0.4,0.4,0.8,0.9,1.3,1.2c0.8,0.5,2.4-0.1,2.7,1.4c0.1,0.5-0.4,1-0.9,0.9c-1.5-0.4-3.4,0-4.4-1.4
	c-0.7-1,0-1.8,1.1-2.1C126,515.2,126.1,515.3,126.1,515.3z"
    />
    <path
      class="st127"
      d="M135.9,511.5c-0.5,0.5-1.1,1-1.7,1.5c-1.2-0.1-2.5-0.2-2.7-1.8c-0.1-0.6,0.2-1.3,0.9-1.5
	C134,509.4,135.2,509.9,135.9,511.5z"
    />
    <path
      class="st127"
      d="M137.4,513.9c1-0.8,2.1-1.2,3.5-0.6c0.1,1.5-1.7,1.1-2.1,2C138,515.2,137.5,514.8,137.4,513.9z"
    />
    <path
      class="st122"
      d="M183,539.2c0.3-0.8,0.5-1.5,0.8-2.2c0.8,1.6,1.9,2.8,3.8,3.1c1.7,0.2,2.2,1.4,2.1,3c-1,0.2-2,0.5-2.9,0.5
	c-1,0-2.8,0.7-1.5-1.8C185.9,540.5,183.4,540.5,183,539.2z"
    />
    <path
      class="st230"
      d="M180.6,530.3c1.2,0,1.8,0.7,2,1.7s-0.7,1.1-1.4,1.2c-1,0.1-2.1,0-2.2-1.3
	C178.9,530.8,179.7,530.4,180.6,530.3z"
    />
    <path
      class="st231"
      d="M140.3,528c0.7,3,3.6,3.6,5.7,4.9c5.8,3.6,11.7,6.9,17.6,10.3c1.4,0.8,1.4,1.9,0.7,3.2c-0.6,1-1.3,1-2.2,0.3
	c-5.2-4.7-11.9-7.1-17.4-11.3c-1.9-1.4-4-2.5-5.6-4.3c-0.6-0.7-2.2-0.9-1.8-2.1C137.8,527.6,139.2,528.1,140.3,528
	C140.3,528,140.3,528,140.3,528z"
    />
    <path
      class="st127"
      d="M150.8,524.2c0.6,0.2,1.2,0.6,1.7,0.7c2.3,0,4.5,0,1.9,3.1c-0.5,0.6,0.5,1,0.8,1.5c-1.7-0.2-3.4-0.5-5.1-0.7
	l0,0C150,527.2,151.5,525.9,150.8,524.2z"
    />
    <path
      class="st127"
      d="M150.1,528.8c-1.2,2.7,2,2.6,2.7,4.2c-2.5,1.1-4.2-0.8-6.1-1.6c-1.5-0.7-1-1.8,0.4-2.3
	C148,528.7,149.1,528.8,150.1,528.8C150,528.7,150.1,528.8,150.1,528.8z"
    />
    <path
      class="st127"
      d="M156,530.2c0.5,0,1,0,1.5,0c-0.2,1,0,2.7-1.4,2.4C154.8,532.3,155.9,531,156,530.2z"
    />
    <path
      class="st123"
      d="M144.8,519c1.7-1.2,3,0.1,4.4,0.6c0.3,0.5,0.5,1,0.8,1.6c-1.3,0.8-2.6,1.3-3.7,2.3c-3.4,2.9-3.7,2.9-5.3-1.5
	C142.9,521.7,146.1,523.2,144.8,519z"
    />
    <path
      class="st9"
      d="M599.7,264.5c-0.8-0.2-1.1-0.7-0.8-1.4c0.3-0.8,0.8-1.6,1.8-1.5c0.6,0.1,0.7,0.6,0.7,1.2
	C601.2,263.8,600.7,264.4,599.7,264.5z"
    />
    <path
      class="st10"
      d="M603.1,169.6c-0.3,0.9-0.8,1.5-1.7,1.6c-1.8,0.1-3.3-0.8-4.9-1.4c-2,0.3-2.5-1.8-3.9-2.4
	c-0.9-0.2-1.9-0.5-2.8-0.7c-0.3-0.5-1.3-0.9-0.1-1.6C594.4,162.1,600.2,164,603.1,169.6z"
    />
    <path
      class="st50"
      d="M427.5,417.8c0.5-0.1,1-0.2,1.5-0.3c1.6-0.2,3.3,0.1,3.2,2c0,1.6-1.6,2-3.1,2c-2.3,0-2.3-1.3-1.8-3
	C427.4,418.2,427.5,418,427.5,417.8C427.5,417.8,427.5,417.8,427.5,417.8z"
    />
    <path
      class="st232"
      d="M701.7,483.4c2.4,1.2,5,2.3,7.5,3.7c6.8,3.8,13.7,7.7,20.4,11.6c1.1,0.6,2.2,1.3,3.1,2
	c1.9,1.4,2.9,3.2,1.3,5.5c-1.8,2.7-3.2,0.1-4.6-0.6c-9.1-4.8-18.2-9.8-27.3-14.6c-2.9-1.5-3.8-3.3-2.7-6.3
	C699.9,483.8,700.4,483.1,701.7,483.4z"
    />
    <path
      class="st233"
      d="M700.7,501.3c2.6,0.3,4.6,2,6.8,3.2c8,4.5,16.7,7.8,23.9,13.7c1.8,1.5,1.8,2.5,0.8,4.4
	c-1.2,2.1-2.5,1.6-4,0.7c-9.4-5.3-18.9-10.7-28.3-16c-1.4-0.8-2.7-1.5-1.9-3.5C698.5,502.7,699.1,501.4,700.7,501.3z"
    />
    <path
      class="st234"
      d="M699.5,492.2c3.9,1.1,6.5,3.9,9.8,5.2c2.3,0.9,4.2,2.5,6.7,3.2c1.7,0.5,3.1,1.6,3.2,3.7
	c0,0.8,0.3,1.9-0.4,2.5c-0.9,0.6-2,0.1-2.7-0.6c-4.3-3.8-9.9-5.7-14.1-9.7C700.7,495.4,699.3,494.6,699.5,492.2z"
    />
    <path
      class="st235"
      d="M573.9,258.9c1.7,0.2,3.4,0.5,5.1,0.7c1.9,0.8,2.5,2.5,2.7,4.4c0,0.8,0,1.6,0,2.4c0,0.9-0.5,1.5-1.2,2
	c-3.6,0.6-4.9-2.1-6.6-4.4C571.8,262.2,571.8,260.5,573.9,258.9z"
    />
    <path
      class="st236"
      d="M573.9,258.9c0,1.7-0.1,3.4-0.1,5.1c-0.4,1.1,0.7,2.1,0,3.3c-2.4-0.3-3.7-1.6-3.7-4.1c0-1.7,0-3.5,0-5.2l0,0
	C571.5,257.4,572.7,257.9,573.9,258.9z"
    />
    <path
      class="st51"
      d="M580.4,264.1c-0.5-1.5-1-3-1.5-4.5c1.6-0.1,3.1-0.5,4.7,0.4c1.9,1.1,2.8,2.5,1.2,4.4c-0.7,0.8-1.4,1.5-1.3,2.6
	c0.3,3.4-1.6,2-3.2,1.5c0.1-0.7,0.1-1.4,0.2-2.1C581.1,265.5,582,264.8,580.4,264.1z"
    />
    <path
      class="st237"
      d="M563.2,212.8c-0.3,1.6-0.1,3.4-1.5,4.2c-1.6,0.9-3.2-0.1-4.5-1.1c-1.4-1.1-3.4-2-3.6-4.1
	c-0.2-2.2-0.9-5,1.8-6.3c1.8-0.9,6.6,2.3,7.3,4.8C563.1,211.2,563.1,212.1,563.2,212.8z"
    />
    <path
      class="st238"
      d="M562.4,231c-1.7,0.2-3-0.9-4.5-1.3c-2.7-1.5-4.4-3.5-4-6.8c0.2-1.3-0.2-3,1.3-3.6c1.4-0.6,2.6,0.1,3.8,0.9
	c1.3,0.9,2.4,2.3,4.2,2C563.2,225.1,563.8,228.1,562.4,231z"
    />
    <path
      class="st239"
      d="M556.4,232.6c1.9,1.4,3.7,2.8,5.7,4.1c1.3,0.9,1.3,1.9,1.1,3.2c-0.7,4.5-1.3,4.8-5.4,2.8
	c-0.9-0.4-1.8-0.9-2.7-1.3c-1.3-2.1-0.9-4.5-1-6.9C554,232.9,554.9,232.4,556.4,232.6z"
    />
    <path
      class="st240"
      d="M554.3,250.6c0-3.1,0.9-3.3,3.9-2.6c1.4,0.3,2.2,4.4,4.5,1.1c0.3-0.4,0.9,0.8,0.7,1.2
	c-1.1,2.1-1.6,4.2-1.1,6.7c0.3,1.4-0.9,1.7-2.2,1.3C556.6,257.2,554.3,254.3,554.3,250.6z"
    />
    <path
      class="st241"
      d="M172.3,333.6c-1,2.8-2.4,5.7-4.5,8.2c-0.2,0.3-0.9,0.5-1.3,0.4c-4.2-1.3-8.3-2.9-10.6-7
	c-1.3-2.4-1.8-5.2-3.1-7.6c-2.8-5.1-0.9-9.3,1.8-13.5c0.7-1.1,1.3-2,3-1.4C164.8,315,172.7,325.9,172.3,333.6z"
    />
    <path
      class="st242"
      d="M175.4,320.9c-6.5-2.8-9-8.5-11.6-14.1c-0.5-1.1,0-1.5,0.9-2c2.9-1.3,6.8-0.1,8.5,2.7
	C175.7,311.7,175.8,316.2,175.4,320.9z"
    />
    <path
      class="st242"
      d="M148.5,320.3c-0.5,2-1,4.1-1.6,6.1c-0.5,1.7-1.5,0.9-2.2,0.1c-2.8-3.1-4.9-6.6-5.7-10.7
	c-0.5-2.7,1.7-5.5,4.5-5.9c1.4-0.2,2.5,0,2.9,1.6C147.2,314.5,147.8,317.4,148.5,320.3z"
    />
    <path
      class="st243"
      d="M153,308.1c-1.4-1-4.5-0.1-5-2.7c-0.6-3.1-1.1-6.5,0.6-9.5c0.8-1.5,2.3-1.4,3.6-0.6c3.1,1.7,5,4.5,6.7,7.4
	C159.9,304.3,155.6,308.3,153,308.1z"
    />
    <path
      class="st243"
      d="M184.5,339.2c-0.2,3.7-2.2,5.2-5,5.7c-3.1,0.5-2.6-2.3-3.1-4c-1.2-3.7-1.7-7.5,0-11.2c0.3-0.6,0.5-1.6,1-1.9
	c0.7-0.4,1.2,0.5,1.7,1.1C181.7,332.1,183.8,335.6,184.5,339.2z"
    />
    <path
      class="st243"
      d="M156.4,350.3c-3.2-0.3-5-1.7-6.3-4c-1.7-2.9-1.5-6.2-2-9.4c-0.1-0.7,0.1-1.6,0.8-1.9c0.8-0.4,1.3,0.4,2,0.7
	c4.8,2.6,6.8,7.5,8.7,12.1C160.8,351.1,156.7,348.8,156.4,350.3z"
    />
    <path
      class="st243"
      d="M175.7,353.6c-0.2,2,0.5,5-2.3,5.9c-2.6,0.8-4.2-1.7-5.8-3.4c-0.6-0.6-1.2-1.3-1.5-2.1
	c-0.6-1.2-3-2.1-1.5-3.6c1.6-1.6,2.8-4.2,6-3.5C175.3,347.9,175.7,348.2,175.7,353.6z"
    />
    <path
      class="st244"
      d="M150.1,316c-0.8-2-2.6-3.9-1.3-5.8c0.5-0.8,2.9-0.1,3.3,0.7C153.4,313.1,150.9,314.2,150.1,316z"
    />
    <path
      class="st245"
      d="M158.3,341.3c2.4,1.2,3.9,2,5.4,2.8c0.6,0.3,1.2,0.6,1,1.4c-0.2,1.1-1.2,1.5-2,2c-0.6,0.4-1.2,0-1.5-0.5
	C160.4,345.3,159.6,343.7,158.3,341.3z"
    />
    <path
      class="st246"
      d="M173.5,329.8c-1.2-3.4-2.2-6-3.3-9C175.1,323.7,175.5,324.5,173.5,329.8z"
    />
    <path
      class="st247"
      d="M153.1,334.1c-4.3-2-5.2-4.6-3.4-8.5C151.8,328,151.9,331,153.1,334.1z"
    />
    <path
      class="st248"
      d="M173.5,338.3c1,2.5,2.2,4.4,0.5,6.3c-0.7,0.7-2.3,0.3-2.7-0.5C170.1,341.8,172.5,340.6,173.5,338.3z"
    />
    <path
      class="st249"
      d="M163.8,312.3c-1.7-1.1-4.6-0.4-5-3c-0.1-0.8,1.2-2.1,2-1.9C163.3,307.8,163.2,310.3,163.8,312.3z"
    />
    <path
      class="st250"
      d="M311.4,627.7c-2,1.1-3.2-0.3-4-2.1c-1.5-3.1-3.3-6.1-4.5-9.4c-0.8-2.3-0.4-3.8,1.8-4.9
	c2.2-1.1,4.5-2.2,6.6-3.5c2.3-1.4,3.8,0.5,5.4,1.3c6,2.9,6.1,3.5,3.4,9.6c-0.7,1.5-1.3,2.9-2,4.4
	C316.2,627.3,316.2,627.3,311.4,627.7z"
    />
    <path
      class="st251"
      d="M286.9,641.2c-2.8-0.4-6-0.5-7.7-3.9c-1.8-3.5-1-6.5,1-9.5c0.9-1.4,1.8-2,3.6-0.9c6.1,3.6,12.8,5.2,19.9,5.3
	c1,0,2.5-0.4,2.8,1.1c0.2,1.4-1.1,1.7-2.1,2.3C299,638.6,293.3,640.8,286.9,641.2z"
    />
    <path
      class="st252"
      d="M322.2,596.6c0,0.2,0,0.5-0.2,0.9c-0.9,2.1-10,5-12,3.9c-1.6-0.9-3.2-2.2-4.9-2.7c-3.3-1-2.9-2.8-1.5-5.1
	c2.5-4.3,6.4-6.4,10.5-5.5C316.9,588.8,322.1,594,322.2,596.6z"
    />
    <path
      class="st253"
      d="M300.8,628.5c-2.2,0.1-4.2-0.8-6.2-0.9c-3.8-0.2-3.9-3.3-4.7-5.7c-0.8-2.3,1.5-3.1,2.9-4.2
	c0.6-0.5,1.2-0.9,1.8-1.4c2.2-1.6,3.8-1.9,5,1.2c0.9,2.4,1.8,4.9,3.4,7c0.8,1,2.3,2.1,1.5,3.5C303.7,629.1,302,628.5,300.8,628.5z"
    />
    <path
      class="st254"
      d="M330.6,626.7c-0.6,0.3-1.2,0.6-1.7,0.8c-2.7,0.8-6.2,2.3-8.2,0.6c-1.5-1.3,1.6-4.2,2.2-6.5
	c0.4-1.4,1.4-2.8,1.5-4.2c0.3-4.3,2.5-2.5,4.4-1.5c1.5,0.8,1.5,1.5,0.2,2.8C325.1,622.6,325.5,624.4,330.6,626.7z"
    />
    <path
      class="st255"
      d="M308.9,604.7c-1.6,1-2.6,1.7-3.7,2.2c-1.2,0.6-2.6,1.7-3.8,0.8c-1.2-0.9-0.6-2.5-0.4-3.9
	c0.3-2.1,1.2-3.2,3.4-1.8C305.7,602.8,307.1,603.6,308.9,604.7z"
    />
    <path
      class="st256"
      d="M323.5,604.6c-0.6,1.1,0.6,3.4-1.5,3.1c-1.6-0.2-4-1.3-4.1-3.1c-0.1-1.8,2.2-2.5,3.8-2.8
	C324,601.5,323,603.6,323.5,604.6z"
    />
    <path
      class="st257"
      d="M334.1,619.7c2.2,1.3,4.4,2.2,4.4,4.5c0,0.8-2,1.7-3.4,1.8c-0.7,0-1.1-0.6-0.9-1.3
	C334.7,623.3,334.2,621.8,334.1,619.7z"
    />
    <path
      class="st258"
      d="M226.7,321.6c-2.3,7.3-2.6,16-12,19.4c-2.5,0.9-3.8,0.4-5.2-1.7c-2.5-4-3.2-7.9-1.7-12.7
	c2-6.2,4.1-11.9,10.9-14.5c2.2-0.8,3.6-0.7,4.6,1.4C224.7,315.9,226.4,318.3,226.7,321.6z"
    />
    <path
      class="st259"
      d="M203.3,352.3c-0.8-4.6,2.8-4.8,5.6-5.4c3.1-0.8,4,2.4,5.8,3.9c0.6,0.5-0.1,1.4-0.5,2c-1.3,2-2.6,4-4.4,5.6
	c-2.9,2.6-4.9,2.1-5.8-1.6C203.6,355.2,203.5,353.8,203.3,352.3z"
    />
    <path
      class="st258"
      d="M201.1,326.6c3.2,5.6,2.2,10.8,0.8,16c-0.4,1.6-1.1,1.9-2.6,1.7c-2.8-0.5-5.2-3.1-4.7-5.7
	C195.4,334,197.6,330.1,201.1,326.6z"
    />
    <path
      class="st260"
      d="M232.3,326.5c-3.8-5.5-2.2-10.8-1.1-16.2c0.4-1.7,1.8-1.5,3-1.2c3.2,0.7,4.9,3.9,3.8,7.3
	C237,320.2,235.1,323.5,232.3,326.5z"
    />
    <path
      class="st261"
      d="M203.3,319.6c-0.2-4-0.4-7.6,0.8-11.1c0.8-2.2,2-3.9,4.3-4.8c1.8-0.7,3.6-0.6,5,0.5c1.6,1.3-0.3,2.6-0.7,3.9
	C211.2,313,208.3,316.8,203.3,319.6z"
    />
    <path
      class="st262"
      d="M230.1,333.5c0.4,3.9,0.5,7.4-0.6,10.7c-0.5,1.7-1.2,3.3-2.7,4.4c-2.1,1.6-4.6,1.7-6.6,0.4
	c-1.8-1.2,0.3-2.8,0.7-4.2C222.2,339.8,225.4,336.4,230.1,333.5z"
    />
    <path
      class="st262"
      d="M229.7,302c1.1,3.5-2.3,3.8-5,4.5c-3.2,0.9-4.1-2.1-5.8-3.7c-0.6-0.6,0-1.4,0.4-2c0.5-0.8,1.1-1.7,1.7-2.5
	c1.6-2.2,3.4-5.2,6.3-4.2C229.9,295.1,229.5,298.5,229.7,302z"
    />
    <path
      class="st263"
      d="M205.3,338.4c1,1.8,3.6,2.9,2.2,5.3c-0.5,0.8-2,0.9-2.7,0.3C203.1,342.1,205,340.3,205.3,338.4z"
    />
    <path
      class="st264"
      d="M228.3,315c-1.9-1.9-3.4-3.1-2.6-5.2c0.4-1,2.5-1.4,2.9-0.8C229.9,310.8,228.6,312.6,228.3,315z"
    />
    <path
      class="st265"
      d="M219.3,342c-0.8,1.8-0.5,4.3-2.7,4.9c-0.9,0.2-2.2-1-2-1.7C215,342.8,217.5,342.8,219.3,342z"
    />
    <path
      class="st266"
      d="M213.9,311.5c0-1.8,1-3.1,1.8-4.4c0.7-1.2,1.6-0.9,2.4-0.1c0.8,0.8,1.2,1.7,0,2.4
	C216.8,310.2,215.6,311.3,213.9,311.5z"
    />
    <path
      class="st267"
      d="M207.7,320c-0.8,3-1.1,5.3-2.8,7.4C203.2,324.2,204.2,322.1,207.7,320z"
    />
    <path
      class="st268"
      d="M225.5,333.1c1-3.1,1.7-5.3,2.4-7.6C230.2,329.1,230.1,329.7,225.5,333.1z"
    />
    <path
      class="st193"
      d="M580.4,264.1c0.2-1-0.4-2.2,0.7-3.1c2.2,2.8,2.1,3.4-0.7,5.3C580.5,265.5,580.5,264.8,580.4,264.1z"
    />
    <path
      class="st269"
      d="M166.4,330.3c-0.2,1.8-0.8,3.7-3.4,3.7c-2.7,0-5.9-5.1-5.8-9c0-2,0.4-3.8,2.9-4
	C163,320.9,166.5,325.8,166.4,330.3z"
    />
    <path
      class="st269"
      d="M212.2,329.1c0-3.2,1-6.2,3.4-8.3c2.4-2.1,4.8-1.3,5.6,1.8c0.8,3.1-1.3,8.5-4,10.3c-1,0.7-2,0.8-3.2,0.3
	C212.3,332.4,212.2,330.7,212.2,329.1z"
    />
  </svg>
</template>

<script>
export default {
  name: "Tech50Vue",
};
</script>

<style scoped>
.st0 {
  fill: #fafbfd;
}
.st1 {
  fill: #276094;
}
.st2 {
  fill: #fce857;
}
.st3 {
  fill: #295d91;
}
.st4 {
  fill: #b67322;
}
.st5 {
  fill: #6383a1;
}
.st6 {
  fill: #87a4e0;
}
.st7 {
  fill: #454967;
}
.st8 {
  fill: #e1f4f8;
}
.st9 {
  fill: #444570;
}
.st10 {
  fill: #add6f5;
}
.st11 {
  fill: #2c92a0;
}
.st12 {
  fill: #2d91a1;
}
.st13 {
  fill: #444e7b;
}
.st14 {
  fill: #e4f5f9;
}
.st15 {
  fill: #e8f7f8;
}
.st16 {
  fill: #2c93a2;
}
.st17 {
  fill: #749dc7;
}
.st18 {
  fill: #2b8bbf;
}
.st19 {
  fill: #329db4;
}
.st20 {
  fill: #89c0e0;
}
.st21 {
  fill: #addc93;
}
.st22 {
  fill: #93e3f3;
}
.st23 {
  fill: #dbeefc;
}
.st24 {
  fill: #2b8b9d;
}
.st25 {
  fill: #2b919f;
}
.st26 {
  fill: #41b782;
}
.st27 {
  fill: #2b909f;
}
.st28 {
  fill: #2c909f;
}
.st29 {
  fill: #2c919f;
}
.st30 {
  fill: #2a8f9e;
}
.st31 {
  fill: #295e90;
}
.st32 {
  fill: #2a8d9e;
}
.st33 {
  fill: #2b8f9e;
}
.st34 {
  fill: #2a8f9f;
}
.st35 {
  fill: #2b909e;
}
.st36 {
  fill: #2a5e8d;
}
.st37 {
  fill: #fadb18;
}
.st38 {
  fill: #295c94;
}
.st39 {
  fill: #2b5c92;
}
.st40 {
  fill: #2c5e95;
}
.st41 {
  fill: #2a5c91;
}
.st42 {
  fill: #295e93;
}
.st43 {
  fill: #2a5c93;
}
.st44 {
  fill: #fbc935;
}
.st45 {
  fill: #3182ba;
}
.st46 {
  fill: #4baff4;
}
.st47 {
  fill: #788fd2;
}
.st48 {
  fill: #414267;
}
.st49 {
  fill: #a5d0f2;
}
.st50 {
  fill: #1b88d2;
}
.st51 {
  fill: #57769b;
}
.st52 {
  fill: #d6effa;
}
.st53 {
  fill: #506c91;
}
.st54 {
  fill: #51577a;
}
.st55 {
  fill: #75e69b;
}
.st56 {
  fill: #90d7fd;
}
.st57 {
  fill: #3d3260;
}
.st58 {
  fill: #4a7adc;
}
.st59 {
  fill: #328dfd;
}
.st60 {
  fill: #2c929f;
}
.st61 {
  fill: #2a8fd4;
}
.st62 {
  fill: #394e7e;
}
.st63 {
  fill: #607b98;
}
.st64 {
  fill: #69b7fc;
}
.st65 {
  fill: #2d92a1;
}
.st66 {
  fill: #38889e;
}
.st67 {
  fill: #2b929f;
}
.st68 {
  fill: #52bd9f;
}
.st69 {
  fill: #2d929f;
}
.st70 {
  fill: #2f90b6;
}
.st71 {
  fill: #3190ba;
}
.st72 {
  fill: #faee59;
}
.st73 {
  fill: #2c91a0;
}
.st74 {
  fill: #f7e454;
}
.st75 {
  fill: #283848;
}
.st76 {
  fill: #2d80dc;
}
.st77 {
  fill: #b67525;
}
.st78 {
  fill: #b76f29;
}
.st79 {
  fill: #2b93a1;
}
.st80 {
  fill: #3298c1;
}
.st81 {
  fill: #2e90b9;
}
.st82 {
  fill: #2888b2;
}
.st83 {
  fill: #7295cc;
}
.st84 {
  fill: #2e92bb;
}
.st85 {
  fill: #2f92be;
}
.st86 {
  fill: #2d92bd;
}
.st87 {
  fill: #71c7f6;
}
.st88 {
  fill: #5795eb;
}
.st89 {
  fill: #59caf4;
}
.st90 {
  fill: #f4ce47;
}
.st91 {
  fill: #3093bf;
}
.st92 {
  fill: #329265;
}
.st93 {
  fill: #58688d;
}
.st94 {
  fill: #dda842;
}
.st95 {
  fill: #d89e3f;
}
.st96 {
  fill: #ebcf4b;
}
.st97 {
  fill: #85b5ac;
}
.st98 {
  fill: #2680b8;
}
.st99 {
  fill: #2d7eb6;
}
.st100 {
  fill: #38a7c3;
}
.st101 {
  fill: #ace0f2;
}
.st102 {
  fill: #afe562;
}
.st103 {
  fill: #8ac695;
}
.st104 {
  fill: #2a497d;
}
.st105 {
  fill: #473059;
}
.st106 {
  fill: #2b5083;
}
.st107 {
  fill: #3b686f;
}
.st108 {
  fill: #5ca5a9;
}
.st109 {
  fill: #3467d5;
}
.st110 {
  fill: #52a3f3;
}
.st111 {
  fill: #e1f5fa;
}
.st112 {
  fill: #576480;
}
.st113 {
  fill: #4c647e;
}
.st114 {
  fill: #5a769a;
}
.st115 {
  fill: #5f85ad;
}
.st116 {
  fill: #b77f90;
}
.st117 {
  fill: #9cb9ce;
}
.st118 {
  fill: #c4b1bf;
}
.st119 {
  fill: #9d86a6;
}
.st120 {
  fill: #a9c2d6;
}
.st121 {
  fill: #aecee6;
}
.st122 {
  fill: #697b94;
}
.st123 {
  fill: #4f647e;
}
.st124 {
  fill: #566884;
}
.st125 {
  fill: #aad3f6;
}
.st126 {
  fill: #566783;
}
.st127 {
  fill: #6c88a1;
}
.st128 {
  fill: #8cbcdf;
}
.st129 {
  fill: #6d91c3;
}
.st130 {
  fill: #9acdea;
}
.st131 {
  fill: #93a1bd;
}
.st132 {
  fill: #3c3d65;
}
.st133 {
  fill: #7aa6ce;
}
.st134 {
  fill: #8ac2e2;
}
.st135 {
  fill: #739fcd;
}
.st136 {
  fill: #6690c1;
}
.st137 {
  fill: #eee450;
}
.st138 {
  fill: #709ac9;
}
.st139 {
  fill: #ebe86b;
}
.st140 {
  fill: #b3994f;
}
.st141 {
  fill: #91aab5;
}
.st142 {
  fill: #f0ea52;
}
.st143 {
  fill: #ebe031;
}
.st144 {
  fill: #588dc6;
}
.st145 {
  fill: #a2884a;
}
.st146 {
  fill: #a8aa6e;
}
.st147 {
  fill: #2ea2f1;
}
.st148 {
  fill: #0a7fcd;
}
.st149 {
  fill: #1874b2;
}
.st150 {
  fill: #135688;
}
.st151 {
  fill: #3a3060;
}
.st152 {
  fill: #495979;
}
.st153 {
  fill: #494a72;
}
.st154 {
  fill: #315f8a;
}
.st155 {
  fill: #356187;
}
.st156 {
  fill: #dbf1fa;
}
.st157 {
  fill: #6888ba;
}
.st158 {
  fill: #2f648a;
}
.st159 {
  fill: #285d90;
}
.st160 {
  fill: #34495e;
}
.st161 {
  fill: #8bd4d7;
}
.st162 {
  fill: #edf36a;
}
.st163 {
  fill: #1966a4;
}
.st164 {
  fill: #3a5e61;
}
.st165 {
  fill: #628e72;
}
.st166 {
  fill: #f9705f;
}
.st167 {
  fill: #648ec7;
}
.st168 {
  fill: #c8e8f1;
}
.st169 {
  fill: #c2e5f3;
}
.st170 {
  fill: #8c86b9;
}
.st171 {
  fill: #9075a0;
}
.st172 {
  fill: #8f547a;
}
.st173 {
  fill: #63a4f4;
}
.st174 {
  fill: #2a457b;
}
.st175 {
  fill: #2b4376;
}
.st176 {
  fill: #2b4076;
}
.st177 {
  fill: #b1742a;
}
.st178 {
  fill: #faec53;
}
.st179 {
  fill: #2d7fa9;
}
.st180 {
  fill: #f1c546;
}
.st181 {
  fill: #406b96;
}
.st182 {
  fill: #5e7480;
}
.st183 {
  fill: #585c89;
}
.st184 {
  fill: #f7f65d;
}
.st185 {
  fill: #f8ea61;
}
.st186 {
  fill: #7c456d;
}
.st187 {
  fill: #483159;
}
.st188 {
  fill: #1d5d8d;
}
.st189 {
  fill: #4289de;
}
.st190 {
  fill: #58aff7;
}
.st191 {
  fill: #85b3dd;
}
.st192 {
  fill: #c3d4e9;
}
.st193 {
  fill: #4e6788;
}
.st194 {
  fill: #495c7c;
}
.st195 {
  fill: #425070;
}
.st196 {
  fill: #86b5ce;
}
.st197 {
  fill: #80715b;
}
.st198 {
  fill: #74a5bf;
}
.st199 {
  fill: #72a7c2;
}
.st200 {
  fill: #87bccf;
}
.st201 {
  fill: #343b5b;
}
.st202 {
  fill: #a1c4de;
}
.st203 {
  fill: #48da8e;
}
.st204 {
  fill: #76eca1;
}
.st205 {
  fill: #54b9a2;
}
.st206 {
  fill: #077ca3;
}
.st207 {
  fill: #a3f1b3;
}
.st208 {
  fill: #e4f9fa;
}
.st209 {
  fill: #087ca2;
}
.st210 {
  fill: #c4eaf9;
}
.st211 {
  fill: #aa8343;
}
.st212 {
  fill: #d3ac44;
}
.st213 {
  fill: #856836;
}
.st214 {
  fill: #55605a;
}
.st215 {
  fill: #aa6372;
}
.st216 {
  fill: #6592c8;
}
.st217 {
  fill: #564b76;
}
.st218 {
  fill: #3f85f2;
}
.st219 {
  fill: #b4e4ed;
}
.st220 {
  fill: #b0e6ee;
}
.st221 {
  fill: #a07d8e;
}
.st222 {
  fill: #abdfe3;
}
.st223 {
  fill: #aedde0;
}
.st224 {
  fill: #ade2ed;
}
.st225 {
  fill: #6a92b3;
}
.st226 {
  fill: #aa8f9c;
}
.st227 {
  fill: #a7c6e3;
}
.st228 {
  fill: #a0c5e0;
}
.st229 {
  fill: #7394b0;
}
.st230 {
  fill: #8f9eba;
}
.st231 {
  fill: #6d8ba6;
}
.st232 {
  fill: #60b5fb;
}
.st233 {
  fill: #60b2f9;
}
.st234 {
  fill: #5eadf9;
}
.st235 {
  fill: #78a6c2;
}
.st236 {
  fill: #384764;
}
.st237 {
  fill: #1fd99c;
}
.st238 {
  fill: #eab333;
}
.st239 {
  fill: #e6c832;
}
.st240 {
  fill: #789fb9;
}
.st241 {
  fill: #54577a;
}
.st242 {
  fill: #54597b;
}
.st243 {
  fill: #54587b;
}
.st244 {
  fill: #566687;
}
.st245 {
  fill: #556888;
}
.st246 {
  fill: #576586;
}
.st247 {
  fill: #586787;
}
.st248 {
  fill: #586586;
}
.st249 {
  fill: #576889;
}
.st250 {
  fill: #78c0f8;
}
.st251 {
  fill: #76bbf7;
}
.st252 {
  fill: #85d1f9;
}
.st253 {
  fill: #7bc6fa;
}
.st254 {
  fill: #77bbec;
}
.st255 {
  fill: #90cef0;
}
.st256 {
  fill: #87caef;
}
.st257 {
  fill: #83b2e9;
}
.st258 {
  fill: #3e3360;
}
.st259 {
  fill: #3e3461;
}
.st260 {
  fill: #3f3462;
}
.st261 {
  fill: #3f3361;
}
.st262 {
  fill: #3e3361;
}
.st263 {
  fill: #474670;
}
.st264 {
  fill: #494872;
}
.st265 {
  fill: #43426d;
}
.st266 {
  fill: #423c68;
}
.st267 {
  fill: #433c67;
}
.st268 {
  fill: #45406b;
}
.st269 {
  fill: #097da3;
}
</style>
