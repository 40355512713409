<template>
  <div ref="observedDiv" :class="{ 'should_reveal': isActive && isTimeout }">
    <slot></slot>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";

export default {
  name: "AppearOnScroll",
  props: {
    delay: { type: Number, default: 1 },
  },
  setup(props) {
    const observedDiv = ref(null);
    const isActive = ref(false);
    const isTimeout = ref(false);

    const checkPosition = () => {
      if (!observedDiv.value) return;

      const rect = observedDiv.value.getBoundingClientRect();
      const screenHeight = window.innerHeight || document.documentElement.clientHeight;

      if(rect.top <= screenHeight * 9 / 10) {
        isActive.value = true
      }
    };

    watch(
      () => props.delay,
      (givenDelay) => {
        setTimeout(() => {
          isTimeout.value = true;
        }, givenDelay);
      },
      { immediate: true }
    );
    onMounted(() => {
      window.addEventListener('scroll', checkPosition);
      checkPosition();
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', checkPosition);
    });

    return { observedDiv, isActive, isTimeout };
  },
};
</script>
