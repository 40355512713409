<template>
  <svg
    v-if="currWidth > 766"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :class="currClassState"
    viewBox="0 0 612 792"
    style="enable-background: new 0 0 612 792"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M0,585.94c53.88-8.69,98.47,7.32,232.29-40.98c116.9-42.19,288.35-105.72,293.48-192.43
	c5.34-90.36-155.46-131.54-148.45-196.04c4.2-38.64,24.12-70.69,158.95-120.25C553.53,29.89,593.72,5.49,593.81,0L0,0V585.94
	L0,585.94z"
    />
  </svg>
  <svg
    v-else-if="currWidth <= 766"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :class="currClassState"
    viewBox="0 0 612 792"
    style="enable-background: new 0 0 612 792"
    xml:space="preserve"
  >
  <path class="st0" d="M-4.3,693.7c19.4,34.6,71.7,75.8,101.6,73.8c26.4-1.7,25.6,1.9,53-20.6c62.7-65.4,104-93.6,130.7-93.5
	c54.2,0.3,56.9,60.9,90,83.2c30,26.2,52.7,42.1,79.5,43c26.6,0.8,46.8-14,72.4-29.9c23.4-17.2,90.2-143,88.9-376.5
	c1.3-204.6-41.9-282.1-77.2-304.9c-83.4-54-156.5-32.7-156.5-32.7c-39.7,11.6-67.7,39.7-95.9,25.7c-26.5-13.1-21.7-47.8-44.1-53.7
	c-22.5-5.9-35.6,26.9-91.5,58.3c-31,17.4-50.5,28.4-71.6,22.2C40.5,78,36.7,32,11.1,29.9c-24.2-2-45.5,37.4-51.8,49
	c-23.6,43.8-52.6,101.6-52.6,291.2C-93.2,540.1-31.2,662.2-4.3,693.7z"/>
  </svg>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
export default {
  name: "TealPCVue",
  setup() {
    const currClassState = ref("init");
    const currWidth = ref(window.innerWidth);
    const onResize = () => {
      currWidth.value = window.innerWidth;
    };
    onMounted(() => {
      window.addEventListener("resize", onResize);
      setTimeout(() => {
        currClassState.value = "done";
      }, 1100);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
    });

    return { currClassState, currWidth };
  },
};
</script>

<style scoped>
@keyframes scaleUp {
  0% {
    transform: scale(0);
    margin-top: -400px;
  }
  90% {
    transform: scale(1.1);
    margin-top: -20px;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes moveLeft {
  0% {
    margin-left: -300%;
    margin-top: 180%;
  }
  90% {
    transform: scale(1.1);
    margin-top: 30%;
  }
  100% {
    transform: scale(1);
    margin-top: 30%;
  }
}
.st0 {
  fill: #b7dfdf;
}
svg {
  width: 100vw;
  transform-origin: top left;
  margin-top: -20px;
  margin-left: -20px;
}
svg.init {
  animation: scaleUp 1s forwards;
}
@media screen and (max-width: 766px) {
    svg {
      display: block;
      scale: 2;
      margin-top: 30%;
    }
svg.init {
  animation: moveLeft 1s forwards;
}
  }
</style>
