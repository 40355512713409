<template>
  <div class="d-flex justify-content-center margin-hafneg">
    <div class="spinner"></div>

</div>
</template>


<script>
export default {
  name: "SendingAnim",
};
</script>

<style scoped>
@keyframes rotate {
	from { transform: rotate(0deg);   }
	to   { transform: rotate(360deg); }
}
.margin-hafneg {
    margin: -1.4vw
}
.spinner {
	animation: rotate 1s linear infinite;
	background: white;
	border-radius: 50%;
	height: 5vw;
	width: 5vw;
	position: relative;
}
.d-flex{
    display: flex;
}
.justify-content-center {
    justify-content: center;
}

.spinner:before,
.spinner:after {
	content: '';
	position: absolute;
}

.spinner:before {
	border-radius: 50%;
	background:
		linear-gradient(0deg,   hsla(0, 0%, 100%, 1  ) 50%, hsla(0, 0%, 100%, 0.9) 100%)   0%   0%,
		linear-gradient(90deg,  hsla(0, 0%, 100%, 0.9)  0%, hsla(0, 0%, 100%, 0.6) 100%) 100%   0%,
		linear-gradient(180deg, hsla(0, 0%, 100%, 0.6)  0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,
		linear-gradient(360deg, hsla(0, 0%, 100%, 0.3)  0%, hsla(0, 0%, 100%, 0  ) 100%)   0% 100%
	;
	background-repeat: no-repeat;
	background-size: 50% 50%;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
}

.spinner:after {
	background: #E8EAF6;
	border-radius: 50%;
	top: 3%;
	bottom: 3%;
	left: 3%;
	right: 3%;
}
</style>
